import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from "@material-ui/core/colors/green";
import clsx from "clsx";
import Avatar from '@material-ui/core/Avatar';
// import Button from "@material-ui/core/Button";
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

// For Tabs
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Box from '@material-ui/core/Box';

// react-responsive
import { useMediaQuery } from 'react-responsive';

// Lodash
import lodash from "lodash";

// Amplify
import { API, Storage } from "aws-amplify";
// import Amplify, { API } from 'aws-amplify';
// import AmplifyConfig from '../../config/AmplifyConfig';

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import LineSpacing from "../misc/LineSpacing";
import DomainUploadToS3 from "./DomainUploadToS3";
import GlobalErrorMessage from "../dialogs/GlobalErrorMessage";

// AppContext
import AppContext from "../../config/AppContext";

const StyledPaper = styled(Paper)`
  min-width: 300px;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100% !important",
        // display: "flex",
        // flexDirection: "column",
        // position: "relative",
        // background: 'red',
        // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
        textAlign: "center",
    },
    main: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
        background: '#fff',
    },
    progress: {
        margin: theme.spacing(2),
    },
    mainForm: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
        // background: '#fff',
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    footer: {
        bottom: 0,
        height: "1rem" /* Footer height */,
        width: "100% !important",
        textAlign: "center",
    },
    tabsRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        // width: '100%',
        // height: 224,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        // width: '100%',
    },
    tabPanel: {
        width: '100%',
    },
    large: {
        width: theme.spacing(14),
        height: theme.spacing(14),
    },
    hdr: {
        color: "#000", 
        textAlign: "center",
        marginTop: theme.spacing(2),
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    formControlLabel: {
        display: "flex",
        textAlign: "left",
        verticalAlign: "middle",
    },
    greenColor: {
        color: green[500],
    },
    divWrapper: {
        width: '100% !important',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    textField: {
        width: "100%",
    },
    leftPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        textAlign: "left",
    },
}));

let pageLabels = {
    commonUploadCenterLabel: "Common upload center",
    requiredFields: "* Required fields",

    languageLabel: "Language",
    languagePrompt: "Select language",
    languageErrMsg: "Language is required",

    domainLabel: "Domain",
    domainPrompt: "Select a domain",
    domainErrMsg: "Domain is required",
    
    uploadCategoryLabel: "Category",
    uploadCategoryPrompt: "Select a category",
    uploadCategoryErrMsg: "Category is required",

    // For BlogCapture Upload
    uploadBlogTitleLabel: "Blog title",
    uploadBlogTitlePrompt: "Please select blog title",
    uploadBlogTitleErrMsg: "Blog title is required",

    uploadTypeLabel: "Upload type",
    uploadTypePrompt: "Select upload type",
    uploadTypeErrMsg: "Upload type is required",

    docsAllowedTypeMsg: "Only TXT, PDF, DOC, DOCX, PPT, PPTX, XLS, XLSX, or GZIP files are allowed.",
    imageAllowedTypeMsg: "Only PNG and JPG (JPEG) photo or image files are allowed.",
    faviconAllowedTypeMsg: "Only ICO and PNG image files are allowed.",
    audioAllowedMsg: "Only MP3, OGG and WAV files are allowed. Maximum file size allowed is 16MB.",
    videoAllowedMsg: "Only MP4, WebM files are allowed. Maximum file size allowed is 16MB.",

    loadingText: "Loading ...",
    noOptionsText: "No options",
    clearText: "Clear",
    closeText: "Close",
};

function DomainUploadCapture(props) {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);
    // const [ isSignedIn, setIsSignedIn ] = useState(appContext.signedIn);

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
    
    const [tabLabel, setTabIndex] = React.useState(0);
    const [progressFlag, setProgressFlag] = useState(false);

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);
    const [currentSelectedMenu, setCurrentSelectedMenu] = useState("DomainUploadCapture");
    const [renderDomFlag, setRenderDomFlag] = useState(false);

    // For Global Errors
    const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");

    const { 
        showHeadersFlag,
        forUserOnly,
    } = props;

    //-----------------------
    // For file upload
    //-----------------------
  
    const [acceptedFiles, setAcceptedFiles] = useState(["image/jpeg","image/png"]);

    // public, protected or private
    const [storageLevel, setStorageLevel] = useState(appContext.storageLevel); 
    // e.g. appContext.shortDomain + "/profile/" + appContext.userName + "/"
    const [keyPrefix, setKeyPrefix] = useState(appContext.keyPrefix);
    const [filesLimit, setFilesLimit] = useState(1000); // Maximum number of files that can be loaded into the dropzone

    const [showFileNamesInPreview, setShowFileNamesInPreview] = useState(appContext.showFileNamesInPreview); // Shows file name under the image
    const [showFileNames, setShowFileNames] = useState(appContext.showFileNames); // Shows file name under the dropzone image.
    // const [showPreviewsFlag, setShowPreviewsFlag] = useState(true); // Shows previews BELOW the Dropzone
    // const [showPreviewsInDropzoneFlag, setShowPreviewsInDropzoneFlag] = useState(true); // Shows preview INSIDE the dropzone

    //-----------------------------------------------------
    // For AppsInfoFooter
    // Google Play and Apple Store Info
    //-----------------------------------------------------

    const [renderGoogleAppleDomFlag, setRenderGoogleAppleDomFlag] = useState(appContext.appsInfoFooter.renderGoogleAppleDomFlag);
    const [googleAppleInfoFlag, setGoogleAppleInfoFlag] = useState(appContext.appsInfoFooter.googleAppleInfoFlag);

    // For Checkbox
    // const [thumbnailFlag, setThumbnailFlag] = useState(false);
    // const [bannerFlag, setBannerFlag] = useState(false);
    // const [imagesFlag, setImagesFlag] = useState(false);
    // const [photosFlag, setPhotosFlag] = useState(false);
    // const [audioFilesFlag, setAudioFilesFlag] = useState(false);
    // const [videoFilesFlag, setVideoFilesFlag] = useState(false);

    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    //-------------------------
    // For Language and Domain
    //-------------------------
    const [language, setLanguage] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [languageErrFlag, setLanguageErrFlag] = useState(false);

    const [domain, setDomain] = useState(null);
    const [domainOptions, setDomainOptions] = useState([]);
    const [domainErrFlag, setDomainErrFlag] = useState(false);

    const [submitButtonFlag, setSubmitButtonFlag] = useState(false);
    const [editModeFlag, setEditModeFlag] = useState(false);

    //---------------------
    // For Form Attributes
    //---------------------
    const [uploadType, setUploadType] = useState(null);
    const [uploadTypeErrFlag, setUploadTypeErrFlag] = useState(false);
    const [uploadTypeOptions, setUploadTypeOptions] = useState([]);
    
    // const [uploadCategory, setUploadCategory] = useState(null);
    const [uploadCategoryErrFlag, setUploadCategoryErrFlag] = useState(false);
    const [readOnlyFlag, setReadOnlyFlag] = useState(false);
    const [uploadCategoryOptions, setUploadCategoryOptions] = useState([]);

    const classes = useStyles();

    const directionAlignment = {
        textAlign: direction === "ltr" ? "left" : "right",
    };

    const marginAlignment = {
        flex: "1",
        marginRight: appContext.direction === "ltr" ? "12px" : "0px",
        marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    };

    const { 
        commonUploadCenterLabel,
        requiredFields,

        languageLabel,
        languagePrompt,
        languageErrMsg,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,

        uploadCategoryLabel,
        uploadCategoryPrompt,
        uploadCategoryErrMsg,

        // For BlogCapture Upload
        uploadBlogTitleLabel,
        uploadBlogTitlePrompt,
        uploadBlogTitleErrMsg,

        uploadTypeLabel,
        uploadTypePrompt,
        uploadTypeErrMsg,

        docsAllowedTypeMsg,
        imageAllowedTypeMsg,
        faviconAllowedTypeMsg,
        audioAllowedMsg,
        videoAllowedMsg,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,
    } = pageLabelsRes;

    const [uploadCategory, setUploadCategory] = useState(null);

    async function fetchDbResources(cacheItemKey) {
        let keys = [];
        let objKeys = Object.keys(pageLabels);
        // console.log("In DomainUploadCapture(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
        if (objKeys !== null && objKeys.length > 0) {
            objKeys.map((key) => {
                let partitionKey = appContext.currentLanguage.languageCode;
                partitionKey += ".";
                partitionKey += GenerateModulusHash(key, 200);

                let itemKey = {
                    ResourcePK: partitionKey, // e.g. 'en.91'
                    ResourceSK: key, // e.g. 'firstName'
                };
                keys.push(itemKey);
            });
        }
        // console.log("In DomainUploadCapture(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

        let arrayData = [];

        setProgressFlag(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/batchGet",
            payload: {
                keys: keys,
                projectionExpression: "ResourceCode,ResourceDesc",
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
            response.data.Responses.resource.map((item, index) => {
                let record = {
                    ResourceCode: item.ResourceCode,
                    ResourceDesc: item.ResourceDesc,
                };

                arrayData.push(record);
            });

            if (arrayData && arrayData.length > 0) {
                arrayData.map((item) => {
                    if (item.ResourceCode === "commonUploadCenterLabel") pageLabels.commonUploadCenterLabel = item.ResourceDesc;
                    if (item.ResourceCode === "requiredFields") pageLabels.requiredFields = item.ResourceDesc;
                    
                    if (item.ResourceCode === "languageLabel") pageLabels.languageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "languagePrompt") pageLabels.languagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "languageErrMsg") pageLabels.languageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "domainLabel") pageLabels.domainLabel = item.ResourceDesc;
                    if (item.ResourceCode === "domainPrompt") pageLabels.domainPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "domainErrMsg") pageLabels.domainErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "uploadCategoryLabel") pageLabels.uploadCategoryLabel = item.ResourceDesc;
                    if (item.ResourceCode === "uploadCategoryPrompt") pageLabels.uploadCategoryPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "uploadCategoryErrMsg") pageLabels.uploadCategoryErrMsg = item.ResourceDesc;
                            
                    if (item.ResourceCode === "uploadTypeLabel") pageLabels.uploadTypeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "uploadTypePrompt") pageLabels.uploadTypePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "uploadTypeErrMsg") pageLabels.uploadTypeErrMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "uploadBlogTitleLabel") pageLabels.uploadBlogTitleLabel = item.ResourceDesc;
                    if (item.ResourceCode === "uploadBlogTitlePrompt") pageLabels.uploadBlogTitlePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "uploadBlogTitleErrMsg") pageLabels.uploadBlogTitleErrMsg = item.ResourceDesc;
                                                
                    if (item.ResourceCode === "docsAllowedTypeMsg") pageLabels.docsAllowedTypeMsg = item.ResourceDesc;
                    if (item.ResourceCode === "imageAllowedTypeMsg") pageLabels.imageAllowedTypeMsg = item.ResourceDesc;
                    if (item.ResourceCode === "faviconAllowedTypeMsg") pageLabels.faviconAllowedTypeMsg = item.ResourceDesc;
                    if (item.ResourceCode === "audioAllowedMsg") pageLabels.audioAllowedMsg = item.ResourceDesc;
                    if (item.ResourceCode === "videoAllowedMsg") pageLabels.videoAllowedMsg = item.ResourceDesc;
                                                
                    if (item.ResourceCode === "loadingText") pageLabels.loadingText = item.ResourceDesc;
                    if (item.ResourceCode === "noOptionsText") pageLabels.noOptionsText = item.ResourceDesc;
                    if (item.ResourceCode === "clearText") pageLabels.clearText = item.ResourceDesc;
                    if (item.ResourceCode === "closeText") pageLabels.closeText = item.ResourceDesc;
                });
                // Add it to cache
                SetCacheItem(cacheItemKey, pageLabels);
            }

            // setRenderGridFlag(true);
            setProgressFlag(false);
            setPageLabelsRes(pageLabels);
            setRenderDomFlag(true);
        }).catch((err) => {
            console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
            setPageLabelsRes(pageLabels);
            setProgressFlag(false);
            setRenderDomFlag(true);
        });
    }

    let uploadCategorySelectOptions = [];

    async function fetchGroupCategories(selectedUploadType) {
        // console.log('In fetchGroupCategories(): selectedUploadType is: ', selectedUploadType);
        // console.log('In fetchGroupCategories(): language is: ', language);
        
        setProgressFlag(true);

        let apiName = null;
        let path = null;
        let payload = {};
        let langCode = language ? language.languageCode : appContext.currentLanguage.languageCode;

        apiName = "ResourceAPI";
        path = "/dynamodb/resources/query";
        payload = {
            keyConditionExpression:
            "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
            expressionAttributeNames: {
                "#resourcePK": "ResourcePK",
                "#resourceSK": "ResourceSK",
            },
            expressionAttributeValues: {
                ":resourcePK": selectedUploadType,
                ":resourceSK": langCode,
            },
        };

        let params = {
            apiName: apiName,
            path: path,
            payload: payload,
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchGroupCategories(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchGroupCategories(): response.length is: ', response.data.Items.length);
            // console.log('In fetchGroupCategories(): response is: ', JSON.stringify(response.data.Items, null, 2));
            response.data.Items.map((item) => {
                if (item.ResourceCode !== "all") {
                    let record = {};

                    record.value = item.ResourceCode;
                    record.text = item.ResourceDesc;
            
                    uploadCategorySelectOptions.push(record);
                }   
            });

            uploadCategorySelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
            setUploadCategoryOptions(uploadCategorySelectOptions);
            setProgressFlag(false);
        }).catch((err) => {
            console.error("In fetchGroupCategories(): err is: ", JSON.stringify(err, null, 2));
            setProgressFlag(false);
        });
    }

    async function fetchLanguages() {
        // console.log("In fetchLanguages()");
        // setFilterDisabledFlag(true);
        return new Promise((resolve, reject) => {
            backdropProgress(true);

            //-------------------------------------------------------
            // Make sure languages is not in cache before proceeding
            //-------------------------------------------------------

            let cachedLanguages = GetCacheItem("languages");
            // console.log('In fetchLanguages(): cachedLanguages is: ', JSON.stringify(cachedLanguages, null, 2));
            cachedLanguages = null;
            if (cachedLanguages && cachedLanguages !== null && cachedLanguages.length > 0) {
                // console.log('In fetchLanguages(): cachedLanguages length is: ', cachedLanguages.length);
                setLanguages(cachedLanguages);
                backdropProgress(false);
                resolve(cachedLanguages);
                return true;
            }

            let params = {
                apiName: "ResourceAPI",
                path: "/dynamodb/resources/query",
                payload: {
                    keyConditionExpression: "#resourcePK = :resourcePK",
                    expressionAttributeNames: { "#resourcePK": "ResourcePK" },
                    expressionAttributeValues: { ":resourcePK": "languages" },
                },
            };

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log("In fetchLanguages(): response length is: ", response.data.Items.length);
                // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));

                // response.data.Items.sort((a, b) =>
                //     a.LanguageName > b.LanguageName ? 1 : -1
                // );

                let records = [];
                let items = response.data.Items;

                items.map(item => {
                    let record ={
                        languageCode: item.ResourceCode,
                        languageName: item.LanguageName
                    };
                    records.push(record);
                });

                // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
                setLanguages(records);

                // Add it to cache
                SetCacheItem("languages", response.data.Items);
                backdropProgress(false);
                resolve(records);
            }).catch((err) => {
                console.error("In fetchLanguages(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject("Error in fetchLanguages()");
            });
        });
    };

    let  refDataSelectOptions = [];
    
    async function fetchReferenceData(refType, lang) {
        return new Promise((resolve, reject) => {
            // console.log('In ManageFaqs  fetchReferenceData(): refType is: ', refType);
            // console.log('In ManageFaqs  fetchReferenceData(): lang is: ', lang);
            backdropProgress(true);

            refDataSelectOptions = [];

            let apiName = null;
            let path = null;
            let keyConditionExpression = null;
            let expressionAttributeNames = {};
            let expressionAttributeValues = {};
            
            switch(refType) {
                case "supported-domains":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "supported-domains",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                case "media-categories":// private-upload-types
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {//
                        ":resourcePK": "media-categories",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                default:
                    break;
            }

            setProgressFlag(true);
        
            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    keyConditionExpression: keyConditionExpression,
                    expressionAttributeNames: expressionAttributeNames,
                    expressionAttributeValues: expressionAttributeValues,
                    // "keyConditionExpression": "#resourcePK = :resourcePK",
                    // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                    // "expressionAttributeValues": {":resourcePK": "languages"}
                },
            };
        
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
        
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In  fetchReferenceData(): initObj is: ', JSON.stringify(initObj, null, 2));
        
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In  fetchReferenceData(): response.length is: ', response.data.Items.length);
                // console.log('In  fetchReferenceData(): response is: ', JSON.stringify(response.data.Items, null, 2));
                response.data.Items.map((item) => {
                    let record = {
                        value: item.ResourceCode,
                        text: item.ResourceDesc,
                    };
    
                    if (record.value !== "all") {// Bypass all
                        refDataSelectOptions.push(record);
                    }
                });

                switch(refType) {
                    case "supported-domains":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setDomainOptions(refDataSelectOptions);
                        break;
                    case "media-categories":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setUploadTypeOptions(refDataSelectOptions);
                        break;
                    default:
                        break;
                }

                backdropProgress(false);
                resolve(refDataSelectOptions);
            }).catch((err) => {
                console.error("In ManageFaqs  fetchReferenceData(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject(err);
            });
        });
    }

    useEffect(() => {
        // console.log('In DomainUploadCapture(): useEffect()');
    }, []);

    useLayoutEffect(() => {
        // console.log("In DomainUploadCapture(): useLayoutEffect()");
        async function fetchItems() {
            await Promise.all([
                await fetchLanguages()
            ]).then(async (result) => {
                // console.log('In DomainUploadCapture(): useLayoutEffect(): result[0] is: ', result[0]);

                setStorageLevel("public");
                setShowFileNamesInPreview(true);
                setShowFileNames(true);
        
                // Fetch resource from catche
                let cacheItemKey = "domain-upload-capture";
                cacheItemKey += ":";
                cacheItemKey += appContext.currentLanguage.languageCode;
                // console.log('In DomainUploadCapture(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

                if (
                    appContext.clearCacheLanguageCodes &&
                    appContext.clearCacheLanguageCodes.length > 0 &&
                    appContext.clearCacheLanguageCodes.includes(
                        appContext.currentLanguage.languageCode
                    )
                ) {
                    // console.log("In DomainUploadCapture(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                    RemoveCacheItem(cacheItemKey);
                }

                let cacheItem = GetCacheItem(cacheItemKey);
                // console.log('In DomainUploadCapture(): useLayoutEffect(): cacheItem is: ', cacheItem);
                cacheItem = null; // remove after testing

                if (cacheItem === null) {
                    // resource is not in cache, fetch from DB
                    // console.log('In DomainUploadCapture(): useLayoutEffect(): resource is not in cache, fetch from DB');
                    await fetchDbResources(cacheItemKey);
                } else {
                    // console.log('In DomainUploadCapture(): useLayoutEffect(): got resource from cache');
                    setPageLabelsRes(cacheItem);
                    setRenderDomFlag(true);
                }
            }).catch((error) => {
                console.error('In DomainUploadCapture(): useLayoutEffect(): error is: ', error);
            });
        }

        fetchItems();
    }, []);
    
    function handleLanguageChange(event, item) {
        // console.log("In handleLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        setSubmitButtonFlag(false);        
        setLanguage(item);

        if (item && 
            !lodash.isEmpty(uploadType) &&
            !lodash.isEmpty(uploadCategory) &&
            !lodash.isEmpty(domain) 
            ) {
                setSubmitButtonFlag(true);
        }

        // if (
        //     !lodash.isEmpty(item) &&
        //     !lodash.isEmpty(domain) &&
        //     !lodash.isEmpty(blogTitle) &&
        //     !lodash.isEmpty(yourNames) &&
        //     !lodash.isEmpty(blogContent) &&
        //     !lodash.isEmpty(blogStartTime) &&
        //     !lodash.isEmpty(blogCategory) &&
        //     !lodash.isEmpty(blogTags) 
        // ) {
        //     setSubmitButtonFlag(true);
        // }

        if (!lodash.isEmpty(item)) {
            Promise.all([
                fetchReferenceData("supported-domains", item)
            ]).then(async (results) => {
                // console.log("In handleListLanguageChange(): results is: ", results);
                fetchReferenceData("media-categories", item)
            }).catch(error => {
                console.log("In handleListLanguageChange(): error is: ", error);
            });
        }
    }

    function handleDomainChange(event, item) {
        // console.log("In handleDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        setSubmitButtonFlag(false);

        if (item && 
            !lodash.isEmpty(uploadType) &&
            !lodash.isEmpty(uploadCategory) &&
            !lodash.isEmpty(language) 
            ) {
                setSubmitButtonFlag(true);
        }

        // if (
        //     !lodash.isEmpty(item) &&
        //     !lodash.isEmpty(language) &&
        //     !lodash.isEmpty(blogTitle) &&
        //     !lodash.isEmpty(yourNames) &&
        //     !lodash.isEmpty(blogContent) &&
        //     !lodash.isEmpty(blogStartTime) &&
        //     !lodash.isEmpty(blogCategory) &&
        //     !lodash.isEmpty(blogTags) 
        //     ) {
        //         setSubmitButtonFlag(true);
        //     }
    }

    // For UploadType
    async function handleUploadTypeChange(event, item) {
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
        setUploadTypeErrFlag(false);
        setSubmitButtonFlag(false);

        setUploadType(null);

        // setThumbnailFlag(false);
        // setBannerFlag(false);
        // setImagesFlag(false);
        // setPhotosFlag(false);
        // setAudioFilesFlag(false);
        // setVideoFilesFlag(false);

        setUploadCategory(null);
        setSubmitButtonFlag(false);
        setUploadCategoryOptions([]);
        setAcceptedFiles([])
        
        setUploadType(item);

        if (item && item.value) {
            switch(item.value) {
                case "thumbnails":
                    // setUploadType("thumbnails");
                    // setThumbnailFlag(true);
                    setFilesLimit(1000);
                    setAcceptedFiles(["image/jpeg","image/png"]);
                    await fetchGroupCategories("thumbnails");
                    break;
                case "banners":
                    // setUploadType("banners");
                    // setBannerFlag(true);
                    setFilesLimit(100);
                    setAcceptedFiles(["image/jpeg","image/png"]);
                    await fetchGroupCategories("banners");
                    break;
                case "favicons":
                    // setUploadType("favicons");
                    // setBannerFlag(true);
                    setFilesLimit(1);
                    setAcceptedFiles(["image/ico","image/png"]);
                    await fetchGroupCategories("favicons");
                    break;
                case "posters":
                    // setUploadType("posters");
                    // setBannerFlag(true);
                    setFilesLimit(100);
                    setAcceptedFiles(["image/jpeg","image/png"]);
                    await fetchGroupCategories("posters");
                    break;
                case "images":
                    // setUploadType("images");
                    // setImagesFlag(true);
                    setFilesLimit(1000);
                    setAcceptedFiles(["image/jpeg","image/png"]);
                    await fetchGroupCategories("images");
                    break;
                case "photos":
                    // setUploadType("photos");
                    // setPhotosFlag(true);
                    setFilesLimit(1000);
                    setAcceptedFiles(["image/jpeg","image/png"]);
                    await fetchGroupCategories("photos");
                    break;
                case "profile":
                    // setUploadType("profile");
                    // setPhotosFlag(true);
                    setFilesLimit(1);
                    setAcceptedFiles(["image/jpeg","image/png"]);
                    await fetchGroupCategories("profile");
                    break;
                case "audios":
                    // setUploadType("audios");
                    // setAudioFilesFlag(true);
                    setFilesLimit(1);
                    // setAcceptedFiles(["application/*"]);
                    setAcceptedFiles(["audio/mpeg","audio/ogg","audio/wav"]);
                    await fetchGroupCategories("audios");
                    break;
                case "videos":
                    // setUploadType("videos");
                    // setVideoFilesFlag(true);
                    setFilesLimit(1);
                    // setAcceptedFiles(["application/*"]);
                    // setAcceptedFiles(["video/mpeg","video/mp4","video/quicktime","video/webm","video/x-ms-wmv"]);
                    // Only mp4 and webm can play in html5 video player
                    setAcceptedFiles(["video/mp4","video/webm"]);
                    await fetchGroupCategories("videos");
                    break;
                case "docs":
                    // setUploadType("docs");
                    // setDocumentsFlag(true);
                    setFilesLimit(100);
                    setAcceptedFiles([
                        "text/plain",
                        "application/pdf",
                        "application/msword",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "application/vnd.ms-powerpoint",
                        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                        "application/vnd.ms-excel",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        "application/gzip"
                    ]);
                    await fetchGroupCategories("docs");
                default:
                    break;
            }
        }

        if (item && 
            !lodash.isEmpty(uploadCategory) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(domain) 
            ) {
                setSubmitButtonFlag(true);
        }
    }

    // For UploadCategory
    function handleUploadCategoryChange(event, item) {
        event.preventDefault();
        event.persist();
        // console.log("In handleUploadCategoryChange(): item is ", item);
        // console.log("In handleUploadCategoryChange(): videoFilesFlag is ", videoFilesFlag);
        // console.log("In handleUploadCategoryChange(): domain is ", domain);
        // console.log("In handleUploadCategoryChange(): language is ", language);

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
        setUploadCategoryErrFlag(false);
        setSubmitButtonFlag(false);
        
        setUploadCategory(item);

        // let s3KeyPrefix = null;

        // if (!lodash.isEmpty(uploadCategory) && !lodash.isEmpty(domain) && !lodash.isEmpty(language) && !lodash.isEmpty(uploadType)) {
        //     s3KeyPrefix = domain.value + "/" + language.languageCode + "/" + uploadType.value + "/" + item.value + "/";
        //     console.log('In handleUploadCategoryChange(): s3KeyPrefix is: ', s3KeyPrefix);
        //     setKeyPrefix(s3KeyPrefix);
        // }

        // Ensure upload type is checked
        if (item && 
            !lodash.isEmpty(uploadType) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(domain) 
            ) {
                setSubmitButtonFlag(true);
            }
    }

    function closePreviewPanel(param) {
        if (param) {
            // console.log('In closePreviewPanel(): param.createdCnt is: ', param.createdCnt);
            
            setUploadCategory(null);
            setTimeout(() => {
                setSubmitButtonFlag(false);
            }, 8000);
        }
    }

    function LanguageAndDomain(props) {
        return (
            <>
                {isDeskTopView &&
                    <>
                        <Grid item xs={12} sm={6}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                        <label htmlFor="languageId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                *{languageLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="languageId"
                                            value={language}
                                            options={languages}
                                            getOptionLabel={option => option.languageName}
                                            getOptionSelected={option => option.languageName}
                                            onChange={handleLanguageChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={languagePrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={languageErrFlag}
                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                        <label htmlFor="domainId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                *{domainLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="domainId"
                                            value={domain}
                                            options={domainOptions}
                                            getOptionLabel={option => option.text}
                                            getOptionSelected={option => option.text}
                                            onChange={handleDomainChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            // disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={domainPrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={domainErrFlag}
                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }

                {isTabletView &&
                    <>
                        <Grid item xs={12} sm={12}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                        <label htmlFor="languageId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                *{languageLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="languageId"
                                            value={language}
                                            options={languages}
                                            getOptionLabel={option => option.languageName}
                                            getOptionSelected={option => option.languageName}
                                            onChange={handleLanguageChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={languagePrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={languageErrFlag}
                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <LineSpacing value={12} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                        <label htmlFor="domainId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                *{domainLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="domainId"
                                            value={domain}
                                            options={domainOptions}
                                            getOptionLabel={option => option.text}
                                            getOptionSelected={option => option.text}
                                            onChange={handleDomainChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            // disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={domainPrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={domainErrFlag}
                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <LineSpacing value={12} />
                        </Grid>
                    </>
                }

                {isMobileView &&
                    <>
                        <Grid item xs={12} sm={12}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                        <label htmlFor="languageId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                *{languageLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="languageId"
                                            value={language}
                                            options={languages}
                                            getOptionLabel={option => option.languageName}
                                            getOptionSelected={option => option.languageName}
                                            onChange={handleLanguageChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={languagePrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={languageErrFlag}
                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <LineSpacing value={12} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                        <label htmlFor="domainId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                *{domainLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="domainId"
                                            value={domain}
                                            options={domainOptions}
                                            getOptionLabel={option => option.text}
                                            getOptionSelected={option => option.text}
                                            onChange={handleDomainChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            // disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={domainPrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={domainErrFlag}
                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <LineSpacing value={12} />
                        </Grid>
                    </>
                }
            </>
        );
    }

    //====================RETURN=====================================================================================

    return (
        <div dir={direction}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {renderDomFlag && 
                <div className={classes.root}>
                    {/* {!appContext.signedIn && <Redirect to="/signIn" />} */}

                    <CssBaseline />

                    {isDeskTopView &&
                        <Container component="div" className={classes.main} maxWidth="lg">
                            <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                                <Grid container className={classes.mainForm} justify="center" alignContent="center" alignItems="center" direction="row"> 
                                    <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                                        <Grid container item justify="center">
                                            {showHeadersFlag &&
                                                <>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant="h5" color="primary">
                                                            {commonUploadCenterLabel}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant="caption" className={classes.greenColor}>
                                                            {requiredFields}
                                                        </Typography>
                                                    </Grid>

                                                    {globalErrorFlag && (
                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                            <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                                                        </Grid>
                                                    )}

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={4} />
                                                    </Grid>
                                                </>
                                            }

                                            <LanguageAndDomain/>
                                                
                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={12} />
                                            </Grid>

                                            <Grid item xs={12} sm={6} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={classes.formControl} error={uploadTypeErrFlag}>
                                                    <label htmlFor="uploadTypeId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={uploadTypeErrFlag ? "secondary" : "inherit"}>
                                                            *{uploadTypeLabel}
                                                        </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="uploadTypeId"
                                                        value={uploadType}
                                                        options={uploadTypeOptions}
                                                        getOptionLabel={option => option.text}
                                                        getOptionSelected={option => option.text}
                                                        onChange={handleUploadTypeChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        // disabled={readOnlyFlag}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={uploadTypePrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={uploadTypeErrFlag}
                                                                helperText={uploadTypeErrFlag ? uploadTypeErrMsg : ""}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6} className={clsx(classes.rightPanelSubItems)}>
                                                <FormControl className={classes.formControl} error={uploadCategoryErrFlag}>
                                                    <label htmlFor="uploadCategoryId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                    <Typography variant="subtitle1" color={uploadCategoryErrFlag ? "secondary" : "inherit"}>
                                                        *{uploadCategoryLabel}
                                                    </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="uploadCategoryId"
                                                        value={uploadCategory}
                                                        options={uploadCategoryOptions}
                                                        getOptionLabel={option => option.text}
                                                        getOptionSelected={option => option.text}
                                                        onChange={handleUploadCategoryChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={uploadCategoryPrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={uploadCategoryErrFlag}
                                                                helperText={uploadCategoryErrFlag ? uploadCategoryErrMsg : ""}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={24} />
                                            </Grid>

                                            <Grid item xs={12} sm={12} style={{marginBottom: '24px'}}>
                                                <Typography variant="subtitle1" color="primary">
                                                    {uploadType === "thumbnails" &&
                                                        <>
                                                            {imageAllowedTypeMsg}
                                                        </>
                                                    }
                                                    {uploadType === "banners" &&
                                                        <>
                                                            {imageAllowedTypeMsg}
                                                        </>
                                                    }
                                                    {uploadType === "posters" &&
                                                        <>
                                                            {imageAllowedTypeMsg}
                                                        </>
                                                    }
                                                    {uploadType === "images" &&
                                                        <>
                                                            {imageAllowedTypeMsg}
                                                        </>
                                                    }
                                                    {uploadType === "photos" &&
                                                        <>
                                                            {imageAllowedTypeMsg}
                                                        </>
                                                    }
                                                    {uploadType === "profile" &&
                                                        <>
                                                            {imageAllowedTypeMsg}
                                                        </>
                                                    }

                                                    {uploadType === "audios" &&
                                                        <>
                                                            {audioAllowedMsg}
                                                        </>
                                                    }

                                                    {uploadType === "videos" &&
                                                        <>
                                                            {videoAllowedMsg}
                                                        </>
                                                    }

                                                    {uploadType === "favicons" &&
                                                        <>
                                                            {faviconAllowedTypeMsg}
                                                        </>
                                                    }

                                                    {uploadType === "docs" &&
                                                        <>
                                                            {docsAllowedTypeMsg}
                                                        </>
                                                    }
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                {submitButtonFlag &&
                                                    <DomainUploadToS3 
                                                        acceptedFiles={acceptedFiles}
                                                        storageLevel={storageLevel}
                                                        filesLimit={filesLimit}
                                                        showFileNamesInPreview={showFileNamesInPreview}
                                                        showFileNames={showFileNames}

                                                        forUserOnly={forUserOnly}

                                                        language={language}
                                                        domain={domain}
                                                        uploadType={uploadType}
                                                        uploadCategory={uploadCategory}
                                                        closePreviewPanelCallback={closePreviewPanel}
                                                    />
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <LineSpacing value={24} />
                                </Grid>
                            </div>
                        </Container>
                    }

                    {isTabletView &&
                        <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                            <Grid container className={classes.mainForm} justify="center" alignContent="center" alignItems="center" direction="row"> 
                                <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                                    <Grid container item justify="center">
                                        {showHeadersFlag &&
                                            <>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="h5" color="primary" style={{paddingTop: "8px"}}>
                                                        {commonUploadCenterLabel}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={24} />
                                                </Grid>
                                            </>
                                        }

                                        <LanguageAndDomain/>
                                            
                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <FormControl className={classes.formControl} error={uploadTypeErrFlag}>
                                                <label htmlFor="uploadTypeId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                    <Typography variant="subtitle1" color={uploadTypeErrFlag ? "secondary" : "inherit"}>
                                                        *{uploadTypeLabel}
                                                    </Typography>
                                                </label>

                                                <Autocomplete
                                                    id="uploadTypeId"
                                                    value={uploadType}
                                                    options={uploadTypeOptions}
                                                    getOptionLabel={option => option.text}
                                                    getOptionSelected={option => option.text}
                                                    onChange={handleUploadTypeChange}
                                                    loadingText={loadingText}
                                                    noOptionsText={noOptionsText}
                                                    autoHighlight={true}
                                                    clearText={clearText}
                                                    closeText={closeText}
                                                    blurOnSelect={true}
                                                    renderInput={params => 
                                                        <TextField 
                                                            {...params} 
                                                            placeholder={uploadTypePrompt} 
                                                            variant="outlined"
                                                            fullWidth
                                                            error={uploadTypeErrFlag}
                                                            helperText={uploadTypeErrFlag ? uploadTypeErrMsg : ""}
                                                        />
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <FormControl className={classes.formControl} error={uploadCategoryErrFlag}>
                                                <label htmlFor="uploadCategoryId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                <Typography variant="subtitle1" color={uploadCategoryErrFlag ? "secondary" : "inherit"}>
                                                    *{uploadCategoryLabel}
                                                </Typography>
                                                </label>

                                                <Autocomplete
                                                    id="uploadCategoryId"
                                                    value={uploadCategory}
                                                    options={uploadCategoryOptions}
                                                    getOptionLabel={option => option.text}
                                                    getOptionSelected={option => option.text}
                                                    onChange={handleUploadCategoryChange}
                                                    loadingText={loadingText}
                                                    noOptionsText={noOptionsText}
                                                    autoHighlight={true}
                                                    clearText={clearText}
                                                    closeText={closeText}
                                                    blurOnSelect={true}
                                                    renderInput={params => 
                                                        <TextField 
                                                            {...params} 
                                                            placeholder={uploadCategoryPrompt} 
                                                            variant="outlined"
                                                            fullWidth
                                                            error={uploadCategoryErrFlag}
                                                            helperText={uploadCategoryErrFlag ? uploadCategoryErrMsg : ""}
                                                        />
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>

                                        <Grid item xs={12} sm={12} style={{marginBottom: '24px'}}>
                                            <Typography variant="subtitle1" color="primary">
                                                {uploadType === "thumbnails" &&
                                                    <>
                                                        {imageAllowedTypeMsg}
                                                    </>
                                                }
                                                {uploadType === "banners" &&
                                                    <>
                                                        {imageAllowedTypeMsg}
                                                    </>
                                                }
                                                {uploadType === "posters" &&
                                                    <>
                                                        {imageAllowedTypeMsg}
                                                    </>
                                                }
                                                {uploadType === "images" &&
                                                    <>
                                                        {imageAllowedTypeMsg}
                                                    </>
                                                }
                                                {uploadType === "photos" &&
                                                    <>
                                                        {imageAllowedTypeMsg}
                                                    </>
                                                }
                                                {uploadType === "profile" &&
                                                    <>
                                                        {imageAllowedTypeMsg}
                                                    </>
                                                }

                                                {uploadType === "audios" &&
                                                    <>
                                                        {audioAllowedMsg}
                                                    </>
                                                }

                                                {uploadType === "videos" &&
                                                    <>
                                                        {videoAllowedMsg}
                                                    </>
                                                }

                                                {uploadType === "favicons" &&
                                                    <>
                                                        {faviconAllowedTypeMsg}
                                                    </>
                                                }

                                                {uploadType === "docs" &&
                                                    <>
                                                        {docsAllowedTypeMsg}
                                                    </>
                                                }
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            {submitButtonFlag &&
                                                <DomainUploadToS3 
                                                    acceptedFiles={acceptedFiles}
                                                    storageLevel={storageLevel}
                                                    filesLimit={filesLimit}
                                                    showFileNamesInPreview={showFileNamesInPreview}
                                                    showFileNames={showFileNames}

                                                    language={language}
                                                    domain={domain}
                                                    uploadType={uploadType}
                                                    uploadCategory={uploadCategory}
                                                    closePreviewPanelCallback={closePreviewPanel}
                                                />
                                            }
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={24} />
                            </Grid>
                        </div>
                    }

                    {isMobileView &&
                        <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                            <Grid container className={classes.mainForm} justify="center" alignContent="center" alignItems="center" direction="row"> 
                                <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                                    <Grid container item justify="center">
                                        {showHeadersFlag &&
                                            <>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="h5" color="primary" style={{paddingTop: "8px"}}>
                                                        {commonUploadCenterLabel}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={24} />
                                                </Grid>
                                            </>
                                        }

                                        <LanguageAndDomain/>
                                            
                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <FormControl className={classes.formControl} error={uploadTypeErrFlag}>
                                                <label htmlFor="uploadTypeId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                    <Typography variant="subtitle1" color={uploadTypeErrFlag ? "secondary" : "inherit"}>
                                                        *{uploadTypeLabel}
                                                    </Typography>
                                                </label>

                                                <Autocomplete
                                                    id="uploadTypeId"
                                                    value={uploadType}
                                                    options={uploadTypeOptions}
                                                    getOptionLabel={option => option.text}
                                                    getOptionSelected={option => option.text}
                                                    onChange={handleUploadTypeChange}
                                                    loadingText={loadingText}
                                                    noOptionsText={noOptionsText}
                                                    autoHighlight={true}
                                                    clearText={clearText}
                                                    closeText={closeText}
                                                    blurOnSelect={true}
                                                    renderInput={params => 
                                                        <TextField 
                                                            {...params} 
                                                            placeholder={uploadTypePrompt} 
                                                            variant="outlined"
                                                            fullWidth
                                                            error={uploadTypeErrFlag}
                                                            helperText={uploadTypeErrFlag ? uploadTypeErrMsg : ""}
                                                        />
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <FormControl className={classes.formControl} error={uploadCategoryErrFlag}>
                                                <label htmlFor="uploadCategoryId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                <Typography variant="subtitle1" color={uploadCategoryErrFlag ? "secondary" : "inherit"}>
                                                    *{uploadCategoryLabel}
                                                </Typography>
                                                </label>

                                                <Autocomplete
                                                    id="uploadCategoryId"
                                                    value={uploadCategory}
                                                    options={uploadCategoryOptions}
                                                    getOptionLabel={option => option.text}
                                                    getOptionSelected={option => option.text}
                                                    onChange={handleUploadCategoryChange}
                                                    loadingText={loadingText}
                                                    noOptionsText={noOptionsText}
                                                    autoHighlight={true}
                                                    clearText={clearText}
                                                    closeText={closeText}
                                                    blurOnSelect={true}
                                                    renderInput={params => 
                                                        <TextField 
                                                            {...params} 
                                                            placeholder={uploadCategoryPrompt} 
                                                            variant="outlined"
                                                            fullWidth
                                                            error={uploadCategoryErrFlag}
                                                            helperText={uploadCategoryErrFlag ? uploadCategoryErrMsg : ""}
                                                        />
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>

                                        <Grid item xs={12} sm={12} style={{marginBottom: '24px'}}>
                                            <Typography variant="subtitle1" color="primary">
                                                {uploadType === "thumbnails" &&
                                                    <>
                                                        {imageAllowedTypeMsg}
                                                    </>
                                                }
                                                {uploadType === "banners" &&
                                                    <>
                                                        {imageAllowedTypeMsg}
                                                    </>
                                                }
                                                {uploadType === "posters" &&
                                                    <>
                                                        {imageAllowedTypeMsg}
                                                    </>
                                                }
                                                {uploadType === "images" &&
                                                    <>
                                                        {imageAllowedTypeMsg}
                                                    </>
                                                }
                                                {uploadType === "photos" &&
                                                    <>
                                                        {imageAllowedTypeMsg}
                                                    </>
                                                }
                                                {uploadType === "profile" &&
                                                    <>
                                                        {imageAllowedTypeMsg}
                                                    </>
                                                }

                                                {uploadType === "audios" &&
                                                    <>
                                                        {audioAllowedMsg}
                                                    </>
                                                }

                                                {uploadType === "videos" &&
                                                    <>
                                                        {videoAllowedMsg}
                                                    </>
                                                }

                                                {uploadType === "favicons" &&
                                                    <>
                                                        {faviconAllowedTypeMsg}
                                                    </>
                                                }

                                                {uploadType === "docs" &&
                                                    <>
                                                        {docsAllowedTypeMsg}
                                                    </>
                                                }
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            {submitButtonFlag &&
                                                <DomainUploadToS3 
                                                    acceptedFiles={acceptedFiles}
                                                    storageLevel={storageLevel}
                                                    filesLimit={filesLimit}
                                                    showFileNamesInPreview={showFileNamesInPreview}
                                                    showFileNames={showFileNames}

                                                    language={language}
                                                    domain={domain}
                                                    uploadType={uploadType}
                                                    uploadCategory={uploadCategory}
                                                    closePreviewPanelCallback={closePreviewPanel}
                                                />
                                            }
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={24} />
                            </Grid>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default DomainUploadCapture;
