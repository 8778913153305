import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
} from "react";
import styled, { css } from "styled-components";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
import Link from "@material-ui/core/Link";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import GlobalConstants from "../../utils/GlobalConstants";
// import GeneratePassword from '../../utils/GeneratePassword';
// import GenerateClientId from '../../utils/GenerateClientId';
// import GenerateTransactionId from '../../utils/GenerateTransactionId';
// import GenerateRandomHash from '../../utils/GenerateRandomHash';
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";

import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";

import ConfirmVerificationCode from "./ConfirmVerificationCode";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";
import LineSpacing from "../../components/misc/LineSpacing";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  display: 'flex',
  flexWrap: 'wrap',
  padding: 10px 30px 10px 30px !important;
  margin: 20px 0 40px 0 !important;
  text-align: center !important;
  align-items: center;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 4px 0 2px 0 !important;
`;

const StyledButton = styled(Button)`
  background: transparent;
  border-radius: 3px;
  border: 2px solid palevioletred;
  color: palevioletred;
  margin-bottom: 12px !important;

  /* When the "primary" property is set we want to add some more css to our component */
  /* in this case change the background and color. */

  ${(props) =>
    props.color === "primary" &&
    css`
      background: green !important;
      color: white !important;
      margin: 0 0 12px 20px !important;
    `};
`;

const StyledTypography = styled(Typography)`
  text-align: right !important;
  color: green;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundImage: `url(${"assets/logos/background.png"})`,
  },
  gridWidth: {
    width: "80%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mainForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  margin: {
    // margin: theme.spacing(1),
  },
  textField: {
    width: "100%",
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    backgroundColor: "#fff",
    textAlign: "center",
  },
  paper: {
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    marginTop: "50px",
  },
  acknoledgement: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

let signup = {
  signUpLabel: "Sign up",
  signUpToAccount: "Please sign up to create an account.",
  usernameLabel: "Username",
  passwordLabel: "Password",
  // cancel: "Cancel",
  togglePassword: "Toggle password visibility",
  enterCorrectUsername: "Please enter valid email address",
  enterCorrectPassword: "Please enter a valid password",
  usernameTaken: "Username has been taken.",
  emailConstraintsNotMet: "Email constraints are not met.",
  passwordConstraintsNotMet: "Password constraints are not met.",
  verificationCodeEmail: "We have just sent a verification code to your email.",
  // capturePhoto: "Capture photo",
  haveAcctSignIn: "Already have an account? Sign in",
  termsAcknowledgement: "By clicking 'SIGN UP' below, you agree to our",
  // termsAcknowledgementLink: "Platform Agreement, Policies & Terms of Service",

  platformAgreement: "Platform Agreement",
  security: "Security",
  privacyPolicies: "Privacy Policies",
  termsService: "Terms of Service",
};

function SignUp(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.signIn);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [signUpFlag, setSignUpFlag] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [signupRes, setSignupRes] = useState(signup);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  // For Error Flags
  const [usernameErrFlag, setUsernameErrFlag] = useState(false);
  const [usernameFocus, setUsernameFocus] = useState(true);
  const [passwordErrFlag, setPasswordErrFlag] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [progressFlag, setProgressFlag] = useState(false);

  // Variants
  const [usernameVariant, setUsernameVariant] = useState(null);
  const [passwordVariant, setPasswordVariant] = useState(null);

  const [passwordType, setPasswordType] = useState("password");
  const [passwordVisibilityIcon, setPasswordVisibilityIcon] = useState(null);
  const [signInButtonFlag, setSignInButtonFlag] = useState(false);

  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const webcamRef = useRef(null);

  const {
    signUpLabel,
    signUpToAccount,
    usernameLabel,
    passwordLabel,
    cancel,
    togglePassword,
    enterCorrectUsername,
    enterCorrectPassword,
    usernameTaken,
    emailConstraintsNotMet,
    passwordConstraintsNotMet,
    verificationCodeEmail,
    // capturePhoto,
    haveAcctSignIn,
    termsAcknowledgement,
    // termsAcknowledgementLink,

    platformAgreement,
    security,
    privacyPolicies,
    termsService,
  } = signupRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(signup);
    // console.log("In SignIn(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In SignIn(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
        // "queryType": "hash-query",
        // "resourcePK": currentLanguage.languageCode,
        // "keyConditionExpression": "#resourcePK = :resourcePK",
        // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
        // "expressionAttributeValues": {":resourcePK": currentLanguage.languageCode}
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            // id: ""+index,
            // ResourceLanguageCode: item.ResourceLanguageCode,
            // ResourcePK: item.ResourcePK,
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
            // ModifiedBy: item.ModifiedBy,
            // CreatedAt: item.CreatedAt,
            // UpdatedAt: item.UpdatedAt,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "signUpLabel")
              signup.signUpLabel = item.ResourceDesc;
            if (item.ResourceCode === "signUpToAccount")
              signup.signUpToAccount = item.ResourceDesc;
            if (item.ResourceCode === "usernameLabel")
              signup.usernameLabel = item.ResourceDesc;
            if (item.ResourceCode === "passwordLabel")
              signup.passwordLabel = item.ResourceDesc;
            if (item.ResourceCode === "togglePassword")
              signup.togglePassword = item.ResourceDesc;
            if (item.ResourceCode === "enterCorrectUsername")
              signup.enterCorrectUsername = item.ResourceDesc;
            if (item.ResourceCode === "enterCorrectPassword")
              signup.enterCorrectPassword = item.ResourceDesc;
            if (item.ResourceCode === "cancel")
              signup.cancel = item.ResourceDesc;
            if (item.ResourceCode === "emailConstraintsNotMet")
              signup.emailConstraintsNotMet = item.ResourceDesc;
            if (item.ResourceCode === "passwordConstraintsNotMet")
              signup.passwordConstraintsNotMet = item.ResourceDesc;
            if (item.ResourceCode === "haveAcctSignIn")
              signup.haveAcctSignIn = item.ResourceDesc;
            if (item.ResourceCode === "verificationCodeEmail")
              signup.verificationCodeEmail = item.ResourceDesc;
            if (item.ResourceCode === "termsAcknowledgement")
              signup.termsAcknowledgement = item.ResourceDesc;

            if (item.ResourceCode === "platformAgreement")
              signup.platformAgreement = item.ResourceDesc;
            if (item.ResourceCode === "security")
              signup.security = item.ResourceDesc;
            if (item.ResourceCode === "privacyPolicies")
              signup.privacyPolicies = item.ResourceDesc;
            if (item.ResourceCode === "termsService")
              signup.termsService = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, signup);
          setSignupRes(signup);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setSignupRes(signup);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    setUsername("");
    setPassword("");

    setUsernameFocus(false);
    setPasswordFocus(false);
  }, []);

  useLayoutEffect(() => {
    // console.log("In SignUp(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "signup";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In SignUp(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In SignUp(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In SignUp(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In SignUp(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In SignUp(): useLayoutEffect(): got resource from cache');
      setSignupRes(cacheItem);
    }

    // console.log('In SignUp(): useLayoutEffect(): currentLanguage is: ', JSON.stringify(currentLanguage, null, 2));
    // console.log('In SignUp(): useLayoutEffect(): direction is: ', direction);
    // console.log('In SignUp(): useLayoutEffect(): appContext.signedIn is: ', appContext.signedIn);
  }, []);

  function handleChange(event) {
    // console.log("In handleChange(): id is: "+event.target.id);
    //console.log("In handleChange(): tagName is: "+event.target.tagName);
    // console.log("In handleChange(): value is: "+event.target.value);

    setGlobalErrorFlag(false);
    setSignInButtonFlag(false);

    // Variants
    setUsernameVariant("danger");
    setPasswordVariant("danger");

    switch (event.target.id) {
      case "usernameId":
        setUsernameErrFlag(false);
        setUsername(event.target.value);

        // Enable button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          password !== undefined &&
          password !== null &&
          password !== ""
        ) {
          setSignInButtonFlag(true);
        }
        break;
      case "passwordId":
        setPasswordErrFlag(false);
        setPassword(event.target.value);

        // Enable button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          username !== undefined &&
          username !== null &&
          username !== ""
        ) {
          setSignInButtonFlag(true);
        }
        break;
      default:
        break;
    }
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function validateForm() {
    let formErrFlag = false;
    let emailConstErrFlag = false;
    let constraintErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setUsernameErrFlag(false);
    setPasswordErrFlag(false);

    if (username === null || username === "") {
      formErrFlag = true;
      setUsernameErrFlag(true);
    } else if (!username.match(GlobalConstants.emailRegex)) {
      emailConstErrFlag = true;
      formErrFlag = true;
      setUsernameErrFlag(true);
    }

    if (password === null || password === "") {
      formErrFlag = true;
      setPasswordErrFlag(true);
    } else if (!password.match(GlobalConstants.passwordRegex)) {
      constraintErrFlag = true;
      formErrFlag = true;
      setPasswordErrFlag(true);
    }

    // Check for Constraints Error
    if (constraintErrFlag) {
      setGlobalErrorFlag(true);
      setGlobalErrorMsg(passwordConstraintsNotMet);
    }

    if (emailConstErrFlag) {
      setGlobalErrorFlag(true);
      setGlobalErrorMsg(emailConstraintsNotMet);
    }

    return formErrFlag;
  }

  async function signUpService() {
    // console.log("In signUpService(): username is: " + username);
    // console.log("In signUpService(): password is: " + password);

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    // Test some utils functions
    // console.log("Generated Random Hash is: "+GenerateRandomHash());
    // console.log("Generated ClientId is: "+GenerateClientId());
    // console.log("Generated TransactionId is: "+GenerateTransactionId());
    // console.log("Generated Password is: "+GeneratePassword('jekwempu@tobisoft.co'));

    // await SetCacheItem("isSignedIn", true);
    // await console.log("In signUpService(): isSignedIn is: "+GetCacheItem("isSignedIn"));

    if (!validateForm()) {
      // DO NOT ALLOW SIGNUP FOR NOW
      // return;
      
      // Validation Passed - you can proceed
      // console.log("Validation Passed.");
      setProgressFlag(true);

      let attributes = {
        email: username,

        // Custom attributes in cognito
        "custom:domainName": appContext.domainName, // e.g. cloudcms.ws
        "custom:shortDomain": appContext.domain, // e.g. ccms
        "custom:subDomain": appContext.subDomain, // e.g. blog
        "custom:dataLanguageCode": appContext.currentLanguage.languageCode,
      };
      console.log(
        "In signUpService(): attributes is: " +
          JSON.stringify(attributes, null, 2)
      );

      // let validationData = [];

      // The Auth.signUp promise returns a data object of type ISignUpResult with a CognitoUser.
      // {
      //   user: CognitoUser;
      //   userConfirmed: boolean;
      //   userSub: string;
      // }
      Auth.signUp({ username, password, attributes })
        .then((result) => {
          // console.log('In signUpService(): result is', JSON.stringify(result, null, 2));
          //setSignUpFlag(true);
          setSnackbarOpen(true);
          setProgressFlag(false);
        })
        .catch((err) => {
          console.log(
            "In signUpService(): err is",
            JSON.stringify(err, null, 2)
          );
          setProgressFlag(false);
          if (err.code === "UsernameExistsException") {
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(usernameTaken);
            // setEmailConstraintsNotMet(usernameTaken);
          }
        });
    }
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    if (reason === "clickaway") {
      setSignUpFlag(true);
      return;
    }
    setSnackbarOpen(false);
    setSignUpFlag(true);
  }

  function AcknowledgementLink() {
    return (
      <span>
        {termsAcknowledgement}
        <br />
        <Link component={RouterLink} to="/platformAgreement">
          {platformAgreement}
        </Link>
        {", "}
        <Link component={RouterLink} to="/security">
          {security}
        </Link>
        {", "}
        <Link component={RouterLink} to="/privacy">
          {privacyPolicies}
        </Link>
        {" & "}
        <Link component={RouterLink} to="/terms">
          {termsService}
        </Link>
      </span>
    );
  }

  return (
    <div dir={direction}>
      <div className={classes.root}>
        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%", height: "100%" }}>
            {progressFlag && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                  size={15}
                />
              </div>
            )}

            {signUpFlag && <ConfirmVerificationCode username={username} />}

            {!signUpFlag && (
              <Container
                component="div"
                className={classes.mainForm}
                maxWidth="md"
              >
                <Grid container justify="center">
                  <Grid item xs={12} sm={9}>
                    <StyledPaper elevation={3} className={classes.paper}>
                      <Grid container justify="center">
                        <Avatar
                          className={classes.avatar}
                          style={{ alignSelf: "center" }}
                        >
                          <LockOutlinedIcon />
                        </Avatar>
                      </Grid>

                      <Typography
                        variant="h5"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        {signUpLabel}
                      </Typography>

                      <Typography
                        variant="caption"
                        component="h3"
                        style={{ color: "green", textAlign: "center" }}
                      >
                        {signUpToAccount}
                      </Typography>

                      {globalErrorFlag && (
                        <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                      )}

                      <LineSpacing value={24} />

                      <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                        error={usernameErrFlag}
                      >
                        <label htmlFor="usernameId" style={directionAlignment}>
                          <Typography
                            variant="subtitle1"
                            color={usernameErrFlag ? "error" : "inherit"}
                          >
                            {usernameLabel}
                          </Typography>
                        </label>
                        <OutlinedInput
                          id="usernameId"
                          value={username}
                          placeholder={enterCorrectUsername}
                          onChange={handleChange}
                          required
                          fullWidth
                          autoComplete="username"
                          autoFocus
                          type={"email"}
                          error={usernameErrFlag}
                        />
                        {usernameErrFlag && (
                          <FormHelperText id="username-helper-text">
                            {emailConstraintsNotMet}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <LineSpacing value={24} />

                      <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                        error={passwordErrFlag}
                      >
                        <label htmlFor="passwordId" style={directionAlignment}>
                          <Typography
                            variant="subtitle1"
                            color={passwordErrFlag ? "error" : "inherit"}
                          >
                            {passwordLabel}
                          </Typography>
                        </label>
                        <OutlinedInput
                          id="passwordId"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          placeholder={enterCorrectPassword}
                          onChange={handleChange}
                          error={passwordErrFlag}
                          required
                          fullWidth
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          aria-describedby="password-helper-text"
                        />
                        {passwordErrFlag && (
                          <FormHelperText id="password-helper-text">
                            {passwordConstraintsNotMet}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <LineSpacing value={12} />

                      <Container
                        component="div"
                        className={classes.acknoledgement}
                        maxWidth="xs"
                      >
                        <Typography
                          variant="caption"
                          color="primary"
                          style={{ fontSize: "10px" }}
                        >
                          <AcknowledgementLink />
                        </Typography>
                      </Container>

                      <Typography
                        component="p"
                        style={{ textAlign: "center", marginTop: "12px" }}
                      >
                        {!signInButtonFlag && (
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled
                            className={classes.button}
                          >
                            <LockOutlinedIcon
                              className={clsx(
                                classes.leftIcon,
                                classes.iconSmall
                              )}
                            />
                            {signUpLabel}
                          </Button>
                        )}

                        {signInButtonFlag && (
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            className={classes.button}
                            onClick={signUpService}
                          >
                            <LockOutlinedIcon
                              className={clsx(
                                classes.leftIcon,
                                classes.iconSmall
                              )}
                            />
                            {signUpLabel}
                          </Button>
                        )}
                      </Typography>

                      <LineSpacing value={12} />

                      {/* <Typography style={{textAlign: 'center', marginTop: '12px'}}>
                    <StyledButton color="primary" onClick={ signUpService }>{signUpLabel}</StyledButton>
                  </Typography> */}

                      <Grid container justify="flex-end">
                        <Grid item>
                          <Link component={RouterLink} to="/signIn">
                            <StyledTypography
                              variant="caption"
                              color="primary"
                              style={{ float: "right" }}
                            >
                              {haveAcctSignIn}
                            </StyledTypography>
                          </Link>
                        </Grid>
                      </Grid>
                    </StyledPaper>
                  </Grid>
                </Grid>
              </Container>
            )}

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={snackbarOpen}
              autoHideDuration={8000}
              onClose={snackbarOnClose}
              ContentProps={{ "aria-describedby": "message-id" }}
              message={<span id="message-id">{verificationCodeEmail}</span>}
              variant="success"
            />
          </div>
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter
              currentLanguage={currentLanguage}
              isSignedIn={isSignedIn}
            />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default SignUp;
