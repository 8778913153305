import React from "react";

let reducerContext = {
  dispatch: null,
  store: null,
};

const ReducerContext = React.createContext(reducerContext);

export default ReducerContext;
