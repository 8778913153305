import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Use this to load additional fonts
// import WebFont from 'webfontloader';

/* Available Google fonts from: https://fonts.google.com/ */

// WebFont.load({
//   google: {
//     families: ['Titillium Web:300,400,700', 'sans-serif', 'Dancing Script', 'Great Vibes']
//   }
// });

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
