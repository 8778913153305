import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Editor
import FroalaEditor from "react-froala-wysiwyg";

// Import all Froala Editor plugins;
// import 'froala-editor/js/plugins.pkgd.min.js';

// Import a single Froala Editor plugin.
// import 'froala-editor/js/plugins/align.min.js';

// Import a language file.
// import 'froala-editor/js/languages/de.js';
// import 'froala-editor/js/languages/zh_cn.js';
// import 'froala-editor/js/languages/ro.js';
// import 'froala-editor/js/languages/ar.js';

// Include font-awesome css if required.
// import 'font-awesome/css/font-awesome.css';
// install using "npm install font-awesome --save"

// Include special components if required.
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

// AppContext
import AppContext from "../../config/AppContext";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";
import LineSpacing from "../../components/misc/LineSpacing";

const StyledPaper = styled(Paper)`
  min-width: 300px;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "#fff",
  },
  main: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  progress: {
    margin: theme.spacing(2),
  },
  mainForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

let pageTemplate = {
  pageTemplateLabel: "EditorTest",
};

function EditorTest(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  const [progressFlag, setProgressFlag] = useState(false);

  // Editor
  const [editorData, setEditorData] = useState("This a test of Froala Editor");
  const [editorReady, setEditorReady] = useState(false);

  const classes = useStyles();

  const { currLanguage } = props;

  const { pageTemplateLabel } = pageTemplate;

  async function importEditorLanguageFile(selectedLang) {
    switch (selectedLang) {
      case "ar":
        await import("froala-editor/js/languages/ar.js");
        await setEditorReady(true);
        break;
      case "es":
        await import("froala-editor/js/languages/es.js");
        await setEditorReady(true);
        break;
      case "zh":
        await import("froala-editor/js/languages/zh_cn.js");
        await setEditorReady(true);
        break;
      case "zh-TW":
        await import("froala-editor/js/languages/zh_tw.js");
        await setEditorReady(true);
        break;
      case "de":
        await import("froala-editor/js/languages/de.js");
        await setEditorReady(true);
        break;
      case "ru":
        await import("froala-editor/js/languages/ru.js");
        await setEditorReady(true);
        break;
      case "ko":
        await import("froala-editor/js/languages/ko.js");
        await setEditorReady(true);
        break;
      case "br":
        await import("froala-editor/js/languages/pt_br.js");
        await setEditorReady(true);
        break;
      case "pt":
        await import("froala-editor/js/languages/pt_pt.js");
        await setEditorReady(true);
        break;
      case "pl":
        await import("froala-editor/js/languages/pl.js");
        await setEditorReady(true);
        break;
      case "it":
        await import("froala-editor/js/languages/it.js");
        await setEditorReady(true);
        break;
      case "ja":
        await import("froala-editor/js/languages/ja.js");
        await setEditorReady(true);
        break;
      case "fr":
        await import("froala-editor/js/languages/fr.js");
        await setEditorReady(true);
        break;
      default:
        await setEditorReady(true);
        break;
    }
  }

  useEffect(() => {
    // console.log('In EditorTest(): useEffect()');
  }, []);

  useLayoutEffect(() => {
    console.log(
      "In EditorTest(): useLayoutEffect(): currentLanguage is: ",
      currentLanguage
    );
    // console.log('In EditorTest(): useLayoutEffect(): currLanguage is: ', currLanguage);
    // console.log('In EditorTest(): useLayoutEffect(): isSignedIn is: ', isSignedIn);

    importEditorLanguageFile(currentLanguage.languageCode);
  }, []);

  // let lang = 'zh_cn';
  // if (lang === 'zh_cn') {
  //     import('froala-editor/js/languages/zh_cn.js')
  //     .then((something) => {
  //         console.log("DONE");
  //         setEditorReady(true);
  //     });
  // }

  let editorConfig = {
    placeholderText: "Edit Your Content Here!",
    charCounterCount: true,
    language: currentLanguage.languageCode,
  };

  function handleEditorModelChange(editorData) {
    console.log("In handleEditorModelChange(): editorData is: ", editorData);
    setEditorData(editorData);
  }

  return (
    <div dir={direction}>
      <div className={classes.root}>
        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />
        {/* { !appContext.signedIn &&
                    <Redirect to='/signIn' />
                } */}

        <Container component="div" className={classes.main} maxWidth="lg">
          <div
            className="ag-theme-balham"
            style={{ width: "100%", height: "100%" }}
          >
            {progressFlag && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                  size={15}
                />
              </div>
            )}
            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="md"
            >
              <Grid container justify="center">
                <Typography
                  variant="h6"
                  style={{ color: "black", textAlign: "center" }}
                >
                  {pageTemplateLabel}
                </Typography>
              </Grid>
              {editorReady && (
                <FroalaEditor
                  tag="textarea"
                  config={editorConfig}
                  model={editorData}
                  onModelChange={handleEditorModelChange}
                />
              )}
            </Container>

            <LineSpacing value={12} />
          </div>
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default EditorTest;
