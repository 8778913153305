import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
// import { Redirect } from "react-router-dom";
import styled from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
// import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import clsx from "clsx";
// import Avatar from '@material-ui/core/Avatar';
import Button from "@material-ui/core/Button";
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import Checkbox from '@material-ui/core/Checkbox';
import Backdrop from '@material-ui/core/Backdrop';
import TranslateIcon from '@material-ui/icons/Translate';

// react-responsive
import { useMediaQuery } from 'react-responsive';

// Lodash
import lodash from "lodash";

// moment
import moment from "moment";

// Amplify
import { API, Storage } from "aws-amplify";
// import Amplify, { API } from 'aws-amplify';
// import AmplifyConfig from '../../config/AmplifyConfig';

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GenerateUniqueName from "../../utils/GenerateUniqueName";

// import FormatTimezoneDates from "../../utils/moment/FormatTimezoneDates";
// import FormatNumber from "../../utils/numberFormat/FormatNumber";
// import FormatNumberWithOption from "../../utils/numberFormat/FormatNumberWithOption";
// import FormatCurrency from "../../utils/numberFormat/FormatCurrency";

import LineSpacing from "../../components/misc/LineSpacing";
// import UploadFilesToS3 from "../../components/upload/UploadFilesToS3";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";

// AppContext
import AppContext from "../../config/AppContext";

// const StyledPaper = styled(Paper)`
//   min-width: 300px;
//   padding: 10px 20px 10px 20px !important;
//   text-align: center !important;
//   align-items: center;
// `;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100% !important",
        // display: "flex",
        // flexDirection: "column",
        // position: "relative",
        // background: 'red',
        // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
        textAlign: "center",
    },
    main: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
        background: '#fff',
    },
    progress: {
        margin: theme.spacing(2),
    },
    mainForm: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
        // background: '#fff',
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    footer: {
        bottom: 0,
        height: "1rem" /* Footer height */,
        width: "100% !important",
        textAlign: "center",
    },
    tabsRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        // width: '100%',
        // height: 224,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        // width: '100%',
    },
    tabPanel: {
        width: '100%',
    },
    large: {
        width: theme.spacing(14),
        height: theme.spacing(14),
    },
    hdr: {
        color: "#000", 
        textAlign: "center",
        marginTop: theme.spacing(2),
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    formControlLabel: {
        display: "flex",
        textAlign: "left",
        verticalAlign: "middle",
    },
    greenColor: {
        color: green[500],
    },
    divWrapper: {
        width: '100% !important',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    textField: {
        width: "100%",
    },
    leftPanelSubItems: {
        width: "100%",
        // marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    leftPanelItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
}));

let pageLabels = {
    webPageConverterLabel: "Web page converter",
    requiredFields: "* Required fields",

    sourceLanguageLabel: "Source language",
    sourceLanguagePrompt: "Select source language",
    sourceLanguageErrMsg: "Source language is required",

    targetLanguageLabel: "Target language",
    selectTargetLangPrompt: "Select target language",
    targetLanguageErrMsg: "Target language is required",

    domainLabel: "Domain",
    domainPrompt: "Select a domain",
    domainErrMsg: "Domain is required",

    webPageLabel: "Web page",
    webPagePrompt: "Select the web page to convert",
    webPageErrMsg: "You must select a web page",

    webPageSectionLabel: "Web page section",
    webPageSectionPrompt: "Select the web page section to convert",
    webPageSectionErrMsg: "You must select a web page section",

    converterLabel: "Convert",

    operationSuccessMsg: "Operation was successful.",
    operationFailureMsg: "Operation failed.",
    duplicateTransMsg: "Duplicate record",
    
    // groupingCategoryLabel, groupingCategoryPrompt and groupingCategoryErrMsg are place holders
    // Their values change depending on what label is passed to this component
    // groupingCategoryLabel: "Select grouping category",
    // groupingCategoryPrompt: "Select a grouping category",
    // groupingCategoryErrMsg: "Group category is required",

    // docsAllowedTypeMsg: "Only TXT, PDF, DOC, DOCX, PPT, PPTX, XLS, XLSX, or GZIP files are allowed.",
    // imageAllowedTypeMsg: "Only PNG and JPG (JPEG) photo or image files are allowed.",
    // audioAllowedMsg: "Only MP3, OGG and WAV files are allowed. Maximum file size allowed is 16MB.",
    // videoAllowedMsg: "Only MP4, WebM files are allowed. Maximum file size allowed is 16MB.",

    loadingText: "Loading ...",
    noOptionsText: "No options",
    clearText: "Clear",
    closeText: "Close",
};

function WebPageConverter(props) {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);
    // const [ isSignedIn, setIsSignedIn ] = useState(appContext.signedIn);

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
    
    const [progressFlag, setProgressFlag] = useState(false);

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);
    const [renderDomFlag, setRenderDomFlag] = useState(false);

    // For Global Errors
    const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");

    // For Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarsMsg, setSnackbarsMsg] = useState("");
    const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'
    
    //---------------------
    // For Form Attributes
    //---------------------
    const [domain, setDomain] = useState(null);
    const [domainOptions, setDomainOptions] = useState([]);
    const [domainErrFlag, setDomainErrFlag] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    
    const [pageRecordBackup, setPageRecordBackup] = useState([]);

    const { 
        showHeadersFlag,
        referrer,
    } = props;

    //------------------
    // For AWS Translate
    //------------------
    const [terminologyNames, setTerminologyNames] = useState([
        "translate_custom_terminology.txt"
    ]);

    //---------------------
    // For Form Attributes
    //---------------------
    const [sourceLanguage, setSourceLanguage] = useState(null);
    const [sourceLanguageErrFlag, setSourceLanguageErrFlag] = useState(false);
    const [sourceLanguageOptions, setSourceLanguageOptions] = useState([]);

    const [targetLanguage, setTargetLanguage] = useState(null);
    const [targetLanguageErrFlag, setTargetLanguageErrFlag] = useState(false);
    const [targetLanguageOptions, setTargetLanguageOptions] = useState([]);
    // const [targetLanguageCategory, setTargetLanguageCategory] = useState(null);
    
    // const [groupingCategory, setGroupingCategory] = useState(null);
    // const [groupingCategoryErrFlag, setGroupingCategoryErrFlag] = useState(false);
    // const [groupingCategoryOptions, setGroupingCategoryOptions] = useState([]);
    // const [groupingCategoryRecords, setGroupingCategoryRecords] = useState([]);
    
    // const [readOnlyFlag, setReadOnlyFlag] = useState(false);
    const [submitButtonFlag, setSubmitButtonFlag] = useState(false);
    const [noRecordFoundFlag, setNoRecordFoundFlag] = useState(false);

    const [pageTranslateItem, setPageTranslateItem] = useState(null);
    const [pageRecordOptions, setPageRecordOptions] = useState([]);
    const [pageTranslateItemErrFlag, setPageTranslateItemErrFlag] = useState(false);
           
    const [pageSize, setPageSize] = useState(10000);// AWS put PageSize to a max 0f 100
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    const classes = useStyles();

    const directionAlignment = {
        textAlign: direction === "ltr" ? "left" : "right",
    };

    const marginAlignment = {
        flex: "1",
        marginRight: appContext.direction === "ltr" ? "12px" : "0px",
        marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    };

    const { 
        webPageConverterLabel,
        requiredFields,

        sourceLanguageLabel,
        sourceLanguagePrompt,
        sourceLanguageErrMsg,

        targetLanguageLabel,
        selectTargetLangPrompt,
        targetLanguageErrMsg,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,

        webPageLabel,
        webPagePrompt,
        webPageErrMsg,

        webPageSectionLabel,
        webPageSectionPrompt,
        webPageSectionErrMsg,
    
        converterLabel,

        // groupingCategoryLabel,
        // groupingCategoryPrompt,
        // groupingCategoryErrMsg,

        operationSuccessMsg,
        operationFailureMsg,
        duplicateTransMsg,

        // docsAllowedTypeMsg,
        // imageAllowedTypeMsg,
        // audioAllowedMsg,
        // videoAllowedMsg,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,
    } = pageLabelsRes;

    // const [categoryCommonLabel, setCategoryCommonLabel] = useState(groupingCategoryLabel);
    // const [categoryCommonPrompt, setCategoryCommonPrompt] = useState(groupingCategoryPrompt);
    // const [categoryCommonErrMsg, setCategoryCommonErrMsg] = useState(groupingCategoryErrMsg);

    async function fetchDbResources(cacheItemKey) {
        let keys = [];
        let objKeys = Object.keys(pageLabels);
        // console.log("In WebPageConverter(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
        if (objKeys !== null && objKeys.length > 0) {
            objKeys.map((key) => {
                let partitionKey = appContext.currentLanguage.languageCode;
                partitionKey += ".";
                partitionKey += GenerateModulusHash(key, 200);

                let itemKey = {
                    ResourcePK: partitionKey, // e.g. 'en.91'
                    ResourceSK: key, // e.g. 'firstName'
                };
                keys.push(itemKey);
            });
        }
        // console.log("In WebPageConverter(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

        let arrayData = [];

        setProgressFlag(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/batchGet",
            payload: {
                keys: keys,
                projectionExpression: "ResourceCode,ResourceDesc",
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
            response.data.Responses.resource.map((item, index) => {
                let record = {
                    ResourceCode: item.ResourceCode,
                    ResourceDesc: item.ResourceDesc,
                };

                arrayData.push(record);
            });

            if (arrayData && arrayData.length > 0) {
                arrayData.map((item) => {
                    if (item.ResourceCode === "webPageConverterLabel") pageLabels.webPageConverterLabel = item.ResourceDesc;
                    if (item.ResourceCode === "requiredFields") pageLabels.requiredFields = item.ResourceDesc;
                    
                    if (item.ResourceCode === "sourceLanguageLabel") pageLabels.sourceLanguageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "sourceLanguagePrompt") pageLabels.sourceLanguagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "sourceLanguageErrMsg") pageLabels.sourceLanguageErrMsg = item.ResourceDesc;
                            
                    if (item.ResourceCode === "targetLanguageLabel") pageLabels.targetLanguageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "selectTargetLangPrompt") pageLabels.selectTargetLangPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "targetLanguageErrMsg") pageLabels.targetLanguageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "domainLabel") pageLabels.domainLabel = item.ResourceDesc;
                    if (item.ResourceCode === "domainPrompt") pageLabels.domainPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "domainErrMsg") pageLabels.domainErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "webPageLabel") pageLabels.webPageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "webPagePrompt") pageLabels.webPagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "webPageErrMsg") pageLabels.webPageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "webPageSectionLabel") pageLabels.webPageSectionLabel = item.ResourceDesc;
                    if (item.ResourceCode === "webPageSectionPrompt") pageLabels.webPageSectionPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "webPageSectionErrMsg") pageLabels.webPageSectionErrMsg = item.ResourceDesc;
                                
                    if (item.ResourceCode === "converterLabel") pageLabels.converterLabel = item.ResourceDesc;
                               
                    // if (item.ResourceCode === "groupingCategoryLabel") pageLabels.groupingCategoryLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "groupingCategoryPrompt") pageLabels.groupingCategoryPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "groupingCategoryErrMsg") pageLabels.groupingCategoryErrMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "operationSuccessMsg") pageLabels.operationSuccessMsg = item.ResourceDesc;
                    if (item.ResourceCode === "operationFailureMsg") pageLabels.operationFailureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "duplicateTransMsg") pageLabels.duplicateTransMsg = item.ResourceDesc;
                                                
                    // if (item.ResourceCode === "docsAllowedTypeMsg") pageLabels.docsAllowedTypeMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "imageAllowedTypeMsg") pageLabels.imageAllowedTypeMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "audioAllowedMsg") pageLabels.audioAllowedMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "videoAllowedMsg") pageLabels.videoAllowedMsg = item.ResourceDesc;
                            
                    if (item.ResourceCode === "loadingText") pageLabels.loadingText = item.ResourceDesc;
                    if (item.ResourceCode === "noOptionsText") pageLabels.noOptionsText = item.ResourceDesc;
                    if (item.ResourceCode === "clearText") pageLabels.clearText = item.ResourceDesc;
                    if (item.ResourceCode === "closeText") pageLabels.closeText = item.ResourceDesc;
                });
                // Add it to cache
                SetCacheItem(cacheItemKey, pageLabels);
            }

            // setRenderGridFlag(true);
            setProgressFlag(false);
            setPageLabelsRes(pageLabels);
            setRenderDomFlag(true);
        }).catch((err) => {
            console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
            setPageLabelsRes(pageLabels);
            setProgressFlag(false);
            setRenderDomFlag(true);
        });
    }

    let  refDataSelectOptions = [];
    
    async function fetchReferenceData(refType, lang) {
        return new Promise((resolve, reject) => {
            // console.log('In ManagePageHeaders  fetchReferenceData(): refType is: ', refType);
            // console.log('In ManagePageHeaders  fetchReferenceData(): lang is: ', lang);
            backdropProgress(true);

            refDataSelectOptions = [];

            let apiName = null;
            let path = null;
            let keyConditionExpression = null;
            let expressionAttributeNames = {};
            let expressionAttributeValues = {};
            
            switch(refType) {
                case "pricing-categories":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "pricing-categories",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                case "supported-domains":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "supported-domains",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                default:
                    break;
            }

            setProgressFlag(true);
        
            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    keyConditionExpression: keyConditionExpression,
                    expressionAttributeNames: expressionAttributeNames,
                    expressionAttributeValues: expressionAttributeValues,
                    // "keyConditionExpression": "#resourcePK = :resourcePK",
                    // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                    // "expressionAttributeValues": {":resourcePK": "languages"}
                },
            };
        
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
        
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In  fetchReferenceData(): initObj is: ', JSON.stringify(initObj, null, 2));
        
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In  fetchReferenceData(): response.length is: ', response.data.Items.length);
                // console.log('In  fetchReferenceData(): response is: ', JSON.stringify(response.data.Items, null, 2));
                response.data.Items.map((item) => {
                    let record = {
                        value: item.ResourceCode,
                        text: item.ResourceDesc,
                    };

                    if (record.value !== "all") {// Bypass all
                        refDataSelectOptions.push(record);
                    }
                });

                switch(refType) {
                    case "pricing-categories":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setCategoryOptions( refDataSelectOptions);
                        break;
                    case "supported-domains":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setDomainOptions(refDataSelectOptions);
                        break;
                    default:
                        break;
                }

                backdropProgress(false);
                resolve(refDataSelectOptions);
            }).catch((err) => {
                console.error("In ManagePageHeaders  fetchReferenceData(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject(err);
            });
        });
    }
    
    //---------------------------------------------------
    // Fetch ManagePageSections or ManageWebPages record
    //---------------------------------------------------

    let listNextToken = null;

    async function fetchWebPageRecords(selectedDomain) {
        // console.log('In fetchWebPageRecords(): referrer is: ', referrer);
        // console.log('In fetchWebPageRecords(): selectedDomain is: ', selectedDomain);
        // console.log('In fetchWebPageRecords(): sourceLanguage is: ', sourceLanguage);

        backdropProgress(true);
        setNoRecordFoundFlag(false);

        setPageRecordOptions([]);
        setPageRecordBackup([]);

        let pagePK = selectedDomain.value + "#" + sourceLanguage.languageCode + "#page";
        let pageSK = selectedDomain.value + "#" + sourceLanguage.languageCode + "#page#";

        if (referrer === "ManagePageSections") {
            pagePK = selectedDomain.value + "#" + sourceLanguage.languageCode + "#page#section";
            pageSK = selectedDomain.value + "#" + sourceLanguage.languageCode + "#page#section#";
        }

        let params = {
            apiName: "PagesAPI",
            path: "/dynamodb/pages/query",
            payload: {
                keyConditionExpression:
                    // "#pagePK = :pagePK",
                    "#pagePK = :pagePK AND begins_with(#pageSK, :pageSK)",
                expressionAttributeNames: {
                    "#pagePK": "PagePK",
                    "#pageSK": "PageSK",
                },
                expressionAttributeValues: {
                    ":pagePK":  pagePK,
                    ":pageSK": pageSK,
                },
                // indexName: "PageDomain-LangAuthorUserName-index"
                    // "keyConditionExpression": "#pagePK = :pagePK",
                    // "expressionAttributeNames": {"#pagePK": "PagePK"},
                    // "expressionAttributeValues": {":pagePK": "languages"}
            },
        };

        if (!lodash.isEmpty(listNextToken)) {
            params.payload.nextToken = listNextToken;
        }
  
        if (!lodash.isEmpty(pageSize)) {
            params.payload.pageSize = pageSize;
        }

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
  
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchWebPageRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
  
        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In fetchWebPageRecords(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchWebPageRecords(): response.data.NextToken is: ', response.data.NextToken);
            // console.log('In fetchWebPageRecords(): response.data.Items is: ', response.data.Items);

            let records = [];
            let backupRecords = [];

            listNextToken = null;

            if (response && !lodash.isEmpty(response.data.NextToken)) {
                listNextToken = response.data.NextToken;
                records = pageRecordOptions;
            }
        
            let items = response.data.Items;

            if (items && items.length > 0) {
                setPageRecordOptions([]);

                items.map((item) => {
                    let record = {};

                    switch(referrer) {
                        case "ManagePageSections":
                            record.value = item.SectionId;
                            record.text = item.WebPage.text + " (" + item.SectionTitle + ")";

                            records.push(record);
                            backupRecords.push(item);
                            break;
                        case "ManageWebPages":
                            record.value = item.PageId;
                            record.text = item.PageName + " (" + item.PageCategory.text + ")";

                            records.push(record);
                            backupRecords.push(item);
                            break;
                        default:
                            break;
                    }
                });
            } else {
                setNoRecordFoundFlag(true);
            }

            // console.log('In fetchWebPageRecords(): listNextToken is: ', listNextToken);
            if (!lodash.isEmpty(listNextToken)) {
                await fetchWebPageRecords();
            }
            
            setTimeout(() => {
                records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                setPageRecordOptions(records);
                setPageRecordBackup(backupRecords);
            }, 100);

            backdropProgress(false);
        }).catch((err) => {
          console.error("In fetchWebPageRecords(): err is: ", JSON.stringify(err, null, 2));
          setPageRecordOptions([]);
          setPageRecordBackup([]);
          setNoRecordFoundFlag(true);
          backdropProgress(false);
        });
    }

    async function fetchLanguages() {
        // console.log("In fetchLanguages()");
        // setFilterDisabledFlag(true);

        //-------------------------------------------------------
        // Make sure languages is not in cache before proceeding
        //-------------------------------------------------------

        let cachedLanguages = GetCacheItem("languages");
        // console.log('In fetchLanguages(): cachedLanguages is: ', JSON.stringify(cachedLanguages, null, 2));
        cachedLanguages = null;
        if (cachedLanguages && cachedLanguages !== null && cachedLanguages.length > 0) {
            // console.log('In fetchLanguages(): cachedLanguages length is: ', cachedLanguages.length);
            setSourceLanguageOptions(cachedLanguages);
            return true;
        }

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/query",
            payload: {
                keyConditionExpression: "#resourcePK = :resourcePK",
                expressionAttributeNames: { "#resourcePK": "ResourcePK" },
                expressionAttributeValues: { ":resourcePK": "languages" },
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log("In fetchLanguages(): response length is: ", response.data.Items.length);
            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));

            // response.data.Items.sort((a, b) =>
            //     a.LanguageName > b.LanguageName ? 1 : -1
            // );

            let records = [];
            let items = response.data.Items;

            items.map(item => {
                let record ={
                    languageCode: item.ResourceCode,
                    languageName: item.LanguageName
                };
                records.push(record);
            });

            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
            setSourceLanguageOptions(records);

            // Add it to cache
            SetCacheItem("languages", response.data.Items);
            backdropProgress(false);
        }).catch((err) => {
            console.error("In fetchLanguages(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    };

    useEffect(() => {
        // console.log('In WebPageConverter(): useEffect()');
    }, []);

    useLayoutEffect(() => {
        // console.log("In WebPageConverter(): useLayoutEffect()");
        async function fetchItems() {
            await fetchLanguages();

            // Fetch resource from catche
            let cacheItemKey = "pricing-converter";
            cacheItemKey += ":";
            cacheItemKey += appContext.currentLanguage.languageCode;
            // console.log('In WebPageConverter(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

            if (
                appContext.clearCacheLanguageCodes &&
                appContext.clearCacheLanguageCodes.length > 0 &&
                appContext.clearCacheLanguageCodes.includes(
                    appContext.currentLanguage.languageCode
                )
            ) {
                // console.log("In WebPageConverter(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                RemoveCacheItem(cacheItemKey);
            }

            let cacheItem = GetCacheItem(cacheItemKey);
            // console.log('In WebPageConverter(): useLayoutEffect(): cacheItem is: ', cacheItem);
            cacheItem = null; // remove after testing

            if (cacheItem === null) {
                // resource is not in cache, fetch from DB
                // console.log('In WebPageConverter(): useLayoutEffect(): resource is not in cache, fetch from DB');
                await fetchDbResources(cacheItemKey);
            } else {
                // console.log('In WebPageConverter(): useLayoutEffect(): got resource from cache');
                setPageLabelsRes(cacheItem);
                setRenderDomFlag(true);
            }
        }

        fetchItems();
    }, []);

    function removeSelectedSourceLang(selectedLang) {
        if (selectedLang && sourceLanguageOptions && sourceLanguageOptions.length > 0) {
            let filteredRecords = sourceLanguageOptions.filter(record => record.languageCode !== selectedLang.languageCode);
            setTargetLanguageOptions(filteredRecords);
        }
    }
    
    async function handleSourceLanguageChange(event, item) {
        // console.log("In handleSourceLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setSourceLanguageErrFlag(false);
        setSubmitButtonFlag(false);        
        setSourceLanguage(item);

        if (!lodash.isEmpty(item)) {
            removeSelectedSourceLang(item);
        }

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(targetLanguage) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(pageTranslateItem) 
        ) {
            setSubmitButtonFlag(true);
        }

        if (!lodash.isEmpty(item)) {
            Promise.all([
                await fetchReferenceData("supported-domains", item)
            ]).then(async (results) => {
                // console.log("In handleSourceLanguageChange(): results is: ", results);
            }).catch(error => {
                console.error("In handleSourceLanguageChange(): error is: ", error);
            });
        }
    }
    
    function handleTargetLanguageChange(event, item) {
        // console.log("In handleTargetLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setTargetLanguageErrFlag(false);
        setSubmitButtonFlag(false);        
        setTargetLanguage(item);

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(sourceLanguage) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(pageTranslateItem) 
        ) {
            setSubmitButtonFlag(true);
        }
    }

    async function handleListDomainChange(event, item) {
        // console.log("In handleListDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            await fetchWebPageRecords(item);
        }

        setSubmitButtonFlag(false);

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(sourceLanguage) &&
            !lodash.isEmpty(targetLanguage) &&
            !lodash.isEmpty(pageTranslateItem) 
        ) {
            setSubmitButtonFlag(true);
        }
    }
    
    function handlePageRecordChange(event, item) {
        // console.log("In handlePageRecordChange(): item is: ", item)
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setPageTranslateItemErrFlag(false);
        
        setPageTranslateItem(item);

        setSubmitButtonFlag(false);

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(sourceLanguage) &&
            !lodash.isEmpty(targetLanguage) &&
            !lodash.isEmpty(domain) 
        ) {
            setSubmitButtonFlag(true);
        }
    }
    
    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    function initializeForm() {
        // setTargetLanguage(null);
        setPageTranslateItem(null);
        setSubmitButtonFlag(false);
    }

    function validateForm() {
        // console.log("In validateForm()");
        let formErrFlag = false;
  
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
    
        setSourceLanguageErrFlag(false);
        setTargetLanguageErrFlag(false);
        setDomainErrFlag(false);
        setPageTranslateItemErrFlag(false);

        if (lodash.isEmpty(sourceLanguage)) {
            formErrFlag = true;
            setSourceLanguageErrFlag(true);
        }

        if (lodash.isEmpty(targetLanguage)) {
            formErrFlag = true;
            setTargetLanguageErrFlag(true);
        }
    
        if (lodash.isEmpty(domain)) {
            formErrFlag = true;
            setDomainErrFlag(true);
        }
    
        if (lodash.isEmpty(pageTranslateItem)) {
            formErrFlag = true;
            setPageTranslateItemErrFlag(true);
        }
    
        if (formErrFlag) {
            setGlobalErrorFlag(true);
        }
        // console.log("In validateForm(): formErrFlag is: ", formErrFlag);
        return formErrFlag;
    }

    //---------------------------------------------------------
    // Multiple translation functions
    // This was done to speed up translation where you have 
    //   multiple attributes to translate on the same record.
    // The translations will run in parallel.
    // Names: translate(), translate2(), translate3(), 
    //        translate4(), translate5(), and translate6().
    //---------------------------------------------------------

    function translate(textToTranslate, sourceLanguageCode, targetLanguageCode, terminologyNames) {
        return new Promise((resolve, reject) => {
            backdropProgress(true);

            if (lodash.isEmpty(textToTranslate)) {
                resolve("");
                return;
            }

            let params = {
                apiName: "AwsTranslateAPI",
                path: "/aws/translate/translateText",
                payload: {
                    "textToTranslate": textToTranslate,
                    "sourceLanguageCode": sourceLanguageCode,
                    "targetLanguageCode": targetLanguageCode,
                    "terminologyNames": terminologyNames
                }
            };
            // console.log('In translate(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In translate(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In translate(): response is: ', JSON.stringify(response, null, 2));
                // console.log("In translate(): response.data.TranslatedText is: ", response.data.TranslatedText);

                resolve(response.data.TranslatedText);
                backdropProgress(false);
                return;
            }).catch((err) => {
                console.error("In translate(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                reject("Error during translation");
            });
        });
    };

    function translate2(textToTranslate, sourceLanguageCode, targetLanguageCode, terminologyNames) {
        return new Promise((resolve, reject) => {
            backdropProgress(true);

            if (lodash.isEmpty(textToTranslate)) {
                resolve("");
                return;
            }

            let params = {
                apiName: "AwsTranslateAPI",
                path: "/aws/translate/translateText",
                payload: {
                    "textToTranslate": textToTranslate,
                    "sourceLanguageCode": sourceLanguageCode,
                    "targetLanguageCode": targetLanguageCode,
                    "terminologyNames": terminologyNames
                }
            };
            // console.log('In translate2(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In translate2(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In translate2(): response is: ', JSON.stringify(response, null, 2));
                // console.log("In translate2(): response.data.TranslatedText is: ", response.data.TranslatedText);

                resolve(response.data.TranslatedText);
                backdropProgress(false);
                return;
            }).catch((err) => {
                console.error("In translate2(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                reject("Error during translation");
            });
        });
    };

    function translate3(textToTranslate, sourceLanguageCode, targetLanguageCode, terminologyNames) {
        return new Promise((resolve, reject) => {
            backdropProgress(true);

            if (lodash.isEmpty(textToTranslate)) {
                resolve("");
                return;
            }

            let params = {
                apiName: "AwsTranslateAPI",
                path: "/aws/translate/translateText",
                payload: {
                    "textToTranslate": textToTranslate,
                    "sourceLanguageCode": sourceLanguageCode,
                    "targetLanguageCode": targetLanguageCode,
                    "terminologyNames": terminologyNames
                }
            };
            // console.log('In translate3(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In translate3(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In translate3(): response is: ', JSON.stringify(response, null, 2));
                // console.log("In translate3(): response.data.TranslatedText is: ", response.data.TranslatedText);

                resolve(response.data.TranslatedText);
                backdropProgress(false);
                return;
            }).catch((err) => {
                console.error("In translate3(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                reject("Error during translation");
            });
        });
    };

    function translate4(textToTranslate, sourceLanguageCode, targetLanguageCode, terminologyNames) {
        return new Promise((resolve, reject) => {
            backdropProgress(true);

            if (lodash.isEmpty(textToTranslate)) {
                resolve("");
                return;
            }

            let params = {
                apiName: "AwsTranslateAPI",
                path: "/aws/translate/translateText",
                payload: {
                    "textToTranslate": textToTranslate,
                    "sourceLanguageCode": sourceLanguageCode,
                    "targetLanguageCode": targetLanguageCode,
                    "terminologyNames": terminologyNames
                }
            };
            // console.log('In translate4(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In translate4(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In translate4(): response is: ', JSON.stringify(response, null, 2));
                // console.log("In translate4(): response.data.TranslatedText is: ", response.data.TranslatedText);

                resolve(response.data.TranslatedText);
                backdropProgress(false);
                return;
            }).catch((err) => {
                console.error("In translate4(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                reject("Error during translation");
            });
        });
    };

    function translate5(textToTranslate, sourceLanguageCode, targetLanguageCode, terminologyNames) {
        return new Promise((resolve, reject) => {
            backdropProgress(true);

            if (lodash.isEmpty(textToTranslate)) {
                resolve("");
                return;
            }

            let params = {
                apiName: "AwsTranslateAPI",
                path: "/aws/translate/translateText",
                payload: {
                    "textToTranslate": textToTranslate,
                    "sourceLanguageCode": sourceLanguageCode,
                    "targetLanguageCode": targetLanguageCode,
                    "terminologyNames": terminologyNames
                }
            };
            // console.log('In translate5(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In translate5(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In translate5(): response is: ', JSON.stringify(response, null, 2));
                // console.log("In translate5(): response.data.TranslatedText is: ", response.data.TranslatedText);

                resolve(response.data.TranslatedText);
                backdropProgress(false);
                return;
            }).catch((err) => {
                console.error("In translate5(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                reject("Error during translation");
            });
        });
    };

    function translate6(textToTranslate, sourceLanguageCode, targetLanguageCode, terminologyNames) {
        return new Promise((resolve, reject) => {
            backdropProgress(true);

            if (lodash.isEmpty(textToTranslate)) {
                resolve("");
                return;
            }

            let params = {
                apiName: "AwsTranslateAPI",
                path: "/aws/translate/translateText",
                payload: {
                    "textToTranslate": textToTranslate,
                    "sourceLanguageCode": sourceLanguageCode,
                    "targetLanguageCode": targetLanguageCode,
                    "terminologyNames": terminologyNames
                }
            };
            // console.log('In translate6(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In translate6(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In translate6(): response is: ', JSON.stringify(response, null, 2));
                // console.log("In translate6(): response.data.TranslatedText is: ", response.data.TranslatedText);

                resolve(response.data.TranslatedText);
                backdropProgress(false);
                return;
            }).catch((err) => {
                console.error("In translate6(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                reject("Error during translation");
            });
        });
    };
    
    async function getTargetLanguageCategory(convertRecord) {
        // console.log("In getTargetLanguageCategory(): convertRecord is: ", convertRecord);
        
        return new Promise((resolve, reject) => {
            backdropProgress(true);
    
            let params = {};

            switch(referrer) {
                case "ManageWebPages":
                    params = {
                        apiName: "ResourceAPI",
                        path: "/dynamodb/resources/get",
                        payload: {
                            key: {
                                ResourcePK: "web-pages",
                                ResourceSK: targetLanguage.languageCode + ":" + convertRecord.PageCategory.value,
                            },
                        },
                    };
                    break;
                case "ManagePageSections":// In this case you want to get the web page of the section
                    params = {
                        apiName: "PagesAPI",
                        path: "/dynamodb/pages/query",
                        payload: {
                            keyConditionExpression:
                                // "#pagePK = :pagePK",
                                "#pagePK = :pagePK AND begins_with(#pageSK, :pageSK)",
                            expressionAttributeNames: {
                                "#pagePK": "PagePK",
                                "#pageSK": "PageId",
                            },
                            expressionAttributeValues: {
                                ":pagePK": convertRecord.Domain.value + "#" + targetLanguage.languageCode + "#page",
                                ":pageSK": convertRecord.PageId,
                            },
                            indexName: "PagePK-PageId-index"
                                // "keyConditionExpression": "#pagePK = :pagePK",
                                // "expressionAttributeNames": {"#pagePK": "PagePK"},
                                // "expressionAttributeValues": {":pagePK": "languages"}
                        },
                    };
                    break;
            };
            // console.log('In getTargetLanguageCategory(): params is: ', JSON.stringify(params, null, 2));
    
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In getTargetLanguageCategory(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In getTargetLanguageCategory(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In getTargetLanguageCategory(): response.data.Item is: ', response.data.Item);

                let category = {};
                switch(referrer) {
                    case "ManageWebPages":
                        // console.log('In getTargetLanguageCategory(): response.data.Item is: ', response.data.Item);
                        if (response.data.Item) {
                            category = {
                                value: response.data.Item.ResourceCode,
                                text: response.data.Item.ResourceDesc
                            }
                        }
                        break;
                    case "ManagePageSections":
                        // console.log('In getTargetLanguageCategory(): response.data.Items is: ', response.data.Items);
                        if (response.data.Items && response.data.Items.length > 0) {
                            category = {
                                value: response.data.Items[0].PageId,
                                text: response.data.Items[0].PageTitle
                            }
                        }
                        break;
                    default:
                        break;
                }

                resolve(category);
                backdropProgress(false);
                return;
            }).catch((err) => {
                console.error("In getTargetLanguageCategory(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject("No target category found");
            });
        });
    }
        
    async function createService(convertRecord, translatedRefData, translatedItems) {
        // console.log("In createService(): convertRecord is: ", convertRecord);
        // console.log("In createService(): sourceLanguage is: ", sourceLanguage);
        // console.log("In createService(): targetLanguage is: ", targetLanguage);
        // console.log("In createService(): translatedRefData is: ", translatedRefData);
        // console.log("In createService(): translatedItems is: ", translatedItems);

        let translatedText1 = null;
        let translatedText2 = null;
        let translatedText3 = null;
        let translatedText4 = null;
        let translatedText5 = null;
        let translatedText6 = null;

        let targetLanguageCategory = null;
        // let targetLanguageStartTime = null;
        // let targetLanguageEndTime = null;
        
        if (!lodash.isEmpty(translatedItems)) {
            if (!lodash.isEmpty(translatedItems.translatedText1)) {
                translatedText1 = translatedItems.translatedText1;
            }

            if (!lodash.isEmpty(translatedItems.translatedText2)) {
                translatedText2 = translatedItems.translatedText2;
            }
            
            if (!lodash.isEmpty(translatedItems.translatedText3)) {
                translatedText3 = translatedItems.translatedText3;
            }
            
            if (!lodash.isEmpty(translatedItems.translatedText4)) {
                translatedText4 = translatedItems.translatedText4;
            }
            
            if (!lodash.isEmpty(translatedItems.translatedText5)) {
                translatedText5 = translatedItems.translatedText5;
            }
            
            if (!lodash.isEmpty(translatedItems.translatedText6)) {
                translatedText6 = translatedItems.translatedText6;
            }
        }
        // console.log("In createService(): translatedText1 is: ", translatedText1);
        // console.log("In createService(): translatedText2 is: ", translatedText2);
        // console.log("In createService(): translatedText3 is: ", translatedText3);
        // console.log("In createService(): translatedText4 is: ", translatedText4);
        
        if (!lodash.isEmpty(translatedRefData)) {
            if (!lodash.isEmpty(translatedRefData.targetLanguageCategory)) {
                targetLanguageCategory = translatedRefData.targetLanguageCategory;
            }

            // if (!lodash.isEmpty(translatedRefData.targetLanguageStartTime)) {
            //     targetLanguageStartTime = translatedRefData.targetLanguageStartTime;
            // }
            
            // if (!lodash.isEmpty(translatedRefData.targetLanguageEndTime)) {
            //     targetLanguageEndTime = translatedRefData.targetLanguageEndTime;
            // }
        }
    
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In createService(): Validation passed.");
            // console.log("In createService(): Validation passed.");

            backdropProgress(true);

            const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            const currentTime = Date.now();
            const timestamp = moment.tz(currentTime, currentTimeZone);           
            // console.log("In createService(): timestamp is: ", timestamp);

            let apiName = null;
            let path = null;
            let item = null;            
            let conditionExpression = null;
            let key = null;

            let uniqueId = GenerateUniqueName();
            
            switch(referrer) {
                case "ManageWebPages":
                    apiName = "PagesAPI";
                    path = "/dynamodb/pages/put";
                    conditionExpression = "attribute_not_exists(UniqueAttribute)";

                    key = {
                        PagePK: convertRecord.Domain.value + "#" + targetLanguage.languageCode + "#page",
                        PageSK: convertRecord.Domain.value + "#" + targetLanguage.languageCode + "#page#" + targetLanguageCategory.value + "#" + convertRecord.PageId,
                    };

                    item = {
                        "PagePK": key.PagePK,
                        "PageSK":  key.PageSK,

                        "PageId": convertRecord.PageId,

                        "UniqueAttribute": lodash.isEmpty(key) ? null : key,
                        "LanguageObject": lodash.isEmpty(targetLanguage) ? null : targetLanguage,
                        "LanguageCode": lodash.isEmpty(targetLanguage) ? null : targetLanguage.languageCode,
                        "Domain": lodash.isEmpty(convertRecord.Domain) ? null : convertRecord.Domain,

                        "PageDomain": lodash.isEmpty(convertRecord.Domain) ? null : convertRecord.Domain.value,
                        "PageCategory": lodash.isEmpty(targetLanguageCategory) ? null : targetLanguageCategory,
                        "PageCategoryCode": lodash.isEmpty(targetLanguageCategory) ? null : targetLanguageCategory.value,

                        "PageName": lodash.isEmpty(convertRecord.PageName) ? null : convertRecord.PageName,
                        "StartTime": lodash.isEmpty(convertRecord.StartTime) ? null : convertRecord.StartTime,
                        "EndTime": lodash.isEmpty(convertRecord.EndTime) ? null : convertRecord.EndTime,
                        "PageBannerUrl": lodash.isEmpty(convertRecord.PageBannerUrl) ? null : convertRecord.PageBannerUrl,

                        "PageHeader": lodash.isEmpty(translatedText1) ? null : translatedText1,
                        "PageTags": lodash.isEmpty(translatedText2) ? null : translatedText2,
                        "PageTitle": lodash.isEmpty(translatedText3) ? null : translatedText3,

                        "DomainLangCategoryCode": convertRecord.Domain.value + "#" + targetLanguage.languageCode + "#" + targetLanguageCategory.value,// For GSI

                        "ModifiedBy": appContext.userName,
                        "CreatedAt": timestamp,
                        "UpdatedAt": timestamp,
                    };
                    break;
                case "ManagePageSections":
                    apiName = "PagesAPI";
                    path = "/dynamodb/pages/put";
                    conditionExpression = "attribute_not_exists(UniqueAttribute)";

                    key = {
                        PagePK: convertRecord.Domain.value + "#" + targetLanguage.languageCode + "#page#section",
                        PageSK: convertRecord.Domain.value + "#" + targetLanguage.languageCode + "#page#section#" + targetLanguageCategory.value + "#" + convertRecord.SectionId,
                    };
    
                    item = {
                        "PagePK": key.PagePK,
                        "PageSK":  key.PageSK,

                        "UniqueAttribute": lodash.isEmpty(key) ? null : key,

                        "PageId": convertRecord.PageId,
                        "SectionId": convertRecord.SectionId,

                        "LanguageObject": lodash.isEmpty(targetLanguage) ? null : targetLanguage,
                        "LanguageCode": lodash.isEmpty(targetLanguage) ? null : targetLanguage.languageCode,

                        "Domain": lodash.isEmpty(convertRecord.Domain) ? null : convertRecord.Domain,
                        "SectionDomain": lodash.isEmpty(convertRecord.Domain) ? null : convertRecord.Domain.value,

                        "WebPage": lodash.isEmpty(targetLanguageCategory) ? null : targetLanguageCategory,

                        "SectionName": convertRecord.SectionName,

                        "SectionTitle": lodash.isEmpty(translatedText1) ? null : translatedText1,
                        "SectionDesc": lodash.isEmpty(translatedText2) ? null : translatedText2,

                        "MediaCaption": lodash.isEmpty(translatedText3) ? null : translatedText3,
                        "MediaDesc": lodash.isEmpty(translatedText4) ? null : translatedText4,
                        "MediaUrl": lodash.isEmpty(convertRecord.MediaUrl) ? null : convertRecord.MediaUrl,

                        "TextContent": lodash.isEmpty(translatedText5) ? null : translatedText5,
                        "EditorContent": lodash.isEmpty(translatedText6) ? null : translatedText6,

                        "SectionSeqNumber": convertRecord.SectionSeqNumber,

                        "DomainLangWebPageCode": convertRecord.Domain.value + "#" + targetLanguage.languageCode + "#" + targetLanguageCategory.value,// For GSI

                        "ModifiedBy": appContext.userName,
                        "CreatedAt": timestamp,
                        "UpdatedAt": timestamp,
                    };
                    break;
                default:
                    break;
            }

            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    item: item,
                    conditionExpression: conditionExpression,
                    // updateExpression: updateExpression,
                    // expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In createService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In createService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In createService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar 
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);
            }).catch((err) => {
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                switch(err.code) {
                    case "ConditionalCheckFailedException":
                        // console.error("In createService(): Duplicate Error");
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        break;
                    default:
                        // console.error("In createService(): err is: ", JSON.stringify(err, null, 2));
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                    break;
                }
            });
        }
    }

    // Uses Promise.all
    async function converterService() {
        if (!validateForm()) {
            // console.log("In converterService(): Validation passed.");
            // console.log("In converterService(): sourceLanguage is: ", sourceLanguage);
            // console.log("In converterService(): targetLanguage is: ", targetLanguage);
            // console.log("In converterService(): domain is: ", domain);
            // console.log("In converterService(): webPage is: ", webPage);
            // console.log("In converterService(): pageRecordBackup is: ", pageRecordBackup);

            let recordToConvert = null;
            let text1 = null;
            let text2 = null;
            let text3 = null;
            let text4 = null;
            let text5 = null;
            let text6 = null;

            let translatedItems = {};
            let translatedRefData = {};

            if (pageRecordBackup && pageRecordBackup.length > 0) {
                switch(referrer) {
                    case "ManageWebPages":
                        recordToConvert = pageRecordBackup.filter(record => record.PageId === pageTranslateItem.value);
                        break;
                    case "ManagePageSections":
                        recordToConvert = pageRecordBackup.filter(record => record.SectionId === pageTranslateItem.value);
                        break;
                    default:
                        return;
                }
                // console.log("In converterService(): recordToConvert is: ", recordToConvert);

                if (!lodash.isEmpty(recordToConvert)) {
                    switch(referrer) {
                        case "ManageWebPages":
                            text1 = recordToConvert[0].PageHeader;
                            text2 = recordToConvert[0].PageTags;
                            text3 = recordToConvert[0].PageTitle;
                            break;
                        case "ManagePageSections":
                            text1 = recordToConvert[0].SectionTitle;
                            text2 = recordToConvert[0].SectionDesc;
                            text3 = recordToConvert[0].MediaCaption;
                            text4 = recordToConvert[0].MediaDesc;
                            text5 = recordToConvert[0].TextContent;
                            text6 = recordToConvert[0].EditorContent;
                            break;
                        default:
                            break;
                    };

                    await Promise.all(
                        [
                            translate(text1, sourceLanguage.languageCode, targetLanguage.languageCode), // text, sourceLang, targetLang
                            translate2(text2, sourceLanguage.languageCode, targetLanguage.languageCode), // text, sourceLang, targetLang

                            translate3(text3, sourceLanguage.languageCode, targetLanguage.languageCode), // text, sourceLang, targetLang
                            translate4(text4, sourceLanguage.languageCode, targetLanguage.languageCode), // text, sourceLang, targetLang

                            translate5(text5, sourceLanguage.languageCode, targetLanguage.languageCode), // text, sourceLang, targetLang
                            translate6(text6, sourceLanguage.languageCode, targetLanguage.languageCode), // text, sourceLang, targetLang

                            getTargetLanguageCategory(recordToConvert[0]),
                        ]).then(async (results) => {
                            // console.log('In converterService(): results is: ', JSON.stringify(results, null, 2));
                            
                            translatedItems.translatedText1 = results[0];
                            // console.log('In converterService(): translatedItems.translatedText1 is: ', translatedItems.translatedText1);
                            
                            translatedItems.translatedText2 = results[1];
                            // console.log('In converterService(): translatedItems.translatedText2 is: ', translatedItems.translatedText2);
                            
                            translatedItems.translatedText3 = results[2];
                            // console.log('In converterService(): translatedItems.translatedText3 is: ', translatedItems.translatedText3);
                            
                            translatedItems.translatedText4 = results[3];
                            // console.log('In converterService(): translatedItems.translatedText4 is: ', translatedItems.translatedText4);
                            
                            translatedItems.translatedText5 = results[4];
                            // console.log('In converterService(): translatedItems.translatedText5 is: ', translatedItems.translatedText5);
                            
                            translatedItems.translatedText6 = results[5];
                            // console.log('In converterService(): translatedItems.translatedText6 is: ', translatedItems.translatedText6);

                            translatedRefData.targetLanguageCategory = results[6];
                            // console.log('In converterService(): targetLanguageCategory is: ', translatedRefData.targetLanguageCategory);

                            switch(referrer) {
                                case "ManageWebPages":
                                    if (!lodash.isEmpty(translatedRefData)) {
                                        await createService(recordToConvert[0], translatedRefData, translatedItems);
                                    }
                                    break;
                                case "ManagePageSections":
                                    if (!lodash.isEmpty(translatedRefData)) {
                                        await createService(recordToConvert[0], translatedRefData, translatedItems);
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }).catch(error => {
                            console.error("In converterService(): error is: ", error);
                            // Show Snackbar 
                            setSnackbarsVariant("error");
                            setSnackbarsMsg(operationFailureMsg);
                            setSnackbarOpen(true);
                            backdropProgress(false);
                        });
                    
                }
                
            }
        }
    }
    
    function snackbarOnClose(event, reason) {
        setSnackbarOpen(false);
    }

    return (
        <div dir={direction} className={classes.divWrapper}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {renderDomFlag && 
                <div className={classes.root}>
                    {/* {!appContext.signedIn && <Redirect to="/signIn" />} */}

                    <CssBaseline />

                    {isDeskTopView &&
                        <Container component="div" className={classes.main} maxWidth="lg">
                            <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                                <Grid container className={classes.mainForm} justify="center" alignContent="center" alignItems="center" direction="row"> 
                                    <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                                        <Grid container item justify="center">
                                            {showHeadersFlag &&
                                                <>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant="h5" color="primary">
                                                            {webPageConverterLabel}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant="caption" className={classes.greenColor}>
                                                            {requiredFields}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={4} />
                                                    </Grid>
                                                </>
                                            }
                                                
                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={12} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={sourceLanguageErrFlag}>
                                                            <label htmlFor="sourceLanguageId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={sourceLanguageErrFlag ? "secondary" : "inherit"}>
                                                                    *{sourceLanguageLabel}
                                                                </Typography>
                                                            </label>

                                                            <Autocomplete
                                                                id="sourceLanguageId"
                                                                value={sourceLanguage}
                                                                options={sourceLanguageOptions}
                                                                getOptionLabel={option => option.languageName}
                                                                getOptionSelected={option => option.languageName}
                                                                onChange={handleSourceLanguageChange}
                                                                loadingText={loadingText}
                                                                noOptionsText={noOptionsText}
                                                                autoHighlight={true}
                                                                clearText={clearText}
                                                                closeText={closeText}
                                                                disabled={false}
                                                                renderInput={params => 
                                                                    <TextField 
                                                                        {...params} 
                                                                        placeholder={sourceLanguagePrompt} 
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        error={sourceLanguageErrFlag}
                                                                        helperText={sourceLanguageErrFlag ? sourceLanguageErrMsg : ""}
                                                                        // InputProps={{
                                                                        //     readOnly: editModeFlag ? true : false,
                                                                        // }}
                                                                    />
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.rightPanelMainItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={targetLanguageErrFlag}>
                                                            <label htmlFor="targetLanguageId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={targetLanguageErrFlag ? "secondary" : "inherit"}>
                                                                    *{targetLanguageLabel}
                                                                </Typography>
                                                            </label>

                                                            <Autocomplete
                                                                id="targetLanguageId"
                                                                value={targetLanguage}
                                                                options={targetLanguageOptions}
                                                                getOptionLabel={option => option.languageName}
                                                                getOptionSelected={option => option.languageName}
                                                                onChange={handleTargetLanguageChange}
                                                                loadingText={loadingText}
                                                                noOptionsText={noOptionsText}
                                                                autoHighlight={true}
                                                                clearText={clearText}
                                                                closeText={closeText}
                                                                disabled={false}
                                                                renderInput={params => 
                                                                    <TextField 
                                                                        {...params} 
                                                                        placeholder={selectTargetLangPrompt} 
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        error={targetLanguageErrFlag}
                                                                        helperText={targetLanguageErrFlag ? targetLanguageErrMsg : ""}
                                                                        // InputProps={{
                                                                        //     readOnly: editModeFlag ? true : false,
                                                                        // }}
                                                                    />
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={12} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                            <label htmlFor="domainId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                    *{domainLabel}
                                                                </Typography>
                                                            </label>

                                                            <Autocomplete
                                                                id="domainId"
                                                                value={domain}
                                                                options={domainOptions}
                                                                getOptionLabel={option => option.text}
                                                                getOptionSelected={option => option.text}
                                                                onChange={handleListDomainChange}
                                                                loadingText={loadingText}
                                                                noOptionsText={noOptionsText}
                                                                autoHighlight={true}
                                                                clearText={clearText}
                                                                closeText={closeText}
                                                                // disabled={editModeFlag ? true : false}
                                                                renderInput={params => 
                                                                    <TextField 
                                                                        {...params} 
                                                                        placeholder={domainPrompt} 
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        error={domainErrFlag}
                                                                        helperText={domainErrFlag ? domainErrMsg : ""}
                                                                        // InputProps={{
                                                                        //     readOnly: editModeFlag ? true : false,
                                                                        // }}
                                                                    />
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            
                                            <Grid item xs={12} sm={6}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.rightPanelMainItems)}>
                                                        <FormControl className={classes.textField} error={pageTranslateItemErrFlag}>
                                                            <label htmlFor="webPageId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={pageTranslateItemErrFlag ? "secondary" : "inherit"}>
                                                                    *{referrer === "ManagePageSections" ? webPageSectionLabel : webPageLabel}
                                                                </Typography>
                                                            </label>

                                                            <Autocomplete
                                                                id="webPageId"
                                                                value={pageTranslateItem}
                                                                options={pageRecordOptions}
                                                                getOptionLabel={option => option.text}
                                                                getOptionSelected={option => option.text}
                                                                onChange={handlePageRecordChange}
                                                                loadingText={loadingText}
                                                                noOptionsText={noOptionsText}
                                                                autoHighlight={true}
                                                                clearText={clearText}
                                                                closeText={closeText}
                                                                renderInput={params => 
                                                                    <TextField 
                                                                        {...params} 
                                                                        placeholder={referrer === "ManagePageSections" ? webPageSectionPrompt : webPagePrompt} 
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        error={pageTranslateItemErrFlag}
                                                                        helperText={pageTranslateItemErrFlag ? (referrer === "ManagePageSections" ? webPageSectionErrMsg : webPageErrMsg) : ""}
                                                                    />
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={24} />
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={converterService}>
                                                        <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                        {converterLabel}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <LineSpacing value={24} />
                                </Grid>
                            </div>
                        </Container>
                    }

                    {isTabletView &&
                        <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                            <Grid container className={classes.mainForm} justify="center" alignContent="center" alignItems="center" direction="row"> 
                                <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                                    <Grid container item justify="center">
                                        {showHeadersFlag &&
                                            <>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="h5" color="primary">
                                                        {webPageConverterLabel}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="caption" className={classes.greenColor}>
                                                        {requiredFields}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={4} />
                                                </Grid>
                                            </>
                                        }

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={sourceLanguageErrFlag}>
                                                        <label htmlFor="sourceLanguageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={sourceLanguageErrFlag ? "secondary" : "inherit"}>
                                                                *{sourceLanguageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="sourceLanguageId"
                                                            value={sourceLanguage}
                                                            options={sourceLanguageOptions}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleSourceLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={sourceLanguagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={sourceLanguageErrFlag}
                                                                    helperText={sourceLanguageErrFlag ? sourceLanguageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={targetLanguageErrFlag}>
                                                        <label htmlFor="targetLanguageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={targetLanguageErrFlag ? "secondary" : "inherit"}>
                                                                *{targetLanguageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="targetLanguageId"
                                                            value={targetLanguage}
                                                            options={targetLanguageOptions}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleTargetLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={selectTargetLangPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={targetLanguageErrFlag}
                                                                    helperText={targetLanguageErrFlag ? targetLanguageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                *{domainLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="domainId"
                                                            value={domain}
                                                            options={domainOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleListDomainChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            // disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={domainPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={domainErrFlag}
                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={classes.textField} error={pageTranslateItemErrFlag}>
                                                        <label htmlFor="webPageId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={pageTranslateItemErrFlag ? "secondary" : "inherit"}>
                                                                *{referrer === "ManagePageSections" ? webPageSectionLabel : webPageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="webPageId"
                                                            value={pageTranslateItem}
                                                            options={pageRecordOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handlePageRecordChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={referrer === "ManagePageSections" ? webPageSectionPrompt : webPagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={pageTranslateItemErrFlag}
                                                                    helperText={pageTranslateItemErrFlag ? (referrer === "ManagePageSections" ? webPageSectionErrMsg : webPageErrMsg) : ""}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={converterService}>
                                                    <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                    {converterLabel}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={24} />
                            </Grid>
                        </div>
                    }

                    {isMobileView &&
                        <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                            <Grid container className={classes.mainForm} justify="center" alignContent="center" alignItems="center" direction="row"> 
                                <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                                    <Grid container item justify="center">
                                        {showHeadersFlag &&
                                            <>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="h5" color="primary">
                                                        {webPageConverterLabel}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="caption" className={classes.greenColor}>
                                                        {requiredFields}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={4} />
                                                </Grid>
                                            </>
                                        }

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={sourceLanguageErrFlag}>
                                                        <label htmlFor="sourceLanguageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={sourceLanguageErrFlag ? "secondary" : "inherit"}>
                                                                *{sourceLanguageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="sourceLanguageId"
                                                            value={sourceLanguage}
                                                            options={sourceLanguageOptions}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleSourceLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={sourceLanguagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={sourceLanguageErrFlag}
                                                                    helperText={sourceLanguageErrFlag ? sourceLanguageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={targetLanguageErrFlag}>
                                                        <label htmlFor="targetLanguageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={targetLanguageErrFlag ? "secondary" : "inherit"}>
                                                                *{targetLanguageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="targetLanguageId"
                                                            value={targetLanguage}
                                                            options={targetLanguageOptions}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleTargetLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={selectTargetLangPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={targetLanguageErrFlag}
                                                                    helperText={targetLanguageErrFlag ? targetLanguageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                *{domainLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="domainId"
                                                            value={domain}
                                                            options={domainOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleListDomainChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            // disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={domainPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={domainErrFlag}
                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={classes.textField} error={pageTranslateItemErrFlag}>
                                                        <label htmlFor="webPageId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={pageTranslateItemErrFlag ? "secondary" : "inherit"}>
                                                                *{referrer === "ManagePageSections" ? webPageSectionLabel : webPageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="webPageId"
                                                            value={pageTranslateItem}
                                                            options={pageRecordOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handlePageRecordChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={referrer === "ManagePageSections" ? webPageSectionPrompt : webPagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={pageTranslateItemErrFlag}
                                                                    helperText={pageTranslateItemErrFlag ? (referrer === "ManagePageSections" ? webPageSectionErrMsg : webPageErrMsg) : ""}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={converterService}>
                                                    <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                    {converterLabel}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={24} />
                            </Grid>
                        </div>
                    }
                </div>
            }

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={8000}
                onClose={snackbarOnClose}
                ContentProps={{ "aria-describedby": "message-id" }}
                message={<span id="message-id">{snackbarsMsg}</span>}
                variant={snackbarsVariant}
            />
        </div>
    );
}

export default WebPageConverter;
