import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
// import TextField from '@material-ui/core/TextField';

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Link from '@material-ui/core/Link';
// import Select from '@material-ui/core/Select';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import Select from 'react-select';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

// AG-Grid
import { AgGridReact, AgGridColumn } from "ag-grid-react";
//import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

// Amplify
import Amplify, { API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// import GlobalConstants from '../../utils/GlobalConstants';
// import GeneratePassword from '../../utils/GeneratePassword';
// import GenerateClientId from '../../utils/GenerateClientId';
// import GenerateTransactionId from '../../utils/GenerateTransactionId';
// import GenerateRandomHash from '../../utils/GenerateRandomHash';
// import GenerateModulusHash from '../../utils/GenerateModulusHash';
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import LineSpacing from "../../components/misc/LineSpacing";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// Renderers
import DateCellRenderer from "../../components/renderers/DateCellRenderer";
// import NumberCellRenderer from '../../components/renderers/NumberCellRenderer';
import ActionsCellRenderer from "../../components/renderers/ActionsCellRenderer";
// import { create } from 'jss';
// import CurrencyCellRenderer from '../../components/renderers/CurrencyCellRenderer';
// import CountryCellRenderer from '../../components/renderers/CountryCellRenderer';

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    backgroundImage: `url(${"assets/logos/background20.png"})`,
  },
  gridWidth: {
    width: "80%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(12),
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  textField: {
    width: "100%",
  },
  verticalAlignLabel: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  filterGrid: {
    textAlign: "left",
    marginBottom: "8px",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  textArea: {
    padding: theme.spacing(1),
    borderRadius: "5px",
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
    textAlign: "center !important",
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

let localeText = {
  page: "Page",
  to: "to",
  of: "of",
  noRowsToShow: "No records found",
};

// let localeText2 = {
//   page: "daPage",
//   more: "daMore",
//   to: "daTo",
//   of: "daOf",
//   next: "daNexten",
//   last: "daLasten",
//   first: "daFirsten",
//   previous: "daPreviousen",
//   loadingOoo: "daLoading...",
//   selectAll: "daSelect Allen",
//   searchOoo: "daSearch...",
//   blanks: "daBlanc",
//   filterOoo: "daFilter...",
//   applyFilter: "daApplyFilter...",
//   equals: "daEquals",
//   notEqual: "daNotEqual",
//   lessThan: "daLessThan",
//   greaterThan: "daGreaterThan",
//   lessThanOrEqual: "daLessThanOrEqual",
//   greaterThanOrEqual: "daGreaterThanOrEqual",
//   inRange: "daInRange",
//   contains: "daContains",
//   notContains: "daNotContains",
//   startsWith: "daStarts dawith",
//   endsWith: "daEnds dawith",
//   andCondition: "daAND",
//   orCondition: "daOR",
//   group: "laGroup",
//   columns: "laColumns",
//   filters: "laFilters",
//   rowGroupColumns: "laPivot Cols",
//   rowGroupColumnsEmptyMessage: "la drag cols to group",
//   valueColumns: "laValue Cols",
//   pivotMode: "laPivot-Mode",
//   groups: "laGroups",
//   values: "laValues",
//   pivots: "laPivots",
//   valueColumnsEmptyMessage: "la drag cols to aggregate",
//   pivotColumnsEmptyMessage: "la drag here to pivot",
//   toolPanelButton: "la tool panel",
//   noRowsToShow: "la no rows",
//   pinColumn: "laPin Column",
//   valueAggregation: "laValue Agg",
//   autosizeThiscolumn: "laAutosize Diz",
//   autosizeAllColumns: "laAutsoie em All",
//   groupBy: "laGroup by",
//   ungroupBy: "laUnGroup by",
//   resetColumns: "laReset Those Cols",
//   expandAll: "laOpen-em-up",
//   collapseAll: "laClose-em-up",
//   toolPanel: "laTool Panelo",
//   export: "laExporto",
//   csvExport: "la CSV Exportp",
//   excelExport: "la Excel Exporto (.xlsx)",
//   excelXmlExport: "la Excel Exporto (.xml)",
//   pinLeft: "laPin &lt;&lt;",
//   pinRight: "laPin &gt;&gt;",
//   noPin: "laDontPin &lt;&gt;",
//   sum: "laSum",
//   min: "laMin",
//   max: "laMax",
//   none: "laNone",
//   count: "laCount",
//   average: "laAverage",
//   copy: "laCopy",
//   copyWithHeaders: "laCopy Wit hHeaders",
//   ctrlC: "ctrl n C",
//   paste: "laPaste",
//   ctrlV: "ctrl n V"
// };

// For AG-Grid

const gridRowsData = [];

//------------------------------
// RENDERERS
//------------------------------
const gridOptions = {
  style: "currency", // decimal, currency, percent
  currency: "USD",
  currencyDisplay: "symbol", // symbol, code, name to use a localized currency name such as "dollar"
  useGrouping: true,
  minimumIntegerDigits: 21,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  payload: {},

  weekday: "short", // long, short
  year: "numeric",
  month: "short", // long, short
  day: "numeric",
  // timeZone: 'America/New_York', // Auto calculated in DateCellRenderer
  timeZoneName: "short", // long, short
  hour12: true,
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  locale: "default", // To use the browser's default locale, use 'default'
  // getRowNodeId: function(item) {
  //   return item.id;
  // },

  getRowHeight: function (params) {
    // console.log("In getRowHeight: params is: ", params);
    // assuming 50 characters per line, working how how many lines we need
    // console.log("In getRowHeight: words is: ", params.data.ResourceDesc.length);
    let lines = 36 * (Math.floor(params.data.ResourceDesc.length / 45) + 1);
    // console.log("In getRowHeight: lines is: ", lines);
    return lines > 36 ? lines : 36;
  },
};

let manageRef = {
  languageCodeLabel: "Reference language",
  languageNameLabel: "Lang. name",
  referenceCodeLabel: "Reference code",
  referenceTypeLabel: "Reference type",
  referenceCodePrompt: "Please enter references code",
  referenceDescLabel: "Reference description",
  referenceDescPrompt: "Please enter references description",
  referenceLanguageLabel: "Language",
  gridRefreshWarning: "If you change language, grid will refresh.",
  gridTypeRefreshWarning: "If you change type, grid will refresh.",
  selectReferenceLanguage: "Please select a reference language",
  selectReferenceType: "Please select a reference type",

  submit: "Submit",
  editLabel: "Edit",
  deleteLabel: "Delete",
  modifiedBy: "Modified by",
  createdAt: " Create date",
  updatedAt: "Modified date",
  manageReferenceSuccessMsg: "Manage referencess was successful.",
  manageReferenceFailureMsg: "Error while managing referencess.",
  manageReferences: "Manage reference data",
  updateGridRow: "Update grid",
  allFieldsAreRequired: "All fields are required",
  filterInputPrompt: "Please enter filter criteria.",
  filterGridLabel: "Filter grid",

  // For Grid
  gridPage: "Page",
  gridTo: "to",
  gridOf: "of",
  noRowsToShow: "No records found",
};

function cellStyle(params) {
  return { "white-space": "normal" };
}

function ManageReferenceData(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [referenceLanguage, setReferenceLanguage] = useState("");
  const [referenceType, setReferenceType] = useState("");
  const [referenceCode, setResourceCode] = useState("");
  const [referenceDesc, setResourceDesc] = useState("");

  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);
  const [gridData, setGridData] = useState(gridRowsData);
  const [editRowIndex, setEditRowIndex] = useState(-1);

  const [langSelectOptions, setLangSelectOptions] = useState([]);

  const [refTypeSelectOptions, setRefTypeSelectOptions] = useState([]);

  const [selectLangChangedFlag, setSelectLangChangedFlag] = useState(false);
  const [selectRefTypeChangedFlag, setSelectRefTypeChangedFlag] = useState(
    false
  );

  // AG-Grid
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [progressFlag, setProgressFlag] = useState(false);
  const [gridUpdatedFlag, setGridUpdatedFlag] = useState(false);
  const [rowSelectedFlag, setRowSelectedFlag] = useState(false);
  const [gridSubmitFlag, setGridSubmitFlag] = useState(false);
  const [gridRowsCount, setGridRowsCount] = useState(0);

  const [filterDisabledFlag, setFilterDisabledFlag] = useState(true);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  const [modifiedGridRows, setModifiedGridRows] = useState([]);
  const [deletedGridRows, setDeletedGridRows] = useState([]);
  const [currentEditRow, setCurrentEditRow] = useState(null); // Current row being edited

  const [gridFilterValue, setGridFilterValue] = useState("");
  const [paginationPageSize, setPaginationPageSize] = useState(10);

  // For Error Flags
  const [referenceLanguageErrFlag, setReferenceLanguageErrFlag] = useState(
    false
  );
  const [referenceCodeErrFlag, setResourceCodeErrFlag] = useState(false);
  const [referenceCodeFocus, setResourceCodeFocus] = useState(true);
  const [referenceDescErrFlag, setResourceDescErrFlag] = useState(false);
  const [referenceDescFocus, setResourceDescFocus] = useState(false);

  // const [redirectSignUpFlag, setRedirectSignUpFlag] = useState(false);
  // const [globalErrorMsg, setGlobalErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  // For Resources
  const [manageRefRes, setManageRefRes] = useState(manageRef);
  const [prevLangCode, setPrevLangCode] = useState(
    appContext.currentLanguage.languageCode
  );

  // For Grid Resources
  const [localeTextRes, setLocaleTextRes] = useState(localeText);

  const [renderGridFlag, setRenderGridFlag] = useState(false);

  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  const {
    languageCodeLabel,
    referenceLanguageLabel,
    referenceTypeLabel,
    gridRefreshWarning,
    gridTypeRefreshWarning,
    referenceCodeLabel,
    referenceCodePrompt,
    referenceDescLabel,
    referenceDescPrompt,
    selectReferenceLanguage,
    selectReferenceType,

    submit,
    editLabel,
    deleteLabel,
    modifiedBy,
    createdAt,
    updatedAt,
    manageReferenceSuccessMsg,
    manageReferenceFailureMsg,
    manageReferences,
    updateGridRow,
    allFieldsAreRequired,
    filterInputPrompt,
    filterGridLabel,
  } = manageRefRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(manageRef);
    // console.log("In ManageReferenceData(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ManageReferenceData(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "languageCodeLabel")
              manageRef.languageCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "referenceLanguageLabel")
              manageRef.referenceLanguageLabel = item.ResourceDesc;
            if (item.ResourceCode === "gridRefreshWarning")
              manageRef.gridRefreshWarning = item.ResourceDesc;
            if (item.ResourceCode === "gridTypeRefreshWarning")
              manageRef.gridTypeRefreshWarning = item.ResourceDesc;

            if (item.ResourceCode === "referenceCodeLabel")
              manageRef.referenceCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "referenceCodePrompt")
              manageRef.referenceCodePrompt = item.ResourceDesc;
            if (item.ResourceCode === "referenceDescLabel")
              manageRef.referenceDescLabel = item.ResourceDesc;
            if (item.ResourceCode === "referenceDescPrompt")
              manageRef.referenceDescPrompt = item.ResourceDesc;
            if (item.ResourceCode === "referenceTypeLabel")
              manageRef.referenceTypeLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectReferenceLanguage")
              manageRef.selectReferenceLanguage = item.ResourceDesc;
            if (item.ResourceCode === "selectReferenceType")
              manageRef.selectReferenceType = item.ResourceDesc;

            if (item.ResourceCode === "languageCodeLabel")
              manageRef.languageCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "resourceLanguageLabel")
              manageRef.resourceLanguageLabel = item.ResourceDesc;
            if (item.ResourceCode === "gridRefreshWarning")
              manageRef.gridRefreshWarning = item.ResourceDesc;
            if (item.ResourceCode === "resourceCodeLabel")
              manageRef.resourceCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "resourceCodePrompt")
              manageRef.resourceCodePrompt = item.ResourceDesc;
            if (item.ResourceCode === "resourceDescLabel")
              manageRef.resourceDescLabel = item.ResourceDesc;
            if (item.ResourceCode === "resourceDescPrompt")
              manageRef.resourceDescPrompt = item.ResourceDesc;

            if (item.ResourceCode === "submit")
              manageRef.submit = item.ResourceDesc;
            if (item.ResourceCode === "editLabel")
              manageRef.editLabel = item.ResourceDesc;
            if (item.ResourceCode === "deleteLabel")
              manageRef.deleteLabel = item.ResourceDesc;

            if (item.ResourceCode === "manageReferenceSuccessMsg")
              manageRef.manageReferenceSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "manageReferenceFailureMsg")
              manageRef.manageReferenceFailureMsg = item.ResourceDesc;
            if (item.ResourceCode === "allFieldsAreRequired")
              manageRef.allFieldsAreRequired = item.ResourceDesc;

            if (item.ResourceCode === "manageReferences")
              manageRef.manageReferences = item.ResourceDesc;
            if (item.ResourceCode === "updateGridRow")
              manageRef.updateGridRow = item.ResourceDesc;
            if (item.ResourceCode === "filterInputPrompt")
              manageRef.filterInputPrompt = item.ResourceDesc;
            if (item.ResourceCode === "filterGridLabel")
              manageRef.filterGridLabel = item.ResourceDesc;

            if (item.ResourceCode === "allFieldsAreRequired")
              manageRef.allFieldsAreRequired = item.ResourceDesc;
            if (item.ResourceCode === "modifiedBy")
              manageRef.modifiedBy = item.ResourceDesc;
            if (item.ResourceCode === "createdAt")
              manageRef.createdAt = item.ResourceDesc;
            if (item.ResourceCode === "updatedAt")
              manageRef.updatedAt = item.ResourceDesc;

            // For Grid
            if (item.ResourceCode === "gridPage")
              localeText.page = item.ResourceDesc;
            if (item.ResourceCode === "gridTo")
              localeText.to = item.ResourceDesc;
            if (item.ResourceCode === "gridOf")
              localeText.of = item.ResourceDesc;
            if (item.ResourceCode === "noRowsToShow")
              localeText.noRowsToShow = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, manageRef);
        }

        setManageRefRes(manageRef);
        setLocaleTextRes(localeText);

        setColumnDefs(columnDefsArray);

        setRenderGridFlag(true);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setManageRefRes(manageRef);
        setProgressFlag(false);
      });
  }

  //---------------------
  // AG-GRID Definitions
  //---------------------

  const columnDefsArray = [
    {
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressMenu: true,
      // filter: false,
      // pinned: false,
      resizable: true,
    },
    {
      headerName: "ResourcePK",
      field: "ResourcePK",
      width: 100,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: "ResourceSK",
      field: "ResourceSK",
      width: 100,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: languageCodeLabel,
      field: "LanguageCode",
      width: 100,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: referenceCodeLabel,
      field: "ResourceCode",
      width: 150,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
    },
    {
      headerName: referenceDescLabel,
      field: "ResourceDesc",
      width: 270,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellStyle: cellStyle,
    },
    {
      headerName: modifiedBy,
      field: "ModifiedBy",
      width: 270,
      // filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      filterParams: {
        clearButton: false,
        applyButton: false,
        debounceMs: 200,
      },
    },
    {
      headerName: createdAt,
      field: "CreatedAt",
      width: 210,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
      filter: "agDateColumnFilter",
      hide: true,
      // filterParams: {
      //   values: ["2000", "2004", "2008", "2012"]
      // }
    },
    {
      headerName: updatedAt,
      field: "UpdatedAt",
      width: 210,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
      filter: "agDateColumnFilter",
    },
    {
      headerName: deleteLabel,
      field: "delete",
      width: 100,
      filter: false,
      sortable: false,
      resizable: true,
      cellRendererFramework: ActionsCellRenderer,
    },
    {
      headerName: editLabel,
      field: "edit",
      width: 100,
      filter: false,
      sortable: false,
      resizable: true,
      cellRendererFramework: ActionsCellRenderer,
    },
  ];
  const [columnDefs, setColumnDefs] = useState(columnDefsArray);

  // AG-Grid
  const defColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
  };
  const [defaultColDef, setDefaultColDef] = useState(defColDef);

  const [rowSelection, setRowSelection] = useState("multiple");

  // Fetch Reference Type Options
  function fetchReferenceTypeOptions(selectedOption) {
    // console.log('In fetchReferenceTypeOptions(): selectedOption is: ', selectedOption);

    setProgressFlag(true);

    setRefTypeSelectOptions([]);
    let selectOptions = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "reference-types",
          ":resourceSK": selectedOption,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchReferenceTypeOptions(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchReferenceTypeOptions(): response length is: ', response.data.Items.length);
        // console.log('In fetchReferenceTypeOptions(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            text: item.ResourceDesc,
          };

          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setRefTypeSelectOptions(selectOptions);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchReferenceTypeOptions(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  // Fetch Grid Data
  async function fetchGridData() {
    // console.log('In fetchGridData(): referenceLanguage is: ', referenceLanguage);
    // console.log('In fetchGridData(): referenceType is: ', referenceType);

    setFilterDisabledFlag(true);
    setProgressFlag(true);

    let arrayData = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": referenceType,
          ":resourceSK": referenceLanguage,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchGridData(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchGridData(): response length is: ', response.data.Items.length);
        // console.log('In fetchGridData(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item, index) => {
          let record = {
            id: "" + index,
            LanguageCode: item.LanguageCode,
            ResourcePK: item.ResourcePK,
            ResourceSK: item.ResourceSK,
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
            ModifiedBy: item.ModifiedBy,
            CreatedAt: item.CreatedAt,
            UpdatedAt: item.UpdatedAt,
          };

          arrayData.push(record);
        });

        // console.log('In fetchGridData(): before setGridData: arrayData.length is: ', arrayData.length);
        setGridData(arrayData);

        if (response.data.Items.length > 0) {
          setFilterDisabledFlag(false);
        }

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchGridData(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  let selectOptions = [];

  function fetchLanguages() {
    // console.log('In fetchLanguages()');
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages(): response.length is: ', response.data.Items.length);
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            text: item.LanguageName,
          };
          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In useEffect()");

    let currLangCode = appContext.currentLanguage.languageCode;
    // console.log("In useEffect(): currLangCode is, ", currLangCode);
    if (currLangCode !== prevLangCode) {
      setPrevLangCode(currLangCode);

      // Fetch resource from catche
      let cacheItemKey = "manageRef";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;

      clearGridData();
      fetchDbResources(cacheItemKey);

      // setManageRefRes(manageRef);
      // clearGridData();
    }
  }, []);

  useLayoutEffect(() => {
    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In ManageReferenceData(): useLayoutEffect(): currentLanguage is: ', currentLanguage);
    // console.log('In ManageReferenceData(): useLayoutEffect(): GenerateModulusHash("firstName", 200) is: ', GenerateModulusHash("firstName", 200));
    // console.log('In ManageReferenceData(): useLayoutEffect(): appContext.signedIn is: ', appContext.signedIn);

    setResourceCodeFocus(true);
    setResourceDescFocus(false);

    if (selectOptions.length === 0) {
      fetchLanguages();
    }

    // Fetch resource from catche
    let cacheItemKey = "manageRef";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ManageReferenceData(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ManageReferenceData(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ManageReferenceData(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ManageReferenceData(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ManageReferenceData(): useLayoutEffect(): got resource from cache');
      setManageRefRes(cacheItem);
    }
  }, []);

  function checkGridUpdated() {
    // console.log('In checkGridUpdated(): referenceLanguage is: ', referenceLanguage);
    // console.log('In checkGridUpdated(): referenceCode is: ', referenceCode);
    // console.log('In checkGridUpdated(): referenceDesc is: ', referenceDesc);

    // Enable add to grid button
    setGridUpdatedFlag(false);
    if (
      referenceCode !== undefined &&
      referenceCode !== null &&
      referenceCode !== "" &&
      referenceDesc !== undefined &&
      referenceDesc !== null &&
      referenceDesc !== "" &&
      referenceLanguage !== undefined &&
      referenceLanguage !== null &&
      referenceLanguage !== ""
    ) {
      setGridUpdatedFlag(true);
    }
  }

  function handleChange(event) {
    //console.log("In handleChange(): id is: "+event.target.id);
    setGlobalErrorFlag(false);
    setGridUpdatedFlag(false);

    switch (event.target.id) {
      case "referenceCodeId":
        setResourceCodeErrFlag(false);
        setResourceCode(event.target.value);
        break;
      case "referenceDescId":
        setResourceDescErrFlag(false);
        setResourceDesc(event.target.value);
        break;
      default:
        break;
    }

    checkGridUpdated();
  }

  function validateForm() {
    let formErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setResourceCodeErrFlag(false);
    setResourceDescErrFlag(false);

    if (referenceLanguage === null || referenceLanguage === "") {
      formErrFlag = true;
      setReferenceLanguageErrFlag(true);
    }

    if (referenceCode === null || referenceCode === "") {
      formErrFlag = true;
      setResourceCodeErrFlag(true);
    }

    if (referenceDesc === null || referenceDesc === "") {
      formErrFlag = true;
      setResourceDescErrFlag(true);
    }

    return formErrFlag;
  }

  async function referenceService() {
    // console.log("In referenceService(): modifiedGridRows are: ", JSON.stringify(modifiedGridRows, null, 2));
    // console.log("In referenceService(): deletedGridRows are: ", JSON.stringify(deletedGridRows, null, 2));

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    // Test some utils functions
    // console.log('Generated Random Hash is: ', GenerateRandomHash());
    // console.log('Generated ClientId is: ', GenerateClientId());
    // console.log('Generated TransactionId is: ', GenerateTransactionId());
    // console.log('Generated Password is: ', GeneratePassword('jekwempu@tobisoft.co'));

    // Get Grid data
    const gridRowCount = getRowCount();
    // console.log('In referenceService(): gridRowCount is: ', gridRowCount);

    if (modifiedGridRows.length > 0 || deletedGridRows.length > 0) {
      // we have data to save
      // console.log('We have data to save.');

      const rowsData = [];
      const deleteRowsData = [];

      // const d = new Date();
      const timestamp = new Date();
      // const timestamp = d.toISOString();
      // console.log("In referenceService(): timestamp is: ", timestamp);

      // Handle Modified Grid Rows
      if (
        modifiedGridRows &&
        typeof modifiedGridRows !== "undefined" &&
        modifiedGridRows.length > 0
      ) {
        modifiedGridRows.map((data) => {
          // Process each node (row)
          let rowData = {
            ResourcePK: data.ResourcePK, // language code
            ResourceSK: data.ResourceSK,
            LanguageCode: data.LanguageCode,

            ResourceDesc: data.ResourceDesc,
            ResourceCode: data.ResourceCode,

            ModifiedBy: appContext.userName,
            CreatedAt: data.CreatedAt,
            UpdatedAt: timestamp,
          };

          rowsData.push(rowData);
        });
      }

      // Handle Deleted Grid Rows
      if (
        deletedGridRows &&
        typeof deletedGridRows !== "undefined" &&
        deletedGridRows.length > 0
      ) {
        deletedGridRows.map((data) => {
          // Process each node (row)
          let rowData = {
            ResourcePK: data.ResourcePK,
            ResourceSK: data.ResourceSK,
          };

          deleteRowsData.push(rowData);
        });
      }

      let params = {
        apiName: "ResourceAPI",
        path: "/dynamodb/resources/batchWrite",
        payload: {
          items: rowsData,
          deleteItems: deleteRowsData,
        },
      };

      let initObj = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        response: true, // return entire response object instead of response.data
        body: {},
      };

      initObj.headers.Authorization = appContext.jwtToken;
      initObj.body = params.payload;
      // console.log('In referenceService(): initObj is: ', JSON.stringify(initObj, null, 2));

      setProgressFlag(true);

      API.post(params.apiName, params.path, initObj)
        .then((response) => {
          // console.log('In referenceService(): response is: ', JSON.stringify(response, null, 2));

          // Clear the grid of any data
          clearGridData();

          // Clear Arrays
          setDeletedGridRows([]);
          setModifiedGridRows([]);

          // Clear Language Selection
          setReferenceLanguage("");
          setReferenceType("");

          // Show Snackbar
          setSnackbarsVariant("success");
          setSnackbarsMsg(manageReferenceSuccessMsg);
          setSnackbarOpen(true);

          setProgressFlag(false);
        })
        .catch((err) => {
          console.log(
            "In referenceService(): err is: ",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setGlobalErrorMsg(manageReferenceFailureMsg);

          setProgressFlag(false);
        });
    }
  }

  // gridOptions.payload = payload;

  //------------------------
  // Grid Functions
  //------------------------

  const deleteRows = (e) => {
    //console.log("In deleteRows(): gridApi is: ", gridApi);
    //console.log("In deleteRows(): gridApi.getSelectedNodes() is: ", gridApi.getSelectedNodes());
    var selectedRows = gridApi.getSelectedRows();
    var res = gridApi.updateRowData({ remove: selectedRows });
    // console.log("In deleteRows(): res is: ", res);

    if (selectedRows && selectedRows.length > 0) {
      let tempArray = deletedGridRows;
      selectedRows.map((record) => {
        tempArray.push(record);
      });
      setDeletedGridRows(tempArray);
    }

    // Update number of rows in grid
    getRowCount();
    initializeForm();

    setGridSubmitFlag(true);
  };

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
  }

  const onCellClicked = (event) => {
    // console.log('onCellClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onCellClicked: event.colDef.field is: ', event.colDef.field);
    // console.log('onCellClicked: event.colDef.field is: ', event.colDef.field);

    // let gridRowsCount;

    switch (event.colDef.field) {
      case "delete":
        var selectedData = gridApi.getSelectedRows();
        // console.log('onCellClicked: selectedData is: ', selectedData);

        let filteredSelectedData = selectedData.filter(
          (data) =>
            data.ResourcePK === event.data.ResourcePK &&
            data.ResourceCode === event.data.ResourceCode
        );
        // console.log('onCellClicked: filteredSelectedData is: ', filteredSelectedData);

        var res = gridApi.updateRowData({ remove: filteredSelectedData });
        // console.log("In onCellClicked() res is: ", res);

        // Update DeletedGridRows State
        if (filteredSelectedData && filteredSelectedData.length > 0) {
          let tempArray = deletedGridRows;
          filteredSelectedData.map((record) => {
            tempArray.push(record);
          });
          setDeletedGridRows(tempArray);
        }

        // Update number of rows in grid
        getRowCount();
        initializeForm();

        setGridSubmitFlag(true);
        break;
      case "edit":
        if (event.data) {
          // setReferenceLanguage(getLanguageByCode(event.data.ResourcePK));
          // setReferenceLanguage({value: 'es', text: 'Espanol'});
          setCurrentEditRow(event.data);
          setEditRowIndex(event.rowIndex);
          setResourceCode(event.data.ResourceCode);
          setResourceDesc(event.data.ResourceDesc);
        }
        break;
      default:
        break;
    }
  };

  const onCellValueChanged = (event) => {
    // console.log('onCellValueChanged: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellValueChanged: event.colDef.field is: ', event.colDef.field);

    const tempArray = modifiedGridRows;
    // console.log('onCellValueChanged: tempArray is: ', JSON.stringify(tempArray, null, 2));

    // Remove any previous changes to the same record
    let filteredTempArray = tempArray.filter(
      (data) =>
        data.ResourcePK !== event.data.ResourcePK &&
        data.ResourceCode !== event.data.ResourceCode
    );
    // console.log('onCellValueChanged: filteredTempArray is: ', JSON.stringify(filteredTempArray, null, 2));
    filteredTempArray.push(event.data);
    setModifiedGridRows(filteredTempArray);
  };

  const onRowSelected = (event) => {
    //gridApi = event.api;
    //console.log('onRowSelected: event is: ', event);
    // console.log('onRowSelected: event.data is: ', JSON.stringify(event.data, null, 2));
    setRowSelectedFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getSelectedNodes().length > 0
    ) {
      setRowSelectedFlag(true);
      // console.log('onRowSelected: after rowSelectedFlag is: ', rowSelectedFlag);
    }
  };

  const onRowClicked = (event) => {
    // console.log('onRowClicked: Total number of rows is: ', gridApi.getDisplayedRowCount());
    // console.log('onRowClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onRowClicked: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onRowClicked: clicked row is: ', gridApi.getDisplayedRowAtIndex(event.rowIndex));
    // gridApi.forEachNode(function(node) {
    //   //if (node.data.country === "United States") {
    //     node.setSelected(true);
    //   //}
    // });
  };

  const onRowValueChanged = (event) => {
    // console.log('onRowValueChanged: event is:  ', event);
  };

  const onRowDataChanged = (event) => {
    //gridApi = event.api;
    // console.log('>>>>In onRowDataChanged: referenceLanguage is: ', referenceLanguage);
    // console.log('>>>>In onRowDataChanged: referenceType is: ', referenceType);
    // console.log('>>>>In onRowDataChanged: event is: ', event);
    // setGridSubmitFlag(false);

    setColumnDefs(columnDefsArray);
    setLocaleTextRes(localeText);

    if (
      selectRefTypeChangedFlag &&
      referenceLanguage !== undefined &&
      referenceLanguage !== null &&
      referenceType !== undefined &&
      referenceType !== null
    ) {
      fetchGridData();
    }

    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getModel().getRowCount() > 0
    ) {
      // console.log('>>>>In onRowDataChanged: gridApi.getModel().getRowCount() is: ', gridApi.getModel().getRowCount());
      // setGridSubmitFlag(true);

      // Update display of number of rows in grid
      getRowCount();
    }
  };

  const onRowDataUpdated = (event) => {
    // console.log('onRowDataUpdated: event is:  ', event);
  };

  const onFirstDataRendered = (event) => {
    // console.log('onFirstDataRendered: event is:  ', event);
  };

  function clearGridData() {
    if (gridApi) {
      gridApi.setRowData([]);

      getRowCount();

      // Disable Submit button
      setGridSubmitFlag(false);
    }
  }

  function initializeForm() {
    // console.log("In initializeForm()");

    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setResourceCode("");
    setResourceDesc("");

    gridApi.deselectAll();
  }

  function getRowCount() {
    let gridRowsCount = gridApi.getModel().getRowCount();
    setGridRowsCount(gridRowsCount);
    return gridRowsCount;
  }

  function updateModifiedGridRow() {
    // console.log("In updateModifiedGridRow(): referenceLanguage is: ", referenceLanguage);
    // console.log("In updateModifiedGridRow(): referenceCode is: ", referenceCode);
    // console.log("In updateModifiedGridRow(): referenceDesc is: ", referenceDesc);
    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    if (!validateForm()) {
      // Validation Passed - you can proceed
      // console.log('Validation Passed.');
      setGridUpdatedFlag(false);
      setGridSubmitFlag(true);

      // console.log("In updateModifiedGridRow(): currentEditRow is: ", currentEditRow);
      if (currentEditRow && currentEditRow !== null) {
        currentEditRow.ResourceDesc = referenceDesc;

        const res = gridApi.updateRowData({ update: [currentEditRow] });
        // console.log("In updateModifiedGridRow(): res is: ", res);

        // Remove any previous changes to the same record
        const tempArray = modifiedGridRows;
        // console.log('In updateModifiedGridRow(): tempArray is: ', JSON.stringify(tempArray, null, 2));

        tempArray.filter(
          (data) =>
            data.ResourcePK !== currentEditRow.ResourcePK &&
            data.ResourcePK !== currentEditRow.ResourcePK
        );
        // console.log('In updateModifiedGridRow(): tempArray before push is: ', JSON.stringify(tempArray, null, 2));
        tempArray.push(currentEditRow);
        setModifiedGridRows(tempArray);
        // console.log('In updateModifiedGridRow(): tempArray after push is: ', JSON.stringify(tempArray, null, 2));

        // Update number of rows in grid
        getRowCount();

        // Initialize Variables
        initializeForm();
      }
    }
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    setSnackbarOpen(false);
  }

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);
    setReferenceLanguage(selectedOption);

    initializeForm();

    setSelectLangChangedFlag(true);

    // Clear the grid of any data
    clearGridData();

    setRefTypeSelectOptions(null);
    setReferenceType(null);
    fetchReferenceTypeOptions(selectedOption);
  }

  function handleTypeChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleTypeChange(): selectedOption is', selectedOption);
    setReferenceType(selectedOption);

    initializeForm();

    setSelectRefTypeChangedFlag(true);

    // Clear the grid of any data
    clearGridData();

    fetchGridData();
  }

  function handleTextareaChange(event) {
    // console.log('In handleTextareaChange(): event is', event.target.value);
    setResourceDesc("");
    if (event.target.value) {
      setResourceDesc(event.target.value);
    }

    setGridUpdatedFlag(false);
    if (
      referenceCode !== undefined &&
      referenceCode !== null &&
      referenceCode !== "" &&
      event.target.value !== undefined &&
      event.target.value !== null &&
      event.target.value !== "" &&
      referenceLanguage !== undefined &&
      referenceLanguage !== null &&
      referenceLanguage !== ""
    ) {
      setGridUpdatedFlag(true);
    }
  }

  const gridComponents = {
    loadingRenderer: function (params) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<CircularProgress className={classes.progress} color="secondary" size={30}/>';
      }
    },
  };

  function handleGridFilterChange(event) {
    // console.log("In handleGridFilterChange(): value is: "+event.target.value);
    setGridFilterValue(event.target.value);
    gridApi.setQuickFilter(event.target.value);
  }

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { !appContext.signedIn &&
          <Redirect to='/signIn' />
        } */}
        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%", height: "100%" }}>
            {/* progressFlag &&
              <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
              </div>
            */}
            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="md"
            >
              <Grid container justify="center">
                <Grid item xs={12} sm={9}>
                  <StyledPaper elevation={3} className={classes.paper}>
                    <Typography
                      variant="h6"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      {manageReferences}
                    </Typography>

                    <Typography
                      variant="caption"
                      style={{ color: "green", textAlign: "center" }}
                    >
                      {allFieldsAreRequired}
                    </Typography>

                    {globalErrorFlag && (
                      <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                    )}

                    <LineSpacing value={24} />

                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="formLanguageId"
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {referenceLanguageLabel}
                        </Typography>
                      </label>
                      <Select
                        id="formLanguageId"
                        value={referenceLanguage}
                        placeholder={selectReferenceLanguage}
                        onChange={handleLanguageChange}
                        variant="outlined"
                        displayEmpty={true}
                        autoFocus
                        fullWidth
                        style={{ width: "100%", flexGrow: 1 }}
                      >
                        <MenuItem value={null} disabled>
                          <Typography variant="body1">
                            {selectReferenceLanguage}
                          </Typography>
                        </MenuItem>
                        {langSelectOptions.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="referenceTypeId"
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {referenceTypeLabel}
                        </Typography>
                      </label>
                      <Select
                        id="referenceTypeId"
                        value={referenceType}
                        placeholder={selectReferenceType}
                        onChange={handleTypeChange}
                        variant="outlined"
                        displayEmpty={true}
                        // autoFocus
                        fullWidth
                        style={{ width: "100%", flexGrow: 1 }}
                      >
                        <MenuItem value={null} disabled>
                          <Typography variant="body1">
                            {selectReferenceType}
                          </Typography>
                        </MenuItem>
                        {refTypeSelectOptions.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <label
                        htmlFor="languageCodeId"
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {referenceCodeLabel}
                        </Typography>
                      </label>
                      <OutlinedInput
                        id="referenceCodeId"
                        value={referenceCode}
                        placeholder={referenceCodePrompt}
                        onChange={handleChange}
                        required
                        fullWidth
                        // autoFocus
                        type={"text"}
                        error={referenceCodeErrFlag}
                        margin="none"
                        readOnly
                      />
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <label
                        htmlFor="resourceDescId"
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {referenceDescLabel}
                        </Typography>
                      </label>
                      <TextareaAutosize
                        id="referenceDescId"
                        aria-label="reference desc"
                        rows={3}
                        rowsMax={6}
                        value={referenceDesc}
                        placeholder={referenceDescPrompt}
                        onChange={handleTextareaChange}
                        required
                        fullWidth
                        error={referenceDescErrFlag}
                        margin="none"
                        className={classes.textArea}
                      />
                    </FormControl>

                    <LineSpacing value={18} />

                    <Button
                      variant="contained"
                      size="large"
                      disabled={!gridUpdatedFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={updateModifiedGridRow}
                    >
                      <AddIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {updateGridRow}
                    </Button>

                    <LineSpacing value={8} />
                  </StyledPaper>
                </Grid>
              </Grid>
            </Container>

            <LineSpacing value={32} />

            {/* AG-GRID */}

            {renderGridFlag && (
              <Container
                component="div"
                className={classes.mainGrid}
                maxWidth="lg"
              >
                <Grid container justify="center">
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!rowSelectedFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={deleteRows}
                      style={directionFloat}
                    >
                      <DeleteIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {deleteLabel}
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Grid container className={classes.filterGrid}>
                      <Grid
                        item
                        xs={2}
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <label htmlFor="filterTextId">
                          <Typography
                            variant="subtitle1"
                            className={classes.label}
                          >
                            {filterGridLabel}:{" "}
                          </Typography>
                        </label>
                      </Grid>

                      <Grid item xs={10}>
                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="filterTextId"
                            value={gridFilterValue}
                            placeholder={filterInputPrompt}
                            onChange={handleGridFilterChange}
                            fullWidth
                            type="search"
                            disabled={filterDisabledFlag}
                            startAdornment={
                              <InputAdornment position="start">
                                <Search color="primary" />
                              </InputAdornment>
                            }
                            margin="none"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!gridSubmitFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={referenceService}
                      style={directionReverseFloat}
                    >
                      <SaveIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {submit}
                    </Button>
                  </Grid>
                </Grid>

                <div style={{ width: "100%", height: "100%" }}>
                  <div
                    id="refGrid"
                    style={{
                      // height: "400px",
                      width: "100%",
                    }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      localeText={localeTextRes}
                      domLayout="autoHeight"
                      onGridReady={onGridReady}
                      gridOptions={gridOptions}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      rowData={gridData}
                      rowSelection={rowSelection}
                      rowMultiSelectWithClick={true}
                      rowDeselection={true}
                      suppressRowClickSelection={false}
                      // debug={true}
                      pagination={true}
                      components={gridComponents}
                      paginationPageSize={paginationPageSize}
                      cacheOverflowSize={2}
                      maxConcurrentDatasourceRequests={2}
                      infiniteInitialRowCount={1}
                      maxBlocksInCache={2}
                      enableRtl={enableRtl}
                      onCellClicked={onCellClicked}
                      onCellValueChanged={onCellValueChanged}
                      onRowSelected={onRowSelected}
                      onRowClicked={onRowClicked}
                      onRowValueChanged={onRowValueChanged}
                      onRowDataChanged={onRowDataChanged}
                      onRowDataUpdated={onRowDataUpdated}
                      onFirstDataRendered={onFirstDataRendered}
                    />
                  </div>
                </div>
              </Container>
            )}
          </div>

          {/* variant: 'success', 'warning', 'error', 'info' */}
          <TsiCustomizedSnackbars
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={8000}
            onClose={snackbarOnClose}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">{snackbarsMsg}</span>}
            variant={snackbarsVariant}
          />
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter isSignedIn={isSignedIn} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default ManageReferenceData;
