import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InputAdornment from "@material-ui/core/InputAdornment";
import TranslateIcon from '@material-ui/icons/Translate';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Froala
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// Amplify
// import { API } from "aws-amplify";
// import { AmplifyTheme   } from 'aws-amplify-react';

// AppContext
import AppContext from "../../../config/AppContext";

import LineSpacing from "../../../components/misc/LineSpacing";
import GlobalErrorMessage from "../../../components/dialogs/GlobalErrorMessage";
import FroalaCommonEditor from "../../../components/editor/FroalaCommonEditor";
import DomainUploadCapture from "../../../components/upload/DomainUploadCapture";

import WebPageConverter from "../WebPageConverter";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        width: "100% !important",
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    leftPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "left",
    },
    leftPanelSubItems: {
        width: "100% !important",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    divLeftPadding: {
        width: '100% !important',
        paddingLeft: theme.spacing(1),
        // paddingRight: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1rem" /* Footer height */,

        width: "100% !important",
        marginTop: "auto",
        // backgroundColor: '#fff',
        textAlign: "center",
    },
    paper: {
        minWidth: "300px",
        marginTop: theme.spacing(2),
        alignItems: "center",
        padding: "10px 30px 10px 30px !important",
        textAlign: "center",
    },
    divPadding: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

function ManageWebPagesMv(props) {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);
    
    const theme = useTheme();
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    const { 
        manageWebPagesLabel, 
        requiredFields,
        languageLabel,
        languagePrompt,
        languageErrMsg,
        domainLabel,
        domainPrompt,
        domainErrMsg,
            
        pageTagsLabel,
        pageTagsPrompt,
        pageTagsErrMsg,
        pageTagsMsg,
        
        pageNameLabel,
        pageNamePrompt,
        pageNameErrMsg,
        pageNameMsg,
        // selectTitlePrompt,

        pageTitleLabel,
        pageTitlePrompt,
        pageTitleErrMsg,

        startTimeLabel,
        startTimePrompt,
        startTimeErrMsg,

        endTimeLabel,
        endTimePrompt,
        endTimeErrMsg,

        pageHeaderLabel,
        pageHeaderPrompt,
        pageHeaderErrMsg,
        pageHeaderMsg,
        
        pageCategoryLabel,
        pageCategoryPrompt,
        pageCategoryErrMsg,

        gridCategory,
        gridPageName,
        gridPageTitle,
        gridPageTags,
    
        loadingText,
        noOptionsText,
        clearText,
        closeText,
        moreLabel,
    
        uploadLabel,
        // createWebPageLabel,
        updateWebPageLabel,
        createLabel,
        closeLabel,
        noWebPageRecordsFound, 
        gridMonthlyPrice,
        gridComments,
        lastUpdateLabel,
        convertLabel,
        clearLabel,
        okLabel,

        bannerUrlLabel,
        bannerUrlPrompt,
        bannerUrlErrMsg,

        cloneRecordLabel,
        cloneAction,
        
        editorRef2,
        editorMaxChars,
        handleEditorContentChange,
        editorReadyCallback,

        convertWebPagesLabel,
        createWebPageLabel,
        uploadFilesLabel,

        //==================================

        datePickerLanguage,
        globalErrorFlag,
        globalErrorMsg,
        showListFlag,
        languageErrFlag,
        language,
        languages,
        handleListLanguageChange,
        editModeFlag,
        domainErrFlag,
        domain,
        domainOptions,
        handleListDomainChange,
        webPageRecords,
        showAlertDialog,
        editRecordAction,
        convertAction,
        uploadAction,
        createMoreAction,
        noRecordFoundFlag,
        createPriceAction,
        showDetailsFormFlag,
        closeDetailsForm,
        handleLanguageChange,
        handleDomainChange,
        pageCategoryErrFlag,
        pageCategory,
        categoryOptions,
        handlePageCategoryChange,
        pageTagsErrFlag,
        pageTags,
        handleChange,
        textMaxLength,
        pageNameErrFlag,
        pageTitleErrFlag,
        pageName,
        pageTitle,
        pageHeaderErrFlag,
        pageHeader,
        submitButtonFlag,
        buttonFormAction,
        convertFlag,
        closeConvertForm,

        startTimeErrFlag,
        startTime,
        startTimeOptions,

        endTimeErrFlag,
        endTime,
        endTimeOptions,
        forUserOnly,

        handleStartTimeChange,
        handleEndTimeChange,
        sliceText,
        sliceMoreAction,
        closeUploadForm,
        uploadFlag,

        cancelLabel,
        timeBeforeErrFlag,
        bannerUrl,
        bannerUrlErrFlag,
        selectMediaAction,
        endTimeBeforeErrMsg,
    } = props;
        
    useEffect(() => {
        // console.log('In ManageWebPagesMv(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        // sessionStorage.removeItem("current-live-event");
    }, []);

    useLayoutEffect(() => {
        // console.log("In ManageWebPagesMv(): useLayoutEffect()");
    }, []);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
            <div className={clsx(classes.main, classes.divPadding)}>
                {/* <StyledPaper elevation={3} className={classes.paper}> */}
                    <Grid container justify="center">
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h5" color="primary" style={{ textAlign: "center" }}>
                                {manageWebPagesLabel}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                                {requiredFields}
                            </Typography>
                        </Grid>

                        {globalErrorFlag && (
                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={24} />
                                <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={12}>
                            <LineSpacing value={24} />
                        </Grid>
                        
                        <Grid item xs={12} sm={12}>
                            <Grid container justify="center">
                                {showListFlag &&
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                *{languageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="languageId"
                                                            value={language}
                                                            options={languages}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleListLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={languagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={languageErrFlag}
                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                *{domainLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="domainId"
                                                            value={domain}
                                                            options={domainOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleListDomainChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            // disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={domainPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={domainErrFlag}
                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            {webPageRecords && webPageRecords.length > 0 ?
                                                <>
                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={12} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={4} sm={4}>
                                                                <Grid container justify="flex-start">
                                                                    <Tooltip title={convertLabel} placement="bottom">
                                                                        <IconButton color="primary" aria-label={convertLabel} component="span" onClick={convertAction}>
                                                                            <TranslateIcon className={clsx(classes.iconLarge)} aria-label={convertLabel}/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={4} sm={4}>
                                                                <Grid container justify="center">
                                                                    <Tooltip title={uploadLabel} placement="bottom">
                                                                        <IconButton color="primary" aria-label={uploadLabel} component="span" onClick={uploadAction}>
                                                                            <CloudUploadIcon className={clsx(classes.iconLarge)} aria-label={uploadLabel}/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={4} sm={4}>
                                                                <Grid container justify="flex-end">
                                                                    <Tooltip title={createLabel} placement="bottom">
                                                                        <IconButton color="primary" aria-label={createLabel} component="span" onClick={createMoreAction}>
                                                                            <AddIcon className={clsx(classes.iconLarge)} aria-label={createLabel}/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={8} />
                                                    </Grid>
                                                    
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <List className={classes.listContainer}>
                                                                <div>
                                                                    {webPageRecords && webPageRecords.length > 0 &&
                                                                        webPageRecords.map((record, index) => (
                                                                            <Grid key={index} container justify="center">
                                                                                <ListItem alignItems="flex-start" disableGutters={true}>
                                                                                    <ListItemText style={{backgroundColor: (index % 2) === 0 ? '#F8F8FF' : 'inherit', padding: '8px'}}>
                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {gridCategory}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.PageCategoryText}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {gridPageName}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.PageName}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {gridPageTitle}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.PageTitle}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        
                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {gridPageTags}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.PageTags}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {startTimeLabel}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.FormattedStartTime}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {endTimeLabel}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.EndTime ? record.FormattedEndTime : ""}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {lastUpdateLabel}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.FormattedDateTime}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={12} sm={12}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {pageHeaderLabel}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={12} sm={12}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        <FroalaEditorView model={sliceText(record.PageHeader, 300)}/>
                                                                                                    </Typography>

                                                                                                    {(record.PageHeader.length > 300) &&
                                                                                                        <Grid container justify="center">
                                                                                                            <Link component="button" variant="caption" onClick={() => sliceMoreAction(record)}>
                                                                                                                {moreLabel}
                                                                                                            </Link>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        
                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="flex-end">
                                                                                                <Grid item style={{paddingRight: theme.spacing(3)}}>
                                                                                                    <DeleteIcon color="secondary" onClick={() => showAlertDialog(record, "single")}/>
                                                                                                </Grid>

                                                                                                <Grid item>
                                                                                                    <EditIcon color="primary" onClick={() => editRecordAction(record)}/>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </ListItemText>
                                                                                </ListItem>
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </List>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            :
                                                <>
                                                    {noRecordFoundFlag &&
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12}>
                                                                <Typography variant="body1" color="secondary">
                                                                    {noWebPageRecordsFound}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={12} sm={12}>
                                                                <LineSpacing value={12} />
                                                            </Grid>

                                                            <Grid item xs={12} sm={12}>
                                                                <Button variant="contained" size="large" disabled={false} color="primary" onClick={createPriceAction}>
                                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                    {createWebPageLabel}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                }

                                {showDetailsFormFlag && 
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-end">
                                                <Tooltip title={closeLabel} placement="bottom">
                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={6} sm={6}>
                                                    <Grid container justify="flex-start">
                                                        <Typography variant="h6" color="primary">
                                                            {createWebPageLabel}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={6} sm={6}>
                                                    <Grid container justify="center">
                                                        {editModeFlag &&
                                                            <Grid container justify="center">
                                                                <Button color="primary" onClick={cloneAction}>
                                                                    <Typography variant="subtitle1" color="primary">
                                                                        {cloneRecordLabel}
                                                                    </Typography>
                                                                </Button>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                *{languageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="languageId"
                                                            value={language}
                                                            options={languages}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={languagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={languageErrFlag}
                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                *{domainLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="domainId"
                                                            value={domain}
                                                            options={domainOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleDomainChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={domainPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={domainErrFlag}
                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={pageCategoryErrFlag}>
                                                        <label htmlFor="pageCategoryId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={pageCategoryErrFlag ? "secondary" : "inherit"}>
                                                                *{pageCategoryLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="pageCategoryId"
                                                            value={pageCategory}
                                                            options={categoryOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handlePageCategoryChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={pageCategoryPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={pageCategoryErrFlag}
                                                                    helperText={pageCategoryErrFlag ? pageCategoryErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={pageTagsErrFlag}>
                                                                <label htmlFor="pageTagsId" style={directionAlignment}>
                                                                    <Typography variant="subtitle1" color={pageTagsErrFlag ? "secondary" : "inherit"}>
                                                                        {pageTagsLabel}
                                                                    </Typography>
                                                                </label>

                                                                <TextareaAutosize
                                                                    id="pageTagsId"
                                                                    aria-label={pageTagsLabel}
                                                                    rows={1.5}
                                                                    rowsMax={6}
                                                                    value={pageTags}
                                                                    placeholder={pageTagsPrompt}
                                                                    onChange={handleChange}
                                                                    required
                                                                    // autoFocus={true}
                                                                    margin="none"
                                                                    className={classes.textArea}
                                                                    maxLength={textMaxLength}
                                                                />
                                                                <FormHelperText>{pageTagsErrFlag ? pageTagsErrMsg : ""}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={startTimeErrFlag}>
                                                                <label htmlFor="startTimeId" style={directionAlignment}>
                                                                    <Typography variant="subtitle1" color={startTimeErrFlag ? "secondary" : "inherit"}>
                                                                        *{startTimeLabel}
                                                                    </Typography>
                                                                </label>

                                                                <KeyboardDateTimePicker
                                                                    id="startTimeId"
                                                                    value={startTime}
                                                                    // views={["year"]}
                                                                    onChange={handleStartTimeChange}
                                                                    clearable
                                                                    placeholder={startTimePrompt}
                                                                    format="MMM DD, YYYY hh:mm a z"
                                                                    // inputVariant="outlined"
                                                                    invalidDateMessage={startTimeErrMsg}
                                                                    cancelLabel={cancelLabel}
                                                                    clearLabel={clearLabel}
                                                                    okLabel={okLabel}
                                                                    // className={classes.phone}
                                                                    inputVariant="outlined"
                                                                    margin="none"
                                                                />
                                                                <FormHelperText>{startTimeErrFlag ? startTimeErrMsg : ""}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={endTimeErrFlag}>
                                                                <label htmlFor="endTimeId" style={directionAlignment}>
                                                                    <Typography variant="subtitle1" color={endTimeErrFlag ? "secondary" : "inherit"}>
                                                                        {endTimeLabel}
                                                                    </Typography>
                                                                </label>

                                                                <KeyboardDateTimePicker
                                                                    id="endTimeId"
                                                                    value={endTime}
                                                                    // views={["year"]}
                                                                    onChange={handleEndTimeChange}
                                                                    clearable
                                                                    placeholder={endTimePrompt}
                                                                    format="MMM DD, YYYY hh:mm a z"
                                                                    // inputVariant="outlined"
                                                                    invalidDateMessage={timeBeforeErrFlag ? endTimeBeforeErrMsg : endTimeErrMsg}
                                                                    cancelLabel={cancelLabel}
                                                                    clearLabel={clearLabel}
                                                                    okLabel={okLabel}
                                                                    // className={classes.phone}
                                                                    inputVariant="outlined"
                                                                    margin="none"
                                                                />
                                                                <FormHelperText>{endTimeErrFlag ? (timeBeforeErrFlag ? endTimeBeforeErrMsg : endTimeErrMsg) : ""}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={pageNameErrFlag}>
                                                        <label htmlFor="pageNameId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={pageNameErrFlag ? "secondary" : "inherit"}>
                                                                {pageNameLabel}
                                                            </Typography>
                                                        </label>

                                                        <TextField
                                                            id="pageNameId"
                                                            value={pageName}
                                                            placeholder={pageNamePrompt}
                                                            onChange={handleChange}
                                                            required
                                                            fullWidth
                                                            type={"text"}
                                                            error={pageNameErrFlag}
                                                            margin="none"
                                                            variant="outlined"
                                                            helperText={pageNameErrFlag ? pageNameErrMsg : ""}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={pageTitleErrFlag}>
                                                                <label htmlFor="pageTitleId" style={directionAlignment}>
                                                                    <Typography variant="subtitle1" color={pageTitleErrFlag ? "secondary" : "inherit"}>
                                                                        *{pageTitleLabel}
                                                                    </Typography>
                                                                </label>

                                                                <TextField
                                                                    id="pageTitleId"
                                                                    value={pageTitle}
                                                                    placeholder={pageTitlePrompt}
                                                                    onChange={handleChange}
                                                                    required
                                                                    fullWidth
                                                                    type={"text"}
                                                                    error={pageTitleErrFlag}
                                                                    margin="none"
                                                                    variant="outlined"
                                                                    helperText={pageTitleErrFlag ? pageTitleErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                                                    // }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={bannerUrlErrFlag}>
                                                        <label htmlFor="bannerUrlId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={bannerUrlErrFlag ? "secondary" : "inherit"}>
                                                                {bannerUrlLabel}
                                                            </Typography>
                                                        </label>

                                                        <OutlinedInput
                                                            id="bannerUrlId"
                                                            value={bannerUrl}
                                                            placeholder={bannerUrlPrompt}
                                                            onChange={handleChange}
                                                            required
                                                            fullWidth
                                                            type={"text"}
                                                            error={bannerUrlErrFlag}
                                                            margin="none"
                                                            variant="outlined"
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label={bannerUrlPrompt}
                                                                        onClick={selectMediaAction}
                                                                    >
                                                                        <ImageSearchIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                        <FormHelperText>{bannerUrlErrFlag ? bannerUrlErrMsg : ""}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>
                                                        
                                        <Grid item xs={12} sm={12}>
                                            {bannerUrl &&
                                                <Grid container justify="center">
                                                    <img src={bannerUrl} style={{height: '96px'}} />
                                                </Grid>
                                            }           
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={pageHeaderErrFlag}>
                                                        <label htmlFor="pageHeaderId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={pageHeaderErrFlag ? "secondary" : "inherit"}>
                                                                {pageHeaderLabel}
                                                            </Typography>
                                                        </label>

                                                        <FroalaCommonEditor
                                                            editorId={"pageHeaderId"}
                                                            editorRef={editorRef2}
                                                            // editorType={"minimal"} // Full Editor when not set
                                                            placeholderPrompt={pageHeaderPrompt}
                                                            editorMaxChars={editorMaxChars}
                                                            editorModel={pageHeader}
                                                            modelChangeCallback={handleEditorContentChange}
                                                            language={language}
                                                            domain={domain}
                                                            editorReadyCallback={editorReadyCallback}
                                                            forUserOnly={forUserOnly}
                                                        />
                                                        <FormHelperText>{pageHeaderErrFlag ? pageHeaderErrMsg : pageHeaderMsg}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={buttonFormAction}>
                                                    {editModeFlag ?
                                                    <>
                                                        <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                        {updateWebPageLabel} 
                                                    </>
                                                    : 
                                                    <>
                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                        {createWebPageLabel}
                                                    </>
                                                    }
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                {uploadFlag && 
                                    <>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-end">
                                                <Tooltip title={closeLabel} placement="bottom">
                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeUploadForm}>
                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-start" className={clsx(classes.divLeftPadding)}>
                                                <Typography variant="h6" color="primary">
                                                    {uploadFilesLabel}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={8} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <DomainUploadCapture 
                                                showHeadersFlag={false}
                                                forUserOnly={forUserOnly}
                                            />
                                        </Grid>
                                    </>
                                }

                                {convertFlag &&
                                    <>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-end">
                                                <Tooltip title={closeLabel} placement="bottom">
                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-start" className={clsx(classes.divLeftPadding)}>
                                                <Typography variant="h6" color="primary">
                                                    {convertWebPagesLabel}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={16} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <WebPageConverter 
                                                showHeadersFlag={false}
                                                referrer={"ManageWebPages"}
                                            />
                                        </Grid>
                                    </>
                                }

                                <Grid item xs={12} sm={12}>
                                    <LineSpacing value={18} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                {/* </StyledPaper> */}
            </div>

            <Grid item xs={12} sm={12}>
                <LineSpacing value={48} />
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

export default ManageWebPagesMv;
