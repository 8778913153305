import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";

// AppContext
import AppContext from "../../config/AppContext";

import UserProfile from "../secure/UserProfile";
import SetupLanguages from "../../components/misc/SetupLanguages";
import Logo from "../../components/misc/Logo";

const PageContainer = styled.span`
  width: 100%;

  right: 0;
  top: 0;
  left: 0;
`;

const TopDiv = styled.div`
  padding: 0 0 0 0;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
  },
  logo: {
    flexGrow: 1,
  },
  mainContainer: {
    width: "100%",
    display: "flex",
  },
  containerChild: {
    width: "100%",
    flex: 1,
  },
}));

function PageTopHeader(props) {
  const appContext = useContext(AppContext);

  // const [direction, setDirection] = useState(appContext.direction);
  // const [enableRtl, setEnableRtl] = useState(direction === 'rtl');
  // const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);

  const classes = useStyles();
  const { direction, currentLanguage } = props;

  // const directionAlignment = {
  //   textAlign: appContext.direction === 'ltr' ? 'left' : 'right',
  // };

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  const {
    isSignedIn,
    langPopupId,
    langPopupOpen,
    langAnchorEl,
    handleLangClicked,
    handleLangClose,
    langRef,
    currentLanguageName,
    languages,
    languageChange,
    languageSelectChange,

    profilePopupId,
    profilePopupOpen,
    profileAnchorEl,
    handleProfileClicked,
    handleProfileClose,
    profileClicked,
    signedInCallback,
    currLanguage,
  } = props;

  useEffect(() => {
    // console.log('In PageTopHeader(): useEffect(): direction is: ', direction);
  }, []);

  return (
    <PageContainer className={classes.root}>
      <Toolbar>
        <Grid container className={classes.mainContainer}>
          <Grid item className={classes.containerChild}>
            {/*direction === 'ltr' &&
              <span className={classes.logo} style={{float: 'left'}}><Logo /></span>
            }
            {direction === 'rtl' &&
              <span className={classes.logo} style={{float: 'right'}}><Logo /></span>
          */}

            <span className={classes.logo} style={directionFloat}>
              <Logo />
            </span>
          </Grid>

          <Grid item className={classes.containerChild}>
            <TopDiv style={directionReverseFloat}>
              <SetupLanguages
                langPopupId={langPopupId}
                langPopupOpen={langPopupOpen}
                langAnchorEl={langAnchorEl}
                handleLangClicked={handleLangClicked}
                handleLangClose={handleLangClose}
                langRef={langRef}
                currentLanguage={currentLanguage}
                currentLanguageName={currentLanguageName}
                languages={languages}
                languageSelectChange={languageSelectChange}
              />

              <UserProfile
                profilePopupId={profilePopupId}
                profilePopupOpen={profilePopupOpen}
                profileAnchorEl={profileAnchorEl}
                handleProfileClicked={handleProfileClicked}
                handleProfileClose={handleProfileClose}
                profileClicked={profileClicked}
                signedInCallback={signedInCallback}
                currLanguage={appContext.currentLanguage}
              />
            </TopDiv>
          </Grid>
        </Grid>
      </Toolbar>
    </PageContainer>
  );
}

export default PageTopHeader;
