import React from "react";

import Home from "../../pages/home/Home";
import SignIn from "../../pages/secure/SignIn";
import SignOut from "../../pages/secure/SignOut";
import SignUp from "../../pages/secure/SignUp";
import ConfirmAttributeCode from "../../pages/secure/ConfirmAttributeCode";
import ConfirmVerificationCode from "../../pages/secure/ConfirmVerificationCode";

import Main from "../../pages/main/Main";
import PlayVideo from "../../pages/main/PlayVideo";
import EditorTest from "../../pages/main/EditorTest";
import LogoTest from "../../pages/main/LogoTest";
import CardsExamples from "../../pages/main/CardsExamples";

import Settings from "../../pages/settings/Settings";
import ChangePassword from "../../pages/secure/ChangePassword";
import CapturePhoto from "../../pages/secure/CapturePhoto";

import CreateLanguages from "../../pages/languages/CreateLanguages";
import ManageLanguages from "../../pages/languages/ManageLanguages";
import ViewLanguages from "../../pages/languages/ViewLanguages";

import CreateDataLanguages from "../../pages/languages/CreateDataLanguages";
import ManageDataLanguages from "../../pages/languages/ManageDataLanguages";
import ConvertDataLanguages from "../../pages/languages/ConvertDataLanguages";
import ViewDataLanguages from "../../pages/languages/ViewDataLanguages";

import CreateResources from "../../pages/resources/CreateResources";
import ManageResources from "../../pages/resources/ManageResources";
import ConvertResources from "../../pages/resources/ConvertResources";
import ViewResources from "../../pages/resources/ViewResources";

import CreateReferenceData from "../../pages/references/CreateReferenceData";
import ManageReferenceData from "../../pages/references/ManageReferenceData";
import ViewReferenceData from "../../pages/references/ViewReferenceData";
import ConvertReferenceData from "../../pages/references/ConvertReferenceData";

import CreateSocialNetworks from "../../pages/socials/CreateSocialNetworks";
import ManageSocialNetworks from "../../pages/socials/ManageSocialNetworks";
import ViewSocialNetworks from "../../pages/socials/ViewSocialNetworks";
import ConvertSocialNetworks from "../../pages/socials/ConvertSocialNetworks";
import AppsInfo from "../../pages/socials/AppsInfo";

import CreateReferenceTypes from "../../pages/referenceTypes/CreateReferenceTypes";
import ManageReferenceTypes from "../../pages/referenceTypes/ManageReferenceTypes";
import ViewReferenceTypes from "../../pages/referenceTypes/ViewReferenceTypes";
import ConvertReferenceTypes from "../../pages/referenceTypes/ConvertReferenceTypes";

import CreateContactInfo from "../../pages/contactInfo/CreateContactInfo";
import ManageContactInfo from "../../pages/contactInfo/ManageContactInfo";
import ViewContactInfo from "../../pages/contactInfo/ViewContactInfo";
import ConvertContactInfo from "../../pages/contactInfo/ConvertContactInfo";
import GeocodeContactAddr from "../../pages/contactInfo/GeocodeContactAddr";
import BusinessHours from "../../pages/contactInfo/BusinessHours";

import CustomerService from "../../pages/support/CustomerService";
import CustomerService_old from "../../pages/support/CustomerService_old";
import BlogCapture from "../../pages/blogs/BlogCapture";
import ManagePriceHeaders from "../../pages/pricing/ManagePriceHeaders";
import ManagePriceList from "../../pages/pricing/ManagePriceList";
import ManageFaqs from "../../pages/faqs/ManageFaqs";
import PostJobs from "../../pages/jobs/PostJobs";
import PostNews from "../../pages/news/PostNews";

import PricingPage from "../../pages/pricing/PricingPage";

import ManageWebPages from "../../pages/webPages/ManageWebPages";
import ManagePageSections from "../../pages/webPages/ManagePageSections";

import ManagePageContent from "../../pages/pageContent/ManagePageContent";
import ConvertPageContent from "../../pages/pageContent/ConvertPageContent";
import DeletePageContent from "../../pages/pageContent/DeletePageContent";

import ManageDesignBlocks from "../../pages/designBlocks/ManageDesignBlocks";
import ManageDesignBlockDetails from "../../pages/designBlocks/ManageDesignBlockDetails";

// import ConvertDesignBlocks from "../../pages/designBlocks/ConvertDesignBlocks";
// import ConvertDesignBlockDetails from "../../pages/designBlocks/ConvertDesignBlockDetails";
// import DeleteDesignBlocks from "../../pages/designBlocks/DeleteDesignBlocks";

import Terms from "../../pages/footers/Terms";
import Privacy from "../../pages/footers/Privacy";
import Security from "../../pages/footers/Security";
import PlatformAgreement from "../../pages/footers/PlatformAgreement";

// Products
import Product1 from "../../pages/footers/products/Product1";
import Product2 from "../../pages/footers/products/Product2";
import Product3 from "../../pages/footers/products/Product3";
import Product4 from "../../pages/footers/products/Product4";
import Product5 from "../../pages/footers/products/Product5";
import Product6 from "../../pages/footers/products/Product6";
import Product7 from "../../pages/footers/products/Product7";
import Product8 from "../../pages/footers/products/Product8";
import Product9 from "../../pages/footers/products/Product9";
import Product10 from "../../pages/footers/products/Product10";

// services
import Service1 from "../../pages/footers/services/Service1";
import Service2 from "../../pages/footers/services/Service2";
import Service3 from "../../pages/footers/services/Service3";
import Service4 from "../../pages/footers/services/Service4";
import Service5 from "../../pages/footers/services/Service5";
import Service6 from "../../pages/footers/services/Service6";
import Service7 from "../../pages/footers/services/Service7";
import Service8 from "../../pages/footers/services/Service8";
import Service9 from "../../pages/footers/services/Service9";
import Service10 from "../../pages/footers/services/Service10";

// Solutions
import Solution1 from "../../pages/footers/solutions/Solution1";
import Solution2 from "../../pages/footers/solutions/Solution2";
import Solution3 from "../../pages/footers/solutions/Solution3";
import Solution4 from "../../pages/footers/solutions/Solution4";
import Solution5 from "../../pages/footers/solutions/Solution5";
import Solution6 from "../../pages/footers/solutions/Solution6";
import Solution7 from "../../pages/footers/solutions/Solution7";
import Solution8 from "../../pages/footers/solutions/Solution8";
import Solution9 from "../../pages/footers/solutions/Solution9";
import Solution10 from "../../pages/footers/solutions/Solution10";

// Company
import Company1 from "../../pages/footers/company/Company1";
import Company2 from "../../pages/footers/company/Company2";
import Company3 from "../../pages/footers/company/Company3";
import Company4 from "../../pages/footers/company/Company4";
import Company5 from "../../pages/footers/company/Company5";
import Company6 from "../../pages/footers/company/Company6";
import Company7 from "../../pages/footers/company/Company7";
import Company8 from "../../pages/footers/company/Company8";
import Company9 from "../../pages/footers/company/Company9";
import Company10 from "../../pages/footers/company/Company10";

// ContactUs
import ContactUs1 from "../../pages/footers/contact-us/ContactUs1";
import ContactUs2 from "../../pages/footers/contact-us/ContactUs2";
import ContactUs3 from "../../pages/footers/contact-us/ContactUs3";
import ContactUs4 from "../../pages/footers/contact-us/ContactUs4";
import ContactUs5 from "../../pages/footers/contact-us/ContactUs5";

// Menus No Sub Menus
import MenuNoSub1 from "../../pages/menus/menuNoSub/MenuNoSub1";
import MenuNoSub2 from "../../pages/menus/menuNoSub/MenuNoSub2";
import MenuNoSub3 from "../../pages/menus/menuNoSub/MenuNoSub3";
import MenuNoSub4 from "../../pages/menus/menuNoSub/MenuNoSub4";
import MenuNoSub5 from "../../pages/menus/menuNoSub/MenuNoSub5";
import MenuNoSub6 from "../../pages/menus/menuNoSub/MenuNoSub6";
import MenuNoSub7 from "../../pages/menus/menuNoSub/MenuNoSub7";

// Menu1
import SubMenu11 from "../../pages/menus/menu1/SubMenu11";
import SubMenu12 from "../../pages/menus/menu1/SubMenu12";
import SubMenu13 from "../../pages/menus/menu1/SubMenu13";
import SubMenu14 from "../../pages/menus/menu1/SubMenu14";
import SubMenu15 from "../../pages/menus/menu1/SubMenu15";

// Menu2
import SubMenu21 from "../../pages/menus/menu2/SubMenu21";
import SubMenu22 from "../../pages/menus/menu2/SubMenu22";
import SubMenu23 from "../../pages/menus/menu2/SubMenu23";
import SubMenu24 from "../../pages/menus/menu2/SubMenu24";
import SubMenu25 from "../../pages/menus/menu2/SubMenu25";

// Menu3
import SubMenu31 from "../../pages/menus/menu3/SubMenu31";
import SubMenu32 from "../../pages/menus/menu3/SubMenu32";
import SubMenu33 from "../../pages/menus/menu3/SubMenu33";
import SubMenu34 from "../../pages/menus/menu3/SubMenu34";
import SubMenu35 from "../../pages/menus/menu3/SubMenu35";

// Menu4
import SubMenu41 from "../../pages/menus/menu4/SubMenu41";
import SubMenu42 from "../../pages/menus/menu4/SubMenu42";
import SubMenu43 from "../../pages/menus/menu4/SubMenu43";
import SubMenu44 from "../../pages/menus/menu4/SubMenu44";
import SubMenu45 from "../../pages/menus/menu4/SubMenu45";

// Menu5
import SubMenu51 from "../../pages/menus/menu5/SubMenu51";
import SubMenu52 from "../../pages/menus/menu5/SubMenu52";
import SubMenu53 from "../../pages/menus/menu5/SubMenu53";
import SubMenu54 from "../../pages/menus/menu5/SubMenu54";
import SubMenu55 from "../../pages/menus/menu5/SubMenu55";

// Menu6
import SubMenu61 from "../../pages/menus/menu6/SubMenu61";
import SubMenu62 from "../../pages/menus/menu6/SubMenu62";
import SubMenu63 from "../../pages/menus/menu6/SubMenu63";
import SubMenu64 from "../../pages/menus/menu6/SubMenu64";
import SubMenu65 from "../../pages/menus/menu6/SubMenu65";

// Menu7
import SubMenu71 from "../../pages/menus/menu7/SubMenu71";
import SubMenu72 from "../../pages/menus/menu7/SubMenu72";
import SubMenu73 from "../../pages/menus/menu7/SubMenu73";
import SubMenu74 from "../../pages/menus/menu7/SubMenu74";
import SubMenu75 from "../../pages/menus/menu7/SubMenu75";

// File Upload
import FilesUploadToS3 from "../upload/FilesUploadToS3";

// Some folks find value in a centralized route config.
// A route config is just data. React is great at mapping
// data into components, and <Route> is a component.

// Our route config is just an array of logical "routes"
// with `path` and `component` props, ordered the same
// way you'd do inside a `<Switch>`.
const appRoutes = [
  { path: "/", component: Home },
  { path: "/home", component: Home },
  { path: "/signIn", component: SignIn },
  { path: "/signOut", component: SignOut },
  { path: "/signUp", component: SignUp },
  { path: "/confirmAttributeCode", component: ConfirmAttributeCode },
  { path: "/confirmVerificationCode", component: ConfirmVerificationCode },

  { path: "/main", component: Main },
  { path: "/playVideo", component: PlayVideo },
  { path: "/editor", component: EditorTest },
  { path: "/logos", component: LogoTest },
  { path: "/cardsExamples", component: CardsExamples },

  { path: "/settings", component: Settings },
  { path: "/changePassword", component: ChangePassword },
  { path: "/capturePhoto", component: CapturePhoto },

  { path: "/createLanguages", component: CreateLanguages },
  { path: "/manageLanguages", component: ManageLanguages },
  { path: "/viewLanguages", component: ViewLanguages },

  { path: "/createDataLanguages", component: CreateDataLanguages },
  { path: "/manageDataLanguages", component: ManageDataLanguages },
  { path: "/convertDataLanguages", component: ConvertDataLanguages },
  { path: "/viewDataLanguages", component: ViewDataLanguages },

  { path: "/createResources", component: CreateResources },
  { path: "/manageResources", component: ManageResources },
  { path: "/convertResources", component: ConvertResources },
  { path: "/viewResources", component: ViewResources },

  { path: "/createReferenceData", component: CreateReferenceData },
  { path: "/manageReferenceData", component: ManageReferenceData },
  { path: "/viewReferenceData", component: ViewReferenceData },
  { path: "/convertReferenceData", component: ConvertReferenceData },

  { path: "/createSocialNetworks", component: CreateSocialNetworks },
  { path: "/manageSocialNetworks", component: ManageSocialNetworks },
  { path: "/viewSocialNetworks", component: ViewSocialNetworks },
  { path: "/convertSocialNetworks", component: ConvertSocialNetworks },
  { path: "/appsInfo", component: AppsInfo },

  { path: "/createReferenceTypes", component: CreateReferenceTypes },
  { path: "/manageReferenceTypes", component: ManageReferenceTypes },
  { path: "/viewReferenceTypes", component: ViewReferenceTypes },
  { path: "/convertReferenceTypes", component: ConvertReferenceTypes },

  { path: "/createContactInfo", component: CreateContactInfo },
  { path: "/manageContactInfo", component: ManageContactInfo },
  { path: "/viewContactInfo", component: ViewContactInfo },
  { path: "/convertContactInfo", component: ConvertContactInfo },
  { path: "/geocodeContactAddr", component: GeocodeContactAddr },
  { path: "/businessHours", component: BusinessHours },

  { path: "/customerService", component: CustomerService },
  { path: "/cs", component: CustomerService_old },
  { path: "/blogCapture", component: BlogCapture },
  { path: "/priceHeader", component: ManagePriceHeaders },
  { path: "/priceList", component: ManagePriceList },
  { path: "/faqs", component: ManageFaqs },
  { path: "/jobs", component: PostJobs },
  { path: "/news", component: PostNews },

  { path: "/pricing", component: PricingPage },

  { path: "/webPages", component: ManageWebPages },
  { path: "/pageSections", component: ManagePageSections },
    
  { path: "/managePageContent", component: ManagePageContent },
  { path: "/convertPageContent", component: ConvertPageContent },
  { path: "/deletePageContent", component: DeletePageContent },

  { path: "/designBlocks", component: ManageDesignBlocks },
  { path: "/designDetails", component: ManageDesignBlockDetails },

  // { path: "/convertDesignBlocks", component: ConvertDesignBlocks },
  // { path: "/convertDesignBlockDetails", component: ConvertDesignBlockDetails },
  // { path: "/deleteDesignBlocks", component: DeleteDesignBlocks },

  { path: "/terms", component: Terms },
  { path: "/privacy", component: Privacy },
  { path: "/security", component: Security },
  { path: "/platformAgreement", component: PlatformAgreement },

  /* Products */
  { path: "/product1", component: Product1 },
  { path: "/product2", component: Product2 },
  { path: "/product3", component: Product3 },
  { path: "/product4", component: Product4 },
  { path: "/product5", component: Product5 },
  { path: "/product6", component: Product6 },
  { path: "/product7", component: Product7 },
  { path: "/product8", component: Product8 },
  { path: "/product9", component: Product9 },
  { path: "/product10", component: Product10 },

  /* Services */
  { path: "/service1", component: Service1 },
  { path: "/service2", component: Service2 },
  { path: "/service3", component: Service3 },
  { path: "/service4", component: Service4 },
  { path: "/service5", component: Service5 },
  { path: "/service6", component: Service6 },
  { path: "/service7", component: Service7 },
  { path: "/service8", component: Service8 },
  { path: "/service9", component: Service9 },
  { path: "/service10", component: Service10 },

  /* Solutions */
  { path: "/solution1", component: Solution1 },
  { path: "/solution2", component: Solution2 },
  { path: "/solution3", component: Solution3 },
  { path: "/solution4", component: Solution4 },
  { path: "/solution5", component: Solution5 },
  { path: "/solution6", component: Solution6 },
  { path: "/solution7", component: Solution7 },
  { path: "/solution8", component: Solution8 },
  { path: "/solution9", component: Solution9 },
  { path: "/solution10", component: Solution10 },

  /* Company */
  { path: "/company1", component: Company1 },
  { path: "/company2", component: Company2 },
  { path: "/company3", component: Company3 },
  { path: "/company4", component: Company4 },
  { path: "/company5", component: Company5 },
  { path: "/company6", component: Company6 },
  { path: "/company7", component: Company7 },
  { path: "/company8", component: Company8 },
  { path: "/company9", component: Company9 },
  { path: "/company10", component: Company10 },

  /* ContactUs */
  { path: "/contactUs1", component: ContactUs1 },
  { path: "/contactUs2", component: ContactUs2 },
  { path: "/contactUs3", component: ContactUs3 },
  { path: "/contactUs4", component: ContactUs4 },
  { path: "/contactUs5", component: ContactUs5 },

  /* MenuNoSub */
  { path: "/menuNoSub1", component: MenuNoSub1 },
  { path: "/menuNoSub2", component: MenuNoSub2 },
  { path: "/menuNoSub3", component: MenuNoSub3 },
  { path: "/menuNoSub4", component: MenuNoSub4 },
  { path: "/menuNoSub5", component: MenuNoSub5 },
  { path: "/menuNoSub6", component: MenuNoSub6 },
  { path: "/menuNoSub7", component: MenuNoSub7 },

  /* Menu1 Sub Menus */
  { path: "/subMenu11", component: SubMenu11 },
  { path: "/subMenu12", component: SubMenu12 },
  { path: "/subMenu13", component: SubMenu13 },
  { path: "/subMenu14", component: SubMenu14 },
  { path: "/subMenu15", component: SubMenu15 },

  /* Menu2 Sub Menus */
  { path: "/subMenu21", component: SubMenu21 },
  { path: "/subMenu22", component: SubMenu22 },
  { path: "/subMenu23", component: SubMenu23 },
  { path: "/subMenu24", component: SubMenu24 },
  { path: "/subMenu25", component: SubMenu25 },

  /* Menu3 Sub Menus */
  { path: "/subMenu31", component: SubMenu31 },
  { path: "/subMenu32", component: SubMenu32 },
  { path: "/subMenu33", component: SubMenu33 },
  { path: "/subMenu34", component: SubMenu34 },
  { path: "/subMenu35", component: SubMenu35 },

  /* Menu4 Sub Menus */
  { path: "/subMenu41", component: SubMenu41 },
  { path: "/subMenu42", component: SubMenu42 },
  { path: "/subMenu43", component: SubMenu43 },
  { path: "/subMenu44", component: SubMenu44 },
  { path: "/subMenu45", component: SubMenu45 },

  /* Menu5 Sub Menus */
  { path: "/subMenu51", component: SubMenu51 },
  { path: "/subMenu52", component: SubMenu52 },
  { path: "/subMenu53", component: SubMenu53 },
  { path: "/subMenu54", component: SubMenu54 },
  { path: "/subMenu55", component: SubMenu55 },

  /* Menu6 Sub Menus */
  { path: "/subMenu61", component: SubMenu61 },
  { path: "/subMenu62", component: SubMenu62 },
  { path: "/subMenu63", component: SubMenu63 },
  { path: "/subMenu64", component: SubMenu64 },
  { path: "/subMenu65", component: SubMenu65 },

  /* Menu7 Sub Menus */
  { path: "/subMenu71", component: SubMenu71 },
  { path: "/subMenu72", component: SubMenu72 },
  { path: "/subMenu73", component: SubMenu73 },
  { path: "/subMenu74", component: SubMenu74 },
  { path: "/subMenu75", component: SubMenu75 },

  /* File Upload */
  { path: "/upload", component: FilesUploadToS3 },

  // {
  //     path: "/signIn",
  //     component: SignIn,
  //     routes: [
  //     {
  //         path: "/manageLanguages",
  //         component: ManageLanguages
  //     },
  //     {
  //         path: "/viewLanguages",
  //         component: ViewLanguages
  //     }
  //     ]
  // }
];

export default appRoutes;
