import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import SaveIcon from "@material-ui/icons/Save";
import CircularProgress from "@material-ui/core/CircularProgress";
// import AddIcon from '@material-ui/icons/Add';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from "@material-ui/core/MenuItem";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
// import FormHelperText from '@material-ui/core/FormHelperText';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import Box from '@material-ui/core/Box';
// import ImageSearchIcon from '@material-ui/icons/ImageSearch';

// Lodash
import lodash from "lodash";

// Phone
// import ReactPhoneInput from 'react-phone-input-2';
// import "react-phone-input-2/lib/style.css";

// Moment
import moment from "moment";
// import Moment from 'react-moment';
// import 'moment-timezone';
import "moment/locale/ar";
import "moment/locale/he";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/ko";
import "moment/locale/fr";
import "moment/locale/zh-cn";

// Validator
// import validator from 'validator';
// import isEmail from 'validator/lib/isEmail';
// import isURL from 'validator/lib/isURL';
// import isMobilePhone from 'validator/lib/isMobilePhone';

// react-datetime-picker, react-time-picker and react-date-picker
// import DatePicker from 'react-date-picker';
// import DateTimePicker from 'react-datetime-picker';
// import TimePicker from 'react-time-picker'

// Froala Editor
// import "froala-editor/css/froala_style.min.css";
// import "froala-editor/css/froala_editor.pkgd.min.css";

// import FroalaEditor from "react-froala-wysiwyg";
// import "froala-editor/js/plugins.pkgd.min.js";

// AG-Grid
import { AgGridReact, AgGridColumn } from "ag-grid-react";
//import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

// Amplify
import Amplify, { API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// ReducerContext
import ReducerContext from "../../config/ReducerContext";

import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import LineSpacing from "../../components/misc/LineSpacing";
import ImportEditorLanguageFile from "../../components/editor/ImportEditorLanguageFile";

import ImagesDraggableDialog from "../../components/dialogs/ImagesDraggableDialog";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GlobalConstants from "../../utils/GlobalConstants";
import GenerateUniqueName from "../../utils/GenerateUniqueName";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// Renderers
import DateCellRenderer from "../../components/renderers/DateCellRenderer";
// import NumberCellRenderer from '../../components/renderers/NumberCellRenderer';
import ActionsCellRenderer from "../../components/renderers/ActionsCellRenderer";
import FroalaCommonEditor from "../../components/editor/FroalaCommonEditor";

import HorizontalRule from "../../components/misc/HorizontalRule";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  width: "100%";
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    // backgroundColor: '#fff',
    backgroundImage: `url(${"assets/logos/background21.png"})`,
  },
  main: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(12),
  },
  mainForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    // backgroundColor: '#fff',
  },
  mainGrid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  footer: {
    // position: 'absolute',
    bottom: 0,
    height: "1rem" /* Footer height */,
    width: "100% !important",
    // marginTop: 'auto',
    textAlign: "center",
    // position: 'absolute',
    // bottom: 0,
    // width: '100%',
    // height: '1rem',           /* Footer height */

    // width: '100% !important',
    // marginTop: 'auto',
    // // backgroundColor: '#fff',
    // textAlign: 'center',
  },
  textField: {
    width: "100%",
  },
  gridHeader: {
    fontWeight: "bold",
    textAlign: "center !important",
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  formControlLabel: {
    display: "flex",
    textAlign: "left",
    verticalAlign: "middle",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  browseIcon: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  // phone: {
  //   flexGrow: 1,
  //   width: '100% !important',
  //   display: 'flex',
  //   flexDirection: 'row',
  // },
  normalVariant: {
    color: "#000",
    textAlign: "center",
  },
  errVariant: {
    color: "red",
    textAlign: "center",
  },
  gridItem: {
    textAlign: "left",
    marginTop: "2px",
  },
  date: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "row",
    borderRadius: "10px",
    fontSize: "13px",
  },
  calendarPopup: {
    zIndex: 99999,
    opacity: 1,
    // background: 'red',
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  verticalAlignLabel: {
    // textAlign: 'left',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  phone: {
    height: "42px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  selectEmpty: {
    // marginTop: theme.spacing.unit * 2,
  },
  bottomMargin: {
    marginBottom: theme.spacing(1),
  },
}));

let localeText = {
  page: "Page",
  to: "to",
  of: "of",
  noRowsToShow: "No records found",
};

// let localeText2 = {
//   page: "daPage",
//   more: "daMore",
//   to: "daTo",
//   of: "daOf",
//   next: "daNexten",
//   last: "daLasten",
//   first: "daFirsten",
//   previous: "daPreviousen",
//   loadingOoo: "daLoading...",
//   selectAll: "daSelect Allen",
//   searchOoo: "daSearch...",
//   blanks: "daBlanc",
//   filterOoo: "daFilter...",
//   applyFilter: "daApplyFilter...",
//   equals: "daEquals",
//   notEqual: "daNotEqual",
//   lessThan: "daLessThan",
//   greaterThan: "daGreaterThan",
//   lessThanOrEqual: "daLessThanOrEqual",
//   greaterThanOrEqual: "daGreaterThanOrEqual",
//   inRange: "daInRange",
//   contains: "daContains",
//   notContains: "daNotContains",
//   startsWith: "daStarts dawith",
//   endsWith: "daEnds dawith",
//   andCondition: "daAND",
//   orCondition: "daOR",
//   group: "laGroup",
//   columns: "laColumns",
//   filters: "laFilters",
//   rowGroupColumns: "laPivot Cols",
//   rowGroupColumnsEmptyMessage: "la drag cols to group",
//   valueColumns: "laValue Cols",
//   pivotMode: "laPivot-Mode",
//   groups: "laGroups",
//   values: "laValues",
//   pivots: "laPivots",
//   valueColumnsEmptyMessage: "la drag cols to aggregate",
//   pivotColumnsEmptyMessage: "la drag here to pivot",
//   toolPanelButton: "la tool panel",
//   noRowsToShow: "la no rows",
//   pinColumn: "laPin Column",
//   valueAggregation: "laValue Agg",
//   autosizeThiscolumn: "laAutosize Diz",
//   autosizeAllColumns: "laAutsoie em All",
//   groupBy: "laGroup by",
//   ungroupBy: "laUnGroup by",
//   resetColumns: "laReset Those Cols",
//   expandAll: "laOpen-em-up",
//   collapseAll: "laClose-em-up",
//   toolPanel: "laTool Panelo",
//   export: "laExporto",
//   csvExport: "la CSV Exportp",
//   excelExport: "la Excel Exporto (.xlsx)",
//   excelXmlExport: "la Excel Exporto (.xml)",
//   pinLeft: "laPin &lt;&lt;",
//   pinRight: "laPin &gt;&gt;",
//   noPin: "laDontPin &lt;&gt;",
//   sum: "laSum",
//   min: "laMin",
//   max: "laMax",
//   none: "laNone",
//   count: "laCount",
//   average: "laAverage",
//   copy: "laCopy",
//   copyWithHeaders: "laCopy Wit hHeaders",
//   ctrlC: "ctrl n C",
//   paste: "laPaste",
//   ctrlV: "ctrl n V"
// };

// For AG-Grid

const gridRowsData = [];

//------------------------------
// RENDERERS
//------------------------------
const gridOptions = {
  style: "currency", // decimal, currency, percent
  currency: "USD",
  currencyDisplay: "symbol", // symbol, code, name to use a localized currency name such as "dollar"
  useGrouping: true,
  minimumIntegerDigits: 21,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  payload: {},

  weekday: "short", // long, short
  year: "numeric",
  month: "short", // long, short
  day: "numeric",
  // timeZone: 'America/New_York', // Auto calculated in DateCellRenderer
  timeZoneName: "short", // long, short
  hour12: true,
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  locale: "default", // To use the browser's default locale, use 'default'
  // getRowNodeId: function(item) {
  //   return item.id;
  // },

  getRowHeight: function (params) {
    // console.log("In getRowHeight: params is: ", params);
    // assuming 50 characters per line, working how how many lines we need
    // console.log("In getRowHeight: words is: ", params.data.StartDate.length);
    let lines = 36;
    // let lines = 36 * (Math.floor(params.data.PageSK.length / 45) + 1);
    // console.log("In getRowHeight: lines is: ", lines);
    return lines;
  },
};

let manageContent = {
  managePageContentLabel: "Manage page content",
  requiredFields: "* Required fields",
  submit: "Submit",
  editLabel: "Edit",

  languageNameLabel: "Language",
  selectLanguage: "Please select a language",
  formLanguageErrMsg: "Language name is a required field",

  pageName: "Page name",
  selectPageName: "Please select a page name",
  pageNameErrMsg: "Page name is a required field",

  newPageName: "New page name",
  selectNewPageName: "Please select new page name",

  startDateLabel: "Start date",
  startDatePrompt: "Please enter page start date",
  startDateErrMsg: "Page start date is invalid",

  endDateLabel: "End date",
  endDatePrompt: "Please enter page end date",
  endDateErrMsg: "Page end date is invalid",
  startEndDateErrMsg: "End date must be greater or equal to start date",

  pageId: "Page ID",
  pageHeaderLabel: "Page header",
  pageHeaderErrMsg: "Page header is a required field",

  pageSubHeaderLabel: "Page sub header",
  pageSubHeaderErrMsg: "Sub page header is invalid",

  pageCategoryLabel: "Page category",
  pageCategoryPrompt: "Please select a page category",
  pageCategoryErrMsg: "You must select a page category",

  pageTagsLabel: "Page tags",
  pageTagsPrompt: "Please enter page tags (separated by commas)",
  pageTagsErrMsg: "Page tag is required",

  pageContentSuccessMsg: "Page content update was successful.",
  pageContentFailureMsg: "Page content update failed.",
  contentCreateSuccessMsg: "Page content creation was successful.",
  contentCreateFailureMsg: "Page content creation failed.",
  pageContentUpdateErrMsg: "Please correct any invalid entries.",

  pageContentLabel: "Page content",
  pageContentPrompt: "Please enter page content",
  pageContentErrMsg: "Page content is a required field",

  editorPrompt: "Please enter editor content here",

  modifiedBy: "Modified by",
  createdAt: " Create date",
  updatedAt: "Modified date",

  createNewContent: "Create new page content",
  cancelLabel: "Cancel",
  clearLabel: "Clear",
  okLabel: "Ok",

  pageContentSettings: "Settings",
  pageStepContent: "Content",
  pageStepHeader: "Header",
  pageStepSubHeader: "Sub header",

  next: "Next",
  previous: "Previous",
  allStepsCompleted: "All steps completed",
  reset: "Reset",

  selectEditPage: "You may select a page to edit it",

  // For Grid
  gridPage: "Page",
  gridTo: "to",
  gridOf: "of",
  noRowsToShow: "No records found",
};

function ManagePageContent(props) {
  const appContext = useContext(AppContext);
  const reducerContext = useContext(ReducerContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  // const [ dateLocale, setDateLocale ] = useState(appContext.currentLanguage);
  const [datePickerLanguage, setDatePickerLanguage] = useState(
    appContext.currentLanguage.languageCode
  );

  // const defaultLang = {
  //   value: appContext.currentLanguage.languageCode,
  //   text: appContext.currentLanguage.languageName
  // };
  // const langSelectPrompt = {
  //   value: "",
  //   text: "Select a language"
  // }
  const [formLanguage, setFormLanguage] = useState("");
  const [selectedPageName, setSelectedPageName] = useState("");
  const [selectedNewPageName, setSelectedNewPageName] = useState("");
  const [pageHdrMediaUrl, setPageHdrMediaUrl] = useState("");

  const [selectedPagePK, setSelectedPagePK] = useState("");
  const [selectedPageSK, setSelectedPageSK] = useState("");
  const [selectedModifiedBy, setSelectedModifiedBy] = useState("");
  const [selectedCreatedAt, setSelectedCreatedAt] = useState("");
  // const [startDate, setStartDate] = useState(new moment().format('L'));
  // const [endDate, setEndDate] = useState(new moment().format('L'));
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [selectedPageCategory, setSelectedPageCategory] = useState(null);
  const [pageTags, setPageTags] = useState(null);

  // For Grid
  const [gridData, setGridData] = useState(gridRowsData);
  // const [gridFilterValue, setGridFilterValue] = useState("");
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [currentEditRow, setCurrentEditRow] = useState(null); // Current row being edited
  const [editRowIndex, setEditRowIndex] = useState(-1);

  // AG-Grid
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [progressFlag, setProgressFlag] = useState(false);
  const [rowSelectedFlag, setRowSelectedFlag] = useState(false);
  const [gridSubmitFlag, setGridSubmitFlag] = useState(false);
  const [gridRowsCount, setGridRowsCount] = useState(0);

  const [filterDisabledFlag, setFilterDisabledFlag] = useState(true);

  // const [showPassword, setShowPassword] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  const [langSelectOptions, setLangSelectOptions] = useState([]);
  const [pageNameOptions, setPageNameOptions] = useState([]);
  const [newPageNameOptions, setNewPageNameOptions] = useState([]);
  const [contactInfoData, setContactInfoData] = useState([]);

  const [pageCategoryOptions, setPageCategoryOptions] = useState([]);

  const [selectLangChangedFlag, setSelectLangChangedFlag] = useState(true);
  const [selectPageNameChangedFlag, setSelectPageNameChangedFlag] = useState(
    false
  );

  // const [progressFlag, setProgressFlag] = useState(false);
  // const [gridUpdatedFlag, setGridUpdatedFlag] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);

  // const [filterDisabledFlag, setFilterDisabledFlag] = useState(true);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  const [modifiedGridRows, setModifiedGridRows] = useState([]);
  // const [deletedGridRows, setDeletedGridRows] = useState([]);

  // For Error Flags
  const [formLanguageErrFlag, setFormLanguageErrFlag] = useState(false);
  const [pageNameErrFlag, setPageNameErrFlag] = useState(false);

  const [startDateErrFlag, setStartDateErrFlag] = useState(false);
  const [endDateErrFlag, setEndDateErrFlag] = useState(false);

  const [pageHeaderErrFlag, setPageHeaderErrFlag] = useState(false);
  const [pageSubHeaderErrFlag, setPageSubHeaderErrFlag] = useState(false);
  const [pageContentErrFlag, setPageContentErrFlag] = useState(false);

  const [pageCategoryErrFlag, setPageCategoryErrFlag] = useState(false);
  const [pageTagsErrFlag, setPageTagsErrFlag] = useState(false);

  const [contactCodeFocus, setContactCodeFocus] = useState(false);
  const [contactDescFocus, setContactDescFocus] = useState(true);

  // const [redirectSignUpFlag, setRedirectSignUpFlag] = useState(false);
  // const [globalErrorMsg, setGlobalErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  // For Resources
  const [manageContentRes, setManageContentRes] = useState(manageContent);
  // const [prevLangCode, setPrevLangCode] = useState(appContext.currentLanguage.languageCode);

  // Variants
  // const [startDateVariant, setStartDateVariant] = useState(null);
  // const [endDateVariant, setEndtDateVariant] = useState(null);

  // For modal
  const [modalOpen, setModalOpen] = useState(false);

  // Froala Editor
  const [pageContent, setPageContent] = useState("");
  const [pageHeader, setPageHeader] = useState("");
  const [pageSubHeader, setPageSubHeader] = useState("");

  const [editorPageContentReady, setEditorPageContentReady] = useState(false);
  const [editorPageHeaderReady, setEditorPageHeaderReady] = useState(false);
  const [editorPageSubHeaderReady, setEditorPageSubHeaderReady] = useState(
    false
  );

  // For Checkbox
  const [checkboxState, setCheckboxState] = useState(false);
  const [checkboxDisabled, setCheckboxDisabled] = useState(true);
  const [newPageDisabled, setNewPageDisabled] = useState(true);

  // For Grid Resources
  const [localeTextRes, setLocaleTextRes] = useState(localeText);

  const [renderGridFlag, setRenderGridFlag] = useState(false);
  
  //--------------------
  // For Froala Editor
  //--------------------
  const editorRef = useRef(null);
  const editorRef2 = useRef(null);
  const editorRef3 = useRef(null);
  const [editorMaxChars, setEditorMaxChars] = useState(24576);// 24K
  const [renderEditorFlag, setRenderEditorFlag] = useState(false); 

  //console.log('AmplifyConfig is: ', JSON.stringify(AmplifyConfig, null, 2));
  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const marginAlignment = {
    flex: "1",
    marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
  };

  const {
    managePageContentLabel,
    requiredFields,
    languageNameLabel,
    selectLanguage,
    formLanguageErrMsg,

    pageName,
    selectPageName,
    pageNameErrMsg,

    newPageName,
    selectNewPageName,

    startDateLabel,
    startDatePrompt,
    startDateErrMsg,

    endDateLabel,
    endDatePrompt,
    endDateErrMsg,
    startEndDateErrMsg,

    pageId,
    pageHeaderLabel,
    pageSubHeaderLabel,
    pageContentLabel,
    pageContentPrompt,

    pageHeaderErrMsg,
    pageSubHeaderErrMsg,
    pageContentErrMsg,

    pageCategoryLabel,
    pageCategoryPrompt,
    pageCategoryErrMsg,

    pageTagsLabel,
    pageTagsPrompt,
    pageTagsErrMsg,

    editorPrompt,

    modifiedBy,
    createdAt,
    updatedAt,

    createNewContent,
    cancelLabel,
    clearLabel,
    okLabel,

    submit,
    editLabel,

    pageContentSettings,
    pageStepContent,
    pageStepHeader,
    pageStepSubHeader,
    next,
    previous,
    allStepsCompleted,
    reset,

    selectEditPage,

    pageContentSuccessMsg,
    pageContentFailureMsg,
    contentCreateSuccessMsg,
    contentCreateFailureMsg,
    pageContentUpdateErrMsg,
  } = manageContentRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(manageContent);
    // console.log("In ManageSocNetworkserenceData(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ManageSocNetworkserenceData(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response.data.Responses.resource is: ', JSON.stringify(response.data.Responses.resource, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "managePageContentLabel")
              manageContent.managePageContentLabel = item.ResourceDesc;
            if (item.ResourceCode === "languageNameLabel")
              manageContent.languageNameLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectLanguage")
              manageContent.selectLanguage = item.ResourceDesc;
            if (item.ResourceCode === "formLanguageErrMsg")
              manageContent.formLanguageErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "pageName")
              manageContent.pageName = item.ResourceDesc;
            if (item.ResourceCode === "selectPageName")
              manageContent.selectPageName = item.ResourceDesc;
            if (item.ResourceCode === "pageNameErrMsg")
              manageContent.pageNameErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "newPageName")
              manageContent.newPageName = item.ResourceDesc;
            if (item.ResourceCode === "selectNewPageName")
              manageContent.selectNewPageName = item.ResourceDesc;

            if (item.ResourceCode === "startDateLabel")
              manageContent.startDateLabel = item.ResourceDesc;
            if (item.ResourceCode === "startDatePrompt")
              manageContent.startDatePrompt = item.ResourceDesc;
            if (item.ResourceCode === "startDateErrMsg")
              manageContent.startDateErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "endDateLabel")
              manageContent.endDateLabel = item.ResourceDesc;
            if (item.ResourceCode === "endDatePrompt")
              manageContent.endDatePrompt = item.ResourceDesc;
            if (item.ResourceCode === "endDateErrMsg")
              manageContent.endDateErrMsg = item.ResourceDesc;
            if (item.ResourceCode === "startEndDateErrMsg")
              manageContent.startEndDateErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "pageId")
              manageContent.pageId = item.ResourceDesc;

            if (item.ResourceCode === "pageHeaderLabel")
              manageContent.pageHeaderLabel = item.ResourceDesc;
            if (item.ResourceCode === "pageHeaderErrMsg")
              manageContent.pageHeaderErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "pageSubHeaderLabel")
              manageContent.pageSubHeaderLabel = item.ResourceDesc;
            if (item.ResourceCode === "pageSubHeaderErrMsg")
              manageContent.pageSubHeaderErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "pageCategoryLabel")
              manageContent.pageCategoryLabel = item.ResourceDesc;
            if (item.ResourceCode === "pageCategoryPrompt")
              manageContent.pageCategoryPrompt = item.ResourceDesc;
            if (item.ResourceCode === "pageCategoryErrMsg")
              manageContent.pageCategoryErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "pageTagsLabel")
              manageContent.pageTagsLabel = item.ResourceDesc;
            if (item.ResourceCode === "pageTagsPrompt")
              manageContent.pageTagsPrompt = item.ResourceDesc;
            if (item.ResourceCode === "pageTagsErrMsg")
              manageContent.pageTagsErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "pageContentLabel")
              manageContent.pageContentLabel = item.ResourceDesc;
            if (item.ResourceCode === "pageContentPrompt")
              manageContent.pageContentPrompt = item.ResourceDesc;
            if (item.ResourceCode === "pageContentErrMsg")
              manageContent.pageContentErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "editorPrompt")
              manageContent.editorPrompt = item.ResourceDesc;

            if (item.ResourceCode === "modifiedBy")
              manageContent.modifiedBy = item.ResourceDesc;
            if (item.ResourceCode === "createdAt")
              manageContent.createdAt = item.ResourceDesc;
            if (item.ResourceCode === "updatedAt")
              manageContent.updatedAt = item.ResourceDesc;

            if (item.ResourceCode === "createNewContent")
              manageContent.createNewContent = item.ResourceDesc;
            if (item.ResourceCode === "cancelLabel")
              manageContent.cancelLabel = item.ResourceDesc;
            if (item.ResourceCode === "clearLabel")
              manageContent.clearLabel = item.ResourceDesc;
            if (item.ResourceCode === "okLabel")
              manageContent.okLabel = item.ResourceDesc;

            if (item.ResourceCode === "submit")
              manageContent.submit = item.ResourceDesc;
            if (item.ResourceCode === "editLabel")
              manageContent.editLabel = item.ResourceDesc;
            if (item.ResourceCode === "requiredFields")
              manageContent.requiredFields = item.ResourceDesc;

            if (item.ResourceCode === "pageContentSuccessMsg")
              manageContent.pageContentSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "pageContentFailureMsg")
              manageContent.pageContentFailureMsg = item.ResourceDesc;
            if (item.ResourceCode === "contentCreateSuccessMsg")
              manageContent.contentCreateSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "contentCreateFailureMsg")
              manageContent.contentCreateFailureMsg = item.ResourceDesc;
            if (item.ResourceCode === "pageContentUpdateErrMsg")
              manageContent.pageContentUpdateErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "pageContentSettings")
              manageContent.pageContentSettings = item.ResourceDesc;
            if (item.ResourceCode === "pageStepContent")
              manageContent.pageStepContent = item.ResourceDesc;
            if (item.ResourceCode === "pageStepHeader")
              manageContent.pageStepHeader = item.ResourceDesc;
            if (item.ResourceCode === "pageStepSubHeader")
              manageContent.pageStepSubHeader = item.ResourceDesc;
            if (item.ResourceCode === "next")
              manageContent.next = item.ResourceDesc;
            if (item.ResourceCode === "previous")
              manageContent.previous = item.ResourceDesc;
            if (item.ResourceCode === "allStepsCompleted")
              manageContent.allStepsCompleted = item.ResourceDesc;
            if (item.ResourceCode === "reset")
              manageContent.reset = item.ResourceDesc;

            if (item.ResourceCode === "selectEditPage")
              manageContent.selectEditPage = item.ResourceDesc;

            // For Grid
            if (item.ResourceCode === "gridPage")
              localeText.page = item.ResourceDesc;
            if (item.ResourceCode === "gridTo")
              localeText.to = item.ResourceDesc;
            if (item.ResourceCode === "gridOf")
              localeText.of = item.ResourceDesc;
            if (item.ResourceCode === "noRowsToShow")
              localeText.noRowsToShow = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, manageContent);
        }

        setManageContentRes(manageContent);
        setLocaleTextRes(localeText);

        setColumnDefs(columnDefsArray);

        setRenderGridFlag(true);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setManageContentRes(manageContent);
        setProgressFlag(false);
      });
  }

  //---------------------
  // AG-GRID Definitions
  //---------------------

  const columnDefsArray = [
    {
      headerName: "PagePK",
      field: "PagePK",
      width: 100,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: pageId,
      field: "PageSK",
      width: 70,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: false,
    },
    {
      headerName: pageHeaderLabel,
      field: "PageHeader",
      width: 150,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: false,
    },
    {
      headerName: pageContentLabel,
      field: "PageContent",
      width: 150,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: false,
    },
    {
      headerName: languageNameLabel,
      field: "LanguageCode",
      width: 100,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: pageHeaderLabel,
      field: "PageHeader",
      width: 50,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      headerName: "PageSubHeader",
      field: "PageSubHeader",
      width: 50,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      headerName: "PageCategory",
      field: "PageCategory",
      width: 50,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      headerName: "PageTags",
      field: "PageTags",
      width: 50,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      headerName: "PageContent",
      field: "PageContent",
      width: 210,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      headerName: startDateLabel,
      field: "StartDate",
      width: 90,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      // cellRenderer: DateCellRenderer,
      // filter: "agDateColumnFilter",
    },
    {
      headerName: endDateLabel,
      field: "EndDate",
      width: 90,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      // cellRenderer: DateCellRenderer,
      // filter: "agDateColumnFilter",
    },
    {
      headerName: modifiedBy,
      field: "ModifiedBy",
      width: 210,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
      filter: "agDateColumnFilter",
      hide: true,
    },
    {
      headerName: createdAt,
      field: "CreatedAt",
      width: 210,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
      filter: "agDateColumnFilter",
      hide: true,
    },
    {
      headerName: updatedAt,
      field: "UpdatedAt",
      width: 210,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
      filter: "agDateColumnFilter",
      hide: true,
    },
    {
      headerName: editLabel,
      field: "edit",
      width: 50,
      filter: false,
      sortable: false,
      resizable: true,
      cellRendererFramework: ActionsCellRenderer,
    },
  ];
  const [columnDefs, setColumnDefs] = useState(columnDefsArray);

  // AG-Grid
  const defColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
  };
  const [defaultColDef, setDefaultColDef] = useState(defColDef);

  const [rowSelection, setRowSelection] = useState("multiple");

  // Fetch Grid Data
  async function fetchGridData(pageName) {
    // console.log('In fetchGridData(): appContext.domain is: ', appContext.domain);
    // console.log('In fetchGridData(): pageName is: ', pageName);
    // console.log('In fetchGridData(): formLanguage is: ', formLanguage);

    let domain = appContext.domain;

    // Handle other domains other than ccms
    if (pageName) {
      domain = pageName.substring(0, pageName.indexOf("-"));
    }
    console.log("In fetchGridData(): domain is: ", domain);

    setFilterDisabledFlag(true);
    setProgressFlag(true);

    let arrayData = [];

    let params = {
      apiName: "PagesAPI",
      path: "/dynamodb/pages/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#pagePK = :pagePK AND begins_with(#pageSK, :pageSK)",
        expressionAttributeNames: { "#pagePK": "PagePK", "#pageSK": "PageSK" },
        expressionAttributeValues: {
          ":pagePK": domain + "#" + pageName,
          ":pageSK": formLanguage,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchGridData(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj).then((response) => {
        // console.log('In fetchGridData(): response length is: ', response.data.Items.length);
        // console.log('In fetchGridData(): response is: ', JSON.stringify(response.data.Items, null, 2));
        // let langCode = currentLanguage.languageCode;
        // switch(currentLanguage.languageCode) {
        //   case 'zh':
        //     langCode = "zh-cn";
        //     break;
        //   default:
        //     langCode = currentLanguage.languageCode;
        //     break;
        // };
        response.data.Items.map((item, index) => {
          //   let startDate = new moment(item.StartDate).locale(langCode);
          //   let strStartDate = startDate.format('L');

          //   let endDate = new moment(item.EndDate).locale(langCode);
          //   let strEndDate = endDate.format('L');

          let record = {
            id: "" + index,
            PagePK: item.PagePK,
            PageSK: item.PageSK,

            LanguageCode: item.LanguageCode,

            StartDate: item.StartDate,
            EndDate: item.EndDate,

            // StartDate: strStartDate,
            // EndDate: strEndDate,

            PageHeader: item.PageHeader,
            PageSubHeader: item.PageSubHeader,
            PageContent: item.PageContent,

            PageCategory: item.PageCategory,
            PageTags: item.PageTags,

            ModifiedBy: item.ModifiedBy,
            CreatedAt: item.CreatedAt,
            UpdatedAt: item.UpdatedAt,
          };

          arrayData.push(record);
        });

        setGridData(arrayData);

        if (response.data.Items.length > 0) {
          setFilterDisabledFlag(false);
        }

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchGridData(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  function fetchWebPages(languageValue) {
    // console.log('In fetchWebPages(): languageValue is: ', languageValue);

    setFilterDisabledFlag(true);
    setProgressFlag(true);

    let arrayData = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "web-pages",
          ":resourceSK": languageValue,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchWebPages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchWebPages(): response length is: ', response.data.Items.length);
        // console.log('In fetchWebPages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item, index) => {
          let record = {
            value: item.ResourceCode,
            text: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        setPageNameOptions(arrayData);
        setContactInfoData(response.data.Items);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchWebPages(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  function fetchPageCategories(languageValue) {
    // console.log('In fetchPageCategories(): languageValue is: ', languageValue);

    setFilterDisabledFlag(true);
    setProgressFlag(true);

    let arrayData = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "page-categories",
          ":resourceSK": languageValue,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchPageCategories(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchPageCategories(): response length is: ', response.data.Items.length);
        // console.log('In fetchPageCategories(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item, index) => {
          let record = {
            value: item.ResourceCode,
            text: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        setPageCategoryOptions(arrayData);
        setContactInfoData(response.data.Items);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchPageCategories(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  let selectOptions = [];

  function fetchLanguages() {
    // console.log('In fetchLanguages()');
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
        // "keyConditionExpression": "#resourcePK = :resourcePK",
        // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
        // "expressionAttributeValues": {":resourcePK": "languages"}
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages(): response.length is: ', response.data.Items.length);
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            // label: item.LanguageName,
            text: item.LanguageName,
          };
          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  }

  // async function importEditorLanguageFile2(selectedLang) {
  //   switch(selectedLang) {
  //     case 'ar':// Arabic
  //       await import('froala-editor/js/languages/ar.js');
  //       await setEditorPageContentReady(true);
  //       await setEditorPageHeaderReady(true);
  //       await setEditorPageSubHeaderReady(true);
  //       break;
  //     case 'es':// Spanish
  //         await import('froala-editor/js/languages/es.js');
  //         await setEditorPageContentReady(true);
  //         await setEditorPageHeaderReady(true);
  //         await setEditorPageSubHeaderReady(true);
  //           break;
  //     case 'zh':// Chinese (Simplified)
  //       await import('froala-editor/js/languages/zh_cn.js');
  //       await setEditorPageContentReady(true);
  //       await setEditorPageHeaderReady(true);
  //       await setEditorPageSubHeaderReady(true);
  //       break;
  //     case 'zh-TW':// Chinese (Traditional)
  //       await import('froala-editor/js/languages/zh_tw.js');
  //       await setEditorPageContentReady(true);
  //       await setEditorPageHeaderReady(true);
  //       await setEditorPageSubHeaderReady(true);
  //       break;
  //     case 'de':// German
  //       await import('froala-editor/js/languages/de.js');
  //       await setEditorPageContentReady(true);
  //       await setEditorPageHeaderReady(true);
  //       await setEditorPageSubHeaderReady(true);
  //       break;
  //     case 'ru':// Russia
  //       await import('froala-editor/js/languages/ru.js');
  //       await setEditorPageContentReady(true);
  //       await setEditorPageHeaderReady(true);
  //       await setEditorPageSubHeaderReady(true);
  //       break;
  //     case 'ko':// Korean
  //       await import('froala-editor/js/languages/ko.js');
  //       await setEditorPageContentReady(true);
  //       await setEditorPageHeaderReady(true);
  //       await setEditorPageSubHeaderReady(true);
  //       break;
  //     case 'br':// Brazil
  //       await import('froala-editor/js/languages/pt_br.js');
  //       await setEditorPageContentReady(true);
  //       await setEditorPageHeaderReady(true);
  //       await setEditorPageSubHeaderReady(true);
  //       break;
  //     case 'pt':// Portuguese
  //       await import('froala-editor/js/languages/pt_pt.js');
  //       await setEditorPageContentReady(true);
  //       await setEditorPageHeaderReady(true);
  //       await setEditorPageSubHeaderReady(true);
  //       break;
  //     case 'pl':// Polish
  //       await import('froala-editor/js/languages/pl.js');
  //       await setEditorPageContentReady(true);
  //       await setEditorPageHeaderReady(true);
  //       await setEditorPageSubHeaderReady(true);
  //       break;
  //     case 'it':// Italian
  //       await import('froala-editor/js/languages/it.js');
  //       await setEditorPageContentReady(true);
  //       await setEditorPageHeaderReady(true);
  //       await setEditorPageSubHeaderReady(true);
  //       break;
  //     case 'ja':// Japanese
  //       await import('froala-editor/js/languages/ja.js');
  //       await setEditorPageContentReady(true);
  //       await setEditorPageHeaderReady(true);
  //       await setEditorPageSubHeaderReady(true);
  //       break;
  //     case 'fr':// French
  //       await import('froala-editor/js/languages/fr.js');
  //       await setEditorPageContentReady(true);
  //       await setEditorPageHeaderReady(true);
  //       await setEditorPageSubHeaderReady(true);
  //       break;
  //     case 'he':// hebrew
  //       await import('froala-editor/js/languages/he.js');
  //       await setEditorPageContentReady(true);
  //       await setEditorPageHeaderReady(true);
  //       await setEditorPageSubHeaderReady(true);
  //       break;
  //     default:
  //       await setEditorPageContentReady(true);
  //       await setEditorPageHeaderReady(true);
  //       await setEditorPageSubHeaderReady(true);
  //       break;
  //   }
  // }

  async function importEditorLanguageFile(selectedLang) {
    // console.log("In importEditorLanguageFile(): selectedLang is: ", selectedLang);

    await ImportEditorLanguageFile(selectedLang);

    await setEditorPageContentReady(true);
    await setEditorPageHeaderReady(true);
    await setEditorPageSubHeaderReady(true);
  }

  useEffect(() => {
    // console.log("In useEffect()");

    let langCode = currentLanguage.languageCode;
    switch (langCode) {
      case "zh":
        setDatePickerLanguage("zh-cn");
        break;
      case "he":
        setDatePickerLanguage("he-HE");
        break;
      default:
        setDatePickerLanguage(langCode);
        break;
    }

    // console.log("In useEffect(): reducerContext.store.signedIn is: ", reducerContext.store.signedIn);
    // let date = new moment('2012-10-14', 'YYYY-MM-DD', 'fr', true);
    let date = new moment("2019-11-01").locale("zh-cn");
    // console.log("In useEffect(): date is", date.format('L'));
    // console.log("In useEffect(): date is", date.localeData('fr').longDateFormat('L'));
    // console.log("In useEffect(): date is", date.localeData("fr"));

    importEditorLanguageFile(currentLanguage.languageCode);
  }, []);

  useLayoutEffect(() => {
    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In ManagePageContent(): useLayoutEffect(): currentLanguage is: ', currentLanguage);
    // console.log('In ManagePageContent(): useLayoutEffect(): GenerateModulusHash("firstName", 200) is: ', GenerateModulusHash("firstName", 200));
    // console.log('In ManagePageContent(): useLayoutEffect(): appContext.signedIn is: ', appContext.signedIn);
    // generateDateLocale(currentLanguage.languageCode);

    // console.log("In useLayoutEffect(): reducerContext.store.signedIn is: ", reducerContext.store.signedIn);
    // console.log("In useLayoutEffect(): reducerContext is: ", reducerContext);
    reducerContext.dispatch({ type: "AUTH_BEGIN" });

    setContactCodeFocus(true);
    setContactDescFocus(false);

    if (selectOptions.length === 0) {
      fetchLanguages();
      // fetchWebPages(currentLanguage.languageCode);
    }

    // Fetch resource from catche
    let cacheItemKey = "manage-content";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ManageSocNetworkserenceData(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): got resource from cache');
      setManageContentRes(cacheItem);
    }
  }, []);

  // function handleChange(event) {
  //   // console.log("In handleChange(): event is: ", event);
  //   // console.log("In handleChange(): id is: "+event.target.id);
  //   // console.log("In handleChange(): value is: "+event.target.value);
  //     setGlobalErrorFlag(false);

  //     switch(event.target.id) {
  //         case "pageHeaderId":
  //             setPageHeaderErrFlag(false);
  //             setPageHeader(event.target.value);
  //             break;
  //         case "pageSubHeaderId":
  //             setPageSubHeaderErrFlag(false);
  //             setPageSubHeader(event.target.value);
  //             break;
  //         case "pageContentId":
  //             setPageContentErrFlag(false);
  //             setPageContent(event.target.value);
  //             break;
  //         default:
  //             break;
  //     }
  // };

  function handleStartDateChange(date) {
    // console.log("In handleStartDateChange(): date is: ", date);
    setGlobalErrorFlag(false);
    setStartDateErrFlag(false);

    setStartDate(date);

    checkEnableGridUpdate("startDate", date);
  }

  function handleEndDateChange(date) {
    // console.log("In handleEndDateChange(): date is: ", date);
    setGlobalErrorFlag(false);
    setEndDateErrFlag(false);

    setEndDate(date);

    checkEnableGridUpdate("endDate", date);
  }

  function validateForm() {
    let formErrFlag = false;

    formErrFlag = validateSettingsForm();

    if (!formErrFlag) {
      formErrFlag = validateContentForm();
    }

    if (!formErrFlag) {
      formErrFlag = validateHeaderForm();
    }

    // Initialize
    // setGlobalErrorFlag(false);
    // setGlobalErrorMsg('');

    // setFormLanguageErrFlag(false);
    // setPageNameErrFlag(false);

    // setPageContentErrFlag(false);
    // setPageHeaderErrFlag(false);
    // setPageSubHeaderErrFlag(false);

    // setStartDateErrFlag(false);
    // setEndDateErrFlag(false);

    // // moment.locale(currentLanguage.languageCode);

    // if (formLanguage === null || formLanguage === "") {
    //     formErrFlag = true;
    //     setFormLanguageErrFlag(true);
    // }

    // if (selectedPageName === null || selectedPageName === "") {
    //   formErrFlag = true;
    //   setPageNameErrFlag(true);
    // }

    // // For PageContent
    // if (pageContent === null || pageContent == "") {
    //   formErrFlag = true;
    //   setPageContentErrFlag(true);
    //   setGlobalErrorMsg(pageContentErrMsg);
    // }

    // if (pageHeader === null || pageHeader === "") {
    //   formErrFlag = true;
    //   setPageHeaderErrFlag(true);
    //   setGlobalErrorMsg(pageHeaderErrMsg);
    // }

    // // if (pageSubHeader === null || pageSubHeader === "") {
    // //   formErrFlag = true;
    // //   setPageSubHeaderErrFlag(true);
    // //   setGlobalErrorMsg(pageSubHeaderErrMsg);
    // // }

    // // For StartDate
    // let start = null;
    // if (startDate === null) {
    //     formErrFlag = true;
    //     setStartDateErrFlag(true);
    //     setGlobalErrorMsg(startDateErrMsg);
    // } else {
    //   start = new moment(startDate);
    //   if (!start.isValid()) {
    //     formErrFlag = true;
    //     setStartDateErrFlag(true);
    //     setGlobalErrorMsg(startDateErrMsg);
    //   }
    // }

    // // For EndDate
    // if (endDate === null) {
    //     formErrFlag = true;
    //     setEndDateErrFlag(true);
    //     setGlobalErrorMsg(endDateErrMsg);
    // } else {
    //   let end = new moment(endDate);
    //   if (!end.isValid()) {
    //     formErrFlag = true;
    //     setEndDateErrFlag(true);
    //     setGlobalErrorMsg(endDateErrMsg);
    //   } else {
    //     if (!(start !== null && end.isSameOrAfter(start))) {
    //       formErrFlag = true;
    //       setEndDateErrFlag(true);
    //       setGlobalErrorMsg(startEndDateErrMsg);
    //     }
    //   }
    // }

    if (formErrFlag) {
      setGlobalErrorFlag(true);
    }
    return formErrFlag;
  }

  const validateSettingsForm = () => {
    // console.log("In validateSettingsForm()");
    let formErrFlag = false;

    // Initialize
    if (!pageContentErrFlag) {
      setGlobalErrorFlag(false);
      setGlobalErrorMsg("");
    }

    setFormLanguageErrFlag(false);
    setPageNameErrFlag(false);

    // setControlDescErrFlag(false);
    // setControlHeaderErrFlag(false);
    // setPageSubHeaderErrFlag(false);

    setStartDateErrFlag(false);
    setEndDateErrFlag(false);

    // moment.locale(currentLanguage.languageCode);

    // For DesignBlocks
    // if (controlDesc === null || controlDesc == "") {
    //   formErrFlag = true;
    //   setControlDescErrFlag(true);
    //   setGlobalErrorMsg(controlLinkErrMsg);
    // }

    // if (pageHeader === null || pageHeader === "") {
    //   formErrFlag = true;
    //   setControlHeaderErrFlag(true);
    //   setGlobalErrorMsg(controlImageErrMsg);
    // }

    // if (pageSubHeader === null || pageSubHeader === "") {
    //   formErrFlag = true;
    //   setPageSubHeaderErrFlag(true);
    //   setGlobalErrorMsg(detailLinkErrMsg);
    // }

    // For StartDate
    let start = null;
    if (startDate === null) {
      formErrFlag = true;
      setStartDateErrFlag(true);
      setGlobalErrorMsg(startDateErrMsg);
    } else {
      start = new moment(startDate);
      if (!start.isValid()) {
        formErrFlag = true;
        setStartDateErrFlag(true);
        setGlobalErrorMsg(startDateErrMsg);
      }
    }

    // For EndDate
    if (endDate === null) {
      formErrFlag = true;
      setEndDateErrFlag(true);
      setGlobalErrorMsg(endDateErrMsg);
    } else {
      let end = new moment(endDate);
      if (!end.isValid()) {
        formErrFlag = true;
        setEndDateErrFlag(true);
        setGlobalErrorMsg(endDateErrMsg);
      } else {
        if (!(start !== null && end.isSameOrAfter(start))) {
          formErrFlag = true;
          setEndDateErrFlag(true);
          setGlobalErrorMsg(startEndDateErrMsg);
        }
      }
    }

    if (selectedPageName === null || selectedPageName === "") {
      formErrFlag = true;
      setPageNameErrFlag(true);
      setGlobalErrorMsg(pageNameErrMsg);
    }

    if (formLanguage === null || formLanguage === "") {
      formErrFlag = true;
      setFormLanguageErrFlag(true);
      setGlobalErrorMsg(formLanguageErrMsg);
    }

    if (formErrFlag) {
      setGlobalErrorFlag(true);
    }
    return formErrFlag;
  };

  const validateContentForm = () => {
    // console.log("In validateContentForm()");
    let formErrFlag = false;

    // Initialize
    if (!pageHeaderErrFlag) {
      setGlobalErrorFlag(false);
      setGlobalErrorMsg("");
    }

    setPageContentErrFlag(false);

    if (pageContent === null || pageContent === "") {
      formErrFlag = true;
      setPageContentErrFlag(true);
      setGlobalErrorMsg(pageContentErrMsg);
    }

    if (selectedPageCategory === null || selectedPageCategory === "") {
      formErrFlag = true;
      setPageCategoryErrFlag(true);
      setGlobalErrorMsg(pageCategoryErrMsg);
    }

    if (formErrFlag) {
      setGlobalErrorFlag(true);
    }
    return formErrFlag;
  };

  const validateHeaderForm = () => {
    // console.log("In validateHeaderForm()");
    let formErrFlag = false;

    // Initialize
    if (!pageSubHeaderErrFlag) {
      setGlobalErrorFlag(false);
      setGlobalErrorMsg("");
    }

    setPageHeaderErrFlag(false);

    if (pageHeader === null || pageHeader === "") {
      formErrFlag = true;
      setPageHeaderErrFlag(true);
      setGlobalErrorMsg(pageHeaderErrMsg);
    }

    if (formErrFlag) {
      setGlobalErrorFlag(true);
    }
    return formErrFlag;
  };

  const validateSubHeaderForm = () => {
    // console.log("In validateSubHeaderForm()");
    let formErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setPageSubHeaderErrFlag(false);

    if (pageSubHeader === null || pageSubHeader === "") {
      formErrFlag = true;
      setPageSubHeaderErrFlag(true);
      setGlobalErrorMsg(pageSubHeaderErrMsg);
    }

    if (formErrFlag) {
      setGlobalErrorFlag(true);
    }
    return formErrFlag;
  };

  function initializeForm() {
    setSelectedNewPageName("");

    setStartDate(new Date());
    setEndDate(new Date());

    clearGridData();

    setPageHeader("");
    setPageSubHeader("");
    setPageContent("");

    setSelectedPageCategory(null);
    setPageTags(null);

    // setGridUpdatedFlag(false);
    setGridSubmitFlag(false);

    setSelectedNewPageName("");
    setNewPageDisabled(true);
    setCheckboxState(false);
    setCheckboxDisabled(true);
  }

  function gridUpdateInitForm() {
    // setSelectedPageName("");

    setStartDate(new Date());
    setEndDate(new Date());

    setPageHeader("");
    setPageSubHeader("");
    setPageContent("");
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    setSnackbarOpen(false);
  }

  function checkEnableGridUpdate(source, value) {
    // console.log('In checkEnableGridUpdate(): source is', source);
    // console.log('In checkEnableGridUpdate(): value is', value);
    // setGridUpdatedFlag(false);
    setGridSubmitFlag(false);

    switch (source) {
      case "formLanguage":
        if (
          selectedPageName &&
          selectedPageName !== null &&
          startDate !== null &&
          endDate !== null &&
          endDate >= startDate &&
          pageContent &&
          pageContent !== null &&
          pageContent !== ""
        ) {
          // setGridUpdatedFlag(true);
          setGridSubmitFlag(true);
        }
        break;
      case "pageName":
        if (
          formLanguage &&
          formLanguage !== null &&
          startDate !== null &&
          endDate !== null &&
          endDate >= startDate &&
          pageContent &&
          pageContent !== null &&
          pageContent !== ""
        ) {
          //setGridUpdatedFlag(true);
          setGridSubmitFlag(true);
        }
        break;
      case "startDate":
        if (
          formLanguage &&
          formLanguage !== null &&
          selectedPageName &&
          selectedPageName !== null &&
          value !== null &&
          endDate !== null &&
          endDate >= value &&
          pageContent &&
          pageContent !== null &&
          pageContent !== ""
        ) {
          // setGridUpdatedFlag(true);
          setGridSubmitFlag(true);
        }
        break;
      case "endDate":
        if (
          formLanguage &&
          formLanguage !== null &&
          selectedPageName &&
          selectedPageName !== null &&
          startDate !== null &&
          value !== null &&
          value >= startDate &&
          pageContent &&
          pageContent !== null &&
          pageContent !== ""
        ) {
          //setGridUpdatedFlag(true);
          setGridSubmitFlag(true);
        }
        break;
      case "contentEditor":
        if (
          formLanguage &&
          formLanguage !== null &&
          selectedPageName &&
          selectedPageName !== null &&
          startDate !== null &&
          endDate !== null &&
          endDate >= startDate &&
          value &&
          value !== null &&
          value !== ""
          // (value && value !== null && value !== "") && (pageHeader && pageHeader !== null && pageHeader !== "")
        ) {
          // setGridUpdatedFlag(true);
          setGridSubmitFlag(true);
        }
        break;
      // case "headerEditor":
      //   if ((formLanguage && formLanguage !== null) && (selectedPageName && selectedPageName !== null) &&
      //       (startDate !== null && endDate !== null && endDate >= startDate) &&
      //       (value && value !== null && value !== "") && (pageContent && pageContent !== null && pageContent !== "")
      //   ) {
      //     // setGridUpdatedFlag(true);
      //     setGridSubmitFlag(true);
      //   }
      //   break;
      default:
        break;
    }
  }

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);

    setGlobalErrorFlag(false);
    setFormLanguageErrFlag(false);

    setFormLanguage(selectedOption);

    setSelectedPageName("");
    setSelectedNewPageName("");

    initializeForm();

    checkEnableGridUpdate("formLanguage", selectedOption);

    setSelectLangChangedFlag(true);

    fetchWebPages(selectedOption);

    fetchPageCategories(selectedOption);

    if (selectPageNameChangedFlag) {
      setSubmitFlag(true);
    }
  }

  function handlePageNameChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handlePageNameChange(): selectedOption is', selectedOption);
    // console.log("In handlePageNameChange(): reducerContext.store.signedIn is: ", reducerContext.store.signedIn);

    setGlobalErrorFlag(false);
    setPageNameErrFlag(false);

    setSelectedPageName(selectedOption);

    setSelectPageNameChangedFlag(true);

    initializeForm();

    checkEnableGridUpdate("pageName", selectedOption);

    if (formLanguage && formLanguage !== null) {
      fetchGridData(selectedOption);
    }
  }

  function handleNewPageNameChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleNewPageNameChange(): selectedOption is', selectedOption);
    setSelectedNewPageName(selectedOption);
  }

  function handleCheckboxChange(event) {
    // console.log("In handleCheckboxChange(): event.target.checked is: ", event.target.checked);
    setCheckboxState(event.target.checked);
    setSelectedNewPageName("");
    setNewPageDisabled(true);

    if (event.target.checked) {
      // console.log("In handleCheckboxChange(): event.target.checked is: ", event.target.checked);
      //   filteredRec = contactInfoData.filter(item => item.PageHeader === selectedOption);
      // console.log("In handleCheckboxChange(): pageNameOptions.length is: ", pageNameOptions.length);
      let filteredPageOptions = pageNameOptions.filter(
        (item) => item.value !== selectedPageName
      );
      // console.log("In handleCheckboxChange(): filteredPageOptions.length is: ", filteredPageOptions.length);
      setNewPageNameOptions(filteredPageOptions);
      setNewPageDisabled(false);
    }
  }

  function handlePageCategoryChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handlePageCategoryChange(): selectedOption is', selectedOption);
    setSelectedPageCategory(selectedOption);
  }

  function handlePageTagsChange(event) {
    // console.log('In handlePageTagsChange(): event is', event.target.value);
    setPageTags("");
    if (event.target.value) {
      setPageTags(event.target.value);
    }
  }

  //-----------------------------------
  // For Draggable Modal Dialog
  //-----------------------------------

  const socialsDraggableClose = () => {
    // console.log('In socialsDraggableClose()');
    setModalOpen(false);
  };

  const onRowClickedSocials = (event) => {
    // console.log('onRowClickedSocials: event.rowIndex is: ', event.rowIndex);
    // console.log('onRowClickedSocials: event.data is: ', JSON.stringify(event.data, null, 2));
    setPageHdrMediaUrl(event.data.IconUrl);
    setModalOpen(false);
  };

  //-----------------------------------
  // For Froala Editor
  //-----------------------------------

  // For PageContent
  let editorPageContentConfig = {
    placeholderText: editorPrompt,
    charCounterCount: true,
    language: currentLanguage.languageCode,
    direction: direction,
    heightMin: 300,

    pluginsEnabled: [
      "align",
      "charCounter",
      "codeBeautifier",
      "codeView",
      "colors",
      "draggable",
      "embedly",
      "emoticons",
      "entities",
      "file",
      "fontFamily",
      "fontSize",
      "fullscreen",
      "image",
      "imageManager",
      "inlineStyle",
      "lineBreaker",
      "link",
      "lists",
      "paragraphFormat",
      "paragraphStyle",
      "quickInsert",
      "quote",
      "save",
      "table",
      "url",
      "video",
      "wordPaste",
    ],
    toolbarButtons: {
      // Key represents the more button from the toolbar.
      moreText: {
        // List of buttons used in the  group.
        buttons: [
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "subscript",
          "superscript",
          "fontFamily",
          "fontSize",
          "textColor",
          "backgroundColor",
          "inlineClass",
          "inlineStyle",
          "clearFormatting",
        ],

        // Alignment of the group in the toolbar.
        align: "left",

        // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
        buttonsVisible: 2,
      },

      moreParagraph: {
        buttons: [
          "alignLeft",
          "alignCenter",
          "formatOLSimple",
          "alignRight",
          "alignJustify",
          "formatOL",
          "formatUL",
          "paragraphFormat",
          "paragraphStyle",
          "lineHeight",
          "outdent",
          "indent",
          "quote",
        ],
        align: "left",
        buttonsVisible: 0,
      },

      moreRich: {
        buttons: [
          "insertLink",
          "insertImage",
          "insertVideo",
          "insertTable",
          "emoticons",
          "fontAwesome",
          "specialCharacters",
          "embedly",
          "insertFile",
          "insertHR",
        ],
        align: "left",
        buttonsVisible: 2,
      },

      moreMisc: {
        buttons: [
          "undo",
          "redo",
          "fullscreen",
          "print",
          "getPDF",
          "spellChecker",
          "selectAll",
          "html",
          "help",
        ],
        align: "right",
        buttonsVisible: 2,
      },
    },
  };

  //--------------------
  // For Froala Editor
  //--------------------

  const editorModelChangeCallback = (editorData) => {
    // console.log("In editorModelChangeCallback(): editorData is: ", editorData);
    setGlobalErrorFlag(false);
    setPageContentErrFlag(false);

    setPageContent(editorData);

    if (!lodash.isEmpty(editorData)) {
      checkEnableGridUpdate("contentEditor", editorData);
    }
  }
  
  const editorPageHeaderModelChangeCallback = (editorData) => {
    // console.log("In editorPageHeaderModelChangeCallback(): editorData is: ", editorData);
    setGlobalErrorFlag(false);
    setPageContentErrFlag(false);

    setPageHeader(editorData);

    if (!lodash.isEmpty(editorData)) {
      checkEnableGridUpdate("headerEditor", editorData);
    }
  }
  
  const editorPageSubHeaderModelChangeCallback = (editorData) => {
    // console.log("In editorPageSubHeaderModelChangeCallback(): editorData is: ", editorData);
    setGlobalErrorFlag(false);
    setPageContentErrFlag(false);

    setPageSubHeader(editorData);

    // if (!lodash.isEmpty(editorData)) {
    //   checkEnableGridUpdate("headerEditor", editorData);
    // }
  }

  // function handleEditorPageContentModelChange(editorData) {
  //   // console.log("In handleEditorPageContentModelChange(): editorData is: ", editorData);
  //   setGlobalErrorFlag(false);
  //   setPageContentErrFlag(false);

  //   setPageContent(editorData);

  //   checkEnableGridUpdate("contentEditor", editorData);
  // }

  // function handleEditorPageHeaderModelChange(editorData) {
  //   // console.log("In handleEditorPageHeaderModelChange(): editorData is: ", editorData);
  //   setGlobalErrorFlag(false);
  //   setPageHeaderErrFlag(false);

  //   setPageHeader(editorData);

  //   // checkEnableGridUpdate("headerEditor", editorData);
  // }

  // function handleEditorPageSubHeaderModelChange(editorData) {
  //   // console.log("In handleEditorPageSubHeaderModelChange(): editorData is: ", editorData);
  //   setGlobalErrorFlag(false);
  //   setPageSubHeaderErrFlag(false);

  //   setPageSubHeader(editorData);
  // }

  // For PageHeader
  let editorPageHeaderConfig = {
    placeholderText: editorPrompt,
    charCounterCount: true,
    language: currentLanguage.languageCode,
    direction: direction,
    heightMin: 300,

    // toolbarButtons: [['undo', 'redo' , 'bold', 'image',], ['alert', 'clear', 'insert']],

    pluginsEnabled: [
      "align",
      "charCounter",
      "codeBeautifier",
      "codeView",
      "colors",
      "draggable",
      "embedly",
      "emoticons",
      "entities",
      "file",
      "fontFamily",
      "fontSize",
      "fullscreen",
      "image",
      "imageManager",
      "inlineStyle",
      "lineBreaker",
      "link",
      "lists",
      "paragraphFormat",
      "paragraphStyle",
      "quickInsert",
      "quote",
      "save",
      "table",
      "url",
      "video",
      "wordPaste",
    ],
    // toolbarButtons: {
    //   'moreText': {
    //     'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
    //   },
    //   'moreParagraph': {
    //     'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
    //   },
    //   'moreRich': {
    //     'buttons': ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
    //   },
    //   'moreMisc': {
    //     'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
    //     'align': 'right',
    //     'buttonsVisible': 2
    //   }
    // }

    // pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'embedly', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quickInsert', 'quote', 'save', 'table', 'url', 'video', 'wordPaste'],
    // toolbarButtons: ['bold', 'italic', 'underline', 'video', 'strikeThrough', 'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'color', 'inlineStyle', 'paragraphStyle', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', 'check', '|', 'insertLink', 'insertImage', 'insertVideo', 'embedly', 'insertFile', 'insertTable', '|', 'emoticons', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|', 'spellChecker', 'help', 'html', '|', 'undo', 'redo'],

    // Set custom buttons.
    toolbarButtons: {
      // Key represents the more button from the toolbar.
      moreText: {
        // List of buttons used in the  group.
        buttons: [
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "lineHeight",
          "subscript",
          "superscript",
          "fontFamily",
          "fontSize",
          "textColor",
          "backgroundColor",
          "inlineClass",
          "inlineStyle",
          "clearFormatting",
        ],

        // Alignment of the group in the toolbar.
        align: "left",

        // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
        buttonsVisible: 2,
      },

      moreParagraph: {
        buttons: [
          "alignLeft",
          "alignCenter",
          "formatOLSimple",
          "alignRight",
          "alignJustify",
          "formatOL",
          "formatUL",
          "paragraphFormat",
          "paragraphStyle",
          "lineHeight",
          "outdent",
          "indent",
          "quote",
        ],
        align: "left",
        buttonsVisible: 0,
      },

      moreRich: {
        buttons: [
          "insertLink",
          "insertImage",
          "insertVideo",
          "insertTable",
          "emoticons",
          "fontAwesome",
          "specialCharacters",
          "embedly",
          "insertFile",
          "insertHR",
        ],
        align: "left",
        buttonsVisible: 2,
      },

      moreMisc: {
        buttons: [
          "undo",
          "redo",
          "fullscreen",
          "print",
          "getPDF",
          "spellChecker",
          "selectAll",
          "html",
          "help",
        ],
        align: "right",
        buttonsVisible: 2,
      },
    },

    //   // Change buttons for XS screen.
    //   toolbarButtonsXS: [['undo', 'redo'], ['bold', 'italic', 'underline']]
  };

  // For PageSubHeader
  let editorPageSubHeaderConfig = {
    placeholderText: editorPrompt,
    charCounterCount: true,
    language: currentLanguage.languageCode,
    direction: direction,
    heightMin: 300,

    pluginsEnabled: [
      "align",
      "charCounter",
      "codeBeautifier",
      "codeView",
      "colors",
      "draggable",
      "embedly",
      "emoticons",
      "entities",
      "file",
      "fontFamily",
      "fontSize",
      "fullscreen",
      "image",
      "imageManager",
      "inlineStyle",
      "lineBreaker",
      "link",
      "lists",
      "paragraphFormat",
      "paragraphStyle",
      "quickInsert",
      "quote",
      "save",
      "table",
      "url",
      "video",
      "wordPaste",
    ],
    toolbarButtons: {
      // Key represents the more button from the toolbar.
      moreText: {
        // List of buttons used in the  group.
        buttons: [
          "bold",
          "italic",
          "underline",
          "strikeThrough",
          "subscript",
          "superscript",
          "fontFamily",
          "fontSize",
          "textColor",
          "backgroundColor",
          "inlineClass",
          "inlineStyle",
          "clearFormatting",
        ],

        // Alignment of the group in the toolbar.
        align: "left",

        // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
        buttonsVisible: 2,
      },

      moreParagraph: {
        buttons: [
          "alignLeft",
          "alignCenter",
          "formatOLSimple",
          "alignRight",
          "alignJustify",
          "formatOL",
          "formatUL",
          "paragraphFormat",
          "paragraphStyle",
          "lineHeight",
          "outdent",
          "indent",
          "quote",
        ],
        align: "left",
        buttonsVisible: 0,
      },

      moreRich: {
        buttons: [
          "insertLink",
          "insertImage",
          "insertVideo",
          "insertTable",
          "emoticons",
          "fontAwesome",
          "specialCharacters",
          "embedly",
          "insertFile",
          "insertHR",
        ],
        align: "left",
        buttonsVisible: 2,
      },

      moreMisc: {
        buttons: [
          "undo",
          "redo",
          "fullscreen",
          "print",
          "getPDF",
          "spellChecker",
          "selectAll",
          "html",
          "help",
        ],
        align: "right",
        buttonsVisible: 2,
      },
    },
  };

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
  }

  const onCellClicked = (event) => {
    // console.log('onCellClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onCellClicked: event.data is: ', event.data);
    // console.log('onCellClicked: event.colDef.field is: ', event.colDef.field);

    // let gridRowsCount;
    setCheckboxDisabled(false);

    let langCode = currentLanguage.languageCode;
    switch (currentLanguage.languageCode) {
      case "zh":
        langCode = "zh-cn";
        break;
      default:
        langCode = currentLanguage.languageCode;
        break;
    }

    switch (event.colDef.field) {
      // case 'delete':
      //   var selectedData = gridApi.getSelectedRows();
      //   // console.log('onCellClicked: selectedData is: ', selectedData);

      //   let filteredSelectedData = selectedData.filter(data => (data.ResourcePK === event.data.ResourcePK && data.PageHeader === event.data.PageHeader));
      //   // console.log('onCellClicked: filteredSelectedData is: ', filteredSelectedData);

      //   var res = gridApi.updateRowData({ remove: filteredSelectedData });
      //   // console.log("In onCellClicked() res is: ", res);

      //   // Update DeletedGridRows State
      //   if (filteredSelectedData && filteredSelectedData.length > 0){
      //     let tempArray = deletedGridRows;
      //     filteredSelectedData.map((record) => {
      //       tempArray.push(record);
      //     })
      //     setDeletedGridRows(tempArray);
      //   }

      //   // Update number of rows in grid
      //   getRowCount();
      //   initializeForm();

      //   setGridSubmitFlag(true);
      //   break;
      case "edit":
        if (event.data) {
          // setReferenceLanguage(getLanguageByCode(event.data.ResourcePK));
          // setReferenceLanguage({value: 'es', text: 'Espanol'});
          setCurrentEditRow(event.data);
          setEditRowIndex(event.rowIndex);

          let startDate = new moment(
            event.data.StartDate,
            "YYYY-MM-DD",
            langCode,
            true
          );
          // let startDate = new moment(event.data.StartDate).locale(langCode).format('L');
          // let startDate = new moment(event.data.StartDate).locale(langCode);
          // let strStartDate = startDate.format('L');

          let endDate = new moment(
            event.data.EndDate,
            "YYYY-MM-DD",
            langCode,
            true
          );
          // let endDate = new moment(event.data.EndDate).locale(langCode).format('L');
          // let endDate = new moment(event.data.EndDate).locale(langCode);
          // let strEndDate = endDate.format('L');

          setSelectedPagePK(event.data.PagePK);
          setSelectedPageSK(event.data.PageSK);

          setSelectedModifiedBy(event.data.ModifiedBy);
          setSelectedCreatedAt(event.data.CreatedAt);

          setStartDate(startDate);
          setEndDate(endDate);
          // setStartDate(event.data.StartDate);
          // setEndDate(event.data.EndDate);

          // console.log('onCellClicked: event.data.PageCategory is: ', event.data.PageCategory);
          // console.log('onCellClicked: event.data.PageTags is: ', event.data.PageTags);

          setSelectedPageCategory(null);
          if (event.data.PageCategory) {
            // console.log('onCellClicked: 2');
            setSelectedPageCategory(
              event.data.PageCategory === null ? null : event.data.PageCategory
            );
          }

          if (event.data.PageTags) {
            setPageTags(
              event.data.PageTags === null ? null : event.data.PageTags
            );
          }

          setPageHeader(event.data.PageHeader);
          setPageSubHeader(event.data.PageSubHeader);
          setPageContent(event.data.PageContent);
        }
        break;
      default:
        break;
    }
  };

  const onCellValueChanged = (event) => {
    // console.log('onCellValueChanged: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellValueChanged: event.colDef.field is: ', event.colDef.field);

    const tempArray = modifiedGridRows;
    // console.log('onCellValueChanged: tempArray is: ', JSON.stringify(tempArray, null, 2));

    // Remove any previous changes to the same record
    let filteredTempArray = tempArray.filter(
      (data) =>
        data.ResourcePK !== event.data.ResourcePK &&
        data.PageHeader !== event.data.PageHeader
    );
    // console.log('onCellValueChanged: filteredTempArray is: ', JSON.stringify(filteredTempArray, null, 2));
    filteredTempArray.push(event.data);
    setModifiedGridRows(filteredTempArray);
  };

  const onRowSelected = (event) => {
    //gridApi = event.api;
    //console.log('onRowSelected: event is: ', event);
    // console.log('onRowSelected: event.data is: ', JSON.stringify(event.data, null, 2));
    setRowSelectedFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getSelectedNodes().length > 0
    ) {
      setRowSelectedFlag(true);
      // console.log('onRowSelected: after rowSelectedFlag is: ', rowSelectedFlag);
    }
  };

  const onRowClicked = (event) => {
    // console.log('onRowClicked: Total number of rows is: ', gridApi.getDisplayedRowCount());
    // console.log('onRowClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onRowClicked: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onRowClicked: clicked row is: ', gridApi.getDisplayedRowAtIndex(event.rowIndex));
    // gridApi.forEachNode(function(node) {
    //   //if (node.data.country === "United States") {
    //     node.setSelected(true);
    //   //}
    // });
  };

  const processRowPostCreate = (event) => {
    // console.log('processRowPostCreate: event.node is:  ', event.node);
    setTimeout(function () {
      let checkCond =
        event.node.data.PagePK === selectedPagePK &&
        event.node.data.PageSK === selectedPageSK;
      // console.log('processRowPostCreate: checkCond is:  ', checkCond);
      if (checkCond) {
        event.node.setSelected(true);
      }
    }, 1000);
  };

  const onRowValueChanged = (event) => {
    // console.log('onRowValueChanged: event is:  ', event);
  };

  const onRowDataChanged = (event) => {
    //gridApi = event.api;
    // console.log('>>>>In onRowDataChanged: referenceLanguage is: ', referenceLanguage);
    // console.log('>>>>In onRowDataChanged: referenceType is: ', referenceType);
    // console.log('>>>>In onRowDataChanged: event is: ', event);
    // setGridSubmitFlag(false);

    setColumnDefs(columnDefsArray);
    setLocaleTextRes(localeText);

    // if (
    //   selectRefTypeChangedFlag && referenceLanguage !== undefined && referenceLanguage !== null &&
    //   referenceType !== undefined && referenceType !== null
    // ) {
    //   fetchGridData();
    // }

    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getModel().getRowCount() > 0
    ) {
      // console.log('>>>>In onRowDataChanged: gridApi.getModel().getRowCount() is: ', gridApi.getModel().getRowCount());
      // setGridSubmitFlag(true);

      // Update display of number of rows in grid
      getRowCount();
    }
  };

  const onRowDataUpdated = (event) => {
    // console.log('onRowDataUpdated: event is:  ', event);
  };

  const onFirstDataRendered = (event) => {
    // console.log('onFirstDataRendered: event is:  ', event);
  };

  function clearGridData() {
    // console.log('clearGridData: gridApi is:  ', gridApi);
    if (gridApi) {
      gridApi.setRowData([]);
      setGridData([]);
      setCurrentEditRow(null);
      setEditRowIndex(-1);

      getRowCount();

      // Disable Submit button
      setGridSubmitFlag(false);
    }
  }

  // function initializeForm() {
  //   // console.log("In initializeForm()");

  //   setGlobalErrorFlag(false);
  //   setGlobalErrorMsg('');

  //   setPageHeader("");
  //   setStartDate("");

  //   gridApi.deselectAll();
  // }

  function getRowCount() {
    let gridRowsCount = gridApi.getModel().getRowCount();
    setGridRowsCount(gridRowsCount);
    return gridRowsCount;
  }

  // function updateModifiedGridRow() {
  //   // console.log("In updateModifiedGridRow(): formLanguage is: ", formLanguage);
  //   // console.log("In updateModifiedGridRow(): selectedPageName is: ", selectedPageName);
  //   // console.log("In updateModifiedGridRow(): startDate is: ", startDate);
  //   // console.log("In updateModifiedGridRow(): endDate is: ", endDate);

  //   // Initialize
  //   setGlobalErrorFlag(false);
  //   setGlobalErrorMsg('');

  //   if (!validateForm()){
  //     // Validation Passed - you can proceed
  //     // console.log('Validation Passed.');
  //     // setGridUpdatedFlag(false);
  //     setGridSubmitFlag(false);

  //     // console.log("In updateModifiedGridRow(): currentEditRow is: ", currentEditRow);
  //     if (currentEditRow && currentEditRow !== null) {
  //       currentEditRow.LanguageCode = formLanguage.languageCode;
  //       currentEditRow.PageName = selectedPageName;

  //       currentEditRow.PageHeader = pageHeader;
  //       currentEditRow.PageSubHeader = pageSubHeader;
  //       currentEditRow.PageContent = pageContent;

  //       currentEditRow.StartDate = startDate;
  //       currentEditRow.EndDate = endDate;

  //       currentEditRow.Status = "modified";

  //       const res = gridApi.updateRowData({ update: [currentEditRow] });
  //       // console.log("In updateModifiedGridRow(): res is: ", res);

  //       // Remove any previous changes to the same record
  //       const tempArray = modifiedGridRows;
  //       // console.log('In updateModifiedGridRow(): tempArray is: ', JSON.stringify(tempArray, null, 2));

  //       tempArray.filter(data => (data.PagePK !== currentEditRow.PagePK && data.PagePK !== currentEditRow.PagePK));
  //       // console.log('In updateModifiedGridRow(): tempArray before push is: ', JSON.stringify(tempArray, null, 2));

  //       tempArray.push(currentEditRow);
  //       setModifiedGridRows(tempArray);
  //       // console.log('In updateModifiedGridRow(): tempArray after push is: ', JSON.stringify(tempArray, null, 2));

  //       // Update number of rows in grid
  //       getRowCount();

  //       // Initialize Variables
  //       gridUpdateInitForm();
  //     }
  //   }
  // }

  const gridComponents = {
    loadingRenderer: function (params) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<CircularProgress className={classes.progress} color="secondary" size={30}/>';
      }
    },
  };

  // function handleGridFilterChange(event){
  //   // console.log("In handleGridFilterChange(): value is: "+event.target.value);
  //   setGridFilterValue(event.target.value);
  //   gridApi.setQuickFilter(event.target.value);
  // }

  async function managePageService() {
    console.log("In managePageService(): appContext.domain is: ", appContext.domain);
    // console.log("In managePageService(): formLanguage is: ", formLanguage);
    console.log("In managePageService(): selectedPageName is: ", selectedPageName);
    // console.log("In managePageService(): startDate is: ", startDate);
    // console.log("In managePageService(): endDate is: ", endDate);
    // console.log("In managePageService(): selectedPagePK is: ", selectedPagePK);
    // console.log("In managePageService(): selectedPageSK is: ", selectedPageSK);
    // console.log("In managePageService(): selectedModifiedBy is: ", selectedModifiedBy);
    // console.log("In managePageService(): selectedCreatedAt is: ", selectedCreatedAt);
    // console.log("In managePageService(): checkboxState is: ", checkboxState);
    // console.log("In managePageService(): selectedNewPageName is: ", (selectedNewPageName && selectedNewPageName) ? selectedNewPageName : "");
    // console.log("In managePageService(): pageSubHeader is: ", pageSubHeader);

    let domain = appContext.domain;

    // Handle other domains other than ccms
    if (selectedPageName) {
      domain = selectedPageName.substring(0, selectedPageName.indexOf("-"));
    }
    console.log("In managePageService(): domain is: ", domain);

    const uniqueName = GenerateUniqueName();
    // console.log("In managePageService(): uniqueName is: ", uniqueName);

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    if (!validateForm()) {
      const timestamp = new Date();

      let start = new moment(startDate).format("YYYY-MM-DD");
      let end = new moment(endDate).format("YYYY-MM-DD");

      let key = {
        PagePK: "",
        PageSK: "",
      };

      // ConditionExpression: "attribute_exists(resourceCode)",
      let conditionExpression = "";

      // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
      let updateExpression =
        "SET " +
        "PageHeader = :pageHeader, " +
        "PageSubHeader = :pageSubHeader, " +
        "PageContent = :pageContent, " +
        "PageCategory = :pageCategory, " +
        "PageTags = :pageTags, " +
        "PageName = :pageName, " +
        "LanguageCode = :languageCode, " +

        "StartDate = :startDate, " +
        "EndDate = :endDate, " +

        "ModifiedBy = :modifiedBy, " +
        "CreatedAt = :createdAt, " + 
        "UpdatedAt = :updatedAt";

      let expressionAttributeValues = {
        ":pageHeader": pageHeader,
        ":pageSubHeader": pageSubHeader === "" ? null : pageSubHeader,
        ":pageContent": pageContent,

        ":pageCategory": selectedPageCategory,
        ":pageTags": pageTags === "" ? null : pageTags,

        ":pageName": selectedPageName,
        ":languageCode": formLanguage,

        ":startDate": start,
        ":endDate": end,

        ":modifiedBy": appContext.userName,
        ":createdAt": timestamp,
        ":updatedAt": timestamp,
      };

      expressionAttributeValues[":pageName"] =
        selectedNewPageName && selectedNewPageName
          ? selectedNewPageName
          : selectedPageName;
      expressionAttributeValues[":createdAt"] = selectedCreatedAt
        ? selectedCreatedAt
        : timestamp;

      // console.log('In managePageService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

      //---------------------------
      // Handle Primary Key
      //---------------------------
      let successMsg = "";
      let failureMsg = "";

      key.PagePK = domain + "#" + selectedPageName;
      key.PageSK = formLanguage + "#" + uniqueName;
      successMsg = contentCreateSuccessMsg;
      failureMsg = contentCreateFailureMsg;

      // For existing record
      if (selectedPagePK && selectedPageSK) {
        key.PagePK = selectedPagePK;
        key.PageSK = selectedPageSK;
        conditionExpression = "attribute_exists(PageSK)";

        successMsg = pageContentSuccessMsg;
        failureMsg = pageContentFailureMsg;

        if (checkboxState) {
          // Duplicate existing record (create new record)
          key.PagePK = selectedPagePK;
          key.PageSK = formLanguage + "#" + uniqueName;
          conditionExpression = "";

          successMsg = contentCreateSuccessMsg;
          failureMsg = contentCreateFailureMsg;

          if (selectedNewPageName && selectedNewPageName) {
            // Create a different page name from the record duplicated
            key.PagePK = domain + "#" + selectedNewPageName;
          }
        }
      }
      // console.log('In managePageService(): key is: ', JSON.stringify(key, null, 2));

      let params = {
        apiName: "PagesAPI",
        path: "/dynamodb/pages/update",
        payload: {
          key: key,
          conditionExpression: conditionExpression,
          updateExpression: updateExpression,
          expressionAttributeValues: expressionAttributeValues,
        },
      };
      // console.log('In managePageService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

      let initObj = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        response: true, // return entire response object instead of response.data
        body: {},
      };

      initObj.headers.Authorization = appContext.jwtToken;
      initObj.body = params.payload;
      // console.log('In managePageService(): initObj is: ', JSON.stringify(initObj, null, 2));

      setProgressFlag(true);

      API.put(params.apiName, params.path, initObj).then((response) => {
          // console.log('In managePageService(): response is: ', JSON.stringify(response, null, 2));

          // Show Snackbar
          setSnackbarsVariant("success");
          setSnackbarsMsg(successMsg);
          setSnackbarOpen(true);

          setProgressFlag(false);

          initializeForm();

          setSelectedPageName("");

          clearGridData();

          // Refresh Contact Info dropdown
          // console.log("In managePageService(): formLanguage is: ", formLanguage);
          // fetchWebPages(formLanguage);

          setActiveStep(0);
        }).catch((err) => {
          console.error("In managePageService(): err is: ", JSON.stringify(err, null, 2));
          setGlobalErrorFlag(true);

          setGlobalErrorMsg(failureMsg);

          setProgressFlag(false);

          setActiveStep(0);
        });
    }
  }

  //------------------------------
  // Page Content settings
  //------------------------------

  const PageContentSettings = () => {
    return <div>PageContentSettings</div>;
  };

  //------------------------------
  // Page Content
  //------------------------------

  const PageContent = () => {
    return <div>PageContent</div>;
  };

  //------------------------------
  // Page Header
  //------------------------------

  const PageHeader = () => {
    return <div>PageHeader</div>;
  };

  //------------------------------
  // Page Sub Header
  //------------------------------

  const PageSubHeader = () => {
    return <div>PageSubHeader</div>;
  };

  //------------------------------
  // For stepper
  //------------------------------

  const [activeStep, setActiveStep] = React.useState(0);

  function getSteps() {
    return [
      pageContentSettings,
      pageStepContent,
      pageStepHeader,
      pageStepSubHeader,
    ];
  }

  const steps = getSteps();

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <PageContentSettings />;
      case 1:
        return <PageContent />;
      case 2:
        return <PageHeader />;
      case 3:
        return <PageSubHeader />;
      default:
        return "Unknown stepIndex";
    }
  }

  const handleNext = () => {
    let errorFlag = false;
    switch (activeStep) {
      case 0:
        errorFlag = validateSettingsForm();
        break;
      case 1:
        errorFlag = validateContentForm();
        break;
      case 2:
        errorFlag = validateHeaderForm();
        break;
      case 3:
        break;
      default:
        break;
    }

    if (!errorFlag) {
      // No errors
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const stepperService = () => {
    let errorFlag = false;

    if (!errorFlag) {
      // No errors
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep);
    }
  };

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { !appContext.signedIn &&
          <Redirect to='/signIn' />
        } */}
        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%", height: "100%" }}>
            {progressFlag && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                  size={15}
                />
              </div>
            )}
            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="lg"
            >
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale={datePickerLanguage}
              >
                <Grid container justify="center">
                  <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                    {/* <StyledPaper elevation={0} className={classes.paper}> */}
                    <Typography
                      variant="h6"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      {managePageContentLabel}
                    </Typography>

                    <Typography
                      variant="caption"
                      style={{ color: "green", textAlign: "center" }}
                    >
                      {requiredFields}
                    </Typography>

                    {globalErrorFlag && (
                      <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                    )}

                    <LineSpacing value={16} />

                    {/* Stepper */}

                    <Container
                      component="div"
                      className={classes.mainForm}
                      maxWidth="md"
                    >
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                      <div>
                        {activeStep === steps.length ? (
                          <div>
                            <Typography className={classes.instructions}>
                              {allStepsCompleted}
                            </Typography>
                            <Button onClick={handleReset}>{reset}</Button>
                          </div>
                        ) : (
                          <div>
                            {/* <Typography className={classes.instructions}>
                              {getStepContent(activeStep)}
                            </Typography> */}

                            {activeStep === 0 && (
                              <div>
                                <LineSpacing value={12} />
                                <StyledPaper
                                  elevation={3}
                                  className={classes.paper}
                                >
                                  <LineSpacing value={24} />
                                  <Grid container justify="center">
                                    <Grid item style={marginAlignment}>
                                      <FormControl
                                        className={classes.formControl}
                                        error={formLanguageErrFlag}
                                      >
                                        <label
                                          htmlFor="formLanguageId"
                                          className={classes.verticalAlignLabel}
                                          style={directionAlignment}
                                        >
                                          <Typography variant="subtitle1">
                                            *{languageNameLabel}
                                          </Typography>
                                        </label>
                                        <Select
                                          id="formLanguageId"
                                          value={formLanguage}
                                          onChange={handleLanguageChange}
                                          variant="outlined"
                                          displayEmpty={true}
                                          className={classes.selectEmpty}
                                          margin="none"
                                        >
                                          <MenuItem value={null} disabled>
                                            <Typography variant="subtitle1">
                                              {selectLanguage}
                                            </Typography>
                                          </MenuItem>
                                          {langSelectOptions.map(
                                            (item, index) => (
                                              <MenuItem
                                                key={index}
                                                value={item.value}
                                              >
                                                {item.text}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>

                                    <Grid item style={{ flex: "1" }}>
                                      <FormControl
                                        className={classes.formControl}
                                        error={pageNameErrFlag}
                                      >
                                        <label
                                          htmlFor="pageNameId"
                                          className={classes.verticalAlignLabel}
                                          style={directionAlignment}
                                        >
                                          <Typography variant="subtitle1">
                                            *{pageName}
                                          </Typography>
                                        </label>
                                        <Select
                                          // labelId="pageName-label"
                                          id="pageNameId"
                                          value={selectedPageName}
                                          onChange={handlePageNameChange}
                                          variant="outlined"
                                          displayEmpty={true}
                                          className={classes.selectEmpty}
                                          placeholder={selectPageName}
                                          margin="none"
                                        >
                                          <MenuItem value={null} disabled>
                                            <Typography variant="subtitle1">
                                              {selectPageName}
                                            </Typography>
                                          </MenuItem>

                                          {pageNameOptions.map(
                                            (item, index) => (
                                              <MenuItem
                                                key={index}
                                                value={item.value}
                                              >
                                                {item.text}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>

                                  <LineSpacing value={24} />

                                  <Grid container item justify="center">
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      style={marginAlignment}
                                      className={classes.verticalAlignLabel}
                                    >
                                      <FormControl
                                        className={classes.formControl}
                                        error={startDateErrFlag}
                                      >
                                        <label
                                          htmlFor="startDateId"
                                          className={classes.verticalAlignLabel}
                                          style={directionAlignment}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            color={
                                              startDateErrFlag
                                                ? "secondary"
                                                : "inherit"
                                            }
                                          >
                                            *{startDateLabel}
                                          </Typography>
                                        </label>
                                        <KeyboardDatePicker
                                          id="startDateId"
                                          value={startDate}
                                          onChange={handleStartDateChange}
                                          clearable
                                          // minDate={new Date()}
                                          format="MMM DD, YYYY"
                                          // inputVariant="outlined"
                                          invalidDateMessage={startDateErrMsg}
                                          cancelLabel={cancelLabel}
                                          clearLabel={clearLabel}
                                          okLabel={okLabel}
                                          className={classes.phone}
                                          inputVariant="outlined"
                                          margin="none"
                                        />
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                      <FormControl
                                        className={classes.formControl}
                                        error={endDateErrFlag}
                                      >
                                        <label
                                          htmlFor="endDateId"
                                          className={classes.verticalAlignLabel}
                                          style={directionAlignment}
                                        >
                                          <Typography
                                            variant="subtitle1"
                                            color={
                                              endDateErrFlag ? "secondary" : "inherit"
                                            }
                                          >
                                            *{endDateLabel}
                                          </Typography>
                                        </label>
                                        <KeyboardDatePicker
                                          id="endDateId"
                                          value={endDate}
                                          onChange={handleEndDateChange}
                                          clearable
                                          // minDate={new Date()}
                                          format="MMM DD, YYYY"
                                          // inputVariant="outlined"
                                          invalidDateMessage={endDateErrMsg}
                                          cancelLabel={cancelLabel}
                                          clearLabel={clearLabel}
                                          okLabel={okLabel}
                                          className={classes.verticalAlignLabel}
                                          inputVariant="outlined"
                                          margin="none"
                                        />
                                      </FormControl>
                                    </Grid>
                                  </Grid>

                                  <LineSpacing value={18} />

                                  <Grid container justify="center">
                                    <Grid
                                      item
                                      style={marginAlignment}
                                      className={classes.verticalAlignLabel}
                                    >
                                      <FormControlLabel
                                        className={classes.formControlLabel}
                                        control={
                                          <Checkbox
                                            checked={checkboxState}
                                            onChange={handleCheckboxChange}
                                            // value="newContent"
                                            color="primary"
                                            disabled={checkboxDisabled}
                                          />
                                        }
                                        label={createNewContent}
                                      />
                                    </Grid>

                                    <Grid item style={{ flex: 1 }}>
                                      <FormControl
                                        className={classes.formControl}
                                      >
                                        <label
                                          htmlFor="newPageId"
                                          className={classes.verticalAlignLabel}
                                          style={directionAlignment}
                                        >
                                          <Typography variant="subtitle1">
                                            {newPageName}
                                          </Typography>
                                        </label>
                                        <Select
                                          id="newPageId"
                                          value={selectedNewPageName}
                                          onChange={handleNewPageNameChange}
                                          variant="outlined"
                                          displayEmpty={true}
                                          className={classes.selectEmpty}
                                          margin="none"
                                        >
                                          <MenuItem value={null} disabled>
                                            <Typography variant="subtitle1">
                                              {selectNewPageName}
                                            </Typography>
                                          </MenuItem>

                                          {newPageNameOptions.map(
                                            (item, index) => (
                                              <MenuItem
                                                key={index}
                                                value={item.value}
                                              >
                                                {item.text}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>

                                  {/* <LineSpacing value={12} />
                                </StyledPaper> */}

                                  <LineSpacing value={24} />

                                  {/* AG-GRID */}
                                  <Grid
                                    item
                                    className={clsx(
                                      classes.verticalAlignLabel,
                                      classes.bottomMargin
                                    )}
                                    style={directionAlignment}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      style={directionFloat}
                                    >
                                      {selectEditPage}
                                    </Typography>
                                  </Grid>

                                  <Grid container justify="center"></Grid>

                                  {renderGridFlag && (
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        textAlign: "left",
                                      }}
                                    >
                                      <div
                                        id="refGrid"
                                        style={{
                                          // height: "100px",
                                          width: "100%",
                                        }}
                                        className="ag-theme-balham"
                                      >
                                        <AgGridReact
                                          localeText={localeTextRes}
                                          domLayout="autoHeight"
                                          onGridReady={onGridReady}
                                          gridOptions={gridOptions}
                                          columnDefs={columnDefs}
                                          defaultColDef={defaultColDef}
                                          rowData={gridData}
                                          rowSelection={rowSelection}
                                          rowMultiSelectWithClick={false}
                                          rowDeselection={true}
                                          suppressRowClickSelection={false}
                                          // debug={true}
                                          pagination={true}
                                          components={gridComponents}
                                          paginationPageSize={
                                            paginationPageSize
                                          }
                                          cacheOverflowSize={2}
                                          maxConcurrentDatasourceRequests={2}
                                          infiniteInitialRowCount={1}
                                          maxBlocksInCache={2}
                                          enableRtl={enableRtl}
                                          onCellClicked={onCellClicked}
                                          onCellValueChanged={
                                            onCellValueChanged
                                          }
                                          onRowSelected={onRowSelected}
                                          onRowClicked={onRowClicked}
                                          onRowValueChanged={onRowValueChanged}
                                          onRowDataChanged={onRowDataChanged}
                                          onRowDataUpdated={onRowDataUpdated}
                                          onFirstDataRendered={
                                            onFirstDataRendered
                                          }
                                          processRowPostCreate={
                                            processRowPostCreate
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <LineSpacing value={8} />
                                </StyledPaper>
                              </div>
                            )}

                            <LineSpacing value={16} />

                            {activeStep === 1 && (
                              <StyledPaper
                                elevation={3}
                                className={classes.paper}
                              >
                                <LineSpacing value={12} />
                                <Grid container justify="center">
                                  <Grid item style={marginAlignment}>
                                    <FormControl
                                      className={classes.formControl}
                                      error={pageCategoryErrFlag}
                                    >
                                      <label
                                        htmlFor="pageCategoryId"
                                        className={classes.verticalAlignLabel}
                                        style={directionAlignment}
                                      >
                                        <Typography variant="subtitle1">
                                          *{pageCategoryLabel}
                                        </Typography>
                                      </label>
                                      <Select
                                        id="pageCategoryId"
                                        value={selectedPageCategory}
                                        onChange={handlePageCategoryChange}
                                        variant="outlined"
                                        displayEmpty={true}
                                        className={classes.selectEmpty}
                                        margin="none"
                                      >
                                        <MenuItem value={null} disabled>
                                          <Typography variant="subtitle1">
                                            {pageCategoryPrompt}
                                          </Typography>
                                        </MenuItem>
                                        {pageCategoryOptions.map(
                                          (item, index) => (
                                            <MenuItem
                                              key={index}
                                              value={item.value}
                                            >
                                              {item.text}
                                            </MenuItem>
                                          )
                                        )}
                                      </Select>
                                    </FormControl>
                                  </Grid>

                                  <Grid item style={{ flex: "1" }}>
                                    <FormControl
                                      className={classes.textField}
                                      variant="outlined"
                                      error={pageTagsErrFlag}
                                    >
                                      <label
                                        htmlFor="pageTagsId"
                                        style={directionAlignment}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          color={
                                            pageTagsErrFlag ? "secondary" : "inherit"
                                          }
                                        >
                                          {pageTagsLabel}
                                        </Typography>
                                      </label>
                                      <OutlinedInput
                                        id="pageTagsId"
                                        value={pageTags}
                                        placeholder={pageTagsPrompt}
                                        onChange={handlePageTagsChange}
                                        required
                                        fullWidth
                                        // autoFocus
                                        type={"text"}
                                        error={pageTagsErrFlag}
                                        margin="none"
                                      />
                                    </FormControl>
                                  </Grid>
                                </Grid>

                                <LineSpacing value={12} />

                                {renderGridFlag && (
                                  <Grid container justify="center">
                                    <Grid item style={{ flex: "1" }}>
                                      <label
                                        htmlFor="pageContentId"
                                        className={classes.verticalAlignLabel}
                                        style={directionAlignment}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          className={classes.verticalAlignLabel}
                                          color={
                                            pageContentErrFlag
                                              ? "secondary"
                                              : "inherit"
                                          }
                                        >
                                          *{pageContentLabel}
                                        </Typography>
                                      </label>
                                      {editorPageContentReady && (
                                        <FroalaCommonEditor
                                          editorId={"pageContentId"}
                                          editorModel={pageContent}
                                          editorRef={editorRef}
                                          placeholderPrompt={editorPrompt}
                                          editorMaxChars={editorMaxChars}
                                          modelChangeCallback={editorModelChangeCallback}
                                        />

                                        // <FroalaEditor
                                        //   id="pageContentId"
                                        //   config={editorPageContentConfig}
                                        //   model={pageContent}
                                        //   onModelChange={
                                        //     handleEditorPageContentModelChange
                                        //   }
                                        // />
                                      )}
                                    </Grid>
                                  </Grid>
                                )}
                                <LineSpacing value={24} />
                              </StyledPaper>
                            )}

                            {activeStep === 2 && (
                              <StyledPaper
                                elevation={3}
                                className={classes.paper}
                              >
                                <LineSpacing value={12} />
                                {renderGridFlag && (
                                  <Grid container justify="center">
                                    <Grid item style={{ flex: "1" }}>
                                      <label
                                        htmlFor="pageContentId"
                                        className={classes.verticalAlignLabel}
                                        style={directionAlignment}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          className={classes.verticalAlignLabel}
                                          color={
                                            pageHeaderErrFlag ? "secondary" : "inherit"
                                          }
                                        >
                                          *{pageHeaderLabel}
                                        </Typography>
                                      </label>
                                      {editorPageHeaderReady && (
                                        <FroalaCommonEditor
                                          editorId={"pageHeaderId"}
                                          editorModel={pageHeader}
                                          editorRef={editorRef2}
                                          placeholderPrompt={editorPrompt}
                                          editorMaxChars={editorMaxChars}
                                          modelChangeCallback={editorPageHeaderModelChangeCallback}
                                        />

                                        // <FroalaEditor
                                        //   id="pageHeaderId"
                                        //   config={editorPageHeaderConfig}
                                        //   model={pageHeader}
                                        //   onModelChange={
                                        //     handleEditorPageHeaderModelChange
                                        //   }
                                        // />
                                      )}
                                    </Grid>
                                  </Grid>
                                )}
                                <LineSpacing value={24} />
                              </StyledPaper>
                            )}

                            {activeStep === 3 && (
                              <StyledPaper
                                elevation={3}
                                className={classes.paper}
                              >
                                <LineSpacing value={12} />
                                {renderGridFlag && (
                                  <Grid container justify="center">
                                    <Grid item style={{ flex: "1" }}>
                                      <label
                                        htmlFor="pageContentId"
                                        className={classes.verticalAlignLabel}
                                        style={directionAlignment}
                                      >
                                        <Typography
                                          variant="subtitle1"
                                          className={classes.verticalAlignLabel}
                                          color={
                                            pageSubHeaderErrFlag
                                              ? "secondary"
                                              : "inherit"
                                          }
                                        >
                                          {pageSubHeaderLabel}
                                        </Typography>
                                      </label>
                                      {editorPageSubHeaderReady && (
                                        <FroalaCommonEditor
                                          editorId={"pageSubHeaderId"}
                                          editorModel={pageSubHeader}
                                          editorRef={editorRef3}
                                          placeholderPrompt={editorPrompt}
                                          editorMaxChars={editorMaxChars}
                                          modelChangeCallback={editorPageSubHeaderModelChangeCallback}
                                        />

                                        // <FroalaEditor
                                        //   id="pageSubHeaderId"
                                        //   config={editorPageSubHeaderConfig}
                                        //   model={pageSubHeader}
                                        //   onModelChange={
                                        //     handleEditorPageSubHeaderModelChange
                                        //   }
                                        // />
                                      )}
                                    </Grid>
                                  </Grid>
                                )}
                                <LineSpacing value={24} />
                              </StyledPaper>
                            )}

                            <LineSpacing value={12} />

                            <div>
                              <Button
                                variant="contained"
                                size="large"
                                disabled={activeStep === 0}
                                className={classes.button}
                                onClick={handleBack}
                              >
                                {direction === "ltr" ? (
                                  <ArrowBackIcon
                                    className={clsx(
                                      classes.leftIcon,
                                      classes.iconSmall
                                    )}
                                  />
                                ) : (
                                  <ArrowForwardIcon
                                    className={clsx(
                                      classes.rightIcon,
                                      classes.iconSmall
                                    )}
                                  />
                                )}
                                {previous}
                              </Button>

                              {activeStep !== steps.length - 1 && (
                                <Button
                                  variant="contained"
                                  size="large"
                                  disabled={false}
                                  color="primary"
                                  className={classes.button}
                                  onClick={handleNext}
                                >
                                  {next}
                                  {direction === "ltr" ? (
                                    <ArrowForwardIcon
                                      className={clsx(
                                        classes.rightIcon,
                                        classes.iconSmall
                                      )}
                                    />
                                  ) : (
                                    <ArrowBackIcon
                                      className={clsx(
                                        classes.leftIcon,
                                        classes.iconSmall
                                      )}
                                    />
                                  )}
                                </Button>
                              )}

                              {activeStep === steps.length - 1 && (
                                <Button
                                  variant="contained"
                                  size="large"
                                  disabled={false}
                                  color="primary"
                                  className={classes.button}
                                  onClick={managePageService}
                                >
                                  <SaveIcon
                                    className={clsx(
                                      classes.leftIcon,
                                      classes.iconSmall
                                    )}
                                  />
                                  {submit}
                                </Button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </Container>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            </Container>
          </div>

          {/* variant: 'success', 'warning', 'error', 'info' */}
          <TsiCustomizedSnackbars
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={8000}
            onClose={snackbarOnClose}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">{snackbarsMsg}</span>}
            variant={snackbarsVariant}
          />

          {modalOpen && (
            <ImagesDraggableDialog
              currLanguage={currentLanguage}
              dialogOpen={modalOpen}
              onDialogClose={socialsDraggableClose}
              onRowClickedSocials={onRowClickedSocials}
            />
          )}
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter isSignedIn={isSignedIn} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default ManagePageContent;
