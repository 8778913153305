import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import clsx from 'clsx';
import Container from "@material-ui/core/Container";
// import Avatar from '@material-ui/core/Avatar';
import Typography from "@material-ui/core/Typography";
// import { green } from '@material-ui/core/colors';
// import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";

import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

// AppContext
import AppContext from "../../../config/AppContext";

// ReducerContext
import ReducerContext from "../../../config/ReducerContext";

const HomeTemplate01 = (props) => {
  const appContext = useContext(AppContext);
  const reducerContext = useContext(ReducerContext);
  const [direction, setDirection] = useState(appContext.direction);

  const {
    currentLanguageCode,
    pageRecord,

    // PageHeader,
    // PageSubHeader,
    // PageContent,
    // PageCategory,
    // PageTags,
  } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      textAlign: "center",
    },
    // paper: {
    //     // height: currentLanguageCode === 'zh' ? 350 : 500,
    //     textAlign: 'center',
    // },
    mainForm: {
      textAlign: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    // verticalAlignLabel: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     justifyContent: 'center',
    // },
    // rounded: {
    //     color: '#fff',
    //     // backgroundColor: green[500],
    // },
    // large: {
    //     width: theme.spacing(8),
    //     height: theme.spacing(8),
    // },
  }));

  const classes = useStyles();

  return (
    <div dir={direction}>
      <div className={classes.root}>
        <Container component="div" maxWidth="lg">
          <Grid container item xs={12}>
            <Grid item xs={12}>
              {pageRecord.PageHeader && (
                <Typography variant="h2" align="center">
                  <FroalaEditorView model={pageRecord.PageHeader} />
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              {pageRecord.PageSubHeader && (
                <Typography variant="h5" align="center">
                  <FroalaEditorView model={pageRecord.PageSubHeader} />
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} className={classes.mainForm}>
              {pageRecord.PageContent && (
                <Typography variant="body1" align="center">
                  <FroalaEditorView model={pageRecord.PageContent} />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default HomeTemplate01;
