import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
import SaveIcon from "@material-ui/icons/Save";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from "@material-ui/core/Container";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import CheckIcon from '@material-ui/icons/Check';
// import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import InputAdornment from "@material-ui/core/InputAdornment";
import TranslateIcon from '@material-ui/icons/Translate';
import Paper from "@material-ui/core/Paper";
import ImportExportIcon from '@material-ui/icons/ImportExport';

import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Froala
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// For Primereact
import { OrderList } from 'primereact/orderlist';

// Amplify
// import { API } from "aws-amplify";
// import { AmplifyTheme   } from 'aws-amplify-react';

// AppContext
import AppContext from "../../../config/AppContext";

import LineSpacing from "../../../components/misc/LineSpacing";
import GlobalErrorMessage from "../../../components/dialogs/GlobalErrorMessage";
import FroalaCommonEditor from "../../../components/editor/FroalaCommonEditor";
import DomainUploadCapture from "../../../components/upload/DomainUploadCapture";

import WebPageConverter from "../WebPageConverter";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        width: "100% !important",
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    leftPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "left",
    },
    leftPanelSubItems: {
        width: "100% !important",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    divLeftPadding: {
        width: '100% !important',
        paddingLeft: theme.spacing(1),
        // paddingRight: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1rem" /* Footer height */,

        width: "100% !important",
        marginTop: "auto",
        // backgroundColor: '#fff',
        textAlign: "center",
    },
    paper: {
        minWidth: "300px",
        marginTop: theme.spacing(2),
        alignItems: "center",
        padding: "10px 30px 10px 30px !important",
        textAlign: "center",
    },
    divPadding: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

function ManagePageSectionsMv(props) {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);
    
    const theme = useTheme();
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    const { 
        managePageSectionsLabel,
        requiredFields,
        languageLabel,
        languagePrompt,
        languageErrMsg,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,
            
        textContentLabel,
        textContentPrompt,
        textContentErrMsg,
        textContentMsg,
            
        mediaCaptionLabel,
        mediaCaptionPrompt,
        mediaCaptionErrMsg,
            
        mediaDescLabel,
        mediaDescPrompt,
        mediaDescErrMsg,

        mediaUrlLabel,
        mediaUrlPrompt,
        mediaUrlErrMsg,
        
        sectionTitleLabel,
        sectionTitlePrompt,
        sectionTitleErrMsg,
        sectionTitleMsg,

        editorContentLabel,
        editorContentPrompt,
        editorContentErrMsg,
        editorContentMsg,
        webPageLabel,
        webPagePrompt,
        webPageErrMsg,
        gridPageName,
        loadingText,
        noOptionsText,
        clearText,
        closeText,
        moreLabel,
        cancelLabel,

        yesLabel,
        deleteRequested,
        areYouSureMsg,
        requestedToDeleteMsg,
        uploadLabel,
        createPageSectionLabel,
        updatePageSectionLabel,
        createLabel,
        closeLabel,
        noPageSectionRecordsFound,
        descLabel,

        gridTitle,
        gridSectionName,
        gridContent,
        lastUpdateLabel,
        convertLabel,
        sliceText,
        sliceMoreAction,
        // moreLabel,
        clearLabel,
        okLabel,
        
        cloneRecordLabel,
        cloneAction,
        convertPageSectionsLabel,
        uploadFilesLabel,

        sectionNameLabel,
        sectionNamePrompt,
        sectionNameErrMsg,
        sectionDescLabel,
        sectionDescPrompt,
        sectionDescErrMsg,
        
        //=======================

        datePickerLanguage,
        showListFlag,
        pageSectionRecords,
        showAlertDialog,
        convertAction,
        editRecordAction,
        uploadAction,
        createMoreAction,
        noRecordFoundFlag,
        createPriceAction,
        showDetailsFormFlag,
        closeDetailsForm,
        timeZone,
        languageErrFlag,
        language,
        languages,
        handleListLanguageChange,
        handleLanguageChange,
        editModeFlag,
        domain,
        domainOptions,
        handleListDomainChange,
        handleDomainChange,
        webPageErrFlag,
        webPage,
        webPageOptions,
        handleWebPageChange,
        domainErrFlag,
        handleChange,
        selectImageAction,
        textContentErrFlag,
        textContent,
        sectionTitleErrFlag,
        sectionTitle,
        mediaCaptionErrFlag,
        mediaCaption,
        editorContentErrFlag,
        editorContent,
        textMaxLength,
        mediaUrlErrFlag,
        editorRef,
        editorMaxChars,
        mediaUrl,
        submitButtonFlag,
        buttonFormAction,
        uploadFlag,
        closeUploadForm,
        convertFlag,
        closeConvertForm,

        // mediaCaptionErrFlag,
        // mediaCaption,

        mediaDescErrFlag,
        mediaDesc,

        editorRef2,
        handleEditorContentChange,
        timeBeforeErrFlag,
        mediaDescBeforeErrMsg,

        organizeLabel,
        organizePageSectionsLabel,
        availablePageSectionsLabel,
        saveLabel,
        orderListAction,

        globalErrorFlag,
        globalErrorMsg,
        // pageSectionRecords,
        // gridCategory,
        // gridPageTags,
        // pageHeaderLabel,
        sectionNameErrFlag,
        sectionName,
        sectionDescErrFlag,
        sectionDesc,
        orderListFlag,
        closeOrderListForm,
        handleOrderListWebPageChange,
        filteredRecords,
        dragdropFlag,
        orderListTemplate,
        handleFilteredRecords,
        organizeService,
    } = props;
        
    useEffect(() => {
        // console.log('In ManagePageSectionsMv(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        // sessionStorage.removeItem("current-live-event");
    }, []);

    useLayoutEffect(() => {
        // console.log("In ManagePageSectionsMv(): useLayoutEffect()");
    }, []);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
            <div className={clsx(classes.main, classes.divPadding)}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12}>
                        <LineSpacing value={12} />
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h5" color="primary" style={{ textAlign: "center" }}>
                            {managePageSectionsLabel}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                            {requiredFields}
                        </Typography>
                    </Grid>

                    {globalErrorFlag && (
                        <Grid item xs={12} sm={12}>
                            <LineSpacing value={24} />
                            <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={12}>
                        <LineSpacing value={24} />
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="center">
                            {showListFlag &&
                                <Grid container justify="center">
                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                    <label htmlFor="languageId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                            *{languageLabel}
                                                        </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="languageId"
                                                        value={language}
                                                        options={languages}
                                                        getOptionLabel={option => option.languageName}
                                                        getOptionSelected={option => option.languageName}
                                                        onChange={handleListLanguageChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        disabled={editModeFlag ? true : false}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={languagePrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={languageErrFlag}
                                                                helperText={languageErrFlag ? languageErrMsg : ""}
                                                                // InputProps={{
                                                                //     readOnly: editModeFlag ? true : false,
                                                                // }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                    <label htmlFor="domainId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                            *{domainLabel}
                                                        </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="domainId"
                                                        value={domain}
                                                        options={domainOptions}
                                                        getOptionLabel={option => option.text}
                                                        getOptionSelected={option => option.text}
                                                        onChange={handleListDomainChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        // disabled={editModeFlag ? true : false}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={domainPrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={domainErrFlag}
                                                                helperText={domainErrFlag ? domainErrMsg : ""}
                                                                // InputProps={{
                                                                //     readOnly: editModeFlag ? true : false,
                                                                // }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={webPageErrFlag}>
                                                    <label htmlFor="webPageId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={webPageErrFlag ? "secondary" : "inherit"}>
                                                            *{webPageLabel}
                                                        </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="webPageId"
                                                        value={webPage}
                                                        options={webPageOptions}
                                                        getOptionLabel={option => option.text}
                                                        getOptionSelected={option => option.text}
                                                        onChange={handleWebPageChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        disabled={editModeFlag ? true : false}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={webPagePrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={webPageErrFlag}
                                                                helperText={webPageErrFlag ? webPageErrMsg : ""}
                                                                // InputProps={{
                                                                //     readOnly: editModeFlag ? true : false,
                                                                // }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        {pageSectionRecords && pageSectionRecords.length > 0 ?
                                            <>
                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={12} />
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="center">
                                                        <Grid item xs={3} sm={3}>
                                                            <Grid container justify="flex-start">
                                                                <Tooltip title={organizeLabel} placement="bottom">
                                                                    <IconButton color="primary" aria-label={organizeLabel} component="span" onClick={orderListAction}>
                                                                        <ImportExportIcon className={clsx(classes.iconLarge)} aria-label={organizeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={3} sm={3}>
                                                            <Grid container justify="center">
                                                                <Tooltip title={convertLabel} placement="bottom">
                                                                    <IconButton color="primary" aria-label={convertLabel} component="span" onClick={convertAction}>
                                                                        <TranslateIcon className={clsx(classes.iconLarge)} aria-label={convertLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={3} sm={3}>
                                                            <Grid container justify="center">
                                                                <Tooltip title={uploadLabel} placement="bottom">
                                                                    <IconButton color="primary" aria-label={uploadLabel} component="span" onClick={uploadAction}>
                                                                        <CloudUploadIcon className={clsx(classes.iconLarge)} aria-label={uploadLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={3} sm={3}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={createLabel} placement="bottom">
                                                                    <IconButton color="primary" aria-label={createLabel} component="span" onClick={createMoreAction}>
                                                                        <AddIcon className={clsx(classes.iconLarge)} aria-label={createLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={8} />
                                                </Grid>
                                                
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12}>
                                                        <List className={classes.listContainer}>
                                                            <div>
                                                                {pageSectionRecords && pageSectionRecords.length > 0 &&
                                                                    pageSectionRecords.map((record, index) => (
                                                                        <Grid key={index} container justify="center">
                                                                            <ListItem alignItems="flex-start" disableGutters={true}>
                                                                                <ListItemText style={{backgroundColor: (index % 2) === 0 ? '#F8F8FF' : 'inherit', padding: '8px'}}>
                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {gridPageName}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {record.WebPageText}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {gridSectionName}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {record.SectionName}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    
                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {gridTitle}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {record.SectionTitle}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={12} sm={12}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {gridContent}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={12} sm={12}>
                                                                                                {record.TextContentFlag ?
                                                                                                    <>
                                                                                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                                                                                            {sliceText(record.DisplayContent, 200)}
                                                                                                        </Typography>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <Typography variant="caption" style={{display: 'block'}}>
                                                                                                            <FroalaEditorView model={sliceText(record.DisplayContent, 300)}/>
                                                                                                        </Typography>

                                                                                                        {(record.DisplayContent && record.DisplayContent.length > 300) &&
                                                                                                            <Grid container justify="center">
                                                                                                                <Link component="button" variant="caption" onClick={() => sliceMoreAction(record)}>
                                                                                                                    {moreLabel}
                                                                                                                </Link>
                                                                                                            </Grid>
                                                                                                        }
                                                                                                    </>
                                                                                                }
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    
                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="flex-end">
                                                                                            <Grid item style={{paddingRight: theme.spacing(3)}}>
                                                                                                <DeleteIcon color="secondary" onClick={() => showAlertDialog(record, "single")}/>
                                                                                            </Grid>

                                                                                            <Grid item>
                                                                                                <EditIcon color="primary" onClick={() => editRecordAction(record)}/>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </ListItemText>
                                                                            </ListItem>
                                                                        </Grid>
                                                                    ))
                                                                }
                                                            </div>
                                                        </List>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        :
                                            <>
                                                {noRecordFoundFlag &&
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <Typography variant="body1" color="secondary">
                                                                {noPageSectionRecordsFound}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Button variant="contained" size="large" disabled={false} color="primary" onClick={createPriceAction}>
                                                                <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                {createPageSectionLabel}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </>
                                        }
                                    </Grid>










                                    {/* <Grid item xs={12} sm={12}>
                                        {pageSectionRecords && pageSectionRecords.length > 0 ?
                                            <>
                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={12} />
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="center">
                                                        <Grid item xs={3} sm={3}>
                                                            <Grid container justify="flex-start">
                                                                <Button variant="contained" size="large" disabled={selectedTableRows && selectedTableRows.length > 0 ? false : true} color="primary" onClick={() => showAlertDialog(null, "multiple")}>
                                                                    <DeleteIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                    {deleteLabel}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={2} sm={2}>
                                                            <Grid container justify="center">
                                                                <Button variant="outlined" size="large" disabled={false} color="primary" onClick={orderListAction}>
                                                                    <ImportExportIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                    {organizeLabel}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={2} sm={2}>
                                                            <Grid container justify="center">
                                                                <Button variant="outlined" size="large" disabled={false} color="primary" onClick={convertAction}>
                                                                    <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                    {convertLabel}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={2} sm={2}>
                                                            <Grid container justify="center">
                                                                <Button variant="outlined" size="large" disabled={false} color="primary" onClick={uploadAction}>
                                                                    <CloudUploadIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                    {uploadLabel}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={3} sm={3}>
                                                            <Grid container justify="flex-end">
                                                                <Button variant="contained" size="large" disabled={false} color="primary" onClick={createMoreAction}>
                                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                    {createMoreLabel}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={8} />
                                                </Grid>
                                                
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12}>

                                                        <div className="datatable-doc-demo">
                                                            <DataTable 
                                                                value={pageSectionRecords}
                                                                resizableColumns={true} 
                                                                columnResizeMode={"fit"}
                                                                sortMode={"multiple"}
                                                                responsive={true}
                                                                reorderableColumns={true}

                                                                // selectionMode={"multiple"}
                                                                selection={selectedTableRows}
                                                                onSelectionChange={handleMultipleSelectionChange}

                                                                // paginator={visibleRowsCount > 10 ? paginatorFlag : !paginatorFlag} 
                                                                paginator={pageSectionRecords && pageSectionRecords.length > 10} 
                                                                // paginatorLeft={paginatorLeft}

                                                                paginatorTemplate={paginatorTemplate}
                                                                currentPageReportTemplate={"{first}" + " " + toLabel + " " + "{last}" + " " + ofLabel + " " + "{totalRecords}" + " " + recordsLabel} 
                                                                rows={visibleRowsCount} 
                                                                rowsPerPageOptions={rowsPerPageOptions}
                                                                // className="p-datatable-responsive-cob"
                                                                // className="p-datatable-striped"
                                                                emptyMessage={noRecordsFound}
                                                                className={clsx("p-datatable-striped", "p-datatable-customers")}
                                                                rowHover 
                                                                >

                                                                <Column 
                                                                    header={deleteLabel} 
                                                                    selectionMode="multiple" 
                                                                    style={{width:'5em', textAlign:'center'}}
                                                                />
                                                                <Column 
                                                                    field="WebPageText" 
                                                                    header={gridPageName} 
                                                                    sortable={true} 
                                                                    filter={true} 
                                                                    filterMatchMode="contains" 
                                                                />
                                                                <Column 
                                                                    field="SectionName" 
                                                                    header={gridSectionName} 
                                                                    sortable={true} 
                                                                    filter={true} 
                                                                    filterMatchMode="contains" 
                                                                    style={{textAlign:'center'}}
                                                                />
                                                                <Column 
                                                                    field="SectionTitle" 
                                                                    header={gridTitle} 
                                                                    sortable={true} 
                                                                    filter={true} 
                                                                    filterMatchMode="contains"
                                                                    style={{textAlign:'center'}} 
                                                                />
                                                                <Column 
                                                                    field="Display" 
                                                                    header={gridContent} 
                                                                    // sortable={true} 
                                                                    // filter={true} 
                                                                    // filterMatchMode="contains" 
                                                                    body={contentTemplate} 
                                                                />
                                                                <Column 
                                                                    field="deleteIcon" 
                                                                    header={deleteLabel} 
                                                                    body={deleteIconTemplate} 
                                                                    style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                />
                                                                <Column 
                                                                    field="editIcon" 
                                                                    header={editLabel} 
                                                                    body={editIconTemplate} 
                                                                    style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                />
                                                            </DataTable>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        :
                                            <>
                                                {noRecordFoundFlag &&
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Typography variant="body1" color="secondary">
                                                                {noPageSectionRecordsFound}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Button variant="contained" size="large" disabled={false} color="primary" onClick={createPriceAction}>
                                                                <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                {createPageSectionLabel}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </>
                                        }
                                    </Grid> */}
                                </Grid>
                            }

                            {showDetailsFormFlag && 
                                <Grid container justify="center">
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-end">
                                            <Tooltip title={closeLabel} placement="bottom">
                                                <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                    <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={6}>
                                                <Grid container justify="flex-start">
                                                    <Typography variant="h6" color="primary">
                                                        {createPageSectionLabel}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                {editModeFlag &&
                                                    <Grid container justify="center">
                                                        <Button color="primary" onClick={cloneAction}>
                                                            <Typography variant="subtitle1" color="primary">
                                                                {cloneRecordLabel}
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                    <label htmlFor="languageId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                            *{languageLabel}
                                                        </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="languageId"
                                                        value={language}
                                                        options={languages}
                                                        getOptionLabel={option => option.languageName}
                                                        getOptionSelected={option => option.languageName}
                                                        onChange={handleLanguageChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        disabled={editModeFlag ? true : false}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={languagePrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={languageErrFlag}
                                                                helperText={languageErrFlag ? languageErrMsg : ""}
                                                                // InputProps={{
                                                                //     readOnly: editModeFlag ? true : false,
                                                                // }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                    <label htmlFor="domainId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                            *{domainLabel}
                                                        </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="domainId"
                                                        value={domain}
                                                        options={domainOptions}
                                                        getOptionLabel={option => option.text}
                                                        getOptionSelected={option => option.text}
                                                        onChange={handleDomainChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        disabled={editModeFlag ? true : false}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={domainPrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={domainErrFlag}
                                                                helperText={domainErrFlag ? domainErrMsg : ""}
                                                                // InputProps={{
                                                                //     readOnly: editModeFlag ? true : false,
                                                                // }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={webPageErrFlag}>
                                                    <label htmlFor="webPageId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={webPageErrFlag ? "secondary" : "inherit"}>
                                                            *{webPageLabel}
                                                        </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="webPageId"
                                                        value={webPage}
                                                        options={webPageOptions}
                                                        getOptionLabel={option => option.text}
                                                        getOptionSelected={option => option.text}
                                                        onChange={handleWebPageChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        disabled={editModeFlag ? true : false}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={webPagePrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={webPageErrFlag}
                                                                helperText={webPageErrFlag ? webPageErrMsg : ""}
                                                                // InputProps={{
                                                                //     readOnly: editModeFlag ? true : false,
                                                                // }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={sectionTitleErrFlag}>
                                                            <label htmlFor="sectionTitleId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={sectionTitleErrFlag ? "secondary" : "inherit"}>
                                                                    *{sectionTitleLabel}
                                                                </Typography>
                                                            </label>

                                                            <TextField
                                                                id="sectionTitleId"
                                                                value={sectionTitle}
                                                                placeholder={sectionTitlePrompt}
                                                                onChange={handleChange}
                                                                required
                                                                fullWidth
                                                                type={"text"}
                                                                error={sectionTitleErrFlag}
                                                                margin="none"
                                                                variant="outlined"
                                                                helperText={sectionTitleErrFlag ? sectionTitleErrMsg : sectionTitleMsg}
                                                                // InputProps={{
                                                                //     startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                                                // }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={sectionNameErrFlag}>
                                                            <label htmlFor="sectionNameId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={sectionNameErrFlag ? "secondary" : "inherit"}>
                                                                    *{sectionNameLabel}
                                                                </Typography>
                                                            </label>

                                                            <TextField
                                                                id="sectionNameId"
                                                                value={sectionName}
                                                                placeholder={sectionNamePrompt}
                                                                onChange={handleChange}
                                                                required
                                                                fullWidth
                                                                type={"text"}
                                                                error={sectionNameErrFlag}
                                                                margin="none"
                                                                variant="outlined"
                                                                helperText={sectionNameErrFlag ? sectionNameErrMsg : ""}
                                                                // InputProps={{
                                                                //     startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                                                // }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={sectionDescErrFlag}>
                                                            <label htmlFor="sectionDescId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={sectionDescErrFlag ? "secondary" : "inherit"}>
                                                                    {sectionDescLabel}
                                                                </Typography>
                                                            </label>

                                                            <TextareaAutosize
                                                                id="sectionDescId"
                                                                aria-label={sectionDescLabel}
                                                                rows={1.5}
                                                                rowsMax={6}
                                                                value={sectionDesc}
                                                                placeholder={sectionDescPrompt}
                                                                onChange={handleChange}
                                                                required
                                                                // autoFocus={true}
                                                                margin="none"
                                                                className={classes.textArea}
                                                                maxLength={textMaxLength}
                                                            />
                                                            <FormHelperText>{sectionDescErrFlag ? sectionDescErrMsg : ""}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={textContentErrFlag}>
                                                            <label htmlFor="textContentId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={textContentErrFlag ? "secondary" : "inherit"}>
                                                                    *{textContentLabel}
                                                                </Typography>
                                                            </label>

                                                            <TextareaAutosize
                                                                id="textContentId"
                                                                aria-label={textContentLabel}
                                                                rows={1.5}
                                                                rowsMax={6}
                                                                value={textContent}
                                                                placeholder={textContentPrompt}
                                                                onChange={handleChange}
                                                                required
                                                                // autoFocus={true}
                                                                margin="none"
                                                                className={classes.textArea}
                                                                maxLength={textMaxLength}
                                                            />
                                                            <FormHelperText>{textContentErrFlag ? textContentErrMsg : textContentMsg}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={mediaCaptionErrFlag}>
                                                            <label htmlFor="mediaCaptionId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={mediaCaptionErrFlag ? "secondary" : "inherit"}>
                                                                    {mediaCaptionLabel}
                                                                </Typography>
                                                            </label>

                                                            <TextField
                                                                id="mediaCaptionId"
                                                                value={mediaCaption}
                                                                placeholder={mediaCaptionPrompt}
                                                                onChange={handleChange}
                                                                required
                                                                fullWidth
                                                                type={"text"}
                                                                error={mediaCaptionErrFlag}
                                                                margin="none"
                                                                variant="outlined"
                                                                helperText={mediaCaptionErrFlag ? mediaCaptionErrMsg : ""}
                                                                // InputProps={{
                                                                //     startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                                                // }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={mediaDescErrFlag}>
                                                            <label htmlFor="mediaDescId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={mediaDescErrFlag ? "secondary" : "inherit"}>
                                                                    {mediaDescLabel}
                                                                </Typography>
                                                            </label>

                                                            <TextareaAutosize
                                                                id="mediaDescId"
                                                                aria-label={mediaDescLabel}
                                                                rows={1.5}
                                                                rowsMax={6}
                                                                value={mediaDesc}
                                                                placeholder={mediaDescPrompt}
                                                                onChange={handleChange}
                                                                required
                                                                // autoFocus={true}
                                                                margin="none"
                                                                className={classes.textArea}
                                                                maxLength={textMaxLength}
                                                            />
                                                            <FormHelperText>{mediaDescErrFlag ? mediaDescErrMsg : ""}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={mediaUrlErrFlag}>
                                                    <label htmlFor="mediaUrlId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={mediaUrlErrFlag ? "secondary" : "inherit"}>
                                                            {mediaUrlLabel}
                                                        </Typography>
                                                    </label>

                                                    <OutlinedInput
                                                        id="mediaUrlId"
                                                        value={mediaUrl}
                                                        placeholder={mediaUrlPrompt}
                                                        onChange={handleChange}
                                                        required
                                                        fullWidth
                                                        type={"text"}
                                                        error={mediaUrlErrFlag}
                                                        margin="none"
                                                        variant="outlined"
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label={mediaUrlPrompt}
                                                                    onClick={selectImageAction}
                                                                >
                                                                    <ImageSearchIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                    <FormHelperText>{mediaUrlErrFlag ? mediaUrlErrMsg : ""}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={6}>
                                        {mediaUrl &&
                                            <Grid container justify="center">
                                                <img src={mediaUrl} style={{height: '96px'}} />
                                            </Grid>
                                        }           
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={editorContentErrFlag}>
                                                            <label htmlFor="editorContentId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={editorContentErrFlag ? "secondary" : "inherit"}>
                                                                    *{editorContentLabel}
                                                                </Typography>
                                                            </label>

                                                            <FroalaCommonEditor
                                                                editorId={"editorContentId"}
                                                                editorRef={editorRef2}
                                                                // editorType={"minimal"} // Full Editor when not set
                                                                placeholderPrompt={editorContentPrompt}
                                                                editorMaxChars={editorMaxChars}
                                                                editorModel={editorContent}
                                                                modelChangeCallback={handleEditorContentChange}
                                                                language={language}
                                                                domain={domain}
                                                            />
                                                            <FormHelperText>{editorContentErrFlag ? editorContentErrMsg : editorContentMsg}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={buttonFormAction}>
                                                {editModeFlag ?
                                                <>
                                                    <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                    {updatePageSectionLabel} 
                                                </>
                                                : 
                                                <>
                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                    {createPageSectionLabel}
                                                </>
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }

                            {uploadFlag && 
                                <>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-end">
                                            <Tooltip title={closeLabel} placement="bottom">
                                                <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeUploadForm}>
                                                    <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Container component="div" maxWidth="lg">
                                            <Grid container justify="flex-start" className={clsx(classes.divLeftPadding)}>
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="flex-start">
                                                        <Typography variant="h6" color="primary">
                                                            {uploadFilesLabel}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <DomainUploadCapture 
                                            showHeadersFlag={false}
                                        />
                                    </Grid>
                                </>
                            }

                            {convertFlag &&
                                <>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-end">
                                            <Tooltip title={closeLabel} placement="bottom">
                                                <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                    <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Container component="div" maxWidth="lg">
                                            <Grid container justify="flex-start" className={clsx(classes.divLeftPadding)}>
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="flex-start">
                                                        <Typography variant="h6" color="primary">
                                                            {convertPageSectionsLabel}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <WebPageConverter 
                                            showHeadersFlag={false}
                                            referrer={"ManagePageSections"}
                                        />
                                    </Grid>
                                </>
                            }

                            {orderListFlag &&
                                <Container component="div" className={classes.main} maxWidth="md">
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-end">
                                            <Tooltip title={closeLabel} placement="bottom">
                                                <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeOrderListForm}>
                                                    <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-start">
                                            <Typography variant="h6" color="primary">
                                                {organizePageSectionsLabel}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={24} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={webPageErrFlag}>
                                                    <label htmlFor="webPageId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={webPageErrFlag ? "secondary" : "inherit"}>
                                                            *{webPageLabel}
                                                        </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="webPageId"
                                                        value={webPage}
                                                        options={webPageOptions}
                                                        getOptionLabel={option => option.text}
                                                        getOptionSelected={option => option.text}
                                                        onChange={handleOrderListWebPageChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        disabled={editModeFlag ? true : false}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={webPagePrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={webPageErrFlag}
                                                                helperText={webPageErrFlag ? webPageErrMsg : ""}
                                                                // InputProps={{
                                                                //     readOnly: editModeFlag ? true : false,
                                                                // }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={24} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center" style={{width: '100% !important'}}>
                                            <div style={{minWidth: '100%'}}>
                                                <OrderList 
                                                    value={filteredRecords} 
                                                    header={availablePageSectionsLabel} 
                                                    dragdrop={dragdropFlag}
                                                    listStyle={{width: '100% !important', height:'auto'}} 
                                                    dataKey="PriceId"
                                                    itemTemplate={orderListTemplate} 
                                                    onChange={(e) => handleFilteredRecords(e)}
                                                    style={{width: '100% !important', display: 'flex'}}
                                                    >
                                                </OrderList>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={24} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={organizeService}>
                                                <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                {saveLabel} 
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Container>
                            }

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={18} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <Grid item xs={12} sm={12}>
                <LineSpacing value={48} />
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

export default ManagePageSectionsMv;
