import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useCallback,
} from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

// Photo Gallery
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

// AppContext
import AppContext from "../../config/AppContext";

Amplify.configure(AmplifyConfig);

const PageContainer = styled.div`
  width: 100%,
  display: 'flex',
  flexWrap: 'wrap',
  background: #fff;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
  },
  progress: {
    margin: theme.spacing(1),
  },
  mainGrid: {
    textAlign: "left",
  },
  fotoGallery: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  hdr: {
    color: "#fff",
  },
  prodDesc: {
    color: "fff",
    fontSize: "12px",
    marginLeft: theme.spacing(1),
    whiteSpace: "nowrap",
  },
  link: {
    color: "#fff",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  textItem: {
    color: "#fff",
    whiteSpace: "nowrap",
  },
}));

// const Checkmark = ({ selected }) => (
//     <div
//       style={
//         selected
//           ? { left: "4px", top: "4px", position: "absolute", zIndex: "1" }
//           : { display: "none" }
//       }
//     >
//       <svg
//         style={{ fill: "white", position: "absolute" }}
//         width="24px"
//         height="24px"
//       >
//         <circle cx="12.5" cy="12.2" r="8.292" />
//       </svg>
//       <svg
//         style={{ fill: "#06befa", position: "absolute" }}
//         width="24px"
//         height="24px"
//       >
//         <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
//       </svg>
//     </div>
//   );

//   const imgStyle = {
//     transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
//   };
//   const selectedImgStyle = {
//     transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
//     transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s"
//   };
//   const cont = {
//     backgroundColor: "#eee",
//     cursor: "pointer",
//     overflow: "hidden",
//     position: "relative"
//   };

//   const SelectedImage = ({
//     index,
//     photo,
//     margin,
//     direction,
//     top,
//     left,
//     selected
//   }) => {
//     const [isSelected, setIsSelected] = useState(selected);
//     //calculate x,y scale
//     const sx = (100 - (30 / photo.width) * 100) / 100;
//     const sy = (100 - (30 / photo.height) * 100) / 100;
//     selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;

//     if (direction === "column") {
//       cont.position = "absolute";
//       cont.left = left;
//       cont.top = top;
//     }

//     const handleOnClick = e => {
//       setIsSelected(!isSelected);
//     };

//     useEffect(() => {
//       setIsSelected(selected);
//     }, [selected]);

//     return (
//       <div
//         style={{ margin, height: photo.height, width: photo.width, ...cont }}
//         className={!isSelected ? "not-selected" : ""}
//       >
//         <Checkmark selected={isSelected ? true : false} />
//         <img
//           alt={photo.title}
//           style={
//             isSelected ? { ...imgStyle, ...selectedImgStyle } : { ...imgStyle }
//           }
//           {...photo}
//           onClick={handleOnClick}
//         />
//         <style>{`.not-selected:hover{outline:2px solid #06befa}`}</style>
//       </div>
//     );
//   };

let fotoGallery = {
  fotoGalleryHdr: "FotoGallery",
  fotoGalleryDesc: "Our fotoGallery information",
  fotoGallery1: "FotoGallery1",
  fotoGallery2: "FotoGallery2",
  fotoGallery3: "FotoGallery3",
  fotoGallery4: "FotoGallery4",
  fotoGallery5: "FotoGallery5",
  fotoGallery6: "FotoGallery6",
  fotoGallery7: "FotoGallery7",
  fotoGallery8: "FotoGallery8",
  fotoGallery9: "FotoGallery9",
  fotoGallery10: "FotoGallery10",
};

function PhotoGallery(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  // For FotoGallery
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const [fotoGalleryRes, setFotoGalleryRes] = useState(fotoGallery);

  const {
    currLanguage,
    galleryItems,
    layoutDirection, // row or column
    selectAllPhotos,
  } = props;

  const classes = useStyles();

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  const {
    fotoGalleryHdr,
    fotoGalleryDesc,
    fotoGallery1,
    fotoGallery2,
    fotoGallery3,
    fotoGallery4,
    fotoGallery5,
    fotoGallery6,
    fotoGallery7,
    fotoGallery8,
    fotoGallery9,
    fotoGallery10,
  } = fotoGalleryRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(fotoGallery);
    // console.log("In PhotoGallery(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In PhotoGallery(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "fotoGalleryHdr")
              fotoGallery.fotoGalleryHdr = item.ResourceDesc;
            if (item.ResourceCode === "fotoGalleryDesc")
              fotoGallery.fotoGalleryDesc = item.ResourceDesc;
            if (item.ResourceCode === "fotoGallery1")
              fotoGallery.fotoGallery1 = item.ResourceDesc;
            if (item.ResourceCode === "fotoGallery2")
              fotoGallery.fotoGallery2 = item.ResourceDesc;
            if (item.ResourceCode === "fotoGallery3")
              fotoGallery.fotoGallery3 = item.ResourceDesc;
            if (item.ResourceCode === "fotoGallery4")
              fotoGallery.fotoGallery4 = item.ResourceDesc;
            if (item.ResourceCode === "fotoGallery5")
              fotoGallery.fotoGallery5 = item.ResourceDesc;
            if (item.ResourceCode === "fotoGallery6")
              fotoGallery.fotoGallery6 = item.ResourceDesc;
            if (item.ResourceCode === "fotoGallery7")
              fotoGallery.fotoGallery7 = item.ResourceDesc;
            if (item.ResourceCode === "fotoGallery8")
              fotoGallery.fotoGallery8 = item.ResourceDesc;
            if (item.ResourceCode === "fotoGallery9")
              fotoGallery.fotoGallery9 = item.ResourceDesc;
            if (item.ResourceCode === "fotoGallery10")
              fotoGallery.fotoGallery10 = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, fotoGallery);
          setFotoGalleryRes(fotoGallery);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setFotoGalleryRes(fotoGallery);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In PhotoGallery(): useEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));

    // currLanguage is the most current language passed to this component through props
    // We are using useEffect to get the functionality of componentDidUpdate()
    if (
      currLanguage !== undefined &&
      currLanguage !== null &&
      currLanguage !== currentLanguage
    ) {
      setCurrentLanguage(currLanguage);
      appContext.currentLanguage = currLanguage;

      // console.log('In PhotoGallery(): useEffect(): about to call fetchDbResources()');

      // Fetch resource from catche
      let cacheItemKey = "fotoGallery";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      // cacheItemKey += currLanguage.languageCode;
      // console.log('In PhotoGallery(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        // console.log("In PhotoGallery(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
        RemoveCacheItem(cacheItemKey);
      }

      let cacheItem = GetCacheItem(cacheItemKey);
      // console.log('In PhotoGallery(): useLayoutEffect(): cacheItem is: ', cacheItem);

      if (cacheItem === null) {
        // resource is not in cache, fetch from DB
        // console.log('In PhotoGallery(): useLayoutEffect(): resource is not in cache, fetch from DB');
        fetchDbResources(cacheItemKey);
      } else {
        // console.log('In PhotoGallery(): useLayoutEffect(): got resource from cache');
        setFotoGalleryRes(cacheItem);
      }
    }
  });

  useLayoutEffect(() => {
    // console.log("In PhotoGallery(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "fotoGallery";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In PhotoGallery(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In PhotoGallery(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In PhotoGallery(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In PhotoGallery(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In PhotoGallery(): useLayoutEffect(): got resource from cache');
      setFotoGalleryRes(cacheItem);
    }
  }, []);

  // Sample Gallery Items
  // const galleryItems = [
  //     { src: 'https://source.unsplash.com/2ShvY8Lf6l0/800x599', width: 4, height: 3 },
  //     { src: 'https://source.unsplash.com/Dm-qxdynoEc/800x799', width: 1, height: 1 },
  //     { src: 'https://source.unsplash.com/qDkso9nvCg0/600x799', width: 4, height: 3 },
  //     { src: 'https://source.unsplash.com/iecJiKe_RNg/600x799', width: 1, height: 1 }
  // ];

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  // const imageRenderer = useCallback(
  //     ({ index, left, top, key, photo }) => (
  //     <SelectedImage
  //         selected={selectAllPhotos ? true : false}
  //         key={key}
  //         margin={"2px"}
  //         index={index}
  //         photo={photo}
  //         left={left}
  //         top={top}
  //     />
  //     ),
  //     [selectAllPhotos]
  // );

  return (
    <div dir={direction}>
      <PageContainer className={classes.root}>
        <CssBaseline />

        <Container
          component="div"
          className={classes.fotoGallery}
          maxWidth="lg"
        >
          {/* progressFlag &&
                <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
                </div>
            */}

          <Gallery
            photos={galleryItems}
            direction={layoutDirection}
            onClick={openLightbox}
            // renderImage={imageRenderer}
          />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={galleryItems.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </Container>
      </PageContainer>
    </div>
  );
}

export default PhotoGallery;
