function FormatNumber(amount, localeCode) {
    // let languageCode = "en-US";

    // Reformat to proper format
    // if (localeCode) {
    //     let lang = null;

    //     switch(localeCode) {
    //         case "en":
    //             lang = "en-US";
    //         break;
    //         case "zh":
    //             lang = "zh-CN";
    //         break;
    //         default:
    //             lang = "en-US";
    //             break;

    //     }
    //     const locales1 = [lang];
    //     const options1 = { localeMatcher: 'lookup' };
        
    //     let supportedLocales = Intl.NumberFormat.supportedLocalesOf(locales1, options1);
    //     if (supportedLocales && supportedLocales.length > 0) {
    //         languageCode = supportedLocales[0];
    //     }
    // }
    // console.log('In FormatNumber(): languageCode is: ', languageCode);
    return new Intl.NumberFormat(localeCode).format(amount);
};
  
export default FormatNumber;
