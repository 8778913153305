import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
} from "react";
// import WebFont from 'webfontloader';
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import { NavLink, Link as RouterLink } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import { Sticky, StickyContainer } from "react-sticky";
import Container from "@material-ui/core/Container";
//import IdleTimer from 'react-idle-timer'
//import { BrowserRouter as Router, Route, NavLink, Switch, Redirect } from 'react-router-dom';
import Axios from "axios";
// import ReactTooltip from 'react-tooltip';
import { findDOMNode } from "react-dom";
// import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import GetCacheItem from "../../utils/cache/GetCacheItem";
import SetCacheItem from "../../utils/cache/SetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
//import ClearCacheItems from '../../utils/cache/ClearCacheItems';

import Config from "../../config/Config";

import AppMainMenu from "../headers/AppMainMenu";
import PageTopHeader from "../headers/PageTopHeader";
// import PageFooter_old from '../footers/PageFooter_old';
import PageFooter from "../footers/PageFooter";
import AlertDialog from "../../components/dialogs/AlertDialog";
import FilesUploadToS3 from "../../components/upload/FilesUploadToS3";
import JoinMailingList from "../../components/footers/JoinMailingList";
import LineSpacing from "../../components/misc/LineSpacing";
import Logo from "../../components/misc/Logo";

import UserProfile from "../secure/UserProfile";
import SetupLanguages from "../../components/misc/SetupLanguages";

// Home Templates
import HomeTemplate01 from "../../components/pageTemplates/home/HomeTemplate01";
// import HomeTemplate02 from '../../components/pageTemplates/home/HomeTemplate02';

// Testimonials
import TestimonialsTemplate01 from "../../components/designBlockTemplates/testimonials/TestimonialsTemplate01";
import TestimonialsTemplate02 from "../../components/designBlockTemplates/testimonials/TestimonialsTemplate02";

Amplify.configure(AmplifyConfig);

// Load Custom Fonts
// WebFont.load({
//   google: {
//     families: ['Titillium Web:300,400,700', 'sans-serif', 'Dancing Script', 'Great Vibes']
//   },
//   loading: function() {
//     console.log("Font loading");
//   },
//   active: function() {
//     console.log("Font active");
//   },
//   inactive: function() {
//     console.log("Font inactive");
//   },
//   fontloading: function(familyName, fvd) {
//     console.log("Font fontloading");
//   },
//   fontactive: function(familyName, fvd) {
//     console.log("Font fontactive");
//   },
//   fontinactive: function(familyName, fvd) {
//     console.log("Font fontinactive");
//   }
// });

const Paragraph = styled.div`
  background: #fff;
  color: #4caf50;
  text-align: center;
  padding: 0 2rem 0 2rem;
`;

const StyledButton = styled(Button)`
  background: transparent;
  border-radius: 3px;
  border: 2px solid palevioletred;
  color: palevioletred;

  /* When the "primary" property is set we want to add some more css to our component, */
  /* in this case change the background and color.*/

  ${(props) =>
    props.color === "primary" &&
    css`
      background: green !important;
      color: white !important;
      margin: 0 0 12px 0 !important;
    `};
`;

const StyledStickyContainer = styled(StickyContainer)`
  width: 100%;
  text-align: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    position: "relative",
    // backgroundColor: '#fff',
    backgroundImage: `url(${"assets/logos/background.png"})`,
  },
  main: {
    textAlign: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "1rem" /* Footer height */,

    width: "100% !important",
    marginTop: "auto",
    // backgroundColor: '#fff',
    textAlign: "center",
  },
  footerContainer: {
    marginTop: "auto",
    backgroundColor: "#fff",
    textAlign: "center",
  },
  dancingScript: {
    fontFamily: "Dancing Script, cursive",
    color: "green",
    fontSize: "36px",
  },
  titilliumWeb: {
    fontFamily: "Titillium Web",
    color: "red",
    fontSize: "36px",
  },
  greatVibes: {
    fontFamily: "Great Vibes, cursive",
    color: "teal",
    fontSize: "36px",
  },
}));

let homeItems = {
  homePage: "Home page",
  dialogTitle: "Save your changes",
  dialogQuestion: "Are you sure you want to leave?",
  dialogMainContent:
    "You haven't saved your changes yet. You'll loose them if you leave this page.",
  dialogSubContent: "Please save your changes before leaving.",
  yesLabel: "Yes",
  noLabel: "No",

  // For SetupLanguages
  selectPreferredLanguage: "Please select your preferred language",

  // For AppsInfoFooter
  downloadApp: "Download our mobile App now!",
};

function Home() {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");

  const [homeItemsRes, setHomeItemsRes] = useState(homeItems);
  // const [mainMenuItems, setMainMenuItems] = useState(menuItems);

  const [user, setUser] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(appContext.signIn);
  const [progressFlag, setProgressFlag] = useState(false);
  const [containerMaxWidth, setContainerMaxWidth] = useState("lg"); // xl, lg, md, sm, xs

  const [languages, setLanguages] = useState([]);
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [currentLanguageCode, setCurrentLanguageCode] = useState(
    appContext.currentLanguage.languageCode
  );
  const [currentLanguageName, setCurrentLanguageName] = useState(
    appContext.currentLanguage.languageName
  );

  const [langAnchorEl, setLangAnchorEl] = React.useState(null); // Language Popover
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null); // Profileuage Popover
  const [alertOpen, setAlertOpen] = useState(false);
  const [homePageFlag, setHomePageFlag] = useState(false);

  // Home Templates
  const [pageRecord, setPageRecord] = useState({});
  const [selectedHomeTemplate, setSelectedHomeTemplate] = useState(
    appContext.designBlocks.selectedHomeTemplate
  );

  // Testimonials Template
  const [designBlockRecord, setDesignBlockRecord] = useState({});
  const [selectedTestimonialsTemplate, setTestimonialsTemplate] = useState(
    appContext.designBlocks.selectedTestimonialsTemplate
  );

  // For SetupLanguages
  const [selectPreferredLanguage, setSelectPreferredLanguage] = useState(
    homeItems.selectPreferredLanguage
  );

  //-----------------------------------------------------
  // For AppsInfoFooter
  // Google Play and Apple Store Info
  //-----------------------------------------------------

  const [renderGoogleAppleDomFlag, setRenderGoogleAppleDomFlag] = useState(
    appContext.appsInfoFooter.renderGoogleAppleDomFlag
  );
  const [googleAppleInfoFlag, setGoogleAppleInfoFlag] = useState(
    appContext.appsInfoFooter.googleAppleInfoFlag
  );

  let googleMediaUrl = "/assets/apps-info/google-play-badge_en.png";
  let appleMediaUrl = "/assets/apps-info/app_store_badge_en.svg";

  const [downloadApp, setDownloadApp] = useState(homeItems.downloadApp);
  const [googlePlayLinkUrl, setGooglePlayLinkUrl] = useState(
    "https://play.google.com/store/apps/"
  );
  const [appleStoreLinkUrl, setAppleStoreLinkUrl] = useState(
    "https://apps.apple.com/us/"
  );
  const [googlePlayMediaUrl, setGooglePlayMediaUrl] = useState(googleMediaUrl);
  const [appleStoreMediaUrl, setAppleStoreMediaUrl] = useState(appleMediaUrl);

  const [renderGridFlag, setRenderGridFlag] = useState(false);

  const classes = useStyles();

  const langRef = useRef(null);
  // let prevLangCode = 'en';

  const langs = [
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-05T12:15:58.233Z",
      ResourceCode: "ar",
      ResourceSK: "ar",
      LanguageName: "عربى",
      ResourcePK: "languages",
      CreatedAt: "2019-07-05T12:15:58.233Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-05T12:17:50.745Z",
      ResourceCode: "cs",
      ResourceSK: "cs",
      LanguageName: "čeština",
      ResourcePK: "languages",
      CreatedAt: "2019-07-05T12:17:50.745Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-05T19:14:13.908Z",
      ResourceCode: "da",
      ResourceSK: "da",
      LanguageName: "dansk",
      ResourcePK: "languages",
      CreatedAt: "2019-07-05T19:14:13.908Z",
    },
    {
      ResourcePK: "languages",
      ResourceSK: "de",
      LanguageName: "Deutsche",
      ResourceCode: "de",
      ModifiedBy: "onyii5119@gmail.com",
      CreatedAt: "2019-07-06T01:25:19.583Z",
      UpdatedAt: "2019-07-06T01:25:19.583Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-05T15:54:34.823Z",
      ResourceCode: "en",
      ResourceSK: "en",
      LanguageName: "English",
      ResourcePK: "languages",
      CreatedAt: "2019-07-05T15:54:34.823Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-05T12:18:20.336Z",
      ResourceCode: "es",
      ResourceSK: "es",
      LanguageName: "Español",
      ResourcePK: "languages",
      CreatedAt: "2019-07-05T12:18:20.336Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-04T21:48:40.139Z",
      ResourceCode: "fa",
      ResourceSK: "fa",
      LanguageName: "فارسی",
      ResourcePK: "languages",
      CreatedAt: "2019-07-04T21:48:40.139Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-05T14:08:37.798Z",
      ResourceCode: "fi",
      ResourceSK: "fi",
      LanguageName: "Fins",
      ResourcePK: "languages",
      CreatedAt: "2019-07-05T14:08:37.798Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-05T13:36:17.723Z",
      ResourceCode: "fr",
      ResourceSK: "fr",
      LanguageName: "Français",
      ResourcePK: "languages",
      CreatedAt: "2019-07-05T13:36:17.723Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-05T14:08:37.798Z",
      ResourceCode: "he",
      ResourceSK: "he",
      LanguageName: "עברית",
      ResourcePK: "languages",
      CreatedAt: "2019-07-05T14:08:37.798Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-04T12:21:40.898Z",
      ResourceCode: "hi",
      ResourceSK: "hi",
      LanguageName: "हिंदी",
      ResourcePK: "languages",
      CreatedAt: "2019-07-04T12:21:40.898Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-04T12:21:40.898Z",
      ResourceCode: "id",
      ResourceSK: "id",
      LanguageName: "bahasa Indonesia",
      ResourcePK: "languages",
      CreatedAt: "2019-07-04T12:21:40.898Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-04T12:59:39.251Z",
      ResourceCode: "it",
      ResourceSK: "it",
      LanguageName: "Italiana",
      ResourcePK: "languages",
      CreatedAt: "2019-07-04T12:59:39.251Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-05T14:08:37.798Z",
      ResourceCode: "ja",
      ResourceSK: "ja",
      LanguageName: "日本人",
      ResourcePK: "languages",
      CreatedAt: "2019-07-05T14:08:37.798Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-05T14:08:37.798Z",
      ResourceCode: "ko",
      ResourceSK: "ko",
      LanguageName: "한국어",
      ResourcePK: "languages",
      CreatedAt: "2019-07-05T14:08:37.798Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-04T13:03:51.664Z",
      ResourceCode: "ms",
      ResourceSK: "ms",
      LanguageName: "Melayu",
      ResourcePK: "languages",
      CreatedAt: "2019-07-04T13:03:51.664Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-05T14:08:37.798Z",
      ResourceCode: "nl",
      ResourceSK: "nl",
      LanguageName: "Nederlands",
      ResourcePK: "languages",
      CreatedAt: "2019-07-05T14:08:37.798Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-04T21:48:40.139Z",
      ResourceCode: "no",
      ResourceSK: "no",
      LanguageName: "norsk",
      ResourcePK: "languages",
      CreatedAt: "2019-07-04T21:48:40.139Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-04T21:48:40.139Z",
      ResourceCode: "pl",
      ResourceSK: "pl",
      LanguageName: "Polskie",
      ResourcePK: "languages",
      CreatedAt: "2019-07-04T21:48:40.139Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-04T21:48:40.139Z",
      ResourceCode: "pt",
      ResourceSK: "pt",
      LanguageName: "Portuguesa",
      ResourcePK: "languages",
      CreatedAt: "2019-07-04T21:48:40.139Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-04T21:48:40.139Z",
      ResourceCode: "ru",
      ResourceSK: "ru",
      LanguageName: "русский",
      ResourcePK: "languages",
      CreatedAt: "2019-07-04T21:48:40.139Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-04T21:48:40.139Z",
      ResourceCode: "sv",
      ResourceSK: "sv",
      LanguageName: "svenska",
      ResourcePK: "languages",
      CreatedAt: "2019-07-04T21:48:40.139Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-04T21:48:40.139Z",
      ResourceCode: "tr",
      ResourceSK: "tr",
      LanguageName: "Türk",
      ResourcePK: "languages",
      CreatedAt: "2019-07-04T21:48:40.139Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-05T14:08:37.798Z",
      ResourceCode: "zh",
      ResourceSK: "zh",
      LanguageName: "简体中文",
      ResourcePK: "languages",
      CreatedAt: "2019-07-05T14:08:37.798Z",
    },
    {
      ModifiedBy: "onyii5119@gmail.com",
      UpdatedAt: "2019-07-05T13:35:01.781Z",
      ResourceCode: "zh-TW",
      ResourceSK: "zh-TW",
      LanguageName: "中國傳統的",
      ResourcePK: "languages",
      CreatedAt: "2019-07-05T13:35:01.781Z",
    },
  ];

  const {
    homePage,
    dialogTitle,
    dialogQuestion,
    dialogMainContent,
    dialogSubContent,
    yesLabel,
    noLabel,
  } = homeItemsRes;

  const fetchLanguages = () => {
    // console.log("In fetchLanguages()");
    // setFilterDisabledFlag(true);
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression: "#resourcePK = :resourcePK",
        expressionAttributeNames: { "#resourcePK": "ResourcePK" },
        expressionAttributeValues: { ":resourcePK": "languages" },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        console.log(
          "In fetchLanguages(): response length is: ",
          response.data.Items.length
        );
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.sort((a, b) =>
          a.LanguageName > b.LanguageName ? 1 : -1
        );
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        setLanguages(response.data.Items);
        // setGridData(response.data.Items);
        // setRowData(response.data.Items);

        // if (response.data.Items.length > 0) {
        //   setFilterDisabledFlag(false);
        // }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.error(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  };

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(homeItems);
    // console.log("In Home(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        // let partitionKey = currentLanguage.languageCode;
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In Home(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response.data.Responses.resource is: ', JSON.stringify(response.data.Responses.resource, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "homePage")
              homeItems.homePage = item.ResourceDesc;
            if (item.ResourceCode === "dialogTitle")
              homeItems.dialogTitle = item.ResourceDesc;
            if (item.ResourceCode === "dialogQuestion")
              homeItems.dialogQuestion = item.ResourceDesc;
            if (item.ResourceCode === "dialogMainContent")
              homeItems.dialogMainContent = item.ResourceDesc;
            if (item.ResourceCode === "dialogSubContent")
              homeItems.dialogSubContent = item.ResourceDesc;
            if (item.ResourceCode === "yesLabel")
              homeItems.yesLabel = item.ResourceDesc;
            if (item.ResourceCode === "noLabel")
              homeItems.noLabel = item.ResourceDesc;

            // For SetupLanguages
            if (item.ResourceCode === "selectPreferredLanguage")
              setSelectPreferredLanguage(item.ResourceDesc);

            // For AppsInfoFooter
            if (item.ResourceCode === "downloadApp")
              setDownloadApp(item.ResourceDesc);
            if (item.ResourceCode === "downloadApp")
              appContext.appsInfoFooter.downloadApp = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, homeItems);
          setHomeItemsRes(homeItems);
          // setMainMenuItems(menuItems);
        } else {
          appContext.appsInfoFooter.googleAppleInfoFlag = true;
          setGoogleAppleInfoFlag(true);
        }

        // setHomeItemsRes(homeItems);
        setRenderGridFlag(true);
        setRenderGoogleAppleDomFlag(true); // For PageFooter/AppsInfoFooter
        appContext.appsInfoFooter.renderGoogleAppleDomFlag = true;
        setProgressFlag(false);
      })
      .catch((err) => {
        console.error(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setHomeItemsRes(homeItems);
        // setMainMenuItems(menuItems);

        setRenderGridFlag(true);
        setRenderGoogleAppleDomFlag(true); // For PageFooter/AppsInfoFooter
        appContext.appsInfoFooter.renderGoogleAppleDomFlag = true;
        appContext.appsInfoFooter.googleAppleInfoFlag = true;
        setGoogleAppleInfoFlag(true);
        setProgressFlag(false);
      });
  }

  function refreshResources() {
    // console.log('In refreshResources()');

    // Fetch resource from catche
    let cacheItemKey = "homeItems";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In refreshResources(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In refreshResources(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In refreshResources(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In refreshResources(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In refreshResources(): got resource from cache');
      // console.log('In refreshResources(): cacheItem.searchLabel is: ', cacheItem.searchLabel);
      setHomeItemsRes(cacheItem);
      // setSearchText(cacheItem.searchLabel);

      // Menus
      // handleMenuItems(cacheItem);
      setRenderGoogleAppleDomFlag(true); // For PageFooter/AppsInfoFooter
      appContext.appsInfoFooter.renderGoogleAppleDomFlag = true;
    }
  }

  // Fetch Home Page
  async function fetchHomePage(languageCode) {
    // console.log('In fetchHomePage(): appContext.domain is: ', appContext.domain);
    // console.log('In fetchHomePage(): languageCode is: ', languageCode);

    // setFilterDisabledFlag(true);
    setProgressFlag(true);

    // let arrayData = [];
    let pageContent = "home";

    let params = {
      apiName: "PagesAPI",
      path: "/dynamodb/pages/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#pagePK = :pagePK AND begins_with(#pageSK, :pageSK)",
        filterExpression: "#startDate <= :startDate AND #endDate >= :endDate",
        expressionAttributeNames: {
          "#pagePK": "PagePK",
          "#pageSK": "PageSK",
          "#startDate": "StartDate",
          "#endDate": "EndDate",
        },
        expressionAttributeValues: {
          ":pagePK": appContext.domain + "#" + pageContent,
          ":pageSK": languageCode,
          ":startDate": "2020-02-10",
          ":endDate": "2020-02-10",
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchHomePage(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchHomePage(): response length is: ', response.data.Items.length);
        // console.log('In fetchHomePage(): response is: ', JSON.stringify(response.data.Items, null, 2));

        if (response.data.Items.length > 0) {
          setPageRecord(response.data.Items[0]);
        }

        setProgressFlag(false);
      })
      .catch((err) => {
        console.error(
          "In fetchHomePage(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  }

  // Fetch Testimonial Design Block
  async function fetchTestimonialDesignBlock(languageCode) {
    // console.log('In fetchTestimonialDesignBlock(): appContext.domain is: ', appContext.domain);
    // console.log('In fetchTestimonialDesignBlock(): languageCode is: ', languageCode);

    // setFilterDisabledFlag(true);
    setProgressFlag(true);

    // let arrayData = [];
    let designBlock = "testimonials";

    let params = {
      apiName: "DesignAPI",
      path: "/dynamodb/design/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#designPK = :designPK AND begins_with(#designSK, :designSK)",
        filterExpression: "#startDate <= :startDate AND #endDate >= :endDate",
        expressionAttributeNames: {
          "#designPK": "DesignPK",
          "#designSK": "DesignSK",
          "#startDate": "StartDate",
          "#endDate": "EndDate",
        },
        expressionAttributeValues: {
          ":designPK": appContext.domain + "#" + designBlock,
          ":designSK": languageCode,
          ":startDate": "2020-02-10",
          ":endDate": "2020-02-10",
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchTestimonialDesignBlock(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchTestimonialDesignBlock(): response length is: ', response.data.Items.length);
        // console.log('In fetchTestimonialDesignBlock(): response is: ', JSON.stringify(response.data.Items, null, 2));

        if (response.data.Items.length > 0) {
          setDesignBlockRecord(response.data.Items[0]);
        }

        setProgressFlag(false);
      })
      .catch((err) => {
        console.error(
          "In fetchTestimonialDesignBlock(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  }

  // Fetch Google Play and Apple Store Info
  function fetchGooglePlayAppleStoreInfo(languageCode) {
    // console.log('In fetchGooglePlayAppleStoreInfo(): languageCode is: ', languageCode);

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "apps-info",
          ":resourceSK": languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchGooglePlayAppleStoreInfo(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchGooglePlayAppleStoreInfo(): response length is: ', response.data.Items.length);
        // console.log('>>>>In fetchGooglePlayAppleStoreInfo(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item, index) => {
          // setGooglePlayUrl(item.GooglePlayUrl);
          // setAppStoreUrl(item.AppStoreUrl);
          // setCreatedAt(item.CreatedAt);

          // appsInfo.playUrl = item.GooglePlayUrl;
          // appsInfo.storeUrl = item.AppStoreUrl;

          setGooglePlayLinkUrl(item.GooglePlayUrl);
          setAppleStoreLinkUrl(item.AppStoreUrl);

          appContext.appsInfoFooter.googlePlayLinkUrl = item.GooglePlayUrl;
          appContext.appsInfoFooter.appleStoreLinkUrl = item.AppStoreUrl;

          //---------------------------------------------------------------------------
          // Handle Icons - change it to reflect the current language
          // e.g. change "google-play-badge_en.png" to "google-play-badge_zh.png"
          // e.g. change "app_store_badge_en.svg" to "app_store_badge_zh.svg"
          //---------------------------------------------------------------------------

          let googlePlayImgUrl = appContext.appsInfoFooter.googleMediaUrl.replace(
            "_en.png",
            "_" + languageCode + ".png"
          );
          let appleStoreImgUrl = appContext.appsInfoFooter.appleMediaUrl.replace(
            "_en.svg",
            "_" + languageCode + ".svg"
          );

          // console.log('In fetchGooglePlayAppleStoreInfo(): googlePlayImgUrl is: ', googlePlayImgUrl);
          // console.log('In fetchGooglePlayAppleStoreInfo(): appleStoreImgUrl is: ', appleStoreImgUrl);

          setGooglePlayMediaUrl(googlePlayImgUrl);
          setAppleStoreMediaUrl(appleStoreImgUrl);

          appContext.appsInfoFooter.googlePlayMediaUrl = googlePlayImgUrl;
          appContext.appsInfoFooter.appleStoreMediaUrl = appleStoreImgUrl;
        });
        // setRenderAppDomFlag(true);

        setGoogleAppleInfoFlag(true);
        appContext.appsInfoFooter.googleAppleInfoFlag = true;
        setProgressFlag(false);
      })
      .catch((err) => {
        console.error(
          "In fetchGooglePlayAppleStoreInfo(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setGoogleAppleInfoFlag(true);
        appContext.appsInfoFooter.googleAppleInfoFlag = true;
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In Home(): useEffect(): currentLanguageCode is: ", currentLanguageCode);

    let referrer = document.referrer;
    // console.log("In Home(): useEffect(): referrer is: ", referrer);

    if (referrer) {
      const parsedUrl = new URL(referrer);
      // console.log("In Home(): useEffect(): parsedUrl.hostname is: ", parsedUrl.hostname);
      // console.log("In Home(): useEffect(): parsedUrl.origin is: ", parsedUrl.origin);
      // console.log("In Home(): useEffect(): parsedUrl.pathname is: ", parsedUrl.pathname);
      // console.log("In Home(): useEffect(): parsedUrl.port is: ", parsedUrl.port);
      // console.log("In Home(): useEffect(): parsedUrl.searchParams is: ", parsedUrl.searchParams);
      // console.log("In Home(): useEffect(): parsedUrl.protocol is: ", parsedUrl.protocol);
      // console.log("In Home(): useEffect(): parsedUrl.username is: ", parsedUrl.username);
      // console.log("\n\n");
    }

    // console.log("In Home(): useEffect(): window.location.protocol is: ", window.location.protocol);
    // console.log("In Home(): useEffect(): window.location.host is: ", window.location.host);
    // console.log("In Home(): useEffect(): window.location.pathname is: ", window.location.pathname);
    // console.log("In Home(): useEffect(): window.location.search is: ", window.location.search);

    fetchLanguages();
    // setLanguages(langs);
    fetchHomePage(currentLanguageCode);
    fetchTestimonialDesignBlock(currentLanguageCode);
  }, []);

  // useEffect(() => {
  //   console.log("In Home(): useEffect(): [screenDirection] direction is: ", direction);
  //   console.log("In Home(): useEffect(): [screenDirection] appContext.direction is: ", appContext.direction);
  // }, [appContext.direction]);

  useLayoutEffect(() => {
    // console.log("In Home(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "home";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In Home(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In Home(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In Home(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In Home(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In Home(): useLayoutEffect(): got resource from cache');
      setHomeItemsRes(cacheItem);
      setRenderGridFlag(true);
      setRenderGoogleAppleDomFlag(true); // For PageFooter/AppsInfoFooter
      appContext.appsInfoFooter.renderGoogleAppleDomFlag = true;
    }

    // Google Play and Apple Store Info
    fetchGooglePlayAppleStoreInfo(appContext.currentLanguage.languageCode);
  }, []);

  // function fetchResources(langCode){
  //   // console.log('In fetchResources(): langCode is: ', langCode);
  //   langs.forEach((lang) => {
  //     //console.log('In fetchResources(): lang.ResourceSK is: ', lang.ResourceSK);
  //     if (lang.ResourceSK === langCode){
  //       // console.log('In fetchResources(): clicked language is: ', JSON.stringify(lang, null, 2));
  //     }
  //   });
  // }

  //-----------------------------
  // For Dialog Modal
  //-----------------------------
  const [dialogModalFlag, setDialogModalFlag] = useState(false);

  const dialogModalOpen = () => {
    setDialogModalFlag(true);
  };

  const dialogModalClose = () => {
    setDialogModalFlag(false);
  };

  function languageSelectChange(event, index) {
    //ReactTooltip.hide(findDOMNode(langRef.current));
    setRenderGoogleAppleDomFlag(false);
    setGoogleAppleInfoFlag(false);

    setDialogModalFlag(false);

    setLangAnchorEl(null);
    setDirection("ltr");
    appContext.direction = "ltr";

    if (index >= 0 && index < languages.length) {
      // console.log('In languageSelectChange(): index is: ', index);

      let langCode = languages[index].ResourceSK;
      // console.log('In languageSelectChange(): langCode is: ', langCode);
      const currLang = {
        languageCode: langCode,
        languageName: languages[index].LanguageName,
      };
      appContext.currentLanguage = currLang;
      setCurrentLanguage(currLang);
      // console.log('In languageSelectChange(): appContext.currentLanguage is: ', JSON.stringify(appContext.currentLanguage, null, 2));

      setCurrentLanguageCode(langCode);
      setCurrentLanguageName(languages[index].LanguageName);

      // Right To Left Languages
      // Arabic (ar), Aramaic, Dhivehi/Maldivian (dv), Hebrew (he), Kurdish (Sorani), Persian/Farsi (fa), Urdu, Hausa (ha),
      // Khowar (khw), Kashmiri (ks), Kurdish (ku), Pashto (ps)
      // Handle these now: Arabic (ar), Hebrew (he), Persian/Farsi (fa)
      if (langCode === "ar" || langCode === "he" || langCode === "fa") {
        appContext.direction = "rtl";
        setDirection(appContext.direction);
      } else {
        appContext.direction = "ltr";
        setDirection(appContext.direction);
      }
      // console.log('In languageSelectChange(): appContext.direction is: ', appContext.direction);

      refreshResources();

      let cacheItemKey = "home";
      cacheItemKey += ":";
      cacheItemKey += langCode;

      setRenderGridFlag(false);
      fetchDbResources(cacheItemKey);

      fetchHomePage(langCode);

      // Google Play and Apple Store Info
      fetchGooglePlayAppleStoreInfo(langCode);

      fetchTestimonialDesignBlock(langCode);
    }
  }

  function signedInCallback() {
    setIsSignedIn(true);
  }

  //******************* */
  // Language Popover
  //******************* */
  function handleLangClicked(event) {
    // console.log("In handleLangClicked()");
    // setLangAnchorEl(event.currentTarget);
    setDialogModalFlag(true);
  }

  function handleLangClose() {
    // console.log("In handleLangClose()");
    setLangAnchorEl(null);
  }

  const langPopupOpen = Boolean(langAnchorEl);
  const langPopupId = langPopupOpen ? "simple-popover" : null;

  //******************* */
  // Profile Popover
  //******************* */
  function handleProfileClicked(event) {
    // console.log("In handleProfileClicked()");
    setProfileAnchorEl(event.currentTarget);
  }

  function handleProfileClose() {
    // console.log("In handleProfileClose()");
    setProfileAnchorEl(null);
  }

  function profileClicked(selection) {
    // console.log("In profileClicked(): selection is: ", selection);
    setProfileAnchorEl(null);

    SetCacheItem("profile_menu_selection", selection);
    // history.push("/main");
  }

  const profilePopupOpen = Boolean(profileAnchorEl);
  const profilePopupId = profilePopupOpen ? "simple-popover" : null;

  //******************* */
  // Alert
  //******************* */

  function alertClickAgree() {
    // console.log("In alertClickAgree()");
    // Close Alert
    setAlertOpen(false);
  }

  function alertClickDisagree() {
    // console.log("In alertClickDisagree()");
    // Close Alert
    setAlertOpen(false);
  }

  function alertClose() {
    // console.log("In alertClose()");
    // Close Alert
    setAlertOpen(false);
  }

  // function PrivacyLink() {
  //   return <Link component={ RouterLink } to="/privacy" >Privacy</Link>
  // }

  const GetHomeTemplate = () => {
    switch (selectedHomeTemplate) {
      case "HomeTemplate01":
        return (
          <HomeTemplate01
            currentLanguageCode={currentLanguageCode}
            pageRecord={pageRecord}
          />
        );
      case "PageTemplate02":
        return (
          <HomeTemplate01
            currentLanguageCode={currentLanguageCode}
            pageRecord={pageRecord}
          />
        );
      case "PageTemplate03":
        return (
          <HomeTemplate01
            currentLanguageCode={currentLanguageCode}
            pageRecord={pageRecord}
          />
        );
      case "PageTemplate04":
        return (
          <HomeTemplate01
            currentLanguageCode={currentLanguageCode}
            pageRecord={pageRecord}
          />
        );
      case "PageTemplate05":
        return (
          <HomeTemplate01
            currentLanguageCode={currentLanguageCode}
            pageRecord={pageRecord}
          />
        );
      default:
        return (
          <HomeTemplate01
            currentLanguageCode={currentLanguageCode}
            pageRecord={pageRecord}
          />
        );
    }
  };

  const GetTestimonialTemplate = () => {
    switch (selectedTestimonialsTemplate) {
      case "TestimonialsTemplate01":
        return (
          <TestimonialsTemplate01
            currentLanguageCode={currentLanguageCode}
            designBlockRecord={designBlockRecord}
          />
        );
      case "TestimonialsTemplate02":
        return (
          <TestimonialsTemplate02
            currentLanguageCode={currentLanguageCode}
            designBlockRecord={designBlockRecord}
          />
        );
      case "TestimonialsTemplate03":
        return (
          <TestimonialsTemplate01
            currentLanguageCode={currentLanguageCode}
            designBlockRecord={designBlockRecord}
          />
        );
      case "TestimonialsTemplate04":
        return (
          <TestimonialsTemplate01
            currentLanguageCode={currentLanguageCode}
            designBlockRecord={designBlockRecord}
          />
        );
      case "TestimonialsTemplate05":
        return (
          <TestimonialsTemplate01
            currentLanguageCode={currentLanguageCode}
            designBlockRecord={designBlockRecord}
          />
        );
      default:
        return (
          <TestimonialsTemplate01
            currentLanguageCode={currentLanguageCode}
            designBlockRecord={designBlockRecord}
          />
        );
    }
  };

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* progressFlag &&
            <div style={{textAlign: 'center'}}>
              <CircularProgress className={classes.progress} color="secondary" size={15}/>
            </div>
          */}

        {/* <PageTopHeader
            langPopupId={langPopupId} 
            langPopupOpen={langPopupOpen}
            langAnchorEl={langAnchorEl}
            handleLangClicked={handleLangClicked}
            handleLangClose={handleLangClose}

            langRef={langRef.current}
            currentLanguage={currentLanguage}
            isSignedIn={isSignedIn} 
            currentLanguageName={currentLanguageName} 
            languages={languages} 
            languageSelectChange={languageSelectChange}

            profilePopupId={profilePopupId} 
            profilePopupOpen={profilePopupOpen}
            profileAnchorEl={profileAnchorEl}
            handleProfileClicked={handleProfileClicked}
            handleProfileClose={handleProfileClose}
            profileClicked={profileClicked}

            signedInCallback={signedInCallback}
            direction={direction}
          /> */}

        <div>
          <Grid container>
            <Grid item xs={12} sm={1}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Logo />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={11}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={8}></Grid>

                <Grid item xs={12} sm={2} style={{ marginTop: 4 }}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <SetupLanguages
                      langPopupId={langPopupId}
                      langPopupOpen={langPopupOpen}
                      langAnchorEl={langAnchorEl}
                      handleLangClicked={handleLangClicked}
                      handleLangClose={handleLangClose}
                      langRef={langRef}
                      currentLanguage={currentLanguage}
                      currentLanguageName={currentLanguageName}
                      languages={languages}
                      languageSelectChange={languageSelectChange}
                      selectPreferredLanguage={selectPreferredLanguage}
                      dialogModalOpen={dialogModalFlag}
                      dialogModalClose={dialogModalClose}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={2} style={{ marginTop: 4 }}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    {renderGridFlag && (
                      <UserProfile
                        profilePopupId={profilePopupId}
                        profilePopupOpen={profilePopupOpen}
                        profileAnchorEl={profileAnchorEl}
                        handleProfileClicked={handleProfileClicked}
                        handleProfileClose={handleProfileClose}
                        profileClicked={profileClicked}
                        signedInCallback={signedInCallback}
                        currLanguage={appContext.currentLanguage}
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    {isSignedIn && renderGridFlag && (
                      <AppMainMenu
                        currLanguage={currentLanguage}
                        homePageFlag={homePageFlag}
                        screenDirection={direction}

                        // resizeCallback={resizeCallback}
                        // homeLabel={homeLabel}
                        // eventsLabel={eventsLabel}
                        // tributesLabel={tributesLabel}
                        // galleryLabel={galleryLabel}
                        // intermentLabel={intermentLabel}
                        // appreciationLabel={appreciationLabel}
                        // guestBookLabel={guestBookLabel}
                        // videosLabel={videosLabel}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <AppMainMenu
          currLanguage={currentLanguage}
          homePageFlag={homePageFlag}
          screenDirection={direction}
        />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%", height: "100%" }}>
            <Grid container justify="center">
              <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                <GetHomeTemplate />
              </Grid>

              <LineSpacing value={24} />

              <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                <GetTestimonialTemplate />
              </Grid>

              <LineSpacing value={24} />
            </Grid>

            {alertOpen && (
              <AlertDialog
                alertOpen={alertOpen}
                alertClose={alertClose}
                alertClickAgree={alertClickAgree}
                alertClickDisagree={alertClickDisagree}
                dialogTitle={dialogTitle}
                dialogQuestion={dialogQuestion}
                dialogMainContent={dialogMainContent}
                dialogSubContent={dialogSubContent}
                yesLabel={yesLabel}
                noLabel={noLabel}
              />
            )}

            {/* Other elements can be in between `StickyContainer` and `Sticky`,
                but certain styles can break the positioning logic used. */}
            {/*  <PageTopHeader
                  langPopupId={langPopupId} 
                  langPopupOpen={langPopupOpen}
                  langAnchorEl={langAnchorEl}
                  handleLangClicked={handleLangClicked}
                  handleLangClose={handleLangClose}

                  langRef={langRef.current}
                  currentLanguage={currentLanguage}
                  isSignedIn={isSignedIn} 
                  currentLanguageName={currentLanguageName} 
                  languages={languages} 
                  languageSelectChange={languageSelectChange}

                  profilePopupId={profilePopupId} 
                  profilePopupOpen={profilePopupOpen}
                  profileAnchorEl={profileAnchorEl}
                  handleProfileClicked={handleProfileClicked}
                  handleProfileClose={handleProfileClose}
                  profileClicked={profileClicked}

                  signedInCallback={signedInCallback}
                /> */}

            {/*  <Sticky topOffset={80} bottomOffset={80} relative={false} >
                  {({ style,}) => (
                    <header style={style}>
                      { (direction === 'ltr' ||  direction === 'rtl') &&
                        <MainMenuBar currLanguage={currentLanguage}
                        />
                      }
                    </header>
                  )}
                </Sticky> */}

            {/* <Link component={RouterLink} to="/">
                    <IconButton>
                      <img src="assets/logos/realid.png" style={{width: '128px'}}/>
                    </IconButton>
                  </Link>
                    
                    <Typography component="h2" variant="h1" gutterBottom>
                      {homePage}
                    </Typography> */}

            {/* <Typography component="h2" variant="h1" gutterBottom>
                      h1. Heading
                    </Typography>
                    <Typography variant="h2" gutterBottom>
                      h2. Heading
                    </Typography>
                    <Typography variant="h3" gutterBottom>
                      h3. Heading
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                      h4. Heading
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                      h5. Heading
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      h6. Heading
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom>
                      subtitle1
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                      body1. While a  chorus of Republicans, based on Attorney General William Barr’s four-page summary of Mueller’s report, are treating the matter as closed, other Democrats aren’t ready to move on.

                      “I'm very concerned because I think what the Attorney General did is undermine the purpose of the special counsel's job by summarizing this report and putting his imprimatur on it, he is taking away the benefit of having someone who's non-partisan, not appointed by the president actually making the decision about whether crimes may have been committed,” Sen. Kirsten Gillibrand said Thursday on MSNBC.While a chorus of Republicans, based on Attorney General William Barr’s four-page summary of Mueller’s report, are treating the matter as closed, other Democrats aren’t ready to move on.

                      “I'm very concerned because I think what the Attorney General did is undermine the purpose of the special counsel's job by summarizing this report and putting his imprimatur on it, he is taking away the benefit of having someone who's non-partisan, not appointed by the president actually making the decision about whether crimes may have been committed,” Sen. Kirsten Gillibrand said Thursday on MSNBC.While a chorus of Republicans, based on Attorney General William Barr’s four-page summary of Mueller’s report, are treating the matter as closed, other Democrats aren’t ready to move on.

                      “I'm very concerned because I think what the Attorney General did is undermine the purpose of the special counsel's job by summarizing this report and putting his imprimatur on it, he is taking away the benefit of having someone who's non-partisan, not appointed by the president actually making the decision about whether crimes may have been committed,” Sen. Kirsten Gillibrand said Thursday on MSNBC.While a chorus of Republicans, based on Attorney General William Barr’s four-page summary of Mueller’s report, are treating the matter as closed, other Democrats aren’t ready to move on.

                      “I'm very concerned because I think what the Attorney General did is undermine the purpose of the special counsel's job by summarizing this report and putting his imprimatur on it, he is taking away the benefit of having someone who's non-partisan, not appointed by the president actually making the decision about whether crimes may have been committed,” Sen. Kirsten Gillibrand said Thursday on MSNBC.
                      While a chorus of Republicans, based on Attorney General William Barr’s four-page summary of Mueller’s report, are treating the matter as closed, other Democrats aren’t ready to move on.

                      “I'm very concerned because I think what the Attorney General did is undermine the purpose of the special counsel's job by summarizing this report and putting his imprimatur on it, he is taking away the benefit of having someone who's non-partisan, not appointed by the president actually making the decision about whether crimes may have been committed,” Sen. Kirsten Gillibrand said Thursday on MSNBC.While a chorus of Republicans, based on Attorney General William Barr’s four-page summary of Mueller’s report, are treating the matter as closed, other Democrats aren’t ready to move on.

                      “I'm very concerned because I think what the Attorney General did is undermine the purpose of the special counsel's job by summarizing this report and putting his imprimatur on it, he is taking away the benefit of having someone who's non-partisan, not appointed by the president actually making the decision about whether crimes may have been committed,” Sen. Kirsten Gillibrand said Thursday on MSNBC.While a chorus of Republicans, based on Attorney General William Barr’s four-page summary of Mueller’s report, are treating the matter as closed, other Democrats aren’t ready to move on.

                      “I'm very concerned because I think what the Attorney General did is undermine the purpose of the special counsel's job by summarizing this report and putting his imprimatur on it, he is taking away the benefit of having someone who's non-partisan, not appointed by the president actually making the decision about whether crimes may have been committed,” Sen. Kirsten Gillibrand said Thursday on MSNBC.While a chorus of Republicans, based on Attorney General William Barr’s four-page summary of Mueller’s report, are treating the matter as closed, other Democrats aren’t ready to move on.

                      “I'm very concerned because I think what the Attorney General did is undermine the purpose of the special counsel's job by summarizing this report and putting his imprimatur on it, he is taking away the benefit of having someone who's non-partisan, not appointed by the president actually making the decision about whether crimes may have been committed,” Sen. Kirsten Gillibrand said Thursday on MSNBC.
                    </Typography>

                    <Typography variant="subtitle2" gutterBottom>
                      subtitle2
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                      body2. While a chorus of Republicans, based on Attorney General William Barr’s four-page summary of Mueller’s report, are treating the matter as closed, other Democrats aren’t ready to move on.

                      “I'm very concerned because I think what the Attorney General did is undermine the purpose of the special counsel's job by summarizing this report and putting his imprimatur on it, he is taking away the benefit of having someone who's non-partisan, not appointed by the president actually making the decision about whether crimes may have been committed,” Sen. Kirsten Gillibrand said Thursday on MSNBC.While a chorus of Republicans, based on Attorney General William Barr’s four-page summary of Mueller’s report, are treating the matter as closed, other Democrats aren’t ready to move on.

                      “I'm very concerned because I think what the Attorney General did is undermine the purpose of the special counsel's job by summarizing this report and putting his imprimatur on it, he is taking away the benefit of having someone who's non-partisan, not appointed by the president actually making the decision about whether crimes may have been committed,” Sen. Kirsten Gillibrand said Thursday on MSNBC.While a chorus of Republicans, based on Attorney General William Barr’s four-page summary of Mueller’s report, are treating the matter as closed, other Democrats aren’t ready to move on.

                      “I'm very concerned because I think what the Attorney General did is undermine the purpose of the special counsel's job by summarizing this report and putting his imprimatur on it, he is taking away the benefit of having someone who's non-partisan, not appointed by the president actually making the decision about whether crimes may have been committed,” Sen. Kirsten Gillibrand said Thursday on MSNBC.While a chorus of Republicans, based on Attorney General William Barr’s four-page summary of Mueller’s report, are treating the matter as closed, other Democrats aren’t ready to move on.

                      “I'm very concerned because I think what the Attorney General did is undermine the purpose of the special counsel's job by summarizing this report and putting his imprimatur on it, he is taking away the benefit of having someone who's non-partisan, not appointed by the president actually making the decision about whether crimes may have been committed,” Sen. Kirsten Gillibrand said Thursday on MSNBC.
                    </Typography>

                    <Divider />

                    <Typography variant="button" gutterBottom>
                      button text
                    </Typography>

                    <Typography variant="caption" gutterBottom>
                      caption text
                    </Typography>

                    <Typography variant="overline" gutterBottom>
                      overline text
                    </Typography> */}

            {/* <Divider variant="middle" />

                    <Link component="button" variant="body2" onClick={() => { alert("I'm a button."); }} >
                      Button Link
                    </Link>

                    <Divider light />

                    <Button variant="outlined" href="/signIn" >Sign In Button</Button>
                    
                    <Divider light />

                    <Link component={ RouterLink } to="/signIn">
                      <Typography variant="button" gutterBottom>Sign In Now</Typography>
                    </Link>

                    <Divider variant="middle" />
                  </div>

                  <StyledButton variant="contained" color="primary">Submit Me</StyledButton>

                  <span>Lorem ipsum dolor sit amet, <Link component={ RouterLink } to="/terms">Terms</Link> adipiscing elit,</span>

                  <Text>
                    Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Sed non purus id dolor maximus eleifend nec in nunc.
                    Integer quis lacinia leo, in suscipit orci. Donec enim augue, vehicula vel
                    enim a, <PrivacyLink /> vestibulum tempus nunc.
                  </Text>

                  <LineSpacing value={8} />

                  <FilesUploadToS3 />

                  <LineSpacing value={8} />

                  <JoinMailingList currLanguage={currentLanguage}/>

                  <LineSpacing value={12} /> */}
            {/* </div> */}
            {/* </Container> */}

            {/* <footer className={classes.footer} dir={direction}>
                <div dir={direction}>
                  <PageFooter currLanguage={currentLanguage}/>
                </div>
              </footer> */}
          </div>
        </Container>

        <LineSpacing value={24} />

        <footer className={classes.footer}>
          <div>
            {renderGoogleAppleDomFlag && googleAppleInfoFlag && (
              <PageFooter
                currLanguage={appContext.currentLanguage}
                screenDirection={appContext.direction}
              />
            )}
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Home;
