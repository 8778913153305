import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
// import TextField from '@material-ui/core/TextField';
import Modal from "@material-ui/core/Modal";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Link from '@material-ui/core/Link';
// import Select from '@material-ui/core/Select';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import Select from 'react-select';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";

// Draggable Modal
// import Draggable from 'react-draggable';
// import TextField from "@material-ui/core/TextField";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";

// Validator
// import validator from 'validator';
// import isEmail from 'validator/lib/isEmail';
import isURL from "validator/lib/isURL";
// import contains from 'validator/lib/contains';

// AG-Grid
import { AgGridReact, AgGridColumn } from "ag-grid-react";
//import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

// Amplify
import Amplify, { AUTH, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// import GlobalConstants from '../../utils/GlobalConstants';
// import GeneratePassword from '../../utils/GeneratePassword';
// import GenerateClientId from '../../utils/GenerateClientId';
// import GenerateTransactionId from '../../utils/GenerateTransactionId';
// import GenerateRandomHash from '../../utils/GenerateRandomHash';
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import LineSpacing from "../../components/misc/LineSpacing";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// Renderers
// import NumberCellRenderer from '../../components/renderers/NumberCellRenderer';
import ActionsCellRenderer from "../../components/renderers/ActionsCellRenderer";
// import { create } from 'jss';
// import CurrencyCellRenderer from '../../components/renderers/CurrencyCellRenderer';
// import CountryCellRenderer from '../../components/renderers/CountryCellRenderer';
import ImageCellRenderer from "../../components/renderers/ImageCellRenderer";

import SocialsDraggableDialog from "../../components/dialogs/SocialsDraggableDialog";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    backgroundImage: `url(${"assets/logos/background20.png"})`,
  },
  gridWidth: {
    width: "80%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  mainForm: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  mainGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  textField: {
    width: "100%",
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  formControlLabel: {
    margin: theme.spacing(1),
  },
  verticalAlignLabel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
    textAlign: "center !important",
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  phone: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "row",
  },
  browseIcon: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

let localeText = {
  page: "Page",
  to: "to",
  of: "of",
  noRowsToShow: "No records found",
};

// let localeText2 = {
//   page: "daPage",
//   more: "daMore",
//   to: "daTo",
//   of: "daOf",
//   next: "daNexten",
//   last: "daLasten",
//   first: "daFirsten",
//   previous: "daPreviousen",
//   loadingOoo: "daLoading...",
//   selectAll: "daSelect Allen",
//   searchOoo: "daSearch...",
//   blanks: "daBlanc",
//   filterOoo: "daFilter...",
//   applyFilter: "daApplyFilter...",
//   equals: "daEquals",
//   notEqual: "daNotEqual",
//   lessThan: "daLessThan",
//   greaterThan: "daGreaterThan",
//   lessThanOrEqual: "daLessThanOrEqual",
//   greaterThanOrEqual: "daGreaterThanOrEqual",
//   inRange: "daInRange",
//   contains: "daContains",
//   notContains: "daNotContains",
//   startsWith: "daStarts dawith",
//   endsWith: "daEnds dawith",
//   andCondition: "daAND",
//   orCondition: "daOR",
//   group: "laGroup",
//   columns: "laColumns",
//   filters: "laFilters",
//   rowGroupColumns: "laPivot Cols",
//   rowGroupColumnsEmptyMessage: "la drag cols to group",
//   valueColumns: "laValue Cols",
//   pivotMode: "laPivot-Mode",
//   groups: "laGroups",
//   values: "laValues",
//   pivots: "laPivots",
//   valueColumnsEmptyMessage: "la drag cols to aggregate",
//   pivotColumnsEmptyMessage: "la drag here to pivot",
//   toolPanelButton: "la tool panel",
//   noRowsToShow: "la no rows",
//   pinColumn: "laPin Column",
//   valueAggregation: "laValue Agg",
//   autosizeThiscolumn: "laAutosize Diz",
//   autosizeAllColumns: "laAutsoie em All",
//   groupBy: "laGroup by",
//   ungroupBy: "laUnGroup by",
//   resetColumns: "laReset Those Cols",
//   expandAll: "laOpen-em-up",
//   collapseAll: "laClose-em-up",
//   toolPanel: "laTool Panelo",
//   export: "laExporto",
//   csvExport: "la CSV Exportp",
//   excelExport: "la Excel Exporto (.xlsx)",
//   excelXmlExport: "la Excel Exporto (.xml)",
//   pinLeft: "laPin &lt;&lt;",
//   pinRight: "laPin &gt;&gt;",
//   noPin: "laDontPin &lt;&gt;",
//   sum: "laSum",
//   min: "laMin",
//   max: "laMax",
//   none: "laNone",
//   count: "laCount",
//   average: "laAverage",
//   copy: "laCopy",
//   copyWithHeaders: "laCopy Wit hHeaders",
//   ctrlC: "ctrl n C",
//   paste: "laPaste",
//   ctrlV: "ctrl n V"
// };

// For AG-Grid

//------------------------------
// RENDERERS
//------------------------------
const gridOptions = {
  style: "currency", // decimal, currency, percent
  currency: "USD",
  currencyDisplay: "symbol", // symbol, code, name to use a localized currency name such as "dollar"
  useGrouping: true,
  minimumIntegerDigits: 21,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  payload: {},
  imageWidth: "16px !important", // Will scale image to a width of 32px in ImageCellRenderer component
  divContainerHeight: "32px !important", // Will set div container height to 32px in ImageCellRenderer component to align the image in the middle
  getRowHeight: function (params) {
    // console.log("In getRowHeight: params is: ", params);
    // assuming 50 characters per line, working how how many lines we need
    // console.log("In getRowHeight: words is: ", params.data.socialNetworkName.length);
    // let lines = 36 * (Math.floor(params.data.socialNetworkName.length / 45) + 1);
    // console.log("In getRowHeight: lines is: ", lines);
    let lines = 36;
    return lines;
  },
};

let createSocNetworks = {
  createSocialNetwork: "Create social networks",
  allFieldsAreRequired: "All fields are required",

  languageNameLabel: "Language",
  selectLanguagePrompt: "Please select a language",
  gridRefreshWarning: "If you change language, grid will refresh.",

  socialNetworkCodeLabel: "Code",
  socialNetworkCodePrompt: "Enter social network code",
  socialNetworkCodeMsg: "Examples: facebook, twitter e.t.c",

  socialNetworkNameLabel: "Name",
  socialNetworkNamePrompt: "Enter social network name",
  socialNetworkNameMsg: "Examples: Facebook, Twitter e.t.c",

  socialNetworkAddressUrlLabel: "Address URL",
  socialNetworkAddressUrlPrompt: "Please enter social network address URL",

  // icon: "Icon",
  // socialNetworkIconUrlGridLabel: "Icon URL",
  // socialNetworkIconUrlLabel: "Icon URL (.png & .jpg only)",
  // socialNetworkIconUrlPrompt: "Please enter social network icon URL",
  // socialNetworkIconMsg: "You may also browse to select an icon",

  submit: "Submit",
  deleteLabel: "Delete",
  createSocialsSuccessMsg: "Create social networks was successful.",
  createSocialsFailureMsg: "Error while creating social networks.",
  addToGrid: "Add to grid",
  maxRecordsAllowed:
    "Maximum number of records allowed in grid is {MAX_RECORDS_ALLOWED}.",
  totalNumberRows: "Total number of rows",

  // For Grid
  // gridPage: "Page",
  // gridTo: "to",
  // gridOf: "of",
  noRowsToShow: "No records found",
};

function CreateSocialNetworks(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [socialsLanguage, setSocialsLanguage] = useState(defaultLang.value);
  const [referenceType, setReferenceType] = useState("socials");
  const [socialNetworkCode, setSocialNetworkCode] = useState("");
  const [socialNetworkName, setSocialNetworkName] = useState("");
  const [socialNetworkAddressUrl, setSocialNetworkAddressUrl] = useState("");
  // const [socialNetworkIconUrl, setSocialNetworkIconUrl] = useState('');

  // For modal
  const [modalOpen, setModalOpen] = useState(false);

  // Draggable Modal
  // const [deltaPosition, setDeltaPosition] = useState({x: 0, y: 0});

  const [payload, setPayload] = useState({});
  const [rowData, setRowData] = useState([]);

  const [langSelectOptions, setLangSelectOptions] = useState([]);
  const [refTypeSelectOptions, setRefTypeSelectOptions] = useState([]);

  // AG-Grid
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [progressFlag, setProgressFlag] = useState(false);
  const [addToGridFlag, setAddToGridFlag] = useState(false);
  const [rowSelectedFlag, setRowSelectedFlag] = useState(false);
  const [gridSubmitFlag, setGridSubmitFlag] = useState(false);
  const [gridRowsCount, setGridRowsCount] = useState(0);
  //const [maxRowsFlag, setMaxRowsFlag] = useState(false);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  // const [ confirmCodeFlag, setConfirmCodeFlag ] = useState(false);
  // const [ changePasswordFlag, setChangePasswordFlag ] = useState(false);
  // const [ theOneTimePasswordFlag, setTheOneTimePasswordFlag ] = useState(false);
  // const [ userNotConfirmedFlag, setUserNotConfirmedFlag ] = useState(false);
  // const [ forgotPasswordFlag, setForgotPasswordFlag ] = useState(false);

  // For Error Flags
  const [socialsLanguageErrFlag, setSocialsLanguageErrFlag] = useState(false);
  const [socialNetworkCodeErrFlag, setSocialNetworkCodeErrFlag] = useState(
    false
  );
  const [socialNetworkCodeFocus, setSocialNetworkCodeFocus] = useState(true);
  const [socialNetworkNameErrFlag, setSocialNetworkNameErrFlag] = useState(
    false
  );
  const [socialNetworkNameFocus, setSocialNetworkNameFocus] = useState(false);
  const [
    socialNetworkAddressUrlErrFlag,
    setSocialNetworkAddressUrlErrFlag,
  ] = useState(false);
  // const [socialNetworkIconUrlErrFlag, setSocialNetworkIconUrlErrFlag] = useState(false);

  // Variants
  const [
    socialNetworkAddressUrlVariant,
    setSocialNetworkAddressUrlVariant,
  ] = useState(null);
  // const [socialNetworkIconUrlVariant, setSocialNetworkIconUrlVariant] = useState(null);

  const [redirectSignUpFlag, setRedirectSignUpFlag] = useState(false);
  // const [globalErrorMsg, setGlobalErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  // For Resources
  const [createSocNetworksRes, setCreateSocNetworksRes] = useState(
    createSocNetworks
  );
  const [prevLangCode, setPrevLangCode] = useState(
    appContext.currentLanguage.languageCode
  );

  // For Grid Resources
  const [localeTextRes, setLocaleTextRes] = useState(localeText);

  const [renderGridFlag, setRenderGridFlag] = useState(false);

  //console.log('AmplifyConfig is: ', JSON.stringify(AmplifyConfig, null, 2));
  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  const marginAlignment = {
    flex: "1",
    marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
  };

  const {
    createSocialNetwork,
    allFieldsAreRequired,

    languageNameLabel,
    selectLanguagePrompt,
    gridRefreshWarning,

    socialNetworkCodeLabel,
    socialNetworkCodePrompt,
    socialNetworkCodeMsg,

    socialNetworkNameLabel,
    socialNetworkNamePrompt,
    socialNetworkNameMsg,

    socialNetworkAddressUrlLabel,
    socialNetworkAddressUrlPrompt,

    // icon,
    // socialNetworkIconUrlGridLabel,
    // socialNetworkIconUrlLabel,
    // socialNetworkIconUrlPrompt,
    // socialNetworkIconMsg,

    submit,
    deleteLabel,
    createSocialsSuccessMsg,
    createSocialsFailureMsg,
    addToGrid,
    maxRecordsAllowed,
    totalNumberRows,
  } = createSocNetworksRes;

  function fetchDbResources(cacheItemKey) {
    // console.time("fetchDbResources");
    let keys = [];
    let objKeys = Object.keys(createSocNetworks);
    // console.log("In ManageLanguages(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ManageLanguages(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        // console.table('In fetchDbResources(): response.data.Responses.resource is: ', response.data.Responses.resource);

        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "createSocialNetwork")
              createSocNetworks.createSocialNetwork = item.ResourceDesc;
            if (item.ResourceCode === "allFieldsAreRequired")
              createSocNetworks.allFieldsAreRequired = item.ResourceDesc;
            if (item.ResourceCode === "languageNameLabel")
              createSocNetworks.languageNameLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectLanguagePrompt")
              createSocNetworks.selectLanguagePrompt = item.ResourceDesc;
            if (item.ResourceCode === "gridRefreshWarning")
              createSocNetworks.gridRefreshWarning = item.ResourceDesc;
            if (item.ResourceCode === "socialNetworkCodeLabel")
              createSocNetworks.socialNetworkCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "socialNetworkCodePrompt")
              createSocNetworks.socialNetworkCodePrompt = item.ResourceDesc;
            if (item.ResourceCode === "socialNetworkCodeMsg")
              createSocNetworks.socialNetworkCodeMsg = item.ResourceDesc;
            if (item.ResourceCode === "socialNetworkNameLabel")
              createSocNetworks.socialNetworkNameLabel = item.ResourceDesc;
            if (item.ResourceCode === "socialNetworkNamePrompt")
              createSocNetworks.socialNetworkNamePrompt = item.ResourceDesc;
            if (item.ResourceCode === "socialNetworkNameMsg")
              createSocNetworks.socialNetworkNameMsg = item.ResourceDesc;
            if (item.ResourceCode === "socialNetworkAddressUrlLabel")
              createSocNetworks.socialNetworkAddressUrlLabel =
                item.ResourceDesc;
            if (item.ResourceCode === "socialNetworkAddressUrlPrompt")
              createSocNetworks.socialNetworkAddressUrlPrompt =
                item.ResourceDesc;

            // if (item.ResourceCode === "icon")                           createSocNetworks.icon = item.ResourceDesc;
            // if (item.ResourceCode === "socialNetworkIconUrlGridLabel")  createSocNetworks.socialNetworkIconUrlGridLabel = item.ResourceDesc;
            // if (item.ResourceCode === "socialNetworkIconUrlLabel")      createSocNetworks.socialNetworkIconUrlLabel = item.ResourceDesc;
            // if (item.ResourceCode === "socialNetworkIconUrlPrompt")     createSocNetworks.socialNetworkIconUrlPrompt = item.ResourceDesc;
            // if (item.ResourceCode === "socialNetworkIconMsg")           createSocNetworks.socialNetworkIconMsg = item.ResourceDesc;

            if (item.ResourceCode === "submit")
              createSocNetworks.submit = item.ResourceDesc;
            if (item.ResourceCode === "deleteLabel")
              createSocNetworks.deleteLabel = item.ResourceDesc;
            if (item.ResourceCode === "createSocialsSuccessMsg")
              createSocNetworks.createSocialsSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "createSocialsFailureMsg")
              createSocNetworks.createSocialsFailureMsg = item.ResourceDesc;
            if (item.ResourceCode === "addToGrid")
              createSocNetworks.addToGrid = item.ResourceDesc;
            if (item.ResourceCode === "maxRecordsAllowed")
              createSocNetworks.maxRecordsAllowed = item.ResourceDesc;
            if (item.ResourceCode === "totalNumberRows")
              createSocNetworks.totalNumberRows = item.ResourceDesc;

            // For Grid
            // if (item.ResourceCode === "gridPage") localeText.page = item.ResourceDesc;
            // if (item.ResourceCode === "gridTo") localeText.to = item.ResourceDesc;
            // if (item.ResourceCode === "gridOf") localeText.from = item.ResourceDesc;
            if (item.ResourceCode === "noRowsToShow")
              localeText.noRowsToShow = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, createSocNetworks);
        }

        setCreateSocNetworksRes(createSocNetworks);
        setLocaleTextRes(localeText);

        setColumnDefs(columnDefsArray);

        setRenderGridFlag(true);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.error(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setCreateSocNetworksRes(createSocNetworks);
        setProgressFlag(false);
      });
    console.timeEnd("fetchDbResources");
  }

  // Maximum number of rows allowed in the grid
  const MAX_RECORDS_ALLOWED = 50;

  let selectOptions = [];

  function fetchLanguages() {
    // console.log('In fetchLanguages()');
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages(): response.length is: ', response.data.Items.length);
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            // label: item.LanguageName,
            text: item.LanguageName,
          };
          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.error(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In useEffect()");

    let currLangCode = appContext.currentLanguage.languageCode;
    // console.log("In useEffect(): currLangCode is, ", currLangCode);
    if (currLangCode !== prevLangCode) {
      setPrevLangCode(currLangCode);
      setCreateSocNetworksRes(createSocNetworks);
    }
  });

  useLayoutEffect(() => {
    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In CreateSocialNetworks(): useLayoutEffect(): currentLanguage is: ', currentLanguage);

    setSocialNetworkCodeFocus(true);
    setSocialNetworkNameFocus(false);

    if (selectOptions.length === 0) {
      fetchLanguages();
    }

    // Fetch resource from catche
    let cacheItemKey = "createSocNetworks";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ManageLanguages(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ManageLanguages(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ManageLanguages(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ManageLanguages(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ManageLanguages(): useLayoutEffect(): got resource from cache');
      setCreateSocNetworksRes(cacheItem);
    }
  }, []);

  function handleChange(event) {
    // console.log("In handleChange(): id is: "+event.target.id);
    // console.log("In handleChange(): value is: "+event.target.value);
    setGlobalErrorFlag(false);
    setAddToGridFlag(false);

    // Variants
    setSocialNetworkAddressUrlVariant("");
    // setSocialNetworkIconUrlVariant('');

    switch (event.target.id) {
      case "socialNetworkCodeId":
        setSocialNetworkCodeErrFlag(false);
        setSocialNetworkCode(event.target.value);

        // Enable add to grid button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          // socialNetworkCode !== undefined &&
          // socialNetworkCode !== null &&
          // socialNetworkCode !== "" &&

          socialNetworkName !== undefined &&
          socialNetworkName !== null &&
          socialNetworkName !== "" &&
          socialsLanguage !== undefined &&
          socialsLanguage !== null &&
          socialsLanguage !== "" &&
          socialNetworkAddressUrl !== undefined &&
          socialNetworkAddressUrl !== null &&
          socialNetworkAddressUrl !== ""

          // socialNetworkIconUrl !== undefined &&
          // socialNetworkIconUrl !== null &&
          // socialNetworkIconUrl !== ""
        ) {
          setAddToGridFlag(true);
        }
        break;
      case "socialNetworkNameId":
        setSocialNetworkNameErrFlag(false);
        setSocialNetworkName(event.target.value);

        // Enable add to grid button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          socialNetworkCode !== undefined &&
          socialNetworkCode !== null &&
          socialNetworkCode !== "" &&
          // socialNetworkName !== undefined &&
          // socialNetworkName !== null &&
          // socialNetworkName !== "" &&

          socialsLanguage !== undefined &&
          socialsLanguage !== null &&
          socialsLanguage !== "" &&
          socialNetworkAddressUrl !== undefined &&
          socialNetworkAddressUrl !== null &&
          socialNetworkAddressUrl !== ""

          // socialNetworkIconUrl !== undefined &&
          // socialNetworkIconUrl !== null &&
          // socialNetworkIconUrl !== ""
        ) {
          setAddToGridFlag(true);
        }
        break;
      case "socialNetworkAddressUrlId":
        setSocialNetworkAddressUrlErrFlag(false);
        setSocialNetworkAddressUrl(event.target.value);

        // Enable add to grid button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          socialNetworkCode !== undefined &&
          socialNetworkCode !== null &&
          socialNetworkCode !== "" &&
          socialNetworkName !== undefined &&
          socialNetworkName !== null &&
          socialNetworkName !== "" &&
          socialsLanguage !== undefined &&
          socialsLanguage !== null &&
          socialsLanguage !== ""

          // socialNetworkAddressUrl !== undefined &&
          // socialNetworkAddressUrl !== null &&
          // socialNetworkAddressUrl !== "" &&

          // socialNetworkIconUrl !== undefined &&
          // socialNetworkIconUrl !== null &&
          // socialNetworkIconUrl !== ""
        ) {
          setAddToGridFlag(true);
        }
        break;
      // case "socialNetworkIconUrlId":
      //   setSocialNetworkIconUrlErrFlag(false);
      //   setSocialNetworkIconUrl(event.target.value);

      //   // Enable add to grid button
      //   if (
      //     event.target.value !== undefined &&
      //     event.target.value !== null &&
      //     event.target.value !== "" &&

      //     socialNetworkCode !== undefined &&
      //     socialNetworkCode !== null &&
      //     socialNetworkCode !== "" &&

      //     socialNetworkName !== undefined &&
      //     socialNetworkName !== null &&
      //     socialNetworkName !== "" &&

      //     socialsLanguage !== undefined &&
      //     socialsLanguage !== null &&
      //     socialsLanguage !== "" &&

      //     socialNetworkAddressUrl !== undefined &&
      //     socialNetworkAddressUrl !== null &&
      //     socialNetworkAddressUrl !== ""

      //     // socialNetworkIconUrl !== undefined &&
      //     // socialNetworkIconUrl !== null &&
      //     // socialNetworkIconUrl !== ""
      //   ) {
      //     setAddToGridFlag(true);
      //   }
      //   break;
      default:
        break;
    }
  }

  function validateForm() {
    let formErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setSocialNetworkCodeErrFlag(false);
    setSocialNetworkNameErrFlag(false);

    // Variants
    setSocialNetworkAddressUrlVariant("");
    // setSocialNetworkIconUrlVariant('');

    if (socialsLanguage === null || socialsLanguage === "") {
      formErrFlag = true;
      setSocialsLanguageErrFlag(true);
    }

    if (referenceType === null || referenceType === "") {
      formErrFlag = true;
      setSocialsLanguageErrFlag(true);
    }

    if (socialNetworkCode === null || socialNetworkCode === "") {
      formErrFlag = true;
      setSocialNetworkCodeErrFlag(true);
    }

    if (socialNetworkName === null || socialNetworkName === "") {
      formErrFlag = true;
      setSocialNetworkNameErrFlag(true);
    }

    if (socialNetworkAddressUrl === null || socialNetworkAddressUrl === "") {
      formErrFlag = true;
      setSocialNetworkAddressUrlErrFlag(true);
    } else if (!isURL(socialNetworkAddressUrl)) {
      formErrFlag = true;
      setSocialNetworkAddressUrlErrFlag(true);
      setSocialNetworkAddressUrlVariant("danger");
    }

    // if (socialNetworkIconUrl === null || socialNetworkIconUrl === "") {
    //   formErrFlag = true;
    //   // setSocialNetworkIconUrlErrFlag(true);
    // } /*else if (!(contains(socialNetworkIconUrl.toLowerCase(), ".png") || contains(socialNetworkIconUrl.toLowerCase(), ".jpg") || contains(socialNetworkIconUrl.toLowerCase(), ".jpeg"))) {
    //   formErrFlag = true;
    //   setSocialNetworkAddressUrlErrFlag(true);
    //   setSocialNetworkIconUrlVariant('danger');
    // }*/

    return formErrFlag;
  }

  const getResourceIconName = (url) => {
    if (url && url.length > 0) {
      if (url.toLowerCase().includes("facebook")) {
        return "facebook";
      } else if (url.toLowerCase().includes("twitter")) {
        return "twitter";
      } else if (url.toLowerCase().includes("linkedin")) {
        return "linkedin";
      } else if (url.toLowerCase().includes("youtube")) {
        return "youtube";
      } else if (url.toLowerCase().includes("instagram")) {
        return "instagram";
      } else if (url.toLowerCase().includes("vimeo")) {
        return "vimeo";
      } else if (url.toLowerCase().includes("pinterest")) {
        return "pinterest";
      } else if (url.toLowerCase().includes("reddit")) {
        return "reddit";
      }
    }
    return null;
  };

  async function socialNetworksService() {
    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    // Get Grid data
    const gridRowCount = getRowCount();
    // console.log('In socialNetworksService(): gridRowCount is: ', gridRowCount);

    if (gridRowCount > 0) {
      // we have data to save
      // console.log('We have data to save.');

      const rowsData = [];

      // const d = new Date();
      const timestamp = new Date();
      // const timestamp = d.toISOString();
      // console.log("In socialNetworksService(): timestamp is: ", timestamp);

      gridApi.forEachNode(function (node) {
        // Process each node (row)
        let iconName = getResourceIconName(node.data.socialNetworkAddressUrl);
        // console.log("In socialNetworksService(): iconName is: ", iconName);

        let rowData = {
          ResourcePK: node.data.referenceTypeCode, // socials
          ResourceSK:
            node.data.socialsLanguageCode + ":" + node.data.socialNetworkCode,
          LanguageCode: node.data.socialsLanguageCode,

          ResourceDesc: node.data.socialNetworkName,
          ResourceCode: node.data.socialNetworkCode,

          ResourceAddressUrl: node.data.socialNetworkAddressUrl,
          // "ResourceIconUrl": node.data.socialNetworkIconUrl,
          ResourceIconName: iconName,

          ModifiedBy: appContext.userName,
          CreatedAt: timestamp,
          UpdatedAt: timestamp,
        };

        rowsData.push(rowData);
      });

      let params = {
        apiName: "ResourceAPI",
        path: "/dynamodb/resources/batchWrite",
        payload: {
          items: rowsData,
        },
      };

      let initObj = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        response: true, // return entire response object instead of response.data
        body: {},
      };

      initObj.headers.Authorization = appContext.jwtToken;
      initObj.body = params.payload;
      // console.log('In socialNetworksService(): initObj is: ', JSON.stringify(initObj, null, 2));

      setProgressFlag(true);

      API.post(params.apiName, params.path, initObj)
        .then((response) => {
          // console.log('In socialNetworksService(): response is: ', JSON.stringify(response, null, 2));

          // Clear the grid of any data
          clearGridData();

          // Show Snackbar
          setSnackbarsVariant("success");
          setSnackbarsMsg(createSocialsSuccessMsg);
          setSnackbarOpen(true);

          setProgressFlag(false);
        })
        .catch((err) => {
          console.error(
            "In socialNetworksService(): err is: ",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setGlobalErrorMsg(createSocialsFailureMsg);

          setProgressFlag(false);
        });
    }
  }

  async function redirectSignUp() {
    setRedirectSignUpFlag(true);
  }

  //===================================================

  gridOptions.payload = payload;

  // Grid Functions

  const deleteRows = (e) => {
    //console.log("In deleteRows(): gridApi is: ", gridApi);
    //console.log("In deleteRows(): gridApi.getSelectedNodes() is: ", gridApi.getSelectedNodes());
    var selectedRows = gridApi.getSelectedRows();
    var res = gridApi.updateRowData({ remove: selectedRows });
    // console.log("In deleteRows(): res is: ", res);

    // Update number of rows in grid
    getRowCount();
    initializeForm();

    // Disable Submit button if need be
    let gridRowsCount = gridApi.getModel().getRowCount();
    if (gridRowsCount === 0) {
      setGridSubmitFlag(false);
    }
  };

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
  }

  const onCellClicked = (event) => {
    // console.log('onCellClicked: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellClicked: event.colDef.field is: ', event.colDef.field);
    if (event.colDef.field === "delete") {
      var selectedData = gridApi.getSelectedRows();
      var res = gridApi.updateRowData({ remove: selectedData });
      // console.log("In onCellClicked() res is: ", res);

      // Update number of rows in grid
      getRowCount();
      initializeForm();

      // Disable Submit button if need be
      let gridRowsCount = gridApi.getModel().getRowCount();
      if (gridRowsCount === 0) {
        setGridSubmitFlag(false);
      }
    }
  };

  const onCellValueChanged = (event) => {
    // console.log('onCellValueChanged: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellValueChanged: event.colDef.field is: ', event.colDef.field);
  };

  const onRowSelected = (event) => {
    //console.log('onRowSelected: event is: ', event);
    //console.log('onRowSelected: event.data is: ', JSON.stringify(event.data, null, 2));
    setRowSelectedFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getSelectedNodes().length > 0
    ) {
      setRowSelectedFlag(true);
      // console.log('onRowSelected: after rowSelectedFlag is: ', rowSelectedFlag);
    }
  };

  const onRowClicked = (event) => {
    // console.log('onRowClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onRowClicked: event.data is: ', JSON.stringify(event.data, null, 2));
  };

  const onRowValueChanged = (event) => {
    // console.log('onRowValueChanged: event is:  ', event);
  };

  const onRowDataChanged = (event) => {
    // console.log('onRowDataChanged: event is: ', event);
    setCreateSocNetworksRes(createSocNetworks);
    setLocaleTextRes(localeText);
    setGridSubmitFlag(false);

    setColumnDefs(columnDefsArray);

    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getModel().getRowCount() > 0
    ) {
      // console.log('onRowDataChanged: gridApi.getModel().getRowCount() is: ', gridApi.getModel().getRowCount());
      setGridSubmitFlag(true);
    }
  };

  const onRowDataUpdated = (event) => {
    // console.log('onRowDataUpdated: event is:  ', event);
  };

  const onFirstDataRendered = (event) => {
    // console.log('onFirstDataRendered: event is:  ', event);
  };

  function clearGridData() {
    if (gridApi) {
      gridApi.setRowData([]);

      getRowCount();

      // Disable Submit button
      setGridSubmitFlag(false);
    }
  }

  function initializeForm() {
    // console.log("In initializeForm()");

    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setSocialNetworkCode("");
    setSocialNetworkName("");

    setSocialNetworkAddressUrl("");
    // setSocialNetworkIconUrl("");

    setSocialNetworkCodeFocus(true);
  }

  function getRowCount() {
    let gridRowsCount = gridApi.getModel().getRowCount();
    setGridRowsCount(gridRowsCount);
    return gridRowsCount;
  }

  function addRecordToGrid() {
    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    if (!validateForm()) {
      // Validation Passed - you can proceed
      // console.log('Validation Passed.');
      setAddToGridFlag(false);
      setGridSubmitFlag(true);

      // Make sure max records allowed in grid is not exceeded
      if (getRowCount() >= MAX_RECORDS_ALLOWED) {
        setGlobalErrorMsg(
          maxRecordsAllowed.replace(
            "{MAX_RECORDS_ALLOWED}",
            MAX_RECORDS_ALLOWED
          )
        );
        setGlobalErrorFlag(true);
        return;
      }

      // Add form data to grid
      let newItem = {
        socialsLanguageCode: socialsLanguage,
        socialsLanguageName: socialsLanguage,
        referenceTypeCode: referenceType,
        referenceTypeName: referenceType,
        socialNetworkCode: socialNetworkCode,
        socialNetworkName: socialNetworkName,
        socialNetworkAddressUrl: socialNetworkAddressUrl,
        // socialNetworkIconUrl: socialNetworkIconUrl,
        // Icon: socialNetworkIconUrl,
      };
      // console.log("In addRecordToGrid(): newItem is: ", JSON.stringify(newItem, null, 2));
      var res = gridApi.updateRowData({ add: [newItem] });
      // console.log("In addRecordToGrid(): res is: ", res);

      // Update number of rows in grid
      getRowCount();

      // Initialize Variables
      initializeForm();
    }
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    setSnackbarOpen(false);
  }

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);
    setSocialsLanguage(selectedOption);

    // setSocialsLanguage(selectedOption);
    setSocialNetworkCodeFocus(true);

    setAddToGridFlag(false);

    if (
      socialNetworkCode !== undefined &&
      socialNetworkCode !== null &&
      socialNetworkCode !== "" &&
      socialNetworkName !== undefined &&
      socialNetworkName !== null &&
      socialNetworkName !== "" &&
      socialNetworkAddressUrl !== undefined &&
      socialNetworkAddressUrl !== null &&
      socialNetworkAddressUrl !== "" &&
      // socialNetworkIconUrl !== undefined &&
      // socialNetworkIconUrl !== null &&
      // socialNetworkIconUrl !== "" &&

      referenceType !== undefined &&
      referenceType !== null &&
      referenceType !== "" &&
      selectedOption !== undefined &&
      selectedOption !== null
    ) {
      setAddToGridFlag(true);
    }
  }

  function cellStyle(params) {
    return { "white-space": "normal" };
  }

  //-----------------------------------
  // For Draggable Modal Dialog
  //-----------------------------------

  function browseSocialIcons(event) {
    // console.log('In browseSocialIcons(): event is: ', event);
    setModalOpen(true);
  }

  // const socialsDraggableAction = () => {
  //   // console.log('In socialsDraggableAction()');
  //   setModalOpen(false);
  // };

  const socialsDraggableClose = () => {
    // console.log('In socialsDraggableClose()');
    setModalOpen(false);
  };

  const onRowClickedSocials = (event) => {
    // console.log('onRowClickedSocials: event.rowIndex is: ', event.rowIndex);
    // console.log('onRowClickedSocials: event.data is: ', JSON.stringify(event.data, null, 2));
    // setSocialNetworkIconUrl(event.data.IconUrl);
    setModalOpen(false);
  };

  // const socIcon = <img src={socialNetworkIconUrl} style={{width: '16px', margin: '5px'}} />;

  //---------------------
  // AG-GRID Definitions
  //---------------------

  const columnDefsArray = [
    {
      width: 30,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressMenu: true,
      // filter: false,
      // pinned: false,
      resizable: true,

      // cellRenderer: "agGroupCellRenderer",
      // cellRendererParams: { checkbox: true }
    },
    // {
    //     headerName: icon,
    //     field: "Icon",
    //     width: 75,
    //     filter: false,
    //     sortable: false,
    //     resizable: true,
    //     editable: false,
    //     suppressMenu: true,
    //     cellRendererFramework: ImageCellRenderer
    // },
    {
      headerName: languageNameLabel,
      headerClass: classes.gridHeader,
      field: "socialsLanguageCode",
      width: 120,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: languageNameLabel,
      headerClass: classes.gridHeader,
      field: "socialsLanguageName",
      width: 120,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: "referenceTypCodeeLabel",
      headerClass: classes.gridHeader,
      field: "referenceTypeCode",
      width: 120,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: "referenceTypeNameLabel",
      headerClass: classes.gridHeader,
      field: "referenceTypeName",
      width: 150,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: socialNetworkCodeLabel,
      headerClass: classes.gridHeader,
      field: "socialNetworkCode",
      width: 150,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: false,
    },
    {
      headerName: socialNetworkNameLabel,
      headerClass: classes.gridHeader,
      field: "socialNetworkName",
      width: 150,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      cellStyle: cellStyle,
      hide: false,
    },
    {
      headerName: socialNetworkAddressUrlLabel,
      headerClass: classes.gridHeader,
      field: "socialNetworkAddressUrl",
      width: 150,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      cellStyle: cellStyle,
      hide: false,
    },
    // {
    //   headerName: socialNetworkIconUrlGridLabel,
    //   headerClass: classes.gridHeader,
    //   field: "socialNetworkIconUrl",
    //   width: 175,
    //   pinned: false,
    //   editable: false,
    //   resizable: true,
    //   filter: true,
    //   sortable: true,
    //   cellStyle: cellStyle,
    //   hide: false,
    // },
    {
      headerName: deleteLabel,
      field: "delete",
      width: 90,
      filter: false,
      sortable: false,
      resizable: true,
      cellRendererFramework: ActionsCellRenderer,
    },
  ];
  const [columnDefs, setColumnDefs] = useState(columnDefsArray);

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { !appContext.signedIn &&
          <Redirect to='/signIn' />
        } */}

        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%", height: "100%" }}>
            {/* progressFlag &&
              <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
              </div>
            */}
            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="md"
            >
              <Grid container justify="center">
                <Grid item xs={12} sm={9}>
                  <StyledPaper elevation={3} className={classes.paper}>
                    <Typography
                      variant="h6"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      {createSocialNetwork}
                    </Typography>

                    <Typography
                      variant="caption"
                      style={{ color: "green", textAlign: "center" }}
                    >
                      {allFieldsAreRequired}
                    </Typography>

                    {globalErrorFlag && (
                      <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                    )}

                    <LineSpacing value={24} />

                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="formLanguageId"
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {languageNameLabel}
                        </Typography>
                      </label>
                      <Select
                        id="formLanguageId"
                        value={socialsLanguage}
                        placeholder={selectLanguagePrompt}
                        onChange={handleLanguageChange}
                        variant="outlined"
                        displayEmpty={true}
                        autoFocus
                        fullWidth
                        style={{ width: "100%", flexGrow: 1 }}
                      >
                        <MenuItem value={null} disabled>
                          <Typography variant="body1">
                            {selectLanguagePrompt}
                          </Typography>
                        </MenuItem>
                        {langSelectOptions.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <LineSpacing value={12} />

                    <Grid container justify="center">
                      <Grid item style={marginAlignment}>
                        <FormControl
                          className={classes.textField}
                          variant="outlined"
                        >
                          <label
                            htmlFor="socialNetworkCodeId"
                            style={directionAlignment}
                          >
                            <Typography variant="subtitle1">
                              {socialNetworkCodeLabel}
                            </Typography>
                          </label>
                          <OutlinedInput
                            id="socialNetworkCodeId"
                            value={socialNetworkCode}
                            placeholder={socialNetworkCodePrompt}
                            onChange={handleChange}
                            required
                            fullWidth
                            // autoFocus
                            type={"text"}
                            error={socialNetworkCodeErrFlag}
                            margin="none"
                          />
                        </FormControl>
                      </Grid>

                      <Grid item style={{ flex: "1" }}>
                        <FormControl
                          className={classes.textField}
                          variant="outlined"
                        >
                          <label
                            htmlFor="socialNetworkNameId"
                            style={directionAlignment}
                          >
                            <Typography variant="subtitle1">
                              {socialNetworkNameLabel}
                            </Typography>
                          </label>
                          <OutlinedInput
                            id="socialNetworkNameId"
                            value={socialNetworkName}
                            placeholder={socialNetworkNamePrompt}
                            onChange={handleChange}
                            required
                            fullWidth
                            // autoFocus
                            type={"text"}
                            error={socialNetworkNameErrFlag}
                            margin="none"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <LineSpacing value={12} />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <label
                        htmlFor="socialNetworkAddressUrlId"
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {socialNetworkAddressUrlLabel}
                        </Typography>
                      </label>
                      <OutlinedInput
                        id="socialNetworkAddressUrlId"
                        value={socialNetworkAddressUrl}
                        placeholder={socialNetworkAddressUrlPrompt}
                        onChange={handleChange}
                        required
                        fullWidth
                        // autoFocus
                        type={"text"}
                        error={socialNetworkAddressUrlErrFlag}
                        margin="none"
                      />
                    </FormControl>

                    {/* <LineSpacing value={12} />

                    <Grid container justify="center">
                      <Grid item style={{flex: '1'}}>
                        <FormControl className={classes.textField} variant="outlined">
                          <label htmlFor="socialNetworkIconUrlId" style={directionAlignment}>
                            <Typography variant="subtitle1">{socialNetworkIconUrlLabel}</Typography>
                          </label>
                          <OutlinedInput
                            id="socialNetworkIconUrlId"
                            value={socialNetworkIconUrl}
                            placeholder={socialNetworkIconUrlPrompt}
                            onChange={handleChange} 
                            required
                            fullWidth
                            // autoFocus
                            type={"text"}
                            error={socialNetworkIconUrlErrFlag}
                            margin="none"
                            // endAdornment={<InputAdornment position="end">Kg</InputAdornment>}
                            startAdornment={ <InputAdornment position="start">
                              {socialNetworkIconUrl === '' ? null : socIcon}
                            </InputAdornment>}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item className={classes.browseIcon} onClick={browseSocialIcons}>
                        <ImageSearchIcon color="primary" style={{cursor: 'pointer', marginTop: '30px', fontSize: 40}} />
                      </Grid>
                    </Grid> */}

                    <LineSpacing value={18} />

                    <Button
                      variant="contained"
                      size="large"
                      disabled={!addToGridFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={addRecordToGrid}
                    >
                      <AddIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {addToGrid}
                    </Button>
                  </StyledPaper>
                </Grid>
              </Grid>
            </Container>

            {/* AG-GRID */}

            {renderGridFlag && (
              <Container
                component="div"
                className={classes.mainGrid}
                maxWidth="lg"
              >
                <Grid container justify="center">
                  <Grid item xs>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!rowSelectedFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={deleteRows}
                      style={directionFloat}
                    >
                      <DeleteIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {deleteLabel}
                    </Button>
                  </Grid>

                  <Grid item xs>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!gridSubmitFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={socialNetworksService}
                      style={directionReverseFloat}
                    >
                      <SaveIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {submit}
                    </Button>
                  </Grid>
                </Grid>

                <div style={{ width: "100%", height: "100%" }}>
                  <div
                    id="refGrid"
                    style={{
                      // height: "400px",
                      width: "100%",
                    }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      localeText={localeTextRes}
                      domLayout="autoHeight"
                      onGridReady={onGridReady}
                      columnDefs={columnDefs}
                      rowData={rowData}
                      rowSelection="multiple"
                      rowMultiSelectWithClick={true}
                      rowDeselection={true}
                      enablePivot={true}
                      enableValue={true}
                      gridOptions={gridOptions}
                      enableRtl={enableRtl}
                      onCellClicked={onCellClicked}
                      onCellValueChanged={onCellValueChanged}
                      onRowSelected={onRowSelected}
                      onRowClicked={onRowClicked}
                      onRowValueChanged={onRowValueChanged}
                      onRowDataChanged={onRowDataChanged}
                      onRowDataUpdated={onRowDataUpdated}
                      onFirstDataRendered={onFirstDataRendered}
                    />
                  </div>
                </div>

                <Typography
                  variant="caption"
                  color="primary"
                  style={directionReverseFloat}
                >
                  {totalNumberRows}: {gridRowsCount}
                </Typography>
              </Container>
            )}
          </div>

          {/* variant: 'success', 'warning', 'error', 'info' */}
          <TsiCustomizedSnackbars
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={8000}
            onClose={snackbarOnClose}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">{snackbarsMsg}</span>}
            variant={snackbarsVariant}
          />

          {modalOpen && (
            <SocialsDraggableDialog
              currLanguage={currentLanguage}
              dialogOpen={modalOpen}
              onDialogClose={socialsDraggableClose}
              onRowClickedSocials={onRowClickedSocials}
            />
          )}
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter isSignedIn={isSignedIn} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default CreateSocialNetworks;
