/*let option = {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
};*/

//  https://atrilsolutions.zendesk.com/hc/en-us/articles/360010413820--Language-Abbreviations-and-Language-Code-Identifiers-LCID-

function FormatNumberWithOption(amount, localeCode, option) {
    // let languageCode = "en-US";

    // // Reformat to proper format
    // if (localeCode) {
    //     let lang = null;

    //     switch(localeCode) {
    //         case "en":
    //             lang = "en-US";
    //         break;
    //         case "zh":
    //             lang = "zh-CN";
    //         break;
    //         default:
    //             lang = "en-US";
    //             break;

    //     }
    //     const locales1 = [lang];
    //     const options1 = { localeMatcher: 'lookup' };
        
    //     let supportedLocales = Intl.NumberFormat.supportedLocalesOf(locales1, options1);
    //     console.log('In FormatNumberWithOption(): supportedLocales is: ', supportedLocales);
    //     if (supportedLocales && supportedLocales.length > 0) {
    //         languageCode = supportedLocales[0];
    //     }
    // }
    // console.log('In FormatNumberWithOption(): languageCode is: ', languageCode);
    return new Intl.NumberFormat(localeCode, option).format(amount/100.00);
};
  
export default FormatNumberWithOption;
