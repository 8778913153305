import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import EditIcon from "@material-ui/icons/Edit";
import PageviewIcon from "@material-ui/icons/Pageview";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
  editIcon: {
    margin: theme.spacing(1),
    // fontSize: 12,
    cursor: "pointer",
    color: "green",
  },
  deleteIcon: {
    margin: theme.spacing(1),
    // fontSize: 12,
    cursor: "pointer",
    color: "red",
  },
}));

function ActionsCellRenderer(props) {
  const { value, api } = props;

  const {
    style,
    currency,
    currencyDisplay,
    useGrouping,
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,

    payload,
  } = props.agGridReact.gridOptions;

  const classes = useStyles();

  function updateItems() {
    var itemsToUpdate = [];
    api.forEachNodeAfterFilterAndSort(function (rowNode, index) {
      if (index >= 5) {
        return;
      }
      var data = rowNode.data;
      data.price = Math.floor(Math.random() * 20000 + 20000);
      itemsToUpdate.push(data);
    });
    var res = api.updateRowData({ update: itemsToUpdate });
    // console.log("In updateItems() res is: ", res);
  }

  // function deleteGridRow(){
  //     console.log(">>>>In deleteGridRow(): gridRowsCount is: ", gridRowsCount);

  //     var selectedData = api.getSelectedRows();
  //     var res = api.updateRowData({ remove: selectedData });

  //     console.log("In deleteGridRow() res is: ", res);
  // }

  function editGridRow() {
    // console.log(">>>>In editGridRow(): payload is: ", JSON.stringify(payload, null, 2));

    var selectedData = api.getSelectedRows();
    // var res = api.updateRowData({ remove: selectedData });

    // console.log("In editGridRow() res is: ", res);
  }

  // console.log("In ActionsCellRenderer(): value is: ", value);
  // console.log("In ActionsCellRenderer(): props is: ", props);
  // console.log("In ActionsCellRenderer(): props.column.colId is: ", props.column.colId);
  // console.log("In ActionsCellRenderer(): props.column.colDef.headerName is: ", props.column.colDef.headerName);
  // console.log("In ActionsCellRenderer(): props.column.colDef.field is: ", props.column.colDef.field);
  // const columnId = props.column.colId;
  const headerName = props.column.colDef.headerName;
  // const colDefId = props.column.colDef.id;
  const colDefField = props.column.colDef.field;
  return (
    <div>
      {/*<DeleteForeverRoundedIcon className={classes.deleteIcon} onClick={deleteGridRow} />*/}
      {(colDefField === "delete" ||
        colDefField === "delete" ||
        headerName === "Delete") && (
        <DeleteForeverRoundedIcon className={classes.deleteIcon} />
      )}

      {(colDefField === "edit" ||
        colDefField === "edit" ||
        headerName === "Edit") && (
        <EditIcon className={classes.editIcon} onClick={editGridRow} />
      )}

      {(colDefField === "view" ||
        colDefField === "view" ||
        headerName === "View") && (
        <PageviewIcon className={classes.editIcon} onClick={editGridRow} />
      )}

      {(colDefField === "select" ||
        colDefField === "select" ||
        headerName === "Select") && (
        <CheckIcon className={classes.editIcon} onClick={editGridRow} />
      )}
    </div>
  );
}

export default ActionsCellRenderer;
