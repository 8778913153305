import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

import SocialIcon from "./SocialIcon";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

// AppContext
import AppContext from "../../config/AppContext";

Amplify.configure(AmplifyConfig);

const PageContainer = styled.div`
  width: 100%,
  display: 'flex',
  flexWrap: 'wrap',
  background: #fff;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
  },
  progress: {
    margin: theme.spacing(1),
  },
  mainGrid: {
    textAlign: "left",
  },
  socials: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  hdr: {
    color: "#fff",
  },
  prodDesc: {
    color: "fff",
    fontSize: "12px",
    marginLeft: theme.spacing(1),
    whiteSpace: "nowrap",
  },
  link: {
    color: "#fff",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  textItem: {
    color: "#fff",
    whiteSpace: "nowrap",
  },
  image: {
    width: "18px !important", // '16px !important'
    background: "transparent",
    verticalAlign: "middle",
  },
  socGrid: {
    color: "#fff",
    fontSize: "12px",
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    whiteSpace: "nowrap",
    backgroundColor: "transparent",
  },
}));

let socials = {
  socialsHdr: "Socials",
  socialsDesc: "Connect with us",
};

function SocialNetworksFooter(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  // For Socials
  const [socialsRes, setSocialsRes] = useState(socials);
  const [socialNetworkData, setSocialNetworkData] = useState([]);

  const { currLanguage } = props;

  const classes = useStyles();

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  const { socialsHdr, socialsDesc } = socialsRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(socials);
    // console.log("In SocialNetworksFooter(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In SocialNetworksFooter(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "socialsHdr")
              socials.socialsHdr = item.ResourceDesc;
            if (item.ResourceCode === "socialsDesc")
              socials.socialsDesc = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, socials);
          // setSocialsRes(socials);
        }

        setSocialsRes(socials);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.error(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setSocialsRes(socials);
        setProgressFlag(false);
      });
  }

  // Fetch SocialNetworks Data
  async function fetchDbSocialNetworks() {
    // console.log('In fetchDbSocialNetworks(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);

    setProgressFlag(true);

    let arrayData = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "socials",
          ":resourceSK": appContext.currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbSocialNetworks(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbSocialNetworks(): response length is: ', response.data.Items.length);
        // console.log('In fetchDbSocialNetworks(): response is: ', JSON.stringify(response.data.Items, null, 2));
        if (response.data.Items.length > 0) {
          response.data.Items.map((item, index) => {
            let record = {
              id: "" + index,
              LanguageCode: item.LanguageCode,
              ResourcePK: item.ResourcePK,
              ResourceCode: item.ResourceCode,
              ResourceDesc: item.ResourceDesc,
              ResourceAddressUrl: item.ResourceAddressUrl,
              ResourceIconName: item.ResourceIconName,
              // Icon: item.ResourceIconUrl,
              ModifiedBy: item.ModifiedBy,
              CreatedAt: item.CreatedAt,
              UpdatedAt: item.UpdatedAt,
            };

            arrayData.push(record);
          });

          setSocialNetworkData(arrayData);
        }

        setProgressFlag(false);
      })
      .catch((err) => {
        console.error(
          "In fetchDbSocialNetworks(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In SocialNetworksFooter(): useEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));

    // currLanguage is the most current language passed to this component through props
    // We are using useEffect to get the functionality of componentDidUpdate()
    if (
      currLanguage !== undefined &&
      currLanguage !== null &&
      currLanguage !== currentLanguage
    ) {
      setCurrentLanguage(currLanguage);
      appContext.currentLanguage = currLanguage;

      // console.log('In SocialNetworksFooter(): useEffect(): about to call fetchDbResources()');

      // Fetch resource from catche
      let cacheItemKey = "socials";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      // cacheItemKey += currLanguage.languageCode;
      // console.log('In SocialNetworksFooter(): useEffect(): cacheItemKey is: ', cacheItemKey);

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        // console.log("In SocialNetworksFooter(): useEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
        RemoveCacheItem(cacheItemKey);
      }

      let cacheItem = GetCacheItem(cacheItemKey);
      // console.log('In SocialNetworksFooter(): useEffect(): cacheItem is: ', cacheItem);

      if (cacheItem === null) {
        // resource is not in cache, fetch from DB
        // console.log('In SocialNetworksFooter(): useEffect(): resource is not in cache, fetch from DB');
        fetchDbResources(cacheItemKey);
      } else {
        // console.log('In SocialNetworksFooter(): useEffect(): got resource from cache');
        setSocialsRes(cacheItem);
      }
      // Fetch SocialNetworks Data
      fetchDbSocialNetworks();
    }
  });

  useLayoutEffect(() => {
    // console.log("In SocialNetworksFooter(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "socials";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In SocialNetworksFooter(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In SocialNetworksFooter(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In SocialNetworksFooter(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In SocialNetworksFooter(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In SocialNetworksFooter(): useLayoutEffect(): got resource from cache');
      setSocialsRes(cacheItem);
    }
    // Fetch SocialNetworks Data
    fetchDbSocialNetworks();
  }, []);

  return (
    <div dir={direction}>
      <PageContainer className={classes.root}>
        <CssBaseline />

        <Container component="div" className={classes.socials} maxWidth="lg">
          {progressFlag && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress
                className={classes.progress}
                color="secondary"
                size={15}
              />
            </div>
          )}
          <Grid container className={classes.mainGrid}>
            <Grid item xs={12} className={classes.hdr}>
              <Typography variant="button" gutterBottom>
                {socialsHdr}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.prodDesc}>
              <Typography variant="caption" gutterBottom>
                {socialsDesc}
              </Typography>
            </Grid>

            {socialNetworkData &&
              socialNetworkData.map((item, index) => {
                return (
                  <Grid key={index} item xs={12} className={classes.socGrid}>
                    <Grid container>
                      <Grid item xs={3}>
                        <Link
                          href={item.ResourceAddressUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <SocialIcon
                            name={item.ResourceIconName}
                            iconColor="#fff"
                          />
                          {/* <img src={item.ResourceIconUrl} className={classes.image}/> */}
                        </Link>
                      </Grid>

                      <Grid item xs={9}>
                        <Link
                          href={item.ResourceAddressUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Typography
                            variant="subtitle2"
                            className={classes.textItem}
                          >
                            {item.ResourceDesc}
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </PageContainer>
    </div>
  );
}

export default SocialNetworksFooter;
