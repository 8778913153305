import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import SaveIcon from "@material-ui/icons/Save";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import Box from '@material-ui/core/Box';
// import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";

// Phone
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Moment
import moment from "moment";
// import Moment from 'react-moment';
// import 'moment-timezone';

// Validator
// import validator from 'validator';
import isEmail from "validator/lib/isEmail";
// import isURL from 'validator/lib/isURL';
import isMobilePhone from "validator/lib/isMobilePhone";

// react-datetime-picker, react-time-picker and react-date-picker
// import DateTimePicker from 'react-datetime-picker';
// import TimePicker from 'react-time-picker'

// Amplify
import Amplify, { API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// ReducerContext
import ReducerContext from "../../config/ReducerContext";

import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import LineSpacing from "../../components/misc/LineSpacing";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GlobalConstants from "../../utils/GlobalConstants";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    backgroundImage: `url(${"assets/logos/background21.png"})`,
  },
  gridWidth: {
    width: "90%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  mainForm: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  mainGrid: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  textField: {
    width: "100%",
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  verticalAlignLabel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "1rem" /* Footer height */,

    width: "100% !important",
    marginTop: "auto",
    // backgroundColor: '#fff',
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
    textAlign: "center !important",
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  // selectEmpty: {
  //   marginTop: theme.spacing(2),
  // },
  browseIcon: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  phone: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "row",
    textAlign: "left",
  },
  normalVariant: {
    color: "#000",
    textAlign: "center",
  },
  errVariant: {
    color: "red",
    textAlign: "center",
  },
  gridItem: {
    textAlign: "left",
    marginTop: "2px",
  },
}));

let officialHrs = {
  customerServLabel: "Customer service",
  requiredFields: "* Required fields",
  submit: "Submit",

  languageNameLabel: "Language",
  selectLanguage: "Please select a language",

  locationLabel: "Location",
  selectLocation: "Please select a location",

  supportPhoneLabel: "Support number",
  smsPhoneLabel: "SMS number",
  whatsAppPhoneLabel: "WhatsApp number",
  callMePhoneLabel: "Call me number",

  searchPlaceholder: "Search countries",

  salesPhoneLabel: "Sales phone",

  salesPhoneErrMsg: "Sales phone number is invalid",
  supportPhoneErrMsg: "Support phone number is invalid",
  smsPhoneErrMsg: "SMS phone number is invalid",
  whatsAppPhoneErrMsg: "WhatsApp phone number is invalid",
  callMePhoneErrMsg: "Call me phone number is invalid",

  salesEmailLabel: "Sales email",
  salesEmailPrompt: "Please enter sales email address",
  salesEmailErrMsg: "Sales email address is invalid",

  supportEmailLabel: "Support email",
  supportEmailPrompt: "Please enter support email address",
  supportEmailErrMsg: "Support email address is invalid",

  customerSrvSuccessMsg: "Customer service update was successful.",
  customerSrvFailureMsg: "Customer service update failed.",
  customerSrvErrMsg: "Please correct any invalid entry.",
};

function CustomerService(props) {
  const appContext = useContext(AppContext);
  const reducerContext = useContext(ReducerContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [formLanguage, setFormLanguage] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [showLeadingZeros, setShowLeadingZeros] = useState(true);

  const [currentPK, setCurrentPK] = useState("");
  const [currentSK, setCurrentSK] = useState("");

  const [monStartDate, setMonStartDate] = useState("8:00 AM");
  const [tueStartDate, setTueStartDate] = useState("8:00 AM");
  const [wedStartDate, setWedStartDate] = useState("8:00 AM");
  const [thuStartDate, setThuStartDate] = useState("8:00 AM");
  const [friStartDate, setFriStartDate] = useState("8:00 AM");
  const [satStartDate, setSatStartDate] = useState("");
  const [sunStartDate, setSunStartDate] = useState("");

  const [monEndDate, setMonEndDate] = useState("5:00 PM");
  const [tueEndDate, setTueEndDate] = useState("5:00 PM");
  const [wedEndDate, setWedEndDate] = useState("5:00 PM");
  const [thuEndDate, setThuEndDate] = useState("5:00 PM");
  const [friEndDate, setFriEndDate] = useState("5:00 PM");
  const [satEndDate, setSatEndDate] = useState("");
  const [sunEndDate, setSunEndDate] = useState("");

  const [salesEmail, setSalesEmail] = useState("");
  const [supportEmail, setSupportEmail] = useState("");

  // const [showPassword, setShowPassword] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  const [langSelectOptions, setLangSelectOptions] = useState([]);
  const [locSelectOptions, setLocSelectOptions] = useState([]);
  const [contactInfoData, setContactInfoData] = useState([]);

  const [selectLangChangedFlag, setSelectLangChangedFlag] = useState(true);
  const [selectLocChangedFlag, setSelectLocChangedFlag] = useState(false);

  const [progressFlag, setProgressFlag] = useState(false);
  //   const [gridUpdatedFlag, setGridUpdatedFlag] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);

  const [filterDisabledFlag, setFilterDisabledFlag] = useState(true);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  //   const [modifiedGridRows, setModifiedGridRows] = useState([]);
  //   const [deletedGridRows, setDeletedGridRows] = useState([]);

  // For Error Flags
  const [formLanguageErrFlag, setFormLanguageErrFlag] = useState(false);
  const [locationErrFlag, setLocationErrFlag] = useState(false);
  const [salesPhoneErrFlag, setSalesPhoneErrFlag] = useState(false);
  const [supportPhoneErrFlag, setSupportPhoneErrFlag] = useState(false);
  const [smsPhoneErrFlag, setSmsPhoneErrFlag] = useState(false);
  const [whatsAppPhoneErrFlag, setWhatsAppPhoneErrFlag] = useState(false);
  const [callMePhoneErrFlag, setCallMePhoneErrFlag] = useState(false);

  const [salesEmailErrFlag, setSalesEmailErrFlag] = useState(false);
  const [supportEmailErrFlag, setSupportEmailErrFlag] = useState(false);

  const [contactCodeFocus, setContactCodeFocus] = useState(false);
  const [contactDescFocus, setContactDescFocus] = useState(true);

  // const [redirectSignUpFlag, setRedirectSignUpFlag] = useState(false);
  // const [globalErrorMsg, setGlobalErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  // For Resources
  const [officialHrsRes, setOfficialHrsRes] = useState(officialHrs);
  // const [prevLangCode, setPrevLangCode] = useState(appContext.currentLanguage.languageCode);

  // For Phones
  const [salesPhone, setSalesPhone] = useState("");
  const [supportPhone, setSupportPhone] = useState("");
  const [smsPhone, setSmsPhone] = useState("");
  const [whatsAppPhone, setWhatsAppPhone] = useState("");
  const [callMePhone, setCallMePhone] = useState("");

  // For Phone/Fax Number Input
  const [defaultPhoneCountry, setDefaultPhoneCountry] = useState(
    appContext.phoneNumber.defaultPhoneCountry
  ); // For phone flag
  const [preferredCountries, setPreferredCountries] = useState(
    appContext.phoneNumber.preferredCountries
  ); //Show these countries at top of dropdown
  const [phoneLocale, setPhoneLocale] = useState(
    appContext.phoneNumber.phoneLocale
  ); // For phone validation
  const [localization, setLocalization] = useState(
    appContext.phoneNumber.localization
  ); // e.g {'Germany': 'Deutschland', 'Spain': 'España'}
  const [enableSearchField, setEnableSearchField] = useState(true);

  // Variants
  const [salesEmailVariant, setSalesEmailVariant] = useState(null);
  const [supportEmailVariant, setSupportEmailVariant] = useState(null);

  const [renderContentFlag, setRenderContentFlag] = useState(false);

  //console.log('AmplifyConfig is: ', JSON.stringify(AmplifyConfig, null, 2));
  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  const marginAlignment = {
    flex: "1",
    marginRight: appContext.direction === "ltr" ? "20px" : "0px",
    marginLeft: appContext.direction === "rtl" ? "20px" : "0px",
  };

  const {
    customerServLabel,
    languageNameLabel,
    selectLanguage,

    locationLabel,
    selectLocation,

    supportPhoneLabel,
    smsPhoneLabel,
    whatsAppPhoneLabel,
    callMePhoneLabel,
    searchPlaceholder,

    salesPhoneLabel,

    salesPhoneErrMsg,
    supportPhoneErrMsg,
    smsPhoneErrMsg,
    whatsAppPhoneErrMsg,
    callMePhoneErrMsg,

    supportEmailLabel,
    supportEmailPrompt,
    supportEmailErrMsg,

    salesEmailLabel,
    salesEmailPrompt,
    salesEmailErrMsg,

    submit,
    customerSrvSuccessMsg,
    customerSrvFailureMsg,
    customerSrvErrMsg,
    requiredFields,
  } = officialHrsRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(officialHrs);
    // console.log("In ManageSocNetworkserenceData(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ManageSocNetworkserenceData(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "customerServLabel")
              officialHrs.customerServLabel = item.ResourceDesc;
            if (item.ResourceCode === "languageNameLabel")
              officialHrs.languageNameLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectLanguage")
              officialHrs.selectLanguage = item.ResourceDesc;

            if (item.ResourceCode === "locationLabel")
              officialHrs.locationLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectLocation")
              officialHrs.selectLocation = item.ResourceDesc;

            if (item.ResourceCode === "supportPhoneLabel")
              officialHrs.supportPhoneLabel = item.ResourceDesc;
            if (item.ResourceCode === "smsPhoneLabel")
              officialHrs.smsPhoneLabel = item.ResourceDesc;
            if (item.ResourceCode === "whatsAppPhoneLabel")
              officialHrs.whatsAppPhoneLabel = item.ResourceDesc;
            if (item.ResourceCode === "callMePhoneLabel")
              officialHrs.callMePhoneLabel = item.ResourceDesc;
            if (item.ResourceCode === "searchPlaceholder")
              officialHrs.searchPlaceholder = item.ResourceDesc;

            if (item.ResourceCode === "salesPhoneLabel")
              officialHrs.salesPhoneLabel = item.ResourceDesc;

            if (item.ResourceCode === "salesPhoneErrMsg")
              officialHrs.salesPhoneErrMsg = item.ResourceDesc;
            if (item.ResourceCode === "supportPhoneErrMsg")
              officialHrs.supportPhoneErrMsg = item.ResourceDesc;
            if (item.ResourceCode === "smsPhoneErrMsg")
              officialHrs.smsPhoneErrMsg = item.ResourceDesc;
            if (item.ResourceCode === "whatsAppPhoneErrMsg")
              officialHrs.whatsAppPhoneErrMsg = item.ResourceDesc;
            if (item.ResourceCode === "callMePhoneErrMsg")
              officialHrs.callMePhoneErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "supportEmailLabel")
              officialHrs.supportEmailLabel = item.ResourceDesc;
            if (item.ResourceCode === "supportEmailPrompt")
              officialHrs.supportEmailPrompt = item.ResourceDesc;
            if (item.ResourceCode === "supportEmailErrMsg")
              officialHrs.supportEmailErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "salesEmailLabel")
              officialHrs.salesEmailLabel = item.ResourceDesc;
            if (item.ResourceCode === "salesEmailPrompt")
              officialHrs.salesEmailPrompt = item.ResourceDesc;
            if (item.ResourceCode === "salesEmailErrMsg")
              officialHrs.salesEmailErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "submit")
              officialHrs.submit = item.ResourceDesc;
            if (item.ResourceCode === "requiredFields")
              officialHrs.requiredFields = item.ResourceDesc;

            if (item.ResourceCode === "customerSrvSuccessMsg")
              officialHrs.customerSrvSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "customerSrvFailureMsg")
              officialHrs.customerSrvFailureMsg = item.ResourceDesc;
            if (item.ResourceCode === "customerSrvErrMsg")
              officialHrs.customerSrvErrMsg = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, officialHrs);
        }

        setOfficialHrsRes(officialHrs);
        setRenderContentFlag(true);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setOfficialHrsRes(officialHrs);
        setProgressFlag(false);
      });
  }

  // Fetch Contact Info
  function fetchContactInfo(languageValue) {
    // console.log('In fetchContactInfo(): languageValue is: ', languageValue);

    setFilterDisabledFlag(true);
    setProgressFlag(true);

    let arrayData = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "contact-info",
          ":resourceSK": languageValue,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchContactInfo(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchContactInfo(): response length is: ', response.data.Items.length);
        // console.log('In fetchContactInfo(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item, index) => {
          let record = {
            value: item.ResourceCode,
            text: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        arrayData.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLocSelectOptions(arrayData);
        setContactInfoData(response.data.Items);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchContactInfo(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  let selectOptions = [];

  function fetchLanguages() {
    // console.log('In fetchLanguages()');
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages(): response.length is: ', response.data.Items.length);
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            // label: item.LanguageName,
            text: item.LanguageName,
          };
          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In useEffect()");
    // console.log("In useEffect(): reducerContext.store.signedIn is: ", reducerContext.store.signedIn);
  }, []);

  useLayoutEffect(() => {
    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In CustomerService(): useLayoutEffect(): currentLanguage is: ', currentLanguage);
    // console.log('In CustomerService(): useLayoutEffect(): GenerateModulusHash("firstName", 200) is: ', GenerateModulusHash("firstName", 200));
    // console.log('In CustomerService(): useLayoutEffect(): appContext.signedIn is: ', appContext.signedIn);

    // console.log("In useLayoutEffect(): reducerContext.store.signedIn is: ", reducerContext.store.signedIn);
    // console.log("In useLayoutEffect(): reducerContext is: ", reducerContext);
    reducerContext.dispatch({ type: "AUTH_BEGIN" });

    setContactCodeFocus(true);
    setContactDescFocus(false);

    if (selectOptions.length === 0) {
      fetchLanguages();
      fetchContactInfo(currentLanguage.languageCode);
    }

    // Fetch resource from catche
    let cacheItemKey = "officialHrs";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ManageSocNetworkserenceData(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): got resource from cache');
      setOfficialHrsRes(cacheItem);
    }
  }, []);

  function handleChange(event) {
    // console.log("In handleChange(): id is: "+event.target.id);
    // console.log("In handleChange(): value is: "+event.target.value);
    setGlobalErrorFlag(false);

    switch (event.target.id) {
      case "salesEmailId":
        setSalesEmailErrFlag(false);
        setSalesEmail(event.target.value);
        break;
      case "supportEmailId":
        setSupportEmailErrFlag(false);
        setSupportEmail(event.target.value);
        break;
      default:
        break;
    }
  }

  function validateForm() {
    let formErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setFormLanguageErrFlag(false);
    setLocationErrFlag(false);

    setSalesPhoneErrFlag(false);
    setSalesEmailErrFlag(false);

    setSupportPhoneErrFlag(false);
    setSmsPhoneErrFlag(false);
    setWhatsAppPhoneErrFlag(false);
    setCallMePhoneErrFlag(false);
    setSupportEmailErrFlag(false);

    // moment.locale(currentLanguage.languageCode);

    if (formLanguage === null || formLanguage === "") {
      formErrFlag = true;
      setFormLanguageErrFlag(true);
    }

    if (selectedLocation === null || selectedLocation === "") {
      formErrFlag = true;
      setLocationErrFlag(true);
    }

    // For Sales
    if (
      salesPhone !== null &&
      salesPhone !== "" &&
      !isMobilePhone(salesPhone, phoneLocale)
    ) {
      formErrFlag = true;
      setSalesPhoneErrFlag(true);
      setGlobalErrorMsg(salesPhoneErrMsg);
    }

    if (salesEmail !== null && salesEmail !== "") {
      if (!salesEmail.match(GlobalConstants.emailRegex)) {
        formErrFlag = true;
        setSalesEmailErrFlag(true);
        setSalesEmailVariant("danger");
        setGlobalErrorMsg(salesEmailErrMsg);
      }
    }

    // For Support
    if (
      supportPhone !== null &&
      supportPhone !== "" &&
      !isMobilePhone(supportPhone, phoneLocale)
    ) {
      formErrFlag = true;
      setSupportPhoneErrFlag(true);
      setGlobalErrorMsg(supportPhoneErrMsg);
    }

    if (
      smsPhone !== null &&
      smsPhone !== "" &&
      !isMobilePhone(smsPhone, phoneLocale)
    ) {
      formErrFlag = true;
      setSmsPhoneErrFlag(true);
      setGlobalErrorMsg(smsPhoneErrMsg);
    }

    if (
      whatsAppPhone !== null &&
      whatsAppPhone !== "" &&
      !isMobilePhone(whatsAppPhone, phoneLocale)
    ) {
      formErrFlag = true;
      setWhatsAppPhoneErrFlag(true);
      setGlobalErrorMsg(whatsAppPhoneErrMsg);
    }

    if (
      callMePhone !== null &&
      callMePhone !== "" &&
      !isMobilePhone(callMePhone, phoneLocale)
    ) {
      formErrFlag = true;
      setCallMePhoneErrFlag(true);
      setGlobalErrorMsg(callMePhoneErrMsg);
    }

    if (supportEmail !== null && supportEmail !== "") {
      if (!supportEmail.match(GlobalConstants.emailRegex)) {
        formErrFlag = true;
        setSupportEmailErrFlag(true);
        setSupportEmailVariant("danger");
        setGlobalErrorMsg(supportEmailErrMsg);
      }
    }

    if (formErrFlag) {
      setGlobalErrorFlag(true);
    }
    return formErrFlag;
  }

  function initializeForm() {
    setSelectedLocation("");

    setSalesPhone("");
    setSalesEmail("");

    setSupportPhone("");
    setSmsPhone("");
    setWhatsAppPhone("");
    setCallMePhone("");
    setSupportEmail("");

    setSubmitFlag(false);
  }

  async function customerServiceFn() {
    // console.log("In customerServiceFn(): formLanguage is: ", formLanguage);
    // console.log("In customerServiceFn(): selectedLocation is: ", selectedLocation);

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    if (!validateForm()) {
      const timestamp = new Date();

      let key = {
        ResourcePK: currentPK,
        ResourceSK: currentSK,
      };

      // ConditionExpression: "attribute_exists(resourceCode)",
      let conditionExpression = "attribute_exists(ResourceSK)";

      // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
      let updateExpression =
        "SET SalesPhone = :salesPhone, SalesEmail = :salesEmail, " +
        "SupportPhone = :supportPhone, SmsPhone = :smsPhone, WhatsAppPhone = :whatsAppPhone, CallMePhone = :callMePhone, " +
        "SupportEmail = :supportEmail, ModifiedBy = :modifiedBy, UpdatedAt = :updatedAt";

      let expressionAttributeValues = {
        ":salesPhone": salesPhone,
        ":salesEmail": salesEmail,

        ":supportPhone": supportPhone,
        ":smsPhone": smsPhone,
        ":whatsAppPhone": whatsAppPhone,
        ":callMePhone": callMePhone,
        ":supportEmail": supportEmail,

        ":modifiedBy": appContext.userName,
        ":updatedAt": timestamp,
      };

      let params = {
        apiName: "ResourceAPI",
        path: "/dynamodb/resources/update",
        payload: {
          key: key,
          conditionExpression: conditionExpression,
          updateExpression: updateExpression,
          expressionAttributeValues: expressionAttributeValues,
        },
      };
      console.log(
        "In customerServiceFn(): params.payload is: ",
        JSON.stringify(params.payload, null, 2)
      );

      let initObj = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        response: true, // return entire response object instead of response.data
        body: {},
      };

      initObj.headers.Authorization = appContext.jwtToken;
      initObj.body = params.payload;
      // console.log('In customerServiceFn(): initObj is: ', JSON.stringify(initObj, null, 2));

      setProgressFlag(true);

      API.put(params.apiName, params.path, initObj)
        .then((response) => {
          // console.log('In customerServiceFn(): response is: ', JSON.stringify(response, null, 2));

          // Clear Arrays
          // setDeletedGridRows([]);
          // setModifiedGridRows([]);

          // Show Snackbar
          setSnackbarsVariant("success");
          setSnackbarsMsg(customerSrvSuccessMsg);
          setSnackbarOpen(true);

          setProgressFlag(false);

          initializeForm();

          // Refresh Contact Info dropdown
          // console.log("In customerServiceFn(): formLanguage.value is: ", formLanguage.value);
          fetchContactInfo(formLanguage.value);
        })
        .catch((err) => {
          console.log(
            "In customerServiceFn(): err is: ",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setGlobalErrorMsg(customerSrvFailureMsg);

          setProgressFlag(false);
        });
    }
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    setSnackbarOpen(false);
  }

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);
    setFormLanguage(selectedOption);

    setSelectLangChangedFlag(true);

    fetchContactInfo(selectedOption);

    if (selectLocChangedFlag) {
      setSubmitFlag(true);
    }
  }

  function handleLocationChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLocationChange(): selectedOption is', selectedOption);
    // console.log("In handleLocationChange(): reducerContext.store.signedIn is: ", reducerContext.store.signedIn);
    setSelectedLocation(selectedOption);

    setSelectLocChangedFlag(true);

    let filteredRec = null;

    if (contactInfoData !== null && contactInfoData.length > 0) {
      filteredRec = contactInfoData.filter(
        (item) => item.ResourceCode === selectedOption
      );
    }
    // console.log('In handleLocationChange(): filteredRec is', filteredRec);
    if (filteredRec !== null) {
      // console.log('In handleLocationChange(): filteredRec.ResourcePK is', filteredRec[0].ResourcePK);
      // console.log('In handleLocationChange(): filteredRec.ResourceSK is', filteredRec[0].ResourceSK);
      setCurrentPK(filteredRec[0].ResourcePK);
      setCurrentSK(filteredRec[0].ResourceSK);
    }

    // For Sales
    if (filteredRec !== null && filteredRec[0].SalesPhone) {
      setSalesPhone(filteredRec[0].SalesPhone);
    }

    if (filteredRec !== null && filteredRec[0].SalesEmail) {
      setSalesEmail(filteredRec[0].SalesEmail);
    }

    // For Support
    if (filteredRec !== null && filteredRec[0].SupportPhone) {
      setSupportPhone(filteredRec[0].SupportPhone);
    }

    if (filteredRec !== null && filteredRec[0].SmsPhone) {
      setSmsPhone(filteredRec[0].SmsPhone);
    }

    if (filteredRec !== null && filteredRec[0].WhatsAppPhone) {
      setWhatsAppPhone(filteredRec[0].WhatsAppPhone);
    }

    if (filteredRec !== null && filteredRec[0].CallMePhone) {
      setCallMePhone(filteredRec[0].CallMePhone);
    }

    if (filteredRec !== null && filteredRec[0].SupportEmail) {
      setSupportEmail(filteredRec[0].SupportEmail);
    }

    if (selectLangChangedFlag) {
      setSubmitFlag(true);
    }
  }

  function handleSalesPhoneChange(salesNum) {
    // console.log("In handleSalesPhoneChange(): salesNum is: ", salesNum);
    setGlobalErrorFlag(false);
    // setSubmitFlag(false);

    setSalesPhoneErrFlag(false);
    setSalesPhone(salesNum);
  }

  function handleSupportPhoneChange(supportNum) {
    // console.log("In handleSupportPhoneChange(): supportNum is: ", supportNum);
    setGlobalErrorFlag(false);
    // setSubmitFlag(false);

    setSupportPhoneErrFlag(false);
    setSupportPhone(supportNum);
  }

  function handleSmsPhoneChange(smsNum) {
    // console.log("In handleSmsPhoneChange(): smsNum is: ", smsNum);
    setGlobalErrorFlag(false);
    // setSubmitFlag(false);

    setSmsPhoneErrFlag(false);
    setSmsPhone(smsNum);
  }

  function handleWhatsAppPhoneChange(whatsAppNum) {
    // console.log("In handleWhatsAppPhoneChange(): whatsAppNum is: ", whatsAppNum);
    setGlobalErrorFlag(false);
    // setSubmitFlag(false);

    setWhatsAppPhoneErrFlag(false);
    setWhatsAppPhone(whatsAppNum);
  }

  function handleCallMePhoneChange(callMeNum) {
    // console.log("In handleCallMePhoneChange(): callMeNum is: ", callMeNum);
    setGlobalErrorFlag(false);
    // setSubmitFlag(false);

    setCallMePhoneErrFlag(false);
    setCallMePhone(callMeNum);
  }

  return (
    <div dir={direction}>
      {renderContentFlag && (
        <div className={classes.root}>
          {/* { !appContext.signedIn && <Redirect to='/signIn' />} */}
          <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
          <CssBaseline />

          <Container component="div" className={classes.main} maxWidth="lg">
            <div style={{ width: "100%", height: "100%" }}>
              {/* progressFlag &&
              <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
              </div>
            */}
              <Container
                component="div"
                className={classes.mainForm}
                maxWidth="md"
              >
                <Grid container justify="center">
                  <Grid item xs={12} sm={10}>
                    <StyledPaper elevation={3} className={classes.paper}>
                      <Typography
                        variant="h6"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        {customerServLabel}
                      </Typography>

                      <Typography
                        variant="caption"
                        style={{ color: "green", textAlign: "center" }}
                      >
                        {requiredFields}
                      </Typography>

                      {globalErrorFlag && (
                        <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                      )}

                      <LineSpacing value={32} />

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <FormControl className={classes.formControl}>
                            <label
                              htmlFor="locationId"
                              className={classes.verticalAlignLabel}
                              style={directionAlignment}
                            >
                              <Typography variant="subtitle1">
                                *{locationLabel}
                              </Typography>
                            </label>
                            <Select
                              id="locationId"
                              value={selectedLocation}
                              onChange={handleLocationChange}
                              variant="outlined"
                              displayEmpty={true}
                              className={classes.selectEmpty}
                              placeholder={selectLocation}
                              margin="dense"
                            >
                              <MenuItem value={null} disabled>
                                <Typography variant="subtitle1">
                                  {selectLocation}
                                </Typography>
                              </MenuItem>

                              {locSelectOptions.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl className={classes.formControl}>
                            <label
                              htmlFor="locationId"
                              className={classes.verticalAlignLabel}
                              style={directionAlignment}
                            >
                              <Typography variant="subtitle1">
                                *{locationLabel}
                              </Typography>
                            </label>
                            <Select
                              id="locationId"
                              value={selectedLocation}
                              onChange={handleLocationChange}
                              variant="outlined"
                              displayEmpty={true}
                              className={classes.selectEmpty}
                              placeholder={selectLocation}
                              margin="dense"
                            >
                              <MenuItem value={null} disabled>
                                <Typography variant="subtitle1">
                                  {selectLocation}
                                </Typography>
                              </MenuItem>

                              {locSelectOptions.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl
                            className={classes.formControl}
                            variant="outlined"
                          >
                            <label
                              htmlFor="salesEmailId"
                              className={classes.verticalAlignLabel}
                              style={directionAlignment}
                            >
                              <Typography variant="subtitle1">
                                {salesEmailLabel}
                              </Typography>
                            </label>
                            <OutlinedInput
                              id="salesEmailId"
                              value={salesEmail}
                              placeholder={salesEmailPrompt}
                              onChange={handleChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              error={salesEmailErrFlag}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl
                            className={classes.formControl}
                            variant="outlined"
                          >
                            <label
                              htmlFor="supportEmailId"
                              className={classes.verticalAlignLabel}
                              style={directionAlignment}
                            >
                              <Typography variant="subtitle1">
                                {supportEmailLabel}
                              </Typography>
                            </label>
                            <OutlinedInput
                              id="supportEmailId"
                              value={supportEmail}
                              placeholder={supportEmailPrompt}
                              onChange={handleChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              error={supportEmailErrFlag}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <label
                            htmlFor="supportPhoneId"
                            className={classes.verticalAlignLabel}
                            style={directionAlignment}
                          >
                            <Typography variant="subtitle1">
                              {supportPhoneLabel}
                            </Typography>
                          </label>
                          {/* Force 'ltr' in all situations  - component does not render well for 'rtl' */}
                          <div dir={"ltr"}>
                            <ReactPhoneInput
                              id="supportPhoneId"
                              defaultCountry={defaultPhoneCountry}
                              value={supportPhone}
                              onChange={handleSupportPhoneChange}
                              inputClass={classes.phone}
                              preferredCountries={preferredCountries}
                              localization={localization}
                              enableSearchField={enableSearchField}
                              searchPlaceholder={searchPlaceholder}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <label
                            htmlFor="smsPhoneId"
                            className={classes.verticalAlignLabel}
                            style={directionAlignment}
                          >
                            <Typography variant="subtitle1">
                              {smsPhoneLabel}
                            </Typography>
                          </label>
                          {/* Force 'ltr' in all situations  - component does not render well for 'rtl' */}
                          <div dir={"ltr"}>
                            <ReactPhoneInput
                              id="smsPhoneId"
                              defaultCountry={defaultPhoneCountry}
                              value={smsPhone}
                              onChange={handleSmsPhoneChange}
                              inputClass={classes.phone}
                              preferredCountries={preferredCountries}
                              localization={localization}
                              enableSearchField={enableSearchField}
                              searchPlaceholder={searchPlaceholder}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <label
                            htmlFor="whatsAppPhoneId"
                            className={classes.verticalAlignLabel}
                            style={directionAlignment}
                          >
                            <Typography variant="subtitle1">
                              {whatsAppPhoneLabel}
                            </Typography>
                          </label>
                          {/* Force 'ltr' in all situations  - component does not render well for 'rtl' */}
                          <div dir={"ltr"}>
                            <ReactPhoneInput
                              id="whatsAppPhoneId"
                              defaultCountry={defaultPhoneCountry}
                              value={whatsAppPhone}
                              onChange={handleWhatsAppPhoneChange}
                              inputClass={classes.phone}
                              preferredCountries={preferredCountries}
                              localization={localization}
                              enableSearchField={enableSearchField}
                              searchPlaceholder={searchPlaceholder}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <label
                            htmlFor="callMePhoneId"
                            className={classes.verticalAlignLabel}
                            style={directionAlignment}
                          >
                            <Typography variant="subtitle1">
                              {callMePhoneLabel}
                            </Typography>
                          </label>
                          {/* Force 'ltr' in all situations  - component does not render well for 'rtl' */}
                          <div dir={"ltr"}>
                            <ReactPhoneInput
                              id="callMePhoneId"
                              defaultCountry={defaultPhoneCountry}
                              value={callMePhone}
                              onChange={handleCallMePhoneChange}
                              inputClass={classes.phone}
                              preferredCountries={preferredCountries}
                              localization={localization}
                              enableSearchField={enableSearchField}
                              searchPlaceholder={searchPlaceholder}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <label
                            htmlFor="salesPhoneId"
                            className={classes.verticalAlignLabel}
                            style={directionAlignment}
                          >
                            <Typography variant="subtitle1">
                              {salesPhoneLabel}
                            </Typography>
                          </label>
                          {/* Force 'ltr' in all situations  - component does not render well for 'rtl' */}
                          <div dir={"ltr"}>
                            <ReactPhoneInput
                              id="salesPhoneId"
                              defaultCountry={defaultPhoneCountry}
                              value={salesPhone}
                              onChange={handleSalesPhoneChange}
                              inputClass={classes.phone}
                              preferredCountries={preferredCountries}
                              localization={localization}
                              enableSearchField={enableSearchField}
                              searchPlaceholder={searchPlaceholder}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            size="large"
                            disabled={!submitFlag ? true : false}
                            color="primary"
                            className={classes.button}
                            onClick={customerServiceFn}
                          >
                            <SaveIcon
                              className={clsx(
                                classes.leftIcon,
                                classes.iconSmall
                              )}
                            />
                            {submit}
                          </Button>
                        </Grid>
                      </Grid>
                    </StyledPaper>
                  </Grid>
                </Grid>
              </Container>
            </div>

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={snackbarOpen}
              autoHideDuration={8000}
              onClose={snackbarOnClose}
              ContentProps={{ "aria-describedby": "message-id" }}
              message={<span id="message-id">{snackbarsMsg}</span>}
              variant={snackbarsVariant}
            />
          </Container>

          <footer className={classes.footer}>
            <div>
              <PageFooter isSignedIn={isSignedIn} />
            </div>
          </footer>
        </div>
      )}
    </div>
  );
}

export default CustomerService;
