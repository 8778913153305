import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

// AppContext
import AppContext from "../../config/AppContext";

Amplify.configure(AmplifyConfig);

const PageContainer = styled.div`
  width: 100%,
  display: 'flex',
  flexWrap: 'wrap',
  background: #fff;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
  },
  progress: {
    margin: theme.spacing(1),
  },
  mainGrid: {
    textAlign: "left",
  },
  products: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  hdr: {
    color: "#fff",
  },
  prodDesc: {
    color: "fff",
    fontSize: "12px",
    marginLeft: theme.spacing(1),
    whiteSpace: "nowrap",
  },
  link: {
    color: "#fff",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  textItem: {
    color: "#fff",
    whiteSpace: "nowrap",
  },
}));

let products = {
  productHdr: "Products",
  productDesc: "Products we offer",
  product1: "Product1",
  product2: "Product2",
  product3: "Product3",
  product4: "Product4",
  product5: "Product5",
  product6: "Product6",
  product7: "Product7",
  product8: "Product8",
  product9: "Product9",
  product10: "Product10",
};

function ProductsFooter(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  // For Products
  const [productsRes, setProductsRes] = useState(products);

  const { currLanguage } = props;

  const classes = useStyles();

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  const {
    productHdr,
    productDesc,
    product1,
    product2,
    product3,
    product4,
    product5,
    product6,
    product7,
    product8,
    product9,
    product10,
  } = productsRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(products);
    // console.log("In ProductsFooter(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ProductsFooter(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "productHdr")
              products.productHdr = item.ResourceDesc;
            if (item.ResourceCode === "productDesc")
              products.productDesc = item.ResourceDesc;
            if (item.ResourceCode === "product1")
              products.product1 = item.ResourceDesc;
            if (item.ResourceCode === "product2")
              products.product2 = item.ResourceDesc;
            if (item.ResourceCode === "product3")
              products.product3 = item.ResourceDesc;
            if (item.ResourceCode === "product4")
              products.product4 = item.ResourceDesc;
            if (item.ResourceCode === "product5")
              products.product5 = item.ResourceDesc;
            if (item.ResourceCode === "product6")
              products.product6 = item.ResourceDesc;
            if (item.ResourceCode === "product7")
              products.product7 = item.ResourceDesc;
            if (item.ResourceCode === "product8")
              products.product8 = item.ResourceDesc;
            if (item.ResourceCode === "product9")
              products.product9 = item.ResourceDesc;
            if (item.ResourceCode === "product10")
              products.product10 = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, products);
          setProductsRes(products);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProductsRes(products);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In ProductsFooter(): useEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));

    // currLanguage is the most current language passed to this component through props
    // We are using useEffect to get the functionality of componentDidUpdate()
    if (
      currLanguage !== undefined &&
      currLanguage !== null &&
      currLanguage !== currentLanguage
    ) {
      setCurrentLanguage(currLanguage);
      appContext.currentLanguage = currLanguage;

      // console.log('In ProductsFooter(): useEffect(): about to call fetchDbResources()');

      // Fetch resource from catche
      let cacheItemKey = "products";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      // cacheItemKey += currLanguage.languageCode;
      // console.log('In ProductsFooter(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        // console.log("In ProductsFooter(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
        RemoveCacheItem(cacheItemKey);
      }

      let cacheItem = GetCacheItem(cacheItemKey);
      // console.log('In ProductsFooter(): useLayoutEffect(): cacheItem is: ', cacheItem);

      if (cacheItem === null) {
        // resource is not in cache, fetch from DB
        // console.log('In ProductsFooter(): useLayoutEffect(): resource is not in cache, fetch from DB');
        fetchDbResources(cacheItemKey);
      } else {
        // console.log('In ProductsFooter(): useLayoutEffect(): got resource from cache');
        setProductsRes(cacheItem);
      }
    }
  });

  useLayoutEffect(() => {
    // console.log("In ProductsFooter(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "products";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ProductsFooter(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ProductsFooter(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ProductsFooter(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ProductsFooter(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ProductsFooter(): useLayoutEffect(): got resource from cache');
      setProductsRes(cacheItem);
    }
  }, []);

  return (
    <div dir={direction}>
      <PageContainer className={classes.root}>
        <CssBaseline />

        <Container component="div" className={classes.products} maxWidth="lg">
          {progressFlag && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress
                className={classes.progress}
                color="secondary"
                size={15}
              />
            </div>
          )}
          <Grid container className={classes.mainGrid}>
            <Grid item xs={12} className={classes.hdr}>
              <Typography variant="button" gutterBottom>
                {productHdr}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.prodDesc}>
              <Typography variant="caption" gutterBottom>
                {productDesc}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/product1">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {product1}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/product2">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {product2}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/product3">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {product3}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/product4">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {product4}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/product5">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {product5}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/product6">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {product6}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/product7">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {product7}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/product8">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {product8}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/product9">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {product9}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/product10">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {product10}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </PageContainer>
    </div>
  );
}

export default ProductsFooter;
