import lodash from "lodash";
import moment from "moment-timezone";

import 'moment/min/locales';

// import "moment/locale/ar";
// import "moment/locale/he";
// import "moment/locale/de";
// import "moment/locale/es";
// import "moment/locale/ko";
// import "moment/locale/fr";
// import "moment/locale/zh-cn";

function FormatTimezoneDates(item, languageCode, timezone, formatType) {
    let retValue = null;
    // console.log("In FormatTimezoneDates(): item is: ", item);
    // console.log("In FormatTimezoneDates(): languageCode is: ", languageCode);
    // console.log("In FormatTimezoneDates(): formatType is: ", formatType);
    // console.log("In FormatTimezoneDates(): timezone is: ", timezone);

    if (item && !lodash.isEmpty(languageCode) && !lodash.isEmpty(formatType) && !lodash.isEmpty(timezone)) {
        let langCode = languageCode;

        // Convert LanguageCode for use with moment
        switch(languageCode) {
            case "zh":
                langCode = "zh-cn";
                break;
            case "zh-TW":
                langCode = "zh-tw";
                break;
            default:
                langCode = "en";
                break;
        }

        moment.locale(langCode);

        switch(formatType) {
            case 'dateWithDayOfWeek': // Date with day of week
                retValue = moment(item).tz(timezone).format("ddd, MMM DD YYYY");// "Sunday, February 14th 2010"
                break;
            case 'date': // Date only
                retValue = moment(item).tz(timezone).format("LL z");
                break;
            case 'shortDate': // Short Date only
                retValue = moment(item).tz(timezone).format("ll z");
                break;
            case 'time': // Time only (no seconds)
                // console.log(moment());
                // console.log(item);
                retValue = moment(item).tz(timezone).format("h:mm A z");
                break;
            case 'currTimeAmPm': // Current Time only (no seconds) with AM/PM
                retValue = moment().format("h:mm A z");
                break;
            case 'timeWithSeconds': // Time only (with seconds)
                retValue = moment(item).tz(timezone).format("LTS z");
                break;
            case 'dateAndTime': // Date and Time
                retValue = moment(item).tz(timezone).format("LLLL z");
                break;
            case 'shortDateAndTime': // Short Date and Time
                retValue = moment(item).tz(timezone).format("llll z");
                break;
            case 'weekDay': // Day of the week
                retValue = moment(item).tz(timezone).format("dddd");
                break;
            case 'globalDate':
                retValue = moment(item).tz(timezone).format("YYYY-MM-DD");
                break;
            case 'year':
                retValue = moment(item).tz(timezone).format("YYYY");
                break;
            case 'ha':
                retValue = moment(item).tz(timezone).format("ha z"); // 7am EST
                break;
            default:
                break;
        }
    }
    return retValue;
};
  
export default FormatTimezoneDates;
  