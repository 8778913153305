import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
// import Button from '@material-ui/core/Button';
import CssBaseline from "@material-ui/core/CssBaseline";
// import { Redirect } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
// import clsx from 'clsx';
// import AddIcon from '@material-ui/icons/Add';
// import DeleteIcon from '@material-ui/icons/Delete';
// import SaveIcon from '@material-ui/icons/Save';
// import TextField from '@material-ui/core/TextField';

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Link from '@material-ui/core/Link';
// import Select from '@material-ui/core/Select';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import Select from 'react-select';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";

// AG-Grid
import { AgGridReact, AgGridColumn } from "ag-grid-react";
//import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

// Amplify
import Amplify, { API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// import GlobalConstants from '../../utils/GlobalConstants';
// import GeneratePassword from '../../utils/GeneratePassword';
// import GenerateClientId from '../../utils/GenerateClientId';
// import GenerateTransactionId from '../../utils/GenerateTransactionId';
// import GenerateRandomHash from '../../utils/GenerateRandomHash';
// import GenerateModulusHash from '../../utils/GenerateModulusHash';
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import LineSpacing from "../../components/misc/LineSpacing";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// Renderers
import DateCellRenderer from "../../components/renderers/DateCellRenderer";
// import NumberCellRenderer from '../../components/renderers/NumberCellRenderer';
// import ActionsCellRenderer from '../../components/renderers/ActionsCellRenderer';
// import { create } from 'jss';
// import CurrencyCellRenderer from '../../components/renderers/CurrencyCellRenderer';
// import CountryCellRenderer from '../../components/renderers/CountryCellRenderer';
import ImageCellRenderer from "../../components/renderers/ImageCellRenderer";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    backgroundImage: `url(${"assets/logos/background20.png"})`,
  },
  main: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mainForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    width: "100%",
  },
  verticalAlignLabel: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  filterGrid: {
    textAlign: "left",
    marginBottom: "8px",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  footer: {
    bottom: 0,
    height: "1rem" /* Footer height */,
    width: "100% !important",
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
    textAlign: "center !important",
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

let localeText = {
  page: "Page",
  to: "to",
  of: "of",
  noRowsToShow: "No records found",
};

// let localeText2 = {
//   page: "daPage",
//   more: "daMore",
//   to: "daTo",
//   of: "daOf",
//   next: "daNexten",
//   last: "daLasten",
//   first: "daFirsten",
//   previous: "daPreviousen",
//   loadingOoo: "daLoading...",
//   selectAll: "daSelect Allen",
//   searchOoo: "daSearch...",
//   blanks: "daBlanc",
//   filterOoo: "daFilter...",
//   applyFilter: "daApplyFilter...",
//   equals: "daEquals",
//   notEqual: "daNotEqual",
//   lessThan: "daLessThan",
//   greaterThan: "daGreaterThan",
//   lessThanOrEqual: "daLessThanOrEqual",
//   greaterThanOrEqual: "daGreaterThanOrEqual",
//   inRange: "daInRange",
//   contains: "daContains",
//   notContains: "daNotContains",
//   startsWith: "daStarts dawith",
//   endsWith: "daEnds dawith",
//   andCondition: "daAND",
//   orCondition: "daOR",
//   group: "laGroup",
//   columns: "laColumns",
//   filters: "laFilters",
//   rowGroupColumns: "laPivot Cols",
//   rowGroupColumnsEmptyMessage: "la drag cols to group",
//   valueColumns: "laValue Cols",
//   pivotMode: "laPivot-Mode",
//   groups: "laGroups",
//   values: "laValues",
//   pivots: "laPivots",
//   valueColumnsEmptyMessage: "la drag cols to aggregate",
//   pivotColumnsEmptyMessage: "la drag here to pivot",
//   toolPanelButton: "la tool panel",
//   noRowsToShow: "la no rows",
//   pinColumn: "laPin Column",
//   valueAggregation: "laValue Agg",
//   autosizeThiscolumn: "laAutosize Diz",
//   autosizeAllColumns: "laAutsoie em All",
//   groupBy: "laGroup by",
//   ungroupBy: "laUnGroup by",
//   resetColumns: "laReset Those Cols",
//   expandAll: "laOpen-em-up",
//   collapseAll: "laClose-em-up",
//   toolPanel: "laTool Panelo",
//   export: "laExporto",
//   csvExport: "la CSV Exportp",
//   excelExport: "la Excel Exporto (.xlsx)",
//   excelXmlExport: "la Excel Exporto (.xml)",
//   pinLeft: "laPin &lt;&lt;",
//   pinRight: "laPin &gt;&gt;",
//   noPin: "laDontPin &lt;&gt;",
//   sum: "laSum",
//   min: "laMin",
//   max: "laMax",
//   none: "laNone",
//   count: "laCount",
//   average: "laAverage",
//   copy: "laCopy",
//   copyWithHeaders: "laCopy Wit hHeaders",
//   ctrlC: "ctrl n C",
//   paste: "laPaste",
//   ctrlV: "ctrl n V"
// };

// For AG-Grid

//------------------------------
// RENDERERS
//------------------------------
const gridOptions = {
  style: "currency", // decimal, currency, percent
  currency: "USD",
  currencyDisplay: "symbol", // symbol, code, name to use a localized currency name such as "dollar"
  useGrouping: true,
  minimumIntegerDigits: 21,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  payload: {},

  weekday: "short", // long, short
  year: "numeric",
  month: "short", // long, short
  day: "numeric",
  // timeZone: 'America/New_York', // Auto calculated in DateCellRenderer
  timeZoneName: "short", // long, short
  hour12: true,
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  locale: "default", // To use the browser's default locale, use 'default'
  // getRowNodeId: function(item) {
  //   return item.id;
  // },
  imageWidth: "16px !important", // Will scale image to a width of 32px in ImageCellRenderer component
  divContainerHeight: "32px !important", // Will set div container height to 32px in ImageCellRenderer component to align the image in the middle

  getRowHeight: function (params) {
    // console.log("In getRowHeight: params is: ", params);
    // assuming 50 characters per line, working how how many lines we need
    // console.log("In getRowHeight: words is: ", params.data.ResourceDesc.length);
    let lines = 36 * (Math.floor(params.data.ResourceDesc.length / 45) + 1);
    // console.log("In getRowHeight: lines is: ", lines);
    // let lines = 32;
    return lines;
  },
};

let viewRefTypes = {
  viewReferenceTypes: "View reference types",
  allFieldsAreRequired: "All fields are required",

  viewRefTypesLanguageLabel: "Language",
  gridRefreshWarning: "If you change language, grid will refresh.",
  selectLanguagePrompt: "Please select reference types language",

  referenceTypesCodeLabel: "Type code",
  referenceTypesDescLabel: "Type description",

  modifiedBy: "Modified by",
  createdAt: " Create date",
  updatedAt: "Modified date",

  filterInputPrompt: "Please enter filter criteria.",
  dataLanguageLabel: "Data language",
  filterGridLabel: "Filter grid",

  // For Grid
  gridPage: "Page",
  gridTo: "to",
  gridOf: "of",
  noRowsToShow: "No records found",
};

function cellStyle(params) {
  return { "white-space": "normal" };
}

function ViewReferenceTypes(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [referenceLanguage, setReferenceLanguage] = useState("");
  const [referenceType, setReferenceType] = useState("reference-types");
  const [referenceCode, setResourceCode] = useState("");
  const [referenceDesc, setResourceDesc] = useState("");

  //   const [showPassword, setShowPassword] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);
  // const [ initComplete, setInitComplete ] = useState(appContext.signedIn);
  //   const [ payload, setPayload ] = useState({});
  const [gridData, setGridData] = useState([]);
  const [editRowIndex, setEditRowIndex] = useState(-1);

  const [langSelectOptions, setLangSelectOptions] = useState([]);
  // const [ refTypeSelectOptions, setRefTypeSelectOptions ] = useState([]);

  const [selectLangChangedFlag, setSelectLangChangedFlag] = useState(false);
  // const [selectRefTypeChangedFlag, setSelectRefTypeChangedFlag] = useState(false);

  // AG-Grid
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [progressFlag, setProgressFlag] = useState(false);
  // const [gridUpdatedFlag, setGridUpdatedFlag] = useState(false);
  const [rowSelectedFlag, setRowSelectedFlag] = useState(false);
  const [gridSubmitFlag, setGridSubmitFlag] = useState(false);
  const [gridRowsCount, setGridRowsCount] = useState(0);

  const [filterDisabledFlag, setFilterDisabledFlag] = useState(true);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  const [modifiedGridRows, setModifiedGridRows] = useState([]);
  const [deletedGridRows, setDeletedGridRows] = useState([]);
  const [currentEditRow, setCurrentEditRow] = useState(null); // Current row being edited

  const [gridFilterValue, setGridFilterValue] = useState("");
  const [paginationPageSize, setPaginationPageSize] = useState(10);

  // const [redirectSignUpFlag, setRedirectSignUpFlag] = useState(false);
  // const [globalErrorMsg, setGlobalErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [ snackbarsMsg, setSnackbarsMsg ] = useState("");
  // const [ snackbarsVariant, setSnackbarsVariant ] = useState("success");// 'success', 'warning', 'error', 'info'

  // For Resources
  const [viewRefTypesRes, setViewRefTypesRes] = useState(viewRefTypes);
  // const [prevLangCode, setPrevLangCode] = useState(appContext.currentLanguage.languageCode);

  // For Grid Resources
  const [localeTextRes, setLocaleTextRes] = useState(localeText);

  const [autoFocusFlag, setAutoFocusFlag] = useState(false);
  const [renderGridFlag, setRenderGridFlag] = useState(false);

  //console.log('AmplifyConfig is: ', JSON.stringify(AmplifyConfig, null, 2));
  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const {
    viewRefTypesLanguageLabel,
    gridRefreshWarning,
    referenceTypesCodeLabel,
    referenceTypesDescLabel,
    selectLanguagePrompt,

    modifiedBy,
    createdAt,
    updatedAt,
    viewReferenceTypes,
    allFieldsAreRequired,
    filterInputPrompt,
    filterGridLabel,
    dataLanguageLabel,
  } = viewRefTypesRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(viewRefTypes);
    // console.log("In ViewRefTypeserenceData(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ViewRefTypeserenceData(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    // setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "viewReferenceTypes")
              viewRefTypes.viewReferenceTypes = item.ResourceDesc;
            if (item.ResourceCode === "viewRefTypesLanguageLabel")
              viewRefTypes.viewRefTypesLanguageLabel = item.ResourceDesc;
            if (item.ResourceCode === "gridRefreshWarning")
              viewRefTypes.gridRefreshWarning = item.ResourceDesc;
            if (item.ResourceCode === "referenceTypesCodeLabel")
              viewRefTypes.referenceTypesCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "referenceTypesDescLabel")
              viewRefTypes.referenceTypesDescLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectLanguagePrompt")
              viewRefTypes.selectLanguagePrompt = item.ResourceDesc;

            if (item.ResourceCode === "modifiedBy")
              viewRefTypes.modifiedBy = item.ResourceDesc;
            if (item.ResourceCode === "createdAt")
              viewRefTypes.createdAt = item.ResourceDesc;
            if (item.ResourceCode === "updatedAt")
              viewRefTypes.updatedAt = item.ResourceDesc;
            if (item.ResourceCode === "allFieldsAreRequired")
              viewRefTypes.allFieldsAreRequired = item.ResourceDesc;
            if (item.ResourceCode === "filterInputPrompt")
              viewRefTypes.filterInputPrompt = item.ResourceDesc;
            if (item.ResourceCode === "filterGridLabel")
              viewRefTypes.filterGridLabel = item.ResourceDesc;
            if (item.ResourceCode === "dataLanguageLabel")
              viewRefTypes.dataLanguageLabel = item.ResourceDesc;

            // For Grid
            if (item.ResourceCode === "gridPage")
              localeText.page = item.ResourceDesc;
            if (item.ResourceCode === "gridTo")
              localeText.to = item.ResourceDesc;
            if (item.ResourceCode === "gridOf")
              localeText.of = item.ResourceDesc;
            if (item.ResourceCode === "noRowsToShow")
              localeText.noRowsToShow = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, viewRefTypes);
        }

        setViewRefTypesRes(viewRefTypes);

        setColumnDefs(columnDefsArray);
        setLocaleTextRes(localeText);

        setRenderGridFlag(true);

        // setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setViewRefTypesRes(viewRefTypes);
        // setProgressFlag(false);
      });
  }

  //---------------------
  // AG-GRID Definitions
  //---------------------

  const columnDefsArray = [
    {
      headerName: referenceTypesCodeLabel,
      field: "ResourceCode",
      width: 140,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
    },
    {
      headerName: referenceTypesDescLabel,
      field: "ResourceDesc",
      width: 200,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellStyle: cellStyle,
    },
    {
      headerName: modifiedBy,
      field: "ModifiedBy",
      width: 200,
      // filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
    },
    {
      headerName: createdAt,
      field: "CreatedAt",
      width: 200,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
      // filter: "agDateColumnFilter",
      hide: true,
    },
    {
      headerName: updatedAt,
      field: "UpdatedAt",
      width: 200,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
      // filter: "agDateColumnFilter",
    },
  ];
  const [columnDefs, setColumnDefs] = useState(columnDefsArray);

  // AG-Grid
  const defColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
  };
  const [defaultColDef, setDefaultColDef] = useState(defColDef);

  const [rowSelection, setRowSelection] = useState("multiple");

  // Fetch Grid Data
  async function fetchGridData(lang) {
    // console.log('In fetchGridData(): lang is: ', lang);

    setFilterDisabledFlag(true);
    // setProgressFlag(true);

    let arrayData = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "reference-types",
          ":resourceSK": lang,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchGridData(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchGridData(): response length is: ', response.data.Items.length);
        // console.log('In fetchGridData(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item, index) => {
          let record = {
            id: "" + index,
            LanguageCode: item.LanguageCode,
            ResourcePK: item.ResourcePK,
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,

            ModifiedBy: item.ModifiedBy,
            CreatedAt: item.CreatedAt,
            UpdatedAt: item.UpdatedAt,
          };

          arrayData.push(record);
        });

        setGridData(arrayData);

        if (response.data.Items.length > 0) {
          setFilterDisabledFlag(false);
        }

        // setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchGridData(): err is: ",
          JSON.stringify(err, null, 2)
        );

        // setProgressFlag(false);
      });
  }

  let selectOptions = [];

  function fetchLanguages() {
    // console.log('In fetchLanguages()');
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages(): response.length is: ', response.data.Items.length);
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            text: item.LanguageName,
          };
          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In useEffect()");
  }, []);

  useLayoutEffect(() => {
    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In ViewReferenceTypes(): useLayoutEffect(): currentLanguage is: ', currentLanguage);
    // console.log('In ViewReferenceTypes(): useLayoutEffect(): GenerateModulusHash("firstName", 200) is: ', GenerateModulusHash("firstName", 200));
    // console.log('In ViewReferenceTypes(): useLayoutEffect(): appContext.signedIn is: ', appContext.signedIn);

    if (selectOptions.length === 0) {
      fetchLanguages();
    }

    // Fetch resource from catche
    let cacheItemKey = "viewRefTypes";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ViewRefTypeserenceData(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ViewRefTypeserenceData(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ViewRefTypeserenceData(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ViewRefTypeserenceData(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ViewRefTypeserenceData(): useLayoutEffect(): got resource from cache');
      setViewRefTypesRes(cacheItem);
    }
  }, []);

  //------------------------
  // Grid Functions
  //------------------------

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
  }

  const onCellClicked = (event) => {
    // console.log('onCellClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onCellClicked: event.data is: ', event.data);
    // console.log('onCellClicked: event.colDef.field is: ', event.colDef.field);

    // let gridRowsCount;

    switch (event.colDef.field) {
      case "delete":
        var selectedData = gridApi.getSelectedRows();
        // console.log('onCellClicked: selectedData is: ', selectedData);

        let filteredSelectedData = selectedData.filter(
          (data) =>
            data.ResourcePK === event.data.ResourcePK &&
            data.ResourceCode === event.data.ResourceCode
        );
        // console.log('onCellClicked: filteredSelectedData is: ', filteredSelectedData);

        var res = gridApi.updateRowData({ remove: filteredSelectedData });
        // console.log("In onCellClicked() res is: ", res);

        // Update DeletedGridRows State
        if (filteredSelectedData && filteredSelectedData.length > 0) {
          let tempArray = deletedGridRows;
          filteredSelectedData.map((record) => {
            tempArray.push(record);
          });
          setDeletedGridRows(tempArray);
        }

        // Update number of rows in grid
        getRowCount();
        initializeForm();

        setGridSubmitFlag(true);
        break;
      case "edit":
        if (event.data) {
          // setReferenceLanguage(getLanguageByCode(event.data.ResourcePK));
          // setReferenceLanguage({value: 'es', text: 'Espanol'});
          setCurrentEditRow(event.data);
          setEditRowIndex(event.rowIndex);
          setResourceCode(event.data.ResourceCode);
          setResourceDesc(event.data.ResourceDesc);
        }
        break;
      default:
        break;
    }
  };

  const onCellValueChanged = (event) => {
    // console.log('onCellValueChanged: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellValueChanged: event.colDef.field is: ', event.colDef.field);

    const tempArray = modifiedGridRows;
    // console.log('onCellValueChanged: tempArray is: ', JSON.stringify(tempArray, null, 2));

    // Remove any previous changes to the same record
    let filteredTempArray = tempArray.filter(
      (data) =>
        data.ResourcePK !== event.data.ResourcePK &&
        data.ResourceCode !== event.data.ResourceCode
    );
    // console.log('onCellValueChanged: filteredTempArray is: ', JSON.stringify(filteredTempArray, null, 2));
    filteredTempArray.push(event.data);
    setModifiedGridRows(filteredTempArray);
  };

  const onRowSelected = (event) => {
    //gridApi = event.api;
    //console.log('onRowSelected: event is: ', event);
    // console.log('onRowSelected: event.data is: ', JSON.stringify(event.data, null, 2));
    setRowSelectedFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getSelectedNodes().length > 0
    ) {
      setRowSelectedFlag(true);
      // console.log('onRowSelected: after rowSelectedFlag is: ', rowSelectedFlag);
    }
  };

  const onRowClicked = (event) => {
    // console.log('onRowClicked: Total number of rows is: ', gridApi.getDisplayedRowCount());
    // console.log('onRowClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onRowClicked: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onRowClicked: clicked row is: ', gridApi.getDisplayedRowAtIndex(event.rowIndex));
  };

  const onRowValueChanged = (event) => {
    // console.log('onRowValueChanged: event is:  ', event);
  };

  const onRowDataChanged = (event) => {
    setColumnDefs(columnDefsArray);
    setLocaleTextRes(localeText);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getModel().getRowCount() > 0
    ) {
      getRowCount();
    }
  };

  const onRowDataUpdated = (event) => {
    // console.log('onRowDataUpdated: event is:  ', event);
  };

  const onFirstDataRendered = (event) => {
    // console.log('onFirstDataRendered: event is:  ', event);
  };

  function clearGridData() {
    if (gridApi !== null) {
      gridApi.setRowData([]);

      getRowCount();

      // Disable Submit button
      setGridSubmitFlag(false);
    }
  }

  function initializeForm() {
    // console.log("In initializeForm()");

    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setResourceCode("");
    setResourceDesc("");

    gridApi.deselectAll();
  }

  function getRowCount() {
    let gridRowsCount = gridApi.getModel().getRowCount();
    setGridRowsCount(gridRowsCount);
    return gridRowsCount;
  }

  function snackbarOnClose(event, reason) {
    let selectedOption = event.target.value;
    // console.log('In snackbarOnClose(): event is', event);
    setSnackbarOpen(false);
  }

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);
    setReferenceLanguage(selectedOption);

    initializeForm();

    setSelectLangChangedFlag(true);

    // Clear the grid of any data
    clearGridData();

    fetchGridData(selectedOption);
  }

  const gridComponents = {
    loadingRenderer: function (params) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<CircularProgress className={classes.progress} color="secondary" size={15}/>';
      }
    },
  };

  function handleGridFilterChange(event) {
    // console.log("In handleGridFilterChange(): value is: "+event.target.value);
    setGridFilterValue(event.target.value);
    gridApi.setQuickFilter(event.target.value);
  }

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { !appContext.signedIn &&
          <Redirect to='/signIn' />
        } */}
        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div
            style={{ boxSizing: "border-box", width: "100%", height: "50%" }}
          >
            {/* progressFlag &&
              <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
              </div>
            */}
            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="md"
            >
              <Grid container justify="center">
                <Grid item style={{ width: "100%" }}>
                  <StyledPaper elevation={0} className={classes.paper}>
                    <Typography
                      variant="h6"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      {viewReferenceTypes}
                    </Typography>

                    {/* <Typography variant="caption" style={{color: 'green', textAlign: 'center'}}>
                      {allFieldsAreRequired}
                    </Typography> */}

                    {globalErrorFlag && (
                      <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                    )}

                    <LineSpacing value={12} />
                  </StyledPaper>
                </Grid>
              </Grid>
            </Container>

            <LineSpacing value={24} />

            {/* AG-GRID */}

            <Container component="div" className={classes.main} maxWidth="lg">
              <Grid container className={classes.filterGrid}>
                <Grid item xs>
                  <Grid container className={classes.filterGrid}>
                    <Grid
                      item
                      xs={3}
                      className={classes.verticalAlignLabel}
                      style={directionAlignment}
                    >
                      <label htmlFor="formLanguageId">
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                        >
                          {dataLanguageLabel}:{" "}
                        </Typography>
                      </label>
                    </Grid>

                    <Grid item xs={8}>
                      <FormControl className={classes.formControl}>
                        <Select
                          id="formLanguageId"
                          value={referenceLanguage}
                          placeholder={selectLanguagePrompt}
                          onChange={handleLanguageChange}
                          variant="outlined"
                          displayEmpty={true}
                          className={classes.selectEmpty}
                          autoFocus
                        >
                          <MenuItem value={null} disabled>
                            <Typography variant="body1">
                              {selectLanguagePrompt}
                            </Typography>
                          </MenuItem>
                          {langSelectOptions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={1}></Grid>
                </Grid>

                <Grid item xs>
                  <Grid container className={classes.filterGrid}>
                    <Grid
                      item
                      xs={2}
                      className={classes.verticalAlignLabel}
                      style={directionAlignment}
                    >
                      <label htmlFor="filterTextId">
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                        >
                          {filterGridLabel}:{" "}
                        </Typography>
                      </label>
                    </Grid>

                    <Grid item xs={10}>
                      <FormControl
                        className={classes.textField}
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="filterTextId"
                          value={gridFilterValue}
                          placeholder={filterInputPrompt}
                          onChange={handleGridFilterChange}
                          fullWidth
                          autoFocus={autoFocusFlag}
                          type="search"
                          disabled={filterDisabledFlag}
                          startAdornment={
                            <InputAdornment position="start">
                              <Search color="primary" />
                            </InputAdornment>
                          }
                          margin="none"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {renderGridFlag && (
                <div style={{ width: "100%", height: "100%" }}>
                  <div
                    id="refGrid"
                    style={{
                      // height: "400px",
                      width: "100%",
                    }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      localeText={localeTextRes}
                      domLayout="autoHeight"
                      onGridReady={onGridReady}
                      gridOptions={gridOptions}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      rowData={gridData}
                      rowSelection={rowSelection}
                      rowMultiSelectWithClick={true}
                      rowDeselection={true}
                      suppressRowClickSelection={false}
                      // debug={true}
                      pagination={true}
                      components={gridComponents}
                      paginationPageSize={paginationPageSize}
                      cacheOverflowSize={2}
                      maxConcurrentDatasourceRequests={2}
                      infiniteInitialRowCount={1}
                      maxBlocksInCache={2}
                      enableRtl={enableRtl}
                      onCellClicked={onCellClicked}
                      onCellValueChanged={onCellValueChanged}
                      onRowSelected={onRowSelected}
                      onRowClicked={onRowClicked}
                      onRowValueChanged={onRowValueChanged}
                      onRowDataChanged={onRowDataChanged}
                      onRowDataUpdated={onRowDataUpdated}
                      onFirstDataRendered={onFirstDataRendered}
                    />
                  </div>
                </div>
              )}
            </Container>
          </div>
        </Container>

        {/* variant: 'success', 'warning', 'error', 'info' 
          <TsiCustomizedSnackbars
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            autoHideDuration={8000}
            onClose={snackbarOnClose}
            ContentProps={{ 'aria-describedby': 'message-id' }}
            message={<span id="message-id">{snackbarsMsg}</span>}
            variant={snackbarsVariant} 
          />*/}

        <footer className={classes.footer}>
          <div>
            <PageFooter isSignedIn={isSignedIn} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default ViewReferenceTypes;
