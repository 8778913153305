import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
import SaveIcon from "@material-ui/icons/Save";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from "@material-ui/core/Container";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import CheckIcon from '@material-ui/icons/Check';
// import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import InputAdornment from "@material-ui/core/InputAdornment";
import TranslateIcon from '@material-ui/icons/Translate';
import Paper from "@material-ui/core/Paper";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';

// react-responsive
import { useMediaQuery } from 'react-responsive';

// moment
import moment from "moment";

// Lodash
import lodash from "lodash";

// currency-symbol-map
import getSymbolFromCurrency from 'currency-symbol-map';

import { OrderList } from 'primereact/orderlist';

// react-media
// import Media from "react-media";

// Validator
// import validator from 'validator';
// import isEmail from "validator/lib/isEmail";
// import isURL from "validator/lib/isURL";
// import isMobilePhone from "validator/lib/isMobilePhone";

//---------------------
// For Primereact
//---------------------

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

// For Primereact
import { Dialog } from 'primereact/dialog';

// Froala
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Amplify
import { API } from "aws-amplify";
// import { AmplifyTheme   } from 'aws-amplify-react';

// AppContext
import AppContext from "../../config/AppContext";

import LineSpacing from "../../components/misc/LineSpacing";
// import FetchS3Object from "../../components/media/FetchS3Object";
// import FroalaCommonEditor from "../../components/editor/FroalaCommonEditor";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import AlertDialog from "../../components/dialogs/AlertDialog";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import CommonMediaSelector from "../../components/dialogs/CommonMediaSelector";
import DomainUploadCapture from "../../components/upload/DomainUploadCapture";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GenerateUniqueName from "../../utils/GenerateUniqueName";
// import GenerateUlid from "../../utils/GenerateUlid";
// import FormatDateTime from "../../../../../utils/moment/FormatDateTime";
import FormatTimezoneDates from "../../utils/moment/FormatTimezoneDates";
import FilterArrayFromArray from "../../utils/filter/FilterArrayFromArray";
import FormatNumber from "../../utils/numberFormat/FormatNumber";
import FormatNumberWithOption from "../../utils/numberFormat/FormatNumberWithOption";
import FormatCurrency from "../../utils/numberFormat/FormatCurrency";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

import DesignBlockConverter from "./DesignBlockConverter";

// Views
import ManageDesignBlockDetailsTv from "./views/ManageDesignBlockDetailsTv";
import ManageDesignBlockDetailsMv from "./views/ManageDesignBlockDetailsMv";
import isURL from "validator/lib/isURL";

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    // leftPanelMainItems: {
    //     marginLeft: theme.spacing(1),
    //     textAlign: "left",
    // },
    leftPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1rem" /* Footer height */,

        width: "100% !important",
        marginTop: "auto",
        // backgroundColor: '#fff',
        textAlign: "center",
    },
    paper: {
        minWidth: "300px",
        marginTop: theme.spacing(2),
        alignItems: "center",
        padding: "10px 30px 10px 30px !important",
        textAlign: "center",
    },
    divLeftPadding: {
        width: '100% !important',
        paddingLeft: theme.spacing(1),
        // paddingRight: theme.spacing(1),
    },
}));

let pageLabels = {
    manageDesignBlockDetailsLabel: "Manage design block details",
    requiredFields: "* Required fields",

    languageLabel: "Language",
    languagePrompt: "Select language",
    languageErrMsg: "Language is required",

    domainLabel: "Domain",
    domainPrompt: "Select a domain",
    domainErrMsg: "Domain is required",

    designBlockLabel: "Design block",
    designBlockPrompt: "Select a design block",
    designBlockErrMsg: "Design block is required",

    detailPositionLabel: "Position",
    detailPositionPrompt: "Enter a design detail position",
    detailPositionErrMsg: "Position is required",
    detailPositionMsg: "For example - Founder & CEO",

    detailTitleLabel: "Title",
    detailTitlePrompt: "Enter a title for this design",
    detailTitleErrMsg: "Title is required",
    // detailTitleMsg: "Add comments about the price category",
    
    designSubTitleLabel: "Sub title",
    designSubTitlePrompt: "Enter a sub title for this design",
    designSubTitleErrMsg: "Sub title is required",

    detailDescLabel: "Description",
    detailDescPrompt: "Enter a description for this design",
    detailDescErrMsg: "Design description is required",

    mediaUrlLabel: "Media URL",
    mediaUrlPrompt: "Please select a media",
    mediaUrlErrMsg: "Media URL is required",

    mediaCaptionLabel: "Media caption",
    mediaCaptionPrompt: "Enter a caption for selected media",
    mediaCaptionErrMsg: "Media caption is required",

    mediaDescLabel: "Media description",
    mediaDescPrompt: "Enter a description for the media",
    mediaDescErrMsg: "Media description is required",

    linkTextLabel: "Link text",
    linkTextPrompt: "Enter some text for the link",
    linkTextErrMsg: "Link text is required",
    linkTextMsg: "This text will be rendered as a link",

    linkDestinationLabel: "Link destination",
    linkDestinationPrompt: "Select a link destination",
    linkDestinationErrMsg: "Link destination is required",
    linkDestinationMsg: "The link destination",

    cloneRecordLabel: "Clone this record",
    uploadFilesLabel: "Upload files",
    convertDesignDetailsLabel: "Convert design block details",

    linkLabel: "Link",
    textLabel: "Text",
    destLabel: "Destination",

    mediaLabel: "Media",
    captionLabel: "Caption",
    descLabel: "Description",
    urlLabel: "URL",

    // yourTimeZoneMsg: "Your timeZone is",
    // timeZoneLabel: "Time zone",

    designDetailRecordLabel: "design detail record",
    designDetailRecordsLabel: "design detail records",
    // inProgressLabel: "In progress",
    // startLabel: "Start",
    // endLabel: "End",
    // notStartedLabel: "Not started",
    // dateCreatedLabel: "Date created",

    editLabel: "Edit",
    deleteLabel: "Delete",

    toLabel: "to",
    ofLabel: "of",
    recordsLabel: "records",
    noRecordsFound: "No records found",

    operationSuccessMsg: "Operation was successful.",
    operationFailureMsg: "Operation failed.",
    duplicateTransMsg: "Duplicate transaction",

    loadingText: "Loading ...",
    noOptionsText: "No options",
    clearText: "Clear",
    closeText: "Close",
    // showLabel: "Show",
    // hideLabel: "Hide",

    cancelLabel: "Cancel",
    // clearLabel: "Clear",
    // okLabel: "Ok",

    yesLabel: "Yes",
    deleteRequested: "Delete requested",
    areYouSureMsg: "Are you sure?",
    requestedToDeleteMsg: "You have requested to delete",
  
    createDesignDetaiLabel: "Create design detail",
    updateDesignDetaiLabel: "Update design detail",

    createMoreLabel: "Create more",
    createLabel: "Create",
    // editEventDestLabel: "Edit email template",
    closeLabel: "Close",
    noDesignDetailsFound: "No design block details found.", 
    // startEventInfoMsg: "This event can't be started now.",    
            
    gridType: "Type",
    gridTitle: "Title",
    gridDesc: "Description",
    lastUpdateLabel: "Last update",

    gridSubTitle: "Sub title",
    // gridStartTime: "Start time",
    // gridBannerUrl: "Banner URL",
    
    convertLabel: "Convert",

    // sourceLanguageLabel: "Source language",
    // sourceLanguagePrompt: "Select source language",
    // sourceLanguageErrMsg: "Source language is required",

    // targetLanguageLabel: "Target language",
    // targetLanguagePrompt: "Select target language",
    // targetLanguageErrMsg: "Target language is required",

    moreLabel: "- more -",

    uploadLabel: "Upload",

    organizeLabel: "Organize",
    organizeDesignDetailsLabel: "Organize design details",
    availableDesignDetailsLabel: "Design block details",
    saveLabel: "Save",
};

function ManageDesignBlockDetails() {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);
    const [renderDomFlag, setRenderDomFlag] = useState(false);
    const [progressFlag, setProgressFlag] = useState(false);

    // For Global Errors
    const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");

    // For Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarsMsg, setSnackbarsMsg] = useState("");
    const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    //--------------------
    // For Froala Editor
    //--------------------
    const editorRef = useRef(null);
    // const [editorModelData, setEditorModelData] = useState(null);
    const [editorMaxChars, setEditorMaxChars] = useState(10000);// Max chars allowed in editor

    //--------------------
    // For Form Attributes
    //--------------------
    // const [profilePhotoSrc, setProfilePhotoSrc] = useState(null);
    
    const [language, setLanguage] = useState(null);
    const [detailTitle, setDetailTitle] = useState("");
    // const [authorPhotoSrc, setAuthorPhotoSrc] = useState("");
    const [designSubTitle, setDesignSubTitle] = useState("");
    // const [currencySymbol, setCurrencySymbol] = useState("$");

    const [detailPosition, setDetailPosition] = useState("");
    // const [liveEventUrl, setLiveEventUrl] = useState("");
    // const [mediaCaption, setLiveEventUrlType] = useState(null);
    const [domain, setDomain] = useState(null);
    const [domainOptions, setDomainOptions] = useState([]);
    
    // const [eventThumbnailUrl, setEventThumbnailUrl] = useState("");
    // const [eventBannerUrl, setEventBannerUrl] = useState("");
    // const [eventPosterUrl, setEventPosterUrl] = useState("");
    // const [eventAudioUrl, setEventAudioUrl] = useState("");
    
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
         
    const [languageErrFlag, setLanguageErrFlag] = useState(false);
    const [domainErrFlag, setDomainErrFlag] = useState(false);
    const [detailTitleErrFlag, setDetailTitleErrFlag] = useState(false);
    const [designSubTitleErrFlag, setDesignSubTitleErrFlag] = useState(false);
    const [detailPositionErrFlag, setDetailPositionErrFlag] = useState(false);
    // const [liveEventUrlErrFlag, setLiveEventUrlErrFlag] = useState(false);
    // const [mediaCaptionErrFlag, setLiveEventUrlTypeErrFlag] = useState(false);

    // const [mediaCaptionOptions, setLiveEventUrlTypeOptions] = useState([]);
    const [detailPositionOptions, setDetailPositionOptions] = useState([]);

    const [submitButtonFlag, setSubmitButtonFlag] = useState(false);
    // const [addTagButtonFlag, setAddTagButtonFlag] = useState(false);
    const [editModeFlag, setEditModeFlag] = useState(false);

    const [datePickerLanguage, setDatePickerLanguage] = useState(appContext.currentLanguage.languageCode);
    
    
    const [showListFlag, setShowListFlag] = useState(true);
    const [showDetailsFormFlag, setShowDetailsFormFlag] = useState(false);
    const [uploadFlag, setUploadFlag] = useState(false);
    const [convertFlag, setConvertFlag] = useState(false);
       
    const [pageSize, setPageSize] = useState(10000);// AWS put PageSize to a max 0f 100

    const [noRecordFoundFlag, setNoRecordFoundFlag] = useState(false);
    const [eventStartedFlag, setEventStartedFlag] = useState(false);
    const [startedEventId, setStartedEventId] = useState(null);
    
    const [textMaxLength, setTextMaxLength] = useState(256);

    const [detailDesc, setDetailDesc] = useState("");
    const [detailDescErrFlag, setDetailDescErrFlag] = useState(false);
    
    const [designBlock, setDesignBlock] = useState(null);
    const [designBlockErrFlag, setDesignBlockErrFlag] = useState(false);
    const [designBlockOptions, setDesignBlockOptions] = useState([]);
    
    const [designDetailRecords, setDesignDetailRecords] = useState(null);
    const [designDetailErrFlag, setDesignDetailErrFlag] = useState(false);
    const [recordsBackup, setRecordsBackup] = useState([]);

    const [mediaUrl, setMediaUrl] = useState("");
    const [mediaUrlErrFlag, setMediaUrlErrFlag] = useState(false);

    const [mediaCaption, setMediaCaption] = useState("");
    const [mediaCaptionErrFlag, setMediaCaptionErrFlag] = useState(false);

    const [mediaDesc, setMediaDesc] = useState("");
    const [mediaDescErrFlag, setMediaDescErrFlag] = useState(false);

    const [linkText, setLinkText] = useState("");
    const [linkTextErrFlag, setLinkTextErrFlag] = useState(false);
    
    const [linkDestination, setLinkDestination] = useState(null);
    const [linkDestinationErrFlag, setLinkDestinationErrFlag] = useState(false);
    const [linkDestinationOptions, setLinkDestinationOptions] = useState([]);
    
    // const [designBlockOption, setDesignBlockOption] = useState(null);
    
    //--------------------------
    // For sliceText and Dialog
    //--------------------------
    const [dialogData, setDialogData] = useState("");
    const [dialogHeader, setDialogHeader] = useState("");
    
    // const [terminologyNames, setTerminologyNames] = useState([
    //     "translate_custom_terminology.txt"
    // ]);
    

    //-------------------------
    // For Primereact 
    // DataTable Attributes 
    //-------------------------
    // const [ dataTableInfo, setDataTableInfo] = useState([]);
    // const [dataTableInfoFlag, setDataTableInfoFlag] = useState(false);
    
    // const [resizableColumnsFlag, setResizableColumnsFlag] = useState(true);
    // const [columnResizeMode, setColumnResizeMode] = useState("fit");
    // const [sortMode, setSortMode] = useState("multiple");
    // const [responsiveFlag, setResponsiveFlag] = useState(true);
    // const [reorderableColumnsFlag, setReorderableColumnsFlag] = useState(true);
    // const [paginatorFlag, setPaginatorFlag] = useState(true);
    const [paginatorTemplate, setPaginatorTemplate] = useState("FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown");
    // const [currentPageReportTemplate, setCurrentPageReportTemplate] = useState(null);
    const [visibleRowsCount, setVisibleRowsCount] = useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5,10,20,50,100]);

    // const [selectionMode, setSelectionMode] = useState("multiple");// single or multiple
    // const [selectedTableRow, setSelectedTableRow] = useState(null);// For single selection
    const [selectedTableRows, setSelectedTableRows] = useState([]);// For multiple selection
    // const [updatedTableRows, setUpdatedTableRows] = useState([]); // For updated (edited) table rows
    // const [deletedTableRows, setDeletedTableRows] = useState([]); // For deleted table rows
    const [tableDirtyFlag, setTableDirtyFlag] = useState(false); // To show DataTable cell was edited (modified)

    // const [deleteRowFlag, setDeleteRowFlag] = useState(false);

    //--------------------
    // For AlertDialog
    //--------------------
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogSubContent, setDialogSubContent] = useState(null);

    const [recordToDelete, setRecordToDelete] = useState(null);
    const [recordToEdit, setRecordToEdit] = useState(null);
    
    //-----------------------
    // For Primereact Dialog
    //-----------------------
    const [dlgVisibleFlag, setDlgVisibleFlag] = useState(false);

    //--------------------
    // For Languages
    //--------------------
    const [languages, setLanguages] = useState([]);

    //------------------
    // For OrderList
    //------------------
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [orderListFlag, setOrderListFlag] = useState(false);
    const [dragdropFlag, setDragdropFlag] = useState(true); // Allow drag and drop
    
    // const {
    //     // editorId,
    // } = props;

    const theme = useTheme();
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    const { 
        manageDesignBlockDetailsLabel, 
        requiredFields,

        languageLabel,
        languagePrompt,
        languageErrMsg,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,
    
        designBlockLabel,
        designBlockPrompt,
        designBlockErrMsg,
            
        detailTitleLabel,
        detailTitlePrompt,
        detailTitleErrMsg,
        // detailTitleMsg,
        
        designSubTitleLabel,
        designSubTitlePrompt,
        designSubTitleErrMsg,

        cloneRecordLabel,
        uploadFilesLabel,
        convertDesignDetailsLabel,

        detailDescLabel,
        detailDescPrompt,
        detailDescErrMsg,

        mediaUrlLabel,
        mediaUrlPrompt,
        mediaUrlErrMsg,
    
        mediaDescLabel,
        mediaDescPrompt,
        mediaDescErrMsg,

        mediaCaptionLabel,
        mediaCaptionPrompt,
        mediaCaptionErrMsg,

        linkTextLabel,
        linkTextPrompt,
        linkTextErrMsg,
        linkTextMsg,

        linkDestinationLabel,
        linkDestinationPrompt,
        linkDestinationErrMsg,
        linkDestinationMsg,

        detailPositionLabel,
        detailPositionPrompt,
        detailPositionErrMsg,
        detailPositionMsg,

        designDetailRecordLabel,
        designDetailRecordsLabel,

        linkLabel,
        textLabel,
        destLabel,

        mediaLabel,
        captionLabel,
        descLabel,
        urlLabel,

        // inProgressLabel,
        // startLabel,
        // endLabel,
        // notStartedLabel,
    
        // dateCreatedLabel,
    
        // mediaDescLabel,
        // mediaDescPrompt,
        // mediaDescErrMsg,

        // yourTimeZoneMsg,
        // timeZoneLabel,
        // convertDesignDetailsLabel,
    
        toLabel,
        ofLabel,
        recordsLabel,
        noRecordsFound,

        gridTitle,
        editLabel,
        deleteLabel,

        operationSuccessMsg,
        operationFailureMsg,
        duplicateTransMsg,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,
        // showLabel,
        // hideLabel,

        cancelLabel,
        // clearLabel,
        // okLabel,

        yesLabel,
        deleteRequested,
        areYouSureMsg,
        requestedToDeleteMsg,
    
        uploadLabel,
        
        createDesignDetaiLabel,
        updateDesignDetaiLabel,

        createMoreLabel,
        createLabel,
        // editEventDestLabel,
        closeLabel,
        noDesignDetailsFound, 
        // startEventInfoMsg,

        gridDesc,
        gridType,
        gridSubTitle,
        lastUpdateLabel,
        // gridStartTime,
        // gridBannerUrl,
    
        convertLabel,

        // sourceLanguageLabel,
        // sourceLanguagePrompt,
        // sourceLanguageErrMsg,

        // targetLanguageLabel,
        // targetLanguagePrompt,
        // targetLanguageErrMsg,

        moreLabel,

        organizeLabel,
        organizeDesignDetailsLabel,
        availableDesignDetailsLabel,
        saveLabel,
    } = pageLabelsRes;

    async function fetchDbResources(cacheItemKey) {
        backdropProgress(true);

        let keys = [];
        let objKeys = Object.keys(pageLabels);
        // console.log("In ManageDesignBlockDetails(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
        if (objKeys !== null && objKeys.length > 0) {
            objKeys.map((key) => {
                let partitionKey = appContext.currentLanguage.languageCode;
                partitionKey += ".";
                partitionKey += GenerateModulusHash(key, 200);

                let itemKey = {
                ResourcePK: partitionKey, // e.g. 'en.91'
                ResourceSK: key, // e.g. 'firstName'
                };
                keys.push(itemKey);
            });
        }
        // console.log("In ManageDesignBlockDetails(): fetchDbResources(): keys.length is: ", keys.length);
        // console.log("In ManageDesignBlockDetails(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

        let arrayData = [];

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/batchGet",
            payload: {
                keys: keys,
                projectionExpression: "ResourceCode,ResourceDesc",
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
            response.data.Responses.resource.map((item, index) => {
            let record = {
                ResourceCode: item.ResourceCode,
                ResourceDesc: item.ResourceDesc,
            };

            arrayData.push(record);
            });
                    
            if (arrayData && arrayData.length > 0) {
                arrayData.map((item) => {
                    if (item.ResourceCode === "manageDesignBlockDetailsLabel") pageLabels.manageDesignBlockDetailsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "requiredFields") pageLabels.requiredFields = item.ResourceDesc;
                    
                    if (item.ResourceCode === "languageLabel") pageLabels.languageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "languagePrompt") pageLabels.languagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "languageErrMsg") pageLabels.languageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "domainLabel") pageLabels.domainLabel = item.ResourceDesc;
                    if (item.ResourceCode === "domainPrompt") pageLabels.domainPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "domainErrMsg") pageLabels.domainErrMsg = item.ResourceDesc;
                                
                    if (item.ResourceCode === "designBlockLabel") pageLabels.designBlockLabel = item.ResourceDesc;
                    if (item.ResourceCode === "designBlockPrompt") pageLabels.designBlockPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "designBlockErrMsg") pageLabels.designBlockErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "detailTitleLabel") pageLabels.detailTitleLabel = item.ResourceDesc;
                    if (item.ResourceCode === "detailTitlePrompt") pageLabels.detailTitlePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "detailTitleErrMsg") pageLabels.detailTitleErrMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "detailTitleMsg") pageLabels.detailTitleMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "designSubTitleLabel") pageLabels.designSubTitleLabel = item.ResourceDesc;
                    if (item.ResourceCode === "designSubTitlePrompt") pageLabels.designSubTitlePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "designSubTitleErrMsg") pageLabels.designSubTitleErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "cloneRecordLabel") pageLabels.cloneRecordLabel = item.ResourceDesc;
                    if (item.ResourceCode === "uploadFilesLabel") pageLabels.uploadFilesLabel = item.ResourceDesc;
                    if (item.ResourceCode === "convertDesignDetailsLabel") pageLabels.convertDesignDetailsLabel = item.ResourceDesc;
                                        
                    if (item.ResourceCode === "detailDescLabel") pageLabels.detailDescLabel = item.ResourceDesc;
                    if (item.ResourceCode === "detailDescPrompt") pageLabels.detailDescPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "detailDescErrMsg") pageLabels.detailDescErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "linkTextLabel") pageLabels.linkTextLabel = item.ResourceDesc;
                    if (item.ResourceCode === "linkTextPrompt") pageLabels.linkTextPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "linkTextErrMsg") pageLabels.linkTextErrMsg = item.ResourceDesc;
                                
                    if (item.ResourceCode === "linkDestinationLabel") pageLabels.linkDestinationLabel = item.ResourceDesc;
                    if (item.ResourceCode === "linkDestinationPrompt") pageLabels.linkDestinationPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "linkDestinationErrMsg") pageLabels.linkDestinationErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "linkDestinationMsg") pageLabels.linkDestinationMsg = item.ResourceDesc;

                    if (item.ResourceCode === "detailPositionLabel") pageLabels.detailPositionLabel = item.ResourceDesc;
                    if (item.ResourceCode === "detailPositionPrompt") pageLabels.detailPositionPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "detailPositionErrMsg") pageLabels.detailPositionErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "detailPositionMsg") pageLabels.detailPositionMsg = item.ResourceDesc;
                                
                    if (item.ResourceCode === "mediaUrlLabel") pageLabels.mediaUrlLabel = item.ResourceDesc;
                    if (item.ResourceCode === "mediaUrlPrompt") pageLabels.mediaUrlPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "mediaUrlErrMsg") pageLabels.mediaUrlErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "mediaDescLabel") pageLabels.mediaDescLabel = item.ResourceDesc;
                    if (item.ResourceCode === "mediaDescPrompt") pageLabels.mediaDescPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "mediaDescErrMsg") pageLabels.mediaDescErrMsg = item.ResourceDesc; 
                    
                    if (item.ResourceCode === "mediaCaptionLabel") pageLabels.mediaCaptionLabel = item.ResourceDesc;
                    if (item.ResourceCode === "mediaCaptionPrompt") pageLabels.mediaCaptionPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "mediaCaptionErrMsg") pageLabels.mediaCaptionErrMsg = item.ResourceDesc;
                                    
                    // if (item.ResourceCode === "yourTimeZoneMsg") pageLabels.yourTimeZoneMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "timeZoneLabel") pageLabels.timeZoneLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "convertDesignDetailsLabel") pageLabels.convertDesignDetailsLabel = item.ResourceDesc;
                                
                    if (item.ResourceCode === "designDetailRecordsLabel") pageLabels.designDetailRecordsLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "inProgressLabel") pageLabels.inProgressLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "startLabel") pageLabels.startLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "endLabel") pageLabels.endLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "notStartedLabel") pageLabels.notStartedLabel = item.ResourceDesc;
                      
                    // if (item.ResourceCode === "dateCreatedLabel") pageLabels.dateCreatedLabel = item.ResourceDesc;
            
                    if (item.ResourceCode === "linkLabel") pageLabels.linkLabel = item.ResourceDesc;
                    if (item.ResourceCode === "textLabel") pageLabels.textLabel = item.ResourceDesc;
                    if (item.ResourceCode === "destLabel") pageLabels.destLabel = item.ResourceDesc;
            
                    if (item.ResourceCode === "mediaLabel") pageLabels.mediaLabel = item.ResourceDesc;
                    if (item.ResourceCode === "captionLabel") pageLabels.captionLabel = item.ResourceDesc;
                    if (item.ResourceCode === "descLabel") pageLabels.descLabel = item.ResourceDesc;
                    if (item.ResourceCode === "urlLabel") pageLabels.urlLabel = item.ResourceDesc;
                        
                    if (item.ResourceCode === "toLabel") pageLabels.toLabel = item.ResourceDesc;
                    if (item.ResourceCode === "ofLabel") pageLabels.ofLabel = item.ResourceDesc;
                    if (item.ResourceCode === "recordsLabel") pageLabels.recordsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noRecordsFound") pageLabels.noRecordsFound = item.ResourceDesc;

                    if (item.ResourceCode === "createLabel") pageLabels.createLabel = item.ResourceDesc;
                    if (item.ResourceCode === "gridTitle") pageLabels.gridTitle = item.ResourceDesc;
                    if (item.ResourceCode === "editLabel") pageLabels.editLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteLabel") pageLabels.deleteLabel = item.ResourceDesc;

                    if (item.ResourceCode === "operationSuccessMsg") pageLabels.operationSuccessMsg = item.ResourceDesc;
                    if (item.ResourceCode === "operationFailureMsg") pageLabels.operationFailureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "duplicateTransMsg") pageLabels.duplicateTransMsg = item.ResourceDesc;
                                                                        
                    if (item.ResourceCode === "loadingText") pageLabels.loadingText = item.ResourceDesc;
                    if (item.ResourceCode === "noOptionsText") pageLabels.noOptionsText = item.ResourceDesc;
                    if (item.ResourceCode === "clearText") pageLabels.clearText = item.ResourceDesc;
                    if (item.ResourceCode === "closeText") pageLabels.closeText = item.ResourceDesc;
                    // if (item.ResourceCode === "showLabel") pageLabels.showLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "hideLabel") pageLabels.hideLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "cancelLabel") pageLabels.cancelLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "clearLabel") pageLabels.clearLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "okLabel") pageLabels.okLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "yesLabel") pageLabels.yesLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteRequested") pageLabels.deleteRequested = item.ResourceDesc;
                    if (item.ResourceCode === "areYouSureMsg") pageLabels.areYouSureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "requestedToDeleteMsg") pageLabels.requestedToDeleteMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "uploadLabel") pageLabels.uploadLabel = item.ResourceDesc;
          
                    if (item.ResourceCode === "createDesignDetaiLabel") pageLabels.createDesignDetaiLabel = item.ResourceDesc;
                    if (item.ResourceCode === "updateDesignDetaiLabel") pageLabels.updateDesignDetaiLabel = item.ResourceDesc;
                              
                    if (item.ResourceCode === "designDetailRecordLabel") pageLabels.designDetailRecordLabel = item.ResourceDesc;
                    if (item.ResourceCode === "createMoreLabel") pageLabels.createMoreLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "editEventDestLabel") pageLabels.editEventDestLabel = item.ResourceDesc;
                    if (item.ResourceCode === "closeLabel") pageLabels.closeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noDesignDetailsFound") pageLabels.noDesignDetailsFound = item.ResourceDesc;
                    // if (item.ResourceCode === "startEventInfoMsg") pageLabels.startEventInfoMsg = item.ResourceDesc;
                      
                    if (item.ResourceCode === "gridDesc") pageLabels.gridDesc = item.ResourceDesc;
                    if (item.ResourceCode === "gridSubTitle") pageLabels.gridSubTitle = item.ResourceDesc;
                    if (item.ResourceCode === "lastUpdateLabel") pageLabels.lastUpdateLabel = item.ResourceDesc;
                                              
                    // if (item.ResourceCode === "gridStartTime") pageLabels.gridStartTime = item.ResourceDesc;
                    // if (item.ResourceCode === "gridBannerUrl") pageLabels.gridBannerUrl = item.ResourceDesc;
                    if (item.ResourceCode === "gridType") pageLabels.gridType = item.ResourceDesc;
                                              
                    if (item.ResourceCode === "convertLabel") pageLabels.convertLabel = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "sourceLanguageLabel") pageLabels.sourceLanguageLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "sourceLanguagePrompt") pageLabels.sourceLanguagePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "sourceLanguageErrMsg") pageLabels.sourceLanguageErrMsg = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "targetLanguageLabel") pageLabels.targetLanguageLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "targetLanguagePrompt") pageLabels.targetLanguagePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "targetLanguageErrMsg") pageLabels.targetLanguageErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "moreLabel") pageLabels.moreLabel = item.ResourceDesc;
                    
                    if (item.ResourceCode === "organizeLabel") pageLabels.organizeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "organizeDesignDetailsLabel") pageLabels.organizeDesignDetailsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "availableDesignDetailsLabel") pageLabels.availableDesignDetailsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "saveLabel") pageLabels.saveLabel = item.ResourceDesc;
                });
                // Add it to cache
                SetCacheItem(cacheItemKey, pageLabels);
            }

            setPageLabelsRes(pageLabels);
            backdropProgress(false);
            setTimeout(() => {setRenderDomFlag(true);}, 1000);
        }).catch((err) => {
            console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
            setPageLabelsRes(pageLabels);
            backdropProgress(false);
            setTimeout(() => {setRenderDomFlag(true);}, 1000);
        });
    }

    async function getDesignDetailRecord(key) {
        // console.log('In getDesignDetailRecord(): key.DesignPK is: ', key.DesignPK);
        // console.log('In getDesignDetailRecord(): key.DesignSK is: ', key.DesignSK);
        
        backdropProgress(true);
    
        let params = {
            apiName: "DesignAPI",
            path: "/dynamodb/design/get",
            payload: {
                key: {
                    DesignPK: key.DesignPK,
                    DesignSK: key.DesignSK,
                },
            },
        };
  
        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
  
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In getDesignDetailRecord(): initObj is: ', JSON.stringify(initObj, null, 2));
  
        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In getDesignDetailRecord(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In getDesignDetailRecord(): response.data.Item is: ', response.data.Item);
        
            let item = response.data.Item;

            appContext.currentPK = item.DesignPK;
            appContext.currentSK = item.DesignSK;

            setLanguage(item.LanguageObject);
            setDomain(item.Domain ? item.Domain : null);
            setDesignBlock(item.DesignBlock ? item.DesignBlock : null);

            setDetailTitle(item.DetailTitle ? item.DetailTitle : "");
            setDetailDesc(item.DetailDesc ? item.DetailDesc : "");
            setDetailPosition(item.DetailPosition ? item.DetailPosition : "");

            setMediaUrl(item.MediaUrl ? item.MediaUrl : "");
            setMediaCaption(item.MediaCaption ? item.MediaCaption : "");
            setMediaDesc(item.MediaDesc ? item.MediaDesc : "");

            setLinkText(item.LinkText ? item.LinkText : "");
            setLinkDestination(item.LinkDestination ? item.LinkDestination : null);

            setShowListFlag(false);
            setUploadFlag(false);
            setShowDetailsFormFlag(true);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);

            backdropProgress(false);

            Promise.all([
                fetchReferenceData("link-destinations", item.LanguageObject)
            ]).then(async (results) => {
                // console.log("In getDesignDetailRecord(): results is: ", results);
                // fetchReferenceData("stripe-curr", item.LanguageObject)
            }).catch(error => {
                console.error("In getDesignDetailRecord(): Promise error is: ", error);
            });
        }).catch((err) => {
            console.error("In getDesignDetailRecord(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        
        setRecordToEdit(item);
        await getDesignDetailRecord({DesignPK: item.DesignPK, DesignSK: item.DesignSK,});
    }

    async function fetchLanguages() {
        // console.log("In fetchLanguages()");
        // setFilterDisabledFlag(true);
        return new Promise((resolve, reject) => {
            backdropProgress(true);

            //-------------------------------------------------------
            // Make sure languages is not in cache before proceeding
            //-------------------------------------------------------

            let cachedLanguages = GetCacheItem("languages");
            // console.log('In fetchLanguages(): cachedLanguages is: ', JSON.stringify(cachedLanguages, null, 2));
            cachedLanguages = null;
            if (cachedLanguages && cachedLanguages !== null && cachedLanguages.length > 0) {
                // console.log('In fetchLanguages(): cachedLanguages length is: ', cachedLanguages.length);
                setLanguages(cachedLanguages);
                backdropProgress(false);
                resolve(cachedLanguages);
                return true;
            }

            let params = {
                apiName: "ResourceAPI",
                path: "/dynamodb/resources/query",
                payload: {
                    keyConditionExpression: "#resourcePK = :resourcePK",
                    expressionAttributeNames: { "#resourcePK": "ResourcePK" },
                    expressionAttributeValues: { ":resourcePK": "languages" },
                },
            };

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log("In fetchLanguages(): response length is: ", response.data.Items.length);
                // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));

                // response.data.Items.sort((a, b) =>
                //     a.LanguageName > b.LanguageName ? 1 : -1
                // );

                let records = [];
                let items = response.data.Items;

                items.map(item => {
                    let record ={
                        languageCode: item.ResourceCode,
                        languageName: item.LanguageName
                    };
                    records.push(record);
                });

                // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
                setLanguages(records);

                // Add it to cache
                SetCacheItem("languages", response.data.Items);
                backdropProgress(false);
                resolve(records);
            }).catch((err) => {
                console.error("In fetchLanguages(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject("Error in fetchLanguages()");
            });
        });
    };

    let  refDataSelectOptions = [];
    
    async function fetchReferenceData(refType, lang) {
        return new Promise((resolve, reject) => {
            // console.log('In ManageDesignBlockDetails  fetchReferenceData(): refType is: ', refType);
            // console.log('In ManageDesignBlockDetails  fetchReferenceData(): lang is: ', lang);
            backdropProgress(true);

            refDataSelectOptions = [];

            let apiName = null;
            let path = null;
            let keyConditionExpression = null;
            let expressionAttributeNames = {};
            let expressionAttributeValues = {};
            
            switch(refType) {
                case "design-blocks":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "design-blocks",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                case "supported-domains":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "supported-domains",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                case "link-destinations":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "link-destinations",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                // case "stripe-curr":
                //     apiName = "ResourceAPI";
                //     path = "/dynamodb/resources/query";
                //     keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                //     expressionAttributeNames = {
                //         "#resourcePK": "ResourcePK",
                //         "#resourceSK": "ResourceSK",
                //     };
                //     expressionAttributeValues = {
                //         ":resourcePK": "stripe-curr",
                //         ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                //     };
                //     break;
                default:
                    break;
            }

            setProgressFlag(true);
        
            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    keyConditionExpression: keyConditionExpression,
                    expressionAttributeNames: expressionAttributeNames,
                    expressionAttributeValues: expressionAttributeValues,
                    // "keyConditionExpression": "#resourcePK = :resourcePK",
                    // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                    // "expressionAttributeValues": {":resourcePK": "languages"}
                },
            };
        
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
        
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In  fetchReferenceData(): initObj is: ', JSON.stringify(initObj, null, 2));
        
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In  fetchReferenceData(): response.length is: ', response.data.Items.length);
                // console.log('In  fetchReferenceData(): response is: ', JSON.stringify(response.data.Items, null, 2));
                response.data.Items.map((item) => {
                    let record = {
                        value: item.ResourceCode,
                        text: item.ResourceDesc,
                    };

                    if (record.value !== "all") {// Bypass all
                        refDataSelectOptions.push(record);
                    }
                });

                switch(refType) {
                    case "design-blocks":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setDetailPositionOptions(refDataSelectOptions);
                        break;
                    case "supported-domains":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setDomainOptions(refDataSelectOptions);
                        break;
                    case "link-destinations":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setLinkDestinationOptions(refDataSelectOptions);
                        break;
                    // case "stripe-curr":
                    //     refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                    //     setLinkTextOptions(refDataSelectOptions);
                    //     break;
                    default:
                        break;
                }

                backdropProgress(false);
                resolve(refDataSelectOptions);
            }).catch((err) => {
                console.error("In ManageDesignBlockDetails  fetchReferenceData(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject(err);
            });
        });
    }
    
    let designBlockNextToken = null;

    async function fetchDesignBlockRecords(selectedDomain) {
        // console.log('In fetchDesignBlockRecords(): selectedDomain is: ', selectedDomain);
        if (language && selectedDomain) {
            backdropProgress(true);

            let params = {
                apiName: "DesignAPI",
                path: "/dynamodb/design/query",
                payload: {
                    keyConditionExpression:
                        // "#designPK = :designPK",
                        "#designPK = :designPK AND begins_with(#designSK, :designSK)",
                    expressionAttributeNames: {
                        "#designPK": "DesignPK",
                        "#designSK": "DesignSK",
                    },
                    expressionAttributeValues: {
                        ":designPK": selectedDomain.value + "#" + language.languageCode + "#design",
                        ":designSK": selectedDomain.value + "#" + language.languageCode + "#design#",
                    },
                    // indexName: "PriceDomain-LangAuthorUserName-index"
                        // "keyConditionExpression": "#designPK = :designPK",
                        // "expressionAttributeNames": {"#designPK": "DesignPK"},
                        // "expressionAttributeValues": {":designPK": "languages"}
                },
            };

            if (!lodash.isEmpty(designBlockNextToken)) {
                params.payload.nextToken = designBlockNextToken;
            }
    
            if (!lodash.isEmpty(pageSize)) {
                params.payload.pageSize = pageSize;
            }

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchDesignBlockRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchDesignBlockRecords(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchDesignBlockRecords(): response.data.NextToken is: ', response.data.NextToken);
                // console.log('In fetchDesignBlockRecords(): response.data.Items is: ', response.data.Items);

                let records = [];

                designBlockNextToken = null;

                if (response && !lodash.isEmpty(response.data.NextToken)) {
                    designBlockNextToken = response.data.NextToken;
                    records = designBlockOptions;
                }
            
                let items = response.data.Items;

                if (items && items.length > 0) {
                    setDesignBlockOptions([]);

                    items.map((item) => {
                        let record = {};

                        record.value = item.DesignId;
                        record.text = item.DesignTitle + " - " + item.WebPage.text;

                        records.push(record);
                    });
                }

                // console.log('In fetchDesignBlockRecords(): designBlockNextToken is: ', designBlockNextToken);
                if (!lodash.isEmpty(designBlockNextToken)) {
                    await fetchDesignBlockRecords(selectedDomain);
                }
                
                setTimeout(() => {
                    records.sort((a, b) => (a.DetailTitle > b.DetailTitle ? 1 : -1));// asc
                    setDesignBlockOptions(records);
                }, 100);

                backdropProgress(false);
            }).catch((err) => {
                console.error("In fetchDesignBlockRecords(): err is: ", JSON.stringify(err, null, 2));
                setDesignBlockOptions([]);
                backdropProgress(false);
            });
        }
    }
    
    let designDetailNextToken = null;

    async function fetchDesignDetailRecords(selectedDesignBlock) {
        // console.log('In fetchDesignDetailRecords(): selectedDesignBlock is: ', selectedDesignBlock);

        if (domain && language && selectedDesignBlock) {
            backdropProgress(true);
            setNoRecordFoundFlag(false);
            setRecordsBackup([]);

            let params = {
                apiName: "DesignAPI",
                path: "/dynamodb/design/query",
                payload: {
                    keyConditionExpression:
                        // "#designPK = :designPK",
                        "#designPK = :designPK AND begins_with(#designSK, :designSK)",
                    expressionAttributeNames: {
                        "#designPK": "DesignPK",
                        "#designSK": "DesignSK",
                    },
                    expressionAttributeValues: {
                        ":designPK": domain.value + "#" + language.languageCode + "#detail",
                        ":designSK": domain.value + "#" + language.languageCode + "#detail#" + selectedDesignBlock.value + "#",
                    },
                    // indexName: "PriceDomain-LangAuthorUserName-index"
                        // "keyConditionExpression": "#designPK = :designPK",
                        // "expressionAttributeNames": {"#designPK": "DesignPK"},
                        // "expressionAttributeValues": {":designPK": "languages"}
                },
            };

            if (!lodash.isEmpty(designDetailNextToken)) {
                params.payload.nextToken = designDetailNextToken;
            }
    
            if (!lodash.isEmpty(pageSize)) {
                params.payload.pageSize = pageSize;
            }

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchDesignDetailRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchDesignDetailRecords(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchDesignDetailRecords(): response.data.NextToken is: ', response.data.NextToken);
                // console.log('In fetchDesignDetailRecords(): response.data.Items is: ', response.data.Items);

                let records = [];

                designDetailNextToken = null;

                if (response && !lodash.isEmpty(response.data.NextToken)) {
                    designDetailNextToken = response.data.NextToken;
                    records = designDetailRecords;
                }
            
                let items = response.data.Items;

                if (items && items.length > 0) {
                    setDesignDetailRecords([]);

                    items.map((record) => {
                        // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                        // console.log('In fetchDesignDetailRecords(): formatedDateTime is: ', formatedDateTime);
                        // console.log('In fetchDesignDetailRecords(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
                        let formattedDateTime = "";
                        if (record.UpdatedAt) {
                            formattedDateTime = FormatTimezoneDates(record.UpdatedAt, record.LanguageCode, timeZone, "shortDateAndTime");
                        }
                        // console.log('In fetchDesignDetailRecords(): formattedDateTime is: ', formattedDateTime);
                                
                        let item = {
                            DesignPK: record.DesignPK,
                            DesignSK: record.DesignSK,

                            LanguageObject: record.LanguageObject,
                            LanguageCode: record.LanguageCode,

                            Domain: record.Domain,
                            "DetailDomain": lodash.isEmpty(record.Domain) ? "" : record.Domain.value,

                            "DesignBlock": record.DesignBlock,
                            "DesignBlockText": lodash.isEmpty(record.DesignBlock) ? "" : record.DesignBlock.text,

                            "DetailId": lodash.isEmpty(record.DetailId) ? "" : record.DetailId,


                            "DetailTitle": lodash.isEmpty(record.DetailTitle) ? "" : record.DetailTitle,
                            "DetailDesc": lodash.isEmpty(record.DetailDesc) ? "" : record.DetailDesc,
                            "DetailPosition": lodash.isEmpty(record.DetailPosition) ? "" : record.DetailPosition,

                            "MediaUrl": lodash.isEmpty(record.MediaUrl) ? "" : record.MediaUrl,
                            "MediaCaption": lodash.isEmpty(record.MediaCaption) ? "" : record.MediaCaption,
                            "MediaDesc": lodash.isEmpty(record.MediaDesc) ? "" : record.MediaDesc,

                            "LinkText": lodash.isEmpty(record.LinkText) ? "" : record.LinkText,
                            "LinkDestinationText": lodash.isEmpty(record.LinkDestination) ? null : record.LinkDestination.text,
                            "LinkDestination": lodash.isEmpty(record.LinkDestination) ? null : record.LinkDestination,

                            "DetailSeqNumber": record.DetailSeqNumber ? record.DetailSeqNumber : 0,
                            "DomainLangDesignBlockId": lodash.isEmpty(record.DomainLangDesignBlockId) ? null : record.DomainLangDesignBlockId,

                            "FormattedDateTime": formattedDateTime,
                            "UpdatedAt": record.UpdatedAt,
                        };
                        records.push(item);
                    });
                } else {
                    setNoRecordFoundFlag(true);
                }

                // console.log('In fetchDesignDetailRecords(): designDetailNextToken is: ', designDetailNextToken);
                if (!lodash.isEmpty(designDetailNextToken)) {
                    await fetchDesignDetailRecords(selectedDesignBlock);
                }
                
                setTimeout(() => {
                    records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                    setDesignDetailRecords(records);
                    setRecordsBackup(records);
                }, 100);

                backdropProgress(false);
            }).catch((err) => {
                console.error("In fetchDesignDetailRecords(): err is: ", JSON.stringify(err, null, 2));
                setDesignDetailRecords([]);
                setRecordsBackup([]);
                setNoRecordFoundFlag(true);
                backdropProgress(false);
            });
        }
    }

    async function fetchDesignDetailsForDesignBlock(selectedDesignBlock) {
        // console.log('In fetchDesignDetailsForDesignBlock(): selectedDesignBlock is: ', selectedDesignBlock);

        if (domain && language && selectedDesignBlock) {
            backdropProgress(true);
            setNoRecordFoundFlag(false);

            let params = {
                apiName: "DesignAPI",
                path: "/dynamodb/design/query",
                payload: {
                    keyConditionExpression:
                        // "#designPK = :designPK",
                        "#designPK = :designPK AND begins_with(#designSK, :designSK)",
                    expressionAttributeNames: {
                        "#designPK": "DesignPK",
                        "#designSK": "DesignSK",
                    },
                    expressionAttributeValues: {
                        ":designPK": domain.value + "#" + language.languageCode + "#detail",
                        ":designSK": domain.value + "#" + language.languageCode + "#detail#" + selectedDesignBlock.value + "#",
                    },
                    // indexName: "PriceDomain-LangAuthorUserName-index"
                        // "keyConditionExpression": "#designPK = :designPK",
                        // "expressionAttributeNames": {"#designPK": "DesignPK"},
                        // "expressionAttributeValues": {":designPK": "languages"}
                },
            };

            if (!lodash.isEmpty(designDetailNextToken)) {
                params.payload.nextToken = designDetailNextToken;
            }
    
            if (!lodash.isEmpty(pageSize)) {
                params.payload.pageSize = pageSize;
            }

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchDesignDetailsForDesignBlock(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchDesignDetailsForDesignBlock(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchDesignDetailsForDesignBlock(): response.data.NextToken is: ', response.data.NextToken);
                // console.log('In fetchDesignDetailsForDesignBlock(): response.data.Items is: ', response.data.Items);

                let records = [];

                designDetailNextToken = null;

                if (response && !lodash.isEmpty(response.data.NextToken)) {
                    designDetailNextToken = response.data.NextToken;
                    records = designBlock;
                }
            
                let items = response.data.Items;

                if (items && items.length > 0) {
                    setFilteredRecords([]);

                    items.map((record) => {
                        // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                        // console.log('In fetchDesignDetailsForDesignBlock(): formatedDateTime is: ', formatedDateTime);
                        // console.log('In fetchDesignDetailsForDesignBlock(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
                        let formattedDateTime = "";
                        if (record.UpdatedAt) {
                            formattedDateTime = FormatTimezoneDates(record.UpdatedAt, record.LanguageCode, timeZone, "shortDateAndTime");
                        }
                        // console.log('In fetchDesignDetailsForDesignBlock(): formattedDateTime is: ', formattedDateTime);
                    
                        let item = {
                            DesignPK: record.DesignPK,
                            DesignSK: record.DesignSK,

                            LanguageObject: record.LanguageObject,
                            LanguageCode: record.LanguageCode,
                            Domain: record.Domain,
                            WebPage: record.WebPage,

                            "DesignId": lodash.isEmpty(record.DesignId) ? "" : record.DesignId,

                            "DesignDomain": lodash.isEmpty(record.Domain) ? "" : record.Domain.value,


                            "DetailTitle": lodash.isEmpty(record.DetailTitle) ? "" : record.DetailTitle,
                            "DetailDesc": lodash.isEmpty(record.DetailDesc) ? "" : record.DetailDesc,
                            "DetailPosition": lodash.isEmpty(record.DetailPosition) ? "" : record.DetailPosition,

                            "MediaUrl": lodash.isEmpty(record.MediaUrl) ? "" : record.MediaUrl,
                            "MediaCaption": lodash.isEmpty(record.MediaCaption) ? "" : record.MediaCaption,
                            "MediaDesc": lodash.isEmpty(record.MediaDesc) ? "" : record.MediaDesc,

                            "LinkText": lodash.isEmpty(record.LinkText) ? "" : record.LinkText,
                            "LinkDestination": lodash.isEmpty(record.LinkDestination) ? null : record.LinkDestination,

                            "DetailSeqNumber": record.DetailSeqNumber ? record.DetailSeqNumber : 0,

                            "FormattedDateTime": formattedDateTime,
                            "UpdatedAt": record.UpdatedAt,
                        };
                        records.push(item);
                    });
                } else {
                    setNoRecordFoundFlag(true);
                }

                // console.log('In fetchDesignDetailsForDesignBlock(): designDetailNextToken is: ', designDetailNextToken);
                if (!lodash.isEmpty(designDetailNextToken)) {
                    await fetchDesignDetailsForDesignBlock();
                }
                
                setTimeout(() => {
                    records.sort((a, b) => (a.DetailSeqNumber > b.DetailSeqNumber ? 1 : -1));// asc
                    setFilteredRecords(records);
                }, 100);

                backdropProgress(false);
            }).catch((err) => {
                console.error("In fetchDesignDetailsForDesignBlock(): err is: ", JSON.stringify(err, null, 2));
                setFilteredRecords([]);
                setNoRecordFoundFlag(true);
                backdropProgress(false);
            });
        }
    }
            
    useEffect(() => {
        // console.log('In ManageDesignBlockDetails(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        // sessionStorage.removeItem("current-live-event");
    }, []);

    useLayoutEffect(() => {
        // console.log("In ManageDesignBlockDetails(): useLayoutEffect()");
        async function fetchItems() {
            await Promise.all([
                await fetchLanguages()
            ]).then(async (result) => {
                // console.log('In DomainUploadCapture(): useLayoutEffect(): result[0] is: ', result[0]);
        
                // Fetch resource from catche
                let cacheItemKey = "manage-design-blocks";
                cacheItemKey += ":";
                cacheItemKey += appContext.currentLanguage.languageCode;
                // console.log('In DomainUploadCapture(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

                if (
                    appContext.clearCacheLanguageCodes &&
                    appContext.clearCacheLanguageCodes.length > 0 &&
                    appContext.clearCacheLanguageCodes.includes(
                        appContext.currentLanguage.languageCode
                    )
                ) {
                    // console.log("In DomainUploadCapture(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                    RemoveCacheItem(cacheItemKey);
                }

                let cacheItem = GetCacheItem(cacheItemKey);
                // console.log('In DomainUploadCapture(): useLayoutEffect(): cacheItem is: ', cacheItem);
                cacheItem = null; // remove after testing

                if (cacheItem === null) {
                    // resource is not in cache, fetch from DB
                    // console.log('In DomainUploadCapture(): useLayoutEffect(): resource is not in cache, fetch from DB');
                    await fetchDbResources(cacheItemKey);
                } else {
                    // console.log('In DomainUploadCapture(): useLayoutEffect(): got resource from cache');
                    setPageLabelsRes(cacheItem);
                    setRenderDomFlag(true);
                }
            }).catch((error) => {
                console.error('In DomainUploadCapture(): useLayoutEffect(): error is: ', error);
            });
        }

        fetchItems();
    }, []);
    
    function handleChange(event) {
        // console.log("In handleChange(): event.target.id is: ", event.target.id);
        // console.log("In handleChange(): event.target.value is: ", event.target.value);
        // setSubmitButtonFlag(false);

        switch(event.target.id) {
            case "detailTitleId":
                setDetailTitleErrFlag(false);
                setDetailTitle(event.target.value);

                setSubmitButtonFlag(false);
                if (
                    !lodash.isEmpty(event.target.value) &&
                    // !lodash.isEmpty(designSubTitle) &&
                    !lodash.isEmpty(detailDesc) &&

                    // !lodash.isEmpty(detailPosition) &&
                    !lodash.isEmpty(designBlock) &&
                    !lodash.isEmpty(domain) &&
                    !lodash.isEmpty(language)
                    ) {
                    setSubmitButtonFlag(true);
                }
                break;
            case "detailDescId":
                setDetailDescErrFlag(false);
                setDetailDesc(event.target.value);

                setSubmitButtonFlag(false);
                if (
                    !lodash.isEmpty(event.target.value) &&
                    !lodash.isEmpty(detailTitle) &&
                    // !lodash.isEmpty(designSubTitle) &&

                    // !lodash.isEmpty(detailPosition) &&
                    !lodash.isEmpty(designBlock) &&
                    !lodash.isEmpty(domain) &&
                    !lodash.isEmpty(language)
                    ) {
                    setSubmitButtonFlag(true);
                }
                break;
            case "detailPositionId":
                setDetailPositionErrFlag(false);
                setDetailPosition(event.target.value);
                break;
            case "designSubTitleId":
                setDesignSubTitleErrFlag(false);
                setDesignSubTitle(event.target.value);
                break;
            case "mediaUrlId":
                setMediaUrlErrFlag(false);
                setMediaUrl(event.target.value);
                break;
            case "mediaCaptionId":
                setMediaCaptionErrFlag(false);
                setMediaCaption(event.target.value);
                break;
            case "mediaDescId":
                setMediaDescErrFlag(false);
                setMediaDesc(event.target.value);
                break;
            case "linkTextId":
                setLinkTextErrFlag(false);
                setLinkText(event.target.value);
                break;
            default:
                break;
        }
    }
    
    function handleLanguageChange(event, item) {
        // console.log("In handleLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        setSubmitButtonFlag(false); 
        
        setDomain(null);
        setDomainOptions([]);
        setDesignBlock(null);
        setDesignBlockOptions([]);

        setLanguage(item);

        if (!lodash.isEmpty(item)) {
            Promise.all([
                fetchReferenceData("design-blocks", item),
            ]).then(async (results) => {
                // console.log("In handleLanguageChange(): results is: ", results);
                Promise.all([
                    await fetchReferenceData("supported-domains", item)
                ]).then(async (results2) => {
                    // console.log("In handleLanguageChange(): results2 is: ", results2);
                    Promise.all([
                        await fetchReferenceData("link-destinations", item)
                    ]).then(async (results3) => {
                        // console.log("In handleLanguageChange(): results3 is: ", results3);
                        // await fetchReferenceData("stripe-curr", item)
                    });
                });
            }).catch(error => {
                console.error("In handleLanguageChange(): error is: ", error);
            });
        }

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(designBlock) &&
            // !lodash.isEmpty(detailPosition) &&
            !lodash.isEmpty(domain) &&

            !lodash.isEmpty(detailTitle) &&
            !lodash.isEmpty(detailDesc)
        ) {
            setSubmitButtonFlag(true);
        }
    }

    function handleDetailDescChange(event, item) {
        // console.log("In handleDetailDescChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDetailDescErrFlag(false);
        
        setDetailDesc(item);
    }

    async function handleDomainChange(event, item) {
        // console.log("In handleDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        setDesignBlock(null);
        setDesignBlockOptions([]);

        if (!lodash.isEmpty(item)) {
            sessionStorage.setItem("media-domain-name", item.value);
        }

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(language) &&
            // !lodash.isEmpty(detailPosition) &&
            !lodash.isEmpty(designBlock) &&
            
            !lodash.isEmpty(detailTitle) &&
            !lodash.isEmpty(detailDesc)
            ) {
                setSubmitButtonFlag(true);
        }

        if (!lodash.isEmpty(item)) {
            await fetchDesignBlockRecords(item);
        }
    }

    function handleDesignBlockChange(event, item) {
        // console.log("In handleDesignBlockChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDesignBlockErrFlag(false);
        
        setDesignBlock(item);

        // if (!lodash.isEmpty(item)) {
        //     sessionStorage.setItem("media-domain-name", item.value);
        // }

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(language) &&
            // !lodash.isEmpty(detailPosition) &&
            !lodash.isEmpty(domain) &&
            
            !lodash.isEmpty(detailTitle) &&
            !lodash.isEmpty(detailDesc)
            ) {
                setSubmitButtonFlag(true);
        }
    }

    function handleLinkDestinationChange(event, item) {
        // console.log("In handleLinkDestinationChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setLinkDestinationErrFlag(false);
        
        setLinkDestination(item);
    }
        
    async function handleListLanguageChange(event, item) {
        // console.log("In handleListLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        // setSubmitButtonFlag(false);        
        setLanguage(item);

        setDomain(null);
        setDomainOptions([]);
        setDesignBlock(null);
        setDesignBlockOptions([]);

        if (!lodash.isEmpty(item)) {
            Promise.all([
                await fetchReferenceData("supported-domains", item)
            ]).then(async (results) => {
                // console.log("In handleListLanguageChange(): results is: ", results);
            }).catch(error => {
                console.error("In handleListLanguageChange(): error is: ", error);
            });
        }
    }
    
    async function handleListDomainChange(event, item) {
        // console.log("In handleListDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);

        setDesignBlock(null);
        setDesignBlockOptions([]);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            await fetchDesignBlockRecords(item);
        }
    }

    async function handleListDesignBlockChange(event, item) {
        // console.log("In handleListDesignBlockChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDesignBlockErrFlag(false);
        
        setDesignBlock(item);

        if (!lodash.isEmpty(item)) {
            await fetchDesignDetailRecords(item);
        }
    }

    async function handleOrderListDesignBlockChange(event, item) {
        // console.log("In handleOrderListDesignBlockChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDesignBlockErrFlag(false);
        
        setDesignBlock(item);

        if (!lodash.isEmpty(item)) {
            await fetchDesignDetailsForDesignBlock(item);
        }
    }
        
    function initializeForm() {
        setDetailTitle("");
        // setDomain(null);
        // setDesignSubTitle("");
        setDetailDesc("");
        setDetailPosition("");

        setMediaUrl("");
        setMediaCaption("");
        setMediaDesc("");

        setLinkText("");
        setLinkDestination(null);
    }

    function createDesignBlockAction() {
        initializeForm();

        setLanguage(null);
        setDomain(null);
        setDesignBlock(null);

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(true);
        setUploadFlag(false);
    }

    function createMoreAction() {
        initializeForm();

        setLanguage(null);
        setDomain(null);
        setDesignBlock(null);

        setEditModeFlag(false);
        setShowListFlag(false);
        setUploadFlag(false);
        setShowDetailsFormFlag(true);
    }

    function uploadAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setUploadFlag(true);
    }
    
    function convertAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setUploadFlag(false);
        setConvertFlag(true);
    }

    // Clone
    function cloneAction() {
        // setDomain(null);        
        // setDesignBlock(null);        
        // setDetailPosition("");    

        setDetailTitle("");
        setDesignSubTitle("");
        setDetailDesc("");

        setEditModeFlag(false);
        setSubmitButtonFlag(false);
    }

    function uploadAction() {
        // initializeForm();

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setOrderListFlag(false);
        setUploadFlag(true);
    }
    
    async function closeConvertForm() {
        // await fetchDesignDetailRecords(designBlock);
        
        setTimeout(() => {
            setShowDetailsFormFlag(false);
            setUploadFlag(false);
            setConvertFlag(false);
            setShowListFlag(true);
        }, 500);
    }
    
    async function closeDetailsForm() {
        await fetchDesignDetailRecords(designBlock);
        
        setTimeout(() => {
            setShowDetailsFormFlag(false);
            setUploadFlag(false);
            setEditModeFlag(false);
            setGlobalErrorMsg("");
            setGlobalErrorFlag(false);
            setShowListFlag(true);
        }, 500);
    }
    
    async function closeUploadForm() {
        await fetchDesignDetailRecords(designBlock);
        
        setTimeout(() => {
            setGlobalErrorMsg("");
            setGlobalErrorFlag(false);

            setEditModeFlag(false);
            setShowDetailsFormFlag(false);
            setOrderListFlag(false);
            setUploadFlag(false);
            setShowListFlag(true);
        }, 500);
    }

    async function orderListAction() {
        // console.log("In orderListAction(): language is: ", language);
        setFilteredRecords([]);
        // let recs = designDetailRecords;
        designDetailRecords.sort((a, b) => (a.DesignSeqNumber > b.DesignSeqNumber ? 1 : -1));
        setFilteredRecords(designDetailRecords);

        setDetailPosition("");

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setUploadFlag(false);
        setSubmitButtonFlag(true);
        setOrderListFlag(true);
    }

    async function closeOrderListForm() {
        await fetchDesignDetailRecords(designBlock);
        // initializeForm();

        setEditModeFlag(false);
        setShowListFlag(true);
        setShowDetailsFormFlag(false);
        setUploadFlag(false);
        setOrderListFlag(false);
    }
    
    async function createService() {
        // console.log("In createService(): language is: ", language);
        // console.log("In createService(): domain is: ", domain);
        // console.log("In createService(): detailPosition is: ", detailPosition);
        // console.log("In createService(): detailTitle is: ", detailTitle);
        // console.log("In createService(): designSubTitle is: ", designSubTitle);
        // console.log("In createService(): linkDestination is: ", linkDestination);
        // console.log("In createService(): timeZone is: ", timeZone);
        // console.log("In createService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In createService(): appContext.userName is: ", appContext.userName);
    
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In createService(): Validation passed.");
            // console.log("In createService(): Validation passed.");

            const timestamp = moment.tz(Date.now(), timeZone);
            // console.log("In createService(): timestamp is: ", timestamp);

            // const startTime = moment.tz(priceStartTime, timeZone);
            // console.log("In createService(): startTime is: ", startTime);

            backdropProgress(true);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            let uniqueId = GenerateUniqueName();

            let key = {
                DesignPK: domain.value + "#" + language.languageCode + "#detail",
                DesignSK: domain.value + "#" + language.languageCode + "#detail#" + designBlock.value + "#" + uniqueId,
            };
            // console.log("In createService(): key is: ", key);

            let item = {
                "DesignPK": key.DesignPK,
                "DesignSK": key.DesignSK,

                "DesignId": designBlock.value,
                "DetailId": uniqueId,

                "UniqueAttribute": lodash.isEmpty(key) ? null : key,
                "LanguageObject": lodash.isEmpty(language) ? null : language,
                "LanguageCode": lodash.isEmpty(language) ? null : language.languageCode,

                "Domain": domain,
                "DetailDomain": lodash.isEmpty(domain) ? null : domain.value,

                "DesignBlock": designBlock,

                "DetailTitle": lodash.isEmpty(detailTitle) ? null : detailTitle,
                "DetailDesc": lodash.isEmpty(detailDesc) ? null : detailDesc,
                "DetailPosition": lodash.isEmpty(detailPosition) ? null : detailPosition,

                "MediaUrl": lodash.isEmpty(mediaUrl) ? null : mediaUrl,
                "MediaCaption": lodash.isEmpty(mediaCaption) ? null : mediaCaption,
                "MediaDesc": lodash.isEmpty(mediaDesc) ? null : mediaDesc,

                "LinkText": lodash.isEmpty(linkText) ? null : linkText,
                "LinkDestination": lodash.isEmpty(linkDestination) ? null : linkDestination,

                "DomainLangDesignBlockId": domain.value + "#" + language.languageCode + "#" + designBlock.value,// For GSI

                "DetailSeqNumber": 0,

                "ModifiedBy": appContext.userName,
                "CreatedAt": timestamp,
                "UpdatedAt": timestamp,
            };

            // console.log('In createService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_not_exists(UniqueAttribute)";

            let params = {
                apiName: "DesignAPI",
                path: "/dynamodb/design/put",
                payload: {
                    item: item,
                    conditionExpression: conditionExpression,
                    // updateExpression: updateExpression,
                    // expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In createService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In createService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In createService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar 
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);
            }).catch((err) => {
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                switch(err.code) {
                    case "ConditionalCheckFailedException":
                        console.error("In createService(): Duplicate Error");
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        break;
                    default:
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        console.error("In createService(): err is: ", JSON.stringify(err, null, 2));
                        break;
                }
            });
        }
    }

    async function updateService() {
        // console.log("In updateService(): language is: ", language);
        // console.log("In updateService(): domain is: ", domain);
        // console.log("In updateService(): detailPosition is: ", detailPosition);
        // console.log("In updateService(): detailTitle is: ", detailTitle);
        // console.log("In updateService(): designSubTitle is: ", designSubTitle);
        // console.log("In updateService(): linkDestination is: ", linkDestination);
        // console.log("In updateService(): timeZone is: ", timeZone);
        // console.log("In updateService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In updateService(): appContext.userName is: ", appContext.userName);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In updateService(): Validation passed.");

            // let uniqueId = GenerateUniqueName();// conversion
                
            const timestamp = moment.tz(Date.now(), timeZone);

            backdropProgress(true);
            const startTime = moment.tz(designBlock, timeZone);
            // console.log("In updateService(): startTime is: ", startTime);

            let key = {
                DesignPK: appContext.currentPK,
                DesignSK: appContext.currentSK,
            };
            // console.log("In updateService(): key is: ", key);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
            let updateExpression =
                "SET " +
                "UniqueAttribute = :uniqueAttribute, " +

                "DetailTitle = :detailTitle, " +
                "DetailDesc = :detailDesc, " +
                "DetailPosition = :detailPosition, " +

                "MediaUrl = :mediaUrl, " +
                "MediaCaption = :mediaCaption, " +
                "MediaDesc = :mediaDesc, " +
    
                "LinkText = :linkText, " +
                "LinkDestination = :linkDestination, " +
                
                "DomainLangDesignBlockId = :domainLangDesignBlockId, " +

                "ModifiedBy = :modifiedBy, " + 
                "UpdatedAt = :updatedAt";

            let expressionAttributeValues = {
                ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
                // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
                // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,
                
                // ":uniqueId": lodash.isEmpty(uniqueId) ? null : uniqueId,// conversion
                
                ":detailTitle": lodash.isEmpty(detailTitle) ? null : detailTitle,
                ":detailDesc": lodash.isEmpty(detailDesc) ? null : detailDesc,
                ":detailPosition": lodash.isEmpty(detailPosition) ? null : detailPosition,
                
                ":mediaUrl": lodash.isEmpty(mediaUrl) ? null : mediaUrl,
                ":mediaCaption": lodash.isEmpty(mediaCaption) ? null : mediaCaption,
                ":mediaDesc": lodash.isEmpty(mediaDesc) ? null : mediaDesc,

                ":linkText": lodash.isEmpty(linkText) ? null : linkText,
                ":linkDestination": lodash.isEmpty(linkDestination) ? null : linkDestination,

                ":domainLangDesignBlockId": domain.value + "#" + language.languageCode + "#" + designBlock.value,// For GSI
                
                ":modifiedBy": appContext.userName,
                ":updatedAt": timestamp,
            };
            // console.log('In updateService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_exists(UniqueAttribute)";

            let params = {
                apiName: "DesignAPI",
                path: "/dynamodb/design/update",
                payload: {
                    key: key,
                    conditionExpression: conditionExpression,
                    updateExpression: updateExpression,
                    expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In updateService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In updateService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.put(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In updateService(): response is: ', JSON.stringify(response, null, 2));
                setShowDetailsFormFlag(false);
                setUploadFlag(false);
                setEditModeFlag(false);

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                // initializeForm();

                backdropProgress(false);

                await fetchDesignDetailRecords(designBlock);
                setShowListFlag(true);
            }).catch((err) => {
                console.error("In updateService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    async function deleteService(recToDelete) {
        // console.log("In deleteService(): recToDelete is: ", recToDelete);
        // setDeleteCompleteFlag(false);
        // let itemsToDelete = [];

        if (!lodash.isEmpty(recToDelete)) {
            backdropProgress(true);

            let key = {
                DesignPK: recToDelete.DesignPK,
                DesignSK: recToDelete.DesignSK,
            };

            let params = {
                apiName: "DesignAPI",
                path: "/dynamodb/design/delete",
                payload: {
                    key: key,
                },
            };
    
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In deleteService(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In deleteService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                backdropProgress(false);
                await fetchDesignDetailRecords(designBlock);
            }).catch((err) => {
                console.error("In deleteService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    function validateForm() {
        // console.log("In validateForm()");
        let formErrFlag = false;
  
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
    
        setLanguageErrFlag(false);
        setDomainErrFlag(false);
        setDesignBlockErrFlag(false);
        // setDetailPositionErrFlag(false);
        setDetailTitleErrFlag(false);
        setDetailDescErrFlag(false);
        setMediaUrlErrFlag(false);

        if (lodash.isEmpty(language)) {
            formErrFlag = true;
            setLanguageErrFlag(true);
        }
    
        if (lodash.isEmpty(domain)) {
            formErrFlag = true;
            setDomainErrFlag(true);
        }
    
        if (lodash.isEmpty(designBlock)) {
            formErrFlag = true;
            setDesignBlockErrFlag(true);
        }
    
        // if (lodash.isEmpty(detailPosition)) {
        //     formErrFlag = true;
        //     setDetailPositionErrFlag(true);
        // }
    
        if (lodash.isEmpty(detailTitle)) {
            formErrFlag = true;
            setDetailTitleErrFlag(true);
        }
    
        if (lodash.isEmpty(detailDesc)) {
            formErrFlag = true;
            setDetailDescErrFlag(true);
        }
    
        if (!lodash.isEmpty(mediaUrl)) {
            if (!(isURL(mediaUrl) || mediaUrl.startsWith("./") || mediaUrl.startsWith("/"))) {
                formErrFlag = true;
                setMediaUrlErrFlag(true);
            }
        }
    
        if (formErrFlag) {
            setGlobalErrorFlag(true);
        }
        // console.log("In validateForm(): formErrFlag is: ", formErrFlag);
        return formErrFlag;
    }

    async function createUpdateBtnAction() {
        // console.log("In createUpdateBtnAction()");

        if (editModeFlag) {
            await updateService();
        } else {
            await createService();
        }
    }
    
    function snackbarOnClose(event, reason) {
        setSnackbarOpen(false);
    }

    function handleMultipleSelectionChange(data) {
        // console.log("In handleMultipleSelectionChange(): data.value is: ", data.value);
        setSelectedTableRows(data.value);
        setTableDirtyFlag(true);
    }

    //------------------
    // For AlertDialog
    //------------------
    const [deleteType, setDeleteType] = useState("");

    function showAlertDialog(item, type) {
        // console.log("In showAlertDialog(): type is: ", type);
        setDeleteType(type);
        
        if (type && type === "single") {// single deletion
            setRecordToDelete(item);

            let subContent = item.DetailTitle;
            if (!lodash.isEmpty(item.DetailDesc)) {
                subContent += " - ";
                subContent += item.DetailDesc;
            }
            setDialogSubContent(subContent);
            setAlertOpen(true);
        } else {// multiple deletions
            let msg = FormatNumber(selectedTableRows.length, appContext.currentLanguage.languageCode);
            msg += " ";
            msg += selectedTableRows.length > 1 ? designDetailRecordsLabel : designDetailRecordLabel;
            setDialogSubContent(msg);
            setAlertOpen(true);
        }
    }

    function alertClickAgree() {
        // Close Alert
        if (deleteType && deleteType === "single") {
            setAlertOpen(false);
            deleteService(recordToDelete);
        } else {
            setAlertOpen(false);
            // deleteMultipleAction();
            processMultipleDeletionRecs();
        }
    }

    function alertClickDisagree() {
        // Close Alert
        setAlertOpen(false);
        return false;
    }

    function alertClose() {
        // Close Alert
        setAlertOpen(false);
    }

    function deleteIconTemplate(rowData, column) {
        return <DeleteIcon color="secondary" onClick={() => showAlertDialog(rowData, "single")}/>
    }

    function editIconTemplate(rowData, column) {
        return <EditIcon color="primary" onClick={() => editRecordAction(rowData)}/>
    }

    async function createOrUpdateStreamRecord(rowData) {
        // console.log("In createOrUpdateStreamRecord(): rowData is: ", JSON.stringify(rowData, null, 2));
        // console.log("In createOrUpdateStreamRecord(): CobPK is: ", rowData.CobPK);
        // console.log("In createOrUpdateStreamRecord(): CobSK is: ", rowData.CobSK);

        // console.log("In createOrUpdateStreamRecord(): liveEventUrl is: ", rowData.LiveEventUrl);
        // console.log("In createOrUpdateStreamRecord(): mediaCaption is: ", rowData.LiveEventUrlType);
        // console.log("In createOrUpdateStreamRecord(): mediaDesc is: ", rowData.BlogContent);
        // console.log("In createOrUpdateStreamRecord(): designSubTitle is: ", rowData.DesignSubTitle);
        // console.log("In createOrUpdateStreamRecord(): linkDestination is: ", rowData.LinkDestination);

        // console.log("In createOrUpdateStreamRecord(): designBlock is: ", rowData.BlogStartTime);
        // console.log("In createOrUpdateStreamRecord(): detailPosition is: ", rowData.DetailPosition);

        // console.log("In createOrUpdateStreamRecord(): timeZone is: ", timeZone);
        // console.log("In createOrUpdateStreamRecord(): appContext.deceasedCode is: ", appContext.deceasedCode);
        // console.log("In createOrUpdateStreamRecord(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In createOrUpdateStreamRecord(): appContext.userName is: ", appContext.userName);

        let detailPosition = null;
        if (rowData.LinkDestination) {
            detailPosition = moment(rowData.BlogStartTime).add(parseInt(rowData.LinkDestination.value), 'minutes');
        }
        // console.log("In createOrUpdateStreamRecord(): detailPosition is: ", detailPosition);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: "live-events",
            CobSK: appContext.currentLanguage.languageCode + "#" + appContext.deceasedCode,
        };
        // console.log("In createOrUpdateStreamRecord(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        // let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            "LanguageObject = :languageObject, " +
            "LanguageCode = :languageCode, " +
            
            "LiveEventStarted = :liveEventStarted, " +

            "LinkDestination = :linkDestination, " +

            "BlogStartTime = :designBlock, " +
            "DetailPosition = :detailPosition, " +

            "EventTimeZone = :eventTimeZone, " +

            "LiveEventUrl = :liveEventUrl, " +
            "LiveEventUrlType = :mediaCaption, " +
            "BlogContent = :mediaDesc, " +
            "DetailTitle = :detailTitle, " +
            "DesignSubTitle = :designSubTitle, " +

            "EventThumbnailUrl = :eventThumbnailUrl, " +
            "EventBannerUrl = :eventBannerUrl, " +
            "EventPosterUrl = :eventPosterUrl, " +
            "EventAudioUrl = :eventAudioUrl, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            ":languageObject": lodash.isEmpty(rowData.LanguageObject) ? null : rowData.LanguageObject,
            ":languageCode": lodash.isEmpty(rowData.LanguageCode) ? null : rowData.LanguageCode,
                    
            ":liveEventStarted": true,

            ":linkDestination": lodash.isEmpty(rowData.LinkDestination) ? null : rowData.LinkDestination,

            ":designBlock": lodash.isEmpty(rowData.BlogStartTime) ? null : rowData.BlogStartTime,
            ":detailPosition": lodash.isEmpty(detailPosition) ? null : detailPosition,

            ":eventTimeZone": lodash.isEmpty(timeZone) ? null : timeZone,

            ":liveEventUrl": lodash.isEmpty(rowData.LiveEventUrl) ? null : rowData.LiveEventUrl,
            ":mediaCaption": lodash.isEmpty(rowData.LiveEventUrlType) ? null : rowData.LiveEventUrlType,
            ":detailTitle": lodash.isEmpty(rowData.DetailTitle) ? null : rowData.DetailTitle,
            ":designSubTitle": lodash.isEmpty(rowData.DesignSubTitle) ? null : rowData.DesignSubTitle,
            ":mediaDesc": lodash.isEmpty(rowData.BlogContent) ? null : rowData.BlogContent,

            ":eventThumbnailUrl": lodash.isEmpty(rowData.EventThumbnailUrl) ? null : rowData.EventThumbnailUrl,
            ":eventBannerUrl": lodash.isEmpty(rowData.EventBannerUrl) ? null : rowData.EventBannerUrl,
            ":eventPosterUrl": lodash.isEmpty(rowData.EventPosterUrl) ? null : rowData.EventPosterUrl,
            ":eventAudioUrl": lodash.isEmpty(rowData.EventAudioUrl) ? null : rowData.EventAudioUrl,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In createOrUpdateStreamRecord(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        // conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                // conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In createOrUpdateStreamRecord(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In createOrUpdateStreamRecord(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.put(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In createOrUpdateStreamRecord(): response is: ', JSON.stringify(response, null, 2));
            // setShowDetailsFormFlag(false);
            // setUploadFlag(false);

            // Show Snackbar
            setSnackbarsVariant("success");
            setSnackbarsMsg(operationSuccessMsg);
            setSnackbarOpen(true);

            // initializeForm();
            // const now = moment();
            // let remainTime = now.diff(moment(formattedTime), "minutes");
            // console.log("In createOrUpdateStreamRecord(): remainTime is: ", remainTime + " minutes");

            let records = [];
            rowData.LiveEventStarted = true;
            records = designDetailRecords.filter(item => item.LiveEventId !== rowData.LiveEventId);
            if (records) {
                records.push(rowData);
                records.sort((a, b) => (a.BlogStartTime > b.BlogStartTime ? 1 : -1));
                // setBlogRecords(records);
            }

            backdropProgress(false);
        }).catch((err) => {
            console.error("In createOrUpdateStreamRecord(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }

    async function updateLiveEventStatus(rowData) {
        // console.log("In updateLiveEventStatus(): rowData.CobPK is: ", rowData.CobPK);
        // console.log("In updateLiveEventStatus(): rowData.CobSK is: ", rowData.CobSK);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
                
        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: rowData.CobPK,
            CobSK: rowData.CobSK,
        };
        // console.log("In updateLiveEventStatus(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            // "LanguageObject = :languageObject, " +
            // "LanguageCode = :languageCode, " +

            "LiveEventStarted = :liveEventStarted, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
            // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,

            ":liveEventStarted": true,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In updateLiveEventStatus(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In updateLiveEventStatus(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In updateLiveEventStatus(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.put(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In updateLiveEventStatus(): response is: ', JSON.stringify(response, null, 2));
            setShowDetailsFormFlag(false);
            setUploadFlag(false);

            // Show Snackbar
            setSnackbarsVariant("success");
            setSnackbarsMsg(operationSuccessMsg);
            setSnackbarOpen(true);

            // initializeForm();

            backdropProgress(false);

            // await fetchDesignDetailRecords(designBlock);
            setShowListFlag(true);
        }).catch((err) => {
            console.error("In updateLiveEventStatus(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }

    async function forcedToEndUpdate(rowData, showStatus) {
        // console.log("In forcedToEndUpdate(): rowData.CobPK is: ", rowData.CobPK);
        // console.log("In forcedToEndUpdate(): rowData.CobSK is: ", rowData.CobSK);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        // console.log("In forcedToEndUpdate(): Validation passed.");
            
        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: rowData.CobPK,
            CobSK: rowData.CobSK,
        };
        // console.log("In forcedToEndUpdate(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            // "LanguageObject = :languageObject, " +
            // "LanguageCode = :languageCode, " +

            "ForcedToEndFlag = :forcedToEndFlag, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
            // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,

            ":forcedToEndFlag": true,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In forcedToEndUpdate(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In forcedToEndUpdate(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In forcedToEndUpdate(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.put(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In forcedToEndUpdate(): response is: ', JSON.stringify(response, null, 2));
            setShowDetailsFormFlag(false);
            setUploadFlag(false);

            // Show Snackbar
            if (showStatus) {
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);
            }

            // initializeForm();

            backdropProgress(false);

            // await fetchDesignDetailRecords(designBlock);
            setShowListFlag(true);
        }).catch((err) => {
            console.error("In forcedToEndUpdate(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }
    
    function sortDateField(event) {
        // console.log("In sortDateField(): event is: ", event);

        var values = [...designDetailRecords];
        values.sort((data1, data2) => {
            // Note: We are using actual DateTime object and not the dateTimeFormatted 
            // used for display on data table.
            let value1 = data1.UpdatedAt;
            let value2 = data2.UpdatedAt;
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });

        return values;
    }
    
    // function sortTimeField(event) {
    //     // console.log("In sortTimeField(): event is: ", event);

    //     var values = [...designDetailRecords];
    //     values.sort((data1, data2) => {
    //         // Note: We are using actual DateTime object and not the dateTimeFormatted 
    //         // used for display on data table.
    //         let value1 = data1.DetailPosition;
    //         let value2 = data2.DetailPosition;
    //         let result = null;

    //         if (value1 == null && value2 != null)
    //             result = -1;
    //         else if (value1 != null && value2 == null)
    //             result = 1;
    //         else if (value1 == null && value2 == null)
    //             result = 0;
    //         else if (typeof value1 === 'string' && typeof value2 === 'string')
    //             result = value1.localeCompare(value2);
    //         else
    //             result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

    //         return (event.order * result);
    //     });

    //     return values;
    // }

    let processedRecs = 0;

    async function deleteMultipleAction() {
        // console.log("In deleteMultipleAction(): selectedTableRows.length is: ", selectedTableRows.length);
        // console.log("In deleteMultipleAction(): selectedTableRows is: ", selectedTableRows);

        // Disable Save changes button
        setTableDirtyFlag(false);

        return new Promise((resolve, reject) => {
            if (selectedTableRows && selectedTableRows.length > 0) {
                selectedTableRows.map(record => {
                    setTimeout(async () => {
                        await deleteService(record);

                        processedRecs++;

                        if (processedRecs === selectedTableRows.length) {
                            resolve("Done");
                        }
                    }, 1000);
                });
            }
        });
    }

    async function processMultipleDeletionRecs() {
        // console.log("In processMultipleDeletionRecs()");

        //-------------------
        // Promise.all
        //-------------------
        Promise.all(
            [
                deleteMultipleAction()
            ]).then(async (results) => {
                // console.log("In processMultipleDeletionRecs(): results is: ", results);
                // console.log("In processMultipleDeletionRecs(): records deleted from AWS, now delete them from DataTable");
                // Remove deleted records from DataTable
                let remainingRecords = FilterArrayFromArray(designDetailRecords, selectedTableRows);
                if (remainingRecords) {
                    // setBlogRecords(remainingRecords);
                    setSelectedTableRows([]);
                }
            });
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        // setBlogContent(item.BlogContent);

        setRecordToEdit(item);

        appContext.currentPK = item.DesignPK;
        appContext.currentSK = item.DesignSK;
        await getDesignDetailRecord({DesignPK: item.DesignPK, DesignSK: item.DesignSK,});

        setTimeout(() => {
            setShowListFlag(false);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);
            setShowDetailsFormFlag(true);
        }, 500);
    }
  
    // function sliceUrl(url, maxChars) {
    //     let retValue = url;
    //     if (url.length > maxChars) {
    //         retValue = url.substring(0, (maxChars - 1));
    //         retValue += " ...";
    //     }
    //     return retValue;
    // }

    //--------------------------
    // For CommonMediaSelector
    //--------------------------

    const [dialogOpenFlag, setDialogOpenFlag] = useState(false);
    const [imagesLocationUri, setImagesLocationUri] = useState("");
        
    function onRowSelectedCallback(src) {
        // console.log("In onRowSelectedCallback(): src is: ", src);
        // console.log("In onRowSelectedCallback(): editorInstance is: ", editorInstance);
        // editorInstance.html.insert('<img src="'+ src + '" style="width: 300px;" class="fr-fic fr-dib">');
        setMediaUrl(src ? src : "");
        setDialogOpenFlag(false);
    }

    function dialogCloseCallback() {
        // console.log("In dialogCloseCallback()");
        setDialogOpenFlag(false);
    }

    function selectMediaAction() {
        // We are doing this to be able to trigger a change in
        // CommonMediaSelector especially when editing a record.
        
        if (editModeFlag) {
            const currLang = language;
            setLanguage(null);

            setTimeout(() => {
                setLanguage(currLang);
                setDialogOpenFlag(true);
            }, 500);
        } else {
            setDialogOpenFlag(true);
        }
    }

    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    //------------------------
    // For sliceText and Dialog
    //------------------------

    function sliceMoreAction(record) {
        setDialogData(record.BlogContent);
        setDialogHeader(record.DesignSubTitle);
        setDlgVisibleFlag(true);
    }

    function hideModalDlg() {
        setDlgVisibleFlag(false);
    }
  
    function sliceText(text, maxChars) {
        let retValue = text;
        if (text.length > maxChars) {
            retValue = text.substring(0, (maxChars - 1));
            retValue += " ...";
        }
        return retValue;
    }

    //------------------
    // For OrderList
    //------------------

    function handleFilteredRecords(event) {
        setFilteredRecords(event.value);
    }

    function handleOrderListCategoryChange(event, item) {
        event.preventDefault();
        event.persist();
        // console.log("In handleOrderListCategoryChange(): item is ", item);
        // console.log("In handleOrderListCategoryChange(): designDetailRecords is ", designDetailRecords);

        setFilteredRecords([]);
        setSubmitButtonFlag(false);
        
        setDetailPosition(item);

        if (!lodash.isEmpty(item) && designDetailRecords && designDetailRecords.length > 0) {
            let filteredRecords = designDetailRecords.filter(record => record.DetailPosition.value === item.value);
            setFilteredRecords(filteredRecords);
            if (filteredRecords && filteredRecords.length > 0) {
                filteredRecords.sort((a, b) => (a.DesignSeqNumber > b.DesignSeqNumber ? 1 : -1));
                setFilteredRecords(filteredRecords);
                setSubmitButtonFlag(true);
            }
        }
    }

    function orderListTemplate(record) {
        return (
            <Grid container justify="flex-start" style={{width: '100% !important', display: 'flex'}}>
                <Grid item xs={12} sm={12}>
                    <Grid container justify="flex-start">
                            <Grid item xs={1} sm={1} style={{textAlign: 'left'}}>
                                <Typography variant="caption" style={{textAlign: 'left'}}>
                                    {record.DetailSeqNumber}.
                                </Typography>
                            </Grid>

                            {isMobileView ?
                                <>
                                    <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {record.DetailTitle ? record.DetailTitle : ""}
                                        </Typography>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item xs={2} sm={2} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {detailTitleLabel}:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sm={9} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {record.DetailTitle ? record.DetailTitle : ""}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                    </Grid>
                </Grid>

                {record.DetailTitle && 
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="flex-start">
                                <Grid item xs={1} sm={1} style={{textAlign: 'left'}}>
                                    <Typography variant="caption" style={{textAlign: 'left'}}>
                                        {""}
                                    </Typography>
                                </Grid>

                                {isMobileView ?
                                <>
                                    <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {record.DetailDesc}
                                        </Typography>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item xs={2} sm={2} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {detailDescLabel}:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sm={9} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {record.DetailDesc}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                }
            </Grid>
        );
    }
    
    async function organizeService() {
        // console.log("In organizeService(): filteredRecords is: ", filteredRecords);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (filteredRecords && filteredRecords.length > 0) {
            // console.log("In organizeService(): Validation passed.");

            // Get user timezone
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const timestamp = moment.tz(Date.now(), timeZone);
            const items = [];

            filteredRecords.map((record, index) => {
                let key = {
                    DesignPK: record.DesignPK,
                    DesignSK: record.DesignSK,
                };
                // console.log("In updateGuestBookService(): key is: ", key);

                // ConditionExpression: "attribute_exists(resourceCode)",
                let conditionExpression = "";

                // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
                let updateExpression =
                    "SET " +
                    "UniqueAttribute = :uniqueAttribute, " +

                    "DetailSeqNumber = :detailSeqNumber, " +

                    "ModifiedBy = :modifiedBy, " + 
                    "UpdatedAt = :updatedAt";
        
                let expressionAttributeValues = {
                    ":uniqueAttribute": key,

                    ":detailSeqNumber": index + 1,

                    ":modifiedBy": appContext.userName,
                    ":updatedAt": timestamp,
                };
                // console.log('In updateGuestBookService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

                conditionExpression = "attribute_exists(UniqueAttribute)";

                let item = {
                    key: key,
                    conditionExpression: conditionExpression,
                    updateExpression: updateExpression,
                    expressionAttributeValues: expressionAttributeValues,
                };
                items.push(item);
            });
            // console.log("In organizeService(): items is: ", items);

            let params = {
                apiName: "DesignAPI",
                path: "/dynamodb/design/customBatchUpdate",
                payload: {
                    updateItems: items,
                },
            };
            // console.log('In organizeService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In organizeService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In organizeService(): response is: ', JSON.stringify(response, null, 2));
                setShowDetailsFormFlag(false);
                setUploadFlag(false);
                setOrderListFlag(false);

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                // initializeForm();

                backdropProgress(false);

                await fetchDesignDetailRecords(designBlock);
                setShowListFlag(true);
            }).catch((err) => {
                console.error("In organizeService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }

    }

    return (
        <div dir={direction} className={clsx(classes.root, classes.divWrapper)}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {renderDomFlag &&
                <>
                    {/* { !appContext.signedIn && <Redirect to='/signIn' />} */}
                    <CssBaseline />
                    <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />

                    {isDeskTopView && 
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
                            <Container component="div" className={classes.main} maxWidth="lg">
                                <StyledPaper elevation={3} className={classes.paper}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h5" color="primary" style={{ textAlign: "center" }}>
                                                {manageDesignBlockDetailsLabel}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                                                {requiredFields}
                                            </Typography>
                                        </Grid>

                                        {globalErrorFlag && (
                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={24} />
                                                <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                {showListFlag &&
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                                *{languageLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="languageId"
                                                                            value={language}
                                                                            options={languages}
                                                                            getOptionLabel={option => option.languageName}
                                                                            getOptionSelected={option => option.languageName}
                                                                            onChange={handleListLanguageChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={languagePrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={languageErrFlag}
                                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                                *{domainLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="domainId"
                                                                            value={domain}
                                                                            options={domainOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleListDomainChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            // disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={domainPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={domainErrFlag}
                                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>
                                            
                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={classes.textField} error={designBlockErrFlag}>
                                                                        <label htmlFor="designBlockId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={designBlockErrFlag ? "secondary" : "inherit"}>
                                                                                *{designBlockLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="designBlockId"
                                                                            value={designBlock}
                                                                            options={designBlockOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleListDesignBlockChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={designBlockPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={designBlockErrFlag}
                                                                                    helperText={designBlockErrFlag ? designBlockErrMsg : ""}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            {designDetailRecords && designDetailRecords.length > 0 ?
                                                                <>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <LineSpacing value={12} />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-start">
                                                                                    <Button variant="contained" size="large" disabled={selectedTableRows && selectedTableRows.length > 0 ? false : true} color="primary" onClick={() => showAlertDialog(null, "multiple")}>
                                                                                        <DeleteIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {deleteLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={2} sm={2}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={orderListAction}>
                                                                                        <ImportExportIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {organizeLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={2} sm={2}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={convertAction}>
                                                                                        <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {convertLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={2} sm={2}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={uploadAction}>
                                                                                        <CloudUploadIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {uploadLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-end">
                                                                                    <Button variant="contained" size="large" disabled={false} color="primary" onClick={createMoreAction}>
                                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {createMoreLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12}>
                                                                        <LineSpacing value={8} />
                                                                    </Grid>
                                                                    
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12}>
                                                                            <div className="datatable-doc-demo">
                                                                                <DataTable 
                                                                                    value={designDetailRecords}
                                                                                    resizableColumns={true} 
                                                                                    columnResizeMode={"fit"}
                                                                                    sortMode={"multiple"}
                                                                                    responsive={true}
                                                                                    reorderableColumns={true}

                                                                                    // selectionMode={"multiple"}
                                                                                    selection={selectedTableRows}
                                                                                    onSelectionChange={handleMultipleSelectionChange}

                                                                                    // paginator={visibleRowsCount > 10 ? paginatorFlag : !paginatorFlag} 
                                                                                    paginator={designDetailRecords && designDetailRecords.length > 10} 
                                                                                    // paginatorLeft={paginatorLeft}

                                                                                    paginatorTemplate={paginatorTemplate}
                                                                                    currentPageReportTemplate={"{first}" + " " + toLabel + " " + "{last}" + " " + ofLabel + " " + "{totalRecords}" + " " + recordsLabel} 
                                                                                    rows={visibleRowsCount} 
                                                                                    rowsPerPageOptions={rowsPerPageOptions}
                                                                                    // className="p-datatable-responsive-cob"
                                                                                    // className="p-datatable-striped"
                                                                                    emptyMessage={noRecordsFound}
                                                                                    className={clsx("p-datatable-striped", "p-datatable-customers")}
                                                                                    rowHover 
                                                                                    >

                                                                                    <Column 
                                                                                        header={deleteLabel} 
                                                                                        selectionMode="multiple" 
                                                                                        style={{width:'5em', textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="DetailTitle" 
                                                                                        header={gridTitle} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="DetailDesc" 
                                                                                        header={gridDesc} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        // style={{textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="DetailPosition" 
                                                                                        header={detailPositionLabel} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        // style={{textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="LinkText" 
                                                                                        header={linkTextLabel} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains"
                                                                                        style={{textAlign:'center'}} 
                                                                                    />
                                                                                    <Column 
                                                                                        field="LinkDestinationText" 
                                                                                        header={linkDestinationLabel} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains"
                                                                                        // style={{textAlign:'center'}} 
                                                                                    />
                                                                                    {/* <Column 
                                                                                        field="FormattedDateTime" 
                                                                                        header={lastUpdateLabel} 
                                                                                        sortable={true} 
                                                                                        sortFunction={sortDateField}
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    /> */}
                                                                                    <Column 
                                                                                        field="deleteIcon" 
                                                                                        header={deleteLabel} 
                                                                                        body={deleteIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="editIcon" 
                                                                                        header={editLabel} 
                                                                                        body={editIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                </DataTable>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            :
                                                                <>
                                                                    {noRecordFoundFlag &&
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={12} sm={12}>
                                                                                <Typography variant="body1" color="secondary">
                                                                                    {noDesignDetailsFound}
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <LineSpacing value={12} />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <Button variant="contained" size="large" disabled={false} color="primary" onClick={createDesignBlockAction}>
                                                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                    {createDesignDetaiLabel}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {showDetailsFormFlag && 
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={6}>
                                                                    <Grid container justify="flex-start">
                                                                        <Typography variant="h6" color="primary">
                                                                            {editModeFlag ? updateDesignDetaiLabel : createDesignDetaiLabel}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={12} sm={6}>
                                                                    {editModeFlag &&
                                                                        <Grid container justify="center">
                                                                            <Button color="primary" onClick={cloneAction}>
                                                                                <Typography variant="subtitle1" color="primary">
                                                                                    {cloneRecordLabel}
                                                                                </Typography>
                                                                            </Button>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                                *{languageLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="languageId"
                                                                            value={language}
                                                                            options={languages}
                                                                            getOptionLabel={option => option.languageName}
                                                                            getOptionSelected={option => option.languageName}
                                                                            onChange={handleLanguageChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={languagePrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={languageErrFlag}
                                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                                *{domainLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="domainId"
                                                                            value={domain}
                                                                            options={domainOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleDomainChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={domainPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={domainErrFlag}
                                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={classes.textField} error={designBlockErrFlag}>
                                                                        <label htmlFor="designBlockId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={designBlockErrFlag ? "secondary" : "inherit"}>
                                                                                *{designBlockLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="designBlockId"
                                                                            value={designBlock}
                                                                            options={designBlockOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleDesignBlockChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={designBlockPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={designBlockErrFlag}
                                                                                    helperText={designBlockErrFlag ? designBlockErrMsg : ""}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={detailTitleErrFlag}>
                                                                                <label htmlFor="detailTitleId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={detailTitleErrFlag ? "secondary" : "inherit"}>
                                                                                        *{detailTitleLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextareaAutosize
                                                                                    id="detailTitleId"
                                                                                    aria-label={detailTitleLabel}
                                                                                    rows={1.5}
                                                                                    rowsMax={6}
                                                                                    value={detailTitle}
                                                                                    placeholder={detailTitlePrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    // autoFocus={true}
                                                                                    margin="none"
                                                                                    className={classes.textArea}
                                                                                    maxLength={textMaxLength}
                                                                                />
                                                                                <FormHelperText>{detailTitleErrFlag ? detailTitleErrMsg : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={detailDescErrFlag}>
                                                                                <label htmlFor="detailDescId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={detailDescErrFlag ? "secondary" : "inherit"}>
                                                                                        *{detailDescLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextareaAutosize
                                                                                    id="detailDescId"
                                                                                    aria-label={detailDescLabel}
                                                                                    rows={1.5}
                                                                                    rowsMax={6}
                                                                                    value={detailDesc}
                                                                                    placeholder={detailDescPrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    // autoFocus={true}
                                                                                    margin="none"
                                                                                    className={classes.textArea}
                                                                                    maxLength={textMaxLength}
                                                                                />
                                                                                <FormHelperText>{detailDescErrFlag ? detailDescErrMsg : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={classes.textField} error={detailPositionErrFlag}>
                                                                        <label htmlFor="detailPositionId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={detailPositionErrFlag ? "secondary" : "inherit"}>
                                                                                {detailPositionLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <TextField
                                                                            id="detailPositionId"
                                                                            value={detailPosition}
                                                                            placeholder={detailPositionPrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={detailPositionErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            helperText={detailPositionErrFlag ? detailPositionErrMsg : detailPositionMsg}
                                                                            // InputProps={{
                                                                            //     startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                                                            // }}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={linkTextErrFlag}>
                                                                                <label htmlFor="linkTextId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={linkTextErrFlag ? "secondary" : "inherit"}>
                                                                                        {linkTextLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <OutlinedInput
                                                                                    id="linkTextId"
                                                                                    value={linkText}
                                                                                    placeholder={linkTextPrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    fullWidth
                                                                                    type={"text"}
                                                                                    error={linkTextErrFlag}
                                                                                    margin="none"
                                                                                    variant="outlined"
                                                                                />
                                                                                <FormHelperText>{linkTextErrFlag ? linkTextErrMsg : linkTextMsg}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={classes.textField} error={linkDestinationErrFlag}>
                                                                        <label htmlFor="linkDestinationId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={linkDestinationErrFlag ? "secondary" : "inherit"}>
                                                                                {linkDestinationLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="linkDestinationId"
                                                                            value={linkDestination}
                                                                            options={linkDestinationOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleLinkDestinationChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={linkDestinationPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={linkDestinationErrFlag}
                                                                                    helperText={linkDestinationErrFlag ? linkDestinationErrMsg : linkDestinationMsg}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={mediaCaptionErrFlag}>
                                                                                <label htmlFor="mediaCaptionId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={mediaCaptionErrFlag ? "secondary" : "inherit"}>
                                                                                        {mediaCaptionLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextField
                                                                                    id="mediaCaptionId"
                                                                                    value={mediaCaption}
                                                                                    placeholder={mediaCaptionPrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    fullWidth
                                                                                    type={"text"}
                                                                                    error={mediaCaptionErrFlag}
                                                                                    margin="none"
                                                                                    variant="outlined"
                                                                                    helperText={mediaCaptionErrFlag ? mediaCaptionErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                                                                    // }}
                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={mediaDescErrFlag}>
                                                                        <label htmlFor="mediaDescId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={mediaDescErrFlag ? "secondary" : "inherit"}>
                                                                                {mediaDescLabel}
                                                                            </Typography>
                                                                        </label>
 
                                                                        <TextareaAutosize
                                                                            id="mediaDescId"
                                                                            aria-label={mediaDescLabel}
                                                                            rows={1.5}
                                                                            rowsMax={6}
                                                                            value={mediaDesc}
                                                                            placeholder={mediaDescPrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            // autoFocus={true}
                                                                            margin="none"
                                                                            className={classes.textArea}
                                                                            maxLength={textMaxLength}
                                                                        />
                                                                        <FormHelperText>{mediaDescErrFlag ? mediaDescErrMsg : ""}</FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={mediaUrlErrFlag}>
                                                                        <label htmlFor="mediaUrlId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={mediaUrlErrFlag ? "secondary" : "inherit"}>
                                                                                {mediaUrlLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <OutlinedInput
                                                                            id="mediaUrlId"
                                                                            value={mediaUrl}
                                                                            placeholder={mediaUrlPrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={mediaUrlErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            endAdornment={
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        aria-label={mediaUrlPrompt}
                                                                                        onClick={selectMediaAction}
                                                                                    >
                                                                                        <ImageSearchIcon />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            }
                                                                        />
                                                                        <FormHelperText>{mediaUrlErrFlag ? mediaUrlErrMsg : ""}</FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            {mediaUrl &&
                                                                <Grid container justify="center">
                                                                    <img src={mediaUrl} style={{height: '96px'}} />
                                                                </Grid>
                                                            }           
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={createUpdateBtnAction}>
                                                                    {editModeFlag ?
                                                                    <>
                                                                        <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {updateDesignDetaiLabel} 
                                                                    </>
                                                                    : 
                                                                    <>
                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {createDesignDetaiLabel}
                                                                    </>
                                                                    }
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {uploadFlag && 
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeUploadForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Container component="div" maxWidth="lg">
                                                                <Grid container justify="flex-start" className={clsx(classes.divLeftPadding)}>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container justify="flex-start">
                                                                            <Typography variant="h6" color="primary">
                                                                                {uploadFilesLabel}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Container>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <DomainUploadCapture 
                                                                showHeadersFlag={false}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                {convertFlag &&
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Container component="div" maxWidth="lg">
                                                                <Grid container justify="flex-start" className={clsx(classes.divLeftPadding)}>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container justify="flex-start">
                                                                            <Typography variant="h6" color="primary">
                                                                                {convertDesignDetailsLabel}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Container>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <DesignBlockConverter 
                                                                showHeadersFlag={false}
                                                                referrer={"ManageDesignBlockDetails"}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                {orderListFlag &&
                                                    <Container component="div" className={classes.main} maxWidth="md">
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeOrderListForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-start">
                                                                <Typography variant="h6" color="primary">
                                                                    {organizeDesignDetailsLabel}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={designBlockErrFlag}>
                                                                        <label htmlFor="designBlockId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={designBlockErrFlag ? "secondary" : "inherit"}>
                                                                                *{designBlockLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="designBlockId"
                                                                            value={designBlock}
                                                                            options={designBlockOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleOrderListDesignBlockChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={designBlockPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={designBlockErrFlag}
                                                                                    helperText={designBlockErrFlag ? designBlockErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center" style={{width: '100% !important'}}>
                                                                <div style={{minWidth: '100%'}}>
                                                                    <OrderList 
                                                                        value={filteredRecords} 
                                                                        header={availableDesignDetailsLabel} 
                                                                        dragdrop={dragdropFlag}
                                                                        listStyle={{width: '100% !important', height:'auto'}} 
                                                                        dataKey="PriceId"
                                                                        itemTemplate={orderListTemplate} 
                                                                        onChange={(e) => handleFilteredRecords(e)}
                                                                        style={{width: '100% !important', display: 'flex'}}
                                                                        >
                                                                    </OrderList>
                                                                </div>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={organizeService}>
                                                                    <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                    {saveLabel} 
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Container>
                                                }

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={18} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledPaper>
                            </Container>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={48} />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    }

                    {isTabletView && 
                        <ManageDesignBlockDetailsTv
                            manageDesignBlockDetailsLabel={manageDesignBlockDetailsLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            domainLabel={domainLabel}
                            domainPrompt={domainPrompt}
                            domainErrMsg={domainErrMsg}
                        
                            designBlockLabel={designBlockLabel}
                            designBlockPrompt={designBlockPrompt}
                            designBlockErrMsg={designBlockErrMsg}
                                
                            detailTitleLabel={detailTitleLabel}
                            detailTitlePrompt={detailTitlePrompt}
                            detailTitleErrMsg={detailTitleErrMsg}
                            // detailTitleMsg={detailTitleMsg}
                    
                            detailDescLabel={detailDescLabel}
                            detailDescPrompt={detailDescPrompt}
                            detailDescErrMsg={detailDescErrMsg}
                    
                            linkTextLabel={linkTextLabel}
                            linkTextPrompt={linkTextPrompt}
                            linkTextErrMsg={linkTextErrMsg}
                            linkTextMsg={linkTextMsg}
                            
                            designSubTitleLabel={designSubTitleLabel}
                            designSubTitlePrompt={designSubTitlePrompt}
                            designSubTitleErrMsg={designSubTitleErrMsg}

                            cloneRecordLabel={cloneRecordLabel}
                            uploadFilesLabel={uploadFilesLabel}
                            convertDesignDetailsLabel={convertDesignDetailsLabel}
                    
                            linkDestinationLabel={linkDestinationLabel}
                            linkDestinationPrompt={linkDestinationPrompt}
                            linkDestinationErrMsg={linkDestinationErrMsg}
                            linkDestinationMsg={linkDestinationMsg}
                            detailPositionLabel={detailPositionLabel}
                            detailPositionPrompt={detailPositionPrompt}
                            detailPositionErrMsg={detailPositionErrMsg}
                            detailPositionMsg={detailPositionMsg}
                            gridTitle={gridTitle}
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            // cancelLabel={cancelLabel}

                            yesLabel={yesLabel}
                            deleteRequested={deleteRequested}
                            areYouSureMsg={areYouSureMsg}
                            requestedToDeleteMsg={requestedToDeleteMsg}
                            uploadLabel={uploadLabel}
                            createDesignDetaiLabel={createDesignDetaiLabel}
                            updateDesignDetaiLabel={updateDesignDetaiLabel}
                            designDetailRecordLabel={designDetailRecordLabel}
                            designDetailRecordsLabel={designDetailRecordsLabel}
                            // createMoreLabel={createMoreLabel}
                            createLabel={createLabel}
                            closeLabel={closeLabel}
                            noDesignDetailsFound={noDesignDetailsFound} 
                            // startEventInfoMsg={startEventInfoMsg}
                    
                            gridDesc={gridDesc}
                            gridType={gridType}
                            gridSubTitle={gridSubTitle}
                            lastUpdateLabel={lastUpdateLabel}
                            // gridStartTime={gridStartTime}
                            // gridBannerUrl={gridBannerUrl}
                        
                            convertLabel={convertLabel}
                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            moreLabel={moreLabel}

                            organizeLabel={organizeLabel}
                            organizeDesignDetailsLabel={organizeDesignDetailsLabel}
                            availableDesignDetailsLabel={availableDesignDetailsLabel}
                            saveLabel={saveLabel}

                            mediaUrlLabel={mediaUrlLabel}
                            mediaUrlPrompt={mediaUrlPrompt}
                            mediaUrlErrMsg={mediaUrlErrMsg}
                        
                            mediaDescLabel={mediaDescLabel}
                            mediaDescPrompt={mediaDescPrompt}
                            mediaDescErrMsg={mediaDescPrompt}
                    
                            mediaCaptionLabel={mediaCaptionLabel}
                            mediaCaptionPrompt={mediaCaptionPrompt}
                            mediaCaptionErrMsg={mediaCaptionErrMsg}

                            linkLabel={linkLabel}
                            textLabel={textLabel}
                            destLabel={destLabel}

                            mediaLabel={mediaLabel}
                            captionLabel={captionLabel}
                            descLabel={descLabel}
                            urlLabel={urlLabel}
                                                                                                
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showListFlag={showListFlag}
                            designDetailRecords={designDetailRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            cloneAction={cloneAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noRecordFoundFlag={noRecordFoundFlag}
                            createDesignBlockAction={createDesignBlockAction}
                            showDetailsFormFlag={showDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleListLanguageChange={handleListLanguageChange}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}

                            domain={domain}
                            domainOptions={domainOptions}
                            domainErrFlag={domainErrFlag}
                            handleListDomainChange={handleListDomainChange}
                            handleDomainChange={handleDomainChange}

                            designBlock={designBlock}
                            designBlockOptions={designBlockOptions}
                            designBlockErrFlag={designBlockErrFlag}
                            handleListDesignBlockChange={handleListDesignBlockChange}
                            handleDesignBlockChange={handleDesignBlockChange}
                            handleOrderListDesignBlockChange={handleOrderListDesignBlockChange}

                            designBlock={designBlock}
                            designBlockErrFlag={designBlockErrFlag}
                            designDetailRecords={designDetailRecords}
                        
                            detailPositionErrFlag={detailPositionErrFlag}
                            detailPosition={detailPosition}
                            detailPositionOptions={detailPositionOptions}
                            handleChange={handleChange}
                            selectMediaAction={selectMediaAction}
                            detailTitleErrFlag={detailTitleErrFlag}
                            detailTitle={detailTitle}
                            designSubTitleErrFlag={designSubTitleErrFlag}
                            designSubTitle={designSubTitle}
                            designBlockErrFlag={designBlockErrFlag}
                            designBlock={designBlock}
                            // handleBlogStartTimeChange={handleBlogStartTimeChange}
                            linkDestinationErrFlag={linkDestinationErrFlag}
                            linkDestination={linkDestination}
                            textMaxLength={textMaxLength}
                            mediaDescErrFlag={mediaDescErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            mediaDesc={mediaDesc}
                            submitButtonFlag={submitButtonFlag}
                            createUpdateBtnAction={createUpdateBtnAction}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            closeConvertForm={closeConvertForm}

                            detailDescErrFlag={detailDescErrFlag}
                            detailDesc={detailDesc}
                            // detailDescOptions={detailDescOptions}
                            handleDetailDescChange={handleDetailDescChange}

                            linkTextErrFlag={linkTextErrFlag}
                            linkText={linkText}
                            // handleLinkTextChange={handleLinkTextChange}

                            filteredRecords={filteredRecords}
                            dragdropFlag={dragdropFlag}
                            orderListTemplate={orderListTemplate}
                            closeOrderListForm={closeOrderListForm}
                            handleFilteredRecords={handleFilteredRecords}
                            organizeService={organizeService}

                            mediaUrl={mediaUrl}
                            mediaUrlErrFlag={mediaUrlErrFlag}
                            mediaCaption={mediaCaption}
                            mediaCaptionErrFlag={mediaCaptionErrFlag}
                            mediaDesc={mediaDesc}
                            mediaDescErrFlag={mediaDescErrFlag}
                            linkText={linkText}
                            linkTextErrFlag={linkTextErrFlag}
                            linkDestination={linkDestination}
                            linkDestinationErrFlag={linkDestinationErrFlag}
                            linkDestinationOptions={linkDestinationOptions}

                            handleLinkDestinationChange={handleLinkDestinationChange}
                            orderListFlag={orderListFlag}
                            orderListAction={orderListAction}
                            sliceText={sliceText}
                        />
                    }

                    {isMobileView && 
                        <ManageDesignBlockDetailsMv
                            manageDesignBlockDetailsLabel={manageDesignBlockDetailsLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            domainLabel={domainLabel}
                            domainPrompt={domainPrompt}
                            domainErrMsg={domainErrMsg}
                        
                            designBlockLabel={designBlockLabel}
                            designBlockPrompt={designBlockPrompt}
                            designBlockErrMsg={designBlockErrMsg}
                                
                            detailTitleLabel={detailTitleLabel}
                            detailTitlePrompt={detailTitlePrompt}
                            detailTitleErrMsg={detailTitleErrMsg}
                            // detailTitleMsg={detailTitleMsg}
                    
                            detailDescLabel={detailDescLabel}
                            detailDescPrompt={detailDescPrompt}
                            detailDescErrMsg={detailDescErrMsg}
                    
                            linkTextLabel={linkTextLabel}
                            linkTextPrompt={linkTextPrompt}
                            linkTextErrMsg={linkTextErrMsg}
                            linkTextMsg={linkTextMsg}
                            
                            designSubTitleLabel={designSubTitleLabel}
                            designSubTitlePrompt={designSubTitlePrompt}
                            designSubTitleErrMsg={designSubTitleErrMsg}

                            cloneRecordLabel={cloneRecordLabel}
                            uploadFilesLabel={uploadFilesLabel}
                            convertDesignDetailsLabel={convertDesignDetailsLabel}
                    
                            linkDestinationLabel={linkDestinationLabel}
                            linkDestinationPrompt={linkDestinationPrompt}
                            linkDestinationErrMsg={linkDestinationErrMsg}
                            linkDestinationMsg={linkDestinationMsg}
                            detailPositionLabel={detailPositionLabel}
                            detailPositionPrompt={detailPositionPrompt}
                            detailPositionErrMsg={detailPositionErrMsg}
                            detailPositionMsg={detailPositionMsg}
                            gridTitle={gridTitle}
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            // cancelLabel={cancelLabel}

                            yesLabel={yesLabel}
                            deleteRequested={deleteRequested}
                            areYouSureMsg={areYouSureMsg}
                            requestedToDeleteMsg={requestedToDeleteMsg}
                            uploadLabel={uploadLabel}
                            createDesignDetaiLabel={createDesignDetaiLabel}
                            updateDesignDetaiLabel={updateDesignDetaiLabel}
                            designDetailRecordLabel={designDetailRecordLabel}
                            designDetailRecordsLabel={designDetailRecordsLabel}
                            // createMoreLabel={createMoreLabel}
                            createLabel={createLabel}
                            closeLabel={closeLabel}
                            noDesignDetailsFound={noDesignDetailsFound} 
                            // startEventInfoMsg={startEventInfoMsg}
                    
                            gridDesc={gridDesc}
                            gridType={gridType}
                            gridSubTitle={gridSubTitle}
                            lastUpdateLabel={lastUpdateLabel}
                            // gridStartTime={gridStartTime}
                            // gridBannerUrl={gridBannerUrl}
                        
                            convertLabel={convertLabel}
                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            moreLabel={moreLabel}

                            organizeLabel={organizeLabel}
                            organizeDesignDetailsLabel={organizeDesignDetailsLabel}
                            availableDesignDetailsLabel={availableDesignDetailsLabel}
                            saveLabel={saveLabel}

                            mediaUrlLabel={mediaUrlLabel}
                            mediaUrlPrompt={mediaUrlPrompt}
                            mediaUrlErrMsg={mediaUrlErrMsg}
                        
                            mediaDescLabel={mediaDescLabel}
                            mediaDescPrompt={mediaDescPrompt}
                            mediaDescErrMsg={mediaDescPrompt}
                    
                            mediaCaptionLabel={mediaCaptionLabel}
                            mediaCaptionPrompt={mediaCaptionPrompt}
                            mediaCaptionErrMsg={mediaCaptionErrMsg}

                            linkLabel={linkLabel}
                            textLabel={textLabel}
                            destLabel={destLabel}

                            mediaLabel={mediaLabel}
                            captionLabel={captionLabel}
                            descLabel={descLabel}
                            urlLabel={urlLabel}
                                                                                                
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showListFlag={showListFlag}
                            designDetailRecords={designDetailRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            cloneAction={cloneAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noRecordFoundFlag={noRecordFoundFlag}
                            createDesignBlockAction={createDesignBlockAction}
                            showDetailsFormFlag={showDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleListLanguageChange={handleListLanguageChange}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}

                            domain={domain}
                            domainOptions={domainOptions}
                            domainErrFlag={domainErrFlag}
                            handleListDomainChange={handleListDomainChange}
                            handleDomainChange={handleDomainChange}

                            designBlock={designBlock}
                            designBlockOptions={designBlockOptions}
                            designBlockErrFlag={designBlockErrFlag}
                            handleListDesignBlockChange={handleListDesignBlockChange}
                            handleDesignBlockChange={handleDesignBlockChange}
                            handleOrderListDesignBlockChange={handleOrderListDesignBlockChange}

                            designBlock={designBlock}
                            designBlockErrFlag={designBlockErrFlag}
                            designDetailRecords={designDetailRecords}
                        
                            detailPositionErrFlag={detailPositionErrFlag}
                            detailPosition={detailPosition}
                            detailPositionOptions={detailPositionOptions}
                            handleChange={handleChange}
                            selectMediaAction={selectMediaAction}
                            detailTitleErrFlag={detailTitleErrFlag}
                            detailTitle={detailTitle}
                            designSubTitleErrFlag={designSubTitleErrFlag}
                            designSubTitle={designSubTitle}
                            designBlockErrFlag={designBlockErrFlag}
                            designBlock={designBlock}
                            // handleBlogStartTimeChange={handleBlogStartTimeChange}
                            linkDestinationErrFlag={linkDestinationErrFlag}
                            linkDestination={linkDestination}
                            textMaxLength={textMaxLength}
                            mediaDescErrFlag={mediaDescErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            mediaDesc={mediaDesc}
                            submitButtonFlag={submitButtonFlag}
                            createUpdateBtnAction={createUpdateBtnAction}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            closeConvertForm={closeConvertForm}

                            detailDescErrFlag={detailDescErrFlag}
                            detailDesc={detailDesc}
                            // detailDescOptions={detailDescOptions}
                            handleDetailDescChange={handleDetailDescChange}

                            linkTextErrFlag={linkTextErrFlag}
                            linkText={linkText}
                            // handleLinkTextChange={handleLinkTextChange}

                            filteredRecords={filteredRecords}
                            dragdropFlag={dragdropFlag}
                            orderListTemplate={orderListTemplate}
                            closeOrderListForm={closeOrderListForm}
                            handleFilteredRecords={handleFilteredRecords}
                            organizeService={organizeService}

                            mediaUrl={mediaUrl}
                            mediaUrlErrFlag={mediaUrlErrFlag}
                            mediaCaption={mediaCaption}
                            mediaCaptionErrFlag={mediaCaptionErrFlag}
                            mediaDesc={mediaDesc}
                            mediaDescErrFlag={mediaDescErrFlag}
                            linkText={linkText}
                            linkTextErrFlag={linkTextErrFlag}
                            linkDestination={linkDestination}
                            linkDestinationErrFlag={linkDestinationErrFlag}
                            linkDestinationOptions={linkDestinationOptions}

                            handleLinkDestinationChange={handleLinkDestinationChange}
                            orderListFlag={orderListFlag}
                            orderListAction={orderListAction}
                            sliceText={sliceText}
                        />
                    }
                </>
            }

            <CommonMediaSelector
                referrer={"ManageDesignBlockDetails"}
                mediaLanguageCode={language ? language.languageCode : null}
                mediaDomainName={domain ? domain.value : null}
                mediaActionFlag={showDetailsFormFlag}
                queryType={"media"} // media, audios or videos
                
                dialogOpenFlag={dialogOpenFlag}
                onRowSelectedCallback={onRowSelectedCallback}
                dialogCloseCallback={dialogCloseCallback}
            />

            <Dialog 
                id="modalDlgId"
                rtl={appContext.dirction === 'rtl'}
                showHeader={true}
                header={dialogHeader}
                visible={dlgVisibleFlag} 
                maximizable={true}
                maximized={(isTabletView || isMobileView) ? true : false}
                modal={true} 
                onHide={hideModalDlg}
                >
                <Container component="div" className={classes.modalDlgMain} maxWidth="md">
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="flex-start" style={{color: '#000'}}>
                            <FroalaEditorView model={dialogData}/>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>

            {alertOpen && 
                <AlertDialog
                    alertOpen={alertOpen}
                    alertClose={alertClose}
                    alertClickAgree={alertClickAgree}
                    alertClickDisagree={alertClickDisagree}

                    dialogTitle={deleteRequested}
                    dialogMainContent={requestedToDeleteMsg}
                    dialogSubContent={dialogSubContent}
                    dialogQuestion={areYouSureMsg}
                    yesLabel={yesLabel}
                    noLabel={cancelLabel}
                />
            }

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={8000}
                onClose={snackbarOnClose}
                ContentProps={{ "aria-describedby": "message-id" }}
                message={<span id="message-id">{snackbarsMsg}</span>}
                variant={snackbarsVariant}
            />

            <footer className={classes.footer}>
                <div>
                    <PageFooter isSignedIn={appContext.signedIn} />
                </div>
            </footer>
        </div>
    )
}

export default ManageDesignBlockDetails;
