import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import WebFont from "webfontloader";
import styled, { css } from "styled-components";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import { NavLink, Redirect } from 'react-router-dom';

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";

// import EmailIcon from '@material-ui/icons/Email';
// import LockIcon from '@material-ui/icons/Lock';

// setUserNotConfirmedFlag
import Config from "../../config/Config";

// Amplify
import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import GlobalConstants from "../../utils/GlobalConstants";
// import GeneratePassword from '../../utils/GeneratePassword';
// import GenerateClientId from '../../utils/GenerateClientId';
// import GenerateTransactionId from '../../utils/GenerateTransactionId';
// import GenerateRandomHash from '../../utils/GenerateRandomHash';

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

// import GetCacheItemWithCallback from '../../utils/cache/GetCacheItemWithCallback';
// import GetCacheCurSize from '../../utils/cache/GetCacheCurSize';
// import GetAllCacheKeys from '../../utils/cache/GetAllCacheKeys';

import ConfirmVerifyCode from "./ConfirmVerificationCode";
// import ResetPassword from './ResetPassword';
import SignUp from "./SignUp";
import ChangePassword from "./ChangePassword";
import TheOneTimePassword from "./TheOneTimePassword";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import ConfirmVerificationCode from "./ConfirmVerificationCode";
import ForgotPassword from "./ForgotPassword";
// import TsiCustomizedSnackbars from '../../components/misc/TsiCustomizedSnackbars';
import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";
// import PageBottomFooter from '../../components/footers/PageBottomFooter';
import AlertDialog from "../../components/dialogs/AlertDialog";
// import { setMaxListeners } from 'cluster';
import LineSpacing from "../../components/misc/LineSpacing";

Amplify.configure(AmplifyConfig);

// Load Custom Fonts
WebFont.load({
  google: {
    families: ["Great Vibes"],
  },
  loading: function () {
    // console.log("Font loading");
  },
  active: function () {
    // console.log("Font active");
  },
  inactive: function () {
    // console.log("Font inactive");
  },
  fontloading: function (familyName, fvd) {
    // console.log("Font fontloading");
  },
  fontactive: function (familyName, fvd) {
    // console.log("Font fontactive");
  },
  fontinactive: function (familyName, fvd) {
    // console.log("Font fontinactive");
  },
});

const StyledPaper = styled(Paper)`
  min-width: 300px;
  display: 'flex',
  flexWrap: 'wrap',
  padding: 10px 60px 10px 60px !important;
  margin: 20px 0 40px 0 !important;
  text-align: center !important;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  text-align: right !important;
  color: green;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    // backgroundColor: '#fff',
    backgroundImage: `url(${"assets/logos/background.png"})`,
  },
  gridWidth: {
    width: "80%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    //backgroundColor: '#fff',
  },
  mainForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  margin: {
    // margin: theme.spacing(1),
  },
  textField: {
    width: "100%",
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    backgroundColor: "#fff",
    textAlign: "center",
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  container: {
    marginTop: "50px",
  },
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  greatVibes: {
    fontFamily: "Great Vibes, cursive",
    color: "teal",
    fontSize: "36px",
  },
}));

let signin = {
  signInLabel: "Sign in",
  signInToAccount: "Please sign in to your account.",
  usernameLabel: "Username",
  passwordLabel: "Password",
  cancel: "Cancel",
  togglePassword: "Toggle password visibility",
  enterCorrectUsername: "Please enter a valid email address",
  enterCorrectPassword: "Please enter a valid password",
  invalidUsername: "Invalid or unknown username (email).",
  invalidCredentials: "Invalid username and/or password.",
  forgotPassword: "Forgot your password?",
  emailConstraintsNotMet: "Email constraints are not met.",
  passwordConstraintsNotMet: "Password constraints are not met.",
  noAcctSignUp: "Don't have an account? Sign up",
  limitExceededException: "Attempt limit exceeded, please try after some time.",
  notRegisteredForApp: "You've not registered to use this application.",

  passwordRecoveryTitle: "Password recovery email",
  passwordRecoveryEmail:
    "We are about to send a validation code to the email account you have on file.",
  recoveryPasswordMsg:
    "You must verify the code and enter a new password in the next step.",
  likeToContinue: "Would you like to continue?",
  yesLabel: "Yes",
  noLabel: "No",
};

function SignIn(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(appContext.signIn);

  const [signinRes, setSigninRes] = useState(signin);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  const [confirmCodeFlag, setConfirmCodeFlag] = useState(false);
  const [changePasswordFlag, setChangePasswordFlag] = useState(false);
  const [theOneTimePasswordFlag, setTheOneTimePasswordFlag] = useState(false);
  const [userNotConfirmedFlag, setUserNotConfirmedFlag] = useState(false);
  const [forgotPasswordFlag, setForgotPasswordFlag] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [progressFlag, setProgressFlag] = useState(false);

  // For Error Flags
  const [usernameErrFlag, setUsernameErrFlag] = useState(false);
  const [usernameFocus, setUsernameFocus] = useState(true);
  const [passwordErrFlag, setPasswordErrFlag] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const [redirectSignUpFlag, setRedirectSignUpFlag] = useState(false);

  // Variants
  const [usernameVariant, setUsernameVariant] = useState(null);
  const [passwordVariant, setPasswordVariant] = useState(null);

  // const [passwordType, setPasswordType] = useState('password');
  const [signInButtonFlag, setSignInButtonFlag] = useState(false);

  const { currLanguage } = props;

  //console.log('AmplifyConfig is: ', JSON.stringify(AmplifyConfig, null, 2));
  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  let user;

  const {
    signInLabel,
    signInToAccount,
    usernameLabel,
    passwordLabel,
    cancel,
    togglePassword,
    enterCorrectUsername,
    enterCorrectPassword,
    invalidUsername,
    invalidCredentials,
    forgotPassword,
    emailConstraintsNotMet,
    passwordConstraintsNotMet,
    noAcctSignUp,
    limitExceededException,
    notRegisteredForApp,

    passwordRecoveryTitle,
    passwordRecoveryEmail,
    recoveryPasswordMsg,
    likeToContinue,
    yesLabel,
    noLabel,
  } = signinRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(signin);
    // console.log("In SignIn(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In SignIn(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
        // "queryType": "hash-query",
        // "resourcePK": currentLanguage.languageCode,
        // "keyConditionExpression": "#resourcePK = :resourcePK",
        // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
        // "expressionAttributeValues": {":resourcePK": currentLanguage.languageCode}
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            // id: ""+index,
            // ResourceLanguageCode: item.ResourceLanguageCode,
            // ResourcePK: item.ResourcePK,
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
            // ModifiedBy: item.ModifiedBy,
            // CreatedAt: item.CreatedAt,
            // UpdatedAt: item.UpdatedAt,
          };

          arrayData.push(record);
        });
        // setDbResources(arrayData);
        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "signInLabel")
              signin.signInLabel = item.ResourceDesc;
            if (item.ResourceCode === "signInToAccount")
              signin.signInToAccount = item.ResourceDesc;
            if (item.ResourceCode === "usernameLabel")
              signin.usernameLabel = item.ResourceDesc;
            if (item.ResourceCode === "passwordLabel")
              signin.passwordLabel = item.ResourceDesc;
            if (item.ResourceCode === "togglePassword")
              signin.togglePassword = item.ResourceDesc;
            if (item.ResourceCode === "enterCorrectUsername")
              signin.enterCorrectUsername = item.ResourceDesc;
            if (item.ResourceCode === "enterCorrectPassword")
              signin.enterCorrectPassword = item.ResourceDesc;
            if (item.ResourceCode === "forgotPassword")
              signin.forgotPassword = item.ResourceDesc;
            if (item.ResourceCode === "noAcctSignUp")
              signin.noAcctSignUp = item.ResourceDesc;
            if (item.ResourceCode === "limitExceededException")
              signin.limitExceededException = item.ResourceDesc;
            if (item.ResourceCode === "passwordRecoveryTitle")
              signin.passwordRecoveryTitle = item.ResourceDesc;
            if (item.ResourceCode === "passwordRecoveryEmail")
              signin.passwordRecoveryEmail = item.ResourceDesc;
            if (item.ResourceCode === "recoveryPasswordMsg")
              signin.recoveryPasswordMsg = item.ResourceDesc;
            if (item.ResourceCode === "likeToContinue")
              signin.likeToContinue = item.ResourceDesc;
            if (item.ResourceCode === "yesLabel")
              signin.yesLabel = item.ResourceDesc;
            if (item.ResourceCode === "noLabel")
              signin.noLabel = item.ResourceDesc;
            if (item.ResourceCode === "invalidUsername")
              signin.invalidUsername = item.ResourceDesc;
            if (item.ResourceCode === "invalidCredentials")
              signin.invalidCredentials = item.ResourceDesc;
            if (item.ResourceCode === "emailConstraintsNotMet")
              signin.emailConstraintsNotMet = item.ResourceDesc;
            if (item.ResourceCode === "passwordConstraintsNotMet")
              signin.passwordConstraintsNotMet = item.ResourceDesc;
            if (item.ResourceCode === "notRegisteredForApp")
              signin.passwordConstraintsNotMet = item.notRegisteredForApp;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, signin);
          setSigninRes(signin);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.error(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setSigninRes(signin);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    setUsernameFocus(true);
    setPasswordFocus(false);
  }, []);

  useLayoutEffect(() => {
    // console.log("In SignIn(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "signin";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In SignIn(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In SignIn(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In SignIn(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In SignIn(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In SignIn(): useLayoutEffect(): got resource from cache');
      setSigninRes(cacheItem);
    }

    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In SignIn(): useLayoutEffect(): currentLanguage is: ', JSON.stringify(currentLanguage, null, 2));
    // console.log('In SignIn(): useLayoutEffect(): direction is: ', direction);
    // console.log('In SignIn(): useLayoutEffect(): appContext.signedIn is: ', appContext.signedIn);
  }, []);

  // Check if user has signed in
  // console.log("In SignIn(): appContext.signedIn is: ", appContext.signedIn);
  if (isSignedIn === true) {
    // console.log("In SignIn(): About to redirect.");
    return <Redirect to="/" />;
  }

  function handleChange(event) {
    //console.log("In handleChange(): id is: "+event.target.id);
    //console.log("In handleChange(): tagName is: "+event.target.tagName);
    // console.log("In handleChange(): value is: "+event.target.value);

    setGlobalErrorFlag(false);
    setSignInButtonFlag(false);

    // Variants
    setUsernameVariant("danger");
    setPasswordVariant("danger");

    switch (event.target.id) {
      case "usernameId":
        setUsernameErrFlag(false);
        setUsername(event.target.value);

        // Enable button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          password !== undefined &&
          password !== null &&
          password !== ""
        ) {
          setSignInButtonFlag(true);
        }
        break;
      case "passwordId":
        setPasswordErrFlag(false);
        setPassword(event.target.value);

        // Enable button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          username !== undefined &&
          username !== null &&
          username !== ""
        ) {
          setSignInButtonFlag(true);
        }
        break;
      default:
        break;
    }
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function validateForm() {
    let formErrFlag = false;
    let emailConstErrFlag = false;
    let constraintErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setUsernameErrFlag(false);
    setPasswordErrFlag(false);

    if (username === null || username === "") {
      formErrFlag = true;
      setUsernameErrFlag(true);
    } else if (!username.match(GlobalConstants.emailRegex)) {
      emailConstErrFlag = true;
      formErrFlag = true;
      setUsernameErrFlag(true);
    }

    if (password === null || password === "") {
      formErrFlag = true;
      setPasswordErrFlag(true);
    } else if (!password.match(GlobalConstants.passwordRegex)) {
      constraintErrFlag = true;
      formErrFlag = true;
      setPasswordErrFlag(true);
    }

    // Check for Constraints Errors
    if (constraintErrFlag) {
      setGlobalErrorFlag(true);
      setGlobalErrorMsg(passwordConstraintsNotMet);
    }

    if (emailConstErrFlag) {
      setGlobalErrorFlag(true);
      setGlobalErrorMsg(emailConstraintsNotMet);
    }

    return formErrFlag;
  }

  async function signInService() {
    // console.log(`In signInService(): username is: ${username} and password is: ${password}`);

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    // Test some utils functions
    // console.log('Generated Random Hash is: ', GenerateRandomHash());
    // console.log('Generated ClientId is: ', GenerateClientId());
    // console.log('Generated TransactionId is: ', GenerateTransactionId());
    // console.log('Generated Password is: ', GeneratePassword('jekwempu@tobisoft.co'));

    if (!validateForm()) {
      // Validation Passed - you can proceed
      // console.log('Validation Passed.');

      setProgressFlag(true);

      let validationData = {
        ClientId: Config.us_east_1.cognito.ccms.appClientId,
      };

      // console.log('In signInService(): validationData is: ', JSON.stringify(validationData, null, 2));

      try {
        user = await Auth.signIn(username, password, validationData);
        // console.log('In signInService(): user is: ', JSON.stringify(user, null, 2));
        if (
          user.challengeName === "SMS_MFA" ||
          user.challengeName === "SOFTWARE_TOKEN_MFA"
        ) {
          setProgressFlag(false);
          setConfirmCodeFlag(true);
        } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setProgressFlag(false);
          setChangePasswordFlag(true);
        } else if (user.challengeName === "MFA_SETUP") {
          setProgressFlag(false);
          // This happens when the MFA method is TOTP
          // The user needs to setup the TOTP before using it
          // More info please check the Enabling MFA part
          setTheOneTimePasswordFlag(true);
          Auth.setupTOTP(user);
        } else {
          setProgressFlag(false);
          // The user directly signs in
          //console.log('In signInService(): user is: ', JSON.stringify(user, null, 2));
          let jwtToken = await user
            .getSignInUserSession()
            .getIdToken()
            .getJwtToken(); // for Amplify
          // console.log('In signInService(): jwtToken is: ', JSON.stringify(jwtToken, null, 2));

          if (jwtToken && jwtToken != null) {
            appContext.signedIn = true;
            appContext.userName = username;
            appContext.jwtToken = jwtToken;
            console.log('In signInService(): appContext.userName is: ', appContext.userName);
            // console.log('In signInService(): appContext is: ', JSON.stringify(appContext, null, 2));

            appContext.domain =
              user.signInUserSession.idToken.payload.shortDomain;
            appContext.groupName =
              user.signInUserSession.idToken.payload.groupName;
            appContext.sysRole = user.signInUserSession.idToken.payload.sysRole;
            appContext.precedence =
              user.signInUserSession.idToken.payload.precedence;
            appContext.iamRole = user.signInUserSession.idToken.payload.iamRole;

            appContext.salutation =
              user.signInUserSession.idToken.payload.salutation;
            appContext.nickName =
              user.signInUserSession.idToken.payload.nickName;
            appContext.firstName =
              user.signInUserSession.idToken.payload.firstName;
            appContext.middleName =
              user.signInUserSession.idToken.payload.middleName;
            appContext.lastName =
              user.signInUserSession.idToken.payload.lastName;

            await SetCacheItem("isSignedIn", true);
            await setIsSignedIn(true);
          }
        }
      } catch (err) {
        setProgressFlag(false);
        // console.log('In signInService(): err is: ', JSON.stringify(err, null, 2));
        switch (err.code) {
          case "UserNotConfirmedException":
            // The error happens if the user didn't finish the confirmation step when signing up
            // In this case you need to resend the code and confirm the user
            // About how to resend the code and confirm the user, please check the signUp part
            setUserNotConfirmedFlag(true);
            break;
          case "PasswordResetRequiredException":
            // The error happens when the password is reset in the Cognito console
            // In this case you need to call forgotPassword to reset the password
            // Please check the Forgot Password part.
            setChangePasswordFlag(true);
            break;
          case "NotAuthorizedException":
            // The error happens when the incorrect password is provided
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(invalidCredentials);
            break;
          case "UserNotFoundException":
            // The error happens when the user is not registered with cognito
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(invalidCredentials);
            break;
          case "UserLambdaValidationException":
            // The error happens when the user is not registered to use this application
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(notRegisteredForApp);
            break;
          default:
            console.error("err is: ", JSON.stringify(err, null, 2));
            break;
        }
      }
    }
  }

  function validateUsername() {
    // console.log("In validateUsername(): username is: " + username);

    let usernameErrFlag = false;
    let emailConstErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setUsernameErrFlag(false);

    if (username === null || username === "") {
      emailConstErrFlag = true;
      usernameErrFlag = true;
      setUsernameErrFlag(true);
    } else if (!username.match(GlobalConstants.emailRegex)) {
      emailConstErrFlag = true;
      usernameErrFlag = true;
      setUsernameErrFlag(true);
    }

    if (emailConstErrFlag) {
      setGlobalErrorFlag(true);
      setGlobalErrorMsg(emailConstraintsNotMet);
      setForgotPasswordFlag(false);
    }

    // console.log("In validateUsername(): usernameErrFlag is: " + usernameErrFlag);
    return usernameErrFlag;
  }

  async function forgotPasswordService() {
    // const { invalidCredentials } = signIn;
    // const { verifyNewPasswordFailed } = signIn;
    // console.log("In forgotPasswordService(): username is: " + username);

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");
    setForgotPasswordFlag(false);

    if (!validateUsername()) {
      // console.log("In forgotPasswordService(): usernameErrFlag is: " + usernameErrFlag);
      setProgressFlag(true);
      setForgotPasswordFlag(true);
      Auth.forgotPassword(username)
        .then((result) => {
          // console.log('In forgotPasswordService(): result is', JSON.stringify(result, null, 2));
          setForgotPasswordFlag(true);
          setProgressFlag(false);
        })
        .catch((err) => {
          console.error(
            "In forgotPasswordService(): err is",
            JSON.stringify(err, null, 2)
          );
          setProgressFlag(false);
          setGlobalErrorFlag(true);
          switch (err.code) {
            case "UserNotFoundException":
              setGlobalErrorMsg(invalidUsername);
              break;
            case "LimitExceededException":
              setGlobalErrorMsg(limitExceededException);
              break;
            default:
              setGlobalErrorMsg(invalidUsername);
              break;
          }
        });
    }
  }

  async function redirectSignUp() {
    setRedirectSignUpFlag(true);
  }

  //******************* */
  // Alert
  //******************* */

  function showAlertDialog() {
    setAlertOpen(true);
  }

  function alertClickAgree() {
    // console.log("In alertClickAgree()");
    // Close Alert
    setAlertOpen(false);

    forgotPasswordService();
  }

  function alertClickDisagree() {
    // console.log("In alertClickDisagree()");
    // Close Alert
    setAlertOpen(false);

    return false;
  }

  function alertClose() {
    // console.log("In alertClose()");
    // Close Alert
    setAlertOpen(false);
  }

  return (
    <div dir={direction}>
      <div className={classes.root}>
        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%", height: "100%" }}>
            {progressFlag && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                  size={15}
                />
              </div>
            )}

            {redirectSignUpFlag && <SignUp />}

            {confirmCodeFlag && <ConfirmVerifyCode user={user} />}

            {changePasswordFlag && <ChangePassword user={user} />}

            {theOneTimePasswordFlag && <TheOneTimePassword user={user} />}

            {userNotConfirmedFlag && (
              <ConfirmVerificationCode username={username} />
            )}

            {forgotPasswordFlag && <ForgotPassword username={username} />}

            <div className={classes.greatVibes}>Great Vibes Custom Font</div>

            {!confirmCodeFlag &&
              !changePasswordFlag &&
              !theOneTimePasswordFlag &&
              !userNotConfirmedFlag &&
              !forgotPasswordFlag &&
              !redirectSignUpFlag && (
                <Container
                  component="div"
                  className={classes.mainForm}
                  maxWidth="md"
                >
                  <Grid container justify="center">
                    <Grid item xs={12} sm={9}>
                      <Paper elevation={3} className={classes.paper}>
                        <Grid container justify="center">
                          <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                          </Avatar>
                        </Grid>

                        <Typography
                          variant="h5"
                          component="h2"
                          style={{ color: "black", textAlign: "center" }}
                        >
                          {signInLabel}
                        </Typography>

                        <Typography
                          variant="caption"
                          style={{ color: "green", textAlign: "center" }}
                        >
                          {signInToAccount}
                        </Typography>

                        {globalErrorFlag && (
                          <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                        )}

                        <LineSpacing value={24} />

                        <FormControl
                          className={classes.textField}
                          variant="outlined"
                          error={usernameErrFlag}
                        >
                          <label
                            htmlFor="usernameId"
                            style={directionAlignment}
                          >
                            <Typography
                              variant="subtitle1"
                              color={usernameErrFlag ? "error" : "inherit"}
                            >
                              {usernameLabel}
                            </Typography>
                          </label>
                          <OutlinedInput
                            id="usernameId"
                            // name="username"
                            value={username}
                            onChange={handleChange}
                            placeholder={enterCorrectUsername}
                            // variant="outlined"
                            // margin="normal"
                            required
                            fullWidth
                            // label={usernameLabel}
                            autoComplete="username"
                            autoFocus
                            type={"email"}
                            error={usernameErrFlag}
                          />
                          {usernameErrFlag && (
                            <FormHelperText id="username-helper-text">
                              {emailConstraintsNotMet}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <LineSpacing value={24} />

                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                          error={passwordErrFlag}
                        >
                          <label
                            htmlFor="passwordId"
                            style={directionAlignment}
                          >
                            <Typography
                              variant="subtitle1"
                              color={passwordErrFlag ? "error" : "inherit"}
                            >
                              {passwordLabel}
                            </Typography>
                          </label>
                          <OutlinedInput
                            id="passwordId"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            placeholder={enterCorrectPassword}
                            onChange={handleChange}
                            error={passwordErrFlag}
                            required
                            fullWidth
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {passwordErrFlag && (
                            <FormHelperText id="password-helper-text">
                              {passwordConstraintsNotMet}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <LineSpacing value={12} />

                        <Typography
                          component="p"
                          style={{ textAlign: "center", marginTop: "12px" }}
                        >
                          {!signInButtonFlag && (
                            <Button
                              variant="contained"
                              size="large"
                              color="primary"
                              disabled
                              className={classes.button}
                            >
                              <LockOutlinedIcon
                                className={clsx(
                                  classes.leftIcon,
                                  classes.iconSmall
                                )}
                              />
                              {signInLabel}
                            </Button>
                          )}

                          {signInButtonFlag && (
                            <Button
                              variant="contained"
                              size="large"
                              color="primary"
                              className={classes.button}
                              onClick={signInService}
                            >
                              <LockOutlinedIcon
                                className={clsx(
                                  classes.leftIcon,
                                  classes.iconSmall
                                )}
                              />
                              {signInLabel}
                            </Button>
                          )}
                        </Typography>

                        <LineSpacing value={18} />

                        <Grid container>
                          <Grid item xs>
                            {direction === "ltr" && (
                              <StyledTypography
                                variant="caption"
                                color="primary"
                                onClick={showAlertDialog}
                                style={{ float: "left" }}
                              >
                                {forgotPassword}
                              </StyledTypography>
                            )}
                            {direction === "rtl" && (
                              <StyledTypography
                                variant="caption"
                                color="primary"
                                onClick={showAlertDialog}
                                style={{ float: "right" }}
                              >
                                {forgotPassword}
                              </StyledTypography>
                            )}
                          </Grid>
                          <Grid item>
                            <Link component={RouterLink} to="/signUp">
                              {direction === "ltr" && (
                                <StyledTypography
                                  variant="caption"
                                  color="primary"
                                  style={{ float: "right" }}
                                >
                                  {noAcctSignUp}
                                </StyledTypography>
                              )}
                              {direction === "rtl" && (
                                <StyledTypography
                                  variant="caption"
                                  color="primary"
                                  style={{ float: "left" }}
                                >
                                  {noAcctSignUp}
                                </StyledTypography>
                              )}
                            </Link>
                          </Grid>
                        </Grid>

                        <LineSpacing value={12} />
                      </Paper>
                    </Grid>
                  </Grid>
                </Container>
              )}

            {alertOpen && (
              <AlertDialog
                alertOpen={alertOpen}
                alertClose={alertClose}
                alertClickAgree={alertClickAgree}
                alertClickDisagree={alertClickDisagree}
                dialogTitle={passwordRecoveryTitle}
                dialogMainContent={passwordRecoveryEmail}
                dialogSubContent={recoveryPasswordMsg}
                dialogQuestion={likeToContinue}
                yesLabel={yesLabel}
                noLabel={noLabel}
              />
            )}
          </div>
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter currLanguage={currLanguage} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default SignIn;
