import React from "react";
import styled, { css } from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import WarningIcon from "@material-ui/icons/Warning";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// import Avatar from '@material-ui/core/Avatar';
import { blue } from "@material-ui/core/colors";
import Draggable from "react-draggable";

const PageContainer = styled.div`
  width: 100%;
  display: 'flex',
  flexWrap: 'wrap',
  background: #fff;
`;

const StyledTypography = styled(Typography)`
  text-align: center;
`;

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogClass: {
    textAlign: "center",
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function AlertDialog(props) {
  const {
    alertOpen,
    alertClose,
    alertClickAgree,
    alertClickDisagree,

    dialogTitle,
    dialogQuestion,
    dialogMainContent,
    dialogSubContent,
    yesLabel,
    noLabel,
  } = props;

  const classes = useStyles();

  return (
    <PageContainer>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        TransitionComponent={Draggable}
        open={alertOpen}
        onClose={alertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ cursor: "all-scroll", padding: "0" }}
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container direction="row" alignItems="flex-start">
            {" "}
            {/* "flex-start","center","flex-end","stretch","baseline" */}
            <Grid item>
              <WarningIcon
                color="secondary"
                style={{ verticalAlign: "middle" }}
              />
              {/* <Avatar className={classes.avatar}>
                <WarningIcon style={{verticalAlign: 'middle'}}/>
              </Avatar> */}
            </Grid>
            <Grid item style={{ marginLeft: "10px", verticalAlign: "middle" }}>
              <Typography variant="h6" color="secondary">
                {dialogTitle}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="Close"
                className={classes.closeButton}
                onClick={alertClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent
          dividers
          style={{
            marginLeft: "20px",
            marginRight: "20px",
            textAlign: "center",
          }}
        >
          <Typography
            variant="caption"
            gutterBottom
            style={{ textAlign: "center" }}
          >
            {dialogMainContent}
          </Typography>
          <br />

          {dialogSubContent && dialogSubContent !== "" && (
            <Typography
              variant="caption"
              gutterBottom
              style={{ textAlign: "center" }}
            >
              {dialogSubContent}
            </Typography>
          )}

          {dialogQuestion && dialogQuestion !== "" && (
            <StyledTypography component="div" variant="body1">
              <Box fontSize="fontSize" m={2} color="primary.main">
                {dialogQuestion}
              </Box>
            </StyledTypography>
          )}
        </DialogContent>

        <DialogActions style={{ marginTop: "12px", marginBottom: "12px" }}>
          <Button
            color="primary"
            size="medium"
            onClick={alertClickDisagree}
            autoFocus
          >
            {noLabel}
          </Button>
          <Button color="primary" size="medium" onClick={alertClickAgree}>
            {yesLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
}

export default AlertDialog;
