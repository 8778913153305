// Lodash
import lodash from "lodash";

// Amplify
import { Storage } from "aws-amplify";

async function FetchS3Object(keyPrefix, callback) {
    let retValue = null;

    if (!lodash.isEmpty(keyPrefix)) {
        const files = await Storage.list(keyPrefix);
        // console.log("In FetchS3Object(): files is: ", files);

        if (files && files.length > 0) {
            retValue = files[0].key;
            // await Storage.get(files[0].key).then(result => {
            //     // console.log("In FetchS3Object(): result is: ", result);
            //     retValue = result;
            // }).catch(err => {
            //     console.error("In FetchS3Object(): err is: ", err);
            // });
        }
    }
    callback(retValue);
  };
  
  export default FetchS3Object;
