import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// import Popover from '@material-ui/core/Popover';
import Link from "@material-ui/core/Link";
// import CircularProgress from '@material-ui/core/CircularProgress';
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import WidgetsIcon from '@material-ui/icons/Widgets';
import DetailsIcon from '@material-ui/icons/Details';
import HomeIcon from "@material-ui/icons/Home";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import PeopleIcon from '@material-ui/icons/People';
// import NewspaperIcon from '@material-ui/icons/Newspaper';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import WorkIcon from '@material-ui/icons/Work';
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import TranslateIcon from "@material-ui/icons/Translate";
import DeleteIcon from "@material-ui/icons/Delete";
import ScheduleIcon from "@material-ui/icons/Schedule";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import ListAltIcon from '@material-ui/icons/ListAlt';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import BookIcon from '@material-ui/icons/Book';
// import ResponsiveMenu from 'react-responsive-navbar';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import WebIcon from '@material-ui/icons/Web';
import WebAssetIcon from '@material-ui/icons/WebAsset';

// react-responsive
import { useMediaQuery } from 'react-responsive';

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import Logo from "../../components/misc/Logo";
import HorizontalRule from "../../components/misc/HorizontalRule";

import GetCacheItem from "../../utils/cache/GetCacheItem";
import SetCacheItem from "../../utils/cache/SetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

Amplify.configure(AmplifyConfig);

const StyledTipButton = styled(Button)`
  vertical-align: middle !important;
`;

const StyledDiv = styled.div`
  min-width: 100px !important;
  margin: 0px 0px 0px 0px;
  cursor: pointer;
  text-align: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundImage: `url(${'assets/logos/background.png'})`,
  },
  menuIcon: {
    cursor: "pointer",
    color: "#fff",
  },
  mainMenu: {
    color: "#fff",
    "&:hover": {
      color: "#fff",
    },
  },
  menuButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      // '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: theme.palette.common.white,
      // },
    },
  },
  menuButton2: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      // '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
      color: theme.palette.common.white,
      // },
    },
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    textAlign: "center",
    verticalAlign: "middle",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  hr: {
    fontWeight: "100",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  popover: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

let mainmenu = {
  pages: "Pages",
  blogs: "Blogs",
  setup: "Setup",

  homeLabel: "Home",
  languagesLabel: "Languages",
  dataLanguagesLabel: "Data Languages",
  resources: "Resources",
  types: "Types",
  references: "References",
  socials: "Socials",
  contactInfo: "Contact",
  // support: "Support",
  webPagesLabel: "Web pages",

  // languagesLabel
  createLanguages: "Create languages",
  manageLanguages: "Manage languages",
  viewLanguages: "View languages",

  // data languagesLabel
  createDataLanguages: "Create data languages",
  manageDataLanguages: "Manage data languages",
  convertDataLanguages: "Convert data languages",
  viewDataLanguages: "View data languages",

  // resources
  createResources: "Create resources",
  manageResources: "Manage resources",
  convertResources: "Convert resources",
  viewResources: "View resources",

  // types
  createReferenceTypes: "Create reference types",
  manageReferenceTypes: "Manage reference types",
  viewReferenceTypes: "View reference types",
  convertReferenceTypes: "Convert reference types",

  // references
  createReferenceData: "Create reference data",
  manageReferenceData: "Manage reference data",
  viewReferenceData: "View reference data",
  convertReferenceData: "Convert reference data",

  // socials
  createSocialNetworks: "Create social networks",
  manageSocialNetworks: "Manage social networks",
  viewSocialNetworks: "View social networks",
  convertSocialNetworks: "Convert social networks",
  appsInfo: "Add Apps information",

  // contactInfo
  createContactInfo: "Create contact info",
  manageContactInfo: "Manage contact info",
  viewContactInfo: "View contact info",
  convertContactInfo: "Convert contact info",
  geocodeContactAddr: "Geocode contact addresses",
  businessHrsLabel: "Business hours",

  // support
  customerService: "Customer service",

  manageDesignBlocksLabel: "Manage design blocks",
  manageDesignDetailsLabel: "Manage design details",

  // convertDesignBlockDetails: "Convert design block details",
  // deleteDesignBlocks: "Delete design blocks",
  
  miscellaneousLabel: "Miscellaneous",
  postBlogsLabel: "Post blogs",
  postJobsLabel: "Post jobs",
  postNewsLabel: "Post news",
  managePriceHeadersLabel: "Manage price headers",
  managePriceListLabel: "Manage price list",
  manageFaqsLabel: "Manage FAQs",

  // Web Pages
  manageWebPagesLabel: "Manage web pages",
  managePageSectionsLabel: "Manage page sections",
};

function AppMainMenu(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  //------------------------
  // For react-responsive
  //------------------------
  const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
  const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
  const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  const [progressFlag, setProgressFlag] = useState(false);

  const [mainmenuRes, setMainmenuRes] = useState(mainmenu);

  const [languagesPopoverOpen, setLanguagesPopoverOpen] = useState(false);
  const [resourcesPopoverOpen, setResourcesPopoverOpen] = useState(false);
  const [referencesPopoverOpen, setReferencesPopoverOpen] = useState(false);
  const [socialsPopoverOpen, setSocialsPopoverOpen] = useState(false);
  const [typesPopoverOpen, setTypesPopoverOpen] = useState(false);
  const [contactInfoPopoverOpen, setContactInfoPopoverOpen] = useState(false);
  const [supportPopoverOpen, setSupportPopoverOpen] = useState(false);
  const [contentPopoverOpen, setContentPopoverOpen] = useState(false);

  const { currLanguage, homePageFlag, screenDirection } = props;

  const classes = useStyles();

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const {
    pages,
    blogs,
    setup,

    homeLabel,
    languagesLabel,
    dataLanguagesLabel,
    resources,
    types,
    references,
    socials,
    contactInfo,
    // support,
    webPagesLabel,

    createLanguages,
    manageLanguages,
    viewLanguages,

    createDataLanguages,
    manageDataLanguages,
    convertDataLanguages,
    viewDataLanguages,

    createResources,
    manageResources,
    convertResources,
    viewResources,

    createReferenceTypes,
    manageReferenceTypes,
    viewReferenceTypes,
    convertReferenceTypes,

    createReferenceData,
    manageReferenceData,
    viewReferenceData,
    convertReferenceData,

    createSocialNetworks,
    manageSocialNetworks,
    viewSocialNetworks,
    convertSocialNetworks,
    appsInfo,

    createContactInfo,
    manageContactInfo,
    viewContactInfo,
    convertContactInfo,
    geocodeContactAddr,
    businessHrsLabel,

    customerService,

    // managePageContent,
    // convertPageContent,
    // deletePageContent,

    manageDesignBlocksLabel,
    manageDesignDetailsLabel,
    // convertDesignBlockDetails,
    // deleteDesignBlocks,
    
    miscellaneousLabel,
    postBlogsLabel,
    postJobsLabel,
    postNewsLabel,
    managePriceHeadersLabel,
    managePriceListLabel,
    manageFaqsLabel,

    // Web Pages
    manageWebPagesLabel,
    managePageSectionsLabel,
  } = mainmenuRes;

  function handleClickOutside(event) {
    // console.log('In handleClickOutside(): event.target.tagName is: ', event.target.tagName);
    if (event.target && event.target.tagName === "DIV") {
      setLanguagesPopoverOpen(false);
      setResourcesPopoverOpen(false);
      setReferencesPopoverOpen(false);
      setSocialsPopoverOpen(false);
      setTypesPopoverOpen(false);
      setContactInfoPopoverOpen(false);
      setSupportPopoverOpen(false);
      setContentPopoverOpen(false);
    }
  }

  async function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(mainmenu);
    // console.log("In AppMainMenu(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In AppMainMenu(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "pages")
              mainmenu.pages = item.ResourceDesc;
            if (item.ResourceCode === "blogs")
              mainmenu.blogs = item.ResourceDesc;
            if (item.ResourceCode === "setup")
              mainmenu.setup = item.ResourceDesc;

            if (item.ResourceCode === "homeLabel")
              mainmenu.homeLabel = item.ResourceDesc;
            if (item.ResourceCode === "languagesLabel")
              mainmenu.languagesLabel = item.ResourceDesc;
            if (item.ResourceCode === "dataLanguagesLabel")
              mainmenu.dataLanguagesLabel = item.ResourceDesc;
            if (item.ResourceCode === "resources")
              mainmenu.resources = item.ResourceDesc;
            if (item.ResourceCode === "references")
              mainmenu.references = item.ResourceDesc;
            if (item.ResourceCode === "socials")
              mainmenu.socials = item.ResourceDesc;
            if (item.ResourceCode === "types")
              mainmenu.types = item.ResourceDesc;
            if (item.ResourceCode === "contactInfo")
              mainmenu.contactInfo = item.ResourceDesc;
            // if (item.ResourceCode === "support") mainmenu.support = item.ResourceDesc;
            if (item.ResourceCode === "webPagesLabel") mainmenu.webPagesLabel = item.ResourceDesc;

            if (item.ResourceCode === "createLanguages")
              mainmenu.createLanguages = item.ResourceDesc;
            if (item.ResourceCode === "manageLanguages")
              mainmenu.manageLanguages = item.ResourceDesc;
            if (item.ResourceCode === "viewLanguages")
              mainmenu.viewLanguages = item.ResourceDesc;

            if (item.ResourceCode === "createDataLanguages")
              mainmenu.createDataLanguages = item.ResourceDesc;
            if (item.ResourceCode === "manageDataLanguages")
              mainmenu.manageDataLanguages = item.ResourceDesc;
            if (item.ResourceCode === "convertDataLanguages")
              mainmenu.convertDataLanguages = item.ResourceDesc;
            if (item.ResourceCode === "viewDataLanguages")
              mainmenu.viewDataLanguages = item.ResourceDesc;

            if (item.ResourceCode === "createResources")
              mainmenu.createResources = item.ResourceDesc;
            if (item.ResourceCode === "manageResources")
              mainmenu.manageResources = item.ResourceDesc;
            if (item.ResourceCode === "convertResources")
              mainmenu.convertResources = item.ResourceDesc;
            if (item.ResourceCode === "viewResources")
              mainmenu.viewResources = item.ResourceDesc;

            if (item.ResourceCode === "createReferenceTypes")
              mainmenu.createReferenceTypes = item.ResourceDesc;
            if (item.ResourceCode === "manageReferenceTypes")
              mainmenu.manageReferenceTypes = item.ResourceDesc;
            if (item.ResourceCode === "viewReferenceTypes")
              mainmenu.viewReferenceTypes = item.ResourceDesc;
            if (item.ResourceCode === "convertReferenceTypes")
              mainmenu.convertReferenceTypes = item.ResourceDesc;

            if (item.ResourceCode === "createReferenceData")
              mainmenu.createReferenceData = item.ResourceDesc;
            if (item.ResourceCode === "manageReferenceData")
              mainmenu.manageReferenceData = item.ResourceDesc;
            if (item.ResourceCode === "viewReferenceData")
              mainmenu.viewReferenceData = item.ResourceDesc;
            if (item.ResourceCode === "convertReferenceData")
              mainmenu.convertReferenceData = item.ResourceDesc;

            if (item.ResourceCode === "createSocialNetworks")
              mainmenu.createSocialNetworks = item.ResourceDesc;
            if (item.ResourceCode === "manageSocialNetworks")
              mainmenu.manageSocialNetworks = item.ResourceDesc;
            if (item.ResourceCode === "viewSocialNetworks")
              mainmenu.viewSocialNetworks = item.ResourceDesc;
            if (item.ResourceCode === "convertSocialNetworks")
              mainmenu.convertSocialNetworks = item.ResourceDesc;
            if (item.ResourceCode === "appsInfo")
              mainmenu.appsInfo = item.ResourceDesc;

            if (item.ResourceCode === "createContactInfo")
              mainmenu.createContactInfo = item.ResourceDesc;
            if (item.ResourceCode === "manageContactInfo")
              mainmenu.manageContactInfo = item.ResourceDesc;
            if (item.ResourceCode === "viewContactInfo")
              mainmenu.viewContactInfo = item.ResourceDesc;
            if (item.ResourceCode === "convertContactInfo")
              mainmenu.convertContactInfo = item.ResourceDesc;
            if (item.ResourceCode === "geocodeContactAddr")
              mainmenu.geocodeContactAddr = item.ResourceDesc;
            if (item.ResourceCode === "businessHrsLabel")
              mainmenu.businessHrsLabel = item.ResourceDesc;

            if (item.ResourceCode === "customerService")
              mainmenu.customerService = item.ResourceDesc;

            // if (item.ResourceCode === "managePageContent")
            //   mainmenu.managePageContent = item.ResourceDesc;
            // if (item.ResourceCode === "convertPageContent")
            //   mainmenu.convertPageContent = item.ResourceDesc;
            // if (item.ResourceCode === "deletePageContent")
            //   mainmenu.deletePageContent = item.ResourceDesc;

            if (item.ResourceCode === "manageWebPagesLabel") mainmenu.manageWebPagesLabel = item.ResourceDesc;
            if (item.ResourceCode === "managePageSectionsLabel") mainmenu.managePageSectionsLabel = item.ResourceDesc;

            if (item.ResourceCode === "manageDesignBlocksLabel") mainmenu.manageDesignBlocksLabel = item.ResourceDesc;
            if (item.ResourceCode === "manageDesignDetailsLabel") mainmenu.manageDesignDetailsLabel = item.ResourceDesc;

            // if (item.ResourceCode === "convertDesignBlockDetails") mainmenu.convertDesignBlockDetails = item.ResourceDesc;
            // if (item.ResourceCode === "deleteDesignBlocks") mainmenu.deleteDesignBlocks = item.ResourceDesc;
                                       
            if (item.ResourceCode === "miscellaneousLabel") mainmenu.miscellaneousLabel = item.ResourceDesc;
            if (item.ResourceCode === "postBlogsLabel") mainmenu.postBlogsLabel = item.ResourceDesc;
            if (item.ResourceCode === "postJobsLabel") mainmenu.postJobsLabel = item.ResourceDesc;
            if (item.ResourceCode === "postNewsLabel") mainmenu.postNewsLabel = item.ResourceDesc;
            if (item.ResourceCode === "managePriceHeadersLabel") mainmenu.managePriceHeadersLabel = item.ResourceDesc;
            if (item.ResourceCode === "managePriceListLabel") mainmenu.managePriceListLabel = item.ResourceDesc;
            if (item.ResourceCode === "manageFaqsLabel") mainmenu.manageFaqsLabel = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, mainmenu);
          setMainmenuRes(mainmenu);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setMainmenuRes(mainmenu);
        setProgressFlag(false);
      });
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // console.log("In AppMainMenu(): useEffect(): isSignedIn is: ", isSignedIn);
    // console.log("In AppMainMenu(): useEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));
    document.addEventListener("mousedown", handleClickOutside);

    // currLanguage is the most current language passed to this component through props
    // We are using useEffect to get the functionality of componentDidUpdate()
    if (
      currLanguage !== undefined &&
      currLanguage !== null &&
      currLanguage !== currentLanguage
    ) {
      setCurrentLanguage(currLanguage);
      appContext.currentLanguage = currLanguage;

      // console.log('In AppMainMenu(): useEffect(): about to call fetchDbResources()');

      // Fetch resource from catche
      let cacheItemKey = "mainmenu";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      // cacheItemKey += currLanguage.languageCode;
      // console.log('In AppMainMenu(): useEffect(): cacheItemKey is: ', cacheItemKey);

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        // console.log("In AppMainMenu(): useEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
        RemoveCacheItem(cacheItemKey);
      }

      let cacheItem = GetCacheItem(cacheItemKey);
      // console.log('In AppMainMenu(): useEffect(): cacheItem is: ', cacheItem);

      if (cacheItem === null) {
        // resource is not in cache, fetch from DB
        // console.log('In AppMainMenu(): useEffect(): resource is not in cache, fetch from DB');
        fetchDbResources(cacheItemKey);
      } else {
        // console.log('In AppMainMenu(): useEffect(): got resource from cache');
        setMainmenuRes(cacheItem);
      }
    }
  });

  useEffect(() => {
    // console.log("In AppMainMenu(): useEffect(): [screenDirection] screenDirection is: ", screenDirection);
    if (screenDirection) {
      setDirection(screenDirection);
    }
  }, [screenDirection]);

  useLayoutEffect(() => {
    // console.log("In AppMainMenu(): useLayoutEffect(): isSignedIn is: ", isSignedIn);
    // console.log("In AppMainMenu(): useLayoutEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));
    // console.log("In AppMainMenu(): useLayoutEffect(): languagesLabel is: ", JSON.stringify(languagesLabel, null, 2));

    // Fetch resource from catche
    let cacheItemKey = "mainmenu";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In AppMainMenu(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In AppMainMenu(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In AppMainMenu(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In AppMainMenu(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In AppMainMenu(): useLayoutEffect(): got resource from cache');
      setMainmenuRes(cacheItem);
    }
  }, []);

  //************************** */
  // Languages Popover Contents
  //************************** */

  function openLanguagesPopover() {
    setLanguagesPopoverOpen(true);

    setResourcesPopoverOpen(false);
    setReferencesPopoverOpen(false);
    setSocialsPopoverOpen(false);
    setTypesPopoverOpen(false);
    setContactInfoPopoverOpen(false);
    setSupportPopoverOpen(false);
    setContentPopoverOpen(false);
  }

  function closeLanguagesPopover() {
    setLanguagesPopoverOpen(false);
  }

  function LanguagesPopoverContent() {
    return (
      <Container component="div" className={classes.main} maxWidth="lg">
        <Grid container justify="center" style={{ width: "100%" }}>
          <Link
            component={RouterLink}
            to="/createLanguages"
            onClick={closeLanguagesPopover}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {createLanguages}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/manageLanguages"
            onClick={closeLanguagesPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {manageLanguages}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/viewLanguages"
            onClick={closeLanguagesPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {viewLanguages}
              </Typography>
            </StyledDiv>
          </Link>

          <HorizontalRule />

          <Link
            component={RouterLink}
            to="/createDataLanguages"
            onClick={closeLanguagesPopover}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {createDataLanguages}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/manageDataLanguages"
            onClick={closeLanguagesPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {manageDataLanguages}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/viewDataLanguages"
            onClick={closeLanguagesPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {viewDataLanguages}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/convertDataLanguages"
            onClick={closeLanguagesPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {convertDataLanguages}
              </Typography>
            </StyledDiv>
          </Link>
        </Grid>
      </Container>
    );
  }

  //********************** */
  // Resources Popover Contents
  //********************** */

  function openResourcesPopover() {
    setResourcesPopoverOpen(true);

    setLanguagesPopoverOpen(false);
    setReferencesPopoverOpen(false);
    setSocialsPopoverOpen(false);
    setTypesPopoverOpen(false);
    setContactInfoPopoverOpen(false);
    setSupportPopoverOpen(false);
    setContentPopoverOpen(false);
  }

  function closeResourcesPopover() {
    setResourcesPopoverOpen(false);
  }

  function ResourcesPopoverContent() {
    return (
      <Container component="div" className={classes.main} maxWidth="lg">
        <Grid container justify="center" style={{ width: "100%" }}>
          <Link
            component={RouterLink}
            to="/createResources"
            onClick={closeResourcesPopover}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {createResources}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/manageResources"
            onClick={closeResourcesPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {manageResources}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/viewResources"
            onClick={closeResourcesPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {viewResources}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/convertResources"
            onClick={closeResourcesPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {convertResources}
              </Typography>
            </StyledDiv>
          </Link>
        </Grid>
      </Container>
    );
  }

  //********************** */
  // Types Popover Contents
  //********************** */

  function openTypesPopover() {
    setTypesPopoverOpen(true);

    setLanguagesPopoverOpen(false);
    setResourcesPopoverOpen(false);
    setReferencesPopoverOpen(false);
    setSocialsPopoverOpen(false);
    setContactInfoPopoverOpen(false);
    setSupportPopoverOpen(false);
    setContentPopoverOpen(false);
  }

  function closeTypesPopover() {
    setTypesPopoverOpen(false);
  }

  function TypesPopoverContent() {
    return (
      <Container component="div" className={classes.main} maxWidth="lg">
        <Grid container justify="center" style={{ width: "100%" }}>
          <Link
            component={RouterLink}
            to="/createReferenceTypes"
            onClick={closeTypesPopover}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {createReferenceTypes}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/manageReferenceTypes"
            onClick={closeTypesPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {manageReferenceTypes}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/viewReferenceTypes"
            onClick={closeTypesPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {viewReferenceTypes}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/convertReferenceTypes"
            onClick={closeTypesPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {convertReferenceTypes}
              </Typography>
            </StyledDiv>
          </Link>
        </Grid>
      </Container>
    );
  }

  //**************************** */
  // References Popover Contents
  //**************************** */

  function openReferencesPopover() {
    setReferencesPopoverOpen(true);

    setLanguagesPopoverOpen(false);
    setResourcesPopoverOpen(false);
    setSocialsPopoverOpen(false);
    setTypesPopoverOpen(false);
    setContactInfoPopoverOpen(false);
    setSupportPopoverOpen(false);
    setContentPopoverOpen(false);
  }

  function closeReferencesPopover() {
    setReferencesPopoverOpen(false);
  }

  function ReferencesPopoverContent() {
    return (
      <Container component="div" className={classes.main} maxWidth="lg">
        <Grid container justify="center" style={{ width: "100%" }}>
          <Link
            component={RouterLink}
            to="/createReferenceData"
            onClick={closeReferencesPopover}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {createReferenceData}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/manageReferenceData"
            onClick={closeReferencesPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {manageReferenceData}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/viewReferenceData"
            onClick={closeReferencesPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {viewReferenceData}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/convertReferenceData"
            onClick={closeReferencesPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {convertReferenceData}
              </Typography>
            </StyledDiv>
          </Link>
        </Grid>
      </Container>
    );
  }

  //********************** */
  // Socials Popover Contents
  //********************** */

  function openSocialsPopover() {
    setSocialsPopoverOpen(true);

    setLanguagesPopoverOpen(false);
    setResourcesPopoverOpen(false);
    setReferencesPopoverOpen(false);
    setTypesPopoverOpen(false);
    setContactInfoPopoverOpen(false);
    setSupportPopoverOpen(false);
    setContentPopoverOpen(false);
  }

  function closeSocialsPopover() {
    setSocialsPopoverOpen(false);
  }

  function SocialsPopoverContent() {
    return (
      <Container component="div" className={classes.main} maxWidth="lg">
        <Grid container justify="center" style={{ width: "100%" }}>
          <Link
            component={RouterLink}
            to="/createSocialNetworks"
            onClick={closeSocialsPopover}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {createSocialNetworks}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/manageSocialNetworks"
            onClick={closeSocialsPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {manageSocialNetworks}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/viewSocialNetworks"
            onClick={closeSocialsPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {viewSocialNetworks}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/convertSocialNetworks"
            onClick={closeSocialsPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {convertSocialNetworks}
              </Typography>
            </StyledDiv>
          </Link>
        </Grid>
      </Container>
    );
  }

  //**************************** */
  // ContactInfo Popover Contents
  //**************************** */

  function openContactInfoPopover() {
    setContactInfoPopoverOpen(true);

    setLanguagesPopoverOpen(false);
    setResourcesPopoverOpen(false);
    setReferencesPopoverOpen(false);
    setSocialsPopoverOpen(false);
    setTypesPopoverOpen(false);
    setSupportPopoverOpen(false);
    setContentPopoverOpen(false);
  }

  function closeContactInfoPopover() {
    setContactInfoPopoverOpen(false);
  }

  function ContactInfoPopoverContent() {
    return (
      <Container component="div" className={classes.main} maxWidth="lg">
        <Grid container justify="center" style={{ width: "100%" }}>
          <Link
            component={RouterLink}
            to="/createContactInfo"
            onClick={closeContactInfoPopover}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {createContactInfo}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/manageContactInfo"
            onClick={closeContactInfoPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {manageContactInfo}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/viewContactInfo"
            onClick={closeContactInfoPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {viewContactInfo}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/convertContactInfo"
            onClick={closeContactInfoPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {convertContactInfo}
              </Typography>
            </StyledDiv>
          </Link>

          <HorizontalRule />

          <Link
            component={RouterLink}
            to="/geocodeContactAddr"
            onClick={closeContactInfoPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {geocodeContactAddr}
              </Typography>
            </StyledDiv>
          </Link>

          <Link
            component={RouterLink}
            to="/businessHours"
            onClick={closeContactInfoPopover}
            style={{ marginTop: "10px" }}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {businessHrsLabel}
              </Typography>
            </StyledDiv>
          </Link>
        </Grid>
      </Container>
    );
  }

  //**************************** */
  // Support Popover Contents
  //**************************** */

  function openSupportPopover() {
    setSupportPopoverOpen(true);

    setLanguagesPopoverOpen(false);
    setResourcesPopoverOpen(false);
    setReferencesPopoverOpen(false);
    setSocialsPopoverOpen(false);
    setTypesPopoverOpen(false);
    setContactInfoPopoverOpen(false);
    setContentPopoverOpen(false);
  }

  function closeSupportPopover() {
    setSupportPopoverOpen(false);
  }

  function SupportPopoverContent() {
    return (
      <Container component="div" className={classes.main} maxWidth="lg">
        <Grid container justify="center" style={{ width: "100%" }}>
          <Link
            component={RouterLink}
            to="/customerService"
            onClick={closeSupportPopover}
          >
            <StyledDiv>
              <Typography variant="subtitle2" noWrap>
                {customerService}
              </Typography>
            </StyledDiv>
          </Link>
        </Grid>
      </Container>
    );
  }

  //**************************** */
  // Content Popover Contents
  //**************************** */

  function openContentPopover() {
    setContentPopoverOpen(true);

    setLanguagesPopoverOpen(false);
    setResourcesPopoverOpen(false);
    setReferencesPopoverOpen(false);
    setSocialsPopoverOpen(false);
    setTypesPopoverOpen(false);
    setContactInfoPopoverOpen(false);
    setSupportPopoverOpen(false);
  }

  function closeContentPopover() {
    setContentPopoverOpen(false);
  }

  //-------------------------
  // For New Menu
  //-------------------------

  const StyledMenu = withStyles({
    paper: {
      border: "1px solid #d3d4d5",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      "&:focus": {
        // backgroundColor: theme.palette.primary.main,
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          color: theme.palette.primary.main,
          // color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  //---------------------
  // For Languages
  //---------------------
  const [languageAnchor, setLanguageAnchor] = React.useState(null);

  const handleLanguageClick = (event) => {
    setLanguageAnchor(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setLanguageAnchor(null);
  };

  //---------------------
  // For Data-Languages
  //---------------------
  const [dataLanguageAnchor, setDataLanguageAnchor] = React.useState(null);

  const handleDataLanguageClick = (event) => {
    setDataLanguageAnchor(event.currentTarget);
  };

  const handleDataLanguageClose = () => {
    setDataLanguageAnchor(null);
  };

  //---------------------
  // For Resources
  //---------------------
  const [resourcesAnchor, setResourcesAnchor] = React.useState(null);

  const handleResourcesClick = (event) => {
    setResourcesAnchor(event.currentTarget);
  };

  const handleResourcesClose = () => {
    setResourcesAnchor(null);
  };

  //---------------------
  // For ReferenceTypes
  //---------------------
  const [referenceTypesAnchor, setReferenceTypesAnchor] = React.useState(null);

  const handleReferenceTypesClick = (event) => {
    setReferenceTypesAnchor(event.currentTarget);
  };

  const handleReferenceTypesClose = () => {
    setReferenceTypesAnchor(null);
  };

  //---------------------
  // For ReferenceData
  //---------------------
  const [referenceDataAnchor, setReferenceDataAnchor] = React.useState(null);

  const handleReferenceDataClick = (event) => {
    setReferenceDataAnchor(event.currentTarget);
  };

  const handleReferenceDataClose = () => {
    setReferenceDataAnchor(null);
  };

  //---------------------
  // For SocialNetworks
  //---------------------
  const [socialNetworksAnchor, setSocialNetworksAnchor] = React.useState(null);

  const handleSocialNetworksClick = (event) => {
    setSocialNetworksAnchor(event.currentTarget);
  };

  const handleSocialNetworksClose = () => {
    setSocialNetworksAnchor(null);
  };

  //---------------------
  // For ContactInfo
  //---------------------
  const [contactInfoAnchor, setContactInfoAnchor] = React.useState(null);

  const handleContactInfoClick = (event) => {
    setContactInfoAnchor(event.currentTarget);
  };

  const handleContactInfoClose = () => {
    setContactInfoAnchor(null);
  };

  //---------------------
  // For Support
  //---------------------
  // const [supportAnchor, setSupportAnchor] = React.useState(null);

  // const handleSupportClick = (event) => {
  //   setSupportAnchor(event.currentTarget);
  // };

  // const handleSupportClose = () => {
  //   setSupportAnchor(null);
  // };

  //---------------------
  // For Miscellaneous
  //---------------------
  const [miscellaneousAnchor, setMiscellaneousAnchor] = React.useState(null);

  const handleMiscellaneousClick = (event) => {
    setMiscellaneousAnchor(event.currentTarget);
  };

  const handleMiscellaneousClose = () => {
    setMiscellaneousAnchor(null);
  };

  //---------------------
  // For Content
  //---------------------
  const [contentAnchor, setContentAnchor] = React.useState(null);

  const handleContentClick = (event) => {
    setContentAnchor(event.currentTarget);
  };

  const handleContentClose = () => {
    setContentAnchor(null);
  };

  return (
    <div dir={direction}>
      <div className={classes.root} style={{ verticalAlign: "middle" }}>
        {/* progressFlag &&
            <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
            </div>
        */}

        {(isDeskTopView || isTabletView || isMobileView) &&
          <Toolbar>
            <div className={classes.title}>
              {/* For Logo */}
              {homePageFlag && (
                <span style={directionFloat}>
                  <Logo />
                </span>
              )}
              {/* { homePageFlag &&
                <span style={{float: 'left'}}><Logo /></span>
              }
              { homePageFlag && direction === 'rtl' &&
                <span style={{float: 'right'}}><Logo /></span>
              } */}

              {/* For Home */}
              {appContext.signedIn && homePageFlag && (
                <Link component={RouterLink} to="/" className={classes.mainMenu}>
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    // variant="contained"
                    color="primary"
                    // onClick={handleLanguageClick}
                    className={
                      homePageFlag ? classes.menuButton : classes.menuButton2
                    }
                  >
                    {homeLabel}
                  </Button>
                </Link>
              )}

              {/* For Languages */}
              {appContext.signedIn && (
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  // variant="contained"
                  color="primary"
                  onClick={handleLanguageClick}
                  className={
                    homePageFlag ? classes.menuButton : classes.menuButton2
                  }
                >
                  {languagesLabel}
                </Button>
              )}

              <StyledMenu
                id="customized-menu"
                anchorEl={languageAnchor}
                keepMounted
                open={Boolean(languageAnchor)}
                onClose={handleLanguageClose}
              >
                <StyledMenuItem>
                  <ListItemIcon>
                    <AddIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <Link
                    component={RouterLink}
                    to="/createLanguages"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={createLanguages} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <EditIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/manageLanguages"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={manageLanguages} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <ViewComfyIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/viewLanguages"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={viewLanguages} />
                  </Link>
                </StyledMenuItem>
              </StyledMenu>

              {/* For DataLanguages */}
              {appContext.signedIn && (
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  // variant="contained"
                  color="primary"
                  onClick={handleDataLanguageClick}
                  className={
                    homePageFlag ? classes.menuButton : classes.menuButton2
                  }
                >
                  {dataLanguagesLabel}
                </Button>
              )}

              <StyledMenu
                id="customized-menu"
                anchorEl={dataLanguageAnchor}
                keepMounted
                open={Boolean(dataLanguageAnchor)}
                onClose={handleDataLanguageClose}
              >
                <StyledMenuItem>
                  <ListItemIcon>
                    <AddIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <Link
                    component={RouterLink}
                    to="/createDataLanguages"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={createDataLanguages} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <EditIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/manageDataLanguages"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={manageDataLanguages} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <ViewComfyIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/viewDataLanguages"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={viewDataLanguages} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <TranslateIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/convertDataLanguages"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={convertDataLanguages} />
                  </Link>
                </StyledMenuItem>
              </StyledMenu>

              {/* For Resources */}
              {appContext.signedIn && (
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  // variant="contained"
                  color="primary"
                  onClick={handleResourcesClick}
                  className={
                    homePageFlag ? classes.menuButton : classes.menuButton2
                  }
                >
                  {resources}
                </Button>
              )}

              <StyledMenu
                id="customized-menu"
                anchorEl={resourcesAnchor}
                keepMounted
                open={Boolean(resourcesAnchor)}
                onClose={handleResourcesClose}
              >
                <StyledMenuItem>
                  <ListItemIcon>
                    <AddIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <Link
                    component={RouterLink}
                    to="/createResources"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={createResources} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <EditIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/manageResources"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={manageResources} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <ViewComfyIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/viewResources"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={viewResources} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <TranslateIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/convertResources"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={convertResources} />
                  </Link>
                </StyledMenuItem>
              </StyledMenu>

              {/* For ReferenceTypes */}
              {appContext.signedIn && (
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  // variant="contained"
                  color="primary"
                  onClick={handleReferenceTypesClick}
                  className={
                    homePageFlag ? classes.menuButton : classes.menuButton2
                  }
                >
                  {types}
                </Button>
              )}

              <StyledMenu
                id="customized-menu"
                anchorEl={referenceTypesAnchor}
                keepMounted
                open={Boolean(referenceTypesAnchor)}
                onClose={handleReferenceTypesClose}
              >
                <StyledMenuItem>
                  <ListItemIcon>
                    <AddIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <Link
                    component={RouterLink}
                    to="/createReferenceTypes"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={createReferenceTypes} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <EditIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/manageReferenceTypes"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={manageReferenceTypes} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <ViewComfyIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/viewReferenceTypes"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={viewReferenceTypes} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <TranslateIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/convertReferenceTypes"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={convertReferenceTypes} />
                  </Link>
                </StyledMenuItem>
              </StyledMenu>

              {/* For ReferenceData */}
              {appContext.signedIn && (
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  // variant="contained"
                  color="primary"
                  onClick={handleReferenceDataClick}
                  className={
                    homePageFlag ? classes.menuButton : classes.menuButton2
                  }
                >
                  {references}
                </Button>
              )}

              <StyledMenu
                id="customized-menu"
                anchorEl={referenceDataAnchor}
                keepMounted
                open={Boolean(referenceDataAnchor)}
                onClose={handleReferenceDataClose}
              >
                <StyledMenuItem>
                  <ListItemIcon>
                    <AddIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <Link
                    component={RouterLink}
                    to="/createReferenceData"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={createReferenceData} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <EditIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/manageReferenceData"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={manageReferenceData} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <ViewComfyIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/viewReferenceData"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={viewReferenceData} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <TranslateIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/convertReferenceData"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={convertReferenceData} />
                  </Link>
                </StyledMenuItem>
              </StyledMenu>

              {/* For SocialNetworks */}
              {appContext.signedIn && (
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  // variant="contained"
                  color="primary"
                  onClick={handleSocialNetworksClick}
                  className={
                    homePageFlag ? classes.menuButton : classes.menuButton2
                  }
                >
                  {socials}
                </Button>
              )}

              <StyledMenu
                id="customized-menu"
                anchorEl={socialNetworksAnchor}
                keepMounted
                open={Boolean(socialNetworksAnchor)}
                onClose={handleSocialNetworksClose}
              >
                <StyledMenuItem>
                  <ListItemIcon>
                    <AddIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <Link
                    component={RouterLink}
                    to="/createSocialNetworks"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={createSocialNetworks} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <EditIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/manageSocialNetworks"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={manageSocialNetworks} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <ViewComfyIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/viewSocialNetworks"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={viewSocialNetworks} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <TranslateIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/convertSocialNetworks"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={convertSocialNetworks} />
                  </Link>
                </StyledMenuItem>
              </StyledMenu>

              {/* For ContactInfo */}
              {appContext.signedIn && (
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  // variant="contained"
                  color="primary"
                  onClick={handleContactInfoClick}
                  className={
                    homePageFlag ? classes.menuButton : classes.menuButton2
                  }
                >
                  {contactInfo}
                </Button>
              )}

              <StyledMenu
                id="customized-menu"
                anchorEl={contactInfoAnchor}
                keepMounted
                open={Boolean(contactInfoAnchor)}
                onClose={handleContactInfoClose}
              >
                <StyledMenuItem>
                  <ListItemIcon>
                    <AddIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <Link
                    component={RouterLink}
                    to="/createContactInfo"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={createContactInfo} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <EditIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/manageContactInfo"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={manageContactInfo} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <ViewComfyIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/viewContactInfo"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={viewContactInfo} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <TranslateIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/convertContactInfo"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={convertContactInfo} />
                  </Link>
                </StyledMenuItem>

                <HorizontalRule />

                <StyledMenuItem>
                  <ListItemIcon>
                    <MyLocationIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/geocodeContactAddr"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={geocodeContactAddr} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <ScheduleIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/businessHours"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={businessHrsLabel} />
                  </Link>
                </StyledMenuItem>
              </StyledMenu>

              {/* For Support */}
              {/* {appContext.signedIn && (
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  // variant="contained"
                  color="primary"
                  onClick={handleSupportClick}
                  className={
                    homePageFlag ? classes.menuButton : classes.menuButton2
                  }
                >
                  {support}
                </Button>
              )}

              <StyledMenu
                id="customized-menu"
                anchorEl={supportAnchor}
                keepMounted
                open={Boolean(supportAnchor)}
                onClose={handleSupportClose}
              >
                <StyledMenuItem>
                  <ListItemIcon>
                    <EditIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <Link
                    component={RouterLink}
                    to="/customerService"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={customerService} />
                  </Link>
                </StyledMenuItem>
              </StyledMenu> */}

              {/* For Content */}
              {appContext.signedIn && (
                <Button aria-controls="customized-menu" aria-haspopup="true" color="primary" onClick={handleContentClick}
                  className={
                    homePageFlag ? classes.menuButton : classes.menuButton2
                  }
                >
                  {webPagesLabel}
                </Button>
              )}

              <StyledMenu id="customized-menu" anchorEl={contentAnchor} keepMounted open={Boolean(contentAnchor)} onClose={handleContentClose}>
                <StyledMenuItem>
                  <ListItemIcon>
                    <WebIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link component={RouterLink} to="/webPages" style={{ textDecoration: "none" }}>
                    <ListItemText primary={manageWebPagesLabel} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <WebAssetIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link component={RouterLink} to="/pageSections" style={{ textDecoration: "none" }}>
                    <ListItemText primary={managePageSectionsLabel} />
                  </Link>
                </StyledMenuItem>

                <HorizontalRule />

                <StyledMenuItem>
                  <ListItemIcon>
                    <WidgetsIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/designBlocks"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={manageDesignBlocksLabel} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <DetailsIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/designDetails"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={manageDesignDetailsLabel} />
                  </Link>
                </StyledMenuItem>

                {/* <StyledMenuItem>
                  <ListItemIcon>
                    <DetailsIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/convertDesignBlockDetails"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={convertDesignBlockDetails} />
                  </Link>
                </StyledMenuItem> */}

                {/* <StyledMenuItem>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link
                    component={RouterLink}
                    to="/deleteDesignBlocks"
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemText primary={deleteDesignBlocks} />
                  </Link>
                </StyledMenuItem> */}
              </StyledMenu>

              {/* For Miscellaneous */}
              {appContext.signedIn && (
                <Button color="primary" onClick={handleMiscellaneousClick} className={homePageFlag ? classes.menuButton : classes.menuButton2} aria-controls="customized-menu" aria-haspopup="true">
                  {miscellaneousLabel}
                </Button>
                )
              }

              <StyledMenu id="customized-menu" anchorEl={miscellaneousAnchor} keepMounted open={Boolean(miscellaneousAnchor)} onClose={handleMiscellaneousClose}>
                <StyledMenuItem>
                  <ListItemIcon>
                    <BookIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link component={RouterLink} to="/blogCapture" style={{ textDecoration: "none" }}>
                    <ListItemText primary={postBlogsLabel} />
                  </Link>
                </StyledMenuItem>
                
                <StyledMenuItem>
                  <ListItemIcon>
                    <WorkIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link component={RouterLink} to="/jobs" style={{ textDecoration: "none" }}>
                    <ListItemText primary={postJobsLabel} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <AnnouncementIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link component={RouterLink} to="/news" style={{ textDecoration: "none" }}>
                    <ListItemText primary={postNewsLabel} />
                  </Link>
                </StyledMenuItem>

                <Divider />

                <StyledMenuItem>
                  <ListItemIcon>
                    <LocalOfferIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link component={RouterLink} to="/priceHeader" style={{ textDecoration: "none" }}>
                    <ListItemText primary={managePriceHeadersLabel} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <ListAltIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link component={RouterLink} to="/priceList" style={{ textDecoration: "none" }}>
                    <ListItemText primary={managePriceListLabel} />
                  </Link>
                </StyledMenuItem>

                <Divider />

                <StyledMenuItem>
                  <ListItemIcon>
                    <PeopleIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link component={RouterLink} to="/customerService" style={{ textDecoration: "none" }}>
                    <ListItemText primary={customerService} />
                  </Link>
                </StyledMenuItem>

                <StyledMenuItem>
                  <ListItemIcon>
                    <LiveHelpIcon fontSize="small" color="primary" />
                  </ListItemIcon>

                  <Link component={RouterLink} to="/faqs" style={{ textDecoration: "none" }}>
                    <ListItemText primary={manageFaqsLabel} />
                  </Link>
                </StyledMenuItem>
              </StyledMenu>
            </div>
          </Toolbar>
        }
        
        {/* {isTabletView &&
          <div className={classes.title}>
            <span style={directionFloat}>
              <Logo />
            </span>
          </div>
        }
        
        {isMobileView &&
          <div className={classes.title}>
            <span style={directionFloat}>
              <Logo />
            </span>
          </div>
        } */}
      </div>
    </div>
  );
}

export default AppMainMenu;
