import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LinearProgress from "@material-ui/core/LinearProgress";

// Toast Image Editor
import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";

// Amplify
import { API, Storage } from "aws-amplify";
// import Amplify, { API, Storage } from 'aws-amplify';
// import AmplifyConfig from '../../config/AmplifyConfig';

// AppContext
import AppContext from "../../config/AppContext";

import AppMainMenu from "../../pages/headers/AppMainMenu";
import PageFooter from "../../pages/footers/PageFooter";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

const uploadFileResources = {
  uploadPageHdr: "Upload your files",
  uploadingLabel: "Uploading",
  uploadFilesLabel: "Upload files",
  uploadComplete: "Upload is complete",
  totalNumberFiles: "Total number of files",

  dialogTitle: "Upload files",
  dropzoneText: "Drop files here or click to select files",
  cancel: "Cancel",
  submit: "Submit",
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100% !important",
    textAlign: "center",
    margin: theme.spacing(4),
  },
  verticalAlignLabel: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  uploadComplete: {
    color: "green",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  hdrLabel: {
    marginBottom: theme.spacing(2),
  },
}));

function FilesUploadToS3() {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [uploadedFilesCnt, setUploadedFilesCnt] = useState(0);
  const [openFlag, setOpenFlag] = useState(false); // Required. Sets whether the dialog is open or closed
  const [filesLimit, setFilesLimit] = useState(1000); // Maximum number of files that can be loaded into the dropzone
  const [showPreviews, setShowPreviews] = useState(false); // Shows previews BELOW the Dropzone
  const [showPreviewsInDropzone, setShowPreviewsInDropzone] = useState(true); // Shows preview INSIDE the dropzone
  const [showAlerts, setShowAlerts] = useState(false); // shows styled snackbar alerts when files are dropped, deleted
  const [maxWidth, setMaxWidth] = useState("sm"); // Sets dialog width. Width grows with the size of the screen.
  const [fullWidth, setFullWidth] = useState(true); // If true, the dialog stretches to maxWidth.
  const [maxFileSize, setMaxFileSize] = useState(5000000);
  const [acceptedFiles, setAcceptedFiles] = useState([
    "image/jpeg",
    "image/png",
    "image/bmp",
  ]);
  const [currentFileName, setCurrentFileName] = useState(""); // File currently being uploaded
  const [progress, setProgress] = useState(null);
  const [showFileNamesInPreview, setShowFileNamesInPreview] = useState(true); // Shows file name under the image
  const [showFileNames, setShowFileNames] = useState(true); // Shows file name under the dropzone image.
  const [showProgressFlag, setShowProgressFlag] = useState(false);
  const [uploadCompleteFlag, setUploadCompleteFlag] = useState(false);

  // For AWS Amplify Storage
  const [storageLevel, setStorageLevel] = useState("private"); // public, protected or private
  const [domain, setDomain] = useState(appContext.domain); // ccms
  const [userName, setUserName] = useState(appContext.userName); // "onyii5119@gmail.com"
  const [customPrefix, setCustomPrefix] = useState(null); //
  const [contentType, setContentType] = useState("text/plain");
  const [cacheControl, setCacheControl] = useState("");
  const [contentDisposition, setContentDisposition] = useState("");
  const [expires, setExpires] = useState(null);
  const [metadata, setMetadata] = useState(null);

  const [completed, setCompleted] = React.useState(0);
  // const [buffer, setBuffer] = React.useState(filesToUpload.length);

  // For Resources
  const [uploadFilesRes, setUploadFilesRes] = useState(uploadFileResources);

  const [renderGridFlag, setRenderGridFlag] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [editImageFlag, setEditImageFlag] = useState(false);

  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  // const directionFloat = {
  //   float: appContext.direction === 'ltr' ? 'left' : 'right',
  // };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  const dropzone = {
    padding: "20px",
    margin: "20px",
  };

  const dropzoneParagraph = {
    marginTop: "20px",
    fontWeight: "bold",
    fontSize: "8px !important",
  };

  const [dropzoneClass, setDropzoneClass] = useState(dropzone); // Custom CSS class name for dropzone container.
  const [dropzoneParagraphClass, setDropzoneParagraphClass] = useState(
    dropzoneParagraph
  ); // Custom CSS class name for text inside the container.

  const {
    uploadPageHdr,
    uploadingLabel,
    uploadFilesLabel,
    uploadComplete,
    totalNumberFiles,
    dialogTitle,
    dropzoneText,
    cancel,
    submit,
  } = uploadFilesRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(uploadFileResources);
    // console.log("In ManageReferenceData(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ManageReferenceData(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    // setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "uploadPageHdr")
              uploadFileResources.uploadPageHdr = item.ResourceDesc;
            if (item.ResourceCode === "uploadingLabel")
              uploadFileResources.uploadingLabel = item.ResourceDesc;
            if (item.ResourceCode === "uploadComplete")
              uploadFileResources.uploadComplete = item.ResourceDesc;
            if (item.ResourceCode === "totalNumberFiles")
              uploadFileResources.totalNumberFiles = item.ResourceDesc;
            if (item.ResourceCode === "uploadFilesLabel")
              uploadFileResources.uploadFilesLabel = item.ResourceDesc;
            if (item.ResourceCode === "dialogTitle")
              uploadFileResources.dialogTitle = item.ResourceDesc;

            if (item.ResourceCode === "dropzoneText")
              uploadFileResources.dropzoneText = item.ResourceDesc;
            if (item.ResourceCode === "cancel")
              uploadFileResources.cancel = item.ResourceDesc;
            if (item.ResourceCode === "submit")
              uploadFileResources.submit = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, uploadFileResources);
        }

        setUploadFilesRes(uploadFileResources);

        setRenderGridFlag(true);

        // setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setUploadFilesRes(uploadFileResources);
        // setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In useEffect()");
  }, []);

  useLayoutEffect(() => {
    // console.log('In ManageReferenceData(): useLayoutEffect(): currentLanguage is: ', currentLanguage);

    // Fetch resource from catche
    let cacheItemKey = "s3FilesUpload";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ManageReferenceData(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ManageReferenceData(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ManageReferenceData(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ManageReferenceData(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ManageReferenceData(): useLayoutEffect(): got resource from cache');
      setUploadFilesRes(cacheItem);
    }
  }, []);

  function upload() {
    // console.log("In upload(): filesToUpload count: ", filesToUpload.length + "\n\n");
    let cnt = 0;

    // setBuffer(filesToUpload.length);
    setShowProgressFlag(true);

    filesToUpload.map((file) => {
      // console.log("In upload(): file.name is: ", file.name);
      // console.log("In upload(): file.lastModified is: ", file.lastModified);
      // console.log("In upload(): file.size is: ", file.size);
      // console.log("In upload(): file.lastModified is: ", file.lastModifiedDate + "\n\n");

      // For public: ccms/onyii5119@gmail.com/myfile.png  For protected/private:
      let objectKey =
        storageLevel === "public"
          ? domain + "/" + userName + "/" + file.name
          : domain + "/" + userName + "/" + file.name;

      Storage.put(objectKey, file, {
        level: storageLevel,
        customPrefix: customPrefix !== null ? customPrefix : null,
        contentType: contentType !== null ? contentType : "text/plain",
        cacheControl: cacheControl !== null ? cacheControl : "",
        contentDisposition:
          contentDisposition !== null ? contentDisposition : "",
        expires: expires !== null ? expires : null,
        metadata: metadata !== null ? metadata : null,

        progressCallback(progress) {
          let percProgress =
            ((progress.loaded / progress.total) * 100).toFixed(0) + "%";
          setCurrentFileName(file.name);
          setProgress(percProgress);
          // console.log("In upload(): Uploaded: ", percProgress);
          // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        },
      })
        .then((result) => {
          cnt++;
          // console.log("In upload(): cnt is: ", cnt);
          // console.log("In upload(): buffer is: ", buffer);
          // console.log("In upload(): result is: ", result);

          setCompleted(cnt);

          if (cnt >= filesToUpload.length) {
            setShowPreviews(false);
            setShowPreviewsInDropzone(false);

            setFilesToUpload([]);
            setShowProgressFlag(false);
            setUploadCompleteFlag(true);
          }
        })
        .catch((err) => {
          console.log("In upload(): err is: ", err);
        });
    });

    /*Storage.put('test.jpg', file, {
      level: 'protected',
      customPrefix: {
        protected: 'protected/predictions/index-faces/',
      }
    });*/
  }

  /***************** */
  /* Dropzone Events */
  /***************** */

  const handleOpen = () => {
    // console.log("In handleOpen()");
    setOpenFlag(true);
  };

  // Fired when the user drops files into dropzone OR deletes a file.
  // Returns all the files currently loaded into the dropzone.
  const onChange = (files) => {
    console.log("In onChange(): files.length is: ", files.length);
    console.log(
      "In onChange(): files is: ",
      files[files.length - 1].webkitRelativePath
    );
    setFilesToUpload(files);
    setShowPreviewsInDropzone(true);
    // setSelectedImage("chi-and-me-smiling.jpg");

    // if (files[0] && files[0].length > 0) {
    console.log("1");
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      console.log("In onChange(): reader.result is: ", reader.result);
      setSelectedImage(reader.result);
      // this.setState({ src: reader.result })
    });
    reader.readAsDataURL(files[files.length - 1]);
    // }
  };

  // Fired when the user clicks the Submit button.
  const onSave = (files) => {
    // console.log("In onSave(): files is: ", files);
    setFilesToUpload(files);
    setOpenFlag(false);
  };

  // Fired when the modal is closed
  const onClose = (event) => {
    // console.log("In onClose(): event is: ", event);
    setOpenFlag(false);
  };

  // Fired when the user drops files into the dropzone.
  // Returns the files dropped
  const onDrop = (files) => {
    // console.log("In onDrop(): files is: ", files);
  };

  // Fired when a file is rejected because of wrong file type, size or goes beyond the filesLimit.
  // Returns the files that were rejected
  const onDropRejected = (files) => {
    // console.log("In onDropRejected(): files is: ", files);
  };

  // Fired when a file is deleted from the previews panel.
  const onDelete = (file) => {
    // console.log("In onDelete(): file is: ", file);
  };

  const MIN = 1;
  let MAX = filesToUpload.length;
  const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

  //--------------------------------
  // Toast Image Editor
  //--------------------------------

  const imageTheme = {
    // Theme object to extends default dark theme.
  };

  const editImage = () => {
    setEditImageFlag(true);
    // return (
    //   <div>
    //   <ImageEditor
    //     includeUI={{
    //       loadImage: {
    //         path: selectedImage,
    //         name: 'SampleImage'
    //       },
    //       theme: imageTheme,
    //       menu: ['shape', 'filter'],
    //       initMenu: 'filter',
    //       uiSize: {
    //         width: '1000px',
    //         height: '700px'
    //       },
    //       menuBarPosition: 'bottom'
    //     }}
    //     cssMaxHeight={500}
    //     cssMaxWidth={700}
    //     selectionStyle={{
    //       cornerSize: 20,
    //       rotatingPointOffset: 70
    //     }}
    //     usageStatistics={true}
    //   />
    //   </div>
    // );
  };

  return (
    <div dir={direction}>
      <div className={classes.root}>
        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        {renderGridFlag && (
          <Container component="div" className={classes.mainGrid} maxWidth="lg">
            <Typography variant="h5" className={classes.hdrLabel}>
              {uploadPageHdr}
            </Typography>

            <Grid container justify="center">
              <Grid item xs={12} sm={4} className={classes.verticalAlignLabel}>
                {showProgressFlag && (
                  <Typography
                    variant="subtitle1"
                    className={classes.label}
                    style={directionAlignment}
                  >
                    {uploadingLabel}: {currentFileName} {progress}
                  </Typography>
                )}
                {uploadCompleteFlag && filesToUpload.length === 0 && (
                  <Typography
                    variant="subtitle1"
                    className={classes.uploadComplete}
                    style={directionAlignment}
                  >
                    {uploadComplete}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12} sm={5} className={classes.verticalAlignLabel}>
                {showProgressFlag && (
                  <LinearProgress
                    variant="determinate"
                    value={normalise(completed)}
                    color="secondary"
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={3} style={{ marginBottom: "8px" }}>
                <Button
                  variant="contained"
                  size="large"
                  disabled={filesToUpload.length > 0 ? false : true}
                  color="primary"
                  className={classes.button}
                  onClick={upload}
                  style={directionReverseFloat}
                >
                  <CloudUploadIcon
                    className={clsx(classes.leftIcon, classes.iconSmall)}
                  />
                  {uploadFilesLabel}
                </Button>
              </Grid>
            </Grid>

            <DropzoneArea
              dialogTitle={dialogTitle}
              dropzoneText={dropzoneText}
              cancelButtonText={cancel}
              submitButtonText={submit}
              dropzoneClass={dropzoneClass}
              dropzoneParagraphClass={dropzoneParagraphClass}
              open={openFlag}
              acceptedFiles={acceptedFiles}
              showPreviews={showPreviews}
              showPreviewsInDropzone={showPreviewsInDropzone}
              showAlerts={showAlerts}
              maxWidth={maxWidth}
              fullWidth={fullWidth}
              filesLimit={filesLimit}
              maxFileSize={maxFileSize}
              showFileNamesInPreview={showFileNamesInPreview}
              showFileNames={showFileNames}
              onSave={onSave}
              onChange={onChange}
              onClose={onClose}
              onDrop={onDrop}
              onDropRejected={onDropRejected}
              onDelete={onDelete}
            />
            <Typography variant="subtitle1" className={classes.label}>
              {totalNumberFiles}: {filesToUpload.length}
            </Typography>
          </Container>
        )}

        {editImageFlag && (
          <ImageEditor
            includeUI={{
              loadImage: {
                path: selectedImage,
                name: "SampleImage",
              },
              theme: imageTheme,
              menu: ["shape", "filter"],
              initMenu: "filter",
              uiSize: {
                width: "1000px",
                height: "700px",
              },
              menuBarPosition: "bottom",
            }}
            cssMaxHeight={500}
            cssMaxWidth={700}
            selectionStyle={{
              cornerSize: 20,
              rotatingPointOffset: 70,
            }}
            usageStatistics={true}
          />
        )}
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={classes.button}
          onClick={editImage}
          style={directionReverseFloat}
        >
          {"Edit image"}
        </Button>

        <footer className={classes.footer}>
          <PageFooter />
        </footer>
      </div>
    </div>
  );
}

export default FilesUploadToS3;
