import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Editor
import FroalaEditor from "react-froala-wysiwyg";

//
import {
  Button as AriaButton,
  Wrapper as AriaWrapper,
  Menu as AriaMenu,
  MenuItem as AriaMenuItem,
  openMenu as AriaOpenMenu,
  closeMenu as AriaCloseMenu,
} from "react-aria-menubutton";

// import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
// import ReactCSSTransitionGroup  from 'react-addons-css-transition-group'; // ES6
import AriaMenuButton from "react-aria-menubutton";

// Import all Froala Editor plugins;
// import 'froala-editor/js/plugins.pkgd.min.js';

// Import a single Froala Editor plugin.
// import 'froala-editor/js/plugins/align.min.js';

// Import a language file.
// import 'froala-editor/js/languages/de.js';
// import 'froala-editor/js/languages/zh_cn.js';
// import 'froala-editor/js/languages/ro.js';
// import 'froala-editor/js/languages/ar.js';

// Include font-awesome css if required.
// import 'font-awesome/css/font-awesome.css';
// install using "npm install font-awesome --save"

// Include special components if required.
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

// AppContext
import AppContext from "../../config/AppContext";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";
import LineSpacing from "../../components/misc/LineSpacing";
// import { useFetchItems } from '../../components/hooks/useFetchItems';

const StyledPaper = styled(Paper)`
  min-width: 300px;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    //backgroundColor: '#fff',
    backgroundImage: `url(${"assets/logos/background.png"})`,
  },
  main: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  progress: {
    margin: theme.spacing(2),
  },
  whiteBg: {
    backgroundColor: "#fff",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  blackBg: {
    backgroundColor: "#000",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  blueBg: {
    background: "#3f51b5",
    // backgroundColor: 'blue',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  fancyMB: {
    position: "relative",
    display: "inline-block",
  },

  fancyMBTrigger: {
    cursor: "pointer",
    display: "inline-block",
    webkitUserSelect: "none",
    mozUserSelect: "none",
    msUserSelect: "none",
    UserSelect: "none",
    background: "#fff",
    color: "#000",
    border: "1px solid #868686",
    borderRadius: "6px",
    padding: "0.88889rem 1.77778rem 0.94444rem 1.77778rem",
    transition: "backgroundColor 300ms ease-out",
  },

  // fancyMBTrigger:hover: {
  //     fancyMB-trigger:focus {
  //     background: #C9F1FF,
  // }

  // fancyMB-trigger::after {
  // content: "",
  // display: inline-block,
  // margin-left: 20px,
  // position: relative,
  // top: 3px,
  // border-color: currentColor transparent transparent,
  // border-width: 5px 5px,
  // border-style: solid,
  // }

  // fancyMB-menu {
  // background: #fff,
  // border: 1px solid #ccc,
  // list-style-type: 'none',
  // padding-left: 0,
  // position: absolute,
  // top: 100%,
  // left: 0,
  // z-index: 99,
  // margin: 2px 0 0 0,
  // width: 100%,
  // max-width: 200px,
  // }

  // fancyMB-menu::before {
  // border: inset 6px,
  // content: "",
  // display: block,
  // height: 0,
  // width: 0,
  // border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #FFF rgba(0, 0, 0, 0),
  // border-bottom-style: solid,
  // position: absolute,
  // top: -12px,
  // left: 10px,
  // z-index: 89,
  // }

  // fancyMB-menu::after {
  // border: inset 7px,
  // content: "",
  // display: block,
  // height: 0,
  // width: 0,
  // border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ccc rgba(0, 0, 0, 0),
  // border-bottom-style: solid,
  // position: absolute,
  // top: -14px,
  // left: 9px,
  // z-index: 88,
  // }

  // fancyMB-menuItem {
  // cursor: pointer,
  // color: #555,
  // padding: 0.5rem,
  // }

  // fancyMB-menuItem:hover,
  // fancyMB-menuItem:focus {
  // background: #EEE,
  // }

  // fancyMB-menuItem.is-selected {
  // cursor: default,
  // font-weight: bold,
  // }

  // fancyMB-menu {
  // width: 250px,
  // max-width: 250px,
  // }

  // fancyMB-menuItem::after {
  // content: '',
  // display: table,
  // clear: both,
  // }

  // fancyMB-svg {
  // float: left,
  // width: 50px,
  // margin-top: 2px,
  // }

  // fancyMB-text {
  // display: block,
  // float: left,
  // margin: 0 5px,
  // }

  // fancyMB-keyword {
  // display: block,
  // font-size: 1.5em,
  // font-weight: bold,
  // text-transform: uppercase,
  // padding: 0 0.5em,
  // border: 1px solid #ccc,
  // border-radius: 4px,
  // }

  // fancyMB-triggerIcon {
  // display: inline-block,
  // vertical-align: middle,
  // margin-right: 5px,
  // }

  // fancyMB-triggerText {
  // display: inline-block,
  // line-height: 1,
  // vertical-align: middle,
  // }

  // fancyMB-triggerSmallText {
  // font-size: 10px,
  // font-weight: normal,
  // }

  // fancyMB-selectedText {
  // margin-top: 1em,
  // }

  // /* transition */

  // fancyMB-menu {
  // -webkit-transform-origin: 10px 0,
  // transform-origin: 10px 0,
  // -webkit-transform: scale(1),
  // transform: scale(1),
  // -webkit-transition: -webkit-transform 0.2s cubic-bezier(.55,0,.1,1),
  // transition: transform 0.2s cubic-bezier(.55,0,.1,1),
  // }
}));

let pageTemplate = {
  pageTemplateLabel: "LogoTest",
};

function LogoTest(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  const [progressFlag, setProgressFlag] = useState(false);

  // Editor
  const [editorData, setEditorData] = useState("This a test of Froala Editor");
  const [editorReady, setEditorReady] = useState(false);

  const [selectedMenu, setSelectedMenu] = useState("");
  const [theMenuItems, setTheMenuItems] = useState("");

  const classes = useStyles();

  const { currLanguage } = props;

  const { pageTemplateLabel } = pageTemplate;

  const menuItems = ["bowling", "science", "scooting"];

  async function GetMenuItems() {
    const items = await menuItems.map(function (activity, i) {
      return (
        <AriaMenuItem
          value={{
            activity,
            somethingArbitrary: "arbitrary",
          }}
          text={activity}
          key={i}
          className="FancyMB-menuItem"
        >
          <img src={`/assets/svg/${activity}.svg`} className="FancyMB-svg" />
          <span className="FancyMB-text">
            I enjoy
            <span className="FancyMB-keyword">{activity}</span>
          </span>
        </AriaMenuItem>
      );
    });
    console.log("In GetMenuItems(): items is: ", items);
    await setTheMenuItems(items);
  }

  // Pre-load images
  function preloadImages() {
    menuItems.forEach((t) => {
      const x = new Image();
      x.src = `/assets/svg/${t}.svg`;
    });
  }

  useEffect(() => {
    // console.log('In LogoTest(): useEffect()');
    GetMenuItems();
  }, []);

  useLayoutEffect(() => {
    // console.log('In LogoTest(): useLayoutEffect(): currentLanguage is: ', currentLanguage);
    // console.log('In LogoTest(): useLayoutEffect(): currLanguage is: ', currLanguage);
    // console.log('In LogoTest(): useLayoutEffect(): isSignedIn is: ', isSignedIn);
    preloadImages();
  }, []);

  // use your custom hook to load the data you need
  // const { books, loading, error } = useFetchItems('https://library.com/api/items');
  // console.log('In LogoTest(): books is: ', books);
  // console.log('In LogoTest(): error is: ', error);
  // if (loading) return <div>Loading...</div>
  // if (error) return <div>{error}</div>

  //---------------------
  // For Menu
  //---------------------

  function handleSelection(data) {
    console.log("In handleSelection(): data is: ", data);
    console.log("In handleSelection(): data.activity is: ", data.activity);
    setSelectedMenu(data.activity);
  }

  const menuInnards = (menuState) => {
    if (!menuState.isOpen) return null;
    return (
      <div className="FancyMB-menu" key="menu">
        {theMenuItems}
      </div>
    );
  };

  return (
    <div dir={direction}>
      <div className={classes.root}>
        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />
        {/* { !appContext.signedIn &&
                    <Redirect to='/signIn' />
                } */}

        <Container component="div" className={classes.main} maxWidth="lg">
          <div>
            {/* progressFlag &&  
                            <div style={{textAlign: 'center'}}>
                            <CircularProgress className={classes.progress} color="secondary" size={15}/>
                            </div> 
                        */}

            {/* For react-aria-menubutton */}
            <div style={{ marginBottom: 20 }}>
              <button onClick={() => AriaOpenMenu("foo")}>
                open menu below
              </button>
              <button onClick={() => AriaOpenMenu("foo", { focusMenu: false })}>
                open menu below without focus
              </button>
              <button onClick={() => AriaCloseMenu("foo")}>
                close menu below
              </button>
            </div>

            <div>
              <AriaWrapper
                onSelection={handleSelection}
                className={classes.fancyMB}
                id="foo"
              >
                <AriaButton className="FancyMB-trigger">
                  <span className="FancyMB-triggerInnards">
                    <img
                      src="/assets/svg/profile-female.svg"
                      className="FancyMB-triggerIcon"
                    />
                    <span className="FancyMB-triggerText">
                      What do you enjoy?
                      <br />
                      <span className="FancyMB-triggerSmallText">
                        (select an enjoyable activity)
                      </span>
                    </span>
                  </span>
                </AriaButton>
                <AriaMenu>{menuInnards}</AriaMenu>
              </AriaWrapper>

              <span
                className="FancyMB-selectedText"
                style={{ marginLeft: "1em" }}
              >
                You said you enjoy: <strong>{selectedMenu}</strong>
              </span>
            </div>

            <LineSpacing value={12} />

            {/* For payandcash */}
            <Container
              component="div"
              maxWidth="md"
              style={{ background: "white" }}
            >
              <img
                src="assets/logos/payandcash.png"
                style={{ width: "192px", padding: "10px" }}
              />
            </Container>

            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/payandcash.png"
                style={{ width: "192px", padding: "10px" }}
              />
            </Container>

            {/* For favor */}
            <Container
              component="div"
              maxWidth="md"
              style={{ background: "white" }}
            >
              <img
                src="assets/logos/favor.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/favor.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/favor_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/favor_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For cloudobituary */}
            <Container
              component="div"
              maxWidth="md"
              style={{ background: "white" }}
            >
              <img
                src="assets/logos/cloudobituary.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/cloudobituary.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/cloudobituary_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/cloudobituary_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For exposeracism */}
            <Container
              component="div"
              maxWidth="md"
              style={{ background: "white" }}
            >
              <img
                src="assets/logos/exposeracism.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/exposeracism.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/exposeracism_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/exposeracism.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For workparadise */}
            <Container
              component="div"
              maxWidth="md"
              style={{ background: "white" }}
            >
              <img
                src="assets/logos/workparadise.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/workparadise.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/workparadise.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/workparadise.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For iwasdefiled */}
            <Container
              component="div"
              maxWidth="md"
              style={{ background: "white" }}
            >
              <img
                src="assets/logos/iwasdefiled.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/iwasdefiled.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/iwasdefiled_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/iwasdefiled_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For greatbestdeal */}
            <Container
              component="div"
              maxWidth="md"
              style={{ background: "white" }}
            >
              <img
                src="assets/logos/greatbestdeal.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/greatbestdeal.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/greatbestdeal_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/greatbestdeal_white_rope.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/greatbestdeal_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For africanalove */}
            <Container
              component="div"
              maxWidth="md"
              style={{ background: "white" }}
            >
              <img
                src="assets/logos/africanalove.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/africanalove.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/africanalove_white_text.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/africanalove_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/africanalove_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For cloudcms */}
            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/cloudcms.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/cloudcms.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/cloudcms_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/cloudcms_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For cloudsecure */}
            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/cloudsecure.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/cloudsecure.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/cloudsecure.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For trustemailer */}
            <Container
              component="div"
              className={classes.whiteBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/trustemailer.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/trustemailer.png"
                style={{ width: "128px", padding: "10px", color: "#fff" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/trustemailer_white.png"
                style={{ width: "128px", padding: "10px", color: "#fff" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/trustemailer_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For tobisoft_blue */}
            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/tobisoft_blue.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/tobisoft_blue.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/tobisoft_blue.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For tobisoft */}
            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/tobisoft.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/tobisoft.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/tobisoft_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/tobisoft.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/tobisoft_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For africanaarts */}
            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/africanaarts.png"
                style={{ width: "192px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/africanaarts.png"
                style={{ width: "192px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/africanaarts.png"
                style={{ width: "192px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/africanaarts_white.png"
                style={{ width: "192px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/africanaarts_white.png"
                style={{ width: "192px", padding: "10px" }}
              />
            </Container>

            {/* For translat */}
            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/translat.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/translat.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/translat.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/translat_white.png"
                style={{ width: "128px", padding: "10px", color: "#fff" }}
              />
            </Container>

            {/* For lovers */}
            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/lovers.png"
                style={{ width: "196px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/lovers.png"
                style={{ width: "196px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/lovers.png"
                style={{ width: "196px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/lovers_white.png"
                style={{ width: "196px", padding: "10px", color: "#fff" }}
              />
            </Container>

            {/* For aldietoday_green */}
            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/aldietoday_green.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/aldietoday_green.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/aldietoday_green.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For cloudrx */}
            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/cloudrx.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/cloudrx.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/cloudrx_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/cloudrx_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For aldietoday */}
            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/aldietoday.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/aldietoday.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/aldietoday.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For brambletontoday */}
            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/brambletontoday.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/brambletontoday.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/brambletontoday.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For cloudmedia */}
            <Container component="div" maxWidth="md">
              <img
                src="assets/logos/cloudmedia.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/cloudmedia.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/cloudmedia.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For realid */}
            <Container
              component="div"
              className={classes.whiteBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/realid.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/realid.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/realid_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/realid_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            {/* For telcomm */}
            <Container
              component="div"
              className={classes.whiteBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/telcomm.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <Container
              component="div"
              className={classes.blackBg}
              maxWidth="md"
            >
              <img
                src="assets/logos/telcomm_white.png"
                style={{ width: "128px", padding: "10px", color: "#fff" }}
              />
            </Container>

            <Container component="div" className={classes.blueBg} maxWidth="md">
              <img
                src="assets/logos/telcomm_white.png"
                style={{ width: "128px", padding: "10px" }}
              />
            </Container>

            <LineSpacing value={12} />
          </div>
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default LogoTest;
