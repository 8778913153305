import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { NavLink, Redirect } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
// import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Link from "@material-ui/core/Link";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import clsx from "clsx";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import Amplify, { Auth, API } from "aws-amplify";

import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import GlobalConstants from "../../utils/GlobalConstants";
// import GeneratePassword from '../../utils/GeneratePassword';
// import GenerateClientId from '../../utils/GenerateClientId';
// import GenerateTransactionId from '../../utils/GenerateTransactionId';
// import GenerateRandomHash from '../../utils/GenerateRandomHash';

import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import SignIn from "./SignIn";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";
import LineSpacing from "../../components/misc/LineSpacing";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 20px 30px 20px 30px !important;
  text-align: center !important;
  align-items: center;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 12px 0 12px 0 !important;
`;

const StyledButton = styled(Button)`
  background: transparent;
  border-radius: 3px;
  border: 2px solid palevioletred;
  color: palevioletred;
  margin-bottom: 12px !important;

  /* When the "primary" property is set we want to add some more css to our component */
  /* in this case change the background and color. */

  ${(props) =>
    props.color === "primary" &&
    css`
      background: green !important;
      color: white !important;
      margin: 0 0 12px 20px !important;
    `};
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    // minHeight: '100vh',
    position: "relative",
    // backgroundColor: '#fff',
    backgroundImage: `url(${"assets/logos/background21.png"})`,
  },
  main: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  footer: {
    // position: 'absolute',
    bottom: 0,
    height: "1rem" /* Footer height */,
    width: "100% !important",
    // marginTop: 'auto',
    textAlign: "center",
  },
  textField: {
    width: "100%",
  },
  gridHeader: {
    fontWeight: "bold",
  },
  progress: {
    margin: theme.spacing(2),
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "20px 40px 20px 40px !important",
    textAlign: "center",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

let changePw = {
  changePasswordLabel: "Change password",
  changeAccountPassword: "Complete form to change your account password",
  currentPasswordLabel: "Current password",
  newPasswordLabel: "New password",
  verifyNewPasswordLabel: "Verify new password",
  // cancel: "Cancel",
  enterCorrectPassword: "Please enter a valid password.",
  verifyNewPasswordFailed: "Could not verify new password.",
  invalidCredentials: "Invalid username and/or password.",
  passwordConstraintsNotMet: "Password constraints are not met.",
  // submit: "Submit",
  toggleNewPassword: "Toggle new password visibility",
  toggleVerifyPassword: "Toggle verify new password visibility",
};

function ChangePassword(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const [changePwRes, setChangePwRes] = useState(changePw);

  const [user, setUser] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);
  const [newPassword, setNewPassword] = useState("");
  const [verifyNewPassword, setVerifyNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showVerifyNewPassword, setShowVerifyNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [changePasswordFlag, setChangePasswordFlag] = useState(false);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  // For Error Flags
  const [newPasswordErrFlag, setNewPasswordErrFlag] = useState(false);
  const [newPasswordFocus, setNewPasswordFocus] = useState(false);
  const [verifyNewPasswordErrFlag, setVerifyNewPasswordErrFlag] = useState(
    false
  );
  const [verifyNewPasswordFocus, setVerifyNewPasswordFocus] = useState(false);
  const [currentPasswordErrFlag, setCurrentPasswordErrFlag] = useState(false);
  const [currentPasswordFocus, setCurrentPasswordFocus] = useState(true);
  const [progressFlag, setProgressFlag] = useState(false);

  const [changePasswordButtonFlag, setChangePasswordButtonFlag] = useState(
    false
  );

  // For Current Password
  const [currentPwVariant, setCurrentPwVariant] = useState(null);
  const [currentPwType, setCurrentPwType] = useState("password");
  const [currentPwVisibilityIcon, setCurrentPwVisibilityIcon] = useState(null);

  // For New Password
  const [newPwVariant, setNewPwVariant] = useState(null);
  const [newPwType, setNewPwType] = useState("password");
  const [newPwVisibilityIcon, setNewPwVisibilityIcon] = useState(null);

  // For Verify New Password
  const [verifyNewPwVariant, setVerifyNewPwVariant] = useState(null);
  const [verifyNewPwType, setVerifyNewPwType] = useState("password");
  const [verifyNewPwVisibilityIcon, setVerifyNewPwVisibilityIcon] = useState(
    null
  );

  const [showPassword, setShowPassword] = useState(false);

  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const {
    changePasswordLabel,
    changeAccountPassword,
    currentPasswordLabel,
    newPasswordLabel,
    verifyNewPasswordLabel,
    cancel,
    enterCorrectPassword,
    verifyNewPasswordFailed,
    invalidCredentials,
    passwordConstraintsNotMet,
    // submit,
    toggleNewPassword,
    toggleVerifyPassword,
  } = changePwRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(changePw);
    // console.log("In ForgotPassword(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ForgotPassword(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "changePasswordLabel")
              changePw.changePasswordLabel = item.ResourceDesc;
            if (item.ResourceCode === "changeAccountPassword")
              changePw.changeAccountPassword = item.ResourceDesc;
            if (item.ResourceCode === "currentPasswordLabel")
              changePw.currentPasswordLabel = item.ResourceDesc;
            if (item.ResourceCode === "newPasswordLabel")
              changePw.newPasswordLabel = item.ResourceDesc;
            if (item.ResourceCode === "verifyNewPasswordLabel")
              changePw.verifyNewPasswordLabel = item.ResourceDesc;
            if (item.ResourceCode === "verifyNewPasswordFailed")
              changePw.verifyNewPasswordFailed = item.ResourceDesc;
            if (item.ResourceCode === "enterCorrectPassword")
              changePw.enterCorrectPassword = item.ResourceDesc;
            if (item.ResourceCode === "invalidCredentials")
              changePw.invalidCredentials = item.ResourceDesc;
            if (item.ResourceCode === "passwordConstraintsNotMet")
              changePw.passwordConstraintsNotMet = item.ResourceDesc;
            if (item.ResourceCode === "toggleNewPassword")
              changePw.toggleNewPassword = item.ResourceDesc;
            if (item.ResourceCode === "toggleVerifyPassword")
              changePw.toggleVerifyPassword = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, changePw);
          setChangePwRes(changePw);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setChangePwRes(changePw);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    setNewPassword("");
    setVerifyNewPassword("");
    setCurrentPassword("");

    setNewPasswordFocus(false);
    setVerifyNewPasswordFocus(false);
    setCurrentPasswordFocus(true);

    // setCurrentPwVisibilityIcon(currentPwIconVisibility);
    // setNewPwVisibilityIcon(newPwIconVisibility);
    // setVerifyNewPwVisibilityIcon(verifyNewPwIconVisibility);
  }, []);

  useLayoutEffect(() => {
    // console.log("In ForgotPassword(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "changePw";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ForgotPassword(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ForgotPassword(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ForgotPassword(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ForgotPassword(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ForgotPassword(): useLayoutEffect(): got resource from cache');
      setChangePwRes(cacheItem);
    }

    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In ForgotPassword(): useLayoutEffect(): currentLanguage is: ', JSON.stringify(currentLanguage, null, 2));
    // console.log('In ForgotPassword(): useLayoutEffect(): direction is: ', direction);
    // console.log('In ForgotPassword(): useLayoutEffect(): appContext.signedIn is: ', appContext.signedIn);
  }, []);

  function handleChange(event) {
    // console.log("In handleChange(): id is: ", event.target.id);
    //console.log("In handleChange(): tagName is: "+event.target.tagName);
    // console.log("In handleChange(): value is: ", event.target.value);

    setGlobalErrorFlag(false);
    setChangePasswordButtonFlag(false);

    setCurrentPwVariant("danger");
    setNewPwVariant("danger");
    setVerifyNewPwVariant("danger");

    switch (event.target.id) {
      case "currentPasswordId":
        setCurrentPasswordErrFlag(false);
        setCurrentPassword(event.target.value);

        // Enable button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          newPassword !== undefined &&
          newPassword !== null &&
          newPassword !== "" &&
          verifyNewPassword !== undefined &&
          verifyNewPassword !== null &&
          verifyNewPassword !== ""
        ) {
          setChangePasswordButtonFlag(true);
        }
        break;
      case "newPasswordId":
        setNewPasswordErrFlag(false);
        setNewPassword(event.target.value);

        // Enable button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          verifyNewPassword !== undefined &&
          verifyNewPassword !== null &&
          verifyNewPassword !== "" &&
          currentPassword !== undefined &&
          currentPassword !== null &&
          currentPassword !== ""
        ) {
          setChangePasswordButtonFlag(true);
        }
        break;
      case "verifyNewPasswordId":
        setVerifyNewPasswordErrFlag(false);
        setVerifyNewPassword(event.target.value);

        // Enable button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          newPassword !== undefined &&
          newPassword !== null &&
          newPassword !== "" &&
          currentPassword !== undefined &&
          currentPassword !== null &&
          currentPassword !== ""
        ) {
          setChangePasswordButtonFlag(true);
        }
        break;
      default:
        break;
    }
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function handleClickShowCurrentPassword() {
    setShowCurrentPassword(!showCurrentPassword);
  }

  function handleClickShowNewPassword() {
    setShowNewPassword(!showNewPassword);
  }

  function handleClickShowVerifyNewPassword() {
    setShowVerifyNewPassword(!showVerifyNewPassword);
  }

  function validateForm() {
    let formErrFlag = false;
    let constraintErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setNewPasswordErrFlag(false);
    setVerifyNewPasswordErrFlag(false);
    setCurrentPasswordErrFlag(false);

    if (currentPassword === null || currentPassword === "") {
      formErrFlag = true;
      setCurrentPasswordErrFlag(true);
    } else if (!currentPassword.match(GlobalConstants.passwordRegex)) {
      constraintErrFlag = true;
      formErrFlag = true;
      setCurrentPasswordErrFlag(true);
    }

    if (newPassword === null || newPassword === "") {
      formErrFlag = true;
      setNewPasswordErrFlag(true);
    } else if (!newPassword.match(GlobalConstants.passwordRegex)) {
      constraintErrFlag = true;
      formErrFlag = true;
      setNewPasswordErrFlag(true);
    }

    if (verifyNewPassword === null || verifyNewPassword === "") {
      formErrFlag = true;
      setVerifyNewPasswordErrFlag(true);
    } else if (!verifyNewPassword.match(GlobalConstants.passwordRegex)) {
      constraintErrFlag = true;
      formErrFlag = true;
      setVerifyNewPasswordErrFlag(true);
    }

    if (verifyNewPassword !== newPassword) {
      formErrFlag = true;
      setVerifyNewPasswordErrFlag(true);
    }

    // Check for Constraints Error
    if (constraintErrFlag) {
      setGlobalErrorFlag(true);
      setGlobalErrorMsg(passwordConstraintsNotMet);
    }

    return formErrFlag;
  }

  async function changePasswordService() {
    // console.log("In changePasswordService(): currentPassword is: " + currentPassword);
    // console.log("In changePasswordService(): newPassword is: " + newPassword);

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    if (!validateForm()) {
      // Validation Passed - you can proceed
      // console.log("Validation Passed.");
      setProgressFlag(true);

      Auth.currentAuthenticatedUser()
        .then((user) => {
          setProgressFlag(false);
          return Auth.changePassword(user, currentPassword, newPassword);
        })
        .then((result) => {
          // console.log('In changePasswordService(): result is', JSON.stringify(result, null, 2));
          setProgressFlag(false);
          setChangePasswordFlag(true);
        })
        .catch((err) => {
          console.log(
            "In changePasswordService(): err is",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setProgressFlag(false);
          switch (err.code) {
            case "InvalidParameterException":
              setGlobalErrorMsg(passwordConstraintsNotMet);
              break;
            case "NotAuthorizedException":
              setGlobalErrorMsg(invalidCredentials);
              break;
            default:
              setGlobalErrorMsg(verifyNewPasswordFailed);
              break;
          }
        });
    }
  }

  return (
    <div className={classes.root} dir={direction}>
      <div className={classes.root}>
        {!appContext.signedIn && <Redirect to="/signIn" />}

        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          {/* { !changePasswordFlag &&
            <SignIn />
          } */}

          {/* { progressFlag &&
            <div style={{textAlign: 'center'}}>
              <CircularProgress className={classes.progress} color="secondary" size={15}/>
            </div>
          } */}

          {isSignedIn && (
            <Container component="div" className={classes.main} maxWidth="md">
              <Grid container justify="center">
                <Grid item xs={12} sm={9}>
                  <StyledPaper elevation={3} className={classes.paper}>
                    <Typography variant="h5" style={{ textAlign: "center" }}>
                      {changePasswordLabel}
                    </Typography>

                    <Typography
                      variant="caption"
                      component="h3"
                      style={{ color: "green", textAlign: "center" }}
                    >
                      {changeAccountPassword}
                    </Typography>

                    {globalErrorFlag && (
                      <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                    )}

                    <LineSpacing value={12} />

                    {/* For Current Password */}

                    <FormControl
                      className={clsx(classes.margin, classes.textField)}
                      variant="outlined"
                      error={currentPasswordErrFlag}
                    >
                      <label
                        htmlFor="currentPasswordId"
                        style={directionAlignment}
                      >
                        <Typography
                          variant="subtitle1"
                          color={currentPasswordErrFlag ? "error" : "inherit"}
                        >
                          {currentPasswordLabel}
                        </Typography>
                      </label>
                      <OutlinedInput
                        id="currentPasswordId"
                        type={showPassword ? "text" : "password"}
                        value={currentPassword}
                        placeholder={enterCorrectPassword}
                        onChange={handleChange}
                        error={currentPasswordErrFlag}
                        required
                        fullWidth
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle currentPassword visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {currentPasswordErrFlag && (
                        <FormHelperText id="currentPassword-helper-text">
                          {passwordConstraintsNotMet}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <LineSpacing value={12} />

                    {/* For New Password */}

                    <FormControl
                      className={clsx(classes.margin, classes.textField)}
                      variant="outlined"
                      error={newPasswordErrFlag}
                    >
                      <label htmlFor="newPasswordId" style={directionAlignment}>
                        <Typography
                          variant="subtitle1"
                          color={newPasswordErrFlag ? "error" : "inherit"}
                        >
                          {newPasswordLabel}
                        </Typography>
                      </label>
                      <OutlinedInput
                        id="newPasswordId"
                        type={showPassword ? "text" : "password"}
                        value={newPassword}
                        placeholder={enterCorrectPassword}
                        onChange={handleChange}
                        error={newPasswordErrFlag}
                        required
                        fullWidth
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle newPassword visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {newPasswordErrFlag && (
                        <FormHelperText id="newPassword-helper-text">
                          {passwordConstraintsNotMet}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <LineSpacing value={12} />

                    {/* For Verify New Password */}

                    <FormControl
                      className={clsx(classes.margin, classes.textField)}
                      variant="outlined"
                      error={verifyNewPasswordErrFlag}
                    >
                      <label
                        htmlFor="verifyNewPasswordId"
                        style={directionAlignment}
                      >
                        <Typography
                          variant="subtitle1"
                          color={verifyNewPasswordErrFlag ? "error" : "inherit"}
                        >
                          {verifyNewPasswordLabel}
                        </Typography>
                      </label>
                      <OutlinedInput
                        id="verifyNewPasswordId"
                        type={showPassword ? "text" : "password"}
                        value={verifyNewPassword}
                        placeholder={enterCorrectPassword}
                        onChange={handleChange}
                        error={verifyNewPasswordErrFlag}
                        required
                        fullWidth
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle verifyNewPassword visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {verifyNewPasswordErrFlag && (
                        <FormHelperText id="verifyNewPassword-helper-text">
                          {passwordConstraintsNotMet}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <LineSpacing value={24} />

                    <Button
                      variant="contained"
                      size="large"
                      disabled={!changePasswordButtonFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={changePasswordService}
                    >
                      <LockOutlinedIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {changePasswordLabel}
                    </Button>

                    <LineSpacing value={18} />
                  </StyledPaper>
                </Grid>
              </Grid>
            </Container>
          )}
        </Container>
        <PageFooter currentLanguage={currentLanguage} isSignedIn={isSignedIn} />
      </div>
    </div>
  );
}

export default ChangePassword;
