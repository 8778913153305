import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";

// AG-Grid
import { AgGridReact, AgGridColumn } from "ag-grid-react";
//import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

// Amplify
import Amplify, { API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import GlobalConstants from "../../utils/GlobalConstants";
import GeneratePassword from "../../utils/GeneratePassword";
import GenerateClientId from "../../utils/GenerateClientId";
import GenerateTransactionId from "../../utils/GenerateTransactionId";
import GenerateRandomHash from "../../utils/GenerateRandomHash";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import AlertDialog from "../../components/dialogs/AlertDialog";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";
import LineSpacing from "../../components/misc/LineSpacing";

// Renderers
import DateCellRenderer from "../../components/renderers/DateCellRenderer";
import NumberCellRenderer from "../../components/renderers/NumberCellRenderer";
import ActionsCellRenderer from "../../components/renderers/ActionsCellRenderer";
import CurrencyCellRenderer from "../../components/renderers/CurrencyCellRenderer";
import CountryCellRenderer from "../../components/renderers/CountryCellRenderer";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 0 20px !important;
  text-align: center !important;
  align-items: center;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 12px 0 12px 0 !important;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${"assets/logos/background20.png"})`,
  },
  gridWidth: {
    width: "80%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  textField: {
    width: "100%",
  },
  verticalAlignLabel: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  filterGrid: {
    textAlign: "left",
    marginBottom: "8px",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  footer: {
    bottom: 0,
    height: "1rem" /* Footer height */,
    width: "100% !important",
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
    textAlign: "center !important",
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

let localeText = {
  page: "Page",
  to: "to",
  of: "of",
  noRowsToShow: "No records found",
};

// let localeText2 = {
//   page: "daPage",
//   more: "daMore",
//   to: "daTo",
//   of: "daOf",
//   next: "daNexten",
//   last: "daLasten",
//   first: "daFirsten",
//   previous: "daPreviousen",
//   loadingOoo: "daLoading...",
//   selectAll: "daSelect Allen",
//   searchOoo: "daSearch...",
//   blanks: "daBlanc",
//   filterOoo: "daFilter...",
//   applyFilter: "daApplyFilter...",
//   equals: "daEquals",
//   notEqual: "daNotEqual",
//   lessThan: "daLessThan",
//   greaterThan: "daGreaterThan",
//   lessThanOrEqual: "daLessThanOrEqual",
//   greaterThanOrEqual: "daGreaterThanOrEqual",
//   inRange: "daInRange",
//   contains: "daContains",
//   notContains: "daNotContains",
//   startsWith: "daStarts dawith",
//   endsWith: "daEnds dawith",
//   andCondition: "daAND",
//   orCondition: "daOR",
//   group: "laGroup",
//   columns: "laColumns",
//   filters: "laFilters",
//   rowGroupColumns: "laPivot Cols",
//   rowGroupColumnsEmptyMessage: "la drag cols to group",
//   valueColumns: "laValue Cols",
//   pivotMode: "laPivot-Mode",
//   groups: "laGroups",
//   values: "laValues",
//   pivots: "laPivots",
//   valueColumnsEmptyMessage: "la drag cols to aggregate",
//   pivotColumnsEmptyMessage: "la drag here to pivot",
//   toolPanelButton: "la tool panel",
//   noRowsToShow: "la no rows",
//   pinColumn: "laPin Column",
//   valueAggregation: "laValue Agg",
//   autosizeThiscolumn: "laAutosize Diz",
//   autosizeAllColumns: "laAutsoie em All",
//   groupBy: "laGroup by",
//   ungroupBy: "laUnGroup by",
//   resetColumns: "laReset Those Cols",
//   expandAll: "laOpen-em-up",
//   collapseAll: "laClose-em-up",
//   toolPanel: "laTool Panelo",
//   export: "laExporto",
//   csvExport: "la CSV Exportp",
//   excelExport: "la Excel Exporto (.xlsx)",
//   excelXmlExport: "la Excel Exporto (.xml)",
//   pinLeft: "laPin &lt;&lt;",
//   pinRight: "laPin &gt;&gt;",
//   noPin: "laDontPin &lt;&gt;",
//   sum: "laSum",
//   min: "laMin",
//   max: "laMax",
//   none: "laNone",
//   count: "laCount",
//   average: "laAverage",
//   copy: "laCopy",
//   copyWithHeaders: "laCopy Wit hHeaders",
//   ctrlC: "ctrl n C",
//   paste: "laPaste",
//   ctrlV: "ctrl n V"
// };

// For AG-Grid

//------------------------------
// RENDERERS
//------------------------------
const gridOptions = {
  style: "currency", // decimal, currency, percent
  currency: "USD",
  currencyDisplay: "symbol", // symbol, code, name to use a localized currency name such as "dollar"
  useGrouping: true,
  minimumIntegerDigits: 21,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  payload: {},

  weekday: "short", // long, short
  year: "numeric",
  month: "short", // long, short
  day: "numeric",
  // timeZone: 'America/New_York', // Auto calculated in DateCellRenderer
  timeZoneName: "short", // long, short
  hour12: true,
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  locale: "default", // To use the browser's default locale, use 'default'

  getRowHeight: function (params) {
    // console.log("In getRowHeight: params is: ", params);
    // assuming 50 characters per line, working how how many lines we need
    // console.log("In getRowHeight: words is: ", params.data.LanguageName.length);
    let lines = 36 * (Math.floor(params.data.LanguageName.length / 45) + 1);
    // console.log("In getRowHeight: lines is: ", lines);
    return lines;
  },
};

let manageLang = {
  selectLanguageLabel: "Select language",
  selectLanguagePrompt: "Select a language",

  dataLanguageCodeLabel: "Original Data language code",
  dataLanguageCodePrompt: "Enter data language code.",

  dataLanguageNameLabel: "Data language name",
  dataLanguageNamePrompt: "Enter data language name.",

  submit: "Submit",
  editLabel: "Edit",
  deleteLabel: "Delete",
  manageLangSuccessMsg: "Your changes were successfully saved.",
  manageLangFailureMsg: "Error while saving your changes.",
  manageDataLanguages: "Manage data languages",
  updateGridRow: "Update grid",
  allFieldsAreRequired: "All fields are required",
  // maxRecordsAllowed: "Maximum number of records allowed in grid is {MAX_RECORDS_ALLOWED}.",
  modifiedBy: "Modified by",
  createdAt: " Create date",
  updatedAt: "Modified date",

  manageLangAlertTitle: "Warning !!!",
  manageLangAlertMainContent: "Your changes contain some deletions.",
  manageLangAlertSubContent:
    "Your confirmation is required before you can continue.",
  manageLangAlertQuestion: "Would you like to continue with submit?",
  yesLabel: "Yes",
  noLabel: "No",

  filterInputPrompt: "Please enter filter criteria",
  selectLanguage: "Please select a language",
  filterGridLabel: "Filter grid",

  // For Grid
  gridPage: "Page",
  gridTo: "to",
  gridOf: "of",
  noRowsToShow: "No records found",
};

function ManageDataLanguages(props) {
  const appContext = useContext(AppContext);
  // console.log('In ManageDataLanguages(): appContext is: ', JSON.stringify(appContext, null, 2));

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const [languageCode, setLanguageCode] = useState("");
  const [languageName, setLanguageName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  // const [ initComplete, setInitComplete ] = useState(appContext.signedIn);
  const [payload, setPayload] = useState({});
  // const [ rowData, setRowData ] = useState([]);
  const [editRowIndex, setEditRowIndex] = useState(-1);

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [langSelectOptions, setLangSelectOptions] = useState([]);

  // AG-Grid
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridData, setGridData] = useState([]);
  const [progressFlag, setProgressFlag] = useState(false);
  const [gridUpdatedFlag, setGridUpdatedFlag] = useState(false);
  const [rowSelectedFlag, setRowSelectedFlag] = useState(false);
  const [gridSubmitFlag, setGridSubmitFlag] = useState(false);
  const [gridRowsCount, setGridRowsCount] = useState(0);

  const [gridFilterValue, setGridFilterValue] = useState("");
  const [paginationPageSize, setPaginationPageSize] = useState(10);

  // For Resources
  const [manageLangRes, setManageLangRes] = useState(manageLang);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'
  const [alertOpen, setAlertOpen] = useState(false);

  // For Error Flags
  const [languageCodeErrFlag, setLanguageCodeErrFlag] = useState(false);
  const [languageCodeFocus, setLanguageCodeFocus] = useState(false);
  const [languageNameErrFlag, setLanguageNameErrFlag] = useState(false);
  const [languageNameFocus, setLanguageNameFocus] = useState(true);

  const [filterDisabledFlag, setFilterDisabledFlag] = useState(true);
  // const iconFilter = <IconFilter style={{color: 'green'}}/>;
  // const iconSearch = <IconSearch style={{color: 'green'}}/>;

  // const [redirectSignUpFlag, setRedirectSignUpFlag] = useState(false);
  // const [globalErrorMsg, setGlobalErrorMsg] = useState('');
  const [modifiedGridRows, setModifiedGridRows] = useState([]);
  const [deletedGridRows, setDeletedGridRows] = useState([]);
  const [currentEditRow, setCurrentEditRow] = useState(null); // Current row being edited

  // For Grid Resources
  const [localeTextRes, setLocaleTextRes] = useState(localeText);

  const [renderGridFlag, setRenderGridFlag] = useState(false);

  //console.log('AmplifyConfig is: ', JSON.stringify(AmplifyConfig, null, 2));
  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  // const { currLanguage } = props;

  // Maximum number of rows allowed in the grid
  const MAX_RECORDS_ALLOWED = 10;

  const {
    selectLanguageLabel,
    selectLanguagePrompt,

    dataLanguageCodeLabel,
    dataLanguageNameLabel,

    dataLanguageCodePrompt,
    dataLanguageNamePrompt,

    submit,
    editLabel,
    deleteLabel,
    manageLangSuccessMsg,
    manageLangFailureMsg,
    manageDataLanguages,
    updateGridRow,
    allFieldsAreRequired,
    // maxRecordsAllowed,

    modifiedBy,
    createdAt,
    updatedAt,

    manageLangAlertTitle,
    manageLangAlertMainContent,
    manageLangAlertSubContent,
    manageLangAlertQuestion,
    yesLabel,
    noLabel,

    filterInputPrompt,
    filterGridLabel,
    selectLanguage,
  } = manageLangRes;

  // const [languageCodeHdr, setLanguageCodeHdr] = useState(dataLanguageCodeLabel);

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(manageLang);
    // console.log("In ManageDataLanguages(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ManageDataLanguages(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "selectLanguageLabel")
              manageLang.selectLanguageLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectLanguagePrompt")
              manageLang.selectLanguagePrompt = item.ResourceDesc;

            if (item.ResourceCode === "dataLanguageCodeLabel")
              manageLang.dataLanguageCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "dataLanguageCodePrompt")
              manageLang.dataLanguageCodePrompt = item.ResourceDesc;

            if (item.ResourceCode === "dataLanguageNameLabel")
              manageLang.dataLanguageNameLabel = item.ResourceDesc;
            if (item.ResourceCode === "dataLanguageNamePrompt")
              manageLang.dataLanguageNamePrompt = item.ResourceDesc;

            if (item.ResourceCode === "submit")
              manageLang.submit = item.ResourceDesc;
            if (item.ResourceCode === "editLabel")
              manageLang.editLabel = item.ResourceDesc;
            if (item.ResourceCode === "deleteLabel")
              manageLang.deleteLabel = item.ResourceDesc;
            if (item.ResourceCode === "manageLangSuccessMsg")
              manageLang.manageLangSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "manageLangFailureMsg")
              manageLang.manageLangFailureMsg = item.ResourceDesc;
            if (item.ResourceCode === "manageDataLanguages")
              manageLang.manageDataLanguages = item.ResourceDesc;
            if (item.ResourceCode === "updateGridRow")
              manageLang.updateGridRow = item.ResourceDesc;
            if (item.ResourceCode === "allFieldsAreRequired")
              manageLang.allFieldsAreRequired = item.ResourceDesc;
            // if (item.ResourceCode === "maxRecordsAllowed")          manageLang.maxRecordsAllowed = item.ResourceDesc;
            if (item.ResourceCode === "modifiedBy")
              manageLang.modifiedBy = item.ResourceDesc;
            if (item.ResourceCode === "createdAt")
              manageLang.createdAt = item.ResourceDesc;
            if (item.ResourceCode === "updatedAt")
              manageLang.updatedAt = item.ResourceDesc;
            if (item.ResourceCode === "manageLangAlertTitle")
              manageLang.manageLangAlertTitle = item.ResourceDesc;
            if (item.ResourceCode === "manageLangAlertMainContent")
              manageLang.manageLangAlertMainContent = item.ResourceDesc;
            if (item.ResourceCode === "manageLangAlertSubContent")
              manageLang.manageLangAlertSubContent = item.ResourceDesc;
            if (item.ResourceCode === "manageLangAlertQuestion")
              manageLang.manageLangAlertQuestion = item.ResourceDesc;
            if (item.ResourceCode === "yesLabel")
              manageLang.yesLabel = item.ResourceDesc;
            if (item.ResourceCode === "noLabel")
              manageLang.noLabel = item.ResourceDesc;
            if (item.ResourceCode === "filterInputPrompt")
              manageLang.filterInputPrompt = item.ResourceDesc;
            if (item.ResourceCode === "filterGridLabel")
              manageLang.filterGridLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectLanguage")
              manageLang.selectLanguage = item.ResourceDesc;

            // For Grid
            if (item.ResourceCode === "gridPage")
              localeText.page = item.ResourceDesc;
            if (item.ResourceCode === "gridTo")
              localeText.to = item.ResourceDesc;
            if (item.ResourceCode === "gridOf")
              localeText.of = item.ResourceDesc;
            if (item.ResourceCode === "noRowsToShow")
              localeText.noRowsToShow = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, manageLang);
        }

        setManageLangRes(manageLang);
        setLocaleTextRes(localeText);

        setColumnDefs(columnDefsArray);

        setRenderGridFlag(true);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setManageLangRes(manageLang);
        setProgressFlag(false);
      });
  }

  //---------------------
  // AG-GRID Definitions
  //---------------------

  let columnDefsArray = [
    {
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressMenu: true,
      resizable: true,
    },
    {
      headerName: "ResourcePK",
      field: "ResourcePK",
      width: 130,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: "ResourceSK",
      field: "ResourceSK",
      width: 130,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: dataLanguageCodeLabel,
      field: "ResourceCode",
      width: 130,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: false,
    },
    {
      headerName: dataLanguageNameLabel,
      field: "LanguageName",
      width: 220,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: false,
    },
    {
      headerName: modifiedBy,
      field: "ModifiedBy",
      width: 150,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
    },
    {
      headerName: createdAt,
      field: "CreatedAt",
      width: 210,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
      filter: "agDateColumnFilter",
    },
    {
      headerName: updatedAt,
      field: "UpdatedAt",
      width: 210,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
      filter: "agDateColumnFilter",
    },
    {
      headerName: deleteLabel,
      field: "delete",
      width: 90,
      filter: false,
      sortable: false,
      resizable: true,
      cellRendererFramework: ActionsCellRenderer,
    },
    {
      headerName: editLabel,
      field: "edit",
      width: 90,
      filter: false,
      sortable: false,
      resizable: true,
      cellRendererFramework: ActionsCellRenderer,
    },
  ];

  const [columnDefs, setColumnDefs] = useState(columnDefsArray);

  // AG-Grid
  const defColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
  };
  const [defaultColDef, setDefaultColDef] = useState(defColDef);

  const [rowSelection, setRowSelection] = useState("multiple");

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);
    setSelectedLanguage(selectedOption);

    // setReferenceLanguage(selectedOption);
    setLanguageCode("");
    setLanguageName("");
    setLanguageCodeFocus(true);

    fetchGridData(selectedOption);
  }

  let selectOptions = [];

  function fetchLanguages() {
    // console.log('In fetchLanguages()');
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages(): response.length is: ', response.data.Items.length);
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            // label: item.LanguageName,
            text: item.LanguageName,
          };
          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  }

  async function fetchGridData(lang) {
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": lang,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchGridData: initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchGridData: response length is: ', response.data.Items.length);
        // console.log('In fetchGridData: response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.sort((a, b) =>
          a.LanguageName > b.LanguageName ? 1 : -1
        );
        setGridData(response.data.Items);
        // setRowData(response.data.Items);

        if (response.data.Items.length > 0) {
          setFilterDisabledFlag(false);
        }
        // console.log('In fetchGridData(): dataLanguageCodeLabel is: ', dataLanguageCodeLabel);
        // setGridDataLanguageCodeLabel(dataLanguageCodeLabel);
        // setColumnDefs(columnDefsArray);
        // if (gridApi !== null) {
        //   gridApi.redrawRows();
        // }

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log("In fetchGridData: err is: ", JSON.stringify(err, null, 2));
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    console.log("In useEffect(): gridApi is: ", gridApi);

    setLanguageCodeFocus(true);
    setLanguageNameFocus(false);

    setFilterDisabledFlag(true);

    // console.log('In useEffect(): dataLanguageCodeLabel is: ', dataLanguageCodeLabel);
    // setGridDataLanguageCodeLabel(dataLanguageCodeLabel);
    // setColumnDefs(columnDefsArray);

    if (selectedLanguage !== null) {
      fetchGridData(selectedLanguage);
    }
  }, []);

  useLayoutEffect(() => {
    // console.log("In ManageDataLanguages(): useLayoutEffect()");
    // console.log("In useLayoutEffect(): gridApi is: ", gridApi);

    if (selectOptions.length === 0) {
      fetchLanguages();
    }

    // Fetch resource from catche
    let cacheItemKey = "manageDataLang";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ManageDataLanguages(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ManageDataLanguages(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ManageDataLanguages(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ManageDataLanguages(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ManageDataLanguages(): useLayoutEffect(): got resource from cache');
      setManageLangRes(cacheItem);
    }
  }, []);

  // Check if user has signed in
  // console.log('In ManageDataLanguages(): appContext.signedIn is: ', appContext.signedIn);
  // if (appContext.signedIn !== true){
  //   console.log("In ManageDataLanguages(): About to redirect.");
  //   return <Redirect to='/main' />;
  // }

  function handleChange(event) {
    //console.log("In handleChange(): id is: "+event.target.id);

    setGlobalErrorFlag(false);
    setGridUpdatedFlag(false);

    switch (event.target.id) {
      case "languageCodeId":
        setLanguageCodeErrFlag(false);
        setLanguageCode(event.target.value);

        // Enable button
        if (
          event.target.value !== null &&
          event.target.value !== "" &&
          languageName !== null &&
          languageName !== ""
        ) {
          setGridUpdatedFlag(true);
        }
        break;
      case "languageNameId":
        setLanguageNameErrFlag(false);
        setLanguageName(event.target.value);

        // Enable button
        if (
          languageCode !== null &&
          languageCode !== "" &&
          event.target.value !== null &&
          event.target.value !== ""
        ) {
          setGridUpdatedFlag(true);
        }
        break;
      default:
        break;
    }
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function validateForm() {
    let formErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setLanguageCodeErrFlag(false);
    setLanguageNameErrFlag(false);

    if (languageCode === null || languageCode === "") {
      formErrFlag = true;
      setLanguageCodeErrFlag(true);
    }

    if (languageName === null || languageName === "") {
      formErrFlag = true;
      setLanguageNameErrFlag(true);
    }

    return formErrFlag;
  }

  async function languageService() {
    // console.log("In languageService(): modifiedGridRows are: ", JSON.stringify(modifiedGridRows, null, 2));
    // console.log("In languageService(): deletedGridRows are: ", JSON.stringify(deletedGridRows, null, 2));

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    if (modifiedGridRows.length > 0 || deletedGridRows.length > 0) {
      const rowsData = [];
      const deleteRowsData = [];

      // const d = new Date();
      const timestamp = new Date();
      // const timestamp = d.toISOString();
      // console.log("In languageService(): timestamp is: ", timestamp);

      // Handle Modified Grid Rows
      if (
        modifiedGridRows &&
        typeof modifiedGridRows !== "undefined" &&
        modifiedGridRows.length > 0
      ) {
        modifiedGridRows.map((data) => {
          // Process each node (row)
          let rowData = {
            ResourcePK: data.ResourcePK,
            ResourceSK: data.ResourceSK,
            // "ResourcePK": "cms-languages",
            // "ResourceSK": selectedLanguage + "#" + data.ResourceCode,

            LanguageName: data.LanguageName,
            ResourceCode: data.ResourceCode,

            ModifiedBy: appContext.userName,
            CreatedAt: data.CreatedAt,
            UpdatedAt: timestamp,
          };

          rowsData.push(rowData);
        });
      }

      // Handle Deleted Grid Rows
      if (
        deletedGridRows &&
        typeof deletedGridRows !== "undefined" &&
        deletedGridRows.length > 0
      ) {
        console.log("In deletedGridRows");
        deletedGridRows.map((data) => {
          // Process each node (row)
          let rowData = {
            ResourcePK: data.ResourcePK,
            ResourceSK: data.ResourceSK,
            // "ResourcePK": "cms-languages",
            // "ResourceSK": selectedLanguage + "#" + data.ResourceCode,
          };

          deleteRowsData.push(rowData);
        });
      }

      let params = {
        apiName: "ResourceAPI",
        path: "/dynamodb/resources/batchWrite",
        payload: {
          items: rowsData,
          deleteItems: deleteRowsData,
        },
      };

      let initObj = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        response: true, // return entire response object instead of response.data
        body: {},
      };

      initObj.headers.Authorization = appContext.jwtToken;
      initObj.body = params.payload;
      // console.log('In languageService(): initObj is: ', JSON.stringify(initObj, null, 2));

      setProgressFlag(true);

      API.post(params.apiName, params.path, initObj)
        .then((response) => {
          // console.log('In languageService(): response is: ', JSON.stringify(response, null, 2));

          // Clear the grid of any data
          clearGridData();

          setSelectedLanguage(null);

          // Clear Arrays
          setDeletedGridRows([]);
          setModifiedGridRows([]);

          // Show Snackbar
          setSnackbarsVariant("success");
          setSnackbarsMsg(manageLangSuccessMsg);
          setSnackbarOpen(true);

          setProgressFlag(false);
        })
        .catch((err) => {
          console.log(
            "In languageService(): err is: ",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setGlobalErrorMsg(manageLangFailureMsg);

          setProgressFlag(false);
        });
    }
  }

  //===================================================

  gridOptions.payload = payload;

  //------------------------
  // Grid Functions
  //------------------------

  const deleteRows = (e) => {
    //console.log("In deleteRows(): gridApi is: ", gridApi);
    //console.log("In deleteRows(): gridApi.getSelectedNodes() is: ", gridApi.getSelectedNodes());
    var selectedRows = gridApi.getSelectedRows();
    var res = gridApi.updateRowData({ remove: selectedRows });
    // console.log("In deleteRows(): res is: ", res);

    if (selectedRows && selectedRows.length > 0) {
      let tempArray = deletedGridRows;
      selectedRows.map((record) => {
        tempArray.push(record);
      });
      setDeletedGridRows(tempArray);
    }

    // Update number of rows in grid
    getRowCount();
    initializeForm();

    setGridSubmitFlag(true);

    // Disable Submit button if need be
    // let gridRowsCount = gridApi.getModel().getRowCount();
    // if (gridRowsCount === 0){
    //   setGridSubmitFlag(false);
    // }

    // if (gridApi && gridApi.getSelectedNodes().length > 0) {
    //   const selectedNodes = gridApi.getSelectedNodes();
    //   const selectedData = selectedNodes.map( node => node.data );
    //   const selectedDataStringPresentation = selectedData.map( node => node.make + ' ' + node.model).join(', ');
    //   console.log(`In deleteRows(): Selected nodes: ${selectedDataStringPresentation}`);
    //   setRowSelectedFlag(false);
    // }
  };

  function onGridReady(params) {
    // console.log("In onGridReady(): params.api is: ", params.api);
    // console.log("In onGridReady(): dataLanguageCodeLabel is: ", dataLanguageCodeLabel);
    // console.log("In onGridReady(): params.columnApi is: ", params.columnApi);
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
    // params.api.redrawRows();
  }

  const onCellClicked = (event) => {
    // console.log('onCellClicked: event is: ', event);
    // console.log('onCellClicked: event.colDef.field is: ', event.colDef.field);

    let gridRowsCount;

    switch (event.colDef.field) {
      case "delete":
        var selectedData = gridApi.getSelectedRows();
        // console.log('onCellClicked: selectedData is: ', selectedData);

        let filteredSelectedData = selectedData.filter(
          (data) =>
            data.ResourcePK === event.data.ResourcePK &&
            data.ResourceCode === event.data.ResourceCode
        );
        // console.log('onCellClicked: filteredSelectedData is: ', filteredSelectedData);

        var res = gridApi.updateRowData({ remove: filteredSelectedData });
        // console.log("In onCellClicked() res is: ", res);

        // Update DeletedGridRows State
        if (filteredSelectedData && filteredSelectedData.length > 0) {
          let tempArray = deletedGridRows;
          filteredSelectedData.map((record) => {
            tempArray.push(record);
          });
          setDeletedGridRows(tempArray);
        }

        // Update number of rows in grid
        getRowCount();
        initializeForm();

        setGridSubmitFlag(true);
        break;
      case "edit":
        if (event.data) {
          setCurrentEditRow(event.data);
          setEditRowIndex(event.rowIndex);
          setLanguageCode(event.data.ResourceCode);
          setLanguageName(event.data.LanguageName);
        }
        break;
      default:
        break;
    }
  };

  const onCellValueChanged = (event) => {
    // console.log('onCellValueChanged: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellValueChanged: event.colDef.field is: ', event.colDef.field);

    const tempArray = modifiedGridRows;
    // console.log('onCellValueChanged: tempArray is: ', JSON.stringify(tempArray, null, 2));

    // Remove any previous changes to the same record
    let filteredTempArray = tempArray.filter(
      (data) =>
        data.ResourcePK !== event.data.ResourcePK &&
        data.ResourceCode !== event.data.ResourceCode
    );
    // console.log('onCellValueChanged: filteredTempArray is: ', JSON.stringify(filteredTempArray, null, 2));
    filteredTempArray.push(event.data);
    setModifiedGridRows(filteredTempArray);
  };

  const onRowSelected = (event) => {
    //gridApi = event.api;
    //console.log('onRowSelected: event is: ', event);
    // console.log('onRowSelected: event.data is: ', JSON.stringify(event.data, null, 2));
    setRowSelectedFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getSelectedNodes().length > 0
    ) {
      setRowSelectedFlag(true);
      // console.log('onRowSelected: after rowSelectedFlag is: ', rowSelectedFlag);
    }
  };

  const onRowClicked = (event) => {
    // console.log('onRowClicked: Total number of rows is: ', gridApi.getDisplayedRowCount());
    // console.log('onRowClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onRowClicked: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onRowClicked: clicked row is: ', gridApi.getDisplayedRowAtIndex(event.rowIndex));
  };

  const onRowValueChanged = (event) => {
    // console.log('onRowValueChanged: event is:  ', event);
  };

  const onRowDataChanged = (event) => {
    setColumnDefs(columnDefsArray);
    setLocaleTextRes(localeText);

    //gridApi = event.api;
    // console.log('onRowDataChanged: event is: ', event);
    // setGridSubmitFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getModel().getRowCount() > 0
    ) {
      // console.log('onRowDataChanged: gridApi.getModel().getRowCount() is: ', gridApi.getModel().getRowCount());
      // setGridSubmitFlag(true);

      // Update display of number of rows in grid
      getRowCount();
    }
  };

  const onRowDataUpdated = (event) => {
    // console.log('onRowDataUpdated: event is:  ', event);
  };

  const onFirstDataRendered = (event) => {
    // console.log('onFirstDataRendered: event is:  ', event);
  };

  function clearGridData() {
    gridApi.setRowData([]);

    getRowCount();

    // Disable Submit button
    setGridSubmitFlag(false);
  }

  function initializeForm() {
    // console.log("In initializeForm()");

    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setLanguageCode("");
    setLanguageName("");

    gridApi.deselectAll();
  }

  function getRowCount() {
    let gridRowsCount = gridApi.getModel().getRowCount();
    setGridRowsCount(gridRowsCount);
    return gridRowsCount;
  }

  function updateModifiedGridRow() {
    // console.log("In updateModifiedGridRow(): languageCode is: ", languageCode);
    // console.log("In updateModifiedGridRow(): languageName is: ", languageName);
    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    if (!validateForm()) {
      // Validation Passed - you can proceed
      // console.log('Validation Passed.');
      setGridUpdatedFlag(false);
      setGridSubmitFlag(true);

      if (currentEditRow && currentEditRow !== null) {
        currentEditRow.LanguageName = languageName;

        // const d = new Date();
        // const timestamp = d.toISOString();
        // currentEditRow.UpdatedAt = timestamp;

        const res = gridApi.updateRowData({ update: [currentEditRow] });
        // console.log("In updateModifiedGridRow(): res is: ", res);

        // Remove any previous changes to the same record
        const tempArray = modifiedGridRows;
        // console.log('In updateModifiedGridRow(): tempArray is: ', JSON.stringify(tempArray, null, 2));

        tempArray.filter(
          (data) =>
            data.ResourcePK !== currentEditRow.ResourcePK &&
            data.ResourceCode !== currentEditRow.ResourceCode
        );
        // console.log('In updateModifiedGridRow(): tempArray before push is: ', JSON.stringify(tempArray, null, 2));
        tempArray.push(currentEditRow);
        setModifiedGridRows(tempArray);
        // console.log('In updateModifiedGridRow(): tempArray after push is: ', JSON.stringify(tempArray, null, 2));

        // Update number of rows in grid
        getRowCount();

        // Initialize Variables
        initializeForm();
      }
    }
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    // if (reason === 'clickaway') {
    //   setSignUpFlag(true);
    //   return;
    // }
    setSnackbarOpen(false);
  }

  function alertClickAgree() {
    // console.log("In alertClickAgree()");
    // Close Alert
    setAlertOpen(false);

    languageService();
  }

  function alertClickDisagree() {
    // console.log("In alertClickDisagree()");
    // Close Alert
    setAlertOpen(false);

    return false;
  }

  function alertClose() {
    // console.log("In alertClose()");
    // Close Alert
    setAlertOpen(false);
  }

  const gridComponents = {
    loadingRenderer: function (params) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<CircularProgress className={classes.progress} color="secondary" size={15}/>';
      }
    },
  };

  function handleGridFilterChange(event) {
    // console.log("In handleGridFilterChange(): value is: "+event.target.value);
    setGridFilterValue(event.target.value);
    gridApi.setQuickFilter(event.target.value);
  }

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { (!appContext.signedIn) &&
          <Redirect to='/signIn' />
        } */}

        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div
            id="myGrid"
            style={{ boxSizing: "border-box", width: "100%", height: "50%" }}
          >
            {/* progressFlag &&
              <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
              </div>
            */}
            <Container component="div" className={classes.main} maxWidth="md">
              <Grid container justify="center">
                <Grid item xs={12} sm={9}>
                  <StyledPaper elevation={3} className={classes.paper}>
                    <Typography
                      variant="h6"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      {manageDataLanguages}
                    </Typography>

                    <Typography
                      variant="caption"
                      style={{ color: "green", textAlign: "center" }}
                    >
                      {allFieldsAreRequired}
                    </Typography>

                    {globalErrorFlag && (
                      <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                    )}

                    <LineSpacing value={24} />

                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="formLanguageId"
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {selectLanguageLabel}
                        </Typography>
                      </label>
                      <Select
                        id="formLanguageId"
                        value={selectedLanguage}
                        placeholder={selectLanguagePrompt}
                        onChange={handleLanguageChange}
                        variant="outlined"
                        displayEmpty={true}
                        className={classes.selectEmpty}
                        autoFocus
                      >
                        <MenuItem value={null} disabled>
                          <Typography variant="body1">
                            {selectLanguage}
                          </Typography>
                        </MenuItem>
                        {langSelectOptions.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <label
                        htmlFor="languageCodeId"
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {dataLanguageCodeLabel}
                        </Typography>
                      </label>
                      <OutlinedInput
                        id="languageCodeId"
                        value={languageCode}
                        placeholder={dataLanguageCodePrompt}
                        onChange={handleChange}
                        required
                        fullWidth
                        // autoFocus
                        type={"text"}
                        error={languageCodeErrFlag}
                        margin="none"
                        readOnly
                      />
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <label
                        htmlFor="languageNameId"
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {dataLanguageNameLabel}
                        </Typography>
                      </label>
                      <OutlinedInput
                        id="languageNameId"
                        value={languageName}
                        placeholder={dataLanguageNamePrompt}
                        onChange={handleChange}
                        required
                        fullWidth
                        type={"text"}
                        error={languageNameErrFlag}
                        margin="none"
                      />
                    </FormControl>

                    <LineSpacing value={18} />

                    <Button
                      variant="contained"
                      size="large"
                      disabled={!gridUpdatedFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={updateModifiedGridRow}
                    >
                      <AddIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {updateGridRow}
                    </Button>
                  </StyledPaper>
                </Grid>
              </Grid>
            </Container>

            {/* AG-GRID */}

            {renderGridFlag && (
              <Container component="div" className={classes.main} maxWidth="lg">
                <Grid container justify="center">
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!rowSelectedFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={deleteRows}
                      style={directionFloat}
                    >
                      <DeleteIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {deleteLabel}
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container className={classes.filterGrid}>
                      <Grid
                        item
                        xs={2}
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <label htmlFor="filterTextId">
                          <Typography
                            variant="subtitle1"
                            className={classes.label}
                          >
                            {filterGridLabel}:{" "}
                          </Typography>
                        </label>
                      </Grid>

                      <Grid item xs={10}>
                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="filterTextId"
                            value={gridFilterValue}
                            placeholder={filterInputPrompt}
                            onChange={handleGridFilterChange}
                            fullWidth
                            type="search"
                            disabled={filterDisabledFlag}
                            startAdornment={
                              <InputAdornment position="start">
                                <Search color="primary" />
                              </InputAdornment>
                            }
                            margin="none"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!gridSubmitFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={languageService}
                      style={directionReverseFloat}
                    >
                      <SaveIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {submit}
                    </Button>
                  </Grid>
                </Grid>

                <div style={{ width: "100%", height: "100%" }}>
                  <div
                    id="refGrid"
                    style={{
                      // height: "400px",
                      width: "100%",
                    }}
                    className="ag-theme-balham"
                    key={columnDefs}
                  >
                    <AgGridReact
                      localeText={localeTextRes}
                      domLayout="autoHeight"
                      onGridReady={onGridReady}
                      gridOptions={gridOptions}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      rowData={gridData}
                      rowSelection={rowSelection}
                      rowMultiSelectWithClick={true}
                      rowDeselection={true}
                      suppressRowClickSelection={false}
                      // debug={true}
                      pagination={true}
                      components={gridComponents}
                      paginationPageSize={paginationPageSize}
                      cacheOverflowSize={2}
                      maxConcurrentDatasourceRequests={2}
                      infiniteInitialRowCount={1}
                      maxBlocksInCache={2}
                      enableRtl={enableRtl}
                      onCellClicked={onCellClicked}
                      onCellValueChanged={onCellValueChanged}
                      onRowSelected={onRowSelected}
                      onRowClicked={onRowClicked}
                      onRowValueChanged={onRowValueChanged}
                      onRowDataChanged={onRowDataChanged}
                      onRowDataUpdated={onRowDataUpdated}
                      onFirstDataRendered={onFirstDataRendered}
                    />
                  </div>
                </div>
              </Container>
            )}
          </div>

          {alertOpen && (
            <AlertDialog
              alertOpen={alertOpen}
              alertClose={alertClose}
              alertClickAgree={alertClickAgree}
              alertClickDisagree={alertClickDisagree}
              dialogTitle={manageLangAlertTitle}
              dialogQuestion={manageLangAlertQuestion}
              dialogMainContent={manageLangAlertMainContent}
              dialogSubContent={manageLangAlertSubContent}
              actionYesLabel={yesLabel}
              actionNoLabel={noLabel}
            />
          )}

          {/* variant: 'success', 'warning', 'error', 'info' */}
          <TsiCustomizedSnackbars
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={8000}
            onClose={snackbarOnClose}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">{snackbarsMsg}</span>}
            variant={snackbarsVariant}
          />
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter isSignedIn={isSignedIn} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default ManageDataLanguages;
