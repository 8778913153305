import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
// import { Redirect } from "react-router-dom";
import styled from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
// import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import clsx from "clsx";
// import Avatar from '@material-ui/core/Avatar';
import Button from "@material-ui/core/Button";
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import Checkbox from '@material-ui/core/Checkbox';
import Backdrop from '@material-ui/core/Backdrop';
import TranslateIcon from '@material-ui/icons/Translate';

// react-responsive
import { useMediaQuery } from 'react-responsive';

// Lodash
import lodash from "lodash";

// moment
import moment from "moment";

// Amplify
import { API, Storage } from "aws-amplify";
// import Amplify, { API } from 'aws-amplify';
// import AmplifyConfig from '../../config/AmplifyConfig';

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GenerateUniqueName from "../../utils/GenerateUniqueName";

// import FormatTimezoneDates from "../../utils/moment/FormatTimezoneDates";
// import FormatNumber from "../../utils/numberFormat/FormatNumber";
// import FormatNumberWithOption from "../../utils/numberFormat/FormatNumberWithOption";
// import FormatCurrency from "../../utils/numberFormat/FormatCurrency";

import LineSpacing from "../../components/misc/LineSpacing";
// import UploadFilesToS3 from "../../components/upload/UploadFilesToS3";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";

// AppContext
import AppContext from "../../config/AppContext";

// const StyledPaper = styled(Paper)`
//   min-width: 300px;
//   padding: 10px 20px 10px 20px !important;
//   text-align: center !important;
//   align-items: center;
// `;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100% !important",
        // display: "flex",
        // flexDirection: "column",
        // position: "relative",
        // background: 'red',
        // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
        textAlign: "center",
    },
    main: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
        background: '#fff',
    },
    progress: {
        margin: theme.spacing(2),
    },
    mainForm: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
        // background: '#fff',
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    footer: {
        bottom: 0,
        height: "1rem" /* Footer height */,
        width: "100% !important",
        textAlign: "center",
    },
    tabsRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        // width: '100%',
        // height: 224,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        // width: '100%',
    },
    tabPanel: {
        width: '100%',
    },
    large: {
        width: theme.spacing(14),
        height: theme.spacing(14),
    },
    hdr: {
        color: "#000", 
        textAlign: "center",
        marginTop: theme.spacing(2),
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    formControlLabel: {
        display: "flex",
        textAlign: "left",
        verticalAlign: "middle",
    },
    greenColor: {
        color: green[500],
    },
    divWrapper: {
        width: '100% !important',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    textField: {
        width: "100%",
    },
    leftPanelSubItems: {
        width: "100%",
        // marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    leftPanelItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
}));

let pageLabels = {
    commonConverterLabel: "Common converter",
    requiredFields: "* Required fields",

    sourceLanguageLabel: "Source language",
    sourceLanguagePrompt: "Select source language",
    sourceLanguageErrMsg: "Source language is required",

    targetLanguageLabel: "Target language",
    selectTargetLangPrompt: "Select target language",
    targetLanguageErrMsg: "Target language is required",

    domainLabel: "Domain",
    domainPrompt: "Select a domain",
    domainErrMsg: "Domain is required",

    priceHdrCommentLabel: "Price comment",
    priceHdrCommentPrompt: "Select the price comment to convert",
    priceHdrCommentErrMsg: "You must select a price comment",

    priceListItemLabel: "Price item",
    priceListItemPrompt: "Select the price item to convert",
    priceListItemErrMsg: "You must select a price item",

    converterLabel: "Convert",

    operationSuccessMsg: "Operation was successful.",
    operationFailureMsg: "Operation failed.",
    duplicateTransMsg: "Duplicate record",
    
    // groupingCategoryLabel, groupingCategoryPrompt and groupingCategoryErrMsg are place holders
    // Their values change depending on what label is passed to this component
    // groupingCategoryLabel: "Select grouping category",
    // groupingCategoryPrompt: "Select a grouping category",
    // groupingCategoryErrMsg: "Group category is required",

    // docsAllowedTypeMsg: "Only TXT, PDF, DOC, DOCX, PPT, PPTX, XLS, XLSX, or GZIP files are allowed.",
    // imageAllowedTypeMsg: "Only PNG and JPG (JPEG) photo or image files are allowed.",
    // audioAllowedMsg: "Only MP3, OGG and WAV files are allowed. Maximum file size allowed is 16MB.",
    // videoAllowedMsg: "Only MP4, WebM files are allowed. Maximum file size allowed is 16MB.",

    loadingText: "Loading ...",
    noOptionsText: "No options",
    clearText: "Clear",
    closeText: "Close",
};

function PricingConverter(props) {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);
    // const [ isSignedIn, setIsSignedIn ] = useState(appContext.signedIn);

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
    
    const [progressFlag, setProgressFlag] = useState(false);

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);
    const [renderDomFlag, setRenderDomFlag] = useState(false);

    // For Global Errors
    const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");

    // For Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarsMsg, setSnackbarsMsg] = useState("");
    const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'
    
    //---------------------
    // For Form Attributes
    //---------------------
    const [domain, setDomain] = useState(null);
    const [domainOptions, setDomainOptions] = useState([]);
    const [domainErrFlag, setDomainErrFlag] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    
    const [priceRecordBackup, setPriceRecordBackup] = useState([]);
    // const [priceHdrRecords, setPriceHdrRecords] = useState([]);

    const { 
        showHeadersFlag,
        referrer,
    } = props;

    //------------------
    // For AWS Translate
    //------------------
    const [terminologyNames, setTerminologyNames] = useState([
        "translate_custom_terminology.txt"
    ]);

    //---------------------
    // For Form Attributes
    //---------------------
    const [sourceLanguage, setSourceLanguage] = useState(null);
    const [sourceLanguageErrFlag, setSourceLanguageErrFlag] = useState(false);
    const [sourceLanguageOptions, setSourceLanguageOptions] = useState([]);

    const [targetLanguage, setTargetLanguage] = useState(null);
    const [targetLanguageErrFlag, setTargetLanguageErrFlag] = useState(false);
    const [targetLanguageOptions, setTargetLanguageOptions] = useState([]);
    // const [targetLanguageCategory, setTargetLanguageCategory] = useState(null);
    
    // const [groupingCategory, setGroupingCategory] = useState(null);
    // const [groupingCategoryErrFlag, setGroupingCategoryErrFlag] = useState(false);
    // const [groupingCategoryOptions, setGroupingCategoryOptions] = useState([]);
    // const [groupingCategoryRecords, setGroupingCategoryRecords] = useState([]);
    
    // const [readOnlyFlag, setReadOnlyFlag] = useState(false);
    const [submitButtonFlag, setSubmitButtonFlag] = useState(false);
    const [noRecordFoundFlag, setNoRecordFoundFlag] = useState(false);

    const [priceTranslateItem, setPriceTranslateItem] = useState(null);
    const [priceRecordOptions, setPriceRecordOptions] = useState([]);
    const [priceTranslateItemErrFlag, setPriceTranslateItemErrFlag] = useState(false);
           
    const [pageSize, setPageSize] = useState(10000);// AWS put PageSize to a max 0f 100
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    const classes = useStyles();

    const directionAlignment = {
        textAlign: direction === "ltr" ? "left" : "right",
    };

    const marginAlignment = {
        flex: "1",
        marginRight: appContext.direction === "ltr" ? "12px" : "0px",
        marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    };

    const { 
        commonConverterLabel,
        requiredFields,

        sourceLanguageLabel,
        sourceLanguagePrompt,
        sourceLanguageErrMsg,

        targetLanguageLabel,
        selectTargetLangPrompt,
        targetLanguageErrMsg,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,

        priceHdrCommentLabel,
        priceHdrCommentPrompt,
        priceHdrCommentErrMsg,

        priceListItemLabel,
        priceListItemPrompt,
        priceListItemErrMsg,
    
        converterLabel,

        // groupingCategoryLabel,
        // groupingCategoryPrompt,
        // groupingCategoryErrMsg,

        operationSuccessMsg,
        operationFailureMsg,
        duplicateTransMsg,

        // docsAllowedTypeMsg,
        // imageAllowedTypeMsg,
        // audioAllowedMsg,
        // videoAllowedMsg,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,
    } = pageLabelsRes;

    // const [categoryCommonLabel, setCategoryCommonLabel] = useState(groupingCategoryLabel);
    // const [categoryCommonPrompt, setCategoryCommonPrompt] = useState(groupingCategoryPrompt);
    // const [categoryCommonErrMsg, setCategoryCommonErrMsg] = useState(groupingCategoryErrMsg);

    async function fetchDbResources(cacheItemKey) {
        let keys = [];
        let objKeys = Object.keys(pageLabels);
        // console.log("In PricingConverter(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
        if (objKeys !== null && objKeys.length > 0) {
            objKeys.map((key) => {
                let partitionKey = appContext.currentLanguage.languageCode;
                partitionKey += ".";
                partitionKey += GenerateModulusHash(key, 200);

                let itemKey = {
                    ResourcePK: partitionKey, // e.g. 'en.91'
                    ResourceSK: key, // e.g. 'firstName'
                };
                keys.push(itemKey);
            });
        }
        // console.log("In PricingConverter(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

        let arrayData = [];

        setProgressFlag(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/batchGet",
            payload: {
                keys: keys,
                projectionExpression: "ResourceCode,ResourceDesc",
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
            response.data.Responses.resource.map((item, index) => {
                let record = {
                    ResourceCode: item.ResourceCode,
                    ResourceDesc: item.ResourceDesc,
                };

                arrayData.push(record);
            });

            if (arrayData && arrayData.length > 0) {
                arrayData.map((item) => {
                    if (item.ResourceCode === "commonConverterLabel") pageLabels.commonConverterLabel = item.ResourceDesc;
                    if (item.ResourceCode === "requiredFields") pageLabels.requiredFields = item.ResourceDesc;
                    
                    if (item.ResourceCode === "sourceLanguageLabel") pageLabels.sourceLanguageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "sourceLanguagePrompt") pageLabels.sourceLanguagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "sourceLanguageErrMsg") pageLabels.sourceLanguageErrMsg = item.ResourceDesc;
                            
                    if (item.ResourceCode === "targetLanguageLabel") pageLabels.targetLanguageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "selectTargetLangPrompt") pageLabels.selectTargetLangPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "targetLanguageErrMsg") pageLabels.targetLanguageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "domainLabel") pageLabels.domainLabel = item.ResourceDesc;
                    if (item.ResourceCode === "domainPrompt") pageLabels.domainPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "domainErrMsg") pageLabels.domainErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "priceHdrCommentLabel") pageLabels.priceHdrCommentLabel = item.ResourceDesc;
                    if (item.ResourceCode === "priceHdrCommentPrompt") pageLabels.priceHdrCommentPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "priceHdrCommentErrMsg") pageLabels.priceHdrCommentErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "priceListItemLabel") pageLabels.priceListItemLabel = item.ResourceDesc;
                    if (item.ResourceCode === "priceListItemPrompt") pageLabels.priceListItemPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "priceListItemErrMsg") pageLabels.priceListItemErrMsg = item.ResourceDesc;
                                
                    if (item.ResourceCode === "converterLabel") pageLabels.converterLabel = item.ResourceDesc;
                               
                    // if (item.ResourceCode === "groupingCategoryLabel") pageLabels.groupingCategoryLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "groupingCategoryPrompt") pageLabels.groupingCategoryPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "groupingCategoryErrMsg") pageLabels.groupingCategoryErrMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "operationSuccessMsg") pageLabels.operationSuccessMsg = item.ResourceDesc;
                    if (item.ResourceCode === "operationFailureMsg") pageLabels.operationFailureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "duplicateTransMsg") pageLabels.duplicateTransMsg = item.ResourceDesc;
                                                
                    // if (item.ResourceCode === "docsAllowedTypeMsg") pageLabels.docsAllowedTypeMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "imageAllowedTypeMsg") pageLabels.imageAllowedTypeMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "audioAllowedMsg") pageLabels.audioAllowedMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "videoAllowedMsg") pageLabels.videoAllowedMsg = item.ResourceDesc;
                            
                    if (item.ResourceCode === "loadingText") pageLabels.loadingText = item.ResourceDesc;
                    if (item.ResourceCode === "noOptionsText") pageLabels.noOptionsText = item.ResourceDesc;
                    if (item.ResourceCode === "clearText") pageLabels.clearText = item.ResourceDesc;
                    if (item.ResourceCode === "closeText") pageLabels.closeText = item.ResourceDesc;
                });
                // Add it to cache
                SetCacheItem(cacheItemKey, pageLabels);
            }

            // setRenderGridFlag(true);
            setProgressFlag(false);
            setPageLabelsRes(pageLabels);
            setRenderDomFlag(true);
        }).catch((err) => {
            console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
            setPageLabelsRes(pageLabels);
            setProgressFlag(false);
            setRenderDomFlag(true);
        });
    }

    let  refDataSelectOptions = [];
    
    async function fetchReferenceData(refType, lang) {
        return new Promise((resolve, reject) => {
            // console.log('In ManagePriceHeaders  fetchReferenceData(): refType is: ', refType);
            // console.log('In ManagePriceHeaders  fetchReferenceData(): lang is: ', lang);
            backdropProgress(true);

            refDataSelectOptions = [];

            let apiName = null;
            let path = null;
            let keyConditionExpression = null;
            let expressionAttributeNames = {};
            let expressionAttributeValues = {};
            
            switch(refType) {
                case "pricing-categories":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "pricing-categories",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                case "supported-domains":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "supported-domains",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                default:
                    break;
            }

            setProgressFlag(true);
        
            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    keyConditionExpression: keyConditionExpression,
                    expressionAttributeNames: expressionAttributeNames,
                    expressionAttributeValues: expressionAttributeValues,
                    // "keyConditionExpression": "#resourcePK = :resourcePK",
                    // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                    // "expressionAttributeValues": {":resourcePK": "languages"}
                },
            };
        
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
        
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In  fetchReferenceData(): initObj is: ', JSON.stringify(initObj, null, 2));
        
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In  fetchReferenceData(): response.length is: ', response.data.Items.length);
                // console.log('In  fetchReferenceData(): response is: ', JSON.stringify(response.data.Items, null, 2));
                response.data.Items.map((item) => {
                    let record = {
                        value: item.ResourceCode,
                        text: item.ResourceDesc,
                    };

                    if (record.value !== "all") {// Bypass all
                        refDataSelectOptions.push(record);
                    }
                });

                switch(refType) {
                    case "pricing-categories":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setCategoryOptions( refDataSelectOptions);
                        break;
                    case "supported-domains":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setDomainOptions(refDataSelectOptions);
                        break;
                    default:
                        break;
                }

                backdropProgress(false);
                resolve(refDataSelectOptions);
            }).catch((err) => {
                console.error("In ManagePriceHeaders  fetchReferenceData(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject(err);
            });
        });
    }
    
    let listNextToken = null;

    async function fetchPriceRecords(selectedDomain) {
        // console.log('In fetchPriceRecords(): selectedDomain is: ', selectedDomain);
        // console.log('In fetchPriceRecords(): sourceLanguage is: ', sourceLanguage);

        backdropProgress(true);
        setNoRecordFoundFlag(false);

        setPriceRecordOptions([]);
        setPriceRecordBackup([]);

        let keySuffice = "header";
        if (referrer === "ManagePriceList") {
            keySuffice = "list";
        }

        let params = {
            apiName: "PriceAPI",
            path: "/dynamodb/price/query",
            payload: {
                keyConditionExpression:
                    // "#pricePK = :pricePK",
                    "#pricePK = :pricePK AND begins_with(#priceSK, :priceSK)",
                expressionAttributeNames: {
                    "#pricePK": "PricePK",
                    "#priceSK": "PriceSK",
                },
                expressionAttributeValues: {
                    ":pricePK": selectedDomain.value + "#" + sourceLanguage.languageCode + "#price#" + keySuffice,
                    ":priceSK": selectedDomain.value + "#" + sourceLanguage.languageCode + "#price#" + keySuffice,
                },
                // indexName: "PriceDomain-LangAuthorUserName-index"
                    // "keyConditionExpression": "#pricePK = :pricePK",
                    // "expressionAttributeNames": {"#pricePK": "PricePK"},
                    // "expressionAttributeValues": {":pricePK": "languages"}
            },
        };

        if (!lodash.isEmpty(listNextToken)) {
            params.payload.nextToken = listNextToken;
        }
  
        if (!lodash.isEmpty(pageSize)) {
            params.payload.pageSize = pageSize;
        }

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
  
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchPriceRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
  
        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In fetchPriceRecords(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchPriceRecords(): response.data.NextToken is: ', response.data.NextToken);
            // console.log('In fetchPriceRecords(): response.data.Items is: ', response.data.Items);

            let records = [];
            let backupRecords = [];

            listNextToken = null;

            if (response && !lodash.isEmpty(response.data.NextToken)) {
                listNextToken = response.data.NextToken;
                records = priceRecordOptions;
            }
        
            let items = response.data.Items;

            if (items && items.length > 0) {
                setPriceRecordOptions([]);

                items.map((item) => {
                    let record = {};

                    switch(referrer) {
                        case "ManagePriceList":
                            record.value = item.PriceId;
                            record.text = item.PriceItem;

                            records.push(record);
                            backupRecords.push(item);
                            break;
                        case "ManagePriceHeaders":
                            record.value = item.PriceId;
                            record.text = item.PriceComments;

                            records.push(record);
                            backupRecords.push(item);
                            break;
                        default:
                            break;
                    }
                });
            } else {
                setNoRecordFoundFlag(true);
            }

            // console.log('In fetchPriceRecords(): listNextToken is: ', listNextToken);
            if (!lodash.isEmpty(listNextToken)) {
                await fetchPriceRecords();
            }
            
            setTimeout(() => {
                records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                setPriceRecordOptions(records);
                setPriceRecordBackup(backupRecords);
            }, 100);

            backdropProgress(false);
        }).catch((err) => {
          console.error("In fetchPriceRecords(): err is: ", JSON.stringify(err, null, 2));
          setPriceRecordOptions([]);
          setPriceRecordBackup([]);
          setNoRecordFoundFlag(true);
          backdropProgress(false);
        });
    }

    async function fetchLanguages() {
        // console.log("In fetchLanguages()");
        // setFilterDisabledFlag(true);

        //-------------------------------------------------------
        // Make sure languages is not in cache before proceeding
        //-------------------------------------------------------

        let cachedLanguages = GetCacheItem("languages");
        // console.log('In fetchLanguages(): cachedLanguages is: ', JSON.stringify(cachedLanguages, null, 2));
        cachedLanguages = null;
        if (cachedLanguages && cachedLanguages !== null && cachedLanguages.length > 0) {
            // console.log('In fetchLanguages(): cachedLanguages length is: ', cachedLanguages.length);
            setSourceLanguageOptions(cachedLanguages);
            return true;
        }

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/query",
            payload: {
                keyConditionExpression: "#resourcePK = :resourcePK",
                expressionAttributeNames: { "#resourcePK": "ResourcePK" },
                expressionAttributeValues: { ":resourcePK": "languages" },
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log("In fetchLanguages(): response length is: ", response.data.Items.length);
            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));

            // response.data.Items.sort((a, b) =>
            //     a.LanguageName > b.LanguageName ? 1 : -1
            // );

            let records = [];
            let items = response.data.Items;

            items.map(item => {
                let record ={
                    languageCode: item.ResourceCode,
                    languageName: item.LanguageName
                };
                records.push(record);
            });

            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
            setSourceLanguageOptions(records);

            // Add it to cache
            SetCacheItem("languages", response.data.Items);
            backdropProgress(false);
        }).catch((err) => {
            console.error("In fetchLanguages(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    };

    useEffect(() => {
        // console.log('In PricingConverter(): useEffect()');
    }, []);

    useLayoutEffect(() => {
        // console.log("In PricingConverter(): useLayoutEffect()");
        async function fetchItems() {
            await fetchLanguages();

            // Fetch resource from catche
            let cacheItemKey = "pricing-converter";
            cacheItemKey += ":";
            cacheItemKey += appContext.currentLanguage.languageCode;
            // console.log('In PricingConverter(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

            if (
                appContext.clearCacheLanguageCodes &&
                appContext.clearCacheLanguageCodes.length > 0 &&
                appContext.clearCacheLanguageCodes.includes(
                    appContext.currentLanguage.languageCode
                )
            ) {
                // console.log("In PricingConverter(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                RemoveCacheItem(cacheItemKey);
            }

            let cacheItem = GetCacheItem(cacheItemKey);
            // console.log('In PricingConverter(): useLayoutEffect(): cacheItem is: ', cacheItem);
            cacheItem = null; // remove after testing

            if (cacheItem === null) {
                // resource is not in cache, fetch from DB
                // console.log('In PricingConverter(): useLayoutEffect(): resource is not in cache, fetch from DB');
                await fetchDbResources(cacheItemKey);
            } else {
                // console.log('In PricingConverter(): useLayoutEffect(): got resource from cache');
                setPageLabelsRes(cacheItem);
                setRenderDomFlag(true);
            }
        }

        fetchItems();
    }, []);

    function removeSelectedSourceLang(selectedLang) {
        if (selectedLang && sourceLanguageOptions && sourceLanguageOptions.length > 0) {
            let filteredRecords = sourceLanguageOptions.filter(record => record.languageCode !== selectedLang.languageCode);
            setTargetLanguageOptions(filteredRecords);
        }
    }
    
    async function handleSourceLanguageChange(event, item) {
        // console.log("In handleSourceLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setSourceLanguageErrFlag(false);
        setSubmitButtonFlag(false);        
        setSourceLanguage(item);

        if (!lodash.isEmpty(item)) {
            removeSelectedSourceLang(item);
        }

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(targetLanguage) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(priceTranslateItem) 
        ) {
            setSubmitButtonFlag(true);
        }

        if (!lodash.isEmpty(item)) {
            Promise.all([
                await fetchReferenceData("supported-domains", item)
            ]).then(async (results) => {
                // console.log("In handleSourceLanguageChange(): results is: ", results);
            }).catch(error => {
                console.error("In handleSourceLanguageChange(): error is: ", error);
            });
        }
    }
    
    function handleTargetLanguageChange(event, item) {
        // console.log("In handleTargetLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setTargetLanguageErrFlag(false);
        setSubmitButtonFlag(false);        
        setTargetLanguage(item);

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(sourceLanguage) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(priceTranslateItem) 
        ) {
            setSubmitButtonFlag(true);
        }
    }

    async function handleListDomainChange(event, item) {
        // console.log("In handleListDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            await fetchPriceRecords(item);
        }

        setSubmitButtonFlag(false);

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(sourceLanguage) &&
            !lodash.isEmpty(targetLanguage) &&
            !lodash.isEmpty(priceTranslateItem) 
        ) {
            setSubmitButtonFlag(true);
        }
    }
    
    function handlePriceRecordChange(event, item) {
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setPriceTranslateItemErrFlag(false);
        
        setPriceTranslateItem(item);

        setSubmitButtonFlag(false);

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(sourceLanguage) &&
            !lodash.isEmpty(targetLanguage) &&
            !lodash.isEmpty(domain) 
        ) {
            setSubmitButtonFlag(true);
        }
    }
    
    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    function initializeForm() {
        // setTargetLanguage(null);
        setPriceTranslateItem(null);
        setSubmitButtonFlag(false);
    }

    function validateForm() {
        // console.log("In validateForm()");
        let formErrFlag = false;
  
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
    
        setSourceLanguageErrFlag(false);
        setTargetLanguageErrFlag(false);
        setDomainErrFlag(false);
        setPriceTranslateItemErrFlag(false);

        if (lodash.isEmpty(sourceLanguage)) {
            formErrFlag = true;
            setSourceLanguageErrFlag(true);
        }

        if (lodash.isEmpty(targetLanguage)) {
            formErrFlag = true;
            setTargetLanguageErrFlag(true);
        }
    
        if (lodash.isEmpty(domain)) {
            formErrFlag = true;
            setDomainErrFlag(true);
        }
    
        if (lodash.isEmpty(priceTranslateItem)) {
            formErrFlag = true;
            setPriceTranslateItemErrFlag(true);
        }
    
        if (formErrFlag) {
            setGlobalErrorFlag(true);
        }
        // console.log("In validateForm(): formErrFlag is: ", formErrFlag);
        return formErrFlag;
    }

    function translate(textToTranslate, sourceLanguageCode, targetLanguageCode, terminologyNames) {
        return new Promise((resolve, reject) => {
            backdropProgress(true);

            if (lodash.isEmpty(textToTranslate)) {
                resolve("");
                return;
            }

            let params = {
                apiName: "AwsTranslateAPI",
                path: "/aws/translate/translateText",
                payload: {
                    "textToTranslate": textToTranslate,
                    "sourceLanguageCode": sourceLanguageCode,
                    "targetLanguageCode": targetLanguageCode,
                    "terminologyNames": terminologyNames
                }
            };
            // console.log('In translate(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In translate(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In translate(): response is: ', JSON.stringify(response, null, 2));
                // console.log("In translate(): response.data.TranslatedText is: ", response.data.TranslatedText);

                resolve(response.data.TranslatedText);
                backdropProgress(false);
                return;
            }).catch((err) => {
                console.error("In translate(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                reject("Error during translation");
            });
        });
    };
    
    async function getTargetLanguageCategory(convertRecord) {
        // console.log("In getTargetLanguageCategory(): convertRecord.PriceCategory.value is: ", convertRecord.PriceCategory.value);
        // console.log("In getTargetLanguageCategory(): targetLanguage.languageCode is: ", targetLanguage.languageCode);
        
        return new Promise((resolve, reject) => {
            backdropProgress(true);
    
            let params = {
                apiName: "ResourceAPI",
                path: "/dynamodb/resources/get",
                payload: {
                    key: {
                        ResourcePK: "pricing-categories",
                        ResourceSK: targetLanguage.languageCode + ":" + convertRecord.PriceCategory.value,
                    },
                },
            };
    
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In getTargetLanguageCategory(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In getTargetLanguageCategory(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In getTargetLanguageCategory(): response.data.Item is: ', response.data.Item);

                const category = {
                    value: response.data.Item.ResourceCode,
                    text: response.data.Item.ResourceDesc
                }

                resolve(category);
                backdropProgress(false);
                return;
            }).catch((err) => {
                console.error("In getTargetLanguageCategory(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject("No target category found");
            });
        });
    }
    
    async function getTargetLanguagePriceUnit(convertRecord) {
        // console.log("In getTargetLanguagePriceUnit(): convertRecord.PriceUnit.value is: ", convertRecord.PriceUnit.value);
        // console.log("In getTargetLanguagePriceUnit(): targetLanguage.languageCode is: ", targetLanguage.languageCode);
        
        return new Promise((resolve, reject) => {
            if (lodash.isEmpty(convertRecord) || lodash.isEmpty(convertRecord.PriceUnit)) {
                resolve(null);
                return;
            }

            backdropProgress(true);
    
            let params = {
                apiName: "ResourceAPI",
                path: "/dynamodb/resources/get",
                payload: {
                    key: {
                        ResourcePK: "pricing-units",
                        ResourceSK: targetLanguage.languageCode + ":" + convertRecord.PriceUnit.value,
                    },
                },
            };
    
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In getTargetLanguagePriceUnit(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In getTargetLanguagePriceUnit(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In getTargetLanguagePriceUnit(): response.data.Item is: ', response.data.Item);

                const pricingUnit = {
                    value: response.data.Item.ResourceCode,
                    text: response.data.Item.ResourceDesc
                }

                resolve(pricingUnit);
                backdropProgress(false);
                return;
            }).catch((err) => {
                console.error("In getTargetLanguagePriceUnit(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject("No target pricing unit found");
            });
        });
    }
    
    async function getTargetLanguagePriceCurrency(convertRecord) {
        // console.log("In getTargetLanguagePriceCurrency(): convertRecord.PriceCurrency.value is: ", convertRecord.PriceCurrency.value);
        // console.log("In getTargetLanguagePriceCurrency(): targetLanguage.languageCode is: ", targetLanguage.languageCode);
        
        return new Promise((resolve, reject) => {
            if (lodash.isEmpty(convertRecord) || lodash.isEmpty(convertRecord.PriceCurrency)) {
                resolve(null);
                return;
            }

            backdropProgress(true);
    
            let params = {
                apiName: "ResourceAPI",
                path: "/dynamodb/resources/get",
                payload: {
                    key: {
                        ResourcePK: "stripe-curr",
                        ResourceSK: targetLanguage.languageCode + ":" + convertRecord.PriceCurrency.value,
                    },
                },
            };
    
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In getTargetLanguagePriceCurrency(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In getTargetLanguagePriceCurrency(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In getTargetLanguagePriceCurrency(): response.data.Item is: ', response.data.Item);

                const pricingCurrency = {
                    value: response.data.Item.ResourceCode,
                    text: response.data.Item.ResourceDesc
                }

                resolve(pricingCurrency);
                backdropProgress(false);
                return;
            }).catch((err) => {
                console.error("In getTargetLanguagePriceCurrency(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject("No target pricing unit found");
            });
        });
    }
        
    async function createService(convertRecord, translatedRefData, translatedItems) {
        // console.log("In createService(): convertRecord is: ", convertRecord);
        // console.log("In createService(): sourceLanguage is: ", sourceLanguage);
        // console.log("In createService(): targetLanguage is: ", targetLanguage);
        // console.log("In createService(): translatedRefData is: ", translatedRefData);
        // console.log("In createService(): translatedItems is: ", translatedItems);

        let translatedText1 = null;
        let translatedText2 = null;

        let targetLanguageCategory = null;
        let targetLanguagePriceUnit = null;
        let targetLanguagePriceCurrency = null;
        
        if (!lodash.isEmpty(translatedItems)) {
            if (!lodash.isEmpty(translatedItems.translatedText1)) {
                translatedText1 = translatedItems.translatedText1;
            }
            if (!lodash.isEmpty(translatedItems.translatedText2)) {
                translatedText2 = translatedItems.translatedText2;
            }
        }
        // console.log("In createService(): translatedText1 is: ", translatedText1);
        // console.log("In createService(): translatedText2 is: ", translatedText2);
        
        if (!lodash.isEmpty(translatedRefData)) {
            if (!lodash.isEmpty(translatedRefData.targetLanguageCategory)) {
                targetLanguageCategory = translatedRefData.targetLanguageCategory;
            }

            if (!lodash.isEmpty(translatedRefData.targetLanguagePriceUnit)) {
                targetLanguagePriceUnit = translatedRefData.targetLanguagePriceUnit;
            }
            
            if (!lodash.isEmpty(translatedRefData.targetLanguagePriceCurrency)) {
                targetLanguagePriceCurrency = translatedRefData.targetLanguagePriceCurrency;
            }
        }
    
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In createService(): Validation passed.");
            // console.log("In createService(): Validation passed.");

            backdropProgress(true);

            const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            const currentTime = Date.now();
            const timestamp = moment.tz(currentTime, currentTimeZone);           
            // console.log("In createService(): timestamp is: ", timestamp);

            let apiName = null;
            let path = null;
            let item = null;            
            let conditionExpression = null;
            let key = null;

            let uniqueId = GenerateUniqueName();
            
            switch(referrer) {
                case "ManagePriceList":
                    apiName = "PriceAPI";
                    path = "/dynamodb/price/put";
                    conditionExpression = "attribute_not_exists(UniqueAttribute)";

                    key = {
                        PricePK: convertRecord.Domain.value + "#" + targetLanguage.languageCode + "#price#list",
                        PriceSK: convertRecord.Domain.value + "#" + targetLanguage.languageCode + "#price#list#" + targetLanguageCategory.value + "#" + convertRecord.PriceId,
                    };

                    item = {
                        "PricePK": key.PricePK,
                        "PriceSK":  key.PriceSK,

                        "PriceId": convertRecord.PriceId,

                        "UniqueAttribute": lodash.isEmpty(key) ? null : key,
                        "LanguageObject": lodash.isEmpty(targetLanguage) ? null : targetLanguage,
                        "LanguageCode": lodash.isEmpty(targetLanguage) ? null : targetLanguage.languageCode,
                        "Domain": lodash.isEmpty(convertRecord.Domain) ? null : convertRecord.Domain,

                        "PriceDomain": lodash.isEmpty(convertRecord.Domain) ? null : convertRecord.Domain.value,
                        "PriceCategory": lodash.isEmpty(targetLanguageCategory) ? null : targetLanguageCategory,
                        "PriceCategoryCode": lodash.isEmpty(targetLanguageCategory) ? null : targetLanguageCategory.value,

                        "PriceItem": lodash.isEmpty(translatedText1) ? null : translatedText1,
                        "PriceDetails": lodash.isEmpty(translatedText2) ? null : translatedText2,

                        "PriceItemSeqNumber": convertRecord.PriceItemSeqNumber,

                        "DomainLangCategoryCode": convertRecord.Domain.value + "#" + targetLanguage.languageCode + "#" + targetLanguageCategory.value,// For GSI

                        "ModifiedBy": appContext.userName,
                        "CreatedAt": timestamp,
                        "UpdatedAt": timestamp,
                    };
                    break;
                case "ManagePriceHeaders":
                    apiName = "PriceAPI";
                    path = "/dynamodb/price/put";
                    conditionExpression = "attribute_not_exists(UniqueAttribute)";

                    key = {
                        PricePK: convertRecord.Domain.value + "#" + targetLanguage.languageCode + "#price#header",
                        PriceSK: convertRecord.Domain.value + "#" + targetLanguage.languageCode + "#price#header#" + targetLanguageCategory.value,
                    };

                    item = {
                        "PricePK": key.PricePK,
                        "PriceSK":  key.PriceSK,

                        "PriceId": uniqueId,

                        "UniqueAttribute": lodash.isEmpty(key) ? null : key,
                        "LanguageObject": lodash.isEmpty(targetLanguage) ? null : targetLanguage,
                        "LanguageCode": lodash.isEmpty(targetLanguage) ? null : targetLanguage.languageCode,
                        "Domain": lodash.isEmpty(convertRecord.Domain) ? null : convertRecord.Domain,

                        "PriceDomain": lodash.isEmpty(convertRecord.Domain) ? null : convertRecord.Domain.value,
                        "PriceUnit": lodash.isEmpty(targetLanguagePriceUnit) ? null : targetLanguagePriceUnit,
                        "PriceCurrency": lodash.isEmpty(targetLanguagePriceCurrency) ? null : targetLanguagePriceCurrency,
                        "PriceCategory": lodash.isEmpty(targetLanguageCategory) ? null : targetLanguageCategory,
                        "PriceCategoryCode": lodash.isEmpty(targetLanguageCategory) ? null : targetLanguageCategory.value,

                        "PriceComments": lodash.isEmpty(translatedText1) ? null : translatedText1,

                        "AmountPerMonth": convertRecord.AmountPerMonth,
                        "YearlyDiscount": convertRecord.YearlyDiscount,

                        "DomainLangCategoryCode": convertRecord.Domain.value + "#" + targetLanguage.languageCode + "#" + targetLanguageCategory.value,// For GSI

                        "ModifiedBy": appContext.userName,
                        "CreatedAt": timestamp,
                        "UpdatedAt": timestamp,
                    };
                    break;
                default:
                    break;
            }

            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    item: item,
                    conditionExpression: conditionExpression,
                    // updateExpression: updateExpression,
                    // expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In createService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In createService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In createService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar 
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);
            }).catch((err) => {
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                switch(err.code) {
                    case "ConditionalCheckFailedException":
                        // console.error("In createService(): Duplicate Error");
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        break;
                    default:
                        // console.error("In createService(): err is: ", JSON.stringify(err, null, 2));
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                    break;
                }
            });
        }
    }

    // Uses Promise.all
    async function converterService() {
        if (!validateForm()) {
            // console.log("In converterService(): Validation passed.");
            // console.log("In converterService(): sourceLanguage is: ", sourceLanguage);
            // console.log("In converterService(): targetLanguage is: ", targetLanguage);
            // console.log("In converterService(): domain is: ", domain);
            // console.log("In converterService(): priceHdrComment is: ", priceHdrComment);

            let recordToConvert = null;
            let text1 = null;
            let text2 = null;
            // let tags = null;
            // let translatedText1 = null;
            // let translatedText2 = null;
            // let translatedText3 = null;
            let translatedItems = {};
            let translatedRefData = {};

            if (priceRecordBackup && priceRecordBackup.length > 0) {
                recordToConvert = priceRecordBackup.filter(record => record.PriceId === priceTranslateItem.value);
                // console.log("In converterService(): recordToConvert is: ", recordToConvert);
                if (!lodash.isEmpty(recordToConvert)) {
                    switch(referrer) {
                        case "ManagePriceHeaders":
                            text1 = recordToConvert[0].PriceComments;
                            break;
                        case "ManagePriceList":
                            text1 = recordToConvert[0].PriceItem;
                            text2 = recordToConvert[0].PriceDetails;
                            break;
                        default:
                            break;
                    };

                    await Promise.all(
                        [
                            translate(text1, sourceLanguage.languageCode, targetLanguage.languageCode), // text, sourceLang, targetLang
                            // translate(content, sourceLanguage.languageCode, targetLanguage.languageCode), // text, sourceLang, targetLang
                            // translate(tags, sourceLanguage.languageCode, targetLanguage.languageCode), // text, sourceLang, targetLang
                            getTargetLanguageCategory(recordToConvert[0]),
                            getTargetLanguagePriceUnit(recordToConvert[0]),
                            getTargetLanguagePriceCurrency(recordToConvert[0])
                        ]).then(async (results) => {
                            // console.log('In converterService(): results is: ', JSON.stringify(results, null, 2));
                            
                            translatedItems.translatedText1 = results[0];
                            // console.log('In converterService(): translatedItems.translatedText1 is: ', translatedItems.translatedText1);

                            translatedRefData.targetLanguageCategory = results[1];
                            // console.log('In converterService(): targetLanguageCategory is: ', translatedRefData.targetLanguageCategory);

                            translatedRefData.targetLanguagePriceUnit = results[2];
                            // console.log('In converterService(): targetLanguagePriceUnit is: ', translatedRefData.targetLanguagePriceUnit);

                            translatedRefData.targetLanguagePriceCurrency = results[3];
                            // console.log('In converterService(): targetLanguagePriceCurrency is: ', translatedRefData.targetLanguagePriceCurrency);

                            switch(referrer) {
                                case "ManagePriceHeaders":
                                    // console.log('In converterService(): ManagePriceHeaders translatedItems is: ', translatedItems);
                                    if (!lodash.isEmpty(translatedRefData)) {
                                        await createService(recordToConvert[0], translatedRefData, translatedItems);
                                    }
                                    break;
                                case "ManagePriceList":
                                    await Promise.all([
                                        translate(text2, sourceLanguage.languageCode, targetLanguage.languageCode), // text, sourceLang, targetLang
                                    ]).then(async (results2) => {
                                        translatedItems.translatedText2 = results2[0];
                                        // console.log('In converterService(): ManagePriceList translatedItems is: ', translatedItems);

                                        if (!lodash.isEmpty(translatedRefData)) {
                                            await createService(recordToConvert[0], translatedRefData, translatedItems);
                                        }
                                    }).catch(error => {
                                        console.error("In converterService(): ManagePriceList error is: ", error);
                                        // Show Snackbar 
                                        setSnackbarsVariant("error");
                                        setSnackbarsMsg(operationFailureMsg);
                                        setSnackbarOpen(true);
                                        backdropProgress(false);
                                    });
                                    break;
                                default:
                                    break;
                            }
                        }).catch(error => {
                            console.error("In converterService(): error is: ", error);
                            // Show Snackbar 
                            setSnackbarsVariant("error");
                            setSnackbarsMsg(operationFailureMsg);
                            setSnackbarOpen(true);
                            backdropProgress(false);
                        });
                    
                }
                
            }
        }
    }
    
    function snackbarOnClose(event, reason) {
        setSnackbarOpen(false);
    }

    return (
        <div dir={direction} className={classes.divWrapper}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {renderDomFlag && 
                <div className={classes.root}>
                    {/* {!appContext.signedIn && <Redirect to="/signIn" />} */}

                    <CssBaseline />

                    {isDeskTopView &&
                        <Container component="div" className={classes.main} maxWidth="lg">
                            <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                                <Grid container className={classes.mainForm} justify="center" alignContent="center" alignItems="center" direction="row"> 
                                    <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                                        <Grid container item justify="center">
                                            {showHeadersFlag &&
                                                <>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant="h5" color="primary">
                                                            {commonConverterLabel}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant="caption" className={classes.greenColor}>
                                                            {requiredFields}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={4} />
                                                    </Grid>
                                                </>
                                            }
                                                
                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={12} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={sourceLanguageErrFlag}>
                                                            <label htmlFor="sourceLanguageId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={sourceLanguageErrFlag ? "secondary" : "inherit"}>
                                                                    *{sourceLanguageLabel}
                                                                </Typography>
                                                            </label>

                                                            <Autocomplete
                                                                id="sourceLanguageId"
                                                                value={sourceLanguage}
                                                                options={sourceLanguageOptions}
                                                                getOptionLabel={option => option.languageName}
                                                                getOptionSelected={option => option.languageName}
                                                                onChange={handleSourceLanguageChange}
                                                                loadingText={loadingText}
                                                                noOptionsText={noOptionsText}
                                                                autoHighlight={true}
                                                                clearText={clearText}
                                                                closeText={closeText}
                                                                disabled={false}
                                                                renderInput={params => 
                                                                    <TextField 
                                                                        {...params} 
                                                                        placeholder={sourceLanguagePrompt} 
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        error={sourceLanguageErrFlag}
                                                                        helperText={sourceLanguageErrFlag ? sourceLanguageErrMsg : ""}
                                                                        // InputProps={{
                                                                        //     readOnly: editModeFlag ? true : false,
                                                                        // }}
                                                                    />
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.rightPanelMainItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={targetLanguageErrFlag}>
                                                            <label htmlFor="targetLanguageId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={targetLanguageErrFlag ? "secondary" : "inherit"}>
                                                                    *{targetLanguageLabel}
                                                                </Typography>
                                                            </label>

                                                            <Autocomplete
                                                                id="targetLanguageId"
                                                                value={targetLanguage}
                                                                options={targetLanguageOptions}
                                                                getOptionLabel={option => option.languageName}
                                                                getOptionSelected={option => option.languageName}
                                                                onChange={handleTargetLanguageChange}
                                                                loadingText={loadingText}
                                                                noOptionsText={noOptionsText}
                                                                autoHighlight={true}
                                                                clearText={clearText}
                                                                closeText={closeText}
                                                                disabled={false}
                                                                renderInput={params => 
                                                                    <TextField 
                                                                        {...params} 
                                                                        placeholder={selectTargetLangPrompt} 
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        error={targetLanguageErrFlag}
                                                                        helperText={targetLanguageErrFlag ? targetLanguageErrMsg : ""}
                                                                        // InputProps={{
                                                                        //     readOnly: editModeFlag ? true : false,
                                                                        // }}
                                                                    />
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={12} />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                            <label htmlFor="domainId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                    *{domainLabel}
                                                                </Typography>
                                                            </label>

                                                            <Autocomplete
                                                                id="domainId"
                                                                value={domain}
                                                                options={domainOptions}
                                                                getOptionLabel={option => option.text}
                                                                getOptionSelected={option => option.text}
                                                                onChange={handleListDomainChange}
                                                                loadingText={loadingText}
                                                                noOptionsText={noOptionsText}
                                                                autoHighlight={true}
                                                                clearText={clearText}
                                                                closeText={closeText}
                                                                // disabled={editModeFlag ? true : false}
                                                                renderInput={params => 
                                                                    <TextField 
                                                                        {...params} 
                                                                        placeholder={domainPrompt} 
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        error={domainErrFlag}
                                                                        helperText={domainErrFlag ? domainErrMsg : ""}
                                                                        // InputProps={{
                                                                        //     readOnly: editModeFlag ? true : false,
                                                                        // }}
                                                                    />
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            
                                            <Grid item xs={12} sm={6}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.rightPanelMainItems)}>
                                                        <FormControl className={classes.textField} error={priceTranslateItemErrFlag}>
                                                            <label htmlFor="priceHdrCommentId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={priceTranslateItemErrFlag ? "secondary" : "inherit"}>
                                                                    *{referrer === "ManagePriceList" ? priceListItemLabel : priceHdrCommentLabel}
                                                                </Typography>
                                                            </label>

                                                            <Autocomplete
                                                                id="priceHdrCommentId"
                                                                value={priceTranslateItem}
                                                                options={priceRecordOptions}
                                                                getOptionLabel={option => option.text}
                                                                getOptionSelected={option => option.text}
                                                                onChange={handlePriceRecordChange}
                                                                loadingText={loadingText}
                                                                noOptionsText={noOptionsText}
                                                                autoHighlight={true}
                                                                clearText={clearText}
                                                                closeText={closeText}
                                                                renderInput={params => 
                                                                    <TextField 
                                                                        {...params} 
                                                                        placeholder={referrer === "ManagePriceList" ? priceListItemPrompt : priceHdrCommentPrompt} 
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        error={priceTranslateItemErrFlag}
                                                                        helperText={priceTranslateItemErrFlag ? (referrer === "ManagePriceList" ? priceListItemErrMsg : priceHdrCommentErrMsg) : ""}
                                                                    />
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={24} />
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={converterService}>
                                                        <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                        {converterLabel}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <LineSpacing value={24} />
                                </Grid>
                            </div>
                        </Container>
                    }

                    {isTabletView &&
                        <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                            <Grid container className={classes.mainForm} justify="center" alignContent="center" alignItems="center" direction="row"> 
                                <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                                    <Grid container item justify="center">
                                        {showHeadersFlag &&
                                            <>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="h5" color="primary">
                                                        {commonConverterLabel}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="caption" className={classes.greenColor}>
                                                        {requiredFields}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={4} />
                                                </Grid>
                                            </>
                                        }

                                        {/* <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelItems)}>
                                                    <FormControl className={classes.textField} error={groupingCategoryErrFlag}>
                                                        <label htmlFor="groupingCategoryId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={groupingCategoryErrFlag ? "secondary" : "inherit"}>
                                                                *{groupingCategoryLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="groupingCategoryId"
                                                            value={groupingCategory}
                                                            options={groupingCategoryOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleGroupingCategoryChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={groupingCategoryPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={groupingCategoryErrFlag}
                                                                    helperText={groupingCategoryErrFlag ? groupingCategoryErrMsg : ""}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid> 

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid> */}

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={sourceLanguageErrFlag}>
                                                        <label htmlFor="sourceLanguageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={sourceLanguageErrFlag ? "secondary" : "inherit"}>
                                                                *{sourceLanguageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="sourceLanguageId"
                                                            value={sourceLanguage}
                                                            options={sourceLanguageOptions}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleSourceLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={sourceLanguagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={sourceLanguageErrFlag}
                                                                    helperText={sourceLanguageErrFlag ? sourceLanguageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={targetLanguageErrFlag}>
                                                        <label htmlFor="targetLanguageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={targetLanguageErrFlag ? "secondary" : "inherit"}>
                                                                *{targetLanguageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="targetLanguageId"
                                                            value={targetLanguage}
                                                            options={targetLanguageOptions}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleTargetLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={selectTargetLangPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={targetLanguageErrFlag}
                                                                    helperText={targetLanguageErrFlag ? targetLanguageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                *{domainLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="domainId"
                                                            value={domain}
                                                            options={domainOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleListDomainChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            // disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={domainPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={domainErrFlag}
                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelMainItems)}>
                                                    <FormControl className={classes.textField} error={priceTranslateItemErrFlag}>
                                                        <label htmlFor="priceHdrCommentId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={priceTranslateItemErrFlag ? "secondary" : "inherit"}>
                                                                *{referrer === "ManagePriceList" ? priceListItemLabel : priceHdrCommentLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="priceHdrCommentId"
                                                            value={priceTranslateItem}
                                                            options={priceRecordOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handlePriceRecordChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={referrer === "ManagePriceList" ? priceListItemPrompt : priceHdrCommentPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={priceTranslateItemErrFlag}
                                                                    helperText={priceTranslateItemErrFlag ? (referrer === "ManagePriceList" ? priceListItemErrMsg : priceHdrCommentErrMsg) : ""}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={converterService}>
                                                    <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                    {converterLabel}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={24} />
                            </Grid>
                        </div>
                    }

                    {isMobileView &&
                        <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                            <Grid container className={classes.mainForm} justify="center" alignContent="center" alignItems="center" direction="row"> 
                                <div style={{ width: "100%", height: "100%", textAlign: 'center' }}>
                                    <Grid container item justify="center">
                                        {showHeadersFlag &&
                                            <>
                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="h5" color="primary">
                                                        {commonConverterLabel}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <Typography variant="caption" className={classes.greenColor}>
                                                        {requiredFields}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={4} />
                                                </Grid>
                                            </>
                                        }

                                        {/* <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelItems)}>
                                                    <FormControl className={classes.textField} error={groupingCategoryErrFlag}>
                                                        <label htmlFor="groupingCategoryId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={groupingCategoryErrFlag ? "secondary" : "inherit"}>
                                                                *{groupingCategoryLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="groupingCategoryId"
                                                            value={groupingCategory}
                                                            options={groupingCategoryOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleGroupingCategoryChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={groupingCategoryPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={groupingCategoryErrFlag}
                                                                    helperText={groupingCategoryErrFlag ? groupingCategoryErrMsg : ""}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid> */}

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={sourceLanguageErrFlag}>
                                                        <label htmlFor="sourceLanguageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={sourceLanguageErrFlag ? "secondary" : "inherit"}>
                                                                *{sourceLanguageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="sourceLanguageId"
                                                            value={sourceLanguage}
                                                            options={sourceLanguageOptions}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleSourceLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={sourceLanguagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={sourceLanguageErrFlag}
                                                                    helperText={sourceLanguageErrFlag ? sourceLanguageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={targetLanguageErrFlag}>
                                                        <label htmlFor="targetLanguageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={targetLanguageErrFlag ? "secondary" : "inherit"}>
                                                                *{targetLanguageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="targetLanguageId"
                                                            value={targetLanguage}
                                                            options={targetLanguageOptions}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleTargetLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={selectTargetLangPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={targetLanguageErrFlag}
                                                                    helperText={targetLanguageErrFlag ? targetLanguageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                *{domainLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="domainId"
                                                            value={domain}
                                                            options={domainOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleListDomainChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            // disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={domainPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={domainErrFlag}
                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={classes.textField} error={priceTranslateItemErrFlag}>
                                                        <label htmlFor="priceHdrCommentId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={priceTranslateItemErrFlag ? "secondary" : "inherit"}>
                                                                *{referrer === "ManagePriceList" ? priceListItemLabel : priceHdrCommentLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="priceHdrCommentId"
                                                            value={priceTranslateItem}
                                                            options={priceRecordOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handlePriceRecordChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={referrer === "ManagePriceList" ? priceListItemPrompt : priceHdrCommentPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={priceTranslateItemErrFlag}
                                                                    helperText={priceTranslateItemErrFlag ? (referrer === "ManagePriceList" ? priceListItemErrMsg : priceHdrCommentErrMsg) : ""}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={converterService}>
                                                    <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                    {converterLabel}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={24} />
                            </Grid>
                        </div>
                    }
                </div>
            }

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={8000}
                onClose={snackbarOnClose}
                ContentProps={{ "aria-describedby": "message-id" }}
                message={<span id="message-id">{snackbarsMsg}</span>}
                variant={snackbarsVariant}
            />
        </div>
    );
}

export default PricingConverter;
