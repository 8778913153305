import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import { NavLink, Redirect } from 'react-router-dom';
import Link from "@material-ui/core/Link";
// import Input from '@material-ui/core/Input';
// import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import MenuItem from '@material-ui/core/MenuItem';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from "@material-ui/core/Popover";
import PersonIcon from "@material-ui/icons/Person";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import green from "@material-ui/core/colors/green";
// import LanguageIcon from '@material-ui/icons/Language';
// import ReactTooltip from 'react-tooltip';
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LockIcon from "@material-ui/icons/Lock";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import LineStyleIcon from "@material-ui/icons/LineStyle"; // Use for Editor
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import BrandingWatermarkIcon from "@material-ui/icons/BrandingWatermark";
import PagesIcon from "@material-ui/icons/Pages";
import DashboardIcon from "@material-ui/icons/Dashboard";

import Amplify, { API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import SignIn from "../../pages/secure/SignIn";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import LineSpacing from "../../components/misc/LineSpacing";

Amplify.configure(AmplifyConfig);

const StyledSpan = styled.span`
  position: 'relative',
  cursor: pointer !important;
`;

const StyledTipButton = styled(Button)`
  padding: 0 0 0 0;
  margin: 0 70px 0 0 !important;
  vertical-align: middle !important;
  line-height: normal !important;
`;

const StyledButton = styled(Button)`
  vertical-align: middle !important;
  line-height: normal !important;
`;

const SelectSpan = styled.span`
  min-width: 50px !important;
`;

const StyledDiv = styled.div`
  min-width: 150px !important;
  margin: 8px 8px 8px 12px;
  cursor: pointer;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  avatar: {
    width: 27,
    height: 27,
    marginRight: 5,
    marginLeft: 5,
    color: "#fff",
    /*backgroundColor: green[500],*/
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  popover: {
    width: 200,
    maxHeight: 300,
    verticalAlign: "middle",
  },
  progress: {
    margin: theme.spacing(2),
  },
  menuColor: {
    color: "#000000",
  },
  linkSpacing: {
    minWidth: "200px !important",
    // marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  leftSpacing: {
    marginLeft: theme.spacing(1),
  },
  rightSpacing: {
    marginLeft: theme.spacing(1),
  },
}));

let usrProfile = {
  signInLabel: "Sign in",
  signOut: "Sign out",
  changePasswordLabel: "Change password",
  userDashboardLabel: "Your dashboard",
};

function UserProfile(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  // const [direction, setDirection] = useState("rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState(appContext.nickName);
  const [profileImage, setProfileImage] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);
  const [signInClickedFlag, setSignInClickedFlag] = useState(false);

  const [progressFlag, setProgressFlag] = useState(false);

  // For Resources
  const [usrProfileRes, setUsrProfileRes] = useState(usrProfile);

  const classes = useStyles();

  const {
    history,
    // currentLanguage,
    profilePopupId,
    profilePopupOpen,
    profileAnchorEl,
    handleProfileClicked,
    handleProfileClose,
    profileClicked,
    signedInCallback,
    currLanguage,
  } = props;

  const {
    signInLabel,
    signOut,
    changePasswordLabel,
    userDashboardLabel,
  } = usrProfileRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(usrProfile);
    // console.log("In UserProfile(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In UserProfile(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "signInLabel")
              usrProfile.signInLabel = item.ResourceDesc;
            if (item.ResourceCode === "signOut")
              usrProfile.signOut = item.ResourceDesc;
            if (item.ResourceCode === "changePasswordLabel")
              usrProfile.changePasswordLabel = item.ResourceDesc;
            if (item.ResourceCode === "userDashboardLabel")
              usrProfile.userDashboardLabel = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, usrProfile);
        }

        setUsrProfileRes(usrProfile);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setUsrProfileRes(usrProfile);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In UserProfile(): useEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));

    // currLanguage is the most current language passed to this component through props
    // We are using useEffect to get the functionality of componentDidUpdate()
    if (
      currLanguage !== undefined &&
      currLanguage !== null &&
      currLanguage !== currentLanguage
    ) {
      setCurrentLanguage(currLanguage);
      appContext.currentLanguage = currLanguage;

      // console.log('In UserProfile(): useEffect(): about to call fetchDbResources()');

      // Fetch resource from catche
      let cacheItemKey = "usrProfile";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      // cacheItemKey += currLanguage.languageCode;
      // console.log('In UserProfile(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        // console.log("In UserProfile(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
        RemoveCacheItem(cacheItemKey);
      }

      let cacheItem = GetCacheItem(cacheItemKey);
      // console.log('In UserProfile(): useLayoutEffect(): cacheItem is: ', cacheItem);

      if (cacheItem === null) {
        // resource is not in cache, fetch from DB
        // console.log('In UserProfile(): useLayoutEffect(): resource is not in cache, fetch from DB');
        fetchDbResources(cacheItemKey);
      } else {
        // console.log('In UserProfile(): useLayoutEffect(): got resource from cache');
        setUsrProfileRes(cacheItem);
      }
    }
  });

  useLayoutEffect(() => {
    // console.log("In UserProfile(): useLayoutEffect()");

    if (isSignedIn) {
      setProfileImage("https://material-ui.com/static/images/avatar/1.jpg");
    }

    // Fetch resource from catche
    let cacheItemKey = "usrProfile";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In UserProfile(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In UserProfile(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In UserProfile(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In UserProfile(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In UserProfile(): useLayoutEffect(): got resource from cache');
      setUsrProfileRes(cacheItem);
    }
  }, []);

  useLayoutEffect(() => {
    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In UserProfile(): useLayoutEffect(): currentLanguage is: ', currentLanguage);
    // console.log('In UserProfile(): useLayoutEffect(): appContext.signedIn is: ', appContext.signedIn);

    if (isSignedIn) {
      setProfileImage("https://material-ui.com/static/images/avatar/1.jpg");
    }
  }, []);

  return (
    <StyledSpan>
      {/* progressFlag &&
        <div style={{textAlign: 'center'}}>
          <CircularProgress className={classes.progress} color="secondary" size={15}/>
        </div>
      */}

      {!isSignedIn && (
        <Link component={RouterLink} to="/signIn">
          <StyledTipButton color="primary" size="medium">
            <Avatar className={classes.avatar}>
              <PersonIcon
                fontSize="default"
                style={{ textAlign: "middle !important" }}
              />
            </Avatar>
            {/* <SelectSpan>Sign in</SelectSpan> */}
            <Typography variant="caption" className={classes.menuColor}>
              {signInLabel}
            </Typography>
          </StyledTipButton>
        </Link>
      )}

      {isSignedIn && (
        <StyledTipButton
          aria-describedby={profilePopupId}
          color="primary"
          size="medium"
          onClick={handleProfileClicked}
        >
          {profileImage && (
            <Avatar
              alt={displayName}
              src={profileImage}
              className={classes.avatar}
            />
          )}
          {!profileImage && (
            <Avatar className={classes.avatar}>
              <PersonIcon
                fontSize="default"
                style={{ textAlign: "middle !important" }}
              />
            </Avatar>
          )}

          <SelectSpan>{displayName}</SelectSpan>
        </StyledTipButton>
      )}

      {signInClickedFlag && <SignIn currLanguage={currLanguage} />}

      <Popover
        id={profilePopupId}
        open={profilePopupOpen}
        anchorEl={profileAnchorEl}
        className={classes.popover}
        onClose={handleProfileClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <LineSpacing value={8} />

        <Link component={RouterLink} to="/menuNoSub2">
          <Grid
            container
            justify="flex-start"
            direction="row"
            className={classes.linkSpacing}
          >
            <Grid item xs={1}>
              <ViewCarouselIcon fontSize="small" />
            </Grid>

            <Typography
              variant="caption"
              color="inherit"
              display="block"
              className={classes.leftSpacing}
            >
              {"Carousel"}
            </Typography>
          </Grid>
        </Link>

        <LineSpacing value={4} />

        <Link component={RouterLink} to="/upload">
          <Grid
            container
            justify="flex-start"
            direction="row"
            className={classes.linkSpacing}
          >
            <Grid item xs={1}>
              <CloudUploadIcon fontSize="small" />
            </Grid>

            <Typography
              variant="caption"
              color="inherit"
              display="block"
              className={classes.leftSpacing}
            >
              {"Files Upload"}
            </Typography>
          </Grid>
        </Link>

        <LineSpacing value={4} />

        <Link component={RouterLink} to="/logos">
          <Grid
            container
            justify="flex-start"
            direction="row"
            className={classes.linkSpacing}
          >
            <Grid item xs={1}>
              <BrandingWatermarkIcon fontSize="small" />
            </Grid>

            <Typography
              variant="caption"
              color="inherit"
              display="block"
              className={classes.leftSpacing}
            >
              {"Test logos"}
            </Typography>
          </Grid>
        </Link>

        <LineSpacing value={4} />

        <Link component={RouterLink} to="/editor">
          <Grid
            container
            justify="flex-start"
            direction="row"
            className={classes.linkSpacing}
          >
            <Grid item xs={1}>
              <LineStyleIcon fontSize="small" />
            </Grid>

            <Typography
              variant="caption"
              color="inherit"
              display="block"
              className={classes.leftSpacing}
            >
              {"Editor"}
            </Typography>
          </Grid>
        </Link>

        <LineSpacing value={4} />

        <Link component={RouterLink} to="/main">
          <Grid
            container
            justify="flex-start"
            direction="row"
            className={classes.linkSpacing}
          >
            <Grid item xs={1}>
              <PagesIcon fontSize="small" />
            </Grid>

            <Typography
              variant="caption"
              color="inherit"
              display="block"
              className={classes.leftSpacing}
            >
              {"Main page"}
            </Typography>
          </Grid>
        </Link>

        <LineSpacing value={4} />

        <Link component={RouterLink} to="/userDashboard">
          <Grid
            container
            justify="flex-start"
            direction="row"
            className={classes.linkSpacing}
          >
            <Grid item xs={1}>
              <DashboardIcon fontSize="small" />
            </Grid>

            <Typography
              variant="caption"
              color="inherit"
              display="block"
              className={classes.leftSpacing}
            >
              {userDashboardLabel}
            </Typography>
          </Grid>
        </Link>

        <Link component={RouterLink} to="/changePassword">
          <Grid
            container
            justify="flex-start"
            direction="row"
            className={classes.linkSpacing}
          >
            <Grid item xs={1}>
              <LockIcon fontSize="small" />
            </Grid>

            <Typography
              variant="caption"
              color="inherit"
              display="block"
              className={classes.leftSpacing}
            >
              {changePasswordLabel}
            </Typography>
          </Grid>
        </Link>

        <LineSpacing value={4} />

        <Link component={RouterLink} to="/signOut">
          <Grid
            container
            justify="flex-start"
            direction="row"
            className={classes.linkSpacing}
          >
            <Grid item xs={1}>
              <ExitToAppIcon fontSize="small" />
            </Grid>

            <Typography
              variant="caption"
              color="inherit"
              display="block"
              className={classes.leftSpacing}
            >
              {signOut}
            </Typography>
          </Grid>
        </Link>

        <LineSpacing value={8} />
      </Popover>
    </StyledSpan>
  );
}

export default UserProfile;
