import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  hor: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "row",

    // display: block,
    marginTop: "0.5em",
    marginBottom: "0.5em",
    fontWeight: "lighter",
    borderWidth: "1px",
    // color: '#b7d0e2',
    border: "0 none",

    height: "1px",
    backgroundImage: "linear-gradient(left, #f0f0f0, #b7d0e2, #f0f0f0)", // #f0f0f0, #8c8c8c, #f0f0f0
  },
}));

export default function HorizontalRule() {
  // const { value } = props;
  const classes = useStyles();

  return <hr className={classes.hor} style={{ color: "red" }}></hr>;
}
