import Config from "../../config/Config";

export default {
  keyPrefix: Config.cache.wss.keyPrefix,
  capacityInBytes: Config.cache.wss.capacityInBytes,
  itemMaxSize: Config.cache.wss.itemMaxSize,
  defaultTTL: Config.cache.wss.defaultTTL,
  defaultPriority: Config.cache.wss.defaultPriority,
  warningThreshold: Config.cache.wss.warningThreshold,
  storage: Config.cache.wss.storage,
};
