import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

// AppContext
import AppContext from "../../config/AppContext";

Amplify.configure(AmplifyConfig);

const PageContainer = styled.div`
  width: 100%,
  display: 'flex',
  flexWrap: 'wrap',
  background: #fff;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
  },
  progress: {
    margin: theme.spacing(1),
  },
  mainGrid: {
    textAlign: "left",
  },
  services: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  hdr: {
    color: "#fff",
  },
  prodDesc: {
    color: "fff",
    fontSize: "12px",
    marginLeft: theme.spacing(1),
    whiteSpace: "nowrap",
  },
  link: {
    color: "#fff",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  textItem: {
    color: "#fff",
    whiteSpace: "nowrap",
  },
}));

let services = {
  serviceHdr: "Services",
  serviceDesc: "Our range of services",
  service1: "Service1",
  service2: "Service2",
  service3: "Service3",
  service4: "Service4",
  service5: "Service5",
  service6: "Service6",
  service7: "Service7",
  service8: "Service8",
  service9: "Service9",
  service10: "Service10",
};

function ServicesFooter(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  // For Services
  const [servicesRes, setServicesRes] = useState(services);

  const { currLanguage } = props;

  const classes = useStyles();

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  const {
    serviceHdr,
    serviceDesc,
    service1,
    service2,
    service3,
    service4,
    service5,
    service6,
    service7,
    service8,
    service9,
    service10,
  } = servicesRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(services);
    // console.log("In ServicesFooter(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ServicesFooter(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "serviceHdr")
              services.serviceHdr = item.ResourceDesc;
            if (item.ResourceCode === "serviceDesc")
              services.serviceDesc = item.ResourceDesc;
            if (item.ResourceCode === "service1")
              services.service1 = item.ResourceDesc;
            if (item.ResourceCode === "service2")
              services.service2 = item.ResourceDesc;
            if (item.ResourceCode === "service3")
              services.service3 = item.ResourceDesc;
            if (item.ResourceCode === "service4")
              services.service4 = item.ResourceDesc;
            if (item.ResourceCode === "service5")
              services.service5 = item.ResourceDesc;
            if (item.ResourceCode === "service6")
              services.service6 = item.ResourceDesc;
            if (item.ResourceCode === "service7")
              services.service7 = item.ResourceDesc;
            if (item.ResourceCode === "service8")
              services.service8 = item.ResourceDesc;
            if (item.ResourceCode === "service9")
              services.service9 = item.ResourceDesc;
            if (item.ResourceCode === "service10")
              services.service10 = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, services);
          setServicesRes(services);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setServicesRes(services);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In ServicesFooter(): useEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));

    // currLanguage is the most current language passed to this component through props
    // We are using useEffect to get the functionality of componentDidUpdate()
    if (
      currLanguage !== undefined &&
      currLanguage !== null &&
      currLanguage !== currentLanguage
    ) {
      setCurrentLanguage(currLanguage);
      appContext.currentLanguage = currLanguage;

      // console.log('In ServicesFooter(): useEffect(): about to call fetchDbResources()');

      // Fetch resource from catche
      let cacheItemKey = "services";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      // cacheItemKey += currLanguage.languageCode;
      // console.log('In ServicesFooter(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        // console.log("In ServicesFooter(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
        RemoveCacheItem(cacheItemKey);
      }

      let cacheItem = GetCacheItem(cacheItemKey);
      // console.log('In ServicesFooter(): useLayoutEffect(): cacheItem is: ', cacheItem);

      if (cacheItem === null) {
        // resource is not in cache, fetch from DB
        // console.log('In ServicesFooter(): useLayoutEffect(): resource is not in cache, fetch from DB');
        fetchDbResources(cacheItemKey);
      } else {
        // console.log('In ServicesFooter(): useLayoutEffect(): got resource from cache');
        setServicesRes(cacheItem);
      }
    }
  });

  useLayoutEffect(() => {
    // console.log("In ServicesFooter(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "services";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ServicesFooter(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ServicesFooter(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ServicesFooter(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ServicesFooter(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ServicesFooter(): useLayoutEffect(): got resource from cache');
      setServicesRes(cacheItem);
    }
  }, []);

  return (
    <div dir={direction}>
      <PageContainer className={classes.root}>
        <CssBaseline />

        <Container component="div" className={classes.services} maxWidth="lg">
          {progressFlag && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress
                className={classes.progress}
                color="secondary"
                size={15}
              />
            </div>
          )}
          <Grid container className={classes.mainGrid}>
            <Grid item xs={12} className={classes.hdr}>
              <Typography variant="button" gutterBottom>
                {serviceHdr}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.prodDesc}>
              <Typography variant="caption" gutterBottom>
                {serviceDesc}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/service1">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {service1}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/service2">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {service2}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/service3">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {service3}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/service4">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {service4}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/service5">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {service5}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/service6">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {service6}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/service7">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {service7}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/service8">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {service8}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/service9">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {service9}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/service10">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {service10}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </PageContainer>
    </div>
  );
}

export default ServicesFooter;
