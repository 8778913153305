import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
} from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";

import Amplify, { Auth } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import TsiWebcam from "../../components/misc/TsiWebcam";

Amplify.configure(AmplifyConfig);

const StyledButton = styled(Button)`
  background: transparent;
  border-radius: 3px;
  border: 2px solid palevioletred;
  color: palevioletred;
  /*margin-bottom: 12px !important;*/

  /* When the "primary" property is set we want to add some more css to our component */
  /* in this case change the background and color. */

  ${(props) =>
    props.color === "primary" &&
    css`
      background: green !important;
      color: white !important;
      /*margin: 0 20px 12px 0 !important;*/
    `};
`;

const Paragraph = styled.div`
  background: #fff;
  color: #4caf50;
  text-align: center;
  padding: 0 2rem 0 2rem;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "#fff",
  },
  container: {
    marginTop: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
  },
  mainForm: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

function CapturePhoto() {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const [imageData, setImageData] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  const classes = useStyles();

  const webcamRef = useRef(null);

  let capPhoto = {
    capturePhotoLabel: "Capture photo",
  };

  const { capturePhotoLabel } = capPhoto;

  useEffect(() => {
    console.log("In CapturePhoto():  useEffect()");
  }, []);

  useLayoutEffect(() => {
    console.log(
      "In CapturePhoto(): useLayoutEffect(): currentLanguage is: ",
      currentLanguage
    );
    console.log(
      "In CapturePhoto(): useLayoutEffect(): appContext.signedIn is: ",
      appContext.signedIn
    );
  }, []);

  // React Webcam constraints
  function capture() {
    console.log("In capture()");
    const imageSrc = webcamRef.current.getScreenshot();
    setImageData(imageSrc);
    //console.log('In capture(): imageSrc is: ', imageSrc);
  }

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
    //facingMode: { exact: "environment" },
  };

  return (
    <div dir={direction}>
      <div className={classes.root}>
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          {progressFlag && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress
                className={classes.progress}
                color="secondary"
                size={15}
              />
            </div>
          )}

          <Container component="div" className={classes.mainForm} maxWidth="md">
            <Grid container spacing={2} style={{ flexGrow: "1" }}>
              <Grid item xs>
                <Typography style={{ textAlign: "center" }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={capture}
                  >
                    {capturePhotoLabel}
                  </StyledButton>
                </Typography>

                <TsiWebcam
                  audio={false}
                  webcamRef={webcamRef}
                  videoConstraints={videoConstraints}
                  screenshotQuality={1}
                />
              </Grid>

              <Grid item xs>
                {/* React Webcam */}
                {imageData !== null && <img src={imageData} />}
              </Grid>
            </Grid>
          </Container>
        </Container>
      </div>
    </div>
  );
}

export default CapturePhoto;
