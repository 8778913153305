import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
// import { NavLink, Redirect } from 'react-router-dom';
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
// import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input"; // max, min, mobile
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

// Phone
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Amplify
import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import GlobalConstants from "../../utils/GlobalConstants";
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";
import LineSpacing from "../../components/misc/LineSpacing";
import CircularProgress from "@material-ui/core/CircularProgress";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 12px 0 12px 0 !important;
`;

const StyledButton = styled(Button)`
  background: transparent;
  border-radius: 3px;
  border: 2px solid palevioletred;
  color: palevioletred;
  margin-bottom: 12px !important;

  /* When the "primary" property is set we want to add some more css to our component */
  /* in this case change the background and color. */

  ${(props) =>
    props.color === "primary" &&
    css`
      background: green !important;
      color: white !important;
      margin: 0 20px 12px 0 !important;
    `};
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "#fff",
  },
  progress: {
    margin: theme.spacing(1),
  },
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  mainComp: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    backgroundColor: "#fff",
    textAlign: "center",
  },
  paper: {
    marginTop: theme.spacing(4),
  },
  phone: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "row",
  },
}));

let main = {
  verifyAttributeHdr: "Verify attribute",
  verifyAttributeSubHdr: "Verify the user attribute shown below.",
  // verifyAttributeLabel: "Verify attribute",
  // cancel: "Cancel",
  // attributeType: "Attribute type",
  emailAddress: "Email address",
  enterValidEmail: "Please enter a valid email address.",
  emailConstraintsNotMet: "Email constraints are not met.",
  // phoneNumberLabel: "Phone number",
  // enterValidPhone: "Please enter a valid phone number.",
  phoneConstraintsNotMet: "Phone constraints are not met.",
  invalidAttributeMsg: "You entered an invalid attribute.",
  limitTrialExceeded: "Attempt limit exceeded, please try later.",

  // changePassword: "Change password",
  // changeAccountPassword: "Complete form to change account password",
  // currentPassword: "Current password",
  // newPassword: "New password",
  // verifyNewPassword: "Verify new password",
  // verifyNewPasswordFailed: "Could not verify new password.",
  // invalidCredentials: "Invalid username and/or password.",
  verificationCodeEmail: "We have just sent a verification code to your email.",
  emailVerified: "Great, we have verified your email address.",
  phoneVerified: "Great, we have verified your phone number.",
  // emailVerifyingCode: "We have just sent you an email with a verifying code.",
  pleaseSignIn: "Please sign in.",
};

function Main(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  const [mainRes, setMainRes] = useState(main);

  const [user, setUser] = useState(null);
  const [attributeType, setAttributeType] = useState("phone");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumberNumber] = useState("");
  const [defaultPhoneCountry, setDefaulPhonetCountry] = useState("us");
  // const [ phone, setPhoneNumber ] = useState('');
  const [phoneCountry, setPhoneNumberCountry] = useState("US");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [verifyAttribute, setVerifyAttribute] = useState(false);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  const [progressFlag, setProgressFlag] = useState(false);

  // For Error Flags
  const [emailErrFlag, setEmailErrFlag] = useState(false);
  const [emailFocus, setEmailFocus] = useState(true);
  const [phoneErrFlag, setPhoneNumberErrFlag] = useState(false);

  // const { currentLanguage, location } = props;

  const classes = useStyles();

  const {
    verifyAttributeHdr,
    verifyAttributeSubHdr,
    // verifyAttributeLabel,
    // cancel,
    // attributeType,
    emailAddress,
    enterValidEmail,
    emailConstraintsNotMet,
    // phoneNumberLabel,
    // enterValidPhone,
    phoneConstraintsNotMet,
    invalidAttributeMsg,
    limitTrialExceeded,

    // changePassword,
    // changeAccountPassword,
    // currentPassword,
    // newPassword,
    // verifyNewPassword,
    // verifyNewPasswordFailed,
    // invalidCredentials,
    verificationCodeEmail,
    emailVerified,
    phoneVerified,
    // emailVerifyingCode,
    pleaseSignIn,
  } = mainRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(main);
    // console.log("In Main(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In Main(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
        // "queryType": "hash-query",
        // "resourcePK": currentLanguage.languageCode,
        // "keyConditionExpression": "#resourcePK = :resourcePK",
        // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
        // "expressionAttributeValues": {":resourcePK": currentLanguage.languageCode}
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            // id: ""+index,
            // ResourceLanguageCode: item.ResourceLanguageCode,
            // ResourcePK: item.ResourcePK,
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
            // ModifiedBy: item.ModifiedBy,
            // CreatedAt: item.CreatedAt,
            // UpdatedAt: item.UpdatedAt,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "verifyAttributeHdr")
              main.verifyAttributeHdr = item.ResourceDesc;
            if (item.ResourceCode === "verifyAttributeSubHdr")
              main.verifyAttributeSubHdr = item.ResourceDesc;
            if (item.ResourceCode === "emailAddress")
              main.emailAddress = item.ResourceDesc;
            if (item.ResourceCode === "enterValidEmail")
              main.enterValidEmail = item.ResourceDesc;
            if (item.ResourceCode === "emailConstraintsNotMet")
              main.emailConstraintsNotMet = item.ResourceDesc;
            if (item.ResourceCode === "phoneConstraintsNotMet")
              main.phoneConstraintsNotMet = item.ResourceDesc;
            if (item.ResourceCode === "invalidAttributeMsg")
              main.invalidAttributeMsg = item.ResourceDesc;
            if (item.ResourceCode === "limitTrialExceeded")
              main.limitTrialExceeded = item.ResourceDesc;
            if (item.ResourceCode === "verificationCodeEmail")
              main.verificationCodeEmail = item.ResourceDesc;
            if (item.ResourceCode === "emailVerified")
              main.emailVerified = item.ResourceDesc;
            if (item.ResourceCode === "phoneVerified")
              main.phoneVerified = item.ResourceDesc;
            if (item.ResourceCode === "pleaseSignIn")
              main.pleaseSignIn = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, main);
          setMainRes(main);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setMainRes(main);
        setProgressFlag(false);
      });
  }

  useEffect(() => {}, []);

  useLayoutEffect(() => {
    // console.log("In Main(): useLayoutEffect()");

    setAttributeType("phone");
    setEmailFocus(true);

    if (attributeType === "phone") {
      setPhoneNumberCountry("US");
    }

    // Fetch resource from catche
    let cacheItemKey = "main";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In Main(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In Main(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In Main(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In Main(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In Main(): useLayoutEffect(): got resource from cache');
      setMainRes(cacheItem);
    }
  }, []);

  function handleChange(event) {
    switch (event.target.id) {
      case "emailId":
        setEmailErrFlag(false);
        setEmail(event.target.value);
        break;
      // case "phoneId":
      //   setPhoneNumberErrFlag(false);
      //   setPhoneNumber(event.target.value);
      //   break;
      default:
        break;
    }
  }

  function validateForm() {
    // console.log('In validateForm(): attributeType is: ', attributeType);

    let formErrFlag = false;
    let emailConstErrFlag = false;
    let phoneConstErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setEmailErrFlag(false);
    setPhoneNumberErrFlag(false);

    if (attributeType === "email") {
      if (email === null || email === "") {
        formErrFlag = true;
        setEmailErrFlag(true);
      } else if (!email.match(GlobalConstants.emailRegex)) {
        emailConstErrFlag = true;
        formErrFlag = true;
        setEmailErrFlag(true);
      }

      // Check for Constraints Error
      if (emailConstErrFlag) {
        setGlobalErrorFlag(true);
        setGlobalErrorMsg(emailConstraintsNotMet);
      }
    }

    if (attributeType === "phone") {
      let phoneRegex = GlobalConstants.phoneNumRegexInter;
      if (isValidPhoneNumber(phoneNumber)) {
        // '+12133734253'
        //let formattedPhone = formatPhoneNumber(phoneNumber);// '(213) 373-4253' - National format
        let formattedPhone = formatPhoneNumberIntl(phoneNumber); // '+1 213 373 4253' - International format
        let phoneArray = formattedPhone.split(" ");

        if (phoneArray[0] === "+1") {
          phoneRegex = GlobalConstants.phoneNumRegexUS;
        }
      }
      // console.log("In validateForm(): phoneRegex is: ", phoneRegex);

      // console.log("In validateForm(): isValidPhoneNumber(phoneNumber) is: ", isValidPhoneNumber(phoneNumber));
      if (
        !isValidPhoneNumber(phoneNumber) ||
        phoneNumber === null ||
        phoneNumber === ""
      ) {
        formErrFlag = true;
        setPhoneNumberErrFlag(true);
        phoneConstErrFlag = true;
      } else if (!phoneNumber.match(phoneRegex)) {
        phoneConstErrFlag = true;
        formErrFlag = true;
        setPhoneNumberErrFlag(true);
      }

      // Check for Constraints Error
      // console.log("In validateForm(): phoneConstErrFlag is: ", phoneConstErrFlag);
      if (phoneConstErrFlag) {
        setGlobalErrorFlag(true);
        setGlobalErrorMsg(phoneConstraintsNotMet);
      }
    }

    return formErrFlag;
  }

  async function verifyUserAttributeService() {
    // console.log('In verifyUserAttributeService(): attributeType is: ', attributeType);

    let attribute = "email";

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");
    setVerifyAttribute(false);

    switch (attributeType) {
      case "email":
        attribute = email;
        break;
      case "phone":
        attribute = phoneNumber;
        break;
      default:
        attribute = phoneNumber;
        break;
    }

    if (!validateForm()) {
      // Validation Passed - you can proceed
      // console.log("Validation Passed.");
      setProgressFlag(true);

      Auth.verifyCurrentUserAttribute(attribute)
        .then((result) => {
          setSnackbarOpen(true);
          switch (attributeType) {
            case "email":
              console.log(emailVerified);
              break;
            case "phone":
              console.log(phoneVerified);
              break;
            default:
              console.log(phoneVerified);
              break;
          }
          setProgressFlag(false);
        })
        .catch((err) => {
          console.log(
            "In verifyUserAttributeService(): err is",
            JSON.stringify(err, null, 2)
          );
          setProgressFlag(false);
          setGlobalErrorFlag(true);
          if (err && err.code) {
            switch (err.code) {
              case "InvalidParameterException":
                setGlobalErrorMsg(invalidAttributeMsg);
                break;
              case "LimitExceededException":
                setGlobalErrorMsg(limitTrialExceeded);
                break;
              default:
                if (err === "No current user") {
                  setGlobalErrorMsg(pleaseSignIn);
                } else {
                  setGlobalErrorMsg(invalidAttributeMsg);
                }
                break;
            }
          } else {
            if (err === "No current user") {
              setGlobalErrorMsg(pleaseSignIn);
            } else {
              setGlobalErrorMsg(invalidAttributeMsg);
            }
          }
        });
    }
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    if (reason === "clickaway") {
      setVerifyAttribute(true);
      return;
    }
    setSnackbarOpen(false);
    setVerifyAttribute(true);
  }

  // Phone
  const iso2FlagEmoji = (iso) =>
    String.fromCodePoint(
      ...[...iso.toUpperCase()].map((char) => char.charCodeAt(0) + 127397)
    );

  function handlePhoneNumberChange(phoneNum) {
    // console.log("In handlePhoneNumberChange(): phoneNum is: ", phoneNum);
    setPhoneNumberNumber(phoneNum);
  }

  return (
    <div className={classes.root} dir={direction}>
      <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />

      <Container component="div" className={classes.main} maxWidth="lg">
        {progressFlag && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress
              className={classes.progress}
              color="secondary"
              size={15}
            />
          </div>
        )}

        {verifyAttribute && <Main />}

        {!verifyAttribute && (
          <Grid container justify="center">
            <StyledPaper elevation={3} className={classes.paper}>
              <Typography
                variant="h6"
                style={{ color: "black", textAlign: "center" }}
              >
                {verifyAttributeHdr}
              </Typography>

              <Typography
                variant="caption"
                style={{ color: "green", textAlign: "center" }}
              >
                {verifyAttributeSubHdr}
              </Typography>

              {globalErrorFlag && (
                <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
              )}

              <LineSpacing value={16} />

              {attributeType === "email" && (
                <StyledFormControl fullWidth error={emailErrFlag}>
                  <Input
                    id="emailId"
                    disableUnderline={false}
                    placeholder={emailAddress}
                    type="text"
                    defaultValue={email}
                    autoFocus={emailFocus}
                    error={emailErrFlag}
                    onChange={handleChange}
                  />
                  {emailErrFlag && (
                    <FormHelperText id="component-error-text">
                      {enterValidEmail}
                    </FormHelperText>
                  )}
                </StyledFormControl>
              )}

              {attributeType === "phone" && (
                <div style={{ flexGrow: 1, width: "100%", textAlign: "left" }}>
                  {/* Force 'ltr' in all situations  - component does not render well for 'rtl' */}
                  <div dir={"ltr"}>
                    <ReactPhoneInput
                      defaultCountry={defaultPhoneCountry}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      inputClass={classes.phone}
                      localization={{ Germany: "Deutschland", Spain: "España" }}
                    />
                  </div>
                </div>
              )}

              <LineSpacing value={12} />

              <Typography style={{ textAlign: "center", marginTop: "12px" }}>
                <StyledButton
                  color="primary"
                  onClick={verifyUserAttributeService}
                >
                  {verifyAttributeHdr}
                </StyledButton>
                {/* <StyledButton color="secondary" component={ RouterLink } to="/">{cancel}</StyledButton> */}
              </Typography>

              {/* <Typography variant="caption" style={{textAlign: 'center', marginTop: '12px'}}>
                <Link color="primary" component={ RouterLink } to="/changePassword">{changePassword}</Link>
              </Typography> */}
            </StyledPaper>
          </Grid>
        )}

        <TsiCustomizedSnackbars
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackbarOpen}
          autoHideDuration={8000}
          onClose={snackbarOnClose}
          ContentProps={{ "aria-describedby": "message-id" }}
          message={<span id="message-id">{verificationCodeEmail}</span>}
          variant="success"
        />
      </Container>

      <footer className={classes.footer}>
        <div>
          <PageFooter
            currentLanguage={currentLanguage}
            isSignedIn={isSignedIn}
          />
        </div>
      </footer>
    </div>
  );
}

export default Main;
