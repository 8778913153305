import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import LinearProgress from "@material-ui/core/LinearProgress";
import DoneIcon from '@material-ui/icons/Done';

// For upload
import { DropzoneArea } from "material-ui-dropzone";
import { DropzoneAreaBase } from "material-ui-dropzone";

// Lodash
import lodash from "lodash";

// moment
import moment from "moment";

// Toast Image Editor
import "tui-image-editor/dist/tui-image-editor.css";
// import ImageEditor from "@toast-ui/react-image-editor";

import Resizer from 'react-image-file-resizer';

// Amplify
import { API, Storage } from 'aws-amplify';
// import Amplify, { Auth, API, Storage } from "aws-amplify";
// import AmplifyConfig from '../../config/AmplifyConfig';
// import Config from "../../config/Config";

// AppContext
import AppContext from "../../config/AppContext";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GenerateUniqueName from "../../utils/GenerateUniqueName";

const pageLabels = {
    uploadPageHdr: "Upload your files",
    uploadingLabel: "Uploading",
    uploadFilesLabel: "Upload files",
    uploadComplete: "Upload is complete",
    totalNumberFiles: "Total number of files",
    previewText: "Preview",

    dialogTitle: "Upload files",
    dropzoneText: "Drag & drop files or browse",
    cancel: "Cancel",
    submit: "Submit",

    uploadBePatientMsg: "We are optimizing your uploaded files for use with different devices.",

    operationSuccessMsg: "Operation was successful.",
    operationFailureMsg: "Operation failed.",
    duplicateTransMsg: "Duplicate record",
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100% !important",
        textAlign: "center",
        // padding: theme.spacing(1),
    },
    verticalAlignLabel: {
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    gridItem: {

    },
    uploadComplete: {
        color: "green",
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    hdrLabel: {
        marginBottom: theme.spacing(2),
        color: "#000",
    },
    dropZone: {
        width: "100% !important",
        paddingTop: theme.spacing(2),
    },

    dropzoneParagraph: {
        marginTop: theme.spacing(2),
        fontWeight: "bold",
        fontSize: "12px !important",
        width: "100% !important",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function DomainUploadToS3(props) {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);

    // For Global Errors
    const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");

    const [filesToUpload, setFilesToUpload] = useState([]);
    const [uploadedFilesCnt, setUploadedFilesCnt] = useState(0);
    const [openFlag, setOpenFlag] = useState(false); // Required. Sets whether the dialog is open or closed
//   const [filesLimit, setFilesLimit] = useState(1000); // Maximum number of files that can be loaded into the dropzone
    const [showPreviews, setShowPreviews] = useState(false); // Shows previews BELOW the Dropzone
    const [showPreviewsInDropzone, setShowPreviewsInDropzone] = useState(appContext.showPreviewsInDropzoneFlag); // Shows preview INSIDE the dropzone
    const [showAlerts, setShowAlerts] = useState(false); // shows styled snackbar alerts when files are dropped, deleted
    const [maxWidth, setMaxWidth] = useState(false); // Sets dialog width. Width grows with the size of the screen.
    const [fullWidth, setFullWidth] = useState(false); // If true, the dialog stretches to maxWidth.
    const [maxFileSize, setMaxFileSize] = useState(20971520);// 6MB=6291456  15MB=15728640 20MB=20971520
    const [currentFileName, setCurrentFileName] = useState(""); // File currently being uploaded
    const [progress, setProgress] = useState(null);
//   const [showFileNamesInPreview, setShowFileNamesInPreview] = useState(true); // Shows file name under the image
//   const [showFileNames, setShowFileNames] = useState(true); // Shows file name under the dropzone image.
    const [showProgressFlag, setShowProgressFlag] = useState(false);
    const [uploadCompleteFlag, setUploadCompleteFlag] = useState(false);

  // For AWS Amplify Storage
//   const [storageLevel, setStorageLevel] = useState("public"); // public, protected or private
  // const [domain, setDomain] = useState(appContext.domain); // cob
  // const [userName, setUserName] = useState(appContext.userName); // "onyii5119@gmail.com"
    const [customPrefix, setCustomPrefix] = useState(null); //
    const [contentType, setContentType] = useState("text/plain");
    const [cacheControl, setCacheControl] = useState("");
    const [contentDisposition, setContentDisposition] = useState("");
    const [expires, setExpires] = useState(null);
    const [metadata, setMetadata] = useState(null);

    const [completed, setCompleted] = React.useState(0);
    // const [buffer, setBuffer] = React.useState(filesToUpload.length);
    const [buffer, setBuffer] = React.useState(10);

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);

    const [renderDomFlag, setRenderDomFlag] = useState(false);

    const [selectedImage, setSelectedImage] = useState(null);
    // const [editImageFlag, setEditImageFlag] = useState(false);

    const [profileImgFlag, setProfileImgFlag] = useState(false);
    const [pageSpecificPhotoFlag, setPageSpecificPhotoFlag] = useState(false);
    // const [originalImgWidth, setOriginalImgWidth] = useState(null);
    // const [originalImgHeight, setOriginalImgHeight] = useState(null);

    const [originalFileType, setOriginalFileType] = useState(null);
    // const [originalFileSize, setOriginalFileSize] = useState(null);
    const [uploadBePatientFlag, setUploadBePatientFlag] = useState(false);
    const [dynamoTableName, setDynamoTableName] = useState("cob");
  
    //---------------------
    // For Form Attributes
    //---------------------
    const [keyPrefix, setKeyPrefix] = useState("");
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    

    const {
        acceptedFiles,
        storageLevel,
        filesLimit,
        showFileNamesInPreview,
        showFileNames,

        forUserOnly,
        
        language,
        domain,
        uploadType,
        uploadCategory,
        closePreviewPanelCallback,
    } = props;

//   let keyPrefix = s3KeyPrefix;

    const classes = useStyles();

    const directionAlignment = {
        textAlign: direction === "ltr" ? "left" : "right",
    };

    // const directionFloat = {
    //   float: appContext.direction === 'ltr' ? 'left' : 'right',
    // };

    const directionReverseFloat = {
        float: appContext.direction === "ltr" ? "right" : "left",
    };

    const {
        uploadPageHdr,
        uploadingLabel,
        uploadFilesLabel,
        uploadComplete,
        totalNumberFiles,
        previewText,
        dialogTitle,
        dropzoneText,
        cancel,
        submit,
        uploadBePatientMsg,

        operationSuccessMsg,
        operationFailureMsg,
        duplicateTransMsg,
    } = pageLabelsRes;

    async function fetchDbResources(cacheItemKey) {
        return new Promise((resolve, reject) => {
            backdropProgress(true);
            let keys = [];
            let objKeys = Object.keys(pageLabels);
            // console.log("In DomainUploadToS3(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));

            if (objKeys !== null && objKeys.length > 0) {
                objKeys.map((key) => {
                    let partitionKey = appContext.currentLanguage.languageCode;
                    partitionKey += ".";
                    partitionKey += GenerateModulusHash(key, 200);

                    let itemKey = {
                        ResourcePK: partitionKey, // e.g. 'en.91'
                        ResourceSK: key, // e.g. 'firstName'
                    };
                    keys.push(itemKey);
                });
            }
            // console.log("In DomainUploadToS3(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

            let arrayData = [];

            let params = {
                apiName: "ResourceAPI",
                path: "/dynamodb/resources/batchGet",
                payload: {
                    keys: keys,
                    projectionExpression: "ResourceCode,ResourceDesc",
                },
            };

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
                // console.log('In fetchDbResources(): response response.data.Responses.resource is: ', response.data.Responses.resource);
                response.data.Responses.resource.map((item, index) => {
                    let record = {
                        ResourceCode: item.ResourceCode,
                        ResourceDesc: item.ResourceDesc,
                    };

                    arrayData.push(record);
                });

                if (arrayData && arrayData.length > 0) {
                    arrayData.map((item) => {
                        if (item.ResourceCode === "uploadPageHdr") pageLabels.uploadPageHdr = item.ResourceDesc;
                        if (item.ResourceCode === "uploadingLabel") pageLabels.uploadingLabel = item.ResourceDesc;
                        if (item.ResourceCode === "uploadComplete") pageLabels.uploadComplete = item.ResourceDesc;
                        if (item.ResourceCode === "totalNumberFiles") pageLabels.totalNumberFiles = item.ResourceDesc;
                        if (item.ResourceCode === "previewText") pageLabels.previewText = item.ResourceDesc;
                        if (item.ResourceCode === "uploadFilesLabel") pageLabels.uploadFilesLabel = item.ResourceDesc;
                        if (item.ResourceCode === "dialogTitle") pageLabels.dialogTitle = item.ResourceDesc;

                        if (item.ResourceCode === "dropzoneText") pageLabels.dropzoneText = item.ResourceDesc;
                        if (item.ResourceCode === "cancel") pageLabels.cancel = item.ResourceDesc;  
                        if (item.ResourceCode === "submit") pageLabels.submit = item.ResourceDesc;

                        if (item.ResourceCode === "uploadBePatientMsg") pageLabels.uploadBePatientMsg = item.ResourceDesc;

                        if (item.ResourceCode === "operationSuccessMsg") pageLabels.operationSuccessMsg = item.ResourceDesc;
                        if (item.ResourceCode === "operationFailureMsg") pageLabels.operationFailureMsg = item.ResourceDesc;
                        if (item.ResourceCode === "duplicateTransMsg") pageLabels.duplicateTransMsg = item.ResourceDesc;
                    });
                    // Add it to cache
                    SetCacheItem(cacheItemKey, pageLabels);
                }

                setPageLabelsRes(pageLabels);

                backdropProgress(false);
                setRenderDomFlag(true);
                resolve(pageLabels);
            }).catch((err) => {
                console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
                setPageLabelsRes(pageLabels);
                backdropProgress(false);
                setRenderDomFlag(true);
                reject("Error while fetching resources")
            });
        });
    }

    const progression = React.useRef(() => {});

    useEffect(() => {
        progression.current = () => {
            if (completed > 100) {
                setCompleted(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setCompleted(completed + diff);
                setBuffer(completed + diff + diff2);
            }
        };
    }, [completed]);

    useEffect(() => {
        function tick() {
            progression.current();
        }
        const timer = setInterval(tick, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useLayoutEffect(() => {
        // console.log('In DomainUploadToS3(): useLayoutEffect(): currentLanguage is: ', currentLanguage);
        async function fetchItems() {
            if (!lodash.isEmpty(domain) && !lodash.isEmpty(language) && !lodash.isEmpty(uploadType) && !lodash.isEmpty(uploadCategory)) {
                let s3KeyPrefix = null;
                switch(uploadType.value) {
                    case "docs":
                    case "videos":
                    case "audios":
                        s3KeyPrefix = domain.value + "/" + language.languageCode + "/" + uploadType.value + "/" + uploadCategory.value + "/";
                        if ((typeof forUserOnly !== 'undefined' && forUserOnly)) {
                            s3KeyPrefix = domain.value + "/" + language.languageCode + "/" + appContext.userName + "/" + uploadType.value + "/" + uploadCategory.value + "/";
                        }
                        break;
                    default:// for images, photos etc
                        s3KeyPrefix = domain.value + "/" + language.languageCode + "/media/" + uploadType.value + "/" + uploadCategory.value + "/";
                        if ((typeof forUserOnly !== 'undefined' && forUserOnly)) {
                            s3KeyPrefix = domain.value + "/" + language.languageCode + "/media/" + appContext.userName + "/" + uploadType.value + "/" + uploadCategory.value + "/";
                        }
                        break;
                }
                // console.log('In  DomainUploadToS3(): useLayoutEffect()(): s3KeyPrefix is: ', s3KeyPrefix);
                setKeyPrefix(s3KeyPrefix);
            }

            // Fetch resource from catche
            let cacheItemKey = "domain-upload-s3";
            cacheItemKey += ":";
            cacheItemKey += appContext.currentLanguage.languageCode;
            // console.log('In DomainUploadToS3(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

            if (
                appContext.clearCacheLanguageCodes &&
                appContext.clearCacheLanguageCodes.length > 0 &&
                appContext.clearCacheLanguageCodes.includes(appContext.currentLanguage.languageCode)
            ) {
                // console.log("In DomainUploadToS3(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                RemoveCacheItem(cacheItemKey);
            }

            let cacheItem = GetCacheItem(cacheItemKey);
            // console.log('In DomainUploadToS3(): useLayoutEffect(): cacheItem is: ', cacheItem);
            // cacheItem = null; // remove after testing

            if (cacheItem === null) {
                // resource is not in cache, fetch from DB
                // console.log('In DomainUploadToS3(): useLayoutEffect(): resource is not in cache, fetch from DB');
                await fetchDbResources(cacheItemKey);
            } else {
                // console.log('In DomainUploadToS3(): useLayoutEffect(): got resource from cache');
                setPageLabelsRes(cacheItem);
                setRenderDomFlag(true);
            }
        }

        fetchItems();
    }, []);

    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    async function createService(dbParams) {
        return new Promise((resolve, reject) => {
            setBackdropFlag(true);
            // console.log("In createService(): dbParams.fileName is: ", dbParams);
            // console.log("In createService(): dbParams.fileName is: ", dbParams.fileName);
            // console.log("In createService(): dbParams is: ", JSON.stringify(dbParams, null, 2));
            // console.log("In createService(): dynamoTableName is: ", dynamoTableName);
            console.log("In createService(): forUserOnly is: ", forUserOnly);
            console.log("In createService(): appContext.userName is: ", appContext.userName);
            // console.log("In createService(): appContext.deceasedCode is: ", appContext.deceasedCode);
            // console.log("In createService(): appContext.currentLanguage is: ", appContext.currentLanguage);

            // Initialize
            setGlobalErrorFlag(false);
            setGlobalErrorMsg("");

            // console.log("In createService(): Validation passed.");
            // console.log("In createService(): Validation passed.");

            const timestamp = moment.tz(Date.now(), timeZone);

            // setProgressFlag(true);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            const uniqueId = GenerateUniqueName();
            // console.log("In createService(): uniqueId is: ", uniqueId);

            let mediaS3FullKey = null;
            let mediaS3SubKey = null;

            let key = {};

            if (!lodash.isEmpty(dbParams) && 
                !lodash.isEmpty(domain) && 
                !lodash.isEmpty(language) && 
                !lodash.isEmpty(uploadType) && 
                !lodash.isEmpty(uploadCategory)
                ) {
                switch(uploadType.value) {
                    case "docs":
                    case "audios":
                    case "videos":
                        mediaS3FullKey =  dbParams.keyPrefix; // Complete s3 key - use with docs, videos and audios
                        key.MediaPK = domain.value + "#" + language.languageCode + "#" + uploadType.value;
                        key.MediaSK = domain.value + "#" + language.languageCode + "#" + uploadType.value + "#" + uploadCategory.value + "#" + uniqueId;
                        break;
                    default:// for photos, images, banners etc
                        mediaS3SubKey =  dbParams.keyPrefix;// not a complete key - use with photos, images etc
                        key.MediaPK = domain.value + "#" + language.languageCode + "#media";
                        key.MediaSK = domain.value + "#" + language.languageCode + "#media#" + uploadType.value + "#" + uploadCategory.value + "#" + uniqueId;
                        if ((typeof forUserOnly !== "undefined") && forUserOnly) {
                            key.MediaSK = appContext.userName + "#" + language.languageCode + "#media#" + uploadType.value + "#" + uploadCategory.value + "#" + uniqueId;
                        }
                        break;
                }
            
                let item = {
                    "MediaPK": key.MediaPK,
                    "MediaSK":  key.MediaSK,

                    "UniqueAttribute": lodash.isEmpty(key) ? null : key,

                    "LanguageObject": lodash.isEmpty(language) ? appContext.currentLanguage : language,
                    "LanguageCode": lodash.isEmpty(language) ? appContext.currentLanguage.languageCode : language.languageCode,

                    MediaRefId: uniqueId,

                    MediaS3FullKey: mediaS3FullKey,
                    MediaS3SubKey: mediaS3SubKey,

                    MediaType: lodash.isEmpty(uploadType) ? null : uploadType,

                    MediaName: dbParams.fileName,

                    // MediaCategoryCode: uploadCategory.value,
                    // MediaCategory: uploadCategory.text,// category description
                    MediaCategory: lodash.isEmpty(uploadCategory) ? null : uploadCategory,

                    MediaCaption: null,
                    MediaDesc: null,

                    "ModifiedBy": appContext.userName,
                    "CreatedAt": timestamp,
                    "UpdatedAt": timestamp,
                };

                conditionExpression = "attribute_not_exists(UniqueAttribute)";

                let apiName = "MediaAPI";
                let path = "/dynamodb/media/put";

                let params = {
                    apiName: apiName,
                    path: path,
                    payload: {
                        item: item,
                        conditionExpression: conditionExpression,
                        // updateExpression: updateExpression,
                        // expressionAttributeValues: expressionAttributeValues,
                    },
                };
                // console.log('In createService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

                let initObj = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "",
                    },
                    response: true, // return entire response object instead of response.data
                    body: {},
                };

                initObj.headers.Authorization = appContext.jwtToken;
                initObj.body = params.payload;
                // console.log('In createService(): initObj is: ', JSON.stringify(initObj, null, 2));

                API.post(params.apiName, params.path, initObj).then(async (response) => {
                    // console.log('In createService(): put operation was successful.');
                    // console.log('In createService(): response is: ', JSON.stringify(response, null, 2));
                    // setProgressFlag(false);
                    setBackdropFlag(false);
                    resolve("DynamoDB put operation was successful.");
                }).catch((err) => {
                    setGlobalErrorFlag(true);
                    setGlobalErrorMsg(operationFailureMsg);
                    // setProgressFlag(false);
                    switch(err.code) {
                        case "ConditionalCheckFailedException":
                            console.error("In createService(): Duplicate Error");
                            setGlobalErrorMsg(duplicateTransMsg);
                            break;
                        default:
                            console.error("In createService(): err is: ", JSON.stringify(err, null, 2));
                            break;
                    }
                    setBackdropFlag(false);
                    reject("DynamoDB put operation failed.");
                });
            } else {
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                setBackdropFlag(false);
                reject("DynamoDB put operation failed.");
            }
        });
    }

    async function upload() {
        return new Promise((resolve, reject) => {
            setBackdropFlag(true);
            // console.log("In upload(): filesToUpload count: ", filesToUpload.length + "\n\n");
            // console.log("In upload(): keyPrefix is: ", keyPrefix);
            // console.log("In upload(): appContext.shortDomain is: ", appContext.shortDomain);
            // console.log("In upload(): appContext.jwtToken is: ", appContext.jwtToken);

            let fileMetadata = {};
            
            let cnt = 0;

            // setBuffer(filesToUpload.length);
            // setShowProgressFlag(true);

            if (filesToUpload && filesToUpload.length > 0) {
                filesToUpload.map((file) => {
                    // console.log("In upload(): file is: ", file);
                    // console.log("In upload(): file.name is: ", file.name);

                    let fileName = file.name;
                    let fileSize = file.size;
                    let fileType = file.type;

                    // console.log("In upload(): fileName is: ", fileName);
                    // console.log("In upload(): fileType is: ", fileType);
                    // console.log("In upload(): keyPrefix is: ", keyPrefix);

                    let objKeyPrefix = keyPrefix;
                    // let objKeyPrefix = keyPrefix + groupCategoryCode + "/";
                    // console.log("In upload(): objKeyPrefix is: ", objKeyPrefix);

                    /*if (objKeyPrefix.includes("profile")) {
                        let fileExt = fileName.substring(fileName.lastIndexOf("."));
                        // console.log("In upload(): fileExt is: ", fileExt);
                        if (objKeyPrefix.includes("user")) {
                            fileName = "userProfile" + fileExt;
                        } else {
                            fileName = "deceasedProfile" + fileExt;
                        }
                    } else if (objKeyPrefix.includes("page-audio") && objKeyPrefix.includes("-page")) {// For page audio
                        let fileExt = fileName.substring(fileName.lastIndexOf("."));
                        // console.log("In upload(): fileExt is: ", fileExt);
                        // console.log("In upload(): groupCategory is: ", groupCategory);
                        fileName = groupCategoryCode + fileExt;
                    }*/

                    // For public: cob/onyii5119@gmail.com/myfile.png  For protected/private:
                    let objectKey = storageLevel === "public" ? objKeyPrefix + fileName : fileName;
                    // console.log("In upload(): objectKey is: ", JSON.stringify(objectKey, null, 2));

                    fileMetadata.filename = fileName;
                    fileMetadata.filetype = fileType;
                    fileMetadata.filesize = fileSize.toString();
                    fileMetadata.groupcategorycode = lodash.isEmpty(uploadCategory) ? "" : uploadCategory.value ;
                    fileMetadata.groupcategory = lodash.isEmpty(uploadCategory) ? "" : uploadCategory.text;
                    fileMetadata.caption = "";
                    fileMetadata.description = "";
                    fileMetadata.objectkey = objectKey;
                    // console.log("In upload(): fileMetadata is: ", JSON.stringify(fileMetadata, null, 2));

                    Storage.put(objectKey, file, {
                        level: storageLevel,
                        customPrefix: (!lodash.isEmpty(customPrefix)) ? customPrefix : null,
                        contentType: (!lodash.isEmpty(fileType)) ? fileType : "text/plain",
                        cacheControl: (!lodash.isEmpty(cacheControl)) ? cacheControl : "",
                        contentDisposition: (!lodash.isEmpty(contentDisposition)) ? contentDisposition : "",
                        expires: (!lodash.isEmpty(expires)) ? expires : null,
                        metadata: (!lodash.isEmpty(fileMetadata)) ? fileMetadata : null,
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: appContext.jwtToken,
                        },

                        progressCallback(progress) {
                            let percProgress = ((progress.loaded / progress.total) * 100).toFixed(0) + "%";
                            setCurrentFileName(file.name);
                            setProgress(percProgress);
                            // console.log("In upload(): Uploaded: ", percProgress);
                            // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                        },
                    }).then(async (result) => {
                        cnt++;
                        // console.log("In upload(): cnt is: ", cnt);
                        // console.log("In upload(): buffer is: ", buffer);
                        // console.log("In upload(): result is: ", result);

                        let qt = null;
                        let dbParams = null;

                        if (cnt >= filesToUpload.length) {
                            setShowPreviews(false);
                            setShowPreviewsInDropzone(false);

                            setFilesToUpload([]);
                            // setShowProgressFlag(false);
                            setUploadCompleteFlag(true);
                            setUploadBePatientFlag(false);

                            setCompleted(cnt);

                            // console.log("In upload(): uploadType is: ", uploadType);

                            // if (keyPrefix.includes("docs")) {
                            //     qt = "docs";
                            // } else if (keyPrefix.includes("audios") || keyPrefix.includes("audio")) {
                            //     qt = "audios";
                            // } else if (keyPrefix.includes("videos") || keyPrefix.includes("video")) {
                            //     qt = "videos";
                            // }

                            dbParams = {
                                // queryType: uploadType,
                                fileName: fileName, 
                                keyPrefix: objectKey, 
                                dimensionName: null, 
                                // groupCategoryCode: uploadCategory ? uploadCategory.value : "", 
                                // groupCategory: uploadCategory ? uploadCategory.text : ""
                            };
                            // setTimeout(() => {createService(dbParams);}, 1000);

                            // console.log("In upload(): fileName is: ", fileName);      
                            // console.log("In upload(): fileType is: ", fileType);      
                            // console.log("In upload(): keyPrefix is: ", keyPrefix);      
                            // console.log("In upload(): groupCategoryCode is: ", groupCategoryCode);      
                            // console.log("In upload(): groupCategory is: ", groupCategory);  

                            setBackdropFlag(false);
                            resolve(dbParams);
                        }
                        // console.log("In upload(): dbParams is: ", dbParams);   
                    }).catch((err) => {
                        console.error("In upload(): err is: ", err);
                        // setShowProgressFlag(false);
                        setBackdropFlag(false);
                        reject("Error while uploading file.");
                    });
                });
            }
        });
    }
    
    let cnt = 0;

    /**
     * Convert a base64 string in a Blob according to the data and contentType.
     * 
     * @param b64Data {String} Pure base64 string without contentType
     * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
     * @param sliceSize {Int} SliceSize to process the byteCharacters
     * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
     * @return Blob
     */
    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        let blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    let createdCnt = 0;

    async function resizeFilesCallback(params) {
        return new Promise((resolve, reject) => {
            setBackdropFlag(true);
            // console.log("In resizeFilesCallback(): params is: ", params);
            let dataUri = params.uri;
            let fileName = params.fileName; 
            let fileType = params.fileType; 
            let imageWidth = params.actualWidth; 
            let imageHeight = params.actualHeight; 
            let dimensionFlag = params.dimensionFlag; 
            let dimensionName = params.dimensionName;

            let fileMetadata = {};
            // setShowProgressFlag(true);

            let ImageURL = dataUri;
            // Split the base64 string in data and contentType
            let block = ImageURL.split(";");
            // Get the content type of the image
            let contentType = block[0].split(":")[1];
            // console.log("In resizeFilesCallback(): contentType is: ", contentType);
            // get the real base64 content of the file
            let realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

            // Convert it to a blob to upload
            let blob = b64toBlob(realData, contentType);
            let fileSize = blob.size;
            // console.log("In resizeFilesCallback(): fileSize is: ", fileSize);

            let objectKey = null;

            // dimensionFlag === true : We are processing for different sizes
            let objKeyPrefix = keyPrefix;

            if (dimensionFlag) {
                objKeyPrefix = objKeyPrefix + dimensionName +"/"
            }

            // For public: cob/onyii5119@gmail.com/myfile.png  For protected/private:
            objectKey = storageLevel === "public" ? objKeyPrefix + fileName : fileName;
            // console.log("In resizeFilesCallback(): objectKey is: ", objectKey);
            
            // console.log("In resizeFilesCallback(): imageWidth is: ", imageWidth);
            // console.log("In resizeFilesCallback(): imageHeight is: ", imageHeight);
            
            fileMetadata.filename = fileName;
            fileMetadata.filetype = fileType;
            fileMetadata.imagewidth = imageWidth.toString();
            fileMetadata.imageheight = imageHeight.toString();
            fileMetadata.filesize = fileSize.toString();
            fileMetadata.groupcategorycode = lodash.isEmpty(uploadCategory) ? "" : uploadCategory.value ;
            fileMetadata.groupcategory = lodash.isEmpty(uploadCategory) ? "" : uploadCategory.text ;
            fileMetadata.caption = "";
            fileMetadata.description = "";
            fileMetadata.objectkey = objectKey;
            // console.log("In resizeFilesCallback(): fileMetadata is: ", JSON.stringify(fileMetadata, null, 2));
            // console.log("In resizeFilesCallback(): appContext.jwtToken is: ", appContext.jwtToken);

            Storage.put(objectKey, blob, {
                level: storageLevel,
                customPrefix: (!lodash.isEmpty(customPrefix)) ? customPrefix : null,
                contentType: (!lodash.isEmpty(fileType)) ? originalFileType : "text/plain", // text/plain
                cacheControl: (!lodash.isEmpty(cacheControl)) ? cacheControl : "",
                contentDisposition: (!lodash.isEmpty(contentDisposition)) ? contentDisposition : "",
                expires: (!lodash.isEmpty(expires)) ? expires : null,
                metadata: (!lodash.isEmpty(fileMetadata)) ? fileMetadata : null,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: appContext.jwtToken,
                },

                progressCallback(progress) {
                    let percProgress = ((progress.loaded / progress.total) * 100).toFixed(0) + "%";
                    setCurrentFileName(fileName);
                    setProgress(percProgress);
                    // console.log("In resizeFilesCallback(): Uploaded: ", percProgress);
                    // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                },
            }).then(async (result) => {
                cnt++;
                // console.log("In resizeFilesCallback(): cnt is: ", cnt);
                // console.log("In resizeFilesCallback(): fileName is: ", fileName);
                // console.log("In resizeFilesCallback(): filesToUpload.length is: ", filesToUpload.length);
                // console.log("In resizeFilesCallback(): dimensionName is: ", dimensionName);

                let qt = null;
                let dbParams = null;
                let resolveParams = null;

                if (dimensionName && (dimensionName === "small300")) {       
                    dbParams = {
                        // queryType: uploadType,
                        fileName: fileName, 
                        keyPrefix: keyPrefix, 
                        dimensionName: dimensionName, 
                        // groupCategoryCode: uploadCategory ? uploadCategory.value : "", 
                        // groupCategory: uploadCategory ? uploadCategory.text : ""
                    };
                    // createService(dbParams);
                    setTimeout(async () => {
                        // console.log("In resizeFilesCallback(): dbParams is: ", dbParams);
                        // await createService(dbParams)
                        Promise.all([
                            await createService(dbParams)
                        ]).then(async (result4) => {
                            createdCnt++;

                            // console.log("In resizeFilesCallback(): result4 is: ", result4[0]);
                            // console.log("In resizeFilesCallback(): filesToUpload.length is: ", filesToUpload.length);
                            // console.log("In resizeFilesCallback(): createdCnt is: ", createdCnt);

                            if (createdCnt >= filesToUpload.length) {
                                // console.log("In resizeFilesCallback(): All records persisted");
                                setShowPreviews(false);
                                setShowPreviewsInDropzone(false);

                                setFilesToUpload([]);
                                setUploadCompleteFlag(true);
                                setUploadBePatientFlag(false);

                                setCompleted(createdCnt);

                                setBackdropFlag(false);
                                closePreviewPanelCallback({createdCnt: createdCnt});
                                resolve("Upload to s3 is complete");
                            }
                        }).catch((error4) => {
                            console.error("In resizeFilesCallback(): error4 is: ", error4);
                            console.error("In resizeFilesCallback(): cnt is: ", cnt);
                            reject("In resizeFilesCallback(): createService error.");
                        });
                    }, 2000);
                } else {
                    resolve("Upload to s3 is complete");
                }
            }).catch((err) => {
                console.error("In resizeFilesCallback(): err is: ", err);
                // setShowProgressFlag(false);
                setBackdropFlag(false);
                reject("Error in resizeFilesCallback()");
            });
        });
    }

    async function resizeFiles(file, actualWidth, actualHeight) {
        return new Promise((resolve, reject) => {
            setBackdropFlag(true);
            // console.log("In resizeFiles(): filesToUpload count: ", filesToUpload.length);
            // console.log("In resizeFiles(): actualWidth is: ", actualWidth);
            // console.log("In resizeFiles(): actualHeight is: ", actualHeight);

            // filesToUpload.map(async (file) => {
            // console.log("In resizeFiles(): file is: ", file);

            let fileName = file.name;
            let fileType = file.type;
            setOriginalFileType(fileType);

            // console.log("In resizeFiles(): fileName is: ", fileName);
            // console.log("In resizeFiles(): fileType is: ", fileType);

            let fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
            fileExtension = fileExtension.toLowerCase();
            // console.log("In resizeFiles(): fileExtension is: ", fileExtension);
            const fileExt = (fileExtension === "jpg" || fileExtension === "jpeg") ? "jpeg" : fileExtension;

            let newImageMinWidth = 2500;
            let newImageMinHeight = 2500;

            let newImageMaxWidth = 2500;
            let newImageMaxHeight = 2500;
            // let resizeParams = {};
            let dimensionFlag = false;
            let dimensionName = null;

            if (appContext.imageDimensions && appContext.imageDimensions.length > 0) {
                appContext.imageDimensions.map(async (item) => {
                    if (item.name === "original") { 
                        // Save the original file as it is - no resize
                        newImageMinWidth = actualWidth;
                        newImageMinHeight = actualHeight;

                        newImageMaxWidth = actualWidth;
                        newImageMaxHeight = actualHeight;

                        dimensionFlag = true;
                        dimensionName = "original";
    
                        Resizer.imageFileResizer(
                            file, // Path of image file
                            newImageMaxWidth, // New image max width
                            newImageMaxHeight, // New image max height
                            fileExt, // compressFormat: JPEG, PNG or WEBP
                            100, // quality: between 0 and 100
                            0, // rotation: 0, 90, 180, 270, 360
                            async (uri) => {
                                return new Promise(async () => {
                                    await resizeFilesCallback({
                                            uri: uri, 
                                            fileName: fileName, 
                                            fileType: fileType, 
                                            actualWidth: actualWidth, 
                                            actualHeight: actualHeight, 
                                            dimensionFlag: dimensionFlag, 
                                            dimensionName: dimensionName
                                        });
                                }).then(async (result3) => {
                                    // console.log("In resizeFiles(): result3 is: ", result3[0]);
                                }).catch((error3) => {
                                    console.log("In resizeFiles(): error3 is: ", error3);
                                });
                            }, // responseUriFunc: Callback function of URI.
                            'base64', // outputType: either base64 or blob
                            newImageMinWidth, // New image min width
                            newImageMinHeight  // New image max width
                        );
                    } else {                       
                        let newImageMinWidth1 = item.value;
                        let newImageMinHeight1 = item.value;

                        let newImageMaxWidth1 = item.value;
                        let newImageMaxHeight1 = item.value;

                        // newImageMaxWidth = 100;
                        // newImageMaxHeight = 100;

                        // We are resizing one image for multiple sizes
                        let dimensionFlag1 = true;
                        let dimensionName1 = item.name;
                        // let dimensionName1 = "small100";
                        // console.log("In  resizeFiles(): dimensionName1 is: ", dimensionName1);

                        Resizer.imageFileResizer(
                            file, // Path of image file
                            newImageMaxWidth1, // New image max width
                            newImageMaxHeight1, // New image max height
                            fileExt, // compressFormat: JPEG, PNG or WEBP
                            100, // quality: between 0 and 100
                            0, // rotation: 0, 90, 180, 270, 360
                            async (uri) => {
                                return new Promise(async () => {
                                    await resizeFilesCallback({
                                            uri: uri, 
                                            fileName: fileName, 
                                            fileType: fileType, 
                                            actualWidth: actualWidth, 
                                            actualHeight: actualHeight, 
                                            dimensionFlag: dimensionFlag1, 
                                            dimensionName: dimensionName1
                                        });
                                }).then(async (result3) => {
                                    // console.log("In resizeFiles(): result3 is: ", result3[0]);
                                }).catch((error3) => {
                                    console.log("In resizeFiles(): error3 is: ", error3);
                                });
                            }, // responseUriFunc: Callback function of URI.
                            'base64', // outputType: either base64 or blob
                            newImageMinWidth1, // New image min width
                            newImageMinHeight1  // New image max width
                        );
                    }
                });
                setBackdropFlag(false);
                resolve("In resizeFiles(): files processing complete.")
            } else {
                setBackdropFlag(false);
                reject("Error encountered in resizeFiles()");
            }
        });
    }

    let actualWidth = null;
    let actualHeight = null;

    async function getImageSize(file) {
        return new Promise((resolve, reject) => {
            setBackdropFlag(true);
            let fr = new FileReader;

            fr.onload = function() { // file is loaded
                let img = new Image;
            
                img.onload = function() {
                    // console.log("In getImageSize(): image width is: ", img.width);
                    // console.log("In getImageSize(): image height is: ", img.height);

                    // callback(file, img.width, img.height);

                    actualWidth = img.width;
                    actualHeight = img.height;
                    setBackdropFlag(false);
                    resolve({actualWidth: actualWidth, actualHeight: actualHeight});
                };
            
                img.src = fr.result; // is the data URL because called with readAsDataURL
                // console.log("In getImageSize(): img.src is: ", img.src);
            };
            
            fr.readAsDataURL(file); // I'm using a <input type="file"> for demonstrating
        });
    }

    async function runIt(file) {
        // console.log("In runIt(): file is: ", file);
        Promise.all([
            await getImageSize(file)
        ]).then(async (result) => {
            // console.log("In runIt(): result is: ", result[0]);
            Promise.all([
                await resizeFiles(file, result[0].actualWidth, result[0].actualHeight)
            ]).then(async (result2) => {
                // console.log("In runIt(): result2 is: ", result2[0]);
            }).catch((error2) => {
                console.log("In runIt(): error2 is: ", error2);
            });
        }).catch((error) => {
            console.log("In runIt(): error is: ", error);
        });
    }
   
    let indexer = 0;

    async function processUploadedFiles() {
        // console.log("In processUploadedFiles(): indexer is: ", indexer);
        setUploadBePatientFlag(true);

        if (!lodash.isEmpty(filesToUpload) && filesToUpload.length > 0) {
            do{
                // console.log("In processUploadedFiles(): indexer is: ", indexer);
                runIt(filesToUpload[indexer]);
                indexer++;
            } while (indexer < filesToUpload.length); // i < filesToUpload.length
        }
    }

    /***************** */
    /* Dropzone Events */
    /***************** */

    // const handleOpen = () => {
    //   // console.log("In handleOpen()");
    //   setOpenFlag(true);
    // };

    // Fired when the user drops files into dropzone OR deletes a file.
    // Returns all the files currently loaded into the dropzone.
    function onChange(files) {
        // console.log("In onChange()");
        setFilesToUpload([]);

        if (files && files.length > 0) {
            // console.log("In onChange(): files.length is: ", files.length);
            setFilesToUpload(files);
            setShowPreviewsInDropzone(true);
            // setSelectedImage("chi-and-me-smiling.jpg");

            const reader = new FileReader();
            reader.addEventListener("load", () => {
                // console.log("In onChange(): reader.result is: ", reader.result);
                setSelectedImage(reader.result);
            });
            reader.readAsDataURL(files[files.length - 1]);
        } else {
            setFilesToUpload([]);
        }
    };

    // Fired when the user clicks the Submit button.
    function onSave(files) {
        // console.log("In onSave(): files is: ", files);
        setFilesToUpload([]);
        setFilesToUpload(files);
        setOpenFlag(false);
    };

    // Fired when the modal is closed
    function onClose(event) {
        // console.log("In onClose(): event is: ", event);
        setOpenFlag(false);
    };

    // Fired when the user drops files into the dropzone.
    // Returns the files dropped
    function onDrop(files) {
        // console.log("In onDrop(): files is: ", files);
    };

    // Fired when a file is rejected because of wrong file type, size or goes beyond the filesLimit.
    // Returns the files that were rejected
    function onDropRejected(files) {
        // console.log("In onDropRejected(): files is: ", files);
    };

    // Fired when a file is deleted from the previews panel.
    function onDelete(file) {
        // console.log("In onDelete(): file is: ", file);
    };

    const MIN = 1;
    let MAX = filesToUpload.length;
    const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

    //--------------------------------
    // Toast Image Editor
    //--------------------------------

    const imageTheme = {
        // Theme object to extends default dark theme.
    };

    async function uploadButtonAction() {
        // console.log("In uploadButtonAction(): uploadType is: ", uploadType);

        if (!lodash.isEmpty(uploadType)) {
            setBackdropFlag(true);
            switch(uploadType.value) {
                case "docs":
                case "audios":
                case "videos":
                    await Promise.all([
                        upload()
                    ]).then(async (results) => {
                        // console.log("In uploadButtonAction(): results is: ", results[0]);
                        await Promise.all([
                            createService(results[0])
                        ]).then(async (results2) => {
                            // console.log("In uploadButtonAction(): results2 is: ", results2[0]);
                            closePreviewPanelCallback({createdCnt: 1});
                        }).catch((error2) => {
                            console.log("In uploadButtonAction(): error2 is: ", error2);
                        });
                        // setTimeout(() => {createService(results[0]);}, 1000);
                    }).catch((error) => {
                        console.log("In uploadButtonAction(): error is: ", error);
                    });
                    break;
                case "images":
                case "photos":
                case "banners":
                case "posters":
                case "thumbnails":
                case "favicons":
                case "profile":
                     processUploadedFiles();
                    break;
                default:// images, banners etc
                    break;
            }
        }
    }

    return (
        <div dir={direction} style={{width: '100%'}}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            <div className={classes.root}>
                {/* { !appContext.signedIn && <Redirect to='/signIn' /> } */}

                <CssBaseline />

                {renderDomFlag && (
                    <Container component="div" maxWidth="lg">
                        <Grid container justify="center">
                            <Grid item xs={12} sm={12}>
                                <Grid container justify="center">
                                    {uploadBePatientFlag &&
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                        <Typography variant="subtitle2" color="secondary">
                                            {uploadBePatientMsg}
                                        </Typography>
                                        </Grid>
                                    </Grid>
                                    }

                                    <Grid item xs={12} sm={5} className={classes.gridItem}>
                                        {showProgressFlag && (
                                        <Typography variant="subtitle2" color="primary" className={classes.label} style={directionAlignment}>
                                            {uploadingLabel}: {currentFileName} {progress}
                                        </Typography>
                                        )}
                                        
                                        {uploadCompleteFlag && filesToUpload.length === 0 && (
                                            <Typography variant="subtitle1" className={classes.uploadComplete} style={directionAlignment}>
                                                <DoneIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                {uploadComplete}
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} sm={4} className={classes.gridItem}>
                                        {showProgressFlag && (
                                        <LinearProgress variant="buffer" value={completed} valueBuffer={buffer} color="secondary" />
                                        // <LinearProgress variant="determinate" value={normalise(completed)} color="secondary"/>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} sm={3} style={{ marginBottom: "8px" }}>
                                        <Button variant="contained" size="large" disabled={filesToUpload.length > 0 ? false : true} color="primary" className={classes.button}
                                            onClick={uploadButtonAction}
                                            style={directionReverseFloat}
                                            >
                                            <CloudUploadIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                            {uploadFilesLabel}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                )}

                <Container component="div" maxWidth="lg">
                    <label htmlFor="dropezoneId" aria-label="drop zone area" style={{width: '100%'}}>
                        <DropzoneArea
                            id="dropezoneId" 
                            dialogTitle={dialogTitle}
                            dropzoneText={dropzoneText}
                            cancelButtonText={cancel}
                            submitButtonText={submit}
                            previewText={previewText}
                            // dropzoneClass={dropzoneClass}
                            dropzoneClass= {classes.dropZone}
                            dropzoneParagraphClass={classes.dropzoneParagraph}
                            open={openFlag}
                            acceptedFiles={acceptedFiles}
                            showPreviews={showPreviews}
                            showPreviewsInDropzone={showPreviewsInDropzone}
                            showAlerts={showAlerts}
                            maxWidth={maxWidth}
                            fullWidth={fullWidth}
                            filesLimit={filesLimit}
                            maxFileSize={maxFileSize}
                            showFileNamesInPreview={showFileNamesInPreview}
                            showFileNames={showFileNames}
                            onSave={onSave}
                            onChange={onChange}
                            onClose={onClose}
                            onDrop={onDrop}
                            onDropRejected={onDropRejected}
                            onDelete={onDelete}
                        />
                    </label>

                    <Typography variant="subtitle1" className={classes.label}>
                        {totalNumberFiles}: {filesToUpload.length}
                    </Typography>
                </Container>
            </div>
        </div>
    );
}

export default DomainUploadToS3;
