import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
// import FormControl from '@material-ui/core/FormControl';
// import Link from '@material-ui/core/Link';
// import Select from '@material-ui/core/Select';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import Select from 'react-select';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

// AG-Grid
import { AgGridReact, AgGridColumn } from "ag-grid-react";
//import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

// Amplify
import Amplify, { API, Storage } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// import GlobalConstants from '../../utils/GlobalConstants';
// import GeneratePassword from '../../utils/GeneratePassword';
// import GenerateClientId from '../../utils/GenerateClientId';
// import GenerateTransactionId from '../../utils/GenerateTransactionId';
// import GenerateRandomHash from '../../utils/GenerateRandomHash';
// import GenerateModulusHash from '../../utils/GenerateModulusHash';
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import LineSpacing from "../../components/misc/LineSpacing";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// Renderers
// import NumberCellRenderer from '../../components/renderers/NumberCellRenderer';
import ActionsCellRenderer from "../../components/renderers/ActionsCellRenderer";
// import { create } from 'jss';
// import CurrencyCellRenderer from '../../components/renderers/CurrencyCellRenderer';
// import CountryCellRenderer from '../../components/renderers/CountryCellRenderer';

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  /*padding: 10px 20px 10px 20px !important;*/
  text-align: center !important;
  align-items: center;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  /* margin: 8px 0 8px 0 !important;*/
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    backgroundImage: `url(${"assets/logos/background20.png"})`,
  },
  gridWidth: {
    width: "80%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mainForm: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  mainGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  textField: {
    width: "100%",
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  formControlLabel: {
    margin: theme.spacing(1),
  },
  verticalAlignLabel: {
    // textAlign: 'left',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  textArea: {
    padding: theme.spacing(1),
    borderRadius: "5px",
  },
  footer: {
    bottom: 0,
    height: "1rem" /* Footer height */,
    width: "100% !important",
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
    textAlign: "center !important",
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

let localeText = {
  page: "Page",
  to: "to",
  of: "of",
  noRowsToShow: "No records found",
};

// let localeText2 = {
//   page: "daPage",
//   more: "daMore",
//   to: "daTo",
//   of: "daOf",
//   next: "daNexten",
//   last: "daLasten",
//   first: "daFirsten",
//   previous: "daPreviousen",
//   loadingOoo: "daLoading...",
//   selectAll: "daSelect Allen",
//   searchOoo: "daSearch...",
//   blanks: "daBlanc",
//   filterOoo: "daFilter...",
//   applyFilter: "daApplyFilter...",
//   equals: "daEquals",
//   notEqual: "daNotEqual",
//   lessThan: "daLessThan",
//   greaterThan: "daGreaterThan",
//   lessThanOrEqual: "daLessThanOrEqual",
//   greaterThanOrEqual: "daGreaterThanOrEqual",
//   inRange: "daInRange",
//   contains: "daContains",
//   notContains: "daNotContains",
//   startsWith: "daStarts dawith",
//   endsWith: "daEnds dawith",
//   andCondition: "daAND",
//   orCondition: "daOR",
//   group: "laGroup",
//   columns: "laColumns",
//   filters: "laFilters",
//   rowGroupColumns: "laPivot Cols",
//   rowGroupColumnsEmptyMessage: "la drag cols to group",
//   valueColumns: "laValue Cols",
//   pivotMode: "laPivot-Mode",
//   groups: "laGroups",
//   values: "laValues",
//   pivots: "laPivots",
//   valueColumnsEmptyMessage: "la drag cols to aggregate",
//   pivotColumnsEmptyMessage: "la drag here to pivot",
//   toolPanelButton: "la tool panel",
//   noRowsToShow: "la no rows",
//   pinColumn: "laPin Column",
//   valueAggregation: "laValue Agg",
//   autosizeThiscolumn: "laAutosize Diz",
//   autosizeAllColumns: "laAutsoie em All",
//   groupBy: "laGroup by",
//   ungroupBy: "laUnGroup by",
//   resetColumns: "laReset Those Cols",
//   expandAll: "laOpen-em-up",
//   collapseAll: "laClose-em-up",
//   toolPanel: "laTool Panelo",
//   export: "laExporto",
//   csvExport: "la CSV Exportp",
//   excelExport: "la Excel Exporto (.xlsx)",
//   excelXmlExport: "la Excel Exporto (.xml)",
//   pinLeft: "laPin &lt;&lt;",
//   pinRight: "laPin &gt;&gt;",
//   noPin: "laDontPin &lt;&gt;",
//   sum: "laSum",
//   min: "laMin",
//   max: "laMax",
//   none: "laNone",
//   count: "laCount",
//   average: "laAverage",
//   copy: "laCopy",
//   copyWithHeaders: "laCopy Wit hHeaders",
//   ctrlC: "ctrl n C",
//   paste: "laPaste",
//   ctrlV: "ctrl n V"
// };

// For AG-Grid

//------------------------------
// RENDERERS
//------------------------------
const gridOptions = {
  style: "currency", // decimal, currency, percent
  currency: "USD",
  currencyDisplay: "symbol", // symbol, code, name to use a localized currency name such as "dollar"
  useGrouping: true,
  minimumIntegerDigits: 21,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  payload: {},
  getRowHeight: function (params) {
    // console.log("In getRowHeight: params is: ", params);
    // assuming 50 characters per line, working how how many lines we need
    // console.log("In getRowHeight: words is: ", params.data.resourceDesc.length);
    let lines = 36 * (Math.floor(params.data.resourceDesc.length / 45) + 1);
    // console.log("In getRowHeight: lines is: ", lines);
    return lines;
  },
};

let createRsr = {
  languageCodeLabel: "Language code",
  languageNameLabel: "Language name",
  resourceCodeLabel: "Resource code",
  resourceCodePrompt: "Please enter resource code",
  resourceDescLabel: "Resource description",
  resourceDescPrompt: "Please enter resource description",
  resourceLanguageLabel: "Resource language",
  submit: "Submit",
  // clear: "Clear",
  deleteLabel: "Delete",
  createResourceSuccessMsg: "Create resources was successful.",
  createResourceFailureMsg: "Error while creating resources.",
  createResources: "Create resources",
  addToGrid: "Add to grid",
  allFieldsAreRequired: "All fields are required",
  maxRecordsAllowed:
    "Maximum number of records allowed in grid is {MAX_RECORDS_ALLOWED}.",
  totalNumberRows: "Total number of rows",

  selectLanguagePrompt: "Select language",
  selectLanguage: "Please select a language",

  // For Grid
  // gridPage: "Page",
  // gridTo: "to",
  // gridOf: "of",
  noRowsToShow: "No records found",
};

function CreateResources(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [resourceLanguage, setResourceLanguage] = useState(defaultLang.value);
  const [resourceCode, setResourceCode] = useState("");
  const [resourceDesc, setResourceDesc] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);
  // const [ initComplete, setInitComplete ] = useState(appContext.signedIn);
  const [payload, setPayload] = useState({});
  const [rowData, setRowData] = useState([]);

  const [langSelectOptions, setLangSelectOptions] = useState([]);

  // AG-Grid
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [progressFlag, setProgressFlag] = useState(false);
  const [addToGridFlag, setAddToGridFlag] = useState(false);
  const [rowSelectedFlag, setRowSelectedFlag] = useState(false);
  const [gridSubmitFlag, setGridSubmitFlag] = useState(false);
  const [gridRowsCount, setGridRowsCount] = useState(0);
  //const [maxRowsFlag, setMaxRowsFlag] = useState(false);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  // const [ confirmCodeFlag, setConfirmCodeFlag ] = useState(false);
  // const [ changePasswordFlag, setChangePasswordFlag ] = useState(false);
  // const [ theOneTimePasswordFlag, setTheOneTimePasswordFlag ] = useState(false);
  // const [ userNotConfirmedFlag, setUserNotConfirmedFlag ] = useState(false);
  // const [ forgotPasswordFlag, setForgotPasswordFlag ] = useState(false);

  // For Error Flags
  const [resourceLanguageErrFlag, setResourceLanguageErrFlag] = useState(false);
  const [resourceCodeErrFlag, setResourceCodeErrFlag] = useState(false);
  const [resourceCodeFocus, setResourceCodeFocus] = useState(true);
  const [resourceDescErrFlag, setResourceDescErrFlag] = useState(false);
  const [resourceDescFocus, setResourceDescFocus] = useState(false);

  const [redirectSignUpFlag, setRedirectSignUpFlag] = useState(false);
  // const [globalErrorMsg, setGlobalErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  // For Resources
  const [createRsrRes, setCreateRsrRes] = useState(createRsr);
  const [prevLangCode, setPrevLangCode] = useState(
    appContext.currentLanguage.languageCode
  );

  // For Grid Resources
  const [localeTextRes, setLocaleTextRes] = useState(localeText);

  const [renderGridFlag, setRenderGridFlag] = useState(false);

  //console.log('AmplifyConfig is: ', JSON.stringify(AmplifyConfig, null, 2));
  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  const {
    languageCodeLabel,
    languageNameLabel,
    resourceLanguageLabel,
    resourceCodeLabel,
    resourceCodePrompt,
    resourceDescLabel,
    resourceDescPrompt,

    submit,
    deleteLabel,
    createResourceSuccessMsg,
    createResourceFailureMsg,
    createResources,
    addToGrid,
    allFieldsAreRequired,
    maxRecordsAllowed,
    totalNumberRows,

    selectLanguagePrompt,
    selectLanguage,
  } = createRsrRes;

  async function fetchDbResources(cacheItemKey) {
    // console.log("In CreateResources(): fetchDbResources()");
    let keys = [];
    let objKeys = Object.keys(createRsr);
    // console.log("In CreateResources(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In CreateResources(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Responses.resource, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "languageCodeLabel")
              createRsr.languageCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "languageNameLabel")
              createRsr.languageNameLabel = item.ResourceDesc;
            if (item.ResourceCode === "resourceLanguageLabel")
              createRsr.resourceLanguageLabel = item.ResourceDesc;
            if (item.ResourceCode === "resourceCodeLabel")
              createRsr.resourceCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "resourceCodePrompt")
              createRsr.resourceCodePrompt = item.ResourceDesc;
            if (item.ResourceCode === "resourceDescLabel")
              createRsr.resourceDescLabel = item.ResourceDesc;
            if (item.ResourceCode === "resourceDescPrompt")
              createRsr.resourceDescPrompt = item.ResourceDesc;
            if (item.ResourceCode === "submit")
              createRsr.submit = item.ResourceDesc;
            if (item.ResourceCode === "deleteLabel")
              createRsr.deleteLabel = item.ResourceDesc;
            if (item.ResourceCode === "createResourceSuccessMsg")
              createRsr.createResourceSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "createResourceFailureMsg")
              createRsr.createResourceFailureMsg = item.ResourceDesc;
            if (item.ResourceCode === "allFieldsAreRequired")
              createRsr.allFieldsAreRequired = item.ResourceDesc;
            if (item.ResourceCode === "maxRecordsAllowed")
              createRsr.maxRecordsAllowed = item.ResourceDesc;
            if (item.ResourceCode === "createResources")
              createRsr.createResources = item.ResourceDesc;
            if (item.ResourceCode === "addToGrid")
              createRsr.addToGrid = item.ResourceDesc;
            if (item.ResourceCode === "totalNumberRows")
              createRsr.totalNumberRows = item.ResourceDesc;

            if (item.ResourceCode === "selectLanguagePrompt")
              createRsr.selectLanguagePrompt = item.ResourceDesc;
            if (item.ResourceCode === "selectLanguage")
              createRsr.selectLanguage = item.ResourceDesc;

            // For Grid
            // if (item.ResourceCode === "gridPage") localeText.page = item.ResourceDesc;
            // if (item.ResourceCode === "gridTo") localeText.to = item.ResourceDesc;
            // if (item.ResourceCode === "gridOf") localeText.from = item.ResourceDesc;
            if (item.ResourceCode === "noRowsToShow")
              localeText.noRowsToShow = item.ResourceDesc;
          });

          // Add it to cache
          SetCacheItem(cacheItemKey, createRsr);
        }

        setColumnDefs(columnDefsArray);
        setLocaleTextRes(localeText);

        setRenderGridFlag(true);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setCreateRsrRes(createRsr);
        setProgressFlag(false);
      });
  }

  // Maximum number of rows allowed in the grid
  const MAX_RECORDS_ALLOWED = 50;

  let selectOptions = [];

  function fetchLanguages() {
    // console.log('In fetchLanguages()');

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
        // "keyConditionExpression": "#resourcePK = :resourcePK",
        // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
        // "expressionAttributeValues": {":resourcePK": "languages"}
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages(): response.length is: ', response.data.Items.length);
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            // label: item.LanguageName,
            text: item.LanguageName,
          };

          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In useEffect(): prevLangCode is, ", prevLangCode);

    let currLangCode = appContext.currentLanguage.languageCode;
    // console.log("In useEffect(): currLangCode is, ", currLangCode);
    if (currLangCode !== prevLangCode) {
      setPrevLangCode(currLangCode);
      setCreateRsrRes(createRsr);
    }

    // Storage.put('test.txt', 'Private Content', {
    //   level: 'private',
    //   contentType: 'text/plain'
    // })
    // .then (result => console.log(result))
    // .catch(err => console.log(err));

    // Storage.list('', {level: 'private'})
    // .then(result => console.log("In useEffect(): list result is, ", result))
    // .catch(err => console.log("In useEffect(): list err is, ", err));

    // Storage.get('test.txt', {level: 'private'})
    // .then(result => console.log("In useEffect(): get result is, ", result))
    // .catch(err => console.log("In useEffect(): get err is, ", err));
  }, []);

  useLayoutEffect(() => {
    // console.log("In CreateResources(): useLayoutEffect(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
    // console.log("In CreateResources(): useLayoutEffect(): selectOptions.length is: ", selectOptions.length);

    setResourceCodeFocus(true);
    setResourceDescFocus(false);

    if (selectOptions.length === 0) {
      fetchLanguages();
    }

    // Fetch resource from catche
    let cacheItemKey = "createRsr";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In CreateResources(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In CreateResources(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In CreateResources(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In CreateResources(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In CreateResources(): useLayoutEffect(): got resource from cache');
      setCreateRsrRes(cacheItem);
    }
  }, []);

  function checkAddToGrid() {
    // console.log('In checkAddToGrid(): resourceLanguage is: ', resourceLanguage);
    // console.log('In checkAddToGrid(): resourceCode is: ', resourceCode);
    // console.log('In checkAddToGrid(): resourceDesc is: ', resourceDesc);

    // Enable add to grid button
    setAddToGridFlag(false);
    if (
      resourceCode !== undefined &&
      resourceCode !== null &&
      resourceCode !== "" &&
      resourceDesc !== undefined &&
      resourceDesc !== null &&
      resourceDesc !== "" &&
      resourceLanguage !== undefined &&
      resourceLanguage !== null &&
      resourceLanguage !== ""
    ) {
      setAddToGridFlag(true);
    }
  }

  function handleChange(event) {
    //console.log("In handleChange(): id is: "+event.target.id);
    setGlobalErrorFlag(false);
    setAddToGridFlag(false);

    switch (event.target.id) {
      case "resourceCodeId":
        setResourceCodeErrFlag(false);
        setResourceCode(event.target.value);
        break;
      // case "resourceDescId":
      //   setResourceDescErrFlag(false);
      //   setResourceDesc(event.target.value);
      //   break;
      default:
        break;
    }

    checkAddToGrid();
  }

  function validateForm() {
    let formErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setResourceCodeErrFlag(false);
    setResourceDescErrFlag(false);

    if (resourceLanguage === null || resourceLanguage === "") {
      formErrFlag = true;
      setResourceLanguageErrFlag(true);
    }

    if (resourceCode === null || resourceCode === "") {
      formErrFlag = true;
      setResourceCodeErrFlag(true);
    }

    if (resourceDesc === null || resourceDesc === "") {
      formErrFlag = true;
      setResourceDescErrFlag(true);
    }

    return formErrFlag;
  }

  async function resourceService() {
    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    // Get Grid data
    const gridRowCount = getRowCount();
    // console.log('In resourceService(): gridRowCount is: ', gridRowCount);

    if (gridRowCount > 0) {
      // we have data to save
      // console.log('We have data to save.');

      const rowsData = [];

      // const d = new Date();
      const timestamp = new Date();
      // const timestamp = d.toISOString();
      // console.log("In resourceService(): timestamp is: ", timestamp);

      gridApi.forEachNode(function (node) {
        // Process each node (row)
        let partitionKey = node.data.resourceLanguageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(node.data.resourceCode, 200);

        let rowData = {
          ResourcePK: partitionKey,
          ResourceSK: node.data.resourceCode,

          ResourceDesc: node.data.resourceDesc,
          ResourceCode: node.data.resourceCode,

          ResourceLanguageCode: resourceLanguage,

          ModifiedBy: appContext.userName,
          CreatedAt: timestamp,
          UpdatedAt: timestamp,
        };

        // New Attributes
        let key = {
          ResourcePK: partitionKey, 
          ResourceSK: node.data.resourceCode,
        };
        rowData.UniqueAttribute = key;
        rowData.BasicResource = "basic-resource";
        rowData.LangAndResCode = resourceLanguage + "#" + node.data.resourceCode;

        rowsData.push(rowData);
      });

      let params = {
        apiName: "ResourceAPI",
        path: "/dynamodb/resources/batchWrite",
        payload: {
          items: rowsData,
        },
      };

      let initObj = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        response: true, // return entire response object instead of response.data
        body: {},
      };

      initObj.headers.Authorization = appContext.jwtToken;
      initObj.body = params.payload;
      // console.log('In resourceService(): initObj is: ', JSON.stringify(initObj, null, 2));

      setProgressFlag(true);

      API.post(params.apiName, params.path, initObj).then((response) => {
        // console.log('In resourceService(): response is: ', JSON.stringify(response, null, 2));

        // Clear the grid of any data
        clearGridData();

        // Show Snackbar
        setSnackbarsVariant("success");
        setSnackbarsMsg(createResourceSuccessMsg);
        setSnackbarOpen(true);

        setProgressFlag(false);
      }).catch((err) => {
        console.error("In resourceService(): err is: ", JSON.stringify(err, null, 2));
        setGlobalErrorFlag(true);
        setGlobalErrorMsg(createResourceFailureMsg);

        setProgressFlag(false);
      });
    }
  }

  //===================================================

  gridOptions.payload = payload;

  // Grid Functions

  const deleteRows = (e) => {
    //console.log("In deleteRows(): gridApi is: ", gridApi);
    //console.log("In deleteRows(): gridApi.getSelectedNodes() is: ", gridApi.getSelectedNodes());
    var selectedRows = gridApi.getSelectedRows();
    var res = gridApi.updateRowData({ remove: selectedRows });
    // console.log("In deleteRows(): res is: ", res);

    // Update number of rows in grid
    getRowCount();
    initializeForm();

    // Disable Submit button if need be
    let gridRowsCount = gridApi.getModel().getRowCount();
    if (gridRowsCount === 0) {
      setGridSubmitFlag(false);
    }
  };

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
  }

  const onCellClicked = (event) => {
    // console.log('onCellClicked: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellClicked: event.colDef.field is: ', event.colDef.field);
    if (event.colDef.field === "delete") {
      var selectedData = gridApi.getSelectedRows();
      var res = gridApi.updateRowData({ remove: selectedData });
      // console.log("In onCellClicked() res is: ", res);

      // Update number of rows in grid
      getRowCount();
      initializeForm();

      // Disable Submit button if need be
      let gridRowsCount = gridApi.getModel().getRowCount();
      if (gridRowsCount === 0) {
        setGridSubmitFlag(false);
      }
    }
  };

  const onCellValueChanged = (event) => {
    // console.log('onCellValueChanged: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellValueChanged: event.colDef.field is: ', event.colDef.field);
  };

  const onRowSelected = (event) => {
    //console.log('onRowSelected: event is: ', event);
    //console.log('onRowSelected: event.data is: ', JSON.stringify(event.data, null, 2));
    setRowSelectedFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getSelectedNodes().length > 0
    ) {
      setRowSelectedFlag(true);
      // console.log('onRowSelected: after rowSelectedFlag is: ', rowSelectedFlag);
    }
  };

  const onRowClicked = (event) => {
    // console.log('onRowClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onRowClicked: event.data is: ', JSON.stringify(event.data, null, 2));
  };

  const onRowValueChanged = (event) => {
    // console.log('onRowValueChanged: event is:  ', event);
  };

  const onRowDataChanged = (event) => {
    // console.log('onRowDataChanged: event is: ', event);
    // setCreateRsrRes(createRsr);
    setColumnDefs(columnDefsArray);
    setLocaleTextRes(localeText);
    setGridSubmitFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getModel().getRowCount() > 0
    ) {
      // console.log('onRowDataChanged: gridApi.getModel().getRowCount() is: ', gridApi.getModel().getRowCount());
      setGridSubmitFlag(true);
    }
  };

  const onRowDataUpdated = (event) => {
    // console.log('onRowDataUpdated: event is:  ', event);
  };

  const onFirstDataRendered = (event) => {
    // console.log('onFirstDataRendered: event is:  ', event);
  };

  function clearGridData() {
    if (gridApi) {
      gridApi.setRowData([]);

      getRowCount();

      // Disable Submit button
      setGridSubmitFlag(false);
    }
  }

  function getDridData() {
    var gridData = [];
    gridApi.forEachNode(function (node) {
      gridData.push(node.data);
    });
    // console.log("In getDridData(): Grid Data is: ", gridData);
    return gridData;
  }

  function initializeForm() {
    // console.log("In initializeForm()");

    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setResourceCode("");
    setResourceDesc("");
  }

  function getRowCount() {
    let gridRowsCount = gridApi.getModel().getRowCount();
    setGridRowsCount(gridRowsCount);
    return gridRowsCount;
  }

  function addRecordToGrid() {
    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    if (!validateForm()) {
      // Validation Passed - you can proceed
      // console.log('Validation Passed.');
      setAddToGridFlag(false);
      setGridSubmitFlag(true);

      // Make sure max records allowed in grid is not exceeded
      if (getRowCount() >= MAX_RECORDS_ALLOWED) {
        setGlobalErrorMsg(
          maxRecordsAllowed.replace(
            "{MAX_RECORDS_ALLOWED}",
            MAX_RECORDS_ALLOWED
          )
        );
        setGlobalErrorFlag(true);
        return;
      }

      // Add form data to grid
      // console.log("In addRecordToGrid(): resourceLanguage is: ", resourceLanguage);
      let newItem = {
        resourceLanguageCode: resourceLanguage,
        resourceLanguageName: resourceLanguage,
        resourceCode: resourceCode,
        resourceDesc: resourceDesc,
      };
      var res = gridApi.updateRowData({ add: [newItem] });

      // Update number of rows in grid
      getRowCount();

      // Initialize Variables
      initializeForm();
    }
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    setSnackbarOpen(false);
  }

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);
    setResourceLanguage(selectedOption);

    // setResourceLanguage(selectedOption);
    setResourceCodeFocus(true);

    setAddToGridFlag(false);
    if (
      resourceCode !== undefined &&
      resourceCode !== null &&
      resourceCode !== "" &&
      resourceDesc !== undefined &&
      resourceDesc !== null &&
      resourceDesc !== "" &&
      selectedOption !== undefined &&
      selectedOption !== null &&
      selectedOption !== ""
    ) {
      setAddToGridFlag(true);
    }
  }

  function handleTextareaChange(event) {
    // console.log('In handleTextareaChange(): event is', event.target.value);
    setResourceDesc("");
    if (event.target.value) {
      setResourceDesc(event.target.value);
    }

    setAddToGridFlag(false);
    if (
      resourceCode !== undefined &&
      resourceCode !== null &&
      resourceCode !== "" &&
      event.target.value !== undefined &&
      event.target.value !== null &&
      event.target.value !== "" &&
      resourceLanguage !== undefined &&
      resourceLanguage !== null &&
      resourceLanguage !== ""
    ) {
      setAddToGridFlag(true);
    }
  }

  // For Mineral-ui
  const muiData = [
    {
      text: "Menu item",
      secondaryText: "Secondary text",
      value: "basic",
    },
    // {
    //   text: 'Icon at start',
    //   iconStart: <IconSentimentSatisfied />,
    //   value: 'iconStart'
    // },
    // {
    //   text: 'Icon at end',
    //   iconEnd: <IconSentimentSatisfied />,
    //   value: 'iconEnd'
    // },
    {
      divider: true,
    },
    {
      text: "Disabled menu item",
      onClick: () => {
        console.log("onClick is not triggered for disabled MenuItems");
      },
      disabled: true,
      value: "disabled",
    },
    {
      group: true,
      title: "Group Title",
    },
    {
      text: "Success variant",
      value: "success",
      variant: "success",
    },
    {
      text: "Warning variant",
      value: "warning",
      variant: "warning",
    },
    {
      text: "Danger variant",
      value: "danger",
      variant: "danger",
    },
  ];

  function cellStyle(params) {
    return { "white-space": "normal" };
  }

  //----------------------------
  // AG-GRID Column Definitions
  //----------------------------

  let columnDefsArray = [
    {
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressMenu: true,
      resizable: true,
    },
    {
      headerName: "languageCodeLabel",
      headerClass: classes.gridHeader,
      field: "resourceLanguageCode",
      width: 170,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: languageNameLabel,
      headerClass: classes.gridHeader,
      field: "resourceLanguageName",
      width: 220,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: false,
    },
    {
      headerName: resourceCodeLabel,
      headerClass: classes.gridHeader,
      field: "resourceCode",
      width: 350,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: false,
    },
    {
      headerName: resourceDescLabel,
      field: "resourceDesc",
      width: 400,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellStyle: cellStyle,
    },
    {
      headerName: deleteLabel,
      field: "delete",
      width: 100,
      filter: false,
      sortable: false,
      resizable: true,
      cellRendererFramework: ActionsCellRenderer,
    },
  ];

  const [columnDefs, setColumnDefs] = useState(columnDefsArray);

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* !appContext.signedIn &&
          <Redirect to='/signIn' />
        */}

        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%", height: "100%" }}>
            {/* progressFlag &&
              <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
              </div>
            */}

            <Container component="div" className={classes.main} maxWidth="md">
              <Grid container justify="center">
                <Grid item xs={12} sm={9}>
                  <StyledPaper elevation={3} className={classes.paper}>
                    <Typography
                      variant="h6"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      {createResources}
                    </Typography>

                    <Typography
                      variant="caption"
                      style={{ color: "green", textAlign: "center" }}
                    >
                      {allFieldsAreRequired}
                    </Typography>

                    {globalErrorFlag && (
                      <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                    )}

                    <LineSpacing value={24} />

                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="formLanguageId"
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {resourceLanguageLabel}
                        </Typography>
                      </label>
                      <Select
                        id="formLanguageId"
                        value={resourceLanguage}
                        placeholder={selectLanguagePrompt}
                        onChange={handleLanguageChange}
                        variant="outlined"
                        displayEmpty={true}
                        // autoFocus
                        fullWidth
                        style={{ width: "100%", flexGrow: 1 }}
                      >
                        <MenuItem value={null} disabled>
                          <Typography variant="body1">
                            {selectLanguage}
                          </Typography>
                        </MenuItem>
                        {langSelectOptions.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <label
                        htmlFor="languageCodeId"
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {resourceCodeLabel}
                        </Typography>
                      </label>
                      <OutlinedInput
                        id="resourceCodeId"
                        value={resourceCode}
                        placeholder={resourceCodePrompt}
                        onChange={handleChange}
                        required
                        fullWidth
                        autoFocus
                        type={"text"}
                        error={resourceCodeErrFlag}
                        margin="none"
                      />
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <label
                        htmlFor="resourceDescId"
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {resourceDescLabel}
                        </Typography>
                      </label>
                      <TextareaAutosize
                        id="resourceDescId"
                        aria-label="resource desc"
                        rows={2}
                        rowsMax={6}
                        value={resourceDesc}
                        placeholder={resourceDescPrompt}
                        onChange={handleTextareaChange}
                        required
                        fullWidth
                        error={resourceDescErrFlag}
                        margin="none"
                        className={classes.textArea}
                      />
                    </FormControl>

                    <LineSpacing value={18} />

                    <Button
                      variant="contained"
                      size="large"
                      disabled={!addToGridFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={addRecordToGrid}
                    >
                      <AddIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {addToGrid}
                    </Button>
                  </StyledPaper>
                </Grid>
              </Grid>
            </Container>

            {/* AG-GRID */}

            {renderGridFlag && (
              <Container component="div" className={classes.main} maxWidth="lg">
                <Grid container justify="center">
                  <Grid item xs>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!rowSelectedFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={deleteRows}
                      style={directionFloat}
                    >
                      <DeleteIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {deleteLabel}
                    </Button>
                  </Grid>

                  <Grid item xs>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!gridSubmitFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={resourceService}
                      style={directionReverseFloat}
                    >
                      <SaveIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {submit}
                    </Button>
                  </Grid>
                </Grid>

                <div style={{ width: "100%", height: "100%" }}>
                  <div
                    id="refGrid"
                    style={{
                      // height: "400px",
                      width: "100%",
                    }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      localeText={localeTextRes}
                      domLayout="autoHeight"
                      onGridReady={onGridReady}
                      columnDefs={columnDefs}
                      rowData={rowData}
                      rowSelection="multiple"
                      rowMultiSelectWithClick={true}
                      rowDeselection={true}
                      enablePivot={true}
                      enableValue={true}
                      gridOptions={gridOptions}
                      enableRtl={enableRtl}
                      onCellClicked={onCellClicked}
                      onCellValueChanged={onCellValueChanged}
                      onRowSelected={onRowSelected}
                      onRowClicked={onRowClicked}
                      onRowValueChanged={onRowValueChanged}
                      onRowDataChanged={onRowDataChanged}
                      onRowDataUpdated={onRowDataUpdated}
                      onFirstDataRendered={onFirstDataRendered}
                    />
                  </div>
                </div>
                <Typography
                  variant="caption"
                  color="primary"
                  style={directionReverseFloat}
                >
                  {totalNumberRows}: {gridRowsCount}
                </Typography>
              </Container>
            )}
          </div>

          {/* variant: 'success', 'warning', 'error', 'info' */}
          <TsiCustomizedSnackbars
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={8000}
            onClose={snackbarOnClose}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">{snackbarsMsg}</span>}
            variant={snackbarsVariant}
          />
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter isSignedIn={isSignedIn} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default CreateResources;
