import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import Draggable from "react-draggable";
// import styled, { css } from 'styled-components';
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
// import Button from '@material-ui/core/Button';
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
// import WarningIcon from '@material-ui/icons/Warning';
// import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
// import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';
// import { blue } from '@material-ui/core/colors';
import Container from "@material-ui/core/Container";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
// import ViewListIcon from '@material-ui/icons/ViewList';
// import ViewStreamIcon from '@material-ui/icons/ViewStream';
import TableChartIcon from '@material-ui/icons/TableChart';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// react-responsive
import { useMediaQuery } from 'react-responsive';

// Lodash
import lodash from "lodash";

//---------------------
// For Primereact
//---------------------
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
// import {InputText as InputTextPrime} from 'primereact/inputtext';
// import {Button as ButtonPrime} from 'primereact/button';
// import {Dropdown as DropdownPrime} from 'primereact/dropdown';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

// For Primereact
import { Dialog } from 'primereact/dialog';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';

// Amplify
import Amplify, { API, Storage } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import LineSpacing from "../misc/LineSpacing";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    modalDialog: {
        flexGrow: 1,
        maxWidth: "860px !important",
        // display: "flex",
        // flexDirection: "column",
        // position: "relative",
        // textAlign: "center",
      },
      closeButton: {
        // position: 'absolute',
        right: theme.spacing(1),
        color: theme.palette.grey[500],
        fontSize: "12px",
    },
    progress: {
        margin: theme.spacing(2),
    },
    mainGrid: {
        marginTop: theme.spacing(1),
        textAlign: "left",
    },
    formControl: {
        flex: 1,
        display: "flex",
        textAlign: "left",
        width: "100%",
    },
}));

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

let pageLabels = {
    mediaSelectionLabel: "Media selection",
    // selectImagePrompt: "Click an image/photo to select",
    // clickToSelect: "Click to select",

    mediaLabel: "Media",
    categoryLabel: "Category",
    captionLabel: "Caption",
    descLabel: "Description",
    mediaNameLabel: "Media name",

    toLabel: "to",
    ofLabel: "of",
    recordsLabel: "records",

    tableViewLabel: "Table view",
    gridViewLabel: "Grid view",
    noRecordsFound: "No records found",
    
    filterByCategory: "Filter by category",
    filterSelectionErrMsg: "Filter selection is required",

    loadingText: "Loading ...",
    noOptionsText: "No options",
    clearText: "Clear",
    closeText: "Close",

    allRecordsLabel: "All records",
};

function CommonMediaSelector(props) {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);
    const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);
    const [renderDomFlag, setRenderDomFlag] = useState(false);
    const [progressFlag, setProgressFlag] = useState(false);
    const [renderVideoFlag, setRenderVideoFlag] = useState(false);

    // const [gridData, setGridData] = useState(staticGridData);
    const [filterDisabledFlag, setFilterDisabledFlag] = useState(true);
    const [paginationPageSize, setPaginationPageSize] = useState(5);

    const [wrapperRef, setWrapperRef] = useState();

    const [open, setOpen] = useState(false);
    // const iconSearch = <IconSearch style={{ color: "green" }} />;

    //-------------------------
    // For Primereact 
    // DataTable Attributes 
    //-------------------------
    const [mediaData, setMediaData] = useState([]);
    const [mediaDataBackup, setMediaDataBackup] = useState([]);
    
    const [resizableColumnsFlag, setResizableColumnsFlag] = useState(true);
    const [columnResizeMode, setColumnResizeMode] = useState("fit");
    const [sortMode, setSortMode] = useState("multiple");
    const [responsiveFlag, setResponsiveFlag] = useState(true);
    const [reorderableColumnsFlag, setReorderableColumnsFlag] = useState(true);
    const [paginatorFlag, setPaginatorFlag] = useState(true);
    const [paginatorTemplate, setPaginatorTemplate] = useState("FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown");
    // const [currentPageReportTemplate, setCurrentPageReportTemplate] = useState(null);
    const [visibleRowsCount, setVisibleRowsCount] = useState(4);
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([3,4,5,10,20,50,100]);

    const [selectionMode, setSelectionMode] = useState("single");// single or multiple
    const [selectedTableRow, setSelectedTableRow] = useState(null);// For single selection
    // const [selectedTableRows, setSelectedTableRows] = useState([]);// For multiple selection
    // const [updatedTableRows, setUpdatedTableRows] = useState([]); // For updated (edited) table rows
    // const [tableDirtyFlag, setTableDirtyFlag] = useState(false); // To show DataTable cell was edited (modified)

    // const [deleteRowFlag, setDeleteRowFlag] = useState(false);
    const [displayViewType, setDisplayViewType] = useState("gridView");// gridView or tableView
    const [dataViewVisibleCount, setDataViewVisibleCount] = useState(30);
    const [dataViewPaginatorFlag, setDataViewPaginatorFlag] = useState(false);

    const [layout, setLayout] = useState("grid");
  
    //--------------------------
    // For Filtering Categories
    //--------------------------
    const [category, setCategory] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [renderGalleryFlag, setRenderGalleryFlag] = useState(false);
    const [categoryErrFlag, setCategoryErrFlag] = useState(false);
    
    const [prevLanguageCode, setPrevLanguageCode] = useState("");
    const [prevDomainName, setPrevDomainName] = useState("");
    

    const {
        dialogOpenFlag,
        onRowSelectedCallback,
        dialogCloseCallback,
        
        mediaLanguageCode,
        mediaDomainName,
        mediaActionFlag,
        queryType,

        forUserOnly,

        // alertClickAgree,
        // alertClickDisagree,

        // dialogTitle,
        // dialogQuestion,
        // dialogMainContent,
        // dialogSubContent,
        // yesLabel,
        // noLabel,
    } = props;

    // For AWS Amplify Storage
    const [storageLevel, setStorageLevel] = useState("private"); // public, protected or private
    const [domain, setDomain] = useState(appContext.domain); // ccms
    const [userName, setUserName] = useState(appContext.userName); // "onyii5119@gmail.com"
    const [customPrefix, setCustomPrefix] = useState(null);
    const [contentType, setContentType] = useState("text/plain");
    const [cacheControl, setCacheControl] = useState(null);
    const [contentDisposition, setContentDisposition] = useState("");
    const [expires, setExpires] = useState(3 * 60); // Expires in 3 minutes
    const [metadata, setMetadata] = useState(null);
    const [presignedURLs, setPresignedURLs] = useState([]);
    const [imageCount, setImageCount] = useState(0);
    
    //-----------------------
    // For Primereact Dialog
    //-----------------------
    const [dlgVisibleFlag, setDlgVisibleFlag] = useState(true);// default true
    // const [dialogType, setDialogType] = useState("liveEventDesc");

    const classes = useStyles();

    const directionFloat = {
        float: appContext.direction === "ltr" ? "left" : "right",
    };

    const directionReverseFloat = {
        float: appContext.direction === "ltr" ? "right" : "left",
    };

    const {
        mediaSelectionLabel,
        // selectImagePrompt,
        // clickToSelect,

        mediaLabel,
        categoryLabel,
        captionLabel,
        descLabel,
        mediaNameLabel,

        toLabel,
        ofLabel,
        recordsLabel,

        tableViewLabel,
        gridViewLabel,
        noRecordsFound,
    
        filterByCategory,
        filterSelectionErrMsg,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,

        allRecordsLabel,
    } = pageLabelsRes;

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    async function fetchDbResources(cacheItemKey) {
        let keys = [];
        let objKeys = Object.keys(pageLabels);
        // console.log("In CommonMediaSelector(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
        if (objKeys !== null && objKeys.length > 0) {
            objKeys.map((key) => {
                let partitionKey = appContext.currentLanguage.languageCode;
                partitionKey += ".";
                partitionKey += GenerateModulusHash(key, 200);

                let itemKey = {
                    ResourcePK: partitionKey, // e.g. 'en.91'
                    ResourceSK: key, // e.g. 'firstName'
                };
                keys.push(itemKey);
            });
        }
        // console.log("In CommonMediaSelector(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

        let arrayData = [];

        setProgressFlag(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/batchGet",
            payload: {
                keys: keys,
                projectionExpression: "ResourceCode,ResourceDesc",
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
            // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
            response.data.Responses.resource.map((item, index) => {
                let record = {
                    ResourceCode: item.ResourceCode,
                    ResourceDesc: item.ResourceDesc,
                };

                arrayData.push(record);
            });

            if (arrayData && arrayData.length > 0) {
                arrayData.map((item) => {
                    if (item.ResourceCode === "mediaSelectionLabel") pageLabels.mediaSelectionLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "selectImagePrompt") pageLabels.selectImagePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "clickToSelect") pageLabels.clickToSelect = item.ResourceDesc;
                    
                    if (item.ResourceCode === "mediaLabel") pageLabels.mediaLabel = item.ResourceDesc;
                    if (item.ResourceCode === "categoryLabel") pageLabels.categoryLabel = item.ResourceDesc;
                    if (item.ResourceCode === "captionLabel") pageLabels.captionLabel = item.ResourceDesc;
                    if (item.ResourceCode === "descLabel") pageLabels.descLabel = item.ResourceDesc;
                    if (item.ResourceCode === "mediaNameLabel") pageLabels.mediaNameLabel = item.ResourceDesc;
                    
                    if (item.ResourceCode === "toLabel") pageLabels.toLabel = item.ResourceDesc;
                    if (item.ResourceCode === "ofLabel") pageLabels.ofLabel = item.ResourceDesc;
                    if (item.ResourceCode === "recordsLabel") pageLabels.recordsLabel = item.ResourceDesc;
                                                    
                    if (item.ResourceCode === "tableViewLabel") pageLabels.tableViewLabel = item.ResourceDesc;
                    if (item.ResourceCode === "gridViewLabel") pageLabels.gridViewLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noRecordsFound") pageLabels.noRecordsFound = item.ResourceDesc;
                                                    
                    if (item.ResourceCode === "filterByCategory") pageLabels.filterByCategory = item.ResourceDesc;
                    if (item.ResourceCode === "filterSelectionErrMsg") pageLabels.filterSelectionErrMsg = item.ResourceDesc;
                        
                    if (item.ResourceCode === "loadingText") pageLabels.loadingText = item.ResourceDesc;
                    if (item.ResourceCode === "noOptionsText") pageLabels.noOptionsText = item.ResourceDesc;
                    if (item.ResourceCode === "clearText") pageLabels.clearText = item.ResourceDesc;
                    if (item.ResourceCode === "closeText") pageLabels.closeText = item.ResourceDesc;

                    if (item.ResourceCode === "allRecordsLabel") pageLabels.allRecordsLabel = item.ResourceDesc;
                });
                // Add it to cache
                SetCacheItem(cacheItemKey, pageLabels);
            }
            setPageLabelsRes(pageLabels);
            setRenderDomFlag(true);
            setProgressFlag(false);
        }).catch((err) => {
            console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
            setPageLabelsRes(pageLabels);
            setRenderDomFlag(true);
            setProgressFlag(false);
        });
    }

    function handleClickOutside(event) {
        if (wrapperRef && !wrapperRef.contains(event.target) && event.target.tagName === "DIV") {
        //console.log('You clicked outside of me! tagName is: '+event.target.tagName);
        setOpen(false);
        }
    }

    let mediaRecordsArray = [];

    function fetchMediaRecords() {
        // console.log('In CommonMediaSelector fetchMediaRecords(): mediaDomainName is: ', mediaDomainName);
        // console.log('In CommonMediaSelector fetchMediaRecords(): mediaLanguageCode is: ', mediaLanguageCode);
        // console.log('In CommonMediaSelector fetchMediaRecords(): queryType is: ', queryType);
        console.log('In CommonMediaSelector fetchMediaRecords(): forUserOnly is: ', forUserOnly);
        console.log('In CommonMediaSelector fetchMediaRecords(): appContext.userName is: ', appContext.userName);
        
        setProgressFlag(true);

        mediaRecordsArray = [];
        setMediaData([]);
        setMediaDataBackup([]);

        // For testing
        // appContext.deceasedCode = "queen";

        let apiName = null;
        let path = null;
        // let keyConditionExpression = null;
        // let expressionAttributeNames = {};
        // let expressionAttributeValues = {};
        let payload = null;

        apiName = "MediaAPI";
        path = "/dynamodb/media/query";
        payload = {
            keyConditionExpression: "#mediaPK = :mediaPK AND begins_with(#mediaSK, :mediaSK)",
            expressionAttributeNames: {
                "#mediaPK": "MediaPK",
                "#mediaSK": "MediaSK"
            },
            expressionAttributeValues: {
                ":mediaPK": mediaDomainName + "#" + mediaLanguageCode + "#" + queryType,
                ":mediaSK": (typeof forUserOnly !== 'undefined' && forUserOnly) ? appContext.userName + "#" + mediaLanguageCode + "#" + queryType : mediaDomainName + "#" + mediaLanguageCode + "#" + queryType,
            },
            // indexName: "MediaDomain-LangAuthorUserName-index"
        };
    
        let params = {
            apiName: apiName,
            path: path,
            payload: payload,
        };
    
        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
    
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchMediaRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
    
        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In CommonMediaSelector fetchMediaRecords(): response.data.Items.length is: ', response.data.Items.length);
            // console.log('In CommonMediaSelector fetchMediaRecords(): response is: ', JSON.stringify(response.data.Items, null, 2));

            response.data.Items.map((item) => {
                let mediaKey = null;
                let removeKey = null;
                
                if (queryType && queryType === "media") {
                    if (isDeskTopView) {
                        mediaKey = appContext.storageCfUrl + item.MediaS3SubKey + "small300/" + item.MediaName;
                        removeKey = item.MediaS3SubKey + "small300/" + item.MediaName;

                        // if (item.MediaS3SubKey && (item.MediaS3SubKey.includes("banners") || item.MediaS3SubKey.includes("favicons"))) {
                        //     mediaKey = appContext.storageCfUrl + item.MediaS3SubKey + "original/" + item.MediaName;
                        //     removeKey = item.MediaS3SubKey + "original/" + item.MediaName;
                        // } else {
                        //     mediaKey = appContext.storageCfUrl + item.MediaS3SubKey + "small300/" + item.MediaName;
                        //     removeKey = item.MediaS3SubKey + "small300/" + item.MediaName;
                        // }
                    } else if (isTabletView) {
                        mediaKey = appContext.storageCfUrl + item.MediaS3SubKey + "small300/" + item.MediaName;
                        removeKey = item.MediaS3SubKey + "small300/" + item.MediaName;

                        // if (item.MediaS3SubKey && (item.MediaS3SubKey.includes("banners") || item.MediaS3SubKey.includes("favicons"))) {
                        //     mediaKey = appContext.storageCfUrl + item.MediaS3SubKey + "original/" + item.MediaName;
                        //     removeKey = item.MediaS3SubKey + "original/" + item.MediaName;
                        // } else {
                        //     mediaKey = appContext.storageCfUrl + item.MediaS3SubKey + "small300/" + item.MediaName;
                        //     removeKey = item.MediaS3SubKey + "small300/" + item.MediaName;
                        // }
                    } else if (isMobileView) {
                        mediaKey = appContext.storageCfUrl + item.MediaS3SubKey + "small300/" + item.MediaName;
                        removeKey = item.MediaS3SubKey + "small300/" + item.MediaName;

                        // if (item.MediaS3SubKey && (item.MediaS3SubKey.includes("banners") || item.MediaS3SubKey.includes("favicons"))) {
                        //     mediaKey = appContext.storageCfUrl + item.MediaS3SubKey + "original/" + item.MediaName;
                        //     removeKey = item.MediaS3SubKey + "original/" + item.MediaName;
                        // } else {
                        //     mediaKey = appContext.storageCfUrl + item.MediaS3SubKey + "small300/" + item.MediaName;
                        //     removeKey = item.MediaS3SubKey + "small300/" + item.MediaName;
                        // }
                    }
                } else {
                    mediaKey = appContext.storageCfUrl + item.MediaS3FullKey; // For Videos
                    removeKey = item.MediaS3FullKey;
                }

                let record = {
                    mediaPK: item.MediaPK,
                    mediaSK: item.MediaSK,
                    
                    languageCode: item.LanguageCode,
                    languageObject: item.LanguageObject,

                    mediaRefId: item.MediaRefId,
                    mediaName: item.MediaName,

                    mediaS3Key: mediaKey, 
                    mediaS3RemoveKey: removeKey, 

                    mediaCategoryCode: item.MediaCategory ? item.MediaCategory.value : "",
                    mediaCategory: item.MediaCategory ? item.MediaCategory : null,

                    mediaType: item.MediaType ? item.MediaType : null,
                    mediaTypeText: item.MediaType ? item.MediaType.text : "",
                  
                    mediaCaption: lodash.isEmpty(item.MediaCaption) ? "" : item.MediaCaption,
                    mediaDesc: lodash.isEmpty(item.MediaDesc) ? "" : item.MediaDesc,
                  
                    modifiedBy: item.ModifiedBy,
                    createdAt: item.CreatedAt,
                    updatedAt: item.UpdatedAt,
                };
          
                mediaRecordsArray.push(record);
            });
    
            mediaRecordsArray.sort((a, b) => (b.updatedAt > a.updatedAt ? 1 : -1));// Sort descending order
            // setOriginalAppreciationData(mediaRecordsArray);
            if (mediaRecordsArray.length > dataViewVisibleCount) {
                setDataViewPaginatorFlag(true);
            }
            // console.log('In CommonMediaSelector fetchMediaRecords(): mediaRecordsArray.length is: ', mediaRecordsArray.length);
            setMediaData(mediaRecordsArray);
            setMediaDataBackup(mediaRecordsArray);

            mediaRecordsArray = [];
            setProgressFlag(false);
        }).catch((err) => {
            console.error("In CommonMediaSelector fetchMediaRecords(): err is: ", JSON.stringify(err, null, 2));
            setProgressFlag(false);
        });
    }

    let  refDataSelectOptions = [];
    
    async function fetchReferenceData(refType, lang) {// In this case lang is languageCode
        return new Promise((resolve, reject) => {
            // console.log('In fetchReferenceData(): refType is: ', refType);
            // console.log('In fetchReferenceData(): lang is: ', lang);
            backdropProgress(true);

            refDataSelectOptions = [];

            let apiName = null;
            let path = null;
            let keyConditionExpression = null;
            let expressionAttributeNames = {};
            let expressionAttributeValues = {};
            
            switch(refType) {
                case "media-categories":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "media-categories",
                        ":resourceSK": lang ? lang : appContext.currentLanguage.languageCode,
                    };
                    break;
                default:
                    break;
            }

            setProgressFlag(true);
        
            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    keyConditionExpression: keyConditionExpression,
                    expressionAttributeNames: expressionAttributeNames,
                    expressionAttributeValues: expressionAttributeValues,
                    // "keyConditionExpression": "#resourcePK = :resourcePK",
                    // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                    // "expressionAttributeValues": {":resourcePK": "languages"}
                },
            };
        
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
        
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In  fetchReferenceData(): initObj is: ', JSON.stringify(initObj, null, 2));
        
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In  fetchReferenceData(): response.length is: ', response.data.Items.length);
                // console.log('In  fetchReferenceData(): response is: ', JSON.stringify(response.data.Items, null, 2));
                response.data.Items.map((item) => {
                    let record = {
                        value: item.ResourceCode,
                        text: item.ResourceDesc,
                    };

                    if (record.value !== "all") {// Bypass all
                        refDataSelectOptions.push(record);
                    }
                });

                switch(refType) {
                    case "media-categories":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setCategoryOptions(refDataSelectOptions);
                        break;
                    default:
                        break;
                }

                backdropProgress(false);
                resolve(refDataSelectOptions);
            }).catch((err) => {
                console.error("In fetchReferenceData(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject(err);
            });
        });
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // console.log("In CommonMediaSelector(): useEffect() 1");

        // console.log('In CommonMediaSelector(): useEffect(): mediaActionFlag is: ', mediaActionFlag);
        // console.log('In CommonMediaSelector(): useEffect(): queryType is: ', queryType);
        // console.log('In CommonMediaSelector(): useEffect(): mediaDomainName is: ', mediaDomainName);
        // console.log('In CommonMediaSelector(): useEffect(): mediaLanguageCode is: ', mediaLanguageCode);
        
        if (mediaActionFlag && mediaLanguageCode) {
            fetchReferenceData("media-categories", mediaLanguageCode);
        }
        
        if (mediaActionFlag && mediaDomainName && mediaLanguageCode && (mediaLanguageCode !== prevLanguageCode)) {
            setPrevLanguageCode(mediaLanguageCode);
            fetchMediaRecords();
        }
    }, [mediaLanguageCode]);

    useEffect(() => {
        // console.log("In CommonMediaSelector(): useEffect() 2");

        // console.log('In CommonMediaSelector(): useEffect(): mediaActionFlag is: ', mediaActionFlag);
        // console.log('In CommonMediaSelector(): useEffect(): queryType is: ', queryType);
        // console.log('In CommonMediaSelector(): useEffect(): mediaDomainName is: ', mediaDomainName);
        // console.log('In CommonMediaSelector(): useEffect(): mediaLanguageCode is: ', mediaLanguageCode);

        if (mediaActionFlag && mediaDomainName && mediaLanguageCode && (mediaDomainName !== prevDomainName)) {
            setPrevDomainName(mediaDomainName);
            fetchMediaRecords();
        }
    }, [mediaDomainName]);

    useLayoutEffect(() => {
        // console.log("In CommonMediaSelector(): useLayoutEffect(): isSignedIn is: ", isSignedIn);
        // console.log("In CommonMediaSelector(): useLayoutEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));
        // console.log("In CommonMediaSelector(): useLayoutEffect(): languagesLabel is: ", JSON.stringify(languagesLabel, null, 2));
        // fetchImagesFromS3();
        async function fetchItems() {
            // let dataLanguage = appContext.currentLanguage.languageCode;
            // let dataLang = sessionStorage.getItem("data-language");
            // if (!lodash.isEmpty(dataLang)) {
            //     dataLanguage = JSON.parse(dataLang);
            // }

            // let mediaDomainName = sessionStorage.getItem("media-domain-name");

            // console.log('In CommonMediaSelector(): useLayoutEffect(): mediaDomainName is: ', mediaDomainName);
            // console.log('In CommonMediaSelector(): useLayoutEffect(): dataLanguage is: ', dataLanguage);

            // Fetch resource from catche
            let cacheItemKey = "common-image-selector";
            cacheItemKey += ":";
            cacheItemKey += appContext.currentLanguage.languageCode;
            // console.log('In CommonMediaSelector(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

            if (
                appContext.clearCacheLanguageCodes &&
                appContext.clearCacheLanguageCodes.length > 0 &&
                appContext.clearCacheLanguageCodes.includes(appContext.currentLanguage.languageCode)
            ) {
                // console.log("In CommonMediaSelector(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                RemoveCacheItem(cacheItemKey);
            }

            let cacheItem = GetCacheItem(cacheItemKey);
            // console.log('In CommonMediaSelector(): useLayoutEffect(): cacheItem is: ', cacheItem);
            cacheItem = null; // Remove after testing

            if (cacheItem === null) {
                // resource is not in cache, fetch from DB
                // console.log('In CommonMediaSelector(): useLayoutEffect(): resource is not in cache, fetch from DB');
                await fetchDbResources(cacheItemKey);
            } else {
                // console.log('In CommonMediaSelector(): useLayoutEffect(): got resource from cache');
                setPageLabelsRes(cacheItem);
                setRenderDomFlag(true);
            }
        }
    
        fetchItems();
    }, []);

    //-------------------------
    // For Primereact 
    // DataTable selection 
    //-------------------------
    function handleSingleSelectionChange(data) {
        // console.log("In handleSingleSelectionChange(): data.value is: ", data.value);
        setSelectedTableRow(data.value);
        onRowSelectedCallback(data.value.mediaS3Key);
    }

    //-------------------------
    // For Primereact 
    // DataTable misc 
    //-------------------------
    // const paginatorLeft = <ButtonPrime icon="pi pi-refresh"/>;
    
    // function deleteTableRow(rowData, rowIndex) {
    //     // console.log("In deleteTableRow(): rowData is: ", rowData);
    //     // console.log("In deleteTableRow(): rowIndex is: ", rowIndex);
    //     setDeleteRowFlag(false);
    // }

    const header = renderHeader();

    function displayViewAction(type) {
        if (type && type === "gridView") {
            setDisplayViewType("gridView");
            // setSummaryFlag(false);
            // setTableOrListFlag(true);
        } else {
            setDisplayViewType("tableView");
            // setTableOrListFlag(false);
            // setSummaryFlag(true);
        }
    }

    function handleReferenceCategoriesChange(event, item) {
        // event.preventDefault();
        // event.persist();
        // console.log("In handleReferenceCategoriesChange(): item is ", item);
        // console.log("In handleReferenceCategoriesChange(): mediaDataBackup is ", mediaDataBackup);

        setCategory(item);

        setMediaData([]);
        let filteredRecords = [];

        if (item && mediaDataBackup && mediaDataBackup.length > 0) {
            // console.log("In handleReferenceCategoriesChange(): item.value is ", item.value);
            if (item.value === "all") {
                setMediaData(mediaDataBackup);
                return;
            }

            // if (!item) {
            //     setMediaData(mediaDataBackup);
            //     return;
            // }

            // filteredRecords = mediaDataBackup.filter(record => record.mediaSK.includes(item.value));
            filteredRecords = mediaDataBackup.filter(record => record.mediaType.value === item.value);
            if (filteredRecords) {
                // console.log("In handleReferenceCategoriesChange(): filteredRecords.length is ", filteredRecords.length);
                setMediaData(filteredRecords);
            }
        }
    }

    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    //-------------------------
    // For Primereact DataView 
    //-------------------------
 
    function imageTemplate(rowData, column) {
        // var src = "https://primefaces.org/primereact/showcase/showcase/demo/images/avatar/ionibowcher.png";
        var src = rowData.mediaS3Key;
        return <img src={src} alt={rowData.caption} style={{width: '100px'}}/>;
        // return <Avatar alt="Remy Sharp" src={src} className={classes.large} />
    }

    function dataViewImageClicked(record) {
        onRowSelectedCallback(record.mediaS3Key);
    }

    function onOptionChange(e) {
        // setLoadingFlag(true);

        setTimeout(() => {
            // setLoadingFlag(true);
            setLayout(e.value);
        }, 1000);
    };

    function renderHeader() {
        return (
            <Grid item xs={12} sm={12}>
                <Grid container justify="flex-end">
                    <DataViewLayoutOptions layout={layout} onChange={onOptionChange} />
                </Grid>
            </Grid>
        );
    }

    function itemTemplate(data, layout) {
        let src = data.mediaS3Key;
    
        if (layout === 'grid') {
            return (
                <>
                    {isDeskTopView &&
                        <Grid item xs={6} sm={4}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center">
                                        <img src={src} alt={data.caption} 
                                            style={{cursor: 'pointer', maxWidth: '220px', height: '84px'}}
                                            onClick={() => dataViewImageClicked(data)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center">
                                        <Typography variant="caption" style={{display: "block", paddingBottom: '4px'}}>
                                            {data.mediaName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {isTabletView &&
                        <Grid item xs={6} sm={4}>
                            <Grid container justify="center" style={{background: '#fff'}}>
                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center">
                                        <img src={src} alt={data.caption} 
                                            style={{cursor: 'pointer', maxWidth: '220px', height: '84px'}}
                                            onClick={() => dataViewImageClicked(data)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center">
                                        <Typography variant="caption" style={{display: "block", paddingBottom: '4px'}}>
                                            {data.mediaName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                    {isMobileView &&
                        <Grid item xs={6} sm={6}>
                            <Grid container justify="center" style={{background: '#fff'}}>
                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center">
                                        <img src={src} alt={data.caption} 
                                            style={{cursor: 'pointer', height: '64px'}}
                                            onClick={() => dataViewImageClicked(data)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center">
                                        <Typography variant="caption" style={{display: "block", paddingBottom: '4px'}}>
                                            {data.mediaName}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </>
            );
        }
    }

    //========================================================================================

    return (
        <div className={classes.root} style={{width: "100%"}}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {isDeskTopView &&
                <>
                    <Container component="div" className={classes.main} maxWidth="md">
                        <Dialog 
                            id="modalDlgId"
                            rtl={appContext.dirction === 'rtl'}
                            showHeader={true}
                            header={mediaSelectionLabel}
                            visible={dialogOpenFlag} 
                            maximizable={true}
                            maximized={isDeskTopView ? false : true}
                            modal={true} 
                            onHide={dialogCloseCallback}
                            className={classes.modalDialog}
                            >
                            <Container component="div" className={classes.mainGrid} maxWidth="md">
                                <div style={{ width: "100%", height: "100%" }}>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={6} sm={6}>
                                                {displayViewType === "gridView" &&
                                                    <Grid container justify={direction === 'ltr' ? 'flex-start' : 'flex-end'}>
                                                        <FormControl className={classes.formControl} error={categoryErrFlag}>
                                                            <label htmlFor="categoryId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={categoryErrFlag ? "secondary" : "inherit"}>
                                                                    *{filterByCategory}
                                                                </Typography>
                                                            </label>

                                                            <Autocomplete
                                                                id="categoryId"
                                                                value={category}
                                                                options={categoryOptions}
                                                                getOptionLabel={option => option.text}
                                                                getOptionSelected={option => option.text}
                                                                onChange={handleReferenceCategoriesChange}
                                                                loadingText={loadingText}
                                                                noOptionsText={noOptionsText}
                                                                autoHighlight={true}
                                                                clearText={clearText}
                                                                closeText={closeText}
                                                                style={{display: 'flex', flex: 1}}
                                                                renderInput={params => 
                                                                    <TextField 
                                                                        {...params} 
                                                                        placeholder={filterByCategory} 
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        error={categoryErrFlag}
                                                                        helperText={categoryErrFlag ? filterSelectionErrMsg : ""}
                                                                        style={{color: 'green'}}
                                                                    />
                                                                }
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                }
                                            </Grid>

                                            <Grid item xs={6} sm={6}>
                                                <Grid container justify="flex-end" spacing={1}>
                                                    <ToggleButtonGroup orientation="horizontal">
                                                        <ToggleButton value="gridView" aria-label={gridViewLabel}>
                                                            <Tooltip title={gridViewLabel} placement="bottom" style={{cursor: "pointer"}}>
                                                                <ViewComfyIcon fontSize="default" 
                                                                    color={displayViewType === 'gridView' ? 'primary' : 'action'} 
                                                                    onClick={() => displayViewAction('gridView')}
                                                                />
                                                            </Tooltip>
                                                        </ToggleButton>

                                                        <ToggleButton value="tableView" aria-label={tableViewLabel}>
                                                            <Tooltip title={tableViewLabel} placement="bottom" style={{cursor: "pointer"}}>
                                                                <TableChartIcon fontSize="default" 
                                                                    className={clsx(classes.leftIcon)}
                                                                    color={displayViewType === 'tableView' ? 'primary' : 'action'} 
                                                                    onClick={() => displayViewAction('tableView')}
                                                                />
                                                            </Tooltip>
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={4} />
                                    </Grid>

                                    {displayViewType === "gridView" &&
                                        <Grid item xs={12} sm={12}>
                                            <DataView 
                                                value={mediaData} 
                                                layout={layout} 
                                                itemTemplate={itemTemplate}
                                                paginator={dataViewPaginatorFlag}
                                                rows={dataViewVisibleCount}
                                                // header={header}
                                                >
                                            </DataView>
                                        </Grid>
                                    }

                                    {displayViewType === "tableView" &&
                                        <div className="datatable-doc-demo">
                                            <DataTable 
                                                // ref={(el) => videoTableRef = el}
                                                value={mediaDataBackup}
                                                resizableColumns={resizableColumnsFlag} 
                                                columnResizeMode={columnResizeMode}
                                                sortMode={sortMode}
                                                responsive={responsiveFlag}
                                                reorderableColumns={reorderableColumnsFlag}

                                                selectionMode={selectionMode}
                                                selection={selectedTableRow}
                                                onSelectionChange={handleSingleSelectionChange}

                                                paginator={paginatorFlag} 
                                                // paginatorLeftpaginatorLeft={paginatorLeft}

                                                paginatorTemplate={paginatorTemplate}
                                                currentPageReportTemplate={"{first}" + " " + toLabel + " " + "{last}" + " " + ofLabel + " " + "{totalRecords}" + " " + recordsLabel} 
                                                rows={visibleRowsCount} 
                                                rowsPerPageOptions={rowsPerPageOptions}

                                                emptyMessage={noRecordsFound}
                                                className={clsx("p-datatable-striped", "p-datatable-customers")}
                                                rowHover 
                                                >
                                                <Column 
                                                    field="media" 
                                                    header={mediaLabel} 
                                                    body={imageTemplate} 
                                                    style={{width:'10em', textAlign:'center'}}
                                                />
                                                <Column 
                                                    field="mediaTypeText" 
                                                    header={categoryLabel} 
                                                    sortable={true} 
                                                    filter={true} 
                                                    filterMatchMode="contains" 
                                                    // style={{width:'16em'}}
                                                />
                                                <Column 
                                                    field="mediaName" 
                                                    header={mediaNameLabel} 
                                                    sortable={true} 
                                                    filter={true} 
                                                    filterMatchMode="contains" 
                                                    // editor={captionEditor} 
                                                    // editorValidator={captionValidator}
                                                    // style={{background:'#f9e6f0'}}
                                                />
                                                <Column 
                                                    field="mediaCaption" 
                                                    header={captionLabel} 
                                                    sortable={true} 
                                                    filter={true} 
                                                    filterMatchMode="contains" 
                                                    // editor={captionEditor} 
                                                    // editorValidator={captionValidator}
                                                    // style={{background:'#f9e6f0'}}
                                                />
                                                {/* <Column 
                                                    field="mediaDesc" 
                                                    header={descLabel} 
                                                    sortable={true} 
                                                    filter={true} 
                                                    filterMatchMode="contains" 
                                                    // editor={descriptionEditor} 
                                                    // editorValidator={descriptionValidator}
                                                    // style={{background:'#e6e6fa'}}
                                                /> */}
                                            </DataTable>
                                        </div>
                                    }
                                </div>
                            </Container>
                        </Dialog>
                    </Container>
                </>
            }

            {isTabletView &&
                <Container component="div" className={classes.main} maxWidth="md">
                    <Dialog 
                        id="modalDlgId"
                        rtl={appContext.dirction === 'rtl'}
                        showHeader={true}
                        header={mediaSelectionLabel}
                        visible={dialogOpenFlag} 
                        maximizable={true}
                        maximized={isDeskTopView ? false : true}
                        modal={true} 
                        onHide={dialogCloseCallback}
                        className={classes.modalDialog}
                        >
                        <Container component="div" className={classes.mainGrid} maxWidth="md">
                            <div style={{ width: "100%", height: "100%" }}>
                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center">
                                        <Grid item xs={6} sm={6}>
                                            {displayViewType === "gridView" &&
                                                <Grid container justify={direction === 'ltr' ? 'flex-start' : 'flex-end'}>
                                                    <FormControl className={classes.formControl} error={categoryErrFlag}>
                                                        <label htmlFor="categoryId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={categoryErrFlag ? "secondary" : "inherit"}>
                                                                *{filterByCategory}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="categoryId"
                                                            value={category}
                                                            options={categoryOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleReferenceCategoriesChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            style={{display: 'flex', flex: 1}}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={filterByCategory} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={categoryErrFlag}
                                                                    helperText={categoryErrFlag ? filterSelectionErrMsg : ""}
                                                                    style={{color: 'green'}}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            }
                                        </Grid>

                                        <Grid item xs={6} sm={6}>
                                            <Grid container justify="flex-end" spacing={1}>
                                                <ToggleButtonGroup orientation="horizontal">
                                                    <ToggleButton value="gridView" aria-label={gridViewLabel}>
                                                        <Tooltip title={gridViewLabel} placement="bottom" style={{cursor: "pointer"}}>
                                                            <ViewComfyIcon fontSize="default" 
                                                                color={displayViewType === 'gridView' ? 'primary' : 'action'} 
                                                                onClick={() => displayViewAction('gridView')}
                                                            />
                                                        </Tooltip>
                                                    </ToggleButton>

                                                    <ToggleButton value="tableView" aria-label={tableViewLabel}>
                                                        <Tooltip title={tableViewLabel} placement="bottom" style={{cursor: "pointer"}}>
                                                            <TableChartIcon fontSize="default" 
                                                                className={clsx(classes.leftIcon)}
                                                                color={displayViewType === 'tableView' ? 'primary' : 'action'} 
                                                                onClick={() => displayViewAction('tableView')}
                                                            />
                                                        </Tooltip>
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <LineSpacing value={4} />
                                </Grid>

                                {displayViewType === "gridView" &&
                                    <Grid item xs={12} sm={12}>
                                        <DataView 
                                            value={mediaData} 
                                            layout={layout} 
                                            itemTemplate={itemTemplate}
                                            paginator={dataViewPaginatorFlag}
                                            rows={dataViewVisibleCount}
                                            // header={header}
                                            >
                                        </DataView>
                                    </Grid>
                                }

                                {displayViewType === "tableView" &&
                                    <div className="datatable-doc-demo">
                                        <DataTable 
                                            // ref={(el) => videoTableRef = el}
                                            value={mediaDataBackup}
                                            resizableColumns={resizableColumnsFlag} 
                                            columnResizeMode={columnResizeMode}
                                            sortMode={sortMode}
                                            responsive={responsiveFlag}
                                            reorderableColumns={reorderableColumnsFlag}

                                            selectionMode={selectionMode}
                                            selection={selectedTableRow}
                                            onSelectionChange={handleSingleSelectionChange}

                                            paginator={paginatorFlag} 
                                            // paginatorLeftpaginatorLeft={paginatorLeft}

                                            paginatorTemplate={paginatorTemplate}
                                            currentPageReportTemplate={"{first}" + " " + toLabel + " " + "{last}" + " " + ofLabel + " " + "{totalRecords}" + " " + recordsLabel} 
                                            rows={visibleRowsCount} 
                                            rowsPerPageOptions={rowsPerPageOptions}

                                            emptyMessage={noRecordsFound}
                                            className={clsx("p-datatable-striped", "p-datatable-customers")}
                                            rowHover 
                                            >
                                            <Column 
                                                field="media" 
                                                header={mediaLabel} 
                                                body={imageTemplate} 
                                                style={{width:'10em', textAlign:'center'}}
                                            />
                                            <Column 
                                                field="mediaTypeText" 
                                                header={categoryLabel} 
                                                sortable={true} 
                                                filter={true} 
                                                filterMatchMode="contains" 
                                                // style={{width:'16em'}}
                                            />
                                            <Column 
                                                field="mediaCaption" 
                                                header={captionLabel} 
                                                sortable={true} 
                                                filter={true} 
                                                filterMatchMode="contains" 
                                                // editor={captionEditor} 
                                                // editorValidator={captionValidator}
                                                // style={{background:'#f9e6f0'}}
                                            />
                                            <Column 
                                                field="mediaDesc" 
                                                header={descLabel} 
                                                sortable={true} 
                                                filter={true} 
                                                filterMatchMode="contains" 
                                                // editor={descriptionEditor} 
                                                // editorValidator={descriptionValidator}
                                                // style={{background:'#e6e6fa'}}
                                            />
                                        </DataTable>
                                    </div>
                                }
                            </div>
                        </Container>
                    </Dialog>
                </Container>
            }

            {isMobileView &&
                <Container component="div" className={classes.main} maxWidth="md">
                    <Dialog 
                        id="modalDlgId"
                        rtl={appContext.dirction === 'rtl'}
                        showHeader={true}
                        header={mediaSelectionLabel}
                        visible={dialogOpenFlag} 
                        maximizable={true}
                        maximized={isDeskTopView ? false : true}
                        modal={true} 
                        onHide={dialogCloseCallback}
                        className={classes.modalDialog}
                        >
                        <Container component="div" className={classes.mainGrid} maxWidth="md">
                            <div style={{ width: "100%", height: "100%" }}>
                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center">
                                        <Grid item xs={6} sm={6}>
                                            {displayViewType === "gridView" &&
                                                <Grid container justify={direction === 'ltr' ? 'flex-start' : 'flex-end'}>
                                                    <FormControl className={classes.formControl} error={categoryErrFlag}>
                                                        <label htmlFor="categoryId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={categoryErrFlag ? "secondary" : "inherit"}>
                                                                *{filterByCategory}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="categoryId"
                                                            value={category}
                                                            options={categoryOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleReferenceCategoriesChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            style={{display: 'flex', flex: 1}}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={filterByCategory} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={categoryErrFlag}
                                                                    helperText={categoryErrFlag ? filterSelectionErrMsg : ""}
                                                                    style={{color: 'green'}}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            }
                                        </Grid>

                                        <Grid item xs={6} sm={6}>
                                            <Grid container justify="flex-end" spacing={1}>
                                                <ToggleButtonGroup orientation="horizontal">
                                                    <ToggleButton value="gridView" aria-label={gridViewLabel}>
                                                        <Tooltip title={gridViewLabel} placement="bottom" style={{cursor: "pointer"}}>
                                                            <ViewComfyIcon fontSize="default" 
                                                                color={displayViewType === 'gridView' ? 'primary' : 'action'} 
                                                                onClick={() => displayViewAction('gridView')}
                                                            />
                                                        </Tooltip>
                                                    </ToggleButton>

                                                    <ToggleButton value="tableView" aria-label={tableViewLabel}>
                                                        <Tooltip title={tableViewLabel} placement="bottom" style={{cursor: "pointer"}}>
                                                            <TableChartIcon fontSize="default" 
                                                                className={clsx(classes.leftIcon)}
                                                                color={displayViewType === 'tableView' ? 'primary' : 'action'} 
                                                                onClick={() => displayViewAction('tableView')}
                                                            />
                                                        </Tooltip>
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <LineSpacing value={4} />
                                </Grid>

                                {displayViewType === "gridView" &&
                                    <Grid item xs={12} sm={12}>
                                        <DataView 
                                            value={mediaData} 
                                            layout={layout} 
                                            itemTemplate={itemTemplate}
                                            paginator={dataViewPaginatorFlag}
                                            rows={dataViewVisibleCount}
                                            // header={header}
                                            >
                                        </DataView>
                                    </Grid>
                                }

                                {displayViewType === "tableView" &&
                                    <div className="datatable-doc-demo">
                                        <DataTable 
                                            // ref={(el) => videoTableRef = el}
                                            value={mediaDataBackup}
                                            resizableColumns={resizableColumnsFlag} 
                                            columnResizeMode={columnResizeMode}
                                            sortMode={sortMode}
                                            responsive={responsiveFlag}
                                            reorderableColumns={reorderableColumnsFlag}

                                            selectionMode={selectionMode}
                                            selection={selectedTableRow}
                                            onSelectionChange={handleSingleSelectionChange}

                                            paginator={paginatorFlag} 
                                            // paginatorLeftpaginatorLeft={paginatorLeft}

                                            paginatorTemplate={paginatorTemplate}
                                            currentPageReportTemplate={"{first}" + " " + toLabel + " " + "{last}" + " " + ofLabel + " " + "{totalRecords}" + " " + recordsLabel} 
                                            rows={visibleRowsCount} 
                                            rowsPerPageOptions={rowsPerPageOptions}

                                            emptyMessage={noRecordsFound}
                                            className={clsx("p-datatable-striped", "p-datatable-customers")}
                                            rowHover 
                                            >
                                            <Column 
                                                field="media" 
                                                header={mediaLabel} 
                                                body={imageTemplate} 
                                                style={{width:'10em', textAlign:'center'}}
                                            />
                                            <Column 
                                                field="mediaTypeText" 
                                                header={categoryLabel} 
                                                sortable={true} 
                                                filter={true} 
                                                filterMatchMode="contains" 
                                                // style={{width:'16em'}}
                                            />
                                            <Column 
                                                field="mediaCaption" 
                                                header={captionLabel} 
                                                sortable={true} 
                                                filter={true} 
                                                filterMatchMode="contains" 
                                                // editor={captionEditor} 
                                                // editorValidator={captionValidator}
                                                // style={{background:'#f9e6f0'}}
                                            />
                                            <Column 
                                                field="mediaDesc" 
                                                header={descLabel} 
                                                sortable={true} 
                                                filter={true} 
                                                filterMatchMode="contains" 
                                                // editor={descriptionEditor} 
                                                // editorValidator={descriptionValidator}
                                                // style={{background:'#e6e6fa'}}
                                            />
                                        </DataTable>
                                    </div>
                                }
                            </div>
                        </Container>
                    </Dialog>
                </Container>
            }
        </div>
    );
}

export default CommonMediaSelector;
