import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
// import SaveIcon from "@material-ui/icons/Save";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputAdornment from "@material-ui/core/InputAdornment";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from "@material-ui/core/Container";
// import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import CheckIcon from '@material-ui/icons/Check';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import ImageSearchIcon from '@material-ui/icons/ImageSearch';
// import InputAdornment from "@material-ui/core/InputAdornment";
// import TranslateIcon from '@material-ui/icons/Translate';
import Paper from "@material-ui/core/Paper";
// import ImportExportIcon from '@material-ui/icons/ImportExport';

// react-responsive
import { useMediaQuery } from 'react-responsive';

// moment
import moment from "moment";

// Lodash
import lodash from "lodash";

// Phone
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// react-media
// import Media from "react-media";

// Validator
// import validator from 'validator';
// import isEmail from "validator/lib/isEmail";
// import isURL from "validator/lib/isURL";
import isMobilePhone from "validator/lib/isMobilePhone";

//---------------------
// For Primereact
//---------------------

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

// For Primereact
import { Dialog } from 'primereact/dialog';
// import { OrderList } from 'primereact/orderlist';

// Froala
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Amplify
import { API } from "aws-amplify";
// import { AmplifyTheme   } from 'aws-amplify-react';

// AppContext
import AppContext from "../../config/AppContext";

import LineSpacing from "../../components/misc/LineSpacing";
// import FetchS3Object from "../../components/media/FetchS3Object";
// import FroalaCommonEditor from "../../components/editor/FroalaCommonEditor";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import AlertDialog from "../../components/dialogs/AlertDialog";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GenerateUniqueName from "../../utils/GenerateUniqueName";
// import GenerateUlid from "../../utils/GenerateUlid";
// import FormatDateTime from "../../../../../utils/moment/FormatDateTime";
import FormatTimezoneDates from "../../utils/moment/FormatTimezoneDates";
import FilterArrayFromArray from "../../utils/filter/FilterArrayFromArray";
import FormatNumber from "../../utils/numberFormat/FormatNumber";
// import FormatNumberWithOption from "../../utils/numberFormat/FormatNumberWithOption";
// import FormatCurrency from "../../utils/numberFormat/FormatCurrency";
import GlobalConstants from "../../utils/GlobalConstants";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// import CustomerServiceConverter from "./CustomerServiceConverter";

// Views
// import CustomerServiceTv from "./views/CustomerServiceTv";
// import CustomerServiceMv from "./views/CustomerServiceMv";

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        // marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    leftPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "left",
    },
    leftPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1rem" /* Footer height */,

        width: "100% !important",
        marginTop: "auto",
        // backgroundColor: '#fff',
        textAlign: "center",
    },
    paper: {
        minWidth: "300px",
        marginTop: theme.spacing(2),
        alignItems: "center",
        padding: "20px 40px 20px 40px !important",
        // padding: theme.spacing(3),
        textAlign: "center",
    },
    phone: {
        flexGrow: 1,
        width: "100% !important",
        // height: theme.spacing(5),// 56px
        height: "52px !important",// 56px
        display: "flex",
        flexDirection: "row",
        textAlign: "left",
    },
    phoneStyle: {
        width: "100%",
        height: theme.spacing(5),// 56px
        textAlign: "left",
    },
}));

let pageLabels = {
    customerServInfoHdr: "Customer Service Info (CSI)",
    requiredFields: "* Required fields",

    languageLabel: "Language",
    languagePrompt: "Select language",
    languageErrMsg: "Language is required",

    domainLabel: "Domain",
    domainPrompt: "Select a domain",
    domainErrMsg: "Domain is required",

    // csiCategoryLabel: "FAQ category",
    // csiCategoryPrompt: "Select FAQ category",
    // csiCategoryErrMsg: "FAQ category is required",
    
    // csiQuestionLabel: "Question",
    // csiQuestionPrompt: "Enter a question",
    // csiQuestionErrMsg: "Question is required",

    // csiAnswerLabel: "Answer",
    // csiAnswerPrompt: "Enter an answer",
    // csiAnswerErrMsg: "Answer is required",
    // csiAnswerMsg: "Add comments about the csi category",

    // selectTitlePrompt: "Select title",
    
    // blogStartTimeLabel: "Blog start time",
    // blogStartTimePrompt: "Select date & time to publish the blog",
    // blogStartTimeErrMsg: "Blog start time is invalid",

    // uploadCsiQuestionLabel: "Blog title",
    // uploadCsiQuestionPrompt: "Please select blog title",
    // uploadCsiQuestionErrMsg: "Blog title is required",

    // liveEventUrlLabel: "Live event URL",
    // liveEventUrlPrompt: "Please enter the live event URL",
    // liveEventUrlErrMsg: "Live event URL is invalid",

    // liveEventUrlTypeLabel: "Live event URL type",
    // liveEventUrlTypePrompt: "Select live event URL type",
    // liveEventUrlTypeErrMsg: "Live event URL type is required",

    // blogContentLabel: "Blog content",
    // blogContentPrompt: "Please enter your blog content",
    // blogContentErrMsg: "Live event description is required",

    // yourTimeZoneMsg: "Your timeZone is",
    // timeZoneLabel: "Time zone",
    // cloneEventLabel: "Clone event",

    csiRecordLabel: "Customer service record",
    csiRecordsLabel: "Customer service records",
    // inProgressLabel: "In progress",
    // startLabel: "Start",
    // endLabel: "End",
    // notStartedLabel: "Not started",
    // dateCreatedLabel: "Date created",

    editLabel: "Edit",
    deleteLabel: "Delete",

    toLabel: "to",
    ofLabel: "of",
    recordsLabel: "records",
    noRecordsFound: "No records found",

    operationSuccessMsg: "Operation was successful.",
    operationFailureMsg: "Operation failed.",
    duplicateTransMsg: "Duplicate transaction",

    loadingText: "Loading ...",
    noOptionsText: "No options",
    clearText: "Clear",
    closeText: "Close",
    // showLabel: "Show",
    // hideLabel: "Hide",

    cancelLabel: "Cancel",
    // clearLabel: "Clear",
    cloneRecordLabel: "Clone record",

    yesLabel: "Yes",
    deleteRequested: "Delete requested",
    areYouSureMsg: "Are you sure?",
    requestedToDeleteMsg: "You have requested to delete",
  
    createCsiLabel: "Create CSI",
    updateCsiLabel: "Update CSI",

    createMoreLabel: "Create more",
    // createLabel: "Create",
    // editEventDestLabel: "Edit email template",
    closeLabel: "Close",
            
    // gridCsiAnswer: "Answer",
    // gridCategory: "Category",
    // gridCsiQuestion: "Question",
    lastUpdateLabel: "Last update",

    // gridEventUrl: "Event URL",
    // gridStartTime: "Start time",
    // gridBannerUrl: "Banner URL",
    
    // convertLabel: "Convert",
    // convertCsisLabel: "Convert FAQs",

    // sourceLanguageLabel: "Source language",
    // sourceLanguagePrompt: "Select source language",
    // sourceLanguageErrMsg: "Source language is required",

    // targetLanguageLabel: "Target language",
    // targetLanguagePrompt: "Select target language",
    // targetLanguageErrMsg: "Target language is required",

    // moreLabel: "- more -",
    // uploadLabel: "Upload",

    // organizeLabel: "Organize",
    // organizeCsiListLabel: "Organize FAQs",
    // freqAskedQuestionsLabel: "Frequently Asked Questions",
    // saveLabel: "Save",

    //================================================

    // customerServLabel: "Customer service",
    // requiredFields: "* Required fields",
    // submit: "Submit",
  
    languageNameLabel: "Language",
    // selectLanguage: "Please select a language",
  
    locationLabel: "Location",
    locationPrompt: "Please select a location",
    locationErrMsg: "Location is required",
  
    supportPhoneLabel: "Support number",
    smsPhoneLabel: "SMS number",
    whatsAppPhoneLabel: "WhatsApp number",
    callMePhoneLabel: "Call me number",
    supportPhonePrompt: "Please enter support number",
    smsPhonePrompt: "Please enter SMS number",
    whatsAppPhonePrompt: "Please enter WhatsApp number",
    callMePhonePrompt: "Please enter call me number",
    salesPhonePrompt: "Please enter sales phone number",

    searchPlaceholder: "Search countries",
  
    salesPhoneLabel: "Sales phone",
  
    salesPhoneErrMsg: "Sales phone number is invalid",
    supportPhoneErrMsg: "Support phone number is invalid",
    smsPhoneErrMsg: "SMS phone number is invalid",
    whatsAppPhoneErrMsg: "WhatsApp phone number is invalid",
    callMePhoneErrMsg: "Call me phone number is invalid",
  
    salesEmailLabel: "Sales email",
    salesEmailPrompt: "Please enter sales email address",
    salesEmailErrMsg: "Sales email address is invalid",
  
    supportEmailLabel: "Support email",
    supportEmailPrompt: "Please enter support email address",
    supportEmailErrMsg: "Support email address is invalid",
  
    // customerSrvSuccessMsg: "Customer service update was successful.",
    // customerSrvFailureMsg: "Customer service update failed.",
    // customerSrvErrMsg: "Please correct any invalid entry.",
};

function CustomerService() {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    // const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);
    const [renderDomFlag, setRenderDomFlag] = useState(false);
    const [progressFlag, setProgressFlag] = useState(false);

    // For Global Errors
    const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");

    // For Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarsMsg, setSnackbarsMsg] = useState("");
    const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    //-------------------
    // Table Names
    //-------------------
    const tableName = "csi"; // Customer Service Info (csi)

    //--------------------
    // For Froala Editor
    //--------------------
    // const editorRef = useRef(null);
    // const [editorModelData, setEditorModelData] = useState(null);
    // const [editorMaxChars, setEditorMaxChars] = useState(10000);// Max chars allowed in editor

    //--------------------
    // For Form Attributes
    //--------------------
    // const [profilePhotoSrc, setProfilePhotoSrc] = useState(null);
    
    const [language, setLanguage] = useState(null);
    // const [csiAnswer, setCsiAnswer] = useState("");
    // const [authorPhotoSrc, setAuthorPhotoSrc] = useState("");
    // const [csiQuestion, setCsiQuestion] = useState("");
    const [blogStartTime, setBlogStartTime] = useState(null);
    // const [csiCategory, setCsiCategory] = useState(null);
    // const [blogContent, setBlogContent] = useState("");
    const [domain, setDomain] = useState(null);
    const [domainOptions, setDomainOptions] = useState([]);
    
    // const [eventThumbnailUrl, setEventThumbnailUrl] = useState("");
    // const [eventBannerUrl, setEventBannerUrl] = useState("");
    // const [eventPosterUrl, setEventPosterUrl] = useState("");
    // const [eventAudioUrl, setEventAudioUrl] = useState("");
    
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
         
    const [languageErrFlag, setLanguageErrFlag] = useState(false);
    const [domainErrFlag, setDomainErrFlag] = useState(false);
    // const [csiAnswerErrFlag, setCsiAnswerErrFlag] = useState(false);
    // const [csiQuestionErrFlag, setCsiQuestionErrFlag] = useState(false);
    // const [blogStartTimeErrFlag, setBlogStartTimeErrFlag] = useState(false);
    // const [csiCategoryErrFlag, setCsiCategoryErrFlag] = useState(false);
    // const [blogContentErrFlag, setBlogContentErrFlag] = useState(false);

    // const [categoryOptions, setCategoryOptions] = useState([]);
    
    const [csiListRecords, setCsiListRecords] = useState([]);

    const [submitButtonFlag, setSubmitButtonFlag] = useState(false);
    // const [addTagButtonFlag, setAddTagButtonFlag] = useState(false);
    const [editModeFlag, setEditModeFlag] = useState(false);

    const [datePickerLanguage, setDatePickerLanguage] = useState(appContext.currentLanguage.languageCode);
    
    
    const [showListFlag, setShowListFlag] = useState(true);
    const [showDetailsFormFlag, setShowDetailsFormFlag] = useState(false);
    // const [uploadFlag, setUploadFlag] = useState(false);
    // const [convertFlag, setConvertFlag] = useState(false);
       
    const [pageSize, setPageSize] = useState(10000);// AWS put PageSize to a max 0f 100

    const [noRecordFoundFlag, setNoRecordFoundFlag] = useState(false);
    // const [eventStartedFlag, setEventStartedFlag] = useState(false);
    // const [startedEventId, setStartedEventId] = useState(null);
    
    // const [textMaxLength, setTextMaxLength] = useState(256);

    //--------------------------
    // For sliceText and Dialog
    //--------------------------
    const [dialogData, setDialogData] = useState("");
    const [dialogHeader, setDialogHeader] = useState("");
    
    // const [terminologyNames, setTerminologyNames] = useState([
    //     "translate_custom_terminology.txt"
    // ]);
  
    //--------------------------
    // For Form Attributes
    //--------------------------
    const [salesEmail, setSalesEmail] = useState("");
    const [supportEmail, setSupportEmail] = useState("");
    const [location, setLocation] = useState(null);
    // const [locationChangedFlag, setLocationChangedFlag] = useState(false);
    const [locationOptions, setLocationOptions] = useState([]);
    // const [contactInfoData, setContactInfoData] = useState([]);
    
    const [salesEmailErrFlag, setSalesEmailErrFlag] = useState(false);
    const [supportEmailErrFlag, setSupportEmailErrFlag] = useState(false);
    
    const [locationErrFlag, setLocationErrFlag] = useState(false);
    const [salesPhoneErrFlag, setSalesPhoneErrFlag] = useState(false);
    const [supportPhoneErrFlag, setSupportPhoneErrFlag] = useState(false);
    const [smsPhoneErrFlag, setSmsPhoneErrFlag] = useState(false);
    const [whatsAppPhoneErrFlag, setWhatsAppPhoneErrFlag] = useState(false);
    const [callMePhoneErrFlag, setCallMePhoneErrFlag] = useState(false);

    // const [currentPK, setCurrentPK] = useState("");
    // const [currentSK, setCurrentSK] = useState("");
         
    //--------------------------
    // For Phones
    //--------------------------
    const [salesPhone, setSalesPhone] = useState("");
    const [supportPhone, setSupportPhone] = useState("+1");
    const [smsPhone, setSmsPhone] = useState("");
    const [whatsAppPhone, setWhatsAppPhone] = useState("");
    const [callMePhone, setCallMePhone] = useState("");

    // For Phone/Fax Number Input
    const [defaultPhoneCountry, setDefaultPhoneCountry] = useState(
        appContext.phoneNumber.defaultPhoneCountry
    ); // For phone flag
    const [preferredCountries, setPreferredCountries] = useState(
        appContext.phoneNumber.preferredCountries
    ); //Show these countries at top of dropdown
    const [phoneLocale, setPhoneLocale] = useState(
        appContext.phoneNumber.phoneLocale
    ); // For phone validation
    const [localization, setLocalization] = useState(
        appContext.phoneNumber.localization
    ); // e.g {'Germany': 'Deutschland', 'Spain': 'España'}
    const [enableSearchField, setEnableSearchField] = useState(true);

    // Variants
    // const [salesEmailVariant, setSalesEmailVariant] = useState(null);
    // const [supportEmailVariant, setSupportEmailVariant] = useState(null);

    // const [renderContentFlag, setRenderContentFlag] = useState(false);
    

    //-------------------------
    // For Primereact 
    // DataTable Attributes 
    //-------------------------
    // const [ dataTableInfo, setDataTableInfo] = useState([]);
    // const [dataTableInfoFlag, setDataTableInfoFlag] = useState(false);
    
    // const [resizableColumnsFlag, setResizableColumnsFlag] = useState(true);
    // const [columnResizeMode, setColumnResizeMode] = useState("fit");
    // const [sortMode, setSortMode] = useState("multiple");
    // const [responsiveFlag, setResponsiveFlag] = useState(true);
    // const [reorderableColumnsFlag, setReorderableColumnsFlag] = useState(true);
    // const [paginatorFlag, setPaginatorFlag] = useState(true);
    const [paginatorTemplate, setPaginatorTemplate] = useState("FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown");
    // const [currentPageReportTemplate, setCurrentPageReportTemplate] = useState(null);
    const [visibleRowsCount, setVisibleRowsCount] = useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5,10,20,50,100]);

    // const [selectionMode, setSelectionMode] = useState("multiple");// single or multiple
    // const [selectedTableRow, setSelectedTableRow] = useState(null);// For single selection
    const [selectedTableRows, setSelectedTableRows] = useState([]);// For multiple selection
    // const [updatedTableRows, setUpdatedTableRows] = useState([]); // For updated (edited) table rows
    // const [deletedTableRows, setDeletedTableRows] = useState([]); // For deleted table rows
    // const [tableDirtyFlag, setTableDirtyFlag] = useState(false); // To show DataTable cell was edited (modified)

    // const [deleteRowFlag, setDeleteRowFlag] = useState(false);

    //--------------------
    // For AlertDialog
    //--------------------
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogSubContent, setDialogSubContent] = useState(null);

    const [recordToDelete, setRecordToDelete] = useState(null);
    // const [recordToEdit, setRecordToEdit] = useState(null);
    
    //-----------------------
    // For Primereact Dialog
    //-----------------------
    const [dlgVisibleFlag, setDlgVisibleFlag] = useState(false);

    //--------------------
    // For Languages
    //--------------------
    const [languages, setLanguages] = useState([]);

    //------------------
    // For OrderList
    //------------------
    // const [filteredRecords, setFilteredRecords] = useState([]);
    // const [orderListFlag, setOrderListFlag] = useState(false);
    // const [dragdropFlag, setDragdropFlag] = useState(true); // Allow drag and drop
    
    // const {
    //     // editorId,
    // } = props;

    const theme = useTheme();
  
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    const { 
        customerServInfoHdr, 
        requiredFields,

        languageLabel,
        languagePrompt,
        languageErrMsg,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,
            
        // csiAnswerLabel,
        // csiAnswerPrompt,
        // csiAnswerErrMsg,
        // csiAnswerMsg,
        
        // csiQuestionLabel,
        // csiQuestionPrompt,
        // csiQuestionErrMsg,
        // selectTitlePrompt,
    
        // blogStartTimeLabel,
        // blogStartTimePrompt,
        // blogStartTimeErrMsg,

        // csiCategoryLabel,
        // csiCategoryPrompt,
        // csiCategoryErrMsg,

        // uploadCsiQuestionLabel,
        // uploadCsiQuestionPrompt,
        // uploadCsiQuestionErrMsg,

        // liveEventUrlLabel,
        // liveEventUrlPrompt,
        // liveEventUrlErrMsg,

        // liveEventUrlTypeLabel,
        // liveEventUrlTypePrompt,
        // liveEventUrlTypeErrMsg,

        csiRecordLabel,
        csiRecordsLabel,
        // inProgressLabel,
        // startLabel,
        // endLabel,
        // notStartedLabel,
    
        // dateCreatedLabel,
    
        // blogContentLabel,
        // blogContentPrompt,
        // blogContentErrMsg,

        // yourTimeZoneMsg,
        // timeZoneLabel,
        // cloneEventLabel,
    
        toLabel,
        ofLabel,
        recordsLabel,
        noRecordsFound,

        // gridCategory,
        editLabel,
        deleteLabel,

        operationSuccessMsg,
        operationFailureMsg,
        duplicateTransMsg,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,
        // showLabel,
        // hideLabel,

        cancelLabel,
        // clearLabel,
        cloneRecordLabel,

        yesLabel,
        deleteRequested,
        areYouSureMsg,
        requestedToDeleteMsg,
    
        // uploadLabel,
        
        createCsiLabel,
        updateCsiLabel,

        createMoreLabel,
        // createLabel,
        // editEventDestLabel,
        closeLabel,
        // createCsiLabel,

        // gridCsiQuestion,
        // gridCsiAnswer,
        // gridEventUrl,
        lastUpdateLabel,
        // gridStartTime,
        // gridBannerUrl,
    
        // convertLabel,
        // convertCsisLabel,

        // sourceLanguageLabel,
        // sourceLanguagePrompt,
        // sourceLanguageErrMsg,

        // targetLanguageLabel,
        // targetLanguagePrompt,
        // targetLanguageErrMsg,

        // moreLabel,

        // organizeLabel,
        // organizeCsiListLabel,
        // freqAskedQuestionsLabel,
        // saveLabel,

        //================================================
        
        // customerServLabel,
        // languageNameLabel,
        // selectLanguage,
    
        locationLabel,
        locationPrompt,
        locationErrMsg,
    
        supportPhoneLabel,
        smsPhoneLabel,
        whatsAppPhoneLabel,
        callMePhoneLabel,
        searchPlaceholder,
        supportPhonePrompt,
        smsPhonePrompt,
        whatsAppPhonePrompt,
        callMePhonePrompt,
        salesPhonePrompt,
        
        salesPhoneLabel,
    
        salesPhoneErrMsg,
        supportPhoneErrMsg,
        smsPhoneErrMsg,
        whatsAppPhoneErrMsg,
        callMePhoneErrMsg,
    
        supportEmailLabel,
        supportEmailPrompt,
        supportEmailErrMsg,
    
        salesEmailLabel,
        salesEmailPrompt,
        salesEmailErrMsg,
    
        // submit,
        // customerSrvSuccessMsg,
        // customerSrvFailureMsg,
        // customerSrvErrMsg,
        // requiredFields,
    } = pageLabelsRes;

    async function fetchDbResources(cacheItemKey) {
        backdropProgress(true);

        let keys = [];
        let objKeys = Object.keys(pageLabels);
        // console.log("In CustomerService(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
        if (objKeys !== null && objKeys.length > 0) {
            objKeys.map((key) => {
                let partitionKey = appContext.currentLanguage.languageCode;
                partitionKey += ".";
                partitionKey += GenerateModulusHash(key, 200);

                let itemKey = {
                ResourcePK: partitionKey, // e.g. 'en.91'
                ResourceSK: key, // e.g. 'firstName'
                };
                keys.push(itemKey);
            });
        }
        // console.log("In CustomerService(): fetchDbResources(): keys.length is: ", keys.length);
        // console.log("In CustomerService(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

        let arrayData = [];

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/batchGet",
            payload: {
                keys: keys,
                projectionExpression: "ResourceCode,ResourceDesc",
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
            response.data.Responses.resource.map((item, index) => {
            let record = {
                ResourceCode: item.ResourceCode,
                ResourceDesc: item.ResourceDesc,
            };

            arrayData.push(record);
            });
                    
            if (arrayData && arrayData.length > 0) {
                arrayData.map((item) => {
                    if (item.ResourceCode === "customerServInfoHdr") pageLabels.customerServInfoHdr = item.ResourceDesc;
                    if (item.ResourceCode === "requiredFields") pageLabels.requiredFields = item.ResourceDesc;
                    
                    if (item.ResourceCode === "languageLabel") pageLabels.languageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "languagePrompt") pageLabels.languagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "languageErrMsg") pageLabels.languageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "domainLabel") pageLabels.domainLabel = item.ResourceDesc;
                    if (item.ResourceCode === "domainPrompt") pageLabels.domainPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "domainErrMsg") pageLabels.domainErrMsg = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "csiAnswerLabel") pageLabels.csiAnswerLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "csiAnswerPrompt") pageLabels.csiAnswerPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "csiAnswerErrMsg") pageLabels.csiAnswerErrMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "csiAnswerMsg") pageLabels.csiAnswerMsg = item.ResourceDesc;
            
                    // if (item.ResourceCode === "csiQuestionLabel") pageLabels.csiQuestionLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "csiQuestionPrompt") pageLabels.csiQuestionPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "csiQuestionErrMsg") pageLabels.csiQuestionErrMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "selectTitlePrompt") pageLabels.selectTitlePrompt = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "blogStartTimeLabel") pageLabels.blogStartTimeLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "blogStartTimePrompt") pageLabels.blogStartTimePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "blogStartTimeErrMsg") pageLabels.blogStartTimeErrMsg = item.ResourceDesc;

                    // if (item.ResourceCode === "csiCategoryLabel") pageLabels.csiCategoryLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "csiCategoryPrompt") pageLabels.csiCategoryPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "csiCategoryErrMsg") pageLabels.csiCategoryErrMsg = item.ResourceDesc;
            
                    // if (item.ResourceCode === "uploadCsiQuestionLabel") pageLabels.uploadCsiQuestionLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "uploadCsiQuestionPrompt") pageLabels.uploadCsiQuestionPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "uploadCsiQuestionErrMsg") pageLabels.uploadCsiQuestionErrMsg = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "liveEventUrlTypeLabel") pageLabels.liveEventUrlTypeLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "liveEventUrlTypePrompt") pageLabels.liveEventUrlTypePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "liveEventUrlTypeErrMsg") pageLabels.liveEventUrlTypeErrMsg = item.ResourceDesc;
                                    
                    // if (item.ResourceCode === "yourTimeZoneMsg") pageLabels.yourTimeZoneMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "timeZoneLabel") pageLabels.timeZoneLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "cloneEventLabel") pageLabels.cloneEventLabel = item.ResourceDesc;
                                
                    if (item.ResourceCode === "csiRecordsLabel") pageLabels.csiRecordsLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "inProgressLabel") pageLabels.inProgressLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "startLabel") pageLabels.startLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "endLabel") pageLabels.endLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "notStartedLabel") pageLabels.notStartedLabel = item.ResourceDesc;
                      
                    // if (item.ResourceCode === "dateCreatedLabel") pageLabels.dateCreatedLabel = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "blogContentLabel") pageLabels.blogContentLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "blogContentPrompt") pageLabels.blogContentPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "blogContentErrMsg") pageLabels.blogContentErrMsg = item.ResourceDesc; 

                    if (item.ResourceCode === "toLabel") pageLabels.toLabel = item.ResourceDesc;
                    if (item.ResourceCode === "ofLabel") pageLabels.ofLabel = item.ResourceDesc;
                    if (item.ResourceCode === "recordsLabel") pageLabels.recordsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noRecordsFound") pageLabels.noRecordsFound = item.ResourceDesc;

                    if (item.ResourceCode === "createLabel") pageLabels.createLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "gridCategory") pageLabels.gridCategory = item.ResourceDesc;
                    if (item.ResourceCode === "editLabel") pageLabels.editLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteLabel") pageLabels.deleteLabel = item.ResourceDesc;

                    if (item.ResourceCode === "operationSuccessMsg") pageLabels.operationSuccessMsg = item.ResourceDesc;
                    if (item.ResourceCode === "operationFailureMsg") pageLabels.operationFailureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "duplicateTransMsg") pageLabels.duplicateTransMsg = item.ResourceDesc;
                                                                        
                    if (item.ResourceCode === "loadingText") pageLabels.loadingText = item.ResourceDesc;
                    if (item.ResourceCode === "noOptionsText") pageLabels.noOptionsText = item.ResourceDesc;
                    if (item.ResourceCode === "clearText") pageLabels.clearText = item.ResourceDesc;
                    if (item.ResourceCode === "closeText") pageLabels.closeText = item.ResourceDesc;
                    // if (item.ResourceCode === "showLabel") pageLabels.showLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "hideLabel") pageLabels.hideLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "cancelLabel") pageLabels.cancelLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "clearLabel") pageLabels.clearLabel = item.ResourceDesc;
                    if (item.ResourceCode === "cloneRecordLabel") pageLabels.cloneRecordLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "yesLabel") pageLabels.yesLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteRequested") pageLabels.deleteRequested = item.ResourceDesc;
                    if (item.ResourceCode === "areYouSureMsg") pageLabels.areYouSureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "requestedToDeleteMsg") pageLabels.requestedToDeleteMsg = item.ResourceDesc;
            
                    // if (item.ResourceCode === "uploadLabel") pageLabels.uploadLabel = item.ResourceDesc;
          
                    if (item.ResourceCode === "createCsiLabel") pageLabels.createCsiLabel = item.ResourceDesc;
                    if (item.ResourceCode === "updateCsiLabel") pageLabels.updateCsiLabel = item.ResourceDesc;
                              
                    if (item.ResourceCode === "csiRecordLabel") pageLabels.csiRecordLabel = item.ResourceDesc;
                    if (item.ResourceCode === "createMoreLabel") pageLabels.createMoreLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "editEventDestLabel") pageLabels.editEventDestLabel = item.ResourceDesc;
                    if (item.ResourceCode === "closeLabel") pageLabels.closeLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "createCsiLabel") pageLabels.createCsiLabel = item.ResourceDesc;
                      
                    // if (item.ResourceCode === "gridCsiQuestion") pageLabels.gridCsiQuestion = item.ResourceDesc;
                    // if (item.ResourceCode === "gridEventUrl") pageLabels.gridEventUrl = item.ResourceDesc;
                    if (item.ResourceCode === "lastUpdateLabel") pageLabels.lastUpdateLabel = item.ResourceDesc;
                                              
                    // if (item.ResourceCode === "gridStartTime") pageLabels.gridStartTime = item.ResourceDesc;
                    // if (item.ResourceCode === "gridBannerUrl") pageLabels.gridBannerUrl = item.ResourceDesc;
                    // if (item.ResourceCode === "gridCsiAnswer") pageLabels.gridCsiAnswer = item.ResourceDesc;
                                              
                    // if (item.ResourceCode === "convertLabel") pageLabels.convertLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "convertCsisLabel") pageLabels.convertCsisLabel = item.ResourceDesc;
                                        
                    // if (item.ResourceCode === "sourceLanguageLabel") pageLabels.sourceLanguageLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "sourceLanguagePrompt") pageLabels.sourceLanguagePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "sourceLanguageErrMsg") pageLabels.sourceLanguageErrMsg = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "targetLanguageLabel") pageLabels.targetLanguageLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "targetLanguagePrompt") pageLabels.targetLanguagePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "targetLanguageErrMsg") pageLabels.targetLanguageErrMsg = item.ResourceDesc;

                    // if (item.ResourceCode === "moreLabel") pageLabels.moreLabel = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "organizeLabel") pageLabels.organizeLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "organizeCsiListLabel") pageLabels.organizeCsiListLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "freqAskedQuestionsLabel") pageLabels.freqAskedQuestionsLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "saveLabel") pageLabels.saveLabel = item.ResourceDesc;

                    //================================================

                    // if (item.ResourceCode === "customerServLabel") pageLabels.customerServLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "languageNameLabel") pageLabels.languageNameLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "selectLanguage") pageLabels.selectLanguage = item.ResourceDesc;

                    if (item.ResourceCode === "locationLabel") pageLabels.locationLabel = item.ResourceDesc;
                    if (item.ResourceCode === "locationPrompt") pageLabels.locationPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "locationErrMsg") pageLabels.locationErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "supportPhoneLabel") pageLabels.supportPhoneLabel = item.ResourceDesc;
                    if (item.ResourceCode === "smsPhoneLabel") pageLabels.smsPhoneLabel = item.ResourceDesc;
                    if (item.ResourceCode === "whatsAppPhoneLabel") pageLabels.whatsAppPhoneLabel = item.ResourceDesc;
                    if (item.ResourceCode === "callMePhoneLabel") pageLabels.callMePhoneLabel = item.ResourceDesc;
                    if (item.ResourceCode === "searchPlaceholder") pageLabels.searchPlaceholder = item.ResourceDesc;

                    if (item.ResourceCode === "supportPhonePrompt") pageLabels.supportPhonePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "smsPhonePrompt") pageLabels.smsPhonePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "whatsAppPhonePrompt") pageLabels.whatsAppPhonePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "callMePhonePrompt") pageLabels.callMePhonePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "salesPhonePrompt") pageLabels.salesPhonePrompt = item.ResourceDesc;
                                    
                    if (item.ResourceCode === "salesPhoneLabel") pageLabels.salesPhoneLabel = item.ResourceDesc;

                    if (item.ResourceCode === "salesPhoneErrMsg") pageLabels.salesPhoneErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "supportPhoneErrMsg") pageLabels.supportPhoneErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "smsPhoneErrMsg") pageLabels.smsPhoneErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "whatsAppPhoneErrMsg") pageLabels.whatsAppPhoneErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "callMePhoneErrMsg") pageLabels.callMePhoneErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "supportEmailLabel") pageLabels.supportEmailLabel = item.ResourceDesc;
                    if (item.ResourceCode === "supportEmailPrompt") pageLabels.supportEmailPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "supportEmailErrMsg") pageLabels.supportEmailErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "salesEmailLabel") pageLabels.salesEmailLabel = item.ResourceDesc;
                    if (item.ResourceCode === "salesEmailPrompt") pageLabels.salesEmailPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "salesEmailErrMsg") pageLabels.salesEmailErrMsg = item.ResourceDesc;

                    // if (item.ResourceCode === "submit") pageLabels.submit = item.ResourceDesc;
                    if (item.ResourceCode === "requiredFields") pageLabels.requiredFields = item.ResourceDesc;

                    // if (item.ResourceCode === "customerSrvSuccessMsg") pageLabels.customerSrvSuccessMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "customerSrvFailureMsg") pageLabels.customerSrvFailureMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "customerSrvErrMsg") pageLabels.customerSrvErrMsg = item.ResourceDesc;
                });
                // Add it to cache
                SetCacheItem(cacheItemKey, pageLabels);
            }

            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setRenderDomFlag(true);
        }).catch((err) => {
            console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setRenderDomFlag(true);
        });
    }
    
    let listNextToken = null;

    async function fetchCsiRecords(selectedDomain) {
        // console.log('In fetchCsiRecords(): selectedDomain is: ', selectedDomain);
        if (language && selectedDomain) {
            backdropProgress(true);
            setNoRecordFoundFlag(false);

            let params = {
                apiName: "CommonDynamodbAPI",
                path: "/common/dynamodb/query",
                payload: {
                    tableName: tableName,
                    keyConditionExpression:
                        // "#csiPK = :csiPK",
                        "#csiPK = :csiPK AND begins_with(#csiSK, :csiSK)",
                    expressionAttributeNames: {
                        "#csiPK": "CsiPK",
                        "#csiSK": "CsiSK",
                    },
                    expressionAttributeValues: {
                        ":csiPK": selectedDomain.value + "#" + language.languageCode + "#csi",
                        ":csiSK": selectedDomain.value + "#" + language.languageCode,
                    },
                    // indexName: "CsiDomain-LangAuthorUserName-index"
                        // "keyConditionExpression": "#csiPK = :csiPK",
                        // "expressionAttributeNames": {"#csiPK": "CsiPK"},
                        // "expressionAttributeValues": {":csiPK": "languages"}
                },
            };

            if (!lodash.isEmpty(listNextToken)) {
                params.payload.nextToken = listNextToken;
            }
    
            if (!lodash.isEmpty(pageSize)) {
                params.payload.pageSize = pageSize;
            }

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchCsiRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchCsiRecords(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchCsiRecords(): response.data.NextToken is: ', response.data.NextToken);
                // console.log('In fetchCsiRecords(): response.data.Items is: ', response.data.Items);

                let records = [];

                listNextToken = null;

                if (response && !lodash.isEmpty(response.data.NextToken)) {
                    listNextToken = response.data.NextToken;
                    records = csiListRecords;
                }
            
                let items = response.data.Items;

                if (items && items.length > 0) {
                    setCsiListRecords([]);

                    items.map((record) => {
                        // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                        // console.log('In fetchCsiRecords(): formatedDateTime is: ', formatedDateTime);
                        // console.log('In fetchCsiRecords(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
                        let formattedDateTime = null;
                        if (record.UpdatedAt) {
                            formattedDateTime = FormatTimezoneDates(record.UpdatedAt, record.LanguageCode, timeZone, "shortDateAndTime");
                        }
                        // console.log('In fetchCsiRecords(): formattedDateTime is: ', formattedDateTime);

                        let item = {
                            "CsiPK": record.CsiPK,
                            "CsiSK": record.CsiSK,

                            "CsiId": record.CsiId,

                            "LanguageObject": record.LanguageObject,
                            "LanguageCode": record.LanguageCode,

                            "Domain": record.Domain,
                            "DomainCode": record.DomainCode,

                            "Location": record.Location,
                            "LocationCode": record.LocationCode,
                            "LocationDesc": record.Location.text,

                            "SalesEmail": record.SalesEmail,
                            "SupportEmail": record.SupportEmail,
            
                            "SalesPhone": lodash.isEmpty(record.SalesPhone) ? "" : record.SalesPhone,
                            "SupportPhone": lodash.isEmpty(record.SupportPhone) ? "" : record.SupportPhone,
                            "SmsPhone": lodash.isEmpty(record.SmsPhone) ? "" : record.SmsPhone,
                            "WhatsAppPhone": lodash.isEmpty(record.WhatsAppPhone) ? "" : record.WhatsAppPhone,
                            "CallMePhone": lodash.isEmpty(record.CallMePhone) ? "" : record.CallMePhone,
            
                            "DomainLangLocation": record.DomainLangLocation,// For GSI

                            // "CsiSeqNumber": record.CsiSeqNumber,
            
                            "FormattedDateTime": formattedDateTime,
                            "UpdatedAt": record.UpdatedAt,
                        };
                        records.push(item);
                    });
                } else {
                    setNoRecordFoundFlag(true);
                }

                // console.log('In fetchCsiRecords(): listNextToken is: ', listNextToken);
                if (!lodash.isEmpty(listNextToken)) {
                    await fetchCsiRecords();
                }
                
                setTimeout(() => {
                    records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                    setCsiListRecords(records);
                }, 100);

                backdropProgress(false);
            }).catch((err) => {
                console.error("In fetchCsiRecords(): err is: ", JSON.stringify(err, null, 2));
                setCsiListRecords([]);
                setNoRecordFoundFlag(true);
                backdropProgress(false);
            });
        }
    }
    
    async function getCsiRecord(key) {
        // console.log('In getCsiRecord(): key.CsiPK is: ', key.CsiPK);
        // console.log('In getCsiRecord(): key.CsiSK is: ', key.CsiSK);
        
        backdropProgress(true);
    
        let params = {
            apiName: "CommonDynamodbAPI",
            path: "/common/dynamodb/get",
            payload: {
                tableName: tableName,
                key: {
                    CsiPK: key.CsiPK,
                    CsiSK: key.CsiSK,
                },
            },
        };
  
        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
  
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In getCsiRecord(): initObj is: ', JSON.stringify(initObj, null, 2));
  
        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In getCsiRecord(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In getCsiRecord(): response.data.Item is: ', response.data.Item);
        
            let item = response.data.Item;

            appContext.currentPK = item.CsiPK;
            appContext.currentSK = item.CsiSK;
        
            setLanguage(item.LanguageObject);

            setDomain(item.Domain ? item.Domain : null);
            setLocation(item.Location ? item.Location : null);

            setSalesEmail(item.SalesEmail ? item.SalesEmail : "");
            setSupportEmail(item.SupportEmail ? item.SupportEmail : "");

            setSalesPhone(item.SalesPhone ? item.SalesPhone : "");
            setSupportPhone(item.SupportPhone ? item.SupportPhone : "");
            setSmsPhone(item.SmsPhone ? item.SmsPhone : "");
            setWhatsAppPhone(item.WhatsAppPhone ? item.WhatsAppPhone : "");
            setCallMePhone(item.CallMePhone ? item.CallMePhone : "");

            setShowListFlag(false);
            // setUploadFlag(false);
            // setOrderListFlag(false);
            setShowDetailsFormFlag(true);

            setSubmitButtonFlag(true);
            setEditModeFlag(true);

            backdropProgress(false);
        }).catch((err) => {
            console.error("In getCsiRecord(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        // console.log("In editRecordAction(): csiListRecords is: ", csiListRecords);
        
        // setRecordToEdit(item);
        await getCsiRecord({CsiPK: item.CsiPK, CsiSK: item.CsiSK,});
    }

    async function fetchLanguages() {
        // console.log("In fetchLanguages()");
        // setFilterDisabledFlag(true);

        //-------------------------------------------------------
        // Make sure languages is not in cache before proceeding
        //-------------------------------------------------------

        let cachedLanguages = GetCacheItem("languages");
        // console.log('In fetchLanguages(): cachedLanguages is: ', JSON.stringify(cachedLanguages, null, 2));
        cachedLanguages = null;
        if (cachedLanguages && cachedLanguages !== null && cachedLanguages.length > 0) {
            // console.log('In fetchLanguages(): cachedLanguages length is: ', cachedLanguages.length);
            setLanguages(cachedLanguages);
            return true;
        }

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/query",
            payload: {
                keyConditionExpression: "#resourcePK = :resourcePK",
                expressionAttributeNames: { "#resourcePK": "ResourcePK" },
                expressionAttributeValues: { ":resourcePK": "languages" },
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log("In fetchLanguages(): response length is: ", response.data.Items.length);
            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));

            // response.data.Items.sort((a, b) =>
            //     a.LanguageName > b.LanguageName ? 1 : -1
            // );

            let records = [];
            let items = response.data.Items;

            items.map(item => {
                let record ={
                    languageCode: item.ResourceCode,
                    languageName: item.LanguageName
                };
                records.push(record);
            });

            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
            setLanguages(records);

            // Add it to cache
            SetCacheItem("languages", response.data.Items);
            backdropProgress(false);
        }).catch((err) => {
            console.error("In fetchLanguages(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    };

    let  refDataSelectOptions = [];
    
    async function fetchReferenceData(refType, lang) {
        return new Promise((resolve, reject) => {
            // console.log('In CustomerService  fetchReferenceData(): refType is: ', refType);
            // console.log('In CustomerService  fetchReferenceData(): lang is: ', lang);
            backdropProgress(true);

            refDataSelectOptions = [];

            let apiName = null;
            let path = null;
            let keyConditionExpression = null;
            let expressionAttributeNames = {};
            let expressionAttributeValues = {};
            
            switch(refType) {
                // case "csi-categories":
                //     apiName = "ResourceAPI";
                //     path = "/dynamodb/resources/query";
                //     keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                //     expressionAttributeNames = {
                //         "#resourcePK": "ResourcePK",
                //         "#resourceSK": "ResourceSK",
                //     };
                //     expressionAttributeValues = {
                //         ":resourcePK": "csi-categories",
                //         ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                //     };
                //     break;
                case "supported-domains":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "supported-domains",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                default:
                    break;
            }

            setProgressFlag(true);
        
            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    keyConditionExpression: keyConditionExpression,
                    expressionAttributeNames: expressionAttributeNames,
                    expressionAttributeValues: expressionAttributeValues,
                    // "keyConditionExpression": "#resourcePK = :resourcePK",
                    // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                    // "expressionAttributeValues": {":resourcePK": "languages"}
                },
            };
        
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
        
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In  fetchReferenceData(): initObj is: ', JSON.stringify(initObj, null, 2));
        
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In  fetchReferenceData(): response.length is: ', response.data.Items.length);
                // console.log('In  fetchReferenceData(): response is: ', JSON.stringify(response.data.Items, null, 2));
                response.data.Items.map((item) => {
                    let record = {
                        value: item.ResourceCode,
                        text: item.ResourceDesc,
                    };

                    if (record.value !== "all") {// Bypass all
                        refDataSelectOptions.push(record);
                    }
                });

                switch(refType) {
                    // case "csi-categories":
                    //     refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                    //     setCategoryOptions( refDataSelectOptions);
                    //     break;
                    case "supported-domains":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setDomainOptions(refDataSelectOptions);
                        break;
                    default:
                        break;
                }

                backdropProgress(false);
                resolve(refDataSelectOptions);
            }).catch((err) => {
                console.error("In CustomerService  fetchReferenceData(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject(err);
            });
        });
    }

    let  locationSelectOptions = [];
    
    async function fetchLocations() {
        return new Promise((resolve, reject) => {
            // console.log('In CustomerService  fetchLocations(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
            backdropProgress(true);

            locationSelectOptions = [];
        
            let params = {
                apiName: "ResourceAPI",
                path: "/dynamodb/resources/query",
                payload: {
                    ConditionalOperator: "AND",
                    keyConditionExpression:
                        "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
                    expressionAttributeNames: {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    },
                    expressionAttributeValues: {
                        ":resourcePK": "company-locations",
                        ":resourceSK": appContext.currentLanguage.languageCode,
                    },
                },
            };
                  
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
        
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In  fetchLocations(): initObj is: ', JSON.stringify(initObj, null, 2));
        
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In fetchLocations(): response length is: ', response.data.Items.length);
                // console.log('In fetchLocations(): response is: ', JSON.stringify(response.data.Items, null, 2));
                response.data.Items.map((item, index) => {
                    let record = {
                        value: item.ResourceCode,
                        text: item.ResourceDesc,
                    };

                    locationSelectOptions.push(record);
                });

                locationSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                setLocationOptions(locationSelectOptions);
                // setContactInfoData(response.data.Items);
                backdropProgress(false);
                resolve(locationSelectOptions);
            }).catch((err) => {
                console.error("In CustomerService  fetchLocations(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject(err);
            });
        });
    }        
        
    useEffect(() => {
        // console.log('In CustomerService(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        // sessionStorage.removeItem("current-live-event");
    }, []);

    useLayoutEffect(() => {
        // console.log("In CustomerService(): useLayoutEffect()");
        async function fetchItems() {
            await fetchLanguages();

            await fetchLocations();

            // await fetchCategories("CustomerService", appContext.currentLanguage);

            // await fetchDomains("CustomerService");

            // await fetchCsiRecords();

            // try{
            //     // let imageKeyPrefix = "cob/jekwempu@tobisoft.co" + "/user/profile/";
            //     let imageKeyPrefix = appContext.shortDomain + "/" + appContext.userName + "/user/profile/";
            //     await FetchS3Object(imageKeyPrefix, imageObjectCallback);
            // } catch (error) {
            //     console.log("In CustomerService(): useLayoutEffect(): error is: ", error);
            // }

            // Fetch resource from catche
            let cacheItemKey = "customer-service";
            cacheItemKey += ":";
            cacheItemKey += appContext.currentLanguage.languageCode;
            // console.log('In CustomerService(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

            if (
                appContext.clearCacheLanguageCodes &&
                appContext.clearCacheLanguageCodes.length > 0 &&
                appContext.clearCacheLanguageCodes.includes(appContext.currentLanguage.languageCode)
            ) {
                // console.log("In CustomerService(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                RemoveCacheItem(cacheItemKey);
            }

            let cacheItem = GetCacheItem(cacheItemKey);
            // console.log('In CustomerService(): useLayoutEffect(): cacheItem is: ', cacheItem);
            cacheItem = null; // remove after testing

            if (cacheItem === null) {
                // resource is not in cache, fetch from DB
                // console.log('In CustomerService(): useLayoutEffect(): resource is not in cache, fetch from DB');
                await fetchDbResources(cacheItemKey);
            } else {
                // console.log('In CustomerService(): useLayoutEffect(): got resource from cache');
                setPageLabelsRes(cacheItem);
                setRenderDomFlag(true);
            }
        }

        fetchItems();
    }, []);
    
    function handleChange(event) {
        // console.log("In handleChange(): event.target.id is: ", event.target.id);
        // console.log("In handleChange(): event.target.value is: ", event.target.value);
        // setSubmitButtonFlag(false);

        switch(event.target.id) {
            case "salesEmailId":
                setSalesEmailErrFlag(false);
                setSalesEmail(event.target.value);

                setSubmitButtonFlag(false);
                if (!lodash.isEmpty(event.target.value)) {
                    if (
                        !lodash.isEmpty(event.target.value) &&
                        !lodash.isEmpty(domain) &&
                        !lodash.isEmpty(supportEmail) &&
                        !lodash.isEmpty(location) &&
                        !lodash.isEmpty(language)
                        ) {
                        setSubmitButtonFlag(true);
                    }
                }
                break;
            case "supportEmailId":
                // console.log("In handleChange(): event.target.value is: ", event.target.value);
                setSupportEmailErrFlag(false);
                setSupportEmail(event.target.value);

                setSubmitButtonFlag(false);
                if (!lodash.isEmpty(event.target.value)) {
                    if (
                        !lodash.isEmpty(event.target.value) &&
                        !lodash.isEmpty(domain) &&
                        !lodash.isEmpty(location) &&
                        !lodash.isEmpty(salesEmail) &&
                        !lodash.isEmpty(language)
                        ) {
                        setSubmitButtonFlag(true);
                    }
                }
                break;
            default:
                break;
        }
    }
    
    async function handleLanguageChange(event, item) {
        // console.log("In handleLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        setSubmitButtonFlag(false);        
        setLanguage(item);


        if (!lodash.isEmpty(item)) {
            await fetchReferenceData("supported-domains", item);
            // Promise.all([
            //     fetchReferenceData("csi-categories", item)
            // ]).then(async (results) => {
            //     // console.log("In handleLanguageChange(): results is: ", results);
            //     await fetchReferenceData("supported-domains", item);
            // }).catch(error => {
            //     console.log("In handleLanguageChange(): error is: ", error);
            // });
        }

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(location) &&
            !lodash.isEmpty(domain) 
        ) {
            setSubmitButtonFlag(true);
        }
    }
    
    async function handleListLanguageChange(event, item) {
        // console.log("In handleListLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        // setSubmitButtonFlag(false);        
        setLanguage(item);

        setDomain(null);
        setCsiListRecords([]);
        // setCsiListRecordsBackup([]);

        if (!lodash.isEmpty(item)) {
            Promise.all([
                await fetchReferenceData("supported-domains", item)
            ]).then(async (results) => {
                // console.log("In handleListLanguageChange(): results is: ", results);
            }).catch(error => {
                console.log("In handleListLanguageChange(): error is: ", error);
            });
        }
    }

    // function handleCsiCategoryChange(event, item) {
    //     // console.log("In handleCsiCategoryChange(): item is: ", item);
    //     setGlobalErrorFlag(false);
    //     setCsiCategoryErrFlag(false);
        
    //     setCsiCategory(item);

    //     setSubmitButtonFlag(false);
    //     if (
    //         !lodash.isEmpty(item) &&
    //         !lodash.isEmpty(language) &&
    //         !lodash.isEmpty(csiQuestion) &&
    //         !lodash.isEmpty(csiAnswer) &&
    //         !lodash.isEmpty(domain) 
    //         ) {
    //             setSubmitButtonFlag(true);
    //         }
    // }

    function handleDomainChange(event, item) {
        // console.log("In handleDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            sessionStorage.setItem("media-domain-name", item.value);
        }

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(salesEmail) &&
            !lodash.isEmpty(supportEmail) &&
            !lodash.isEmpty(location) 
            ) {
            setSubmitButtonFlag(true);
        }
    }

    function handleListDomainChange(event, item) {
        // console.log("In handleListDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            fetchCsiRecords(item);
        }
    }

    function createCsiAction() {
        initializeForm();

        setLanguage(null);
        setDomain(null);

        setEditModeFlag(false);
        setShowListFlag(false);
        // setOrderListFlag(false);
        // setUploadFlag(false);
        setShowDetailsFormFlag(true);
    }

    function createMoreAction() {
        initializeForm();

        setLanguage(null);
        setDomain(null);
        setLocation(null);

        setEditModeFlag(false);
        setShowListFlag(false);
        // setUploadFlag(false);
        // setOrderListFlag(false);
        setShowDetailsFormFlag(true);
    }

    // function uploadAction() {
    //     initializeForm();

    //     setEditModeFlag(false);
    //     setShowListFlag(false);
    //     setShowDetailsFormFlag(false);
    //     // setOrderListFlag(false);
    //     // setUploadFlag(true);
    // }
    
    // function convertAction() {
    //     initializeForm();

    //     setEditModeFlag(false);
    //     setShowListFlag(false);
    //     setShowDetailsFormFlag(false);
    //     // setUploadFlag(false);
    //     setOrderListFlag(false);
    //     setConvertFlag(true);
    // }
    
    // async function closeConvertForm() {
    //     // await fetchCsiRecords();
        
    //     setTimeout(() => {
    //         setShowDetailsFormFlag(false);
    //         // setUploadFlag(false);
    //         setConvertFlag(false);
    //         setOrderListFlag(false);
    //         setShowListFlag(true);
    //     }, 500);
    // }
    
    async function closeDetailsForm() {
        await fetchCsiRecords(domain);
        
        setTimeout(() => {
            setShowDetailsFormFlag(false);
            // setUploadFlag(false);
            setGlobalErrorMsg("");
            setGlobalErrorFlag(false);
            // setOrderListFlag(false);
            setShowListFlag(true);
        }, 500);
    }
    
    // async function closeUploadForm() {
    //     // await fetchCsiRecords();
        
    //     setTimeout(() => {
    //         setShowListFlag(true);
    //         setShowDetailsFormFlag(false);
    //         setUploadFlag(false);
    //         setOrderListFlag(false);
    //     }, 500);
    // }

    // async function orderListAction() {
    //     // console.log("In orderListAction(): language is: ", language);
    //     await fetchReferenceData("csi-categories", language);

    //     setCsiCategory(null);
    //     setFilteredRecords([]);

    //     setEditModeFlag(false);
    //     setShowListFlag(false);
    //     setShowDetailsFormFlag(false);
    //     setUploadFlag(false);
    //     setOrderListFlag(true);
    // }

    // function closeOrderListForm() {
    //     initializeForm();

    //     setEditModeFlag(false);
    //     setShowListFlag(true);
    //     setShowDetailsFormFlag(false);
    //     setUploadFlag(false);
    //     setOrderListFlag(false);
    // }
    
    async function createService() {
        // console.log("In createService(): language is: ", language);
        // console.log("In createService(): domain is: ", domain);
        // console.log("In createService(): location is: ", location);
        // console.log("In createService(): salesEmail is: ", salesEmail);
        // console.log("In createService(): supportEmail is: ", supportEmail);
        // console.log("In createService(): timeZone is: ", timeZone);
        // console.log("In createService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In createService(): appContext.userName is: ", appContext.userName);
    
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In createService(): Validation passed.");
            // console.log("In createService(): Validation passed.");

            const timestamp = moment.tz(Date.now(), timeZone);
            // console.log("In createService(): timestamp is: ", timestamp);

            // const startTime = moment.tz(csiStartTime, timeZone);
            // console.log("In createService(): startTime is: ", startTime);

            backdropProgress(true);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            let uniqueId = GenerateUniqueName();

            let key = {
                CsiPK: domain.value + "#" + language.languageCode + "#csi",
                CsiSK: domain.value + "#" + language.languageCode + "#" + location.value + "#" + uniqueId,
            };
            // console.log("In createService(): key is: ", key);

            let item = {
                "CsiPK": key.CsiPK,
                "CsiSK": key.CsiSK,

                "UniqueAttribute": key.CsiPK + "#" + key.CsiSK,

                "CsiId": uniqueId,

                "LanguageObject": lodash.isEmpty(language) ? null : language,
                "LanguageCode": lodash.isEmpty(language) ? null : language.languageCode,

                "Domain": lodash.isEmpty(domain) ? null : domain,
                "DomainCode": lodash.isEmpty(domain) ? null : domain.value,

                "Location": lodash.isEmpty(location) ? null : location,
                "LocationCode": lodash.isEmpty(location) ? null : location.value,

                "SalesEmail": lodash.isEmpty(salesEmail) ? null : salesEmail,
                "SupportEmail": lodash.isEmpty(supportEmail) ? null : supportEmail,

                "SalesPhone": lodash.isEmpty(salesPhone) ? null : salesPhone,
                "SupportPhone": lodash.isEmpty(supportPhone) ? null : supportPhone,
                "SmsPhone": lodash.isEmpty(smsPhone) ? null : smsPhone,
                "WhatsAppPhone": lodash.isEmpty(whatsAppPhone) ? null : whatsAppPhone,
                "CallMePhone": lodash.isEmpty(callMePhone) ? null : callMePhone,

                "DomainLangLocation": domain.value + "#" + language.languageCode + "#" + location.value,// For GSI

                "ModifiedBy": appContext.userName,
                "CreatedAt": timestamp,
                "UpdatedAt": timestamp,
            };

            // console.log('In createService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_not_exists(UniqueAttribute)";

            let params = {
                apiName: "CommonDynamodbAPI",
                path: "/common/dynamodb/put",
                payload: {
                    tableName: tableName,
                    item: item,
                    conditionExpression: conditionExpression,
                    // updateExpression: updateExpression,
                    // expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In createService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In createService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In createService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar 
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);
            }).catch((err) => {
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                switch(err.code) {
                    case "ConditionalCheckFailedException":
                        console.error("In createService(): Duplicate Error");
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        break;
                    default:
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        console.error("In createService(): err is: ", JSON.stringify(err, null, 2));
                        break;
                }
            });
        }
    }

    async function updateService() {
        // console.log("In updateService(): language is: ", language);
        // console.log("In updateService(): domain is: ", domain);
        // console.log("In updateService(): location is: ", location);
        // console.log("In updateService(): salesEmail is: ", salesEmail);
        // console.log("In updateService(): supportEmail is: ", supportEmail);
        // console.log("In updateService(): timeZone is: ", timeZone);
        // console.log("In updateService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In updateService(): appContext.userName is: ", appContext.userName);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In updateService(): Validation passed.");

            // let uniqueId = GenerateUniqueName();// conversion
                
            const timestamp = moment.tz(Date.now(), timeZone);

            backdropProgress(true);
            const startTime = moment.tz(blogStartTime, timeZone);
            // console.log("In updateService(): startTime is: ", startTime);

            let key = {
                CsiPK: appContext.currentPK,
                CsiSK: appContext.currentSK,
            };
            // console.log("In updateService(): key is: ", key);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
            let updateExpression =
                "SET " +
                "UniqueAttribute = :uniqueAttribute, " +

                "SalesEmail = :salesEmail, " +
                "SupportEmail = :supportEmail, " +

                "SalesPhone = :salesPhone, " +
                "SupportPhone = :supportPhone, " +
                "SmsPhone = :smsPhone, " +
                "WhatsAppPhone = :whatsAppPhone, " +
                "CallMePhone = :callMePhone, " +

                "ModifiedBy = :modifiedBy, " + 
                "UpdatedAt = :updatedAt";

            let expressionAttributeValues = {
                ":uniqueAttribute": key.CsiPK + "#" + key.CsiSK,
                
                ":supportEmail": lodash.isEmpty(supportEmail) ? null : supportEmail,
                ":salesEmail": lodash.isEmpty(salesEmail) ? null : salesEmail,

                ":salesPhone": lodash.isEmpty(salesPhone) ? null : salesPhone,
                ":supportPhone": lodash.isEmpty(supportPhone) ? null : supportPhone,
                ":smsPhone": lodash.isEmpty(smsPhone) ? null : smsPhone,
                ":whatsAppPhone": lodash.isEmpty(whatsAppPhone) ? null : whatsAppPhone,
                ":callMePhone": lodash.isEmpty(callMePhone) ? null : callMePhone,
                
                ":modifiedBy": appContext.userName,
                ":updatedAt": timestamp,
            };
            // console.log('In updateService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_exists(UniqueAttribute)";

            let params = {
                apiName: "CommonDynamodbAPI",
                path: "/common/dynamodb/update",
                payload: {
                    tableName: tableName,
                    key: key,
                    conditionExpression: conditionExpression,
                    updateExpression: updateExpression,
                    expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In updateService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In updateService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In updateService(): response is: ', JSON.stringify(response, null, 2));
                setShowDetailsFormFlag(false);
                // setUploadFlag(false);

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                // initializeForm();

                backdropProgress(false);

                await fetchCsiRecords(domain);
                setShowListFlag(true);
            }).catch((err) => {
                console.error("In updateService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    async function deleteService(recToDelete) {
        // console.log("In deleteService(): recToDelete is: ", recToDelete);
        // setDeleteCompleteFlag(false);
        // let itemsToDelete = [];

        if (!lodash.isEmpty(recToDelete)) {
            backdropProgress(true);

            let key = {
                CsiPK: recToDelete.CsiPK,
                CsiSK: recToDelete.CsiSK,
            };

            let params = {
                apiName: "CommonDynamodbAPI",
                path: "/common/dynamodb/delete",
                payload: {
                    tableName: tableName,
                    key: key,
                },
            };
    
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In deleteService(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In deleteService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                backdropProgress(false);
                await fetchCsiRecords(domain);
            }).catch((err) => {
                console.error("In deleteService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    function validateForm() {
        let formErrFlag = false;
    
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
    
        setLanguageErrFlag(false);
        setDomainErrFlag(false);
        setLocationErrFlag(false);
    
        setSalesPhoneErrFlag(false);
        setSalesEmailErrFlag(false);
    
        setSupportPhoneErrFlag(false);
        setSmsPhoneErrFlag(false);
        setWhatsAppPhoneErrFlag(false);
        setCallMePhoneErrFlag(false);
        setSupportEmailErrFlag(false);
    
        // moment.locale(currentLanguage.languageCode);

        if (lodash.isEmpty(language)) {
            formErrFlag = true;
            setLanguageErrFlag(true);
        }
    
        if (lodash.isEmpty(domain)) {
            formErrFlag = true;
            setDomainErrFlag(true);
        }
    
        if (location === null || location === "") {
          formErrFlag = true;
          setLocationErrFlag(true);
        }
    
        // For Sales
        if (
          salesPhone !== null &&
          salesPhone !== "" &&
          !isMobilePhone(salesPhone, phoneLocale)
        ) {
          formErrFlag = true;
          setSalesPhoneErrFlag(true);
          setGlobalErrorMsg(salesPhoneErrMsg);
        }
    
        if (salesEmail !== null && salesEmail !== "") {
          if (!salesEmail.match(GlobalConstants.emailRegex)) {
            formErrFlag = true;
            setSalesEmailErrFlag(true);
            // setSalesEmailVariant("danger");
            setGlobalErrorMsg(salesEmailErrMsg);
          }
        }
    
        // For Support
        if (
          supportPhone !== null &&
          supportPhone !== "" &&
          !isMobilePhone(supportPhone, phoneLocale)
        ) {
          formErrFlag = true;
          setSupportPhoneErrFlag(true);
          setGlobalErrorMsg(supportPhoneErrMsg);
        }
    
        if (
          smsPhone !== null &&
          smsPhone !== "" &&
          !isMobilePhone(smsPhone, phoneLocale)
        ) {
          formErrFlag = true;
          setSmsPhoneErrFlag(true);
          setGlobalErrorMsg(smsPhoneErrMsg);
        }
    
        if (
          whatsAppPhone !== null &&
          whatsAppPhone !== "" &&
          !isMobilePhone(whatsAppPhone, phoneLocale)
        ) {
          formErrFlag = true;
          setWhatsAppPhoneErrFlag(true);
          setGlobalErrorMsg(whatsAppPhoneErrMsg);
        }
    
        if (
          callMePhone !== null &&
          callMePhone !== "" &&
          !isMobilePhone(callMePhone, phoneLocale)
        ) {
          formErrFlag = true;
          setCallMePhoneErrFlag(true);
          setGlobalErrorMsg(callMePhoneErrMsg);
        }
    
        if (supportEmail !== null && supportEmail !== "") {
          if (!supportEmail.match(GlobalConstants.emailRegex)) {
            formErrFlag = true;
            setSupportEmailErrFlag(true);
            // setSupportEmailVariant("danger");
            setGlobalErrorMsg(supportEmailErrMsg);
          }
        }
    
        if (formErrFlag) {
          setGlobalErrorFlag(true);
        }
        return formErrFlag;
      }
    
      function initializeForm() {
        // setLanguage(null);
        // setDomain(null);
        setLocation(null);
    
        setSalesPhone("");
        setSalesEmail("");
    
        setSupportPhone("");
        setSmsPhone("");
        setWhatsAppPhone("");
        setCallMePhone("");
        setSupportEmail("");
    
        setSubmitButtonFlag(false);
      }

    async function buttonFormAction() {
        // console.log("In buttonFormAction()");

        if (editModeFlag) {
            await updateService();
        } else {
            await createService();
        }
    }
    
    function snackbarOnClose(event, reason) {
        setSnackbarOpen(false);
    }

    function handleMultipleSelectionChange(data) {
        // console.log("In handleMultipleSelectionChange(): data.value is: ", data.value);
        setSelectedTableRows(data.value);
        // setTableDirtyFlag(true);
    }

    //------------------
    // For AlertDialog
    //------------------
    const [deleteType, setDeleteType] = useState("");

    function showAlertDialog(item, type) {
        // console.log("In showAlertDialog(): type is: ", type);
        setDeleteType(type);
        
        if (type && type === "single") {// single deletion
            setRecordToDelete(item);

            let subContent = item.Location.text;
            if (!lodash.isEmpty(item.Location)) {
                subContent += " - ";
                subContent += csiRecordLabel;
            }
            setDialogSubContent(subContent);
            setAlertOpen(true);
        } else {// multiple deletions
            let msg = FormatNumber(selectedTableRows.length, appContext.currentLanguage.languageCode);
            msg += " ";
            msg += selectedTableRows.length > 1 ? csiRecordsLabel : csiRecordLabel;
            setDialogSubContent(msg);
            setAlertOpen(true);
        }
    }

    async function alertClickAgree() {
        // Close Alert
        if (deleteType && deleteType === "single") {
            setAlertOpen(false);
            await deleteService(recordToDelete);
            setRecordToDelete(null);
            setSelectedTableRows([]);
        } else {
            setAlertOpen(false);
            // deleteMultipleAction();
            processMultipleDeletionRecs();
        }
    }

    function alertClickDisagree() {
        // Close Alert
        setAlertOpen(false);
        return false;
    }

    function alertClose() {
        // Close Alert
        setAlertOpen(false);
    }

    function deleteIconTemplate(rowData, column) {
        return <DeleteIcon color="secondary" onClick={() => showAlertDialog(rowData, "single")}/>
    }

    function editIconTemplate(rowData, column) {
        return <EditIcon color="primary" onClick={() => editRecordAction(rowData)}/>
    }
    
    function sortDateField(event) {
        // console.log("In sortDateField(): event is: ", event);

        var values = [...csiListRecords];
        values.sort((data1, data2) => {
            // Note: We are using actual DateTime object and not the dateTimeFormatted 
            // used for display on data table.
            let value1 = data1.UpdatedAt;
            let value2 = data2.UpdatedAt;
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });

        return values;
    }

    let processedRecs = 0;

    async function deleteMultipleAction() {
        // console.log("In deleteMultipleAction(): selectedTableRows.length is: ", selectedTableRows.length);
        // console.log("In deleteMultipleAction(): selectedTableRows is: ", selectedTableRows);

        // Disable Save changes button
        // setTableDirtyFlag(false);

        return new Promise((resolve, reject) => {
            if (selectedTableRows && selectedTableRows.length > 0) {
                selectedTableRows.map(record => {
                    setTimeout(async () => {
                        await deleteService(record);

                        processedRecs++;

                        if (processedRecs === selectedTableRows.length) {
                            resolve("Done");
                        }
                    }, 1000);
                });
            }
        });
    }

    async function processMultipleDeletionRecs() {
        // console.log("In processMultipleDeletionRecs()");

        //-------------------
        // Promise.all
        //-------------------
        Promise.all(
            [
                deleteMultipleAction()
            ]).then(async (results) => {
                // console.log("In processMultipleDeletionRecs(): results is: ", results);
                // console.log("In processMultipleDeletionRecs(): records deleted from AWS, now delete them from DataTable");
                // Remove deleted records from DataTable
                let remainingRecords = FilterArrayFromArray(csiListRecords, selectedTableRows);
                if (remainingRecords) {
                    // setBlogRecords(remainingRecords);
                    setSelectedTableRows([]);
                }
            });
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        // setBlogContent(item.BlogContent);

        // setRecordToEdit(item);

        appContext.currentPK = item.CsiPK;
        appContext.currentSK = item.CsiSK;
        await getCsiRecord({CsiPK: item.CsiPK, CsiSK: item.CsiSK,});

        setTimeout(() => {
            setShowListFlag(false);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);
            setShowDetailsFormFlag(true);
        }, 500);
    }

    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    //------------------------
    // For sliceText and Dialog
    //------------------------

    // function sliceMoreAction(record) {
    //     setDialogData(record.BlogContent);
    //     setDialogHeader(record.CsiQuestion);
    //     setDlgVisibleFlag(true);
    // }

    function hideModalDlg() {
        setDlgVisibleFlag(false);
    }
  
    // function sliceText(text, maxChars) {
    //     let retValue = text;
    //     if (text.length > maxChars) {
    //         retValue = text.substring(0, (maxChars - 1));
    //         retValue += " ...";
    //     }
    //     return retValue;
    // }

    //------------------
    // For OrderList
    //------------------

    // function handleFilteredRecords(event) {
    //     setFilteredRecords(event.value);
    // }

    // function handleOrderListCategoryChange(event, item) {
    //     event.preventDefault();
    //     event.persist();
    //     // console.log("In handleOrderListCategoryChange(): item is ", item);
    //     // console.log("In handleOrderListCategoryChange(): csiListRecords is ", csiListRecords);

    //     setFilteredRecords([]);
    //     setSubmitButtonFlag(false);
        
    //     setCsiCategory(item);

    //     if (!lodash.isEmpty(item) && csiListRecords && csiListRecords.length > 0) {
    //         let filteredRecords = csiListRecords.filter(record => record.CsiCategory.value === item.value);
    //         setFilteredRecords(filteredRecords);
    //         if (filteredRecords && filteredRecords.length > 0) {
    //             filteredRecords.sort((a, b) => (a.CsiSeqNumber > b.CsiSeqNumber ? 1 : -1));
    //             setFilteredRecords(filteredRecords);
    //             setSubmitButtonFlag(true);
    //         }
    //     }
    // }

    // function orderListTemplate(record) {
    //     return (
    //         <Grid container justify="flex-start" style={{width: '100% !important', display: 'flex'}}>
    //             <Grid item xs={12} sm={12}>
    //                 <Grid container justify="flex-start">
    //                         <Grid item xs={1} sm={1} style={{textAlign: 'left'}}>
    //                             <Typography variant="caption" style={{textAlign: 'left'}}>
    //                                 {"" + record.CsiSeqNumber}.
    //                             </Typography>
    //                         </Grid>

    //                         <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
    //                             <Typography variant="caption" style={{textAlign: 'left'}}>
    //                                 {record.CsiQuestion}
    //                             </Typography>
    //                         </Grid>
    //                 </Grid>
    //             </Grid>
    //         </Grid>
    //     );
    // }
    
    // async function organizeService() {
    //     // console.log("In organizeService(): filteredRecords is: ", filteredRecords);

    //     backdropProgress(true);

    //     // Initialize
    //     setGlobalErrorFlag(false);
    //     setGlobalErrorMsg("");

    //     if (filteredRecords && filteredRecords.length > 0) {
    //         // console.log("In organizeService(): Validation passed.");

    //         // Get user timezone
    //         const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //         const timestamp = moment.tz(Date.now(), timeZone);
    //         const items = [];

    //         filteredRecords.map((record, index) => {
    //             let key = {
    //                 CsiPK: record.CsiPK,
    //                 CsiSK: record.CsiSK,
    //             };
    //             // console.log("In updateGuestBookService(): key is: ", key);

    //             // ConditionExpression: "attribute_exists(resourceCode)",
    //             let conditionExpression = "";

    //             // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
    //             let updateExpression =
    //                 "SET " +
    //                 "UniqueAttribute = :uniqueAttribute, " +

    //                 "CsiSeqNumber = :csiSeqNumber, " +

    //                 "ModifiedBy = :modifiedBy, " + 
    //                 "UpdatedAt = :updatedAt";
        
    //             let expressionAttributeValues = {
    //                 ":uniqueAttribute": key,

    //                 ":csiSeqNumber": index + 1,

    //                 ":modifiedBy": appContext.userName,
    //                 ":updatedAt": timestamp,
    //             };
    //             // console.log('In updateGuestBookService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

    //             conditionExpression = "attribute_exists(UniqueAttribute)";

    //             let item = {
    //                 key: key,
    //                 conditionExpression: conditionExpression,
    //                 updateExpression: updateExpression,
    //                 expressionAttributeValues: expressionAttributeValues,
    //             };
    //             items.push(item);
    //         });
    //         // console.log("In organizeService(): items is: ", items);

    //         let params = {
    //             apiName: "CommonDynamodbAPI",
    //             path: "/common/dynamodb/customBatchUpdate",
    //             payload: {
    //                 updateItems: items,
    //             },
    //         };
    //         // console.log('In organizeService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

    //         let initObj = {
    //             headers: {
    //             "Content-Type": "application/json",
    //             Authorization: "",
    //             },
    //             response: true, // return entire response object instead of response.data
    //             body: {},
    //         };

    //         initObj.headers.Authorization = appContext.jwtToken;
    //         initObj.body = params.payload;
    //         // console.log('In organizeService(): initObj is: ', JSON.stringify(initObj, null, 2));

    //         API.post(params.apiName, params.path, initObj).then(async (response) => {
    //             // console.log('In organizeService(): response is: ', JSON.stringify(response, null, 2));
    //             setShowDetailsFormFlag(false);
    //             setUploadFlag(false);
    //             setOrderListFlag(false);

    //             // Show Snackbar
    //             setSnackbarsVariant("success");
    //             setSnackbarsMsg(operationSuccessMsg);
    //             setSnackbarOpen(true);

    //             initializeForm();

    //             backdropProgress(false);

    //             await fetchCsiRecords();
    //             setShowListFlag(true);
    //         }).catch((err) => {
    //             console.error("In organizeService(): err is: ", JSON.stringify(err, null, 2));
    //             setGlobalErrorFlag(true);
    //             setGlobalErrorMsg(operationFailureMsg);
    //             backdropProgress(false);
    //         });
    //     }

    // }

    const cloneRecordAction = () => {
        setLanguage(null);
        setDomain(null);
        setLocation(null);

        setSubmitButtonFlag(false);
        setEditModeFlag(false);
    }
    
    function handleLocationChange(event, item) {
        // console.log('In handleLocationChange(): item is', item);
        setLocation(item);
    
        // setLocationChangedFlag(true);

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(salesEmail) &&
            !lodash.isEmpty(supportEmail) &&
            !lodash.isEmpty(domain) 
        ) {
            setSubmitButtonFlag(true);
        }
    
        // let filteredRec = null;
    
        // if (contactInfoData !== null && contactInfoData.length > 0) {
        //     filteredRec = contactInfoData.filter(
        //         (item) => item.ResourceCode === selectedOption
        //     );
        // }
        // // console.log('In handleLocationChange(): filteredRec is', filteredRec);
        // if (filteredRec !== null) {
        //     // console.log('In handleLocationChange(): filteredRec.ResourcePK is', filteredRec[0].ResourcePK);
        //     // console.log('In handleLocationChange(): filteredRec.ResourceSK is', filteredRec[0].ResourceSK);
        //     setCurrentPK(filteredRec[0].ResourcePK);
        //     setCurrentSK(filteredRec[0].ResourceSK);
        // }
    
        // // For Sales
        // if (filteredRec !== null && filteredRec[0].SalesPhone) {
        //     setSalesPhone(filteredRec[0].SalesPhone);
        // }
    
        // if (filteredRec !== null && filteredRec[0].SalesEmail) {
        //     setSalesEmail(filteredRec[0].SalesEmail);
        // }
    
        // // For Support
        // if (filteredRec !== null && filteredRec[0].SupportPhone) {
        //     setSupportPhone(filteredRec[0].SupportPhone);
        // }
    
        // if (filteredRec !== null && filteredRec[0].SmsPhone) {
        //     setSmsPhone(filteredRec[0].SmsPhone);
        // }
    
        // if (filteredRec !== null && filteredRec[0].WhatsAppPhone) {
        //     setWhatsAppPhone(filteredRec[0].WhatsAppPhone);
        // }
    
        // if (filteredRec !== null && filteredRec[0].CallMePhone) {
        //     setCallMePhone(filteredRec[0].CallMePhone);
        // }
    
        // if (filteredRec !== null && filteredRec[0].SupportEmail) {
        //     setSupportEmail(filteredRec[0].SupportEmail);
        // }
    
        // if (selectLangChangedFlag) {
        //     setSubmitFlag(true);
        // }
    }
    
    function handleSalesPhoneChange(salesNum) {
        // console.log("In handleSalesPhoneChange(): salesNum is: ", salesNum);
        setGlobalErrorFlag(false);
        // setSubmitFlag(false);
    
        setSalesPhoneErrFlag(false);
        setSalesPhone(salesNum);
    }
    
    function handleSupportPhoneChange(supportNum) {
        // console.log("In handleSupportPhoneChange(): supportNum is: ", supportNum);
        setGlobalErrorFlag(false);
        // setSubmitFlag(false);
    
        setSupportPhoneErrFlag(false);
        setSupportPhone(supportNum);
    }
    
    function handleSmsPhoneChange(smsNum) {
        // console.log("In handleSmsPhoneChange(): smsNum is: ", smsNum);
        setGlobalErrorFlag(false);
        // setSubmitFlag(false);
    
        setSmsPhoneErrFlag(false);
        setSmsPhone(smsNum);
    }
    
    function handleWhatsAppPhoneChange(whatsAppNum) {
        // console.log("In handleWhatsAppPhoneChange(): whatsAppNum is: ", whatsAppNum);
        setGlobalErrorFlag(false);
        // setSubmitFlag(false);
    
        setWhatsAppPhoneErrFlag(false);
        setWhatsAppPhone(whatsAppNum);
    }
    
    function handleCallMePhoneChange(callMeNum) {
        // console.log("In handleCallMePhoneChange(): callMeNum is: ", callMeNum);
        setGlobalErrorFlag(false);
        // setSubmitFlag(false);

        setCallMePhoneErrFlag(false);
        setCallMePhone(callMeNum);
    }
    
    return (
        <div dir={direction} className={clsx(classes.root, classes.divWrapper)}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {renderDomFlag &&
                <>
                    {/* { !appContext.signedIn && <Redirect to='/signIn' />} */}
                    <CssBaseline />
                    <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />

                    <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
                        <Container component="div" className={classes.main} maxWidth="lg">
                            <StyledPaper elevation={3} className={classes.paper}>
                                <Grid container justify="center">
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="h5" color="primary" style={{ textAlign: "center" }}>
                                            {customerServInfoHdr}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                                            {requiredFields}
                                        </Typography>
                                    </Grid>

                                    {globalErrorFlag && (
                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                            <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                                        </Grid>
                                    )}

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            {showListFlag &&
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                                    <label htmlFor="languageId" style={directionAlignment}>
                                                                        <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                            *{languageLabel}
                                                                        </Typography>
                                                                    </label>

                                                                    <Autocomplete
                                                                        id="languageId"
                                                                        value={language}
                                                                        options={languages}
                                                                        getOptionLabel={option => option.languageName}
                                                                        getOptionSelected={option => option.languageName}
                                                                        onChange={handleListLanguageChange}
                                                                        loadingText={loadingText}
                                                                        noOptionsText={noOptionsText}
                                                                        autoHighlight={true}
                                                                        clearText={clearText}
                                                                        closeText={closeText}
                                                                        disabled={editModeFlag ? true : false}
                                                                        renderInput={params => 
                                                                            <TextField 
                                                                                {...params} 
                                                                                placeholder={languagePrompt} 
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                error={languageErrFlag}
                                                                                helperText={languageErrFlag ? languageErrMsg : ""}
                                                                                // InputProps={{
                                                                                //     readOnly: editModeFlag ? true : false,
                                                                                // }}
                                                                            />
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                                    <label htmlFor="domainId" style={directionAlignment}>
                                                                        <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                            *{domainLabel}
                                                                        </Typography>
                                                                    </label>

                                                                    <Autocomplete
                                                                        id="domainId"
                                                                        value={domain}
                                                                        options={domainOptions}
                                                                        getOptionLabel={option => option.text}
                                                                        getOptionSelected={option => option.text}
                                                                        onChange={handleListDomainChange}
                                                                        loadingText={loadingText}
                                                                        noOptionsText={noOptionsText}
                                                                        autoHighlight={true}
                                                                        clearText={clearText}
                                                                        closeText={closeText}
                                                                        // disabled={editModeFlag ? true : false}
                                                                        renderInput={params => 
                                                                            <TextField 
                                                                                {...params} 
                                                                                placeholder={domainPrompt} 
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                error={domainErrFlag}
                                                                                helperText={domainErrFlag ? domainErrMsg : ""}
                                                                                // InputProps={{
                                                                                //     readOnly: editModeFlag ? true : false,
                                                                                // }}
                                                                            />
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={12} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        {csiListRecords && csiListRecords.length > 0 ?
                                                            <>
                                                                <Grid item xs={12} sm={12}>
                                                                    <LineSpacing value={12} />
                                                                </Grid>

                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={6} sm={6}>
                                                                            <Grid container justify="flex-start">
                                                                                <Button variant="contained" size="large" disabled={selectedTableRows && selectedTableRows.length > 0 ? false : true} color="primary" onClick={() => showAlertDialog(null, "multiple")}>
                                                                                    <DeleteIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                    {deleteLabel}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>

                                                                        {/* <Grid item xs={3} sm={3}>
                                                                            <Grid container justify="center">
                                                                                <Button variant="outlined" size="large" disabled={false} color="primary" onClick={orderListAction}>
                                                                                    <ImportExportIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                    {organizeLabel}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid item xs={3} sm={3}>
                                                                            <Grid container justify="center">
                                                                                <Button variant="outlined" size="large" disabled={false} color="primary" onClick={convertAction}>
                                                                                    <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                    {convertLabel}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid> */}

                                                                        <Grid item xs={6} sm={6}>
                                                                            <Grid container justify="flex-end">
                                                                                <Button variant="contained" size="large" disabled={false} color="primary" onClick={createMoreAction}>
                                                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                    {createMoreLabel}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12}>
                                                                    <LineSpacing value={8} />
                                                                </Grid>
                                                                
                                                                <Grid container justify="center">
                                                                    <Grid item xs={12} sm={12}>
                                                                        <div className="datatable-doc-demo">
                                                                            <DataTable 
                                                                                value={csiListRecords}
                                                                                resizableColumns={true} 
                                                                                columnResizeMode={"fit"}
                                                                                sortMode={"multiple"}
                                                                                responsive={true}
                                                                                reorderableColumns={true}

                                                                                // selectionMode={"multiple"}
                                                                                selection={selectedTableRows}
                                                                                onSelectionChange={handleMultipleSelectionChange}

                                                                                // paginator={visibleRowsCount > 10 ? paginatorFlag : !paginatorFlag} 
                                                                                paginator={csiListRecords && csiListRecords.length > 10} 
                                                                                // paginatorLeft={paginatorLeft}

                                                                                paginatorTemplate={paginatorTemplate}
                                                                                currentPageReportTemplate={"{first}" + " " + toLabel + " " + "{last}" + " " + ofLabel + " " + "{totalRecords}" + " " + recordsLabel} 
                                                                                rows={visibleRowsCount} 
                                                                                rowsPerPageOptions={rowsPerPageOptions}
                                                                                // className="p-datatable-responsive-cob"
                                                                                // className="p-datatable-striped"
                                                                                emptyMessage={noRecordsFound}
                                                                                className={clsx("p-datatable-striped", "p-datatable-customers")}
                                                                                rowHover 
                                                                                >

                                                                                <Column 
                                                                                    header={deleteLabel} 
                                                                                    selectionMode="multiple" 
                                                                                    style={{width:'5em', textAlign:'center'}}
                                                                                />
                                                                                <Column 
                                                                                    field="LocationDesc" 
                                                                                    header={locationLabel} 
                                                                                    sortable={true} 
                                                                                    filter={true} 
                                                                                    filterMatchMode="contains" 
                                                                                />
                                                                                <Column 
                                                                                    field="SalesEmail" 
                                                                                    header={salesEmailLabel} 
                                                                                    sortable={true} 
                                                                                    filter={true} 
                                                                                    filterMatchMode="contains" 
                                                                                />
                                                                                <Column 
                                                                                    field="SupportEmail" 
                                                                                    header={supportEmailLabel} 
                                                                                    sortable={true} 
                                                                                    filter={true} 
                                                                                    filterMatchMode="contains" 
                                                                                />
                                                                                <Column 
                                                                                    field="FormattedDateTime" 
                                                                                    header={lastUpdateLabel} 
                                                                                    sortable={true} 
                                                                                    sortFunction={sortDateField}
                                                                                    filter={true} 
                                                                                    filterMatchMode="contains" 
                                                                                    style={{textAlign:'center'}}
                                                                                />
                                                                                <Column 
                                                                                    field="deleteIcon" 
                                                                                    header={deleteLabel} 
                                                                                    body={deleteIconTemplate} 
                                                                                    style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                />
                                                                                <Column 
                                                                                    field="editIcon" 
                                                                                    header={editLabel} 
                                                                                    body={editIconTemplate} 
                                                                                    style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                />
                                                                            </DataTable>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        :
                                                            <>
                                                                {noRecordFoundFlag &&
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12}>
                                                                            <Typography variant="body1" color="secondary">
                                                                                {noRecordsFound}
                                                                            </Typography>
                                                                        </Grid>

                                                                        <Grid item xs={12} sm={12}>
                                                                            <LineSpacing value={12} />
                                                                        </Grid>

                                                                        <Grid item xs={12} sm={12}>
                                                                            <Button variant="contained" size="large" disabled={false} color="primary" onClick={createCsiAction}>
                                                                                <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                {createCsiLabel}
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            </>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            }

                                            {showDetailsFormFlag && 
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="flex-end">
                                                            <Tooltip title={closeLabel} placement="bottom">
                                                                <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                                    <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={6} sm={6}>
                                                        <Grid container justify="flex-start">
                                                            <Typography variant="h6" color="primary">
                                                                {editModeFlag ? updateCsiLabel : createCsiLabel}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={6} sm={6}>
                                                        <Grid container justify="center">
                                                            <Button variant="contained" size="small" disabled={editModeFlag ? false : true} color="primary" onClick={cloneRecordAction}>
                                                                {cloneRecordLabel}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={12} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                                    <label htmlFor="languageId" style={directionAlignment}>
                                                                        <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                            *{languageLabel}
                                                                        </Typography>
                                                                    </label>

                                                                    <Autocomplete
                                                                        id="languageId"
                                                                        value={language}
                                                                        options={languages}
                                                                        getOptionLabel={option => option.languageName}
                                                                        getOptionSelected={option => option.languageName}
                                                                        onChange={handleLanguageChange}
                                                                        loadingText={loadingText}
                                                                        noOptionsText={noOptionsText}
                                                                        autoHighlight={true}
                                                                        clearText={clearText}
                                                                        closeText={closeText}
                                                                        disabled={editModeFlag ? true : false}
                                                                        renderInput={params => 
                                                                            <TextField 
                                                                                {...params} 
                                                                                placeholder={languagePrompt} 
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                error={languageErrFlag}
                                                                                helperText={languageErrFlag ? languageErrMsg : ""}
                                                                                // InputProps={{
                                                                                //     readOnly: editModeFlag ? true : false,
                                                                                // }}
                                                                            />
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                                    <label htmlFor="domainId" style={directionAlignment}>
                                                                        <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                            *{domainLabel}
                                                                        </Typography>
                                                                    </label>

                                                                    <Autocomplete
                                                                        id="domainId"
                                                                        value={domain}
                                                                        options={domainOptions}
                                                                        getOptionLabel={option => option.text}
                                                                        getOptionSelected={option => option.text}
                                                                        onChange={handleDomainChange}
                                                                        loadingText={loadingText}
                                                                        noOptionsText={noOptionsText}
                                                                        autoHighlight={true}
                                                                        clearText={clearText}
                                                                        closeText={closeText}
                                                                        disabled={editModeFlag ? true : false}
                                                                        renderInput={params => 
                                                                            <TextField 
                                                                                {...params} 
                                                                                placeholder={domainPrompt} 
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                error={domainErrFlag}
                                                                                helperText={domainErrFlag ? domainErrMsg : ""}
                                                                                // InputProps={{
                                                                                //     readOnly: editModeFlag ? true : false,
                                                                                // }}
                                                                            />
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={12} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={locationErrFlag}>
                                                                    <label htmlFor="locationId" style={directionAlignment}>
                                                                        <Typography variant="subtitle1" color={locationErrFlag ? "secondary" : "inherit"}>
                                                                            *{locationLabel}
                                                                        </Typography>
                                                                    </label>

                                                                    <Autocomplete
                                                                        id="locationId"
                                                                        value={location}
                                                                        options={locationOptions}
                                                                        getOptionLabel={option => option.text}
                                                                        getOptionSelected={option => option.text}
                                                                        onChange={handleLocationChange}
                                                                        loadingText={loadingText}
                                                                        noOptionsText={noOptionsText}
                                                                        autoHighlight={true}
                                                                        clearText={clearText}
                                                                        closeText={closeText}
                                                                        disabled={editModeFlag ? true : false}
                                                                        renderInput={params => 
                                                                            <TextField 
                                                                                {...params} 
                                                                                placeholder={locationPrompt} 
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                error={locationErrFlag}
                                                                                helperText={locationErrFlag ? locationErrMsg : ""}
                                                                                // InputProps={{
                                                                                //     readOnly: editModeFlag ? true : false,
                                                                                // }}
                                                                            />
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={supportEmailErrFlag}>
                                                                    <label htmlFor="supportEmailId" style={directionAlignment}>
                                                                        <Typography variant="subtitle1" color={supportEmailErrFlag ? "secondary" : "inherit"}>
                                                                            *{supportEmailLabel}
                                                                        </Typography>
                                                                    </label>

                                                                    <TextField
                                                                        id="supportEmailId"
                                                                        value={supportEmail}
                                                                        placeholder={supportEmailPrompt}
                                                                        onChange={handleChange}
                                                                        required
                                                                        fullWidth
                                                                        type={"text"}
                                                                        error={supportEmailErrFlag}
                                                                        margin="none"
                                                                        variant="outlined"
                                                                        helperText={supportEmailErrFlag ? supportEmailErrMsg : ""}
                                                                        // InputProps={{
                                                                        //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                        // }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={12} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={salesEmailErrFlag}>
                                                                    <label htmlFor="salesEmailId" style={directionAlignment}>
                                                                        <Typography variant="subtitle1" color={salesEmailErrFlag ? "secondary" : "inherit"}>
                                                                            *{salesEmailLabel}
                                                                        </Typography>
                                                                    </label>

                                                                    <TextField
                                                                        id="salesEmailId"
                                                                        value={salesEmail}
                                                                        placeholder={salesEmailPrompt}
                                                                        onChange={handleChange}
                                                                        required
                                                                        fullWidth
                                                                        type={"text"}
                                                                        error={salesEmailErrFlag}
                                                                        margin="none"
                                                                        variant="outlined"
                                                                        helperText={salesEmailErrFlag ? salesEmailErrMsg : ""}
                                                                        // InputProps={{
                                                                        //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                        // }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12}>
                                                                <Grid container justify="center">
                                                                    <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={salesPhoneErrFlag}>
                                                                            <label htmlFor="salesPhoneId" style={directionAlignment}>
                                                                                <Typography variant="subtitle1" color={salesPhoneErrFlag ? "secondary" : "inherit"}>
                                                                                    {salesPhoneLabel}
                                                                                </Typography>
                                                                            </label>

                                                                            <div dir={"ltr"}>
                                                                                <ReactPhoneInput
                                                                                    id="salesPhoneId"
                                                                                    defaultCountry={defaultPhoneCountry}
                                                                                    value={salesPhone}
                                                                                    onChange={handleSalesPhoneChange}
                                                                                    inputClass={classes.phone}
                                                                                    preferredCountries={preferredCountries}
                                                                                    localization={localization}
                                                                                    placeholder={salesPhonePrompt}
                                                                                    enableSearchField={enableSearchField}
                                                                                    searchPlaceholder={searchPlaceholder}
                                                                                    isValid={(value) => {if (salesPhoneErrFlag) {
                                                                                            return false;
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <FormHelperText>{salesPhoneErrFlag ? salesPhoneErrMsg : ""}</FormHelperText>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={12} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12}>
                                                                <Grid container justify="center">
                                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={supportPhoneErrFlag}>
                                                                            <label htmlFor="supportPhoneId" style={directionAlignment}>
                                                                                <Typography variant="subtitle1" color={supportPhoneErrFlag ? "secondary" : "inherit"}>
                                                                                    {supportPhoneLabel}
                                                                                </Typography>
                                                                            </label>

                                                                            <div dir={"ltr"}>
                                                                                <ReactPhoneInput
                                                                                    id="supportPhoneId"
                                                                                    defaultCountry={defaultPhoneCountry}
                                                                                    value={supportPhone}
                                                                                    onChange={handleSupportPhoneChange}
                                                                                    inputClass={classes.phone}
                                                                                    preferredCountries={preferredCountries}
                                                                                    localization={localization}
                                                                                    placeholder={supportPhonePrompt}
                                                                                    enableSearchField={enableSearchField}
                                                                                    searchPlaceholder={searchPlaceholder}
                                                                                    isValid={(value) => {if (supportPhoneErrFlag) {
                                                                                            return false;
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <FormHelperText>{supportPhoneErrFlag ? supportPhoneErrMsg : ""}</FormHelperText>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12}>
                                                                <Grid container justify="center">
                                                                    <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={smsPhoneErrFlag}>
                                                                            <label htmlFor="smsPhoneId" style={directionAlignment}>
                                                                                <Typography variant="subtitle1" color={smsPhoneErrFlag ? "secondary" : "inherit"}>
                                                                                    {smsPhoneLabel}
                                                                                </Typography>
                                                                            </label>

                                                                            <div dir={"ltr"}>
                                                                                <ReactPhoneInput
                                                                                    id="smsPhoneId"
                                                                                    defaultCountry={defaultPhoneCountry}
                                                                                    value={smsPhone}
                                                                                    onChange={handleSmsPhoneChange}
                                                                                    inputClass={classes.phone}
                                                                                    preferredCountries={preferredCountries}
                                                                                    localization={localization}
                                                                                    placeholder={smsPhonePrompt}
                                                                                    enableSearchField={enableSearchField}
                                                                                    searchPlaceholder={searchPlaceholder}
                                                                                    isValid={(value) => {if (smsPhoneErrFlag) {
                                                                                            return false;
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <FormHelperText>{smsPhoneErrFlag ? smsPhoneErrMsg : ""}</FormHelperText>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={12} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12}>
                                                                <Grid container justify="center">
                                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={whatsAppPhoneErrFlag}>
                                                                            <label htmlFor="whatsAppPhoneId" style={directionAlignment}>
                                                                                <Typography variant="subtitle1" color={whatsAppPhoneErrFlag ? "secondary" : "inherit"}>
                                                                                    {whatsAppPhoneLabel}
                                                                                </Typography>
                                                                            </label>

                                                                            <div dir={"ltr"}>
                                                                                <ReactPhoneInput
                                                                                    id="whatsAppPhoneId"
                                                                                    defaultCountry={defaultPhoneCountry}
                                                                                    value={whatsAppPhone}
                                                                                    onChange={handleWhatsAppPhoneChange}
                                                                                    inputClass={classes.phone}
                                                                                    preferredCountries={preferredCountries}
                                                                                    localization={localization}
                                                                                    placeholder={whatsAppPhonePrompt}
                                                                                    enableSearchField={enableSearchField}
                                                                                    searchPlaceholder={searchPlaceholder}
                                                                                    isValid={(value) => {if (whatsAppPhoneErrFlag) {
                                                                                            return false;
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <FormHelperText>{whatsAppPhoneErrFlag ? whatsAppPhoneErrMsg : ""}</FormHelperText>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12}>
                                                                <Grid container justify="center">
                                                                    <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={callMePhoneErrFlag}>
                                                                            <label htmlFor="callMePhoneId" style={directionAlignment}>
                                                                                <Typography variant="subtitle1" color={callMePhoneErrFlag ? "secondary" : "inherit"}>
                                                                                    {callMePhoneLabel}
                                                                                </Typography>
                                                                            </label>

                                                                            <div dir={"ltr"}>
                                                                                <ReactPhoneInput
                                                                                    id="callMePhoneId"
                                                                                    defaultCountry={defaultPhoneCountry}
                                                                                    value={callMePhone}
                                                                                    onChange={handleCallMePhoneChange}
                                                                                    inputClass={classes.phone}
                                                                                    preferredCountries={preferredCountries}
                                                                                    localization={localization}
                                                                                    placeholder={callMePhonePrompt}
                                                                                    enableSearchField={enableSearchField}
                                                                                    searchPlaceholder={searchPlaceholder}
                                                                                    isValid={(value) => {if (callMePhoneErrFlag) {
                                                                                            return false;
                                                                                        } else {
                                                                                            return true;
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <FormHelperText>{callMePhoneErrFlag ? callMePhoneErrMsg : ""}</FormHelperText>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={24} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="center">
                                                            <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={buttonFormAction}>
                                                                {editModeFlag ?
                                                                <>
                                                                    <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                    {updateCsiLabel} 
                                                                </>
                                                                : 
                                                                <>
                                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                    {createCsiLabel}
                                                                </>
                                                                }
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }

                                            {/* {convertFlag &&
                                                <>
                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="flex-end">
                                                            <Tooltip title={closeLabel} placement="bottom">
                                                                <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                                    <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="flex-start">
                                                            <Typography variant="h6" color="primary">
                                                                {convertCsisLabel}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={12} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <CustomerServiceConverter 
                                                            showHeadersFlag={false}
                                                            referrer={"CustomerService"}
                                                        />
                                                    </Grid>
                                                </>
                                            } */}

                                            {/* {orderListFlag &&
                                                <Container component="div" className={classes.main} maxWidth="md">
                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="flex-end">
                                                            <Tooltip title={closeLabel} placement="bottom">
                                                                <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeOrderListForm}>
                                                                    <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="flex-start">
                                                            <Typography variant="h6" color="primary">
                                                                {organizeCsiListLabel}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={24} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={csiCategoryErrFlag}>
                                                                    <label htmlFor="csiCategoryId" style={directionAlignment}>
                                                                        <Typography variant="subtitle1" color={csiCategoryErrFlag ? "secondary" : "inherit"}>
                                                                            *{csiCategoryLabel}
                                                                        </Typography>
                                                                    </label>

                                                                    <Autocomplete
                                                                        id="csiCategoryId"
                                                                        value={csiCategory}
                                                                        options={categoryOptions}
                                                                        getOptionLabel={option => option.text}
                                                                        getOptionSelected={option => option.text}
                                                                        onChange={handleOrderListCategoryChange}
                                                                        loadingText={loadingText}
                                                                        noOptionsText={noOptionsText}
                                                                        autoHighlight={true}
                                                                        clearText={clearText}
                                                                        closeText={closeText}
                                                                        disabled={editModeFlag ? true : false}
                                                                        renderInput={params => 
                                                                            <TextField 
                                                                                {...params} 
                                                                                placeholder={csiCategoryPrompt} 
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                error={csiCategoryErrFlag}
                                                                                helperText={csiCategoryErrFlag ? csiCategoryErrMsg : ""}
                                                                                // InputProps={{
                                                                                //     readOnly: editModeFlag ? true : false,
                                                                                // }}
                                                                            />
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={24} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="center" style={{width: '100% !important'}}>
                                                            <div style={{minWidth: '100%'}}>
                                                                <OrderList 
                                                                    value={filteredRecords} 
                                                                    header={freqAskedQuestionsLabel} 
                                                                    dragdrop={dragdropFlag}
                                                                    listStyle={{width: '100% !important', height:'auto'}} 
                                                                    dataKey="CsiId"
                                                                    itemTemplate={orderListTemplate} 
                                                                    onChange={(e) => handleFilteredRecords(e)}
                                                                    style={{width: '100% !important', display: 'flex'}}
                                                                    >
                                                                </OrderList>
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={24} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="center">
                                                            <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={organizeService}>
                                                                <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                {saveLabel} 
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Container>
                                            } */}

                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={18} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </StyledPaper>
                        </Container>

                        <Grid item xs={12} sm={12}>
                            <LineSpacing value={48} />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </>
            }

            <Dialog 
                id="modalDlgId"
                rtl={appContext.dirction === 'rtl'}
                showHeader={true}
                header={dialogHeader}
                visible={dlgVisibleFlag} 
                maximizable={true}
                maximized={(isTabletView || isMobileView) ? true : false}
                modal={true} 
                onHide={hideModalDlg}
                >
                <Container component="div" className={classes.modalDlgMain} maxWidth="md">
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="flex-start" style={{color: '#000'}}>
                            <FroalaEditorView model={dialogData}/>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>

            {alertOpen && 
                <AlertDialog
                    alertOpen={alertOpen}
                    alertClose={alertClose}
                    alertClickAgree={alertClickAgree}
                    alertClickDisagree={alertClickDisagree}

                    dialogTitle={deleteRequested}
                    dialogMainContent={requestedToDeleteMsg}
                    dialogSubContent={dialogSubContent}
                    dialogQuestion={areYouSureMsg}
                    yesLabel={yesLabel}
                    noLabel={cancelLabel}
                />
            }

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={8000}
                onClose={snackbarOnClose}
                ContentProps={{ "aria-describedby": "message-id" }}
                message={<span id="message-id">{snackbarsMsg}</span>}
                variant={snackbarsVariant}
            />

            <footer className={classes.footer}>
                <div>
                    <PageFooter isSignedIn={appContext.signedIn} />
                </div>
            </footer>
        </div>
    )
}

export default CustomerService;
