import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// For React Google Maps
// import {
//     withGoogleMap,
//     withScriptjs,
//     GoogleMap,
//     Marker,
//     InfoWindow
// } from "react-google-maps";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";

// For React Google Maps
import * as parkData from "../../utils/maps/skateboard-parks.json";
import mapStyles from "../../utils/maps/mapStyles";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";
import LineSpacing from "../../components/misc/LineSpacing";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";

// AppContext
import AppContext from "../../config/AppContext";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 400px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const PageContainer = styled.div`
  width: 100%,
  display: 'flex',
  flexWrap: 'wrap',
  background: #fff;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    backgroundImage: `url(${"assets/logos/background20.png"})`,
  },
  gridWidth: {
    width: "80%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  mainForm: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  verticalAlignLabel: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  snackIcon: {
    marginRight: theme.spacing(1),
  },
}));

let geocodeAddr = {
  geocodeContactAddr: "Geocode contact addresses",
  allFieldsAreRequired: "All fields are required",
  languageFile: "Select Language",
  selectLanguageFile: "Please select language file to geocode",
  geocode: "Geocode",
  geocodeSuccessMsg: "Geocoding was successful.",
  geocodeFailureMsg: "Geocoding failed.",
  noDataGeocoded: "No records found to geocode.",
};

// let lang = "";

function GeocodeContactAddr(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLang.value);
  const [langSelectOptions, setLangSelectOptions] = useState([]);

  // For Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  // For ContactInfo
  const [geocodeAddrRes, setGeocodeAddrRes] = useState(geocodeAddr);
  const [contactInfoData, setContactInfoData] = useState([]);

  const {
    currLanguage,

    paperBackground,
    headerColor,
    iconsColor,
    textColor,

    mapId,
    mapWidth,
    mapHeight,
    mapOptions,
    regionCode,
    // onMapLoad,
    onMapScriptLoaded,
  } = props;

  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  const [formSubmitFlag, setFormSubmitFlag] = useState(true);

  // For React Google Maps
  const [selectedPark, setSelectedPark] = useState(null);

  const [googleScriptFlag, setGoogleScriptFlag] = useState(false);

  const {
    geocodeContactAddr,
    allFieldsAreRequired,
    languageFile,
    selectLanguageFile,
    geocode,
    geocodeSuccessMsg,
    geocodeFailureMsg,
    noDataGeocoded,
  } = geocodeAddrRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(geocodeAddr);
    // console.log("In GeocodeContactAddr(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In GeocodeContactAddr(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "geocodeContactAddr")
              geocodeAddr.geocodeContactAddr = item.ResourceDesc;
            if (item.ResourceCode === "allFieldsAreRequired")
              geocodeAddr.allFieldsAreRequired = item.ResourceDesc;
            if (item.ResourceCode === "languageFile")
              geocodeAddr.languageFile = item.ResourceDesc;
            if (item.ResourceCode === "selectLanguageFile")
              geocodeAddr.selectLanguageFile = item.ResourceDesc;
            if (item.ResourceCode === "geocode")
              geocodeAddr.geocode = item.ResourceDesc;
            if (item.ResourceCode === "geocodeSuccessMsg")
              geocodeAddr.geocodeSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "geocodeFailureMsg")
              geocodeAddr.geocodeFailureMsg = item.ResourceDesc;
            if (item.ResourceCode === "noDataGeocoded")
              geocodeAddr.noDataGeocoded = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, geocodeAddr);
        }
        setGeocodeAddrRes(geocodeAddr);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setGeocodeAddrRes(geocodeAddr);
        setProgressFlag(false);
      });
  }

  // Fetch ContactInfo Data from Database
  async function fetchDbContactInfoData(selectedLang) {
    // console.log('In fetchDbContactInfoData(): selectedLang is: ', selectedLang);

    setProgressFlag(true);

    let arrayData = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "contact-info",
          ":resourceSK": selectedLang,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbContactInfoData(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbContactInfoData(): response length is: ', response.data.Items.length);
        //   console.log('In fetchDbContactInfoData(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item, index) => {
          // let record = {
          //   id: ""+index,
          //   LanguageCode: item.LanguageCode,
          //   ResourcePK: item.ResourcePK,
          //   ResourceCode: item.ResourceCode,
          //   ResourceDesc: item.ResourceDesc,
          //   ResourceAddressUrl: item.ResourceAddressUrl,
          //   ResourceIconUrl: item.ResourceIconUrl,
          //   Icon: item.ResourceIconUrl,
          //   ModifiedBy: item.ModifiedBy,
          //   CreatedAt: item.CreatedAt,
          //   UpdatedAt: item.UpdatedAt,
          // };
          // arrayData.push(record);

          arrayData.push(item);
        });

        setContactInfoData(arrayData);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbContactInfoData(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  function onScriptLoad() {
    console.log("In onScriptLoad(): google map script loadeded successfully.");
    // console.log("In onScriptLoad(): mapOptions is: ", mapOptions);

    // const mapElement = document.getElementById(mapId);
    // onMapScriptLoaded(mapElement);
  }

  function loadGoogleMapScript(langCode) {
    // console.log("In loadGoogleMapScript(): window.google is: ", window.google);

    let mapElement = document.getElementById(mapId);
    // console.log("In loadGoogleMapScript(): mapElement is: ", mapElement);

    if (mapElement === null) {
      // console.log("In loadGoogleMapScript(): langCode is: ", langCode);

      // Build the JS API request node.
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "mapScriptId";
      // var query = '?key=AIzaSyD8dsL8KOtOMMdbYwdVLCSoa4t_SzQKjUo';
      var query = "";
      //   var langCode = getUrlParameter('language');
      // Try to be generous with accepting upper/lower case.
      if (langCode.length === 2) {
        langCode = langCode.toLowerCase();
      }
      if (langCode) {
        query = "&language=" + langCode;
      }
      //   var regionCode = getUrlParameter('region').toUpperCase();
      if (regionCode && regionCode !== null && regionCode !== "") {
        query += "&region=" + regionCode;
      }
      query += `&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;
      // console.log("In loadGoogleMapScript(): query is: ", query);

      script.src =
        "https://maps.googleapis.com/maps/api/js?v=3.exp&amp;client=gme-addictive&amp;channel=geocoder-tool&amp;libraries=places" +
        query;
      // script.src = 'https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places,geocode' + query;
      // script.src=`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&language=${langCode}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;
      //   script.setAttribute('async', '');
      //   script.setAttribute('defer', '');
      //   document.head.appendChild(script);

      var scriptTag = document.getElementsByTagName("head")[0];
      scriptTag.parentNode.insertBefore(script, scriptTag);
      // Below is important.
      // We cannot access google.maps until it's finished loading
      script.addEventListener("load", (e) => {
        // handleMapLoaded();
        onScriptLoad();
      });
    }
  }

  let selectOptions = [];

  function fetchLanguages() {
    // Fetch Language Select Data

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages: initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages: response length is: ', response.data.Items.length);
        // console.log('In fetchLanguages: response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            // label: item.LanguageName,
            text: item.LanguageName,
          };

          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);
      })
      .catch((err) => {
        console.log(
          "In fetchLanguages: err is: ",
          JSON.stringify(err, null, 2)
        );
      });
  }

  useEffect(() => {
    // console.log("In GeocodeContactAddr(): useEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));
  }, []);

  useLayoutEffect(() => {
    // console.log("In GeocodeContactAddr(): useLayoutEffect()");

    if (selectOptions.length === 0) {
      fetchLanguages();
    }

    // Load Google map scripts
    loadGoogleMapScript("en");

    let langCode = appContext.currentLanguage.languageCode;

    // Fetch resource from catche
    let cacheItemKey = "geocodeAddr";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In GeocodeContactAddr(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In GeocodeContactAddr(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In GeocodeContactAddr(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In GeocodeContactAddr(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In GeocodeContactAddr(): useLayoutEffect(): got resource from cache');
      setGeocodeAddrRes(cacheItem);
    }
    // Fetch ContactInfo Data
    fetchDbContactInfoData(appContext.currentLanguage.languageCode);

    // Load Google Map Script
    // loadGoogleMapScript(currLanguage.languageCode);

    // For React Google Maps
    const keydownListener = (e) => {
      if (e.key === "Escape") {
        setSelectedPark(null);
      }
    };

    window.addEventListener("keydown", keydownListener);

    return () => {
      window.removeEventListener("keydown", keydownListener);
    };
  }, []);

  function initializeForm() {
    // console.log("In initializeForm()");
    setFormSubmitFlag(false);
    setSelectedLanguage(null);
  }

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);
    setSelectedLanguage(selectedOption);

    setGlobalErrorMsg("");
    setGlobalErrorFlag(false);

    // Fetch ContactInfo data for selected language
    fetchDbContactInfoData(selectedOption.value);

    if (
      selectedOption !== null &&
      selectedOption.value !== null &&
      selectedOption.value !== ""
    ) {
      setFormSubmitFlag(true);
    }
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    setSnackbarOpen(false);
  }

  async function geocodeSuccessCallback(contactInfo, geometry) {
    // console.log('In geocodeSuccessCallback(): contactInfo is: ', contactInfo);
    // console.log('In geocodeSuccessCallback(): geometry is: ', geometry);
    // console.log('In geocodeSuccessCallback(): geometry.location.lat is: ', geometry.location.lat);
    // console.log('In geocodeSuccessCallback(): geometry.location.lng is: ', geometry.location.lng);

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    // Get Grid data
    const gridRowCount = 1;
    // console.log('In geocodeSuccessCallback(): gridRowCount is: ', gridRowCount);

    if (gridRowCount > 0) {
      const rowsData = [];
      const deleteRowsData = [];

      // const d = new Date();
      const timestamp = new Date();

      // Delete later
      contactInfo.Lat = 38.436833;
      contactInfo.Lng = -77.42539;

      // contactInfo.Lat = geometry.location.lat;
      // contactInfo.Lng = geometry.location.lng;
      contactInfo.UpdatedAt = timestamp;
      rowsData.push(contactInfo);

      let params = {
        apiName: "ResourceAPI",
        path: "/dynamodb/resources/batchWrite",
        payload: {
          items: rowsData,
          deleteItems: deleteRowsData,
        },
      };

      let initObj = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        response: true, // return entire response object instead of response.data
        body: {},
      };

      initObj.headers.Authorization = appContext.jwtToken;
      initObj.body = params.payload;
      // console.log('In geocodeSuccessCallback(): initObj is: ', JSON.stringify(initObj, null, 2));

      setProgressFlag(true);

      API.post(params.apiName, params.path, initObj)
        .then((response) => {
          // console.log('In geocodeSuccessCallback(): response is: ', JSON.stringify(response, null, 2));

          // Show Snackbar
          setSnackbarsVariant("success");
          setSnackbarsMsg(geocodeSuccessMsg);
          setSnackbarOpen(true);

          initializeForm();
          setProgressFlag(false);
        })
        .catch((err) => {
          console.log(
            "In geocodeSuccessCallback(): err is: ",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setGlobalErrorMsg(geocodeFailureMsg);

          setProgressFlag(false);
        });
    }
  }

  async function geocodeFailureCallback(status) {
    console.log(
      "In geocodeFailureCallback(): Geocode was not successful for the following reason: ",
      status
    );
  }

  async function geocodeAddress(contactInfo, successCallback, failureCallback) {
    // console.log('In geocodeAddress(): window.google is: ', window.google);
    // var address = document.getElementById('address').value;
    // let address = "332+Pear+Blossom+Road,+Stafford,+VA+22554,+USA";

    //================================
    // Using Google Map API
    //================================

    // How to Dynamically load a module and use it
    // import('react-google-maps')
    // .then((GoogleMap) => {
    //     console.log('In geocodeAddress(): GoogleMap is: ', GoogleMap);
    // }).catch(err => {
    //     console.log('In geocodeAddress(): err.message is: ', err.message);
    // });

    var geocoder = new window.google.maps.Geocoder();

    await geocoder.geocode({ address: contactInfo.StreetAddress }, function (
      results,
      status
    ) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        // console.log('In geocodeAddress(): results is: ', results);
        // console.log('In geocodeAddress(): results[0].geometry.location.lat is: ', results[0].geometry.location.lat);
        // console.log('In geocodeAddress(): results[0].geometry.location.lng is: ', results[0].geometry.location.lng);
        // console.log("In geocodeAddress(): after geocoding OK");
        successCallback(contactInfo, results[0].geometry);
      } else {
        console.log(
          "In geocodeAddress(): Geocode was not successful for the following reason: ",
          status
        );
        failureCallback(status);
      }
    });

    //================================
    // Using fetch
    //================================

    // var headersInfo = new Headers({
    //     // 'Content-Type': 'text/plain',
    //     'Access-Control-Allow-Origin': 'http://127.0.0.1:3000',
    //     'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
    //     'Access-Control-Request-Headers': 'origin, x-requested-with Origin: 127.0.0.1:3000'
    // });

    // let queryString = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&language=en&address=${address}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;

    // fetch(queryString, {
    //     method: 'GET',
    //     headers: headersInfo,
    //     // mode: 'no-cors', // 'cors' by default
    // })
    // .then(validateResponse)
    // .then(readResponseAsJSON)
    // .then(logResult)
    // .catch(logError);
  }

  async function geocodeService() {
    // console.log("In geocodeService(): selectedLanguage is: ", selectedLanguage);
    // console.log("In geocodeService(): contactInfoData.length is: ", contactInfoData.length);

    setProgressFlag(true);
    setGlobalErrorMsg("");
    setGlobalErrorFlag(false);

    // let address = "328 Pear Blossom Road, Stafford, Virginia 22554, USA";// 38.436833,-77.42539 and place_id: "ChIJheBXw1TutokRaKdCAaFFBRg"
    // let address = "ChIJheBXw1TutokRaKdCAaFFBRg";// 38.436833,-77.42539 and place_id: "ChIJheBXw1TutokRaKdCAaFFBRg"

    if (
      contactInfoData &&
      contactInfoData !== null &&
      contactInfoData.length > 0
    ) {
      contactInfoData.map((contactInfo) => {
        geocodeAddress(
          contactInfo,
          geocodeSuccessCallback,
          geocodeFailureCallback
        );
      });
    }

    await setProgressFlag(false);
  }

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { !appContext.signedIn &&
                    <Redirect to='/signIn' />
                } */}

        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%", height: "100%" }}>
            {/* progressFlag &&
                            <div style={{textAlign: 'center'}}>
                                <CircularProgress className={classes.progress} color="secondary" size={15}/>
                            </div>
                        */}

            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="md"
            >
              <Grid container justify="center">
                <Grid item xs={12} sm={9}>
                  <StyledPaper elevation={3} className={classes.paper}>
                    <Typography
                      variant="h6"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      {geocodeContactAddr}
                    </Typography>

                    <Typography
                      variant="caption"
                      style={{ color: "green", textAlign: "center" }}
                    >
                      {allFieldsAreRequired}
                    </Typography>

                    {globalErrorFlag && (
                      <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                    )}

                    <LineSpacing value={20} />

                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="formLanguageId"
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {languageFile}
                        </Typography>
                      </label>
                      <Select
                        id="formLanguageId"
                        value={selectedLanguage}
                        placeholder={selectLanguageFile}
                        onChange={handleLanguageChange}
                        variant="outlined"
                        displayEmpty={true}
                        className={classes.selectEmpty}
                        // autoFocus
                      >
                        <MenuItem value={null} disabled>
                          <Typography variant="body1">
                            {selectLanguageFile}
                          </Typography>
                        </MenuItem>
                        {langSelectOptions.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <LineSpacing value={20} />

                    <Button
                      variant="contained"
                      size="large"
                      disabled={!formSubmitFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={geocodeService}
                    >
                      <SaveIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {geocode}
                    </Button>

                    <LineSpacing value={16} />
                  </StyledPaper>
                </Grid>
              </Grid>
            </Container>
          </div>

          {/* variant: 'success', 'warning', 'error', 'info' */}
          <TsiCustomizedSnackbars
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={8000}
            onClose={snackbarOnClose}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">{snackbarsMsg}</span>}
            variant={snackbarsVariant}
          />
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter isSignedIn={isSignedIn} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default GeocodeContactAddr;
