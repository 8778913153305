import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
// import SaveIcon from "@material-ui/icons/Save";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from "@material-ui/core/Container";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import InputAdornment from "@material-ui/core/InputAdornment";
import TranslateIcon from '@material-ui/icons/Translate';
import Paper from "@material-ui/core/Paper";

// react-responsive
import { useMediaQuery } from 'react-responsive';

// moment
import moment from "moment";

// Lodash
import lodash from "lodash";

// react-media
// import Media from "react-media";

// Validator
// import validator from 'validator';
// import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";
// import isMobilePhone from "validator/lib/isMobilePhone";

//---------------------
// For Primereact
//---------------------

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

// For Primereact
import { Dialog } from 'primereact/dialog';

// Froala
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Amplify
import { API } from "aws-amplify";
// import { AmplifyTheme   } from 'aws-amplify-react';

// AppContext
import AppContext from "../../config/AppContext";

import LineSpacing from "../../components/misc/LineSpacing";
import FetchS3Object from "../../components/media/FetchS3Object";
import FroalaCommonEditor from "../../components/editor/FroalaCommonEditor";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import AlertDialog from "../../components/dialogs/AlertDialog";
import CommonMediaSelector from "../../components/dialogs/CommonMediaSelector";
import DomainUploadCapture from "../../components/upload/DomainUploadCapture";
import CommonConverter from "../../components/convert/CommonConverter";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GenerateUniqueName from "../../utils/GenerateUniqueName";
import GenerateUlid from "../../utils/GenerateUlid";
// import FormatDateTime from "../../../../../utils/moment/FormatDateTime";
import FormatTimezoneDates from "../../utils/moment/FormatTimezoneDates";
import FilterArrayFromArray from "../../utils/filter/FilterArrayFromArray";
import FormatNumber from "../../utils/numberFormat/FormatNumber";
import EditorPixelTransform from "../../utils/transform/EditorPixelTransform";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// Views
import PostJobsTv from "./views/PostJobsTv";
import PostJobsMv from "./views/PostJobsMv";

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    leftPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "left",
    },
    leftPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1rem" /* Footer height */,

        width: "100% !important",
        marginTop: "auto",
        // backgroundColor: '#fff',
        textAlign: "center",
    },
    paper: {
        minWidth: "300px",
        marginTop: theme.spacing(2),
        alignItems: "center",
        padding: "10px 30px 10px 30px !important",
        textAlign: "center",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    divPadding: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

let pageLabels = {
    manageJobsLabel: "Manage job postings",
    requiredFields: "* Required fields",

    languageLabel: "Language",
    languagePrompt: "Select language",
    languageErrMsg: "Language is required",

    domainLabel: "Domain",
    domainPrompt: "Select a domain",
    domainErrMsg: "Domain is required",

    jobBannerUrlLabel: "Job banner URL",
    jobBannerUrlPrompt: "Select job banner",
    jobBannerUrlErrMsg: "Job banner is required",

    yourNamesLabel: "Your names",
    yourNamesPrompt: "Enter your full names",
    yourNamesErrMsg: "Your names are required",
    yourNamesMsg: "Names entered will appear in the job",
    
    jobTitleLabel: "Job title",
    jobTitlePrompt: "Please enter a title for your job",
    jobTitleErrMsg: "Job title is required",
    selectTitlePrompt: "Select title",

    jobTagsLabel: "Job tags",
    jobTagsPrompt: "Enter job tags separated by space",
    jobTagsErrMsg: "Job tags are required",
    
    jobStartTimeLabel: "Publish start time",
    jobStartTimePrompt: "Select date & time to publish the job",
    jobStartTimeErrMsg: "Publish start time is invalid",

    jobCategoryLabel: "Job category",
    jobCategoryPrompt: "Select a job category",
    jobCategoryErrMsg: "Job category is required",

    // uploadJobTitleLabel: "Job title",
    // uploadJobTitlePrompt: "Please select job title",
    // uploadJobTitleErrMsg: "Job title is required",

    // liveEventUrlLabel: "Live event URL",
    // liveEventUrlPrompt: "Please enter the live event URL",
    // liveEventUrlErrMsg: "Live event URL is invalid",

    // liveEventUrlTypeLabel: "Live event URL type",
    // liveEventUrlTypePrompt: "Select live event URL type",
    // liveEventUrlTypeErrMsg: "Live event URL type is required",

    jobContentLabel: "Job content",
    jobContentPrompt: "Please enter your job content",
    // jobContentErrMsg: "Live event description is required",

    yourTimeZoneMsg: "Your timeZone is",
    // timeZoneLabel: "Time zone",
    cloneRecordLabel: "Clone record",

    jobRecordLabel: "job record",
    jobRecordsLabel: "job records",
    // inProgressLabel: "In progress",
    startLabel: "Start",
    endLabel: "End",
    notStartedLabel: "Not started",
    // dateCreatedLabel: "Date created",

    // statusLabel: "Status",
    editLabel: "Edit",
    deleteLabel: "Delete",

    toLabel: "to",
    ofLabel: "of",
    recordsLabel: "records",
    noRecordsFound: "No records found",

    operationSuccessMsg: "Operation was successful.",
    operationFailureMsg: "Operation failed.",
    duplicateTransMsg: "Duplicate record",

    loadingText: "Loading ...",
    noOptionsText: "No options",
    clearText: "Clear",
    closeText: "Close",
    // showLabel: "Show",
    // hideLabel: "Hide",

    cancelLabel: "Cancel",
    clearLabel: "Clear",
    okLabel: "Ok",
  
    createJobContentLabel: "Create job content",
    updateJobContentLabel: "Update job content",

    createMoreLabel: "Create more",
    createLabel: "Create",
    // editEventDestLabel: "Edit email template",
    closeLabel: "Close",
    // noRecordsFound: "No records found.", 
    startEventInfoMsg: "This event can't be started now.",    
    // createJobContentLabel: "Create live event",
        
    gridNames: "Names",
    gridTitle: "Title",
    gridEventUrl: "Event URL",
    gridEventUrlType: "URL type",
    gridStartTime: "Start time",
    gridBannerUrl: "Banner URL",
    
    convertLabel: "Convert",

    sourceLanguageLabel: "Source language",
    sourceLanguagePrompt: "Select source language",
    sourceLanguageErrMsg: "Source language is required",

    targetLanguageLabel: "Target language",
    targetLanguagePrompt: "Select target language",
    targetLanguageErrMsg: "Target language is required",

    moreLabel: "- more -",

    uploadLabel: "Upload",
};

function PostJobs() {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);
    const [renderDomFlag, setRenderDomFlag] = useState(false);
    const [progressFlag, setProgressFlag] = useState(false);

    // For Global Errors
    const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");

    // For Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarsMsg, setSnackbarsMsg] = useState("");
    const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    //--------------------
    // For Froala Editor
    //--------------------
    const editorRef = useRef(null);
    // const [editorModelData, setEditorModelData] = useState(null);
    const [editorMaxChars, setEditorMaxChars] = useState(10000);// Max chars allowed in editor

    //-------------------------
    // For Language and Domain
    //-------------------------
    const [language, setLanguage] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [languageErrFlag, setLanguageErrFlag] = useState(false);

    const [domain, setDomain] = useState(null);
    const [domainOptions, setDomainOptions] = useState([]);
    const [domainErrFlag, setDomainErrFlag] = useState(false);

    //-------------------
    // Table Names
    //-------------------
    const tableName = "job";
    
    //--------------------
    // For Form Attributes
    //--------------------
    const [profilePhotoSrc, setProfilePhotoSrc] = useState(null);

    const [yourNames, setYourNames] = useState("");
    const [authorPhotoSrc, setAuthorPhotoSrc] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [jobTags, setJobTags] = useState("");
    const [jobStartTime, setJobStartTime] = useState(null);
    const [jobCategory, setJobCategory] = useState(null);
    // const [liveEventUrl, setLiveEventUrl] = useState("");
    // const [liveEventUrlType, setLiveEventUrlType] = useState(null);
    const [jobContent, setJobContent] = useState("");
    const [jobBannerUrl, setJobBannerUrl] = useState("");
    
    // const [eventThumbnailUrl, setEventThumbnailUrl] = useState("");
    // const [eventBannerUrl, setEventBannerUrl] = useState("");
    // const [eventPosterUrl, setEventPosterUrl] = useState("");
    // const [eventAudioUrl, setEventAudioUrl] = useState("");
    
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
         
    const [jobBannerUrlErrFlag, setJobBannerUrlErrFlag] = useState(false);
    const [yourNamesErrFlag, setYourNamesErrFlag] = useState(false);
    const [jobTitleErrFlag, setJobTitleErrFlag] = useState(false);
    const [jobTagsErrFlag, setJobTagsErrFlag] = useState(false);
    const [jobStartTimeErrFlag, setJobStartTimeErrFlag] = useState(false);
    const [jobCategoryErrFlag, setJobCategoryErrFlag] = useState(false);
    // const [liveEventUrlErrFlag, setLiveEventUrlErrFlag] = useState(false);
    // const [liveEventUrlTypeErrFlag, setLiveEventUrlTypeErrFlag] = useState(false);
    const [jobContentErrFlag, setJobContentErrFlag] = useState(false);

    // const [liveEventUrlTypeOptions, setLiveEventUrlTypeOptions] = useState([]);
    // const [jobTagsOptions, setJobTagsOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    
    const [jobRecordsBackup, setJobRecordsBackup] = useState([]);
    const [jobRecords, setJobRecords] = useState([]);

    const [submitButtonFlag, setSubmitButtonFlag] = useState(false);
    const [addTagButtonFlag, setAddTagButtonFlag] = useState(false);
    const [editModeFlag, setEditModeFlag] = useState(false);

    const [datePickerLanguage, setDatePickerLanguage] = useState(appContext.currentLanguage.languageCode);
    
    
    const [showJobListFlag, setShowJobListFlag] = useState(true);
    const [showJobDetailsFormFlag, setShowJobDetailsFormFlag] = useState(false);
    const [uploadFlag, setUploadFlag] = useState(false);
    const [convertFlag, setConvertFlag] = useState(false);
       
    const [pageSize, setPageSize] = useState(10000);// AWS put PageSize to a max 0f 100

    const [noJobRecordFoundFlag, setNoJobRecordFoundFlag] = useState(false);
    const [eventStartedFlag, setEventStartedFlag] = useState(false);
    const [startedEventId, setStartedEventId] = useState(null);
    
    const [textMaxLength, setTextMaxLength] = useState(256);

    //--------------------------
    // For sliceText and Dialog
    //--------------------------
    const [dialogData, setDialogData] = useState("");
    const [dialogHeader, setDialogHeader] = useState("");
    
    // const [terminologyNames, setTerminologyNames] = useState([
    //     "translate_custom_terminology.txt"
    // ]);
    

    //-------------------------
    // For Primereact 
    // DataTable Attributes 
    //-------------------------
    // const [ dataTableInfo, setDataTableInfo] = useState([]);
    // const [dataTableInfoFlag, setDataTableInfoFlag] = useState(false);
    
    // const [resizableColumnsFlag, setResizableColumnsFlag] = useState(true);
    // const [columnResizeMode, setColumnResizeMode] = useState("fit");
    // const [sortMode, setSortMode] = useState("multiple");
    // const [responsiveFlag, setResponsiveFlag] = useState(true);
    // const [reorderableColumnsFlag, setReorderableColumnsFlag] = useState(true);
    // const [paginatorFlag, setPaginatorFlag] = useState(true);
    const [paginatorTemplate, setPaginatorTemplate] = useState("FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown");
    // const [currentPageReportTemplate, setCurrentPageReportTemplate] = useState(null);
    const [visibleRowsCount, setVisibleRowsCount] = useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5,10,20,50,100]);

    // const [selectionMode, setSelectionMode] = useState("multiple");// single or multiple
    // const [selectedTableRow, setSelectedTableRow] = useState(null);// For single selection
    const [selectedTableRows, setSelectedTableRows] = useState([]);// For multiple selection
    // const [updatedTableRows, setUpdatedTableRows] = useState([]); // For updated (edited) table rows
    // const [deletedTableRows, setDeletedTableRows] = useState([]); // For deleted table rows
    const [tableDirtyFlag, setTableDirtyFlag] = useState(false); // To show DataTable cell was edited (modified)

    // const [deleteRowFlag, setDeleteRowFlag] = useState(false);

    //--------------------
    // For AlertDialog
    //--------------------
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogSubContent, setDialogSubContent] = useState(null);

    const [recordToDelete, setRecordToDelete] = useState(null);
    const [recordToEdit, setRecordToEdit] = useState(null);
    
    //-----------------------
    // For Primereact Dialog
    //-----------------------
    const [dlgVisibleFlag, setDlgVisibleFlag] = useState(false);
    
    // const {
    //     // editorId,
    // } = props;

    const theme = useTheme();
  
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    const { 
        manageJobsLabel, 
        requiredFields,

        languageLabel,
        languagePrompt,
        languageErrMsg,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,
    
        jobBannerUrlLabel,
        jobBannerUrlPrompt,
        jobBannerUrlErrMsg,
            
        yourNamesLabel,
        yourNamesPrompt,
        yourNamesErrMsg,
        yourNamesMsg,
        
        jobTitleLabel,
        jobTitlePrompt,
        jobTitleErrMsg,
        selectTitlePrompt,

        jobTagsLabel,
        jobTagsPrompt,
        jobTagsErrMsg,
    
        jobStartTimeLabel,
        jobStartTimePrompt,
        jobStartTimeErrMsg,

        jobCategoryLabel,
        jobCategoryPrompt,
        jobCategoryErrMsg,

        // uploadJobTitleLabel,
        // uploadJobTitlePrompt,
        // uploadJobTitleErrMsg,

        // liveEventUrlLabel,
        // liveEventUrlPrompt,
        // liveEventUrlErrMsg,

        // liveEventUrlTypeLabel,
        // liveEventUrlTypePrompt,
        // liveEventUrlTypeErrMsg,

        jobRecordsLabel,
        // inProgressLabel,
        startLabel,
        endLabel,
        notStartedLabel,
    
        // dateCreatedLabel,
    
        jobContentLabel,
        jobContentPrompt,
        // jobContentErrMsg,

        yourTimeZoneMsg,
        // timeZoneLabel,
        cloneRecordLabel,
    
        toLabel,
        ofLabel,
        recordsLabel,
        // noRecordsFound,

        // statusLabel,
        editLabel,
        deleteLabel,

        operationSuccessMsg,
        operationFailureMsg,
        duplicateTransMsg,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,
        // showLabel,
        // hideLabel,

        cancelLabel,
        clearLabel,
        okLabel,

        uploadLabel,
        
        createJobContentLabel,
        updateJobContentLabel,

        jobRecordLabel,
        createMoreLabel,
        createLabel,
        // editEventDestLabel,
        closeLabel,
        noRecordsFound, 
        startEventInfoMsg,
        // createJobContentLabel,

        gridTitle,
        gridNames,
        // gridEventUrl,
        // gridEventUrlType,
        gridStartTime,
        gridBannerUrl,
    
        convertLabel,

        sourceLanguageLabel,
        sourceLanguagePrompt,
        sourceLanguageErrMsg,

        targetLanguageLabel,
        targetLanguagePrompt,
        targetLanguageErrMsg,

        moreLabel,
    } = pageLabelsRes;

    async function fetchDbResources(cacheItemKey) {
        backdropProgress(true);

        let keys = [];
        let objKeys = Object.keys(pageLabels);
        // console.log("In PostJobs(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
        if (objKeys !== null && objKeys.length > 0) {
            objKeys.map((key) => {
                let partitionKey = appContext.currentLanguage.languageCode;
                partitionKey += ".";
                partitionKey += GenerateModulusHash(key, 200);

                let itemKey = {
                ResourcePK: partitionKey, // e.g. 'en.91'
                ResourceSK: key, // e.g. 'firstName'
                };
                keys.push(itemKey);
            });
        }
        // console.log("In PostJobs(): fetchDbResources(): keys.length is: ", keys.length);
        // console.log("In PostJobs(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

        let arrayData = [];

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/batchGet",
            payload: {
                keys: keys,
                projectionExpression: "ResourceCode,ResourceDesc",
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
            response.data.Responses.resource.map((item, index) => {
            let record = {
                ResourceCode: item.ResourceCode,
                ResourceDesc: item.ResourceDesc,
            };

            arrayData.push(record);
            });
                    
            if (arrayData && arrayData.length > 0) {
                arrayData.map((item) => {
                    if (item.ResourceCode === "manageJobsLabel") pageLabels.manageJobsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "requiredFields") pageLabels.requiredFields = item.ResourceDesc;
                    
                    if (item.ResourceCode === "languageLabel") pageLabels.languageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "languagePrompt") pageLabels.languagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "languageErrMsg") pageLabels.languageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "domainLabel") pageLabels.domainLabel = item.ResourceDesc;
                    if (item.ResourceCode === "domainPrompt") pageLabels.domainPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "domainErrMsg") pageLabels.domainErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "jobBannerUrlLabel") pageLabels.jobBannerUrlLabel = item.ResourceDesc;
                    if (item.ResourceCode === "jobBannerUrlPrompt") pageLabels.jobBannerUrlPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "jobBannerUrlErrMsg") pageLabels.jobBannerUrlErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "yourNamesLabel") pageLabels.yourNamesLabel = item.ResourceDesc;
                    if (item.ResourceCode === "yourNamesPrompt") pageLabels.yourNamesPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "yourNamesErrMsg") pageLabels.yourNamesErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "yourNamesMsg") pageLabels.yourNamesMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "jobTitleLabel") pageLabels.jobTitleLabel = item.ResourceDesc;
                    if (item.ResourceCode === "jobTitlePrompt") pageLabels.jobTitlePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "jobTitleErrMsg") pageLabels.jobTitleErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "selectTitlePrompt") pageLabels.selectTitlePrompt = item.ResourceDesc;
                                
                    if (item.ResourceCode === "jobTagsLabel") pageLabels.jobTagsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "jobTagsPrompt") pageLabels.jobTagsPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "jobTagsErrMsg") pageLabels.jobTagsErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "jobStartTimeLabel") pageLabels.jobStartTimeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "jobStartTimePrompt") pageLabels.jobStartTimePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "jobStartTimeErrMsg") pageLabels.jobStartTimeErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "jobCategoryLabel") pageLabels.jobCategoryLabel = item.ResourceDesc;
                    if (item.ResourceCode === "jobCategoryPrompt") pageLabels.jobCategoryPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "jobCategoryErrMsg") pageLabels.jobCategoryErrMsg = item.ResourceDesc;
            
                    // if (item.ResourceCode === "uploadJobTitleLabel") pageLabels.uploadJobTitleLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "uploadJobTitlePrompt") pageLabels.uploadJobTitlePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "uploadJobTitleErrMsg") pageLabels.uploadJobTitleErrMsg = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "liveEventUrlTypeLabel") pageLabels.liveEventUrlTypeLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "liveEventUrlTypePrompt") pageLabels.liveEventUrlTypePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "liveEventUrlTypeErrMsg") pageLabels.liveEventUrlTypeErrMsg = item.ResourceDesc;
                                    
                    if (item.ResourceCode === "yourTimeZoneMsg") pageLabels.yourTimeZoneMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "timeZoneLabel") pageLabels.timeZoneLabel = item.ResourceDesc;
                    if (item.ResourceCode === "cloneRecordLabel") pageLabels.cloneRecordLabel = item.ResourceDesc;
                                
                    if (item.ResourceCode === "jobRecordsLabel") pageLabels.jobRecordsLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "inProgressLabel") pageLabels.inProgressLabel = item.ResourceDesc;
                    if (item.ResourceCode === "startLabel") pageLabels.startLabel = item.ResourceDesc;
                    if (item.ResourceCode === "endLabel") pageLabels.endLabel = item.ResourceDesc;
                    if (item.ResourceCode === "notStartedLabel") pageLabels.notStartedLabel = item.ResourceDesc;
                      
                    // if (item.ResourceCode === "dateCreatedLabel") pageLabels.dateCreatedLabel = item.ResourceDesc;
                    
                    if (item.ResourceCode === "jobContentLabel") pageLabels.jobContentLabel = item.ResourceDesc;
                    if (item.ResourceCode === "jobContentPrompt") pageLabels.jobContentPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "jobContentErrMsg") pageLabels.jobContentErrMsg = item.ResourceDesc; 

                    if (item.ResourceCode === "toLabel") pageLabels.toLabel = item.ResourceDesc;
                    if (item.ResourceCode === "ofLabel") pageLabels.ofLabel = item.ResourceDesc;
                    if (item.ResourceCode === "recordsLabel") pageLabels.recordsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noRecordsFound") pageLabels.noRecordsFound = item.ResourceDesc;

                    if (item.ResourceCode === "createLabel") pageLabels.createLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "statusLabel") pageLabels.statusLabel = item.ResourceDesc;
                    if (item.ResourceCode === "editLabel") pageLabels.editLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteLabel") pageLabels.deleteLabel = item.ResourceDesc;

                    if (item.ResourceCode === "operationSuccessMsg") pageLabels.operationSuccessMsg = item.ResourceDesc;
                    if (item.ResourceCode === "operationFailureMsg") pageLabels.operationFailureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "duplicateTransMsg") pageLabels.duplicateTransMsg = item.ResourceDesc;
                                                                        
                    if (item.ResourceCode === "loadingText") pageLabels.loadingText = item.ResourceDesc;
                    if (item.ResourceCode === "noOptionsText") pageLabels.noOptionsText = item.ResourceDesc;
                    if (item.ResourceCode === "clearText") pageLabels.clearText = item.ResourceDesc;
                    if (item.ResourceCode === "closeText") pageLabels.closeText = item.ResourceDesc;
                    // if (item.ResourceCode === "showLabel") pageLabels.showLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "hideLabel") pageLabels.hideLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "cancelLabel") pageLabels.cancelLabel = item.ResourceDesc;
                    if (item.ResourceCode === "clearLabel") pageLabels.clearLabel = item.ResourceDesc;
                    if (item.ResourceCode === "okLabel") pageLabels.okLabel = item.ResourceDesc;

                    if (item.ResourceCode === "uploadLabel") pageLabels.uploadLabel = item.ResourceDesc;
          
                    if (item.ResourceCode === "createJobContentLabel") pageLabels.createJobContentLabel = item.ResourceDesc;
                    if (item.ResourceCode === "updateJobContentLabel") pageLabels.updateJobContentLabel = item.ResourceDesc;
                              
                    if (item.ResourceCode === "jobRecordLabel") pageLabels.jobRecordLabel = item.ResourceDesc;
                    if (item.ResourceCode === "createMoreLabel") pageLabels.createMoreLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "editEventDestLabel") pageLabels.editEventDestLabel = item.ResourceDesc;
                    if (item.ResourceCode === "closeLabel") pageLabels.closeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noRecordsFound") pageLabels.noRecordsFound = item.ResourceDesc;
                    if (item.ResourceCode === "startEventInfoMsg") pageLabels.startEventInfoMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "createJobContentLabel") pageLabels.createJobContentLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "gridTitle") pageLabels.gridTitle = item.ResourceDesc;
                    if (item.ResourceCode === "gridEventUrl") pageLabels.gridEventUrl = item.ResourceDesc;
                    if (item.ResourceCode === "gridEventUrlType") pageLabels.gridEventUrlType = item.ResourceDesc;
                                              
                    if (item.ResourceCode === "gridStartTime") pageLabels.gridStartTime = item.ResourceDesc;
                    if (item.ResourceCode === "gridBannerUrl") pageLabels.gridBannerUrl = item.ResourceDesc;
                    if (item.ResourceCode === "gridNames") pageLabels.gridNames = item.ResourceDesc;
                                              
                    if (item.ResourceCode === "convertLabel") pageLabels.convertLabel = item.ResourceDesc;
                    
                    if (item.ResourceCode === "sourceLanguageLabel") pageLabels.sourceLanguageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "sourceLanguagePrompt") pageLabels.sourceLanguagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "sourceLanguageErrMsg") pageLabels.sourceLanguageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "targetLanguageLabel") pageLabels.targetLanguageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "targetLanguagePrompt") pageLabels.targetLanguagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "targetLanguageErrMsg") pageLabels.targetLanguageErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "moreLabel") pageLabels.moreLabel = item.ResourceDesc;
                });
                // Add it to cache
                SetCacheItem(cacheItemKey, pageLabels);
            }

            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setRenderDomFlag(true);
        }).catch((err) => {
            console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setRenderDomFlag(true);
        });
    }
    
    let listNextToken = null;

    async function fetchJobRecords(language, domain) {
        // console.log('In fetchJobRecords()');
        if (domain && language) {
            backdropProgress(true);
            setNoJobRecordFoundFlag(false);
            setJobRecordsBackup([]);

            let params = {
                apiName: "CommonDynamodbAPI",
                path: "/common/dynamodb/query",
                payload: {
                    tableName: tableName,
                    keyConditionExpression:
                        // "#jobPK = :jobPK",
                        "#jobPK = :jobPK AND begins_with(#jobSK, :jobSK)",
                    expressionAttributeNames: {
                        "#jobPK": "JobPK",
                        "#jobSK": "JobSK",
                    },
                    expressionAttributeValues: {
                        ":jobPK": domain.value + "#" + language.languageCode + "#job",
                        ":jobSK": domain.value + "#" + language.languageCode + "#job",
                    },
                    // indexName: "JobDomain-DomainLangAuthorUserName-index"
                        // "keyConditionExpression": "#jobPK = :jobPK",
                        // "expressionAttributeNames": {"#jobPK": "JobPK"},
                        // "expressionAttributeValues": {":jobPK": "languages"}
                },
            };
    
            if (!lodash.isEmpty(listNextToken)) {
                params.payload.nextToken = listNextToken;
            }
    
            if (!lodash.isEmpty(pageSize)) {
                params.payload.pageSize = pageSize;
            }

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchJobRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchJobRecords(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchJobRecords(): response.data.Items is: ', response.data.Items);
                // console.log('In fetchJobRecords(): response.data.NextToken is: ', response.data.NextToken);

                let records = [];

                listNextToken = null;

                if (response && !lodash.isEmpty(response.data.NextToken)) {
                    listNextToken = response.data.NextToken;
                    records = jobRecords;
                }
            
                let items = response.data.Items;

                if (items && items.length > 0) {
                    setJobRecords([]);

                    items.map((record) => {
                        // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                        // console.log('In fetchJobRecords(): formatedDateTime is: ', formatedDateTime);
                        
                        let formattedStartTime = null;
                        if (record.StartTime) {
                            formattedStartTime = FormatTimezoneDates(record.StartTime, record.LanguageCode, record.JobTimeZone, "shortDateAndTime");
                        }
                        // console.log('In fetchJobRecords(): formattedStartTime is: ', formattedStartTime);

                        let editorTransform = record.JobContent;
                        if (record.JobContent) {
                            if (isTabletView || isMobileView) {
                                editorTransform = EditorPixelTransform(record.JobContent, (isTabletView ? "tabletView" : "mobileView"));
                            }
                        }
                        // console.log('In fetchJobRecords(): editorTransform is: ', editorTransform);
    
                        let item = {
                            JobPK: record.JobPK,
                            JobSK: record.JobSK,

                            LanguageObject: record.LanguageObject,
                            LanguageCode: record.LanguageCode,
                            Domain: record.Domain,

                            "JobId": lodash.isEmpty(record.JobId) ? "" : record.JobId,

                            "JobDomain": lodash.isEmpty(record.JobDomain) ? "" : record.JobDomain,
                            "JobCategory": lodash.isEmpty(record.JobCategory) ? "" : record.JobCategory.text,
                            "JobTitle": lodash.isEmpty(record.JobTitle) ? "" : record.JobTitle,
                            "JobContent": lodash.isEmpty(editorTransform) ? "" : editorTransform,
                            "Tags": lodash.isEmpty(record.Tags) ? "" : record.Tags,
                            "BannerUrl": lodash.isEmpty(record.BannerUrl) ? "" : record.BannerUrl,
                            "AuthorNames": lodash.isEmpty(record.AuthorNames) ? "" : record.AuthorNames,
                            "AuthorUserName": lodash.isEmpty(record.AuthorUserName) ? "" : record.AuthorUserName,
                            "PopularityCount": record.PopularityCount,
            
                            "FormattedStartTime": formattedStartTime,
                            "StartTime": record.StartTime,
                            "JobTimeZone": lodash.isEmpty(record.JobTimeZone) ? "" : record.JobTimeZone,
                        };
                        records.push(item);
                    });
                } else {
                    setNoJobRecordFoundFlag(true);
                }

                // console.log('In fetchJobRecords(): listNextToken is: ', listNextToken);
                if (!lodash.isEmpty(listNextToken)) {
                    await fetchJobRecords(language, domain);
                }
                
                setTimeout(() => {
                    records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                    setJobRecords(records);
                    setJobRecordsBackup(records);
                }, 100);

                backdropProgress(false);
            }).catch((err) => {
            console.error("In fetchJobRecords(): err is: ", JSON.stringify(err, null, 2));
            setJobRecords([]);
            setNoJobRecordFoundFlag(true);
            backdropProgress(false);
            });
        } else {
            setNoJobRecordFoundFlag(true);
        }
    }

    async function fetchAuthorJobs() {
        // console.log('In fetchAuthorJobs()');
        backdropProgress(true);
        setNoJobRecordFoundFlag(false);
        setJobRecordsBackup([]);

        let params = {
            apiName: "CommonDynamodbAPI",
            path: "/common/dynamodb/query",
            payload: {
                tableName: tableName,
                keyConditionExpression:
                    // "#jobPK = :jobPK",
                    "#jobPK = :jobPK AND begins_with(#jobSK, :jobSK)",
                expressionAttributeNames: {
                    "#jobPK": "JobDomain",
                    "#jobSK": "DomainLangAuthorUserName",
                },
                expressionAttributeValues: {
                    ":jobPK": appContext.domainName,
                    ":jobSK": appContext.currentLanguage.languageCode + "#" + appContext.userName,
                },
                indexName: "JobDomain-DomainLangAuthorUserName-index"
                    // "keyConditionExpression": "#jobPK = :jobPK",
                    // "expressionAttributeNames": {"#jobPK": "JobPK"},
                    // "expressionAttributeValues": {":jobPK": "languages"}
            },
        };
  
        if (!lodash.isEmpty(listNextToken)) {
            params.payload.nextToken = listNextToken;
        }
  
        if (!lodash.isEmpty(pageSize)) {
            params.payload.pageSize = pageSize;
        }

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
  
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchAuthorJobs(): initObj is: ', JSON.stringify(initObj, null, 2));
  
        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In fetchAuthorJobs(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchAuthorJobs(): response.data.Items is: ', response.data.Items);
            // console.log('In fetchAuthorJobs(): response.data.NextToken is: ', response.data.NextToken);

            let records = [];

            listNextToken = null;

            if (response && !lodash.isEmpty(response.data.NextToken)) {
                listNextToken = response.data.NextToken;
                records = jobRecords;
            }
        
            let items = response.data.Items;

            if (items && items.length > 0) {
                setJobRecords([]);

                items.map((record) => {
                    // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                    // console.log('In fetchAuthorJobs(): formatedDateTime is: ', formatedDateTime);
                    
                    let formattedStartTime = null;
                    if (record.StartTime) {
                        formattedStartTime = FormatTimezoneDates(record.StartTime, appContext.currentLanguage.languageCode, record.JobTimeZone, "shortDateAndTime");
                    }
                    // console.log('In fetchAuthorJobs(): formattedStartTime is: ', formattedStartTime);

                    let item = {
                        JobPK: record.JobPK,
                        JobSK: record.JobSK,

                        LanguageObject: record.LanguageObject,
                        LanguageCode: record.LanguageCode,
                        Domain: record.Domain,

                        "JobId": lodash.isEmpty(record.JobId) ? "" : record.JobId,

                        "JobDomain": lodash.isEmpty(record.JobDomain) ? "" : record.JobDomain,
                        "JobCategory": lodash.isEmpty(record.JobCategory) ? "" : record.JobCategory.text,
                        "JobTitle": lodash.isEmpty(record.JobTitle) ? "" : record.JobTitle,
                        "JobContent": lodash.isEmpty(record.JobContent) ? "" : record.JobContent,
                        "Tags": lodash.isEmpty(record.Tags) ? "" : record.Tags,
                        "BannerUrl": lodash.isEmpty(record.BannerUrl) ? "" : record.BannerUrl,
                        "AuthorNames": lodash.isEmpty(record.AuthorNames) ? "" : record.AuthorNames,
                        "AuthorUserName": lodash.isEmpty(record.AuthorUserName) ? "" : record.AuthorUserName,
                        "PopularityCount": record.PopularityCount,
        
                        "FormattedStartTime": formattedStartTime,
                        "StartTime": record.StartTime,
                        "JobTimeZone": lodash.isEmpty(record.JobTimeZone) ? "" : record.JobTimeZone,
                    };
                    records.push(item);
                });
            } else {
                setNoJobRecordFoundFlag(true);
            }

            // console.log('In fetchAuthorJobs(): listNextToken is: ', listNextToken);
            if (!lodash.isEmpty(listNextToken)) {
                await fetchAuthorJobs();
            }
            
            setTimeout(() => {
                records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                setJobRecords(records);
                setJobRecordsBackup(records);
            }, 100);

            backdropProgress(false);
        }).catch((err) => {
          console.error("In fetchAuthorJobs(): err is: ", JSON.stringify(err, null, 2));
          setJobRecords([]);
          setNoJobRecordFoundFlag(true);
          backdropProgress(false);
        });
    }
    
    async function getJob(key) {
        // console.log('In getJob(): key.JobPK is: ', key.JobPK);
        // console.log('In getJob(): key.JobSK is: ', key.JobSK);
        
        backdropProgress(true);
    
        let params = {
            apiName: "CommonDynamodbAPI",
            path: "/common/dynamodb/get",
            payload: {
                tableName: tableName,
                key: {
                    JobPK: key.JobPK,
                    JobSK: key.JobSK,
                },
            },
        };
  
        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
  
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In getJob(): initObj is: ', JSON.stringify(initObj, null, 2));
  
        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In getJob(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In getJob(): response.data is: ', response.data);
        
            let item = response.data.Item;

            appContext.currentPK = item.JobPK;
            appContext.currentSK = item.JobSK;
        
            setLanguage(item.LanguageObject);
            setDomain(item.Domain);
            
            setYourNames(item.AuthorNames);
            setAuthorPhotoSrc(item.AuthorPhotoSrc);
            setJobTitle(item.JobTitle);
            setJobContent(item.JobContent);
            setJobTags(item.Tags);
            setJobBannerUrl(item.BannerUrl ? item.BannerUrl : "");

            setJobStartTime(item.StartTime);
            setJobCategory(item.JobCategory);
            setTimeZone(item.JobTimeZone);

            setShowJobListFlag(false);
            setUploadFlag(false);
            setShowJobDetailsFormFlag(true);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);

            backdropProgress(false);
        }).catch((err) => {
            console.error("In getJob(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    }
  
    async function editJobAction(item) {
        // console.log("In editJobAction(): item is: ", item);
        // console.log("In editJobAction(): jobRecords is: ", jobRecords);
        
        setLanguage(null);
        setDomain(null);
        setShowJobDetailsFormFlag(true);

        setRecordToEdit(item);
        await getJob({JobPK: item.JobPK, JobSK: item.JobSK,});
    }

    let  refDataSelectOptions = [];
    
    async function fetchReferenceData(refType, lang) {
        return new Promise((resolve, reject) => {
            // console.log('In ManageFaqs  fetchReferenceData(): refType is: ', refType);
            // console.log('In ManageFaqs  fetchReferenceData(): lang is: ', lang);
            backdropProgress(true);

            refDataSelectOptions = [];

            let apiName = null;
            let path = null;
            let keyConditionExpression = null;
            let expressionAttributeNames = {};
            let expressionAttributeValues = {};
            
            switch(refType) {
                case "supported-domains":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "supported-domains",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                default:
                    break;
            }

            setProgressFlag(true);
        
            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    keyConditionExpression: keyConditionExpression,
                    expressionAttributeNames: expressionAttributeNames,
                    expressionAttributeValues: expressionAttributeValues,
                    // "keyConditionExpression": "#resourcePK = :resourcePK",
                    // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                    // "expressionAttributeValues": {":resourcePK": "languages"}
                },
            };
        
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
        
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In  fetchReferenceData(): initObj is: ', JSON.stringify(initObj, null, 2));
        
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In  fetchReferenceData(): response.length is: ', response.data.Items.length);
                // console.log('In  fetchReferenceData(): response is: ', JSON.stringify(response.data.Items, null, 2));
                response.data.Items.map((item) => {
                    let record = {
                        value: item.ResourceCode,
                        text: item.ResourceDesc,
                    };

                    if (record.value !== "all") {// Bypass all
                        refDataSelectOptions.push(record);
                    }
                });

                switch(refType) {
                    case "supported-domains":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setDomainOptions(refDataSelectOptions);
                        break;
                    default:
                        break;
                }

                backdropProgress(false);
                resolve(refDataSelectOptions);
            }).catch((err) => {
                console.error("In ManageFaqs  fetchReferenceData(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject(err);
            });
        });
    }

    async function fetchLanguages() {
        // console.log("In fetchLanguages()");
        // setFilterDisabledFlag(true);

        //-------------------------------------------------------
        // Make sure languages is not in cache before proceeding
        //-------------------------------------------------------

        let cachedLanguages = GetCacheItem("languages");
        // console.log('In fetchLanguages(): cachedLanguages is: ', JSON.stringify(cachedLanguages, null, 2));
        cachedLanguages = null;
        if (cachedLanguages && cachedLanguages !== null && cachedLanguages.length > 0) {
            // console.log('In fetchLanguages(): cachedLanguages length is: ', cachedLanguages.length);
            setLanguages(cachedLanguages);
            return true;
        }

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/query",
            payload: {
                keyConditionExpression: "#resourcePK = :resourcePK",
                expressionAttributeNames: { "#resourcePK": "ResourcePK" },
                expressionAttributeValues: { ":resourcePK": "languages" },
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log("In fetchLanguages(): response length is: ", response.data.Items.length);
            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));

            // response.data.Items.sort((a, b) =>
            //     a.LanguageName > b.LanguageName ? 1 : -1
            // );

            let records = [];
            let items = response.data.Items;

            items.map(item => {
                let record ={
                    languageCode: item.ResourceCode,
                    languageName: item.LanguageName
                };
                records.push(record);
            });

            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
            setLanguages(records);

            // Add it to cache
            SetCacheItem("languages", response.data.Items);
            backdropProgress(false);
        }).catch((err) => {
            console.error("In fetchLanguages(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    };

    let categorySelectOptions = [];

    async function fetchCategories(referrer) {
        // console.log('In PostJobs fetchCategories()');
        backdropProgress(true);

        let apiName = null;
        let path = null;
        let keyConditionExpression = null;
        let expressionAttributeNames = {};
        let expressionAttributeValues = {};
        
        switch(referrer) {
            case "PostJobs":
                apiName = "ResourceAPI";
                path = "/dynamodb/resources/query";
                keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                expressionAttributeNames = {
                    "#resourcePK": "ResourcePK",
                    "#resourceSK": "ResourceSK",
                };
                expressionAttributeValues = {
                    ":resourcePK": "job-categories",
                    ":resourceSK": appContext.currentLanguage.languageCode,
                };
                break;
            default:
                break;
        }

        setProgressFlag(true);
    
        let params = {
            apiName: apiName,
            path: path,
            payload: {
                keyConditionExpression: keyConditionExpression,
                expressionAttributeNames: expressionAttributeNames,
                expressionAttributeValues: expressionAttributeValues,
                // "keyConditionExpression": "#resourcePK = :resourcePK",
                // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                // "expressionAttributeValues": {":resourcePK": "languages"}
            },
        };
    
        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
    
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchCategories(): initObj is: ', JSON.stringify(initObj, null, 2));
    
        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchCategories(): response.length is: ', response.data.Items.length);
            // console.log('In PostJobs fetchCategories(): response is: ', JSON.stringify(response.data.Items, null, 2));
            response.data.Items.map((item) => {
                let record = {
                    value: item.ResourceCode,
                    text: item.ResourceDesc,
                };
                // if (record.value === "all") {
                //     setJobCategory(record); // set it as default value 
                // }

                if (record.value !== "all") {// Bypass all
                    categorySelectOptions.push(record);
                }
            });
    
            categorySelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
            setCategoryOptions(categorySelectOptions);
            backdropProgress(false);
            setProgressFlag(false);
        }).catch((err) => {
            console.error("In PostJobs fetchCategories(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
            setProgressFlag(false);
        });
    }

    // async function imageObjectCallback(result) {
    //     // console.log("In imageObjectCallback(): appContext.storageCfUrl is: ", appContext.storageCfUrl);
    //     // console.log("In imageObjectCallback(): result is: ", result);
    //     sessionStorage.removeItem("user-photo-url");

    //     if (result) {
    //         const photoUrl = appContext.storageCfUrl + result;
    //         setProfilePhotoSrc(photoUrl);
    //         sessionStorage.setItem("user-photo-url", photoUrl);
    //     }
    // }
        
    useEffect(() => {
        // console.log('In PostJobs(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        // sessionStorage.removeItem("current-live-event");
    }, []);

    useLayoutEffect(() => {
        // console.log("In PostJobs(): useLayoutEffect()");
        async function fetchItems() {
            await fetchLanguages();

            await fetchCategories("PostJobs");

            // try{
            //     let imageKeyPrefix = "ccms/onyii5119@gmail.com/" + "capitalone_m2.jpg";// Test
            //     // let imageKeyPrefix = appContext.shortDomain + "/" + appContext.userName + "/user/profile/";
            //     await FetchS3Object(imageKeyPrefix, imageObjectCallback);
            // } catch (error) {
            //     console.log("In PostJobs(): useLayoutEffect(): error is: ", error);
            // }

            // Fetch resource from catche
            let cacheItemKey = "post-jobs";
            cacheItemKey += ":";
            cacheItemKey += appContext.currentLanguage.languageCode;
            // console.log('In PostJobs(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

            if (
                appContext.clearCacheLanguageCodes &&
                appContext.clearCacheLanguageCodes.length > 0 &&
                appContext.clearCacheLanguageCodes.includes(appContext.currentLanguage.languageCode)
            ) {
                // console.log("In PostJobs(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                RemoveCacheItem(cacheItemKey);
            }

            let cacheItem = GetCacheItem(cacheItemKey);
            // console.log('In PostJobs(): useLayoutEffect(): cacheItem is: ', cacheItem);
            cacheItem = null; // remove after testing

            if (cacheItem === null) {
                // resource is not in cache, fetch from DB
                // console.log('In PostJobs(): useLayoutEffect(): resource is not in cache, fetch from DB');
                await fetchDbResources(cacheItemKey);
            } else {
                // console.log('In PostJobs(): useLayoutEffect(): got resource from cache');
                setPageLabelsRes(cacheItem);
                setRenderDomFlag(true);
            }
        }

        fetchItems();
    }, []);
    
    function handleChange(event) {
        // console.log("In handleChange(): event.target.id is: ", event.target.id);
        // console.log("In handleChange(): event.target.value is: ", event.target.value);
        // setSubmitButtonFlag(false);

        switch(event.target.id) {
            case "jobTagsId":
                // console.log("In handleChange(): event.target.value is: ", event.target.value);
                setJobTagsErrFlag(false);
                setJobTags(event.target.value);

                setSubmitButtonFlag(false);
                if (
                    !lodash.isEmpty(event.target.value) &&
                    !lodash.isEmpty(jobContent) &&
                    !lodash.isEmpty(yourNames) &&
                    !lodash.isEmpty(jobTitle) &&
                    !lodash.isEmpty(jobStartTime) &&
                    !lodash.isEmpty(jobCategory) &&
                    !lodash.isEmpty(language)
                    ) {
                    setSubmitButtonFlag(true);
                }
                break;
            case "yourNamesId":
                // console.log("In handleChange(): event.target.value is: ", event.target.value);
                setYourNamesErrFlag(false);
                setYourNames(event.target.value);

                setSubmitButtonFlag(false);
                if (
                    !lodash.isEmpty(event.target.value) &&
                    !lodash.isEmpty(jobTitle) &&
                    !lodash.isEmpty(jobContent) &&
                    !lodash.isEmpty(jobStartTime) &&
                    !lodash.isEmpty(jobTags) &&
                    !lodash.isEmpty(jobCategory) &&
                    !lodash.isEmpty(language)
                    ) {
                    setSubmitButtonFlag(true);
                }
                break;
            case "jobTitleId":
                setJobTitleErrFlag(false);
                setJobTitle(event.target.value);

                setSubmitButtonFlag(false);
                if (
                    !lodash.isEmpty(event.target.value) &&
                    !lodash.isEmpty(yourNames) &&
                    !lodash.isEmpty(jobContent) &&
                    !lodash.isEmpty(jobStartTime) &&
                    !lodash.isEmpty(jobTags) &&
                    !lodash.isEmpty(jobCategory) &&
                    !lodash.isEmpty(language)
                    ) {
                    setSubmitButtonFlag(true);
                }
                break;
            case "jobBannerUrlId":
                setJobBannerUrlErrFlag(false);
                setJobBannerUrl(event.target.value ? event.target.value : "");

                // setSubmitButtonFlag(false);
                // if (
                //     !lodash.isEmpty(event.target.value) &&
                //     !lodash.isEmpty(yourNames) &&
                //     !lodash.isEmpty(jobContent) &&
                //     !lodash.isEmpty(jobStartTime) &&
                //     !lodash.isEmpty(jobTags) &&
                //     !lodash.isEmpty(language)
                //     ) {
                //     setSubmitButtonFlag(true);
                // }
                break;
            default:
                break;
        }
    }
    
    function handleLanguageChange(event, item) {
        // console.log("In handleLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        setSubmitButtonFlag(false);        
        setLanguage(item);

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(jobTitle) &&
            !lodash.isEmpty(yourNames) &&
            !lodash.isEmpty(jobContent) &&
            !lodash.isEmpty(jobStartTime) &&
            !lodash.isEmpty(jobCategory) &&
            !lodash.isEmpty(jobTags) 
        ) {
            setSubmitButtonFlag(true);
        }

        if (!lodash.isEmpty(item)) {
            Promise.all([
                fetchReferenceData("supported-domains", item)
            ]).then(async (results) => {
                // console.log("In handleListLanguageChange(): results is: ", results);
            }).catch(error => {
                console.log("In handleListLanguageChange(): error is: ", error);
            });
        }
    }

    function handleDomainChange(event, item) {
        // console.log("In handleDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            sessionStorage.setItem("media-domain-name", item.value);
        }

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(jobTitle) &&
            !lodash.isEmpty(yourNames) &&
            !lodash.isEmpty(jobContent) &&
            !lodash.isEmpty(jobStartTime) &&
            !lodash.isEmpty(jobCategory) &&
            !lodash.isEmpty(jobTags) 
            ) {
                setSubmitButtonFlag(true);
        }

        if (showJobListFlag && language && item) {
            fetchJobRecords(language, item);
        }
    }

    function handleListDomainChange(event, item) {
        // console.log("In handleListDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            fetchJobRecords(item);
        }
    }

    // For tablet and mobile views
    function handleCategoryFilterChange(event, item) {
        // event.preventDefault();
        // event.persist();
        // console.log("In handleCategoryFilterChange(): item is ", item);
        
        setJobCategory(item);

        setJobRecords([]);

        if (item && jobRecordsBackup && jobRecordsBackup.length > 0) {
            console.log("In handleCategoryFilterChange(): item.value is ", item.value);
            if (item.value === "all") {
                setJobRecords(jobRecordsBackup);
                return;
            }
            let filteredRecords = jobRecordsBackup.filter(record => record.mediaCategoryCode === item.value);
            if (filteredRecords) {
                setJobRecords(filteredRecords);
            }
        }
    }

    function handleJobStartTimeChange(startTime) {
        // console.log("In handleJobStartTimeChange(): startTime is: ", startTime);

        setGlobalErrorFlag(false);
        setJobStartTimeErrFlag(false);

        setJobStartTime(startTime);

        // const timestamp = new Date();
        // const timestamp2 = Date.now();
        // console.log("In handleJobStartTimeChange(): timestamp is: ", timestamp);
        // console.log("In handleJobStartTimeChange(): timestamp2 is: ", timestamp2);

        // const startTime2 = moment.tz(startTime, timeZone);
        // console.log("In handleJobStartTimeChange(): startTime2 is: ", startTime2);

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(startTime) &&
            !lodash.isEmpty(yourNames) &&
            !lodash.isEmpty(jobTitle) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(jobContent) &&
            !lodash.isEmpty(jobCategory) &&
            !lodash.isEmpty(jobTags) 
            ) {
            setSubmitButtonFlag(true);
        }
    }

    function handleJobCategoryChange(event, item) {
        // console.log("In handleJobCategoryChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setJobCategoryErrFlag(false);
        
        setJobCategory(item);

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(yourNames) &&
            !lodash.isEmpty(jobTitle) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(jobContent) &&
            !lodash.isEmpty(jobTags) &&
            !lodash.isEmpty(jobStartTime)
            ) {
            setSubmitButtonFlag(true);
        }
    }

    //--------------------
    // For Froala Editor
    //--------------------
    async function handleEditorHtmlBodyChange(editorData) {
        // console.log("In handleEditorHtmlBodyChange(): editorData is: ", editorData);

        // if (editorData || editorData === "") {
            setJobContent(editorData);
            setSubmitButtonFlag(false);

            if (
                !lodash.isEmpty(editorData) &&
                !lodash.isEmpty(yourNames) &&
                !lodash.isEmpty(jobTitle) &&
                !lodash.isEmpty(language) &&
                !lodash.isEmpty(domain) &&
                !lodash.isEmpty(jobCategory) &&
                !lodash.isEmpty(jobTags) &&
                !lodash.isEmpty(jobStartTime)
                ) {
                setSubmitButtonFlag(true);
            }
        // }
    }

    function initializeForm() {
        setYourNames("");
        setJobTitle("");
        setJobStartTime(null);
        setJobCategory(null);
        setJobContent("");
        setJobBannerUrl("");
        setJobTags("");
    }

    function createLiveEventAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowJobListFlag(false);
        setShowJobDetailsFormFlag(true);
        setUploadFlag(false);
    }

    function createMoreAction() {
        initializeForm();
        setLanguage(null);
        setDomain(null);

        setEditModeFlag(false);
        setShowJobListFlag(false);
        setShowJobDetailsFormFlag(true);
        setUploadFlag(false);
    }

    function uploadAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowJobListFlag(false);
        setShowJobDetailsFormFlag(false);
        setUploadFlag(true);
    }
    
    function convertAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowJobListFlag(false);
        setShowJobDetailsFormFlag(false);
        setUploadFlag(false);
        setConvertFlag(true);
    }
    
    async function closeConvertForm() {
        await fetchJobRecords(language, domain);
        
        setTimeout(() => {
            setShowJobDetailsFormFlag(false);
            setUploadFlag(false);
            setConvertFlag(false);
            setShowJobListFlag(true);
        }, 500);
    }
    
    async function closeDetailsForm() {
        await fetchJobRecords(language, domain);
        
        setTimeout(() => {
            setShowJobDetailsFormFlag(false);
            setUploadFlag(false);
            setShowJobListFlag(true);
            setEditModeFlag(false);
        }, 500);
    }
    
    async function closeUploadForm() {
        await fetchJobRecords(language, domain);
        
        setTimeout(() => {
            setShowJobListFlag(true);
            setShowJobDetailsFormFlag(false);
            setUploadFlag(false);
        }, 500);
    }
    
    // function liveEventsUploadCallback() {
    //     setEditModeFlag(false);
    //     setShowJobListFlag(true);
    //     setShowJobDetailsFormFlag(false);
    //     setUploadFlag(false);
    // }
    
    async function createService() {
        console.log("In createService(): domain is: ", domain);
        console.log("In createService(): language is: ", language);
        // console.log("In createService(): jobContent is: ", jobContent);
        // console.log("In createService(): jobTitle is: ", jobTitle);
        // console.log("In createService(): jobTags is: ", jobTags);
        // console.log("In createService(): jobStartTime is: ", jobStartTime);
        // console.log("In createService(): jobCategory is: ", jobCategory);
        // console.log("In createService(): timeZone is: ", timeZone);
        // console.log("In createService(): appContext.deceasedCode is: ", appContext.deceasedCode);
        // console.log("In createService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In createService(): appContext.userName is: ", appContext.userName);
    
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In createService(): Validation passed.");
            // console.log("In createService(): Validation passed.");

            // const timestamp = new Date();
            const timestamp = moment.tz(Date.now(), timeZone);
            // console.log("In createService(): timestamp is: ", timestamp);

            const startTime = moment.tz(jobStartTime, timeZone);
            // console.log("In createService(): startTime is: ", startTime);

            backdropProgress(true);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            let uniqueId = GenerateUniqueName();

            let key = {
                JobPK: domain.value + "#" + language.languageCode + "#job",
                JobSK: domain.value + "#" + language.languageCode + "#job#" + uniqueId,
            };

            let item = {
                "JobPK": key.JobPK,
                "JobSK":  key.JobSK,

                "JobId": uniqueId,

                "UniqueAttribute": lodash.isEmpty(key) ? null : key,
                "LanguageObject": lodash.isEmpty(language) ? null : language,
                "LanguageCode": lodash.isEmpty(language) ? null : language.languageCode,
                "Domain": domain,

                "JobDomain": domain.value,
                "JobCategory": lodash.isEmpty(jobCategory) ? null : jobCategory,
                "JobCategoryCode": lodash.isEmpty(jobCategory) ? null : jobCategory.value,
                "JobTitle": lodash.isEmpty(jobTitle) ? null : jobTitle,
                "JobContent": lodash.isEmpty(jobContent) ? null : jobContent,
                "Tags": lodash.isEmpty(jobTags) ? null : jobTags,
                "BannerUrl": lodash.isEmpty(jobBannerUrl) ? null : jobBannerUrl,
                "AuthorNames": lodash.isEmpty(yourNames) ? null : yourNames,
                "AuthorUserName": appContext.userName,
                "AuthorPhotoSrc": lodash.isEmpty(profilePhotoSrc) ? null : profilePhotoSrc, 
                "PopularityCount": 0,

                "StartTime": startTime,
                "JobTimeZone": timeZone,

                "DomainLangAuthorUserName": domain.value + "#" + language.languageCode + "#" + appContext.userName,// For GSI
                "DomainLangJobTitle": domain.value + "#" + language.languageCode + "#" + jobTitle,// For GSI
                "DomainLangStartTime": domain.value + "#" + language.languageCode + "#" + startTime,// For GSI
                "DomainLangPopularityCount": domain.value + "#" + language.languageCode + "#" + 0,// For GSI
                "DomainLangJobCategoryCode": domain.value + "#" + language.languageCode + "#" + jobCategory.value,// For GSI

                "ModifiedBy": appContext.userName,
                "CreatedAt": timestamp,
                "UpdatedAt": timestamp,
            };

            // console.log('In createService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_not_exists(UniqueAttribute)";

            let params = {
                apiName: "CommonDynamodbAPI",
                path: "/common/dynamodb/put",
                payload: {
                    tableName: tableName,
                    item: item,
                    conditionExpression: conditionExpression,
                    // updateExpression: updateExpression,
                    // expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In createService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In createService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In createService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar 
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);
            }).catch((err) => {
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                switch(err.code) {
                    case "ConditionalCheckFailedException":
                        console.error("In createService(): Duplicate Error");
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        break;
                    default:
                        console.error("In createService(): err is: ", JSON.stringify(err, null, 2));
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                    break;
                }
            });
        }
    }

    async function updateService() {
        // console.log("In updateService(): liveEventUrl is: ", liveEventUrl);
        // console.log("In updateService(): liveEventUrlType is: ", liveEventUrlType);
        // console.log("In updateService(): jobContent is: ", jobContent);
        // console.log("In updateService(): jobTitle is: ", jobTitle);
        // console.log("In updateService(): jobTags is: ", jobTags);
        // console.log("In updateService(): jobStartTime is: ", jobStartTime);
        // console.log("In updateService(): jobCategory is: ", jobCategory);
        // console.log("In updateService(): timeZone is: ", timeZone);
        // console.log("In updateService(): appContext.deceasedCode is: ", appContext.deceasedCode);
        // console.log("In updateService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In updateService(): appContext.userName is: ", appContext.userName);
        // console.log("In updateService(): appContext.currentPK is: ", appContext.currentPK);
        // console.log("In updateService(): appContext.currentSK is: ", appContext.currentSK);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In updateService(): Validation passed.");

            // let jobId = GenerateUniqueName();// conversion
                
            const timestamp = new Date();

            backdropProgress(true);
            const startTime = moment.tz(jobStartTime, timeZone);
            // console.log("In updateService(): startTime is: ", startTime);

            let key = {
                JobPK: appContext.currentPK,
                JobSK: appContext.currentSK,
            };
            // console.log("In updateService(): key is: ", key);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
            let updateExpression =
                "SET " +
                "UniqueAttribute = :uniqueAttribute, " +
                // "LanguageObject = :languageObject, " +
                // "LanguageCode = :languageCode, " +

                // "JobId = :jobId, " + // conversion
                
                "JobCategory = :jobCategory, " +
                "JobCategoryCode = :jobCategoryCode, " +
                "JobTitle = :jobTitle, " +
                "JobContent = :jobContent, " +
                "Tags = :tags, " +
                "BannerUrl = :bannerUrl, " +
                "AuthorNames = :authorNames, " +
                "AuthorPhotoSrc = :authorPhotoSrc, " +
                "StartTime = :startTime, " +
                "JobTimeZone = :jobTimeZone, " +
                
                "DomainLangAuthorUserName = :domainLangAuthorUserName, " +
                "DomainLangJobTitle = :domainLangJobTitle, " +
                "DomainLangStartTime = :domainLangStartTime, " +
                "DomainLangJobCategoryCode = :domainLangJobCategoryCode, " +

                "ModifiedBy = :modifiedBy, " + 
                "UpdatedAt = :updatedAt";

            let expressionAttributeValues = {
                ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
                // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
                // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,
                
                // ":jobId": lodash.isEmpty(jobId) ? null : jobId,// conversion
                
                ":jobCategory": lodash.isEmpty(jobCategory) ? null : jobCategory,
                ":jobCategoryCode": lodash.isEmpty(jobCategory) ? null : jobCategory.value,
                ":jobTitle": lodash.isEmpty(jobTitle) ? null : jobTitle,
                ":jobContent": lodash.isEmpty(jobContent) ? null : jobContent,
                ":tags": lodash.isEmpty(jobTags) ? null : jobTags,
                ":bannerUrl": lodash.isEmpty(jobBannerUrl) ? null : jobBannerUrl,
                ":authorNames": lodash.isEmpty(yourNames) ? null : yourNames,
                ":authorPhotoSrc": lodash.isEmpty(profilePhotoSrc) ? null : profilePhotoSrc,
                ":startTime": lodash.isEmpty(startTime) ? null : startTime,
                ":jobTimeZone": lodash.isEmpty(timeZone) ? null : timeZone,
                
                ":domainLangAuthorUserName": domain.value + "#" + language.languageCode + "#" + appContext.userName,// For GSI
                ":domainLangJobTitle": domain.value + "#" + language.languageCode + "#" + jobTitle,// For GSI
                ":domainLangStartTime": domain.value + "#" + language.languageCode + "#" + startTime,// For GSI
                ":domainLangJobCategoryCode": domain.value + "#" + language.languageCode + "#" + jobCategory.value,// For GSI
                
                ":modifiedBy": appContext.userName,
                ":updatedAt": moment.tz(timestamp, timeZone),
            };
            // console.log('In updateService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_exists(UniqueAttribute)";

            let params = {
                apiName: "CommonDynamodbAPI",
                path: "/common/dynamodb/update",
                payload: {
                    tableName: tableName,
                    key: key,
                    conditionExpression: conditionExpression,
                    updateExpression: updateExpression,
                    expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In updateService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In updateService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In updateService(): response is: ', JSON.stringify(response, null, 2));
                setShowJobDetailsFormFlag(false);
                setUploadFlag(false);
                setEditModeFlag(false);

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);

                await fetchJobRecords(language, domain);
                setShowJobListFlag(true);
            }).catch((err) => {
                console.error("In updateService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    async function deleteService(recToDelete) {
        // console.log("In deleteService(): recToDelete is: ", recToDelete);
        // setDeleteCompleteFlag(false);
        // let itemsToDelete = [];

        if (!lodash.isEmpty(recToDelete)) {
            backdropProgress(true);

            let key = {
                JobPK: recToDelete.JobPK,
                JobSK: recToDelete.JobSK,
            };

            let params = {
                apiName: "CommonDynamodbAPI",
                path: "/common/dynamodb/delete",
                payload: {
                    tableName: tableName,
                    key: key,
                },
            };
    
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In deleteService(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In deleteService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                backdropProgress(false);
                await fetchJobRecords(language, domain);
            }).catch((err) => {
                console.error("In deleteService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    function validateForm() {
        // console.log("In validateForm()");
        let formErrFlag = false;
  
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
    
        setLanguageErrFlag(false);
        setDomainErrFlag(false);
        setYourNamesErrFlag(false);
        setJobTitleErrFlag(false);
        setJobStartTimeErrFlag(false);
        setJobCategoryErrFlag(false);
        setJobTagsErrFlag(false);
        setJobContentErrFlag(false);
        setJobBannerUrlErrFlag(false);

        if (lodash.isEmpty(language)) {
            formErrFlag = true;
            setLanguageErrFlag(true);
        }

        if (lodash.isEmpty(domain)) {
            formErrFlag = true;
            setDomainErrFlag(true);
        }
    
        if (lodash.isEmpty(yourNames)) {
            formErrFlag = true;
            setYourNamesErrFlag(true);
        }
    
        if (lodash.isEmpty(jobTitle)) {
            formErrFlag = true;
            setJobTitleErrFlag(true);
        }
    
        if (lodash.isEmpty(jobStartTime)) {
            formErrFlag = true;
            setJobStartTimeErrFlag(true);
        }
    
        if (lodash.isEmpty(jobCategory)) {
            formErrFlag = true;
            setJobCategoryErrFlag(true);
        }
    
        if (lodash.isEmpty(jobTags)) {
            formErrFlag = true;
            setJobTagsErrFlag(true);
        }
    
        if (lodash.isEmpty(jobContent)) {
            formErrFlag = true;
            setJobContentErrFlag(true);
        }
    
        if (!lodash.isEmpty(jobBannerUrl) && !isURL(jobBannerUrl)) {
            formErrFlag = true;
            setJobBannerUrlErrFlag(true);
        }
    
        if (formErrFlag) {
            setGlobalErrorFlag(true);
        }
        // console.log("In validateForm(): formErrFlag is: ", formErrFlag);
        return formErrFlag;
    }

    async function jobService() {
        // console.log("In jobService()");

        if (!validateForm()) {
            // console.log("In jobService(): Validation passed.");
            if (editModeFlag) {
                await updateService();
            } else {
                await createService();
            }
        }
    }
    
    function snackbarOnClose(event, reason) {
        setSnackbarOpen(false);
    }

    function handleMultipleSelectionChange(data) {
        // console.log("In handleMultipleSelectionChange(): data.value is: ", data.value);
        setSelectedTableRows(data.value);
        setTableDirtyFlag(true);
    }

    //------------------
    // For AlertDialog
    //------------------
    const [deleteType, setDeleteType] = useState("");

    function showAlertDialog(item, type) {
        // console.log("In showAlertDialog(): type is: ", type);
        setDeleteType(type);
        
        if (type && type === "single") {// single deletion
            setRecordToDelete(item);
            setDialogSubContent(item.JobTitle);
            setAlertOpen(true);
        } else {// multiple deletions
            let msg = FormatNumber(selectedTableRows.length, appContext.currentLanguage.languageCode);
            msg += " ";
            msg += selectedTableRows.length > 1 ? jobRecordsLabel : jobRecordLabel;
            setDialogSubContent(msg);
            setAlertOpen(true);
        }
    }

    function alertClickAgree() {
        // Close Alert
        if (deleteType && deleteType === "single") {
            setAlertOpen(false);
            deleteService(recordToDelete);
        } else {
            setAlertOpen(false);
            // deleteMultipleAction();
            processMultipleDeletionRecs();
        }
    }

    function alertClickDisagree() {
        // Close Alert
        setAlertOpen(false);
        return false;
    }

    function alertClose() {
        // Close Alert
        setAlertOpen(false);
    }

    function deleteIconTemplate(rowData, column) {
        return <DeleteIcon color="secondary" onClick={() => showAlertDialog(rowData, "single")}/>
    }

    function editIconTemplate(rowData, column) {
        return <EditIcon color="primary" onClick={() => editJobAction(rowData)}/>
    }

    async function createOrUpdateStreamRecord(rowData) {
        // console.log("In createOrUpdateStreamRecord(): rowData is: ", JSON.stringify(rowData, null, 2));
        // console.log("In createOrUpdateStreamRecord(): CobPK is: ", rowData.CobPK);
        // console.log("In createOrUpdateStreamRecord(): CobSK is: ", rowData.CobSK);

        // console.log("In createOrUpdateStreamRecord(): liveEventUrl is: ", rowData.LiveEventUrl);
        // console.log("In createOrUpdateStreamRecord(): liveEventUrlType is: ", rowData.LiveEventUrlType);
        // console.log("In createOrUpdateStreamRecord(): jobContent is: ", rowData.JobContent);
        // console.log("In createOrUpdateStreamRecord(): jobTitle is: ", rowData.JobTitle);
        // console.log("In createOrUpdateStreamRecord(): jobTags is: ", rowData.JobTags);

        // console.log("In createOrUpdateStreamRecord(): jobStartTime is: ", rowData.JobStartTime);
        // console.log("In createOrUpdateStreamRecord(): jobCategory is: ", rowData.JobCategory);

        // console.log("In createOrUpdateStreamRecord(): timeZone is: ", timeZone);
        // console.log("In createOrUpdateStreamRecord(): appContext.deceasedCode is: ", appContext.deceasedCode);
        // console.log("In createOrUpdateStreamRecord(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In createOrUpdateStreamRecord(): appContext.userName is: ", appContext.userName);

        let jobCategory = null;
        if (rowData.JobTags) {
            jobCategory = moment(rowData.JobStartTime).add(parseInt(rowData.JobTags.value), 'minutes');
        }
        // console.log("In createOrUpdateStreamRecord(): jobCategory is: ", jobCategory);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: "live-events",
            CobSK: appContext.currentLanguage.languageCode + "#" + appContext.deceasedCode,
        };
        // console.log("In createOrUpdateStreamRecord(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        // let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            "LanguageObject = :languageObject, " +
            "LanguageCode = :languageCode, " +
            
            "LiveEventStarted = :liveEventStarted, " +

            "JobTags = :jobTags, " +

            "JobStartTime = :jobStartTime, " +
            "JobCategory = :jobCategory, " +

            "EventTimeZone = :eventTimeZone, " +

            "LiveEventUrl = :liveEventUrl, " +
            "LiveEventUrlType = :liveEventUrlType, " +
            "JobContent = :jobContent, " +
            "YourNames = :yourNames, " +
            "JobTitle = :jobTitle, " +

            "EventThumbnailUrl = :eventThumbnailUrl, " +
            "EventBannerUrl = :eventBannerUrl, " +
            "EventPosterUrl = :eventPosterUrl, " +
            "EventAudioUrl = :eventAudioUrl, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            ":languageObject": lodash.isEmpty(rowData.LanguageObject) ? null : rowData.LanguageObject,
            ":languageCode": lodash.isEmpty(rowData.LanguageCode) ? null : rowData.LanguageCode,
                    
            ":liveEventStarted": true,

            ":jobTags": lodash.isEmpty(rowData.JobTags) ? null : rowData.JobTags,

            ":jobStartTime": lodash.isEmpty(rowData.JobStartTime) ? null : rowData.JobStartTime,
            ":jobCategory": lodash.isEmpty(jobCategory) ? null : jobCategory,

            ":eventTimeZone": lodash.isEmpty(timeZone) ? null : timeZone,

            ":liveEventUrl": lodash.isEmpty(rowData.LiveEventUrl) ? null : rowData.LiveEventUrl,
            ":liveEventUrlType": lodash.isEmpty(rowData.LiveEventUrlType) ? null : rowData.LiveEventUrlType,
            ":yourNames": lodash.isEmpty(rowData.YourNames) ? null : rowData.YourNames,
            ":jobTitle": lodash.isEmpty(rowData.JobTitle) ? null : rowData.JobTitle,
            ":jobContent": lodash.isEmpty(rowData.JobContent) ? null : rowData.JobContent,

            ":eventThumbnailUrl": lodash.isEmpty(rowData.EventThumbnailUrl) ? null : rowData.EventThumbnailUrl,
            ":eventBannerUrl": lodash.isEmpty(rowData.EventBannerUrl) ? null : rowData.EventBannerUrl,
            ":eventPosterUrl": lodash.isEmpty(rowData.EventPosterUrl) ? null : rowData.EventPosterUrl,
            ":eventAudioUrl": lodash.isEmpty(rowData.EventAudioUrl) ? null : rowData.EventAudioUrl,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In createOrUpdateStreamRecord(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        // conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                // conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In createOrUpdateStreamRecord(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In createOrUpdateStreamRecord(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In createOrUpdateStreamRecord(): response is: ', JSON.stringify(response, null, 2));
            // setShowJobDetailsFormFlag(false);
            // setUploadFlag(false);

            // Show Snackbar
            setSnackbarsVariant("success");
            setSnackbarsMsg(operationSuccessMsg);
            setSnackbarOpen(true);

            // initializeForm();
            // const now = moment();
            // let remainTime = now.diff(moment(formattedTime), "minutes");
            // console.log("In createOrUpdateStreamRecord(): remainTime is: ", remainTime + " minutes");

            let records = [];
            rowData.LiveEventStarted = true;
            records = jobRecords.filter(item => item.LiveEventId !== rowData.LiveEventId);
            if (records) {
                records.push(rowData);
                records.sort((a, b) => (a.JobStartTime > b.JobStartTime ? 1 : -1));
                setJobRecords(records);
            }

            backdropProgress(false);
        }).catch((err) => {
            console.error("In createOrUpdateStreamRecord(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }

    async function updateLiveEventStatus(rowData) {
        // console.log("In updateLiveEventStatus(): rowData.CobPK is: ", rowData.CobPK);
        // console.log("In updateLiveEventStatus(): rowData.CobSK is: ", rowData.CobSK);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
                
        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: rowData.CobPK,
            CobSK: rowData.CobSK,
        };
        // console.log("In updateLiveEventStatus(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            // "LanguageObject = :languageObject, " +
            // "LanguageCode = :languageCode, " +

            "LiveEventStarted = :liveEventStarted, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
            // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,

            ":liveEventStarted": true,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In updateLiveEventStatus(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In updateLiveEventStatus(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In updateLiveEventStatus(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In updateLiveEventStatus(): response is: ', JSON.stringify(response, null, 2));
            setShowJobDetailsFormFlag(false);
            setUploadFlag(false);

            // Show Snackbar
            setSnackbarsVariant("success");
            setSnackbarsMsg(operationSuccessMsg);
            setSnackbarOpen(true);

            // initializeForm();

            backdropProgress(false);

            await fetchJobRecords(language, domain);
            setShowJobListFlag(true);
        }).catch((err) => {
            console.error("In updateLiveEventStatus(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }

    async function forcedToEndUpdate(rowData, showStatus) {
        // console.log("In forcedToEndUpdate(): rowData.CobPK is: ", rowData.CobPK);
        // console.log("In forcedToEndUpdate(): rowData.CobSK is: ", rowData.CobSK);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        // console.log("In forcedToEndUpdate(): Validation passed.");
            
        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: rowData.CobPK,
            CobSK: rowData.CobSK,
        };
        // console.log("In forcedToEndUpdate(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            // "LanguageObject = :languageObject, " +
            // "LanguageCode = :languageCode, " +

            "ForcedToEndFlag = :forcedToEndFlag, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
            // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,

            ":forcedToEndFlag": true,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In forcedToEndUpdate(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In forcedToEndUpdate(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In forcedToEndUpdate(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In forcedToEndUpdate(): response is: ', JSON.stringify(response, null, 2));
            setShowJobDetailsFormFlag(false);
            setUploadFlag(false);

            // Show Snackbar
            if (showStatus) {
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);
            }

            // initializeForm();

            backdropProgress(false);

            await fetchJobRecords(language, domain);
            setShowJobListFlag(true);
        }).catch((err) => {
            console.error("In forcedToEndUpdate(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }

    async function startEventAction(rowData) {
        // console.log("In startEventAction(): rowData is: ", JSON.stringify(rowData));

        if (rowData) {
            // console.log("In startEventAction(): rowData.JobCategory is: ", rowData.JobCategory);
            setGlobalErrorFlag(false);
            // sessionStorage.setItem("current-live-event", JSON.stringify(rowData));
            // let diff2 = moment(rowData.JobCategory).fromNow();
            // console.log("In startEventAction(): diff2 is: ", diff2);

            let diff = moment(rowData.JobStartTime).diff(moment(), "minutes");
            // console.log("In startEventAction(): diff is: ", diff);

            if (diff <= appContext.liveEventLeadTime) {
                setEventStartedFlag(true);
                setStartedEventId(rowData.LiveEventId);

                await createOrUpdateStreamRecord(rowData);
                await updateLiveEventStatus(rowData);
            } else {
                // Show Snackbar
                setSnackbarsVariant("error");
                setSnackbarsMsg(startEventInfoMsg);
                setSnackbarOpen(true);
            }
        }
    }

    async function endEventAction(rowData) {
        // console.log("In endEventAction(): rowData.LiveEventId is: ", rowData.LiveEventId);

        await forcedToEndUpdate(rowData, true);
    }

    // function MobileEventStatus(props) {
    //     // console.log("In MobileEventStatus(): props.rowData is: ", props.rowData);
    //     // let liveEventStatus = sessionStorage.getItem("live-event-status");
    //     // console.log("In MobileEventStatus(): JobTitle is: ", rowData.JobTitle);

    //     const {
    //         rowData,
    //     } = props;

    //     if (rowData) {
    //         let eventStarted = rowData.LiveEventStarted;
    //         // console.log("In MobileEventStatus(): eventStarted is: ", eventStarted);
    
    //         let forcedToEnd = rowData.ForcedToEndFlag;
    //         // console.log("In MobileEventStatus(): forcedToEnd is: ", forcedToEnd);
    
    //         let currentTime = moment();
    //         let startTime = moment(rowData.JobStartTime).subtract(parseInt(appContext.liveEventLeadTime), 'minutes');
    //         // let startTime = moment(rowData.JobStartTime);
    //         let endTime = moment(rowData.JobCategory);
    
    //         // console.log("In MobileEventStatus(): currentTime is: ", currentTime);
    //         // console.log("In MobileEventStatus(): startTime is: ", startTime);
    //         // console.log("In MobileEventStatus(): endTime is: ", endTime);
    
    //         if (forcedToEnd) {
    //             return  (
    //                 <CheckIcon style={{color: 'green'}}/>
    //             );
    //         }
    
    //         if (currentTime.isAfter(startTime) && currentTime.isSameOrBefore(endTime)) {
    //             if (eventStarted) {
    //                 return (
    //                     <>
    //                         <LinearProgress color="secondary" style={{background: '#ff9800'}} />
    
    //                         <Button size="small" disabled={false} color="primary" onClick={() => endEventAction(rowData)}>
    //                             {endLabel}
    //                         </Button>
    //                     </>
    //                 );
    //             } else {// Event has not started. Give user opportunity to start the event.
    //                 return (
    //                     <>
    //                         <Typography variant="caption" color="secondary">
    //                             {notStartedLabel}
    //                         </Typography>
    
    //                         <Button variant="contained" size="small" disabled={false} color="primary" onClick={() => startEventAction(rowData)}>
    //                             {startLabel}
    //                         </Button>
    //                     </>
    //                 );
    //             }
    //         } else if (currentTime.isBefore(startTime)) {
    //             if (!eventStarted) {
    //                 // Event not started and It is not yet time to start the event. 
    //                 // Give user the opportunity to start the event
    //                 return (
    //                     <Button variant="contained" size="small" disabled={false} color="primary" onClick={() => startEventAction(rowData)}>
    //                         {startLabel}
    //                     </Button>
    //                 );
    //             }
    //         } else if (currentTime.isAfter(endTime)) {
    //             // Event should have started and ended
    //             if (eventStarted) {
    //                 // Silently mark event as ended
    //                 forcedToEndUpdate(rowData, false);
                    
    //                 return (
    //                     <CheckIcon style={{color: 'green'}}/>
    //                 );
    //             } else {
    //                 return (
    //                     <Typography variant="caption" color="secondary">
    //                         {notStartedLabel}
    //                     </Typography>
    //                 );
    //             }
    //         }
    //     }
    //     return null;
    // }
    
    // function sortDateTimeField(event) {
    //     // console.log("In sortDateTimeField(): event is: ", event);

    //     var values = [...jobRecords];
    //     values.sort((data1, data2) => {
    //         // Note: We are using actual DateTime object and not the dateTimeFormatted 
    //         // used for display on data table.
    //         let value1 = data1.CreatedTimestamp;
    //         let value2 = data2.CreatedTimestamp;
    //         let result = null;

    //         if (value1 == null && value2 != null)
    //             result = -1;
    //         else if (value1 != null && value2 == null)
    //             result = 1;
    //         else if (value1 == null && value2 == null)
    //             result = 0;
    //         else if (typeof value1 === 'string' && typeof value2 === 'string')
    //             result = value1.localeCompare(value2);
    //         else
    //             result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

    //         return (event.order * result);
    //     });

    //     return values;
    // }
    
    function sortDateField(event) {
        // console.log("In sortDateField(): event is: ", event);

        var values = [...jobRecords];
        values.sort((data1, data2) => {
            // Note: We are using actual DateTime object and not the dateTimeFormatted 
            // used for display on data table.
            let value1 = data1.StartTime;
            let value2 = data2.StartTime;
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });

        return values;
    }
    
    // function sortTimeField(event) {
    //     // console.log("In sortTimeField(): event is: ", event);

    //     var values = [...jobRecords];
    //     values.sort((data1, data2) => {
    //         // Note: We are using actual DateTime object and not the dateTimeFormatted 
    //         // used for display on data table.
    //         let value1 = data1.JobCategory;
    //         let value2 = data2.JobCategory;
    //         let result = null;

    //         if (value1 == null && value2 != null)
    //             result = -1;
    //         else if (value1 != null && value2 == null)
    //             result = 1;
    //         else if (value1 == null && value2 == null)
    //             result = 0;
    //         else if (typeof value1 === 'string' && typeof value2 === 'string')
    //             result = value1.localeCompare(value2);
    //         else
    //             result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

    //         return (event.order * result);
    //     });

    //     return values;
    // }

    let processedRecs = 0;

    async function deleteMultipleAction() {
        // console.log("In deleteMultipleAction(): selectedTableRows.length is: ", selectedTableRows.length);
        // console.log("In deleteMultipleAction(): selectedTableRows is: ", selectedTableRows);

        // Disable Save changes button
        setTableDirtyFlag(false);

        return new Promise((resolve, reject) => {
            if (selectedTableRows && selectedTableRows.length > 0) {
                selectedTableRows.map(record => {
                    setTimeout(async () => {
                        await deleteService(record);

                        processedRecs++;

                        if (processedRecs === selectedTableRows.length) {
                            resolve("Done");
                        }
                    }, 1000);
                });
            }
        });
    }

    async function processMultipleDeletionRecs() {
        // console.log("In processMultipleDeletionRecs()");

        //-------------------
        // Promise.all
        //-------------------
        Promise.all(
            [
                deleteMultipleAction()
            ]).then(async (results) => {
                // console.log("In processMultipleDeletionRecs(): results is: ", results);
                // console.log("In processMultipleDeletionRecs(): records deleted from AWS, now delete them from DataTable");
                // Remove deleted records from DataTable
                let remainingRecords = FilterArrayFromArray(jobRecords, selectedTableRows);
                if (remainingRecords) {
                    setJobRecords(remainingRecords);
                    setSelectedTableRows([]);
                }
            });
    }

    function cloneEventAction() {
        setJobStartTime(null);
        setJobCategory(null);
        setEditModeFlag(false);
        setSubmitButtonFlag(false);
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        setJobContent(item.JobContent);

        setRecordToEdit(item);

        appContext.currentPK = item.JobPK;
        appContext.currentSK = item.JobSK;
        await getJob({JobPK: item.JobPK, JobSK: item.JobSK,});

        setTimeout(() => {
            setShowJobListFlag(false);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);
            setShowJobDetailsFormFlag(true);
        }, 500);
    }
  
    function sliceUrl(url, maxChars) {
        let retValue = url;
        if (url.length > maxChars) {
            retValue = url.substring(0, (maxChars - 1));
            retValue += " ...";
        }
        return retValue;
    }

    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    //--------------------------
    // For CommonMediaSelector
    //--------------------------
    const [dialogOpenFlag, setDialogOpenFlag] = useState(false);
    const [imagesLocationUri, setImagesLocationUri] = useState("");
    
    function onRowSelectedCallback(src) {
        // console.log("In onRowSelectedCallback(): src is: ", src);
        // console.log("In onRowSelectedCallback(): editorInstance is: ", editorInstance);
        // editorInstance.html.insert('<img src="'+ src + '" style="width: 300px;" class="fr-fic fr-dib">');
        setJobBannerUrl(src ? src : "");
        setDialogOpenFlag(false);
    }

    function dialogCloseCallback() {
        // console.log("In dialogCloseCallback()");
        setDialogOpenFlag(false);
    }

    function selectImageAction() {
        // Open CommonMediaSelector
        setDialogOpenFlag(true);
        // setImageSelectorFlag(true);
    }

    //------------------------
    // For sliceText and Dialog
    //------------------------

    function sliceMoreAction(record) {
        setDialogData(record.JobContent);
        setDialogHeader(record.JobTitle);
        setDlgVisibleFlag(true);
    }

    function hideModalDlg() {
        setDlgVisibleFlag(false);
    }
  
    function sliceText(text, maxChars) {
        let retValue = text;
        if (text.length > maxChars) {
            retValue = text.substring(0, (maxChars - 1));
            retValue += " ...";
        }
        return retValue;
    }

    function LanguageAndDomain(props) {
        return (
            <>
                {isDeskTopView &&
                    <>
                        <Grid item xs={12} sm={6}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                        <label htmlFor="languageId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                *{languageLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="languageId"
                                            value={language}
                                            options={languages}
                                            getOptionLabel={option => option.languageName}
                                            getOptionSelected={option => option.languageName}
                                            onChange={handleLanguageChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={languagePrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={languageErrFlag}
                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                        <label htmlFor="domainId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                *{domainLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="domainId"
                                            value={domain}
                                            options={domainOptions}
                                            getOptionLabel={option => option.text}
                                            getOptionSelected={option => option.text}
                                            onChange={handleDomainChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={domainPrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={domainErrFlag}
                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }

                {isTabletView &&
                    <div>
                        <Grid item xs={12} sm={12}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                        <label htmlFor="languageId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                *{languageLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="languageId"
                                            value={language}
                                            options={languages}
                                            getOptionLabel={option => option.languageName}
                                            getOptionSelected={option => option.languageName}
                                            onChange={handleLanguageChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={languagePrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={languageErrFlag}
                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <LineSpacing value={12} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                        <label htmlFor="domainId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                *{domainLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="domainId"
                                            value={domain}
                                            options={domainOptions}
                                            getOptionLabel={option => option.text}
                                            getOptionSelected={option => option.text}
                                            onChange={handleDomainChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            // disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={domainPrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={domainErrFlag}
                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                }

                {isMobileView &&
                    <div className={clsx(classes.divPadding, classes.divWrapper)}>
                        <Grid container justify="center">
                            <Grid item xs={12} sm={6}>
                                <Grid container justify="center">
                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                            <label htmlFor="languageId" style={directionAlignment}>
                                                <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                    *{languageLabel}
                                                </Typography>
                                            </label>

                                            <Autocomplete
                                                id="languageId"
                                                value={language}
                                                options={languages}
                                                getOptionLabel={option => option.languageName}
                                                getOptionSelected={option => option.languageName}
                                                onChange={handleLanguageChange}
                                                loadingText={loadingText}
                                                noOptionsText={noOptionsText}
                                                autoHighlight={true}
                                                clearText={clearText}
                                                closeText={closeText}
                                                disabled={editModeFlag ? true : false}
                                                renderInput={params => 
                                                    <TextField 
                                                        {...params} 
                                                        placeholder={languagePrompt} 
                                                        variant="outlined"
                                                        fullWidth
                                                        error={languageErrFlag}
                                                        helperText={languageErrFlag ? languageErrMsg : ""}
                                                        // InputProps={{
                                                        //     readOnly: editModeFlag ? true : false,
                                                        // }}
                                                    />
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Grid container justify="center">
                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                            <label htmlFor="domainId" style={directionAlignment}>
                                                <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                    *{domainLabel}
                                                </Typography>
                                            </label>

                                            <Autocomplete
                                                id="domainId"
                                                value={domain}
                                                options={domainOptions}
                                                getOptionLabel={option => option.text}
                                                getOptionSelected={option => option.text}
                                                onChange={handleDomainChange}
                                                loadingText={loadingText}
                                                noOptionsText={noOptionsText}
                                                autoHighlight={true}
                                                clearText={clearText}
                                                closeText={closeText}
                                                // disabled={editModeFlag ? true : false}
                                                renderInput={params => 
                                                    <TextField 
                                                        {...params} 
                                                        placeholder={domainPrompt} 
                                                        variant="outlined"
                                                        fullWidth
                                                        error={domainErrFlag}
                                                        helperText={domainErrFlag ? domainErrMsg : ""}
                                                        // InputProps={{
                                                        //     readOnly: editModeFlag ? true : false,
                                                        // }}
                                                    />
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                }
            </>
        );
    }

    const cloneRecordAction = () => {
        setLanguage(null);
        setJobCategory(null);
        setJobStartTime(null);
        setJobTitle("");

        setSubmitButtonFlag(false);
        setEditModeFlag(false);
    }

    //====================RETURN=====================================================================================

    return (
        <div dir={direction} className={clsx(classes.root, classes.divWrapper)}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {renderDomFlag &&
                <>
                    {/* { !appContext.signedIn && <Redirect to='/signIn' />} */}
                    <CssBaseline />

                    <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />

                    {isDeskTopView && 
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
                            <Container component="div" className={classes.main} maxWidth="lg">
                                <StyledPaper elevation={3} className={classes.paper}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h5" color="primary" style={{ textAlign: "center" }}>
                                                {manageJobsLabel}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                                                {requiredFields}
                                            </Typography>
                                        </Grid>

                                        {globalErrorFlag && (
                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={24} />
                                                <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                {showJobListFlag &&
                                                    <>
                                                        <LanguageAndDomain/>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            {jobRecords && jobRecords.length > 0 ?
                                                                <>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-start">
                                                                                    <Button variant="contained" size="large" disabled={selectedTableRows && selectedTableRows.length > 0 ? false : true} color="primary" onClick={() => showAlertDialog(null, "multiple")}>
                                                                                        <DeleteIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {deleteLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={convertAction}>
                                                                                        <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {convertLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={uploadAction}>
                                                                                        <CloudUploadIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {uploadLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-end">
                                                                                    <Button variant="contained" size="large" disabled={false} color="primary" onClick={createMoreAction}>
                                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {createMoreLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12}>
                                                                        <LineSpacing value={8} />
                                                                    </Grid>
                                                                    
                                                                    {/* {jobRecords.map((record, index) => ( */}
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12}>
                                                                            <div className="datatable-doc-demo">
                                                                                <DataTable 
                                                                                    value={jobRecords}
                                                                                    resizableColumns={true} 
                                                                                    columnResizeMode={"fit"}
                                                                                    sortMode={"multiple"}
                                                                                    responsive={true}
                                                                                    reorderableColumns={true}

                                                                                    // selectionMode={"multiple"}
                                                                                    selection={selectedTableRows}
                                                                                    onSelectionChange={handleMultipleSelectionChange}

                                                                                    // paginator={visibleRowsCount > 10 ? paginatorFlag : !paginatorFlag} 
                                                                                    paginator={jobRecords && jobRecords.length > 10} 
                                                                                    // paginatorLeft={paginatorLeft}

                                                                                    paginatorTemplate={paginatorTemplate}
                                                                                    currentPageReportTemplate={"{first}" + " " + toLabel + " " + "{last}" + " " + ofLabel + " " + "{totalRecords}" + " " + recordsLabel} 
                                                                                    rows={visibleRowsCount} 
                                                                                    rowsPerPageOptions={rowsPerPageOptions}
                                                                                    // className="p-datatable-responsive-cob"
                                                                                    // className="p-datatable-striped"
                                                                                    emptyMessage={noRecordsFound}
                                                                                    className={clsx("p-datatable-striped", "p-datatable-customers")}
                                                                                    rowHover 
                                                                                    >

                                                                                    <Column 
                                                                                        header={deleteLabel} 
                                                                                        selectionMode="multiple" 
                                                                                        style={{width:'5em', textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="JobCategory" 
                                                                                        header={jobCategoryLabel} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="AuthorNames" 
                                                                                        header={gridNames} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="JobTitle" 
                                                                                        header={gridTitle} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="Tags" 
                                                                                        header={jobTagsLabel} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains"
                                                                                        style={{textAlign:'center'}} 
                                                                                    />
                                                                                    <Column 
                                                                                        field="FormattedStartTime" 
                                                                                        header={gridStartTime} 
                                                                                        sortable={true} 
                                                                                        sortFunction={sortDateField}
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="BannerUrl" 
                                                                                        header={gridBannerUrl} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    {/* <Column 
                                                                                        field="JobContent" 
                                                                                        header={jobContentLabel} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    /> */}
                                                                                    <Column 
                                                                                        field="deleteIcon" 
                                                                                        header={deleteLabel} 
                                                                                        body={deleteIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="editIcon" 
                                                                                        header={editLabel} 
                                                                                        body={editIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                </DataTable>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            :
                                                                <>
                                                                    {noJobRecordFoundFlag &&
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={12} sm={12}>
                                                                                <Typography variant="body1">
                                                                                    {noRecordsFound}
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <LineSpacing value={12} />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <Button variant="contained" size="large" disabled={false} color="primary" onClick={createLiveEventAction}>
                                                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                    {createJobContentLabel}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                            }
                                                        </Grid>
                                                    </>
                                                }

                                                {showJobDetailsFormFlag && 
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={6} sm={3}>
                                                                    <Grid container justify="flex-start">
                                                                        <Typography variant="subtitle1" color="primary">
                                                                            {yourTimeZoneMsg}: <span style={{color: '#000', paddingLeft: '8px'}}>{timeZone}</span>
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={6} sm={3}>
                                                                    <Grid container justify="center">
                                                                        <Button variant="contained" size="small" disabled={editModeFlag ? false : true} color="primary" onClick={cloneRecordAction}>
                                                                            {cloneRecordLabel}
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={12} sm={6}>
                                                                    <Grid container justify="center">
                                                                        <img src={jobBannerUrl} style={{height: '100px'}} />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <LanguageAndDomain/>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={jobCategoryErrFlag}>
                                                                        <label htmlFor="jobCategoryId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={jobCategoryErrFlag ? "secondary" : "inherit"}>
                                                                                *{jobCategoryLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="jobCategoryId"
                                                                            value={jobCategory}
                                                                            options={categoryOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleJobCategoryChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            // disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={jobCategoryPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={jobCategoryErrFlag}
                                                                                    helperText={jobCategoryErrFlag ? jobCategoryErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={jobBannerUrlErrFlag}>
                                                                        <label htmlFor="jobBannerUrlId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={jobBannerUrlErrFlag ? "secondary" : "inherit"}>
                                                                                {jobBannerUrlLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <OutlinedInput
                                                                            id="jobBannerUrlId"
                                                                            value={jobBannerUrl}
                                                                            placeholder={jobBannerUrlPrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={jobBannerUrlErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            endAdornment={
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        aria-label={jobBannerUrlPrompt}
                                                                                        onClick={selectImageAction}
                                                                                    >
                                                                                        <ImageSearchIcon />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            }
                                                                        />
                                                                        <FormHelperText>{jobBannerUrlErrFlag ? jobBannerUrlErrMsg : ""}</FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={yourNamesErrFlag}>
                                                                        <label htmlFor="yourNamesId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={yourNamesErrFlag ? "secondary" : "inherit"}>
                                                                                *{yourNamesLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <TextField
                                                                            id="yourNamesId"
                                                                            value={yourNames}
                                                                            placeholder={yourNamesPrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={yourNamesErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            helperText={yourNamesErrFlag ? yourNamesErrMsg : yourNamesMsg}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={jobTitleErrFlag}>
                                                                        <label htmlFor="jobTitleId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={jobTitleErrFlag ? "secondary" : "inherit"}>
                                                                                *{jobTitleLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <TextField
                                                                            id="jobTitleId"
                                                                            value={jobTitle}
                                                                            placeholder={jobTitlePrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={jobTitleErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            helperText={jobTitleErrFlag ? jobTitleErrMsg : ""}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={jobStartTimeErrFlag}>
                                                                                <label htmlFor="jobStartTimeId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={jobStartTimeErrFlag ? "secondary" : "inherit"}>
                                                                                        *{jobStartTimeLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <KeyboardDateTimePicker
                                                                                    id="jobStartTimeId"
                                                                                    value={jobStartTime}
                                                                                    // views={["year"]}
                                                                                    onChange={handleJobStartTimeChange}
                                                                                    clearable
                                                                                    placeholder={jobStartTimePrompt}
                                                                                    format="MMM DD, YYYY hh:mm a z"
                                                                                    // inputVariant="outlined"
                                                                                    invalidDateMessage={jobStartTimeErrMsg}
                                                                                    cancelLabel={cancelLabel}
                                                                                    clearLabel={clearLabel}
                                                                                    okLabel={okLabel}
                                                                                    // className={classes.phone}
                                                                                    inputVariant="outlined"
                                                                                    margin="none"
                                                                                />
                                                                                <FormHelperText>{jobStartTimeErrFlag ? jobStartTimeErrMsg : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={jobTagsErrFlag}>
                                                                                <label htmlFor="jobTagsId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={jobTagsErrFlag ? "secondary" : "inherit"}>
                                                                                        *{jobTagsLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextareaAutosize
                                                                                    id="jobTagsId"
                                                                                    aria-label={jobTagsLabel}
                                                                                    rows={1.5}
                                                                                    rowsMax={6}
                                                                                    value={jobTags}
                                                                                    placeholder={jobTagsPrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    // autoFocus={true}
                                                                                    margin="none"
                                                                                    className={classes.textArea}
                                                                                    maxLength={textMaxLength}
                                                                                />
                                                                                <FormHelperText>{jobTagsErrFlag ? jobTagsErrMsg : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={jobContentErrFlag}>
                                                                        <label htmlFor="jobContentId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={jobContentErrFlag ? "secondary" : "inherit"}>
                                                                                *{jobContentLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <FroalaCommonEditor
                                                                            editorId={"jobContentId"}
                                                                            editorRef={editorRef}
                                                                            // editorType={"minimal"} // Full Editor when not set
                                                                            placeholderPrompt={jobContentPrompt}
                                                                            editorMaxChars={editorMaxChars}
                                                                            editorModel={jobContent}
                                                                            modelChangeCallback={handleEditorHtmlBodyChange}
                                                                            referrer={"PostJobs"}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={jobService}>
                                                                    {editModeFlag ?
                                                                    <>
                                                                        <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {updateJobContentLabel} 
                                                                    </>
                                                                    : 
                                                                    <>
                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {createJobContentLabel}
                                                                    </>
                                                                    }
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {uploadFlag && 
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeUploadForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <DomainUploadCapture 
                                                                showHeadersFlag={false}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                {convertFlag &&
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <CommonConverter 
                                                                groupingCategoryLabel={jobTitleLabel}
                                                                groupingCategoryPrompt={jobTitlePrompt}
                                                                groupingCategoryErrMsg={jobTitleErrMsg}

                                                                showHeadersFlag={false}
                                                                actionMsgLabel={uploadLabel}

                                                                referrer={"PostJobs"}
                                                                categoryType={"job-titles"}

                                                                tableName={tableName}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={18} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledPaper>
                            </Container>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={48} />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    }

                    {isTabletView && 
                        <PostJobsTv
                            manageJobsLabel={manageJobsLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            jobBannerUrlLabel={jobBannerUrlLabel}
                            jobBannerUrlPrompt={jobBannerUrlPrompt}
                            jobBannerUrlErrMsg={jobBannerUrlErrMsg}
                                
                            yourNamesLabel={yourNamesLabel}
                            yourNamesPrompt={yourNamesPrompt}
                            yourNamesErrMsg={yourNamesErrMsg}
                            yourNamesMsg={yourNamesMsg}
                            
                            jobTitleLabel={jobTitleLabel}
                            jobTitlePrompt={jobTitlePrompt}
                            jobTitleErrMsg={jobTitleErrMsg}
                            selectTitlePrompt={selectTitlePrompt}
                    
                            jobTagsLabel={jobTagsLabel}
                            jobTagsPrompt={jobTagsPrompt}
                            jobTagsErrMsg={jobTagsErrMsg}
                        
                            jobStartTimeLabel={jobStartTimeLabel}
                            jobStartTimePrompt={jobStartTimePrompt}
                            jobStartTimeErrMsg={jobStartTimeErrMsg}
                    
                            jobCategoryLabel={jobCategoryLabel}
                            jobCategoryPrompt={jobCategoryPrompt}
                            jobCategoryErrMsg={jobCategoryErrMsg}
                    
                            // uploadJobTitleLabel={uploadJobTitleLabel}
                            // uploadJobTitlePrompt={uploadJobTitlePrompt}
                            // uploadJobTitleErrMsg={uploadJobTitleErrMsg}
                    
                            // jobRecordsLabel={jobRecordsLabel}
                            // inProgressLabel={inProgressLabel}
                            // startLabel={startLabel}
                            // endLabel={endLabel}
                            // notStartedLabel={notStartedLabel}
                            // dateCreatedLabel={dateCreatedLabel}
                        
                            jobContentLabel={jobContentLabel}
                            jobContentPrompt={jobContentPrompt}
                            yourTimeZoneMsg={yourTimeZoneMsg}
                            cloneRecordLabel={cloneRecordLabel}

                            // timeZoneLabel={timeZoneLabel}
                            // toLabel={toLabel}
                            // ofLabel={ofLabel}
                            // recordsLabel={recordsLabel}
                            // noRecordsFound={noRecordsFound}
                            // statusLabel={statusLabel}
                            // editLabel={editLabel}
                            // deleteLabel={deleteLabel}
                    
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            cancelLabel={cancelLabel}
                            clearLabel={clearLabel}
                            okLabel={okLabel}
                            uploadLabel={uploadLabel}
                            createJobContentLabel={createJobContentLabel}
                            updateJobContentLabel={updateJobContentLabel}
                            // jobRecordLabel={jobRecordLabel}
                            createMoreLabel={createMoreLabel}
                            createLabel={createLabel}
                            closeLabel={closeLabel}
                            noRecordsFound={noRecordsFound} 
                            // startEventInfoMsg={startEventInfoMsg}
                    
                            gridTitle={gridTitle}
                            gridNames={gridNames}
                            // gridEventUrl={gridEventUrl}
                            // gridEventUrlType={gridEventUrlType}
                            gridStartTime={gridStartTime}
                            gridBannerUrl={gridBannerUrl}
                        
                            convertLabel={convertLabel}

                            sourceLanguageLabel={sourceLanguageLabel}
                            sourceLanguagePrompt={sourceLanguagePrompt}
                            sourceLanguageErrMsg={sourceLanguageErrMsg}

                            targetLanguageLabel={targetLanguageLabel}
                            targetLanguagePrompt={targetLanguagePrompt}
                            targetLanguageErrMsg={targetLanguageErrMsg}

                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            moreLabel={moreLabel}
                            
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showJobListFlag={showJobListFlag}
                            jobRecords={jobRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noJobRecordFoundFlag={noJobRecordFoundFlag}
                            createLiveEventAction={createLiveEventAction}
                            showJobDetailsFormFlag={showJobDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}
                            jobBannerUrl={jobBannerUrl}
                            jobCategoryErrFlag={jobCategoryErrFlag}
                            jobCategory={jobCategory}
                            categoryOptions={categoryOptions}
                            handleJobCategoryChange={handleJobCategoryChange}
                            jobBannerUrlErrFlag={jobBannerUrlErrFlag}
                            handleChange={handleChange}
                            selectImageAction={selectImageAction}
                            yourNamesErrFlag={yourNamesErrFlag}
                            yourNames={yourNames}
                            jobTitleErrFlag={jobTitleErrFlag}
                            jobTitle={jobTitle}
                            jobStartTimeErrFlag={jobStartTimeErrFlag}
                            jobStartTime={jobStartTime}
                            handleJobStartTimeChange={handleJobStartTimeChange}
                            jobTagsErrFlag={jobTagsErrFlag}
                            jobTags={jobTags}
                            textMaxLength={textMaxLength}
                            jobContentErrFlag={jobContentErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            jobContent={jobContent}
                            handleEditorHtmlBodyChange={handleEditorHtmlBodyChange}
                            submitButtonFlag={submitButtonFlag}
                            jobService={jobService}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            closeConvertForm={closeConvertForm}

                            LanguageAndDomain={LanguageAndDomain}
                            globalErrorMsg={globalErrorMsg}
                        />
                    }

                    {isMobileView && 
                        <PostJobsMv
                            manageJobsLabel={manageJobsLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            jobBannerUrlLabel={jobBannerUrlLabel}
                            jobBannerUrlPrompt={jobBannerUrlPrompt}
                            jobBannerUrlErrMsg={jobBannerUrlErrMsg}
                                
                            yourNamesLabel={yourNamesLabel}
                            yourNamesPrompt={yourNamesPrompt}
                            yourNamesErrMsg={yourNamesErrMsg}
                            yourNamesMsg={yourNamesMsg}
                            
                            jobTitleLabel={jobTitleLabel}
                            jobTitlePrompt={jobTitlePrompt}
                            jobTitleErrMsg={jobTitleErrMsg}
                            selectTitlePrompt={selectTitlePrompt}
                    
                            jobTagsLabel={jobTagsLabel}
                            jobTagsPrompt={jobTagsPrompt}
                            jobTagsErrMsg={jobTagsErrMsg}
                        
                            jobStartTimeLabel={jobStartTimeLabel}
                            jobStartTimePrompt={jobStartTimePrompt}
                            jobStartTimeErrMsg={jobStartTimeErrMsg}
                    
                            jobCategoryLabel={jobCategoryLabel}
                            jobCategoryPrompt={jobCategoryPrompt}
                            jobCategoryErrMsg={jobCategoryErrMsg}
                    
                            // uploadJobTitleLabel={uploadJobTitleLabel}
                            // uploadJobTitlePrompt={uploadJobTitlePrompt}
                            // uploadJobTitleErrMsg={uploadJobTitleErrMsg}
                    
                            // jobRecordsLabel={jobRecordsLabel}
                            // inProgressLabel={inProgressLabel}
                            // startLabel={startLabel}
                            // endLabel={endLabel}
                            // notStartedLabel={notStartedLabel}
                            // dateCreatedLabel={dateCreatedLabel}
                        
                            jobContentLabel={jobContentLabel}
                            jobContentPrompt={jobContentPrompt}
                            yourTimeZoneMsg={yourTimeZoneMsg}
                            cloneRecordLabel={cloneRecordLabel}

                            // timeZoneLabel={timeZoneLabel}
                            // toLabel={toLabel}
                            // ofLabel={ofLabel}
                            // recordsLabel={recordsLabel}
                            // noRecordsFound={noRecordsFound}
                            // statusLabel={statusLabel}
                            // editLabel={editLabel}
                            // deleteLabel={deleteLabel}
                    
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            cancelLabel={cancelLabel}
                            clearLabel={clearLabel}
                            okLabel={okLabel}
                            uploadLabel={uploadLabel}
                            createJobContentLabel={createJobContentLabel}
                            updateJobContentLabel={updateJobContentLabel}
                            // jobRecordLabel={jobRecordLabel}
                            createMoreLabel={createMoreLabel}
                            createLabel={createLabel}
                            closeLabel={closeLabel}
                            noRecordsFound={noRecordsFound} 
                            // startEventInfoMsg={startEventInfoMsg}
                    
                            gridTitle={gridTitle}
                            gridNames={gridNames}
                            // gridEventUrl={gridEventUrl}
                            // gridEventUrlType={gridEventUrlType}
                            gridStartTime={gridStartTime}
                            gridBannerUrl={gridBannerUrl}
                        
                            convertLabel={convertLabel}

                            sourceLanguageLabel={sourceLanguageLabel}
                            sourceLanguagePrompt={sourceLanguagePrompt}
                            sourceLanguageErrMsg={sourceLanguageErrMsg}

                            targetLanguageLabel={targetLanguageLabel}
                            targetLanguagePrompt={targetLanguagePrompt}
                            targetLanguageErrMsg={targetLanguageErrMsg}

                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            moreLabel={moreLabel}
    
                            domainLabel={domainLabel}
                            domainPrompt={domainPrompt}
                            domainErrMsg={domainErrMsg}
                            domainErrFlag={domainErrFlag}
                            domain={domain}
                            domainOptions={domainOptions}
                            handleDomainChange={handleDomainChange}
                                                                    
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showJobListFlag={showJobListFlag}
                            jobRecords={jobRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noJobRecordFoundFlag={noJobRecordFoundFlag}
                            createLiveEventAction={createLiveEventAction}
                            showJobDetailsFormFlag={showJobDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}
                            jobBannerUrl={jobBannerUrl}
                            jobCategoryErrFlag={jobCategoryErrFlag}
                            jobCategory={jobCategory}
                            categoryOptions={categoryOptions}
                            handleJobCategoryChange={handleJobCategoryChange}
                            jobBannerUrlErrFlag={jobBannerUrlErrFlag}
                            handleChange={handleChange}
                            selectImageAction={selectImageAction}
                            yourNamesErrFlag={yourNamesErrFlag}
                            yourNames={yourNames}
                            jobTitleErrFlag={jobTitleErrFlag}
                            jobTitle={jobTitle}
                            jobStartTimeErrFlag={jobStartTimeErrFlag}
                            jobStartTime={jobStartTime}
                            handleJobStartTimeChange={handleJobStartTimeChange}
                            jobTagsErrFlag={jobTagsErrFlag}
                            jobTags={jobTags}
                            textMaxLength={textMaxLength}
                            jobContentErrFlag={jobContentErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            jobContent={jobContent}
                            handleEditorHtmlBodyChange={handleEditorHtmlBodyChange}
                            submitButtonFlag={submitButtonFlag}
                            jobService={jobService}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            closeConvertForm={closeConvertForm}

                            globalErrorMsg={globalErrorMsg}
                        />
                    }
                </>
            }

            <Dialog 
                id="modalDlgId"
                rtl={appContext.dirction === 'rtl'}
                showHeader={true}
                header={dialogHeader}
                visible={dlgVisibleFlag} 
                maximizable={true}
                maximized={(isTabletView || isMobileView) ? true : false}
                modal={true} 
                onHide={hideModalDlg}
                >
                <Container component="div" className={classes.modalDlgMain} maxWidth="md">
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="flex-start" style={{color: '#000'}}>
                            <FroalaEditorView model={dialogData}/>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>

            <CommonMediaSelector
                referrer={"PostJobs"}
                mediaLanguageCode={language ? language.languageCode : null}
                mediaDomainName={domain ? domain.value : null}
                mediaActionFlag={showJobDetailsFormFlag}
                queryType={"media"} // media, audios or videos
                
                dialogOpenFlag={dialogOpenFlag}
                onRowSelectedCallback={onRowSelectedCallback}
                dialogCloseCallback={dialogCloseCallback}
            />

            {alertOpen && 
                <AlertDialog
                    alertOpen={alertOpen}
                    alertClose={alertClose}
                    alertClickAgree={alertClickAgree}
                    alertClickDisagree={alertClickDisagree}

                    dialogSubContent={dialogSubContent}
                />
            }

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={8000}
                onClose={snackbarOnClose}
                ContentProps={{ "aria-describedby": "message-id" }}
                message={<span id="message-id">{snackbarsMsg}</span>}
                variant={snackbarsVariant}
            />

            <footer className={classes.footer}>
                <div>
                    <PageFooter isSignedIn={appContext.signedIn} />
                </div>
            </footer>
        </div>
    )
}

export default PostJobs;
