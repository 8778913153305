import React, { useState, useEffect } from "react";

// Toast Image Editor
// import 'tui-image-editor/dist/tui-image-editor.css';
// import ImageEditor from '@toast-ui/react-image-editor';
import FilerobotImageEditor from "filerobot-image-editor";

const ImagePhotoEditor = (props) => {
  const {
    imageEditorRef,
    selectedMediaUrl,
    currentLanguageCode,
    imageEditorConfig,

    imageEditorFlag,
    imageEditorClose,

    onBeforeComplete,
    onComplete,

    // imageEditorRef,
    // imageEditorOptions,
    // selectedImage,
  } = props;

  useEffect(() => {
    console.log(
      "In useEffect(): currentLanguageCode is: ",
      currentLanguageCode
    );
  }, []);

  //--------------------------------
  // Toast Image Editor
  //--------------------------------

  const imageTheme = {
    // Theme object to extends default dark theme.
  };

  return (
    <div>
      {
        <FilerobotImageEditor
          ref={imageEditorRef}
          show={imageEditorFlag}
          src={selectedMediaUrl}
          onClose={imageEditorClose}
          onBeforeComplete={onBeforeComplete}
          onComplete={onComplete}
          config={imageEditorConfig}
        />

        // <ImageEditor
        //     ref={imageEditorRef}

        //     includeUI={{
        //         loadImage: {
        //             path: selectedImage,
        //             name: 'SampleImage'
        //         },
        //         theme: imageTheme,
        //         menu: ['shape', 'filter'],
        //         initMenu: 'filter',
        //         uiSize: {
        //         width: '1000px',
        //         height: '700px'
        //         },
        //         menuBarPosition: 'bottom'
        //     }}
        //     cssMaxHeight={500}
        //     cssMaxWidth={700}
        //     selectionStyle={{
        //         cornerSize: 20,
        //         rotatingPointOffset: 70
        //     }}
        //     usageStatistics={true}
        // />
      }
    </div>
  );
};

export default ImagePhotoEditor;
