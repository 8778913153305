import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// import IconButton from '@material-ui/core/IconButton';
import CssBaseline from "@material-ui/core/CssBaseline";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
// import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
// import ImageSearchIcon from '@material-ui/icons/ImageSearch';
// import TextField from '@material-ui/core/TextField';
// import Modal from '@material-ui/core/Modal';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Link from '@material-ui/core/Link';
// import Select from '@material-ui/core/Select';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import Select from 'react-select';

// Phone
// import ReactPhoneInput from 'react-phone-input-2';
// import "react-phone-input-2/lib/style.css";

// Draggable Modal
// import Draggable from 'react-draggable';
// import TextField from "@material-ui/core/TextField";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";

// Validator
// import validator from 'validator';
// import isEmail from 'validator/lib/isEmail';
import isURL from "validator/lib/isURL";
// import contains from 'validator/lib/contains';

// AG-Grid
// import { AgGridReact, AgGridColumn } from 'ag-grid-react';
//import "ag-grid-enterprise";
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';

// Amplify
import Amplify, { AUTH, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// import GlobalConstants from '../../utils/GlobalConstants';
// import GeneratePassword from '../../utils/GeneratePassword';
// import GenerateClientId from '../../utils/GenerateClientId';
// import GenerateTransactionId from '../../utils/GenerateTransactionId';
// import GenerateRandomHash from '../../utils/GenerateRandomHash';
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import LineSpacing from "../../components/misc/LineSpacing";

// Cache Utilities
// import SetCacheItem from '../../utils/cache/SetCacheItem';
// import GetCacheItem from '../../utils/cache/GetCacheItem';

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// Renderers
// import NumberCellRenderer from '../../components/renderers/NumberCellRenderer';
// import ActionsCellRenderer from '../../components/renderers/ActionsCellRenderer';
// import { create } from 'jss';
// import CurrencyCellRenderer from '../../components/renderers/CurrencyCellRenderer';
// import CountryCellRenderer from '../../components/renderers/CountryCellRenderer';
// import ImageCellRenderer from '../../components/renderers/ImageCellRenderer';

// import SocialsDraggableDialog from '../../components/dialogs/SocialsDraggableDialog';
import AlertDialog from "../../components/dialogs/AlertDialog";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "#fff",
  },
  main: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  mainForm: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  mainGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    backgroundColor: "#fff",
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
    textAlign: "center !important",
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  phone: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "row",
  },
  browseIcon: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  // modalPaper: {
  //   width: 400,
  //   position: 'absolute',
  //   left: '50%',
  //   top: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   backgroundColor: theme.palette.background.paper,
  //   // border: '2px solid #000',
  //   boxShadow: '0 0 30px rgba(0, 0, 0, 0.2)',
  //   // boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 4),
  //   zIndex: 999,
  // },
}));

let createSocNetworks = {
  addappsInfo: "Add Apps information",
  allFieldsAreRequired: "All fields are required",

  languageNameLabel: "Language",
  selectLanguage: "Please select a language",

  googlePlayUrlLabel: "Google Play URL",
  googlePlayUrlPrompt: "Please enter Google Play URL",

  appStoreUrlLabel: "App Store URL",
  appStoreUrlPrompt: "Please enter App Store URL",
  enterValidUrl: "Please enter a valid URL (start with https://)",

  submit: "Submit",
  submitAppsInfoSuccessMsg: "Adding Apps information was successful.",
  submitAppsInfoFailureMsg: "Error while adding Apps information.",

  // For Alert
  appsInfoAlertTitle: "Delete existing record warning",
  appsInfoAlertDetails:
    "Submitting this form will over write (delete) the existing record on file for your selected language.",
  appsInfoAlertMakeSureMsg:
    "Please make sure it is what you really want to do.",
  likeToContinue: "Would you like to continue?",
  yesLabel: "Yes",
  noLabel: "No",
};

function AppsInfo(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLang);
  const [googlePlayUrl, setGooglePlayUrl] = useState("");
  const [appStoreUrl, setAppStoreUrl] = useState("");

  const [createdAt, setCreatedAt] = useState(null);

  // For modal
  const [modalOpen, setModalOpen] = useState(false);

  // Draggable Modal
  // const [deltaPosition, setDeltaPosition] = useState({x: 0, y: 0});

  const [payload, setPayload] = useState({});
  const [rowData, setRowData] = useState([]);

  const [langSelectOptions, setLangSelectOptions] = useState([]);
  const [refTypeSelectOptions, setRefTypeSelectOptions] = useState([]);

  const [progressFlag, setProgressFlag] = useState(false);
  const [submitFormFlag, setSubmitFormFlag] = useState(true);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  // const [ confirmCodeFlag, setConfirmCodeFlag ] = useState(false);
  // const [ changePasswordFlag, setChangePasswordFlag ] = useState(false);
  // const [ theOneTimePasswordFlag, setTheOneTimePasswordFlag ] = useState(false);
  // const [ userNotConfirmedFlag, setUserNotConfirmedFlag ] = useState(false);
  // const [ forgotPasswordFlag, setForgotPasswordFlag ] = useState(false);

  // For Error Flags
  const [selectedLanguageErrFlag, setSelectedLanguageErrFlag] = useState(false);
  const [googlePlayUrlErrFlag, setGooglePlayUrlErrFlag] = useState(false);
  const [appStoreUrlErrFlag, setAppStoreUrlErrFlag] = useState(false);

  // Variants
  const [selectedLanguageVariant, setSelectedLanguageVariant] = useState(
    "success"
  );
  const [googlePlayUrlVariant, setGooglePlayUrlVariant] = useState("warning");
  const [appStoreUrlVariant, setAppStoreUrlVariant] = useState("warning");

  const [redirectSignUpFlag, setRedirectSignUpFlag] = useState(false);
  // const [globalErrorMsg, setGlobalErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  // For Alert
  const [alertOpen, setAlertOpen] = useState(false);

  //console.log('AmplifyConfig is: ', JSON.stringify(AmplifyConfig, null, 2));
  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const {
    addappsInfo,
    allFieldsAreRequired,

    languageNameLabel,
    selectLanguage,

    googlePlayUrlLabel,
    googlePlayUrlPrompt,

    appStoreUrlLabel,
    appStoreUrlPrompt,
    enterValidUrl,

    submit,
    submitAppsInfoSuccessMsg,
    submitAppsInfoFailureMsg,

    appsInfoAlertTitle,
    appsInfoAlertDetails,
    appsInfoAlertMakeSureMsg,
    likeToContinue,
    yesLabel,
    noLabel,
  } = createSocNetworks;

  // Fetch Apps Info
  function fetchAppsInfo(languageCode) {
    console.log("In fetchAppsInfo(): languageCode is: ", languageCode);

    setProgressFlag(true);

    initializeForm();

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "apps-info",
          ":resourceSK": languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchAppsInfo(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        console.log(
          "In fetchAppsInfo(): response length is: ",
          response.data.Items.length
        );
        console.log(
          "In fetchAppsInfo(): response is: ",
          JSON.stringify(response.data.Items, null, 2)
        );
        response.data.Items.map((item, index) => {
          setGooglePlayUrl(item.GooglePlayUrl);
          setAppStoreUrl(item.AppStoreUrl);
          setCreatedAt(item.CreatedAt);

          // let record = {
          //   id: ""+index,
          //   LanguageCode: item.LanguageCode,
          //   ResourcePK: item.ResourcePK,
          //   ResourceCode: item.ResourceCode,
          //   ResourceDesc: item.ResourceDesc,
          //   ModifiedBy: item.ModifiedBy,
          //   CreatedAt: item.CreatedAt,
          //   UpdatedAt: item.UpdatedAt,
          // };

          // arrayData.push(record);
        });

        // setGridData(arrayData);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchAppsInfo(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In useEffect(): appContext.jwtToken is: ", appContext.jwtToken);

    let selectOptions = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression: "#resourcePK = :resourcePK",
        expressionAttributeNames: { "#resourcePK": "ResourcePK" },
        expressionAttributeValues: { ":resourcePK": "languages" },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In AppsInfo(): useEffect(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        console.log(
          "In AppsInfo(): useEffect(): response length is: ",
          response.data.Items.length
        );
        // console.log('In AppsInfo(): useEffect(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            // label: item.LanguageName,
            text: item.LanguageName,
          };

          selectOptions.push(record);
        });

        setLangSelectOptions(selectOptions);

        console.log(
          "In useEffect(): selectedLanguage.value is: ",
          selectedLanguage.value
        );
        fetchAppsInfo(selectedLanguage.value);
      })
      .catch((err) => {
        console.log(
          "In AppsInfo(): useEffect(): err is: ",
          JSON.stringify(err, null, 2)
        );
      });
  }, []);

  useLayoutEffect(() => {
    // const currentLanguage = GetCacheItem("currentLanguage");
    console.log(
      "In AppsInfo(): useLayoutEffect(): currentLanguage is: ",
      currentLanguage
    );
    try {
      Amplify.configure(AmplifyConfig);
    } catch (err) {
      console.log("In AppsInfo(): useLayoutEffect(): err is: ", err);
    }

    console.log(
      "In AppsInfo(): useLayoutEffect(): appContext.signedIn is: ",
      appContext.signedIn
    );
  }, []);

  function initializeForm() {
    console.log("In initializeForm()");

    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setGooglePlayUrl("");
    setAppStoreUrl("");

    setSubmitFormFlag(true);

    setGooglePlayUrlVariant("warning");
    setAppStoreUrlVariant("warning");
  }

  function handleChange(event) {
    console.log("In handleChange(): id is: " + event.target.id);
    console.log("In handleChange(): value is: " + event.target.value);

    setGlobalErrorFlag(false);
    // setSubmitFormFlag(false);

    switch (event.target.id) {
      case "googlePlayUrlId":
        setGooglePlayUrlErrFlag(false);
        setGooglePlayUrlVariant("success");
        setGooglePlayUrl(event.target.value);

        // // Enable Submit button
        // if (
        //   event.target.value !== undefined &&
        //   event.target.value !== null &&
        //   event.target.value !== "" &&

        //   appStoreUrl !== undefined &&
        //   appStoreUrl !== null &&
        //   appStoreUrl !== ""
        // ) {
        //   setSubmitFormFlag(true);
        // }
        break;
      case "appStoreUrlId":
        setAppStoreUrlErrFlag(false);
        setAppStoreUrlVariant("success");
        setAppStoreUrl(event.target.value);

        // // Enable Submit button
        // if (
        //   event.target.value !== undefined &&
        //   event.target.value !== null &&
        //   event.target.value !== "" &&

        //   googlePlayUrl !== undefined &&
        //   googlePlayUrl !== null &&
        //   googlePlayUrl !== ""
        // ) {
        //   setSubmitFormFlag(true);
        // }
        break;
      default:
        break;
    }
  }

  function handleLanguageChange(selectedOption) {
    console.log("In handleLanguageChange(): selectedOption is", selectedOption);
    setSelectedLanguage(selectedOption);

    initializeForm();

    fetchAppsInfo(selectedOption.value);
  }

  function validateForm() {
    let formErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    // Variants
    // setGooglePlayUrlVariant('');
    // setAppStoreUrlVariant('');

    // if (googlePlayUrl === null || googlePlayUrl === "") {
    //   formErrFlag = true;
    //   setGooglePlayUrlErrFlag(true);
    // } else if (!isURL(googlePlayUrl)) {
    //   formErrFlag = true;
    //   setGooglePlayUrlErrFlag(true);
    //   setGooglePlayUrlVariant('danger');
    // }

    // if (appStoreUrl === null || appStoreUrl === "") {
    //   formErrFlag = true;
    //   setAppStoreUrlErrFlag(true);
    // } else if (!isURL(appStoreUrl)) {
    //   formErrFlag = true;
    //   setGooglePlayUrlErrFlag(true);
    //   setAppStoreUrlVariant('danger');
    // }

    let googlePlayErrFlag = false;
    let appStoreErrFlag = false;

    if (googlePlayUrl !== null && googlePlayUrl !== "") {
      if (!isURL(googlePlayUrl)) {
        googlePlayErrFlag = true;
      }
    }

    if (appStoreUrl !== null && appStoreUrl !== "") {
      if (!isURL(appStoreUrl)) {
        appStoreErrFlag = true;
      }
    }

    if (googlePlayErrFlag || appStoreErrFlag) {
      formErrFlag = true;

      if (googlePlayErrFlag) {
        setGooglePlayUrlErrFlag(true);
        setGooglePlayUrlVariant("danger");
      }

      if (appStoreErrFlag) {
        setAppStoreUrlErrFlag(true);
        setAppStoreUrlVariant("danger");
      }
    }

    return formErrFlag;
  }

  async function appsInfoService() {
    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    // const d = new Date();
    const timestamp = new Date();
    // const timestamp = d.toISOString();
    console.log("In appsInfoService(): timestamp is: ", timestamp);

    if (!validateForm()) {
      console.log("In appsInfoService(): validation passed.");

      let appsData = [];

      let appsInfo = {
        ResourcePK: "apps-info",
        ResourceSK: selectedLanguage.value + ":" + "appsInfo",
        LanguageCode: selectedLanguage.value,

        // "GooglePlayUrl": googlePlayUrl,
        // "AppStoreUrl": appStoreUrl,

        ModifiedBy: appContext.userName,
        CreatedAt: createdAt ? createdAt : timestamp,
        UpdatedAt: timestamp,
      };

      if (googlePlayUrl !== "") {
        appsInfo.GooglePlayUrl = googlePlayUrl;
      }

      if (appStoreUrl !== "") {
        appsInfo.AppStoreUrl = appStoreUrl;
      }

      appsData.push(appsInfo);

      let params = {
        apiName: "ResourceAPI",
        path: "/dynamodb/resources/batchWrite",
        payload: {
          items: appsData,
        },
      };

      let initObj = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        response: true, // return entire response object instead of response.data
        body: {},
      };

      initObj.headers.Authorization = appContext.jwtToken;
      initObj.body = params.payload;
      console.log(
        "In appsInfoService(): initObj is: ",
        JSON.stringify(initObj, null, 2)
      );

      setProgressFlag(true);

      API.post(params.apiName, params.path, initObj)
        .then((response) => {
          console.log(
            "In appsInfoService(): response is: ",
            JSON.stringify(response, null, 2)
          );

          // Show Snackbar
          setSnackbarsVariant("success");
          setSnackbarsMsg(submitAppsInfoSuccessMsg);
          setSnackbarOpen(true);

          setProgressFlag(false);
          initializeForm();
        })
        .catch((err) => {
          console.log(
            "In appsInfoService(): err is: ",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setGlobalErrorMsg(submitAppsInfoFailureMsg);

          setProgressFlag(false);
        });
    }
  }

  function snackbarOnClose(event, reason) {
    console.log("In snackbarOnClose(): event is", event);
    setSnackbarOpen(false);
  }

  //******************* */
  // Alert
  //******************* */

  function showAlertDialog() {
    console.log("In showAlertDialog()");
    if (googlePlayUrl === "" && appStoreUrl === "") {
      setAlertOpen(true);
    } else {
      appsInfoService();
    }
  }

  function alertClickAgree() {
    console.log("In alertClickAgree()");
    // Close Alert
    setAlertOpen(false);

    appsInfoService();
  }

  function alertClickDisagree() {
    console.log("In alertClickDisagree()");
    // Close Alert
    setAlertOpen(false);

    return false;
  }

  function alertClose() {
    console.log("In alertClose()");
    // Close Alert
    setAlertOpen(false);
  }

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { !appContext.signedIn &&
            <Redirect to='/signIn' />
        } */}

        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div
            className="ag-theme-balham"
            style={{ width: "100%", height: "100%" }}
          >
            {progressFlag && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                  size={15}
                />
              </div>
            )}
            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="md"
            >
              <Grid container justify="center">
                <StyledPaper elevation={3} className={classes.paper}>
                  <Typography
                    variant="h6"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    {addappsInfo}
                  </Typography>

                  <Typography
                    variant="caption"
                    style={{ color: "green", textAlign: "center" }}
                  >
                    {allFieldsAreRequired}
                  </Typography>

                  {globalErrorFlag && (
                    <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                  )}

                  <LineSpacing value={12} />

                  <FormControl className={classes.formControl}>
                    <label
                      htmlFor="formLanguageId"
                      className={classes.verticalAlignLabel}
                      style={directionAlignment}
                    >
                      <Typography variant="subtitle1">
                        {languageNameLabel}
                      </Typography>
                    </label>
                    <Select
                      id="formLanguageId"
                      value={selectedLanguage}
                      placeholder={selectLanguage}
                      onChange={handleLanguageChange}
                      variant="outlined"
                      displayEmpty={true}
                      autoFocus
                    >
                      <MenuItem value={null} disabled>
                        <Typography variant="body1">
                          {selectLanguage}
                        </Typography>
                      </MenuItem>
                      {langSelectOptions.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Mineral-ui 

                  <FormField label={languageNameLabel} variant={selectedLanguageVariant}>
                    <Select 
                      data={langSelectOptions} 
                      name="state" 
                      defaultSelectedItem={langSelectOptions[5]} 
                      selectedItem={selectedLanguage}
                      placeholder={selectLanguage}
                      onChange={handleLanguageChange}
                      disabled={false}
                      readOnly={false} 
                      size={"medium"}
                      style={{textAlign: 'left'}} 
                    />
                  </FormField>*/}

                  <LineSpacing value={12} />

                  <FormControl className={classes.textField} variant="outlined">
                    <label htmlFor="googlePlayUrlId" style={directionAlignment}>
                      <Typography variant="subtitle1">
                        {googlePlayUrlLabel}
                      </Typography>
                    </label>
                    <OutlinedInput
                      id="googlePlayUrlId"
                      value={googlePlayUrl}
                      placeholder={googlePlayUrlPrompt}
                      onChange={handleChange}
                      required
                      fullWidth
                      // autoFocus
                      type={"text"}
                      error={googlePlayUrlErrFlag}
                      margin="none"
                    />
                  </FormControl>

                  {/* <FormField label={googlePlayUrlLabel} caption={enterValidUrl} variant={googlePlayUrlVariant}>
                    <TextInput 
                      id="googlePlayUrlId"
                      value={googlePlayUrl}
                      onChange={handleChange} 
                      placeholder={googlePlayUrlPrompt}
                      disabled={false}
                      readOnly={false} 
                      size={"medium"}
                    />
                  </FormField> */}

                  <LineSpacing value={12} />

                  <FormControl className={classes.textField} variant="outlined">
                    <label htmlFor="appStoreUrlId" style={directionAlignment}>
                      <Typography variant="subtitle1">
                        {appStoreUrlLabel}
                      </Typography>
                    </label>
                    <OutlinedInput
                      id="appStoreUrlId"
                      value={appStoreUrl}
                      placeholder={appStoreUrlPrompt}
                      onChange={handleChange}
                      required
                      fullWidth
                      // autoFocus
                      type={"text"}
                      error={appStoreUrlErrFlag}
                      margin="none"
                    />
                  </FormControl>

                  {/* <FormField label={appStoreUrlLabel} caption={enterValidUrl} variant={appStoreUrlVariant}>
                    <TextInput
                      id="appStoreUrlId"
                      value={appStoreUrl}
                      onChange={handleChange} 
                      placeholder={appStoreUrlPrompt}
                      disabled={false}
                      readOnly={false} 
                      size={"medium"}
                    />
                  </FormField> */}

                  <LineSpacing value={8} />

                  <Button
                    variant="contained"
                    size="large"
                    disabled={!submitFormFlag ? true : false}
                    color="primary"
                    className={classes.button}
                    onClick={showAlertDialog}
                  >
                    <SaveIcon
                      className={clsx(classes.leftIcon, classes.iconSmall)}
                    />
                    {submit}
                  </Button>

                  {/* { !submitFormFlag &&
                    <Button variant="contained" size="small" disabled color="secondary" className={classes.button}>
                      <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
                      {submit}
                    </Button>
                  }

                  { submitFormFlag &&
                    <Button variant="contained" size="small" color="primary" className={classes.button} onClick={showAlertDialog}>
                      <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
                      {submit}
                    </Button>
                  } */}
                </StyledPaper>
              </Grid>
            </Container>
          </div>

          {alertOpen && (
            <AlertDialog
              alertOpen={alertOpen}
              alertClose={alertClose}
              alertClickAgree={alertClickAgree}
              alertClickDisagree={alertClickDisagree}
              dialogTitle={appsInfoAlertTitle}
              dialogMainContent={appsInfoAlertDetails}
              dialogSubContent={appsInfoAlertMakeSureMsg}
              dialogQuestion={likeToContinue}
              yesLabel={yesLabel}
              noLabel={noLabel}
            />
          )}

          {/* variant: 'success', 'warning', 'error', 'info' */}
          <TsiCustomizedSnackbars
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={8000}
            onClose={snackbarOnClose}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">{snackbarsMsg}</span>}
            variant={snackbarsVariant}
          />
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter isSignedIn={isSignedIn} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default AppsInfo;
