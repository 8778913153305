import React from "react";
import Webcam from "react-webcam";

function TsiWebcam(props) {
  const { audio, webcamRef, videoConstraints, screenshotQuality } = props;

  return (
    <div>
      <Webcam
        audio={audio}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        screenshotQuality={screenshotQuality}
      />
    </div>
  );
}

export default TsiWebcam;
