import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
// import SaveIcon from "@material-ui/icons/Save";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from "@material-ui/core/Container";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import InputAdornment from "@material-ui/core/InputAdornment";
import TranslateIcon from '@material-ui/icons/Translate';
import Paper from "@material-ui/core/Paper";

// react-responsive
import { useMediaQuery } from 'react-responsive';

// moment
import moment from "moment";

// Lodash
import lodash from "lodash";

// react-media
// import Media from "react-media";

// Validator
// import validator from 'validator';
// import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";
// import isMobilePhone from "validator/lib/isMobilePhone";

//---------------------
// For Primereact
//---------------------

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

// For Primereact
import { Dialog } from 'primereact/dialog';

// Froala
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Amplify
import { API } from "aws-amplify";
// import { AmplifyTheme   } from 'aws-amplify-react';

// AppContext
import AppContext from "../../config/AppContext";

import LineSpacing from "../../components/misc/LineSpacing";
import FetchS3Object from "../../components/media/FetchS3Object";
import FroalaCommonEditor from "../../components/editor/FroalaCommonEditor";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import AlertDialog from "../../components/dialogs/AlertDialog";
import CommonMediaSelector from "../../components/dialogs/CommonMediaSelector";
import DomainUploadCapture from "../../components/upload/DomainUploadCapture";
import CommonConverter from "../../components/convert/CommonConverter";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GenerateUniqueName from "../../utils/GenerateUniqueName";
import GenerateUlid from "../../utils/GenerateUlid";
// import FormatDateTime from "../../../../../utils/moment/FormatDateTime";
import FormatTimezoneDates from "../../utils/moment/FormatTimezoneDates";
import FilterArrayFromArray from "../../utils/filter/FilterArrayFromArray";
import FormatNumber from "../../utils/numberFormat/FormatNumber";
import EditorPixelTransform from "../../utils/transform/EditorPixelTransform";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// Views
import PostNewsTv from "./views/PostNewsTv";
import PostNewsMv from "./views/PostNewsMv";

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    leftPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "left",
    },
    leftPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1rem" /* Footer height */,

        width: "100% !important",
        marginTop: "auto",
        // backgroundColor: '#fff',
        textAlign: "center",
    },
    paper: {
        minWidth: "300px",
        marginTop: theme.spacing(2),
        alignItems: "center",
        padding: "10px 30px 10px 30px !important",
        textAlign: "center",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    divPadding: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

let pageLabels = {
    manageNewsLabel: "Manage news postings",
    requiredFields: "* Required fields",

    languageLabel: "Language",
    languagePrompt: "Select language",
    languageErrMsg: "Language is required",

    domainLabel: "Domain",
    domainPrompt: "Select a domain",
    domainErrMsg: "Domain is required",

    newsBannerUrlLabel: "News banner URL",
    newsBannerUrlPrompt: "Select news banner",
    newsBannerUrlErrMsg: "News banner is required",

    yourNamesLabel: "Your names",
    yourNamesPrompt: "Enter your full names",
    yourNamesErrMsg: "Your names are required",
    yourNamesMsg: "Names entered will appear in the news",
    
    newsTitleLabel: "News title",
    newsTitlePrompt: "Please enter a title for your news",
    newsTitleErrMsg: "News title is required",
    selectTitlePrompt: "Select title",

    newsTagsLabel: "News tags",
    newsTagsPrompt: "Enter news tags separated by space",
    newsTagsErrMsg: "News tags are required",
    
    newsStartTimeLabel: "Publish start time",
    newsStartTimePrompt: "Select date & time to publish the news",
    newsStartTimeErrMsg: "Publish start time is invalid",

    newsCategoryLabel: "News category",
    newsCategoryPrompt: "Select a news category",
    newsCategoryErrMsg: "News category is required",

    // uploadNewsTitleLabel: "News title",
    // uploadNewsTitlePrompt: "Please select news title",
    // uploadNewsTitleErrMsg: "News title is required",

    // liveEventUrlLabel: "Live event URL",
    // liveEventUrlPrompt: "Please enter the live event URL",
    // liveEventUrlErrMsg: "Live event URL is invalid",

    // liveEventUrlTypeLabel: "Live event URL type",
    // liveEventUrlTypePrompt: "Select live event URL type",
    // liveEventUrlTypeErrMsg: "Live event URL type is required",

    newsContentLabel: "News content",
    newsContentPrompt: "Please enter your news content",
    // newsContentErrMsg: "Live event description is required",

    yourTimeZoneMsg: "Your timeZone is",
    // timeZoneLabel: "Time zone",
    cloneRecordLabel: "Clone record",

    newsRecordLabel: "news record",
    newsRecordsLabel: "news records",
    // inProgressLabel: "In progress",
    startLabel: "Start",
    endLabel: "End",
    notStartedLabel: "Not started",
    // dateCreatedLabel: "Date created",

    // statusLabel: "Status",
    editLabel: "Edit",
    deleteLabel: "Delete",

    toLabel: "to",
    ofLabel: "of",
    recordsLabel: "records",
    noRecordsFound: "No records found",

    operationSuccessMsg: "Operation was successful.",
    operationFailureMsg: "Operation failed.",
    duplicateTransMsg: "Duplicate record",

    loadingText: "Loading ...",
    noOptionsText: "No options",
    clearText: "Clear",
    closeText: "Close",
    // showLabel: "Show",
    // hideLabel: "Hide",

    cancelLabel: "Cancel",
    clearLabel: "Clear",
    okLabel: "Ok",
  
    createNewsContentLabel: "Create news content",
    updateNewsContentLabel: "Update news content",

    createMoreLabel: "Create more",
    createLabel: "Create",
    // editEventDestLabel: "Edit email template",
    closeLabel: "Close",
    noRecordsFound: "No records found.", 
    startEventInfoMsg: "This event can't be started now.",    
    // createNewsContentLabel: "Create live event",
        
    gridNames: "Names",
    gridTitle: "Title",
    gridEventUrl: "Event URL",
    gridEventUrlType: "URL type",
    gridStartTime: "Start time",
    gridBannerUrl: "Banner URL",
    
    convertLabel: "Convert",

    sourceLanguageLabel: "Source language",
    sourceLanguagePrompt: "Select source language",
    sourceLanguageErrMsg: "Source language is required",

    targetLanguageLabel: "Target language",
    targetLanguagePrompt: "Select target language",
    targetLanguageErrMsg: "Target language is required",

    moreLabel: "- more -",

    uploadLabel: "Upload",
};

function PostNews() {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);
    const [renderDomFlag, setRenderDomFlag] = useState(false);
    const [progressFlag, setProgressFlag] = useState(false);

    // For Global Errors
    const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");

    // For Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarsMsg, setSnackbarsMsg] = useState("");
    const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    //-------------------
    // Table Names
    //-------------------
    const tableName = "news";

    //--------------------
    // For Froala Editor
    //--------------------
    const editorRef = useRef(null);
    // const [editorModelData, setEditorModelData] = useState(null);
    const [editorMaxChars, setEditorMaxChars] = useState(10000);// Max chars allowed in editor

    //-------------------------
    // For Language and Domain
    //-------------------------
    const [language, setLanguage] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [languageErrFlag, setLanguageErrFlag] = useState(false);

    const [domain, setDomain] = useState(null);
    const [domainOptions, setDomainOptions] = useState([]);
    const [domainErrFlag, setDomainErrFlag] = useState(false);

    //--------------------
    // For Form Attributes
    //--------------------
    const [profilePhotoSrc, setProfilePhotoSrc] = useState(null);

    const [yourNames, setYourNames] = useState("");
    const [authorPhotoSrc, setAuthorPhotoSrc] = useState("");
    const [newsTitle, setNewsTitle] = useState("");
    const [newsTags, setNewsTags] = useState("");
    const [newsStartTime, setNewsStartTime] = useState(null);
    const [newsCategory, setNewsCategory] = useState(null);
    // const [liveEventUrl, setLiveEventUrl] = useState("");
    // const [liveEventUrlType, setLiveEventUrlType] = useState(null);
    const [newsContent, setNewsContent] = useState("");
    const [newsBannerUrl, setNewsBannerUrl] = useState("");
    
    // const [eventThumbnailUrl, setEventThumbnailUrl] = useState("");
    // const [eventBannerUrl, setEventBannerUrl] = useState("");
    // const [eventPosterUrl, setEventPosterUrl] = useState("");
    // const [eventAudioUrl, setEventAudioUrl] = useState("");
    
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
         
    const [newsBannerUrlErrFlag, setNewsBannerUrlErrFlag] = useState(false);
    const [yourNamesErrFlag, setYourNamesErrFlag] = useState(false);
    const [newsTitleErrFlag, setNewsTitleErrFlag] = useState(false);
    const [newsTagsErrFlag, setNewsTagsErrFlag] = useState(false);
    const [newsStartTimeErrFlag, setNewsStartTimeErrFlag] = useState(false);
    const [newsCategoryErrFlag, setNewsCategoryErrFlag] = useState(false);
    // const [liveEventUrlErrFlag, setLiveEventUrlErrFlag] = useState(false);
    // const [liveEventUrlTypeErrFlag, setLiveEventUrlTypeErrFlag] = useState(false);
    const [newsContentErrFlag, setNewsContentErrFlag] = useState(false);

    // const [liveEventUrlTypeOptions, setLiveEventUrlTypeOptions] = useState([]);
    // const [newsTagsOptions, setNewsTagsOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    
    const [newsRecordsBackup, setNewsRecordsBackup] = useState([]);
    const [newsRecords, setNewsRecords] = useState([]);

    const [submitButtonFlag, setSubmitButtonFlag] = useState(false);
    const [addTagButtonFlag, setAddTagButtonFlag] = useState(false);
    const [editModeFlag, setEditModeFlag] = useState(false);

    const [datePickerLanguage, setDatePickerLanguage] = useState(appContext.currentLanguage.languageCode);
    
    
    const [showNewsListFlag, setShowNewsListFlag] = useState(true);
    const [showNewsDetailsFormFlag, setShowNewsDetailsFormFlag] = useState(false);
    const [uploadFlag, setUploadFlag] = useState(false);
    const [convertFlag, setConvertFlag] = useState(false);
       
    const [pageSize, setPageSize] = useState(10000);// AWS put PageSize to a max 0f 100

    const [noNewsRecordFoundFlag, setNoNewsRecordFoundFlag] = useState(false);
    const [eventStartedFlag, setEventStartedFlag] = useState(false);
    const [startedEventId, setStartedEventId] = useState(null);
    
    const [textMaxLength, setTextMaxLength] = useState(256);

    //--------------------------
    // For sliceText and Dialog
    //--------------------------
    const [dialogData, setDialogData] = useState("");
    const [dialogHeader, setDialogHeader] = useState("");
    
    // const [terminologyNames, setTerminologyNames] = useState([
    //     "translate_custom_terminology.txt"
    // ]);
    

    //-------------------------
    // For Primereact 
    // DataTable Attributes 
    //-------------------------
    // const [ dataTableInfo, setDataTableInfo] = useState([]);
    // const [dataTableInfoFlag, setDataTableInfoFlag] = useState(false);
    
    // const [resizableColumnsFlag, setResizableColumnsFlag] = useState(true);
    // const [columnResizeMode, setColumnResizeMode] = useState("fit");
    // const [sortMode, setSortMode] = useState("multiple");
    // const [responsiveFlag, setResponsiveFlag] = useState(true);
    // const [reorderableColumnsFlag, setReorderableColumnsFlag] = useState(true);
    // const [paginatorFlag, setPaginatorFlag] = useState(true);
    const [paginatorTemplate, setPaginatorTemplate] = useState("FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown");
    // const [currentPageReportTemplate, setCurrentPageReportTemplate] = useState(null);
    const [visibleRowsCount, setVisibleRowsCount] = useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5,10,20,50,100]);

    // const [selectionMode, setSelectionMode] = useState("multiple");// single or multiple
    // const [selectedTableRow, setSelectedTableRow] = useState(null);// For single selection
    const [selectedTableRows, setSelectedTableRows] = useState([]);// For multiple selection
    // const [updatedTableRows, setUpdatedTableRows] = useState([]); // For updated (edited) table rows
    // const [deletedTableRows, setDeletedTableRows] = useState([]); // For deleted table rows
    const [tableDirtyFlag, setTableDirtyFlag] = useState(false); // To show DataTable cell was edited (modified)

    // const [deleteRowFlag, setDeleteRowFlag] = useState(false);

    //--------------------
    // For AlertDialog
    //--------------------
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogSubContent, setDialogSubContent] = useState(null);

    const [recordToDelete, setRecordToDelete] = useState(null);
    const [recordToEdit, setRecordToEdit] = useState(null);
    
    //-----------------------
    // For Primereact Dialog
    //-----------------------
    const [dlgVisibleFlag, setDlgVisibleFlag] = useState(false);
    
    // const {
    //     // editorId,
    // } = props;

    const theme = useTheme();
  
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    const { 
        manageNewsLabel, 
        requiredFields,

        languageLabel,
        languagePrompt,
        languageErrMsg,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,
    
        newsBannerUrlLabel,
        newsBannerUrlPrompt,
        newsBannerUrlErrMsg,
            
        yourNamesLabel,
        yourNamesPrompt,
        yourNamesErrMsg,
        yourNamesMsg,
        
        newsTitleLabel,
        newsTitlePrompt,
        newsTitleErrMsg,
        selectTitlePrompt,

        newsTagsLabel,
        newsTagsPrompt,
        newsTagsErrMsg,
    
        newsStartTimeLabel,
        newsStartTimePrompt,
        newsStartTimeErrMsg,

        newsCategoryLabel,
        newsCategoryPrompt,
        newsCategoryErrMsg,

        // uploadNewsTitleLabel,
        // uploadNewsTitlePrompt,
        // uploadNewsTitleErrMsg,

        // liveEventUrlLabel,
        // liveEventUrlPrompt,
        // liveEventUrlErrMsg,

        // liveEventUrlTypeLabel,
        // liveEventUrlTypePrompt,
        // liveEventUrlTypeErrMsg,

        newsRecordsLabel,
        // inProgressLabel,
        startLabel,
        endLabel,
        notStartedLabel,
    
        // dateCreatedLabel,
    
        newsContentLabel,
        newsContentPrompt,
        // newsContentErrMsg,

        yourTimeZoneMsg,
        // timeZoneLabel,
        cloneRecordLabel,
    
        toLabel,
        ofLabel,
        recordsLabel,
        // noRecordsFound,

        // statusLabel,
        editLabel,
        deleteLabel,

        operationSuccessMsg,
        operationFailureMsg,
        duplicateTransMsg,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,
        // showLabel,
        // hideLabel,

        cancelLabel,
        clearLabel,
        okLabel,

        uploadLabel,
        
        createNewsContentLabel,
        updateNewsContentLabel,

        newsRecordLabel,
        createMoreLabel,
        createLabel,
        // editEventDestLabel,
        closeLabel,
        noRecordsFound, 
        startEventInfoMsg,
        // createNewsContentLabel,

        gridTitle,
        gridNames,
        // gridEventUrl,
        // gridEventUrlType,
        gridStartTime,
        gridBannerUrl,
    
        convertLabel,

        sourceLanguageLabel,
        sourceLanguagePrompt,
        sourceLanguageErrMsg,

        targetLanguageLabel,
        targetLanguagePrompt,
        targetLanguageErrMsg,

        moreLabel,
    } = pageLabelsRes;

    async function fetchDbResources(cacheItemKey) {
        backdropProgress(true);

        let keys = [];
        let objKeys = Object.keys(pageLabels);
        // console.log("In PostNews(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
        if (objKeys !== null && objKeys.length > 0) {
            objKeys.map((key) => {
                let partitionKey = appContext.currentLanguage.languageCode;
                partitionKey += ".";
                partitionKey += GenerateModulusHash(key, 200);

                let itemKey = {
                ResourcePK: partitionKey, // e.g. 'en.91'
                ResourceSK: key, // e.g. 'firstName'
                };
                keys.push(itemKey);
            });
        }
        // console.log("In PostNews(): fetchDbResources(): keys.length is: ", keys.length);
        // console.log("In PostNews(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

        let arrayData = [];

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/batchGet",
            payload: {
                keys: keys,
                projectionExpression: "ResourceCode,ResourceDesc",
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
            response.data.Responses.resource.map((item, index) => {
            let record = {
                ResourceCode: item.ResourceCode,
                ResourceDesc: item.ResourceDesc,
            };

            arrayData.push(record);
            });
                    
            if (arrayData && arrayData.length > 0) {
                arrayData.map((item) => {
                    if (item.ResourceCode === "manageNewsLabel") pageLabels.manageNewsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "requiredFields") pageLabels.requiredFields = item.ResourceDesc;
                    
                    if (item.ResourceCode === "languageLabel") pageLabels.languageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "languagePrompt") pageLabels.languagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "languageErrMsg") pageLabels.languageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "domainLabel") pageLabels.domainLabel = item.ResourceDesc;
                    if (item.ResourceCode === "domainPrompt") pageLabels.domainPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "domainErrMsg") pageLabels.domainErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "newsBannerUrlLabel") pageLabels.newsBannerUrlLabel = item.ResourceDesc;
                    if (item.ResourceCode === "newsBannerUrlPrompt") pageLabels.newsBannerUrlPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "newsBannerUrlErrMsg") pageLabels.newsBannerUrlErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "yourNamesLabel") pageLabels.yourNamesLabel = item.ResourceDesc;
                    if (item.ResourceCode === "yourNamesPrompt") pageLabels.yourNamesPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "yourNamesErrMsg") pageLabels.yourNamesErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "yourNamesMsg") pageLabels.yourNamesMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "newsTitleLabel") pageLabels.newsTitleLabel = item.ResourceDesc;
                    if (item.ResourceCode === "newsTitlePrompt") pageLabels.newsTitlePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "newsTitleErrMsg") pageLabels.newsTitleErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "selectTitlePrompt") pageLabels.selectTitlePrompt = item.ResourceDesc;
                                
                    if (item.ResourceCode === "newsTagsLabel") pageLabels.newsTagsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "newsTagsPrompt") pageLabels.newsTagsPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "newsTagsErrMsg") pageLabels.newsTagsErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "newsStartTimeLabel") pageLabels.newsStartTimeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "newsStartTimePrompt") pageLabels.newsStartTimePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "newsStartTimeErrMsg") pageLabels.newsStartTimeErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "newsCategoryLabel") pageLabels.newsCategoryLabel = item.ResourceDesc;
                    if (item.ResourceCode === "newsCategoryPrompt") pageLabels.newsCategoryPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "newsCategoryErrMsg") pageLabels.newsCategoryErrMsg = item.ResourceDesc;
            
                    // if (item.ResourceCode === "uploadNewsTitleLabel") pageLabels.uploadNewsTitleLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "uploadNewsTitlePrompt") pageLabels.uploadNewsTitlePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "uploadNewsTitleErrMsg") pageLabels.uploadNewsTitleErrMsg = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "liveEventUrlTypeLabel") pageLabels.liveEventUrlTypeLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "liveEventUrlTypePrompt") pageLabels.liveEventUrlTypePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "liveEventUrlTypeErrMsg") pageLabels.liveEventUrlTypeErrMsg = item.ResourceDesc;
                                    
                    if (item.ResourceCode === "yourTimeZoneMsg") pageLabels.yourTimeZoneMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "timeZoneLabel") pageLabels.timeZoneLabel = item.ResourceDesc;
                    if (item.ResourceCode === "cloneRecordLabel") pageLabels.cloneRecordLabel = item.ResourceDesc;
                                
                    if (item.ResourceCode === "newsRecordsLabel") pageLabels.newsRecordsLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "inProgressLabel") pageLabels.inProgressLabel = item.ResourceDesc;
                    if (item.ResourceCode === "startLabel") pageLabels.startLabel = item.ResourceDesc;
                    if (item.ResourceCode === "endLabel") pageLabels.endLabel = item.ResourceDesc;
                    if (item.ResourceCode === "notStartedLabel") pageLabels.notStartedLabel = item.ResourceDesc;
                      
                    // if (item.ResourceCode === "dateCreatedLabel") pageLabels.dateCreatedLabel = item.ResourceDesc;
                    
                    if (item.ResourceCode === "newsContentLabel") pageLabels.newsContentLabel = item.ResourceDesc;
                    if (item.ResourceCode === "newsContentPrompt") pageLabels.newsContentPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "newsContentErrMsg") pageLabels.newsContentErrMsg = item.ResourceDesc; 

                    if (item.ResourceCode === "toLabel") pageLabels.toLabel = item.ResourceDesc;
                    if (item.ResourceCode === "ofLabel") pageLabels.ofLabel = item.ResourceDesc;
                    if (item.ResourceCode === "recordsLabel") pageLabels.recordsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noRecordsFound") pageLabels.noRecordsFound = item.ResourceDesc;

                    if (item.ResourceCode === "createLabel") pageLabels.createLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "statusLabel") pageLabels.statusLabel = item.ResourceDesc;
                    if (item.ResourceCode === "editLabel") pageLabels.editLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteLabel") pageLabels.deleteLabel = item.ResourceDesc;

                    if (item.ResourceCode === "operationSuccessMsg") pageLabels.operationSuccessMsg = item.ResourceDesc;
                    if (item.ResourceCode === "operationFailureMsg") pageLabels.operationFailureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "duplicateTransMsg") pageLabels.duplicateTransMsg = item.ResourceDesc;
                                                                        
                    if (item.ResourceCode === "loadingText") pageLabels.loadingText = item.ResourceDesc;
                    if (item.ResourceCode === "noOptionsText") pageLabels.noOptionsText = item.ResourceDesc;
                    if (item.ResourceCode === "clearText") pageLabels.clearText = item.ResourceDesc;
                    if (item.ResourceCode === "closeText") pageLabels.closeText = item.ResourceDesc;
                    // if (item.ResourceCode === "showLabel") pageLabels.showLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "hideLabel") pageLabels.hideLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "cancelLabel") pageLabels.cancelLabel = item.ResourceDesc;
                    if (item.ResourceCode === "clearLabel") pageLabels.clearLabel = item.ResourceDesc;
                    if (item.ResourceCode === "okLabel") pageLabels.okLabel = item.ResourceDesc;

                    if (item.ResourceCode === "uploadLabel") pageLabels.uploadLabel = item.ResourceDesc;
          
                    if (item.ResourceCode === "createNewsContentLabel") pageLabels.createNewsContentLabel = item.ResourceDesc;
                    if (item.ResourceCode === "updateNewsContentLabel") pageLabels.updateNewsContentLabel = item.ResourceDesc;
                              
                    if (item.ResourceCode === "newsRecordLabel") pageLabels.newsRecordLabel = item.ResourceDesc;
                    if (item.ResourceCode === "createMoreLabel") pageLabels.createMoreLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "editEventDestLabel") pageLabels.editEventDestLabel = item.ResourceDesc;
                    if (item.ResourceCode === "closeLabel") pageLabels.closeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noRecordsFound") pageLabels.noRecordsFound = item.ResourceDesc;
                    if (item.ResourceCode === "startEventInfoMsg") pageLabels.startEventInfoMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "createNewsContentLabel") pageLabels.createNewsContentLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "gridTitle") pageLabels.gridTitle = item.ResourceDesc;
                    if (item.ResourceCode === "gridEventUrl") pageLabels.gridEventUrl = item.ResourceDesc;
                    if (item.ResourceCode === "gridEventUrlType") pageLabels.gridEventUrlType = item.ResourceDesc;
                                              
                    if (item.ResourceCode === "gridStartTime") pageLabels.gridStartTime = item.ResourceDesc;
                    if (item.ResourceCode === "gridBannerUrl") pageLabels.gridBannerUrl = item.ResourceDesc;
                    if (item.ResourceCode === "gridNames") pageLabels.gridNames = item.ResourceDesc;
                                              
                    if (item.ResourceCode === "convertLabel") pageLabels.convertLabel = item.ResourceDesc;
                    
                    if (item.ResourceCode === "sourceLanguageLabel") pageLabels.sourceLanguageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "sourceLanguagePrompt") pageLabels.sourceLanguagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "sourceLanguageErrMsg") pageLabels.sourceLanguageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "targetLanguageLabel") pageLabels.targetLanguageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "targetLanguagePrompt") pageLabels.targetLanguagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "targetLanguageErrMsg") pageLabels.targetLanguageErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "moreLabel") pageLabels.moreLabel = item.ResourceDesc;
                });
                // Add it to cache
                SetCacheItem(cacheItemKey, pageLabels);
            }

            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setRenderDomFlag(true);
        }).catch((err) => {
            console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setRenderDomFlag(true);
        });
    }
    
    let listNextToken = null;

    async function fetchNewsRecords(language, domain) {
        // console.log('In fetchNewsRecords()');
        if (domain && language) {
            backdropProgress(true);
            setNoNewsRecordFoundFlag(false);
            setNewsRecordsBackup([]);

            let params = {
                apiName: "CommonDynamodbAPI",
                path: "/common/dynamodb/query",
                payload: {
                    tableName: tableName,
                    keyConditionExpression:
                        // "#newsPK = :newsPK",
                        "#newsPK = :newsPK AND begins_with(#newsSK, :newsSK)",
                    expressionAttributeNames: {
                        "#newsPK": "NewsPK",
                        "#newsSK": "NewsSK",
                    },
                    expressionAttributeValues: {
                        ":newsPK": domain.value + "#" + language.languageCode + "#news",
                        ":newsSK": domain.value + "#" + language.languageCode + "#news",
                    },
                    // indexName: "NewsDomain-DomainLangAuthorUserName-index"
                        // "keyConditionExpression": "#newsPK = :newsPK",
                        // "expressionAttributeNames": {"#newsPK": "NewsPK"},
                        // "expressionAttributeValues": {":newsPK": "languages"}
                },
            };
    
            if (!lodash.isEmpty(listNextToken)) {
                params.payload.nextToken = listNextToken;
            }
    
            if (!lodash.isEmpty(pageSize)) {
                params.payload.pageSize = pageSize;
            }

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchNewsRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchNewsRecords(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchNewsRecords(): response.data.Items is: ', response.data.Items);
                // console.log('In fetchNewsRecords(): response.data.NextToken is: ', response.data.NextToken);

                let records = [];

                listNextToken = null;

                if (response && !lodash.isEmpty(response.data.NextToken)) {
                    listNextToken = response.data.NextToken;
                    records = newsRecords;
                }
            
                let items = response.data.Items;

                if (items && items.length > 0) {
                    setNewsRecords([]);

                    items.map((record) => {
                        // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                        // console.log('In fetchNewsRecords(): formatedDateTime is: ', formatedDateTime);
                        
                        let formattedStartTime = null;
                        if (record.StartTime) {
                            formattedStartTime = FormatTimezoneDates(record.StartTime, record.LanguageCode, record.NewsTimeZone, "shortDateAndTime");
                        }
                        // console.log('In fetchNewsRecords(): formattedStartTime is: ', formattedStartTime);

                        let editorTransform = record.NewsContent;
                        if (record.NewsContent) {
                            if (isTabletView || isMobileView) {
                                editorTransform = EditorPixelTransform(record.NewsContent, (isTabletView ? "tabletView" : "mobileView"));
                            }
                        }
                        // console.log('In fetchNewsRecords(): editorTransform is: ', editorTransform);
    
                        let item = {
                            NewsPK: record.NewsPK,
                            NewsSK: record.NewsSK,

                            LanguageObject: record.LanguageObject,
                            LanguageCode: record.LanguageCode,
                            Domain: record.Domain,

                            "NewsId": lodash.isEmpty(record.NewsId) ? "" : record.NewsId,

                            "NewsDomain": lodash.isEmpty(record.NewsDomain) ? "" : record.NewsDomain,
                            "NewsCategory": lodash.isEmpty(record.NewsCategory) ? "" : record.NewsCategory.text,
                            "NewsTitle": lodash.isEmpty(record.NewsTitle) ? "" : record.NewsTitle,
                            "NewsContent": lodash.isEmpty(editorTransform) ? "" : editorTransform,
                            "Tags": lodash.isEmpty(record.Tags) ? "" : record.Tags,
                            "BannerUrl": lodash.isEmpty(record.BannerUrl) ? "" : record.BannerUrl,
                            "AuthorNames": lodash.isEmpty(record.AuthorNames) ? "" : record.AuthorNames,
                            "AuthorUserName": lodash.isEmpty(record.AuthorUserName) ? "" : record.AuthorUserName,
                            "PopularityCount": record.PopularityCount,
            
                            "FormattedStartTime": formattedStartTime,
                            "StartTime": record.StartTime,
                            "NewsTimeZone": lodash.isEmpty(record.NewsTimeZone) ? "" : record.NewsTimeZone,
                        };
                        records.push(item);
                    });
                } else {
                    setNoNewsRecordFoundFlag(true);
                }

                // console.log('In fetchNewsRecords(): listNextToken is: ', listNextToken);
                if (!lodash.isEmpty(listNextToken)) {
                    await fetchNewsRecords(language, domain);
                }
                
                setTimeout(() => {
                    records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                    setNewsRecords(records);
                    setNewsRecordsBackup(records);
                }, 100);

                backdropProgress(false);
            }).catch((err) => {
            console.error("In fetchNewsRecords(): err is: ", JSON.stringify(err, null, 2));
            setNewsRecords([]);
            setNoNewsRecordFoundFlag(true);
            backdropProgress(false);
            });
        } else {
            setNoNewsRecordFoundFlag(true);
        }
    }

    async function fetchAuthorNewss() {
        // console.log('In fetchAuthorNewss()');
        backdropProgress(true);
        setNoNewsRecordFoundFlag(false);
        setNewsRecordsBackup([]);

        let params = {
            apiName: "CommonDynamodbAPI",
            path: "/common/dynamodb/query",
            payload: {
                tableName: tableName,
                keyConditionExpression:
                    // "#newsPK = :newsPK",
                    "#newsPK = :newsPK AND begins_with(#newsSK, :newsSK)",
                expressionAttributeNames: {
                    "#newsPK": "NewsDomain",
                    "#newsSK": "DomainLangAuthorUserName",
                },
                expressionAttributeValues: {
                    ":newsPK": appContext.domainName,
                    ":newsSK": appContext.currentLanguage.languageCode + "#" + appContext.userName,
                },
                indexName: "NewsDomain-DomainLangAuthorUserName-index"
                    // "keyConditionExpression": "#newsPK = :newsPK",
                    // "expressionAttributeNames": {"#newsPK": "NewsPK"},
                    // "expressionAttributeValues": {":newsPK": "languages"}
            },
        };
  
        if (!lodash.isEmpty(listNextToken)) {
            params.payload.nextToken = listNextToken;
        }
  
        if (!lodash.isEmpty(pageSize)) {
            params.payload.pageSize = pageSize;
        }

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
  
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchAuthorNewss(): initObj is: ', JSON.stringify(initObj, null, 2));
  
        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In fetchAuthorNewss(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchAuthorNewss(): response.data.Items is: ', response.data.Items);
            // console.log('In fetchAuthorNewss(): response.data.NextToken is: ', response.data.NextToken);

            let records = [];

            listNextToken = null;

            if (response && !lodash.isEmpty(response.data.NextToken)) {
                listNextToken = response.data.NextToken;
                records = newsRecords;
            }
        
            let items = response.data.Items;

            if (items && items.length > 0) {
                setNewsRecords([]);

                items.map((record) => {
                    // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                    // console.log('In fetchAuthorNewss(): formatedDateTime is: ', formatedDateTime);
                    
                    let formattedStartTime = null;
                    if (record.StartTime) {
                        formattedStartTime = FormatTimezoneDates(record.StartTime, appContext.currentLanguage.languageCode, record.NewsTimeZone, "shortDateAndTime");
                    }
                    // console.log('In fetchAuthorNewss(): formattedStartTime is: ', formattedStartTime);

                    let item = {
                        NewsPK: record.NewsPK,
                        NewsSK: record.NewsSK,

                        LanguageObject: record.LanguageObject,
                        LanguageCode: record.LanguageCode,
                        Domain: record.Domain,

                        "NewsId": lodash.isEmpty(record.NewsId) ? "" : record.NewsId,

                        "NewsDomain": lodash.isEmpty(record.NewsDomain) ? "" : record.NewsDomain,
                        "NewsCategory": lodash.isEmpty(record.NewsCategory) ? "" : record.NewsCategory.text,
                        "NewsTitle": lodash.isEmpty(record.NewsTitle) ? "" : record.NewsTitle,
                        "NewsContent": lodash.isEmpty(record.NewsContent) ? "" : record.NewsContent,
                        "Tags": lodash.isEmpty(record.Tags) ? "" : record.Tags,
                        "BannerUrl": lodash.isEmpty(record.BannerUrl) ? "" : record.BannerUrl,
                        "AuthorNames": lodash.isEmpty(record.AuthorNames) ? "" : record.AuthorNames,
                        "AuthorUserName": lodash.isEmpty(record.AuthorUserName) ? "" : record.AuthorUserName,
                        "PopularityCount": record.PopularityCount,
        
                        "FormattedStartTime": formattedStartTime,
                        "StartTime": record.StartTime,
                        "NewsTimeZone": lodash.isEmpty(record.NewsTimeZone) ? "" : record.NewsTimeZone,
                    };
                    records.push(item);
                });
            } else {
                setNoNewsRecordFoundFlag(true);
            }

            // console.log('In fetchAuthorNewss(): listNextToken is: ', listNextToken);
            if (!lodash.isEmpty(listNextToken)) {
                await fetchAuthorNewss();
            }
            
            setTimeout(() => {
                records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                setNewsRecords(records);
                setNewsRecordsBackup(records);
            }, 100);

            backdropProgress(false);
        }).catch((err) => {
          console.error("In fetchAuthorNewss(): err is: ", JSON.stringify(err, null, 2));
          setNewsRecords([]);
          setNoNewsRecordFoundFlag(true);
          backdropProgress(false);
        });
    }
    
    async function getNews(key) {
        // console.log('In getNews(): key.NewsPK is: ', key.NewsPK);
        // console.log('In getNews(): key.NewsSK is: ', key.NewsSK);
        
        backdropProgress(true);
    
        let params = {
            apiName: "CommonDynamodbAPI",
            path: "/common/dynamodb/get",
            payload: {
                tableName: tableName,
                key: {
                    NewsPK: key.NewsPK,
                    NewsSK: key.NewsSK,
                },
            },
        };
  
        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
  
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In getNews(): initObj is: ', JSON.stringify(initObj, null, 2));
  
        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In getNews(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In getNews(): response.data is: ', response.data);
        
            let item = response.data.Item;

            appContext.currentPK = item.NewsPK;
            appContext.currentSK = item.NewsSK;
        
            setLanguage(item.LanguageObject);
            setDomain(item.Domain);
            
            setYourNames(item.AuthorNames);
            setAuthorPhotoSrc(item.AuthorPhotoSrc);
            setNewsTitle(item.NewsTitle);
            setNewsContent(item.NewsContent);
            setNewsTags(item.Tags);
            setNewsBannerUrl(item.BannerUrl ? item.BannerUrl : "");

            setNewsStartTime(item.StartTime);
            setNewsCategory(item.NewsCategory);
            setTimeZone(item.NewsTimeZone);

            setShowNewsListFlag(false);
            setUploadFlag(false);
            setShowNewsDetailsFormFlag(true);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);

            backdropProgress(false);
        }).catch((err) => {
            console.error("In getNews(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    }
  
    async function editNewsAction(item) {
        // console.log("In editNewsAction(): item is: ", item);
        // console.log("In editNewsAction(): newsRecords is: ", newsRecords);
        
        setLanguage(null);
        setDomain(null);
        setShowNewsDetailsFormFlag(true);

        setRecordToEdit(item);
        await getNews({NewsPK: item.NewsPK, NewsSK: item.NewsSK,});
    }

    let  refDataSelectOptions = [];
    
    async function fetchReferenceData(refType, lang) {
        return new Promise((resolve, reject) => {
            // console.log('In ManageFaqs  fetchReferenceData(): refType is: ', refType);
            // console.log('In ManageFaqs  fetchReferenceData(): lang is: ', lang);
            backdropProgress(true);

            refDataSelectOptions = [];

            let apiName = null;
            let path = null;
            let keyConditionExpression = null;
            let expressionAttributeNames = {};
            let expressionAttributeValues = {};
            
            switch(refType) {
                case "supported-domains":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "supported-domains",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                default:
                    break;
            }

            setProgressFlag(true);
        
            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    keyConditionExpression: keyConditionExpression,
                    expressionAttributeNames: expressionAttributeNames,
                    expressionAttributeValues: expressionAttributeValues,
                    // "keyConditionExpression": "#resourcePK = :resourcePK",
                    // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                    // "expressionAttributeValues": {":resourcePK": "languages"}
                },
            };
        
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
        
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In  fetchReferenceData(): initObj is: ', JSON.stringify(initObj, null, 2));
        
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In  fetchReferenceData(): response.length is: ', response.data.Items.length);
                // console.log('In  fetchReferenceData(): response is: ', JSON.stringify(response.data.Items, null, 2));
                response.data.Items.map((item) => {
                    let record = {
                        value: item.ResourceCode,
                        text: item.ResourceDesc,
                    };

                    if (record.value !== "all") {// Bypass all
                        refDataSelectOptions.push(record);
                    }
                });

                switch(refType) {
                    case "supported-domains":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setDomainOptions(refDataSelectOptions);
                        break;
                    default:
                        break;
                }

                backdropProgress(false);
                resolve(refDataSelectOptions);
            }).catch((err) => {
                console.error("In ManageFaqs  fetchReferenceData(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject(err);
            });
        });
    }

    async function fetchLanguages() {
        // console.log("In fetchLanguages()");
        // setFilterDisabledFlag(true);

        //-------------------------------------------------------
        // Make sure languages is not in cache before proceeding
        //-------------------------------------------------------

        let cachedLanguages = GetCacheItem("languages");
        // console.log('In fetchLanguages(): cachedLanguages is: ', JSON.stringify(cachedLanguages, null, 2));
        cachedLanguages = null;
        if (cachedLanguages && cachedLanguages !== null && cachedLanguages.length > 0) {
            // console.log('In fetchLanguages(): cachedLanguages length is: ', cachedLanguages.length);
            setLanguages(cachedLanguages);
            return true;
        }

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/query",
            payload: {
                keyConditionExpression: "#resourcePK = :resourcePK",
                expressionAttributeNames: { "#resourcePK": "ResourcePK" },
                expressionAttributeValues: { ":resourcePK": "languages" },
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log("In fetchLanguages(): response length is: ", response.data.Items.length);
            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));

            // response.data.Items.sort((a, b) =>
            //     a.LanguageName > b.LanguageName ? 1 : -1
            // );

            let records = [];
            let items = response.data.Items;

            items.map(item => {
                let record ={
                    languageCode: item.ResourceCode,
                    languageName: item.LanguageName
                };
                records.push(record);
            });

            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
            setLanguages(records);

            // Add it to cache
            SetCacheItem("languages", response.data.Items);
            backdropProgress(false);
        }).catch((err) => {
            console.error("In fetchLanguages(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    };

    let categorySelectOptions = [];

    async function fetchCategories(referrer) {
        // console.log('In PostNews fetchCategories()');
        backdropProgress(true);

        let apiName = null;
        let path = null;
        let keyConditionExpression = null;
        let expressionAttributeNames = {};
        let expressionAttributeValues = {};
        
        switch(referrer) {
            case "PostNews":
                apiName = "ResourceAPI";
                path = "/dynamodb/resources/query";
                keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                expressionAttributeNames = {
                    "#resourcePK": "ResourcePK",
                    "#resourceSK": "ResourceSK",
                };
                expressionAttributeValues = {
                    ":resourcePK": "news-categories",
                    ":resourceSK": appContext.currentLanguage.languageCode,
                };
                break;
            default:
                break;
        }

        setProgressFlag(true);
    
        let params = {
            apiName: apiName,
            path: path,
            payload: {
                keyConditionExpression: keyConditionExpression,
                expressionAttributeNames: expressionAttributeNames,
                expressionAttributeValues: expressionAttributeValues,
                // "keyConditionExpression": "#resourcePK = :resourcePK",
                // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                // "expressionAttributeValues": {":resourcePK": "languages"}
            },
        };
    
        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
    
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchCategories(): initObj is: ', JSON.stringify(initObj, null, 2));
    
        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchCategories(): response.length is: ', response.data.Items.length);
            // console.log('In PostNews fetchCategories(): response is: ', JSON.stringify(response.data.Items, null, 2));
            response.data.Items.map((item) => {
                let record = {
                    value: item.ResourceCode,
                    text: item.ResourceDesc,
                };
                // if (record.value === "all") {
                //     setNewsCategory(record); // set it as default value 
                // }

                if (record.value !== "all") {// Bypass all
                    categorySelectOptions.push(record);
                }
            });
    
            categorySelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
            setCategoryOptions(categorySelectOptions);
            backdropProgress(false);
            setProgressFlag(false);
        }).catch((err) => {
            console.error("In PostNews fetchCategories(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
            setProgressFlag(false);
        });
    }

    // async function imageObjectCallback(result) {
    //     // console.log("In imageObjectCallback(): appContext.storageCfUrl is: ", appContext.storageCfUrl);
    //     // console.log("In imageObjectCallback(): result is: ", result);
    //     sessionStorage.removeItem("user-photo-url");

    //     if (result) {
    //         const photoUrl = appContext.storageCfUrl + result;
    //         setProfilePhotoSrc(photoUrl);
    //         sessionStorage.setItem("user-photo-url", photoUrl);
    //     }
    // }
        
    useEffect(() => {
        // console.log('In PostNews(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        // sessionStorage.removeItem("current-live-event");
    }, []);

    useLayoutEffect(() => {
        // console.log("In PostNews(): useLayoutEffect()");
        async function fetchItems() {
            await fetchLanguages();

            await fetchCategories("PostNews");

            // try{
            //     let imageKeyPrefix = "ccms/onyii5119@gmail.com/" + "capitalone_m2.jpg";// Test
            //     // let imageKeyPrefix = appContext.shortDomain + "/" + appContext.userName + "/user/profile/";
            //     await FetchS3Object(imageKeyPrefix, imageObjectCallback);
            // } catch (error) {
            //     console.log("In PostNews(): useLayoutEffect(): error is: ", error);
            // }

            // Fetch resource from catche
            let cacheItemKey = "post-news";
            cacheItemKey += ":";
            cacheItemKey += appContext.currentLanguage.languageCode;
            // console.log('In PostNews(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

            if (
                appContext.clearCacheLanguageCodes &&
                appContext.clearCacheLanguageCodes.length > 0 &&
                appContext.clearCacheLanguageCodes.includes(appContext.currentLanguage.languageCode)
            ) {
                // console.log("In PostNews(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                RemoveCacheItem(cacheItemKey);
            }

            let cacheItem = GetCacheItem(cacheItemKey);
            // console.log('In PostNews(): useLayoutEffect(): cacheItem is: ', cacheItem);
            cacheItem = null; // remove after testing

            if (cacheItem === null) {
                // resource is not in cache, fetch from DB
                // console.log('In PostNews(): useLayoutEffect(): resource is not in cache, fetch from DB');
                await fetchDbResources(cacheItemKey);
            } else {
                // console.log('In PostNews(): useLayoutEffect(): got resource from cache');
                setPageLabelsRes(cacheItem);
                setRenderDomFlag(true);
            }
        }

        fetchItems();
    }, []);
    
    function handleChange(event) {
        // console.log("In handleChange(): event.target.id is: ", event.target.id);
        // console.log("In handleChange(): event.target.value is: ", event.target.value);
        // setSubmitButtonFlag(false);

        switch(event.target.id) {
            case "newsTagsId":
                // console.log("In handleChange(): event.target.value is: ", event.target.value);
                setNewsTagsErrFlag(false);
                setNewsTags(event.target.value);

                setSubmitButtonFlag(false);
                if (
                    !lodash.isEmpty(event.target.value) &&
                    !lodash.isEmpty(newsContent) &&
                    !lodash.isEmpty(yourNames) &&
                    !lodash.isEmpty(newsTitle) &&
                    !lodash.isEmpty(newsStartTime) &&
                    !lodash.isEmpty(newsCategory) &&
                    !lodash.isEmpty(language)
                    ) {
                    setSubmitButtonFlag(true);
                }
                break;
            case "yourNamesId":
                // console.log("In handleChange(): event.target.value is: ", event.target.value);
                setYourNamesErrFlag(false);
                setYourNames(event.target.value);

                setSubmitButtonFlag(false);
                if (
                    !lodash.isEmpty(event.target.value) &&
                    !lodash.isEmpty(newsTitle) &&
                    !lodash.isEmpty(newsContent) &&
                    !lodash.isEmpty(newsStartTime) &&
                    !lodash.isEmpty(newsTags) &&
                    !lodash.isEmpty(newsCategory) &&
                    !lodash.isEmpty(language)
                    ) {
                    setSubmitButtonFlag(true);
                }
                break;
            case "newsTitleId":
                setNewsTitleErrFlag(false);
                setNewsTitle(event.target.value);

                setSubmitButtonFlag(false);
                if (
                    !lodash.isEmpty(event.target.value) &&
                    !lodash.isEmpty(yourNames) &&
                    !lodash.isEmpty(newsContent) &&
                    !lodash.isEmpty(newsStartTime) &&
                    !lodash.isEmpty(newsTags) &&
                    !lodash.isEmpty(newsCategory) &&
                    !lodash.isEmpty(language)
                    ) {
                    setSubmitButtonFlag(true);
                }
                break;
            case "newsBannerUrlId":
                setNewsBannerUrlErrFlag(false);
                setNewsBannerUrl(event.target.value ? event.target.value : "");

                // setSubmitButtonFlag(false);
                // if (
                //     !lodash.isEmpty(event.target.value) &&
                //     !lodash.isEmpty(yourNames) &&
                //     !lodash.isEmpty(newsContent) &&
                //     !lodash.isEmpty(newsStartTime) &&
                //     !lodash.isEmpty(newsTags) &&
                //     !lodash.isEmpty(language)
                //     ) {
                //     setSubmitButtonFlag(true);
                // }
                break;
            default:
                break;
        }
    }
    
    function handleLanguageChange(event, item) {
        // console.log("In handleLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        setSubmitButtonFlag(false);        
        setLanguage(item);

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(newsTitle) &&
            !lodash.isEmpty(yourNames) &&
            !lodash.isEmpty(newsContent) &&
            !lodash.isEmpty(newsStartTime) &&
            !lodash.isEmpty(newsCategory) &&
            !lodash.isEmpty(newsTags) 
        ) {
            setSubmitButtonFlag(true);
        }

        if (!lodash.isEmpty(item)) {
            Promise.all([
                fetchReferenceData("supported-domains", item)
            ]).then(async (results) => {
                // console.log("In handleListLanguageChange(): results is: ", results);
            }).catch(error => {
                console.log("In handleListLanguageChange(): error is: ", error);
            });
        }
    }

    function handleDomainChange(event, item) {
        // console.log("In handleDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            sessionStorage.setItem("media-domain-name", item.value);
        }

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(newsTitle) &&
            !lodash.isEmpty(yourNames) &&
            !lodash.isEmpty(newsContent) &&
            !lodash.isEmpty(newsStartTime) &&
            !lodash.isEmpty(newsCategory) &&
            !lodash.isEmpty(newsTags) 
            ) {
                setSubmitButtonFlag(true);
        }

        if (showNewsListFlag && language && item) {
            fetchNewsRecords(language, item);
        }
    }

    function handleListDomainChange(event, item) {
        // console.log("In handleListDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            fetchNewsRecords(item);
        }
    }

    // For tablet and mobile views
    function handleCategoryFilterChange(event, item) {
        // event.preventDefault();
        // event.persist();
        // console.log("In handleCategoryFilterChange(): item is ", item);
        
        setNewsCategory(item);

        setNewsRecords([]);

        if (item && newsRecordsBackup && newsRecordsBackup.length > 0) {
            console.log("In handleCategoryFilterChange(): item.value is ", item.value);
            if (item.value === "all") {
                setNewsRecords(newsRecordsBackup);
                return;
            }
            let filteredRecords = newsRecordsBackup.filter(record => record.mediaCategoryCode === item.value);
            if (filteredRecords) {
                setNewsRecords(filteredRecords);
            }
        }
    }

    function handleNewsStartTimeChange(startTime) {
        // console.log("In handleNewsStartTimeChange(): startTime is: ", startTime);

        setGlobalErrorFlag(false);
        setNewsStartTimeErrFlag(false);

        setNewsStartTime(startTime);

        // const timestamp = new Date();
        // const timestamp2 = Date.now();
        // console.log("In handleNewsStartTimeChange(): timestamp is: ", timestamp);
        // console.log("In handleNewsStartTimeChange(): timestamp2 is: ", timestamp2);

        // const startTime2 = moment.tz(startTime, timeZone);
        // console.log("In handleNewsStartTimeChange(): startTime2 is: ", startTime2);

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(startTime) &&
            !lodash.isEmpty(yourNames) &&
            !lodash.isEmpty(newsTitle) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(newsContent) &&
            !lodash.isEmpty(newsCategory) &&
            !lodash.isEmpty(newsTags) 
            ) {
            setSubmitButtonFlag(true);
        }
    }

    function handleNewsCategoryChange(event, item) {
        // console.log("In handleNewsCategoryChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setNewsCategoryErrFlag(false);
        
        setNewsCategory(item);

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(yourNames) &&
            !lodash.isEmpty(newsTitle) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(newsContent) &&
            !lodash.isEmpty(newsTags) &&
            !lodash.isEmpty(newsStartTime)
            ) {
            setSubmitButtonFlag(true);
        }
    }

    //--------------------
    // For Froala Editor
    //--------------------
    async function handleEditorHtmlBodyChange(editorData) {
        // console.log("In handleEditorHtmlBodyChange(): editorData is: ", editorData);

        // if (editorData || editorData === "") {
            setNewsContent(editorData);
            setSubmitButtonFlag(false);

            if (
                !lodash.isEmpty(editorData) &&
                !lodash.isEmpty(yourNames) &&
                !lodash.isEmpty(newsTitle) &&
                !lodash.isEmpty(language) &&
                !lodash.isEmpty(domain) &&
                !lodash.isEmpty(newsCategory) &&
                !lodash.isEmpty(newsTags) &&
                !lodash.isEmpty(newsStartTime)
                ) {
                setSubmitButtonFlag(true);
            }
        // }
    }

    function initializeForm() {
        setYourNames("");
        setNewsTitle("");
        setNewsStartTime(null);
        setNewsCategory(null);
        setNewsContent("");
        setNewsBannerUrl("");
        setNewsTags("");
    }

    function createLiveEventAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowNewsListFlag(false);
        setShowNewsDetailsFormFlag(true);
        setUploadFlag(false);
    }

    function createMoreAction() {
        initializeForm();
        setLanguage(null);
        setDomain(null);

        setEditModeFlag(false);
        setShowNewsListFlag(false);
        setShowNewsDetailsFormFlag(true);
        setUploadFlag(false);
    }

    function uploadAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowNewsListFlag(false);
        setShowNewsDetailsFormFlag(false);
        setUploadFlag(true);
    }
    
    function convertAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowNewsListFlag(false);
        setShowNewsDetailsFormFlag(false);
        setUploadFlag(false);
        setConvertFlag(true);
    }
    
    async function closeConvertForm() {
        await fetchNewsRecords(language, domain);
        
        setTimeout(() => {
            setShowNewsDetailsFormFlag(false);
            setUploadFlag(false);
            setConvertFlag(false);
            setShowNewsListFlag(true);
        }, 500);
    }
    
    async function closeDetailsForm() {
        await fetchNewsRecords(language, domain);
        
        setTimeout(() => {
            setShowNewsDetailsFormFlag(false);
            setUploadFlag(false);
            setShowNewsListFlag(true);
            setEditModeFlag(false);
        }, 500);
    }
    
    async function closeUploadForm() {
        await fetchNewsRecords(language, domain);
        
        setTimeout(() => {
            setShowNewsListFlag(true);
            setShowNewsDetailsFormFlag(false);
            setUploadFlag(false);
        }, 500);
    }
    
    // function liveEventsUploadCallback() {
    //     setEditModeFlag(false);
    //     setShowNewsListFlag(true);
    //     setShowNewsDetailsFormFlag(false);
    //     setUploadFlag(false);
    // }
    
    async function createService() {
        console.log("In createService(): domain is: ", domain);
        console.log("In createService(): language is: ", language);
        // console.log("In createService(): newsContent is: ", newsContent);
        // console.log("In createService(): newsTitle is: ", newsTitle);
        // console.log("In createService(): newsTags is: ", newsTags);
        // console.log("In createService(): newsStartTime is: ", newsStartTime);
        // console.log("In createService(): newsCategory is: ", newsCategory);
        // console.log("In createService(): timeZone is: ", timeZone);
        // console.log("In createService(): appContext.deceasedCode is: ", appContext.deceasedCode);
        // console.log("In createService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In createService(): appContext.userName is: ", appContext.userName);
    
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In createService(): Validation passed.");
            // console.log("In createService(): Validation passed.");

            // const timestamp = new Date();
            const timestamp = moment.tz(Date.now(), timeZone);
            // console.log("In createService(): timestamp is: ", timestamp);

            const startTime = moment.tz(newsStartTime, timeZone);
            // console.log("In createService(): startTime is: ", startTime);

            backdropProgress(true);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            let uniqueId = GenerateUniqueName();

            let key = {
                NewsPK: domain.value + "#" + language.languageCode + "#news",
                NewsSK: domain.value + "#" + language.languageCode + "#news#" + uniqueId,
            };

            let item = {
                "NewsPK": key.NewsPK,
                "NewsSK":  key.NewsSK,

                "NewsId": uniqueId,

                "UniqueAttribute": lodash.isEmpty(key) ? null : key,
                "LanguageObject": lodash.isEmpty(language) ? null : language,
                "LanguageCode": lodash.isEmpty(language) ? null : language.languageCode,
                "Domain": domain,

                "NewsDomain": domain.value,
                "NewsCategory": lodash.isEmpty(newsCategory) ? null : newsCategory,
                "NewsCategoryCode": lodash.isEmpty(newsCategory) ? null : newsCategory.value,
                "NewsTitle": lodash.isEmpty(newsTitle) ? null : newsTitle,
                "NewsContent": lodash.isEmpty(newsContent) ? null : newsContent,
                "Tags": lodash.isEmpty(newsTags) ? null : newsTags,
                "BannerUrl": lodash.isEmpty(newsBannerUrl) ? null : newsBannerUrl,
                "AuthorNames": lodash.isEmpty(yourNames) ? null : yourNames,
                "AuthorUserName": appContext.userName,
                "AuthorPhotoSrc": lodash.isEmpty(profilePhotoSrc) ? null : profilePhotoSrc, 
                "PopularityCount": 0,

                "StartTime": startTime,
                "NewsTimeZone": timeZone,

                "DomainLangAuthorUserName": domain.value + "#" + language.languageCode + "#" + appContext.userName,// For GSI
                "DomainLangNewsTitle": domain.value + "#" + language.languageCode + "#" + newsTitle,// For GSI
                "DomainLangStartTime": domain.value + "#" + language.languageCode + "#" + startTime,// For GSI
                "DomainLangPopularityCount": domain.value + "#" + language.languageCode + "#" + 0,// For GSI
                "DomainLangNewsCategoryCode": domain.value + "#" + language.languageCode + "#" + newsCategory.value,// For GSI

                "ModifiedBy": appContext.userName,
                "CreatedAt": timestamp,
                "UpdatedAt": timestamp,
            };

            // console.log('In createService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_not_exists(UniqueAttribute)";

            let params = {
                apiName: "CommonDynamodbAPI",
                path: "/common/dynamodb/put",
                payload: {
                    tableName: tableName,
                    item: item,
                    conditionExpression: conditionExpression,
                    // updateExpression: updateExpression,
                    // expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In createService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In createService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In createService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar 
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);
            }).catch((err) => {
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                switch(err.code) {
                    case "ConditionalCheckFailedException":
                        console.error("In createService(): Duplicate Error");
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        break;
                    default:
                        console.error("In createService(): err is: ", JSON.stringify(err, null, 2));
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                    break;
                }
            });
        }
    }

    async function updateService() {
        // console.log("In updateService(): liveEventUrl is: ", liveEventUrl);
        // console.log("In updateService(): liveEventUrlType is: ", liveEventUrlType);
        // console.log("In updateService(): newsContent is: ", newsContent);
        // console.log("In updateService(): newsTitle is: ", newsTitle);
        // console.log("In updateService(): newsTags is: ", newsTags);
        // console.log("In updateService(): newsStartTime is: ", newsStartTime);
        // console.log("In updateService(): newsCategory is: ", newsCategory);
        // console.log("In updateService(): timeZone is: ", timeZone);
        // console.log("In updateService(): appContext.deceasedCode is: ", appContext.deceasedCode);
        // console.log("In updateService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In updateService(): appContext.userName is: ", appContext.userName);
        // console.log("In updateService(): appContext.currentPK is: ", appContext.currentPK);
        // console.log("In updateService(): appContext.currentSK is: ", appContext.currentSK);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In updateService(): Validation passed.");

            // let newsId = GenerateUniqueName();// conversion
                
            const timestamp = new Date();

            backdropProgress(true);
            const startTime = moment.tz(newsStartTime, timeZone);
            // console.log("In updateService(): startTime is: ", startTime);

            let key = {
                NewsPK: appContext.currentPK,
                NewsSK: appContext.currentSK,
            };
            // console.log("In updateService(): key is: ", key);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
            let updateExpression =
                "SET " +
                "UniqueAttribute = :uniqueAttribute, " +
                // "LanguageObject = :languageObject, " +
                // "LanguageCode = :languageCode, " +

                // "NewsId = :newsId, " + // conversion
                
                "NewsCategory = :newsCategory, " +
                "NewsCategoryCode = :newsCategoryCode, " +
                "NewsTitle = :newsTitle, " +
                "NewsContent = :newsContent, " +
                "Tags = :tags, " +
                "BannerUrl = :bannerUrl, " +
                "AuthorNames = :authorNames, " +
                "AuthorPhotoSrc = :authorPhotoSrc, " +
                "StartTime = :startTime, " +
                "NewsTimeZone = :newsTimeZone, " +
                
                "DomainLangAuthorUserName = :domainLangAuthorUserName, " +
                "DomainLangNewsTitle = :domainLangNewsTitle, " +
                "DomainLangStartTime = :domainLangStartTime, " +
                "DomainLangNewsCategoryCode = :domainLangNewsCategoryCode, " +

                "ModifiedBy = :modifiedBy, " + 
                "UpdatedAt = :updatedAt";

            let expressionAttributeValues = {
                ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
                // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
                // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,
                
                // ":newsId": lodash.isEmpty(newsId) ? null : newsId,// conversion
                
                ":newsCategory": lodash.isEmpty(newsCategory) ? null : newsCategory,
                ":newsCategoryCode": lodash.isEmpty(newsCategory) ? null : newsCategory.value,
                ":newsTitle": lodash.isEmpty(newsTitle) ? null : newsTitle,
                ":newsContent": lodash.isEmpty(newsContent) ? null : newsContent,
                ":tags": lodash.isEmpty(newsTags) ? null : newsTags,
                ":bannerUrl": lodash.isEmpty(newsBannerUrl) ? null : newsBannerUrl,
                ":authorNames": lodash.isEmpty(yourNames) ? null : yourNames,
                ":authorPhotoSrc": lodash.isEmpty(profilePhotoSrc) ? null : profilePhotoSrc,
                ":startTime": lodash.isEmpty(startTime) ? null : startTime,
                ":newsTimeZone": lodash.isEmpty(timeZone) ? null : timeZone,
                
                ":domainLangAuthorUserName": domain.value + "#" + language.languageCode + "#" + appContext.userName,// For GSI
                ":domainLangNewsTitle": domain.value + "#" + language.languageCode + "#" + newsTitle,// For GSI
                ":domainLangStartTime": domain.value + "#" + language.languageCode + "#" + startTime,// For GSI
                ":domainLangNewsCategoryCode": domain.value + "#" + language.languageCode + "#" + newsCategory.value,// For GSI
                
                ":modifiedBy": appContext.userName,
                ":updatedAt": moment.tz(timestamp, timeZone),
            };
            // console.log('In updateService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_exists(UniqueAttribute)";

            let params = {
                apiName: "CommonDynamodbAPI",
                path: "/common/dynamodb/update",
                payload: {
                    tableName: tableName,
                    key: key,
                    conditionExpression: conditionExpression,
                    updateExpression: updateExpression,
                    expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In updateService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In updateService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In updateService(): response is: ', JSON.stringify(response, null, 2));
                setShowNewsDetailsFormFlag(false);
                setUploadFlag(false);
                setEditModeFlag(false);

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);

                await fetchNewsRecords(language, domain);
                setShowNewsListFlag(true);
            }).catch((err) => {
                console.error("In updateService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    async function deleteService(recToDelete) {
        // console.log("In deleteService(): recToDelete is: ", recToDelete);
        // setDeleteCompleteFlag(false);
        // let itemsToDelete = [];

        if (!lodash.isEmpty(recToDelete)) {
            backdropProgress(true);

            let key = {
                NewsPK: recToDelete.NewsPK,
                NewsSK: recToDelete.NewsSK,
            };

            let params = {
                apiName: "CommonDynamodbAPI",
                path: "/common/dynamodb/delete",
                payload: {
                    tableName: tableName,
                    key: key,
                },
            };
    
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In deleteService(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In deleteService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                backdropProgress(false);
                await fetchNewsRecords(language, domain);
            }).catch((err) => {
                console.error("In deleteService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    function validateForm() {
        // console.log("In validateForm()");
        let formErrFlag = false;
  
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
    
        setLanguageErrFlag(false);
        setDomainErrFlag(false);
        setYourNamesErrFlag(false);
        setNewsTitleErrFlag(false);
        setNewsStartTimeErrFlag(false);
        setNewsCategoryErrFlag(false);
        setNewsTagsErrFlag(false);
        setNewsContentErrFlag(false);
        setNewsBannerUrlErrFlag(false);

        if (lodash.isEmpty(language)) {
            formErrFlag = true;
            setLanguageErrFlag(true);
        }

        if (lodash.isEmpty(domain)) {
            formErrFlag = true;
            setDomainErrFlag(true);
        }
    
        if (lodash.isEmpty(yourNames)) {
            formErrFlag = true;
            setYourNamesErrFlag(true);
        }
    
        if (lodash.isEmpty(newsTitle)) {
            formErrFlag = true;
            setNewsTitleErrFlag(true);
        }
    
        if (lodash.isEmpty(newsStartTime)) {
            formErrFlag = true;
            setNewsStartTimeErrFlag(true);
        }
    
        if (lodash.isEmpty(newsCategory)) {
            formErrFlag = true;
            setNewsCategoryErrFlag(true);
        }
    
        if (lodash.isEmpty(newsTags)) {
            formErrFlag = true;
            setNewsTagsErrFlag(true);
        }
    
        if (lodash.isEmpty(newsContent)) {
            formErrFlag = true;
            setNewsContentErrFlag(true);
        }
    
        if (!lodash.isEmpty(newsBannerUrl) && !isURL(newsBannerUrl)) {
            formErrFlag = true;
            setNewsBannerUrlErrFlag(true);
        }
    
        if (formErrFlag) {
            setGlobalErrorFlag(true);
        }
        // console.log("In validateForm(): formErrFlag is: ", formErrFlag);
        return formErrFlag;
    }

    async function newsService() {
        // console.log("In newsService()");

        if (!validateForm()) {
            // console.log("In newsService(): Validation passed.");
            if (editModeFlag) {
                await updateService();
            } else {
                await createService();
            }
        }
    }
    
    function snackbarOnClose(event, reason) {
        setSnackbarOpen(false);
    }

    function handleMultipleSelectionChange(data) {
        // console.log("In handleMultipleSelectionChange(): data.value is: ", data.value);
        setSelectedTableRows(data.value);
        setTableDirtyFlag(true);
    }

    //------------------
    // For AlertDialog
    //------------------
    const [deleteType, setDeleteType] = useState("");

    function showAlertDialog(item, type) {
        // console.log("In showAlertDialog(): type is: ", type);
        setDeleteType(type);
        
        if (type && type === "single") {// single deletion
            setRecordToDelete(item);
            setDialogSubContent(item.NewsTitle);
            setAlertOpen(true);
        } else {// multiple deletions
            let msg = FormatNumber(selectedTableRows.length, appContext.currentLanguage.languageCode);
            msg += " ";
            msg += selectedTableRows.length > 1 ? newsRecordsLabel : newsRecordLabel;
            setDialogSubContent(msg);
            setAlertOpen(true);
        }
    }

    function alertClickAgree() {
        // Close Alert
        if (deleteType && deleteType === "single") {
            setAlertOpen(false);
            deleteService(recordToDelete);
        } else {
            setAlertOpen(false);
            // deleteMultipleAction();
            processMultipleDeletionRecs();
        }
    }

    function alertClickDisagree() {
        // Close Alert
        setAlertOpen(false);
        return false;
    }

    function alertClose() {
        // Close Alert
        setAlertOpen(false);
    }

    function deleteIconTemplate(rowData, column) {
        return <DeleteIcon color="secondary" onClick={() => showAlertDialog(rowData, "single")}/>
    }

    function editIconTemplate(rowData, column) {
        return <EditIcon color="primary" onClick={() => editNewsAction(rowData)}/>
    }

    async function createOrUpdateStreamRecord(rowData) {
        // console.log("In createOrUpdateStreamRecord(): rowData is: ", JSON.stringify(rowData, null, 2));
        // console.log("In createOrUpdateStreamRecord(): CobPK is: ", rowData.CobPK);
        // console.log("In createOrUpdateStreamRecord(): CobSK is: ", rowData.CobSK);

        // console.log("In createOrUpdateStreamRecord(): liveEventUrl is: ", rowData.LiveEventUrl);
        // console.log("In createOrUpdateStreamRecord(): liveEventUrlType is: ", rowData.LiveEventUrlType);
        // console.log("In createOrUpdateStreamRecord(): newsContent is: ", rowData.NewsContent);
        // console.log("In createOrUpdateStreamRecord(): newsTitle is: ", rowData.NewsTitle);
        // console.log("In createOrUpdateStreamRecord(): newsTags is: ", rowData.NewsTags);

        // console.log("In createOrUpdateStreamRecord(): newsStartTime is: ", rowData.NewsStartTime);
        // console.log("In createOrUpdateStreamRecord(): newsCategory is: ", rowData.NewsCategory);

        // console.log("In createOrUpdateStreamRecord(): timeZone is: ", timeZone);
        // console.log("In createOrUpdateStreamRecord(): appContext.deceasedCode is: ", appContext.deceasedCode);
        // console.log("In createOrUpdateStreamRecord(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In createOrUpdateStreamRecord(): appContext.userName is: ", appContext.userName);

        let newsCategory = null;
        if (rowData.NewsTags) {
            newsCategory = moment(rowData.NewsStartTime).add(parseInt(rowData.NewsTags.value), 'minutes');
        }
        // console.log("In createOrUpdateStreamRecord(): newsCategory is: ", newsCategory);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: "live-events",
            CobSK: appContext.currentLanguage.languageCode + "#" + appContext.deceasedCode,
        };
        // console.log("In createOrUpdateStreamRecord(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        // let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            "LanguageObject = :languageObject, " +
            "LanguageCode = :languageCode, " +
            
            "LiveEventStarted = :liveEventStarted, " +

            "NewsTags = :newsTags, " +

            "NewsStartTime = :newsStartTime, " +
            "NewsCategory = :newsCategory, " +

            "EventTimeZone = :eventTimeZone, " +

            "LiveEventUrl = :liveEventUrl, " +
            "LiveEventUrlType = :liveEventUrlType, " +
            "NewsContent = :newsContent, " +
            "YourNames = :yourNames, " +
            "NewsTitle = :newsTitle, " +

            "EventThumbnailUrl = :eventThumbnailUrl, " +
            "EventBannerUrl = :eventBannerUrl, " +
            "EventPosterUrl = :eventPosterUrl, " +
            "EventAudioUrl = :eventAudioUrl, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            ":languageObject": lodash.isEmpty(rowData.LanguageObject) ? null : rowData.LanguageObject,
            ":languageCode": lodash.isEmpty(rowData.LanguageCode) ? null : rowData.LanguageCode,
                    
            ":liveEventStarted": true,

            ":newsTags": lodash.isEmpty(rowData.NewsTags) ? null : rowData.NewsTags,

            ":newsStartTime": lodash.isEmpty(rowData.NewsStartTime) ? null : rowData.NewsStartTime,
            ":newsCategory": lodash.isEmpty(newsCategory) ? null : newsCategory,

            ":eventTimeZone": lodash.isEmpty(timeZone) ? null : timeZone,

            ":liveEventUrl": lodash.isEmpty(rowData.LiveEventUrl) ? null : rowData.LiveEventUrl,
            ":liveEventUrlType": lodash.isEmpty(rowData.LiveEventUrlType) ? null : rowData.LiveEventUrlType,
            ":yourNames": lodash.isEmpty(rowData.YourNames) ? null : rowData.YourNames,
            ":newsTitle": lodash.isEmpty(rowData.NewsTitle) ? null : rowData.NewsTitle,
            ":newsContent": lodash.isEmpty(rowData.NewsContent) ? null : rowData.NewsContent,

            ":eventThumbnailUrl": lodash.isEmpty(rowData.EventThumbnailUrl) ? null : rowData.EventThumbnailUrl,
            ":eventBannerUrl": lodash.isEmpty(rowData.EventBannerUrl) ? null : rowData.EventBannerUrl,
            ":eventPosterUrl": lodash.isEmpty(rowData.EventPosterUrl) ? null : rowData.EventPosterUrl,
            ":eventAudioUrl": lodash.isEmpty(rowData.EventAudioUrl) ? null : rowData.EventAudioUrl,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In createOrUpdateStreamRecord(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        // conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                // conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In createOrUpdateStreamRecord(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In createOrUpdateStreamRecord(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In createOrUpdateStreamRecord(): response is: ', JSON.stringify(response, null, 2));
            // setShowNewsDetailsFormFlag(false);
            // setUploadFlag(false);

            // Show Snackbar
            setSnackbarsVariant("success");
            setSnackbarsMsg(operationSuccessMsg);
            setSnackbarOpen(true);

            // initializeForm();
            // const now = moment();
            // let remainTime = now.diff(moment(formattedTime), "minutes");
            // console.log("In createOrUpdateStreamRecord(): remainTime is: ", remainTime + " minutes");

            let records = [];
            rowData.LiveEventStarted = true;
            records = newsRecords.filter(item => item.LiveEventId !== rowData.LiveEventId);
            if (records) {
                records.push(rowData);
                records.sort((a, b) => (a.NewsStartTime > b.NewsStartTime ? 1 : -1));
                setNewsRecords(records);
            }

            backdropProgress(false);
        }).catch((err) => {
            console.error("In createOrUpdateStreamRecord(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }

    async function updateLiveEventStatus(rowData) {
        // console.log("In updateLiveEventStatus(): rowData.CobPK is: ", rowData.CobPK);
        // console.log("In updateLiveEventStatus(): rowData.CobSK is: ", rowData.CobSK);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
                
        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: rowData.CobPK,
            CobSK: rowData.CobSK,
        };
        // console.log("In updateLiveEventStatus(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            // "LanguageObject = :languageObject, " +
            // "LanguageCode = :languageCode, " +

            "LiveEventStarted = :liveEventStarted, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
            // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,

            ":liveEventStarted": true,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In updateLiveEventStatus(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In updateLiveEventStatus(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In updateLiveEventStatus(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In updateLiveEventStatus(): response is: ', JSON.stringify(response, null, 2));
            setShowNewsDetailsFormFlag(false);
            setUploadFlag(false);

            // Show Snackbar
            setSnackbarsVariant("success");
            setSnackbarsMsg(operationSuccessMsg);
            setSnackbarOpen(true);

            // initializeForm();

            backdropProgress(false);

            await fetchNewsRecords(language, domain);
            setShowNewsListFlag(true);
        }).catch((err) => {
            console.error("In updateLiveEventStatus(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }

    async function forcedToEndUpdate(rowData, showStatus) {
        // console.log("In forcedToEndUpdate(): rowData.CobPK is: ", rowData.CobPK);
        // console.log("In forcedToEndUpdate(): rowData.CobSK is: ", rowData.CobSK);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        // console.log("In forcedToEndUpdate(): Validation passed.");
            
        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: rowData.CobPK,
            CobSK: rowData.CobSK,
        };
        // console.log("In forcedToEndUpdate(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            // "LanguageObject = :languageObject, " +
            // "LanguageCode = :languageCode, " +

            "ForcedToEndFlag = :forcedToEndFlag, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
            // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,

            ":forcedToEndFlag": true,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In forcedToEndUpdate(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In forcedToEndUpdate(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In forcedToEndUpdate(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In forcedToEndUpdate(): response is: ', JSON.stringify(response, null, 2));
            setShowNewsDetailsFormFlag(false);
            setUploadFlag(false);

            // Show Snackbar
            if (showStatus) {
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);
            }

            // initializeForm();

            backdropProgress(false);

            await fetchNewsRecords(language, domain);
            setShowNewsListFlag(true);
        }).catch((err) => {
            console.error("In forcedToEndUpdate(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }

    async function startEventAction(rowData) {
        // console.log("In startEventAction(): rowData is: ", JSON.stringify(rowData));

        if (rowData) {
            // console.log("In startEventAction(): rowData.NewsCategory is: ", rowData.NewsCategory);
            setGlobalErrorFlag(false);
            // sessionStorage.setItem("current-live-event", JSON.stringify(rowData));
            // let diff2 = moment(rowData.NewsCategory).fromNow();
            // console.log("In startEventAction(): diff2 is: ", diff2);

            let diff = moment(rowData.NewsStartTime).diff(moment(), "minutes");
            // console.log("In startEventAction(): diff is: ", diff);

            if (diff <= appContext.liveEventLeadTime) {
                setEventStartedFlag(true);
                setStartedEventId(rowData.LiveEventId);

                await createOrUpdateStreamRecord(rowData);
                await updateLiveEventStatus(rowData);
            } else {
                // Show Snackbar
                setSnackbarsVariant("error");
                setSnackbarsMsg(startEventInfoMsg);
                setSnackbarOpen(true);
            }
        }
    }

    async function endEventAction(rowData) {
        // console.log("In endEventAction(): rowData.LiveEventId is: ", rowData.LiveEventId);

        await forcedToEndUpdate(rowData, true);
    }

    // function MobileEventStatus(props) {
    //     // console.log("In MobileEventStatus(): props.rowData is: ", props.rowData);
    //     // let liveEventStatus = sessionStorage.getItem("live-event-status");
    //     // console.log("In MobileEventStatus(): NewsTitle is: ", rowData.NewsTitle);

    //     const {
    //         rowData,
    //     } = props;

    //     if (rowData) {
    //         let eventStarted = rowData.LiveEventStarted;
    //         // console.log("In MobileEventStatus(): eventStarted is: ", eventStarted);
    
    //         let forcedToEnd = rowData.ForcedToEndFlag;
    //         // console.log("In MobileEventStatus(): forcedToEnd is: ", forcedToEnd);
    
    //         let currentTime = moment();
    //         let startTime = moment(rowData.NewsStartTime).subtract(parseInt(appContext.liveEventLeadTime), 'minutes');
    //         // let startTime = moment(rowData.NewsStartTime);
    //         let endTime = moment(rowData.NewsCategory);
    
    //         // console.log("In MobileEventStatus(): currentTime is: ", currentTime);
    //         // console.log("In MobileEventStatus(): startTime is: ", startTime);
    //         // console.log("In MobileEventStatus(): endTime is: ", endTime);
    
    //         if (forcedToEnd) {
    //             return  (
    //                 <CheckIcon style={{color: 'green'}}/>
    //             );
    //         }
    
    //         if (currentTime.isAfter(startTime) && currentTime.isSameOrBefore(endTime)) {
    //             if (eventStarted) {
    //                 return (
    //                     <>
    //                         <LinearProgress color="secondary" style={{background: '#ff9800'}} />
    
    //                         <Button size="small" disabled={false} color="primary" onClick={() => endEventAction(rowData)}>
    //                             {endLabel}
    //                         </Button>
    //                     </>
    //                 );
    //             } else {// Event has not started. Give user opportunity to start the event.
    //                 return (
    //                     <>
    //                         <Typography variant="caption" color="secondary">
    //                             {notStartedLabel}
    //                         </Typography>
    
    //                         <Button variant="contained" size="small" disabled={false} color="primary" onClick={() => startEventAction(rowData)}>
    //                             {startLabel}
    //                         </Button>
    //                     </>
    //                 );
    //             }
    //         } else if (currentTime.isBefore(startTime)) {
    //             if (!eventStarted) {
    //                 // Event not started and It is not yet time to start the event. 
    //                 // Give user the opportunity to start the event
    //                 return (
    //                     <Button variant="contained" size="small" disabled={false} color="primary" onClick={() => startEventAction(rowData)}>
    //                         {startLabel}
    //                     </Button>
    //                 );
    //             }
    //         } else if (currentTime.isAfter(endTime)) {
    //             // Event should have started and ended
    //             if (eventStarted) {
    //                 // Silently mark event as ended
    //                 forcedToEndUpdate(rowData, false);
                    
    //                 return (
    //                     <CheckIcon style={{color: 'green'}}/>
    //                 );
    //             } else {
    //                 return (
    //                     <Typography variant="caption" color="secondary">
    //                         {notStartedLabel}
    //                     </Typography>
    //                 );
    //             }
    //         }
    //     }
    //     return null;
    // }
    
    // function sortDateTimeField(event) {
    //     // console.log("In sortDateTimeField(): event is: ", event);

    //     var values = [...newsRecords];
    //     values.sort((data1, data2) => {
    //         // Note: We are using actual DateTime object and not the dateTimeFormatted 
    //         // used for display on data table.
    //         let value1 = data1.CreatedTimestamp;
    //         let value2 = data2.CreatedTimestamp;
    //         let result = null;

    //         if (value1 == null && value2 != null)
    //             result = -1;
    //         else if (value1 != null && value2 == null)
    //             result = 1;
    //         else if (value1 == null && value2 == null)
    //             result = 0;
    //         else if (typeof value1 === 'string' && typeof value2 === 'string')
    //             result = value1.localeCompare(value2);
    //         else
    //             result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

    //         return (event.order * result);
    //     });

    //     return values;
    // }
    
    function sortDateField(event) {
        // console.log("In sortDateField(): event is: ", event);

        var values = [...newsRecords];
        values.sort((data1, data2) => {
            // Note: We are using actual DateTime object and not the dateTimeFormatted 
            // used for display on data table.
            let value1 = data1.StartTime;
            let value2 = data2.StartTime;
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });

        return values;
    }
    
    // function sortTimeField(event) {
    //     // console.log("In sortTimeField(): event is: ", event);

    //     var values = [...newsRecords];
    //     values.sort((data1, data2) => {
    //         // Note: We are using actual DateTime object and not the dateTimeFormatted 
    //         // used for display on data table.
    //         let value1 = data1.NewsCategory;
    //         let value2 = data2.NewsCategory;
    //         let result = null;

    //         if (value1 == null && value2 != null)
    //             result = -1;
    //         else if (value1 != null && value2 == null)
    //             result = 1;
    //         else if (value1 == null && value2 == null)
    //             result = 0;
    //         else if (typeof value1 === 'string' && typeof value2 === 'string')
    //             result = value1.localeCompare(value2);
    //         else
    //             result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

    //         return (event.order * result);
    //     });

    //     return values;
    // }

    let processedRecs = 0;

    async function deleteMultipleAction() {
        // console.log("In deleteMultipleAction(): selectedTableRows.length is: ", selectedTableRows.length);
        // console.log("In deleteMultipleAction(): selectedTableRows is: ", selectedTableRows);

        // Disable Save changes button
        setTableDirtyFlag(false);

        return new Promise((resolve, reject) => {
            if (selectedTableRows && selectedTableRows.length > 0) {
                selectedTableRows.map(record => {
                    setTimeout(async () => {
                        await deleteService(record);

                        processedRecs++;

                        if (processedRecs === selectedTableRows.length) {
                            resolve("Done");
                        }
                    }, 1000);
                });
            }
        });
    }

    async function processMultipleDeletionRecs() {
        // console.log("In processMultipleDeletionRecs()");

        //-------------------
        // Promise.all
        //-------------------
        Promise.all(
            [
                deleteMultipleAction()
            ]).then(async (results) => {
                // console.log("In processMultipleDeletionRecs(): results is: ", results);
                // console.log("In processMultipleDeletionRecs(): records deleted from AWS, now delete them from DataTable");
                // Remove deleted records from DataTable
                let remainingRecords = FilterArrayFromArray(newsRecords, selectedTableRows);
                if (remainingRecords) {
                    setNewsRecords(remainingRecords);
                    setSelectedTableRows([]);
                }
            });
    }

    function cloneEventAction() {
        setNewsStartTime(null);
        setNewsCategory(null);
        setEditModeFlag(false);
        setSubmitButtonFlag(false);
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        setNewsContent(item.NewsContent);

        setRecordToEdit(item);

        appContext.currentPK = item.NewsPK;
        appContext.currentSK = item.NewsSK;
        await getNews({NewsPK: item.NewsPK, NewsSK: item.NewsSK,});

        setTimeout(() => {
            setShowNewsListFlag(false);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);
            setShowNewsDetailsFormFlag(true);
        }, 500);
    }
  
    function sliceUrl(url, maxChars) {
        let retValue = url;
        if (url.length > maxChars) {
            retValue = url.substring(0, (maxChars - 1));
            retValue += " ...";
        }
        return retValue;
    }

    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    //--------------------------
    // For CommonMediaSelector
    //--------------------------
    const [dialogOpenFlag, setDialogOpenFlag] = useState(false);
    const [imagesLocationUri, setImagesLocationUri] = useState("");
    
    function onRowSelectedCallback(src) {
        // console.log("In onRowSelectedCallback(): src is: ", src);
        // console.log("In onRowSelectedCallback(): editorInstance is: ", editorInstance);
        // editorInstance.html.insert('<img src="'+ src + '" style="width: 300px;" class="fr-fic fr-dib">');
        setNewsBannerUrl(src ? src : "");
        setDialogOpenFlag(false);
    }

    function dialogCloseCallback() {
        // console.log("In dialogCloseCallback()");
        setDialogOpenFlag(false);
    }

    function selectImageAction() {
        // Open CommonMediaSelector
        setDialogOpenFlag(true);
        // setImageSelectorFlag(true);
    }

    //------------------------
    // For sliceText and Dialog
    //------------------------

    function sliceMoreAction(record) {
        setDialogData(record.NewsContent);
        setDialogHeader(record.NewsTitle);
        setDlgVisibleFlag(true);
    }

    function hideModalDlg() {
        setDlgVisibleFlag(false);
    }
  
    function sliceText(text, maxChars) {
        let retValue = text;
        if (text.length > maxChars) {
            retValue = text.substring(0, (maxChars - 1));
            retValue += " ...";
        }
        return retValue;
    }

    function LanguageAndDomain(props) {
        return (
            <>
                {isDeskTopView &&
                    <>
                        <Grid item xs={12} sm={6}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                        <label htmlFor="languageId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                *{languageLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="languageId"
                                            value={language}
                                            options={languages}
                                            getOptionLabel={option => option.languageName}
                                            getOptionSelected={option => option.languageName}
                                            onChange={handleLanguageChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={languagePrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={languageErrFlag}
                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                        <label htmlFor="domainId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                *{domainLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="domainId"
                                            value={domain}
                                            options={domainOptions}
                                            getOptionLabel={option => option.text}
                                            getOptionSelected={option => option.text}
                                            onChange={handleDomainChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={domainPrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={domainErrFlag}
                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }

                {isTabletView &&
                    <div>
                        <Grid item xs={12} sm={12}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                        <label htmlFor="languageId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                *{languageLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="languageId"
                                            value={language}
                                            options={languages}
                                            getOptionLabel={option => option.languageName}
                                            getOptionSelected={option => option.languageName}
                                            onChange={handleLanguageChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={languagePrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={languageErrFlag}
                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <LineSpacing value={12} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                        <label htmlFor="domainId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                *{domainLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="domainId"
                                            value={domain}
                                            options={domainOptions}
                                            getOptionLabel={option => option.text}
                                            getOptionSelected={option => option.text}
                                            onChange={handleDomainChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            // disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={domainPrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={domainErrFlag}
                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                }

                {isMobileView &&
                    <div className={clsx(classes.divPadding, classes.divWrapper)}>
                        <Grid container justify="center">
                            <Grid item xs={12} sm={6}>
                                <Grid container justify="center">
                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                            <label htmlFor="languageId" style={directionAlignment}>
                                                <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                    *{languageLabel}
                                                </Typography>
                                            </label>

                                            <Autocomplete
                                                id="languageId"
                                                value={language}
                                                options={languages}
                                                getOptionLabel={option => option.languageName}
                                                getOptionSelected={option => option.languageName}
                                                onChange={handleLanguageChange}
                                                loadingText={loadingText}
                                                noOptionsText={noOptionsText}
                                                autoHighlight={true}
                                                clearText={clearText}
                                                closeText={closeText}
                                                disabled={editModeFlag ? true : false}
                                                renderInput={params => 
                                                    <TextField 
                                                        {...params} 
                                                        placeholder={languagePrompt} 
                                                        variant="outlined"
                                                        fullWidth
                                                        error={languageErrFlag}
                                                        helperText={languageErrFlag ? languageErrMsg : ""}
                                                        // InputProps={{
                                                        //     readOnly: editModeFlag ? true : false,
                                                        // }}
                                                    />
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Grid container justify="center">
                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                            <label htmlFor="domainId" style={directionAlignment}>
                                                <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                    *{domainLabel}
                                                </Typography>
                                            </label>

                                            <Autocomplete
                                                id="domainId"
                                                value={domain}
                                                options={domainOptions}
                                                getOptionLabel={option => option.text}
                                                getOptionSelected={option => option.text}
                                                onChange={handleDomainChange}
                                                loadingText={loadingText}
                                                noOptionsText={noOptionsText}
                                                autoHighlight={true}
                                                clearText={clearText}
                                                closeText={closeText}
                                                // disabled={editModeFlag ? true : false}
                                                renderInput={params => 
                                                    <TextField 
                                                        {...params} 
                                                        placeholder={domainPrompt} 
                                                        variant="outlined"
                                                        fullWidth
                                                        error={domainErrFlag}
                                                        helperText={domainErrFlag ? domainErrMsg : ""}
                                                        // InputProps={{
                                                        //     readOnly: editModeFlag ? true : false,
                                                        // }}
                                                    />
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                }
            </>
        );
    }

    const cloneRecordAction = () => {
        setLanguage(null);
        setNewsCategory(null);
        setNewsStartTime(null);
        setNewsTitle("");

        setSubmitButtonFlag(false);
        setEditModeFlag(false);
    }

    //====================RETURN=====================================================================================

    return (
        <div dir={direction} className={clsx(classes.root, classes.divWrapper)}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {renderDomFlag &&
                <>
                    {/* { !appContext.signedIn && <Redirect to='/signIn' />} */}
                    <CssBaseline />

                    <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />

                    {isDeskTopView && 
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
                            <Container component="div" className={classes.main} maxWidth="lg">
                                <StyledPaper elevation={3} className={classes.paper}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h5" color="primary" style={{ textAlign: "center" }}>
                                                {manageNewsLabel}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                                                {requiredFields}
                                            </Typography>
                                        </Grid>

                                        {globalErrorFlag && (
                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={24} />
                                                <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                {showNewsListFlag &&
                                                    <>
                                                        <LanguageAndDomain/>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            {newsRecords && newsRecords.length > 0 ?
                                                                <>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-start">
                                                                                    <Button variant="contained" size="large" disabled={selectedTableRows && selectedTableRows.length > 0 ? false : true} color="primary" onClick={() => showAlertDialog(null, "multiple")}>
                                                                                        <DeleteIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {deleteLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={convertAction}>
                                                                                        <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {convertLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={uploadAction}>
                                                                                        <CloudUploadIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {uploadLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-end">
                                                                                    <Button variant="contained" size="large" disabled={false} color="primary" onClick={createMoreAction}>
                                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {createMoreLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12}>
                                                                        <LineSpacing value={8} />
                                                                    </Grid>
                                                                    
                                                                    {/* {newsRecords.map((record, index) => ( */}
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12}>
                                                                            <div className="datatable-doc-demo">
                                                                                <DataTable 
                                                                                    value={newsRecords}
                                                                                    resizableColumns={true} 
                                                                                    columnResizeMode={"fit"}
                                                                                    sortMode={"multiple"}
                                                                                    responsive={true}
                                                                                    reorderableColumns={true}

                                                                                    // selectionMode={"multiple"}
                                                                                    selection={selectedTableRows}
                                                                                    onSelectionChange={handleMultipleSelectionChange}

                                                                                    // paginator={visibleRowsCount > 10 ? paginatorFlag : !paginatorFlag} 
                                                                                    paginator={newsRecords && newsRecords.length > 10} 
                                                                                    // paginatorLeft={paginatorLeft}

                                                                                    paginatorTemplate={paginatorTemplate}
                                                                                    currentPageReportTemplate={"{first}" + " " + toLabel + " " + "{last}" + " " + ofLabel + " " + "{totalRecords}" + " " + recordsLabel} 
                                                                                    rows={visibleRowsCount} 
                                                                                    rowsPerPageOptions={rowsPerPageOptions}
                                                                                    // className="p-datatable-responsive-cob"
                                                                                    // className="p-datatable-striped"
                                                                                    emptyMessage={noRecordsFound}
                                                                                    className={clsx("p-datatable-striped", "p-datatable-customers")}
                                                                                    rowHover 
                                                                                    >

                                                                                    <Column 
                                                                                        header={deleteLabel} 
                                                                                        selectionMode="multiple" 
                                                                                        style={{width:'5em', textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="NewsCategory" 
                                                                                        header={newsCategoryLabel} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="AuthorNames" 
                                                                                        header={gridNames} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="NewsTitle" 
                                                                                        header={gridTitle} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="Tags" 
                                                                                        header={newsTagsLabel} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains"
                                                                                        style={{textAlign:'center'}} 
                                                                                    />
                                                                                    <Column 
                                                                                        field="FormattedStartTime" 
                                                                                        header={gridStartTime} 
                                                                                        sortable={true} 
                                                                                        sortFunction={sortDateField}
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="BannerUrl" 
                                                                                        header={gridBannerUrl} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    {/* <Column 
                                                                                        field="NewsContent" 
                                                                                        header={newsContentLabel} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    /> */}
                                                                                    <Column 
                                                                                        field="deleteIcon" 
                                                                                        header={deleteLabel} 
                                                                                        body={deleteIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="editIcon" 
                                                                                        header={editLabel} 
                                                                                        body={editIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                </DataTable>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            :
                                                                <>
                                                                    {noNewsRecordFoundFlag &&
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={12} sm={12}>
                                                                                <Typography variant="body1">
                                                                                    {noRecordsFound}
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <LineSpacing value={12} />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <Button variant="contained" size="large" disabled={false} color="primary" onClick={createLiveEventAction}>
                                                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                    {createNewsContentLabel}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                            }
                                                        </Grid>
                                                    </>
                                                }

                                                {showNewsDetailsFormFlag && 
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={6} sm={3}>
                                                                    <Grid container justify="flex-end">
                                                                        <Typography variant="subtitle1" color="primary">
                                                                            {yourTimeZoneMsg}: <span style={{color: '#000', paddingLeft: '8px'}}>{timeZone}</span>
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={6} sm={3}>
                                                                    <Grid container justify="center">
                                                                        <Button variant="contained" size="small" disabled={editModeFlag ? false : true} color="primary" onClick={cloneRecordAction}>
                                                                            {cloneRecordLabel}
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={12} sm={6}>
                                                                    <Grid container justify="center">
                                                                        <img src={newsBannerUrl} style={{height: '100px'}} />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <LanguageAndDomain/>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={newsCategoryErrFlag}>
                                                                        <label htmlFor="newsCategoryId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={newsCategoryErrFlag ? "secondary" : "inherit"}>
                                                                                *{newsCategoryLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="newsCategoryId"
                                                                            value={newsCategory}
                                                                            options={categoryOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleNewsCategoryChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            // disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={newsCategoryPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={newsCategoryErrFlag}
                                                                                    helperText={newsCategoryErrFlag ? newsCategoryErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={newsBannerUrlErrFlag}>
                                                                        <label htmlFor="newsBannerUrlId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={newsBannerUrlErrFlag ? "secondary" : "inherit"}>
                                                                                {newsBannerUrlLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <OutlinedInput
                                                                            id="newsBannerUrlId"
                                                                            value={newsBannerUrl}
                                                                            placeholder={newsBannerUrlPrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={newsBannerUrlErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            endAdornment={
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        aria-label={newsBannerUrlPrompt}
                                                                                        onClick={selectImageAction}
                                                                                    >
                                                                                        <ImageSearchIcon />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            }
                                                                        />
                                                                        <FormHelperText>{newsBannerUrlErrFlag ? newsBannerUrlErrMsg : ""}</FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={yourNamesErrFlag}>
                                                                        <label htmlFor="yourNamesId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={yourNamesErrFlag ? "secondary" : "inherit"}>
                                                                                *{yourNamesLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <TextField
                                                                            id="yourNamesId"
                                                                            value={yourNames}
                                                                            placeholder={yourNamesPrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={yourNamesErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            helperText={yourNamesErrFlag ? yourNamesErrMsg : yourNamesMsg}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={newsTitleErrFlag}>
                                                                        <label htmlFor="newsTitleId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={newsTitleErrFlag ? "secondary" : "inherit"}>
                                                                                *{newsTitleLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <TextField
                                                                            id="newsTitleId"
                                                                            value={newsTitle}
                                                                            placeholder={newsTitlePrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={newsTitleErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            helperText={newsTitleErrFlag ? newsTitleErrMsg : ""}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={newsStartTimeErrFlag}>
                                                                                <label htmlFor="newsStartTimeId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={newsStartTimeErrFlag ? "secondary" : "inherit"}>
                                                                                        *{newsStartTimeLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <KeyboardDateTimePicker
                                                                                    id="newsStartTimeId"
                                                                                    value={newsStartTime}
                                                                                    // views={["year"]}
                                                                                    onChange={handleNewsStartTimeChange}
                                                                                    clearable
                                                                                    placeholder={newsStartTimePrompt}
                                                                                    format="MMM DD, YYYY hh:mm a z"
                                                                                    // inputVariant="outlined"
                                                                                    invalidDateMessage={newsStartTimeErrMsg}
                                                                                    cancelLabel={cancelLabel}
                                                                                    clearLabel={clearLabel}
                                                                                    okLabel={okLabel}
                                                                                    // className={classes.phone}
                                                                                    inputVariant="outlined"
                                                                                    margin="none"
                                                                                />
                                                                                <FormHelperText>{newsStartTimeErrFlag ? newsStartTimeErrMsg : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={newsTagsErrFlag}>
                                                                                <label htmlFor="newsTagsId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={newsTagsErrFlag ? "secondary" : "inherit"}>
                                                                                        *{newsTagsLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextareaAutosize
                                                                                    id="newsTagsId"
                                                                                    aria-label={newsTagsLabel}
                                                                                    rows={1.5}
                                                                                    rowsMax={6}
                                                                                    value={newsTags}
                                                                                    placeholder={newsTagsPrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    // autoFocus={true}
                                                                                    margin="none"
                                                                                    className={classes.textArea}
                                                                                    maxLength={textMaxLength}
                                                                                />
                                                                                <FormHelperText>{newsTagsErrFlag ? newsTagsErrMsg : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={newsContentErrFlag}>
                                                                        <label htmlFor="newsContentId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={newsContentErrFlag ? "secondary" : "inherit"}>
                                                                                *{newsContentLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <FroalaCommonEditor
                                                                            editorId={"newsContentId"}
                                                                            editorRef={editorRef}
                                                                            // editorType={"minimal"} // Full Editor when not set
                                                                            placeholderPrompt={newsContentPrompt}
                                                                            editorMaxChars={editorMaxChars}
                                                                            editorModel={newsContent}
                                                                            modelChangeCallback={handleEditorHtmlBodyChange}
                                                                            referrer={"PostNews"}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={newsService}>
                                                                    {editModeFlag ?
                                                                    <>
                                                                        <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {updateNewsContentLabel} 
                                                                    </>
                                                                    : 
                                                                    <>
                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {createNewsContentLabel}
                                                                    </>
                                                                    }
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {uploadFlag && 
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeUploadForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <DomainUploadCapture 
                                                                showHeadersFlag={false}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                {convertFlag &&
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <CommonConverter 
                                                                groupingCategoryLabel={newsTitleLabel}
                                                                groupingCategoryPrompt={newsTitlePrompt}
                                                                groupingCategoryErrMsg={newsTitleErrMsg}

                                                                showHeadersFlag={false}
                                                                actionMsgLabel={uploadLabel}

                                                                referrer={"PostNews"}
                                                                categoryType={"news-titles"}

                                                                tableName={tableName}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={18} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledPaper>
                            </Container>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={48} />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    }

                    {isTabletView && 
                        <PostNewsTv
                            manageNewsLabel={manageNewsLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            newsBannerUrlLabel={newsBannerUrlLabel}
                            newsBannerUrlPrompt={newsBannerUrlPrompt}
                            newsBannerUrlErrMsg={newsBannerUrlErrMsg}
                                
                            yourNamesLabel={yourNamesLabel}
                            yourNamesPrompt={yourNamesPrompt}
                            yourNamesErrMsg={yourNamesErrMsg}
                            yourNamesMsg={yourNamesMsg}
                            
                            newsTitleLabel={newsTitleLabel}
                            newsTitlePrompt={newsTitlePrompt}
                            newsTitleErrMsg={newsTitleErrMsg}
                            selectTitlePrompt={selectTitlePrompt}
                    
                            newsTagsLabel={newsTagsLabel}
                            newsTagsPrompt={newsTagsPrompt}
                            newsTagsErrMsg={newsTagsErrMsg}
                        
                            newsStartTimeLabel={newsStartTimeLabel}
                            newsStartTimePrompt={newsStartTimePrompt}
                            newsStartTimeErrMsg={newsStartTimeErrMsg}
                    
                            newsCategoryLabel={newsCategoryLabel}
                            newsCategoryPrompt={newsCategoryPrompt}
                            newsCategoryErrMsg={newsCategoryErrMsg}
                    
                            // uploadNewsTitleLabel={uploadNewsTitleLabel}
                            // uploadNewsTitlePrompt={uploadNewsTitlePrompt}
                            // uploadNewsTitleErrMsg={uploadNewsTitleErrMsg}
                    
                            // newsRecordsLabel={newsRecordsLabel}
                            // inProgressLabel={inProgressLabel}
                            // startLabel={startLabel}
                            // endLabel={endLabel}
                            // notStartedLabel={notStartedLabel}
                            // dateCreatedLabel={dateCreatedLabel}
                        
                            newsContentLabel={newsContentLabel}
                            newsContentPrompt={newsContentPrompt}
                            yourTimeZoneMsg={yourTimeZoneMsg}

                            // timeZoneLabel={timeZoneLabel}
                            // toLabel={toLabel}
                            // ofLabel={ofLabel}
                            // recordsLabel={recordsLabel}
                            // noRecordsFound={noRecordsFound}
                            // statusLabel={statusLabel}
                            // editLabel={editLabel}
                            // deleteLabel={deleteLabel}
                    
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            cancelLabel={cancelLabel}
                            clearLabel={clearLabel}
                            okLabel={okLabel}
                            uploadLabel={uploadLabel}
                            createNewsContentLabel={createNewsContentLabel}
                            updateNewsContentLabel={updateNewsContentLabel}
                            // newsRecordLabel={newsRecordLabel}
                            createMoreLabel={createMoreLabel}
                            createLabel={createLabel}
                            closeLabel={closeLabel}
                            noRecordsFound={noRecordsFound} 
                            // startEventInfoMsg={startEventInfoMsg}
                    
                            gridTitle={gridTitle}
                            gridNames={gridNames}
                            // gridEventUrl={gridEventUrl}
                            // gridEventUrlType={gridEventUrlType}
                            gridStartTime={gridStartTime}
                            gridBannerUrl={gridBannerUrl}
                        
                            convertLabel={convertLabel}

                            sourceLanguageLabel={sourceLanguageLabel}
                            sourceLanguagePrompt={sourceLanguagePrompt}
                            sourceLanguageErrMsg={sourceLanguageErrMsg}

                            targetLanguageLabel={targetLanguageLabel}
                            targetLanguagePrompt={targetLanguagePrompt}
                            targetLanguageErrMsg={targetLanguageErrMsg}

                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            moreLabel={moreLabel}
                            
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showNewsListFlag={showNewsListFlag}
                            newsRecords={newsRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noNewsRecordFoundFlag={noNewsRecordFoundFlag}
                            createLiveEventAction={createLiveEventAction}
                            showNewsDetailsFormFlag={showNewsDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}
                            newsBannerUrl={newsBannerUrl}
                            newsCategoryErrFlag={newsCategoryErrFlag}
                            newsCategory={newsCategory}
                            categoryOptions={categoryOptions}
                            handleNewsCategoryChange={handleNewsCategoryChange}
                            newsBannerUrlErrFlag={newsBannerUrlErrFlag}
                            handleChange={handleChange}
                            selectImageAction={selectImageAction}
                            yourNamesErrFlag={yourNamesErrFlag}
                            yourNames={yourNames}
                            newsTitleErrFlag={newsTitleErrFlag}
                            newsTitle={newsTitle}
                            newsStartTimeErrFlag={newsStartTimeErrFlag}
                            newsStartTime={newsStartTime}
                            handleNewsStartTimeChange={handleNewsStartTimeChange}
                            newsTagsErrFlag={newsTagsErrFlag}
                            newsTags={newsTags}
                            textMaxLength={textMaxLength}
                            newsContentErrFlag={newsContentErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            newsContent={newsContent}
                            handleEditorHtmlBodyChange={handleEditorHtmlBodyChange}
                            submitButtonFlag={submitButtonFlag}
                            newsService={newsService}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            closeConvertForm={closeConvertForm}

                            LanguageAndDomain={LanguageAndDomain}
                            globalErrorMsg={globalErrorMsg}
                        />
                    }

                    {isMobileView && 
                        <PostNewsMv
                            manageNewsLabel={manageNewsLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            newsBannerUrlLabel={newsBannerUrlLabel}
                            newsBannerUrlPrompt={newsBannerUrlPrompt}
                            newsBannerUrlErrMsg={newsBannerUrlErrMsg}
                                
                            yourNamesLabel={yourNamesLabel}
                            yourNamesPrompt={yourNamesPrompt}
                            yourNamesErrMsg={yourNamesErrMsg}
                            yourNamesMsg={yourNamesMsg}
                            
                            newsTitleLabel={newsTitleLabel}
                            newsTitlePrompt={newsTitlePrompt}
                            newsTitleErrMsg={newsTitleErrMsg}
                            selectTitlePrompt={selectTitlePrompt}
                    
                            newsTagsLabel={newsTagsLabel}
                            newsTagsPrompt={newsTagsPrompt}
                            newsTagsErrMsg={newsTagsErrMsg}
                        
                            newsStartTimeLabel={newsStartTimeLabel}
                            newsStartTimePrompt={newsStartTimePrompt}
                            newsStartTimeErrMsg={newsStartTimeErrMsg}
                    
                            newsCategoryLabel={newsCategoryLabel}
                            newsCategoryPrompt={newsCategoryPrompt}
                            newsCategoryErrMsg={newsCategoryErrMsg}
                    
                            // uploadNewsTitleLabel={uploadNewsTitleLabel}
                            // uploadNewsTitlePrompt={uploadNewsTitlePrompt}
                            // uploadNewsTitleErrMsg={uploadNewsTitleErrMsg}
                    
                            // newsRecordsLabel={newsRecordsLabel}
                            // inProgressLabel={inProgressLabel}
                            // startLabel={startLabel}
                            // endLabel={endLabel}
                            // notStartedLabel={notStartedLabel}
                            // dateCreatedLabel={dateCreatedLabel}
                        
                            newsContentLabel={newsContentLabel}
                            newsContentPrompt={newsContentPrompt}
                            yourTimeZoneMsg={yourTimeZoneMsg}

                            // timeZoneLabel={timeZoneLabel}
                            // toLabel={toLabel}
                            // ofLabel={ofLabel}
                            // recordsLabel={recordsLabel}
                            // noRecordsFound={noRecordsFound}
                            // statusLabel={statusLabel}
                            // editLabel={editLabel}
                            // deleteLabel={deleteLabel}
                    
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            cancelLabel={cancelLabel}
                            clearLabel={clearLabel}
                            okLabel={okLabel}
                            uploadLabel={uploadLabel}
                            createNewsContentLabel={createNewsContentLabel}
                            updateNewsContentLabel={updateNewsContentLabel}
                            // newsRecordLabel={newsRecordLabel}
                            createMoreLabel={createMoreLabel}
                            createLabel={createLabel}
                            closeLabel={closeLabel}
                            noRecordsFound={noRecordsFound} 
                            // startEventInfoMsg={startEventInfoMsg}
                    
                            gridTitle={gridTitle}
                            gridNames={gridNames}
                            // gridEventUrl={gridEventUrl}
                            // gridEventUrlType={gridEventUrlType}
                            gridStartTime={gridStartTime}
                            gridBannerUrl={gridBannerUrl}
                        
                            convertLabel={convertLabel}

                            sourceLanguageLabel={sourceLanguageLabel}
                            sourceLanguagePrompt={sourceLanguagePrompt}
                            sourceLanguageErrMsg={sourceLanguageErrMsg}

                            targetLanguageLabel={targetLanguageLabel}
                            targetLanguagePrompt={targetLanguagePrompt}
                            targetLanguageErrMsg={targetLanguageErrMsg}

                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            moreLabel={moreLabel}
    
                            domainLabel={domainLabel}
                            domainPrompt={domainPrompt}
                            domainErrMsg={domainErrMsg}
                            domainErrFlag={domainErrFlag}
                            domain={domain}
                            domainOptions={domainOptions}
                            handleDomainChange={handleDomainChange}
                                                                    
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showNewsListFlag={showNewsListFlag}
                            newsRecords={newsRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noNewsRecordFoundFlag={noNewsRecordFoundFlag}
                            createLiveEventAction={createLiveEventAction}
                            showNewsDetailsFormFlag={showNewsDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}
                            newsBannerUrl={newsBannerUrl}
                            newsCategoryErrFlag={newsCategoryErrFlag}
                            newsCategory={newsCategory}
                            categoryOptions={categoryOptions}
                            handleNewsCategoryChange={handleNewsCategoryChange}
                            newsBannerUrlErrFlag={newsBannerUrlErrFlag}
                            handleChange={handleChange}
                            selectImageAction={selectImageAction}
                            yourNamesErrFlag={yourNamesErrFlag}
                            yourNames={yourNames}
                            newsTitleErrFlag={newsTitleErrFlag}
                            newsTitle={newsTitle}
                            newsStartTimeErrFlag={newsStartTimeErrFlag}
                            newsStartTime={newsStartTime}
                            handleNewsStartTimeChange={handleNewsStartTimeChange}
                            newsTagsErrFlag={newsTagsErrFlag}
                            newsTags={newsTags}
                            textMaxLength={textMaxLength}
                            newsContentErrFlag={newsContentErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            newsContent={newsContent}
                            handleEditorHtmlBodyChange={handleEditorHtmlBodyChange}
                            submitButtonFlag={submitButtonFlag}
                            newsService={newsService}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            closeConvertForm={closeConvertForm}

                            globalErrorMsg={globalErrorMsg}
                        />
                    }
                </>
            }

            <Dialog 
                id="modalDlgId"
                rtl={appContext.dirction === 'rtl'}
                showHeader={true}
                header={dialogHeader}
                visible={dlgVisibleFlag} 
                maximizable={true}
                maximized={(isTabletView || isMobileView) ? true : false}
                modal={true} 
                onHide={hideModalDlg}
                >
                <Container component="div" className={classes.modalDlgMain} maxWidth="md">
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="flex-start" style={{color: '#000'}}>
                            <FroalaEditorView model={dialogData}/>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>

            <CommonMediaSelector
                referrer={"PostNews"}
                mediaLanguageCode={language ? language.languageCode : null}
                mediaDomainName={domain ? domain.value : null}
                mediaActionFlag={showNewsDetailsFormFlag}
                queryType={"media"} // media, audios or videos
                
                dialogOpenFlag={dialogOpenFlag}
                onRowSelectedCallback={onRowSelectedCallback}
                dialogCloseCallback={dialogCloseCallback}
            />

            {alertOpen && 
                <AlertDialog
                    alertOpen={alertOpen}
                    alertClose={alertClose}
                    alertClickAgree={alertClickAgree}
                    alertClickDisagree={alertClickDisagree}

                    dialogSubContent={dialogSubContent}
                />
            }

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={8000}
                onClose={snackbarOnClose}
                ContentProps={{ "aria-describedby": "message-id" }}
                message={<span id="message-id">{snackbarsMsg}</span>}
                variant={snackbarsVariant}
            />

            <footer className={classes.footer}>
                <div>
                    <PageFooter isSignedIn={appContext.signedIn} />
                </div>
            </footer>
        </div>
    )
}

export default PostNews;
