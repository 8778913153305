import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
// import FormControl from '@material-ui/core/FormControl';
// import Link from '@material-ui/core/Link';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";

// AG-Grid
import { AgGridReact, AgGridColumn } from "ag-grid-react";
//import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

// Amplify
import Amplify, { API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// import GlobalConstants from '../../utils/GlobalConstants';
// import GeneratePassword from '../../utils/GeneratePassword';
// import GenerateClientId from '../../utils/GenerateClientId';
// import GenerateTransactionId from '../../utils/GenerateTransactionId';
// import GenerateRandomHash from '../../utils/GenerateRandomHash';
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import LineSpacing from "../../components/misc/LineSpacing";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// Renderers
// import NumberCellRenderer from '../../components/renderers/NumberCellRenderer';
import ActionsCellRenderer from "../../components/renderers/ActionsCellRenderer";
// import CurrencyCellRenderer from '../../components/renderers/CurrencyCellRenderer';
// import CountryCellRenderer from '../../components/renderers/CountryCellRenderer';

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 12px 0 12px 0 !important;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    backgroundImage: `url(${"assets/logos/background20.png"})`,
  },
  gridWidth: {
    width: "80%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  textField: {
    width: "100%",
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  formControlLabel: {
    margin: theme.spacing(1),
  },
  verticalAlignLabel: {
    // textAlign: 'left',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  footer: {
    bottom: 0,
    height: "1rem" /* Footer height */,
    width: "100% !important",
    textAlign: "center",
    // position: 'absolute',
    // bottom: 0,
    // width: '100%',
    // height: '1rem',           /* Footer height */

    // width: '100% !important',
    // marginTop: 'auto',
    // textAlign: 'center',
  },
  gridHeader: {
    fontWeight: "bold",
    textAlign: "center !important",
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

let localeText = {
  page: "Page",
  to: "to",
  of: "of",
  noRowsToShow: "No records found",
};

// let localeText2 = {
//   page: "daPage",
//   more: "daMore",
//   to: "daTo",
//   of: "daOf",
//   next: "daNexten",
//   last: "daLasten",
//   first: "daFirsten",
//   previous: "daPreviousen",
//   loadingOoo: "daLoading...",
//   selectAll: "daSelect Allen",
//   searchOoo: "daSearch...",
//   blanks: "daBlanc",
//   filterOoo: "daFilter...",
//   applyFilter: "daApplyFilter...",
//   equals: "daEquals",
//   notEqual: "daNotEqual",
//   lessThan: "daLessThan",
//   greaterThan: "daGreaterThan",
//   lessThanOrEqual: "daLessThanOrEqual",
//   greaterThanOrEqual: "daGreaterThanOrEqual",
//   inRange: "daInRange",
//   contains: "daContains",
//   notContains: "daNotContains",
//   startsWith: "daStarts dawith",
//   endsWith: "daEnds dawith",
//   andCondition: "daAND",
//   orCondition: "daOR",
//   group: "laGroup",
//   columns: "laColumns",
//   filters: "laFilters",
//   rowGroupColumns: "laPivot Cols",
//   rowGroupColumnsEmptyMessage: "la drag cols to group",
//   valueColumns: "laValue Cols",
//   pivotMode: "laPivot-Mode",
//   groups: "laGroups",
//   values: "laValues",
//   pivots: "laPivots",
//   valueColumnsEmptyMessage: "la drag cols to aggregate",
//   pivotColumnsEmptyMessage: "la drag here to pivot",
//   toolPanelButton: "la tool panel",
//   noRowsToShow: "la no rows",
//   pinColumn: "laPin Column",
//   valueAggregation: "laValue Agg",
//   autosizeThiscolumn: "laAutosize Diz",
//   autosizeAllColumns: "laAutsoie em All",
//   groupBy: "laGroup by",
//   ungroupBy: "laUnGroup by",
//   resetColumns: "laReset Those Cols",
//   expandAll: "laOpen-em-up",
//   collapseAll: "laClose-em-up",
//   toolPanel: "laTool Panelo",
//   export: "laExporto",
//   csvExport: "la CSV Exportp",
//   excelExport: "la Excel Exporto (.xlsx)",
//   excelXmlExport: "la Excel Exporto (.xml)",
//   pinLeft: "laPin &lt;&lt;",
//   pinRight: "laPin &gt;&gt;",
//   noPin: "laDontPin &lt;&gt;",
//   sum: "laSum",
//   min: "laMin",
//   max: "laMax",
//   none: "laNone",
//   count: "laCount",
//   average: "laAverage",
//   copy: "laCopy",
//   copyWithHeaders: "laCopy Wit hHeaders",
//   ctrlC: "ctrl n C",
//   paste: "laPaste",
//   ctrlV: "ctrl n V"
// };

// For AG-Grid

//------------------------------
// RENDERERS
//------------------------------
const gridOptions = {
  style: "currency", // decimal, currency, percent
  currency: "USD",
  currencyDisplay: "symbol", // symbol, code, name to use a localized currency name such as "dollar"
  useGrouping: true,
  minimumIntegerDigits: 21,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  payload: {},
};

let createLang = {
  selectLanguageLabel: "Select language",
  selectLanguagePrompt: "Select language",

  dataLanguageCodeLabel: "Data language code",
  dataLanguageCodePrompt: "Enter data language code",

  dataLanguageNameLabel: "Data language name",
  dataLanguageNamePrompt: "Enter data language name",
  selectLanguage: "Please select a language",

  submit: "Submit",
  deleteLabel: "Delete",
  createLangSubmitSuccessMsg: "Create languages was successful.",
  createLangSubmitFailureMsg: "Error while creating languages.",
  createDataLanguages: "Create data languages",
  addToGrid: "Add to grid",
  allFieldsAreRequired: "All fields are required",
  maxRecordsAllowed:
    "Maximum number of records allowed in grid is {MAX_RECORDS_ALLOWED}.",
  totalNumberRows: "Total number of rows",

  // For Grid
  // gridPage: "Page",
  // gridTo: "to",
  // gridOf: "of",
  noRowsToShow: "No records found",
};

function CreateDataLanguages(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const [languageCode, setLanguageCode] = useState("");
  const [languageName, setLanguageName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [payload, setPayload] = useState({});
  const [rowData, setRowData] = useState([]);

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLang.value);
  const [langSelectOptions, setLangSelectOptions] = useState([]);

  // AG-Grid
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [progressFlag, setProgressFlag] = useState(false);
  const [addToGridFlag, setAddToGridFlag] = useState(false);
  const [rowSelectedFlag, setRowSelectedFlag] = useState(false);
  const [gridSubmitFlag, setGridSubmitFlag] = useState(false);
  const [gridRowsCount, setGridRowsCount] = useState(0);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  // For Error Flags
  const [languageCodeErrFlag, setLanguageCodeErrFlag] = useState(false);
  const [languageCodeFocus, setLanguageCodeFocus] = useState(true);
  const [languageNameErrFlag, setLanguageNameErrFlag] = useState(false);
  const [languageNameFocus, setLanguageNameFocus] = useState(false);

  const [redirectSignUpFlag, setRedirectSignUpFlag] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  // For Resources
  const [createLangRes, setCreateLangRes] = useState(createLang);

  // For Grid Resources
  const [localeTextRes, setLocaleTextRes] = useState(localeText);

  const [renderGridFlag, setRenderGridFlag] = useState(false);

  //console.log('AmplifyConfig is: ', JSON.stringify(AmplifyConfig, null, 2));
  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  //const { currentLanguage } = props;

  // Maximum number of rows allowed in the grid
  const MAX_RECORDS_ALLOWED = 50;

  const {
    selectLanguageLabel,
    selectLanguagePrompt,

    dataLanguageCodeLabel,
    dataLanguageCodePrompt,

    dataLanguageNameLabel,
    dataLanguageNamePrompt,
    selectLanguage,

    submit,
    deleteLabel,
    createLangSubmitSuccessMsg,
    createLangSubmitFailureMsg,
    createDataLanguages,
    addToGrid,
    allFieldsAreRequired,
    maxRecordsAllowed,
    totalNumberRows,
  } = createLangRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(createLang);
    // console.log("In CreateDataLanguages(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In CreateDataLanguages(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "selectLanguageLabel")
              createLang.selectLanguageLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectLanguagePrompt")
              createLang.selectLanguagePrompt = item.ResourceDesc;

            if (item.ResourceCode === "dataLanguageCodeLabel")
              createLang.dataLanguageCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "dataLanguageNameLabel")
              createLang.dataLanguageNameLabel = item.ResourceDesc;
            if (item.ResourceCode === "dataLanguageCodePrompt")
              createLang.dataLanguageCodePrompt = item.ResourceDesc;
            if (item.ResourceCode === "dataLanguageNamePrompt")
              createLang.dataLanguageNamePrompt = item.ResourceDesc;
            if (item.ResourceCode === "dataLanguageNamePrompt")
              createLang.dataLanguageNamePrompt = item.ResourceDesc;
            if (item.ResourceCode === "selectLanguage")
              createLang.selectLanguage = item.ResourceDesc;

            if (item.ResourceCode === "submit")
              createLang.submit = item.ResourceDesc;
            if (item.ResourceCode === "deleteLabel")
              createLang.deleteLabel = item.ResourceDesc;
            if (item.ResourceCode === "createLangSubmitSuccessMsg")
              createLang.createLangSubmitSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "createLangSubmitFailureMsg")
              createLang.createLangSubmitFailureMsg = item.ResourceDesc;
            if (item.ResourceCode === "createDataLanguages")
              createLang.createDataLanguages = item.ResourceDesc;
            if (item.ResourceCode === "addToGrid")
              createLang.addToGrid = item.ResourceDesc;
            if (item.ResourceCode === "allFieldsAreRequired")
              createLang.allFieldsAreRequired = item.ResourceDesc;
            if (item.ResourceCode === "maxRecordsAllowed")
              createLang.maxRecordsAllowed = item.ResourceDesc;
            if (item.ResourceCode === "totalNumberRows")
              createLang.totalNumberRows = item.ResourceDesc;

            // For Grid
            // if (item.ResourceCode === "gridPage") localeText.page = item.ResourceDesc;
            // if (item.ResourceCode === "gridTo") localeText.to = item.ResourceDesc;
            // if (item.ResourceCode === "gridOf") localeText.from = item.ResourceDesc;
            if (item.ResourceCode === "noRowsToShow")
              localeText.noRowsToShow = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, createLang);
        }

        setCreateLangRes(createLang);
        setLocaleTextRes(localeText);

        setColumnDefs(columnDefsArray);

        setRenderGridFlag(true);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setCreateLangRes(createLang);
        setProgressFlag(false);
      });
  }

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    console.log("In handleLanguageChange(): selectedOption is", selectedOption);
    setSelectedLanguage(selectedOption);

    // setReferenceLanguage(selectedOption);
    setLanguageCode("");
    setLanguageName("");
    setLanguageCodeFocus(true);
  }

  let selectOptions = [];

  function fetchLanguages() {
    // console.log('In fetchLanguages()');
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages(): response.length is: ', response.data.Items.length);
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            // label: item.LanguageName,
            text: item.LanguageName,
          };
          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log('In CreateDataLanguages(): useEffect(): currentLanguage is: ', currentLanguage);
    // console.log("In CreateDataLanguages(): useEffect(): defaultLang is: ", defaultLang);
    // console.log('In CreateDataLanguages(): useEffect(): appContext.currentLanguage is: ', appContext.currentLanguage);
    // setSelectedLanguage(defaultLang);

    setLanguageCodeFocus(true);
    setLanguageNameFocus(false);

    // setRowData([]);
  }, []);

  useLayoutEffect(() => {
    // console.log("In CreateDataLanguages(): useLayoutEffect(): defaultLang is: ", defaultLang);

    if (selectOptions.length === 0) {
      fetchLanguages();
    }

    // Fetch resource from catche
    let cacheItemKey = "createLang";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In CreateDataLanguages(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In CreateDataLanguages(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In CreateDataLanguages(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In CreateDataLanguages(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In CreateDataLanguages(): useLayoutEffect(): got resource from cache');
      setCreateLangRes(cacheItem);
    }
  }, []);

  function handleChange(event) {
    //console.log("In handleChange(): id is: "+event.target.id);

    setGlobalErrorFlag(false);
    setAddToGridFlag(false);

    switch (event.target.id) {
      case "languageCodeId":
        setLanguageCodeErrFlag(false);
        setLanguageCode(event.target.value);

        // Enable button
        if (
          selectedLanguage !== null &&
          event.target.value !== null &&
          event.target.value !== "" &&
          languageName !== null &&
          languageName !== ""
        ) {
          setAddToGridFlag(true);
        }
        break;
      case "languageNameId":
        setLanguageNameErrFlag(false);
        setLanguageName(event.target.value);

        // Enable button
        if (
          selectedLanguage !== null &&
          languageCode !== null &&
          languageCode !== "" &&
          event.target.value !== null &&
          event.target.value !== ""
        ) {
          setAddToGridFlag(true);
        }
        break;
      default:
        break;
    }
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function validateForm() {
    let formErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setLanguageCodeErrFlag(false);
    setLanguageNameErrFlag(false);

    if (languageCode === null || languageCode === "") {
      formErrFlag = true;
      setLanguageCodeErrFlag(true);
    }

    if (languageName === null || languageName === "") {
      formErrFlag = true;
      setLanguageNameErrFlag(true);
    }

    return formErrFlag;
  }

  async function languageService() {
    console.log(
      `In languageService(): languageCode is: ${languageCode} and languageName is: ${languageName} and selectedLanguage is: ${selectedLanguage}`
    );

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    // Test some utils functions
    // console.log('Generated Random Hash is: ', GenerateRandomHash());
    // console.log('Generated ClientId is: ', GenerateClientId());
    // console.log('Generated TransactionId is: ', GenerateTransactionId());
    // console.log('Generated Password is: ', GeneratePassword('jekwempu@tobisoft.co'));

    // Get Grid data
    const gridRowCount = getRowCount();
    // console.log('In languageService(): gridRowCount is: ', gridRowCount);

    if (gridRowCount > 0) {
      // we have data to save
      // console.log('We have data to save.');

      const rowsData = [];

      // const d = new Date();
      const timestamp = new Date();
      // const timestamp = d.toISOString();
      // console.log("In languageService(): timestamp is: ", timestamp);

      gridApi.forEachNode(function (node) {
        // Process each node (row)
        let rowData = {
          ResourcePK: "cms-languages",
          ResourceSK: selectedLanguage + "#" + node.data.languageCode,

          LanguageName: node.data.languageName,
          ResourceCode: node.data.languageCode,

          ModifiedBy: appContext.userName,
          CreatedAt: timestamp,
          UpdatedAt: timestamp,
        };

        rowsData.push(rowData);
      });

      let params = {
        apiName: "ResourceAPI",
        path: "/dynamodb/resources/batchWrite",
        payload: {
          items: rowsData,
        },
      };

      let initObj = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        response: true, // return entire response object instead of response.data
        body: {},
      };

      initObj.headers.Authorization = appContext.jwtToken;
      initObj.body = params.payload;
      // console.log('In languageService(): initObj is: ', JSON.stringify(initObj, null, 2));

      setProgressFlag(true);

      API.post(params.apiName, params.path, initObj)
        .then((response) => {
          // console.log('In languageService(): response is: ', JSON.stringify(response, null, 2));

          // Clear the grid of any data
          clearGridData();

          // Show Snackbar
          setSnackbarsVariant("success");
          setSnackbarsMsg(createLangSubmitSuccessMsg);
          setSnackbarOpen(true);

          setProgressFlag(false);
        })
        .catch((err) => {
          console.log(
            "In languageService(): err is: ",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setGlobalErrorMsg(createLangSubmitFailureMsg);

          setProgressFlag(false);
        });
    }
  }

  async function redirectSignUp() {
    setRedirectSignUpFlag(true);
  }

  //===================================================

  gridOptions.payload = payload;

  // Grid Functions

  const deleteRows = (e) => {
    //console.log("In deleteRows(): gridApi is: ", gridApi);
    //console.log("In deleteRows(): gridApi.getSelectedNodes() is: ", gridApi.getSelectedNodes());
    var selectedRows = gridApi.getSelectedRows();
    var res = gridApi.updateRowData({ remove: selectedRows });
    // console.log("In deleteRows(): res is: ", res);

    // Update number of rows in grid
    getRowCount();
    initializeForm();

    // Disable Submit button if need be
    let gridRowsCount = gridApi.getModel().getRowCount();
    if (gridRowsCount === 0) {
      setGridSubmitFlag(false);
    }

    // if (gridApi && gridApi.getSelectedNodes().length > 0) {
    //   const selectedNodes = gridApi.getSelectedNodes();
    //   const selectedData = selectedNodes.map( node => node.data );
    //   const selectedDataStringPresentation = selectedData.map( node => node.make + ' ' + node.model).join(', ');
    //   console.log(`In deleteRows(): Selected nodes: ${selectedDataStringPresentation}`);
    //   setRowSelectedFlag(false);
    // }
  };

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    //gridApi = params.api;
    //gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
  }

  const onCellClicked = (event) => {
    // console.log('onCellClicked: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellClicked: event.colDef.field is: ', event.colDef.field);
    if (event.colDef.field === "delete") {
      var selectedData = gridApi.getSelectedRows();
      var res = gridApi.updateRowData({ remove: selectedData });
      // console.log("In onCellClicked() res is: ", res);

      // Update number of rows in grid
      getRowCount();
      initializeForm();

      // Disable Submit button if need be
      let gridRowsCount = gridApi.getModel().getRowCount();
      if (gridRowsCount === 0) {
        setGridSubmitFlag(false);
      }
    }
  };

  const onCellValueChanged = (event) => {
    // console.log('onCellValueChanged: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellValueChanged: event.colDef.field is: ', event.colDef.field);
  };

  const onRowSelected = (event) => {
    //gridApi = event.api;
    //console.log('onRowSelected: event is: ', event);
    //console.log('onRowSelected: event.data is: ', JSON.stringify(event.data, null, 2));
    setRowSelectedFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getSelectedNodes().length > 0
    ) {
      setRowSelectedFlag(true);
      // console.log('onRowSelected: after rowSelectedFlag is: ', rowSelectedFlag);
    }
  };

  const onRowClicked = (event) => {
    // console.log('onRowClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onRowClicked: event.data is: ', JSON.stringify(event.data, null, 2));
  };

  const onRowValueChanged = (event) => {
    // console.log('onRowValueChanged: event is:  ', event);
  };

  const onRowDataChanged = (event) => {
    setColumnDefs(columnDefsArray);
    setLocaleTextRes(localeText);
    //gridApi = event.api;
    // console.log('onRowDataChanged: event is: ', event);
    setGridSubmitFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getModel().getRowCount() > 0
    ) {
      // console.log('onRowDataChanged: gridApi.getModel().getRowCount() is: ', gridApi.getModel().getRowCount());
      setGridSubmitFlag(true);
    }
  };

  const onRowDataUpdated = (event) => {
    // console.log('onRowDataUpdated: event is:  ', event);
  };

  const onFirstDataRendered = (event) => {
    // console.log('onFirstDataRendered: event is:  ', event);
  };

  function clearGridData() {
    gridApi.setRowData([]);

    getRowCount();

    // Disable Submit button
    setGridSubmitFlag(false);
  }

  function getDridData() {
    var gridData = [];
    gridApi.forEachNode(function (node) {
      gridData.push(node.data);
    });
    // console.log("In getDridData(): Grid Data is: ", gridData);
    return gridData;
  }

  function initializeForm() {
    // console.log("In initializeForm()");

    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setLanguageCode("");
    setLanguageName("");
  }

  function getRowCount() {
    let gridRowsCount = gridApi.getModel().getRowCount();
    setGridRowsCount(gridRowsCount);
    return gridRowsCount;
  }

  function addRecordToGrid() {
    // console.log("In addRecordToGrid(): gridApi is: ", gridApi);
    // console.log("In addRecordToGrid(): languageCode is: ", languageCode);
    // console.log("In addRecordToGrid(): languageName is: ", languageName);

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    if (!validateForm()) {
      // Validation Passed - you can proceed
      // console.log('Validation Passed.');
      setAddToGridFlag(false);
      setGridSubmitFlag(true);

      // Make sure max records allowed in grid is not exceeded
      if (getRowCount() >= MAX_RECORDS_ALLOWED) {
        setGlobalErrorMsg(
          maxRecordsAllowed.replace(
            "{MAX_RECORDS_ALLOWED}",
            MAX_RECORDS_ALLOWED
          )
        );
        setGlobalErrorFlag(true);
        //setMaxRowsFlag(true);
        return;
      }

      // Add form data to grid
      let newItem = {
        languageCode: languageCode,
        languageName: languageName,
      };
      var res = gridApi.updateRowData({ add: [newItem] });
      // console.log("In addRecordToGrid(): res is: ", res);

      // Update number of rows in grid
      getRowCount();

      // Initialize Variables
      initializeForm();
    }
  }

  function snackbarOnClose(event, reason) {
    setSnackbarOpen(false);
  }

  //----------------------------
  // AG-GRID Column Definitions
  //----------------------------

  let columnDefsArray = [
    {
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressMenu: true,
      resizable: true,
    },
    {
      headerName: dataLanguageCodeLabel,
      field: "languageCode",
      width: 430,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: false,
    },
    {
      headerName: dataLanguageNameLabel,
      field: "languageName",
      width: 420,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: false,
    },
    {
      headerName: deleteLabel,
      field: "delete",
      width: 250,
      filter: false,
      sortable: false,
      resizable: true,
      cellRendererFramework: ActionsCellRenderer,
    },
  ];

  const [columnDefs, setColumnDefs] = useState(columnDefsArray);

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* !appContext.signedIn &&
          <Redirect to='/signIn' />
        */}
        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%", height: "100%" }}>
            {/* progressFlag &&
              <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={30}/>
              </div>
            */}
            <Container component="div" className={classes.main} maxWidth="md">
              <Grid container justify="center">
                <Grid item xs={12} sm={9}>
                  <StyledPaper elevation={3} className={classes.paper}>
                    <Typography
                      variant="h6"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      {createDataLanguages}
                    </Typography>

                    <Typography
                      variant="caption"
                      style={{ color: "green", textAlign: "center" }}
                    >
                      {allFieldsAreRequired}
                    </Typography>

                    {globalErrorFlag && (
                      <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                    )}

                    <LineSpacing value={24} />

                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="formLanguageId"
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {selectLanguageLabel}
                        </Typography>
                      </label>
                      <Select
                        id="formLanguageId"
                        value={selectedLanguage}
                        placeholder={selectLanguagePrompt}
                        onChange={handleLanguageChange}
                        variant="outlined"
                        displayEmpty={true}
                        autoFocus
                      >
                        <MenuItem value={null} disabled>
                          <Typography variant="body1">
                            {selectLanguage}
                          </Typography>
                        </MenuItem>
                        {langSelectOptions.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <label
                        htmlFor="languageCodeId"
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {dataLanguageCodeLabel}
                        </Typography>
                      </label>
                      <OutlinedInput
                        id="languageCodeId"
                        value={languageCode}
                        placeholder={dataLanguageCodePrompt}
                        onChange={handleChange}
                        required
                        fullWidth
                        // autoFocus
                        type={"text"}
                        error={languageCodeErrFlag}
                        margin="none"
                      />
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <label
                        htmlFor="languageNameId"
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {dataLanguageNameLabel}
                        </Typography>
                      </label>
                      <OutlinedInput
                        id="languageNameId"
                        value={languageName}
                        placeholder={dataLanguageNamePrompt}
                        onChange={handleChange}
                        required
                        fullWidth
                        type={"text"}
                        error={languageNameErrFlag}
                        margin="none"
                      />
                    </FormControl>

                    <LineSpacing value={18} />

                    <Button
                      variant="contained"
                      size="large"
                      disabled={!addToGridFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={addRecordToGrid}
                    >
                      <AddIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {addToGrid}
                    </Button>
                  </StyledPaper>
                </Grid>
              </Grid>
            </Container>

            {/* AG-GRID */}

            {renderGridFlag && (
              <Container component="div" className={classes.main} maxWidth="lg">
                <Grid container justify="center">
                  <Grid item xs>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!rowSelectedFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={deleteRows}
                      style={directionFloat}
                    >
                      <DeleteIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {deleteLabel}
                    </Button>
                  </Grid>

                  <Grid item xs>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!gridSubmitFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={languageService}
                      style={directionReverseFloat}
                    >
                      <SaveIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {submit}
                    </Button>
                  </Grid>
                </Grid>

                <div style={{ width: "100%", height: "100%" }}>
                  <div
                    id="refGrid"
                    style={{
                      // height: "400px",
                      width: "100%",
                    }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      localeText={localeTextRes}
                      domLayout="autoHeight"
                      onGridReady={onGridReady}
                      columnDefs={columnDefs}
                      rowData={rowData}
                      rowSelection="multiple"
                      rowMultiSelectWithClick={true}
                      rowDeselection={true}
                      enablePivot={true}
                      enableValue={true}
                      gridOptions={gridOptions}
                      enableRtl={enableRtl}
                      onCellClicked={onCellClicked}
                      onCellValueChanged={onCellValueChanged}
                      onRowSelected={onRowSelected}
                      onRowClicked={onRowClicked}
                      onRowValueChanged={onRowValueChanged}
                      onRowDataChanged={onRowDataChanged}
                      onRowDataUpdated={onRowDataUpdated}
                      onFirstDataRendered={onFirstDataRendered}
                    />
                  </div>
                </div>

                <Typography
                  variant="caption"
                  color="primary"
                  style={directionReverseFloat}
                >
                  {totalNumberRows}: {gridRowsCount}
                </Typography>
              </Container>
            )}
          </div>

          {/* variant: 'success', 'warning', 'error', 'info' */}
          <TsiCustomizedSnackbars
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={8000}
            onClose={snackbarOnClose}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">{snackbarsMsg}</span>}
            variant={snackbarsVariant}
          />
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter isSignedIn={isSignedIn} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default CreateDataLanguages;
