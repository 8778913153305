import React from "react";
import styled from "styled-components";
// import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const PageContainer = styled.div`
  width: 100%;
  display: 'flex',
  flexWrap: 'wrap',
  /*width: 100vw;*/
  background: #fff;
  align-items: center;
`;

const StyledTypography = styled(Typography)`
  /*padding: 5px 0 5px 0 !important;*/
  text-align: center !important;
`;

function GlobalInfoMessage(props) {
  const { justify, color, globalInfoMsg } = props;

  return (
    <PageContainer>
      <Grid container justify={justify}>
        <StyledTypography
          variant="caption"
          color={color}
          noWrap={false}
          display="initial"
          gutterBottom={false}
          paragraph={false}
        >
          {globalInfoMsg}
        </StyledTypography>
      </Grid>
    </PageContainer>
  );
}

export default GlobalInfoMessage;
