import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import clsx from "clsx";
// import Backdrop from '@material-ui/core/Backdrop';
// import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
// import SaveIcon from "@material-ui/icons/Save";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Container from "@material-ui/core/Container";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import CheckIcon from '@material-ui/icons/Check';
// import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import InputAdornment from "@material-ui/core/InputAdornment";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import TranslateIcon from '@material-ui/icons/Translate';

// Froala
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// AppContext
import AppContext from "../../../config/AppContext";

import LineSpacing from "../../../components/misc/LineSpacing";
import FroalaCommonEditor from "../../../components/editor/FroalaCommonEditor";
// import TsiCustomizedSnackbars from "../../../../../components/misc/TsiCustomizedSnackbars";
// import AlertDialog from "../../../../../components/dialogs/AlertDialog";
import DomainUploadCapture from "../../../components/upload/DomainUploadCapture";
import CommonConverter from "../../../components/convert/CommonConverter";
import GlobalErrorMessage from "../../../components/dialogs/GlobalErrorMessage";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    leftPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "left",
    },
    leftPanelSubItems: {
        width: "100%",
        // marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    divPadding: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    listContainer: {
        width: '100%',
        maxHeight: '65vh',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
    },
}));

function PostNewsMv(props) {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);

    const { 
        manageNewssLabel, 
        requiredFields,
        languageLabel,
        languagePrompt,
        languageErrMsg,
    
        newsBannerUrlLabel,
        newsBannerUrlPrompt,
        newsBannerUrlErrMsg,
            
        yourNamesLabel,
        yourNamesPrompt,
        yourNamesErrMsg,
        yourNamesMsg,
        
        newsTitleLabel,
        newsTitlePrompt,
        newsTitleErrMsg,

        newsTagsLabel,
        newsTagsPrompt,
        newsTagsErrMsg,
    
        newsStartTimeLabel,
        newsStartTimePrompt,
        newsStartTimeErrMsg,

        newsCategoryLabel,
        newsCategoryPrompt,
        newsCategoryErrMsg,

        // uploadNewsTitleLabel,
        // uploadNewsTitlePrompt,
        // uploadNewsTitleErrMsg,

        // newsRecordsLabel,
        // inProgressLabel,
        // startLabel,
        // endLabel,
        // notStartedLabel,
        // dateCreatedLabel,
    
        newsContentLabel,
        newsContentPrompt,
        yourTimeZoneMsg,

        // timeZoneLabel,
        // toLabel,
        // ofLabel,
        // recordsLabel,
        // noRecordsFound,
        // statusLabel,
        // editLabel,
        // deleteLabel,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,
        cancelLabel,
        clearLabel,
        okLabel,
        uploadLabel,
        createNewsContentLabel,
        updateNewsContentLabel,
        // newsRecordLabel,
        createMoreLabel,
        createLabel,
        closeLabel,
        noNewsRecordsFound, 
        // startEventInfoMsg,

        gridTitle,
        gridNames,
        // gridDuration,
        // gridEventUrl,
        // gridEventUrlType,
        gridStartTime,
        gridBannerUrl,
    
        convertLabel,

        sourceLanguageLabel,
        sourceLanguagePrompt,
        sourceLanguageErrMsg,

        targetLanguageLabel,
        targetLanguagePrompt,
        targetLanguageErrMsg,

        sliceText,
        sliceMoreAction,
        moreLabel,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,
        domainErrFlag,
        domain,
        domainOptions,
        handleDomainChange,

        //=====================

        datePickerLanguage,
        showNewsListFlag,
        newsRecords,
        // selectedTableRows,
        showAlertDialog,
        convertAction,
        editRecordAction,
        uploadAction,
        createMoreAction,
        noNewsRecordFoundFlag,
        createLiveEventAction,
        showNewsDetailsFormFlag,
        closeDetailsForm,
        timeZone,
        languageErrFlag,
        language,
        languages,
        handleLanguageChange,
        editModeFlag,
        newsBannerUrl,
        newsCategoryErrFlag,
        newsCategory,
        categoryOptions,
        handleNewsCategoryChange,
        newsBannerUrlErrFlag,
        handleChange,
        selectImageAction,
        yourNamesErrFlag,
        yourNames,
        newsTitleErrFlag,
        newsTitle,
        newsStartTimeErrFlag,
        newsStartTime,
        handleNewsStartTimeChange,
        newsTagsErrFlag,
        newsTags,
        textMaxLength,
        newsContentErrFlag,
        editorRef,
        editorMaxChars,
        newsContent,
        handleEditorHtmlBodyChange,
        submitButtonFlag,
        newsService,
        uploadFlag,
        closeUploadForm,
        convertFlag,
        closeConvertForm,
        selectTitlePrompt,

        LanguageAndDomain,
        globalErrorFlag,
        globalErrorMsg,
    } = props;

    const theme = useTheme();
  
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    // function LanguageAndDomain(props) {
    //     return (
    //         <>
                // <div className={clsx(classes.divPadding, classes.divWrapper)}>
                //     <Grid item xs={12} sm={6}>
                //         <Grid container justify="center">
                //             <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                //                 <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                //                     <label htmlFor="languageId" style={directionAlignment}>
                //                         <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                //                             *{languageLabel}
                //                         </Typography>
                //                     </label>

                //                     <Autocomplete
                //                         id="languageId"
                //                         value={language}
                //                         options={languages}
                //                         getOptionLabel={option => option.languageName}
                //                         getOptionSelected={option => option.languageName}
                //                         onChange={handleLanguageChange}
                //                         loadingText={loadingText}
                //                         noOptionsText={noOptionsText}
                //                         autoHighlight={true}
                //                         clearText={clearText}
                //                         closeText={closeText}
                //                         disabled={editModeFlag ? true : false}
                //                         renderInput={params => 
                //                             <TextField 
                //                                 {...params} 
                //                                 placeholder={languagePrompt} 
                //                                 variant="outlined"
                //                                 fullWidth
                //                                 error={languageErrFlag}
                //                                 helperText={languageErrFlag ? languageErrMsg : ""}
                //                                 // InputProps={{
                //                                 //     readOnly: editModeFlag ? true : false,
                //                                 // }}
                //                             />
                //                         }
                //                     />
                //                 </FormControl>
                //             </Grid>
                //         </Grid>
                //     </Grid>

                //     <Grid item xs={12} sm={6}>
                //         <Grid container justify="center">
                //             <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                //                 <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                //                     <label htmlFor="domainId" style={directionAlignment}>
                //                         <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                //                             *{domainLabel}
                //                         </Typography>
                //                     </label>

                //                     <Autocomplete
                //                         id="domainId"
                //                         value={domain}
                //                         options={domainOptions}
                //                         getOptionLabel={option => option.text}
                //                         getOptionSelected={option => option.text}
                //                         onChange={handleDomainChange}
                //                         loadingText={loadingText}
                //                         noOptionsText={noOptionsText}
                //                         autoHighlight={true}
                //                         clearText={clearText}
                //                         closeText={closeText}
                //                         // disabled={editModeFlag ? true : false}
                //                         renderInput={params => 
                //                             <TextField 
                //                                 {...params} 
                //                                 placeholder={domainPrompt} 
                //                                 variant="outlined"
                //                                 fullWidth
                //                                 error={domainErrFlag}
                //                                 helperText={domainErrFlag ? domainErrMsg : ""}
                //                                 // InputProps={{
                //                                 //     readOnly: editModeFlag ? true : false,
                //                                 // }}
                //                             />
                //                         }
                //                     />
                //                 </FormControl>
                //             </Grid>
                //         </Grid>
                //     </Grid>
                // </div>
    //         </>
    //     );
    // }

    return (
        <div dir={direction} className={classes.divWrapper}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h6" color="primary" style={{ textAlign: "center" }}>
                            {manageNewssLabel}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                            {requiredFields}
                        </Typography>
                    </Grid>

                    {globalErrorFlag && (
                        <Grid item xs={12} sm={12}>
                            <LineSpacing value={24} />
                            <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={12}>
                        <LineSpacing value={12} />
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="center">
                            {showNewsListFlag &&
                                <>
                                    {/* <LanguageAndDomain/> */}

                                    <div className={clsx(classes.divPadding, classes.divWrapper)}>
                                        <Grid item xs={12} sm={6}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                *{languageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="languageId"
                                                            value={language}
                                                            options={languages}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={languagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={languageErrFlag}
                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                *{domainLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="domainId"
                                                            value={domain}
                                                            options={domainOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleDomainChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            // disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={domainPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={domainErrFlag}
                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        {newsRecords && newsRecords.length > 0 ?
                                            <>
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="center" className={classes.divPadding}>
                                                        <Grid item xs={4} sm={4}>
                                                            <Grid container justify="flex-start">
                                                                <Tooltip title={convertLabel} placement="bottom">
                                                                    <IconButton color="primary" aria-label={convertLabel} component="span" onClick={uploadAction}>
                                                                        <CloudUploadIcon className={clsx(classes.iconLarge)} aria-label={uploadLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={4} sm={4}>
                                                            <Grid container justify="center">
                                                                <Tooltip title={convertLabel} placement="bottom">
                                                                    <IconButton color="primary" aria-label={convertLabel} component="span" onClick={convertAction}>
                                                                        <TranslateIcon className={clsx(classes.iconLarge)} aria-label={convertLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={4} sm={4}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={createLabel} placement="bottom">
                                                                    <IconButton color="primary" aria-label={createLabel} component="span" onClick={createMoreAction}>
                                                                        <AddIcon className={clsx(classes.iconLarge)} aria-label={createLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={8} />
                                                </Grid>
                                                
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12}>
                                                        <List className={classes.listContainer}>
                                                            <div>
                                                                {newsRecords && newsRecords.length > 0 &&
                                                                    newsRecords.map((record, index) => (
                                                                        <Grid key={index} container justify="center">
                                                                            <ListItem alignItems="flex-start" disableGutters={true}>
                                                                                <ListItemText style={{backgroundColor: (index % 2) === 0 ? '#F8F8FF' : 'inherit', padding: '8px'}}>
                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {newsCategoryLabel}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {record.NewsCategory}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    
                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {gridNames}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {record.AuthorNames}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {gridTitle}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {record.NewsTitle}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {newsTagsLabel}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {record.Tags}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {gridStartTime}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {record.FormattedStartTime}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {gridBannerUrl}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {sliceText(record.BannerUrl, 35)}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Typography variant="caption" style={{display: 'block'}}>
                                                                                            {newsContentLabel}:
                                                                                        </Typography>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Typography variant="caption" style={{display: 'block'}}>
                                                                                            <FroalaEditorView model={sliceText(record.NewsContent, 200)}/>
                                                                                        </Typography>

                                                                                        {(record.NewsContent.length > 200) &&
                                                                                            <Grid container justify="center">
                                                                                                <Link component="button" variant="caption" onClick={() => sliceMoreAction(record)}>
                                                                                                    {moreLabel}
                                                                                                </Link>
                                                                                            </Grid>
                                                                                        }
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="flex-end">
                                                                                            <Grid item style={{paddingRight: theme.spacing(4)}}>
                                                                                                <DeleteIcon color="secondary" onClick={() => showAlertDialog(record, "single")}/>
                                                                                            </Grid>

                                                                                            <Grid item style={{paddingRight: theme.spacing(4)}}>
                                                                                                <TranslateIcon color="primary" onClick={convertAction}/>
                                                                                            </Grid>

                                                                                            <Grid item>
                                                                                                <EditIcon color="primary" onClick={() => editRecordAction(record)}/>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </ListItemText>
                                                                            </ListItem>
                                                                        </Grid>
                                                                    ))
                                                                }
                                                            </div>
                                                        </List>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        :
                                            <>
                                                {noNewsRecordFoundFlag &&
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <Typography variant="body1">
                                                                {noNewsRecordsFound}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Button variant="contained" size="large" disabled={false} color="primary" onClick={createLiveEventAction}>
                                                                <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                {createNewsContentLabel}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </>
                                        }
                                    </Grid>
                                </>
                            }

                            {showNewsDetailsFormFlag && 
                                <div className={clsx(classes.divPadding)} style={{width: '100%'}}>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-end">
                                            <Tooltip title={closeLabel} placement="bottom">
                                                <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                    <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Typography variant="subtitle1" color="primary">
                                                        {yourTimeZoneMsg}: <span style={{color: '#000', paddingLeft: '8px'}}>{timeZone}</span>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* <LanguageAndDomain/> */}
                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                    <label htmlFor="languageId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                            *{languageLabel}
                                                        </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="languageId"
                                                        value={language}
                                                        options={languages}
                                                        getOptionLabel={option => option.languageName}
                                                        getOptionSelected={option => option.languageName}
                                                        onChange={handleLanguageChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        disabled={editModeFlag ? true : false}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={languagePrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={languageErrFlag}
                                                                helperText={languageErrFlag ? languageErrMsg : ""}
                                                                // InputProps={{
                                                                //     readOnly: editModeFlag ? true : false,
                                                                // }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                    <label htmlFor="domainId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                            *{domainLabel}
                                                        </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="domainId"
                                                        value={domain}
                                                        options={domainOptions}
                                                        getOptionLabel={option => option.text}
                                                        getOptionSelected={option => option.text}
                                                        onChange={handleDomainChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        // disabled={editModeFlag ? true : false}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={domainPrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={domainErrFlag}
                                                                helperText={domainErrFlag ? domainErrMsg : ""}
                                                                // InputProps={{
                                                                //     readOnly: editModeFlag ? true : false,
                                                                // }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={newsCategoryErrFlag}>
                                                    <label htmlFor="newsCategoryId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={newsCategoryErrFlag ? "secondary" : "inherit"}>
                                                            *{newsCategoryLabel}
                                                        </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="newsCategoryId"
                                                        value={newsCategory}
                                                        options={categoryOptions}
                                                        getOptionLabel={option => option.text}
                                                        getOptionSelected={option => option.text}
                                                        onChange={handleNewsCategoryChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        // disabled={editModeFlag ? true : false}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={newsCategoryPrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={newsCategoryErrFlag}
                                                                helperText={newsCategoryErrFlag ? newsCategoryErrMsg : ""}
                                                                // InputProps={{
                                                                //     readOnly: editModeFlag ? true : false,
                                                                // }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    {newsBannerUrl &&
                                        <>
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <img src={newsBannerUrl} style={{height: '100px'}} />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={12} />
                                            </Grid>
                                        </>
                                    }

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={newsBannerUrlErrFlag}>
                                                    <label htmlFor="newsBannerUrlId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={newsBannerUrlErrFlag ? "secondary" : "inherit"}>
                                                            {newsBannerUrlLabel}
                                                        </Typography>
                                                    </label>

                                                    <OutlinedInput
                                                        id="newsBannerUrlId"
                                                        value={newsBannerUrl}
                                                        placeholder={newsBannerUrlPrompt}
                                                        onChange={handleChange}
                                                        required
                                                        fullWidth
                                                        type={"text"}
                                                        error={newsBannerUrlErrFlag}
                                                        margin="none"
                                                        variant="outlined"
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label={newsBannerUrlPrompt}
                                                                    onClick={selectImageAction}
                                                                >
                                                                    <ImageSearchIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                    <FormHelperText>{newsBannerUrlErrFlag ? newsBannerUrlErrMsg : ""}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={yourNamesErrFlag}>
                                                    <label htmlFor="yourNamesId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={yourNamesErrFlag ? "secondary" : "inherit"}>
                                                            *{yourNamesLabel}
                                                        </Typography>
                                                    </label>

                                                    <TextField
                                                        id="yourNamesId"
                                                        value={yourNames}
                                                        placeholder={yourNamesPrompt}
                                                        onChange={handleChange}
                                                        required
                                                        fullWidth
                                                        type={"text"}
                                                        error={yourNamesErrFlag}
                                                        margin="none"
                                                        variant="outlined"
                                                        helperText={yourNamesErrFlag ? yourNamesErrMsg : yourNamesMsg}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={newsTitleErrFlag}>
                                                    <label htmlFor="newsTitleId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={newsTitleErrFlag ? "secondary" : "inherit"}>
                                                            *{newsTitleLabel}
                                                        </Typography>
                                                    </label>

                                                    <TextField
                                                        id="newsTitleId"
                                                        value={newsTitle}
                                                        placeholder={newsTitlePrompt}
                                                        onChange={handleChange}
                                                        required
                                                        fullWidth
                                                        type={"text"}
                                                        error={newsTitleErrFlag}
                                                        margin="none"
                                                        variant="outlined"
                                                        helperText={newsTitleErrFlag ? newsTitleErrMsg : ""}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={newsStartTimeErrFlag}>
                                                            <label htmlFor="newsStartTimeId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={newsStartTimeErrFlag ? "secondary" : "inherit"}>
                                                                    *{newsStartTimeLabel}
                                                                </Typography>
                                                            </label>

                                                            <KeyboardDateTimePicker
                                                                id="newsStartTimeId"
                                                                value={newsStartTime}
                                                                // views={["year"]}
                                                                onChange={handleNewsStartTimeChange}
                                                                clearable
                                                                placeholder={newsStartTimePrompt}
                                                                format="MMM DD, YYYY hh:mm a z"
                                                                // inputVariant="outlined"
                                                                invalidDateMessage={newsStartTimeErrMsg}
                                                                cancelLabel={cancelLabel}
                                                                clearLabel={clearLabel}
                                                                okLabel={okLabel}
                                                                // className={classes.phone}
                                                                inputVariant="outlined"
                                                                margin="none"
                                                            />
                                                            <FormHelperText>{newsStartTimeErrFlag ? newsStartTimeErrMsg : ""}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={newsTagsErrFlag}>
                                                            <label htmlFor="newsTagsId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={newsTagsErrFlag ? "secondary" : "inherit"}>
                                                                    *{newsTagsLabel}
                                                                </Typography>
                                                            </label>

                                                            <TextareaAutosize
                                                                id="newsTagsId"
                                                                aria-label={newsTagsLabel}
                                                                rows={2}
                                                                rowsMax={6}
                                                                value={newsTags}
                                                                placeholder={newsTagsPrompt}
                                                                onChange={handleChange}
                                                                required
                                                                // autoFocus={true}
                                                                margin="none"
                                                                className={classes.textArea}
                                                                maxLength={textMaxLength}
                                                            />
                                                            <FormHelperText>{newsTagsErrFlag ? newsTagsErrMsg : ""}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={newsContentErrFlag}>
                                                    <label htmlFor="newsContentId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={newsContentErrFlag ? "secondary" : "inherit"}>
                                                            *{newsContentLabel}
                                                        </Typography>
                                                    </label>

                                                    <FroalaCommonEditor
                                                        editorId={"newsContentId"}
                                                        editorRef={editorRef}
                                                        // editorType={"minimal"} // Full Editor when not set
                                                        placeholderPrompt={newsContentPrompt}
                                                        editorMaxChars={editorMaxChars}
                                                        editorModel={newsContent}
                                                        modelChangeCallback={handleEditorHtmlBodyChange}
                                                        referrer={"PostNews"}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={newsService}>
                                                {editModeFlag ?
                                                <>
                                                    <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                    {updateNewsContentLabel} 
                                                </>
                                                : 
                                                <>
                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                    {createNewsContentLabel}
                                                </>
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={24} />
                                    </Grid>
                                </div>
                            }

                            {uploadFlag && 
                                <>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-end">
                                            <Tooltip title={closeLabel} placement="bottom">
                                                <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeUploadForm}>
                                                    <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <DomainUploadCapture 
                                            showHeadersFlag={false}
                                        />
                                    </Grid>
                                </>
                            }

                            {convertFlag &&
                                <>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-end">
                                            <Tooltip title={closeLabel} placement="bottom">
                                                <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                    <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <CommonConverter 
                                            groupingCategoryLabel={newsTitleLabel}
                                            groupingCategoryPrompt={newsTitlePrompt}
                                            groupingCategoryErrMsg={newsTitleErrMsg}

                                            showHeadersFlag={false}
                                            actionMsgLabel={uploadLabel}

                                            referrer={"PostNews"}
                                            categoryType={"news-titles"}

                                            tableName={"news"}
                                        />
                                    </Grid>
                                </>
                            }

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={18} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    )
}

export default PostNewsMv;
