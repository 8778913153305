import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

function Logo() {
  return (
    <Link component={RouterLink} to="/">
      <IconButton>
        <img src="assets/logos/cloudcms.png" style={{ width: "64px" }} />
      </IconButton>
    </Link>
  );
}

export default Logo;
