import React, { useEffect, useState, useContext } from "react";

import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";

import clsx from "clsx";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

// react-responsive
import { useMediaQuery } from 'react-responsive';

// Lodash
import lodash from "lodash";

// Primereact
import { InputSwitch } from 'primereact/inputswitch';

import LineSpacing from "../../../components/misc/LineSpacing";

// AppContext
import AppContext from "../../../config/AppContext";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    card: {
        minWidth: 345,
        maxWidth: 545,
        width: "100% !important",
        height: "500px !important",
    },
    popularCard: {
        minWidth: 345,
        maxWidth: 545,
        width: "100% !important",
        // width: '345px !important',
        height: "560px !important",
        textAlign: 'center',
    },
    cardTop: {
        borderTop: 'solid 5px tomato',// #528bff
    },
    cardAction: {
        height: "400px !important",
    },
    popularCardAction: {
        minWidth: '345px !important',
        maxWidth: '345px !important',
        height: "460px !important",
        textAlign: 'center',
    },
    lead: {
        fontSize: '1.25rem',
        fontWeight: 300,
    },
    textLowercase: {
        textTransform: 'lowercase !important',
    },

    textUppercase: {
        textTransform: 'uppercase !important',
    },

    textCapitalize: {
        textTransform: 'capitalize !important',
    },

    fontWeightLight: {
        fontWeight: '300 !important',
    },

    fontWeightLighter: {
        fontWeight: 'lighter !important',
    },

    fontWeightNormal: {
        fontWeight: '400 !important',
    },

    fontWeightBold: {
        fontWeight: '700 !important',
    },

    fontWeightBolder: {
        fontWeight: 'bolder !important',
    },

    fontItalic: {
        fontStyle: 'italic !important',
    },

    textLight: {
        color: '#f8f9fa !important',
    },
      
    'textLight:hover, textLight:focus': {
        color: '#cbd3da !important',
    },

    h1: { fontSize: '32px' },
    h2: { fontSize: '24px' },
    h3: { fontSize: '18.72px' },
    h4: { fontSize: '16px' },
    h5: { fontSize: '13.28px' },
    h6: { fontSize: '10.72px' },
}));

function Pricings01(props) {
    const appContext = useContext(AppContext);
    
    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

    const [switchChecked, setSwitchChecked] = useState(false);

    const {
        direction,
        pricingHdrLabel, 
        pricingHdrMsg1,
        pricingHdrMsg2,
        monthLabel,
        yearLabel,
        saveLcLabel,
        savingsLcLabel,
        priceActionLabel,
        monthlyLabel,
        yearlyLabel,

        pricePlanRecord1,
        pricePlanRecord2,
        pricePlanRecord3,
        pricePlanRecord4,
    } = props;

    const theme = useTheme();
    const classes = useStyles();
        
    useEffect(() => {
        // console.log('In Pricings01(): useEffect(): pricePlanRecord1 is: ', pricePlanRecord1);
        // console.log('In Pricings01(): useEffect(): pricePlanRecord2 is: ', pricePlanRecord2);
        // console.log('In Pricings01(): useEffect(): pricePlanRecord3 is: ', pricePlanRecord3);
        // console.log('In Pricings01(): useEffect(): pricePlanRecord4 is: ', pricePlanRecord4);
    }, []);

    return (
        <>
            {isDeskTopView &&
                <>
                    <section className="fdb-block" style={{backgroundImage: 'url(./assets/images/shapes/1.svg)'}}>
                        <Container component="div" className={classes.main} maxWidth="lg">
                            <Grid item xs={12} sm={12}>
                                <Grid container justify="center">
                                    <div className="col">
                                        <h1 className={classes.textLight}>{pricingHdrLabel}</h1>

                                        {pricingHdrMsg1 &&
                                            <h4 className={classes.textLight}>{pricingHdrMsg1}</h4>
                                        }

                                        {pricingHdrMsg2 &&
                                            <h6 className={classes.textLight}>{pricingHdrMsg2} {lodash.isEmpty(pricePlanRecord1) ? "" : pricePlanRecord1.PriceCurrency.text}</h6>
                                        }
                                    </div>

                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <LineSpacing value={36} />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center" spacing={4}>
                                        <Grid item>
                                            <Grid container justify="center">
                                                <Typography variant="h6" color="primary">
                                                    {monthlyLabel}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Grid container justify="center">
                                                <InputSwitch checked={switchChecked} onChange={(e) => setSwitchChecked(e.value)} />
                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Grid container justify="center">
                                                <Typography variant="h6" color="primary">
                                                    {yearlyLabel} ({saveLcLabel})
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* CARD 1 */}

                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center" alignItems="center" alignContent="center" direction="column" spacing={4} style={{height: '600px'}}>
                                        {!lodash.isEmpty(pricePlanRecord1) &&
                                            <Grid item xs={12} sm={4}>
                                                <Grid container justify="center" alignItems="flex-start" alignContent="flex-start">
                                                    {pricePlanRecord1 &&
                                                        <Card className={classes.card}>
                                                            <CardActionArea>
                                                                <CardContent className={classes.cardAction}>
                                                                    <h2>{pricePlanRecord1.PriceCategoryText}</h2>

                                                                    {switchChecked ?
                                                                        <p className={classes.lead}>
                                                                            {pricePlanRecord1.AmountPerYear &&
                                                                                <strong>{pricePlanRecord1.AmountPerYear} / {yearLabel} {pricePlanRecord1.PriceUnit ? "("+pricePlanRecord1.PriceUnit.text+")" : ""}</strong>
                                                                            }

                                                                            {pricePlanRecord1.YearlyDiscount &&
                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                    {pricePlanRecord1.YearlyDiscount} {savingsLcLabel}
                                                                                </Typography>
                                                                            }
                                                                        </p>
                                                                        :
                                                                        <p className={classes.lead}>
                                                                            {pricePlanRecord1.AmountPerMonth &&
                                                                                <strong>{pricePlanRecord1.AmountPerMonth} / {monthLabel} {pricePlanRecord1.PriceUnit ? "("+pricePlanRecord1.PriceUnit.text+")" : ""}</strong>
                                                                            }
                                                                        </p>
                                                                    }

                                                                    {pricePlanRecord1.PriceComments &&
                                                                        <p className={clsx(classes.fontWeightLight)}>
                                                                            {pricePlanRecord1.PriceComments}
                                                                        </p>
                                                                    }

                                                                    {pricePlanRecord1 && pricePlanRecord1.priceItems &&
                                                                        <ul className="text-left mt-5 mb-5">
                                                                            {
                                                                                pricePlanRecord1.priceItems.map((record, index) => (
                                                                                    <p key={index}><li>{record.PriceItem}</li></p>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    }
                                                                </CardContent>
                                                            </CardActionArea>

                                                            <CardActions>
                                                                <Grid container justify="center">
                                                                    <RouterLink to="/webPages">
                                                                        <span className="btn btn-outline-primary mt-4">{priceActionLabel}</span>
                                                                    </RouterLink>
                                                                </Grid>
                                                            </CardActions>
                                                        </Card>
                                                    }
                                                </Grid>
                                            </Grid>
                                        }

                                        {/* CARD 2 */}

                                        {!lodash.isEmpty(pricePlanRecord2) &&
                                            <Grid item xs={12} sm={4}>
                                                <Grid container justify="center" alignItems="center" alignContent="center" direction="column">
                                                    {pricePlanRecord2 &&
                                                        <Card className={clsx(classes.cardTop, classes.popularCard)}>
                                                            <CardActionArea>
                                                                <CardContent className={classes.popularCardAction}>
                                                                    <Grid container justify="center" alignItems="center" alignContent="center" direction="column">
                                                                        <h2 className="text-primary">{pricePlanRecord2.PriceCategoryText}</h2>

                                                                        {switchChecked ?
                                                                            <p className={classes.lead}>
                                                                                {pricePlanRecord2.AmountPerYear &&
                                                                                    <strong>{pricePlanRecord2.AmountPerYear} / {yearLabel} {pricePlanRecord2.PriceUnit ? "("+pricePlanRecord2.PriceUnit.text+")" : ""}</strong>
                                                                                }

                                                                                {pricePlanRecord2.YearlyDiscount &&
                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                        {pricePlanRecord2.YearlyDiscount} {savingsLcLabel}
                                                                                    </Typography>
                                                                                }
                                                                            </p>
                                                                            :
                                                                            <p className={classes.lead}>
                                                                                {pricePlanRecord2.AmountPerMonth &&
                                                                                    <strong>{pricePlanRecord2.AmountPerMonth} / {monthLabel} {pricePlanRecord2.PriceUnit ? "("+pricePlanRecord2.PriceUnit.text+")" : ""}</strong>
                                                                                }
                                                                            </p>
                                                                        }

                                                                        {pricePlanRecord2.PriceComments &&
                                                                            <p className={clsx(classes.fontWeightLight)}>
                                                                                {pricePlanRecord2.PriceComments}
                                                                            </p>
                                                                        }

                                                                        {pricePlanRecord2 && pricePlanRecord2.priceItems &&
                                                                            <ul className="text-left mt-5 mb-5">
                                                                                {
                                                                                    pricePlanRecord2.priceItems.map((record, index) => (
                                                                                        <p key={index}><li>{record.PriceItem}</li></p>
                                                                                    ))
                                                                                }
                                                                            </ul>
                                                                        }
                                                                    </Grid>
                                                                </CardContent>
                                                            </CardActionArea>

                                                            <CardActions>
                                                                <Grid container justify="center">
                                                                    <RouterLink to="/webPages">
                                                                        <span className="btn btn-primary mt-4">{priceActionLabel}</span>
                                                                    </RouterLink>
                                                                </Grid>
                                                            </CardActions>
                                                        </Card>
                                                    }
                                                </Grid>
                                            </Grid>
                                        }

                                        {/* CARD 3 */}

                                        {!lodash.isEmpty(pricePlanRecord3) &&
                                            <Grid item xs={12} sm={4}>
                                                <Grid container justify="center" alignItems="flex-start" alignContent="flex-start" direction="column">
                                                    {pricePlanRecord3 &&
                                                        <Card className={classes.card}>
                                                            <CardActionArea>
                                                                <CardContent className={classes.cardAction}>
                                                                    <Grid container justify="center" alignItems="center" alignContent="center" direction="column">
                                                                    <h2>{pricePlanRecord3.PriceCategoryText}</h2>

                                                                    {switchChecked ?
                                                                        <>
                                                                            {pricePlanRecord3.AmountPerYear &&
                                                                                <p className={classes.lead}>
                                                                                    {pricePlanRecord3.AmountPerYear &&
                                                                                        <strong>{pricePlanRecord3.AmountPerYear} / {yearLabel} {pricePlanRecord3.PriceUnit ? "("+pricePlanRecord3.PriceUnit.text+")" : ""}</strong>
                                                                                    }

                                                                                    {pricePlanRecord3.YearlyDiscount &&
                                                                                        <Typography variant="caption" style={{display: 'block'}}>
                                                                                            {pricePlanRecord3.YearlyDiscount} {savingsLcLabel}
                                                                                        </Typography>
                                                                                    }
                                                                                </p>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {pricePlanRecord3.AmountPerMonth &&
                                                                                <p className={classes.lead}>
                                                                                    {pricePlanRecord3.AmountPerMonth &&
                                                                                        <strong>{pricePlanRecord3.AmountPerMonth} / {monthLabel} {pricePlanRecord3.PriceUnit ? "("+pricePlanRecord3.PriceUnit.text+")" : ""}</strong>
                                                                                    }
                                                                                </p>
                                                                            }
                                                                        </>
                                                                    }

                                                                    {!pricePlanRecord3.AmountPerYear &&
                                                                        <>
                                                                            {pricePlanRecord3.PriceUnit &&
                                                                                <p className={classes.lead}>
                                                                                    {pricePlanRecord3.PriceUnit.text}
                                                                                </p>
                                                                            }
                                                                        </>
                                                                    }

                                                                    {pricePlanRecord3.PriceComments &&
                                                                        <p className={clsx(classes.fontWeightLight)}>
                                                                            {pricePlanRecord3.PriceComments}
                                                                        </p>
                                                                    }

                                                                    {pricePlanRecord3 && pricePlanRecord3.priceItems &&
                                                                        <ul className="text-left mt-5 mb-5">
                                                                            {
                                                                                pricePlanRecord3.priceItems.map((record, index) => (
                                                                                    <p key={index}><li>{record.PriceItem}</li></p>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    }
                                                                    </Grid>
                                                                </CardContent>
                                                            </CardActionArea>

                                                            <CardActions>
                                                                <Grid container justify="center">
                                                                    <RouterLink to="/webPages">
                                                                        <span className="btn btn-outline-primary mt-4">{priceActionLabel}</span>
                                                                    </RouterLink>
                                                                </Grid>
                                                            </CardActions>
                                                        </Card>
                                                    }
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </section>
                </>
            }

            {isTabletView &&
                <>
                    <section className="fdb-block" style={{backgroundImage: 'url(./assets/images/shapes/1.svg)'}}>
                        <Container component="div" className={classes.main} maxWidth="lg">
                            <Grid item xs={12} sm={12}>
                                <Grid container justify="center">
                                    <div className="col">
                                        <h1>{pricingHdrLabel}</h1>

                                        {pricingHdrMsg1 &&
                                            <h4 className={classes.textLight}>{pricingHdrMsg1}</h4>
                                        }

                                        {pricingHdrMsg2 &&
                                            <h6 className={classes.textLight}>{pricingHdrMsg2} {lodash.isEmpty(pricePlanRecord1) ? "" : pricePlanRecord1.PriceCurrency.text}</h6>
                                        }
                                    </div>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={36} />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Grid container justify="center" spacing={4}>
                                    <Grid item>
                                        <Grid container justify="center">
                                            <Typography variant="h6" color="primary">
                                                {monthlyLabel}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Grid container justify="center">
                                            <InputSwitch checked={switchChecked} onChange={(e) => setSwitchChecked(e.value)} />
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Grid container justify="center">
                                            <Typography variant="h6" color="primary">
                                                {yearlyLabel} ({saveLcLabel})
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={36} />
                            </Grid>

                            {/* CARD 1 */}

                            {!lodash.isEmpty(pricePlanRecord1) &&
                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center" alignItems="flex-start" alignContent="flex-start">
                                        {pricePlanRecord1 &&
                                            <Card className={classes.card}>
                                                <CardActionArea>
                                                    <CardContent className={classes.cardAction}>
                                                        <h2>{pricePlanRecord1.PriceCategoryText}</h2>

                                                        {switchChecked ?
                                                            <p className={classes.lead}>
                                                                {pricePlanRecord1.AmountPerYear &&
                                                                    <strong>{pricePlanRecord1.AmountPerYear} / {yearLabel} {pricePlanRecord1.PriceUnit ? "("+pricePlanRecord1.PriceUnit.text+")" : ""}</strong>
                                                                }

                                                                {pricePlanRecord1.YearlyDiscount &&
                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                        {pricePlanRecord1.YearlyDiscount} {savingsLcLabel}
                                                                    </Typography>
                                                                }
                                                            </p>
                                                            :
                                                            <p className={classes.lead}>
                                                                {pricePlanRecord1.AmountPerMonth &&
                                                                    <strong>{pricePlanRecord1.AmountPerMonth} / {monthLabel} {pricePlanRecord1.PriceUnit ? "("+pricePlanRecord1.PriceUnit.text+")" : ""}</strong>
                                                                }
                                                            </p>
                                                        }

                                                        {pricePlanRecord1.PriceComments &&
                                                            <p className={clsx(classes.fontWeightLight)}>
                                                                {pricePlanRecord1.PriceComments}
                                                            </p>
                                                        }

                                                        {pricePlanRecord1 && pricePlanRecord1.priceItems &&
                                                            <ul className="text-left mt-5 mb-5">
                                                                {
                                                                    pricePlanRecord1.priceItems.map((record, index) => (
                                                                        <p key={index}><li>{record.PriceItem}</li></p>
                                                                    ))
                                                                }
                                                            </ul>
                                                        }
                                                    </CardContent>
                                                </CardActionArea>

                                                <CardActions>
                                                    <Grid container justify="center">
                                                        <RouterLink to="/webPages">
                                                            <span className="btn btn-outline-primary mt-4">{priceActionLabel}</span>
                                                        </RouterLink>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        }
                                    </Grid>
                                </Grid>
                            }

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={36} />
                            </Grid>

                            {/* CARD 2 */}

                            {!lodash.isEmpty(pricePlanRecord2) &&
                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center" alignItems="flex-start" alignContent="flex-start">
                                        {pricePlanRecord2 &&
                                            <Card className={clsx(classes.cardTop, classes.popularCard)}>
                                                <CardActionArea>
                                                    <CardContent className={classes.cardAction}>
                                                        <h2 className="text-primary">{pricePlanRecord2.PriceCategoryText}</h2>

                                                        {switchChecked ?
                                                            <p className={classes.lead}>
                                                                {pricePlanRecord2.AmountPerYear &&
                                                                    <strong>{pricePlanRecord2.AmountPerYear} / {yearLabel} {pricePlanRecord2.PriceUnit ? "("+pricePlanRecord2.PriceUnit.text+")" : ""}</strong>
                                                                }

                                                                {pricePlanRecord2.YearlyDiscount &&
                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                        {pricePlanRecord2.YearlyDiscount} {savingsLcLabel}
                                                                    </Typography>
                                                                }
                                                            </p>
                                                            :
                                                            <p className={classes.lead}>
                                                                {pricePlanRecord2.AmountPerMonth &&
                                                                    <strong>{pricePlanRecord2.AmountPerMonth} / {monthLabel} {pricePlanRecord2.PriceUnit ? "("+pricePlanRecord2.PriceUnit.text+")" : ""}</strong>
                                                                }
                                                            </p>
                                                        }

                                                        {pricePlanRecord2.PriceComments &&
                                                            <p className={clsx(classes.fontWeightLight)}>
                                                                {pricePlanRecord2.PriceComments}
                                                            </p>
                                                        }

                                                        {pricePlanRecord2 && pricePlanRecord2.priceItems &&
                                                            <ul className="text-left mt-5 mb-5">
                                                                {
                                                                    pricePlanRecord2.priceItems.map((record, index) => (
                                                                        <p key={index}><li>{record.PriceItem}</li></p>
                                                                    ))
                                                                }
                                                            </ul>
                                                        }
                                                    </CardContent>
                                                </CardActionArea>

                                                <CardActions>
                                                    <Grid container justify="center">
                                                        <RouterLink to="/webPages">
                                                            <span className="btn btn-outline-primary mt-4">{priceActionLabel}</span>
                                                        </RouterLink>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        }
                                    </Grid>
                                </Grid>
                            }

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={36} />
                            </Grid>

                            {/* CARD 3 */}

                            {!lodash.isEmpty(pricePlanRecord3) &&
                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center" alignItems="flex-start" alignContent="flex-start">
                                        {pricePlanRecord3 &&
                                            <Card className={classes.card}>
                                                <CardActionArea>
                                                    <CardContent className={classes.cardAction}>
                                                        <h2>{pricePlanRecord3.PriceCategoryText}</h2>

                                                        {switchChecked ?
                                                            <p className={classes.lead}>
                                                                {pricePlanRecord3.AmountPerYear &&
                                                                    <strong>{pricePlanRecord3.AmountPerYear} / {yearLabel} {pricePlanRecord3.PriceUnit ? "("+pricePlanRecord3.PriceUnit.text+")" : ""}</strong>
                                                                }

                                                                {pricePlanRecord3.YearlyDiscount &&
                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                        {pricePlanRecord3.YearlyDiscount} {savingsLcLabel}
                                                                    </Typography>
                                                                }
                                                            </p>
                                                            :
                                                            <p className={classes.lead}>
                                                                {pricePlanRecord3.AmountPerMonth &&
                                                                    <strong>{pricePlanRecord3.AmountPerMonth} / {monthLabel} {pricePlanRecord3.PriceUnit ? "("+pricePlanRecord3.PriceUnit.text+")" : ""}</strong>
                                                                }
                                                            </p>
                                                        }

                                                        {pricePlanRecord3.PriceComments &&
                                                            <p className={clsx(classes.fontWeightLight)}>
                                                                {pricePlanRecord3.PriceComments}
                                                            </p>
                                                        }

                                                        {pricePlanRecord3 && pricePlanRecord3.priceItems &&
                                                            <ul className="text-left mt-5 mb-5">
                                                                {
                                                                    pricePlanRecord3.priceItems.map((record, index) => (
                                                                        <p key={index}><li>{record.PriceItem}</li></p>
                                                                    ))
                                                                }
                                                            </ul>
                                                        }
                                                    </CardContent>
                                                </CardActionArea>

                                                <CardActions>
                                                    <Grid container justify="center">
                                                        <RouterLink to="/webPages">
                                                            <span className="btn btn-outline-primary mt-4">{priceActionLabel}</span>
                                                        </RouterLink>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </Container>
                    </section>
                </>
            }

            {isMobileView &&
                <>
                    <section className="fdb-block" style={{backgroundImage: 'url(./assets/images/shapes/1.svg)'}}>
                        <Container component="div" className={classes.main} maxWidth="lg">
                            <Grid item xs={12} sm={12}>
                                <Grid container justify="center">
                                    <div className="col">
                                        <h1>{pricingHdrLabel}</h1>

                                        {pricingHdrMsg1 &&
                                            <h4 className={classes.textLight}>{pricingHdrMsg1}</h4>
                                        }

                                        {pricingHdrMsg2 &&
                                            <h6 className={classes.textLight}>{pricingHdrMsg2} {lodash.isEmpty(pricePlanRecord1) ? "" : pricePlanRecord1.PriceCurrency.text}</h6>
                                        }
                                    </div>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={36} />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Grid container justify="center" spacing={4}>
                                    <Grid item>
                                        <Grid container justify="center">
                                            <Typography variant="h6" color="primary">
                                                {monthlyLabel}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Grid container justify="center">
                                            <InputSwitch checked={switchChecked} onChange={(e) => setSwitchChecked(e.value)} />
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Grid container justify="center">
                                            <Typography variant="h6" color="primary">
                                                {yearlyLabel} ({saveLcLabel})
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={36} />
                            </Grid>

                            {/* CARD 1 */}

                            {!lodash.isEmpty(pricePlanRecord1) &&
                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center" alignItems="flex-start" alignContent="flex-start">
                                        {pricePlanRecord1 &&
                                            <Card className={classes.card}>
                                                <CardActionArea>
                                                    <CardContent className={classes.cardAction}>
                                                        <h2>{pricePlanRecord1.PriceCategoryText}</h2>

                                                        {switchChecked ?
                                                            <p className={classes.lead}>
                                                                {pricePlanRecord1.AmountPerYear &&
                                                                    <strong>{pricePlanRecord1.AmountPerYear} / {yearLabel} {pricePlanRecord1.PriceUnit ? "("+pricePlanRecord1.PriceUnit.text+")" : ""}</strong>
                                                                }

                                                                {pricePlanRecord1.YearlyDiscount &&
                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                        {pricePlanRecord1.YearlyDiscount} {savingsLcLabel}
                                                                    </Typography>
                                                                }
                                                            </p>
                                                            :
                                                            <p className={classes.lead}>
                                                                {pricePlanRecord1.AmountPerMonth &&
                                                                    <strong>{pricePlanRecord1.AmountPerMonth} / {monthLabel} {pricePlanRecord1.PriceUnit ? "("+pricePlanRecord1.PriceUnit.text+")" : ""}</strong>
                                                                }
                                                            </p>
                                                        }

                                                        {pricePlanRecord1.PriceComments &&
                                                            <p className={clsx(classes.fontWeightLight)}>
                                                                {pricePlanRecord1.PriceComments}
                                                            </p>
                                                        }

                                                        {pricePlanRecord1 && pricePlanRecord1.priceItems &&
                                                            <ul className="text-left mt-5 mb-5">
                                                                {
                                                                    pricePlanRecord1.priceItems.map((record, index) => (
                                                                        <p key={index}><li>{record.PriceItem}</li></p>
                                                                    ))
                                                                }
                                                            </ul>
                                                        }
                                                    </CardContent>
                                                </CardActionArea>

                                                <CardActions>
                                                    <Grid container justify="center">
                                                        <RouterLink to="/webPages">
                                                            <span className="btn btn-outline-primary mt-4">{priceActionLabel}</span>
                                                        </RouterLink>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        }
                                    </Grid>
                                </Grid>
                            }

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={36} />
                            </Grid>

                            {/* CARD 2 */}

                            {!lodash.isEmpty(pricePlanRecord2) &&
                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center" alignItems="center" alignContent="center" direction="column">
                                        {pricePlanRecord2 &&
                                            <Card className={clsx(classes.cardTop, classes.popularCard)}>
                                                <CardActionArea>
                                                    <CardContent className={classes.popularCardAction}>
                                                        <Grid container justify="center" alignItems="center" alignContent="center" direction="column">
                                                            <h2 className="text-primary">{pricePlanRecord2.PriceCategoryText}</h2>

                                                            {switchChecked ?
                                                                <p className={classes.lead}>
                                                                    {pricePlanRecord2.AmountPerYear &&
                                                                        <strong>{pricePlanRecord2.AmountPerYear} / {yearLabel} {pricePlanRecord2.PriceUnit ? "("+pricePlanRecord2.PriceUnit.text+")" : ""}</strong>
                                                                    }

                                                                    {pricePlanRecord2.YearlyDiscount &&
                                                                        <Typography variant="caption" style={{display: 'block'}}>
                                                                            {pricePlanRecord2.YearlyDiscount} {savingsLcLabel}
                                                                        </Typography>
                                                                    }
                                                                </p>
                                                                :
                                                                <p className={classes.lead}>
                                                                    {pricePlanRecord2.AmountPerMonth &&
                                                                        <strong>{pricePlanRecord2.AmountPerMonth} / {monthLabel} {pricePlanRecord2.PriceUnit ? "("+pricePlanRecord2.PriceUnit.text+")" : ""}</strong>
                                                                    }
                                                                </p>
                                                            }

                                                            {pricePlanRecord2.PriceComments &&
                                                                <p className={clsx(classes.fontWeightLight)}>
                                                                    {pricePlanRecord2.PriceComments}
                                                                </p>
                                                            }

                                                            {pricePlanRecord2 && pricePlanRecord2.priceItems &&
                                                                <ul className="text-left mt-5 mb-5">
                                                                    {
                                                                        pricePlanRecord2.priceItems.map((record, index) => (
                                                                            <p key={index}><li>{record.PriceItem}</li></p>
                                                                        ))
                                                                    }
                                                                </ul>
                                                            }
                                                        </Grid>
                                                    </CardContent>
                                                </CardActionArea>

                                                <CardActions>
                                                    <Grid container justify="center">
                                                        <RouterLink to="/webPages">
                                                            <span className="btn btn-primary mt-4">{priceActionLabel}</span>
                                                        </RouterLink>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        }
                                    </Grid>
                                </Grid>
                            }

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={36} />
                            </Grid>

                            {/* CARD 3 */}

                            {!lodash.isEmpty(pricePlanRecord3) &&
                                <Grid item xs={12} sm={12}>
                                    <Grid container justify="center">
                                        {pricePlanRecord3 &&
                                            <Card className={classes.card}>
                                                <CardActionArea>
                                                    <CardContent className={classes.cardAction}>
                                                        <Grid container justify="center" alignItems="center" alignContent="center" direction="column">
                                                        <h2>{pricePlanRecord3.PriceCategoryText}</h2>

                                                        {switchChecked ?
                                                            <>
                                                                {pricePlanRecord3.AmountPerYear &&
                                                                    <p className={classes.lead}>
                                                                        {pricePlanRecord3.AmountPerYear &&
                                                                            <strong>{pricePlanRecord3.AmountPerYear} / {yearLabel} {pricePlanRecord3.PriceUnit ? "("+pricePlanRecord3.PriceUnit.text+")" : ""}</strong>
                                                                        }

                                                                        {pricePlanRecord3.YearlyDiscount &&
                                                                            <Typography variant="caption" style={{display: 'block'}}>
                                                                                {pricePlanRecord3.YearlyDiscount} {savingsLcLabel}
                                                                            </Typography>
                                                                        }
                                                                    </p>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {pricePlanRecord3.AmountPerMonth &&
                                                                    <p className={classes.lead}>
                                                                        {pricePlanRecord3.AmountPerMonth &&
                                                                            <strong>{pricePlanRecord3.AmountPerMonth} / {monthLabel} {pricePlanRecord3.PriceUnit ? "("+pricePlanRecord3.PriceUnit.text+")" : ""}</strong>
                                                                        }
                                                                    </p>
                                                                }
                                                            </>
                                                        }

                                                        {!pricePlanRecord3.AmountPerYear &&
                                                            <>
                                                                {pricePlanRecord3.PriceUnit &&
                                                                    <p className={classes.lead}>
                                                                        {pricePlanRecord3.PriceUnit.text}
                                                                    </p>
                                                                }
                                                            </>
                                                        }

                                                        {pricePlanRecord3.PriceComments &&
                                                            <p className={clsx(classes.fontWeightLight)}>
                                                                {pricePlanRecord3.PriceComments}
                                                            </p>
                                                        }

                                                        {pricePlanRecord3 && pricePlanRecord3.priceItems &&
                                                            <ul className="text-left mt-5 mb-5">
                                                                {
                                                                    pricePlanRecord3.priceItems.map((record, index) => (
                                                                        <p key={index}><li>{record.PriceItem}</li></p>
                                                                    ))
                                                                }
                                                            </ul>
                                                        }
                                                        </Grid>
                                                    </CardContent>
                                                </CardActionArea>

                                                <CardActions>
                                                    <Grid container justify="center">
                                                        <RouterLink to="/webPages">
                                                            <span className="btn btn-outline-primary mt-4">{priceActionLabel}</span>
                                                        </RouterLink>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        }
                                    </Grid>
                                </Grid>
                            }
                        </Container>
                    </section>
                </>
            }
        </>
    );
}

export default Pricings01;
