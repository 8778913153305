import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
import SaveIcon from "@material-ui/icons/Save";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputAdornment from "@material-ui/core/InputAdornment";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from "@material-ui/core/Container";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import CheckIcon from '@material-ui/icons/Check';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import ImageSearchIcon from '@material-ui/icons/ImageSearch';
// import InputAdornment from "@material-ui/core/InputAdornment";
import TranslateIcon from '@material-ui/icons/Translate';
import Paper from "@material-ui/core/Paper";
import ImportExportIcon from '@material-ui/icons/ImportExport';

// react-responsive
import { useMediaQuery } from 'react-responsive';

// moment
import moment from "moment";

// Lodash
import lodash from "lodash";

// react-media
// import Media from "react-media";

// Validator
// import validator from 'validator';
// import isEmail from "validator/lib/isEmail";
// import isURL from "validator/lib/isURL";
// import isMobilePhone from "validator/lib/isMobilePhone";

//---------------------
// For Primereact
//---------------------

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

// For Primereact
import { Dialog } from 'primereact/dialog';
import { OrderList } from 'primereact/orderlist';

// Froala
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Amplify
import { API } from "aws-amplify";
// import { AmplifyTheme   } from 'aws-amplify-react';

// AppContext
import AppContext from "../../config/AppContext";

import LineSpacing from "../../components/misc/LineSpacing";
// import FetchS3Object from "../../components/media/FetchS3Object";
// import FroalaCommonEditor from "../../components/editor/FroalaCommonEditor";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import AlertDialog from "../../components/dialogs/AlertDialog";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GenerateUniqueName from "../../utils/GenerateUniqueName";
// import GenerateUlid from "../../utils/GenerateUlid";
// import FormatDateTime from "../../../../../utils/moment/FormatDateTime";
import FormatTimezoneDates from "../../utils/moment/FormatTimezoneDates";
import FilterArrayFromArray from "../../utils/filter/FilterArrayFromArray";
import FormatNumber from "../../utils/numberFormat/FormatNumber";
// import FormatNumberWithOption from "../../utils/numberFormat/FormatNumberWithOption";
// import FormatCurrency from "../../utils/numberFormat/FormatCurrency";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

import FaqConverter from "./FaqConverter";

// Views
import ManageFaqsTv from "./views/ManageFaqsTv";
import ManageFaqsMv from "./views/ManageFaqsMv";

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    leftPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "left",
    },
    leftPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1rem" /* Footer height */,

        width: "100% !important",
        marginTop: "auto",
        // backgroundColor: '#fff',
        textAlign: "center",
    },
    paper: {
        minWidth: "300px",
        marginTop: theme.spacing(2),
        alignItems: "center",
        padding: "10px 30px 10px 30px !important",
        textAlign: "center",
    },
}));

let pageLabels = {
    manageFaqsLabel: "Manage FAQs",
    requiredFields: "* Required fields",

    languageLabel: "Language",
    languagePrompt: "Select language",
    languageErrMsg: "Language is required",

    domainLabel: "Domain",
    domainPrompt: "Select a domain",
    domainErrMsg: "Domain is required",

    faqCategoryLabel: "FAQ category",
    faqCategoryPrompt: "Select FAQ category",
    faqCategoryErrMsg: "FAQ category is required",
    
    faqQuestionLabel: "Question",
    faqQuestionPrompt: "Enter a question",
    faqQuestionErrMsg: "Question is required",

    faqAnswerLabel: "Answer",
    faqAnswerPrompt: "Enter an answer",
    faqAnswerErrMsg: "Answer is required",
    // faqAnswerMsg: "Add comments about the faq category",

    // selectTitlePrompt: "Select title",
    
    // blogStartTimeLabel: "Blog start time",
    // blogStartTimePrompt: "Select date & time to publish the blog",
    // blogStartTimeErrMsg: "Blog start time is invalid",

    // uploadFaqQuestionLabel: "Blog title",
    // uploadFaqQuestionPrompt: "Please select blog title",
    // uploadFaqQuestionErrMsg: "Blog title is required",

    // liveEventUrlLabel: "Live event URL",
    // liveEventUrlPrompt: "Please enter the live event URL",
    // liveEventUrlErrMsg: "Live event URL is invalid",

    // liveEventUrlTypeLabel: "Live event URL type",
    // liveEventUrlTypePrompt: "Select live event URL type",
    // liveEventUrlTypeErrMsg: "Live event URL type is required",

    // blogContentLabel: "Blog content",
    // blogContentPrompt: "Please enter your blog content",
    // blogContentErrMsg: "Live event description is required",

    // yourTimeZoneMsg: "Your timeZone is",
    // timeZoneLabel: "Time zone",
    // cloneEventLabel: "Clone event",

    faqRecordLabel: "frequently asked question",
    faqRecordsLabel: "frequently asked questions",
    // inProgressLabel: "In progress",
    // startLabel: "Start",
    // endLabel: "End",
    // notStartedLabel: "Not started",
    // dateCreatedLabel: "Date created",

    editLabel: "Edit",
    deleteLabel: "Delete",

    toLabel: "to",
    ofLabel: "of",
    recordsLabel: "records",
    noRecordsFound: "No records found",

    operationSuccessMsg: "Operation was successful.",
    operationFailureMsg: "Operation failed.",
    duplicateTransMsg: "Duplicate transaction",

    loadingText: "Loading ...",
    noOptionsText: "No options",
    clearText: "Clear",
    closeText: "Close",
    // showLabel: "Show",
    // hideLabel: "Hide",

    cancelLabel: "Cancel",
    // clearLabel: "Clear",
    cloneRecordLabel: "Clone record",

    yesLabel: "Yes",
    deleteRequested: "Delete requested",
    areYouSureMsg: "Are you sure?",
    requestedToDeleteMsg: "You have requested to delete",
  
    createFaqLabel: "Create FAQ",
    updateFaqLabel: "Update FAQ",

    createMoreLabel: "Create more",
    createLabel: "Create",
    // editEventDestLabel: "Edit email template",
    closeLabel: "Close",
            
    gridFaqAnswer: "Answer",
    gridCategory: "Category",
    gridFaqQuestion: "Question",
    lastUpdateLabel: "Last update",

    // gridEventUrl: "Event URL",
    // gridStartTime: "Start time",
    // gridBannerUrl: "Banner URL",
    
    convertLabel: "Convert",
    convertFaqsLabel: "Convert FAQs",

    // sourceLanguageLabel: "Source language",
    // sourceLanguagePrompt: "Select source language",
    // sourceLanguageErrMsg: "Source language is required",

    // targetLanguageLabel: "Target language",
    // targetLanguagePrompt: "Select target language",
    // targetLanguageErrMsg: "Target language is required",

    moreLabel: "- more -",
    uploadLabel: "Upload",

    organizeLabel: "Organize",
    organizeFaqListLabel: "Organize FAQs",
    freqAskedQuestionsLabel: "Frequently Asked Questions",
    saveLabel: "Save",
};

function ManageFaqs() {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);
    const [renderDomFlag, setRenderDomFlag] = useState(false);
    const [progressFlag, setProgressFlag] = useState(false);

    // For Global Errors
    const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");

    // For Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarsMsg, setSnackbarsMsg] = useState("");
    const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    //--------------------
    // For Froala Editor
    //--------------------
    const editorRef = useRef(null);
    // const [editorModelData, setEditorModelData] = useState(null);
    const [editorMaxChars, setEditorMaxChars] = useState(10000);// Max chars allowed in editor

    //--------------------
    // For Form Attributes
    //--------------------
    // const [profilePhotoSrc, setProfilePhotoSrc] = useState(null);
    
    const [language, setLanguage] = useState(null);
    const [faqAnswer, setFaqAnswer] = useState("");
    // const [authorPhotoSrc, setAuthorPhotoSrc] = useState("");
    const [faqQuestion, setFaqQuestion] = useState("");
    const [blogStartTime, setBlogStartTime] = useState(null);
    const [faqCategory, setFaqCategory] = useState(null);
    const [blogContent, setBlogContent] = useState("");
    const [domain, setDomain] = useState(null);
    const [domainOptions, setDomainOptions] = useState([]);
    
    // const [eventThumbnailUrl, setEventThumbnailUrl] = useState("");
    // const [eventBannerUrl, setEventBannerUrl] = useState("");
    // const [eventPosterUrl, setEventPosterUrl] = useState("");
    // const [eventAudioUrl, setEventAudioUrl] = useState("");
    
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
         
    const [languageErrFlag, setLanguageErrFlag] = useState(false);
    const [domainErrFlag, setDomainErrFlag] = useState(false);
    const [faqAnswerErrFlag, setFaqAnswerErrFlag] = useState(false);
    const [faqQuestionErrFlag, setFaqQuestionErrFlag] = useState(false);
    const [blogStartTimeErrFlag, setBlogStartTimeErrFlag] = useState(false);
    const [faqCategoryErrFlag, setFaqCategoryErrFlag] = useState(false);
    const [blogContentErrFlag, setBlogContentErrFlag] = useState(false);

    const [categoryOptions, setCategoryOptions] = useState([]);
    
    const [faqListRecordsBackup, setFaqListRecordsBackup] = useState([]);
    const [faqListRecords, setFaqListRecords] = useState([]);

    const [submitButtonFlag, setSubmitButtonFlag] = useState(false);
    // const [addTagButtonFlag, setAddTagButtonFlag] = useState(false);
    const [editModeFlag, setEditModeFlag] = useState(false);

    const [datePickerLanguage, setDatePickerLanguage] = useState(appContext.currentLanguage.languageCode);
    
    
    const [showListFlag, setShowListFlag] = useState(true);
    const [showDetailsFormFlag, setShowDetailsFormFlag] = useState(false);
    const [uploadFlag, setUploadFlag] = useState(false);
    const [convertFlag, setConvertFlag] = useState(false);
       
    const [pageSize, setPageSize] = useState(10000);// AWS put PageSize to a max 0f 100

    const [noRecordFoundFlag, setNoRecordFoundFlag] = useState(false);
    // const [eventStartedFlag, setEventStartedFlag] = useState(false);
    // const [startedEventId, setStartedEventId] = useState(null);
    
    const [textMaxLength, setTextMaxLength] = useState(256);

    //--------------------------
    // For sliceText and Dialog
    //--------------------------
    const [dialogData, setDialogData] = useState("");
    const [dialogHeader, setDialogHeader] = useState("");
    
    // const [terminologyNames, setTerminologyNames] = useState([
    //     "translate_custom_terminology.txt"
    // ]);
    

    //-------------------------
    // For Primereact 
    // DataTable Attributes 
    //-------------------------
    // const [ dataTableInfo, setDataTableInfo] = useState([]);
    // const [dataTableInfoFlag, setDataTableInfoFlag] = useState(false);
    
    // const [resizableColumnsFlag, setResizableColumnsFlag] = useState(true);
    // const [columnResizeMode, setColumnResizeMode] = useState("fit");
    // const [sortMode, setSortMode] = useState("multiple");
    // const [responsiveFlag, setResponsiveFlag] = useState(true);
    // const [reorderableColumnsFlag, setReorderableColumnsFlag] = useState(true);
    // const [paginatorFlag, setPaginatorFlag] = useState(true);
    const [paginatorTemplate, setPaginatorTemplate] = useState("FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown");
    // const [currentPageReportTemplate, setCurrentPageReportTemplate] = useState(null);
    const [visibleRowsCount, setVisibleRowsCount] = useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5,10,20,50,100]);

    // const [selectionMode, setSelectionMode] = useState("multiple");// single or multiple
    // const [selectedTableRow, setSelectedTableRow] = useState(null);// For single selection
    const [selectedTableRows, setSelectedTableRows] = useState([]);// For multiple selection
    // const [updatedTableRows, setUpdatedTableRows] = useState([]); // For updated (edited) table rows
    // const [deletedTableRows, setDeletedTableRows] = useState([]); // For deleted table rows
    const [tableDirtyFlag, setTableDirtyFlag] = useState(false); // To show DataTable cell was edited (modified)

    // const [deleteRowFlag, setDeleteRowFlag] = useState(false);

    //--------------------
    // For AlertDialog
    //--------------------
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogSubContent, setDialogSubContent] = useState(null);

    const [recordToDelete, setRecordToDelete] = useState(null);
    const [recordToEdit, setRecordToEdit] = useState(null);
    
    //-----------------------
    // For Primereact Dialog
    //-----------------------
    const [dlgVisibleFlag, setDlgVisibleFlag] = useState(false);

    //--------------------
    // For Languages
    //--------------------
    const [languages, setLanguages] = useState([]);

    //------------------
    // For OrderList
    //------------------
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [orderListFlag, setOrderListFlag] = useState(false);
    const [dragdropFlag, setDragdropFlag] = useState(true); // Allow drag and drop
    
    // const {
    //     // editorId,
    // } = props;

    const theme = useTheme();
  
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    const { 
        manageFaqsLabel, 
        requiredFields,

        languageLabel,
        languagePrompt,
        languageErrMsg,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,
            
        faqAnswerLabel,
        faqAnswerPrompt,
        faqAnswerErrMsg,
        // faqAnswerMsg,
        
        faqQuestionLabel,
        faqQuestionPrompt,
        faqQuestionErrMsg,
        // selectTitlePrompt,
    
        // blogStartTimeLabel,
        // blogStartTimePrompt,
        // blogStartTimeErrMsg,

        faqCategoryLabel,
        faqCategoryPrompt,
        faqCategoryErrMsg,

        // uploadFaqQuestionLabel,
        // uploadFaqQuestionPrompt,
        // uploadFaqQuestionErrMsg,

        // liveEventUrlLabel,
        // liveEventUrlPrompt,
        // liveEventUrlErrMsg,

        // liveEventUrlTypeLabel,
        // liveEventUrlTypePrompt,
        // liveEventUrlTypeErrMsg,

        faqRecordLabel,
        faqRecordsLabel,
        // inProgressLabel,
        // startLabel,
        // endLabel,
        // notStartedLabel,
    
        // dateCreatedLabel,
    
        // blogContentLabel,
        // blogContentPrompt,
        // blogContentErrMsg,

        // yourTimeZoneMsg,
        // timeZoneLabel,
        // cloneEventLabel,
    
        toLabel,
        ofLabel,
        recordsLabel,
        noRecordsFound,

        gridCategory,
        editLabel,
        deleteLabel,

        operationSuccessMsg,
        operationFailureMsg,
        duplicateTransMsg,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,
        // showLabel,
        // hideLabel,

        cancelLabel,
        // clearLabel,
        cloneRecordLabel,

        yesLabel,
        deleteRequested,
        areYouSureMsg,
        requestedToDeleteMsg,
    
        uploadLabel,
        
        createFaqLabel,
        updateFaqLabel,

        createMoreLabel,
        createLabel,
        // editEventDestLabel,
        closeLabel,
        // createFaqLabel,

        gridFaqQuestion,
        gridFaqAnswer,
        // gridEventUrl,
        lastUpdateLabel,
        // gridStartTime,
        // gridBannerUrl,
    
        convertLabel,
        convertFaqsLabel,

        // sourceLanguageLabel,
        // sourceLanguagePrompt,
        // sourceLanguageErrMsg,

        // targetLanguageLabel,
        // targetLanguagePrompt,
        // targetLanguageErrMsg,

        moreLabel,

        organizeLabel,
        organizeFaqListLabel,
        freqAskedQuestionsLabel,
        saveLabel,
    } = pageLabelsRes;

    async function fetchDbResources(cacheItemKey) {
        backdropProgress(true);

        let keys = [];
        let objKeys = Object.keys(pageLabels);
        // console.log("In ManageFaqs(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
        if (objKeys !== null && objKeys.length > 0) {
            objKeys.map((key) => {
                let partitionKey = appContext.currentLanguage.languageCode;
                partitionKey += ".";
                partitionKey += GenerateModulusHash(key, 200);

                let itemKey = {
                ResourcePK: partitionKey, // e.g. 'en.91'
                ResourceSK: key, // e.g. 'firstName'
                };
                keys.push(itemKey);
            });
        }
        // console.log("In ManageFaqs(): fetchDbResources(): keys.length is: ", keys.length);
        // console.log("In ManageFaqs(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

        let arrayData = [];

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/batchGet",
            payload: {
                keys: keys,
                projectionExpression: "ResourceCode,ResourceDesc",
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
            response.data.Responses.resource.map((item, index) => {
            let record = {
                ResourceCode: item.ResourceCode,
                ResourceDesc: item.ResourceDesc,
            };

            arrayData.push(record);
            });
                    
            if (arrayData && arrayData.length > 0) {
                arrayData.map((item) => {
                    if (item.ResourceCode === "manageFaqsLabel") pageLabels.manageFaqsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "requiredFields") pageLabels.requiredFields = item.ResourceDesc;
                    
                    if (item.ResourceCode === "languageLabel") pageLabels.languageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "languagePrompt") pageLabels.languagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "languageErrMsg") pageLabels.languageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "domainLabel") pageLabels.domainLabel = item.ResourceDesc;
                    if (item.ResourceCode === "domainPrompt") pageLabels.domainPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "domainErrMsg") pageLabels.domainErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "faqAnswerLabel") pageLabels.faqAnswerLabel = item.ResourceDesc;
                    if (item.ResourceCode === "faqAnswerPrompt") pageLabels.faqAnswerPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "faqAnswerErrMsg") pageLabels.faqAnswerErrMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "faqAnswerMsg") pageLabels.faqAnswerMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "faqQuestionLabel") pageLabels.faqQuestionLabel = item.ResourceDesc;
                    if (item.ResourceCode === "faqQuestionPrompt") pageLabels.faqQuestionPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "faqQuestionErrMsg") pageLabels.faqQuestionErrMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "selectTitlePrompt") pageLabels.selectTitlePrompt = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "blogStartTimeLabel") pageLabels.blogStartTimeLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "blogStartTimePrompt") pageLabels.blogStartTimePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "blogStartTimeErrMsg") pageLabels.blogStartTimeErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "faqCategoryLabel") pageLabels.faqCategoryLabel = item.ResourceDesc;
                    if (item.ResourceCode === "faqCategoryPrompt") pageLabels.faqCategoryPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "faqCategoryErrMsg") pageLabels.faqCategoryErrMsg = item.ResourceDesc;
            
                    // if (item.ResourceCode === "uploadFaqQuestionLabel") pageLabels.uploadFaqQuestionLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "uploadFaqQuestionPrompt") pageLabels.uploadFaqQuestionPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "uploadFaqQuestionErrMsg") pageLabels.uploadFaqQuestionErrMsg = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "liveEventUrlTypeLabel") pageLabels.liveEventUrlTypeLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "liveEventUrlTypePrompt") pageLabels.liveEventUrlTypePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "liveEventUrlTypeErrMsg") pageLabels.liveEventUrlTypeErrMsg = item.ResourceDesc;
                                    
                    // if (item.ResourceCode === "yourTimeZoneMsg") pageLabels.yourTimeZoneMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "timeZoneLabel") pageLabels.timeZoneLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "cloneEventLabel") pageLabels.cloneEventLabel = item.ResourceDesc;
                                
                    if (item.ResourceCode === "faqRecordsLabel") pageLabels.faqRecordsLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "inProgressLabel") pageLabels.inProgressLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "startLabel") pageLabels.startLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "endLabel") pageLabels.endLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "notStartedLabel") pageLabels.notStartedLabel = item.ResourceDesc;
                      
                    // if (item.ResourceCode === "dateCreatedLabel") pageLabels.dateCreatedLabel = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "blogContentLabel") pageLabels.blogContentLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "blogContentPrompt") pageLabels.blogContentPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "blogContentErrMsg") pageLabels.blogContentErrMsg = item.ResourceDesc; 

                    if (item.ResourceCode === "toLabel") pageLabels.toLabel = item.ResourceDesc;
                    if (item.ResourceCode === "ofLabel") pageLabels.ofLabel = item.ResourceDesc;
                    if (item.ResourceCode === "recordsLabel") pageLabels.recordsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noRecordsFound") pageLabels.noRecordsFound = item.ResourceDesc;

                    if (item.ResourceCode === "createLabel") pageLabels.createLabel = item.ResourceDesc;
                    if (item.ResourceCode === "gridCategory") pageLabels.gridCategory = item.ResourceDesc;
                    if (item.ResourceCode === "editLabel") pageLabels.editLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteLabel") pageLabels.deleteLabel = item.ResourceDesc;

                    if (item.ResourceCode === "operationSuccessMsg") pageLabels.operationSuccessMsg = item.ResourceDesc;
                    if (item.ResourceCode === "operationFailureMsg") pageLabels.operationFailureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "duplicateTransMsg") pageLabels.duplicateTransMsg = item.ResourceDesc;
                                                                        
                    if (item.ResourceCode === "loadingText") pageLabels.loadingText = item.ResourceDesc;
                    if (item.ResourceCode === "noOptionsText") pageLabels.noOptionsText = item.ResourceDesc;
                    if (item.ResourceCode === "clearText") pageLabels.clearText = item.ResourceDesc;
                    if (item.ResourceCode === "closeText") pageLabels.closeText = item.ResourceDesc;
                    // if (item.ResourceCode === "showLabel") pageLabels.showLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "hideLabel") pageLabels.hideLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "cancelLabel") pageLabels.cancelLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "clearLabel") pageLabels.clearLabel = item.ResourceDesc;
                    if (item.ResourceCode === "cloneRecordLabel") pageLabels.cloneRecordLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "yesLabel") pageLabels.yesLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteRequested") pageLabels.deleteRequested = item.ResourceDesc;
                    if (item.ResourceCode === "areYouSureMsg") pageLabels.areYouSureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "requestedToDeleteMsg") pageLabels.requestedToDeleteMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "uploadLabel") pageLabels.uploadLabel = item.ResourceDesc;
          
                    if (item.ResourceCode === "createFaqLabel") pageLabels.createFaqLabel = item.ResourceDesc;
                    if (item.ResourceCode === "updateFaqLabel") pageLabels.updateFaqLabel = item.ResourceDesc;
                              
                    if (item.ResourceCode === "faqRecordLabel") pageLabels.faqRecordLabel = item.ResourceDesc;
                    if (item.ResourceCode === "createMoreLabel") pageLabels.createMoreLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "editEventDestLabel") pageLabels.editEventDestLabel = item.ResourceDesc;
                    if (item.ResourceCode === "closeLabel") pageLabels.closeLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "createFaqLabel") pageLabels.createFaqLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "gridFaqQuestion") pageLabels.gridFaqQuestion = item.ResourceDesc;
                    // if (item.ResourceCode === "gridEventUrl") pageLabels.gridEventUrl = item.ResourceDesc;
                    if (item.ResourceCode === "lastUpdateLabel") pageLabels.lastUpdateLabel = item.ResourceDesc;
                                              
                    // if (item.ResourceCode === "gridStartTime") pageLabels.gridStartTime = item.ResourceDesc;
                    // if (item.ResourceCode === "gridBannerUrl") pageLabels.gridBannerUrl = item.ResourceDesc;
                    if (item.ResourceCode === "gridFaqAnswer") pageLabels.gridFaqAnswer = item.ResourceDesc;
                                              
                    if (item.ResourceCode === "convertLabel") pageLabels.convertLabel = item.ResourceDesc;
                    if (item.ResourceCode === "convertFaqsLabel") pageLabels.convertFaqsLabel = item.ResourceDesc;
                                        
                    // if (item.ResourceCode === "sourceLanguageLabel") pageLabels.sourceLanguageLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "sourceLanguagePrompt") pageLabels.sourceLanguagePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "sourceLanguageErrMsg") pageLabels.sourceLanguageErrMsg = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "targetLanguageLabel") pageLabels.targetLanguageLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "targetLanguagePrompt") pageLabels.targetLanguagePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "targetLanguageErrMsg") pageLabels.targetLanguageErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "moreLabel") pageLabels.moreLabel = item.ResourceDesc;
                    
                    if (item.ResourceCode === "organizeLabel") pageLabels.organizeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "organizeFaqListLabel") pageLabels.organizeFaqListLabel = item.ResourceDesc;
                    if (item.ResourceCode === "freqAskedQuestionsLabel") pageLabels.freqAskedQuestionsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "saveLabel") pageLabels.saveLabel = item.ResourceDesc;
                });
                // Add it to cache
                SetCacheItem(cacheItemKey, pageLabels);
            }

            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setRenderDomFlag(true);
        }).catch((err) => {
            console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setRenderDomFlag(true);
        });
    }
    
    let listNextToken = null;

    async function fetchFaqRecords(selectedDomain) {
        // console.log('In fetchFaqRecords(): selectedDomain is: ', selectedDomain);
        if (language && selectedDomain) {
            backdropProgress(true);
            setNoRecordFoundFlag(false);
            setFaqListRecordsBackup([]);

            let params = {
                apiName: "FaqAPI",
                path: "/dynamodb/faq/query",
                payload: {
                    keyConditionExpression:
                        // "#faqPK = :faqPK",
                        "#faqPK = :faqPK AND begins_with(#faqSK, :faqSK)",
                    expressionAttributeNames: {
                        "#faqPK": "FaqPK",
                        "#faqSK": "FaqSK",
                    },
                    expressionAttributeValues: {
                        ":faqPK": selectedDomain.value + "#" + language.languageCode + "#faq",
                        ":faqSK": selectedDomain.value + "#" + language.languageCode + "#faq",
                    },
                    // indexName: "FaqDomain-LangAuthorUserName-index"
                        // "keyConditionExpression": "#faqPK = :faqPK",
                        // "expressionAttributeNames": {"#faqPK": "FaqPK"},
                        // "expressionAttributeValues": {":faqPK": "languages"}
                },
            };

            if (!lodash.isEmpty(listNextToken)) {
                params.payload.nextToken = listNextToken;
            }
    
            if (!lodash.isEmpty(pageSize)) {
                params.payload.pageSize = pageSize;
            }

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchFaqRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchFaqRecords(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchFaqRecords(): response.data.NextToken is: ', response.data.NextToken);
                // console.log('In fetchFaqRecords(): response.data.Items is: ', response.data.Items);

                let records = [];

                listNextToken = null;

                if (response && !lodash.isEmpty(response.data.NextToken)) {
                    listNextToken = response.data.NextToken;
                    records = faqListRecords;
                }
            
                let items = response.data.Items;

                if (items && items.length > 0) {
                    setFaqListRecords([]);

                    items.map((record) => {
                        // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                        // console.log('In fetchFaqRecords(): formatedDateTime is: ', formatedDateTime);
                        // console.log('In fetchFaqRecords(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
                        let formattedDateTime = null;
                        if (record.UpdatedAt) {
                            formattedDateTime = FormatTimezoneDates(record.UpdatedAt, record.LanguageCode, timeZone, "shortDateAndTime");
                        }
                        // console.log('In fetchFaqRecords(): formattedDateTime is: ', formattedDateTime);

                        let item = {
                            FaqPK: record.FaqPK,
                            FaqSK: record.FaqSK,

                            LanguageObject: record.LanguageObject,
                            LanguageCode: record.LanguageCode,
                            Domain: record.Domain,

                            "FaqId": lodash.isEmpty(record.FaqId) ? "" : record.FaqId,

                            "FaqDomain": lodash.isEmpty(record.Domain) ? "" : record.Domain.value,
                            "FaqCategory": lodash.isEmpty(record.FaqCategory) ? null : record.FaqCategory,
                            "FaqCategoryText": lodash.isEmpty(record.FaqCategory) ? "" : record.FaqCategory.text,
                            "FaqQuestion": lodash.isEmpty(record.FaqQuestion) ? "" : record.FaqQuestion,
                            "FaqAnswer": lodash.isEmpty(record.FaqAnswer) ? "" : record.FaqAnswer,

                            "FaqSeqNumber": record.FaqSeqNumber,
            
                            "FormattedDateTime": formattedDateTime,
                            "UpdatedAt": record.UpdatedAt,
                        };
                        records.push(item);
                    });
                } else {
                    setNoRecordFoundFlag(true);
                }

                // console.log('In fetchFaqRecords(): listNextToken is: ', listNextToken);
                if (!lodash.isEmpty(listNextToken)) {
                    await fetchFaqRecords();
                }
                
                setTimeout(() => {
                    records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                    setFaqListRecords(records);
                    setFaqListRecordsBackup(records);
                }, 100);

                backdropProgress(false);
            }).catch((err) => {
                console.error("In fetchFaqRecords(): err is: ", JSON.stringify(err, null, 2));
                setFaqListRecords([]);
                setNoRecordFoundFlag(true);
                backdropProgress(false);
            });
        }
    }
    
    async function getFaqRecord(key) {
        // console.log('In getFaqRecord(): key.FaqPK is: ', key.FaqPK);
        // console.log('In getFaqRecord(): key.FaqSK is: ', key.FaqSK);
        
        backdropProgress(true);
    
        let params = {
            apiName: "FaqAPI",
            path: "/dynamodb/faq/get",
            payload: {
                key: {
                    FaqPK: key.FaqPK,
                    FaqSK: key.FaqSK,
                },
            },
        };
  
        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
  
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In getFaqRecord(): initObj is: ', JSON.stringify(initObj, null, 2));
  
        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In getFaqRecord(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In getFaqRecord(): response.data.Item is: ', response.data.Item);
        
            let item = response.data.Item;

            appContext.currentPK = item.FaqPK;
            appContext.currentSK = item.FaqSK;
        
            setLanguage(item.LanguageObject);

            setDomain(item.Domain ? item.Domain : null);
            setFaqCategory(item.FaqCategory ? item.FaqCategory : null);
            setFaqQuestion(item.FaqQuestion ? item.FaqQuestion : "");
            setFaqAnswer(item.FaqAnswer ? item.FaqAnswer : "");

            setShowListFlag(false);
            setUploadFlag(false);
            setOrderListFlag(false);
            setShowDetailsFormFlag(true);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);

            backdropProgress(false);
        }).catch((err) => {
            console.error("In getFaqRecord(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        // console.log("In editRecordAction(): faqListRecords is: ", faqListRecords);
        
        setRecordToEdit(item);
        await getFaqRecord({FaqPK: item.FaqPK, FaqSK: item.FaqSK,});
    }

    async function fetchLanguages() {
        // console.log("In fetchLanguages()");
        // setFilterDisabledFlag(true);

        //-------------------------------------------------------
        // Make sure languages is not in cache before proceeding
        //-------------------------------------------------------

        let cachedLanguages = GetCacheItem("languages");
        // console.log('In fetchLanguages(): cachedLanguages is: ', JSON.stringify(cachedLanguages, null, 2));
        cachedLanguages = null;
        if (cachedLanguages && cachedLanguages !== null && cachedLanguages.length > 0) {
            // console.log('In fetchLanguages(): cachedLanguages length is: ', cachedLanguages.length);
            setLanguages(cachedLanguages);
            return true;
        }

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/query",
            payload: {
                keyConditionExpression: "#resourcePK = :resourcePK",
                expressionAttributeNames: { "#resourcePK": "ResourcePK" },
                expressionAttributeValues: { ":resourcePK": "languages" },
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log("In fetchLanguages(): response length is: ", response.data.Items.length);
            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));

            // response.data.Items.sort((a, b) =>
            //     a.LanguageName > b.LanguageName ? 1 : -1
            // );

            let records = [];
            let items = response.data.Items;

            items.map(item => {
                let record ={
                    languageCode: item.ResourceCode,
                    languageName: item.LanguageName
                };
                records.push(record);
            });

            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
            setLanguages(records);

            // Add it to cache
            SetCacheItem("languages", response.data.Items);
            backdropProgress(false);
        }).catch((err) => {
            console.error("In fetchLanguages(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    };

    let  refDataSelectOptions = [];
    
    async function fetchReferenceData(refType, lang) {
        return new Promise((resolve, reject) => {
            // console.log('In ManageFaqs  fetchReferenceData(): refType is: ', refType);
            // console.log('In ManageFaqs  fetchReferenceData(): lang is: ', lang);
            backdropProgress(true);

            refDataSelectOptions = [];

            let apiName = null;
            let path = null;
            let keyConditionExpression = null;
            let expressionAttributeNames = {};
            let expressionAttributeValues = {};
            
            switch(refType) {
                case "faq-categories":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "faq-categories",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                case "supported-domains":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "supported-domains",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                default:
                    break;
            }

            setProgressFlag(true);
        
            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    keyConditionExpression: keyConditionExpression,
                    expressionAttributeNames: expressionAttributeNames,
                    expressionAttributeValues: expressionAttributeValues,
                    // "keyConditionExpression": "#resourcePK = :resourcePK",
                    // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                    // "expressionAttributeValues": {":resourcePK": "languages"}
                },
            };
        
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
        
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In  fetchReferenceData(): initObj is: ', JSON.stringify(initObj, null, 2));
        
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In  fetchReferenceData(): response.length is: ', response.data.Items.length);
                // console.log('In  fetchReferenceData(): response is: ', JSON.stringify(response.data.Items, null, 2));
                response.data.Items.map((item) => {
                    let record = {
                        value: item.ResourceCode,
                        text: item.ResourceDesc,
                    };

                    if (record.value !== "all") {// Bypass all
                        refDataSelectOptions.push(record);
                    }
                });

                switch(refType) {
                    case "faq-categories":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setCategoryOptions( refDataSelectOptions);
                        break;
                    case "supported-domains":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setDomainOptions(refDataSelectOptions);
                        break;
                    default:
                        break;
                }

                backdropProgress(false);
                resolve(refDataSelectOptions);
            }).catch((err) => {
                console.error("In ManageFaqs  fetchReferenceData(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject(err);
            });
        });
    }
        
    useEffect(() => {
        // console.log('In ManageFaqs(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        // sessionStorage.removeItem("current-live-event");
    }, []);

    useLayoutEffect(() => {
        // console.log("In ManageFaqs(): useLayoutEffect()");
        async function fetchItems() {
            await fetchLanguages();

            // await fetchCategories("ManageFaqs", appContext.currentLanguage);

            // await fetchDomains("ManageFaqs");

            // await fetchFaqRecords();

            // try{
            //     // let imageKeyPrefix = "cob/jekwempu@tobisoft.co" + "/user/profile/";
            //     let imageKeyPrefix = appContext.shortDomain + "/" + appContext.userName + "/user/profile/";
            //     await FetchS3Object(imageKeyPrefix, imageObjectCallback);
            // } catch (error) {
            //     console.log("In ManageFaqs(): useLayoutEffect(): error is: ", error);
            // }

            // Fetch resource from catche
            let cacheItemKey = "manage-faq-headers";
            cacheItemKey += ":";
            cacheItemKey += appContext.currentLanguage.languageCode;
            // console.log('In ManageFaqs(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

            if (
                appContext.clearCacheLanguageCodes &&
                appContext.clearCacheLanguageCodes.length > 0 &&
                appContext.clearCacheLanguageCodes.includes(appContext.currentLanguage.languageCode)
            ) {
                // console.log("In ManageFaqs(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                RemoveCacheItem(cacheItemKey);
            }

            let cacheItem = GetCacheItem(cacheItemKey);
            // console.log('In ManageFaqs(): useLayoutEffect(): cacheItem is: ', cacheItem);
            cacheItem = null; // remove after testing

            if (cacheItem === null) {
                // resource is not in cache, fetch from DB
                // console.log('In ManageFaqs(): useLayoutEffect(): resource is not in cache, fetch from DB');
                await fetchDbResources(cacheItemKey);
            } else {
                // console.log('In ManageFaqs(): useLayoutEffect(): got resource from cache');
                setPageLabelsRes(cacheItem);
                setRenderDomFlag(true);
            }
        }

        fetchItems();
    }, []);
    
    function handleChange(event) {
        // console.log("In handleChange(): event.target.id is: ", event.target.id);
        // console.log("In handleChange(): event.target.value is: ", event.target.value);
        // setSubmitButtonFlag(false);

        switch(event.target.id) {
            case "faqQuestionId":
                setFaqQuestionErrFlag(false);
                setFaqQuestion(event.target.value);

                setSubmitButtonFlag(false);
                if (!lodash.isEmpty(event.target.value)) {
                    if (
                        !lodash.isEmpty(event.target.value) &&
                        !lodash.isEmpty(domain) &&
                        !lodash.isEmpty(faqCategory) &&
                        !lodash.isEmpty(faqAnswer) &&
                        !lodash.isEmpty(language)
                        ) {
                        setSubmitButtonFlag(true);
                    }
                }
                break;
            case "faqAnswerId":
                // console.log("In handleChange(): event.target.value is: ", event.target.value);
                setFaqAnswerErrFlag(false);
                setFaqAnswer(event.target.value);

                setSubmitButtonFlag(false);
                if (!lodash.isEmpty(event.target.value)) {
                    if (
                        !lodash.isEmpty(event.target.value) &&
                        !lodash.isEmpty(domain) &&
                        !lodash.isEmpty(faqCategory) &&
                        !lodash.isEmpty(faqQuestion) &&
                        !lodash.isEmpty(language)
                        ) {
                        setSubmitButtonFlag(true);
                    }
                }
                break;
            default:
                break;
        }
    }
    
    function handleLanguageChange(event, item) {
        // console.log("In handleLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        setSubmitButtonFlag(false);        
        setLanguage(item);

        if (!lodash.isEmpty(item)) {
            Promise.all([
                fetchReferenceData("faq-categories", item)
            ]).then(async (results) => {
                // console.log("In handleLanguageChange(): results is: ", results);
                await fetchReferenceData("supported-domains", item);
            }).catch(error => {
                console.log("In handleLanguageChange(): error is: ", error);
            });
        }

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(faqCategory) &&
            !lodash.isEmpty(faqQuestion) &&
            !lodash.isEmpty(faqAnswer) &&
            !lodash.isEmpty(domain) 
        ) {
            setSubmitButtonFlag(true);
        }
    }
    
    async function handleListLanguageChange(event, item) {
        // console.log("In handleListLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        // setSubmitButtonFlag(false);        
        setLanguage(item);

        setDomain(null);
        setFaqListRecords([]);
        setFaqListRecordsBackup([]);

        if (!lodash.isEmpty(item)) {
            Promise.all([
                await fetchReferenceData("supported-domains", item)
            ]).then(async (results) => {
                // console.log("In handleListLanguageChange(): results is: ", results);
            }).catch(error => {
                console.log("In handleListLanguageChange(): error is: ", error);
            });
        }
    }

    function handleFaqCategoryChange(event, item) {
        // console.log("In handleFaqCategoryChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setFaqCategoryErrFlag(false);
        
        setFaqCategory(item);

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(faqQuestion) &&
            !lodash.isEmpty(faqAnswer) &&
            !lodash.isEmpty(domain) 
            ) {
                setSubmitButtonFlag(true);
            }
    }

    function handleDomainChange(event, item) {
        // console.log("In handleDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            sessionStorage.setItem("media-domain-name", item.value);
        }

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(faqQuestion) &&
            !lodash.isEmpty(faqAnswer) &&
            !lodash.isEmpty(faqCategory) 
            ) {
                setSubmitButtonFlag(true);
            }
    }

    function handleListDomainChange(event, item) {
        // console.log("In handleListDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            fetchFaqRecords(item);
        }
    }

    function initializeForm() {
        setFaqAnswer("");
        // setFaqCategory(null);
        // setDomain(null);
        setFaqQuestion("");
    }

    function createFaqAction() {
        initializeForm();

        setLanguage(null);
        setDomain(null);

        setEditModeFlag(false);
        setShowListFlag(false);
        setOrderListFlag(false);
        setUploadFlag(false);
        setShowDetailsFormFlag(true);
    }

    function createMoreAction() {
        initializeForm();

        setLanguage(null);
        setDomain(null);
        setFaqCategory(null);

        setEditModeFlag(false);
        setShowListFlag(false);
        setUploadFlag(false);
        setOrderListFlag(false);
        setShowDetailsFormFlag(true);
    }

    function uploadAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setOrderListFlag(false);
        setUploadFlag(true);
    }
    
    function convertAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setUploadFlag(false);
        setOrderListFlag(false);
        setConvertFlag(true);
    }
    
    async function closeConvertForm() {
        // await fetchFaqRecords();
        
        setTimeout(() => {
            setShowDetailsFormFlag(false);
            setUploadFlag(false);
            setConvertFlag(false);
            setOrderListFlag(false);
            setShowListFlag(true);
        }, 500);
    }
    
    async function closeDetailsForm() {
        await fetchFaqRecords(domain);
        
        setTimeout(() => {
            setShowDetailsFormFlag(false);
            setUploadFlag(false);
            setGlobalErrorMsg("");
            setGlobalErrorFlag(false);
            setOrderListFlag(false);
            setShowListFlag(true);
        }, 500);
    }
    
    async function closeUploadForm() {
        // await fetchFaqRecords();
        
        setTimeout(() => {
            setShowListFlag(true);
            setShowDetailsFormFlag(false);
            setUploadFlag(false);
            setOrderListFlag(false);
        }, 500);
    }

    async function orderListAction() {
        // console.log("In orderListAction(): language is: ", language);
        await fetchReferenceData("faq-categories", language);

        setFaqCategory(null);
        setFilteredRecords([]);

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setUploadFlag(false);
        setOrderListFlag(true);
    }

    function closeOrderListForm() {
        initializeForm();

        setEditModeFlag(false);
        setShowListFlag(true);
        setShowDetailsFormFlag(false);
        setUploadFlag(false);
        setOrderListFlag(false);
    }
    
    async function createService() {
        // console.log("In createService(): language is: ", language);
        // console.log("In createService(): domain is: ", domain);
        // console.log("In createService(): faqCategory is: ", faqCategory);
        // console.log("In createService(): faqAnswer is: ", faqAnswer);
        // console.log("In createService(): faqQuestion is: ", faqQuestion);
        // console.log("In createService(): timeZone is: ", timeZone);
        // console.log("In createService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In createService(): appContext.userName is: ", appContext.userName);
    
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In createService(): Validation passed.");
            // console.log("In createService(): Validation passed.");

            const timestamp = moment.tz(Date.now(), timeZone);
            // console.log("In createService(): timestamp is: ", timestamp);

            // const startTime = moment.tz(faqStartTime, timeZone);
            // console.log("In createService(): startTime is: ", startTime);

            backdropProgress(true);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            let uniqueId = GenerateUniqueName();

            let key = {
                FaqPK: domain.value + "#" + language.languageCode + "#faq",
                FaqSK: domain.value + "#" + language.languageCode + "#faq#" + faqCategory.value + "#" + uniqueId,
            };
            // console.log("In createService(): key is: ", key);

            let item = {
                "FaqPK": key.FaqPK,
                "FaqSK": key.FaqSK,

                "FaqId": uniqueId,

                "UniqueAttribute": lodash.isEmpty(key) ? null : key,
                "LanguageObject": lodash.isEmpty(language) ? null : language,
                "LanguageCode": lodash.isEmpty(language) ? null : language.languageCode,
                "Domain": lodash.isEmpty(domain) ? null : domain,

                "FaqDomain": lodash.isEmpty(domain) ? null : domain.value,
                "FaqCategory": lodash.isEmpty(faqCategory) ? null : faqCategory,
                "FaqCategoryCode": lodash.isEmpty(faqCategory) ? null : faqCategory.value,
                "FaqQuestion": lodash.isEmpty(faqQuestion) ? null : faqQuestion,
                "FaqAnswer": lodash.isEmpty(faqAnswer) ? null : faqAnswer,

                "FaqSeqNumber": 0,

                "DomainLangCategoryCode": domain.value + "#" + language.languageCode + "#" + faqCategory.value,// For GSI

                "ModifiedBy": appContext.userName,
                "CreatedAt": timestamp,
                "UpdatedAt": timestamp,
            };

            // console.log('In createService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_not_exists(UniqueAttribute)";

            let params = {
                apiName: "FaqAPI",
                path: "/dynamodb/faq/put",
                payload: {
                    item: item,
                    conditionExpression: conditionExpression,
                    // updateExpression: updateExpression,
                    // expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In createService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In createService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In createService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar 
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);
            }).catch((err) => {
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                switch(err.code) {
                    case "ConditionalCheckFailedException":
                        console.error("In createService(): Duplicate Error");
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        break;
                    default:
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        console.error("In createService(): err is: ", JSON.stringify(err, null, 2));
                        break;
                }
            });
        }
    }

    async function updateService() {
        // console.log("In updateService(): language is: ", language);
        // console.log("In updateService(): domain is: ", domain);
        // console.log("In updateService(): faqCategory is: ", faqCategory);
        // console.log("In updateService(): faqAnswer is: ", faqAnswer);
        // console.log("In updateService(): faqQuestion is: ", faqQuestion);
        // console.log("In updateService(): timeZone is: ", timeZone);
        // console.log("In updateService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In updateService(): appContext.userName is: ", appContext.userName);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In updateService(): Validation passed.");

            // let uniqueId = GenerateUniqueName();// conversion
                
            const timestamp = moment.tz(Date.now(), timeZone);

            backdropProgress(true);
            const startTime = moment.tz(blogStartTime, timeZone);
            // console.log("In updateService(): startTime is: ", startTime);

            let key = {
                FaqPK: appContext.currentPK,
                FaqSK: appContext.currentSK,
            };
            // console.log("In updateService(): key is: ", key);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
            let updateExpression =
                "SET " +
                "UniqueAttribute = :uniqueAttribute, " +
                
                "FaqDomain = :faqDomain, " +
                "FaqCategory = :faqCategory, " +
                "FaqCategoryCode = :faqCategoryCode, " +
                "FaqQuestion = :faqQuestion, " +
                "FaqAnswer = :faqAnswer, " +
                
                "DomainLangCategoryCode = :domainLangCategoryCode, " +

                "ModifiedBy = :modifiedBy, " + 
                "UpdatedAt = :updatedAt";

            let expressionAttributeValues = {
                ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
                // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
                // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,
                
                // ":uniqueId": lodash.isEmpty(uniqueId) ? null : uniqueId,// conversion
                
                ":faqDomain": lodash.isEmpty(domain) ? null : domain.value,
                ":faqCategory": lodash.isEmpty(faqCategory) ? null : faqCategory,
                ":faqCategoryCode": lodash.isEmpty(faqCategory) ? null : faqCategory.value,
                ":faqQuestion": lodash.isEmpty(faqQuestion) ? null : faqQuestion,
                ":faqAnswer": lodash.isEmpty(faqAnswer) ? null : faqAnswer,

                ":domainLangCategoryCode": domain.value + "#" + language.languageCode + "#" + faqCategory.value,// For GSI
                
                ":modifiedBy": appContext.userName,
                ":updatedAt": timestamp,
            };
            // console.log('In updateService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_exists(UniqueAttribute)";

            let params = {
                apiName: "FaqAPI",
                path: "/dynamodb/faq/update",
                payload: {
                    key: key,
                    conditionExpression: conditionExpression,
                    updateExpression: updateExpression,
                    expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In updateService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In updateService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.put(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In updateService(): response is: ', JSON.stringify(response, null, 2));
                setShowDetailsFormFlag(false);
                setUploadFlag(false);

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                // initializeForm();

                backdropProgress(false);

                await fetchFaqRecords(domain);
                setShowListFlag(true);
            }).catch((err) => {
                console.error("In updateService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    async function deleteService(recToDelete) {
        // console.log("In deleteService(): recToDelete is: ", recToDelete);
        // setDeleteCompleteFlag(false);
        // let itemsToDelete = [];

        if (!lodash.isEmpty(recToDelete)) {
            backdropProgress(true);

            let key = {
                FaqPK: recToDelete.FaqPK,
                FaqSK: recToDelete.FaqSK,
            };

            let params = {
                apiName: "FaqAPI",
                path: "/dynamodb/faq/delete",
                payload: {
                    key: key,
                },
            };
    
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In deleteService(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In deleteService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                backdropProgress(false);
                await fetchFaqRecords(domain);
            }).catch((err) => {
                console.error("In deleteService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    function validateForm() {
        // console.log("In validateForm()");
        let formErrFlag = false;
  
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
    
        setLanguageErrFlag(false);
        setFaqAnswerErrFlag(false);
        setFaqQuestionErrFlag(false);
        setFaqCategoryErrFlag(false);
        setDomainErrFlag(false);

        if (lodash.isEmpty(language)) {
            formErrFlag = true;
            setLanguageErrFlag(true);
        }
    
        if (lodash.isEmpty(domain)) {
            formErrFlag = true;
            setDomainErrFlag(true);
        }
    
        if (lodash.isEmpty(faqCategory)) {
            formErrFlag = true;
            setFaqCategoryErrFlag(true);
        }
    
        if (lodash.isEmpty(faqQuestion)) {
            formErrFlag = true;
            setFaqQuestionErrFlag(true);
        }
    
        if (lodash.isEmpty(faqAnswer)) {
            formErrFlag = true;
            setFaqAnswerErrFlag(true);
        }
    
        if (formErrFlag) {
            setGlobalErrorFlag(true);
        }
        // console.log("In validateForm(): formErrFlag is: ", formErrFlag);
        return formErrFlag;
    }

    async function buttonFormAction() {
        // console.log("In buttonFormAction()");

        if (editModeFlag) {
            await updateService();
        } else {
            await createService();
        }
    }
    
    function snackbarOnClose(event, reason) {
        setSnackbarOpen(false);
    }

    function handleMultipleSelectionChange(data) {
        // console.log("In handleMultipleSelectionChange(): data.value is: ", data.value);
        setSelectedTableRows(data.value);
        setTableDirtyFlag(true);
    }

    //------------------
    // For AlertDialog
    //------------------
    const [deleteType, setDeleteType] = useState("");

    function showAlertDialog(item, type) {
        // console.log("In showAlertDialog(): type is: ", type);
        setDeleteType(type);
        
        if (type && type === "single") {// single deletion
            setRecordToDelete(item);

            let subContent = item.FaqCategoryText;
            if (!lodash.isEmpty(item.FaqQuestion)) {
                subContent += " - ";
                subContent += item.FaqQuestion;
            }
            setDialogSubContent(subContent);
            setAlertOpen(true);
        } else {// multiple deletions
            let msg = FormatNumber(selectedTableRows.length, appContext.currentLanguage.languageCode);
            msg += " ";
            msg += selectedTableRows.length > 1 ? faqRecordsLabel : faqRecordLabel;
            setDialogSubContent(msg);
            setAlertOpen(true);
        }
    }

    function alertClickAgree() {
        // Close Alert
        if (deleteType && deleteType === "single") {
            setAlertOpen(false);
            deleteService(recordToDelete);
        } else {
            setAlertOpen(false);
            // deleteMultipleAction();
            processMultipleDeletionRecs();
        }
    }

    function alertClickDisagree() {
        // Close Alert
        setAlertOpen(false);
        return false;
    }

    function alertClose() {
        // Close Alert
        setAlertOpen(false);
    }

    function deleteIconTemplate(rowData, column) {
        return <DeleteIcon color="secondary" onClick={() => showAlertDialog(rowData, "single")}/>
    }

    function editIconTemplate(rowData, column) {
        return <EditIcon color="primary" onClick={() => editRecordAction(rowData)}/>
    }
    
    function sortDateField(event) {
        // console.log("In sortDateField(): event is: ", event);

        var values = [...faqListRecords];
        values.sort((data1, data2) => {
            // Note: We are using actual DateTime object and not the dateTimeFormatted 
            // used for display on data table.
            let value1 = data1.UpdatedAt;
            let value2 = data2.UpdatedAt;
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });

        return values;
    }

    let processedRecs = 0;

    async function deleteMultipleAction() {
        // console.log("In deleteMultipleAction(): selectedTableRows.length is: ", selectedTableRows.length);
        // console.log("In deleteMultipleAction(): selectedTableRows is: ", selectedTableRows);

        // Disable Save changes button
        setTableDirtyFlag(false);

        return new Promise((resolve, reject) => {
            if (selectedTableRows && selectedTableRows.length > 0) {
                selectedTableRows.map(record => {
                    setTimeout(async () => {
                        await deleteService(record);

                        processedRecs++;

                        if (processedRecs === selectedTableRows.length) {
                            resolve("Done");
                        }
                    }, 1000);
                });
            }
        });
    }

    async function processMultipleDeletionRecs() {
        // console.log("In processMultipleDeletionRecs()");

        //-------------------
        // Promise.all
        //-------------------
        Promise.all(
            [
                deleteMultipleAction()
            ]).then(async (results) => {
                // console.log("In processMultipleDeletionRecs(): results is: ", results);
                // console.log("In processMultipleDeletionRecs(): records deleted from AWS, now delete them from DataTable");
                // Remove deleted records from DataTable
                let remainingRecords = FilterArrayFromArray(faqListRecords, selectedTableRows);
                if (remainingRecords) {
                    // setBlogRecords(remainingRecords);
                    setSelectedTableRows([]);
                }
            });
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        setBlogContent(item.BlogContent);

        setRecordToEdit(item);

        appContext.currentPK = item.FaqPK;
        appContext.currentSK = item.FaqSK;
        await getFaqRecord({FaqPK: item.FaqPK, FaqSK: item.FaqSK,});

        setTimeout(() => {
            setShowListFlag(false);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);
            setShowDetailsFormFlag(true);
        }, 500);
    }

    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    //------------------------
    // For sliceText and Dialog
    //------------------------

    function sliceMoreAction(record) {
        setDialogData(record.BlogContent);
        setDialogHeader(record.FaqQuestion);
        setDlgVisibleFlag(true);
    }

    function hideModalDlg() {
        setDlgVisibleFlag(false);
    }
  
    function sliceText(text, maxChars) {
        let retValue = text;
        if (text.length > maxChars) {
            retValue = text.substring(0, (maxChars - 1));
            retValue += " ...";
        }
        return retValue;
    }

    //------------------
    // For OrderList
    //------------------

    function handleFilteredRecords(event) {
        setFilteredRecords(event.value);
    }

    function handleOrderListCategoryChange(event, item) {
        event.preventDefault();
        event.persist();
        // console.log("In handleOrderListCategoryChange(): item is ", item);
        // console.log("In handleOrderListCategoryChange(): faqListRecords is ", faqListRecords);

        setFilteredRecords([]);
        setSubmitButtonFlag(false);
        
        setFaqCategory(item);

        if (!lodash.isEmpty(item) && faqListRecords && faqListRecords.length > 0) {
            let filteredRecords = faqListRecords.filter(record => record.FaqCategory.value === item.value);
            setFilteredRecords(filteredRecords);
            if (filteredRecords && filteredRecords.length > 0) {
                filteredRecords.sort((a, b) => (a.FaqSeqNumber > b.FaqSeqNumber ? 1 : -1));
                setFilteredRecords(filteredRecords);
                setSubmitButtonFlag(true);
            }
        }
    }

    function orderListTemplate(record) {
        return (
            <Grid container justify="flex-start" style={{width: '100% !important', display: 'flex'}}>
                <Grid item xs={12} sm={12}>
                    <Grid container justify="flex-start">
                            <Grid item xs={1} sm={1} style={{textAlign: 'left'}}>
                                <Typography variant="caption" style={{textAlign: 'left'}}>
                                    {"" + record.FaqSeqNumber}.
                                </Typography>
                            </Grid>

                            <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
                                <Typography variant="caption" style={{textAlign: 'left'}}>
                                    {record.FaqQuestion}
                                </Typography>
                            </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    
    async function organizeService() {
        // console.log("In organizeService(): filteredRecords is: ", filteredRecords);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (filteredRecords && filteredRecords.length > 0) {
            // console.log("In organizeService(): Validation passed.");

            // Get user timezone
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const timestamp = moment.tz(Date.now(), timeZone);
            const items = [];

            filteredRecords.map((record, index) => {
                let key = {
                    FaqPK: record.FaqPK,
                    FaqSK: record.FaqSK,
                };
                // console.log("In updateGuestBookService(): key is: ", key);

                // ConditionExpression: "attribute_exists(resourceCode)",
                let conditionExpression = "";

                // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
                let updateExpression =
                    "SET " +
                    "UniqueAttribute = :uniqueAttribute, " +

                    "FaqSeqNumber = :faqSeqNumber, " +

                    "ModifiedBy = :modifiedBy, " + 
                    "UpdatedAt = :updatedAt";
        
                let expressionAttributeValues = {
                    ":uniqueAttribute": key,

                    ":faqSeqNumber": index + 1,

                    ":modifiedBy": appContext.userName,
                    ":updatedAt": timestamp,
                };
                // console.log('In updateGuestBookService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

                conditionExpression = "attribute_exists(UniqueAttribute)";

                let item = {
                    key: key,
                    conditionExpression: conditionExpression,
                    updateExpression: updateExpression,
                    expressionAttributeValues: expressionAttributeValues,
                };
                items.push(item);
            });
            // console.log("In organizeService(): items is: ", items);

            let params = {
                apiName: "FaqAPI",
                path: "/dynamodb/faq/customBatchUpdate",
                payload: {
                    updateItems: items,
                },
            };
            // console.log('In organizeService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In organizeService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In organizeService(): response is: ', JSON.stringify(response, null, 2));
                setShowDetailsFormFlag(false);
                setUploadFlag(false);
                setOrderListFlag(false);

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);

                await fetchFaqRecords();
                setShowListFlag(true);
            }).catch((err) => {
                console.error("In organizeService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }

    }

    const cloneRecordAction = () => {
        setLanguage(null);
        setDomain(null);

        setSubmitButtonFlag(false);
        setEditModeFlag(false);
    }

    return (
        <div dir={direction} className={clsx(classes.root, classes.divWrapper)}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {renderDomFlag &&
                <>
                    {/* { !appContext.signedIn && <Redirect to='/signIn' />} */}
                    <CssBaseline />
                    <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />

                    {isDeskTopView && 
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
                            <Container component="div" className={classes.main} maxWidth="lg">
                                <StyledPaper elevation={3} className={classes.paper}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h5" color="primary" style={{ textAlign: "center" }}>
                                                {manageFaqsLabel}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                                                {requiredFields}
                                            </Typography>
                                        </Grid>

                                        {globalErrorFlag && (
                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={24} />
                                                <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                {showListFlag &&
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                                *{languageLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="languageId"
                                                                            value={language}
                                                                            options={languages}
                                                                            getOptionLabel={option => option.languageName}
                                                                            getOptionSelected={option => option.languageName}
                                                                            onChange={handleListLanguageChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={languagePrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={languageErrFlag}
                                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                                *{domainLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="domainId"
                                                                            value={domain}
                                                                            options={domainOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleListDomainChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            // disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={domainPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={domainErrFlag}
                                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            {faqListRecords && faqListRecords.length > 0 ?
                                                                <>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <LineSpacing value={12} />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-start">
                                                                                    <Button variant="contained" size="large" disabled={selectedTableRows && selectedTableRows.length > 0 ? false : true} color="primary" onClick={() => showAlertDialog(null, "multiple")}>
                                                                                        <DeleteIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {deleteLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={orderListAction}>
                                                                                        <ImportExportIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {organizeLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={convertAction}>
                                                                                        <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {convertLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-end">
                                                                                    <Button variant="contained" size="large" disabled={false} color="primary" onClick={createMoreAction}>
                                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {createMoreLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12}>
                                                                        <LineSpacing value={8} />
                                                                    </Grid>
                                                                    
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12}>
                                                                            <div className="datatable-doc-demo">
                                                                                <DataTable 
                                                                                    value={faqListRecords}
                                                                                    resizableColumns={true} 
                                                                                    columnResizeMode={"fit"}
                                                                                    sortMode={"multiple"}
                                                                                    responsive={true}
                                                                                    reorderableColumns={true}

                                                                                    // selectionMode={"multiple"}
                                                                                    selection={selectedTableRows}
                                                                                    onSelectionChange={handleMultipleSelectionChange}

                                                                                    // paginator={visibleRowsCount > 10 ? paginatorFlag : !paginatorFlag} 
                                                                                    paginator={faqListRecords && faqListRecords.length > 10} 
                                                                                    // paginatorLeft={paginatorLeft}

                                                                                    paginatorTemplate={paginatorTemplate}
                                                                                    currentPageReportTemplate={"{first}" + " " + toLabel + " " + "{last}" + " " + ofLabel + " " + "{totalRecords}" + " " + recordsLabel} 
                                                                                    rows={visibleRowsCount} 
                                                                                    rowsPerPageOptions={rowsPerPageOptions}
                                                                                    // className="p-datatable-responsive-cob"
                                                                                    // className="p-datatable-striped"
                                                                                    emptyMessage={noRecordsFound}
                                                                                    className={clsx("p-datatable-striped", "p-datatable-customers")}
                                                                                    rowHover 
                                                                                    >

                                                                                    <Column 
                                                                                        header={deleteLabel} 
                                                                                        selectionMode="multiple" 
                                                                                        style={{width:'5em', textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="FaqCategoryText" 
                                                                                        header={gridCategory} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="FaqQuestion" 
                                                                                        header={gridFaqQuestion} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="FaqAnswer" 
                                                                                        header={gridFaqAnswer} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="FormattedDateTime" 
                                                                                        header={lastUpdateLabel} 
                                                                                        sortable={true} 
                                                                                        sortFunction={sortDateField}
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="deleteIcon" 
                                                                                        header={deleteLabel} 
                                                                                        body={deleteIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="editIcon" 
                                                                                        header={editLabel} 
                                                                                        body={editIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                </DataTable>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            :
                                                                <>
                                                                    {noRecordFoundFlag &&
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={12} sm={12}>
                                                                                <Typography variant="body1" color="secondary">
                                                                                    {noRecordsFound}
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <LineSpacing value={12} />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <Button variant="contained" size="large" disabled={false} color="primary" onClick={createFaqAction}>
                                                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                    {createFaqLabel}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {showDetailsFormFlag && 
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={6} sm={6}>
                                                            <Grid container justify="flex-start">
                                                                <Typography variant="h6" color="primary">
                                                                    {editModeFlag ? updateFaqLabel : createFaqLabel}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={6} sm={6}>
                                                            <Grid container justify="center">
                                                                <Button variant="contained" size="small" disabled={editModeFlag ? false : true} color="primary" onClick={cloneRecordAction}>
                                                                    {cloneRecordLabel}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                                *{languageLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="languageId"
                                                                            value={language}
                                                                            options={languages}
                                                                            getOptionLabel={option => option.languageName}
                                                                            getOptionSelected={option => option.languageName}
                                                                            onChange={handleLanguageChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={languagePrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={languageErrFlag}
                                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                                *{domainLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="domainId"
                                                                            value={domain}
                                                                            options={domainOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleDomainChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={domainPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={domainErrFlag}
                                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={faqCategoryErrFlag}>
                                                                        <label htmlFor="faqCategoryId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={faqCategoryErrFlag ? "secondary" : "inherit"}>
                                                                                *{faqCategoryLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="faqCategoryId"
                                                                            value={faqCategory}
                                                                            options={categoryOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleFaqCategoryChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={faqCategoryPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={faqCategoryErrFlag}
                                                                                    helperText={faqCategoryErrFlag ? faqCategoryErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={faqQuestionErrFlag}>
                                                                        <label htmlFor="faqQuestionId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={faqQuestionErrFlag ? "secondary" : "inherit"}>
                                                                                *{faqQuestionLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <TextField
                                                                            id="faqQuestionId"
                                                                            value={faqQuestion}
                                                                            placeholder={faqQuestionPrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={faqQuestionErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            helperText={faqQuestionErrFlag ? faqQuestionErrMsg : ""}
                                                                            // InputProps={{
                                                                            //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                            // }}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={faqAnswerErrFlag}>
                                                                                <label htmlFor="faqAnswerId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={faqAnswerErrFlag ? "secondary" : "inherit"}>
                                                                                        *{faqAnswerLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextareaAutosize
                                                                                    id="faqAnswerId"
                                                                                    aria-label={faqAnswerLabel}
                                                                                    rows={1.5}
                                                                                    rowsMax={6}
                                                                                    value={faqAnswer}
                                                                                    placeholder={faqAnswerPrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    // autoFocus={true}
                                                                                    margin="none"
                                                                                    className={classes.textArea}
                                                                                    maxLength={textMaxLength}
                                                                                />
                                                                                <FormHelperText>{faqAnswerErrFlag ? faqAnswerErrMsg : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={buttonFormAction}>
                                                                    {editModeFlag ?
                                                                    <>
                                                                        <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {updateFaqLabel} 
                                                                    </>
                                                                    : 
                                                                    <>
                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {createFaqLabel}
                                                                    </>
                                                                    }
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {convertFlag &&
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-start">
                                                                <Typography variant="h6" color="primary">
                                                                    {convertFaqsLabel}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <FaqConverter 
                                                                showHeadersFlag={false}
                                                                referrer={"ManageFaqs"}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                {orderListFlag &&
                                                    <Container component="div" className={classes.main} maxWidth="md">
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeOrderListForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-start">
                                                                <Typography variant="h6" color="primary">
                                                                    {organizeFaqListLabel}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={faqCategoryErrFlag}>
                                                                        <label htmlFor="faqCategoryId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={faqCategoryErrFlag ? "secondary" : "inherit"}>
                                                                                *{faqCategoryLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="faqCategoryId"
                                                                            value={faqCategory}
                                                                            options={categoryOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleOrderListCategoryChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={faqCategoryPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={faqCategoryErrFlag}
                                                                                    helperText={faqCategoryErrFlag ? faqCategoryErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center" style={{width: '100% !important'}}>
                                                                <div style={{minWidth: '100%'}}>
                                                                    <OrderList 
                                                                        value={filteredRecords} 
                                                                        header={freqAskedQuestionsLabel} 
                                                                        dragdrop={dragdropFlag}
                                                                        listStyle={{width: '100% !important', height:'auto'}} 
                                                                        dataKey="FaqId"
                                                                        itemTemplate={orderListTemplate} 
                                                                        onChange={(e) => handleFilteredRecords(e)}
                                                                        style={{width: '100% !important', display: 'flex'}}
                                                                        >
                                                                    </OrderList>
                                                                </div>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={organizeService}>
                                                                    <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                    {saveLabel} 
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Container>
                                                }

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={18} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledPaper>
                            </Container>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={48} />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    }

                    {isTabletView && 
                        <ManageFaqsTv
                            manageFaqsLabel={manageFaqsLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            domainLabel={domainLabel}
                            domainPrompt={domainPrompt}
                            domainErrMsg={domainErrMsg}
                                
                            faqAnswerLabel={faqAnswerLabel}
                            faqAnswerPrompt={faqAnswerPrompt}
                            faqAnswerErrMsg={faqAnswerErrMsg}
                            // faqAnswerMsg={faqAnswerMsg}
                            
                            faqQuestionLabel={faqQuestionLabel}
                            faqQuestionPrompt={faqQuestionPrompt}
                            faqQuestionErrMsg={faqQuestionErrMsg}
                            // selectTitlePrompt={selectTitlePrompt}
                    
                            faqCategoryLabel={faqCategoryLabel}
                            faqCategoryPrompt={faqCategoryPrompt}
                            faqCategoryErrMsg={faqCategoryErrMsg}
                            gridCategory={gridCategory}
                            // editLabel={editLabel}
                            // deleteLabel={deleteLabel}

                            organizeLabel={organizeLabel}
                            organizeFaqListLabel={organizeFaqListLabel}
                            freqAskedQuestionsLabel={freqAskedQuestionsLabel}
                            saveLabel={saveLabel}
                            orderListAction={orderListAction}
                            orderListFlag={orderListFlag}
                            handleOrderListCategoryChange={handleOrderListCategoryChange}
                    
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            uploadLabel={uploadLabel}
                            createFaqLabel={createFaqLabel}
                            updateFaqLabel={updateFaqLabel}
                            createLabel={createLabel}
                            convertFaqsLabel={convertFaqsLabel}
                            closeLabel={closeLabel}
                            noRecordsFound={noRecordsFound} 
                    
                            gridFaqQuestion={gridFaqQuestion}
                            gridFaqAnswer={gridFaqAnswer}
                            // gridEventUrl={gridEventUrl}
                            lastUpdateLabel={lastUpdateLabel}
                            convertLabel={convertLabel}
                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            moreLabel={moreLabel}
                            // cloneEventLabel={cloneEventLabel}

                            filteredRecords={filteredRecords}
                            dragdropFlag={dragdropFlag}
                            orderListTemplate={orderListTemplate}
                            closeOrderListForm={closeOrderListForm}
                            handleFilteredRecords={handleFilteredRecords}
                            organizeService={organizeService}
                                                
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showListFlag={showListFlag}
                            faqListRecords={faqListRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noRecordFoundFlag={noRecordFoundFlag}
                            createFaqAction={createFaqAction}
                            showDetailsFormFlag={showDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleListLanguageChange={handleListLanguageChange}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}
                            domain={domain}
                            domainOptions={domainOptions}
                            handleListDomainChange={handleListDomainChange}
                            handleDomainChange={handleDomainChange}
                            faqCategoryErrFlag={faqCategoryErrFlag}
                            faqCategory={faqCategory}
                            categoryOptions={categoryOptions}
                            handleFaqCategoryChange={handleFaqCategoryChange}
                            domainErrFlag={domainErrFlag}
                            handleChange={handleChange}
                            // selectImageAction={selectImageAction}
                            faqAnswerErrFlag={faqAnswerErrFlag}
                            faqAnswer={faqAnswer}
                            faqQuestionErrFlag={faqQuestionErrFlag}
                            faqQuestion={faqQuestion}
                            blogStartTimeErrFlag={blogStartTimeErrFlag}
                            blogStartTime={blogStartTime}
                            // handleBlogStartTimeChange={handleBlogStartTimeChange}
                            textMaxLength={textMaxLength}
                            blogContentErrFlag={blogContentErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            blogContent={blogContent}
                            submitButtonFlag={submitButtonFlag}
                            buttonFormAction={buttonFormAction}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            closeConvertForm={closeConvertForm}
                            // faqListRecords={faqListRecords}
                        />
                    }

                    {isMobileView && 
                        <ManageFaqsMv
                            manageFaqsLabel={manageFaqsLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            domainLabel={domainLabel}
                            domainPrompt={domainPrompt}
                            domainErrMsg={domainErrMsg}
                                
                            faqAnswerLabel={faqAnswerLabel}
                            faqAnswerPrompt={faqAnswerPrompt}
                            faqAnswerErrMsg={faqAnswerErrMsg}
                            // faqAnswerMsg={faqAnswerMsg}
                            
                            faqQuestionLabel={faqQuestionLabel}
                            faqQuestionPrompt={faqQuestionPrompt}
                            faqQuestionErrMsg={faqQuestionErrMsg}
                            // selectTitlePrompt={selectTitlePrompt}

                            faqCategoryLabel={faqCategoryLabel}
                            faqCategoryPrompt={faqCategoryPrompt}
                            faqCategoryErrMsg={faqCategoryErrMsg}
                            gridCategory={gridCategory}
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            // cancelLabel={cancelLabel}

                            yesLabel={yesLabel}
                            deleteRequested={deleteRequested}
                            areYouSureMsg={areYouSureMsg}
                            requestedToDeleteMsg={requestedToDeleteMsg}
                            uploadLabel={uploadLabel}
                            createFaqLabel={createFaqLabel}
                            updateFaqLabel={updateFaqLabel}
                            // faqRecordLabel={faqRecordLabel}
                            // createMoreLabel={createMoreLabel}
                            createLabel={createLabel}
                            convertFaqsLabel={convertFaqsLabel}
                            closeLabel={closeLabel}
                            noRecordsFound={noRecordsFound} 
                    
                            gridFaqQuestion={gridFaqQuestion}
                            gridFaqAnswer={gridFaqAnswer}
                            // gridEventUrl={gridEventUrl}
                            lastUpdateLabel={lastUpdateLabel}
                            // gridStartTime={gridStartTime}
                            // gridBannerUrl={gridBannerUrl}
                        
                            convertLabel={convertLabel}
                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            moreLabel={moreLabel}

                            organizeLabel={organizeLabel}
                            organizeFaqListLabel={organizeFaqListLabel}
                            freqAskedQuestionsLabel={freqAskedQuestionsLabel}
                            saveLabel={saveLabel}
                            orderListAction={orderListAction}
                            orderListFlag={orderListFlag}
                            handleOrderListCategoryChange={handleOrderListCategoryChange}

                            filteredRecords={filteredRecords}
                            dragdropFlag={dragdropFlag}
                            orderListTemplate={orderListTemplate}
                            closeOrderListForm={closeOrderListForm}
                            handleFilteredRecords={handleFilteredRecords}
                            organizeService={organizeService}
                            
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showListFlag={showListFlag}
                            faqListRecords={faqListRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noRecordFoundFlag={noRecordFoundFlag}
                            createFaqAction={createFaqAction}
                            showDetailsFormFlag={showDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleListLanguageChange={handleListLanguageChange}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}
                            domain={domain}
                            domainOptions={domainOptions}
                            handleListDomainChange={handleListDomainChange}
                            handleDomainChange={handleDomainChange}
                            faqCategoryErrFlag={faqCategoryErrFlag}
                            faqCategory={faqCategory}
                            categoryOptions={categoryOptions}
                            handleFaqCategoryChange={handleFaqCategoryChange}
                            domainErrFlag={domainErrFlag}
                            handleChange={handleChange}
                            // selectImageAction={selectImageAction}
                            faqAnswerErrFlag={faqAnswerErrFlag}
                            faqAnswer={faqAnswer}
                            faqQuestionErrFlag={faqQuestionErrFlag}
                            faqQuestion={faqQuestion}
                            blogStartTimeErrFlag={blogStartTimeErrFlag}
                            blogStartTime={blogStartTime}
                            // handleBlogStartTimeChange={handleBlogStartTimeChange}
                            textMaxLength={textMaxLength}
                            blogContentErrFlag={blogContentErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            blogContent={blogContent}
                            submitButtonFlag={submitButtonFlag}
                            buttonFormAction={buttonFormAction}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            closeConvertForm={closeConvertForm}
                            // faqListRecords={faqListRecords}
                        />
                    }
                </>
            }

            <Dialog 
                id="modalDlgId"
                rtl={appContext.dirction === 'rtl'}
                showHeader={true}
                header={dialogHeader}
                visible={dlgVisibleFlag} 
                maximizable={true}
                maximized={(isTabletView || isMobileView) ? true : false}
                modal={true} 
                onHide={hideModalDlg}
                >
                <Container component="div" className={classes.modalDlgMain} maxWidth="md">
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="flex-start" style={{color: '#000'}}>
                            <FroalaEditorView model={dialogData}/>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>

            {alertOpen && 
                <AlertDialog
                    alertOpen={alertOpen}
                    alertClose={alertClose}
                    alertClickAgree={alertClickAgree}
                    alertClickDisagree={alertClickDisagree}

                    dialogTitle={deleteRequested}
                    dialogMainContent={requestedToDeleteMsg}
                    dialogSubContent={dialogSubContent}
                    dialogQuestion={areYouSureMsg}
                    yesLabel={yesLabel}
                    noLabel={cancelLabel}
                />
            }

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={8000}
                onClose={snackbarOnClose}
                ContentProps={{ "aria-describedby": "message-id" }}
                message={<span id="message-id">{snackbarsMsg}</span>}
                variant={snackbarsVariant}
            />

            <footer className={classes.footer}>
                <div>
                    <PageFooter isSignedIn={appContext.signedIn} />
                </div>
            </footer>
        </div>
    )
}

export default ManageFaqs;
