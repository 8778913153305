import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import Paper from "@material-ui/core/Paper";

// Mineral-ui
import { IconLocationOn } from "mineral-ui-icons";
import { IconPhone } from "mineral-ui-icons";
import { IconPrint } from "mineral-ui-icons";
import { IconEmail } from "mineral-ui-icons";
import { IconLink } from "mineral-ui-icons";

// For React Google Maps
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// For React Google Maps
import * as parkData from "../../utils/maps/skateboard-parks.json";
import mapStyles from "../../utils/maps/mapStyles";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

// AppContext
import AppContext from "../../config/AppContext";

Amplify.configure(AmplifyConfig);

const PageContainer = styled.div`
  text-align: 'center',
  width: 100%,
  display: 'flex',
  flexWrap: 'wrap',
  background: #fff;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
  },
  progress: {
    margin: theme.spacing(1),
  },
  mainGrid: {
    textAlign: "center",
    itemAlign: "center",
  },
  // contSingle: {
  //     marginTop: theme.spacing(1),
  //     marginBottom: theme.spacing(1),
  //     // background: 'cyan',
  // },
  hdr: {
    color: "#fff",
  },
  // prodDesc: {
  //     color: 'fff',
  //     fontSize: '12px',
  //     marginLeft: theme.spacing(1),
  //     whiteSpace: 'nowrap',
  // },
  // link: {
  //     color: '#fff',
  //     marginTop: theme.spacing(1),
  //     marginLeft: theme.spacing(1),
  // },
  // textItem: {
  //     // whiteSpace: 'nowrap',
  //     whiteSpace: 'pre-wrap',// Obeys '\n' character
  // },
  // image: {
  //     width: '18px !important', // '16px !important'
  //     background: 'transparent',
  //     verticalAlign: 'middle',
  // },
  // contactGrid: {
  //     color: 'red',
  //     fontSize: '12px',
  //     marginLeft: theme.spacing(1),
  //     marginTop: theme.spacing(1),
  //     marginBottom: theme.spacing(1),
  //     // whiteSpace: 'nowrap',
  //     backgroundColor: 'transparent',
  // },
  // rowGrid: {
  //     marginTop: theme.spacing(2),
  // },
  paper: {
    width: "100%",
    padding: theme.spacing(1, 1),
  },
}));

let contactSingle = {
  contactMapHdr: "ContactInfo",
  contactMapDesc: "Connect with us",
};

let lang = "";

function ContactInfoCustomMap(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  // For ContactInfo
  const [contactMapRes, setContactMapRes] = useState(contactSingle);
  const [contactInfoData, setContactInfoData] = useState([]);
  const [dummyData, setDummyData] = useState([]);

  const {
    currLanguage,

    paperBackground,
    // headerColor,
    // iconsColor,
    // textColor,

    mapId,
    // mapWidth,
    // mapHeight,
    lat,
    lng,
    zoom,
    infoWindowTitle,
    mapOptions,
    regionCode,
  } = props;

  const classes = useStyles();

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  // For React Google Maps
  const [selectedPark, setSelectedPark] = useState(null);

  const [googleScriptFlag, setGoogleScriptFlag] = useState(false);

  const { contactMapHdr, contactMapDesc } = contactMapRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(contactSingle);
    // console.log("In ContactInfoCustomMap(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ContactInfoCustomMap(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "contactMapHdr")
              contactSingle.contactMapHdr = item.ResourceDesc;
            if (item.ResourceCode === "contactMapDesc")
              contactSingle.contactMapDesc = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, contactSingle);
          setContactMapRes(contactSingle);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.error(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setContactMapRes(contactSingle);
        setProgressFlag(false);
      });
  }

  // Fetch ContactInfo Data from Database
  async function fetchDbContactInfoData() {
    // console.log('In fetchDbContactInfoData(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);

    setProgressFlag(true);

    let arrayData = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "contact-info",
          ":resourceSK": appContext.currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbContactInfoData(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbContactInfoData(): response length is: ', response.data.Items.length);
        //   console.log('In fetchDbContactInfoData(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item, index) => {
          // let record = {
          //   id: ""+index,
          //   LanguageCode: item.LanguageCode,
          //   ResourcePK: item.ResourcePK,
          //   ResourceCode: item.ResourceCode,
          //   ResourceDesc: item.ResourceDesc,
          //   ResourceAddressUrl: item.ResourceAddressUrl,
          //   ResourceIconUrl: item.ResourceIconUrl,
          //   Icon: item.ResourceIconUrl,
          //   ModifiedBy: item.ModifiedBy,
          //   CreatedAt: item.CreatedAt,
          //   UpdatedAt: item.UpdatedAt,
          // };
          // arrayData.push(record);

          arrayData.push(item);
        });

        setContactInfoData(arrayData);
        setDummyData([arrayData[0]]); // [arrayData[0]]

        setProgressFlag(false);
      })
      .catch((err) => {
        console.error(
          "In fetchDbContactInfoData(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  //   const handleMapLoaded = (map, maps) => {
  //     // use map and maps objects
  //     console.log("In handleMapLoaded(): maps is: ", maps);
  //     console.log("In handleMapLoaded(): map is: ", map);
  //     const infoWindow = maps.InfoWindow;
  //     // infoWindow.setContent('<b>القاهرة</b>');
  //     console.log("In handleMapLoaded(): infoWindow is: ", infoWindow);

  //     // var marker = maps.Marker({map: map, position: ottawa});
  //     // console.log("In handleMapLoaded(): marker is: ", marker);
  //     // marker.addListener('click', function() {
  //     //     infoWindow.open(map, marker);
  //     // });
  //   };

  // function onScriptLoad() {
  //     console.log("In onScriptLoad(): mapId is: ", mapId);
  //     console.log("In onScriptLoad(): mapOptions is: ", mapOptions);

  //     const mapElement = document.getElementById(mapId);
  //     console.log("In onScriptLoad(): mapElement is: ", mapElement);
  //     // let script = document.getElementById('mapScriptId');

  //     if (mapElement !== null && mapOptions !== null) {
  //         // setGoogleScriptFlag(true);
  //         const map = new window.google.maps.Map(mapElement, mapOptions);
  //         console.log("In onScriptLoad(): map is: ", map);
  //         onMapLoad(map);
  //     }
  // }

  function onScriptLoad() {
    // console.log("In onScriptLoad(): mapId is: ", mapId);
    // console.log("In onScriptLoad(): mapOptions is: ", mapOptions);

    const mapElement = document.getElementById(mapId);
    // console.log("In onScriptLoad(): mapElement is: ", mapElement);
    // // let script = document.getElementById('mapScriptId');

    // if (mapElement !== null && mapOptions !== null) {
    //     // setGoogleScriptFlag(true);
    //     const map = new window.google.maps.Map(mapElement, mapOptions);
    //     console.log("In onScriptLoad(): map is: ", map);
    //     onMapLoad(map);
    // }
    onMapScriptLoaded(mapElement);
  }

  function loadGoogleMapScript(langCode) {
    // console.log("In loadGoogleMapScript(): window.google is: ", window.google);

    let mapElement = document.getElementById(mapId);
    // console.log("In loadGoogleMapScript(): mapElement is: ", mapElement);

    // let mapScript = document.getElementById('mapScriptId');
    // if (mapElement !== null && mapScript !== null){
    //     var head = document.getElementsByTagName("head")[0];
    //     // let script = document.getElementById('mapScriptId');
    //     // let scriptSrc = document.getElementById('mapScriptId').src;
    //     // console.log("In loadGoogleMapScript(): scriptSrc is: ", scriptSrc);
    //     // document.getElementById('mapScriptId').src = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&language=zh&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;
    //     // mapElement.removeElement;
    //     head.parentNode.removeChild(mapScript);
    //     mapElement.innerHTML = null;
    // }

    if (mapElement === null) {
      // console.log("In loadGoogleMapScript(): langCode is: ", langCode);

      // Build the JS API request node.
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "mapScriptId";
      // var query = '?key=AIzaSyD8dsL8KOtOMMdbYwdVLCSoa4t_SzQKjUo';
      var query = "";
      //   var langCode = getUrlParameter('language');
      // Try to be generous with accepting upper/lower case.
      if (langCode.length === 2) {
        langCode = langCode.toLowerCase();
      }
      if (langCode) {
        query = "&language=" + langCode;
      }
      //   var regionCode = getUrlParameter('region').toUpperCase();
      if (regionCode && regionCode !== null && regionCode !== "") {
        query += "&region=" + regionCode;
      }
      query += `&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;
      // console.log("In loadGoogleMapScript(): query is: ", query);

      script.src =
        "https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places,geocode" +
        query;
      // script.src=`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&language=${langCode}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;
      //   script.setAttribute('async', '');
      //   script.setAttribute('defer', '');
      //   document.head.appendChild(script);

      var scriptTag = document.getElementsByTagName("head")[0];
      scriptTag.parentNode.insertBefore(script, scriptTag);
      // Below is important.
      // We cannot access google.maps until it's finished loading
      script.addEventListener("load", (e) => {
        // handleMapLoaded();
        onScriptLoad();
      });
    }
  }

  useEffect(() => {
    // console.log("In ContactInfoCustomMap(): useEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));

    // currLanguage is the most current language passed to this component through props
    // We are using useEffect to get the functionality of componentDidUpdate()
    if (
      currLanguage !== undefined &&
      currLanguage !== null &&
      currLanguage !== currentLanguage
    ) {
      setCurrentLanguage(currLanguage);
      appContext.currentLanguage = currLanguage;
      lang = currLanguage.languageCode;

      // console.log('In ContactInfoCustomMap(): useEffect(): about to call fetchDbResources()');

      // Fetch resource from catche
      let cacheItemKey = "contactSingle";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      // cacheItemKey += currLanguage.languageCode;
      // console.log('In ContactInfoCustomMap(): useEffect(): cacheItemKey is: ', cacheItemKey);

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        // console.log("In ContactInfoCustomMap(): useEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
        RemoveCacheItem(cacheItemKey);
      }

      let cacheItem = GetCacheItem(cacheItemKey);
      // console.log('In ContactInfoCustomMap(): useEffect(): cacheItem is: ', cacheItem);

      if (cacheItem === null) {
        // resource is not in cache, fetch from DB
        // console.log('In ContactInfoCustomMap(): useEffect(): resource is not in cache, fetch from DB');
        fetchDbResources(cacheItemKey);
      } else {
        // console.log('In ContactInfoCustomMap(): useEffect(): got resource from cache');
        setContactMapRes(cacheItem);
      }
      // Fetch SocialNetworks Data
      fetchDbContactInfoData();

      // loadGoogleMapScript(currLanguage.languageCode);
      // ContactInfoCustomMap();
    } else {
      let address = "Reston, VA 20191";
      // geocodeAddress(address); // uncomment later
    }
  });

  useLayoutEffect(() => {
    // console.log("In ContactInfoCustomMap(): useLayoutEffect()");
    let langCode = appContext.currentLanguage.languageCode;

    // Fetch resource from catche
    let cacheItemKey = "contactSingle";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ContactInfoCustomMap(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ContactInfoCustomMap(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ContactInfoCustomMap(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ContactInfoCustomMap(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ContactInfoCustomMap(): useLayoutEffect(): got resource from cache');
      setContactMapRes(cacheItem);
    }
    // Fetch ContactInfo Data
    fetchDbContactInfoData();

    // Load Google Map Script
    // loadGoogleMapScript(currLanguage.languageCode);

    // For React Google Maps
    const keydownListener = (e) => {
      if (e.key === "Escape") {
        setSelectedPark(null);
      }
    };

    window.addEventListener("keydown", keydownListener);

    return () => {
      window.removeEventListener("keydown", keydownListener);
    };
  }, []);

  function geocodeAddress(address) {
    // console.log('In geocodeAddress(): window is: ', window);
    // var address = document.getElementById('address').value;
    // let address = "332+Pear+Blossom+Road,+Stafford,+VA+22554,+USA";

    if (window && window !== null && window.google && window.google !== null) {
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, function (results, status) {
        if (
          window.google &&
          window.google !== null &&
          status === window.google.maps.GeocoderStatus.OK
        ) {
          // console.log('In geocodeAddress(): results is: ', results);
          // console.log('In geocodeAddress(): results[0].geometry.location.lat is: ', results[0].geometry.location.lat);
          // console.log('In geocodeAddress(): results[0].geometry.location.lng is: ', results[0].geometry.location.lng);

          // mapOptions.center.lat = results[0].geometry.location.lat;
          // mapOptions.center.lng = results[0].geometry.location.lng;
          // initMapOptions.center.lat = 38.436995;
          // initMapOptions.center.lng = -77.425396;
          // console.log("In geocodeAddress(): initMapOptions is: ", initMapOptions);
          // setMapOptions(initMapOptions);

          // console.log('In geocodeAddress(): mapId is: ', mapId);
          const mapElement = document.getElementById(mapId);

          // console.log('In geocodeAddress(): mapElement is: ', mapElement);

          // console.log('In geocodeAddress(): lat is: ', lat);
          // console.log('In geocodeAddress(): lng is: ', lng);
          mapOptions.center.lat = lat;
          mapOptions.center.lng = lng;
          mapOptions.zoom = zoom;
          // console.log('In geocodeAddress(): mapOptions is: ', mapOptions);

          const map = new window.google.maps.Map(mapElement, mapOptions);
          // console.log("In geocodeAddress(): map is: ", map);

          // map.setCenter(results[0].geometry.location);

          // var infoWindow = new window.google.maps.InfoWindow({content: "CreateCardInfoWindow" });
          // console.log('In geocodeAddress(): infoWindowTitle is: ', infoWindowTitle);
          var infoWindow = new window.google.maps.InfoWindow();
          infoWindow.setContent(
            `<b><span style="color: \'black\'">` +
              infoWindowTitle +
              `</span></b>`
          );

          let marker = new window.google.maps.Marker({
            position: { lat: lat, lng: lng },
            // position: { lat: 45.4211, lng: -75.6903 },
            map: map,
            // title: infoWindowTitle
          });

          marker.addListener("click", (e) => {
            infoWindow.open(map, marker);
          });

          // resultsMap.setCenter(results[0].geometry.location);
          // var marker = new window.google.maps.Marker({
          //   map: resultsMap,
          //   position: results[0].geometry.location
          // });
        } else {
          console.log(
            "In geocodeAddress(): Geocode was not successful for the following reason: ",
            status
          );
        }
      });
    }
  }

  function onMapScriptLoaded() {
    // console.log('In onMapScriptLoaded(): mapElement is: ', mapElement);
    setGoogleScriptFlag(true);

    // Now that Map script is loaded GeoCode Street Address
    // let address = "332+Pear+Blossom+Road,+Stafford,+VA+22554,+USA";
    // let address = "Reston, VA 20191";
    // geocodeAddress(address);

    // // var infoWindow = new window.google.maps.InfoWindow({content: "CreateCardInfoWindow" });
    // var infoWindow = new window.google.maps.InfoWindow;
    // infoWindow.setContent('<b>القاهرة</b>');

    // let marker = new window.google.maps.Marker({
    //   position: { lat: 45.4211, lng: -75.6903 },
    //   map: map,
    //   title: 'Hello Ottawa!'
    // });

    // marker.addListener('click', e => {
    //   infoWindow.open(map, marker);
    // });
  }

  return (
    <div dir={direction}>
      {/* progressFlag &&
                <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
                </div>
            */}

      <Grid
        container
        alignContent="center"
        alignItems="center"
        className={classes.mainGrid}
        style={{ width: "100%" }}
      >
        {dummyData &&
          dummyData.map((item, index) => {
            return (
              <Grid
                container
                alignContent="center"
                alignItems="center"
                style={{
                  minWidth: "34vw",
                  width: "100%",
                  background: paperBackground,
                  display: "flex",
                  flexGrow: 2,
                  flexDirection: "row",
                  flexBasis: "100%",
                }}
              >
                <div
                  id={mapId}
                  style={{
                    minWidth: "34vw",
                    maxWidth: "100vw",
                    width: "100%",
                    height: "100%",
                    minHeight: "65vh",
                  }}
                />
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
}

export default ContactInfoCustomMap;
