import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InputAdornment from "@material-ui/core/InputAdornment";
import TranslateIcon from '@material-ui/icons/Translate';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SaveIcon from "@material-ui/icons/Save";
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { OrderList } from 'primereact/orderlist';

// Amplify
// import { API } from "aws-amplify";
// import { AmplifyTheme   } from 'aws-amplify-react';

// AppContext
import AppContext from "../../../config/AppContext";

import LineSpacing from "../../../components/misc/LineSpacing";
import GlobalErrorMessage from "../../../components/dialogs/GlobalErrorMessage";
import CommonMediaSelector from "../../../components/dialogs/CommonMediaSelector";
import DomainUploadCapture from "../../../components/upload/DomainUploadCapture";

import DesignBlockConverter from "../DesignBlockConverter";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        width: "100% !important",
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    // leftPanelMainItems: {
    //     marginLeft: theme.spacing(1),
    //     textAlign: "left",
    // },
    leftPanelSubItems: {
        width: "100% !important",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1rem" /* Footer height */,

        width: "100% !important",
        marginTop: "auto",
        // backgroundColor: '#fff',
        textAlign: "center",
    },
    paper: {
        minWidth: "300px",
        marginTop: theme.spacing(2),
        alignItems: "center",
        padding: "10px 30px 10px 30px !important",
        textAlign: "center",
    },
    divPadding: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    divLeftPadding: {
        width: '100% !important',
        paddingLeft: theme.spacing(1),
        // paddingRight: theme.spacing(1),
    },
}));

function ManageDesignBlocksTv(props) {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);
    
    const theme = useTheme();
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    const { 
        manageDesignBlocksLabel, 
        requiredFields,
        languageLabel,
        languagePrompt,
        languageErrMsg,
        domainLabel,
        domainPrompt,
        domainErrMsg,
            
        designTitleLabel,
        designTitlePrompt,
        designTitleErrMsg,
        
        designSubTitleLabel,
        designSubTitlePrompt,
        designSubTitleErrMsg,
                
        cloneRecordLabel,
        uploadFilesLabel,
        convertDesignBlocksLabel,

        // selectTitlePrompt,

        designDescLabel,
        designDescPrompt,
        designDescErrMsg,

        priceCurrencyLabel,
        priceCurrencyPrompt,
        priceCurrencyErrMsg,

        yearlyDiscountLabel,
        yearlyDiscountPrompt,
        yearlyDiscountErrMsg,
        yearlyDiscountMsg,
        
        designTypeLabel,
        designTypePrompt,
        designTypeErrMsg,

        designTypeRefLabel,
        designTypeRefPrompt,
        designTypeRefErrMsg,
        designTypeRefMsg,
        handleDesignTypeRefChange,
                        
        designTypeRefErrFlag,
        designTypeRef,
        designTypeRefOptions,

        designRecordLabel,
        designRecordsLabel,

        gridTitle,
        gridSubTitle,
        loadingText,
        noOptionsText,
        clearText,
        closeText,
    
        uploadLabel,
        createDesignBlockLabel,
        updateDesignBlockLabel,
        createLabel,
        closeLabel,
        noDesignBlocksFound, 
        gridDesc,
        gridType,
        lastUpdateLabel,
        convertLabel,

        organizeLabel,
        organizeDesignBlocksLabel,
        availableDesignBlocksLabel,
        saveLabel,
    
        dbWebPageLabel,
        dbWebPagePrompt,
        dbWebPageErrMsg,

        mediaUrlLabel,
        mediaUrlPrompt,
        mediaUrlErrMsg,
    
        mediaDescLabel,
        mediaDescPrompt,
        mediaDescErrMsg,

        mediaCaptionLabel,
        mediaCaptionPrompt,
        mediaCaptionErrMsg,

        linkTextLabel,
        linkTextPrompt,
        linkTextErrMsg,
        linkTextMsg,

        linkDestinationLabel,
        linkDestinationPrompt,
        linkDestinationErrMsg,
        linkDestinationMsg,

        linkLabel,
        textLabel,
        destLabel,

        mediaLabel,
        captionLabel,
        descLabel,
        urlLabel,

        //==================================

        datePickerLanguage,
        globalErrorFlag,
        globalErrorMsg,
        showListFlag,
        languageErrFlag,
        language,
        languages,
        handleListLanguageChange,
        editModeFlag,
        domainErrFlag,
        domain,
        domainOptions,
        handleListDomainChange,
        designBlockRecords,
        showAlertDialog,
        editRecordAction,
        convertAction,
        cloneAction,
        createMoreAction,
        noRecordFoundFlag,
        createPriceAction,
        showDetailsFormFlag,
        closeDetailsForm,
        handleLanguageChange,
        handleDomainChange,
        designTypeErrFlag,
        designType,
        categoryOptions,
        handleDesignTypeChange,
        designTitleErrFlag,
        designTitle,
        handleChange,
        textMaxLength,
        designSubTitleErrFlag,
        designSubTitle,
        yearlyDiscountErrFlag,
        yearlyDiscount,
        submitButtonFlag,
        createUpdateBtnAction,
        convertFlag,
        closeConvertForm,

        designDescErrFlag,
        designDesc,
        designDescOptions,
        handleDesignDescChange,

        priceCurrencyErrFlag,
        priceCurrency,
        priceCurrencyOptions,
        handlePriceCurrencyChange,

        filteredRecords,
        dragdropFlag,
        orderListTemplate,
        closeOrderListForm,
        handleFilteredRecords,
        organizeService,

        webPage,
        webPageOptions,
        webPageErrFlag,
        handleListWebPageChange,
        handleWebPageChange,
        handleOrderListWebPageChange,

        designBlock,
        designBlockErrFlag,
        // designBlockRecords,
        closeUploadForm,

        mediaUrl,
        mediaUrlErrFlag,
        mediaCaption,
        mediaCaptionErrFlag,
        mediaDesc,
        mediaDescErrFlag,
        linkText,
        linkTextErrFlag,
        linkDestination,
        linkDestinationErrFlag,
        linkDestinationOptions,

        orderListAction,
        uploadAction,

        createDesignBlockAction,
        designTypeOptions,
        selectMediaAction,
        handleLinkDestinationChange,
        uploadFlag,
        orderListFlag,
        sliceText,
    } = props;
        
    useEffect(() => {
        // console.log('In ManageDesignBlocksTv(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        // sessionStorage.removeItem("current-live-event");
    }, []);

    useLayoutEffect(() => {
        // console.log("In ManageDesignBlocksTv(): useLayoutEffect()");
    }, []);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
            <div className={classes.divPadding}>
                {/* <StyledPaper elevation={3} className={classes.paper}> */}
                    <Grid container justify="center">
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h5" color="primary" style={{ textAlign: "center" }}>
                                {manageDesignBlocksLabel}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                                {requiredFields}
                            </Typography>
                        </Grid>

                        {globalErrorFlag && (
                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={24} />
                                <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={12}>
                            <LineSpacing value={24} />
                        </Grid>
                        
                        <Grid item xs={12} sm={12}>
                            <Grid container justify="center">
                                {showListFlag &&
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                *{languageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="languageId"
                                                            value={language}
                                                            options={languages}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleListLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={languagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={languageErrFlag}
                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                *{domainLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="domainId"
                                                            value={domain}
                                                            options={domainOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleListDomainChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            // disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={domainPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={domainErrFlag}
                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>
                            
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={classes.textField} error={webPageErrFlag}>
                                                        <label htmlFor="webPageId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={webPageErrFlag ? "secondary" : "inherit"}>
                                                                *{dbWebPageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="webPageId"
                                                            value={webPage}
                                                            options={webPageOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleListWebPageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={dbWebPagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={webPageErrFlag}
                                                                    helperText={webPageErrFlag ? dbWebPageErrMsg : ""}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            {designBlockRecords && designBlockRecords.length > 0 ?
                                                <>
                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={12} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12}>
                                                                <Grid container justify="center">
                                                                    <Grid item xs={3} sm={3}>
                                                                        <Grid container justify="flex-start">
                                                                            <Tooltip title={organizeLabel} placement="bottom">
                                                                                <IconButton color="primary" aria-label={organizeLabel} component="span" onClick={orderListAction}>
                                                                                    <ImportExportIcon className={clsx(classes.iconLarge)} aria-label={organizeLabel}/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={3} sm={3}>
                                                                        <Grid container justify="center">
                                                                            <Tooltip title={convertLabel} placement="bottom">
                                                                                <IconButton color="primary" aria-label={convertLabel} component="span" onClick={convertAction}>
                                                                                    <TranslateIcon className={clsx(classes.iconLarge)} aria-label={convertLabel}/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={3} sm={3}>
                                                                        <Grid container justify="center">
                                                                            <Tooltip title={uploadLabel} placement="bottom">
                                                                                <IconButton color="primary" aria-label={uploadLabel} component="span" onClick={uploadAction}>
                                                                                    <CloudUploadIcon className={clsx(classes.iconLarge)} aria-label={uploadLabel}/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={3} sm={3}>
                                                                        <Grid container justify="flex-end">
                                                                            <Tooltip title={createLabel} placement="bottom">
                                                                                <IconButton color="primary" aria-label={createLabel} component="span" onClick={createMoreAction}>
                                                                                    <AddIcon className={clsx(classes.iconLarge)} aria-label={createLabel}/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} sm={12}>
                                                                <LineSpacing value={8} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={8} />
                                                    </Grid>
                                                    
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <List className={classes.listContainer}>
                                                                <div>
                                                                    {designBlockRecords && designBlockRecords.length > 0 &&
                                                                        designBlockRecords.map((record, index) => (
                                                                            <Grid key={index} container justify="center">
                                                                                <ListItem alignItems="flex-start" disableGutters={true}>
                                                                                    <ListItemText style={{backgroundColor: (index % 2) === 0 ? '#F8F8FF' : 'inherit', padding: '8px'}}>
                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {gridType}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.DesignTypeText}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        
                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {gridTitle}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.DesignTitle}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {gridSubTitle}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.DesignSubTitle}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {gridDesc}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {sliceText(record.DesignDesc, 250)}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Typography variant="subtitle1" style={{display: 'block'}}>
                                                                                                {linkLabel}
                                                                                            </Typography>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block', paddingLeft: '4px'}}>
                                                                                                        {textLabel}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.LinkText}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block', paddingLeft: '4px'}}>
                                                                                                        {destLabel}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.LinkDestinationText}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Typography variant="subtitle1" style={{display: 'block'}}>
                                                                                                {mediaLabel}
                                                                                            </Typography>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block', paddingLeft: '4px'}}>
                                                                                                        {captionLabel}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.MediaCaption}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block', paddingLeft: '4px'}}>
                                                                                                        {descLabel}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {sliceText(record.MediaDesc, 250)}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block', paddingLeft: '4px'}}>
                                                                                                        {urlLabel}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {sliceText(record.MediaUrl, 40)}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <img src={record.MediaUrl} style={{height: '64px'}} />
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {lastUpdateLabel}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.FormattedDateTime}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        
                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="flex-end">
                                                                                                <Grid item style={{paddingRight: theme.spacing(3)}}>
                                                                                                    <DeleteIcon color="secondary" onClick={() => showAlertDialog(record, "single")}/>
                                                                                                </Grid>

                                                                                                <Grid item>
                                                                                                    <EditIcon color="primary" onClick={() => editRecordAction(record)}/>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </ListItemText>
                                                                                </ListItem>
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </List>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            :
                                                <>
                                                    {noRecordFoundFlag &&
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12}>
                                                                <Typography variant="body1" color="secondary">
                                                                    {noDesignBlocksFound}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={12} sm={12}>
                                                                <LineSpacing value={12} />
                                                            </Grid>

                                                            <Grid item xs={12} sm={12}>
                                                                <Button variant="contained" size="large" disabled={false} color="primary" onClick={createDesignBlockAction}>
                                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                    {createDesignBlockLabel}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                }

                                {showDetailsFormFlag && 
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-end">
                                                <Tooltip title={closeLabel} placement="bottom">
                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={6}>
                                                    <Grid container justify="flex-start">
                                                        <Typography variant="h6" color="primary">
                                                            {editModeFlag ? updateDesignBlockLabel : createDesignBlockLabel}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    {editModeFlag &&
                                                        <Grid container justify="center">
                                                            <Button color="primary" onClick={cloneAction}>
                                                                <Typography variant="subtitle1" color="primary">
                                                                    {cloneRecordLabel}
                                                                </Typography>
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                *{languageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="languageId"
                                                            value={language}
                                                            options={languages}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={languagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={languageErrFlag}
                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                *{domainLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="domainId"
                                                            value={domain}
                                                            options={domainOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleDomainChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={domainPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={domainErrFlag}
                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={classes.textField} error={webPageErrFlag}>
                                                        <label htmlFor="webPageId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={webPageErrFlag ? "secondary" : "inherit"}>
                                                                *{dbWebPageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="webPageId"
                                                            value={webPage}
                                                            options={webPageOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleWebPageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={dbWebPagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={webPageErrFlag}
                                                                    helperText={webPageErrFlag ? dbWebPageErrMsg : ""}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                    <FormControl className={classes.textField} error={designTypeErrFlag}>
                                                        <label htmlFor="designTypeId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={designTypeErrFlag ? "secondary" : "inherit"}>
                                                                *{designTypeLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="designTypeId"
                                                            value={designType}
                                                            options={designTypeOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleDesignTypeChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={designTypePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={designTypeErrFlag}
                                                                    helperText={designTypeErrFlag ? designTypeErrMsg : ""}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={classes.textField} error={designTypeRefErrFlag}>
                                                        <label htmlFor="designTypeRefId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={designTypeRefErrFlag ? "secondary" : "inherit"}>
                                                                *{designTypeRefLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="designTypeRefId"
                                                            value={designTypeRef}
                                                            options={designTypeRefOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleDesignTypeRefChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={designTypeRefPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={designTypeRefErrFlag}
                                                                    helperText={designTypeRefErrFlag ? designTypeRefErrMsg : designTypeRefMsg}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={designTitleErrFlag}>
                                                                <label htmlFor="designTitleId" style={directionAlignment}>
                                                                    <Typography variant="subtitle1" color={designTitleErrFlag ? "secondary" : "inherit"}>
                                                                        *{designTitleLabel}
                                                                    </Typography>
                                                                </label>

                                                                <TextareaAutosize
                                                                    id="designTitleId"
                                                                    aria-label={designTitleLabel}
                                                                    rows={1.5}
                                                                    rowsMax={6}
                                                                    value={designTitle}
                                                                    placeholder={designTitlePrompt}
                                                                    onChange={handleChange}
                                                                    required
                                                                    // autoFocus={true}
                                                                    margin="none"
                                                                    className={classes.textArea}
                                                                    maxLength={textMaxLength}
                                                                />
                                                                <FormHelperText>{designTitleErrFlag ? designTitleErrMsg : ""}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={designSubTitleErrFlag}>
                                                                <label htmlFor="designSubTitleId" style={directionAlignment}>
                                                                    <Typography variant="subtitle1" color={designSubTitleErrFlag ? "secondary" : "inherit"}>
                                                                        {designSubTitleLabel}
                                                                    </Typography>
                                                                </label>

                                                                <TextareaAutosize
                                                                    id="designSubTitleId"
                                                                    aria-label={designSubTitleLabel}
                                                                    rows={1.5}
                                                                    rowsMax={6}
                                                                    value={designSubTitle}
                                                                    placeholder={designSubTitlePrompt}
                                                                    onChange={handleChange}
                                                                    required
                                                                    // autoFocus={true}
                                                                    margin="none"
                                                                    className={classes.textArea}
                                                                    maxLength={textMaxLength}
                                                                />
                                                                <FormHelperText>{designSubTitleErrFlag ? designSubTitleErrMsg : ""}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={designDescErrFlag}>
                                                                <label htmlFor="designDescId" style={directionAlignment}>
                                                                    <Typography variant="subtitle1" color={designDescErrFlag ? "secondary" : "inherit"}>
                                                                        *{designDescLabel}
                                                                    </Typography>
                                                                </label>

                                                                <TextareaAutosize
                                                                    id="designDescId"
                                                                    aria-label={designDescLabel}
                                                                    rows={1.5}
                                                                    rowsMax={6}
                                                                    value={designDesc}
                                                                    placeholder={designDescPrompt}
                                                                    onChange={handleChange}
                                                                    required
                                                                    // autoFocus={true}
                                                                    margin="none"
                                                                    className={classes.textArea}
                                                                    maxLength={textMaxLength}
                                                                />
                                                                <FormHelperText>{designDescErrFlag ? designDescErrMsg : ""}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={mediaCaptionErrFlag}>
                                                                <label htmlFor="mediaCaptionId" style={directionAlignment}>
                                                                    <Typography variant="subtitle1" color={mediaCaptionErrFlag ? "secondary" : "inherit"}>
                                                                        {mediaCaptionLabel}
                                                                    </Typography>
                                                                </label>

                                                                <TextField
                                                                    id="mediaCaptionId"
                                                                    value={mediaCaption}
                                                                    placeholder={mediaCaptionPrompt}
                                                                    onChange={handleChange}
                                                                    required
                                                                    fullWidth
                                                                    type={"text"}
                                                                    error={mediaCaptionErrFlag}
                                                                    margin="none"
                                                                    variant="outlined"
                                                                    helperText={mediaCaptionErrFlag ? mediaCaptionErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                                                    // }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={mediaDescErrFlag}>
                                                        <label htmlFor="mediaDescId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={mediaDescErrFlag ? "secondary" : "inherit"}>
                                                                {mediaDescLabel}
                                                            </Typography>
                                                        </label>

                                                        <TextareaAutosize
                                                            id="mediaDescId"
                                                            aria-label={mediaDescLabel}
                                                            rows={1.5}
                                                            rowsMax={6}
                                                            value={mediaDesc}
                                                            placeholder={mediaDescPrompt}
                                                            onChange={handleChange}
                                                            required
                                                            // autoFocus={true}
                                                            margin="none"
                                                            className={classes.textArea}
                                                            maxLength={textMaxLength}
                                                        />
                                                        <FormHelperText>{mediaDescErrFlag ? mediaDescErrMsg : ""}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={mediaUrlErrFlag}>
                                                        <label htmlFor="mediaUrlId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={mediaUrlErrFlag ? "secondary" : "inherit"}>
                                                                {mediaUrlLabel}
                                                            </Typography>
                                                        </label>

                                                        <OutlinedInput
                                                            id="mediaUrlId"
                                                            value={mediaUrl}
                                                            placeholder={mediaUrlPrompt}
                                                            onChange={handleChange}
                                                            required
                                                            fullWidth
                                                            type={"text"}
                                                            error={mediaUrlErrFlag}
                                                            margin="none"
                                                            variant="outlined"
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label={mediaUrlPrompt}
                                                                        onClick={selectMediaAction}
                                                                    >
                                                                        <ImageSearchIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                        <FormHelperText>{mediaUrlErrFlag ? mediaUrlErrMsg : ""}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        {mediaUrl &&
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <img src={mediaUrl} style={{height: '96px'}} />
                                                </Grid>
                                            </Grid>
                                        }           

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={linkTextErrFlag}>
                                                                <label htmlFor="linkTextId" style={directionAlignment}>
                                                                    <Typography variant="subtitle1" color={linkTextErrFlag ? "secondary" : "inherit"}>
                                                                        {linkTextLabel}
                                                                    </Typography>
                                                                </label>

                                                                <OutlinedInput
                                                                    id="linkTextId"
                                                                    value={linkText}
                                                                    placeholder={linkTextPrompt}
                                                                    onChange={handleChange}
                                                                    required
                                                                    fullWidth
                                                                    type={"text"}
                                                                    error={linkTextErrFlag}
                                                                    margin="none"
                                                                    variant="outlined"
                                                                />
                                                                <FormHelperText>{linkTextErrFlag ? linkTextErrMsg : linkTextMsg}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={classes.textField} error={linkDestinationErrFlag}>
                                                        <label htmlFor="linkDestinationId" className={classes.verticalAlignLabel} style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={linkDestinationErrFlag ? "secondary" : "inherit"}>
                                                                {linkDestinationLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="linkDestinationId"
                                                            value={linkDestination}
                                                            options={linkDestinationOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleLinkDestinationChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={linkDestinationPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={linkDestinationErrFlag}
                                                                    helperText={linkDestinationErrFlag ? linkDestinationErrMsg : linkDestinationMsg}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={createUpdateBtnAction}>
                                                    {editModeFlag ?
                                                    <>
                                                        <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                        {updateDesignBlockLabel} 
                                                    </>
                                                    : 
                                                    <>
                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                        {createDesignBlockLabel}
                                                    </>
                                                    }
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                {uploadFlag && 
                                    <>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-end">
                                                <Tooltip title={closeLabel} placement="bottom">
                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeUploadForm}>
                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <div>
                                                <Grid container justify="flex-start">
                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="flex-start">
                                                            <Typography variant="h6" color="primary">
                                                                {uploadFilesLabel}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <DomainUploadCapture 
                                                showHeadersFlag={false}
                                            />
                                        </Grid>
                                    </>
                                }

                                {convertFlag &&
                                    <>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-end">
                                                <Tooltip title={closeLabel} placement="bottom">
                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <div>
                                                <Grid container justify="flex-start">
                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="flex-start">
                                                            <Typography variant="h6" color="primary">
                                                                {convertDesignBlocksLabel}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <DesignBlockConverter 
                                                showHeadersFlag={false}
                                                referrer={"ManageDesignBlocks"}
                                            />
                                        </Grid>
                                    </>
                                }

                                {orderListFlag &&
                                    <div style={{width: '100%'}}>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-end">
                                                <Tooltip title={closeLabel} placement="bottom">
                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeOrderListForm}>
                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                            <Grid container justify="flex-start">
                                                <Typography variant="h6" color="primary">
                                                    {organizeDesignBlocksLabel}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={webPageErrFlag}>
                                                        <label htmlFor="webPageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={webPageErrFlag ? "secondary" : "inherit"}>
                                                                *{dbWebPageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="webPageId"
                                                            value={webPage}
                                                            options={webPageOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleOrderListWebPageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={dbWebPagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={webPageErrFlag}
                                                                    helperText={webPageErrFlag ? dbWebPageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center" style={{width: '100% !important'}}>
                                                <div style={{minWidth: '100%'}}>
                                                    <OrderList 
                                                        value={filteredRecords} 
                                                        header={availableDesignBlocksLabel} 
                                                        dragdrop={dragdropFlag}
                                                        listStyle={{width: '100% !important', height:'auto'}} 
                                                        dataKey="PriceId"
                                                        itemTemplate={orderListTemplate} 
                                                        onChange={(e) => handleFilteredRecords(e)}
                                                        style={{width: '100% !important', display: 'flex'}}
                                                        >
                                                    </OrderList>
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={organizeService}>
                                                    <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                    {saveLabel} 
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                }

                                <Grid item xs={12} sm={12}>
                                    <LineSpacing value={18} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                {/* </StyledPaper> */}
            </div>

            <Grid item xs={12} sm={12}>
                <LineSpacing value={48} />
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

export default ManageDesignBlocksTv;
