import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import clsx from "clsx";
// import Backdrop from '@material-ui/core/Backdrop';
// import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
// import SaveIcon from "@material-ui/icons/Save";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Container from "@material-ui/core/Container";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import CheckIcon from '@material-ui/icons/Check';
// import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import InputAdornment from "@material-ui/core/InputAdornment";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import TranslateIcon from '@material-ui/icons/Translate';

// Froala
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// AppContext
import AppContext from "../../../config/AppContext";

import LineSpacing from "../../../components/misc/LineSpacing";
import FroalaCommonEditor from "../../../components/editor/FroalaCommonEditor";
// import TsiCustomizedSnackbars from "../../../../../components/misc/TsiCustomizedSnackbars";
// import AlertDialog from "../../../../../components/dialogs/AlertDialog";
import DomainUploadCapture from "../../../components/upload/DomainUploadCapture";
import CommonConverter from "../../../components/convert/CommonConverter";
import GlobalErrorMessage from "../../../components/dialogs/GlobalErrorMessage";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    leftPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "left",
    },
    leftPanelSubItems: {
        width: "100%",
        // marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    divPadding: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    listContainer: {
        width: '100%',
        maxHeight: '65vh',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
    },
}));

function PostJobsMv(props) {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);

    const { 
        manageJobsLabel, 
        requiredFields,
        languageLabel,
        languagePrompt,
        languageErrMsg,
    
        jobBannerUrlLabel,
        jobBannerUrlPrompt,
        jobBannerUrlErrMsg,
            
        yourNamesLabel,
        yourNamesPrompt,
        yourNamesErrMsg,
        yourNamesMsg,
        
        jobTitleLabel,
        jobTitlePrompt,
        jobTitleErrMsg,

        jobTagsLabel,
        jobTagsPrompt,
        jobTagsErrMsg,
    
        jobStartTimeLabel,
        jobStartTimePrompt,
        jobStartTimeErrMsg,

        jobCategoryLabel,
        jobCategoryPrompt,
        jobCategoryErrMsg,

        // uploadJobTitleLabel,
        // uploadJobTitlePrompt,
        // uploadJobTitleErrMsg,

        // jobRecordsLabel,
        // inProgressLabel,
        // startLabel,
        // endLabel,
        // notStartedLabel,
        // dateCreatedLabel,
    
        jobContentLabel,
        jobContentPrompt,
        yourTimeZoneMsg,

        // timeZoneLabel,
        // toLabel,
        // ofLabel,
        // recordsLabel,
        // noRecordsFound,
        // statusLabel,
        // editLabel,
        // deleteLabel,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,
        cancelLabel,
        clearLabel,
        okLabel,
        uploadLabel,
        createJobContentLabel,
        updateJobContentLabel,
        // jobRecordLabel,
        createMoreLabel,
        createLabel,
        closeLabel,
        noJobRecordsFound, 
        // startEventInfoMsg,

        gridTitle,
        gridNames,
        // gridDuration,
        // gridEventUrl,
        // gridEventUrlType,
        gridStartTime,
        gridBannerUrl,
    
        convertLabel,

        sourceLanguageLabel,
        sourceLanguagePrompt,
        sourceLanguageErrMsg,

        targetLanguageLabel,
        targetLanguagePrompt,
        targetLanguageErrMsg,

        sliceText,
        sliceMoreAction,
        moreLabel,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,
        domainErrFlag,
        domain,
        domainOptions,
        handleDomainChange,

        //=====================

        datePickerLanguage,
        showJobListFlag,
        jobRecords,
        // selectedTableRows,
        showAlertDialog,
        convertAction,
        editRecordAction,
        uploadAction,
        createMoreAction,
        noJobRecordFoundFlag,
        createLiveEventAction,
        showJobDetailsFormFlag,
        closeDetailsForm,
        timeZone,
        languageErrFlag,
        language,
        languages,
        handleLanguageChange,
        editModeFlag,
        jobBannerUrl,
        jobCategoryErrFlag,
        jobCategory,
        categoryOptions,
        handleJobCategoryChange,
        jobBannerUrlErrFlag,
        handleChange,
        selectImageAction,
        yourNamesErrFlag,
        yourNames,
        jobTitleErrFlag,
        jobTitle,
        jobStartTimeErrFlag,
        jobStartTime,
        handleJobStartTimeChange,
        jobTagsErrFlag,
        jobTags,
        textMaxLength,
        jobContentErrFlag,
        editorRef,
        editorMaxChars,
        jobContent,
        handleEditorHtmlBodyChange,
        submitButtonFlag,
        jobService,
        uploadFlag,
        closeUploadForm,
        convertFlag,
        closeConvertForm,
        selectTitlePrompt,

        LanguageAndDomain,
        globalErrorFlag,
        globalErrorMsg,
    } = props;

    const theme = useTheme();
  
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    // function LanguageAndDomain(props) {
    //     return (
    //         <>
                // <div className={clsx(classes.divPadding, classes.divWrapper)}>
                //     <Grid item xs={12} sm={6}>
                //         <Grid container justify="center">
                //             <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                //                 <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                //                     <label htmlFor="languageId" style={directionAlignment}>
                //                         <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                //                             *{languageLabel}
                //                         </Typography>
                //                     </label>

                //                     <Autocomplete
                //                         id="languageId"
                //                         value={language}
                //                         options={languages}
                //                         getOptionLabel={option => option.languageName}
                //                         getOptionSelected={option => option.languageName}
                //                         onChange={handleLanguageChange}
                //                         loadingText={loadingText}
                //                         noOptionsText={noOptionsText}
                //                         autoHighlight={true}
                //                         clearText={clearText}
                //                         closeText={closeText}
                //                         disabled={editModeFlag ? true : false}
                //                         renderInput={params => 
                //                             <TextField 
                //                                 {...params} 
                //                                 placeholder={languagePrompt} 
                //                                 variant="outlined"
                //                                 fullWidth
                //                                 error={languageErrFlag}
                //                                 helperText={languageErrFlag ? languageErrMsg : ""}
                //                                 // InputProps={{
                //                                 //     readOnly: editModeFlag ? true : false,
                //                                 // }}
                //                             />
                //                         }
                //                     />
                //                 </FormControl>
                //             </Grid>
                //         </Grid>
                //     </Grid>

                //     <Grid item xs={12} sm={6}>
                //         <Grid container justify="center">
                //             <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                //                 <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                //                     <label htmlFor="domainId" style={directionAlignment}>
                //                         <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                //                             *{domainLabel}
                //                         </Typography>
                //                     </label>

                //                     <Autocomplete
                //                         id="domainId"
                //                         value={domain}
                //                         options={domainOptions}
                //                         getOptionLabel={option => option.text}
                //                         getOptionSelected={option => option.text}
                //                         onChange={handleDomainChange}
                //                         loadingText={loadingText}
                //                         noOptionsText={noOptionsText}
                //                         autoHighlight={true}
                //                         clearText={clearText}
                //                         closeText={closeText}
                //                         // disabled={editModeFlag ? true : false}
                //                         renderInput={params => 
                //                             <TextField 
                //                                 {...params} 
                //                                 placeholder={domainPrompt} 
                //                                 variant="outlined"
                //                                 fullWidth
                //                                 error={domainErrFlag}
                //                                 helperText={domainErrFlag ? domainErrMsg : ""}
                //                                 // InputProps={{
                //                                 //     readOnly: editModeFlag ? true : false,
                //                                 // }}
                //                             />
                //                         }
                //                     />
                //                 </FormControl>
                //             </Grid>
                //         </Grid>
                //     </Grid>
                // </div>
    //         </>
    //     );
    // }

    return (
        <div dir={direction} className={classes.divWrapper}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h6" color="primary" style={{ textAlign: "center" }}>
                            {manageJobsLabel}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                            {requiredFields}
                        </Typography>
                    </Grid>

                    {globalErrorFlag && (
                        <Grid item xs={12} sm={12}>
                            <LineSpacing value={24} />
                            <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={12}>
                        <LineSpacing value={12} />
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="center">
                            {showJobListFlag &&
                                <>
                                    {/* <LanguageAndDomain/> */}

                                    <div className={clsx(classes.divPadding, classes.divWrapper)}>
                                        <Grid item xs={12} sm={6}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                *{languageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="languageId"
                                                            value={language}
                                                            options={languages}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={languagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={languageErrFlag}
                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                *{domainLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="domainId"
                                                            value={domain}
                                                            options={domainOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleDomainChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            // disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={domainPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={domainErrFlag}
                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        {jobRecords && jobRecords.length > 0 ?
                                            <>
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="center" className={classes.divPadding}>
                                                        <Grid item xs={4} sm={4}>
                                                            <Grid container justify="flex-start">
                                                                <Tooltip title={convertLabel} placement="bottom">
                                                                    <IconButton color="primary" aria-label={convertLabel} component="span" onClick={uploadAction}>
                                                                        <CloudUploadIcon className={clsx(classes.iconLarge)} aria-label={uploadLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={4} sm={4}>
                                                            <Grid container justify="center">
                                                                <Tooltip title={convertLabel} placement="bottom">
                                                                    <IconButton color="primary" aria-label={convertLabel} component="span" onClick={convertAction}>
                                                                        <TranslateIcon className={clsx(classes.iconLarge)} aria-label={convertLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={4} sm={4}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={createLabel} placement="bottom">
                                                                    <IconButton color="primary" aria-label={createLabel} component="span" onClick={createMoreAction}>
                                                                        <AddIcon className={clsx(classes.iconLarge)} aria-label={createLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={8} />
                                                </Grid>
                                                
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12}>
                                                        <List className={classes.listContainer}>
                                                            <div>
                                                                {jobRecords && jobRecords.length > 0 &&
                                                                    jobRecords.map((record, index) => (
                                                                        <Grid key={index} container justify="center">
                                                                            <ListItem alignItems="flex-start" disableGutters={true}>
                                                                                <ListItemText style={{backgroundColor: (index % 2) === 0 ? '#F8F8FF' : 'inherit', padding: '8px'}}>
                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {jobCategoryLabel}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {record.JobCategory}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    
                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {gridNames}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {record.AuthorNames}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {gridTitle}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {record.JobTitle}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {jobTagsLabel}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {record.Tags}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {gridStartTime}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {record.FormattedStartTime}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="center">
                                                                                            <Grid item xs={3} sm={3}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {gridBannerUrl}:
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            <Grid item xs={9} sm={9}>
                                                                                                <Typography variant="caption" style={{display: 'block'}}>
                                                                                                    {sliceText(record.BannerUrl, 35)}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Typography variant="caption" style={{display: 'block'}}>
                                                                                            {jobContentLabel}:
                                                                                        </Typography>
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Typography variant="caption" style={{display: 'block'}}>
                                                                                            <FroalaEditorView model={sliceText(record.JobContent, 200)}/>
                                                                                        </Typography>

                                                                                        {(record.JobContent.length > 200) &&
                                                                                            <Grid container justify="center">
                                                                                                <Link component="button" variant="caption" onClick={() => sliceMoreAction(record)}>
                                                                                                    {moreLabel}
                                                                                                </Link>
                                                                                            </Grid>
                                                                                        }
                                                                                    </Grid>

                                                                                    <Grid item xs={12} sm={12}>
                                                                                        <Grid container justify="flex-end">
                                                                                            <Grid item style={{paddingRight: theme.spacing(4)}}>
                                                                                                <DeleteIcon color="secondary" onClick={() => showAlertDialog(record, "single")}/>
                                                                                            </Grid>

                                                                                            <Grid item style={{paddingRight: theme.spacing(4)}}>
                                                                                                <TranslateIcon color="primary" onClick={convertAction}/>
                                                                                            </Grid>

                                                                                            <Grid item>
                                                                                                <EditIcon color="primary" onClick={() => editRecordAction(record)}/>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </ListItemText>
                                                                            </ListItem>
                                                                        </Grid>
                                                                    ))
                                                                }
                                                            </div>
                                                        </List>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        :
                                            <>
                                                {noJobRecordFoundFlag &&
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <Typography variant="body1">
                                                                {noJobRecordsFound}
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Button variant="contained" size="large" disabled={false} color="primary" onClick={createLiveEventAction}>
                                                                <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                {createJobContentLabel}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </>
                                        }
                                    </Grid>
                                </>
                            }

                            {showJobDetailsFormFlag && 
                                <div className={clsx(classes.divPadding)} style={{width: '100%'}}>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-end">
                                            <Tooltip title={closeLabel} placement="bottom">
                                                <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                    <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Typography variant="subtitle1" color="primary">
                                                        {yourTimeZoneMsg}: <span style={{color: '#000', paddingLeft: '8px'}}>{timeZone}</span>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* <LanguageAndDomain/> */}
                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                    <label htmlFor="languageId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                            *{languageLabel}
                                                        </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="languageId"
                                                        value={language}
                                                        options={languages}
                                                        getOptionLabel={option => option.languageName}
                                                        getOptionSelected={option => option.languageName}
                                                        onChange={handleLanguageChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        disabled={editModeFlag ? true : false}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={languagePrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={languageErrFlag}
                                                                helperText={languageErrFlag ? languageErrMsg : ""}
                                                                // InputProps={{
                                                                //     readOnly: editModeFlag ? true : false,
                                                                // }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                    <label htmlFor="domainId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                            *{domainLabel}
                                                        </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="domainId"
                                                        value={domain}
                                                        options={domainOptions}
                                                        getOptionLabel={option => option.text}
                                                        getOptionSelected={option => option.text}
                                                        onChange={handleDomainChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        // disabled={editModeFlag ? true : false}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={domainPrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={domainErrFlag}
                                                                helperText={domainErrFlag ? domainErrMsg : ""}
                                                                // InputProps={{
                                                                //     readOnly: editModeFlag ? true : false,
                                                                // }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={jobCategoryErrFlag}>
                                                    <label htmlFor="jobCategoryId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={jobCategoryErrFlag ? "secondary" : "inherit"}>
                                                            *{jobCategoryLabel}
                                                        </Typography>
                                                    </label>

                                                    <Autocomplete
                                                        id="jobCategoryId"
                                                        value={jobCategory}
                                                        options={categoryOptions}
                                                        getOptionLabel={option => option.text}
                                                        getOptionSelected={option => option.text}
                                                        onChange={handleJobCategoryChange}
                                                        loadingText={loadingText}
                                                        noOptionsText={noOptionsText}
                                                        autoHighlight={true}
                                                        clearText={clearText}
                                                        closeText={closeText}
                                                        // disabled={editModeFlag ? true : false}
                                                        renderInput={params => 
                                                            <TextField 
                                                                {...params} 
                                                                placeholder={jobCategoryPrompt} 
                                                                variant="outlined"
                                                                fullWidth
                                                                error={jobCategoryErrFlag}
                                                                helperText={jobCategoryErrFlag ? jobCategoryErrMsg : ""}
                                                                // InputProps={{
                                                                //     readOnly: editModeFlag ? true : false,
                                                                // }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    {jobBannerUrl &&
                                        <>
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <img src={jobBannerUrl} style={{height: '100px'}} />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={12} />
                                            </Grid>
                                        </>
                                    }

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={jobBannerUrlErrFlag}>
                                                    <label htmlFor="jobBannerUrlId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={jobBannerUrlErrFlag ? "secondary" : "inherit"}>
                                                            {jobBannerUrlLabel}
                                                        </Typography>
                                                    </label>

                                                    <OutlinedInput
                                                        id="jobBannerUrlId"
                                                        value={jobBannerUrl}
                                                        placeholder={jobBannerUrlPrompt}
                                                        onChange={handleChange}
                                                        required
                                                        fullWidth
                                                        type={"text"}
                                                        error={jobBannerUrlErrFlag}
                                                        margin="none"
                                                        variant="outlined"
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label={jobBannerUrlPrompt}
                                                                    onClick={selectImageAction}
                                                                >
                                                                    <ImageSearchIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                    <FormHelperText>{jobBannerUrlErrFlag ? jobBannerUrlErrMsg : ""}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={yourNamesErrFlag}>
                                                    <label htmlFor="yourNamesId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={yourNamesErrFlag ? "secondary" : "inherit"}>
                                                            *{yourNamesLabel}
                                                        </Typography>
                                                    </label>

                                                    <TextField
                                                        id="yourNamesId"
                                                        value={yourNames}
                                                        placeholder={yourNamesPrompt}
                                                        onChange={handleChange}
                                                        required
                                                        fullWidth
                                                        type={"text"}
                                                        error={yourNamesErrFlag}
                                                        margin="none"
                                                        variant="outlined"
                                                        helperText={yourNamesErrFlag ? yourNamesErrMsg : yourNamesMsg}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={jobTitleErrFlag}>
                                                    <label htmlFor="jobTitleId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={jobTitleErrFlag ? "secondary" : "inherit"}>
                                                            *{jobTitleLabel}
                                                        </Typography>
                                                    </label>

                                                    <TextField
                                                        id="jobTitleId"
                                                        value={jobTitle}
                                                        placeholder={jobTitlePrompt}
                                                        onChange={handleChange}
                                                        required
                                                        fullWidth
                                                        type={"text"}
                                                        error={jobTitleErrFlag}
                                                        margin="none"
                                                        variant="outlined"
                                                        helperText={jobTitleErrFlag ? jobTitleErrMsg : ""}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={jobStartTimeErrFlag}>
                                                            <label htmlFor="jobStartTimeId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={jobStartTimeErrFlag ? "secondary" : "inherit"}>
                                                                    *{jobStartTimeLabel}
                                                                </Typography>
                                                            </label>

                                                            <KeyboardDateTimePicker
                                                                id="jobStartTimeId"
                                                                value={jobStartTime}
                                                                // views={["year"]}
                                                                onChange={handleJobStartTimeChange}
                                                                clearable
                                                                placeholder={jobStartTimePrompt}
                                                                format="MMM DD, YYYY hh:mm a z"
                                                                // inputVariant="outlined"
                                                                invalidDateMessage={jobStartTimeErrMsg}
                                                                cancelLabel={cancelLabel}
                                                                clearLabel={clearLabel}
                                                                okLabel={okLabel}
                                                                // className={classes.phone}
                                                                inputVariant="outlined"
                                                                margin="none"
                                                            />
                                                            <FormHelperText>{jobStartTimeErrFlag ? jobStartTimeErrMsg : ""}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={jobTagsErrFlag}>
                                                            <label htmlFor="jobTagsId" style={directionAlignment}>
                                                                <Typography variant="subtitle1" color={jobTagsErrFlag ? "secondary" : "inherit"}>
                                                                    *{jobTagsLabel}
                                                                </Typography>
                                                            </label>

                                                            <TextareaAutosize
                                                                id="jobTagsId"
                                                                aria-label={jobTagsLabel}
                                                                rows={2}
                                                                rowsMax={6}
                                                                value={jobTags}
                                                                placeholder={jobTagsPrompt}
                                                                onChange={handleChange}
                                                                required
                                                                // autoFocus={true}
                                                                margin="none"
                                                                className={classes.textArea}
                                                                maxLength={textMaxLength}
                                                            />
                                                            <FormHelperText>{jobTagsErrFlag ? jobTagsErrMsg : ""}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                <FormControl className={clsx(classes.textField)} variant="outlined" error={jobContentErrFlag}>
                                                    <label htmlFor="jobContentId" style={directionAlignment}>
                                                        <Typography variant="subtitle1" color={jobContentErrFlag ? "secondary" : "inherit"}>
                                                            *{jobContentLabel}
                                                        </Typography>
                                                    </label>

                                                    <FroalaCommonEditor
                                                        editorId={"jobContentId"}
                                                        editorRef={editorRef}
                                                        // editorType={"minimal"} // Full Editor when not set
                                                        placeholderPrompt={jobContentPrompt}
                                                        editorMaxChars={editorMaxChars}
                                                        editorModel={jobContent}
                                                        modelChangeCallback={handleEditorHtmlBodyChange}
                                                        referrer={"PostJobs"}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="center">
                                            <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={jobService}>
                                                {editModeFlag ?
                                                <>
                                                    <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                    {updateJobContentLabel} 
                                                </>
                                                : 
                                                <>
                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                    {createJobContentLabel}
                                                </>
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={24} />
                                    </Grid>
                                </div>
                            }

                            {uploadFlag && 
                                <>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-end">
                                            <Tooltip title={closeLabel} placement="bottom">
                                                <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeUploadForm}>
                                                    <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <DomainUploadCapture 
                                            showHeadersFlag={false}
                                        />
                                    </Grid>
                                </>
                            }

                            {convertFlag &&
                                <>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-end">
                                            <Tooltip title={closeLabel} placement="bottom">
                                                <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                    <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <CommonConverter 
                                            groupingCategoryLabel={jobTitleLabel}
                                            groupingCategoryPrompt={jobTitlePrompt}
                                            groupingCategoryErrMsg={jobTitleErrMsg}

                                            showHeadersFlag={false}
                                            actionMsgLabel={uploadLabel}

                                            referrer={"PostJobs"}
                                            categoryType={"job-titles"}

                                            tableName={"job"}
                                        />
                                    </Grid>
                                </>
                            }

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={18} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    )
}

export default PostJobsMv;
