import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useCallback,
} from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

// Photo Gallery
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

// AppContext
import AppContext from "../../config/AppContext";

Amplify.configure(AmplifyConfig);

const PageContainer = styled.div`
  width: 100%,
  display: 'flex',
  flexWrap: 'wrap',
  background: #fff;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
  },
  progress: {
    margin: theme.spacing(1),
  },
  mainGrid: {
    textAlign: "left",
  },
  imgCarousel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  hdr: {
    color: "#fff",
  },
  prodDesc: {
    color: "fff",
    fontSize: "12px",
    marginLeft: theme.spacing(1),
    whiteSpace: "nowrap",
  },
  link: {
    color: "#fff",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  textItem: {
    color: "#fff",
    whiteSpace: "nowrap",
  },
}));

let imgCarousel = {
  imgCarouselHdr: "ImgCarousel",
  imgCarouselDesc: "Our imgCarousel information",
  imgCarousel1: "ImgCarousel1",
  imgCarousel2: "ImgCarousel2",
  imgCarousel3: "ImgCarousel3",
  imgCarousel4: "ImgCarousel4",
  imgCarousel5: "ImgCarousel5",
  imgCarousel6: "ImgCarousel6",
  imgCarousel7: "ImgCarousel7",
  imgCarousel8: "ImgCarousel8",
  imgCarousel9: "ImgCarousel9",
  imgCarousel10: "ImgCarousel10",
};

function ImageCarousel(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  // For ImgCarousel
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const [imgCarouselRes, setImgCarouselRes] = useState(imgCarousel);

  const {
    currLanguage,

    imageCarouselRef,
    imageItems,
    lazyLoadFlag,
    onClick,
    onImageLoad,
    onSlide,
    onBeforeSlide,
    onPause,
    onScreenChange,
    onPlay,
    infiniteFlag,
    showBulletsFlag,
    showFullscreenButtonFlag,
    showGalleryFullscreenButtonFlag,
    showPlayButtonFlag,
    showGalleryPlayButtonFlag,
    showThumbnailsFlag,
    showIndexFlag,
    showNavFlag,
    isRTL,
    thumbnailPosition, // bottom
    slideDuration, // 450
    slideInterval, // 2000
    slideOnThumbnailOverFlag,
  } = props;

  const classes = useStyles();

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  const {
    imgCarouselHdr,
    imgCarouselDesc,
    imgCarousel1,
    imgCarousel2,
    imgCarousel3,
    imgCarousel4,
    imgCarousel5,
    imgCarousel6,
    imgCarousel7,
    imgCarousel8,
    imgCarousel9,
    imgCarousel10,
  } = imgCarouselRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(imgCarousel);
    // console.log("In ImageCarousel(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ImageCarousel(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "imgCarouselHdr")
              imgCarousel.imgCarouselHdr = item.ResourceDesc;
            if (item.ResourceCode === "imgCarouselDesc")
              imgCarousel.imgCarouselDesc = item.ResourceDesc;
            if (item.ResourceCode === "imgCarousel1")
              imgCarousel.imgCarousel1 = item.ResourceDesc;
            if (item.ResourceCode === "imgCarousel2")
              imgCarousel.imgCarousel2 = item.ResourceDesc;
            if (item.ResourceCode === "imgCarousel3")
              imgCarousel.imgCarousel3 = item.ResourceDesc;
            if (item.ResourceCode === "imgCarousel4")
              imgCarousel.imgCarousel4 = item.ResourceDesc;
            if (item.ResourceCode === "imgCarousel5")
              imgCarousel.imgCarousel5 = item.ResourceDesc;
            if (item.ResourceCode === "imgCarousel6")
              imgCarousel.imgCarousel6 = item.ResourceDesc;
            if (item.ResourceCode === "imgCarousel7")
              imgCarousel.imgCarousel7 = item.ResourceDesc;
            if (item.ResourceCode === "imgCarousel8")
              imgCarousel.imgCarousel8 = item.ResourceDesc;
            if (item.ResourceCode === "imgCarousel9")
              imgCarousel.imgCarousel9 = item.ResourceDesc;
            if (item.ResourceCode === "imgCarousel10")
              imgCarousel.imgCarousel10 = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, imgCarousel);
          setImgCarouselRes(imgCarousel);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setImgCarouselRes(imgCarousel);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In ImageCarousel(): useEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));

    // currLanguage is the most current language passed to this component through props
    // We are using useEffect to get the functionality of componentDidUpdate()
    if (
      currLanguage !== undefined &&
      currLanguage !== null &&
      currLanguage !== currentLanguage
    ) {
      setCurrentLanguage(currLanguage);
      appContext.currentLanguage = currLanguage;

      // console.log('In ImageCarousel(): useEffect(): about to call fetchDbResources()');

      // Fetch resource from catche
      let cacheItemKey = "imgCarousel";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      // cacheItemKey += currLanguage.languageCode;
      // console.log('In ImageCarousel(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        // console.log("In ImageCarousel(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
        RemoveCacheItem(cacheItemKey);
      }

      let cacheItem = GetCacheItem(cacheItemKey);
      // console.log('In ImageCarousel(): useLayoutEffect(): cacheItem is: ', cacheItem);

      if (cacheItem === null) {
        // resource is not in cache, fetch from DB
        // console.log('In ImageCarousel(): useLayoutEffect(): resource is not in cache, fetch from DB');
        fetchDbResources(cacheItemKey);
      } else {
        // console.log('In ImageCarousel(): useLayoutEffect(): got resource from cache');
        setImgCarouselRes(cacheItem);
      }
    }
  }, []);

  useLayoutEffect(() => {
    // console.log("In ImageCarousel(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "imgCarousel";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ImageCarousel(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ImageCarousel(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ImageCarousel(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ImageCarousel(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ImageCarousel(): useLayoutEffect(): got resource from cache');
      setImgCarouselRes(cacheItem);
    }
  }, []);

  // Sample Gallery Items
  // const galleryItems = [
  //     { src: 'https://source.unsplash.com/2ShvY8Lf6l0/800x599', width: 4, height: 3 },
  //     { src: 'https://source.unsplash.com/Dm-qxdynoEc/800x799', width: 1, height: 1 },
  //     { src: 'https://source.unsplash.com/qDkso9nvCg0/600x799', width: 4, height: 3 },
  //     { src: 'https://source.unsplash.com/iecJiKe_RNg/600x799', width: 1, height: 1 }
  // ];

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  // const imageRenderer = useCallback(
  //     ({ index, left, top, key, photo }) => (
  //     <SelectedImage
  //         selected={selectAllPhotos ? true : false}
  //         key={key}
  //         margin={"2px"}
  //         index={index}
  //         photo={photo}
  //         left={left}
  //         top={top}
  //     />
  //     ),
  //     [selectAllPhotos]
  // );

  return (
    <div dir={direction}>
      <PageContainer className={classes.root}>
        <CssBaseline />

        <Container
          component="div"
          className={classes.imgCarousel}
          maxWidth="lg"
        >
          {/* progressFlag &&
                <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
                </div>
            */}

          <ImageGallery
            ref={imageCarouselRef}
            items={imageItems}
            lazyLoad={lazyLoadFlag}
            onClick={onClick}
            onImageLoad={onImageLoad}
            onSlide={onSlide}
            onBeforeSlide={onBeforeSlide}
            onPause={onPause}
            onScreenChange={onScreenChange}
            onPlay={onPlay}
            infinite={infiniteFlag}
            showBullets={showBulletsFlag}
            showFullscreenButton={
              showFullscreenButtonFlag && showGalleryFullscreenButtonFlag
            }
            showPlayButton={showPlayButtonFlag && showGalleryPlayButtonFlag}
            showThumbnails={showThumbnailsFlag}
            showIndex={showIndexFlag}
            showNav={showNavFlag}
            isRTL={isRTL}
            thumbnailPosition={thumbnailPosition} // bottom
            slideDuration={parseInt(slideDuration)} // 450
            slideInterval={parseInt(slideInterval)} // 2000
            slideOnThumbnailOver={slideOnThumbnailOverFlag}
            // additionalClass="app-image-gallery"
          />
        </Container>
      </PageContainer>
    </div>
  );
}

export default ImageCarousel;
