import { Cache } from "aws-amplify";
import CacheConfig from "./CacheConfig";

// Removes all of the items in the cache
const ClearCacheItems = (key) => {
  let cache = Cache.createInstance(CacheConfig);
  return cache.clear(key);
};

export default ClearCacheItems;
