import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
// import styled, { css } from 'styled-components';
import { makeStyles } from "@material-ui/core/styles";
// import Button from '@material-ui/core/Button';
import CssBaseline from "@material-ui/core/CssBaseline";
// import { NavLink } from 'react-router-dom';
// import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

// Froala Editor
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// AppContext
import AppContext from "../../config/AppContext";

Amplify.configure(AmplifyConfig);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    backgroundColor: "#fff",
    // backgroundImage: `url(${'assets/logos/background21.png'})`,
  },
  progress: {
    margin: theme.spacing(1),
  },
  pageContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mainGrid: {
    textAlign: "center",
    display: "flex",
  },
  mainForm: {
    textAlign: "center",
    display: "flex",
  },
  titleGrid: {
    textAlign: "center",
  },
  title: {
    color: "#000",
    textAlign: "center",
  },
  subTitle: {
    color: "#000",
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  titleContent: {
    color: "#000",
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  bodyGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: "#fff",
  },
  bodyLC: {
    color: "#000",
    textAlign: "left",
    background: "#fff",
  },
  bodyContent: {
    color: "#000",
    textAlign: "left",
    background: "#fff",
  },
  bodyRC: {
    color: "#000",
    textAlign: "left",
    background: "#fff",
  },
  footerGrid: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    background: "#fff",
  },
  footerTC: {
    color: "#000",
    textAlign: "left",
    marginTop: theme.spacing(1),
    background: "#fff",
  },
  footerMiddleContent: {
    color: "#000",
    textAlign: "left",
    marginTop: theme.spacing(1),
    background: "#fff",
  },
  footerBottomContent: {
    color: "#000",
    textAlign: "left",
    marginTop: theme.spacing(1),
    background: "#fff",
  },
  textItem: {
    color: "#000",
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    backgroundColor: "#fff",
    textAlign: "center",
  },
}));

let privacy = {
  privacyTitle: "Privacy policy",
  privacySubTitle: "Sub title",
  privacyTC: "Top content",
  privacyLC: "Left content",
  privacyBC: "Body content",
  privacyRC: "Right content",
  privacyFCT: "Footer top",
  privacyFCM: "Footer middle",
  privacyFCB: "Footer bottom",
};

function Privacy(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  // Page Column Format
  // 'leftBodyRight' = render left, body and right of page
  // 'bodyOnly' = render only body of page
  // 'leftAndBody' = render left column and body of page
  // 'bodyAndRight' = render body and right column of page
  const [pageColumnFormat, setPageColumnFormat] = useState("leftBodyRight");
  const [containerMaxWidth, setContainerMaxWidth] = useState("lg"); // xl, lg, md, sm, xs

  // For Products
  const [privacyRes, setPrivacyRes] = useState(privacy);
  const [pageContentData, setPageContentData] = useState({});

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  const classes = useStyles();

  const {
    privacyTitle,
    privacyTC,
    privacySubTitle,
    privacyLC,
    privacyBC,
    privacyRC,
    privacyFCT,
    privacyFCM,
    privacyFCB,
  } = privacyRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(privacy);
    // console.log("In Privacy(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In Privacy(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "privacyTitle")
              privacy.privacyTitle = item.ResourceDesc;
            if (item.ResourceCode === "privacySubTitle")
              privacy.privacySubTitle = item.ResourceDesc;
            if (item.ResourceCode === "privacyTC")
              privacy.privacyTC = item.ResourceDesc;
            if (item.ResourceCode === "privacyLC")
              privacy.privacyLC = item.ResourceDesc;
            if (item.ResourceCode === "privacyBC")
              privacy.privacyBC = item.ResourceDesc;
            if (item.ResourceCode === "privacyRC")
              privacy.privacyRC = item.ResourceDesc;
            if (item.ResourceCode === "privacyFCT")
              privacy.privacyFCT = item.ResourceDesc;
            if (item.ResourceCode === "privacyFCM")
              privacy.privacyFCM = item.ResourceDesc;
            if (item.ResourceCode === "privacyFCB")
              privacy.privacyFCB = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, privacy);
        }
        setPrivacyRes(privacy);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setPrivacyRes(privacy);
        setProgressFlag(false);
      });
  }

  async function fetchPageContent() {
    const pageName = "policy";
    // console.log('In fetchPageContent(): appContext.domain is: ', appContext.domain);
    // console.log('In fetchPageContent(): pageName is: ', pageName);
    console.log(
      "In fetchPageContent(): currentLanguage.languageCode is: ",
      currentLanguage.languageCode
    );

    setProgressFlag(true);

    let arrayData = [];

    let params = {
      apiName: "PagesAPI",
      path: "/dynamodb/pages/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#pagePK = :pagePK AND begins_with(#pageSK, :pageSK)",
        expressionAttributeNames: { "#pagePK": "PagePK", "#pageSK": "PageSK" },
        expressionAttributeValues: {
          ":pagePK": appContext.domain + "#" + pageName,
          ":pageSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    console.log(
      "In fetchPageContent(): initObj is: ",
      JSON.stringify(initObj, null, 2)
    );

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchPageContent(): response length is: ', response.data.Items.length);
        console.log(
          "In fetchPageContent(): response is: ",
          JSON.stringify(response.data.Items, null, 2)
        );
        // let langCode = currentLanguage.languageCode;
        // switch(currentLanguage.languageCode) {
        //   case 'zh':
        //     langCode = "zh-cn";
        //     break;
        //   default:
        //     langCode = currentLanguage.languageCode;
        //     break;
        // };
        // response.data.Items.map((item, index) => {
        // //   let startDate = new moment(item.StartDate).locale(langCode);
        // //   let strStartDate = startDate.format('L');

        // //   let endDate = new moment(item.EndDate).locale(langCode);
        // //   let strEndDate = endDate.format('L');

        //     let record = {
        //     id: ""+index,
        //     PagePK: item.PagePK,
        //     PageSK: item.PageSK,

        //     LanguageCode: item.LanguageCode,

        //     StartDate: item.StartDate,
        //     EndDate: item.EndDate,

        //     // StartDate: strStartDate,
        //     // EndDate: strEndDate,

        //     PageHeader: item.PageHeader,
        //     PageSubHeader: item.PageSubHeader,
        //     PageContent: item.PageContent,

        //     ModifiedBy: item.ModifiedBy,
        //     CreatedAt: item.CreatedAt,
        //     UpdatedAt: item.UpdatedAt,
        //     };

        //     arrayData.push(record);
        // });

        if (response.data.Items && response.data.Items.length > 0) {
          setPageContentData(response.data.Items[0]);
        }

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchPageContent(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In Privacy(): useEffect()");
    fetchPageContent();
  }, []);

  useLayoutEffect(() => {
    // console.log("In Privacy(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "privacy";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In Privacy(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In Privacy(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In Privacy(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In Privacy(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In Privacy(): useLayoutEffect(): got resource from cache');
      setPrivacyRes(cacheItem);
    }
  }, []);

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { !appContext.signedIn &&
                    <Redirect to='/signIn' />
                } */}

        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container
          component="div"
          className={classes.pageContainer}
          maxWidth={containerMaxWidth}
        >
          {/* progressFlag &&
                        <div style={{textAlign: 'center'}}>
                            <CircularProgress className={classes.progress} color="secondary" size={15}/>
                        </div>
                    */}

          <Grid container className={classes.mainGrid} justify="center">
            <Grid
              container
              item
              xs={12}
              sm={8}
              className={classes.mainGrid}
              justify="center"
            >
              <Grid
                container
                item
                xs={12}
                spacing={3}
                justify="center"
                direction="column"
              >
                {pageContentData !== null &&
                  pageContentData.PageHeader !== null && (
                    <FroalaEditorView model={pageContentData.PageHeader} />
                  )}

                {pageContentData !== null &&
                  pageContentData.PageSubHeader !== null && (
                    <FroalaEditorView model={pageContentData.PageSubHeader} />
                  )}

                {pageContentData !== null &&
                  pageContentData.PageContent !== null && (
                    <FroalaEditorView model={pageContentData.PageContent} />
                  )}
              </Grid>
            </Grid>

            {/* "flex-start", "center", "flex-end" */}
            <Grid
              container
              item
              xs={12}
              sm={4}
              justify="center"
              alignItems="flex-start"
              direction="row"
            >
              {pageContentData !== null &&
                pageContentData.PageSHeader !== null && (
                  <FroalaEditorView model={pageContentData.PageHeader} />
                )}
            </Grid>
          </Grid>
        </Container>

        <footer className={classes.footer}>
          <PageFooter />
        </footer>
      </div>
    </div>
  );
}

export default Privacy;
