// import Amplify from 'aws-amplify';
// import { Auth, API } from 'aws-amplify';

// import AmplifyConfig from "./AmplifyConfig";
import Config from "./Config";

export default {
  Auth: {
    // cloudcms.ws

    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: Config.us_east_1.cognito.ccms.federatedIdentityPoolId,

    // REQUIRED - Amazon Cognito Region
    region: Config.us_east_1.cognito.ccms.region,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: Config.us_east_1.cognito.ccms.userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: Config.us_east_1.cognito.ccms.appClientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: Config.us_east_1.cognito.ccms.mandatorySignIn,
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Configuration for cookie storage
    //   cookieStorage: {
    //       // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    //       domain: Config.us_east_1.cookieStorage.ccms.domain,

    //       // OPTIONAL - Cookie path
    //       path: Config.us_east_1.cookieStorage.ccms.path,

    //       // OPTIONAL - Cookie expiration in days
    //       expires: Config.us_east_1.cookieStorage.ccms.expires,

    //       // OPTIONAL - Cookie secure flag
    //       secure: Config.us_east_1.cookieStorage.ccms.secure
    //   }
  },
  Storage: {
    // Amplify Storage
    AWSS3: {
      bucket: "tsi.storage", //REQUIRED -  Amazon S3 bucket
      region: "us-east-1", //OPTIONAL -  Amazon service region
      identityPoolId: Config.us_east_1.cognito.ccms.federatedIdentityPoolId //Specify your identityPoolId for Auth and Unauth access to your bucket;
    },
  },
  API: {
    endpoints: [
      {
        name: "CommonDynamodbAPI", // common-dynamodb-api (can be used with any table -- pass in the table during calls)
        endpoint: "https://112xvxl9a8.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "ResourceAPI",
        endpoint: "https://led3awag6l.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "PagesAPI",
        endpoint: "https://b3ss4uy8i2.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "GoogleTranslateAPI",
        endpoint: "https://uwf703v1vg.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "TranslateResourcesAPI",
        endpoint: "https://tvj586lgta.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "TranslateSocialsAPI",
        endpoint: "https://masr5c70y2.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "TranslateReferenceDataAPI",
        endpoint: "https://fpbadn33h0.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "TranslateReferenceTypesAPI",
        endpoint: "https://ccudao7kf1.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "TranslateContactInfoAPI",
        endpoint: "https://xwbubrqvr6.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "TranslateLanguagesAPI",
        endpoint: "https://sn7d09j3z9.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "TranslatePageContentAPI",
        endpoint: "https://di4cgue18l.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "DeletePageContentAPI",
        endpoint: "https://dev",
      },
      {
        name: "DesignAPI",
        endpoint: "https://oiffl6rj6k.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "TranslateDesignAPI",
        endpoint: "https://punkk5wpgb.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "TranslateDesignBlockDetailsAPI",
        endpoint: "https://repq0kq1pc.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "AwsTranslateAPI",
        endpoint: "https://natzkq8oxl.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "CobAPI",
        endpoint: "https://7zr1f2geo5.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "BlogAPI",
        endpoint: "https://hril0mjcw9.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "PriceAPI",
        endpoint: "https://eqce10t2kg.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "FaqAPI",
        endpoint: "https://uy7qbcjkik.execute-api.us-east-1.amazonaws.com/dev",
      },
      {
        name: "MediaAPI",
        endpoint: "https://tt9fqgalr5.execute-api.us-east-1.amazonaws.com/dev",
      }
      
      // {
      //   name: "MyAPIGatewayAPI",
      //   endpoint: Config.us_east_1.apiGateway.ccms.url
      // },
    ],
  },
};

// Amplify.configure(AmplifyConfig);
