import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

// AppContext
import AppContext from "../../config/AppContext";

Amplify.configure(AmplifyConfig);

const PageContainer = styled.div`
  width: 100%,
  display: 'flex',
  flexWrap: 'wrap',
  background: #fff;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
  },
  progress: {
    margin: theme.spacing(1),
  },
  mainGrid: {
    textAlign: "left",
  },
  contactUs: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  hdr: {
    color: "#fff",
  },
  prodDesc: {
    color: "fff",
    fontSize: "12px",
    marginLeft: theme.spacing(1),
    whiteSpace: "nowrap",
  },
  link: {
    color: "#fff",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  textItem: {
    color: "#fff",
    whiteSpace: "nowrap",
  },
}));

let contactUs = {
  contactUsHdr: "Contact us",
  contactUsDesc: "Our contact information",
  contactUs1: "Sales inquiry",
  contactUs2: "Support services",
  contactUs3: "FAQs",
  contactUs4: "Contact us form",
  contactUs5: "ContactUs5",
};

function ContactUsFooter(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  // For ContactUs
  const [contactUsRes, setContactUsRes] = useState(contactUs);

  const { currLanguage } = props;

  const classes = useStyles();

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  const {
    contactUsHdr,
    contactUsDesc,
    contactUs1,
    contactUs2,
    contactUs3,
    contactUs4,
    contactUs5,
  } = contactUsRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(contactUs);
    // console.log("In ContactUsFooter(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ContactUsFooter(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "contactUsHdr")
              contactUs.contactUsHdr = item.ResourceDesc;
            if (item.ResourceCode === "contactUsDesc")
              contactUs.contactUsDesc = item.ResourceDesc;
            if (item.ResourceCode === "contactUs1")
              contactUs.contactUs1 = item.ResourceDesc;
            if (item.ResourceCode === "contactUs2")
              contactUs.contactUs2 = item.ResourceDesc;
            if (item.ResourceCode === "contactUs3")
              contactUs.contactUs3 = item.ResourceDesc;
            if (item.ResourceCode === "contactUs4")
              contactUs.contactUs4 = item.ResourceDesc;
            if (item.ResourceCode === "contactUs5")
              contactUs.contactUs5 = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, contactUs);
          setContactUsRes(contactUs);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setContactUsRes(contactUs);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In ContactUsFooter(): useEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));

    // currLanguage is the most current language passed to this component through props
    // We are using useEffect to get the functionality of componentDidUpdate()
    if (
      currLanguage !== undefined &&
      currLanguage !== null &&
      currLanguage !== currentLanguage
    ) {
      setCurrentLanguage(currLanguage);
      appContext.currentLanguage = currLanguage;

      // console.log('In ContactUsFooter(): useEffect(): about to call fetchDbResources()');

      // Fetch resource from catche
      let cacheItemKey = "contactUs";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      // cacheItemKey += currLanguage.languageCode;
      // console.log('In ContactUsFooter(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        // console.log("In ContactUsFooter(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
        RemoveCacheItem(cacheItemKey);
      }

      let cacheItem = GetCacheItem(cacheItemKey);
      // console.log('In ContactUsFooter(): useLayoutEffect(): cacheItem is: ', cacheItem);

      if (cacheItem === null) {
        // resource is not in cache, fetch from DB
        // console.log('In ContactUsFooter(): useLayoutEffect(): resource is not in cache, fetch from DB');
        fetchDbResources(cacheItemKey);
      } else {
        // console.log('In ContactUsFooter(): useLayoutEffect(): got resource from cache');
        setContactUsRes(cacheItem);
      }
    }
  });

  useLayoutEffect(() => {
    // console.log("In ContactUsFooter(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "contactUs";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ContactUsFooter(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ContactUsFooter(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ContactUsFooter(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ContactUsFooter(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ContactUsFooter(): useLayoutEffect(): got resource from cache');
      setContactUsRes(cacheItem);
    }
  }, []);

  return (
    <div dir={direction}>
      <PageContainer className={classes.root}>
        <CssBaseline />

        <Container component="div" className={classes.contactUs} maxWidth="lg">
          {progressFlag && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress
                className={classes.progress}
                color="secondary"
                size={15}
              />
            </div>
          )}
          <Grid container className={classes.mainGrid}>
            <Grid item xs={12} className={classes.hdr}>
              <Typography variant="button" gutterBottom>
                {contactUsHdr}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.prodDesc}>
              <Typography variant="caption" gutterBottom>
                {contactUsDesc}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/contactUs1">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {contactUs1}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/contactUs2">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {contactUs2}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/contactUs3">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {contactUs3}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/contactUs4">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {contactUs4}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/contactUs5">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {contactUs5}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </PageContainer>
    </div>
  );
}

export default ContactUsFooter;
