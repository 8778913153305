export default {
  userName: "",
  signedIn: false,
  jwtToken: "",
  mandatorySignIn: true,

  cache: {
    // For window.localStorage
    keyPrefix: "tsi", // The ID of Cache which can only be configured when creating new instance
    capacityInBytes: 5 * 1024, // Max size of Cache in bytes. By default is 1MB and has a maximum of 5MB
    itemMaxSize: 5 * 1024, // Max size of individual item which can be set into Cache in bytes. The default value is 200KB
    defaultTTL: 365 * 24 * 60 * 60 * 60, // TTL for the cache items in milliseconds. The default value is 72 hours.
    defaultPriority: 1, // Default priority of the cache items. The default value is 5, the highest priority is 1.
    warningThreshold: 0.9, // This is for keeping Cache’s current capacity in a reasonable level
    // The default is 0.8, which sets warnings for 80% of space usage.
    storage: window.localStorage, // The storage medium that will be used for the Cache. Supported values are
    // window.localStorage(default) and window.sessionStorage for Web development and AsyncStorage for React Native.

    // For window.sessionStorage
    wss: {
      keyPrefix: "tsi", // The ID of Cache which can only be configured when creating new instance
      capacityInBytes: 5 * 1024, // Max size of Cache in bytes. By default is 1MB and has a maximum of 5MB
      itemMaxSize: 5 * 1024, // Max size of individual item which can be set into Cache in bytes. The default value is 200KB
      defaultTTL: 365 * 24 * 60 * 60 * 60, // TTL for the cache items in milliseconds. The default value is 72 hours.
      defaultPriority: 1, // Default priority of the cache items. The default value is 5, the highest priority is 1.
      warningThreshold: 0.9, // This is for keeping Cache’s current capacity in a reasonable level
      // The default is 0.8, which sets warnings for 80% of space usage.
      storage: window.sessionStorage, // The storage medium that will be used for the Cache. Supported values are
      // window.localStorage(default) and window.sessionStorage for Web development and AsyncStorage for React Native.
    },
    pac: {
      keyPrefix: "pac", // The ID of Cache which can only be configured when creating new instance
      capacityInBytes: 5 * 1024, // Max size of Cache in bytes. By default is 1MB and has a maximum of 5MB
      itemMaxSize: 5 * 1024, // Max size of individual item which can be set into Cache in bytes. The default value is 200KB
      defaultTTL: 365 * 24 * 60 * 60 * 60, // TTL for the cache items in milliseconds. The default value is 72 hours.
      defaultPriority: 1, // Default priority of the cache items. The default value is 5, the highest priority is 1.
      warningThreshold: 0.9, // This is for keeping Cache’s current capacity in a reasonable level
      // The default is 0.8, which sets warnings for 80% of space usage.
      storage: window.localStorage, // The storage medium that will be used for the Cache. Supported values are
      // window.localStorage(default) and window.sessionStorage for Web development and AsyncStorage for React Native.
    },
    ccms: {
      keyPrefix: "ccms", // The ID of Cache which can only be configured when creating new instance
      capacityInBytes: 5 * 1024, // Max size of Cache in bytes. By default is 1MB and has a maximum of 5MB
      itemMaxSize: 5 * 1024, // Max size of individual item which can be set into Cache in bytes. The default value is 200KB
      defaultTTL: 365 * 24 * 60 * 60 * 60, // TTL for the cache items in milliseconds. The default value is 72 hours.
      defaultPriority: 1, // Default priority of the cache items. The default value is 5, the highest priority is 1.
      warningThreshold: 0.9, // This is for keeping Cache’s current capacity in a reasonable level
      // The default is 0.8, which sets warnings for 80% of space usage.
      storage: window.localStorage, // The storage medium that will be used for the Cache. Supported values are
      // window.localStorage(default) and window.sessionStorage for Web development and AsyncStorage for React Native.
    },
  },

  us_east_1: {
    region: "us-east-1",
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
      BUCKET: "notes-app-uploads",
    },
    apiGateway: {
      //dev-dynamicHtml
      URL: "https://d23kr2cdnj.execute-api.us-east-1.amazonaws.com/dev",
      // CognitoAPIGWAngular
      //URL: "https://b7j2zkbij8.execute-api.us-east-1.amazonaws.com/demo",

      pac: {
        url: "",
        braintree: {
          // See braintree-api
          url: "https://s7exsppuu8.execute-api.us-east-1.amazonaws.com/dev",
          //url: "https://6kcgglpzne.execute-api.us-east-1.amazonaws.com/dev"
          //url: "https://b7j2zkbij8.execute-api.us-east-1.amazonaws.com/demo" // CognitoAPIGWAngular
        },
        crud: {
          url: "https://8zykg5hwk4.execute-api.us-east-1.amazonaws.com/dev", // for crud-api
          //url: "https://l6fgdt6yn6.execute-api.us-east-1.amazonaws.com/dev" // for dynamodbCrud
        },
      },
      ccms: {
        url: "",
      },
    },
    cognito: {
      //REGION: "us-east-1",
      //USER_POOL_ID: "us-east-1_NOMcHMfyv",// CognitoAPIGWAngular
      //APP_CLIENT_ID: "8d85abdtmonvgumc7jvj776aa",
      //FEDERATED_IDENTITY_POOL_ID: "us-east-1:ceef8ccc-0a19-4616-9067-854dc69c2d82",
      //IDENTITY_POOL_ID: "us-east-1:df90185a-f4d4-40f9-9573-3ecd38cd3d23",

      ccms: {
        // cloudcms.ws
        region: "us-east-1",
        userPoolId: "us-east-1_vllH1y2br", // TsiUserPool
        arn:
          "arn:aws:cognito-idp:us-east-1:841420124267:userpool/us-east-1_vllH1y2br", // TsiUserPool ARN
        federatedIdentityPoolId:
          "us-east-1:02adbc2b-a9e2-4699-8915-71c5c30b7d8a", // TsiIdentityPool
        appClientId: "2arjgqgsthvliufsmu7oluh5t7", // cloudcms.ws - "2arjgqgsthvliufsmu7oluh5t7" and cloudobituary.com - "30v5mpocde275nl669fc9em84h"
        mandatorySignIn: true,
      },
      pac: {
        // payandcash.com
        region: "us-east-1",
        userPoolId: "us-east-1_vllH1y2br", // TsiUserPool
        arn:
          "arn:aws:cognito-idp:us-east-1:841420124267:userpool/us-east-1_vllH1y2br", // TsiUserPool ARN
        federatedIdentityPoolId:
          "us-east-1:02adbc2b-a9e2-4699-8915-71c5c30b7d8a", // TsiIdentityPool
        appClientId: "7ukqkqd6ql5fqgivsiv90saufp", // payandcash.com
        mandatorySignIn: true,
      },
      cob: {
        // cloudobituary.com
        region: "us-east-1",
        userPoolId: "us-east-1_vllH1y2br", // TsiUserPool
        arn:
          "arn:aws:cognito-idp:us-east-1:841420124267:userpool/us-east-1_vllH1y2br", // TsiUserPool ARN
        federatedIdentityPoolId:
          "us-east-1:02adbc2b-a9e2-4699-8915-71c5c30b7d8a", // TsiIdentityPool
        appClientId: "30v5mpocde275nl669fc9em84h", // cloudobituary.com
        mandatorySignIn: true,
      },
    },
    cookieStorage: {
      //domain: ".tobisoft.co",
      //path: '/',
      //expires: 365,
      //secure: true,

      pac: {
        domain: ".payandcash.com",
        path: "/",
        expires: 365,
        secure: true,
      },
      ccms: {
        domain: ".cloud1cms.com",
        path: "/",
        expires: 365,
        secure: true,
      },
    },
    storage: {
      // Amplify Storage
      bucket: "tsi.storage", //REQUIRED -  Amazon S3 bucket
      region: "us-east-1", //OPTIONAL -  Amazon service region
    },
    iot: {
      // Amplify IoT
      pubsub: {
        region: "us-east-1", // Amazon service region
        endpoint: "wss://a24j4l25ltzuvo.iot.us-east-1.amazonaws.com", // wss://xxxxxxxxxxxxx.iot.<YOUR-AWS-REGION>.amazonaws.com/mqtt
      },
    },
    sqs: {
      // SQS
      MyLambdaSqsQueue: {
        url:
          "https://sqs.us-east-1.amazonaws.com/841420124267/MyLambdaSqsQueue",
        region: "us-east-1", // Amazon service region
      },
    },
    rekognition: {
      // Rekognition
      region: "us-east-1",
      collectionId: "98738855",
    },

    api_endpoints: {
      resources: {
        dev: {
          getItem:
            "https://led3awag6l.execute-api.us-east-1.amazonaws.com/dev/dynamodb/resources/getItem",
          scanItems:
            "https://led3awag6l.execute-api.us-east-1.amazonaws.com/dev/dynamodb/resources/scanItems",
        },
      },
    },
  },

  tableNames: {
    languageTable: "language",
    resourceTable: "resource",
  },
};
