import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
// import { Redirect } from 'react-router-dom';
import styled, { css } from "styled-components";
// import { NavLink } from 'react-router-dom';
// import Link from '@material-ui/core/Link';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// import TextField from '@material-ui/core/TextField';
// import IconButton from '@material-ui/core/IconButton';
import Input from "@material-ui/core/Input";
// import InputLabel from '@material-ui/core/InputLabel';
// import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
//import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

//import GlobalConstants from '../../utils/GlobalConstants';
import SetCacheItem from "../../utils/cache/SetCacheItem";
// import ClearCacheItems from '../../utils/cache/ClearCacheItems';
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import SignIn from "./SignIn";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import GlobalInfoMessage from "../../components/dialogs/GlobalInfoMessage";
import LineSpacing from "../../components/misc/LineSpacing";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 4px 0 4px 0 !important;
`;

const StyledButton = styled(Button)`
  background: transparent;
  border-radius: 3px;
  border: 2px solid palevioletred;
  color: palevioletred;
  margin-bottom: 12px !important;

  /* When the "primary" property is set we want to add some more css to our component */
  /* in this case change the background and color. */

  ${(props) =>
    props.color === "primary" &&
    css`
      background: green !important;
      color: white !important;
      margin: 0 0 12px 20px !important;
    `};
`;

const StyledTypography = styled(Typography)`
  text-align: right !important;
  color: green;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
  },
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    backgroundColor: "#fff",
  },
  mainForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

let confirmAttrCode = {
  verificationCodeHdr: "Verify attribute code",
  verificationCodeSubHdr: "Let's verify the code you received.",
  verificationCodeLabel: "Enter verification code",
  verifyAttributeCodeLabel: "Verify code",
  // cancel: "Cancel",
  // enterVerificationCode: "Please enter verification code",
  enterReceivedVeriCode: "Please enter verification code you received",
  resendCode: "Resend verification code",
  verificationCodeSent: "We just sent you a new verification code.",
  alreadyConfirmed: "You are already confirmed.",
  limitTrialExceeded: "Attempt limit exceeded, please try later.",
  codeMismatch: "Sorry!!! you entered the wrong code.",
  emailVerified: "Great, we have verified your email address.",
  phoneVerified: "Great, we have verified your phone number.",
  emailVerifyingCode: "We have just sent you an email with a verifying code.",
};

function ConfirmAttributeCode(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const [confirmAttrCodeRes, setConfirmAttrCodeRes] = useState(confirmAttrCode);

  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  const [progressFlag, setProgressFlag] = useState(false);

  const [verificationCode, setVerificationCode] = useState("");
  const [verifyAttributeCode, setVerifyAttributeCode] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  const [resendCodeFlag, setResendCodeFlag] = useState(false);

  // For Error Flags
  const [verificationCodeErrFlag, setVerificationCodeErrFlag] = useState(false);
  const [verificationCodeFocus, setVerificationCodeFocus] = useState(false);

  // const { currentLanguage, isSignedIn } = props;
  const classes = useStyles();

  const {
    verificationCodeHdr,
    verificationCodeSubHdr,
    verificationCodeLabel,
    verifyAttributeCodeLabel,
    // cancel,
    // enterVerificationCode,
    enterReceivedVeriCode,
    resendCode,
    verificationCodeSent,
    alreadyConfirmed,
    limitTrialExceeded,
    codeMismatch,
    emailVerified,
    phoneVerified,
    emailVerifyingCode,
  } = confirmAttrCodeRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(confirmAttrCode);
    // console.log("In ConfirmAttributeCode(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ConfirmAttributeCode(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
        // "queryType": "hash-query",
        // "resourcePK": currentLanguage.languageCode,
        // "keyConditionExpression": "#resourcePK = :resourcePK",
        // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
        // "expressionAttributeValues": {":resourcePK": currentLanguage.languageCode}
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        console.log(
          "In fetchDbResources(): response length is: ",
          response.data.Responses.resource.length
        );
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            // id: ""+index,
            // ResourceLanguageCode: item.ResourceLanguageCode,
            // ResourcePK: item.ResourcePK,
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
            // ModifiedBy: item.ModifiedBy,
            // CreatedAt: item.CreatedAt,
            // UpdatedAt: item.UpdatedAt,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "verificationCodeHdr")
              confirmAttrCode.verificationCodeHdr = item.ResourceDesc;
            if (item.ResourceCode === "verificationCodeSubHdr")
              confirmAttrCode.verificationCodeSubHdr = item.ResourceDesc;
            if (item.ResourceCode === "verificationCodeLabel")
              confirmAttrCode.verificationCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "verifyAttributeCodeLabel")
              confirmAttrCode.verifyAttributeCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "enterReceivedVeriCode")
              confirmAttrCode.enterReceivedVeriCode = item.ResourceDesc;
            if (item.ResourceCode === "resendCode")
              confirmAttrCode.resendCode = item.ResourceDesc;
            if (item.ResourceCode === "verificationCodeSent")
              confirmAttrCode.verificationCodeSent = item.ResourceDesc;
            if (item.ResourceCode === "alreadyConfirmed")
              confirmAttrCode.alreadyConfirmed = item.ResourceDesc;
            if (item.ResourceCode === "limitTrialExceeded")
              confirmAttrCode.limitTrialExceeded = item.ResourceDesc;
            if (item.ResourceCode === "codeMismatch")
              confirmAttrCode.codeMismatch = item.ResourceDesc;
            if (item.ResourceCode === "emailVerified")
              confirmAttrCode.emailVerified = item.ResourceDesc;
            if (item.ResourceCode === "phoneVerified")
              confirmAttrCode.phoneVerified = item.ResourceDesc;
            if (item.ResourceCode === "emailVerifyingCode")
              confirmAttrCode.emailVerifyingCode = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, confirmAttrCode);
          setConfirmAttrCodeRes(confirmAttrCode);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setConfirmAttrCodeRes(confirmAttrCode);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    setVerificationCodeFocus(true);
  }, []);

  useLayoutEffect(() => {
    console.log("In ConfirmAttributeCode(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "confirmAttrCode";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    console.log(
      "In ConfirmAttributeCode(): useLayoutEffect(): cacheItemKey is: ",
      cacheItemKey
    );

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      console.log(
        "In ConfirmAttributeCode(): useLayoutEffect(): removing cacheItem for ",
        appContext.currentLanguage.languageCode
      );
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    console.log(
      "In ConfirmAttributeCode(): useLayoutEffect(): cacheItem is: ",
      cacheItem
    );

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      console.log(
        "In ConfirmAttributeCode(): useLayoutEffect(): resource is not in cache, fetch from DB"
      );
      fetchDbResources(cacheItemKey);
    } else {
      console.log(
        "In ConfirmAttributeCode(): useLayoutEffect(): got resource from cache"
      );
      setConfirmAttrCodeRes(cacheItem);
    }

    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In ConfirmAttributeCode(): useLayoutEffect(): currentLanguage is: ', JSON.stringify(currentLanguage, null, 2));
    // console.log('In ConfirmAttributeCode(): useLayoutEffect(): direction is: ', direction);
    // console.log('In ConfirmAttributeCode(): useLayoutEffect(): appContext.signedIn is: ', appContext.signedIn);
  }, []);

  function handleChange(event) {
    //console.log("In handleChange(): id is: "+event.target.id);
    //console.log("In handleChange(): tagName is: "+event.target.tagName);
    //console.log("In handleChange(): value is: "+event.target.value);

    // Clear Flags
    setGlobalErrorFlag(false);

    switch (event.target.id) {
      case "verificationCodeId":
        setResendCodeFlag(false);
        setVerificationCodeErrFlag(false);
        setVerificationCode(event.target.value);
        break;
      default:
        setVerificationCode("");
        break;
    }
  }

  function validateForm() {
    let formErrFlag = false;

    setVerificationCodeErrFlag(false);

    if (verificationCode === null || verificationCode === "") {
      formErrFlag = true;
      setVerificationCodeErrFlag(true);
    }

    return formErrFlag;
  }

  async function confirmAttributeService() {
    const { type, attribute } = props;
    // const { codeMismatch, emailVerified, phoneVerified } = msg;

    console.log(
      "In confirmAttributeService(): verificationCode is: ",
      verificationCode
    );
    console.log("In confirmAttributeService(): type is: ", type);
    console.log("In confirmAttributeService(): attribute is: ", attribute);

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");
    setVerifyAttributeCode(false);

    // await SetCacheItem("isSignedIn", true);
    // await console.log("In confirmAttributeService(): isSignedIn is: "+GetCacheItem("isSignedIn"));

    if (!validateForm()) {
      // Validation Passed - you can proceed
      console.log("Validation Passed.");
      setProgressFlag(true);

      Auth.verifyCurrentUserAttributeSubmit(attribute, verificationCode)
        .then((result) => {
          console.log(
            "In confirmAttributeService(): result is",
            JSON.stringify(result, null, 2)
          );
          setVerifyAttributeCode(true);
          setProgressFlag(false);
          if (type === "email") {
            console.log(emailVerified);
          } else {
            console.log(phoneVerified);
          }
        })
        .catch((err) => {
          console.log(
            "In confirmAttributeService(): err is",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setProgressFlag(false);
          switch (err.code) {
            case "CodeMismatchException":
              setGlobalErrorMsg(codeMismatch);
              break;
            default:
              setGlobalErrorMsg(codeMismatch);
              break;
          }
        });
    }
  }

  async function resendVerificationCode() {
    const { username } = props;
    // const { alreadyConfirmed } = msg;
    // const { limitTrialExceeded } = msg;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");
    setResendCodeFlag(false);

    Auth.resendSignUp(username)
      .then(() => {
        setResendCodeFlag(true);
        setVerificationCode("");
      })
      .catch((err) => {
        console.log(
          "In resendVerificationCode(): err is",
          JSON.stringify(err, null, 2)
        );
        setGlobalErrorFlag(true);
        switch (err.code) {
          case "InvalidParameterException":
            setGlobalErrorMsg(alreadyConfirmed);
            break;
          case "LimitExceededException":
            setGlobalErrorMsg(limitTrialExceeded);
            break;
          default:
            break;
        }
      });
  }

  function snackbarOnClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  }

  return (
    <div dir={direction}>
      <div className={classes.root}>
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div
            className="ag-theme-balham"
            style={{ width: "100%", height: "100%" }}
          >
            {progressFlag && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                  size={15}
                />
              </div>
            )}
            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="md"
            >
              {verifyAttributeCode && <SignIn />}

              {!verifyAttributeCode && (
                <Grid container justify="center">
                  <StyledPaper elevation={3} className={classes.paper}>
                    <Typography
                      variant="h6"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      {verificationCodeHdr}
                    </Typography>

                    <Typography
                      variant="caption"
                      style={{ color: "green", textAlign: "center" }}
                    >
                      {verificationCodeSubHdr}
                    </Typography>

                    {globalErrorFlag && (
                      <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                    )}

                    <LineSpacing value={16} />

                    <StyledFormControl
                      fullWidth
                      error={verificationCodeErrFlag}
                    >
                      <Input
                        id="verificationCodeId"
                        disableUnderline={false}
                        placeholder={verificationCodeLabel}
                        type="text"
                        defaultValue={verificationCode}
                        autoFocus={verificationCodeFocus}
                        error={verificationCodeErrFlag}
                        onChange={handleChange}
                      />
                      {verificationCodeErrFlag && (
                        <FormHelperText id="component-error-text">
                          {enterReceivedVeriCode}
                        </FormHelperText>
                      )}
                    </StyledFormControl>

                    <Typography
                      component="p"
                      style={{
                        textAlign: "center",
                        marginTop: "12px",
                        marginBottom: "12px",
                      }}
                    >
                      {/* <Link component={ RouterLink } to="/">
                        <Typography variant="button" gutterBottom>{cancel}</Typography>
                      </Link> */}
                      <StyledButton
                        color="primary"
                        onClick={confirmAttributeService}
                      >
                        {verifyAttributeCodeLabel}
                      </StyledButton>
                    </Typography>

                    <Typography
                      component="p"
                      style={{
                        textAlign: "center",
                        marginTop: "4px",
                        marginBottom: "4px",
                      }}
                    >
                      {resendCodeFlag && (
                        <GlobalInfoMessage
                          globalInfoMsg={verificationCodeSent}
                          justify="center"
                          color="primary"
                        />
                      )}
                      {direction === "ltr" && (
                        <StyledTypography
                          variant="caption"
                          color="primary"
                          onClick={resendVerificationCode}
                          style={{ float: "right" }}
                        >
                          {resendCode}
                        </StyledTypography>
                      )}
                      {direction === "rtl" && (
                        <StyledTypography
                          variant="caption"
                          color="primary"
                          onClick={resendVerificationCode}
                          style={{ float: "left" }}
                        >
                          {resendCode}
                        </StyledTypography>
                      )}
                    </Typography>
                  </StyledPaper>
                </Grid>
              )}

              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={snackbarOnClose}
                ContentProps={{
                  "aria-describedby": "message-id",
                }}
                message={<span id="message-id">{emailVerifyingCode}</span>}
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="primary"
                    style={{ padding: "8px" }}
                    onClick={snackbarOnClose}
                  >
                    <CloseIcon />
                  </IconButton>,
                ]}
              />
            </Container>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ConfirmAttributeCode;
