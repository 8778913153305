import lodash from 'lodash';

const FilterArrayFromArray = (allArrayRecords, arrayRecordsToRemove) => {
    let filteredRecords = allArrayRecords;

    if (!lodash.isEmpty(arrayRecordsToRemove) && arrayRecordsToRemove.length > 0) {
        if (allArrayRecords.length > 0) {
            // Filter out arrayRecordsToRemove
            filteredRecords = allArrayRecords.filter(
                function(item) {
                    return !arrayRecordsToRemove.includes(item); 
                }
            );
        }
    }
    return filteredRecords
};
  
  export default FilterArrayFromArray;
  