import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled from "styled-components";
// import { Redirect } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import Amplify from "aws-amplify";
// import { Auth } from 'aws-amplify';
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// import SetCacheItem from '../../utils/cache/SetCacheItem';
// import ClearCacheItems from '../../utils/cache/ClearCacheItems';
// import GetCacheItem from '../../utils/cache/GetCacheItem';

Amplify.configure(AmplifyConfig);

const PageContainer = styled.div`
  width: 100%;
  background: #fff;
  align-items: center;
  margin: 0 0 250px 0;
`;

const Paragraph = styled.p`
  background: #fff;
  color: #4caf50;
  text-align: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    marginTop: "50px",
  },
}));

function TheOneTimePassword(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  // const [ isSignedIn, setIsSignedIn ] = useState(false);
  // const { currentLanguage, isSignedIn } = props;
  const classes = useStyles();

  useEffect(() => {}, []);

  useLayoutEffect(() => {
    // const currentLanguage = GetCacheItem("currentLanguage");
    console.log(
      "In ForgotPassword(): useLayoutEffect(): currentLanguage is: ",
      currentLanguage
    );

    console.log(
      "In ForgotPassword(): useLayoutEffect(): appContext.signedIn is: ",
      appContext.signedIn
    );
  }, []);

  return (
    <PageContainer className={classes.root}>
      <Container className={classes.container}>
        In The One Time Password (TOTP)
      </Container>
    </PageContainer>
  );
}

export default TheOneTimePassword;
