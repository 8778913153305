import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CssBaseline from "@material-ui/core/CssBaseline";
// import { Redirect } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
// import TextField from '@material-ui/core/TextField';

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Link from '@material-ui/core/Link';
// import Select from '@material-ui/core/Select';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import Select from 'react-select';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

// Phone
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Validator
// import validator from 'validator';
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";
import isMobilePhone from "validator/lib/isMobilePhone";
import contains from "validator/lib/contains";

// AG-Grid
import { AgGridReact, AgGridColumn } from "ag-grid-react";
//import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

// Amplify
import Amplify, { API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// import GlobalConstants from '../../utils/GlobalConstants';
// import GeneratePassword from '../../utils/GeneratePassword';
// import GenerateClientId from '../../utils/GenerateClientId';
// import GenerateTransactionId from '../../utils/GenerateTransactionId';
// import GenerateRandomHash from '../../utils/GenerateRandomHash';
// import GenerateModulusHash from '../../utils/GenerateModulusHash';
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import LineSpacing from "../../components/misc/LineSpacing";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// Renderers
import DateCellRenderer from "../../components/renderers/DateCellRenderer";
// import NumberCellRenderer from '../../components/renderers/NumberCellRenderer';
import ActionsCellRenderer from "../../components/renderers/ActionsCellRenderer";
// import { create } from 'jss';
// import CurrencyCellRenderer from '../../components/renderers/CurrencyCellRenderer';
// import CountryCellRenderer from '../../components/renderers/CountryCellRenderer';
import ImageCellRenderer from "../../components/renderers/ImageCellRenderer";

import SocialsDraggableDialog from "../../components/dialogs/SocialsDraggableDialog";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    backgroundImage: `url(${"assets/logos/background20.png"})`,
  },
  gridWidth: {
    width: "80%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(12),
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  textField: {
    width: "100%",
  },
  verticalAlignLabel: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  filterGrid: {
    textAlign: "left",
    marginBottom: "8px",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  textArea: {
    padding: theme.spacing(1),
    borderRadius: "5px",
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
    textAlign: "center !important",
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  browseIcon: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  phone: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "row",
  },
}));

let localeText = {
  page: "Page",
  to: "to",
  of: "of",
  noRowsToShow: "No records found",
};

// let localeText2 = {
//   page: "daPage",
//   more: "daMore",
//   to: "daTo",
//   of: "daOf",
//   next: "daNexten",
//   last: "daLasten",
//   first: "daFirsten",
//   previous: "daPreviousen",
//   loadingOoo: "daLoading...",
//   selectAll: "daSelect Allen",
//   searchOoo: "daSearch...",
//   blanks: "daBlanc",
//   filterOoo: "daFilter...",
//   applyFilter: "daApplyFilter...",
//   equals: "daEquals",
//   notEqual: "daNotEqual",
//   lessThan: "daLessThan",
//   greaterThan: "daGreaterThan",
//   lessThanOrEqual: "daLessThanOrEqual",
//   greaterThanOrEqual: "daGreaterThanOrEqual",
//   inRange: "daInRange",
//   contains: "daContains",
//   notContains: "daNotContains",
//   startsWith: "daStarts dawith",
//   endsWith: "daEnds dawith",
//   andCondition: "daAND",
//   orCondition: "daOR",
//   group: "laGroup",
//   columns: "laColumns",
//   filters: "laFilters",
//   rowGroupColumns: "laPivot Cols",
//   rowGroupColumnsEmptyMessage: "la drag cols to group",
//   valueColumns: "laValue Cols",
//   pivotMode: "laPivot-Mode",
//   groups: "laGroups",
//   values: "laValues",
//   pivots: "laPivots",
//   valueColumnsEmptyMessage: "la drag cols to aggregate",
//   pivotColumnsEmptyMessage: "la drag here to pivot",
//   toolPanelButton: "la tool panel",
//   noRowsToShow: "la no rows",
//   pinColumn: "laPin Column",
//   valueAggregation: "laValue Agg",
//   autosizeThiscolumn: "laAutosize Diz",
//   autosizeAllColumns: "laAutsoie em All",
//   groupBy: "laGroup by",
//   ungroupBy: "laUnGroup by",
//   resetColumns: "laReset Those Cols",
//   expandAll: "laOpen-em-up",
//   collapseAll: "laClose-em-up",
//   toolPanel: "laTool Panelo",
//   export: "laExporto",
//   csvExport: "la CSV Exportp",
//   excelExport: "la Excel Exporto (.xlsx)",
//   excelXmlExport: "la Excel Exporto (.xml)",
//   pinLeft: "laPin &lt;&lt;",
//   pinRight: "laPin &gt;&gt;",
//   noPin: "laDontPin &lt;&gt;",
//   sum: "laSum",
//   min: "laMin",
//   max: "laMax",
//   none: "laNone",
//   count: "laCount",
//   average: "laAverage",
//   copy: "laCopy",
//   copyWithHeaders: "laCopy Wit hHeaders",
//   ctrlC: "ctrl n C",
//   paste: "laPaste",
//   ctrlV: "ctrl n V"
// };

// For AG-Grid

//------------------------------
// RENDERERS
//------------------------------
const gridOptions = {
  style: "currency", // decimal, currency, percent
  currency: "USD",
  currencyDisplay: "symbol", // symbol, code, name to use a localized currency name such as "dollar"
  useGrouping: true,
  minimumIntegerDigits: 21,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  payload: {},

  weekday: "short", // long, short
  year: "numeric",
  month: "short", // long, short
  day: "numeric",
  // timeZone: 'America/New_York', // Auto calculated in DateCellRenderer
  timeZoneName: "short", // long, short
  hour12: true,
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  locale: "default", // To use the browser's default locale, use 'default'
  // getRowNodeId: function(item) {
  //   return item.id;
  // },
  imageWidth: "16px !important", // Will scale image to a width of 32px in ImageCellRenderer component
  divContainerHeight: "32px !important", // Will set div container height to 32px in ImageCellRenderer component to align the image in the middle

  getRowHeight: function (params) {
    // console.log("In getRowHeight: params is: ", params);
    // assuming 50 characters per line, working how how many lines we need
    // console.log("In getRowHeight: words is: ", params.data.ResourceDesc.length);
    // let lines = 36 * (Math.floor(params.data.ResourceDesc.length / 45) + 1);
    // console.log("In getRowHeight: lines is: ", lines);
    let lines = 36;
    return lines;
  },
};

let manageContact = {
  manageContactInfo: "Manage contact information",
  requiredFields: "* Required fields",

  languageCodeLabel: "Language code",
  languageNameLabel: "Language",
  selectLanguagePrompt: "Please select a language",
  gridRefreshWarning: "If you change language, grid will refresh.",

  searchPlaceholder: "Search countries",

  contactCodeLabel: "Code",
  contactCodePrompt: "Please enter a contact code",
  contactCodeMsg: "Example: hq",

  contactDescLabel: "Description",
  contactDescPrompt: "Please enter contact description",
  contactDescMsg: "Example: Corporate headquarters",

  contactAddressLabel: "Street address",
  contactAddressPrompt: "Enter street address",
  contactAddressErrMsg: "Please enter a correct street address",

  contactPhoneLabel: "Phone",
  contactPhonePrompt: "Enter contact phone number",
  contactPhoneErrMsg: "Please enter a correct phone number",

  contactFaxLabel: "Fax",
  contactFaxPrompt: "Enter contact fax number",
  contactFaxErrMsg: "Please enter a correct fax number",

  contactEmailLabel: "Email",
  contactEmailPrompt: "Please enter email address",
  contactEmailErrMsg: "Please enter a correct email address",

  webAddressLabel: "Website address",
  webAddressPrompt: "Please enter a website address",
  webAddressErrMsg: "Please enter a correct website address",

  submit: "Submit",
  editLabel: "Edit",
  deleteLabel: "Delete",
  modifiedBy: "Modified by",
  createdAt: " Create date",
  updatedAt: "Modified date",
  manageContactSuccessMsg: "Manage contact information was successful.",
  manageContactFailureMsg: "Error while managing contact information.",
  updateGridRow: "Update grid",
  filterInputPrompt: "Please enter filter criteria.",
  filterGridLabel: "Filter grid",

  // For Grid
  gridPage: "Page",
  gridTo: "to",
  gridOf: "of",
  noRowsToShow: "No records found",
};

function cellStyle(params) {
  return { "white-space": "normal" };
}

function ManageContactInfo(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [formLanguage, setFormLanguage] = useState(null);
  const [referenceType, setReferenceType] = useState("contact-info");
  const [contactCode, setContactCode] = useState("");
  const [contactDesc, setContactDesc] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactFax, setContactFax] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [webAddress, setWebAddress] = useState("");

  // For Phone/Fax Number Input
  const [defaultPhoneCountry, setDefaultPhoneCountry] = useState(
    appContext.phoneNumber.defaultPhoneCountry
  ); // For phone flag
  const [preferredCountries, setPreferredCountries] = useState(
    appContext.phoneNumber.preferredCountries
  ); //Show these countries at top of dropdown
  const [phoneLocale, setPhoneLocale] = useState(
    appContext.phoneNumber.phoneLocale
  ); // For phone validation
  const [localization, setLocalization] = useState(
    appContext.phoneNumber.localization
  ); // e.g {'Germany': 'Deutschland', 'Spain': 'España'}
  const [enableSearchField, setEnableSearchField] = useState(true);

  // const [showPassword, setShowPassword] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);
  const [gridData, setGridData] = useState([]);
  const [editRowIndex, setEditRowIndex] = useState(-1);

  const [langSelectOptions, setLangSelectOptions] = useState([]);
  // const [ refTypeSelectOptions, setRefTypeSelectOptions ] = useState([]);

  // For modal
  const [modalOpen, setModalOpen] = useState(false);

  const [selectLangChangedFlag, setSelectLangChangedFlag] = useState(false);

  // AG-Grid
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [progressFlag, setProgressFlag] = useState(false);
  const [gridUpdatedFlag, setGridUpdatedFlag] = useState(false);
  const [rowSelectedFlag, setRowSelectedFlag] = useState(false);
  const [gridSubmitFlag, setGridSubmitFlag] = useState(false);
  const [gridRowsCount, setGridRowsCount] = useState(0);

  const [filterDisabledFlag, setFilterDisabledFlag] = useState(true);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  const [modifiedGridRows, setModifiedGridRows] = useState([]);
  const [deletedGridRows, setDeletedGridRows] = useState([]);
  const [currentEditRow, setCurrentEditRow] = useState(null); // Current row being edited

  const [gridFilterValue, setGridFilterValue] = useState("");
  const [paginationPageSize, setPaginationPageSize] = useState(10);

  // For Error Flags
  const [formLanguageErrFlag, setFormLanguageErrFlag] = useState(false);
  const [contactAddressErrFlag, setContactAddressErrFlag] = useState(false);
  const [contactCodeFocus, setContactCodeFocus] = useState(false);
  const [contactDescFocus, setContactDescFocus] = useState(true);
  const [contactPhoneErrFlag, setContactPhoneErrFlag] = useState(false);
  const [contactPhoneFocus, setContactPhoneFocus] = useState(true);
  const [contactFaxErrFlag, setContactFaxErrFlag] = useState(false);
  const [contactFaxFocus, setContactFaxFocus] = useState(false);
  const [contactEmailErrFlag, setContactEmailErrFlag] = useState(false);
  const [webAddressErrFlag, setWebAddressErrFlag] = useState(false);
  const [contactCodeErrFlag, setContactCodeErrFlag] = useState(false);
  const [contactDescErrFlag, setContactDescErrFlag] = useState(false);

  // Variants
  const [contactEmailVariant, setContactEmailVariant] = useState(null);
  const [webAddressVariant, setWebAddressVariant] = useState(null);

  // const [redirectSignUpFlag, setRedirectSignUpFlag] = useState(false);
  // const [globalErrorMsg, setGlobalErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  // For Resources
  const [manageContactRes, setManageSocNetworksRes] = useState(manageContact);
  const [prevLangCode, setPrevLangCode] = useState(
    appContext.currentLanguage.languageCode
  );

  // For Grid Resources
  const [localeTextRes, setLocaleTextRes] = useState(localeText);

  const [renderGridFlag, setRenderGridFlag] = useState(false);

  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  const marginAlignment = {
    flex: "1",
    marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
  };

  const {
    manageContactInfo,

    languageCodeLabel,
    languageNameLabel,
    selectLanguagePrompt,
    gridRefreshWarning,

    searchPlaceholder,

    contactCodeLabel,
    contactCodePrompt,
    contactCodeMsg,

    contactDescLabel,
    contactDescPrompt,
    contactDescMsg,

    contactAddressLabel,
    contactAddressPrompt,
    contactAddressErrMsg,

    contactPhoneLabel,
    contactPhonePrompt,
    contactPhoneErrMsg,

    contactFaxLabel,
    contactFaxPrompt,
    contactFaxErrMsg,

    contactEmailLabel,
    contactEmailPrompt,
    contactEmailErrMsg,

    webAddressLabel,
    webAddressPrompt,
    webAddressErrMsg,

    submit,
    editLabel,
    deleteLabel,
    modifiedBy,
    createdAt,
    updatedAt,
    manageContactSuccessMsg,
    manageContactFailureMsg,
    updateGridRow,
    requiredFields,
    filterInputPrompt,
    filterGridLabel,
  } = manageContactRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(manageContact);
    // console.log("In ManageSocNetworkserenceData(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ManageSocNetworkserenceData(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "manageContactInfo")
              manageContact.manageContactInfo = item.ResourceDesc;
            if (item.ResourceCode === "languageCodeLabel")
              manageContact.languageCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "languageNameLabel")
              manageContact.languageNameLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectLanguagePrompt")
              manageContact.selectLanguagePrompt = item.ResourceDesc;
            if (item.ResourceCode === "gridRefreshWarning")
              manageContact.gridRefreshWarning = item.ResourceDesc;

            if (item.ResourceCode === "searchPlaceholder")
              manageContact.searchPlaceholder = item.ResourceDesc;

            if (item.ResourceCode === "contactCodeLabel")
              manageContact.contactCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "contactCodePrompt")
              manageContact.contactCodePrompt = item.ResourceDesc;
            if (item.ResourceCode === "contactCodeMsg")
              manageContact.contactCodeMsg = item.ResourceDesc;

            if (item.ResourceCode === "contactDescLabel")
              manageContact.contactDescLabel = item.ResourceDesc;
            if (item.ResourceCode === "contactDescPrompt")
              manageContact.contactDescPrompt = item.ResourceDesc;
            if (item.ResourceCode === "contactDescMsg")
              manageContact.contactDescMsg = item.ResourceDesc;

            if (item.ResourceCode === "contactAddressLabel")
              manageContact.contactAddressLabel = item.ResourceDesc;
            if (item.ResourceCode === "contactAddressPrompt")
              manageContact.contactAddressPrompt = item.ResourceDesc;

            if (item.ResourceCode === "contactPhoneLabel")
              manageContact.contactPhoneLabel = item.ResourceDesc;
            if (item.ResourceCode === "contactPhonePrompt")
              manageContact.contactPhonePrompt = item.ResourceDesc;

            if (item.ResourceCode === "contactFaxLabel")
              manageContact.contactFaxLabel = item.ResourceDesc;
            if (item.ResourceCode === "contactFaxPrompt")
              manageContact.contactFaxPrompt = item.ResourceDesc;

            if (item.ResourceCode === "contactEmailLabel")
              manageContact.contactEmailLabel = item.ResourceDesc;
            if (item.ResourceCode === "contactEmailPrompt")
              manageContact.contactEmailPrompt = item.ResourceDesc;

            if (item.ResourceCode === "webAddressLabel")
              manageContact.webAddressLabel = item.ResourceDesc;
            if (item.ResourceCode === "webAddressPrompt")
              manageContact.webAddressPrompt = item.ResourceDesc;

            if (item.ResourceCode === "manageContactSuccessMsg")
              manageContact.manageContactSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "manageContactFailureMsg")
              manageContact.manageContactFailureMsg = item.ResourceDesc;

            if (item.ResourceCode === "submit")
              manageContact.submit = item.ResourceDesc;
            if (item.ResourceCode === "editLabel")
              manageContact.editLabel = item.ResourceDesc;
            if (item.ResourceCode === "deleteLabel")
              manageContact.deleteLabel = item.ResourceDesc;
            if (item.ResourceCode === "modifiedBy")
              manageContact.modifiedBy = item.ResourceDesc;
            if (item.ResourceCode === "createdAt")
              manageContact.createdAt = item.ResourceDesc;
            if (item.ResourceCode === "updatedAt")
              manageContact.updatedAt = item.ResourceDesc;
            if (item.ResourceCode === "requiredFields")
              manageContact.requiredFields = item.ResourceDesc;
            if (item.ResourceCode === "filterInputPrompt")
              manageContact.filterInputPrompt = item.ResourceDesc;
            if (item.ResourceCode === "filterGridLabel")
              manageContact.filterGridLabel = item.ResourceDesc;
            if (item.ResourceCode === "updateGridRow")
              manageContact.updateGridRow = item.ResourceDesc;

            // For Grid
            if (item.ResourceCode === "gridPage")
              localeText.page = item.ResourceDesc;
            if (item.ResourceCode === "gridTo")
              localeText.to = item.ResourceDesc;
            if (item.ResourceCode === "gridOf")
              localeText.of = item.ResourceDesc;
            if (item.ResourceCode === "noRowsToShow")
              localeText.noRowsToShow = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, manageContact);
        }

        setManageSocNetworksRes(manageContact);

        setColumnDefs(columnDefsArray);
        setLocaleTextRes(localeText);

        setRenderGridFlag(true);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setManageSocNetworksRes(manageContact);
        setProgressFlag(false);
      });
  }

  //---------------------
  // AG-GRID Definitions
  //---------------------

  const columnDefsArray = [
    {
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressMenu: true,
      resizable: true,
    },
    {
      headerName: "ResourcePK",
      field: "ResourcePK",
      width: 100,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: "ResourceSK",
      field: "ResourceSK",
      width: 100,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: "Lat",
      field: "Lat",
      width: 100,
      hide: true,
    },
    {
      headerName: "Lng",
      field: "Lng",
      width: 100,
      hide: true,
    },
    {
      headerName: languageCodeLabel,
      field: "LanguageCode",
      width: 100,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: contactCodeLabel,
      field: "ResourceCode",
      width: 80,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
    },
    {
      headerName: contactDescLabel,
      field: "ResourceDesc",
      width: 140,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellStyle: cellStyle,
    },
    {
      headerName: contactAddressLabel,
      field: "StreetAddress",
      width: 150,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellStyle: cellStyle,
    },
    {
      headerName: contactPhoneLabel,
      field: "Phone",
      width: 150,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellStyle: cellStyle,
    },
    {
      headerName: contactFaxLabel,
      field: "Fax",
      width: 150,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellStyle: cellStyle,
    },
    {
      headerName: contactEmailLabel,
      field: "Email",
      width: 150,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellStyle: cellStyle,
    },
    {
      headerName: webAddressLabel,
      field: "WebAddress",
      width: 150,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellStyle: cellStyle,
    },
    {
      headerName: modifiedBy,
      field: "ModifiedBy",
      width: 150,
      // filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      filterParams: {
        clearButton: false,
        applyButton: false,
        debounceMs: 200,
      },
      hide: true,
    },
    {
      headerName: createdAt,
      field: "CreatedAt",
      width: 210,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
      filter: "agDateColumnFilter",
      hide: true,
      // filterParams: {
      //   values: ["2000", "2004", "2008", "2012"]
      // }
    },
    {
      headerName: updatedAt,
      field: "UpdatedAt",
      width: 190,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
      filter: "agDateColumnFilter",
      hide: true,
    },
    {
      id: "delete",
      headerName: deleteLabel,
      field: "delete",
      width: 70,
      filter: false,
      sortable: false,
      resizable: true,
      cellRendererFramework: ActionsCellRenderer,
    },
    {
      id: "edit",
      headerName: editLabel,
      field: "edit",
      width: 60,
      filter: false,
      sortable: false,
      resizable: true,
      cellRendererFramework: ActionsCellRenderer,
    },
  ];
  const [columnDefs, setColumnDefs] = useState(columnDefsArray);

  // AG-Grid
  const defColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
  };
  const [defaultColDef, setDefaultColDef] = useState(defColDef);

  const [rowSelection, setRowSelection] = useState("multiple");

  // Fetch Grid Data
  function fetchGridData() {
    // console.log('In fetchGridData(): formLanguage is: ', formLanguage);
    // console.log('In fetchGridData(): referenceType is: ', referenceType);

    setFilterDisabledFlag(true);
    setProgressFlag(true);

    let arrayData = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "contact-info",
          ":resourceSK": formLanguage,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchGridData(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchGridData(): response length is: ', response.data.Items.length);
        // console.log('In fetchGridData(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item, index) => {
          let record = {
            id: "" + index,
            LanguageCode: item.LanguageCode,
            ResourcePK: item.ResourcePK,
            ResourceSK: item.ResourceSK,

            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,

            Lat: item.Lat,
            Lng: item.Lng,

            StreetAddress: item.StreetAddress,
            Email: item.Email,
            Phone: item.Phone,
            Fax: item.Fax,
            WebAddress: item.WebAddress,

            CustomerService: item.CustomerService,

            SalesPhone: item.SalesPhone,
            SalesEmail: item.SalesEmail,

            SupportPhone: item.SupportPhone,
            SmsPhone: item.SmsPhone,
            WhatsAppPhone: item.WhatsAppPhone,
            CallMePhone: item.CallMePhone,
            SupportEmail: item.SupportEmail,

            ModifiedBy: item.ModifiedBy,
            CreatedAt: item.CreatedAt,
            UpdatedAt: item.UpdatedAt,
          };

          arrayData.push(record);
        });

        setGridData(arrayData);

        if (response.data.Items.length > 0) {
          setFilterDisabledFlag(false);
        }

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchGridData(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  let selectOptions = [];

  function fetchLanguages() {
    // console.log('In fetchLanguages()');
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages(): response.length is: ', response.data.Items.length);
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            // label: item.LanguageName,
            text: item.LanguageName,
          };
          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In useEffect()");
  }, []);

  useLayoutEffect(() => {
    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In ManageContactInfo(): useLayoutEffect(): currentLanguage is: ', currentLanguage);
    // console.log('In ManageContactInfo(): useLayoutEffect(): GenerateModulusHash("firstName", 200) is: ', GenerateModulusHash("firstName", 200));
    // console.log('In ManageContactInfo(): useLayoutEffect(): appContext.signedIn is: ', appContext.signedIn);

    setContactCodeFocus(true);
    setContactDescFocus(false);

    if (selectOptions.length === 0) {
      fetchLanguages();
    }

    // Fetch resource from catche
    let cacheItemKey = "manageContact";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ManageSocNetworkserenceData(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): got resource from cache');
      setManageSocNetworksRes(cacheItem);
    }
  }, []);

  function handleChange(event) {
    // console.log("In handleChange(): id is: "+event.target.id);
    // console.log("In handleChange(): value is: "+event.target.value);
    setGlobalErrorFlag(false);
    setGridUpdatedFlag(false);

    // Variants
    setContactEmailErrFlag(false);
    setWebAddressErrFlag(false);
    setContactEmailVariant("");
    setWebAddressVariant("");

    switch (event.target.id) {
      case "contactCodeId":
        setContactCodeErrFlag(false);
        setContactCode(event.target.value);

        // Enable add to grid button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          // contactAddress !== undefined &&
          // contactAddress !== null &&
          // contactAddress !== "" &&

          contactDesc !== undefined &&
          contactDesc !== null &&
          contactDesc !== "" &&
          // contactPhone !== undefined &&
          // contactPhone !== null &&
          // contactPhone !== "" &&

          // contactFax !== undefined &&
          // contactFax !== null &&
          // contactFax !== "" &&

          formLanguage !== undefined &&
          formLanguage !== null &&
          formLanguage !== "" &&
          contactEmail !== undefined &&
          contactEmail !== null &&
          contactEmail !== ""

          // webAddress !== undefined &&
          // webAddress !== null &&
          // webAddress !== ""
        ) {
          setGridUpdatedFlag(true);
        }
        break;
      case "contactDescId":
        setContactDescErrFlag(false);
        setContactDesc(event.target.value);

        // Enable add to grid button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          contactCode !== undefined &&
          contactCode !== null &&
          contactCode !== "" &&
          // contactAddress !== undefined &&
          // contactAddress !== null &&
          // contactAddress !== "" &&

          // contactPhone !== undefined &&
          // contactPhone !== null &&
          // contactPhone !== "" &&

          // contactFax !== undefined &&
          // contactFax !== null &&
          // contactFax !== "" &&

          formLanguage !== undefined &&
          formLanguage !== null &&
          formLanguage !== "" &&
          contactEmail !== undefined &&
          contactEmail !== null &&
          contactEmail !== ""

          // webAddress !== undefined &&
          // webAddress !== null &&
          // webAddress !== ""
        ) {
          setGridUpdatedFlag(true);
        }
        break;
      case "contactAddressId":
        setContactAddressErrFlag(false);
        setContactAddress(event.target.value);

        // Enable add to grid button
        if (
          // event.target.value !== undefined &&
          // event.target.value !== null &&
          // event.target.value !== "" &&

          contactCode !== undefined &&
          contactCode !== null &&
          contactCode !== "" &&
          contactDesc !== undefined &&
          contactDesc !== null &&
          contactDesc !== "" &&
          // contactPhone !== undefined &&
          // contactPhone !== null &&
          // contactPhone !== "" &&

          // contactFax !== undefined &&
          // contactFax !== null &&
          // contactFax !== "" &&

          formLanguage !== undefined &&
          formLanguage !== null &&
          formLanguage !== "" &&
          contactEmail !== undefined &&
          contactEmail !== null &&
          contactEmail !== ""

          // webAddress !== undefined &&
          // webAddress !== null &&
          // webAddress !== ""
        ) {
          setGridUpdatedFlag(true);
        }
        break;
      case "contactPhoneId":
        setContactPhoneErrFlag(false);
        setContactPhone(event.target.value);

        // Enable add to grid button
        if (
          // event.target.value !== undefined &&
          // event.target.value !== null &&
          // event.target.value !== "" &&

          contactCode !== undefined &&
          contactCode !== null &&
          contactCode !== "" &&
          contactDesc !== undefined &&
          contactDesc !== null &&
          contactDesc !== "" &&
          // contactAddress !== undefined &&
          // contactAddress !== null &&
          // contactAddress !== "" &&

          // contactFax !== undefined &&
          // contactFax !== null &&
          // contactFax !== "" &&

          formLanguage !== undefined &&
          formLanguage !== null &&
          formLanguage !== "" &&
          contactEmail !== undefined &&
          contactEmail !== null &&
          contactEmail !== ""

          // webAddress !== undefined &&
          // webAddress !== null &&
          // webAddress !== ""
        ) {
          setGridUpdatedFlag(true);
        }
        break;
      case "contactFaxId":
        setContactFaxErrFlag(false);
        setContactFax(event.target.value);

        // Enable add to grid button
        if (
          // event.target.value !== undefined &&
          // event.target.value !== null &&
          // event.target.value !== "" &&

          contactCode !== undefined &&
          contactCode !== null &&
          contactCode !== "" &&
          contactDesc !== undefined &&
          contactDesc !== null &&
          contactDesc !== "" &&
          // contactAddress !== undefined &&
          // contactAddress !== null &&
          // contactAddress !== "" &&

          // contactPhone !== undefined &&
          // contactPhone !== null &&
          // contactPhone !== "" &&

          formLanguage !== undefined &&
          formLanguage !== null &&
          formLanguage !== "" &&
          contactEmail !== undefined &&
          contactEmail !== null &&
          contactEmail !== ""

          // webAddress !== undefined &&
          // webAddress !== null &&
          // webAddress !== ""
        ) {
          setGridUpdatedFlag(true);
        }
        break;
      case "contactEmailId":
        setContactEmailErrFlag(false);
        setContactEmail(event.target.value);

        // Enable add to grid button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          contactCode !== undefined &&
          contactCode !== null &&
          contactCode !== "" &&
          contactDesc !== undefined &&
          contactDesc !== null &&
          contactDesc !== "" &&
          // contactAddress !== undefined &&
          // contactAddress !== null &&
          // contactAddress !== "" &&

          // contactPhone !== undefined &&
          // contactPhone !== null &&
          // contactPhone !== "" &&

          // contactFax !== undefined &&
          // contactFax !== null &&
          // contactFax !== "" &&

          formLanguage !== undefined &&
          formLanguage !== null &&
          formLanguage !== ""

          // webAddress !== undefined &&
          // webAddress !== null &&
          // webAddress !== ""
        ) {
          setGridUpdatedFlag(true);
        }
        break;
      case "webAddressId":
        setWebAddressErrFlag(false);
        setWebAddress(event.target.value);

        // Enable add to grid button
        if (
          // event.target.value !== undefined &&
          // event.target.value !== null &&
          // event.target.value !== "" &&

          contactCode !== undefined &&
          contactCode !== null &&
          contactCode !== "" &&
          contactDesc !== undefined &&
          contactDesc !== null &&
          contactDesc !== "" &&
          // contactAddress !== undefined &&
          // contactAddress !== null &&
          // contactAddress !== "" &&

          // contactPhone !== undefined &&
          // contactPhone !== null &&
          // contactPhone !== "" &&

          // contactFax !== undefined &&
          // contactFax !== null &&
          // contactFax !== "" &&

          formLanguage !== undefined &&
          formLanguage !== null &&
          formLanguage !== "" &&
          contactEmail !== undefined &&
          contactEmail !== null &&
          contactEmail !== ""
        ) {
          setGridUpdatedFlag(true);
        }
        break;
      default:
        break;
    }
  }

  function handlePhoneNumberChange(phoneNum) {
    console.log(
      "In handlePhoneNumberChange(): contactPhone is: ",
      contactPhone
    );
    setGlobalErrorFlag(false);
    setGridUpdatedFlag(false);

    setContactPhoneErrFlag(false);
    setContactPhone(phoneNum);

    // Enable add to grid button
    if (
      // phoneNum !== undefined &&
      // phoneNum !== null &&
      // phoneNum !== "" &&

      // contactAddress !== undefined &&
      // contactAddress !== null &&
      // contactAddress !== "" &&

      // contactFax !== undefined &&
      // contactFax !== null &&
      // contactFax !== "" &&

      formLanguage !== undefined &&
      formLanguage !== null &&
      formLanguage !== "" &&
      contactEmail !== undefined &&
      contactEmail !== null &&
      contactEmail !== ""

      // webAddress !== undefined &&
      // webAddress !== null &&
      // webAddress !== ""
    ) {
      setGridUpdatedFlag(true);
    }
  }

  function handleFaxNumberChange(faxNum) {
    console.log("In handleFaxNumberChange(): contactFax is: ", contactFax);
    setGlobalErrorFlag(false);
    setGridUpdatedFlag(false);

    setContactFaxErrFlag(false);
    setContactFax(faxNum);

    // Enable add to grid button
    if (
      // faxNum !== undefined &&
      // faxNum !== null &&
      // faxNum !== "" &&

      // contactAddress !== undefined &&
      // contactAddress !== null &&
      // contactAddress !== "" &&

      // contactPhone !== undefined &&
      // contactPhone !== null &&
      // contactPhone !== "" &&

      formLanguage !== undefined &&
      formLanguage !== null &&
      formLanguage !== "" &&
      contactEmail !== undefined &&
      contactEmail !== null &&
      contactEmail !== ""

      // webAddress !== undefined &&
      // webAddress !== null &&
      // webAddress !== ""
    ) {
      setGridUpdatedFlag(true);
    }
  }

  function checkFormModified() {
    // Every form element has a default value associated with its object
    let formModFlag = false;

    // How to check if input value is changed
    var contactAddInput = document.getElementById("contactAddressId");
    if (contactAddInput.value != contactAddInput.defaultValue) {
      formModFlag = true;
    }

    var webAddInput = document.getElementById("webAddressId");
    if (webAddInput.value != webAddInput.defaultValue) {
      formModFlag = true;
    }

    // How to check if checkbox value is changed
    // var checkbox = document.getElementById("checkboxId");
    // if (checkbox.checked != checkbox.defaultChecked) {
    //   formModFlag = true;
    // }

    // // How to check if select is changed
    // var select = document.getElementById("selectId");
    // if (!select.options[select.selectedIndex].defaultSelected) {
    //   formModFlag = true;
    // }

    if (formModFlag) {
      setGridUpdatedFlag(true);
    }
  }

  function validateForm() {
    let formErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setContactPhoneErrFlag(false);
    setContactFaxErrFlag(false);

    // Variants
    setContactEmailVariant("");
    setWebAddressVariant("");

    if (formLanguage === null || formLanguage === "") {
      formErrFlag = true;
      setFormLanguageErrFlag(true);
    }

    // if (referenceType === null || referenceType === "") {
    //   formErrFlag = true;
    //   setFormLanguageErrFlag(true);
    // }

    if (contactCode === null || contactCode === "") {
      formErrFlag = true;
      setContactCodeErrFlag(true);
      setGlobalErrorMsg(contactCodePrompt);
    }

    if (contactDesc === null || contactDesc === "") {
      formErrFlag = true;
      setContactDescErrFlag(true);
      setGlobalErrorMsg(contactDescPrompt);
    }

    // if (contactAddress === null || contactAddress === "") {
    //   formErrFlag = true;
    //   setContactAddressErrFlag(true);
    //   setGlobalErrorMsg(contactAddressErrMsg);
    // }

    if (
      contactPhone === null ||
      contactPhone === "" ||
      contactPhone.length <= 5
    ) {
      setContactPhone(null);
      // formErrFlag = true;
      // setContactPhoneErrFlag(true);
    } else if (!isMobilePhone(contactPhone, phoneLocale)) {
      formErrFlag = true;
      setContactPhoneErrFlag(true);
      // setContactPhoneVariant('danger');
      setGlobalErrorMsg(contactPhoneErrMsg);
    }

    if (contactFax === null || contactFax === "" || contactFax.length <= 5) {
      setContactFax(null);
      // formErrFlag = true;
      // setContactFaxErrFlag(true);
    } else if (!isMobilePhone(contactFax, phoneLocale)) {
      formErrFlag = true;
      setContactFaxErrFlag(true);
      // setContactFaxVariant('danger');
      setGlobalErrorMsg(contactFaxErrMsg);
    }

    if (contactEmail === null || contactEmail === "") {
      formErrFlag = true;
      setContactEmailErrFlag(true);
    } else if (!isEmail(contactEmail)) {
      formErrFlag = true;
      setContactEmailErrFlag(true);
      setContactEmailVariant("danger");
      setGlobalErrorMsg(contactEmailErrMsg);
    }

    if (webAddress === null || webAddress === "") {
      // formErrFlag = true;
      // setWebAddressErrFlag(true);
    } else if (!isURL(webAddress)) {
      formErrFlag = true;
      setWebAddressErrFlag(true);
      setWebAddressVariant("danger");
      setGlobalErrorMsg(webAddressErrMsg);
    }

    if (formErrFlag) {
      setGlobalErrorFlag(true);
    }
    return formErrFlag;
  }

  async function contactInfoService() {
    // console.log("In contactInfoService(): modifiedGridRows are: ", JSON.stringify(modifiedGridRows, null, 2));
    // console.log("In contactInfoService(): deletedGridRows are: ", JSON.stringify(deletedGridRows, null, 2));

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    // Get Grid data
    const gridRowCount = getRowCount();
    // console.log('In contactInfoService(): gridRowCount is: ', gridRowCount);

    if (gridRowCount > 0) {
      // we have data to save
      // console.log('We have data to save.');

      const rowsData = [];
      const deleteRowsData = [];

      // const d = new Date();
      const timestamp = new Date();
      // const timestamp = d.toISOString();
      // console.log("In contactInfoService(): timestamp is: ", timestamp);

      // Handle Modified Grid Rows
      if (
        modifiedGridRows &&
        typeof modifiedGridRows !== "undefined" &&
        modifiedGridRows.length > 0
      ) {
        modifiedGridRows.map((data) => {
          // Process each node (row)
          let rowData = {
            ResourcePK: data.ResourcePK, // language code
            ResourceSK: data.ResourceSK,
            LanguageCode: data.LanguageCode,

            ResourceCode: data.ResourceCode,
            ResourceDesc: data.ResourceDesc,

            Lat: data.Lat,
            Lng: data.Lng,

            StreetAddress: data.StreetAddress,
            Email: data.Email,
            Phone:
              data.Phone !== null && data.Phone.length > 5 ? data.Phone : null,
            Fax: data.Fax !== null && data.Fax.length > 5 ? data.Fax : null,
            WebAddress: data.WebAddress,

            CustomerService: data.CustomerService,

            SalesPhone: data.SalesPhone,
            SalesEmail: data.SalesEmail,

            SupportPhone: data.SupportPhone,
            SmsPhone: data.SmsPhone,
            WhatsAppPhone: data.WhatsAppPhone,
            CallMePhone: data.CallMePhone,
            SupportEmail: data.SupportEmail,

            ModifiedBy: appContext.userName,
            CreatedAt: data.CreatedAt,
            UpdatedAt: timestamp,
          };

          rowsData.push(rowData);
        });
      }

      // Handle Deleted Grid Rows
      if (
        deletedGridRows &&
        typeof deletedGridRows !== "undefined" &&
        deletedGridRows.length > 0
      ) {
        deletedGridRows.map((data) => {
          // Process each node (row)
          let rowData = {
            ResourcePK: data.ResourcePK,
            ResourceSK: data.ResourceSK,
          };

          deleteRowsData.push(rowData);
        });
      }

      let params = {
        apiName: "ResourceAPI",
        path: "/dynamodb/resources/batchWrite",
        payload: {
          items: rowsData,
          deleteItems: deleteRowsData,
        },
      };

      let initObj = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        response: true, // return entire response object instead of response.data
        body: {},
      };

      initObj.headers.Authorization = appContext.jwtToken;
      initObj.body = params.payload;
      // console.log('In contactInfoService(): initObj is: ', JSON.stringify(initObj, null, 2));

      setProgressFlag(true);

      API.post(params.apiName, params.path, initObj)
        .then((response) => {
          // console.log('In contactInfoService(): response is: ', JSON.stringify(response, null, 2));

          // Clear the grid of any data
          clearGridData();

          // Clear Arrays
          setDeletedGridRows([]);
          setModifiedGridRows([]);

          // Clear Language Selection
          setFormLanguage(null);

          // Show Snackbar
          setSnackbarsVariant("success");
          setSnackbarsMsg(manageContactSuccessMsg);
          setSnackbarOpen(true);

          setProgressFlag(false);
        })
        .catch((err) => {
          console.log(
            "In contactInfoService(): err is: ",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setGlobalErrorMsg(manageContactFailureMsg);

          setProgressFlag(false);
        });
    }
  }

  // gridOptions.payload = payload;

  //------------------------
  // Grid Functions
  //------------------------

  const deleteRows = (e) => {
    //console.log("In deleteRows(): gridApi is: ", gridApi);
    //console.log("In deleteRows(): gridApi.getSelectedNodes() is: ", gridApi.getSelectedNodes());
    var selectedRows = gridApi.getSelectedRows();
    var res = gridApi.updateRowData({ remove: selectedRows });
    // console.log("In deleteRows(): res is: ", res);

    if (selectedRows && selectedRows.length > 0) {
      let tempArray = deletedGridRows;
      selectedRows.map((record) => {
        tempArray.push(record);
      });
      setDeletedGridRows(tempArray);
    }

    // Update number of rows in grid
    getRowCount();
    initializeForm();

    setGridSubmitFlag(true);
  };

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
  }

  const onCellClicked = (event) => {
    // console.log('onCellClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onCellClicked: event.data is: ', event.data);
    // console.log('onCellClicked: event.colDef.field is: ', event.colDef.field);
    // console.log('onCellClicked: event.column.colDef.id is: ', event.column.colDef.id);
    // console.log('onCellClicked: event.column.colDef.headerName is: ', event.column.colDef.headerName);

    // let gridRowsCount;

    switch (event.column.colDef.id) {
      case "delete":
        var selectedData = gridApi.getSelectedRows();
        // console.log('onCellClicked: selectedData is: ', selectedData);

        let filteredSelectedData = selectedData.filter(
          (data) =>
            data.ResourcePK === event.data.ResourcePK &&
            data.ResourceCode === event.data.ResourceCode
        );
        // console.log('onCellClicked: filteredSelectedData is: ', filteredSelectedData);

        var res = gridApi.updateRowData({ remove: filteredSelectedData });
        // console.log("In onCellClicked() res is: ", res);

        // Update DeletedGridRows State
        if (filteredSelectedData && filteredSelectedData.length > 0) {
          let tempArray = deletedGridRows;
          filteredSelectedData.map((record) => {
            tempArray.push(record);
          });
          setDeletedGridRows(tempArray);
        }

        // Update number of rows in grid
        getRowCount();
        initializeForm();

        setGridSubmitFlag(true);
        break;
      case "edit":
        if (event.data) {
          // setFormLanguage(getLanguageByCode(event.data.ResourcePK));
          // setFormLanguage({value: 'es', text: 'Espanol'});
          setCurrentEditRow(event.data);
          setEditRowIndex(event.rowIndex);
          setContactCode(event.data.ResourceCode);
          setContactDesc(event.data.ResourceDesc);

          setContactAddress(event.data.StreetAddress);
          setContactPhone(event.data.Phone === null ? "" : event.data.Phone);
          setContactFax(event.data.Fax === null ? "" : event.data.Fax);
          setContactEmail(event.data.Email);
          setWebAddress(event.data.WebAddress);

          setGridUpdatedFlag(true);
        }
        break;
      default:
        break;
    }
  };

  const onCellValueChanged = (event) => {
    // console.log('onCellValueChanged: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellValueChanged: event.colDef.field is: ', event.colDef.field);

    const tempArray = modifiedGridRows;
    // console.log('onCellValueChanged: tempArray is: ', JSON.stringify(tempArray, null, 2));

    // Remove any previous changes to the same record
    let filteredTempArray = tempArray.filter(
      (data) =>
        data.ResourcePK !== event.data.ResourcePK &&
        data.ContactCode !== event.data.ContactCode
    );
    // console.log('onCellValueChanged: filteredTempArray is: ', JSON.stringify(filteredTempArray, null, 2));
    filteredTempArray.push(event.data);
    setModifiedGridRows(filteredTempArray);
  };

  const onRowSelected = (event) => {
    //gridApi = event.api;
    //console.log('onRowSelected: event is: ', event);
    // console.log('onRowSelected: event.data is: ', JSON.stringify(event.data, null, 2));
    setRowSelectedFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getSelectedNodes().length > 0
    ) {
      setRowSelectedFlag(true);
      // console.log('onRowSelected: after rowSelectedFlag is: ', rowSelectedFlag);
    }
  };

  const onRowClicked = (event) => {
    // console.log('onRowClicked: Total number of rows is: ', gridApi.getDisplayedRowCount());
    // console.log('onRowClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onRowClicked: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onRowClicked: clicked row is: ', gridApi.getDisplayedRowAtIndex(event.rowIndex));
  };

  const onRowValueChanged = (event) => {
    // console.log('onRowValueChanged: event is:  ', event);
  };

  const onRowDataChanged = (event) => {
    //gridApi = event.api;
    // console.log('>>>>In onRowDataChanged: formLanguage is: ', formLanguage);
    // console.log('>>>>In onRowDataChanged: referenceType is: ', referenceType);
    // console.log('>>>>In onRowDataChanged: event is: ', event);
    // setGridSubmitFlag(false);

    setColumnDefs(columnDefsArray);
    setLocaleTextRes(localeText);

    if (
      formLanguage !== undefined &&
      formLanguage !== null &&
      referenceType !== undefined &&
      referenceType !== null &&
      referenceType !== ""
    ) {
      fetchGridData();
    }

    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getModel().getRowCount() > 0
    ) {
      // console.log('>>>>In onRowDataChanged: gridApi.getModel().getRowCount() is: ', gridApi.getModel().getRowCount());
      // setGridSubmitFlag(true);

      // Update display of number of rows in grid
      getRowCount();
    }
  };

  const onRowDataUpdated = (event) => {
    // console.log('onRowDataUpdated: event is:  ', event);
  };

  const onFirstDataRendered = (event) => {
    // console.log('onFirstDataRendered: event is:  ', event);
  };

  function clearGridData() {
    if (gridApi) {
      gridApi.setRowData([]);

      getRowCount();

      // Disable Submit button
      setGridSubmitFlag(false);
    }
  }

  function initializeForm() {
    // console.log("In initializeForm()");

    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setContactCode("");
    setContactDesc("");
    setContactAddress("");
    setContactPhone("");
    setContactFax("");
    setContactEmail("");
    setWebAddress("");

    gridApi.deselectAll();
  }

  function getRowCount() {
    let gridRowsCount = gridApi.getModel().getRowCount();
    setGridRowsCount(gridRowsCount);
    return gridRowsCount;
  }

  function updateModifiedGridRow() {
    // console.log("In updateModifiedGridRow(): formLanguage is: ", formLanguage);
    // console.log("In updateModifiedGridRow(): contactCode is: ", contactCode);
    // console.log("In updateModifiedGridRow(): contactDesc is: ", contactDesc);
    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    if (!validateForm()) {
      // Validation Passed - you can proceed
      // console.log('Validation Passed.');
      setGridUpdatedFlag(false);
      setGridSubmitFlag(true);

      // console.log("In updateModifiedGridRow(): currentEditRow is: ", currentEditRow);
      if (currentEditRow && currentEditRow !== null) {
        currentEditRow.ResourceDesc = contactDesc;
        currentEditRow.StreetAddress = contactAddress;
        currentEditRow.Email = contactEmail;
        currentEditRow.Phone = contactPhone.length <= 5 ? "" : contactPhone;
        currentEditRow.Fax = contactFax.length <= 5 ? "" : contactPhone;
        // currentEditRow.Phone = contactPhone;
        currentEditRow.WebAddress = webAddress;

        const res = gridApi.updateRowData({ update: [currentEditRow] });
        // console.log("In updateModifiedGridRow(): res is: ", res);

        // Remove any previous changes to the same record
        const tempArray = modifiedGridRows;
        // console.log('In updateModifiedGridRow(): tempArray is: ', JSON.stringify(tempArray, null, 2));

        tempArray.filter(
          (data) =>
            data.ResourcePK !== currentEditRow.ResourcePK &&
            data.ResourcePK !== currentEditRow.ResourcePK
        );
        // console.log('In updateModifiedGridRow(): tempArray before push is: ', JSON.stringify(tempArray, null, 2));
        tempArray.push(currentEditRow);
        setModifiedGridRows(tempArray);
        // console.log('In updateModifiedGridRow(): tempArray after push is: ', JSON.stringify(tempArray, null, 2));

        // Update number of rows in grid
        getRowCount();

        // Initialize Variables
        initializeForm();
      }
    }
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    setSnackbarOpen(false);
  }

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);
    setFormLanguage(selectedOption);

    initializeForm();

    setSelectLangChangedFlag(true);

    // Clear the grid of any data
    clearGridData();

    fetchGridData();
  }

  const gridComponents = {
    loadingRenderer: function (params) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<CircularProgress className={classes.progress} color="secondary" size={30}/>';
      }
    },
  };

  function handleGridFilterChange(event) {
    // console.log("In handleGridFilterChange(): value is: "+event.target.value);
    setGridFilterValue(event.target.value);
    gridApi.setQuickFilter(event.target.value);
  }

  //-----------------------------------
  // For Draggable Modal Dialog
  //-----------------------------------

  const socialsDraggableClose = () => {
    // console.log('In socialsDraggableClose()');
    setModalOpen(false);
  };

  const onRowClickedSocials = (event) => {
    // console.log('onRowClickedSocials: event.rowIndex is: ', event.rowIndex);
    // console.log('onRowClickedSocials: event.data is: ', JSON.stringify(event.data, null, 2));
    setContactPhone(event.data.IconUrl);
    setModalOpen(false);
  };

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { !appContext.signedIn &&
          <Redirect to='/signIn' />
        } */}
        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%", height: "100%" }}>
            {/* progressFlag &&
              <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
              </div>
            */}
            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="md"
            >
              <Grid container justify="center">
                <Grid item xs={12} sm={9}>
                  <StyledPaper elevation={3} className={classes.paper}>
                    <Typography
                      variant="h6"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      {manageContactInfo}
                    </Typography>

                    <Typography
                      variant="caption"
                      style={{ color: "green", textAlign: "center" }}
                    >
                      {requiredFields}
                    </Typography>

                    {globalErrorFlag && (
                      <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                    )}

                    <LineSpacing value={4} />

                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="formLanguageId"
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          *{languageNameLabel}
                        </Typography>
                      </label>
                      <Select
                        id="formLanguageId"
                        value={formLanguage}
                        placeholder={selectLanguagePrompt}
                        onChange={handleLanguageChange}
                        variant="outlined"
                        displayEmpty={true}
                        autoFocus
                        fullWidth
                        style={{ width: "100%", flexGrow: 1 }}
                      >
                        <MenuItem value={null} disabled>
                          <Typography variant="body1">
                            {selectLanguagePrompt}
                          </Typography>
                        </MenuItem>
                        {langSelectOptions.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <LineSpacing value={12} />

                    <Grid container justify="center">
                      <Grid item style={marginAlignment}>
                        <FormControl
                          className={classes.textField}
                          variant="outlined"
                        >
                          <label
                            htmlFor="contactCodeId"
                            style={directionAlignment}
                          >
                            <Typography variant="subtitle1">
                              *{contactCodeLabel}
                            </Typography>
                          </label>
                          <OutlinedInput
                            id="contactCodeId"
                            value={contactCode}
                            placeholder={contactCodePrompt}
                            onChange={handleChange}
                            required
                            fullWidth
                            // autoFocus
                            type={"text"}
                            error={contactCodeErrFlag}
                            margin="none"
                          />
                        </FormControl>
                      </Grid>

                      <Grid item style={marginAlignment}>
                        <FormControl
                          className={classes.textField}
                          variant="outlined"
                        >
                          <label
                            htmlFor="contactDescId"
                            style={directionAlignment}
                          >
                            <Typography variant="subtitle1">
                              *{contactDescLabel}
                            </Typography>
                          </label>
                          <OutlinedInput
                            id="contactDescId"
                            value={contactDesc}
                            placeholder={contactDescPrompt}
                            onChange={handleChange}
                            required
                            fullWidth
                            // autoFocus
                            type={"text"}
                            error={contactDescErrFlag}
                            margin="none"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <LineSpacing value={12} />

                    <Grid container justify="center">
                      <Grid item style={marginAlignment}>
                        <FormControl
                          className={classes.textField}
                          variant="outlined"
                        >
                          <label
                            htmlFor="contactAddressId"
                            style={directionAlignment}
                          >
                            <Typography variant="subtitle1">
                              {contactAddressLabel}
                            </Typography>
                          </label>
                          <TextareaAutosize
                            id="contactAddressId"
                            aria-label="contact address"
                            rows={2}
                            rowsMax={6}
                            value={contactAddress}
                            placeholder={contactAddressPrompt}
                            onChange={handleChange}
                            required
                            fullWidth
                            error={contactAddressErrFlag}
                            margin="none"
                            className={classes.textArea}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <LineSpacing value={12} />

                    <Grid container justify="center">
                      <Grid item style={{ flex: "1", marginRight: "5px" }}>
                        <FormControl
                          className={classes.textField}
                          variant="outlined"
                        >
                          <label
                            htmlFor="contactEmailId"
                            style={directionAlignment}
                          >
                            <Typography variant="subtitle1">
                              *{contactEmailLabel}
                            </Typography>
                          </label>
                          <OutlinedInput
                            id="contactEmailId"
                            value={contactEmail}
                            placeholder={contactEmailPrompt}
                            onChange={handleChange}
                            required
                            fullWidth
                            // autoFocus
                            type={"text"}
                            error={contactEmailErrFlag}
                            margin="none"
                          />
                        </FormControl>
                      </Grid>

                      <Grid item style={{ flex: "1", marginRight: "5px" }}>
                        <FormControl
                          className={classes.textField}
                          variant="outlined"
                        >
                          <label
                            htmlFor="webAddressId"
                            style={directionAlignment}
                          >
                            <Typography variant="subtitle1">
                              {webAddressLabel}
                            </Typography>
                          </label>
                          <OutlinedInput
                            id="webAddressId"
                            value={webAddress}
                            placeholder={webAddressPrompt}
                            onChange={handleChange}
                            required
                            fullWidth
                            // autoFocus
                            type={"text"}
                            error={webAddressErrFlag}
                            margin="none"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <LineSpacing value={12} />

                    <Grid container justify="center">
                      <Grid item style={marginAlignment}>
                        <label
                          htmlFor="contactPhoneId"
                          className={classes.verticalAlignLabel}
                          style={directionAlignment}
                        >
                          <Typography variant="subtitle1">
                            {contactPhoneLabel}
                          </Typography>
                        </label>
                        {/* Force 'ltr' in all situations  - component does not render well for 'rtl' */}
                        <div dir={"ltr"}>
                          <ReactPhoneInput
                            id="contactPhoneId"
                            defaultCountry={defaultPhoneCountry}
                            value={contactPhone}
                            onChange={handlePhoneNumberChange}
                            inputClass={classes.phone}
                            preferredCountries={preferredCountries}
                            localization={localization}
                            enableSearchField={enableSearchField}
                            searchPlaceholder={searchPlaceholder}
                          />
                        </div>
                      </Grid>

                      <Grid item style={marginAlignment}>
                        <label
                          htmlFor="contactFaxId"
                          className={classes.verticalAlignLabel}
                          style={directionAlignment}
                        >
                          <Typography variant="subtitle1">
                            {contactFaxLabel}
                          </Typography>
                        </label>
                        {/* Force 'ltr' in all situations  - component does not render well for 'rtl' */}
                        <div dir={"ltr"}>
                          <ReactPhoneInput
                            id="contactFaxId"
                            defaultCountry={defaultPhoneCountry}
                            value={contactFax}
                            onChange={handleFaxNumberChange}
                            inputClass={classes.phone}
                            preferredCountries={preferredCountries}
                            localization={localization}
                            enableSearchField={enableSearchField}
                            searchPlaceholder={searchPlaceholder}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <LineSpacing value={18} />

                    <Button
                      variant="contained"
                      size="large"
                      disabled={!gridUpdatedFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={updateModifiedGridRow}
                    >
                      <AddIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {updateGridRow}
                    </Button>
                  </StyledPaper>
                </Grid>
              </Grid>
            </Container>

            <LineSpacing value={12} />

            {/* AG-GRID */}

            {renderGridFlag && (
              <Container
                component="div"
                className={classes.mainGrid}
                maxWidth="lg"
              >
                <Grid container justify="center">
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!rowSelectedFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={deleteRows}
                      style={directionFloat}
                    >
                      <DeleteIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {deleteLabel}
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container className={classes.filterGrid}>
                      <Grid
                        item
                        xs={2}
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <label htmlFor="filterTextId">
                          <Typography
                            variant="subtitle1"
                            className={classes.label}
                          >
                            {filterGridLabel}:{" "}
                          </Typography>
                        </label>
                      </Grid>

                      <Grid item xs={10}>
                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                          variant="outlined"
                        >
                          <OutlinedInput
                            id="filterTextId"
                            value={gridFilterValue}
                            placeholder={filterInputPrompt}
                            onChange={handleGridFilterChange}
                            fullWidth
                            type="search"
                            disabled={filterDisabledFlag}
                            startAdornment={
                              <InputAdornment position="start">
                                <Search color="primary" />
                              </InputAdornment>
                            }
                            margin="none"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!gridSubmitFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={contactInfoService}
                      style={directionReverseFloat}
                    >
                      <SaveIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {submit}
                    </Button>
                  </Grid>
                </Grid>

                <div style={{ width: "100%", height: "100%" }}>
                  <div
                    id="refGrid"
                    style={{
                      // height: "400px",
                      width: "100%",
                    }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      localeText={localeTextRes}
                      domLayout="autoHeight"
                      onGridReady={onGridReady}
                      gridOptions={gridOptions}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      rowData={gridData}
                      rowSelection={rowSelection}
                      rowMultiSelectWithClick={true}
                      rowDeselection={true}
                      suppressRowClickSelection={false}
                      // debug={true}
                      pagination={true}
                      components={gridComponents}
                      paginationPageSize={paginationPageSize}
                      cacheOverflowSize={2}
                      maxConcurrentDatasourceRequests={2}
                      infiniteInitialRowCount={1}
                      maxBlocksInCache={2}
                      enableRtl={enableRtl}
                      onCellClicked={onCellClicked}
                      onCellValueChanged={onCellValueChanged}
                      onRowSelected={onRowSelected}
                      onRowClicked={onRowClicked}
                      onRowValueChanged={onRowValueChanged}
                      onRowDataChanged={onRowDataChanged}
                      onRowDataUpdated={onRowDataUpdated}
                      onFirstDataRendered={onFirstDataRendered}
                    />
                  </div>
                </div>
              </Container>
            )}
          </div>

          {/* variant: 'success', 'warning', 'error', 'info' */}
          <TsiCustomizedSnackbars
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={8000}
            onClose={snackbarOnClose}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">{snackbarsMsg}</span>}
            variant={snackbarsVariant}
          />

          {modalOpen && (
            <SocialsDraggableDialog
              currLanguage={currentLanguage}
              dialogOpen={modalOpen}
              onDialogClose={socialsDraggableClose}
              onRowClickedSocials={onRowClickedSocials}
            />
          )}
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter isSignedIn={isSignedIn} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default ManageContactInfo;
