import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
} from "react";
import styled from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

// AppContext
import AppContext from "../../config/AppContext";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

import VideoPlayerOther from "../../components/media/VideoPlayerOther";
import ReactVideoPlayer from "../../components/media/ReactVideoPlayer";

const StyledPaper = styled(Paper)`
  min-width: 300px;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    //   display: 'flex',
    //   flexDirection: 'column',
    //   minHeight: '100vh',
    backgroundColor: "#fff",
  },
  main: {
    width: "100% !important",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  progress: {
    margin: theme.spacing(2),
  },
  mainForm: {
    width: "100% !important",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

let playVideo = {
  playVideoLabel: "Play video",
};

let playListArray = [
  {
    videoName: "sintelTrailer",
    videoUrl: "http://media.w3.org/2010/05/sintel/trailer.mp4",
  },
  {
    videoName: "bunnyTrailer",
    videoUrl: "http://media.w3.org/2010/05/bunny/trailer.mp4",
  },
  {
    videoName: "bunnyMovie",
    videoUrl: "http://media.w3.org/2010/05/bunny/movie.mp4",
  },
  {
    videoName: "test",
    videoUrl: "http://media.w3.org/2010/05/video/movie_300.webm",
  },
];

function PlayVideo(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  const [progressFlag, setProgressFlag] = useState(false);

  //------------------------
  // For Video Player
  //------------------------
  const videoPlayer = useRef(null);

  // const [videoPlayer, setVideoPlayer] = useState({});
  // const [videoPlayer, setPlayerRef] = useState();
  const [playerId, setPlayerId] = useState();
  const [fluidFlag, setFluidFlag] = useState(true);
  const [preload, setPreloadFlag] = useState("auto"); // "auto","metadata","none"
  const [mutedFlag, setMutedFlag] = useState(false);
  const [playsInlineFlag, setPlaysInlineFlag] = useState(false); // iOS only: Determines whether videos play inline or use the native full-screen controller.
  const [aspectRatio, setAspectRatio] = useState("auto"); // auto, 16:9, 4:3
  const [autoPlayFlag, setAutoPlayFlag] = useState(false);
  const [playStartTime, setPlayStartTime] = useState(); // Seek the Video at A Specific Time On Load

  const [playerControls, setPlayerControls] = useState(true);
  const [videoSrc, setVideoSrc] = useState(
    "//cdn.nuevolab.com/media/demo_720.mp4"
  ); // https://media.w3.org/2010/05/sintel/trailer_hd.mp4
  const [videoPoster, setVideoPoster] = useState(
    "//cdn.nuevolab.com/media/poster.jpg"
  );
  const [videoPlayerWidth, setVideoPlayerWidth] = useState(1024); // e.g. 720
  const [videoPlayerHeight, setVideoPlayerHeight] = useState(
    (videoPlayerWidth * 9) / 16
  ); // e.g. 420

  const [videoPlayList, setVideoPlayList] = useState(playListArray);
  const [videoFromPlayList, setVideoFromPlayList] = useState(
    "https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
  );
  const [videoFromPlayListPoster, setVideoFromPlayListPoster] = useState(
    "https://video-react.js.org/assets/poster.png"
  );
  const [bigPlayButtonPosition, setBigPlayButtonPosition] = useState("center");
  const [autoHideFlag, setAutoHideFlag] = useState(true);
  const [autoHideTime, setAutoHideTime] = useState();
  const [disableDefaultControlsFlag, setDisableDefaultControlsFlag] = useState(
    false
  );
  const [disableCompletelyFlag, setDisableCompletelyFlag] = useState(false);
  const [controlBarClass, setControlBarClass] = useState(
    "video-js vjs-default-skin video-react-fluid"
  );
  const [playbackRates, setPlaybackRates] = useState([5, 2, 1, 0.5, 0.1]);
  const [replayContolSeconds, setReplayContolSeconds] = useState();
  const [replayContolOrder, setReplayContolOrder] = useState(2.3);
  const [forwardControlSeconds, setForwardControlSeconds] = useState();
  const [forwardControlOrder, setForwardControlOrder] = useState(3.3);

  const [videoVolume, setVideoVolume] = useState(); // Sets or returns the volume of the video
  const [playerState, setPlayerState] = useState(null);

  //----------------------------------------------------------------------

  const classes = useStyles();

  const { currLanguage } = props;

  const { playVideoLabel } = playVideo;

  function handleStateChange(playerState) {
    console.log("In handleStateChange(): playerState is: ", playerState);
    setPlayerState(playerState);
  }

  useEffect(() => {
    console.log("In PlayVideo(): useEffect(): videoPlayer is: ", videoPlayer);

    // subscribe state change
    if (videoPlayer !== undefined && videoPlayer !== null) {
      // videoPlayer.subscribeToStateChange(handleStateChange);
    }
  }, []);

  useLayoutEffect(() => {
    console.log(
      "In PlayVideo(): useLayoutEffect(): currentLanguage is: ",
      currentLanguage
    );
    console.log(
      "In PlayVideo(): useLayoutEffect(): currLanguage is: ",
      currLanguage
    );
    console.log(
      "In PlayVideo(): useLayoutEffect(): isSignedIn is: ",
      isSignedIn
    );
  }, []);

  // function resizeVideo(newWidth) {
  //     console.log('In resizeVideo(): newWidth is: ', newWidth);

  //     var ASPECT_RATIO = 9/16; // 9/16, 16:9
  //     var newHeight    = (ASPECT_RATIO * newWidth);
  //     console.log('In resizeVideo(): newHeight is: ', newHeight);

  //     setVideoPlayerWidth(newWidth);
  //     setVideoPlayerHeight(newHeight);
  // }

  function onPlayerReady(player) {
    console.log("Player is ready: ", player);
    // setVideoPlayer(player);

    // window.addEventListener("resize", function() {
    //     console.log('Do something when user goes into full screen mode');
    //     setTimeout(resizeVideo(200));
    // });
  }

  function onVideoPlay(duration) {
    console.log("Video played at: ", duration);
  }

  function onVideoPause(duration) {
    console.log("Video paused at: ", duration);
  }

  function onVideoTimeUpdate(duration) {
    // console.log("Time updated: ", duration);
  }

  function onVideoSeeking(duration) {
    console.log("Video seeking: ", duration);
  }

  function onVideoSeeked(from, to) {
    console.log(`Video seeked from ${from} to ${to}`);
  }

  function onVideoEnd() {
    console.log("Video ended");
  }

  const playerConfig = {
    videoPlayer: videoPlayer,
    playerId: playerId,
    fluidFlag: fluidFlag,
    preload: preload,
    mutedFlag: mutedFlag,
    playsInlineFlag: playsInlineFlag,
    aspectRatio: aspectRatio,
    autoPlayFlag: autoPlayFlag,
    playStartTime: playStartTime,

    // playerControls: playerControls,
    videoSrc: videoSrc,
    videoPoster: videoPoster,
    videoPlayerWidth: videoPlayerWidth,
    videoPlayerHeight: videoPlayerHeight,

    onPlayerReady: onPlayerReady,
    onVideoPlay: onVideoPlay,
    onVideoPause: onVideoPause,
    onVideoTimeUpdate: onVideoTimeUpdate,
    onVideoSeeking: onVideoSeeking,
    onVideoSeeked: onVideoSeeked,
    onVideoEnd: onVideoEnd,

    videoFromPlayList: videoFromPlayList, // from sources {}
    videoFromPlayListPoster: videoFromPlayListPoster,
    bigPlayButtonPosition: bigPlayButtonPosition, // "left-top", "center"
    autoHideFlag: autoHideFlag, // ControlBar
    autoHideTime: autoHideTime, // ControlBar
    disableDefaultControlsFlag: disableDefaultControlsFlag, // ControlBar
    disableCompletelyFlag: disableCompletelyFlag, // ControlBar
    controlBarClass: controlBarClass, // ControlBar
    playbackRates: playbackRates, // PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]}
    replayContolSeconds: replayContolSeconds, // ReplayControl seconds
    replayContolOrder: replayContolOrder, // ReplayControl order={2.3}
    forwardControlSeconds: forwardControlSeconds, // ForwardControl seconds
    forwardControlOrder: forwardControlOrder, // ForwardControl order={3.3}
  };

  //-----------------------------
  // Player functions
  //-----------------------------

  // Play the video.
  function play() {
    videoPlayer.play();
  }

  // mute true or false
  function setMuted(muted) {
    return () => {
      videoPlayer.muted = muted;
    };
  }

  // Pause the video.
  function pause() {
    videoPlayer.pause();
  }

  // Change the video source and re-load the video
  function load() {
    videoPlayer.load();
  }

  // Add a new text track to the video
  function addTextTrack() {}

  // Check if your browser can play different types of video:
  function canPlayType() {}

  // Seek video by time (seconds)
  function seek(seconds) {
    return () => {
      videoPlayer.seek(seconds);
    };
  }

  // Jump forward x seconds
  function forward(seconds) {
    return () => {
      videoPlayer.forward(seconds);
    };
  }

  // Jump back x seconds
  function replay(seconds) {
    return () => {
      videoPlayer.replay(seconds);
    };
  }

  // Enter or exist full screen
  function toggleFullscreen() {}

  function canPlayType() {}

  // seconds 10, -10 etc
  function changeCurrentTime(seconds) {
    return () => {
      videoPlayer.seek(playerState.currentTime + seconds);
    };
  }

  // e.g. steps 1 or -1 or 0.1 or -0.1
  function changePlaybackRateRate(steps) {
    return () => {
      videoPlayer.playbackRate = playerState.playbackRate + steps;
    };
  }

  // e.g. steps 0.1 or -0.1
  function changeVolume(steps) {
    return () => {
      videoPlayer.volume = playerState.volume + steps;
    };
  }

  function changeSource(videoName) {
    console.log("In changeSource(): videoName is: ", videoName);
    return () => {
      let videoToPlay = videoPlayList.filter(
        (item) => item.videoName === videoName
      );
      console.log("In changeSource(): videoToPlay is: ", videoToPlay);
      setVideoFromPlayList(videoToPlay.videoUrl);
      videoPlayer.load();
    };
  }

  return (
    <div dir={direction}>
      <div className={classes.root}>
        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />
        {/* { !appContext.signedIn &&
                    <Redirect to='/signIn' />
                } */}

        <Container component="div" className={classes.main} maxWidth="lg">
          <div
            className="ag-theme-balham"
            style={{ width: "100%", height: "100%" }}
          >
            {progressFlag && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                  size={15}
                />
              </div>
            )}
            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="lg"
            >
              <Grid container justify="center">
                <StyledPaper elevation={3} className={classes.paper}>
                  <Typography
                    variant="h6"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    {playVideoLabel}
                  </Typography>

                  <Typography
                    variant="h6"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    {"From ReactVideoPlayer"}
                  </Typography>
                  <ReactVideoPlayer {...playerConfig} />

                  <Typography
                    variant="h6"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    {"From VideoPlayerOther"}
                  </Typography>
                  <VideoPlayerOther {...playerConfig} />
                </StyledPaper>
              </Grid>
            </Container>
          </div>
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default PlayVideo;
