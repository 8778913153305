import React, { useState } from "react";

import IdleTimer from "react-idle-timer";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch,
} from "react-router-dom";

import appRoutes from "./AppRoutes";
import SetCacheItem from "../../utils/cache/SetCacheItem";
import ClearCacheItems from "../../utils/cache/ClearCacheItems";

// File Upload
// import FilesUploadToS3 from '../components/upload/FilesUploadToS3';

function Navigation() {
  const [isIdle, setIsIdle] = useState(false);
  // const { currentLanguage, isSignedIn } = props;

  function onAction(e) {
    //console.log('In onAction(): user did something', e)
  }

  function onActive(e) {
    //console.log('In onActive(): user is active', e)
    //console.log('In onActive(): time remaining', IdleTimer.getRemainingTime())
  }

  function onIdle() {
    // console.log('In Navigation(): onIdle(): user is idle');
    //console.log('In onIdle(): last active', IdleTimer.getLastActiveTime());

    SetCacheItem("isSignedIn", false);
    ClearCacheItems();
    setIsIdle(true);
    //return (<Redirect to='/signIn' />);
  }

  return (
    <Router>
      <div>
        <IdleTimer
          onActive={onActive}
          onIdle={onIdle}
          onAction={onAction}
          debounce={250}
          timeout={1000 * 60 * 30}
        />

        <Switch>
          {appRoutes.map((route, i) => (
            <Route
              key={i}
              exact
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </div>
    </Router>
  );
}

export default Navigation;
