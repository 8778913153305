import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
// import { NavLink } from 'react-router-dom';
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
// import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";

// AppContext
import AppContext from "../../config/AppContext";

import PageBottomFooter from "../../components/footers/PageBottomFooter";
import ProductsFooter from "../../components/footers/ProductsFooter";
import ServicesFooter from "../../components/footers/ServicesFooter";
import SolutionsFooter from "../../components/footers/SolutionsFooter";
import CompanyFooter from "../../components/footers/CompanyFooter";
import ContactUsFooter from "../../components/footers/ContactUsFooter";
import SocialNetworksFooter from "../../components/footers/SocialNetworksFooter";
import AppsInfoFooter from "../../components/footers/AppsInfoFooter";

const PageContainer = styled.div`
  width: 100%,
  display: 'flex',
  flexWrap: 'wrap',
  background: #fff;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
    background: "#757de8",
    color: "#fff",
  },
  grid: {
    margin: 10,
    color: "#fff",
  },
  googlePlayBadgeImage: {
    width: "156px",
  },
  appStoreBadgeGrid: {
    marginTop: "10px",
  },
}));

function PageFooter(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  const [renderDomFlag, setRenderDomFlag] = useState(false);

  const {
    currLanguage,
    screenDirection,

    // For AppsInfoFooter
    renderGoogleAppleDomFlag,
    googleAppleInfoFlag,
    downloadApp,
    googlePlayLinkUrl,
    appleStoreLinkUrl,
    googlePlayMediaUrl,
    appleStoreMediaUrl,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    setRenderDomFlag(true);
  }, []);

  useEffect(() => {
    // console.log("In PageFooter(): useEffect(): [screenDirection] appContext.direction is: ", appContext.direction);
    // console.log("In PageFooter(): useEffect(): [screenDirection] direction is: ", direction);
    if (screenDirection) {
      setDirection(screenDirection);
    }
  }, [screenDirection]);

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {renderDomFlag && (
          <PageContainer>
            <CssBaseline />

            <Container maxWidth="lg">
              <Grid container className={classes.grid}>
                <Grid item xs={4} sm={2}>
                  <ProductsFooter currLanguage={currLanguage} />
                </Grid>

                <Grid item xs={4} sm={2}>
                  <ServicesFooter currLanguage={currLanguage} />
                </Grid>

                <Grid item xs={4} sm={2}>
                  <SolutionsFooter currLanguage={currLanguage} />
                </Grid>

                <Grid item xs={4} sm={2}>
                  <CompanyFooter currLanguage={currLanguage} />
                </Grid>

                <Grid item xs={4} sm={2}>
                  <ContactUsFooter currLanguage={currLanguage} />
                </Grid>

                <Grid item xs={4} sm={2}>
                  <SocialNetworksFooter currLanguage={currLanguage} />
                </Grid>
              </Grid>

              <Grid container className={classes.grid}>
                <Grid item xs={12} sm={12}>
                  <AppsInfoFooter
                    currLanguage={currLanguage}
                    renderGoogleAppleDomFlag={renderGoogleAppleDomFlag}
                    googleAppleInfoFlag={googleAppleInfoFlag}
                    downloadApp={downloadApp}
                    googlePlayLinkUrl={googlePlayLinkUrl}
                    appleStoreLinkUrl={appleStoreLinkUrl}
                    googlePlayMediaUrl={googlePlayMediaUrl}
                    appleStoreMediaUrl={appleStoreMediaUrl}
                  />
                </Grid>
              </Grid>
            </Container>
            <PageBottomFooter currLanguage={currLanguage} />
          </PageContainer>
        )}
      </div>
    </div>
  );
}

export default PageFooter;
