import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
import SaveIcon from "@material-ui/icons/Save";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from "@material-ui/core/Container";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import CheckIcon from '@material-ui/icons/Check';
// import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import InputAdornment from "@material-ui/core/InputAdornment";
import TranslateIcon from '@material-ui/icons/Translate';
import Paper from "@material-ui/core/Paper";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// react-responsive
import { useMediaQuery } from 'react-responsive';

// moment
import moment from "moment";

// Lodash
import lodash from "lodash";

// currency-symbol-map
import getSymbolFromCurrency from 'currency-symbol-map';

// Amplify
import { API } from "aws-amplify";
// import { AmplifyTheme   } from 'aws-amplify-react';

// AppContext
import AppContext from "../../config/AppContext";

import LineSpacing from "../../components/misc/LineSpacing";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";

// DesignBlocks
import Pricings01 from "../../components/designBlocks/pricings/Pricings01";
import Pricings05 from "../../components/designBlocks/pricings/Pricings05";

import Contents06 from "../../components/designBlocks/contents/Contents06";
import Contents07 from "../../components/designBlocks/contents/Contents07";
import Contents35 from "../../components/designBlocks/contents/Contents35";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GenerateUniqueName from "../../utils/GenerateUniqueName";
// import GenerateUlid from "../../utils/GenerateUlid";
// import FormatDateTime from "../../../../../utils/moment/FormatDateTime";
import FormatTimezoneDates from "../../utils/moment/FormatTimezoneDates";
import FilterArrayFromArray from "../../utils/filter/FilterArrayFromArray";
import FormatNumber from "../../utils/numberFormat/FormatNumber";
import FormatNumberWithOption from "../../utils/numberFormat/FormatNumberWithOption";
import FormatCurrency from "../../utils/numberFormat/FormatCurrency";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    leftPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "left",
    },
    leftPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    divLeftPadding: {
        width: '100% !important',
        paddingLeft: theme.spacing(1),
        // paddingRight: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1rem" /* Footer height */,

        width: "100% !important",
        marginTop: "auto",
        // backgroundColor: '#fff',
        textAlign: "center",
    },
    paper: {
        minWidth: "300px",
        marginTop: theme.spacing(2),
        alignItems: "center",
        padding: "10px 30px 10px 30px !important",
        textAlign: "center",
    },
    accord: {
        width: '100%',
    },
    accordHeading: {
        fontSize: theme.typography.pxToRem(15),
        // flexBasis: '33.33%',
        flexShrink: 0,
    },
    accordSecHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

let pageLabels = {
    pricingHdrLabel: "Pricing",
    pricingHdrMsg1: "Explore a perfect plan to host your engaging virtual events",// engaging or interactive or fantastic
    pricingHdrMsg2: "All prices are in",

    signUpLabel: "Sign up",
    signUpFreeLabel: "Sign up free",
    signUpForFreeLabel: "Sign up free",

    contactUsLabel: "Contact us",
    getStartedLabel: "Get started",

    letsChatLabel: "Let's chat",
    subscribeLabel: "Subscribe",
    choosePlanLabel: "Choose plan",

    monthLabel: "Month",
    yearLabel: "Year",


    faqsLabel: "Frequently asked questions (FAQs)",
    faqsMsg: "Please review our FAQs for answers to frequently asked question.",

    yearlyLabel: "Yearly",
    monthlyLabel: "Monthly",
    saveLcLabel: "save",
    savingsLcLabel: "savings",


    // pageNameMsg: "A reference name for identifying this page.",

    // startTimeLabel: "Start time",
    // startTimePrompt: "Select a start time",
    // startTimeErrMsg: "Invalid start time",

    // endTimeLabel: "End time",
    // endTimePrompt: "Select an end time",
    // endTimeErrMsg: "Invalid end time",
    // endTimeBeforeErrMsg: "End time must be greater or equal to start time",

    // pageHeaderLabel: "Header content",
    // pageHeaderPrompt: "Enter content to display on page header",
    // pageHeaderErrMsg: "Header content is required",
    // pageHeaderMsg: "This content will display on the page header",



    // selectTitlePrompt: "Select title",
    
    // startTimeLabel: "Blog start time",
    // startTimePrompt: "Select date & time to publish the blog",
    // startTimeErrMsg: "Blog start time is invalid",

    // uploadPageNameLabel: "Blog title",
    // uploadPageNamePrompt: "Please select blog title",
    // uploadPageNameErrMsg: "Blog title is required",

    // liveEventUrlLabel: "Live event URL",
    // liveEventUrlPrompt: "Please enter the live event URL",
    // liveEventUrlErrMsg: "Live event URL is invalid",

    // convertPageSectionsLabel: "Convert page sections",
    // createPageSectionLabel: "Create page section",
    // uploadFilesLabel: "Upload files",

    // bannerUrlLabel: "Banner URL",
    // bannerUrlPrompt: "Select a banner",
    // bannerUrlErrMsg: "Banner URL is required",

    // yourTimeZoneMsg: "Your timeZone is",
    // timeZoneLabel: "Time zone",
    // cloneEventLabel: "Clone event",

    // pageSectionLabel: "page section record",
    // pageSectionsLabel: "page section records",
    // inProgressLabel: "In progress",
    // startLabel: "Start",
    // endLabel: "End",
    // notStartedLabel: "Not started",
    // dateCreatedLabel: "Date created",

    // editLabel: "Edit",
    // deleteLabel: "Delete",

    // toLabel: "to",
    // ofLabel: "of",
    // recordsLabel: "records",
    // noRecordsFound: "No records found",

    // operationSuccessMsg: "Operation was successful.",
    // operationFailureMsg: "Operation failed.",
    // duplicateTransMsg: "Duplicate transaction",

    // loadingText: "Loading ...",
    // noOptionsText: "No options",
    // clearText: "Clear",
    // closeText: "Close",
    // showLabel: "Show",
    // hideLabel: "Hide",

    // cancelLabel: "Cancel",
    // clearLabel: "Clear",
    // okLabel: "Ok",

    // yesLabel: "Yes",
    // deleteRequested: "Delete requested",
    // areYouSureMsg: "Are you sure?",
    // requestedToDeleteMsg: "You have requested to delete",
  
    // createPageSectionLabel: "Create page section",
    // updatePageSectionLabel: "Update page section",

    // createMoreLabel: "Create more",
    // createLabel: "Create",
    // cloneRecordLabel: "Clone this record",
    // closeLabel: "Close",
    // noPageSectionRecordsFound: "No page section records found.", 
    
    // sectionDescLabel: "Description",  
    // sectionTitleLabel: "Title",  
            
    // gridCategory: "Category",
    // gridPageName: "Name",
    // gridPageHeader: "Title",
    // gridPageTags: "Tags",
    // lastUpdateLabel: "Last update",

    // gridStartTime: "Start time",
    // gridBannerUrl: "Banner URL",
    
    // convertLabel: "Convert",

    // sourceLanguageLabel: "Source language",
    // sourceLanguagePrompt: "Select source language",
    // sourceLanguageErrMsg: "Source language is required",

    // targetLanguageLabel: "Target language",
    // targetLanguagePrompt: "Select target language",
    // targetLanguageErrMsg: "Target language is required",

    // moreLabel: "- more -",
    // uploadLabel: "Upload",

    // organizeLabel: "Organize",
    // organizePageSectionsLabel: "Organize page sections",
    // availablePageSectionsLabel: "Page sections",
};

function PricingPage() {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);// appContext.direction
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);
    const [renderDomFlag, setRenderDomFlag] = useState(false);
    const [progressFlag, setProgressFlag] = useState(false);

    // For Global Errors
    const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");

    // For Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarsMsg, setSnackbarsMsg] = useState("");
    const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    //--------------------
    // For Form Attributes
    //--------------------
    const [language, setLanguage] = useState(null);
    const [prevCategory, setPrevCategory] = useState("");
    
    const [pricePlanRecord1, setPricePlanRecord1] = useState(null);
    const [pricePlanRecord2, setPricePlanRecord2] = useState(null);
    const [pricePlanRecord3, setPricePlanRecord3] = useState(null);
    const [pricePlanRecord4, setPricePlanRecord4] = useState(null);

    const [priceHdrRecords, setPriceHdrRecords] = useState([]);
    const [priceListRecords, setPriceListRecords] = useState([]);
    const [faqListRecords, setFaqListRecords] = useState([]);
        
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [pageSize, setPageSize] = useState(10000);// AWS put PageSize to a max 0f 100

    const theme = useTheme();
    const classes = useStyles();

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    const { 
        pricingHdrLabel, 
        pricingHdrMsg1,
        pricingHdrMsg2,

        signUpLabel,
        signUpFreeLabel,
        contactUsLabel,
        signUpForFreeLabel,
        getStartedLabel,
        letsChatLabel,
        subscribeLabel,
        choosePlanLabel,
            
        monthLabel,
        yearLabel,

        faqsLabel,
        faqsMsg,

        yearlyLabel,
        monthlyLabel,
        saveLcLabel,
        savingsLcLabel,

        // pageNameMsg,
        // // selectTitlePrompt,

        // startTimeLabel,
        // startTimePrompt,
        // startTimeErrMsg,

        // endTimeLabel,
        // endTimePrompt,
        // endTimeErrMsg,
        // endTimeBeforeErrMsg,

        // pageHeaderLabel,
        // pageHeaderPrompt,
        // pageHeaderErrMsg,
        // pageHeaderMsg,
    
        // startTimeLabel,
        // startTimePrompt,
        // startTimeErrMsg,

        // uploadPageNameLabel,
        // uploadPageNamePrompt,
        // uploadPageNameErrMsg,

        // liveEventUrlLabel,
        // liveEventUrlPrompt,
        // liveEventUrlErrMsg,

        // convertPageSectionsLabel,
        // createPageSectionLabel,
        // uploadFilesLabel,

        // pageSectionLabel,
        // pageSectionsLabel,
        // inProgressLabel,
        // startLabel,
        // endLabel,
        // notStartedLabel,
    
        // dateCreatedLabel,
    
        // bannerUrlLabel,
        // bannerUrlPrompt,
        // bannerUrlErrMsg,

        // yourTimeZoneMsg,
        // timeZoneLabel,
        // cloneEventLabel,
    
        // toLabel,
        // ofLabel,
        // recordsLabel,
        // noRecordsFound,

        // gridCategory,
        // editLabel,
        // deleteLabel,

        // operationSuccessMsg,
        // operationFailureMsg,
        // duplicateTransMsg,
  
        // loadingText,
        // noOptionsText,
        // clearText,
        // closeText,
        // showLabel,
        // hideLabel,

        // cancelLabel,
        // clearLabel,
        // okLabel,

        // yesLabel,
        // deleteRequested,
        // areYouSureMsg,
        // requestedToDeleteMsg,
    
        // uploadLabel,
        
        // createPageSectionLabel,
        // updatePageSectionLabel,

        // createMoreLabel,
        // createLabel,
        // cloneRecordLabel,
        // closeLabel,
        // noPageSectionRecordsFound, 

        // sectionDescLabel,
        // sectionTitleLabel,
        // createPageSectionLabel,

        // gridPageHeader,
        // gridPageName,
        // gridPageTags,
        // lastUpdateLabel,
        // gridStartTime,
        // gridBannerUrl,
    
        // convertLabel,

        // sourceLanguageLabel,
        // sourceLanguagePrompt,
        // sourceLanguageErrMsg,

        // targetLanguageLabel,
        // targetLanguagePrompt,
        // targetLanguageErrMsg,

        // moreLabel,

        // organizeLabel,
        // organizePageSectionsLabel,
        // availablePageSectionsLabel,
    } = pageLabelsRes;

    async function fetchDbResources(cacheItemKey) {
        return new Promise((resolve, reject) => {
            backdropProgress(true);

            let keys = [];
            let objKeys = Object.keys(pageLabels);
            // console.log("In PricingPage(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
            if (objKeys !== null && objKeys.length > 0) {
                objKeys.map((key) => {
                    let partitionKey = appContext.currentLanguage.languageCode;
                    partitionKey += ".";
                    partitionKey += GenerateModulusHash(key, 200);

                    let itemKey = {
                    ResourcePK: partitionKey, // e.g. 'en.91'
                    ResourceSK: key, // e.g. 'firstName'
                    };
                    keys.push(itemKey);
                });
            }
            // console.log("In PricingPage(): fetchDbResources(): keys.length is: ", keys.length);
            // console.log("In PricingPage(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

            let arrayData = [];

            backdropProgress(true);

            let params = {
                apiName: "ResourceAPI",
                path: "/dynamodb/resources/batchGet",
                payload: {
                    keys: keys,
                    projectionExpression: "ResourceCode,ResourceDesc",
                },
            };

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
                response.data.Responses.resource.map((item, index) => {
                let record = {
                    ResourceCode: item.ResourceCode,
                    ResourceDesc: item.ResourceDesc,
                };

                arrayData.push(record);
                });
                        
                if (arrayData && arrayData.length > 0) {
                    arrayData.map((item) => {
                        if (item.ResourceCode === "pricingHdrLabel") pageLabels.pricingHdrLabel = item.ResourceDesc;
                        if (item.ResourceCode === "pricingHdrMsg1") pageLabels.pricingHdrMsg1 = item.ResourceDesc;
                        
                        if (item.ResourceCode === "pricingHdrMsg2") pageLabels.pricingHdrMsg2 = item.ResourceDesc;
                        if (item.ResourceCode === "signUpLabel") pageLabels.signUpLabel = item.ResourceDesc;
                        if (item.ResourceCode === "signUpFreeLabel") pageLabels.signUpFreeLabel = item.ResourceDesc;
                        
                        if (item.ResourceCode === "contactUsLabel") pageLabels.contactUsLabel = item.ResourceDesc;
                        if (item.ResourceCode === "signUpForFreeLabel") pageLabels.signUpForFreeLabel = item.ResourceDesc;
                        if (item.ResourceCode === "getStartedLabel") pageLabels.getStartedLabel = item.ResourceDesc;

                        if (item.ResourceCode === "letsChatLabel") pageLabels.letsChatLabel = item.ResourceDesc;
                        if (item.ResourceCode === "subscribeLabel") pageLabels.subscribeLabel = item.ResourceDesc;
                        if (item.ResourceCode === "choosePlanLabel") pageLabels.choosePlanLabel = item.ResourceDesc;
                        
                        if (item.ResourceCode === "monthLabel") pageLabels.monthLabel = item.ResourceDesc;
                        if (item.ResourceCode === "yearLabel") pageLabels.yearLabel = item.ResourceDesc;

                        if (item.ResourceCode === "faqsLabel") pageLabels.faqsLabel = item.ResourceDesc;
                        if (item.ResourceCode === "faqsMsg") pageLabels.faqsMsg = item.ResourceDesc;

                        if (item.ResourceCode === "yearlyLabel") pageLabels.yearlyLabel = item.ResourceDesc;
                        if (item.ResourceCode === "monthlyLabel") pageLabels.monthlyLabel = item.ResourceDesc;
                        if (item.ResourceCode === "saveLcLabel") pageLabels.saveLcLabel = item.ResourceDesc;
                        if (item.ResourceCode === "savingsLcLabel") pageLabels.savingsLcLabel = item.ResourceDesc;

                        // if (item.ResourceCode === "pageNameMsg") pageLabels.pageNameMsg = item.ResourceDesc;
                        // // if (item.ResourceCode === "selectTitlePrompt") pageLabels.selectTitlePrompt = item.ResourceDesc;
                        
                        // if (item.ResourceCode === "startTimeLabel") pageLabels.startTimeLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "startTimePrompt") pageLabels.startTimePrompt = item.ResourceDesc;
                        // if (item.ResourceCode === "startTimeErrMsg") pageLabels.startTimeErrMsg = item.ResourceDesc;

                        // if (item.ResourceCode === "endTimeLabel") pageLabels.endTimeLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "endTimePrompt") pageLabels.endTimePrompt = item.ResourceDesc;
                        // if (item.ResourceCode === "endTimeErrMsg") pageLabels.endTimeErrMsg = item.ResourceDesc;
                        // if (item.ResourceCode === "endTimeBeforeErrMsg") pageLabels.endTimeBeforeErrMsg = item.ResourceDesc;
                                                        
                        // if (item.ResourceCode === "pageHeaderLabel") pageLabels.pageHeaderLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "pageHeaderPrompt") pageLabels.pageHeaderPrompt = item.ResourceDesc;
                        // if (item.ResourceCode === "pageHeaderErrMsg") pageLabels.pageHeaderErrMsg = item.ResourceDesc;
                        // if (item.ResourceCode === "pageHeaderMsg") pageLabels.pageHeaderMsg = item.ResourceDesc;
                                    
                        // if (item.ResourceCode === "startTimeLabel") pageLabels.startTimeLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "startTimePrompt") pageLabels.startTimePrompt = item.ResourceDesc;
                        // if (item.ResourceCode === "startTimeErrMsg") pageLabels.startTimeErrMsg = item.ResourceDesc;
                
                        // if (item.ResourceCode === "uploadPageNameLabel") pageLabels.uploadPageNameLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "uploadPageNamePrompt") pageLabels.uploadPageNamePrompt = item.ResourceDesc;
                        // if (item.ResourceCode === "uploadPageNameErrMsg") pageLabels.uploadPageNameErrMsg = item.ResourceDesc;
                        
                        // if (item.ResourceCode === "convertPageSectionsLabel") pageLabels.convertPageSectionsLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "createPageSectionLabel") pageLabels.createPageSectionLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "uploadFilesLabel") pageLabels.uploadFilesLabel = item.ResourceDesc;
                                        
                        // if (item.ResourceCode === "yourTimeZoneMsg") pageLabels.yourTimeZoneMsg = item.ResourceDesc;
                        // if (item.ResourceCode === "timeZoneLabel") pageLabels.timeZoneLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "cloneEventLabel") pageLabels.cloneEventLabel = item.ResourceDesc;
                                    
                        // if (item.ResourceCode === "pageSectionsLabel") pageLabels.pageSectionsLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "inProgressLabel") pageLabels.inProgressLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "startLabel") pageLabels.startLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "endLabel") pageLabels.endLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "notStartedLabel") pageLabels.notStartedLabel = item.ResourceDesc;
                        
                        // if (item.ResourceCode === "dateCreatedLabel") pageLabels.dateCreatedLabel = item.ResourceDesc;
                        
                        // if (item.ResourceCode === "bannerUrlLabel") pageLabels.bannerUrlLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "bannerUrlPrompt") pageLabels.bannerUrlPrompt = item.ResourceDesc;
                        // if (item.ResourceCode === "bannerUrlErrMsg") pageLabels.bannerUrlErrMsg = item.ResourceDesc; 

                        // if (item.ResourceCode === "toLabel") pageLabels.toLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "ofLabel") pageLabels.ofLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "recordsLabel") pageLabels.recordsLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "noRecordsFound") pageLabels.noRecordsFound = item.ResourceDesc;

                        // if (item.ResourceCode === "createLabel") pageLabels.createLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "gridCategory") pageLabels.gridCategory = item.ResourceDesc;
                        // if (item.ResourceCode === "editLabel") pageLabels.editLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "deleteLabel") pageLabels.deleteLabel = item.ResourceDesc;

                        // if (item.ResourceCode === "operationSuccessMsg") pageLabels.operationSuccessMsg = item.ResourceDesc;
                        // if (item.ResourceCode === "operationFailureMsg") pageLabels.operationFailureMsg = item.ResourceDesc;
                        // if (item.ResourceCode === "duplicateTransMsg") pageLabels.duplicateTransMsg = item.ResourceDesc;
                                                                            
                        // if (item.ResourceCode === "loadingText") pageLabels.loadingText = item.ResourceDesc;
                        // if (item.ResourceCode === "noOptionsText") pageLabels.noOptionsText = item.ResourceDesc;
                        // if (item.ResourceCode === "clearText") pageLabels.clearText = item.ResourceDesc;
                        // if (item.ResourceCode === "closeText") pageLabels.closeText = item.ResourceDesc;
                        // // if (item.ResourceCode === "showLabel") pageLabels.showLabel = item.ResourceDesc;
                        // // if (item.ResourceCode === "hideLabel") pageLabels.hideLabel = item.ResourceDesc;
                        
                        // if (item.ResourceCode === "cancelLabel") pageLabels.cancelLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "clearLabel") pageLabels.clearLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "okLabel") pageLabels.okLabel = item.ResourceDesc;
                        
                        // if (item.ResourceCode === "yesLabel") pageLabels.yesLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "deleteRequested") pageLabels.deleteRequested = item.ResourceDesc;
                        // if (item.ResourceCode === "areYouSureMsg") pageLabels.areYouSureMsg = item.ResourceDesc;
                        // if (item.ResourceCode === "requestedToDeleteMsg") pageLabels.requestedToDeleteMsg = item.ResourceDesc;
                
                        // if (item.ResourceCode === "uploadLabel") pageLabels.uploadLabel = item.ResourceDesc;
            
                        // if (item.ResourceCode === "createPageSectionLabel") pageLabels.createPageSectionLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "updatePageSectionLabel") pageLabels.updatePageSectionLabel = item.ResourceDesc;
                                
                        // if (item.ResourceCode === "pageSectionLabel") pageLabels.pageSectionLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "createMoreLabel") pageLabels.createMoreLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "cloneRecordLabel") pageLabels.cloneRecordLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "closeLabel") pageLabels.closeLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "noPageSectionRecordsFound") pageLabels.noPageSectionRecordsFound = item.ResourceDesc;

                        // if (item.ResourceCode === "sectionDescLabel") pageLabels.sectionDescLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "sectionTitleLabel") pageLabels.sectionTitleLabel = item.ResourceDesc;
                        // // if (item.ResourceCode === "createPageSectionLabel") pageLabels.createPageSectionLabel = item.ResourceDesc;
                            
                        // if (item.ResourceCode === "gridPageHeader") pageLabels.gridPageHeader = item.ResourceDesc;
                        // if (item.ResourceCode === "gridPageTags") pageLabels.gridPageTags = item.ResourceDesc;
                        // if (item.ResourceCode === "lastUpdateLabel") pageLabels.lastUpdateLabel = item.ResourceDesc;
                                                
                        // if (item.ResourceCode === "gridStartTime") pageLabels.gridStartTime = item.ResourceDesc;
                        // if (item.ResourceCode === "gridBannerUrl") pageLabels.gridBannerUrl = item.ResourceDesc;
                        // if (item.ResourceCode === "gridPageName") pageLabels.gridPageName = item.ResourceDesc;
                                                
                        // if (item.ResourceCode === "convertLabel") pageLabels.convertLabel = item.ResourceDesc;
                        
                        // if (item.ResourceCode === "sourceLanguageLabel") pageLabels.sourceLanguageLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "sourceLanguagePrompt") pageLabels.sourceLanguagePrompt = item.ResourceDesc;
                        // if (item.ResourceCode === "sourceLanguageErrMsg") pageLabels.sourceLanguageErrMsg = item.ResourceDesc;
                        
                        // if (item.ResourceCode === "targetLanguageLabel") pageLabels.targetLanguageLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "targetLanguagePrompt") pageLabels.targetLanguagePrompt = item.ResourceDesc;
                        // if (item.ResourceCode === "targetLanguageErrMsg") pageLabels.targetLanguageErrMsg = item.ResourceDesc;

                        // if (item.ResourceCode === "moreLabel") pageLabels.moreLabel = item.ResourceDesc;
                        
                        // if (item.ResourceCode === "organizeLabel") pageLabels.organizeLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "organizePageSectionsLabel") pageLabels.organizePageSectionsLabel = item.ResourceDesc;
                        // if (item.ResourceCode === "availablePageSectionsLabel") pageLabels.availablePageSectionsLabel = item.ResourceDesc;
                    });
                    // Add it to cache
                    SetCacheItem(cacheItemKey, pageLabels);
                }

                setPageLabelsRes(pageLabels);
                backdropProgress(true);
                backdropProgress(false);
                setRenderDomFlag(true);
                resolve(pageLabels);
            }).catch((err) => {
                console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
                setPageLabelsRes(pageLabels);
                backdropProgress(true);
                backdropProgress(false);
                setRenderDomFlag(true);
                reject("Error in fetchDbResources")
            });
        });
    }
    
    let priceHdrNextToken = null;

    async function fetchPriceHeaderRecords() {
        // appContext.domainName = "cloudobituary.com";// For Testing

        // console.log('In fetchPriceHeaderRecords(): appContext.domainName is: ', appContext.domainName);
        // console.log('In fetchPriceHeaderRecords(): appContext.currentLanguage is: ', appContext.currentLanguage);

        backdropProgress(true);
        setPriceHdrRecords([]);

        return new Promise((resolve, reject) => {
            if (appContext.currentLanguage && appContext.domainName) {
                let params = {
                    apiName: "PriceAPI",
                    path: "/dynamodb/price/query",
                    payload: {
                        keyConditionExpression:
                            // "#pricePK = :pricePK",
                            "#pricePK = :pricePK AND begins_with(#priceSK, :priceSK)",
                        expressionAttributeNames: {
                            "#pricePK": "PricePK",
                            "#priceSK": "PriceSK",
                        },
                        expressionAttributeValues: {
                            ":pricePK": appContext.domainName + "#" + appContext.currentLanguage.languageCode + "#price#header",
                            ":priceSK": appContext.domainName + "#" + appContext.currentLanguage.languageCode + "#price#header",
                        },
                        // indexName: "PriceDomain-LangAuthorUserName-index"
                            // "keyConditionExpression": "#pricePK = :pricePK",
                            // "expressionAttributeNames": {"#pricePK": "PricePK"},
                            // "expressionAttributeValues": {":pricePK": "languages"}
                    },
                };

                if (!lodash.isEmpty(priceHdrNextToken)) {
                    params.payload.nextToken = priceHdrNextToken;
                }
        
                if (!lodash.isEmpty(pageSize)) {
                    params.payload.pageSize = pageSize;
                }

                let initObj = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "",
                    },
                    response: true, // return entire response object instead of response.data
                    body: {},
                };
        
                initObj.headers.Authorization = appContext.jwtToken;
                initObj.body = params.payload;
                // console.log('In fetchPriceHeaderRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
        
                API.post(params.apiName, params.path, initObj).then(async (response) => {
                    // console.log('In fetchPriceHeaderRecords(): response is: ', JSON.stringify(response, null, 2));
                    // console.log('In fetchPriceHeaderRecords(): response.data.NextToken is: ', response.data.NextToken);
                    // console.log('In fetchPriceHeaderRecords(): response.data.Items is: ', response.data.Items);

                    let records = [];

                    priceHdrNextToken = null;

                    if (response && !lodash.isEmpty(response.data.NextToken)) {
                        priceHdrNextToken = response.data.NextToken;
                        records = priceHdrRecords;
                    }
                
                    let items = response.data.Items;

                    if (items && items.length > 0) {
                        setPriceHdrRecords([]);

                        items.map((record) => {
                            // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                            // console.log('In fetchPriceHeaderRecords(): formatedDateTime is: ', formatedDateTime);
                            // console.log('In fetchPriceHeaderRecords(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
                            let formattedDateTime = null;
                            if (record.UpdatedAt) {
                                formattedDateTime = FormatTimezoneDates(record.UpdatedAt, record.LanguageCode, timeZone, "shortDateAndTime");
                            }
                            // console.log('In fetchPriceHeaderRecords(): formattedDateTime is: ', formattedDateTime);

                            let formattedCurrency = "";
                            if (record.AmountPerMonth && record.PriceCurrency) {
                                // new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
                                // FormatCurrency = (amount, currencyCode, localeCode)
                                formattedCurrency = FormatCurrency(JSON.parse(record.AmountPerMonth), record.PriceCurrency.value, record.LanguageCode);
                            }
                            // console.log('In fetchPriceHeaderRecords(): formattedCurrency is: ', formattedCurrency);

                            let formattedNumber = "";
                            if (record.YearlyDiscount) {
                                // new Intl.NumberFormat(localeCode).format(amount);
                                // FormatNumber(amount, localeCode)
                                // formattedNumber = FormatNumber(JSON.parse(record.YearlyDiscount), "en-US");
                                let option = {
                                    style: 'percent',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                };
                                formattedNumber = FormatNumberWithOption(JSON.parse(record.YearlyDiscount), record.LanguageCode, option); 
                            }
                            // console.log('In fetchPriceHeaderRecords(): formattedNumber is: ', formattedNumber);

                            let formattedAmountPerYear = "";
                            if (record.AmountPerMonth && record.PriceCurrency && record.YearlyDiscount) {
                                // Calculate Yearly Price
                                let yearlyPrice = ((record.AmountPerMonth * 12 * (100.00 - JSON.parse(record.YearlyDiscount))) / 100.00);
                                // new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
                                // FormatCurrency = (amount, currencyCode, localeCode)
                                if (yearlyPrice) {
                                    formattedAmountPerYear = FormatCurrency(yearlyPrice, record.PriceCurrency.value, record.LanguageCode);
                                }
                            }
                            // console.log('In fetchPriceHeaderRecords(): formattedAmountPerYear is: ', formattedAmountPerYear);

                            let item = {
                                PricePK: record.PricePK,
                                PriceSK: record.PriceSK,

                                LanguageObject: record.LanguageObject,
                                LanguageCode: record.LanguageCode,
                                Domain: record.Domain,

                                "PriceUnit": lodash.isEmpty(record.PriceUnit) ? null : record.PriceUnit,
                                "PriceCurrency": lodash.isEmpty(record.PriceCurrency) ? null : record.PriceCurrency,

                                "PriceId": lodash.isEmpty(record.PriceId) ? "" : record.PriceId,

                                "PriceDomain": lodash.isEmpty(record.Domain) ? "" : record.Domain.value,
                                "PriceCategoryText": lodash.isEmpty(record.PriceCategory) ? "" : record.PriceCategory.text,
                                "PriceComments": lodash.isEmpty(record.PriceComments) ? "" : record.PriceComments,

                                "AmountPerMonth": formattedCurrency,
                                "YearlyDiscount": formattedNumber,
                                "AmountPerYear": formattedAmountPerYear,

                                "PriceHdrSeqNumber": record.PriceHdrSeqNumber,

                                "FormattedDateTime": formattedDateTime,
                                "UpdatedAt": record.UpdatedAt,
                            };
                            records.push(item);
                        });
                    } else {
                        backdropProgress(false);
                        resolve(records)
                    }

                    // console.log('In fetchPriceHeaderRecords(): priceHdrNextToken is: ', priceHdrNextToken);
                    if (!lodash.isEmpty(priceHdrNextToken)) {
                        await fetchPriceHeaderRecords();
                    }
                    
                    setTimeout(() => {
                        records.sort((a, b) => (a.PriceHdrSeqNumber > b.PriceHdrSeqNumber ? 1 : -1));// asc
                        // console.log('In fetchPriceHeaderRecords(): records is: ', records);
                        setPriceHdrRecords(records);
                    }, 100);

                    backdropProgress(false);
                    resolve(records)
                }).catch((err) => {
                    console.error("In fetchPriceHeaderRecords(): err is: ", JSON.stringify(err, null, 2));
                    setPriceHdrRecords([]);
                    backdropProgress(false);
                    reject("Error in fetchPriceHeaderRecords")
                });
            } else {
                reject("Error in fetchPriceHeaderRecords")
            }
        });
    }
    
    let listNextToken = null;

    async function fetchPriceListRecords() {
        // appContext.domainName = "cloudobituary.com";// For Testing

        // console.log('In fetchPriceListRecords(): appContext.domainName is: ', appContext.domainName);
        // console.log('In fetchPriceListRecords(): appContext.currentLanguage is: ', appContext.currentLanguage);

        backdropProgress(true);
        setPriceHdrRecords([]);

        return new Promise((resolve, reject) => {
            if (appContext.currentLanguage && appContext.domainName) {
                // setNoRecordFoundFlag(false);
                // setPriceListRecordsBackup([]);

                let params = {
                    apiName: "PriceAPI",
                    path: "/dynamodb/price/query",
                    payload: {
                        keyConditionExpression:
                            // "#pricePK = :pricePK",
                            "#pricePK = :pricePK AND begins_with(#priceSK, :priceSK)",
                        expressionAttributeNames: {
                            "#pricePK": "PricePK",
                            "#priceSK": "PriceSK",
                        },
                        expressionAttributeValues: {
                            ":pricePK": appContext.domainName + "#" + appContext.currentLanguage.languageCode + "#price#list",
                            ":priceSK": appContext.domainName + "#" + appContext.currentLanguage.languageCode + "#price#list",
                        },
                        // indexName: "PriceDomain-LangAuthorUserName-index"
                            // "keyConditionExpression": "#pricePK = :pricePK",
                            // "expressionAttributeNames": {"#pricePK": "PricePK"},
                            // "expressionAttributeValues": {":pricePK": "languages"}
                    },
                };

                if (!lodash.isEmpty(listNextToken)) {
                    params.payload.nextToken = listNextToken;
                }
        
                if (!lodash.isEmpty(pageSize)) {
                    params.payload.pageSize = pageSize;
                }

                let initObj = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "",
                    },
                    response: true, // return entire response object instead of response.data
                    body: {},
                };
        
                initObj.headers.Authorization = appContext.jwtToken;
                initObj.body = params.payload;
                // console.log('In fetchPriceListRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
        
                API.post(params.apiName, params.path, initObj).then(async (response) => {
                    // console.log('In fetchPriceListRecords(): response is: ', JSON.stringify(response, null, 2));
                    // console.log('In fetchPriceListRecords(): response.data.NextToken is: ', response.data.NextToken);
                    // console.log('In fetchPriceListRecords(): response.data.Items is: ', response.data.Items);

                    let records = [];

                    listNextToken = null;

                    if (response && !lodash.isEmpty(response.data.NextToken)) {
                        listNextToken = response.data.NextToken;
                        records = priceListRecords;
                    }
                
                    let items = response.data.Items;

                    if (items && items.length > 0) {
                        setPriceListRecords([]);

                        items.map((record) => {
                            // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                            // console.log('In fetchPriceListRecords(): formatedDateTime is: ', formatedDateTime);
                            // console.log('In fetchPriceListRecords(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
                            let formattedDateTime = null;
                            if (record.UpdatedAt) {
                                formattedDateTime = FormatTimezoneDates(record.UpdatedAt, record.LanguageCode, timeZone, "shortDateAndTime");
                            }
                            // console.log('In fetchPriceListRecords(): formattedDateTime is: ', formattedDateTime);

                            let item = {
                                PricePK: record.PricePK,
                                PriceSK: record.PriceSK,

                                LanguageObject: record.LanguageObject,
                                LanguageCode: record.LanguageCode,
                                Domain: record.Domain,

                                "PriceId": lodash.isEmpty(record.PriceId) ? "" : record.PriceId,

                                "PriceDomain": lodash.isEmpty(record.PriceDomain) ? "" : record.PriceDomain,
                                "PriceCategory": lodash.isEmpty(record.PriceCategory) ? null : record.PriceCategory,
                                "PriceCategoryText": lodash.isEmpty(record.PriceCategory) ? "" : record.PriceCategory.text,
                                "PriceItem": lodash.isEmpty(record.PriceItem) ? "" : record.PriceItem,
                                "PriceDetails": lodash.isEmpty(record.PriceDetails) ? "" : record.PriceDetails,

                                "PriceItemSeqNumber": record.PriceItemSeqNumber,
                
                                "FormattedDateTime": formattedDateTime,
                                "UpdatedAt": record.UpdatedAt,
                            };
                            records.push(item);
                        });
                    } else {
                        backdropProgress(false);
                        resolve(records)
                    }

                    // console.log('In fetchPriceListRecords(): listNextToken is: ', listNextToken);
                    if (!lodash.isEmpty(listNextToken)) {
                        await fetchPriceListRecords();
                    }
                    
                    setTimeout(() => {
                        records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                        setPriceListRecords(records);
                        // setPriceListRecordsBackup(records);
                    }, 100);

                    backdropProgress(false);
                    resolve(records)
                }).catch((err) => {
                    console.error("In fetchPriceListRecords(): err is: ", JSON.stringify(err, null, 2));
                    setPriceListRecords([]);
                    // setNoRecordFoundFlag(true);
                    backdropProgress(false);
                    reject("Error in fetchPriceListRecords()");
                });
            } else {
                backdropProgress(false);
                reject("Error in fetchPriceListRecords()");
            }
        });
    }
    
    function sortTwoColumns(data) {
        console.log("In sortTwoColumns(): data is: ", data);
        let retVal = null;
        if (data && data.length > 0) {
            data.sort(function (a, b) {
                retVal = a.FaqCategory.value.localeCompare(b.FaqCategory.value) || b.FaqSeqNumber - a.FaqSeqNumber;
            });
        }
        console.log("In sortTwoColumns(): retVal is: ", retVal);
        return retVal;
    }

    let faqNextToken = null;

    async function fetchFaqRecords() {
        // console.log('In fetchFaqRecords(): appContext.domainName is: ', appContext.domainName);
        // console.log('In fetchFaqRecords(): appContext.currentLanguage is: ', appContext.currentLanguage);

        return new Promise((resolve, reject) => {
            backdropProgress(true);

            let params = {
                apiName: "FaqAPI",
                path: "/dynamodb/faq/query",
                payload: {
                    keyConditionExpression:
                        // "#faqPK = :faqPK",
                        "#faqPK = :faqPK AND begins_with(#faqSK, :faqSK)",
                    expressionAttributeNames: {
                        "#faqPK": "FaqPK",
                        "#faqSK": "FaqSK",
                    },
                    expressionAttributeValues: {
                        ":faqPK": appContext.domainName + "#" + appContext.currentLanguage.languageCode + "#faq",
                        ":faqSK": appContext.domainName + "#" + appContext.currentLanguage.languageCode + "#faq",
                    },
                    // indexName: "FaqDomain-LangAuthorUserName-index"
                        // "keyConditionExpression": "#faqPK = :faqPK",
                        // "expressionAttributeNames": {"#faqPK": "FaqPK"},
                        // "expressionAttributeValues": {":faqPK": "languages"}
                },
            };

            if (!lodash.isEmpty(faqNextToken)) {
                params.payload.nextToken = faqNextToken;
            }
    
            if (!lodash.isEmpty(pageSize)) {
                params.payload.pageSize = pageSize;
            }

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchFaqRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchFaqRecords(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchFaqRecords(): response.data.NextToken is: ', response.data.NextToken);
                // console.log('In fetchFaqRecords(): response.data.Items is: ', response.data.Items);

                let records = [];

                faqNextToken = null;

                if (response && !lodash.isEmpty(response.data.NextToken)) {
                    faqNextToken = response.data.NextToken;
                    records = faqListRecords;
                }
            
                let items = response.data.Items;

                if (items && items.length > 0) {
                    setFaqListRecords([]);

                    items.map((record) => {
                        // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                        // console.log('In fetchFaqRecords(): formatedDateTime is: ', formatedDateTime);
                        // console.log('In fetchFaqRecords(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
                        let formattedDateTime = null;
                        if (record.UpdatedAt) {
                            formattedDateTime = FormatTimezoneDates(record.UpdatedAt, record.LanguageCode, timeZone, "shortDateAndTime");
                        }
                        // console.log('In fetchFaqRecords(): formattedDateTime is: ', formattedDateTime);

                        let item = {
                            FaqPK: record.FaqPK,
                            FaqSK: record.FaqSK,

                            LanguageObject: record.LanguageObject,
                            LanguageCode: record.LanguageCode,
                            Domain: record.Domain,

                            "FaqId": lodash.isEmpty(record.FaqId) ? "" : record.FaqId,

                            "FaqDomain": lodash.isEmpty(record.Domain) ? "" : record.Domain.value,
                            "FaqCategory": lodash.isEmpty(record.FaqCategory) ? null : record.FaqCategory,
                            "FaqCategoryText": lodash.isEmpty(record.FaqCategory) ? "" : record.FaqCategory.text,
                            "FaqQuestion": lodash.isEmpty(record.FaqQuestion) ? "" : record.FaqQuestion,
                            "FaqAnswer": lodash.isEmpty(record.FaqAnswer) ? "" : record.FaqAnswer,

                            "FaqSeqNumber": record.FaqSeqNumber,
            
                            "FormattedDateTime": formattedDateTime,
                            "UpdatedAt": record.UpdatedAt,
                        };
                        records.push(item);
                    });
                } else {
                    backdropProgress(false);
                    resolve(records);
                }

                // console.log('In fetchFaqRecords(): faqNextToken is: ', faqNextToken);
                if (!lodash.isEmpty(faqNextToken)) {
                    await fetchFaqRecords();
                }
                
                setTimeout(() => {
                    // records = sortTwoColumns(records);
                    records.sort((a, b) => a.FaqCategory.value.localeCompare(b.FaqCategory.value) || a.FaqSeqNumber - b.FaqSeqNumber);
                    // records.sort((a, b) => (a.FaqSeqNumber > b.FaqSeqNumber ? 1 : -1));// asc
                    // console.log('In fetchFaqRecords(): records is: ', records);
                    setPrevCategory((records && records.length > 0) ? records[0].FaqCategoryText : "");
                    setFaqListRecords(records);
                }, 100);

                backdropProgress(false);
                resolve(records);
            }).catch((err) => {
                console.error("In fetchFaqRecords(): err is: ", JSON.stringify(err, null, 2));
                setFaqListRecords([]);
                backdropProgress(false);
                reject("Error in fetchFaqRecords()");
            });
        });
    }

    async function aggregatePricingRecords(headerRecords, listRecords) {
        // console.log("In aggregatePricingRecords(): headerRecords is: ", headerRecords);
        // console.log("In aggregatePricingRecords(): listRecords is: ", listRecords);

        return new Promise((resolve, reject) => {
            let planCategories = [];

            let pricePlan1 = {};
            let pricePlan2 = {};
            let pricePlan3 = {};
            let pricePlan4 = {};

            let listItem = {};
                    
            // Handle Price Header
            if (headerRecords && headerRecords.length > 0) {
                headerRecords.map((record, index) => {
                    switch(index) {
                        case 0:
                            planCategories.push({ind: 0, priceCat: record.PriceCategoryText});
                            pricePlan1.PriceCategoryText = record.PriceCategoryText; 
                            pricePlan1.PriceComments = record.PriceComments; 
                            pricePlan1.AmountPerMonth = record.AmountPerMonth; 
                            pricePlan1.PriceUnit = record.PriceUnit; // Object
                            pricePlan1.YearlyDiscount = record.YearlyDiscount; 
                            pricePlan1.AmountPerYear = record.AmountPerYear; 
                            pricePlan1.PriceCurrency = record.PriceCurrency; // Object
                            pricePlan1.priceItems = [];
                            break;
                        case 1:
                            planCategories.push({ind: 1, priceCat: record.PriceCategoryText});
                            pricePlan2.PriceCategoryText = record.PriceCategoryText; 
                            pricePlan2.PriceComments = record.PriceComments; 
                            pricePlan2.AmountPerMonth = record.AmountPerMonth; 
                            pricePlan2.PriceUnit = record.PriceUnit; // Object
                            pricePlan2.YearlyDiscount = record.YearlyDiscount; 
                            pricePlan2.AmountPerYear = record.AmountPerYear; 
                            pricePlan2.PriceCurrency = record.PriceCurrency; // Object
                            pricePlan2.priceItems = [];
                            break;
                        case 2:
                            planCategories.push({ind: 2, priceCat: record.PriceCategoryText});
                            pricePlan3.PriceCategoryText = record.PriceCategoryText; 
                            pricePlan3.PriceComments = record.PriceComments; 
                            pricePlan3.AmountPerMonth = record.AmountPerMonth; 
                            pricePlan3.PriceUnit = record.PriceUnit; // Object
                            pricePlan3.YearlyDiscount = record.YearlyDiscount; 
                            pricePlan3.AmountPerYear = record.AmountPerYear; 
                            pricePlan3.PriceCurrency = record.PriceCurrency; // Object
                            pricePlan3.priceItems = [];
                            break;
                        case 3:
                            planCategories.push({ind: 3, priceCat: record.PriceCategoryText});
                            pricePlan4.PriceCategoryText = record.PriceCategoryText; 
                            pricePlan4.PriceComments = record.PriceComments; 
                            pricePlan4.AmountPerMonth = record.AmountPerMonth; 
                            pricePlan4.PriceUnit = record.PriceUnit; // Object
                            pricePlan4.YearlyDiscount = record.YearlyDiscount; 
                            pricePlan4.AmountPerYear = record.AmountPerYear; 
                            pricePlan4.PriceCurrency = record.PriceCurrency; // Object
                            pricePlan4.priceItems = [];
                            break;
                        default:
                            break;
                    }
                });
            }
            // console.log("In aggregatePricingRecords(): planCategories is: ", planCategories);
            
            // Handle Price Items
            if ((planCategories && planCategories.length > 0) && (listRecords && listRecords.length > 0)) {
                planCategories.map((record, index) => {
                    switch(record.ind) {
                        case 0:
                            let filteredRecs1 = listRecords.filter(item => item.PriceCategoryText === record.priceCat);
                            // console.log("In aggregatePricingRecords(): case 0: filteredRecs1 is: ", filteredRecs1);
                            if (filteredRecs1 && filteredRecs1.length > 0) {
                                filteredRecs1.sort((a, b) => (b.PriceItemSeqNumber > a.PriceItemSeqNumber ? 1 : -1));
                                pricePlan1.priceItems = filteredRecs1;

                                setPricePlanRecord1(pricePlan1);
                            }
                            // listItem.PriceItem = record.PriceItem;
                            // listItem.PriceDetails = record.PriceDetails;
                            break;
                        case 1:
                            let filteredRecs2 = listRecords.filter(item => item.PriceCategoryText === record.priceCat);
                            // console.log("In aggregatePricingRecords(): case 1: filteredRecs2 is: ", filteredRecs2);
                            if (filteredRecs2 && filteredRecs2.length > 0) {
                                filteredRecs2.sort((a, b) => (b.PriceItemSeqNumber > a.PriceItemSeqNumber ? 1 : -1));
                                pricePlan2.priceItems = filteredRecs2;

                                setPricePlanRecord2(pricePlan2);
                            }
                            break;
                        case 2:
                            let filteredRecs3 = listRecords.filter(item => item.PriceCategoryText === record.priceCat);
                            // console.log("In aggregatePricingRecords(): case 2: filteredRecs3 is: ", filteredRecs3);
                            if (filteredRecs3 && filteredRecs3.length > 0) {
                                filteredRecs3.sort((a, b) => (b.PriceItemSeqNumber > a.PriceItemSeqNumber ? 2 : -2));
                                // console.log("In aggregatePricingRecords(): case 2: filteredRecs3 is: ", filteredRecs3);
                                pricePlan3.priceItems = filteredRecs3;

                                setPricePlanRecord3(pricePlan3);
                            }
                            break;
                        case 3:
                            let filteredRecs4 = listRecords.filter(item => item.PriceCategoryText === record.priceCat);
                            // console.log("In aggregatePricingRecords(): case 3: filteredRecs4 is: ", filteredRecs4);
                            if (filteredRecs4 && filteredRecs4.length > 0) {
                                filteredRecs4.sort((a, b) => (b.PriceItemSeqNumber > a.PriceItemSeqNumber ? 3 : -3));
                                pricePlan4.priceItems = filteredRecs4;

                                setPricePlanRecord4(pricePlan4);
                            }
                            break;
                        default:
                            break;
                    }
                });
            }

            // console.log("In aggregatePricingRecords(): pricePlan1 is: ", pricePlan1);
            // console.log("In aggregatePricingRecords(): pricePlan2 is: ", pricePlan2);
            // console.log("In aggregatePricingRecords(): pricePlan3 is: ", pricePlan3);
            // console.log("In aggregatePricingRecords(): pricePlan4 is: ", pricePlan4);

            setPricePlanRecord1(pricePlan1);
            setPricePlanRecord2(pricePlan2);
            setPricePlanRecord3(pricePlan3);
            setPricePlanRecord4(pricePlan4);

            resolve({
                pricePlan1: pricePlan1,
                pricePlan2: pricePlan2,
                pricePlan3: pricePlan3,
                pricePlan4: pricePlan4,
            });
        });
    }

    async function fetchWebPage() {

    }

    async function fetchDesignBlocksForWebPage() {

    }

    async function fetchDesignDetailsForDesignBlock() {

    }
        
    useEffect(() => {
        // console.log('In ManagePriceHeaders(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        // sessionStorage.removeItem("current-live-event");
    }, []);

    useLayoutEffect(() => {
        // console.log("In ManagePriceHeaders(): useLayoutEffect()");
        async function fetchItems() {
            // await fetchLanguages();

            await Promise.all([
                await fetchPriceHeaderRecords(),
                await fetchPriceListRecords(),
                await fetchFaqRecords()
            ]).then(async (result) =>{
                // console.log("In ManagePriceHeaders(): useLayoutEffect(): priceHdrRecords is: ", result[0]);
                // console.log("In ManagePriceHeaders(): useLayoutEffect(): priceListRecords is: ", result[1]);
                // console.log("In ManagePriceHeaders(): useLayoutEffect(): faqRecords is: ", result[2]);

                await Promise.all([
                    await aggregatePricingRecords(result[0], result[1])
                ]).then(async (result2) =>{
                    // console.log("In ManagePriceHeaders(): useLayoutEffect(): aggregatePricingRecords is: ", result2[0]);

                    // Fetch resource from catche
                    let cacheItemKey = "manage-price-headers";
                    cacheItemKey += ":";
                    cacheItemKey += appContext.currentLanguage.languageCode;
                    // console.log('In ManagePriceHeaders(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

                    if (
                        appContext.clearCacheLanguageCodes &&
                        appContext.clearCacheLanguageCodes.length > 0 &&
                        appContext.clearCacheLanguageCodes.includes(appContext.currentLanguage.languageCode)
                    ) {
                        // console.log("In ManagePriceHeaders(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                        RemoveCacheItem(cacheItemKey);
                    }

                    let cacheItem = GetCacheItem(cacheItemKey);
                    // console.log('In ManagePriceHeaders(): useLayoutEffect(): cacheItem is: ', cacheItem);
                    cacheItem = null; // remove after testing

                    if (cacheItem === null) {
                        // resource is not in cache, fetch from DB
                        // console.log('In ManagePriceHeaders(): useLayoutEffect(): resource is not in cache, fetch from DB');
                        await fetchDbResources(cacheItemKey);
                    } else {
                        // console.log('In ManagePriceHeaders(): useLayoutEffect(): got resource from cache');
                        setPageLabelsRes(cacheItem);
                        setRenderDomFlag(true);
                    }
                }).catch((error2) => {
                    console.error('In ManagePriceHeaders(): useLayoutEffect(): error2 is: ', error2);
                });
            }).catch((error) => {
                console.error('In ManagePriceHeaders(): useLayoutEffect(): error is: ', error);
            });
        }

        fetchItems();
    }, []);

    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    //----------------------------------
    // FrequentlyAskedQuestions (FAQs)
    //----------------------------------
    const [expanded, setExpanded] = React.useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
  
    function FrequentlyAskedQuestions() {
        return (
            <>
                {isDeskTopView &&
                    <>
                        {faqListRecords && faqListRecords.length > 0 &&
                            <div className={classes.accord}>
                                <Grid container justify="center">
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="h4" color="primary">
                                            {faqsLabel}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <LineSpacing value={12} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <Typography variant="subtitle2" color="primary">
                                            {faqsMsg}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <LineSpacing value={24} />
                                </Grid>

                                {faqListRecords && faqListRecords.length > 0 &&
                                    faqListRecords.map((record, index) => (
                                        <div key={index}>
                                            {(index > 0 && faqListRecords[index - 1].FaqCategoryText !== record.FaqCategoryText) ? 
                                                <>
                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={12} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="flex-start">
                                                            <Typography variant="h6" color="primary">
                                                                {record.FaqCategoryText}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={4} />
                                                    </Grid>
                                                </>
                                                :
                                                <>
                                                    {index == 0 &&
                                                        <>
                                                            <Grid item xs={12} sm={12}>
                                                                <Grid container justify="flex-start">
                                                                    <Typography variant="h6" color="primary">
                                                                        {record.FaqCategoryText}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={12} sm={12}>
                                                                <LineSpacing value={4} />
                                                            </Grid>
                                                        </>
                                                    }
                                                </>
                                            }

                                            <Accordion expanded={expanded === record.FaqId} onChange={handleAccordionChange(record.FaqId)}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography variant="subtitle2">{record.FaqQuestion}</Typography>
                                                </AccordionSummary>

                                                <AccordionDetails>
                                                    <Typography variant="body2">
                                                        {record.FaqAnswer}
                                                    </Typography>
                                                </AccordionDetails>

                                            </Accordion>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </>
                }
            </>
        )
    }

    // const [contents06Hdr, setContents06Hdr] = useState("Far far away...");
    // const [contents06Txt1, setContents06Txt1] = useState('Far far away, behind the word mountains, far from the countries <a href="https://www.froala.com">Vokalia</a> and <a href="https://www.froala.com">Consonantia</a>, there live the blind texts. Separated they live in Bookmarksgrove right at the coast ' +
    //                                     'of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.');
    // const [contents06Txt2, setContents06Txt2] = useState('On her way she met a copy. The copy warned the Little Blind Text, that where it came from it would have been rewritten a thousand times and everything that was left from its origin would be the word "and" and the <a href="https://www.froala.com">Little Blind Text</a>          should turn around and return to its own, safe country. But nothing the copy said could convince her and so it didn’t take long until a few insidious Copy Writers ambushed her, made her drunk with Longe and Parole and dragged her into their ' +
    //                                     'agency, where they abused her for their.');

    // const [contents07Hdr, setContents07Hdr] = useState("Froala Design Blocks");
    // const [contents07Txt1, setContents07Txt1] = useState('Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden ' +
    //                                                     'flows by their place far far away.');
    // const [contents07Txt2, setContents07Txt2] = useState('Separated they live in Bookmarksgrove right at the coast of the Semantics, far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at ' +
                                                        // 'the coast.');
                                        
    return (
        <div dir={direction} className={clsx(classes.root, classes.divWrapper)}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {renderDomFlag &&
                <>
                    {/* { !appContext.signedIn && <Redirect to='/signIn' />} */}
                    <CssBaseline />
                    <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />

                    {isDeskTopView && 
                        <div style={{width: '100%'}}>
                            <Pricings01 
                                direction={direction}
                                pricingHdrLabel={pricingHdrLabel} 
                                pricingHdrMsg1={pricingHdrMsg1}
                                pricingHdrMsg2={pricingHdrMsg2}
                                monthLabel={monthLabel}
                                yearLabel={yearLabel}
                                saveLcLabel={saveLcLabel}
                                savingsLcLabel={savingsLcLabel}
                                priceActionLabel={choosePlanLabel}
                                monthlyLabel={monthlyLabel}
                                yearlyLabel={yearlyLabel}
                                                
                                pricePlanRecord1={pricePlanRecord1}
                                pricePlanRecord2={pricePlanRecord2}
                                pricePlanRecord3={pricePlanRecord3}
                                pricePlanRecord4={pricePlanRecord4}
                            />

                            <section className="fdb-block">
                                <Container component="div" className={classes.main} maxWidth="lg">
                                    <FrequentlyAskedQuestions />
                                </Container>
                            </section>

                            <Contents35 
                                direction={direction}
                                webPage={"home"}
                            />

                            <Contents06 
                                direction={direction}
                                webPage={"home"}
                            />

                            <Contents07 
                                direction={direction}
                                webPage={"home"}
                            />
                        </div>
                    }

                    {isTabletView && 
                        <div style={{width: '100%'}}>
                            <Pricings01 
                                direction={direction}
                                pricingHdrLabel={pricingHdrLabel} 
                                pricingHdrMsg1={pricingHdrMsg1}
                                pricingHdrMsg2={pricingHdrMsg2}
                                monthLabel={monthLabel}
                                yearLabel={yearLabel}
                                saveLcLabel={saveLcLabel}
                                savingsLcLabel={savingsLcLabel}
                                priceActionLabel={choosePlanLabel}
                                monthlyLabel={monthlyLabel}
                                yearlyLabel={yearlyLabel}
                                                
                                pricePlanRecord1={pricePlanRecord1}
                                pricePlanRecord2={pricePlanRecord2}
                                pricePlanRecord3={pricePlanRecord3}
                                pricePlanRecord4={pricePlanRecord4}
                            />

                            <section className="fdb-block">
                                <Container component="div" className={classes.main} maxWidth="lg">
                                    <FrequentlyAskedQuestions />
                                </Container>
                            </section>

                            <Contents35 
                                direction={direction}
                                webPage={"home"}
                            />

                            <Contents06 
                                direction={direction}
                                webPage={"home"}
                            />

                            <Contents07 
                                direction={direction}
                                webPage={"home"}
                            />
                        </div>
                    }

                    {isMobileView && 
                        <div style={{width: '100%'}}>
                            <Pricings01 
                                direction={direction}
                                pricingHdrLabel={pricingHdrLabel} 
                                pricingHdrMsg1={pricingHdrMsg1}
                                pricingHdrMsg2={pricingHdrMsg2}
                                monthLabel={monthLabel}
                                yearLabel={yearLabel}
                                saveLcLabel={saveLcLabel}
                                savingsLcLabel={savingsLcLabel}
                                priceActionLabel={choosePlanLabel}
                                monthlyLabel={monthlyLabel}
                                yearlyLabel={yearlyLabel}
                                                
                                pricePlanRecord1={pricePlanRecord1}
                                pricePlanRecord2={pricePlanRecord2}
                                pricePlanRecord3={pricePlanRecord3}
                                pricePlanRecord4={pricePlanRecord4}
                            />

                            <section className="fdb-block">
                                <Container component="div" className={classes.main} maxWidth="lg">
                                    <FrequentlyAskedQuestions />
                                </Container>
                            </section>

                            <Contents35 
                                direction={direction}
                                webPage={"home"}
                            />

                            <Contents06 
                                direction={direction}
                                webPage={"home"}
                            />

                            <Contents07 
                                direction={direction}
                                webPage={"home"}
                            />
                        </div>
                    }

                    <Grid item xs={12} sm={12}>
                        <LineSpacing value={36} />
                    </Grid>
                </>
            }

            <footer className={classes.footer}>
                <div>
                    <PageFooter isSignedIn={appContext.signedIn} />
                </div>
            </footer>
        </div>
    )
}

export default PricingPage;

