import React from "react";
import styled from "styled-components";
//import { Redirect } from 'react-router-dom';

//import SetCacheItem from '../../utils/cache/SetCacheItem';
//import GetCacheItem from '../../utils/cache/GetCacheItem';

const PageContainer = styled.div`
  width: 100%;
  background: #fff;
  align-items: center;
`;

const Paragraph = styled.p`
  background: #fff;
  color: #4caf50;
  text-align: center;
`;

function Settings() {
  //const [ isSignedIn, setIsSignedIn ] = useState(false);

  /*useEffect(() => {
    let signedIn = GetCacheItem("isSignedIn");
    console.log("In Settings(): signedIn is: "+signedIn);
    setIsSignedIn(signedIn);
    
    ChatAPI.subscribeToFriendStatus(friendID, handleStatusChange);
    return () => {
      ChatAPI.unsubscribeFromFriendStatus(friendID, handleStatusChange);
    };
  }, []);*/

  // Check if user has signed in
  //   if (isSignedIn === false){
  //     console.log("In Settings(): About to redirect.");
  //     return <Redirect to='/signIn' />;
  //   }

  return (
    <PageContainer>
      <Paragraph>Settings Page</Paragraph>
    </PageContainer>
  );
}

export default Settings;
