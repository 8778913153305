import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
// import styled, { css } from 'styled-components';
import { makeStyles } from "@material-ui/core/styles";
// import Button from '@material-ui/core/Button';
import CssBaseline from "@material-ui/core/CssBaseline";
// import { NavLink } from 'react-router-dom';
// import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// AppContext
import AppContext from "../../config/AppContext";

Amplify.configure(AmplifyConfig);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#fff",
  },
  progress: {
    margin: theme.spacing(1),
  },
  pageContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mainGrid: {
    textAlign: "left",
  },
  titleGrid: {
    textAlign: "center",
  },
  title: {
    color: "#000",
    textAlign: "center",
  },
  subTitle: {
    color: "#000",
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  titleContent: {
    color: "#000",
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  bodyGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: "#fff",
  },
  bodyLC: {
    color: "#000",
    textAlign: "left",
    background: "#fff",
  },
  bodyContent: {
    color: "#000",
    textAlign: "left",
    background: "#fff",
  },
  bodyRC: {
    color: "#000",
    textAlign: "left",
    background: "#fff",
  },
  footerGrid: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    background: "#fff",
  },
  footerTC: {
    color: "#000",
    textAlign: "left",
    marginTop: theme.spacing(1),
    background: "#fff",
  },
  footerMiddleContent: {
    color: "#000",
    textAlign: "left",
    marginTop: theme.spacing(1),
    background: "#fff",
  },
  footerBottomContent: {
    color: "#000",
    textAlign: "left",
    marginTop: theme.spacing(1),
    background: "#fff",
  },
  textItem: {
    color: "#000",
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    backgroundColor: "#fff",
    textAlign: "center",
  },
}));

let terms = {
  termsTitle: "Terms of use",
  termsSubTitle: "Sub title",
  termsTC: "Top content",
  termsLC: "Left content",
  termsBC: "Body content",
  termsRC: "Right content",
  termsFCT: "Footer top",
  termsFCM: "Footer middle",
  termsFCB: "Footer bottom",
};

function Terms(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  // Page Column Format
  // 'leftBodyRight' = render left, body and right of page
  // 'bodyOnly' = render only body of page
  // 'leftAndBody' = render left column and body of page
  // 'bodyAndRight' = render body and right column of page
  const [pageColumnFormat, setPageColumnFormat] = useState("leftBodyRight");
  const [containerMaxWidth, setContainerMaxWidth] = useState("lg"); // xl, lg, md, sm, xs

  // For Products
  const [termsRes, setTermsRes] = useState(terms);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  const classes = useStyles();

  const {
    termsTitle,
    termsTC,
    termsSubTitle,
    termsLC,
    termsBC,
    termsRC,
    termsFCT,
    termsFCM,
    termsFCB,
  } = termsRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(terms);
    // console.log("In Terms(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In Terms(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "termsTitle")
              terms.termsTitle = item.ResourceDesc;
            if (item.ResourceCode === "termsSubTitle")
              terms.termsSubTitle = item.ResourceDesc;
            if (item.ResourceCode === "termsTC")
              terms.termsTC = item.ResourceDesc;
            if (item.ResourceCode === "termsLC")
              terms.termsLC = item.ResourceDesc;
            if (item.ResourceCode === "termsBC")
              terms.termsBC = item.ResourceDesc;
            if (item.ResourceCode === "termsRC")
              terms.termsRC = item.ResourceDesc;
            if (item.ResourceCode === "termsFCT")
              terms.termsFCT = item.ResourceDesc;
            if (item.ResourceCode === "termsFCM")
              terms.termsFCM = item.ResourceDesc;
            if (item.ResourceCode === "termsFCB")
              terms.termsFCB = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, terms);
        }
        setTermsRes(terms);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setTermsRes(terms);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In Terms(): useEffect()");
  }, []);

  useLayoutEffect(() => {
    // console.log("In Terms(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "terms";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In Terms(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In Terms(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In Terms(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In Terms(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In Terms(): useLayoutEffect(): got resource from cache');
      setTermsRes(cacheItem);
    }
  }, []);

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { !appContext.signedIn &&
                    <Redirect to='/signIn' />
                } */}

        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container
          component="div"
          className={classes.pageContainer}
          maxWidth={containerMaxWidth}
        >
          {progressFlag && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress
                className={classes.progress}
                color="secondary"
                size={15}
              />
            </div>
          )}

          <Container
            component="div"
            className={classes.mainForm}
            maxWidth={containerMaxWidth}
          >
            <Grid container className={classes.mainGrid}>
              {/* Page Titles and Title Body */}
              <Grid container className={classes.titleGrid}>
                {termsTitle !== null && termsTitle !== "" && (
                  <Grid item xs={12} className={classes.title}>
                    <Typography variant="button" gutterBottom>
                      {termsTitle}
                    </Typography>
                  </Grid>
                )}

                {termsSubTitle !== null && termsSubTitle !== "" && (
                  <Grid item xs={12} className={classes.subTitle}>
                    <Typography
                      variant="subtitle2"
                      className={classes.textItem}
                    >
                      {termsSubTitle}
                    </Typography>
                  </Grid>
                )}

                {termsTC !== null && termsTC !== "" && (
                  <Grid item xs={12} className={classes.titleContent}>
                    <Typography variant="caption" gutterBottom>
                      {termsTC}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              {/* Page Body Content */}
              <Grid container className={classes.bodyGrid}>
                {pageColumnFormat === "leftBodyRight" && (
                  <Grid container className={classes.bodyGrid}>
                    <Grid item xs={3} className={classes.bodyLC}>
                      <Typography
                        variant="caption"
                        className={classes.textItem}
                      >
                        {termsLC}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} className={classes.bodyContent}>
                      <Typography
                        variant="caption"
                        className={classes.textItem}
                      >
                        {termsBC}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} className={classes.bodyRC}>
                      <Typography
                        variant="subtitle2"
                        className={classes.textItem}
                      >
                        {termsRC}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {pageColumnFormat === "bodyOnly" && (
                  <Grid item xs={12} className={classes.bodyContent}>
                    <Typography variant="caption" className={classes.textItem}>
                      {termsBC}
                    </Typography>
                  </Grid>
                )}

                {pageColumnFormat === "leftAndBody" && (
                  <Grid container className={classes.bodyGrid}>
                    <Grid item xs={3} className={classes.bodyLC}>
                      <Typography
                        variant="caption"
                        className={classes.textItem}
                      >
                        {termsLC}
                      </Typography>
                    </Grid>

                    <Grid item xs={9} className={classes.bodyContent}>
                      <Typography
                        variant="caption"
                        className={classes.textItem}
                      >
                        {termsBC}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {pageColumnFormat === "bodyAndRight" && (
                  <Grid container className={classes.bodyGrid}>
                    <Grid item xs={9} className={classes.bodyContent}>
                      <Typography
                        variant="caption"
                        className={classes.textItem}
                      >
                        {termsBC}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} className={classes.bodyRC}>
                      <Typography
                        variant="subtitle2"
                        className={classes.textItem}
                      >
                        {termsRC}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {/* Page Body Footer */}
              <Grid container className={classes.footerGrid}>
                {termsFCT !== null && termsFCT !== "" && (
                  <Grid item xs={12} className={classes.footerTC}>
                    <Typography variant="caption" className={classes.textItem}>
                      {termsFCT}
                    </Typography>
                  </Grid>
                )}

                {termsFCM !== null && termsFCM !== "" && (
                  <Grid item xs={12} className={classes.footerMiddleContent}>
                    <Typography
                      variant="subtitle2"
                      className={classes.textItem}
                    >
                      {termsFCM}
                    </Typography>
                  </Grid>
                )}

                {termsFCB !== null && termsFCB !== "" && (
                  <Grid item xs={12} className={classes.footerBottomContent}>
                    <Typography variant="caption" className={classes.textItem}>
                      {termsFCB}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Container>
        </Container>

        <footer className={classes.footer}>
          <PageFooter />
        </footer>
      </div>
    </div>
  );
}

export default Terms;
