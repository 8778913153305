import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// import IconButton from '@material-ui/core/IconButton';
import CssBaseline from "@material-ui/core/CssBaseline";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
// import ImageSearchIcon from '@material-ui/icons/ImageSearch';
// import TextField from '@material-ui/core/TextField';
// import Modal from '@material-ui/core/Modal';

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Link from '@material-ui/core/Link';
// import Select from '@material-ui/core/Select';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import Select from 'react-select';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';

// Phone
// import ReactPhoneInput from 'react-phone-input-2';
// import "react-phone-input-2/lib/style.css";

// Draggable Modal
// import Draggable from 'react-draggable';
// import TextField from "@material-ui/core/TextField";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";

// Validator
// import validator from 'validator';
// import isEmail from 'validator/lib/isEmail';
// import isURL from 'validator/lib/isURL';
// import contains from 'validator/lib/contains';

// AG-Grid
import { AgGridReact, AgGridColumn } from "ag-grid-react";
//import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

// Amplify
import Amplify, { AUTH, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// import GlobalConstants from '../../utils/GlobalConstants';
// import GeneratePassword from '../../utils/GeneratePassword';
// import GenerateClientId from '../../utils/GenerateClientId';
// import GenerateTransactionId from '../../utils/GenerateTransactionId';
// import GenerateRandomHash from '../../utils/GenerateRandomHash';
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import LineSpacing from "../../components/misc/LineSpacing";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// Renderers
// import NumberCellRenderer from '../../components/renderers/NumberCellRenderer';
import ActionsCellRenderer from "../../components/renderers/ActionsCellRenderer";
// import { create } from 'jss';
// import CurrencyCellRenderer from '../../components/renderers/CurrencyCellRenderer';
// import CountryCellRenderer from '../../components/renderers/CountryCellRenderer';
// import ImageCellRenderer from '../../components/renderers/ImageCellRenderer';

// import SocialsDraggableDialog from '../../components/dialogs/SocialsDraggableDialog';

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    backgroundImage: `url(${"assets/logos/background20.png"})`,
  },
  gridWidth: {
    width: "80%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  mainForm: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  mainGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  textField: {
    width: "100%",
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  formControlLabel: {
    margin: theme.spacing(1),
  },
  verticalAlignLabel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  footer: {
    bottom: 0,
    height: "1rem" /* Footer height */,
    width: "100% !important",
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
    textAlign: "center !important",
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  phone: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "row",
  },
  browseIcon: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

let localeText = {
  page: "Page",
  to: "to",
  of: "of",
  noRowsToShow: "No records found",
};

// let localeText2 = {
//   page: "daPage",
//   more: "daMore",
//   to: "daTo",
//   of: "daOf",
//   next: "daNexten",
//   last: "daLasten",
//   first: "daFirsten",
//   previous: "daPreviousen",
//   loadingOoo: "daLoading...",
//   selectAll: "daSelect Allen",
//   searchOoo: "daSearch...",
//   blanks: "daBlanc",
//   filterOoo: "daFilter...",
//   applyFilter: "daApplyFilter...",
//   equals: "daEquals",
//   notEqual: "daNotEqual",
//   lessThan: "daLessThan",
//   greaterThan: "daGreaterThan",
//   lessThanOrEqual: "daLessThanOrEqual",
//   greaterThanOrEqual: "daGreaterThanOrEqual",
//   inRange: "daInRange",
//   contains: "daContains",
//   notContains: "daNotContains",
//   startsWith: "daStarts dawith",
//   endsWith: "daEnds dawith",
//   andCondition: "daAND",
//   orCondition: "daOR",
//   group: "laGroup",
//   columns: "laColumns",
//   filters: "laFilters",
//   rowGroupColumns: "laPivot Cols",
//   rowGroupColumnsEmptyMessage: "la drag cols to group",
//   valueColumns: "laValue Cols",
//   pivotMode: "laPivot-Mode",
//   groups: "laGroups",
//   values: "laValues",
//   pivots: "laPivots",
//   valueColumnsEmptyMessage: "la drag cols to aggregate",
//   pivotColumnsEmptyMessage: "la drag here to pivot",
//   toolPanelButton: "la tool panel",
//   noRowsToShow: "la no rows",
//   pinColumn: "laPin Column",
//   valueAggregation: "laValue Agg",
//   autosizeThiscolumn: "laAutosize Diz",
//   autosizeAllColumns: "laAutsoie em All",
//   groupBy: "laGroup by",
//   ungroupBy: "laUnGroup by",
//   resetColumns: "laReset Those Cols",
//   expandAll: "laOpen-em-up",
//   collapseAll: "laClose-em-up",
//   toolPanel: "laTool Panelo",
//   export: "laExporto",
//   csvExport: "la CSV Exportp",
//   excelExport: "la Excel Exporto (.xlsx)",
//   excelXmlExport: "la Excel Exporto (.xml)",
//   pinLeft: "laPin &lt;&lt;",
//   pinRight: "laPin &gt;&gt;",
//   noPin: "laDontPin &lt;&gt;",
//   sum: "laSum",
//   min: "laMin",
//   max: "laMax",
//   none: "laNone",
//   count: "laCount",
//   average: "laAverage",
//   copy: "laCopy",
//   copyWithHeaders: "laCopy Wit hHeaders",
//   ctrlC: "ctrl n C",
//   paste: "laPaste",
//   ctrlV: "ctrl n V"
// };

// For AG-Grid

//------------------------------
// RENDERERS
//------------------------------
const gridOptions = {
  style: "currency", // decimal, currency, percent
  currency: "USD",
  currencyDisplay: "symbol", // symbol, code, name to use a localized currency name such as "dollar"
  useGrouping: true,
  minimumIntegerDigits: 21,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  payload: {},
  imageWidth: "16px !important", // Will scale image to a width of 32px in ImageCellRenderer component
  divContainerHeight: "32px !important", // Will set div container height to 32px in ImageCellRenderer component to align the image in the middle
  getRowHeight: function (params) {
    // console.log("In getRowHeight: params is: ", params);
    // assuming 50 characters per line, working how how many lines we need
    // console.log("In getRowHeight: words is: ", params.data.referenceTypesDesc.length);
    let lines =
      36 * (Math.floor(params.data.referenceTypesDesc.length / 45) + 1);
    // console.log("In getRowHeight: lines is: ", lines);
    // let lines = 36;
    return lines;
  },
};

let createRefTypes = {
  createReferenceTypes: "Create reference types",
  allFieldsAreRequired: "All fields are required",

  languageCodeLabel: "Language",
  selectLanguagePrompt: "Please select a language",
  gridRefreshWarning: "If you change language, grid will refresh.",

  referenceTypesCodeLabel: "Type code",
  referenceTypesCodePrompt: "Enter reference type code",
  referenceTypesCodeMsg: "Examples: polly, stripe e.t.c",

  referenceTypesDescLabel: "Type description",
  referenceTypesDescPrompt: "Enter reference type description",
  referenceTypesDescMsg: "Example: Polly supported languages",

  submit: "Submit",
  clear: "Clear",
  deleteLabel: "Delete",
  createRefTypesSuccessMsg: "Create reference types was successful.",
  createRefTypesFailureMsg: "Error while creating reference types.",
  addToGrid: "Add to grid",
  deleteSelectedRows: "Delete selected rows",
  maxRecordsAllowed:
    "Maximum number of records allowed in grid is {MAX_RECORDS_ALLOWED}.",
  totalNumberRows: "Total number of rows",

  // For Grid
  // gridPage: "Page",
  // gridTo: "to",
  // gridOf: "of",
  noRowsToShow: "No records found",
};

function CreateRefTypeserenceTypes(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [referenceTypesLanguage, setReferenceTypesLanguage] = useState(null);
  const [referenceType, setReferenceType] = useState("reference-types");
  const [referenceTypesCode, setReferenceTypesCode] = useState(null);
  const [referenceTypesDesc, setReferenceTypesDesc] = useState(null);

  // For modal
  // const [modalOpen, setModalOpen] = useState(false);

  // Draggable Modal
  // const [deltaPosition, setDeltaPosition] = useState({x: 0, y: 0});

  const [payload, setPayload] = useState({});
  const [rowData, setRowData] = useState([]);

  const [langSelectOptions, setLangSelectOptions] = useState([]);
  const [refTypeSelectOptions, setRefTypeSelectOptions] = useState([]);

  // AG-Grid
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [progressFlag, setProgressFlag] = useState(false);
  const [addToGridFlag, setAddToGridFlag] = useState(false);
  const [rowSelectedFlag, setRowSelectedFlag] = useState(false);
  const [gridSubmitFlag, setGridSubmitFlag] = useState(false);
  const [gridRowsCount, setGridRowsCount] = useState(0);
  //const [maxRowsFlag, setMaxRowsFlag] = useState(false);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  // const [ confirmCodeFlag, setConfirmCodeFlag ] = useState(false);
  // const [ changePasswordFlag, setChangePasswordFlag ] = useState(false);
  // const [ theOneTimePasswordFlag, setTheOneTimePasswordFlag ] = useState(false);
  // const [ userNotConfirmedFlag, setUserNotConfirmedFlag ] = useState(false);
  // const [ forgotPasswordFlag, setForgotPasswordFlag ] = useState(false);

  // For Error Flags
  const [
    referenceTypesLanguageErrFlag,
    setReferenceTypesLanguageErrFlag,
  ] = useState(false);
  const [referenceTypesCodeErrFlag, setReferenceTypesCodeErrFlag] = useState(
    false
  );
  const [referenceTypesCodeFocus, setReferenceTypesCodeFocus] = useState(true);
  const [referenceTypesDescErrFlag, setReferenceTypesDescErrFlag] = useState(
    false
  );
  const [referenceTypesDescFocus, setReferenceTypesDescFocus] = useState(false);

  const [redirectSignUpFlag, setRedirectSignUpFlag] = useState(false);
  // const [globalErrorMsg, setGlobalErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  // For Resources
  const [createRefTypesRes, setCreateRefTypesRes] = useState(createRefTypes);
  const [prevLangCode, setPrevLangCode] = useState(
    appContext.currentLanguage.languageCode
  );

  // For Grid Resources
  const [localeTextRes, setLocaleTextRes] = useState(localeText);

  const [renderGridFlag, setRenderGridFlag] = useState(false);

  //console.log('AmplifyConfig is: ', JSON.stringify(AmplifyConfig, null, 2));
  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  const {
    createReferenceTypes,
    allFieldsAreRequired,

    languageCodeLabel,
    selectLanguagePrompt,
    gridRefreshWarning,

    referenceTypesCodeLabel,
    referenceTypesCodePrompt,
    referenceTypesCodeMsg,

    referenceTypesDescLabel,
    referenceTypesDescPrompt,
    referenceTypesDescMsg,

    submit,
    deleteLabel,
    createRefTypesSuccessMsg,
    createRefTypesFailureMsg,
    addToGrid,
    maxRecordsAllowed,
    totalNumberRows,
  } = createRefTypesRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(createRefTypes);
    // console.log("In ManageLanguages(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ManageLanguages(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "createReferenceTypes")
              createRefTypes.createReferenceTypes = item.ResourceDesc;
            if (item.ResourceCode === "allFieldsAreRequired")
              createRefTypes.allFieldsAreRequired = item.ResourceDesc;
            if (item.ResourceCode === "languageCodeLabel")
              createRefTypes.languageCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectLanguagePrompt")
              createRefTypes.selectLanguagePrompt = item.ResourceDesc;
            if (item.ResourceCode === "gridRefreshWarning")
              createRefTypes.gridRefreshWarning = item.ResourceDesc;
            if (item.ResourceCode === "referenceTypesCodeLabel")
              createRefTypes.referenceTypesCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "referenceTypesCodePrompt")
              createRefTypes.referenceTypesCodePrompt = item.ResourceDesc;
            if (item.ResourceCode === "referenceTypesCodeMsg")
              createRefTypes.referenceTypesCodeMsg = item.ResourceDesc;
            if (item.ResourceCode === "referenceTypesDescLabel")
              createRefTypes.referenceTypesDescLabel = item.ResourceDesc;
            if (item.ResourceCode === "referenceTypesDescPrompt")
              createRefTypes.referenceTypesDescPrompt = item.ResourceDesc;
            if (item.ResourceCode === "referenceTypesDescMsg")
              createRefTypes.referenceTypesDescMsg = item.ResourceDesc;

            if (item.ResourceCode === "submit")
              createRefTypes.submit = item.ResourceDesc;
            if (item.ResourceCode === "deleteLabel")
              createRefTypes.deleteLabel = item.ResourceDesc;
            if (item.ResourceCode === "createRefTypesSuccessMsg")
              createRefTypes.createRefTypesSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "createRefTypesFailureMsg")
              createRefTypes.createRefTypesFailureMsg = item.ResourceDesc;
            if (item.ResourceCode === "addToGrid")
              createRefTypes.addToGrid = item.ResourceDesc;
            if (item.ResourceCode === "maxRecordsAllowed")
              createRefTypes.maxRecordsAllowed = item.ResourceDesc;
            if (item.ResourceCode === "totalNumberRows")
              createRefTypes.totalNumberRows = item.ResourceDesc;

            // For Grid
            // if (item.ResourceCode === "gridPage") localeText.page = item.ResourceDesc;
            // if (item.ResourceCode === "gridTo") localeText.to = item.ResourceDesc;
            // if (item.ResourceCode === "gridOf") localeText.from = item.ResourceDesc;
            if (item.ResourceCode === "noRowsToShow")
              localeText.noRowsToShow = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, createRefTypes);
        }

        setCreateRefTypesRes(createRefTypes);
        setLocaleTextRes(localeText);

        setColumnDefs(columnDefsArray);

        setRenderGridFlag(true);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setCreateRefTypesRes(createRefTypes);
        setProgressFlag(false);
      });
  }

  // Maximum number of rows allowed in the grid
  const MAX_RECORDS_ALLOWED = 50;

  let selectOptions = [];

  function fetchLanguages() {
    // console.log('In fetchLanguages()');
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages(): response.length is: ', response.data.Items.length);
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            // label: item.LanguageName,
            text: item.LanguageName,
          };
          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In useEffect()");

    let currLangCode = appContext.currentLanguage.languageCode;
    // console.log("In useEffect(): currLangCode is, ", currLangCode);
    if (currLangCode !== prevLangCode) {
      setPrevLangCode(currLangCode);
      setCreateRefTypesRes(createRefTypes);
    }
  });

  useLayoutEffect(() => {
    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In CreateRefTypeserenceTypes(): useLayoutEffect(): currentLanguage is: ', currentLanguage);

    setReferenceTypesCodeFocus(true);
    setReferenceTypesDescFocus(false);

    if (selectOptions.length === 0) {
      fetchLanguages();
    }

    // Fetch resource from catche
    let cacheItemKey = "createRefTypes";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ManageLanguages(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ManageLanguages(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ManageLanguages(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ManageLanguages(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ManageLanguages(): useLayoutEffect(): got resource from cache');
      setCreateRefTypesRes(cacheItem);
    }
  }, []);

  function handleChange(event) {
    // console.log("In handleChange(): id is: "+event.target.id);
    // console.log("In handleChange(): value is: "+event.target.value);
    setGlobalErrorFlag(false);
    setAddToGridFlag(false);

    switch (event.target.id) {
      case "referenceTypesCodeId":
        setReferenceTypesCodeErrFlag(false);
        setReferenceTypesCode(event.target.value);

        // Enable add to grid button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          // referenceTypesCode !== undefined &&
          // referenceTypesCode !== null &&
          // referenceTypesCode !== "" &&

          referenceTypesDesc !== undefined &&
          referenceTypesDesc !== null &&
          referenceTypesDesc !== "" &&
          referenceTypesLanguage !== undefined &&
          referenceTypesLanguage !== null &&
          referenceTypesLanguage !== ""
        ) {
          setAddToGridFlag(true);
        }
        break;
      case "referenceTypesDescId":
        setReferenceTypesDescErrFlag(false);
        setReferenceTypesDesc(event.target.value);

        // Enable add to grid button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          referenceTypesCode !== undefined &&
          referenceTypesCode !== null &&
          referenceTypesCode !== "" &&
          // referenceTypesDesc !== undefined &&
          // referenceTypesDesc !== null &&
          // referenceTypesDesc !== "" &&

          referenceTypesLanguage !== undefined &&
          referenceTypesLanguage !== null &&
          referenceTypesLanguage !== ""
        ) {
          setAddToGridFlag(true);
        }
        break;
      default:
        break;
    }
  }

  function validateForm() {
    let formErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setReferenceTypesCodeErrFlag(false);
    setReferenceTypesDescErrFlag(false);

    // Variants

    if (referenceTypesLanguage === null || referenceTypesLanguage === "") {
      formErrFlag = true;
      setReferenceTypesLanguageErrFlag(true);
    }

    if (referenceType === null || referenceType === "") {
      formErrFlag = true;
      setReferenceTypesLanguageErrFlag(true);
    }

    if (referenceTypesCode === null || referenceTypesCode === "") {
      formErrFlag = true;
      setReferenceTypesCodeErrFlag(true);
    }

    if (referenceTypesDesc === null || referenceTypesDesc === "") {
      formErrFlag = true;
      setReferenceTypesDescErrFlag(true);
    }

    return formErrFlag;
  }

  async function referenceTypesService() {
    console.log(
      "In referenceTypesService(): referenceType is: ",
      referenceType
    );

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    // Get Grid data
    const gridRowCount = getRowCount();
    console.log("In referenceTypesService(): gridRowCount is: ", gridRowCount);

    if (gridRowCount > 0) {
      // we have data to save
      // console.log('We have data to save.');

      const rowsData = [];

      // const d = new Date();
      const timestamp = new Date();
      // const timestamp = d.toISOString();
      // console.log("In referenceTypesService(): timestamp is: ", timestamp);

      gridApi.forEachNode(function (node) {
        // Process each node (row)
        let rowData = {
          ResourcePK: node.data.referenceType, // e.g reference-types
          ResourceSK:
            node.data.referenceTypesLanguageCode +
            ":" +
            node.data.referenceTypesCode,
          LanguageCode: node.data.referenceTypesLanguageCode,

          ResourceDesc: node.data.referenceTypesDesc,
          ResourceCode: node.data.referenceTypesCode,

          ModifiedBy: appContext.userName,
          CreatedAt: timestamp,
          UpdatedAt: timestamp,
        };

        rowsData.push(rowData);
      });

      let params = {
        apiName: "ResourceAPI",
        path: "/dynamodb/resources/batchWrite",
        payload: {
          items: rowsData,
        },
      };

      let initObj = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        response: true, // return entire response object instead of response.data
        body: {},
      };

      initObj.headers.Authorization = appContext.jwtToken;
      initObj.body = params.payload;
      // console.log('In referenceTypesService(): initObj is: ', JSON.stringify(initObj, null, 2));

      setProgressFlag(true);

      API.post(params.apiName, params.path, initObj)
        .then((response) => {
          // console.log('In referenceTypesService(): response is: ', JSON.stringify(response, null, 2));

          // Clear the grid of any data
          clearGridData();

          // Show Snackbar
          setSnackbarsVariant("success");
          setSnackbarsMsg(createRefTypesSuccessMsg);
          setSnackbarOpen(true);

          setProgressFlag(false);
        })
        .catch((err) => {
          console.log(
            "In referenceTypesService(): err is: ",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setGlobalErrorMsg(createRefTypesFailureMsg);

          setProgressFlag(false);
        });
    }
  }

  async function redirectSignUp() {
    setRedirectSignUpFlag(true);
  }

  //===================================================

  gridOptions.payload = payload;

  // Grid Functions

  const deleteRows = (e) => {
    //console.log("In deleteRows(): gridApi is: ", gridApi);
    //console.log("In deleteRows(): gridApi.getSelectedNodes() is: ", gridApi.getSelectedNodes());
    var selectedRows = gridApi.getSelectedRows();
    var res = gridApi.updateRowData({ remove: selectedRows });
    // console.log("In deleteRows(): res is: ", res);

    // Update number of rows in grid
    getRowCount();
    initializeForm();

    // Disable Submit button if need be
    let gridRowsCount = gridApi.getModel().getRowCount();
    if (gridRowsCount === 0) {
      setGridSubmitFlag(false);
    }
  };

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
  }

  const onCellClicked = (event) => {
    // console.log('onCellClicked: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellClicked: event.colDef.field is: ', event.colDef.field);
    if (event.colDef.field === "delete") {
      var selectedData = gridApi.getSelectedRows();
      var res = gridApi.updateRowData({ remove: selectedData });
      // console.log("In onCellClicked() res is: ", res);

      // Update number of rows in grid
      getRowCount();
      initializeForm();

      // Disable Submit button if need be
      let gridRowsCount = gridApi.getModel().getRowCount();
      if (gridRowsCount === 0) {
        setGridSubmitFlag(false);
      }
    }
  };

  const onCellValueChanged = (event) => {
    // console.log('onCellValueChanged: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellValueChanged: event.colDef.field is: ', event.colDef.field);
  };

  const onRowSelected = (event) => {
    //console.log('onRowSelected: event is: ', event);
    //console.log('onRowSelected: event.data is: ', JSON.stringify(event.data, null, 2));
    setRowSelectedFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getSelectedNodes().length > 0
    ) {
      setRowSelectedFlag(true);
      // console.log('onRowSelected: after rowSelectedFlag is: ', rowSelectedFlag);
    }
  };

  const onRowClicked = (event) => {
    // console.log('onRowClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onRowClicked: event.data is: ', JSON.stringify(event.data, null, 2));
  };

  const onRowValueChanged = (event) => {
    // console.log('onRowValueChanged: event is:  ', event);
  };

  const onRowDataChanged = (event) => {
    // console.log('onRowDataChanged: event is: ', event);
    setColumnDefs(columnDefsArray);
    setLocaleTextRes(localeText);
    // setCreateRefTypesRes(createRefTypes);
    setGridSubmitFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getModel().getRowCount() > 0
    ) {
      // console.log('onRowDataChanged: gridApi.getModel().getRowCount() is: ', gridApi.getModel().getRowCount());
      setGridSubmitFlag(true);
    }
  };

  const onRowDataUpdated = (event) => {
    // console.log('onRowDataUpdated: event is:  ', event);
  };

  const onFirstDataRendered = (event) => {
    // console.log('onFirstDataRendered: event is:  ', event);
  };

  function clearGridData() {
    if (gridApi) {
      gridApi.setRowData([]);

      getRowCount();

      // Disable Submit button
      setGridSubmitFlag(false);
    }
  }

  function initializeForm() {
    // console.log("In initializeForm()");

    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setReferenceTypesCode("");
    setReferenceTypesDesc("");

    setReferenceTypesCodeFocus(true);
  }

  function getRowCount() {
    let gridRowsCount = gridApi.getModel().getRowCount();
    setGridRowsCount(gridRowsCount);
    return gridRowsCount;
  }

  function addRecordToGrid() {
    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    if (!validateForm()) {
      // Validation Passed - you can proceed
      // console.log('Validation Passed.');
      setAddToGridFlag(false);
      setGridSubmitFlag(true);

      // Make sure max records allowed in grid is not exceeded
      if (getRowCount() >= MAX_RECORDS_ALLOWED) {
        setGlobalErrorMsg(
          maxRecordsAllowed.replace(
            "{MAX_RECORDS_ALLOWED}",
            MAX_RECORDS_ALLOWED
          )
        );
        setGlobalErrorFlag(true);
        return;
      }

      // Add form data to grid
      let newItem = {
        referenceTypesLanguageCode: referenceTypesLanguage,
        // referenceTypesLanguageCode: referenceTypesLanguage,
        referenceType: referenceType,
        referenceTypesCode: referenceTypesCode,
        referenceTypesDesc: referenceTypesDesc,
      };
      var res = gridApi.updateRowData({ add: [newItem] });
      // console.log("In addRecordToGrid(): res is: ", res);

      // Update number of rows in grid
      getRowCount();

      // Initialize Variables
      initializeForm();
    }
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    setSnackbarOpen(false);
  }

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);
    setReferenceTypesLanguage(selectedOption);

    setReferenceTypesCodeFocus(true);

    setAddToGridFlag(false);

    initializeForm();

    clearGridData();

    if (
      referenceTypesCode !== undefined &&
      referenceTypesCode !== null &&
      referenceTypesCode !== "" &&
      referenceTypesDesc !== undefined &&
      referenceTypesDesc !== null &&
      referenceTypesDesc !== "" &&
      referenceType !== undefined &&
      referenceType !== null &&
      referenceType !== "" &&
      selectedOption !== undefined &&
      selectedOption !== null
    ) {
      setAddToGridFlag(true);
    }
  }

  function cellStyle(params) {
    return { "white-space": "normal" };
  }

  //----------------------------
  // AG-GRID Column Definitions
  //----------------------------

  let columnDefsArray = [
    {
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressMenu: true,
      resizable: true,
    },
    // {
    //   headerName: "languageCodeLabel",
    //   headerClass: classes.gridHeader,
    //   field: "referenceTypesLanguageCode",
    //   width: 170,
    //   pinned: false,
    //   editable: false,
    //   resizable: true,
    //   filter: true,
    //   sortable: true,
    //   hide: true,
    // },
    {
      headerName: languageCodeLabel,
      headerClass: classes.gridHeader,
      field: "referenceTypesLanguageCode",
      width: 300,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: false,
    },
    {
      headerName: "referenceType",
      headerClass: classes.gridHeader,
      field: "referenceType",
      width: 300,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: referenceTypesCodeLabel,
      headerClass: classes.gridHeader,
      field: "referenceTypesCode",
      width: 340,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: false,
    },
    {
      headerName: referenceTypesDescLabel,
      headerClass: classes.gridHeader,
      field: "referenceTypesDesc",
      width: 300,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellStyle: cellStyle,
    },
    {
      headerName: deleteLabel,
      field: "delete",
      width: 160,
      filter: false,
      sortable: false,
      resizable: true,
      cellRendererFramework: ActionsCellRenderer,
    },
  ];

  const [columnDefs, setColumnDefs] = useState(columnDefsArray);

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { !appContext.signedIn &&
          <Redirect to='/signIn' />
        } */}

        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%", height: "100%" }}>
            {/* progressFlag &&
              <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
              </div>
            */}

            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="md"
            >
              <Grid container justify="center">
                <Grid item xs={12} sm={9}>
                  <StyledPaper elevation={3} className={classes.paper}>
                    <Typography
                      variant="h6"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      {createReferenceTypes}
                    </Typography>

                    <Typography
                      variant="caption"
                      style={{ color: "green", textAlign: "center" }}
                    >
                      {allFieldsAreRequired}
                    </Typography>

                    {globalErrorFlag && (
                      <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                    )}

                    <LineSpacing value={24} />

                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="formLanguageId"
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {languageCodeLabel}
                        </Typography>
                      </label>
                      <Select
                        id="formLanguageId"
                        value={referenceTypesLanguage}
                        placeholder={selectLanguagePrompt}
                        onChange={handleLanguageChange}
                        variant="outlined"
                        displayEmpty={true}
                        autoFocus
                        fullWidth
                        style={{ width: "100%", flexGrow: 1 }}
                      >
                        <MenuItem value={null} disabled>
                          <Typography variant="body1">
                            {selectLanguagePrompt}
                          </Typography>
                        </MenuItem>
                        {langSelectOptions.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <label
                        htmlFor="languageCodeId"
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {referenceTypesCodeLabel}
                        </Typography>
                      </label>
                      <OutlinedInput
                        id="referenceTypesCodeId"
                        value={referenceTypesCode}
                        placeholder={referenceTypesCodePrompt}
                        onChange={handleChange}
                        required
                        fullWidth
                        // autoFocus
                        type={"text"}
                        error={referenceTypesCodeErrFlag}
                        margin="none"
                      />
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <label
                        htmlFor="languageDescId"
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {referenceTypesDescLabel}
                        </Typography>
                      </label>
                      <OutlinedInput
                        id="referenceTypesDescId"
                        value={referenceTypesDesc}
                        placeholder={referenceTypesDescPrompt}
                        onChange={handleChange}
                        required
                        fullWidth
                        // autoFocus
                        type={"text"}
                        error={referenceTypesDescErrFlag}
                        margin="none"
                      />
                    </FormControl>

                    <LineSpacing value={18} />

                    <Button
                      variant="contained"
                      size="large"
                      disabled={!addToGridFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={addRecordToGrid}
                    >
                      <AddIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {addToGrid}
                    </Button>
                  </StyledPaper>
                </Grid>
              </Grid>
            </Container>

            {/* AG-GRID */}

            {renderGridFlag && (
              <Container
                component="div"
                className={classes.mainGrid}
                maxWidth="lg"
              >
                <Grid container justify="center">
                  <Grid item xs>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!rowSelectedFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={deleteRows}
                      style={directionFloat}
                    >
                      <DeleteIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {deleteLabel}
                    </Button>
                  </Grid>

                  <Grid item xs>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!gridSubmitFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={referenceTypesService}
                      style={directionReverseFloat}
                    >
                      <SaveIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {submit}
                    </Button>
                  </Grid>
                </Grid>

                <div style={{ width: "100%", height: "100%" }}>
                  <div
                    id="refGrid"
                    style={{
                      // height: "400px",
                      width: "100%",
                    }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      localeText={localeTextRes}
                      domLayout="autoHeight"
                      onGridReady={onGridReady}
                      columnDefs={columnDefs}
                      rowData={rowData}
                      rowSelection="multiple"
                      rowMultiSelectWithClick={true}
                      rowDeselection={true}
                      enablePivot={true}
                      enableValue={true}
                      gridOptions={gridOptions}
                      enableRtl={enableRtl}
                      onCellClicked={onCellClicked}
                      onCellValueChanged={onCellValueChanged}
                      onRowSelected={onRowSelected}
                      onRowClicked={onRowClicked}
                      onRowValueChanged={onRowValueChanged}
                      onRowDataChanged={onRowDataChanged}
                      onRowDataUpdated={onRowDataUpdated}
                      onFirstDataRendered={onFirstDataRendered}
                    />
                  </div>
                </div>

                <Typography
                  variant="caption"
                  color="primary"
                  style={directionReverseFloat}
                >
                  {totalNumberRows}: {gridRowsCount}
                </Typography>
              </Container>
            )}
          </div>

          {/* variant: 'success', 'warning', 'error', 'info' */}
          <TsiCustomizedSnackbars
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={8000}
            onClose={snackbarOnClose}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">{snackbarsMsg}</span>}
            variant={snackbarsVariant}
          />
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter isSignedIn={isSignedIn} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default CreateRefTypeserenceTypes;
