import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

// import VideoPlayer from 'react-video-js-player';
import {
  Player,
  ControlBar,
  BigPlayButton,
  LoadingSpinner,
  PlayToggle,
  ReplayControl,
  ForwardControl,
  PlaybackRateMenuButton,
} from "video-react";
import "../../../node_modules/video-react/dist/video-react.css";

// AppContext
import AppContext from "../../config/AppContext";

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    //   display: 'flex',
    //   flexDirection: 'column',
    //   minHeight: '100vh',
    backgroundColor: "#fff",
  },
  main: {
    width: "100% !important",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  progress: {
    margin: theme.spacing(2),
  },
  mainForm: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  video: {
    // width: '100%    !important',
    // height: 'auto   !important',
    objectFit: "fill",
  },
}));

let templatePage = {
  template: "Template",
};

function ReactVideoPlayer(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  const [progressFlag, setProgressFlag] = useState(false);

  const classes = useStyles();

  const {
    currLanguage,

    videoPlayer,
    playerId,
    fluidFlag,
    preload,
    mutedFlag,
    playsInlineFlag,
    aspectRatio,
    autoPlayFlag,
    playStartTime,

    // playerControls,
    videoSrc,
    videoPoster,
    videoPlayerWidth,
    videoPlayerHeight,

    onPlayerReady,
    onVideoPlay,
    onVideoPause,
    onVideoTimeUpdate,
    onVideoSeeking,
    onVideoSeeked,
    onVideoEnd,

    videoFromPlayList, // from sources {}
    videoFromPlayListPoster,
    bigPlayButtonPosition, // "left-top", "center"
    autoHideFlag, // ControlBar
    autoHideTime, // ControlBar
    disableDefaultControlsFlag, // ControlBar
    disableCompletelyFlag, // ControlBar
    controlBarClass, // ControlBar
    playbackRates, // PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]}
    replayContolSeconds, // ReplayControl seconds
    replayContolOrder, // ReplayControl order={2.3}
    forwardControlSeconds, // ForwardControl seconds
    forwardControlOrder, // ForwardControl order={3.3}
  } = props;

  const { template } = templatePage;

  useEffect(() => {
    console.log("In ReactVideoPlayer(): useEffect()");
  }, []);

  useLayoutEffect(() => {
    console.log(
      "In ReactVideoPlayer(): useLayoutEffect(): currentLanguage is: ",
      currentLanguage
    );
    console.log(
      "In ReactVideoPlayer(): useLayoutEffect(): currLanguage is: ",
      currLanguage
    );
  }, []);

  return (
    <div dir={direction}>
      <div className={classes.root}>
        <CssBaseline />
        {/* { !appContext.signedIn &&
                    <Redirect to='/signIn' />
                } */}

        <Container component="div" className={classes.main} maxWidth="lg">
          <div data-vjs-player>
            <Player
              ref={videoPlayer}
              id={playerId}
              fluid={fluidFlag}
              width={videoPlayerWidth}
              height={videoPlayerHeight}
              src={videoFromPlayList ? null : videoSrc} // if there is video from videoFromPlayList don't assign video to src
              poster={videoFromPlayList ? videoFromPlayListPoster : videoPoster}
              preload={preload}
              muted={mutedFlag}
              playsInline={playsInlineFlag}
              aspectRatio={aspectRatio}
              autoPlay={autoPlayFlag}
              startTime={playStartTime}
              onReady={onPlayerReady}
              onPlay={onVideoPlay}
              onPause={onVideoPause}
              onTimeUpdate={onVideoTimeUpdate}
              onSeeking={onVideoSeeking}
              onSeeked={onVideoSeeked}
              onEnd={onVideoEnd}
            >
              <source src={videoFromPlayList} />
              <LoadingSpinner />
              <BigPlayButton position={bigPlayButtonPosition} />
              <ControlBar
                autoHide={autoHideFlag}
                autoHideTime={autoHideTime}
                disableDefaultControls={disableDefaultControlsFlag}
                disableCompletely={disableCompletelyFlag}
                className={controlBarClass}
              >
                <PlayToggle />
                <PlaybackRateMenuButton rates={playbackRates} />
                <ReplayControl
                  seconds={replayContolSeconds}
                  order={replayContolOrder}
                />
                <ForwardControl
                  seconds={forwardControlSeconds}
                  order={forwardControlOrder}
                />
              </ControlBar>
            </Player>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ReactVideoPlayer;
