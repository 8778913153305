/* eslint-disable */
export default {
  domain: "realid.ai", // realid.ai, trasurehour.org
  signedIn: "false",
  jwtToken: "",
  userName: "",
  displayName: "",
  photoUrl: "",

  /*
  Regex usage Example:

  if(inputTextValue.match(emailRegex)){
    Success
  }else{
    Failure
  }
  
  Credit Card Regex
  =============================
  
  American Express :- Starting with 34 or 37, length 15 digits.
  Visa :- Starting with 4, length 13 or 16 digits.
  MasterCard :- Starting with 51 through 55, length 16 digits.
  Discover :- Starting with 6011, length 16 digits or starting with 5, length 15 digits.
  Diners Club :- Starting with 300 through 305, 36, or 38, length 14 digits.
  JCB :- Starting with 2131 or 1800, length 15 digits or starting with 35, length 16 digits.
  */

  //phoneNumRegexUS: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  phoneNumRegexInter: /^\+(?:[0-9]●?){6,14}[0-9]$/,
  phoneNumRegexUS: /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,

  emailRegex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,

  amexRegex: /^(?:3[47][0-9]{13})$/,

  visaRegex: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,

  masterCardRegex: /^(?:5[1-5][0-9]{14})$/,

  discoverRegex: /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/,

  dinersRegex: /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/,

  jcbRegex: /^(?:(?:2131|1800|35\d{3})\d{11})$/,

  // Check a password between 8 to 15 characters which contain at least one
  // lowercase letter, one uppercase letter, one numeric digit, and one special character.
  // I prefer this
  passwordRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,

  // To check a password between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter
  password2Regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,

  // IP Regex
  ipRegex: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,

  // Non US Date Regex: dd/mm/yyyy or dd-mm-yyyy format
  dateOthersRegex: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/,

  // US and Canada Regex: mm/dd/yyyy or mm-dd-yyyy format (US Format)
  dateUsRegex: /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/,

  // Only Letters Regex
  lettersOnlyRegex: /^[A-Za-z]+$/,

  // Only Numbers Regex
  numbersOnlyRegex: /^[0-9]+$/,

  // AlphaNumeric Regex (No special characters)
  lettersAndNumbersRegex: /^[0-9a-zA-Z]+$/,

  // Float/Double (decimal) Numbers Regex
  decimalNumberRegex: /^[-+]?[0-9]+\.[0-9]+$/,
};
