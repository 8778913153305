const GenerateModulusHash = (item, modulus) => {
  /*let result = 0;
    if (item && modulus) {
        result = item.charCodeAt(0);
        for (var i=1; i<item.length; i++) {
            result = result * item.charCodeAt(i);
        }
        return ((result % modulus) + 1);
    }
    return null;
    */

  let hash = 5381;
  let i = item.length;

  while (i) {
    hash = (hash * 33) ^ item.charCodeAt(--i);
  }

  //JavaScript does bitwise operations (like XOR, above) on 32-bit signed
  //* integers. Since we want the results to be always positive, convert the
  //* signed int to an unsigned by doing an unsigned bitshift.
  let result = hash >>> 0;
  return (result % modulus) + 1;
};

export default GenerateModulusHash;
