import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

Amplify.configure(AmplifyConfig);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
  },
  googlePlayBadgeImage: {
    width: "156px",
  },
  appStoreBadgeGrid: {
    // marginTop: '10px',
    // background: 'red',
  },
  googlePlayBadgeGrid: {
    // marginTop: '10px',
    // background: 'green',
  },
}));

// let appsLabels = {
//     downloadApp: "Download our our mobile App now!",
// };

function AppsInfoFooter(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  //-----------------------------------------------------
  // For AppsInfoFooter
  // Google Play and Apple Store Info
  //-----------------------------------------------------

  const [renderGoogleAppleDomFlag, setRenderGoogleAppleDomFlag] = useState(
    appContext.appsInfoFooter.renderGoogleAppleDomFlag
  );
  const [googleAppleInfoFlag, setGoogleAppleInfoFlag] = useState(
    appContext.appsInfoFooter.googleAppleInfoFlag
  );
  const [downloadApp, setDownloadApp] = useState(
    appContext.appsInfoFooter.downloadApp
  );
  const [googlePlayLinkUrl, setGooglePlayLinkUrl] = useState(
    appContext.appsInfoFooter.googlePlayLinkUrl
  );
  const [googlePlayMediaUrl, setGooglePlayMediaUrl] = useState(
    appContext.appsInfoFooter.googlePlayMediaUrl
  );
  const [appleStoreLinkUrl, setAppleStoreLinkUrl] = useState(
    appContext.appsInfoFooter.appleStoreLinkUrl
  );
  const [appleStoreMediaUrl, setAppleStoreMediaUrl] = useState(
    appContext.appsInfoFooter.appleStoreMediaUrl
  );

  const { currLanguage } = props;

  const classes = useStyles();

  useEffect(() => {
    // console.log("In AppsInfoFooter(): useEffect(): downloadApp is: ", downloadApp);
  }, []);

  useLayoutEffect(() => {
    // console.log("In AppsInfoFooter(): useLayoutEffect()");
  }, []);

  //------------------------------------
  // useCallback Example
  //------------------------------------

  // const [height, setHeight] = useState(100);
  // const [age, setAge] = useState(3);

  // const handleSetHeight = useCallback(() => setHeight(height + 10), [height]);

  // const handleSetAge = useCallback(() => setAge(age + 1), [age]);

  return (
    <div dir={direction}>
      <div style={{ textAlign: "center" }}>
        {renderGoogleAppleDomFlag && googleAppleInfoFlag && (
          <Grid container alignContent="center" alignItems="center">
            <Grid item xs={12} xm={12}>
              <Typography
                variant="subtitle2"
                className={classes.textItem}
                style={{ color: "#fff" }}
              >
                {downloadApp}
              </Typography>
            </Grid>

            <Grid item xs={12} xm={12} style={{ textAlign: "center" }}>
              <Grid container alignContent="center" alignItems="center">
                <Grid
                  item
                  xs={6}
                  xm={6}
                  className={classes.googlePlayBadgeGrid}
                >
                  <Link
                    href={googlePlayLinkUrl}
                    target="_blank"
                    rel="noreferrer"
                    style={{ float: "right" }}
                  >
                    <img
                      alt="Google play"
                      src={googlePlayMediaUrl}
                      className={classes.googlePlayBadgeImage}
                    />
                  </Link>
                </Grid>

                <Grid item xs={6} xm={6} className={classes.appStoreBadgeGrid}>
                  <Link
                    href={appleStoreLinkUrl}
                    target="_blank"
                    rel="noreferrer"
                    style={{ float: "left" }}
                  >
                    <img alt="Apple store" src={appleStoreMediaUrl} />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
      {/* <div style={{textAlign: 'center'}}>
                <Grid container alignContent="center" alignItems="center">
                    <Grid item xs={12} xm={12}>
                        <Typography variant="subtitle2" className={classes.textItem} style={{color: '#fff'}}>
                            {downloadApp}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} xm={12} style={{textAlign: 'center'}}>
                        <Grid container alignContent="center" alignItems="center">
                            <Grid item xs={6} xm={6} className={classes.googlePlayBadgeGrid}>
                                <Link href={playUrl} target="_blank" rel="noreferrer" style={{float: 'right'}}>
                                    <img src={playSrcImage} className={classes.googlePlayBadgeImage}/>
                                </Link>
                            </Grid>

                            <Grid item xs={6} xm={6} className={classes.appStoreBadgeGrid}>
                                <Link href={storeUrl} target="_blank" rel="noreferrer" style={{float: 'left'}}>
                                    <img src={storeSrcImage}/>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div> */}
    </div>
  );
}

export default AppsInfoFooter;
