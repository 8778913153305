import React, { useReducer, useContext } from "react";

import Navigation from "./components/routes/Navigation";
import './App.css';

//-------------------
// AppContext
//-------------------
import AppContext from "./config/AppContext";

//-------------------
// For useReducer
//-------------------
import ReducerContext from "./config/ReducerContext";
import Reducer from "./config/Reducer";
import ReducerState from "./config/ReducerState";

function App() {
  const appContext = useContext(AppContext);

  //----------------------------------------------------------------
  // For useReducer
  // We use Context to pass along the store and dispatch objects
  // instead of passing them through props
  //----------------------------------------------------------------
  const [store, dispatch] = useReducer(Reducer, ReducerState);
  // console.log("In App(): store is: ", store);
  // console.log("In App(): dispatch is: ", dispatch);
  // ReducerContext.dispatch = dispatch;
  // ReducerContext.store = store;
  const reducerContext = useContext(ReducerContext);
  reducerContext.dispatch = dispatch;
  reducerContext.store = store;

  document.onmouseover = function () {
    //User's mouse is inside the page.
    window.innerDocClick = true;
  };

  document.onmouseleave = function () {
    //User's mouse has left the page.
    window.innerDocClick = false;
  };

  window.onhashchange = function () {
    if (window.innerDocClick) {
      //Your own in-page mechanism triggered the hash change
    } else {
      //Browser back button was clicked
      console.log(">>>>Browser back button was clicked");
    }
  };
  return (
    <div>
      <Navigation />
    </div>
  );
}

export default App;
