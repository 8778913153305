// To transform Froala Editor Data for different screen sizes
// let inputStr = "I have a cat, a dog, and a goat. font-size: 96px; font-size: 72px;";

function EditorPixelTransform(inputStr, viewType) {
    // console.log("In EditorPixelTransform(): viewType is: ", viewType);
    // console.log("In EditorPixelTransform(): inputStr is: ", inputStr);

    let mapObj = {};

    if (inputStr && viewType) {
        switch(viewType) {
            case "tabletView":
                mapObj = {
                    "96px;": "60px;",
                    "72px;": "54px;",
                    "60px;": "48px;",
                    "48px;": "40px;",
                    "36px;": "32px;",
                    "30px;": "28px;",
                };
                break;
            case "mobileView":
                mapObj = {
                    "96px;": "36px;",
                    "72px;": "32px;",
                    "60px;": "28px;",
                    "48px;": "24px;",
                    "36px;": "20px;",
                    "30px;": "18px;",
                    "24px;": "16px;",
                    "18px;": "12px;",
                };
                break;
            default:
                return inputStr;
        }
    } else {
        return inputStr;
    }

    inputStr = inputStr.replace(/96px;|72px;|60px;|48px;|36px;/gi, function(matched){
        return mapObj[matched];
    });
    // console.log("In EditorPixelTransform(): inputStr is: ", inputStr);
    return inputStr;
}
  
export default EditorPixelTransform;
