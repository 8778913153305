import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
import SaveIcon from "@material-ui/icons/Save";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputAdornment from "@material-ui/core/InputAdornment";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from "@material-ui/core/Container";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import CheckIcon from '@material-ui/icons/Check';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import ImageSearchIcon from '@material-ui/icons/ImageSearch';
// import InputAdornment from "@material-ui/core/InputAdornment";
import TranslateIcon from '@material-ui/icons/Translate';
import Paper from "@material-ui/core/Paper";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FileCopyIcon from '@material-ui/icons/FileCopy';

// react-responsive
import { useMediaQuery } from 'react-responsive';

// moment
import moment from "moment";

// Lodash
import lodash from "lodash";

// react-media
// import Media from "react-media";

// Validator
// import validator from 'validator';
// import isEmail from "validator/lib/isEmail";
// import isURL from "validator/lib/isURL";
// import isMobilePhone from "validator/lib/isMobilePhone";

//---------------------
// For Primereact
//---------------------

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

// For Primereact
import { Dialog } from 'primereact/dialog';
import { OrderList } from 'primereact/orderlist';

// Froala
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Amplify
import { API } from "aws-amplify";
// import { AmplifyTheme   } from 'aws-amplify-react';

// AppContext
import AppContext from "../../config/AppContext";

import LineSpacing from "../../components/misc/LineSpacing";
// import FetchS3Object from "../../components/media/FetchS3Object";
// import FroalaCommonEditor from "../../components/editor/FroalaCommonEditor";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import AlertDialog from "../../components/dialogs/AlertDialog";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GenerateUniqueName from "../../utils/GenerateUniqueName";
// import GenerateUlid from "../../utils/GenerateUlid";
// import FormatDateTime from "../../../../../utils/moment/FormatDateTime";
import FormatTimezoneDates from "../../utils/moment/FormatTimezoneDates";
import FilterArrayFromArray from "../../utils/filter/FilterArrayFromArray";
import FormatNumber from "../../utils/numberFormat/FormatNumber";
// import FormatNumberWithOption from "../../utils/numberFormat/FormatNumberWithOption";
// import FormatCurrency from "../../utils/numberFormat/FormatCurrency";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

import PricingConverter from "./PricingConverter";
import ClonePriceList from "./ClonePriceList";

// Views
import ManagePriceListTv from "./views/ManagePriceListTv";
import ManagePriceListMv from "./views/ManagePriceListMv";

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    leftPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "left",
    },
    leftPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1rem" /* Footer height */,

        width: "100% !important",
        marginTop: "auto",
        // backgroundColor: '#fff',
        textAlign: "center",
    },
    paper: {
        minWidth: "300px",
        marginTop: theme.spacing(2),
        alignItems: "center",
        padding: "10px 30px 10px 30px !important",
        textAlign: "center",
    },
}));

let pageLabels = {
    managePriceListLabel: "Manage price list",
    requiredFields: "* Required fields",

    languageLabel: "Language",
    languagePrompt: "Select language",
    languageErrMsg: "Language is required",

    domainLabel: "Domain",
    domainPrompt: "Select a domain",
    domainErrMsg: "Domain is required",

    priceCategoryLabel: "Price category",
    priceCategoryPrompt: "Select a price category",
    priceCategoryErrMsg: "Price category is required",
    
    priceItemLabel: "Price item",
    priceItemPrompt: "Enter a price item",
    priceItemErrMsg: "Price item is required",

    priceDetailsLabel: "Price details",
    priceDetailsPrompt: "Enter price details",
    priceDetailsErrMsg: "Price details is required",
    // priceDetailsMsg: "Add comments about the price category",

    // selectTitlePrompt: "Select title",
    
    // blogStartTimeLabel: "Blog start time",
    // blogStartTimePrompt: "Select date & time to publish the blog",
    // blogStartTimeErrMsg: "Blog start time is invalid",

    // uploadPriceItemLabel: "Blog title",
    // uploadPriceItemPrompt: "Please select blog title",
    // uploadPriceItemErrMsg: "Blog title is required",

    // liveEventUrlLabel: "Live event URL",
    // liveEventUrlPrompt: "Please enter the live event URL",
    // liveEventUrlErrMsg: "Live event URL is invalid",

    // liveEventUrlTypeLabel: "Live event URL type",
    // liveEventUrlTypePrompt: "Select live event URL type",
    // liveEventUrlTypeErrMsg: "Live event URL type is required",

    // blogContentLabel: "Blog content",
    // blogContentPrompt: "Please enter your blog content",
    // blogContentErrMsg: "Live event description is required",

    // yourTimeZoneMsg: "Your timeZone is",
    // timeZoneLabel: "Time zone",
    // cloneEventLabel: "Clone event",

    priceRecordLabel: "price record",
    priceRecordsLabel: "price records",
    // inProgressLabel: "In progress",
    // startLabel: "Start",
    // endLabel: "End",
    // notStartedLabel: "Not started",
    // dateCreatedLabel: "Date created",

    editLabel: "Edit",
    deleteLabel: "Delete",

    toLabel: "to",
    ofLabel: "of",
    recordsLabel: "records",
    noRecordsFound: "No records found",

    operationSuccessMsg: "Operation was successful.",
    operationFailureMsg: "Operation failed.",
    duplicateTransMsg: "Duplicate transaction",

    loadingText: "Loading ...",
    noOptionsText: "No options",
    clearText: "Clear",
    closeText: "Close",
    // showLabel: "Show",
    // hideLabel: "Hide",

    cancelLabel: "Cancel",
    // clearLabel: "Clear",
    // okLabel: "Ok",

    yesLabel: "Yes",
    deleteRequested: "Delete requested",
    areYouSureMsg: "Are you sure?",
    requestedToDeleteMsg: "You have requested to delete",
  
    createPriceListLabel: "Create price list",
    updatePriceListLabel: "Update price list",

    createMoreLabel: "Create more",
    createLabel: "Create",
    // editEventDestLabel: "Edit email template",
    closeLabel: "Close",
    noPriceListRecordsFound: "No price list records found.", 
    // createPriceListLabel: "Create live event",
            
    gridItemDetails: "Item details",
    gridCategory: "Category",
    gridPriceItem: "Price item",
    lastUpdateLabel: "Last update",

    gridEventUrl: "Event URL",
    // gridStartTime: "Start time",
    // gridBannerUrl: "Banner URL",
    
    cloneListLabel: "Clone list",
    convertLabel: "Convert",
    convertPriceListLabel: "Convert price list",

    // sourceLanguageLabel: "Source language",
    // sourceLanguagePrompt: "Select source language",
    // sourceLanguageErrMsg: "Source language is required",

    // targetLanguageLabel: "Target language",
    // targetLanguagePrompt: "Select target language",
    // targetLanguageErrMsg: "Target language is required",

    moreLabel: "- more -",
    uploadLabel: "Upload",

    organizeLabel: "Organize",
    organizePriceListLabel: "Organize price list",
    availablePriceItemsLabel: "Price items",
    saveLabel: "Save",
};

function ManagePriceList() {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);
    const [renderDomFlag, setRenderDomFlag] = useState(false);
    const [progressFlag, setProgressFlag] = useState(false);

    // For Global Errors
    const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");

    // For Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarsMsg, setSnackbarsMsg] = useState("");
    const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    //--------------------
    // For Froala Editor
    //--------------------
    const editorRef = useRef(null);
    // const [editorModelData, setEditorModelData] = useState(null);
    const [editorMaxChars, setEditorMaxChars] = useState(10000);// Max chars allowed in editor

    //--------------------
    // For Form Attributes
    //--------------------
    // const [profilePhotoSrc, setProfilePhotoSrc] = useState(null);
    
    const [language, setLanguage] = useState(null);
    const [priceDetails, setPriceDetails] = useState("");
    // const [authorPhotoSrc, setAuthorPhotoSrc] = useState("");
    const [priceItem, setPriceItem] = useState("");
    const [blogStartTime, setBlogStartTime] = useState(null);
    const [priceCategory, setPriceCategory] = useState(null);
    const [blogContent, setBlogContent] = useState("");
    const [domain, setDomain] = useState(null);
    const [domainOptions, setDomainOptions] = useState([]);
    
    // const [eventThumbnailUrl, setEventThumbnailUrl] = useState("");
    // const [eventBannerUrl, setEventBannerUrl] = useState("");
    // const [eventPosterUrl, setEventPosterUrl] = useState("");
    // const [eventAudioUrl, setEventAudioUrl] = useState("");
    
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
         
    const [languageErrFlag, setLanguageErrFlag] = useState(false);
    const [domainErrFlag, setDomainErrFlag] = useState(false);
    const [priceDetailsErrFlag, setPriceDetailsErrFlag] = useState(false);
    const [priceItemErrFlag, setPriceItemErrFlag] = useState(false);
    const [blogStartTimeErrFlag, setBlogStartTimeErrFlag] = useState(false);
    const [priceCategoryErrFlag, setPriceCategoryErrFlag] = useState(false);
    const [blogContentErrFlag, setBlogContentErrFlag] = useState(false);

    const [categoryOptions, setCategoryOptions] = useState([]);
    
    const [priceListRecordsBackup, setPriceListRecordsBackup] = useState([]);
    const [priceListRecords, setPriceListRecords] = useState([]);

    const [submitButtonFlag, setSubmitButtonFlag] = useState(false);
    // const [addTagButtonFlag, setAddTagButtonFlag] = useState(false);
    const [editModeFlag, setEditModeFlag] = useState(false);

    const [datePickerLanguage, setDatePickerLanguage] = useState(appContext.currentLanguage.languageCode);
    
    
    const [showListFlag, setShowListFlag] = useState(true);
    const [showDetailsFormFlag, setShowDetailsFormFlag] = useState(false);
    const [uploadFlag, setUploadFlag] = useState(false);
    const [convertFlag, setConvertFlag] = useState(false);
    const [cloneListFlag, setCloneListFlag] = useState(false);
           
    const [pageSize, setPageSize] = useState(10000);// AWS put PageSize to a max 0f 100

    const [noRecordFoundFlag, setNoRecordFoundFlag] = useState(false);
    // const [eventStartedFlag, setEventStartedFlag] = useState(false);
    // const [startedEventId, setStartedEventId] = useState(null);
    
    const [textMaxLength, setTextMaxLength] = useState(256);

    //--------------------------
    // For sliceText and Dialog
    //--------------------------
    const [dialogData, setDialogData] = useState("");
    const [dialogHeader, setDialogHeader] = useState("");
    
    // const [terminologyNames, setTerminologyNames] = useState([
    //     "translate_custom_terminology.txt"
    // ]);
    

    //-------------------------
    // For Primereact 
    // DataTable Attributes 
    //-------------------------
    // const [ dataTableInfo, setDataTableInfo] = useState([]);
    // const [dataTableInfoFlag, setDataTableInfoFlag] = useState(false);
    
    // const [resizableColumnsFlag, setResizableColumnsFlag] = useState(true);
    // const [columnResizeMode, setColumnResizeMode] = useState("fit");
    // const [sortMode, setSortMode] = useState("multiple");
    // const [responsiveFlag, setResponsiveFlag] = useState(true);
    // const [reorderableColumnsFlag, setReorderableColumnsFlag] = useState(true);
    // const [paginatorFlag, setPaginatorFlag] = useState(true);
    const [paginatorTemplate, setPaginatorTemplate] = useState("FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown");
    // const [currentPageReportTemplate, setCurrentPageReportTemplate] = useState(null);
    const [visibleRowsCount, setVisibleRowsCount] = useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5,10,20,50,100]);

    // const [selectionMode, setSelectionMode] = useState("multiple");// single or multiple
    // const [selectedTableRow, setSelectedTableRow] = useState(null);// For single selection
    const [selectedTableRows, setSelectedTableRows] = useState([]);// For multiple selection
    // const [updatedTableRows, setUpdatedTableRows] = useState([]); // For updated (edited) table rows
    // const [deletedTableRows, setDeletedTableRows] = useState([]); // For deleted table rows
    const [tableDirtyFlag, setTableDirtyFlag] = useState(false); // To show DataTable cell was edited (modified)

    // const [deleteRowFlag, setDeleteRowFlag] = useState(false);

    //--------------------
    // For AlertDialog
    //--------------------
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogSubContent, setDialogSubContent] = useState(null);

    const [recordToDelete, setRecordToDelete] = useState(null);
    const [recordToEdit, setRecordToEdit] = useState(null);
    
    //-----------------------
    // For Primereact Dialog
    //-----------------------
    const [dlgVisibleFlag, setDlgVisibleFlag] = useState(false);

    //--------------------
    // For Languages
    //--------------------
    const [languages, setLanguages] = useState([]);

    //------------------
    // For OrderList
    //------------------
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [orderListFlag, setOrderListFlag] = useState(false);
    const [dragdropFlag, setDragdropFlag] = useState(true); // Allow drag and drop
    
    // const {
    //     // editorId,
    // } = props;

    const theme = useTheme();
  
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    const { 
        managePriceListLabel, 
        requiredFields,

        languageLabel,
        languagePrompt,
        languageErrMsg,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,
            
        priceDetailsLabel,
        priceDetailsPrompt,
        priceDetailsErrMsg,
        // priceDetailsMsg,
        
        priceItemLabel,
        priceItemPrompt,
        priceItemErrMsg,
        // selectTitlePrompt,
    
        // blogStartTimeLabel,
        // blogStartTimePrompt,
        // blogStartTimeErrMsg,

        priceCategoryLabel,
        priceCategoryPrompt,
        priceCategoryErrMsg,

        // uploadPriceItemLabel,
        // uploadPriceItemPrompt,
        // uploadPriceItemErrMsg,

        // liveEventUrlLabel,
        // liveEventUrlPrompt,
        // liveEventUrlErrMsg,

        // liveEventUrlTypeLabel,
        // liveEventUrlTypePrompt,
        // liveEventUrlTypeErrMsg,

        priceRecordLabel,
        priceRecordsLabel,
        // inProgressLabel,
        // startLabel,
        // endLabel,
        // notStartedLabel,
    
        // dateCreatedLabel,
    
        // blogContentLabel,
        // blogContentPrompt,
        // blogContentErrMsg,

        // yourTimeZoneMsg,
        // timeZoneLabel,
        // cloneEventLabel,
    
        toLabel,
        ofLabel,
        recordsLabel,
        noRecordsFound,

        gridCategory,
        editLabel,
        deleteLabel,

        operationSuccessMsg,
        operationFailureMsg,
        duplicateTransMsg,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,
        // showLabel,
        // hideLabel,

        cancelLabel,
        // clearLabel,
        // okLabel,

        yesLabel,
        deleteRequested,
        areYouSureMsg,
        requestedToDeleteMsg,
    
        uploadLabel,
        
        createPriceListLabel,
        updatePriceListLabel,

        createMoreLabel,
        createLabel,
        // editEventDestLabel,
        closeLabel,
        noPriceListRecordsFound, 
        // createPriceListLabel,

        gridPriceItem,
        gridItemDetails,
        // gridEventUrl,
        lastUpdateLabel,
        // gridStartTime,
        // gridBannerUrl,
    
        cloneListLabel,
        convertLabel,
        convertPriceListLabel,

        // sourceLanguageLabel,
        // sourceLanguagePrompt,
        // sourceLanguageErrMsg,

        // targetLanguageLabel,
        // targetLanguagePrompt,
        // targetLanguageErrMsg,

        moreLabel,

        organizeLabel,
        organizePriceListLabel,
        availablePriceItemsLabel,
        saveLabel,
    } = pageLabelsRes;

    async function fetchDbResources(cacheItemKey) {
        backdropProgress(true);

        let keys = [];
        let objKeys = Object.keys(pageLabels);
        // console.log("In ManagePriceList(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
        if (objKeys !== null && objKeys.length > 0) {
            objKeys.map((key) => {
                let partitionKey = appContext.currentLanguage.languageCode;
                partitionKey += ".";
                partitionKey += GenerateModulusHash(key, 200);

                let itemKey = {
                ResourcePK: partitionKey, // e.g. 'en.91'
                ResourceSK: key, // e.g. 'firstName'
                };
                keys.push(itemKey);
            });
        }
        // console.log("In ManagePriceList(): fetchDbResources(): keys.length is: ", keys.length);
        // console.log("In ManagePriceList(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

        let arrayData = [];

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/batchGet",
            payload: {
                keys: keys,
                projectionExpression: "ResourceCode,ResourceDesc",
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
            response.data.Responses.resource.map((item, index) => {
            let record = {
                ResourceCode: item.ResourceCode,
                ResourceDesc: item.ResourceDesc,
            };

            arrayData.push(record);
            });
                    
            if (arrayData && arrayData.length > 0) {
                arrayData.map((item) => {
                    if (item.ResourceCode === "managePriceListLabel") pageLabels.managePriceListLabel = item.ResourceDesc;
                    if (item.ResourceCode === "requiredFields") pageLabels.requiredFields = item.ResourceDesc;
                    
                    if (item.ResourceCode === "languageLabel") pageLabels.languageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "languagePrompt") pageLabels.languagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "languageErrMsg") pageLabels.languageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "domainLabel") pageLabels.domainLabel = item.ResourceDesc;
                    if (item.ResourceCode === "domainPrompt") pageLabels.domainPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "domainErrMsg") pageLabels.domainErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "priceDetailsLabel") pageLabels.priceDetailsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "priceDetailsPrompt") pageLabels.priceDetailsPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "priceDetailsErrMsg") pageLabels.priceDetailsErrMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "priceDetailsMsg") pageLabels.priceDetailsMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "priceItemLabel") pageLabels.priceItemLabel = item.ResourceDesc;
                    if (item.ResourceCode === "priceItemPrompt") pageLabels.priceItemPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "priceItemErrMsg") pageLabels.priceItemErrMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "selectTitlePrompt") pageLabels.selectTitlePrompt = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "blogStartTimeLabel") pageLabels.blogStartTimeLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "blogStartTimePrompt") pageLabels.blogStartTimePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "blogStartTimeErrMsg") pageLabels.blogStartTimeErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "priceCategoryLabel") pageLabels.priceCategoryLabel = item.ResourceDesc;
                    if (item.ResourceCode === "priceCategoryPrompt") pageLabels.priceCategoryPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "priceCategoryErrMsg") pageLabels.priceCategoryErrMsg = item.ResourceDesc;
            
                    // if (item.ResourceCode === "uploadPriceItemLabel") pageLabels.uploadPriceItemLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "uploadPriceItemPrompt") pageLabels.uploadPriceItemPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "uploadPriceItemErrMsg") pageLabels.uploadPriceItemErrMsg = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "liveEventUrlTypeLabel") pageLabels.liveEventUrlTypeLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "liveEventUrlTypePrompt") pageLabels.liveEventUrlTypePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "liveEventUrlTypeErrMsg") pageLabels.liveEventUrlTypeErrMsg = item.ResourceDesc;
                                    
                    // if (item.ResourceCode === "yourTimeZoneMsg") pageLabels.yourTimeZoneMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "timeZoneLabel") pageLabels.timeZoneLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "cloneEventLabel") pageLabels.cloneEventLabel = item.ResourceDesc;
                                
                    if (item.ResourceCode === "priceRecordsLabel") pageLabels.priceRecordsLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "inProgressLabel") pageLabels.inProgressLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "startLabel") pageLabels.startLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "endLabel") pageLabels.endLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "notStartedLabel") pageLabels.notStartedLabel = item.ResourceDesc;
                      
                    // if (item.ResourceCode === "dateCreatedLabel") pageLabels.dateCreatedLabel = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "blogContentLabel") pageLabels.blogContentLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "blogContentPrompt") pageLabels.blogContentPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "blogContentErrMsg") pageLabels.blogContentErrMsg = item.ResourceDesc; 

                    if (item.ResourceCode === "toLabel") pageLabels.toLabel = item.ResourceDesc;
                    if (item.ResourceCode === "ofLabel") pageLabels.ofLabel = item.ResourceDesc;
                    if (item.ResourceCode === "recordsLabel") pageLabels.recordsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noRecordsFound") pageLabels.noRecordsFound = item.ResourceDesc;

                    if (item.ResourceCode === "createLabel") pageLabels.createLabel = item.ResourceDesc;
                    if (item.ResourceCode === "gridCategory") pageLabels.gridCategory = item.ResourceDesc;
                    if (item.ResourceCode === "editLabel") pageLabels.editLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteLabel") pageLabels.deleteLabel = item.ResourceDesc;

                    if (item.ResourceCode === "operationSuccessMsg") pageLabels.operationSuccessMsg = item.ResourceDesc;
                    if (item.ResourceCode === "operationFailureMsg") pageLabels.operationFailureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "duplicateTransMsg") pageLabels.duplicateTransMsg = item.ResourceDesc;
                                                                        
                    if (item.ResourceCode === "loadingText") pageLabels.loadingText = item.ResourceDesc;
                    if (item.ResourceCode === "noOptionsText") pageLabels.noOptionsText = item.ResourceDesc;
                    if (item.ResourceCode === "clearText") pageLabels.clearText = item.ResourceDesc;
                    if (item.ResourceCode === "closeText") pageLabels.closeText = item.ResourceDesc;
                    // if (item.ResourceCode === "showLabel") pageLabels.showLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "hideLabel") pageLabels.hideLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "cancelLabel") pageLabels.cancelLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "clearLabel") pageLabels.clearLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "okLabel") pageLabels.okLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "yesLabel") pageLabels.yesLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteRequested") pageLabels.deleteRequested = item.ResourceDesc;
                    if (item.ResourceCode === "areYouSureMsg") pageLabels.areYouSureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "requestedToDeleteMsg") pageLabels.requestedToDeleteMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "uploadLabel") pageLabels.uploadLabel = item.ResourceDesc;
          
                    if (item.ResourceCode === "createPriceListLabel") pageLabels.createPriceListLabel = item.ResourceDesc;
                    if (item.ResourceCode === "updatePriceListLabel") pageLabels.updatePriceListLabel = item.ResourceDesc;
                              
                    if (item.ResourceCode === "priceRecordLabel") pageLabels.priceRecordLabel = item.ResourceDesc;
                    if (item.ResourceCode === "createMoreLabel") pageLabels.createMoreLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "editEventDestLabel") pageLabels.editEventDestLabel = item.ResourceDesc;
                    if (item.ResourceCode === "closeLabel") pageLabels.closeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noPriceListRecordsFound") pageLabels.noPriceListRecordsFound = item.ResourceDesc;
                    // if (item.ResourceCode === "createPriceListLabel") pageLabels.createPriceListLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "gridPriceItem") pageLabels.gridPriceItem = item.ResourceDesc;
                    if (item.ResourceCode === "gridEventUrl") pageLabels.gridEventUrl = item.ResourceDesc;
                    if (item.ResourceCode === "lastUpdateLabel") pageLabels.lastUpdateLabel = item.ResourceDesc;
                                              
                    // if (item.ResourceCode === "gridStartTime") pageLabels.gridStartTime = item.ResourceDesc;
                    // if (item.ResourceCode === "gridBannerUrl") pageLabels.gridBannerUrl = item.ResourceDesc;
                    if (item.ResourceCode === "gridItemDetails") pageLabels.gridItemDetails = item.ResourceDesc;
                                              
                    if (item.ResourceCode === "cloneListLabel") pageLabels.cloneListLabel = item.ResourceDesc;
                    if (item.ResourceCode === "convertLabel") pageLabels.convertLabel = item.ResourceDesc;
                    if (item.ResourceCode === "convertPriceListLabel") pageLabels.convertPriceListLabel = item.ResourceDesc;
                                                           
                    // if (item.ResourceCode === "sourceLanguageLabel") pageLabels.sourceLanguageLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "sourceLanguagePrompt") pageLabels.sourceLanguagePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "sourceLanguageErrMsg") pageLabels.sourceLanguageErrMsg = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "targetLanguageLabel") pageLabels.targetLanguageLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "targetLanguagePrompt") pageLabels.targetLanguagePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "targetLanguageErrMsg") pageLabels.targetLanguageErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "moreLabel") pageLabels.moreLabel = item.ResourceDesc;
                    
                    if (item.ResourceCode === "organizeLabel") pageLabels.organizeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "organizePriceListLabel") pageLabels.organizePriceListLabel = item.ResourceDesc;
                    if (item.ResourceCode === "availablePriceItemsLabel") pageLabels.availablePriceItemsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "saveLabel") pageLabels.saveLabel = item.ResourceDesc;
                });
                // Add it to cache
                SetCacheItem(cacheItemKey, pageLabels);
            }

            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setRenderDomFlag(true);
        }).catch((err) => {
            console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setRenderDomFlag(true);
        });
    }
    
    let listNextToken = null;

    async function fetchPriceListRecords(selectedDomain) {
        // console.log('In fetchPriceListRecords(): selectedDomain is: ', selectedDomain);
        if (language && selectedDomain) {
            backdropProgress(true);
            setNoRecordFoundFlag(false);
            setPriceListRecordsBackup([]);

            let params = {
                apiName: "PriceAPI",
                path: "/dynamodb/price/query",
                payload: {
                    keyConditionExpression:
                        // "#pricePK = :pricePK",
                        "#pricePK = :pricePK AND begins_with(#priceSK, :priceSK)",
                    expressionAttributeNames: {
                        "#pricePK": "PricePK",
                        "#priceSK": "PriceSK",
                    },
                    expressionAttributeValues: {
                        ":pricePK": selectedDomain.value + "#" + language.languageCode + "#price#list",
                        ":priceSK": selectedDomain.value + "#" + language.languageCode + "#price#list",
                    },
                    // indexName: "PriceDomain-LangAuthorUserName-index"
                        // "keyConditionExpression": "#pricePK = :pricePK",
                        // "expressionAttributeNames": {"#pricePK": "PricePK"},
                        // "expressionAttributeValues": {":pricePK": "languages"}
                },
            };

            if (!lodash.isEmpty(listNextToken)) {
                params.payload.nextToken = listNextToken;
            }
    
            if (!lodash.isEmpty(pageSize)) {
                params.payload.pageSize = pageSize;
            }

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchPriceListRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchPriceListRecords(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchPriceListRecords(): response.data.NextToken is: ', response.data.NextToken);
                // console.log('In fetchPriceListRecords(): response.data.Items is: ', response.data.Items);

                let records = [];

                listNextToken = null;

                if (response && !lodash.isEmpty(response.data.NextToken)) {
                    listNextToken = response.data.NextToken;
                    records = priceListRecords;
                }
            
                let items = response.data.Items;

                if (items && items.length > 0) {
                    setPriceListRecords([]);

                    items.map((record) => {
                        // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                        // console.log('In fetchPriceListRecords(): formatedDateTime is: ', formatedDateTime);
                        // console.log('In fetchPriceListRecords(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
                        let formattedDateTime = null;
                        if (record.UpdatedAt) {
                            formattedDateTime = FormatTimezoneDates(record.UpdatedAt, record.LanguageCode, timeZone, "shortDateAndTime");
                        }
                        // console.log('In fetchPriceListRecords(): formattedDateTime is: ', formattedDateTime);

                        // let formattedCurrency = "";
                        // if (record.PriceItem) {
                        //     // new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
                        //     // FormatCurrency = (amount, currencyCode, localeCode)
                        //     formattedCurrency = FormatCurrency(JSON.parse(record.PriceItem), "USD", "en-US");
                        // }
                        // console.log('In fetchPriceListRecords(): formattedCurrency is: ', formattedCurrency);

                        // let formattedNumber = "";
                        // if (record.YearlyDiscount) {
                        //     // new Intl.NumberFormat(localeCode).format(amount);
                        //     // FormatNumber(amount, localeCode)
                        //     // formattedNumber = FormatNumber(JSON.parse(record.YearlyDiscount), "en-US");
                        //     let option = {
                        //         style: 'percent',
                        //         minimumFractionDigits: 2,
                        //         maximumFractionDigits: 2
                        //     };
                        //     formattedNumber = FormatNumberWithOption(JSON.parse(record.YearlyDiscount), "en-US", option);
                        // }
                        // console.log('In fetchPriceListRecords(): formattedNumber is: ', formattedNumber);

                        let item = {
                            PricePK: record.PricePK,
                            PriceSK: record.PriceSK,

                            LanguageObject: record.LanguageObject,
                            LanguageCode: record.LanguageCode,
                            Domain: record.Domain,

                            "PriceId": lodash.isEmpty(record.PriceId) ? "" : record.PriceId,

                            "PriceDomain": lodash.isEmpty(record.PriceDomain) ? "" : record.PriceDomain,
                            "PriceCategory": lodash.isEmpty(record.PriceCategory) ? null : record.PriceCategory,
                            "PriceCategoryText": lodash.isEmpty(record.PriceCategory) ? "" : record.PriceCategory.text,
                            "PriceItem": lodash.isEmpty(record.PriceItem) ? "" : record.PriceItem,
                            "PriceDetails": lodash.isEmpty(record.PriceDetails) ? "" : record.PriceDetails,

                            "PriceItemSeqNumber": record.PriceItemSeqNumber,
            
                            "FormattedDateTime": formattedDateTime,
                            "UpdatedAt": record.UpdatedAt,
                        };
                        records.push(item);
                    });
                } else {
                    setNoRecordFoundFlag(true);
                }

                // console.log('In fetchPriceListRecords(): listNextToken is: ', listNextToken);
                if (!lodash.isEmpty(listNextToken)) {
                    await fetchPriceListRecords();
                }
                
                setTimeout(() => {
                    records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                    setPriceListRecords(records);
                    setPriceListRecordsBackup(records);
                }, 100);

                backdropProgress(false);
            }).catch((err) => {
                console.error("In fetchPriceListRecords(): err is: ", JSON.stringify(err, null, 2));
                setPriceListRecords([]);
                setNoRecordFoundFlag(true);
                backdropProgress(false);
            });
        }
    }
    
    async function getPriceListRecord(key) {
        // console.log('In getPriceListRecord(): key.PricePK is: ', key.PricePK);
        // console.log('In getPriceListRecord(): key.PriceSK is: ', key.PriceSK);
        
        backdropProgress(true);
    
        let params = {
            apiName: "PriceAPI",
            path: "/dynamodb/price/get",
            payload: {
                key: {
                    PricePK: key.PricePK,
                    PriceSK: key.PriceSK,
                },
            },
        };
  
        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
  
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In getPriceListRecord(): initObj is: ', JSON.stringify(initObj, null, 2));
  
        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In getPriceListRecord(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In getPriceListRecord(): response.data.Item is: ', response.data.Item);
        
            let item = response.data.Item;

            appContext.currentPK = item.PricePK;
            appContext.currentSK = item.PriceSK;
        
            setLanguage(item.LanguageObject);
            // setDomain(item.Domain);

            setDomain(item.Domain ? item.Domain : null);
            setPriceCategory(item.PriceCategory ? item.PriceCategory : null);
            setPriceItem(item.PriceItem ? item.PriceItem : "");
            setPriceDetails(item.PriceDetails ? item.PriceDetails : "");

            setShowListFlag(false);
            setUploadFlag(false);
            setOrderListFlag(false);
            setShowDetailsFormFlag(true);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);

            backdropProgress(false);
        }).catch((err) => {
            console.error("In getPriceListRecord(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        // console.log("In editRecordAction(): priceListRecords is: ", priceListRecords);
        
        setRecordToEdit(item);
        await getPriceListRecord({PricePK: item.PricePK, PriceSK: item.PriceSK,});
    }

    async function fetchLanguages() {
        // console.log("In fetchLanguages()");
        // setFilterDisabledFlag(true);

        //-------------------------------------------------------
        // Make sure languages is not in cache before proceeding
        //-------------------------------------------------------

        let cachedLanguages = GetCacheItem("languages");
        // console.log('In fetchLanguages(): cachedLanguages is: ', JSON.stringify(cachedLanguages, null, 2));
        cachedLanguages = null;
        if (cachedLanguages && cachedLanguages !== null && cachedLanguages.length > 0) {
            // console.log('In fetchLanguages(): cachedLanguages length is: ', cachedLanguages.length);
            setLanguages(cachedLanguages);
            return true;
        }

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/query",
            payload: {
                keyConditionExpression: "#resourcePK = :resourcePK",
                expressionAttributeNames: { "#resourcePK": "ResourcePK" },
                expressionAttributeValues: { ":resourcePK": "languages" },
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log("In fetchLanguages(): response length is: ", response.data.Items.length);
            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));

            // response.data.Items.sort((a, b) =>
            //     a.LanguageName > b.LanguageName ? 1 : -1
            // );

            let records = [];
            let items = response.data.Items;

            items.map(item => {
                let record ={
                    languageCode: item.ResourceCode,
                    languageName: item.LanguageName
                };
                records.push(record);
            });

            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
            setLanguages(records);

            // Add it to cache
            SetCacheItem("languages", response.data.Items);
            backdropProgress(false);
        }).catch((err) => {
            console.error("In fetchLanguages(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    };

    let  refDataSelectOptions = [];
    
    async function fetchReferenceData(refType, lang) {
        return new Promise((resolve, reject) => {
            // console.log('In ManagePriceList  fetchReferenceData(): refType is: ', refType);
            // console.log('In ManagePriceList  fetchReferenceData(): lang is: ', lang);
            backdropProgress(true);

            refDataSelectOptions = [];

            let apiName = null;
            let path = null;
            let keyConditionExpression = null;
            let expressionAttributeNames = {};
            let expressionAttributeValues = {};
            
            switch(refType) {
                case "pricing-categories":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "pricing-categories",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                case "supported-domains":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "supported-domains",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                default:
                    break;
            }

            setProgressFlag(true);
        
            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    keyConditionExpression: keyConditionExpression,
                    expressionAttributeNames: expressionAttributeNames,
                    expressionAttributeValues: expressionAttributeValues,
                    // "keyConditionExpression": "#resourcePK = :resourcePK",
                    // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                    // "expressionAttributeValues": {":resourcePK": "languages"}
                },
            };
        
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
        
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In  fetchReferenceData(): initObj is: ', JSON.stringify(initObj, null, 2));
        
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In  fetchReferenceData(): response.length is: ', response.data.Items.length);
                // console.log('In  fetchReferenceData(): response is: ', JSON.stringify(response.data.Items, null, 2));
                response.data.Items.map((item) => {
                    let record = {
                        value: item.ResourceCode,
                        text: item.ResourceDesc,
                    };

                    if (record.value !== "all") {// Bypass all
                        refDataSelectOptions.push(record);
                    }
                });

                switch(refType) {
                    case "pricing-categories":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setCategoryOptions( refDataSelectOptions);
                        break;
                    case "supported-domains":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setDomainOptions(refDataSelectOptions);
                        break;
                    default:
                        break;
                }

                backdropProgress(false);
                resolve(refDataSelectOptions);
            }).catch((err) => {
                console.error("In ManagePriceList  fetchReferenceData(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject(err);
            });
        });
    }
        
    useEffect(() => {
        // console.log('In ManagePriceList(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        // sessionStorage.removeItem("current-live-event");
    }, []);

    useLayoutEffect(() => {
        // console.log("In ManagePriceList(): useLayoutEffect()");
        async function fetchItems() {
            await fetchLanguages();

            // await fetchCategories("ManagePriceList", appContext.currentLanguage);

            // await fetchDomains("ManagePriceList");

            // await fetchPriceListRecords();

            // try{
            //     // let imageKeyPrefix = "cob/jekwempu@tobisoft.co" + "/user/profile/";
            //     let imageKeyPrefix = appContext.shortDomain + "/" + appContext.userName + "/user/profile/";
            //     await FetchS3Object(imageKeyPrefix, imageObjectCallback);
            // } catch (error) {
            //     console.log("In ManagePriceList(): useLayoutEffect(): error is: ", error);
            // }

            // Fetch resource from catche
            let cacheItemKey = "manage-price-headers";
            cacheItemKey += ":";
            cacheItemKey += appContext.currentLanguage.languageCode;
            // console.log('In ManagePriceList(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

            if (
                appContext.clearCacheLanguageCodes &&
                appContext.clearCacheLanguageCodes.length > 0 &&
                appContext.clearCacheLanguageCodes.includes(appContext.currentLanguage.languageCode)
            ) {
                // console.log("In ManagePriceList(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                RemoveCacheItem(cacheItemKey);
            }

            let cacheItem = GetCacheItem(cacheItemKey);
            // console.log('In ManagePriceList(): useLayoutEffect(): cacheItem is: ', cacheItem);
            cacheItem = null; // remove after testing

            if (cacheItem === null) {
                // resource is not in cache, fetch from DB
                // console.log('In ManagePriceList(): useLayoutEffect(): resource is not in cache, fetch from DB');
                await fetchDbResources(cacheItemKey);
            } else {
                // console.log('In ManagePriceList(): useLayoutEffect(): got resource from cache');
                setPageLabelsRes(cacheItem);
                setRenderDomFlag(true);
            }
        }

        fetchItems();
    }, []);
    
    function handleChange(event) {
        // console.log("In handleChange(): event.target.id is: ", event.target.id);
        // console.log("In handleChange(): event.target.value is: ", event.target.value);
        // setSubmitButtonFlag(false);

        switch(event.target.id) {
            case "priceItemId":
                setPriceItemErrFlag(false);
                setPriceItem(event.target.value);

                setSubmitButtonFlag(false);
                if (!lodash.isEmpty(event.target.value)) {
                    if (
                        !lodash.isEmpty(event.target.value) &&
                        !lodash.isEmpty(domain) &&
                        !lodash.isEmpty(priceCategory) &&
                        !lodash.isEmpty(language)
                        ) {
                        setSubmitButtonFlag(true);
                    }
                }
                break;
            case "priceDetailsId":
                // console.log("In handleChange(): event.target.value is: ", event.target.value);
                setPriceDetailsErrFlag(false);
                setPriceDetails(event.target.value);
                break;
            default:
                break;
        }
    }
    
    function handleLanguageChange(event, item) {
        // console.log("In handleLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        setSubmitButtonFlag(false);        
        setLanguage(item);

        if (!lodash.isEmpty(item)) {
            Promise.all([
                fetchReferenceData("pricing-categories", item)
            ]).then(async (results) => {
                // console.log("In handleLanguageChange(): results is: ", results);
                await fetchReferenceData("supported-domains", item);
            }).catch(error => {
                console.log("In handleLanguageChange(): error is: ", error);
            });
        }

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(priceCategory) &&
            !lodash.isEmpty(priceItem) &&
            !lodash.isEmpty(domain) 
        ) {
            setSubmitButtonFlag(true);
        }
    }
    
    async function handleListLanguageChange(event, item) {
        // console.log("In handleListLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        // setSubmitButtonFlag(false);        
        setLanguage(item);

        setDomain(null);
        setPriceListRecords([]);
        setPriceListRecordsBackup([]);

        if (!lodash.isEmpty(item)) {
            Promise.all([
                await fetchReferenceData("supported-domains", item)
            ]).then(async (results) => {
                // console.log("In handleListLanguageChange(): results is: ", results);
            }).catch(error => {
                console.log("In handleListLanguageChange(): error is: ", error);
            });
        }
    }

    function handlePriceCategoryChange(event, item) {
        // console.log("In handlePriceCategoryChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setPriceCategoryErrFlag(false);
        
        setPriceCategory(item);

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(priceItem) &&
            !lodash.isEmpty(domain) 
            ) {
                setSubmitButtonFlag(true);
            }
    }

    function handleDomainChange(event, item) {
        // console.log("In handleDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            sessionStorage.setItem("media-domain-name", item.value);
        }

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(priceItem) &&
            !lodash.isEmpty(priceCategory) 
            ) {
                setSubmitButtonFlag(true);
            }
    }

    function handleListDomainChange(event, item) {
        // console.log("In handleListDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            fetchPriceListRecords(item);
        }
    }

    function initializeForm() {
        setPriceDetails("");
        // setPriceCategory(null);
        // setDomain(null);
        setPriceItem("");
    }

    function createPriceAction() {
        initializeForm();

        setLanguage(null);
        setDomain(null);

        setEditModeFlag(false);
        setShowListFlag(false);
        setOrderListFlag(false);
        setUploadFlag(false);
        setShowDetailsFormFlag(true);
    }

    function createMoreAction() {
        initializeForm();

        setLanguage(null);
        setDomain(null);
        setPriceCategory(null);

        setEditModeFlag(false);
        setShowListFlag(false);
        setUploadFlag(false);
        setOrderListFlag(false);
        setShowDetailsFormFlag(true);
    }

    function uploadAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setOrderListFlag(false);
        setUploadFlag(true);
    }
    
    function convertAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setUploadFlag(false);
        setOrderListFlag(false);
        setCloneListFlag(false);
        setConvertFlag(true);
    }
    
    function cloneListAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setUploadFlag(false);
        setOrderListFlag(false);
        setConvertFlag(false);
        setCloneListFlag(true);
    }
        
    async function closeCloneForm() {
        await fetchPriceListRecords(domain);

        setTimeout(() => {
            setShowDetailsFormFlag(false);
            setUploadFlag(false);
            setConvertFlag(false);
            setOrderListFlag(false);
            setCloneListFlag(false);
            setShowListFlag(true);
        }, 500);
    }
    
    async function closeConvertForm() {
        await fetchPriceListRecords(domain);
        
        setTimeout(() => {
            setShowDetailsFormFlag(false);
            setUploadFlag(false);
            setCloneListFlag(false);
            setConvertFlag(false);
            setOrderListFlag(false);
            setShowListFlag(true);
        }, 500);
    }
    
    async function closeDetailsForm() {
        await fetchPriceListRecords(domain);
        
        setTimeout(() => {
            setShowDetailsFormFlag(false);
            setUploadFlag(false);
            setGlobalErrorMsg("");
            setGlobalErrorFlag(false);
            setOrderListFlag(false);
            setShowListFlag(true);
        }, 500);
    }
    
    async function closeUploadForm() {
        await fetchPriceListRecords(domain);
        
        setTimeout(() => {
            setShowListFlag(true);
            setShowDetailsFormFlag(false);
            setUploadFlag(false);
            setOrderListFlag(false);
        }, 500);
    }

    async function orderListAction() {
        // console.log("In orderListAction(): language is: ", language);
        await fetchReferenceData("pricing-categories", language);

        setPriceCategory(null);
        setFilteredRecords([]);

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setUploadFlag(false);
        setOrderListFlag(true);
    }

    function closeOrderListForm() {
        initializeForm();

        setEditModeFlag(false);
        setShowListFlag(true);
        setShowDetailsFormFlag(false);
        setUploadFlag(false);
        setOrderListFlag(false);
    }
    
    // function liveEventsUploadCallback() {
    //     setEditModeFlag(false);
    //     setShowListFlag(true);
    //     setShowDetailsFormFlag(false);
    //     setUploadFlag(false);
    // }
    
    async function createService() {
        // console.log("In createService(): language is: ", language);
        // console.log("In createService(): domain is: ", domain);
        // console.log("In createService(): priceCategory is: ", priceCategory);
        // console.log("In createService(): priceDetails is: ", priceDetails);
        // console.log("In createService(): priceItem is: ", priceItem);
        // console.log("In createService(): timeZone is: ", timeZone);
        // console.log("In createService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In createService(): appContext.userName is: ", appContext.userName);
    
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In createService(): Validation passed.");
            // console.log("In createService(): Validation passed.");

            const timestamp = moment.tz(Date.now(), timeZone);
            // console.log("In createService(): timestamp is: ", timestamp);

            // const startTime = moment.tz(priceStartTime, timeZone);
            // console.log("In createService(): startTime is: ", startTime);

            backdropProgress(true);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            let uniqueId = GenerateUniqueName();

            let key = {
                PricePK: domain.value + "#" + language.languageCode + "#price#list",
                PriceSK: domain.value + "#" + language.languageCode + "#price#list#" + priceCategory.value + "#" + uniqueId,
            };
            // console.log("In createService(): key is: ", key);

            let item = {
                "PricePK": key.PricePK,
                "PriceSK": key.PriceSK,

                "PriceId": uniqueId,

                "UniqueAttribute": lodash.isEmpty(key) ? null : key,
                "LanguageObject": lodash.isEmpty(language) ? null : language,
                "LanguageCode": lodash.isEmpty(language) ? null : language.languageCode,
                "Domain": domain,

                "PriceDomain": domain.value,
                "PriceCategory": lodash.isEmpty(priceCategory) ? null : priceCategory,
                "PriceCategoryCode": lodash.isEmpty(priceCategory) ? null : priceCategory.value,
                "PriceItem": lodash.isEmpty(priceItem) ? null : priceItem,
                "PriceDetails": lodash.isEmpty(priceDetails) ? null : priceDetails,

                "PriceItemSeqNumber": 0,

                "DomainLangCategoryCode": domain.value + "#" + language.languageCode + "#" + priceCategory.value,// For GSI

                "ModifiedBy": appContext.userName,
                "CreatedAt": timestamp,
                "UpdatedAt": timestamp,
            };

            // console.log('In createService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_not_exists(UniqueAttribute)";

            let params = {
                apiName: "PriceAPI",
                path: "/dynamodb/price/put",
                payload: {
                    item: item,
                    conditionExpression: conditionExpression,
                    // updateExpression: updateExpression,
                    // expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In createService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In createService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In createService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar 
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);
            }).catch((err) => {
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                switch(err.code) {
                    case "ConditionalCheckFailedException":
                        console.error("In createService(): Duplicate Error");
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        break;
                    default:
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        console.error("In createService(): err is: ", JSON.stringify(err, null, 2));
                        break;
                }
            });
        }
    }

    async function updateService() {
        // console.log("In updateService(): language is: ", language);
        // console.log("In updateService(): domain is: ", domain);
        // console.log("In updateService(): priceCategory is: ", priceCategory);
        // console.log("In updateService(): priceDetails is: ", priceDetails);
        // console.log("In updateService(): priceItem is: ", priceItem);
        // console.log("In updateService(): timeZone is: ", timeZone);
        // console.log("In updateService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In updateService(): appContext.userName is: ", appContext.userName);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In updateService(): Validation passed.");

            // let uniqueId = GenerateUniqueName();// conversion
                
            const timestamp = moment.tz(Date.now(), timeZone);

            backdropProgress(true);
            const startTime = moment.tz(blogStartTime, timeZone);
            // console.log("In updateService(): startTime is: ", startTime);

            let key = {
                PricePK: appContext.currentPK,
                PriceSK: appContext.currentSK,
            };
            // console.log("In updateService(): key is: ", key);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
            let updateExpression =
                "SET " +
                "UniqueAttribute = :uniqueAttribute, " +
                
                // "PriceDomain = :priceDomain, " +
                "PriceCategory = :priceCategory, " +
                "PriceCategoryCode = :priceCategoryCode, " +
                "PriceItem = :priceItem, " +
                "PriceDetails = :priceDetails, " +
                
                "DomainLangCategoryCode = :domainLangCategoryCode, " +

                "ModifiedBy = :modifiedBy, " + 
                "UpdatedAt = :updatedAt";

            let expressionAttributeValues = {
                ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
                // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
                // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,
                
                // ":uniqueId": lodash.isEmpty(uniqueId) ? null : uniqueId,// conversion
                
                // ":priceDomain": lodash.isEmpty(domain) ? null : domain,
                ":priceCategory": lodash.isEmpty(priceCategory) ? null : priceCategory,
                ":priceCategoryCode": lodash.isEmpty(priceCategory) ? null : priceCategory.value,
                ":priceItem": lodash.isEmpty(priceItem) ? null : priceItem,
                ":priceDetails": lodash.isEmpty(priceDetails) ? null : priceDetails,

                ":domainLangCategoryCode": domain.value + "#" + language.languageCode + "#" + priceCategory.value,// For GSI
                
                ":modifiedBy": appContext.userName,
                ":updatedAt": timestamp,
            };
            // console.log('In updateService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_exists(UniqueAttribute)";

            let params = {
                apiName: "PriceAPI",
                path: "/dynamodb/price/update",
                payload: {
                    key: key,
                    conditionExpression: conditionExpression,
                    updateExpression: updateExpression,
                    expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In updateService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In updateService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.put(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In updateService(): response is: ', JSON.stringify(response, null, 2));
                setShowDetailsFormFlag(false);
                setUploadFlag(false);

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                // initializeForm();

                backdropProgress(false);

                await fetchPriceListRecords(domain);
                setShowListFlag(true);
            }).catch((err) => {
                console.error("In updateService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    async function deleteService(recToDelete) {
        // console.log("In deleteService(): recToDelete is: ", recToDelete);
        // setDeleteCompleteFlag(false);
        // let itemsToDelete = [];

        if (!lodash.isEmpty(recToDelete)) {
            backdropProgress(true);

            let key = {
                PricePK: recToDelete.PricePK,
                PriceSK: recToDelete.PriceSK,
            };

            let params = {
                apiName: "PriceAPI",
                path: "/dynamodb/price/delete",
                payload: {
                    key: key,
                },
            };
    
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In deleteService(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In deleteService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                backdropProgress(false);
                await fetchPriceListRecords(domain);
            }).catch((err) => {
                console.error("In deleteService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    function validateForm() {
        // console.log("In validateForm()");
        let formErrFlag = false;
  
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
    
        setLanguageErrFlag(false);
        setPriceDetailsErrFlag(false);
        setPriceItemErrFlag(false);
        setPriceCategoryErrFlag(false);
        setDomainErrFlag(false);

        if (lodash.isEmpty(language)) {
            formErrFlag = true;
            setLanguageErrFlag(true);
        }
    
        if (lodash.isEmpty(domain)) {
            formErrFlag = true;
            setDomainErrFlag(true);
        }
    
        if (lodash.isEmpty(priceCategory)) {
            formErrFlag = true;
            setPriceCategoryErrFlag(true);
        }
    
        if (lodash.isEmpty(priceItem)) {
            formErrFlag = true;
            setPriceItemErrFlag(true);
        }
    
        if (lodash.isEmpty(priceDetails)) {
            formErrFlag = true;
            setPriceDetailsErrFlag(true);
        }
    
        if (formErrFlag) {
            setGlobalErrorFlag(true);
        }
        // console.log("In validateForm(): formErrFlag is: ", formErrFlag);
        return formErrFlag;
    }

    async function buttonFormAction() {
        // console.log("In buttonFormAction()");

        if (editModeFlag) {
            await updateService();
        } else {
            await createService();
        }
    }
    
    function snackbarOnClose(event, reason) {
        setSnackbarOpen(false);
    }

    function handleMultipleSelectionChange(data) {
        // console.log("In handleMultipleSelectionChange(): data.value is: ", data.value);
        setSelectedTableRows(data.value);
        setTableDirtyFlag(true);
    }

    //------------------
    // For AlertDialog
    //------------------
    const [deleteType, setDeleteType] = useState("");

    function showAlertDialog(item, type) {
        // console.log("In showAlertDialog(): type is: ", type);
        setDeleteType(type);
        
        if (type && type === "single") {// single deletion
            setRecordToDelete(item);

            let subContent = item.PriceCategoryText;
            if (!lodash.isEmpty(item.PriceDetails)) {
                subContent += " - ";
                subContent += item.PriceDetails;
            }
            setDialogSubContent(subContent);
            setAlertOpen(true);
        } else {// multiple deletions
            let msg = FormatNumber(selectedTableRows.length, appContext.currentLanguage.languageCode);
            msg += " ";
            msg += selectedTableRows.length > 1 ? priceRecordsLabel : priceRecordLabel;
            setDialogSubContent(msg);
            setAlertOpen(true);
        }
    }

    function alertClickAgree() {
        // Close Alert
        if (deleteType && deleteType === "single") {
            setAlertOpen(false);
            deleteService(recordToDelete);
        } else {
            setAlertOpen(false);
            // deleteMultipleAction();
            processMultipleDeletionRecs();
        }
    }

    function alertClickDisagree() {
        // Close Alert
        setAlertOpen(false);
        return false;
    }

    function alertClose() {
        // Close Alert
        setAlertOpen(false);
    }

    function deleteIconTemplate(rowData, column) {
        return <DeleteIcon color="secondary" onClick={() => showAlertDialog(rowData, "single")}/>
    }

    function editIconTemplate(rowData, column) {
        return <EditIcon color="primary" onClick={() => editRecordAction(rowData)}/>
    }

    // async function updateLiveEventStatus(rowData) {
    //     // console.log("In updateLiveEventStatus(): rowData.CobPK is: ", rowData.CobPK);
    //     // console.log("In updateLiveEventStatus(): rowData.CobSK is: ", rowData.CobSK);

    //     backdropProgress(true);

    //     // Initialize
    //     setGlobalErrorFlag(false);
    //     setGlobalErrorMsg("");
                
    //     const timestamp = new Date();

    //     backdropProgress(true);

    //     let key = {
    //         CobPK: rowData.CobPK,
    //         CobSK: rowData.CobSK,
    //     };
    //     // console.log("In updateLiveEventStatus(): key is: ", key);

    //     // ConditionExpression: "attribute_exists(resourceCode)",
    //     let conditionExpression = "";

    //     // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
    //     let updateExpression =
    //         "SET " +
    //         "UniqueAttribute = :uniqueAttribute, " +
    //         // "LanguageObject = :languageObject, " +
    //         // "LanguageCode = :languageCode, " +

    //         "LiveEventStarted = :liveEventStarted, " +

    //         "ModifiedBy = :modifiedBy, " + 
    //         "UpdatedAt = :updatedAt";

    //     let expressionAttributeValues = {
    //         ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
    //         // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
    //         // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,

    //         ":liveEventStarted": true,

    //         ":modifiedBy": appContext.userName,
    //         ":updatedAt": timestamp,
    //     };
    //     // console.log('In updateLiveEventStatus(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

    //     conditionExpression = "attribute_exists(UniqueAttribute)";

    //     let params = {
    //         apiName: "CobAPI",
    //         path: "/dynamodb/cob/update",
    //         payload: {
    //             key: key,
    //             conditionExpression: conditionExpression,
    //             updateExpression: updateExpression,
    //             expressionAttributeValues: expressionAttributeValues,
    //         },
    //     };
    //     // console.log('In updateLiveEventStatus(): params.payload is: ', JSON.stringify(params.payload, null, 2));

    //     let initObj = {
    //         headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "",
    //         },
    //         response: true, // return entire response object instead of response.data
    //         body: {},
    //     };

    //     initObj.headers.Authorization = appContext.jwtToken;
    //     initObj.body = params.payload;
    //     // console.log('In updateLiveEventStatus(): initObj is: ', JSON.stringify(initObj, null, 2));

    //     API.put(params.apiName, params.path, initObj).then(async (response) => {
    //         // console.log('In updateLiveEventStatus(): response is: ', JSON.stringify(response, null, 2));
    //         setShowDetailsFormFlag(false);
    //         setUploadFlag(false);

    //         // Show Snackbar
    //         setSnackbarsVariant("success");
    //         setSnackbarsMsg(operationSuccessMsg);
    //         setSnackbarOpen(true);

    //         // initializeForm();

    //         backdropProgress(false);

    //         // await fetchPriceListRecords();
    //         setShowListFlag(true);
    //     }).catch((err) => {
    //         console.error("In updateLiveEventStatus(): err is: ", JSON.stringify(err, null, 2));
    //         setGlobalErrorFlag(true);
    //         setGlobalErrorMsg(operationFailureMsg);
    //         backdropProgress(false);
    //     });
    // }

    // async function forcedToEndUpdate(rowData, showStatus) {
    //     // console.log("In forcedToEndUpdate(): rowData.CobPK is: ", rowData.CobPK);
    //     // console.log("In forcedToEndUpdate(): rowData.CobSK is: ", rowData.CobSK);

    //     backdropProgress(true);

    //     // Initialize
    //     setGlobalErrorFlag(false);
    //     setGlobalErrorMsg("");

    //     // console.log("In forcedToEndUpdate(): Validation passed.");
            
    //     const timestamp = new Date();

    //     backdropProgress(true);

    //     let key = {
    //         CobPK: rowData.CobPK,
    //         CobSK: rowData.CobSK,
    //     };
    //     // console.log("In forcedToEndUpdate(): key is: ", key);

    //     // ConditionExpression: "attribute_exists(resourceCode)",
    //     let conditionExpression = "";

    //     // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
    //     let updateExpression =
    //         "SET " +
    //         "UniqueAttribute = :uniqueAttribute, " +
    //         // "LanguageObject = :languageObject, " +
    //         // "LanguageCode = :languageCode, " +

    //         "ForcedToEndFlag = :forcedToEndFlag, " +

    //         "ModifiedBy = :modifiedBy, " + 
    //         "UpdatedAt = :updatedAt";

    //     let expressionAttributeValues = {
    //         ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
    //         // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
    //         // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,

    //         ":forcedToEndFlag": true,

    //         ":modifiedBy": appContext.userName,
    //         ":updatedAt": timestamp,
    //     };
    //     // console.log('In forcedToEndUpdate(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

    //     conditionExpression = "attribute_exists(UniqueAttribute)";

    //     let params = {
    //         apiName: "CobAPI",
    //         path: "/dynamodb/cob/update",
    //         payload: {
    //             key: key,
    //             conditionExpression: conditionExpression,
    //             updateExpression: updateExpression,
    //             expressionAttributeValues: expressionAttributeValues,
    //         },
    //     };
    //     // console.log('In forcedToEndUpdate(): params.payload is: ', JSON.stringify(params.payload, null, 2));

    //     let initObj = {
    //         headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "",
    //         },
    //         response: true, // return entire response object instead of response.data
    //         body: {},
    //     };

    //     initObj.headers.Authorization = appContext.jwtToken;
    //     initObj.body = params.payload;
    //     // console.log('In forcedToEndUpdate(): initObj is: ', JSON.stringify(initObj, null, 2));

    //     API.put(params.apiName, params.path, initObj).then(async (response) => {
    //         // console.log('In forcedToEndUpdate(): response is: ', JSON.stringify(response, null, 2));
    //         setShowDetailsFormFlag(false);
    //         setUploadFlag(false);

    //         // Show Snackbar
    //         if (showStatus) {
    //             setSnackbarsVariant("success");
    //             setSnackbarsMsg(operationSuccessMsg);
    //             setSnackbarOpen(true);
    //         }

    //         // initializeForm();

    //         backdropProgress(false);

    //         // await fetchPriceListRecords();
    //         setShowListFlag(true);
    //     }).catch((err) => {
    //         console.error("In forcedToEndUpdate(): err is: ", JSON.stringify(err, null, 2));
    //         setGlobalErrorFlag(true);
    //         setGlobalErrorMsg(operationFailureMsg);
    //         backdropProgress(false);
    //     });
    // }
    
    function sortDateField(event) {
        // console.log("In sortDateField(): event is: ", event);

        var values = [...priceListRecords];
        values.sort((data1, data2) => {
            // Note: We are using actual DateTime object and not the dateTimeFormatted 
            // used for display on data table.
            let value1 = data1.UpdatedAt;
            let value2 = data2.UpdatedAt;
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });

        return values;
    }

    let processedRecs = 0;

    async function deleteMultipleAction() {
        // console.log("In deleteMultipleAction(): selectedTableRows.length is: ", selectedTableRows.length);
        // console.log("In deleteMultipleAction(): selectedTableRows is: ", selectedTableRows);

        // Disable Save changes button
        setTableDirtyFlag(false);

        return new Promise((resolve, reject) => {
            if (selectedTableRows && selectedTableRows.length > 0) {
                selectedTableRows.map(record => {
                    setTimeout(async () => {
                        await deleteService(record);

                        processedRecs++;

                        if (processedRecs === selectedTableRows.length) {
                            resolve("Done");
                        }
                    }, 1000);
                });
            }
        });
    }

    async function processMultipleDeletionRecs() {
        // console.log("In processMultipleDeletionRecs()");

        //-------------------
        // Promise.all
        //-------------------
        Promise.all(
            [
                deleteMultipleAction()
            ]).then(async (results) => {
                // console.log("In processMultipleDeletionRecs(): results is: ", results);
                // console.log("In processMultipleDeletionRecs(): records deleted from AWS, now delete them from DataTable");
                // Remove deleted records from DataTable
                let remainingRecords = FilterArrayFromArray(priceListRecords, selectedTableRows);
                if (remainingRecords) {
                    // setBlogRecords(remainingRecords);
                    setSelectedTableRows([]);
                }
            });
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        setBlogContent(item.BlogContent);

        setRecordToEdit(item);

        appContext.currentPK = item.PricePK;
        appContext.currentSK = item.PriceSK;
        await getPriceListRecord({PricePK: item.PricePK, PriceSK: item.PriceSK,});

        setTimeout(() => {
            setShowListFlag(false);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);
            setShowDetailsFormFlag(true);
        }, 500);
    }

    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    //------------------------
    // For sliceText and Dialog
    //------------------------

    function sliceMoreAction(record) {
        setDialogData(record.BlogContent);
        setDialogHeader(record.PriceItem);
        setDlgVisibleFlag(true);
    }

    function hideModalDlg() {
        setDlgVisibleFlag(false);
    }
  
    function sliceText(text, maxChars) {
        let retValue = text;
        if (text.length > maxChars) {
            retValue = text.substring(0, (maxChars - 1));
            retValue += " ...";
        }
        return retValue;
    }

    //------------------
    // For OrderList
    //------------------

    function handleFilteredRecords(event) {
        setFilteredRecords(event.value);
    }

    function handleOrderListCategoryChange(event, item) {
        event.preventDefault();
        event.persist();
        // console.log("In handleOrderListCategoryChange(): item is ", item);
        // console.log("In handleOrderListCategoryChange(): priceListRecords is ", priceListRecords);

        setFilteredRecords([]);
        setSubmitButtonFlag(false);
        
        setPriceCategory(item);

        if (!lodash.isEmpty(item) && priceListRecords && priceListRecords.length > 0) {
            let filteredRecords = priceListRecords.filter(record => record.PriceCategory.value === item.value);
            setFilteredRecords(filteredRecords);
            if (filteredRecords && filteredRecords.length > 0) {
                filteredRecords.sort((a, b) => (a.PriceItemSeqNumber > b.PriceItemSeqNumber ? 1 : -1));
                setFilteredRecords(filteredRecords);
                setSubmitButtonFlag(true);
            }
        }
    }

    function orderListTemplate(record) {
        return (
            <Grid container justify="flex-start" style={{width: '100% !important', display: 'flex'}}>
                {/* <Grid item xs={12} sm={12}>
                    <Grid container justify="flex-start" style={{width: '100% !important', display: 'flex'}}>
                            <Grid item xs={2} sm={2} style={{textAlign: 'left'}}>
                                <Typography variant="caption" style={{textAlign: 'left'}}>
                                    {gridCategory}:
                                </Typography>
                            </Grid>
                            <Grid item xs={10} sm={10} style={{textAlign: 'left'}}>
                                <Typography variant="caption" style={{textAlign: 'left'}}>
                                    {record.PriceCategoryText}
                                </Typography>
                            </Grid>
                    </Grid>
                </Grid> */}

                <Grid item xs={12} sm={12}>
                    <Grid container justify="flex-start">
                            <Grid item xs={1} sm={1} style={{textAlign: 'left'}}>
                                <Typography variant="caption" style={{textAlign: 'left'}}>
                                    {record.PriceItemSeqNumber}.
                                    {/* {gridPriceItem}: */}
                                </Typography>
                            </Grid>
                            {isMobileView ?
                                <>
                                    <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {record.PriceItem}
                                        </Typography>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item xs={2} sm={2} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {gridPriceItem}:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sm={9} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {record.PriceItem}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                    </Grid>
                </Grid>

                {record.PriceDetails && 
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="flex-start">
                                <Grid item xs={1} sm={1} style={{textAlign: 'left'}}>
                                    <Typography variant="caption" style={{textAlign: 'left'}}>
                                        {""}
                                        {/* {gridItemDetails}: */}
                                    </Typography>
                                </Grid>

                                {isMobileView ?
                                <>
                                    <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {record.PriceDetails}
                                        </Typography>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item xs={2} sm={2} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {gridItemDetails}:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sm={9} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {record.PriceDetails}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                }
            </Grid>
        );
    }
    
    async function organizeService() {
        // console.log("In organizeService(): filteredRecords is: ", filteredRecords);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (filteredRecords && filteredRecords.length > 0) {
            // console.log("In organizeService(): Validation passed.");

            // Get user timezone
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const timestamp = moment.tz(Date.now(), timeZone);
            const items = [];

            filteredRecords.map((record, index) => {
                let key = {
                    PricePK: record.PricePK,
                    PriceSK: record.PriceSK,
                };
                // console.log("In updateGuestBookService(): key is: ", key);

                // ConditionExpression: "attribute_exists(resourceCode)",
                let conditionExpression = "";

                // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
                let updateExpression =
                    "SET " +
                    "UniqueAttribute = :uniqueAttribute, " +

                    "PriceItemSeqNumber = :priceItemSeqNumber, " +

                    "ModifiedBy = :modifiedBy, " + 
                    "UpdatedAt = :updatedAt";
        
                let expressionAttributeValues = {
                    ":uniqueAttribute": key,

                    ":priceItemSeqNumber": index + 1,

                    ":modifiedBy": appContext.userName,
                    ":updatedAt": timestamp,
                };
                // console.log('In updateGuestBookService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

                conditionExpression = "attribute_exists(UniqueAttribute)";

                let item = {
                    key: key,
                    conditionExpression: conditionExpression,
                    updateExpression: updateExpression,
                    expressionAttributeValues: expressionAttributeValues,
                };
                items.push(item);
            });
            // console.log("In organizeService(): items is: ", items);

            let params = {
                apiName: "PriceAPI",
                path: "/dynamodb/price/customBatchUpdate",
                payload: {
                    updateItems: items,
                },
            };
            // console.log('In organizeService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In organizeService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In organizeService(): response is: ', JSON.stringify(response, null, 2));
                setShowDetailsFormFlag(false);
                setUploadFlag(false);
                setOrderListFlag(false);

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);

                await fetchPriceListRecords();
                setShowListFlag(true);
            }).catch((err) => {
                console.error("In organizeService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }

    }

    return (
        <div dir={direction} className={clsx(classes.root, classes.divWrapper)}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {renderDomFlag &&
                <>
                    {/* { !appContext.signedIn && <Redirect to='/signIn' />} */}
                    <CssBaseline />
                    <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />

                    {isDeskTopView && 
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
                            <Container component="div" className={classes.main} maxWidth="lg">
                                <StyledPaper elevation={3} className={classes.paper}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h5" color="primary" style={{ textAlign: "center" }}>
                                                {managePriceListLabel}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                                                {requiredFields}
                                            </Typography>
                                        </Grid>

                                        {globalErrorFlag && (
                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={24} />
                                                <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                {showListFlag &&
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                                *{languageLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="languageId"
                                                                            value={language}
                                                                            options={languages}
                                                                            getOptionLabel={option => option.languageName}
                                                                            getOptionSelected={option => option.languageName}
                                                                            onChange={handleListLanguageChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={languagePrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={languageErrFlag}
                                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                                *{domainLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="domainId"
                                                                            value={domain}
                                                                            options={domainOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleListDomainChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            // disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={domainPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={domainErrFlag}
                                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            {priceListRecords && priceListRecords.length > 0 ?
                                                                <>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <LineSpacing value={12} />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-start">
                                                                                    <Button variant="contained" size="small" disabled={selectedTableRows && selectedTableRows.length > 0 ? false : true} color="primary" onClick={() => showAlertDialog(null, "multiple")}>
                                                                                        <DeleteIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {deleteLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={2}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="contained" size="small" disabled={false} color="primary" onClick={orderListAction}>
                                                                                        <ImportExportIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {organizeLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={2}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="contained" size="small" disabled={false} color="primary" onClick={cloneListAction}>
                                                                                        <FileCopyIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {cloneListLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={2}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="contained" size="small" disabled={false} color="primary" onClick={convertAction}>
                                                                                        <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {convertLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-end">
                                                                                    <Button variant="contained" size="small" disabled={false} color="primary" onClick={createMoreAction}>
                                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {createMoreLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12}>
                                                                        <LineSpacing value={8} />
                                                                    </Grid>
                                                                    
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12}>
                                                                            <div className="datatable-doc-demo">
                                                                                <DataTable 
                                                                                    value={priceListRecords}
                                                                                    resizableColumns={true} 
                                                                                    columnResizeMode={"fit"}
                                                                                    sortMode={"multiple"}
                                                                                    responsive={true}
                                                                                    reorderableColumns={true}

                                                                                    // selectionMode={"multiple"}
                                                                                    selection={selectedTableRows}
                                                                                    onSelectionChange={handleMultipleSelectionChange}

                                                                                    // paginator={visibleRowsCount > 10 ? paginatorFlag : !paginatorFlag} 
                                                                                    paginator={priceListRecords && priceListRecords.length > 10} 
                                                                                    // paginatorLeft={paginatorLeft}

                                                                                    paginatorTemplate={paginatorTemplate}
                                                                                    currentPageReportTemplate={"{first}" + " " + toLabel + " " + "{last}" + " " + ofLabel + " " + "{totalRecords}" + " " + recordsLabel} 
                                                                                    rows={visibleRowsCount} 
                                                                                    rowsPerPageOptions={rowsPerPageOptions}
                                                                                    // className="p-datatable-responsive-cob"
                                                                                    // className="p-datatable-striped"
                                                                                    emptyMessage={noRecordsFound}
                                                                                    className={clsx("p-datatable-striped", "p-datatable-customers")}
                                                                                    rowHover 
                                                                                    >

                                                                                    <Column 
                                                                                        header={deleteLabel} 
                                                                                        selectionMode="multiple" 
                                                                                        style={{width:'5em', textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="PriceCategoryText" 
                                                                                        header={gridCategory} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="PriceItem" 
                                                                                        header={gridPriceItem} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="PriceDetails" 
                                                                                        header={gridItemDetails} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="FormattedDateTime" 
                                                                                        header={lastUpdateLabel} 
                                                                                        sortable={true} 
                                                                                        sortFunction={sortDateField}
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="deleteIcon" 
                                                                                        header={deleteLabel} 
                                                                                        body={deleteIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="editIcon" 
                                                                                        header={editLabel} 
                                                                                        body={editIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                </DataTable>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            :
                                                                <>
                                                                    {noRecordFoundFlag &&
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={12} sm={12}>
                                                                                <Typography variant="body1" color="secondary">
                                                                                    {noPriceListRecordsFound}
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <LineSpacing value={12} />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <Button variant="contained" size="large" disabled={false} color="primary" onClick={createPriceAction}>
                                                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                    {createPriceListLabel}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {showDetailsFormFlag && 
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                                *{languageLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="languageId"
                                                                            value={language}
                                                                            options={languages}
                                                                            getOptionLabel={option => option.languageName}
                                                                            getOptionSelected={option => option.languageName}
                                                                            onChange={handleLanguageChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={languagePrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={languageErrFlag}
                                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                                *{domainLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="domainId"
                                                                            value={domain}
                                                                            options={domainOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleDomainChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={domainPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={domainErrFlag}
                                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={priceCategoryErrFlag}>
                                                                        <label htmlFor="priceCategoryId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={priceCategoryErrFlag ? "secondary" : "inherit"}>
                                                                                *{priceCategoryLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="priceCategoryId"
                                                                            value={priceCategory}
                                                                            options={categoryOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handlePriceCategoryChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={priceCategoryPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={priceCategoryErrFlag}
                                                                                    helperText={priceCategoryErrFlag ? priceCategoryErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={priceItemErrFlag}>
                                                                        <label htmlFor="priceItemId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={priceItemErrFlag ? "secondary" : "inherit"}>
                                                                                *{priceItemLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <TextField
                                                                            id="priceItemId"
                                                                            value={priceItem}
                                                                            placeholder={priceItemPrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={priceItemErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            helperText={priceItemErrFlag ? priceItemErrMsg : ""}
                                                                            // InputProps={{
                                                                            //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                            // }}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={priceDetailsErrFlag}>
                                                                                <label htmlFor="priceDetailsId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={priceDetailsErrFlag ? "secondary" : "inherit"}>
                                                                                        {priceDetailsLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextareaAutosize
                                                                                    id="priceDetailsId"
                                                                                    aria-label={priceDetailsLabel}
                                                                                    rows={1.5}
                                                                                    rowsMax={6}
                                                                                    value={priceDetails}
                                                                                    placeholder={priceDetailsPrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    // autoFocus={true}
                                                                                    margin="none"
                                                                                    className={classes.textArea}
                                                                                    maxLength={textMaxLength}
                                                                                />
                                                                                <FormHelperText>{priceDetailsErrFlag ? priceDetailsErrMsg : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={buttonFormAction}>
                                                                    {editModeFlag ?
                                                                    <>
                                                                        <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {updatePriceListLabel} 
                                                                    </>
                                                                    : 
                                                                    <>
                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {createPriceListLabel}
                                                                    </>
                                                                    }
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {convertFlag &&
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <PricingConverter 
                                                                showHeadersFlag={false}
                                                                referrer={"ManagePriceList"}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                {cloneListFlag &&
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeCloneForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <ClonePriceList />
                                                        </Grid>
                                                    </>
                                                }

                                                {orderListFlag &&
                                                    <Container component="div" className={classes.main} maxWidth="md">
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeOrderListForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-start">
                                                                <Typography variant="h6" color="primary">
                                                                    {organizePriceListLabel}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={priceCategoryErrFlag}>
                                                                        <label htmlFor="priceCategoryId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={priceCategoryErrFlag ? "secondary" : "inherit"}>
                                                                                *{priceCategoryLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="priceCategoryId"
                                                                            value={priceCategory}
                                                                            options={categoryOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleOrderListCategoryChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={priceCategoryPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={priceCategoryErrFlag}
                                                                                    helperText={priceCategoryErrFlag ? priceCategoryErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center" style={{width: '100% !important'}}>
                                                                <div style={{minWidth: '100%'}}>
                                                                    <OrderList 
                                                                        value={filteredRecords} 
                                                                        header={availablePriceItemsLabel} 
                                                                        dragdrop={dragdropFlag}
                                                                        listStyle={{width: '100% !important', height:'auto'}} 
                                                                        dataKey="PriceId"
                                                                        itemTemplate={orderListTemplate} 
                                                                        onChange={(e) => handleFilteredRecords(e)}
                                                                        style={{width: '100% !important', display: 'flex'}}
                                                                        >
                                                                    </OrderList>
                                                                </div>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={organizeService}>
                                                                    <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                    {saveLabel} 
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Container>
                                                }

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={18} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledPaper>
                            </Container>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={48} />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    }

                    {isTabletView && 
                        <ManagePriceListTv
                            managePriceListLabel={managePriceListLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            domainLabel={domainLabel}
                            domainPrompt={domainPrompt}
                            domainErrMsg={domainErrMsg}
                                
                            priceDetailsLabel={priceDetailsLabel}
                            priceDetailsPrompt={priceDetailsPrompt}
                            priceDetailsErrMsg={priceDetailsErrMsg}
                            // priceDetailsMsg={priceDetailsMsg}
                            
                            priceItemLabel={priceItemLabel}
                            priceItemPrompt={priceItemPrompt}
                            priceItemErrMsg={priceItemErrMsg}
                            // selectTitlePrompt={selectTitlePrompt}
                    
                            priceCategoryLabel={priceCategoryLabel}
                            priceCategoryPrompt={priceCategoryPrompt}
                            priceCategoryErrMsg={priceCategoryErrMsg}
                            gridCategory={gridCategory}
                            // editLabel={editLabel}
                            // deleteLabel={deleteLabel}

                            organizeLabel={organizeLabel}
                            organizePriceListLabel={organizePriceListLabel}
                            availablePriceItemsLabel={availablePriceItemsLabel}
                            saveLabel={saveLabel}
                            orderListAction={orderListAction}
                            orderListFlag={orderListFlag}
                            handleOrderListCategoryChange={handleOrderListCategoryChange}
                    
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            uploadLabel={uploadLabel}
                            createPriceListLabel={createPriceListLabel}
                            updatePriceListLabel={updatePriceListLabel}
                            createLabel={createLabel}
                            convertPriceListLabel={convertPriceListLabel}
                            closeLabel={closeLabel}
                            noPriceListRecordsFound={noPriceListRecordsFound} 
                    
                            gridPriceItem={gridPriceItem}
                            gridItemDetails={gridItemDetails}
                            // gridEventUrl={gridEventUrl}
                            lastUpdateLabel={lastUpdateLabel}
                            convertLabel={convertLabel}
                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            moreLabel={moreLabel}
                            // cloneEventLabel={cloneEventLabel}

                            filteredRecords={filteredRecords}
                            dragdropFlag={dragdropFlag}
                            orderListTemplate={orderListTemplate}
                            closeOrderListForm={closeOrderListForm}
                            handleFilteredRecords={handleFilteredRecords}
                            organizeService={organizeService}
                                                
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showListFlag={showListFlag}
                            priceListRecords={priceListRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noRecordFoundFlag={noRecordFoundFlag}
                            createPriceAction={createPriceAction}
                            showDetailsFormFlag={showDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleListLanguageChange={handleListLanguageChange}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}
                            domain={domain}
                            domainOptions={domainOptions}
                            handleListDomainChange={handleListDomainChange}
                            handleDomainChange={handleDomainChange}
                            priceCategoryErrFlag={priceCategoryErrFlag}
                            priceCategory={priceCategory}
                            categoryOptions={categoryOptions}
                            handlePriceCategoryChange={handlePriceCategoryChange}
                            domainErrFlag={domainErrFlag}
                            handleChange={handleChange}
                            // selectImageAction={selectImageAction}
                            priceDetailsErrFlag={priceDetailsErrFlag}
                            priceDetails={priceDetails}
                            priceItemErrFlag={priceItemErrFlag}
                            priceItem={priceItem}
                            blogStartTimeErrFlag={blogStartTimeErrFlag}
                            blogStartTime={blogStartTime}
                            // handleBlogStartTimeChange={handleBlogStartTimeChange}
                            textMaxLength={textMaxLength}
                            blogContentErrFlag={blogContentErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            blogContent={blogContent}
                            submitButtonFlag={submitButtonFlag}
                            buttonFormAction={buttonFormAction}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            cloneListFlag={cloneListFlag}
                            closeConvertForm={closeConvertForm}
                            priceListRecords={priceListRecords}
                        />
                    }

                    {isMobileView && 
                        <ManagePriceListMv
                            managePriceListLabel={managePriceListLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            domainLabel={domainLabel}
                            domainPrompt={domainPrompt}
                            domainErrMsg={domainErrMsg}
                                
                            priceDetailsLabel={priceDetailsLabel}
                            priceDetailsPrompt={priceDetailsPrompt}
                            priceDetailsErrMsg={priceDetailsErrMsg}
                            // priceDetailsMsg={priceDetailsMsg}
                            
                            priceItemLabel={priceItemLabel}
                            priceItemPrompt={priceItemPrompt}
                            priceItemErrMsg={priceItemErrMsg}
                            // selectTitlePrompt={selectTitlePrompt}

                            priceCategoryLabel={priceCategoryLabel}
                            priceCategoryPrompt={priceCategoryPrompt}
                            priceCategoryErrMsg={priceCategoryErrMsg}
                            gridCategory={gridCategory}
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            // cancelLabel={cancelLabel}

                            yesLabel={yesLabel}
                            deleteRequested={deleteRequested}
                            areYouSureMsg={areYouSureMsg}
                            requestedToDeleteMsg={requestedToDeleteMsg}
                            uploadLabel={uploadLabel}
                            createPriceListLabel={createPriceListLabel}
                            updatePriceListLabel={updatePriceListLabel}
                            // priceRecordLabel={priceRecordLabel}
                            // createMoreLabel={createMoreLabel}
                            createLabel={createLabel}
                            convertPriceListLabel={convertPriceListLabel}
                            closeLabel={closeLabel}
                            noPriceListRecordsFound={noPriceListRecordsFound} 
                    
                            gridPriceItem={gridPriceItem}
                            gridItemDetails={gridItemDetails}
                            // gridEventUrl={gridEventUrl}
                            lastUpdateLabel={lastUpdateLabel}
                            // gridStartTime={gridStartTime}
                            // gridBannerUrl={gridBannerUrl}
                        
                            convertLabel={convertLabel}
                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            moreLabel={moreLabel}

                            organizeLabel={organizeLabel}
                            organizePriceListLabel={organizePriceListLabel}
                            availablePriceItemsLabel={availablePriceItemsLabel}
                            saveLabel={saveLabel}
                            orderListAction={orderListAction}
                            orderListFlag={orderListFlag}
                            handleOrderListCategoryChange={handleOrderListCategoryChange}

                            filteredRecords={filteredRecords}
                            dragdropFlag={dragdropFlag}
                            orderListTemplate={orderListTemplate}
                            closeOrderListForm={closeOrderListForm}
                            handleFilteredRecords={handleFilteredRecords}
                            organizeService={organizeService}
                            
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showListFlag={showListFlag}
                            priceListRecords={priceListRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noRecordFoundFlag={noRecordFoundFlag}
                            createPriceAction={createPriceAction}
                            showDetailsFormFlag={showDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleListLanguageChange={handleListLanguageChange}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}
                            domain={domain}
                            domainOptions={domainOptions}
                            handleListDomainChange={handleListDomainChange}
                            handleDomainChange={handleDomainChange}
                            priceCategoryErrFlag={priceCategoryErrFlag}
                            priceCategory={priceCategory}
                            categoryOptions={categoryOptions}
                            handlePriceCategoryChange={handlePriceCategoryChange}
                            domainErrFlag={domainErrFlag}
                            handleChange={handleChange}
                            // selectImageAction={selectImageAction}
                            priceDetailsErrFlag={priceDetailsErrFlag}
                            priceDetails={priceDetails}
                            priceItemErrFlag={priceItemErrFlag}
                            priceItem={priceItem}
                            blogStartTimeErrFlag={blogStartTimeErrFlag}
                            blogStartTime={blogStartTime}
                            // handleBlogStartTimeChange={handleBlogStartTimeChange}
                            textMaxLength={textMaxLength}
                            blogContentErrFlag={blogContentErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            blogContent={blogContent}
                            submitButtonFlag={submitButtonFlag}
                            buttonFormAction={buttonFormAction}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            cloneListFlag={cloneListFlag}
                            closeConvertForm={closeConvertForm}
                            priceListRecords={priceListRecords}
                        />
                    }
                </>
            }

            <Dialog 
                id="modalDlgId"
                rtl={appContext.dirction === 'rtl'}
                showHeader={true}
                header={dialogHeader}
                visible={dlgVisibleFlag} 
                maximizable={true}
                maximized={(isTabletView || isMobileView) ? true : false}
                modal={true} 
                onHide={hideModalDlg}
                >
                <Container component="div" className={classes.modalDlgMain} maxWidth="md">
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="flex-start" style={{color: '#000'}}>
                            <FroalaEditorView model={dialogData}/>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>

            {alertOpen && 
                <AlertDialog
                    alertOpen={alertOpen}
                    alertClose={alertClose}
                    alertClickAgree={alertClickAgree}
                    alertClickDisagree={alertClickDisagree}

                    dialogTitle={deleteRequested}
                    dialogMainContent={requestedToDeleteMsg}
                    dialogSubContent={dialogSubContent}
                    dialogQuestion={areYouSureMsg}
                    yesLabel={yesLabel}
                    noLabel={cancelLabel}
                />
            }

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={8000}
                onClose={snackbarOnClose}
                ContentProps={{ "aria-describedby": "message-id" }}
                message={<span id="message-id">{snackbarsMsg}</span>}
                variant={snackbarsVariant}
            />

            <footer className={classes.footer}>
                <div>
                    <PageFooter isSignedIn={appContext.signedIn} />
                </div>
            </footer>
        </div>
    )
}

export default ManagePriceList;
