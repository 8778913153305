import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
// import Button from '@material-ui/core/Button';
import CssBaseline from "@material-ui/core/CssBaseline";
import { NavLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../../config/AmplifyConfig";

// Cache Utilities
import SetCacheItem from "../../../utils/cache/SetCacheItem";
import GetCacheItem from "../../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../../utils/GenerateModulusHash";

import AppMainMenu from "../../headers/AppMainMenu";
import PageFooter from "../../footers/PageFooter";

// AppContext
import AppContext from "../../../config/AppContext";

Amplify.configure(AmplifyConfig);

const PageContainer = styled.div`
  width: 100%,
  display: 'flex',
  flexWrap: 'wrap',
  background: #fff;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    // backgroundColor: '#fff',
    // backgroundImage: `url(${'assets/logos/background21.png'})`,
  },
  progress: {
    margin: theme.spacing(1),
  },
  pageContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mainGrid: {
    textAlign: "center",
    display: "flex",
  },
  title: {
    color: "#000",
    textAlign: "center",
  },
}));

let pageLabels = {
  product1Title: "Product1 Title",
};

function Product1(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  // const [prevLanguage, setPrevLanguage] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  // Page Column Format
  // 'leftBodyRight' = render left, body and right of page
  // 'bodyOnly' = render only body of page
  // 'leftAndBody' = render left column and body of page
  // 'bodyAndRight' = render body and right column of page
  // const [pageColumnFormat, setPageColumnFormat] = useState('leftBodyRight');
  // const [containerMaxWidth, setContainerMaxWidth] = useState('lg');// xl, lg, md, sm, xs

  // For Products
  const [pageLabelsRes, setProduct1Res] = useState(pageLabels);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  const [renderGridFlag, setRenderGridFlag] = useState(false);
  const [renderDomFlag, setRenderDomFlag] = useState(false);

  const [pageContentData, setPageContentData] = useState({});

  // const { currLanguage } = props;

  const classes = useStyles();

  const { product1Title } = pageLabelsRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(pageLabels);
    // console.log("In Product1(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In Product1(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "product1Title")
              pageLabels.product1Title = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, pageLabels);
        }
        setProduct1Res(pageLabels);

        setRenderGridFlag(true);
        setRenderDomFlag(true);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.error(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProduct1Res(pageLabels);
        setRenderGridFlag(true);
        setRenderDomFlag(true);
        setProgressFlag(false);
      });
  }

  async function fetchPageContent() {
    const pageName = "policy";
    // console.log('In fetchPageContent(): appContext.domain is: ', appContext.domain);
    // console.log('In fetchPageContent(): pageName is: ', pageName);
    // console.log('In fetchPageContent(): currentLanguage.languageCode is: ', currentLanguage.languageCode);

    setProgressFlag(true);

    // let arrayData = [];

    let params = {
      apiName: "PagesAPI",
      path: "/dynamodb/pages/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#pagePK = :pagePK AND begins_with(#pageSK, :pageSK)",
        expressionAttributeNames: { "#pagePK": "PagePK", "#pageSK": "PageSK" },
        expressionAttributeValues: {
          ":pagePK": appContext.domain + "#" + pageName,
          ":pageSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchPageContent(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchPageContent(): response length is: ', response.data.Items.length);
        // console.log('In fetchPageContent(): response is: ', JSON.stringify(response.data.Items, null, 2));
        // let langCode = currentLanguage.languageCode;
        // switch(currentLanguage.languageCode) {
        //   case 'zh':
        //     langCode = "zh-cn";
        //     break;
        //   default:
        //     langCode = currentLanguage.languageCode;
        //     break;
        // };
        // response.data.Items.map((item, index) => {
        // //   let startDate = new moment(item.StartDate).locale(langCode);
        // //   let strStartDate = startDate.format('L');

        // //   let endDate = new moment(item.EndDate).locale(langCode);
        // //   let strEndDate = endDate.format('L');

        //     let record = {
        //     id: ""+index,
        //     PagePK: item.PagePK,
        //     PageSK: item.PageSK,

        //     LanguageCode: item.LanguageCode,

        //     StartDate: item.StartDate,
        //     EndDate: item.EndDate,

        //     // StartDate: strStartDate,
        //     // EndDate: strEndDate,

        //     PageHeader: item.PageHeader,
        //     PageSubHeader: item.PageSubHeader,
        //     PageContent: item.PageContent,

        //     ModifiedBy: item.ModifiedBy,
        //     CreatedAt: item.CreatedAt,
        //     UpdatedAt: item.UpdatedAt,
        //     };

        //     arrayData.push(record);
        // });

        if (response.data.Items && response.data.Items.length > 0) {
          setPageContentData(response.data.Items[0]);
        }

        setProgressFlag(false);
      })
      .catch((err) => {
        console.error(
          "In fetchPageContent(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In Product1(): useEffect()");
    fetchPageContent();
  }, []);

  let cacheItemKey = null;

  useLayoutEffect(() => {
    // console.log("In Product1(): useLayoutEffect()");
    // console.log("In Product1(): useLayoutEffect()");

    // Fetch resource from catche
    cacheItemKey = "product1-footer";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In Product1(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In Product1(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In Product1(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In Product1(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In Product1(): useLayoutEffect(): got resource from cache');
      setProduct1Res(cacheItem);
      setRenderGridFlag(true);
      setRenderDomFlag(true);
    }
  }, []);

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { !appContext.signedIn &&
            <Redirect to='/signIn' />
          } */}

        <CssBaseline />

        {renderDomFlag && (
          <PageContainer className={classes.root}>
            <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />

            <Container
              component="div"
              className={classes.pageContainer}
              maxWidth="lg"
            >
              {/* { progressFlag &&
                  <div style={{textAlign: 'center'}}>
                    <CircularProgress className={classes.progress} color="secondary" size={15}/>
                  </div>
              } */}

              <Grid container className={classes.mainGrid} justify="center">
                <Grid item xs={12} sm={8} style={{ background: "yellow" }}>
                  <Grid container justify="center">
                    {
                      pageContentData !== null &&
                        pageContentData.PageHeader !== null && (
                          <Typography
                            variant="button"
                            color="secondary"
                            gutterBottom
                          >
                            {product1Title}
                          </Typography>
                        )

                      // <FroalaEditorView
                      //  model={pageContentData.PageHeader}
                      // />
                    }

                    {pageContentData !== null &&
                      pageContentData.PageSubHeader !== null && (
                        <FroalaEditorView
                          model={pageContentData.PageSubHeader}
                        />
                      )}

                    {pageContentData !== null &&
                      pageContentData.PageContent !== null && (
                        <FroalaEditorView model={pageContentData.PageContent} />
                      )}
                  </Grid>
                </Grid>

                {/* "flex-start", "center", "flex-end" */}
                <Grid item xs={12} sm={4} style={{ background: "green" }}>
                  <Grid container justify="center">
                    {
                      pageContentData !== null &&
                        pageContentData.PageHeader !== null && (
                          <Typography
                            variant="button"
                            color="secondary"
                            gutterBottom
                          >
                            {"Right Column Header"}
                          </Typography>
                        )
                      // <FroalaEditorView
                      //     model={pageContentData.PageHeader}
                      // />
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Container>

            <footer className={classes.footer}>
              <PageFooter />
            </footer>
          </PageContainer>
        )}
      </div>
    </div>
  );
}

export default Product1;
