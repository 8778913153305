import React from "react";

function DateCellRenderer(props) {
  const { value } = props;

  // console.log("In DateCellRenderer(): value is: ", value);
  // console.log("In DateCellRenderer(): timeZone is: ", Intl.DateTimeFormat().resolvedOptions().timeZone);

  // let options = {
  //   weekday: 'short', // long, short
  //   year: 'numeric',
  //   month: 'short', // long, short
  //   day: 'numeric',
  //   timeZone: 'GMT',
  //   timeZoneName: 'short', // long, short

  //   hour12: true,

  //   hour: 'numeric',
  //   minute: 'numeric',
  //   second: 'numeric',
  // };

  // let locale = "en-US";

  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {
    weekday,
    year,
    month,
    day,
    // timeZone,
    timeZoneName,

    hour12,

    hour,
    minute,
    second,
    locale,
  } = props.agGridReact.gridOptions;

  let options = {
    weekday: weekday, // long, short
    year: year,
    month: month, // long, short
    day: day,
    timeZone: timeZone,
    timeZoneName: timeZoneName, // long, short

    hour12: hour12,

    hour: hour,
    minute: minute,
    second: second,
  };

  try {
    const date = new Date(value);
    return new Intl.DateTimeFormat(locale, options).format(date);
  } catch (err) {
    console.log(
      "In DateCellRenderer(): err is: ",
      JSON.stringify(err, null, 2)
    );
  }
  return new Intl.DateTimeFormat(locale, options).format(new Date());
}

export default DateCellRenderer;
