import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

// AppContext
import AppContext from "../../../config/AppContext";

// ReducerContext
import ReducerContext from "../../../config/ReducerContext";

const TestimonialsTemplate02 = (props) => {
  const appContext = useContext(AppContext);
  const reducerContext = useContext(ReducerContext);
  const [direction, setDirection] = useState(appContext.direction);

  const {
    currentLanguageCode,
    designBlockRecord,
    // controlHeader,
    // controlSubHeader,
    // controlDesc,

    // controlLinkUrl,
    // controlLinkText,

    // controlMediaUrl,
    // controlImageText,

    // Array of details: controlHeader, controlSubHeader, controlDesc, controlLinkUrl, controlLinkText, controlMediaUrl, controlImageText,
    // designBlocksDetails,
  } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      textAlign: "center",
    },
    paper: {
      // height: currentLanguageCode === 'zh' ? 350 : 500,
      textAlign: "center",
    },
    mainForm: {
      textAlign: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    rounded: {
      color: "#fff",
      // backgroundColor: green[500],
    },
    large: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  }));

  const classes = useStyles();

  return (
    <div dir={direction}>
      <div className={classes.root}>
        <Container component="div" maxWidth="sm">
          <Grid container item xs={12}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              {designBlockRecord.ControlHeader && (
                <Typography variant="h2" align="center">
                  {designBlockRecord.ControlHeader}
                </Typography>
              )}
            </Grid>

            {/* <Grid item xs={12} className={classes.mainForm} style={{textAlign: 'center'}}>
                            {designBlockRecord.ControlDesc &&
                            <Typography variant="h6" align="center">
                                <FroalaEditorView model={designBlockRecord.ControlDesc} />
                            </Typography>
                            }
                        </Grid>

                        <Grid item xs={12}>
                        </Grid> */}
          </Grid>
        </Container>

        <Grid container item xs={12} sm={12} spacing={4}>
          {designBlockRecord &&
            designBlockRecord.DesignBlocksDetails &&
            designBlockRecord.DesignBlocksDetails.map((item) => (
              <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                <div
                  className={classes.paper}
                  style={{ height: currentLanguageCode === "zh" ? 350 : 500 }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    style={{
                      textAlign: "center",
                      padding: "30px 0px 30px 0px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      className={classes.verticalAlignLabel}
                      style={{ padding: "30px" }}
                    >
                      {item.DetailsDesc && (
                        <Typography
                          variant="h6"
                          align="center"
                          gutterBottom={true}
                          className={classes.verticalAlignLabel}
                        >
                          <FroalaEditorView model={item.DetailsDesc} />
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      style={{ textAlign: "center" }}
                    >
                      <Grid item xs={6}>
                        {item.DetailsImageURL && (
                          <Avatar
                            variant="rounded"
                            style={{ float: "right", marginRight: "20px" }}
                            className={clsx(classes.rounded, classes.large)}
                          >
                            <img
                              alt={
                                item.DetailsImageText
                                  ? item.DetailsImageText
                                  : ""
                              }
                              src={item.DetailsImageURL}
                              style={{ textAlign: "center", width: "96px" }}
                            />
                          </Avatar>
                        )}
                      </Grid>

                      <Grid item xs={6}>
                        {item.DetailsHeader && (
                          <Typography
                            variant="h6"
                            align="left"
                            className={classes.verticalAlignLabel}
                          >
                            {item.DetailsHeader}
                          </Typography>
                        )}

                        {item.DetailsSubHeader && (
                          <Typography
                            variant="caption"
                            align="left"
                            className={classes.verticalAlignLabel}
                          >
                            {item.DetailsSubHeader}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            ))}
          ;
        </Grid>
      </div>
    </div>
  );
};

export default TestimonialsTemplate02;
