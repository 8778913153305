import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

// AppContext
import AppContext from "../../config/AppContext";

Amplify.configure(AmplifyConfig);

const PageContainer = styled.div`
  width: 100%,
  display: 'flex',
  flexWrap: 'wrap',
  background: #fff;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
  },
  progress: {
    margin: theme.spacing(1),
  },
  mainGrid: {
    textAlign: "left",
  },
  company: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  hdr: {
    color: "#fff",
  },
  prodDesc: {
    color: "fff",
    fontSize: "12px",
    marginLeft: theme.spacing(1),
    whiteSpace: "nowrap",
  },
  link: {
    color: "#fff",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  textItem: {
    color: "#fff",
    whiteSpace: "nowrap",
  },
}));

let company = {
  companyHdr: "Company",
  companyDesc: "Our company information",
  company1: "Company1",
  company2: "Company2",
  company3: "Company3",
  company4: "Company4",
  company5: "Company5",
  company6: "Company6",
  company7: "Company7",
  company8: "Company8",
  company9: "Company9",
  company10: "Company10",
};

function CompanyFooter(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  // For Company
  const [companyRes, setCompanyRes] = useState(company);

  const { currLanguage } = props;

  const classes = useStyles();

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  const {
    companyHdr,
    companyDesc,
    company1,
    company2,
    company3,
    company4,
    company5,
    company6,
    company7,
    company8,
    company9,
    company10,
  } = companyRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(company);
    // console.log("In CompanyFooter(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In CompanyFooter(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "companyHdr")
              company.companyHdr = item.ResourceDesc;
            if (item.ResourceCode === "companyDesc")
              company.companyDesc = item.ResourceDesc;
            if (item.ResourceCode === "company1")
              company.company1 = item.ResourceDesc;
            if (item.ResourceCode === "company2")
              company.company2 = item.ResourceDesc;
            if (item.ResourceCode === "company3")
              company.company3 = item.ResourceDesc;
            if (item.ResourceCode === "company4")
              company.company4 = item.ResourceDesc;
            if (item.ResourceCode === "company5")
              company.company5 = item.ResourceDesc;
            if (item.ResourceCode === "company6")
              company.company6 = item.ResourceDesc;
            if (item.ResourceCode === "company7")
              company.company7 = item.ResourceDesc;
            if (item.ResourceCode === "company8")
              company.company8 = item.ResourceDesc;
            if (item.ResourceCode === "company9")
              company.company9 = item.ResourceDesc;
            if (item.ResourceCode === "company10")
              company.company10 = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, company);
          setCompanyRes(company);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setCompanyRes(company);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In CompanyFooter(): useEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));

    // currLanguage is the most current language passed to this component through props
    // We are using useEffect to get the functionality of componentDidUpdate()
    if (
      currLanguage !== undefined &&
      currLanguage !== null &&
      currLanguage !== currentLanguage
    ) {
      setCurrentLanguage(currLanguage);
      appContext.currentLanguage = currLanguage;

      // console.log('In CompanyFooter(): useEffect(): about to call fetchDbResources()');

      // Fetch resource from catche
      let cacheItemKey = "company";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      // cacheItemKey += currLanguage.languageCode;
      // console.log('In CompanyFooter(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        // console.log("In CompanyFooter(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
        RemoveCacheItem(cacheItemKey);
      }

      let cacheItem = GetCacheItem(cacheItemKey);
      // console.log('In CompanyFooter(): useLayoutEffect(): cacheItem is: ', cacheItem);

      if (cacheItem === null) {
        // resource is not in cache, fetch from DB
        // console.log('In CompanyFooter(): useLayoutEffect(): resource is not in cache, fetch from DB');
        fetchDbResources(cacheItemKey);
      } else {
        // console.log('In CompanyFooter(): useLayoutEffect(): got resource from cache');
        setCompanyRes(cacheItem);
      }
    }
  });

  useLayoutEffect(() => {
    // console.log("In CompanyFooter(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "company";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In CompanyFooter(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In CompanyFooter(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In CompanyFooter(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In CompanyFooter(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In CompanyFooter(): useLayoutEffect(): got resource from cache');
      setCompanyRes(cacheItem);
    }
  }, []);

  return (
    <div dir={direction}>
      <PageContainer className={classes.root}>
        <CssBaseline />

        <Container component="div" className={classes.company} maxWidth="lg">
          {progressFlag && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress
                className={classes.progress}
                color="secondary"
                size={15}
              />
            </div>
          )}
          <Grid container className={classes.mainGrid}>
            <Grid item xs={12} className={classes.hdr}>
              <Typography variant="button" gutterBottom>
                {companyHdr}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.prodDesc}>
              <Typography variant="caption" gutterBottom>
                {companyDesc}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/company1">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {company1}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/company2">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {company2}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/company3">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {company3}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/company4">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {company4}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/company5">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {company5}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/company6">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {company6}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/company7">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {company7}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/company8">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {company8}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/company9">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {company9}
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12} className={classes.link}>
              <Link component={RouterLink} to="/company10">
                <Typography variant="subtitle2" className={classes.textItem}>
                  {company10}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </PageContainer>
    </div>
  );
}

export default CompanyFooter;
