import React from "react";

const ReducerState = {
  userName: "",
  signedIn: false,
  jwtToken: "",
  direction: "ltr",
  mandatorySignIn: true,
  currentLanguage: {
    languageCode: "en", // Change this when user selects a new language
    languageName: "English", // Change this when user selects a new language
  },
  userLocale: {
    // Populate this from user record when user signs in (can't be changed)
    languageCode: "en",
    languageName: "English",
  },
  phoneNumber: {
    defaultPhoneCountry: "us", // For phone flag
    phoneLocale: "en-US", // For phone validation
    preferredCountries: ["mx", "us"], //Show these countries at top of dropdown
    localization: {
      Germany: "Deutschland",
      Spain: "España",
    },
  },
  userPreferences: {},
  // Array of language codes whose cache is to be refreshed by removing them in cache if in cache already
  // Clear the cache for language whenever the resources for language changes (added, deleted, changed)
  // Clear existing cache then refresh
  clearCacheLanguageCodes: [
    "en",
    "zh",
    // "ar",
  ],
};

export default ReducerState;
