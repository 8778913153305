import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import SaveIcon from "@material-ui/icons/Save";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import InputAdornment from '@material-ui/core/InputAdornment';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

// Moment
import moment from "moment";
// import Moment from 'react-moment';
// import 'moment-timezone';

// react-datetime-picker, react-time-picker and react-date-picker
// import DateTimePicker from 'react-datetime-picker';
// import TimePicker from 'react-time-picker'

// Amplify
import Amplify, { API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// ReducerContext
import ReducerContext from "../../config/ReducerContext";

import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import LineSpacing from "../../components/misc/LineSpacing";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    backgroundImage: `url(${"assets/logos/background20.png"})`,
  },
  gridWidth: {
    width: "80%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  mainForm: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  textField: {
    width: "100%",
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  verticalAlignLabel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  textArea: {
    padding: theme.spacing(1),
    borderRadius: "5px",
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
    textAlign: "center !important",
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  // selectEmpty: {
  //   marginTop: theme.spacing(2),
  // },
  browseIcon: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  phone: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "row",
  },
  normalVariant: {
    // color: '#000',
    textAlign: "center",
  },
  errVariant: {
    color: "red",
    textAlign: "center",
  },
  gridItem: {
    textAlign: "left",
    marginTop: "2px",
  },
}));

let officialHrs = {
  businessHrsLabel: "Business hours",
  allFieldsAreRequired: "All fields are required",

  languageNameLabel: "Language",
  selectLanguage: "Please select a language",

  locationLabel: "Location",
  selectLocation: "Please select a location",

  monLabel: "Monday",
  tueLabel: "Tuesday",
  wedLabel: "Wednesday",
  thuLabel: "Thursday",
  friLabel: "Friday",
  satLabel: "Saturday",
  sunLabel: "Sunday",
  startTime: "Start time",
  endTime: "End time",

  additionalInfoLabel: "Additional information",
  additionalInfoPrompt: "Please enter any other additional information",
  additionalInfoErrMsg: "Please enter correct information",

  submit: "Submit",
  businessHrsSuccessMsg: "Business hours update was successful.",
  businessHrsFailureMsg: "Business hours update failed.",
  businessHrsErrMsg: "One or more business hours entered is invalid.",
};

function BusinessHours(props) {
  const appContext = useContext(AppContext);
  const reducerContext = useContext(ReducerContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [formLanguage, setFormLanguage] = useState(null); // defaultLang.value
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showLeadingZeros, setShowLeadingZeros] = useState(true);

  const [currentPK, setCurrentPK] = useState("");
  const [currentSK, setCurrentSK] = useState("");

  const [monStartDate, setMonStartDate] = useState(
    defaultLang.value === "en" ? "8:00 AM" : ""
  );
  const [tueStartDate, setTueStartDate] = useState(
    defaultLang.value === "en" ? "8:00 AM" : ""
  );
  const [wedStartDate, setWedStartDate] = useState(
    defaultLang.value === "en" ? "8:00 AM" : ""
  );
  const [thuStartDate, setThuStartDate] = useState(
    defaultLang.value === "en" ? "8:00 AM" : ""
  );
  const [friStartDate, setFriStartDate] = useState(
    defaultLang.value === "en" ? "8:00 AM" : ""
  );
  const [satStartDate, setSatStartDate] = useState("");
  const [sunStartDate, setSunStartDate] = useState("");

  const [monEndDate, setMonEndDate] = useState(
    defaultLang.value === "en" ? "5:00 PM" : ""
  );
  const [tueEndDate, setTueEndDate] = useState(
    defaultLang.value === "en" ? "5:00 PM" : ""
  );
  const [wedEndDate, setWedEndDate] = useState(
    defaultLang.value === "en" ? "5:00 PM" : ""
  );
  const [thuEndDate, setThuEndDate] = useState(
    defaultLang.value === "en" ? "5:00 PM" : ""
  );
  const [friEndDate, setFriEndDate] = useState(
    defaultLang.value === "en" ? "5:00 PM" : ""
  );
  const [satEndDate, setSatEndDate] = useState("");
  const [sunEndDate, setSunEndDate] = useState("");

  const [additionalInfo, setAdditionalInfo] = useState("");

  // const [showPassword, setShowPassword] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  const [langSelectOptions, setLangSelectOptions] = useState([]);
  const [locSelectOptions, setLocSelectOptions] = useState([]);
  const [contactInfoData, setContactInfoData] = useState([]);

  const [selectLangChangedFlag, setSelectLangChangedFlag] = useState(true);
  const [selectLocChangedFlag, setSelectLocChangedFlag] = useState(false);

  const [progressFlag, setProgressFlag] = useState(false);
  const [gridUpdatedFlag, setGridUpdatedFlag] = useState(false);
  const [submitFlag, setSubmitFlag] = useState(false);

  const [filterDisabledFlag, setFilterDisabledFlag] = useState(true);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  const [modifiedGridRows, setModifiedGridRows] = useState([]);
  const [deletedGridRows, setDeletedGridRows] = useState([]);

  // For Error Flags
  const [formLanguageErrFlag, setFormLanguageErrFlag] = useState(false);
  const [locationErrFlag, setLocationErrFlag] = useState(false);
  const [monHrsErrFlag, setMonHrsErrFlag] = useState(false);
  const [tueHrsErrFlag, setTueHrsErrFlag] = useState(false);
  const [wedHrsErrFlag, setWedHrsErrFlag] = useState(false);
  const [thuHrsErrFlag, setThuHrsErrFlag] = useState(false);
  const [friHrsErrFlag, setFriHrsErrFlag] = useState(false);
  const [satHrsErrFlag, setSatHrsErrFlag] = useState(false);
  const [sunHrsErrFlag, setSunHrsErrFlag] = useState(false);

  const [additionalInfoErrFlag, setAdditionalInfoErrFlag] = useState(false);

  const [contactCodeFocus, setContactCodeFocus] = useState(false);
  const [contactDescFocus, setContactDescFocus] = useState(true);

  // const [redirectSignUpFlag, setRedirectSignUpFlag] = useState(false);
  // const [globalErrorMsg, setGlobalErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  // For Resources
  const [officialHrsRes, setOfficialHrsRes] = useState(officialHrs);
  // const [prevLangCode, setPrevLangCode] = useState(appContext.currentLanguage.languageCode);

  const [renderContentFlag, setRenderContentFlag] = useState(false);

  //console.log('AmplifyConfig is: ', JSON.stringify(AmplifyConfig, null, 2));
  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  const marginAlignment = {
    flex: "1",
    marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
  };

  const {
    businessHrsLabel,
    languageNameLabel,
    selectLanguage,

    locationLabel,
    selectLocation,

    monLabel,
    tueLabel,
    wedLabel,
    thuLabel,
    friLabel,
    satLabel,
    sunLabel,
    startTime,
    endTime,

    additionalInfoLabel,
    additionalInfoPrompt,
    additionalInfoErrMsg,

    submit,
    businessHrsSuccessMsg,
    businessHrsFailureMsg,
    businessHrsErrMsg,
    allFieldsAreRequired,
  } = officialHrsRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(officialHrs);
    // console.log("In ManageSocNetworkserenceData(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ManageSocNetworkserenceData(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "businessHrsLabel")
              officialHrs.businessHrsLabel = item.ResourceDesc;
            if (item.ResourceCode === "languageNameLabel")
              officialHrs.languageNameLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectLanguage")
              officialHrs.selectLanguage = item.ResourceDesc;

            if (item.ResourceCode === "locationLabel")
              officialHrs.locationLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectLocation")
              officialHrs.selectLocation = item.ResourceDesc;

            if (item.ResourceCode === "monLabel")
              officialHrs.monLabel = item.ResourceDesc;
            if (item.ResourceCode === "tueLabel")
              officialHrs.tueLabel = item.ResourceDesc;
            if (item.ResourceCode === "wedLabel")
              officialHrs.wedLabel = item.ResourceDesc;
            if (item.ResourceCode === "thuLabel")
              officialHrs.thuLabel = item.ResourceDesc;
            if (item.ResourceCode === "friLabel")
              officialHrs.friLabel = item.ResourceDesc;
            if (item.ResourceCode === "satLabel")
              officialHrs.satLabel = item.ResourceDesc;
            if (item.ResourceCode === "sunLabel")
              officialHrs.sunLabel = item.ResourceDesc;
            if (item.ResourceCode === "startTime")
              officialHrs.startTime = item.ResourceDesc;
            if (item.ResourceCode === "endTime")
              officialHrs.endTime = item.ResourceDesc;

            if (item.ResourceCode === "additionalInfoLabel")
              officialHrs.additionalInfoLabel = item.ResourceDesc;
            if (item.ResourceCode === "additionalInfoPrompt")
              officialHrs.additionalInfoPrompt = item.ResourceDesc;
            if (item.ResourceCode === "additionalInfoErrMsg")
              officialHrs.additionalInfoErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "businessHrsSuccessMsg")
              officialHrs.businessHrsSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "businessHrsFailureMsg")
              officialHrs.businessHrsFailureMsg = item.ResourceDesc;
            if (item.ResourceCode === "businessHrsErrMsg")
              officialHrs.businessHrsErrMsg = item.ResourceDesc;

            if (item.ResourceCode === "submit")
              officialHrs.submit = item.ResourceDesc;
            if (item.ResourceCode === "allFieldsAreRequired")
              officialHrs.allFieldsAreRequired = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, officialHrs);
        }

        setOfficialHrsRes(officialHrs);
        setRenderContentFlag(true);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setOfficialHrsRes(officialHrs);
        setProgressFlag(false);
      });
  }

  // Fetch Contact Info
  function fetchContactInfo(languageValue) {
    // console.log('In fetchContactInfo(): languageValue is: ', languageValue);

    setFilterDisabledFlag(true);
    setProgressFlag(true);

    let arrayData = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "contact-info",
          ":resourceSK": languageValue,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchContactInfo(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchContactInfo(): response length is: ', response.data.Items.length);
        // console.log('In fetchContactInfo(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item, index) => {
          let record = {
            value: item.ResourceCode,
            text: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        setLocSelectOptions(arrayData);
        setContactInfoData(response.data.Items);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchContactInfo(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  let selectOptions = [];

  function fetchLanguages() {
    // console.log('In fetchLanguages()');
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages(): response.length is: ', response.data.Items.length);
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            // label: item.LanguageName,
            text: item.LanguageName,
          };
          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In useEffect()");
    // console.log("In useEffect(): reducerContext.store.signedIn is: ", reducerContext.store.signedIn);
  }, []);

  useLayoutEffect(() => {
    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In BusinessHours(): useLayoutEffect(): currentLanguage is: ', currentLanguage);
    // console.log('In BusinessHours(): useLayoutEffect(): GenerateModulusHash("firstName", 200) is: ', GenerateModulusHash("firstName", 200));
    // console.log('In BusinessHours(): useLayoutEffect(): appContext.signedIn is: ', appContext.signedIn);

    // console.log("In useLayoutEffect(): reducerContext.store.signedIn is: ", reducerContext.store.signedIn);
    // console.log("In useLayoutEffect(): reducerContext is: ", reducerContext);
    reducerContext.dispatch({ type: "AUTH_BEGIN" });

    setContactCodeFocus(true);
    setContactDescFocus(false);

    if (selectOptions.length === 0) {
      fetchLanguages();
      fetchContactInfo(currentLanguage.languageCode);
    }

    // Fetch resource from catche
    let cacheItemKey = "officialHrs";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ManageSocNetworkserenceData(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ManageSocNetworkserenceData(): useLayoutEffect(): got resource from cache');
      setOfficialHrsRes(cacheItem);
    }
  }, []);

  function handleChange(event) {
    // console.log("In handleChange(): id is: "+event.target.id);
    // console.log("In handleChange(): value is: "+event.target.value);
    setGlobalErrorFlag(false);
    setGridUpdatedFlag(false);

    setAdditionalInfoErrFlag(false);
    setAdditionalInfo(event.target.value);
  }

  function validateForm() {
    let formErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setFormLanguageErrFlag(false);
    setLocationErrFlag(false);
    setMonHrsErrFlag(false);
    setTueHrsErrFlag(false);
    setWedHrsErrFlag(false);
    setThuHrsErrFlag(false);
    setFriHrsErrFlag(false);
    setSatHrsErrFlag(false);
    setSunHrsErrFlag(false);
    setAdditionalInfoErrFlag(false);

    moment.locale(currentLanguage.languageCode);

    if (formLanguage === null || formLanguage === "") {
      formErrFlag = true;
      setFormLanguageErrFlag(true);
    }

    if (selectedLocation === null || selectedLocation === "") {
      formErrFlag = true;
      setLocationErrFlag(true);
    }

    // Monday
    if (
      (monStartDate !== null &&
        monEndDate !== null &&
        monStartDate !== "" &&
        monEndDate !== "") ||
      (monStartDate === null && monEndDate !== null) ||
      (monStartDate !== null && monEndDate === null)
    ) {
      let timeStart = moment(monStartDate, "hh:mm A");
      let timeEnd = moment(monEndDate, "hh:mm A");
      console.log("In validateForm(): timeStart is: ", timeStart);
      console.log("In validateForm(): timeEnd is: ", timeEnd);
      if (isNaN(timeStart) || isNaN(timeEnd)) {
        // if (isNaN(timeStart) || isNaN(timeEnd) || moment(timeEnd).isBefore(timeStart)) {
        formErrFlag = true;
        setMonHrsErrFlag(true);
        setGlobalErrorMsg(businessHrsErrMsg);
      }
    }

    // Tuesday
    if (
      (tueStartDate !== null &&
        tueEndDate !== null &&
        tueStartDate !== "" &&
        tueEndDate !== "") ||
      (tueStartDate === null && tueEndDate !== null) ||
      (tueStartDate !== null && tueEndDate === null)
    ) {
      let timeStart = moment(tueStartDate, "hh:mm A");
      let timeEnd = moment(tueEndDate, "hh:mm A");
      if (isNaN(timeStart) || isNaN(timeEnd)) {
        formErrFlag = true;
        setTueHrsErrFlag(true);
        setGlobalErrorMsg(businessHrsErrMsg);
      }
    }

    // Wednesday
    if (
      (wedStartDate !== null &&
        wedEndDate !== null &&
        wedStartDate !== "" &&
        wedEndDate !== "") ||
      (wedStartDate === null && wedEndDate !== null) ||
      (wedStartDate !== null && wedEndDate === null)
    ) {
      let timeStart = moment(wedStartDate, "hh:mm A");
      let timeEnd = moment(wedEndDate, "hh:mm A");
      if (isNaN(timeStart) || isNaN(timeEnd)) {
        formErrFlag = true;
        setWedHrsErrFlag(true);
        setGlobalErrorMsg(businessHrsErrMsg);
      }
    }

    // Thursday
    if (
      (thuStartDate !== null &&
        thuEndDate !== null &&
        thuStartDate !== "" &&
        thuEndDate !== "") ||
      (thuStartDate === null && thuEndDate !== null) ||
      (thuStartDate !== null && thuEndDate === null)
    ) {
      let timeStart = moment(thuStartDate, "hh:mm A");
      let timeEnd = moment(thuEndDate, "hh:mm A");
      if (isNaN(timeStart) || isNaN(timeEnd)) {
        formErrFlag = true;
        setThuHrsErrFlag(true);
        setGlobalErrorMsg(businessHrsErrMsg);
      }
    }

    // Friday
    if (
      (friStartDate !== null &&
        friEndDate !== null &&
        friStartDate !== "" &&
        friEndDate !== "") ||
      (friStartDate === null && friEndDate !== null) ||
      (friStartDate !== null && friEndDate === null)
    ) {
      let timeStart = moment(friStartDate, "hh:mm A");
      let timeEnd = moment(friEndDate, "hh:mm A");
      if (isNaN(timeStart) || isNaN(timeEnd)) {
        formErrFlag = true;
        setFriHrsErrFlag(true);
        setGlobalErrorMsg(businessHrsErrMsg);
      }
    }

    // Saturday
    if (
      (satStartDate !== null &&
        satEndDate !== null &&
        satStartDate !== "" &&
        satEndDate !== "") ||
      (satStartDate === null && satEndDate !== null) ||
      (satStartDate !== null && satEndDate === null)
    ) {
      let timeStart = moment(satStartDate, "hh:mm A");
      let timeEnd = moment(satEndDate, "hh:mm A");
      if (isNaN(timeStart) || isNaN(timeEnd)) {
        formErrFlag = true;
        setSatHrsErrFlag(true);
        setGlobalErrorMsg(businessHrsErrMsg);
      }
    }

    // Sunday
    if (
      (sunStartDate !== null &&
        sunEndDate !== null &&
        sunStartDate !== "" &&
        sunEndDate !== "") ||
      (sunStartDate === null && sunEndDate !== null) ||
      (sunStartDate !== null && sunEndDate === null)
    ) {
      let timeStart = moment(sunStartDate, "hh:mm A");
      let timeEnd = moment(sunEndDate, "hh:mm A");
      if (isNaN(timeStart) || isNaN(timeEnd)) {
        formErrFlag = true;
        setSunHrsErrFlag(true);
        setGlobalErrorMsg(businessHrsErrMsg);
      }
    }

    if (formErrFlag) {
      setGlobalErrorFlag(true);
    }
    return formErrFlag;
  }

  function initializeDates() {
    setMonStartDate("8:00 AM");
    setMonEndDate("5:00 PM");

    setTueStartDate("8:00 AM");
    setTueEndDate("5:00 PM");

    setWedStartDate("8:00 AM");
    setWedEndDate("5:00 PM");

    setThuStartDate("8:00 AM");
    setThuEndDate("5:00 PM");

    setFriStartDate("8:00 AM");
    setFriEndDate("5:00 PM");

    setSatStartDate(null);
    setSatEndDate(null);

    setSunStartDate(null);
    setSunEndDate(null);
  }

  function initializeForm() {
    setAdditionalInfo("");
    setSelectedLocation(null);
    initializeDates();
    setSubmitFlag(false);
  }

  async function businessHoursService() {
    // console.log("In businessHoursService(): formLanguage is: ", formLanguage);
    // console.log("In businessHoursService(): selectedLocation is: ", selectedLocation);

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    if (!validateForm()) {
      const timestamp = new Date();

      let key = {
        ResourcePK: currentPK,
        ResourceSK: currentSK,
      };

      // ConditionExpression: "attribute_exists(resourceCode)",
      let conditionExpression = "attribute_exists(ResourceSK)";

      // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
      let updateExpression =
        "SET BusinessHours = :businessHours, Notes = :additionalInfo, ModifiedBy = :modifiedBy, UpdatedAt = :updatedAt";

      let businessHours = {
        monStartDate: monStartDate,
        monEndDate: monEndDate,

        tueStartDate: tueStartDate,
        tueEndDate: tueEndDate,

        wedStartDate: wedStartDate,
        wedEndDate: wedEndDate,

        thuStartDate: thuStartDate,
        thuEndDate: thuEndDate,

        friStartDate: friStartDate,
        friEndDate: friEndDate,

        satStartDate: satStartDate,
        satEndDate: satEndDate,

        sunStartDate: sunStartDate,
        sunEndDate: sunEndDate,
      };

      let expressionAttributeValues = {
        ":businessHours": businessHours,
        ":additionalInfo": additionalInfo,
        ":modifiedBy": appContext.userName,
        ":updatedAt": timestamp,
      };

      let params = {
        apiName: "ResourceAPI",
        path: "/dynamodb/resources/update",
        payload: {
          key: key,
          conditionExpression: conditionExpression,
          updateExpression: updateExpression,
          expressionAttributeValues: expressionAttributeValues,
        },
      };
      console.log(
        "In businessHoursService(): params.payload is: ",
        JSON.stringify(params.payload, null, 2)
      );

      let initObj = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        response: true, // return entire response object instead of response.data
        body: {},
      };

      initObj.headers.Authorization = appContext.jwtToken;
      initObj.body = params.payload;
      // console.log('In businessHoursService(): initObj is: ', JSON.stringify(initObj, null, 2));

      setProgressFlag(true);

      API.put(params.apiName, params.path, initObj)
        .then((response) => {
          // console.log('In businessHoursService(): response is: ', JSON.stringify(response, null, 2));

          // Clear Arrays
          setDeletedGridRows([]);
          setModifiedGridRows([]);

          // Show Snackbar
          setSnackbarsVariant("success");
          setSnackbarsMsg(businessHrsSuccessMsg);
          setSnackbarOpen(true);

          setProgressFlag(false);

          initializeForm();

          // Refresh Contact Info dropdown
          // console.log("In businessHoursService(): formLanguage.value is: ", formLanguage.value);
          fetchContactInfo(formLanguage.value);
        })
        .catch((err) => {
          console.log(
            "In businessHoursService(): err is: ",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setGlobalErrorMsg(businessHrsFailureMsg);

          setProgressFlag(false);
        });
    }
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    setSnackbarOpen(false);
  }

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);
    setFormLanguage(selectedOption);

    setSelectLangChangedFlag(true);

    initializeForm();

    fetchContactInfo(selectedOption);

    if (selectLocChangedFlag) {
      setSubmitFlag(true);
    }
  }

  function handleLocationChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLocationChange(): selectedOption is', selectedOption);
    // console.log("In handleLocationChange(): reducerContext.store.signedIn is: ", reducerContext.store.signedIn);
    setSelectedLocation(selectedOption);

    setSelectLocChangedFlag(true);

    let filteredRec = null;

    initializeDates();

    if (contactInfoData !== null && contactInfoData.length > 0) {
      filteredRec = contactInfoData.filter(
        (item) => item.ResourceCode === selectedOption
      );
    }
    // console.log('In handleLocationChange(): filteredRec is', filteredRec);
    if (filteredRec !== null) {
      // console.log('In handleLocationChange(): filteredRec.ResourcePK is', filteredRec[0].ResourcePK);
      // console.log('In handleLocationChange(): filteredRec.ResourceSK is', filteredRec[0].ResourceSK);
      setCurrentPK(filteredRec[0].ResourcePK);
      setCurrentSK(filteredRec[0].ResourceSK);
      setAdditionalInfo(filteredRec[0].Notes);
    }

    if (filteredRec !== null && filteredRec[0].BusinessHours !== null) {
      let bh = filteredRec[0].BusinessHours;
      // console.log('In handleLocationChange(): bh is', bh);
      if (
        bh &&
        bh !== null &&
        bh.monStartDate !== null &&
        bh.monStartDate !== ""
      ) {
        setMonStartDate(bh.monStartDate);
        setMonEndDate(bh.monEndDate);
      }

      if (
        bh &&
        bh !== null &&
        bh.tueStartDate !== null &&
        bh.tueStartDate !== ""
      ) {
        setTueStartDate(bh.tueStartDate);
        setTueEndDate(bh.tueEndDate);
      }

      if (
        bh &&
        bh !== null &&
        bh.wedStartDate !== null &&
        bh.wedStartDate !== ""
      ) {
        setWedStartDate(bh.wedStartDate);
        setWedEndDate(bh.wedEndDate);
      }

      if (
        bh &&
        bh !== null &&
        bh.thuStartDate !== null &&
        bh.thuStartDate !== ""
      ) {
        setThuStartDate(bh.thuStartDate);
        setThuEndDate(bh.thuEndDate);
      }

      if (
        bh &&
        bh !== null &&
        bh.friStartDate !== null &&
        bh.friStartDate !== ""
      ) {
        setFriStartDate(bh.friStartDate);
        setFriEndDate(bh.friEndDate);
      }

      if (
        bh &&
        bh !== null &&
        bh.satStartDate !== null &&
        bh.satStartDate !== ""
      ) {
        setSatStartDate(bh.satStartDate);
        setSatEndDate(bh.satEndDate);
      }

      if (
        bh &&
        bh !== null &&
        bh.sunStartDate !== null &&
        bh.sunStartDate !== ""
      ) {
        setSunStartDate(bh.sunStartDate);
        setSunEndDate(bh.sunEndDate);
      }
    }

    if (selectLangChangedFlag) {
      setSubmitFlag(true);
    }
  }

  // Handle Dates on change
  function handleMonChange(event) {
    setMonHrsErrFlag(false);

    switch (event.target.id) {
      case "monStartDate":
        setMonStartDate(event.target.value);
        break;
      case "monEndDate":
        setMonEndDate(event.target.value);
        break;
      default:
        break;
    }
  }

  function handleTueChange(event) {
    setTueHrsErrFlag(false);

    switch (event.target.id) {
      case "tueStartDate":
        setTueStartDate(event.target.value);
        break;
      case "tueEndDate":
        setTueEndDate(event.target.value);
        break;
      default:
        break;
    }
  }

  function handleWedChange(event) {
    setWedHrsErrFlag(false);

    switch (event.target.id) {
      case "wedStartDate":
        setWedStartDate(event.target.value);
        break;
      case "wedEndDate":
        setWedEndDate(event.target.value);
        break;
      default:
        break;
    }
  }

  function handleThuChange(event) {
    setThuHrsErrFlag(false);

    switch (event.target.id) {
      case "thuStartDate":
        setThuStartDate(event.target.value);
        break;
      case "thuEndDate":
        setThuEndDate(event.target.value);
        break;
      default:
        break;
    }
  }

  function handleFriChange(event) {
    setFriHrsErrFlag(false);

    switch (event.target.id) {
      case "friStartDate":
        setFriStartDate(event.target.value);
        break;
      case "friEndDate":
        setFriEndDate(event.target.value);
        break;
      default:
        break;
    }
  }

  function handleSatChange(event) {
    setSatHrsErrFlag(false);

    switch (event.target.id) {
      case "satStartDate":
        setSatStartDate(event.target.value);
        break;
      case "satEndDate":
        setSatEndDate(event.target.value);
        break;
      default:
        break;
    }
  }

  function handleSunChange(event) {
    setSunHrsErrFlag(false);

    switch (event.target.id) {
      case "sunStartDate":
        setSunStartDate(event.target.value);
        break;
      case "sunEndDate":
        setSunEndDate(event.target.value);
        break;
      default:
        break;
    }
  }

  return (
    <div dir={direction}>
      {renderContentFlag && (
        <div className={classes.root}>
          {/* { !appContext.signedIn &&
          <Redirect to='/signIn' />
        } */}
          <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
          <CssBaseline />

          <Container component="div" className={classes.main} maxWidth="lg">
            <div style={{ width: "100%", height: "100%" }}>
              {/* progressFlag &&
              <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
              </div>
            */}
              <Container
                component="div"
                className={classes.mainForm}
                maxWidth="md"
              >
                <Grid container justify="center">
                  <Grid item xs={12} sm={9}>
                    <StyledPaper elevation={3} className={classes.paper}>
                      <Typography variant="h6" style={{ textAlign: "center" }}>
                        {businessHrsLabel}
                      </Typography>

                      <Typography
                        variant="body1"
                        style={{ color: "green", textAlign: "center" }}
                      >
                        {allFieldsAreRequired}
                      </Typography>

                      {globalErrorFlag && (
                        <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                      )}

                      <LineSpacing value={24} />

                      <Grid container justify="center">
                        <Grid item style={marginAlignment}>
                          <FormControl className={classes.formControl}>
                            <label
                              htmlFor="formLanguageId"
                              className={classes.verticalAlignLabel}
                              style={directionAlignment}
                            >
                              <Typography variant="body1">
                                *{languageNameLabel}
                              </Typography>
                            </label>
                            <Select
                              id="formLanguageId"
                              value={formLanguage}
                              onChange={handleLanguageChange}
                              variant="outlined"
                              displayEmpty={true}
                              className={classes.selectEmpty}
                              margin="none"
                            >
                              <MenuItem value={null} disabled>
                                <Typography variant="body1">
                                  {selectLanguage}
                                </Typography>
                              </MenuItem>
                              {langSelectOptions.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item style={{ flex: "1" }}>
                          <FormControl className={classes.formControl}>
                            <label
                              htmlFor="locationId"
                              className={classes.verticalAlignLabel}
                              style={directionAlignment}
                            >
                              <Typography variant="body1">
                                *{locationLabel}
                              </Typography>
                            </label>
                            <Select
                              id="locationId"
                              value={selectedLocation}
                              onChange={handleLocationChange}
                              variant="outlined"
                              displayEmpty={true}
                              className={classes.selectEmpty}
                              placeholder={selectLocation}
                              margin="none"
                            >
                              <MenuItem value={null} disabled>
                                <Typography variant="body1">
                                  {selectLocation}
                                </Typography>
                              </MenuItem>

                              {locSelectOptions.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                  {item.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <LineSpacing value={12} />

                      <Grid container justify="center">
                        <Grid item xs={3}>
                          {""}
                        </Grid>

                        <Grid item xs={4}>
                          <Typography
                            variant="body1"
                            style={{ textAlign: "center" }}
                          >
                            {startTime}
                          </Typography>
                        </Grid>

                        <Grid item xs={1}>
                          {""}
                        </Grid>

                        <Grid item xs={4}>
                          <Typography
                            variant="body1"
                            style={{ textAlign: "center" }}
                          >
                            {endTime}
                          </Typography>
                        </Grid>

                        {/* Monday */}
                        <Grid
                          item
                          xs={3}
                          className={classes.verticalAlignLabel}
                        >
                          <Typography
                            variant="body1"
                            className={
                              monHrsErrFlag
                                ? classes.errVariant
                                : classes.normalVariant
                            }
                            style={directionAlignment}
                          >
                            {monLabel}
                          </Typography>
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                          <FormControl
                            className={classes.textField}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="monStartDate"
                              value={monStartDate}
                              placeholder={monStartDate}
                              onChange={handleMonChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={1} className={classes.gridItem}>
                          {""}
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                          <FormControl
                            className={classes.textField}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="monEndDate"
                              value={monEndDate}
                              placeholder={monEndDate}
                              onChange={handleMonChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        {/* Tuesday */}
                        <Grid
                          item
                          xs={3}
                          className={classes.verticalAlignLabel}
                        >
                          <Typography
                            variant="body1"
                            className={
                              tueHrsErrFlag
                                ? classes.errVariant
                                : classes.normalVariant
                            }
                            style={directionAlignment}
                          >
                            {tueLabel}
                          </Typography>
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                          <FormControl
                            className={classes.textField}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="tueStartDate"
                              value={tueStartDate}
                              placeholder={tueStartDate}
                              onChange={handleMonChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={1} className={classes.gridItem}>
                          {""}
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                          <FormControl
                            className={classes.textField}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="tueEndDate"
                              value={tueEndDate}
                              placeholder={tueEndDate}
                              onChange={handleMonChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        {/* Wednesday */}
                        <Grid
                          item
                          xs={3}
                          className={classes.verticalAlignLabel}
                        >
                          <Typography
                            variant="body1"
                            className={
                              wedHrsErrFlag
                                ? classes.errVariant
                                : classes.normalVariant
                            }
                            style={directionAlignment}
                          >
                            {wedLabel}
                          </Typography>
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                          <FormControl
                            className={classes.textField}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="wedStartDate"
                              value={wedStartDate}
                              placeholder={wedStartDate}
                              onChange={handleMonChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={1} className={classes.gridItem}>
                          {""}
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                          <FormControl
                            className={classes.textField}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="wedEndDate"
                              value={wedEndDate}
                              placeholder={wedEndDate}
                              onChange={handleMonChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        {/* Thursday */}
                        <Grid
                          item
                          xs={3}
                          className={classes.verticalAlignLabel}
                        >
                          <Typography
                            variant="body1"
                            className={
                              thuHrsErrFlag
                                ? classes.errVariant
                                : classes.normalVariant
                            }
                            style={directionAlignment}
                          >
                            {thuLabel}
                          </Typography>
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                          <FormControl
                            className={classes.textField}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="thuStartDate"
                              value={thuStartDate}
                              placeholder={thuStartDate}
                              onChange={handleMonChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={1} className={classes.gridItem}>
                          {""}
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                          <FormControl
                            className={classes.textField}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="thuEndDate"
                              value={thuEndDate}
                              placeholder={thuEndDate}
                              onChange={handleMonChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        {/* Friday */}
                        <Grid
                          item
                          xs={3}
                          className={classes.verticalAlignLabel}
                        >
                          <Typography
                            variant="body1"
                            className={
                              friHrsErrFlag
                                ? classes.errVariant
                                : classes.normalVariant
                            }
                            style={directionAlignment}
                          >
                            {friLabel}
                          </Typography>
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                          <FormControl
                            className={classes.textField}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="friStartDate"
                              value={friStartDate}
                              placeholder={friStartDate}
                              onChange={handleMonChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={1} className={classes.gridItem}>
                          {""}
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                          <FormControl
                            className={classes.textField}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="friEndDate"
                              value={friEndDate}
                              placeholder={friEndDate}
                              onChange={handleMonChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        {/* Saturday */}
                        <Grid
                          item
                          xs={3}
                          className={classes.verticalAlignLabel}
                        >
                          <Typography
                            variant="body1"
                            className={
                              satHrsErrFlag
                                ? classes.errVariant
                                : classes.normalVariant
                            }
                            style={directionAlignment}
                          >
                            {satLabel}
                          </Typography>
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                          <FormControl
                            className={classes.textField}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="satStartDate"
                              value={satStartDate}
                              placeholder={satStartDate}
                              onChange={handleMonChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={1} className={classes.gridItem}>
                          {""}
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                          <FormControl
                            className={classes.textField}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="satEndDate"
                              value={satEndDate}
                              placeholder={satEndDate}
                              onChange={handleMonChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        {/* Sunday */}
                        <Grid
                          item
                          xs={3}
                          className={classes.verticalAlignLabel}
                        >
                          <Typography
                            variant="body1"
                            className={
                              sunHrsErrFlag
                                ? classes.errVariant
                                : classes.normalVariant
                            }
                            style={directionAlignment}
                          >
                            {sunLabel}
                          </Typography>
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                          <FormControl
                            className={classes.textField}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="sunStartDate"
                              value={sunStartDate}
                              placeholder={sunStartDate}
                              onChange={handleMonChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={1} className={classes.gridItem}>
                          {""}
                        </Grid>

                        <Grid item xs={4} className={classes.gridItem}>
                          <FormControl
                            className={classes.textField}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="sunEndDate"
                              value={sunEndDate}
                              placeholder={sunEndDate}
                              onChange={handleMonChange}
                              required
                              fullWidth
                              // autoFocus
                              type={"text"}
                              margin="none"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <LineSpacing value={12} />

                      <Grid container justify="center">
                        <Grid item style={marginAlignment}>
                          <FormControl
                            className={classes.formControl}
                            variant="outlined"
                          >
                            <label
                              htmlFor="additionalInfoId"
                              style={directionAlignment}
                            >
                              <Typography variant="body1">
                                {additionalInfoLabel}
                              </Typography>
                            </label>
                            <TextareaAutosize
                              id="additionalInfoId"
                              aria-label="additional info"
                              rows={2}
                              rowsMax={6}
                              value={additionalInfo}
                              placeholder={additionalInfoPrompt}
                              onChange={handleChange}
                              required
                              fullWidth
                              error={additionalInfoErrFlag}
                              margin="none"
                              className={classes.textArea}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <LineSpacing value={18} />

                      <Button
                        variant="contained"
                        size="large"
                        disabled={!submitFlag ? true : false}
                        color="primary"
                        className={classes.button}
                        onClick={businessHoursService}
                      >
                        <SaveIcon
                          className={clsx(classes.leftIcon, classes.iconSmall)}
                        />
                        {submit}
                      </Button>
                    </StyledPaper>
                  </Grid>
                </Grid>
              </Container>
            </div>

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={snackbarOpen}
              autoHideDuration={8000}
              onClose={snackbarOnClose}
              ContentProps={{ "aria-describedby": "message-id" }}
              message={<span id="message-id">{snackbarsMsg}</span>}
              variant={snackbarsVariant}
            />
          </Container>

          <footer className={classes.footer}>
            <div>
              <PageFooter isSignedIn={isSignedIn} />
            </div>
          </footer>
        </div>
      )}
    </div>
  );
}

export default BusinessHours;
