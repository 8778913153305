import React, { useState, useEffect, useLayoutEffect, useContext } from "react";

// Lodash
import lodash from "lodash";

// Froala Editor
// import FroalaEditor from "react-froala-wysiwyg";
// import Froalaeditor from "froala-editor";
// import "froala-editor/js/plugins.pkgd.min.js";

// import "froala-editor/css/froala_style.min.css";
// import "froala-editor/css/froala_editor.pkgd.min.css";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import FroalaEditorComponent from "react-froala-wysiwyg";
import FroalaEditor from "froala-editor";

// AppContext
import AppContext from "../../config/AppContext";

import ImportEditorLanguageFile from "./ImportEditorLanguageFile";
// import EditorImageSelector from "../../components/dialogs/EditorImageSelector";
import CommonMediaSelector from "../../components/dialogs/CommonMediaSelector";

function FroalaCommonEditor(props) {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);
  
    //--------------------------
    // For EditorImageSelector
    //--------------------------
    const [mediaSelectorFlag, setMediaSelectorFlag] = useState(false);
    const [dialogOpenFlag, setDialogOpenFlag] = useState(false);
    const [editorInstance, setEditorInstance] = useState(null);
    
    const [renderEditorFlag, setRenderEditorFlag] = useState(false);
    
    const [editorLanguage, setEditorLanguage] = useState("en");
  
    const {
        editorType, // "minimal" or not specified
        editorId,
        editorRef,
        placeholderPrompt,
        editorMaxChars,
        editorModel,
        modelChangeCallback,
        editorReadyCallback,

        language,
        domain,
        forUserOnly,
    } = props;

    const froalaBrowseCallback = (editor) => {
        // console.log("In froalaBrowseCallback(): editor is: ", editor);
        // console.log("In froalaBrowseCallback(): announceRef is: ", announceRef);
        setEditorInstance(editor);

        // Open EditorImageSelector
        setDialogOpenFlag(true);
        setMediaSelectorFlag(true);
    }

    const onRowSelectedCallback = (src) => {
        // console.log("In onRowSelectedCallback(): src is: ", src);
        // console.log("In onRowSelectedCallback(): editorInstance is: ", editorInstance);
        const selectedImage = '<img src="'+ src + '" style="width: 300px;" class="fr-fic fr-dib">';
        editorInstance.html.insert(selectedImage);
        modelChangeCallback(selectedImage);
        setDialogOpenFlag(false);
    }

    const onEditorReady = (status) => {
        // console.log("In onEditorReady(): status is: ", status);
        // setRenderEditorFlag(true);
        if (editorReadyCallback && editorReadyCallback !== null) {
            editorReadyCallback(status);
        }
    }

    FroalaEditor.DefineIcon("browseButton", { NAME: "Browse", SVG_KEY: "imageManager" }); // imageManager
    FroalaEditor.RegisterCommand("browseButton", {
        title: "Browse",
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        
        callback: function() {
            // console.log("In callback(): event is: ", event);
            // console.log("In callback(): this is: ", this);
            // this.html.insert("My New HTML");
            // browseButtonClickCallback(this);
            froalaBrowseCallback(this);
        }
    });
  
    let editorConfig = {
        key: "gVG3C-8F2G2C6C1A3D2ud1BI1IMNBUMRWAi1AYMSTRBUZYB-16D4E3D2B2C3I2H1B10B2D1==",
        enter: FroalaEditor.ENTER_BR,
        attribution: false,
        placeholderText: placeholderPrompt,
        charCounterCount: true,
        language: editorLanguage,
        direction: direction,
        width: '100% !important',
        heightMin: 100,
        heightMax: 400,
        // Set maximum number of characters.
        charCounterMax: editorMaxChars,

        fontFamily: {
            'Roboto,sans-serif': 'Roboto',
            'Oswald,sans-serif': 'Oswald',
            'Montserrat,sans-serif': 'Montserrat',
            "'Open Sans Condensed',sans-serif": 'Open Sans Condensed',
            'Pacifico,sans-serif': 'Pacifico',
        },

        lineHeights: {
            Default: '',
            Single: '1',
            '1.15': '1.15',
            '1.5': '1.5',
            Double: '2'
        }, 

        pluginsEnabled: [
            "align",
            "charCounter",
            "codeBeautifier",
            "codeView",
            "colors",
            "draggable",
            "embedly",
            "emoticons",
            "entities",
            "file",
            "fontFamily",
            "fontSize",
            "fullscreen",
            "image",
            // "imageManager",
            "inlineStyle",
            "lineHeight",
            "lineBreaker",
            "link",
            "lists",
            "paragraphFormat",
            "paragraphStyle",
            "quickInsert",
            "quote",
            "save",
            "table",
            "url",
            "video",
            "wordPaste",
        ],

        // imageEditButtons: ['imageReplace', 'imageAlign', 'mediaCaption', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
        imageEditButtons: [
            'imageAlign', 
            'mediaCaption', 
            'imageRemove', '|', 
            'imageLink', 
            'linkOpen', 
            'linkEdit', 
            'linkRemove', '-', 
            'imageDisplay', 
            'imageStyle', 
            'imageAlt', 
            'imageSize'
        ],

        toolbarButtons: {
            // Key represents the more button from the toolbar.
            moreText: {
                // List of buttons used in the  group.
                buttons: [
                "bold",
                "italic",
                "browseButton",
                "underline",
                "strikeThrough",
                "subscript",
                "superscript",
                "fontFamily",
                "fontSize",
                "textColor",
                "backgroundColor",
                "inlineClass",
                "inlineStyle",
                "lineHeight",
                "clearFormatting",
                ],

                // Alignment of the group in the toolbar.
                align: "left",

                // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                buttonsVisible: 3,
            },

            moreParagraph: {
                buttons: [
                    "alignLeft",
                    "alignCenter",
                    "formatOLSimple",
                    "alignRight",
                    "alignJustify",
                    "formatOL",
                    "formatUL",
                    "paragraphFormat",
                    "paragraphStyle",
                    "lineHeight",
                    "outdent",
                    "indent",
                    "quote",
                ],
                align: "left",
                buttonsVisible: 0,
            },

            moreRich: {
                buttons: [
                    // "insertImage",
                    "insertLink",
                    "insertVideo",
                    "insertTable",
                    "emoticons",
                    "fontAwesome",
                    "specialCharacters",
                    // "embedly",
                    "insertFile",
                    "insertHR",
                ],
                align: "left",
                buttonsVisible: 1,
            },

            moreMisc: {
                buttons: [
                    "undo",
                    "redo",
                    "fullscreen",
                    "print",
                    "getPDF",
                    "spellChecker",
                    "selectAll",
                    "html",
                    "help",
                ],
                align: "right",
                buttonsVisible: 2,
            },
        },
    };
    const [mainConfig, setMainConfig] = useState(editorConfig);

    let minimalEditorConfig = {
        key: "gVG3C-8F2G2C6C1A3D2ud1BI1IMNBUMRWAi1AYMSTRBUZYB-16D4E3D2B2C3I2H1B10B2D1==",
        enter: FroalaEditor.ENTER_BR,
        attribution: false,
        placeholderText: placeholderPrompt,
        charCounterCount: true,
        language: editorLanguage,
        direction: direction,
        width: '100% !important',
        heightMin: 100,
        heightMax: lodash.isEmpty(editorType) ? 400 : 200,
        // Set maximum number of characters.
        charCounterMax: editorMaxChars,

        fontFamily: {
            'Roboto,sans-serif': 'Roboto',
            'Oswald,sans-serif': 'Oswald',
            'Montserrat,sans-serif': 'Montserrat',
            "'Open Sans Condensed',sans-serif": 'Open Sans Condensed',
            'Pacifico,sans-serif': 'Pacifico',
        },

        lineHeights: {
            Default: '',
            Single: '1',
            '1.15': '1.15',
            '1.5': '1.5',
            Double: '2'
        }, 
    
        pluginsEnabled: [
            "align",
            "charCounter",
            "codeBeautifier",
            "codeView",
            "colors",
            "draggable",
            "embedly",
            "emoticons",
            "entities",
            "file",
            "fontFamily",
            "fontSize",
            "fullscreen",
            "image",
            // "imageManager",
            "inlineStyle",
            "lineHeight",
            "lineBreaker",
            "link",
            "lists",
            "paragraphFormat",
            "paragraphStyle",
            "quickInsert",
            "quote",
            "save",
            "table",
            "url",
            "video",
            "wordPaste",
        ],

        // imageEditButtons: ['imageReplace', 'imageAlign', 'mediaCaption', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
        imageEditButtons: [
            'imageAlign', 
            'mediaCaption', 
            'imageRemove', '|', 
            'imageLink', 
            'linkOpen', 
            'linkEdit', 
            'linkRemove', '-', 
            'imageDisplay', 
            'imageStyle', 
            'imageAlt', 
            'imageSize'
        ],

        toolbarButtons: {
            // Key represents the more button from the toolbar.
            moreText: {
                // List of buttons used in the  group.
                buttons: [
                "bold",
                "italic",
                // "browseButton",
                "underline",
                "strikeThrough",
                // "subscript",
                // "superscript",
                "fontFamily",
                "fontSize",
                "textColor",
                "backgroundColor",
                "inlineClass",
                "inlineStyle",
                "lineHeight",
                "clearFormatting",
                ],

                // Alignment of the group in the toolbar.
                align: "left",

                // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                buttonsVisible: 3,
            },

            moreParagraph: {
                buttons: [
                    "alignLeft",
                    "alignCenter",
                    "formatOLSimple",
                    "alignRight",
                    "alignJustify",
                    "formatOL",
                    "formatUL",
                    "paragraphFormat",
                    "paragraphStyle",
                    "lineHeight",
                    "outdent",
                    "indent",
                    "quote",
                ],
                align: "left",
                buttonsVisible: 0,
            },

            moreRich: {
                buttons: [
                    // "insertImage",
                    // "insertLink",
                    // "insertVideo",
                    // "insertTable",
                    "emoticons",
                    // "fontAwesome",
                    // "specialCharacters",
                    // "embedly",
                    // "insertFile",
                    // "insertHR",
                ],
                align: "left",
                buttonsVisible: 1,
            },

            moreMisc: {
                buttons: [
                    "undo",
                    "redo",
                    "fullscreen",
                    // "print",
                    // "getPDF",
                    // "spellChecker",
                    "selectAll",
                    // "html",
                    // "help",
                ],
                align: "right",
                buttonsVisible: 0,
            },
        },
    };
    const [minConfig, setMinConfig] = useState(minimalEditorConfig);
    
    async function importEditorLanguageFile(selectedLang) {
        // console.log("In importEditorLanguageFile(): selectedLang is: ", selectedLang);
    
        await ImportEditorLanguageFile(selectedLang);

        // setAnnouncementEditorReady(true);
        // setShortBiographyEditorReady(true);
        onEditorReady(true)
    }

    async function reformatLanguageCode(selectedLang) {
        // console.log("In reformatLanguageCode(): selectedLang is: ", selectedLang);

        let lang = selectedLang;

        switch (selectedLang) {
            case "zh": // Chinese (Simplified)
                lang = "zh_cn";
                break;
            case "zh-TW": // Chinese (Traditional)
                lang = "zh_tw";
                break;
            case "br": // Brazil
                lang = "pt_br";
                break;
            case "pt": // Portuguese
                lang = "pt_pt";
                break;
            default:
                lang = selectedLang;
                break;
        }
        // console.log("In reformatLanguageCode(): lang is: ", lang);
        setEditorLanguage(lang);

        minimalEditorConfig.language = lang;
        setMinConfig(minimalEditorConfig);

        editorConfig.language = lang;
        setMainConfig(editorConfig);
        // setRenderEditorFlag(true);
    }
    
    useEffect(() => {
        // console.log('In FroalaCommonEditor(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        async function handleLanguage() {
            setRenderEditorFlag(false);
            await reformatLanguageCode(appContext.currentLanguage.languageCode);
            await importEditorLanguageFile(appContext.currentLanguage.languageCode);
            // setTimeout( function() {
                setRenderEditorFlag(true);
            // }, 500);
        }
        handleLanguage();
    }, [appContext.currentLanguage.languageCode]);

    useLayoutEffect(() => {
        // console.log("In FroalaCommonEditor(): useLayoutEffect()");
        async function handleLanguage() {
            await reformatLanguageCode(appContext.currentLanguage.languageCode);
            // setRenderEditorFlag(true);
            // setTimeout( function() {
                setRenderEditorFlag(true);
            // }, 500);
        }
        handleLanguage();
    }, []);

    const dialogCloseCallback = () => {
        setDialogOpenFlag(false);
    }

    function handleModelChangeCallback(data) {
        modelChangeCallback(data);
    }

    return (
        <>
            {renderEditorFlag &&
                <FroalaEditorComponent
                    id={editorId}
                    ref={editorRef}
                    config={lodash.isEmpty(editorType) ? mainConfig : minConfig}
                    model={editorModel}
                    onModelChange={data => handleModelChangeCallback(data)}
                    style={{width: '100% !important'}}
                />
            }

            {mediaSelectorFlag &&
                <CommonMediaSelector
                    mediaLanguageCode={language ? language.languageCode : null}
                    mediaDomainName={domain ? domain.value : null}
                    mediaActionFlag={true}
                    queryType={"media"} // media, audios or videos

                    forUserOnly={forUserOnly}
                    
                    dialogOpenFlag={dialogOpenFlag}
                    onRowSelectedCallback={onRowSelectedCallback}
                    dialogCloseCallback={dialogCloseCallback}
                />
            }
        </>
    )
}

export default FroalaCommonEditor;