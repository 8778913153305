import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
// import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';
// import Collapse from '@material-ui/core/Collapse';
import Avatar from "@material-ui/core/Avatar";
// import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
// import { red } from '@material-ui/core/colors';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ShareIcon from '@material-ui/icons/Share';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import { red } from '@material-ui/core/colors';
// import { deepOrange } from '@material-ui/core/colors';
import { green } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

// import FroalaEditor from 'react-froala-wysiwyg';
// import 'froala-editor/js/plugins.pkgd.min.js';
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

// AppContext
import AppContext from "../../../config/AppContext";

// ReducerContext
import ReducerContext from "../../../config/ReducerContext";

const TestimonialsTemplate01 = (props) => {
  const appContext = useContext(AppContext);
  const reducerContext = useContext(ReducerContext);
  const [direction, setDirection] = useState(appContext.direction);
  // const [currentLanguageCode, setCurrentLanguageCode] = useState(appContext.currentLanguage.languageCode);

  const {
    currentLanguageCode,
    designBlockRecord,
    // controlHeader,
    // controlSubHeader,
    // controlDesc,

    // controlLinkUrl,
    // controlLinkText,

    // controlMediaUrl,
    // controlImageText,

    // Array of details: controlHeader, controlSubHeader, controlDesc, controlLinkUrl, controlLinkText, controlMediaUrl, controlImageText,
    // designBlocksDetails,
  } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      textAlign: "center",
    },
    paper: {
      height: currentLanguageCode === "zh" ? 350 : 500,
      textAlign: "center",
    },
    mainForm: {
      textAlign: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    verticalAlignLabel: {
      // textAlign: 'left',
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    rounded: {
      color: "#fff",
      // backgroundColor: green[500],
    },
    large: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  }));

  const classes = useStyles();

  return (
    <div dir={direction}>
      <div className={classes.root}>
        <Container component="div" maxWidth="sm">
          <Grid container item xs={12}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              {designBlockRecord.ControlHeader && (
                <Typography variant="h2" align="center">
                  {designBlockRecord.ControlHeader}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.mainForm}
              style={{ textAlign: "center" }}
            >
              {designBlockRecord.ControlDesc && (
                <Typography variant="h6" align="center">
                  <FroalaEditorView model={designBlockRecord.ControlDesc} />
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}></Grid>
          </Grid>
        </Container>

        <Grid container item xs={12} sm={12} spacing={4}>
          {designBlockRecord &&
            designBlockRecord.DesignBlocksDetails &&
            designBlockRecord.DesignBlocksDetails.map((item, index) => (
              <Grid key={index} item xs={12} sm={4} style={{ textAlign: "center" }}>
                <Paper
                  elevation={3}
                  className={classes.paper}
                  style={{ height: currentLanguageCode === "zh" ? 350 : 500 }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    style={{
                      textAlign: "center",
                      padding: "50px 30px 30px 40px",
                    }}
                  >
                    <Grid
                      item
                      xs={3}
                      className={classes.verticalAlignLabel}
                      style={{ textAlign: "center" }}
                    >
                      {item.DetailsImageURL && (
                        <Avatar
                          variant="rounded"
                          className={clsx(classes.rounded, classes.large)}
                        >
                          <img
                            alt={
                              item.DetailsImageText ? item.DetailsImageText : ""
                            }
                            src={item.DetailsImageURL}
                            style={{ width: "96px" }}
                          />
                        </Avatar>
                      )}
                    </Grid>
                    <Grid item xs={9} style={{ textAlign: "left" }}>
                      {item.DetailsHeader && (
                        <Typography
                          variant="h6"
                          align="left"
                          className={classes.verticalAlignLabel}
                        >
                          {item.DetailsHeader}
                        </Typography>
                      )}

                      {item.DetailsSubHeader && (
                        <Typography
                          variant="caption"
                          align="left"
                          className={classes.verticalAlignLabel}
                        >
                          {item.DetailsSubHeader}
                        </Typography>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      className={classes.verticalAlignLabel}
                      style={{ padding: "30px" }}
                    >
                      {item.DetailsDesc && (
                        <Typography
                          variant="h6"
                          align="center"
                          gutterBottom={true}
                          className={classes.verticalAlignLabel}
                        >
                          <FroalaEditorView model={item.DetailsDesc} />
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} className={classes.mainForm}>
                      Image
                      {/* <img alt={item.DetailsLinkText ? item.DetailsLinkText : ''} src={"https://k.com/image.png"} style={{width: '96px'}}/> */}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          ;
        </Grid>
      </div>
    </div>
  );
};

export default TestimonialsTemplate01;
