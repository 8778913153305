import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import SaveIcon from "@material-ui/icons/Save";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// Amplify
import Amplify, { API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";
import LineSpacing from "../../components/misc/LineSpacing";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    backgroundImage: `url(${"assets/logos/background20.png"})`,
  },
  gridWidth: {
    width: "80%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  mainForm: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  verticalAlignLabel: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  snackIcon: {
    marginRight: theme.spacing(1),
  },
}));

let convertSoc = {
  convertContactInfo: "Convert contact information",

  convertSuccessMsg: "Conversion was successful.",
  convertFailureMsg: "Conversion failed.",
  prevConvertedMsg: "Only records not previously converted would be converted",
  convertFrom: "Convert from (Source)",
  selectSourceLanguage: "Please select source language",
  convertTo: "Convert to (Target)",
  selectTargetLanguage: "Please select target language",
  submit: "Submit",
  noDataConverted: "No records found to converted.",
};

function ConvertContactInfo(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [resourceLanguage, setResourceLanguage] = useState(defaultLang.value);
  const [targetResourceLanguage, setTargetResourceLanguage] = useState(null);
  const [langSelectOptions, setLangSelectOptions] = useState([]);
  const [targetLangSelectOptions, setTargetLangSelectOptions] = useState([]);

  const [formSubmitFlag, setFormSubmitFlag] = useState(false);
  const [progressFlag, setProgressFlag] = useState(false);

  // For Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  // For Resources
  const [convertSocRes, setConvertSocRes] = useState(convertSoc);

  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const {
    convertContactInfo,

    prevConvertedMsg,
    convertFrom,
    selectSourceLanguage,
    convertTo,
    selectTargetLanguage,
    submit,
    convertSuccessMsg,
    convertFailureMsg,
    noDataConverted,
  } = convertSocRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(convertSoc);
    // console.log("In ViewLanguages(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ViewLanguages(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "convertContactInfo")
              convertSoc.convertContactInfo = item.ResourceDesc;
            if (item.ResourceCode === "prevConvertedMsg")
              convertSoc.prevConvertedMsg = item.ResourceDesc;
            if (item.ResourceCode === "convertFrom")
              convertSoc.convertFrom = item.ResourceDesc;
            if (item.ResourceCode === "selectSourceLanguage")
              convertSoc.selectSourceLanguage = item.ResourceDesc;
            if (item.ResourceCode === "convertTo")
              convertSoc.convertTo = item.ResourceDesc;
            if (item.ResourceCode === "selectTargetLanguage")
              convertSoc.selectTargetLanguage = item.ResourceDesc;
            if (item.ResourceCode === "submit")
              convertSoc.submit = item.ResourceDesc;
            if (item.ResourceCode === "convertSuccessMsg")
              convertSoc.convertSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "convertFailureMsg")
              convertSoc.convertFailureMsg = item.ResourceDesc;
            if (item.ResourceCode === "noDataConverted")
              convertSoc.noDataConverted = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, convertSoc);
        }

        setConvertSocRes(convertSoc);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setConvertSocRes(convertSoc);
        setProgressFlag(false);
      });
  }

  let selectOptions = [];

  async function fetchLanguages() {
    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In ConvertContactInfo(): fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In ConvertContactInfo(): fetchLanguages(): response length is: ', response.data.Items.length);
        // console.log('In ConvertContactInfo(): fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            // label: item.LanguageName,
            text: item.LanguageName,
          };

          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);

        // Filter out default select options
        const flteredRecs = selectOptions.filter(
          (record) => record.value !== resourceLanguage
        );

        setTargetLangSelectOptions(flteredRecs);
      })
      .catch((err) => {
        console.log(
          "In ConvertContactInfo(): useEffect(): err is: ",
          JSON.stringify(err, null, 2)
        );
      });
  }

  useEffect(() => {
    // console.log("In useEffect()");
  }, []);

  useLayoutEffect(() => {
    // console.log("In ManageLanguages(): useLayoutEffect()");

    if (selectOptions.length === 0) {
      fetchLanguages();
    }

    // Fetch resource from catche
    let cacheItemKey = "convertSoc";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ManageLanguages(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ManageLanguages(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ManageLanguages(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ManageLanguages(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ManageLanguages(): useLayoutEffect(): got resource from cache');
      setConvertSocRes(cacheItem);
    }
  }, []);

  function initializeForm() {
    // console.log("In initializeForm()");

    setTargetResourceLanguage(null);
    setFormSubmitFlag(false);
  }

  async function convertService() {
    // console.log("In convertService(): resourceLanguage is: ", resourceLanguage);
    // console.log("In convertService(): targetResourceLanguage is: ", targetResourceLanguage);

    setProgressFlag(true);
    setGlobalErrorMsg("");
    setGlobalErrorFlag(false);

    // const d = new Date();
    const timestamp = new Date();
    // const timestamp = d.toISOString();
    // console.log("In convertService(): timestamp is: ", timestamp);

    let params = {
      apiName: "TranslateContactInfoAPI",
      path: "/dynamodb/contactInfo/translateContactInfo",
      payload: {
        sourceLanguageCode: resourceLanguage,
        targetLanguageCode: targetResourceLanguage,

        modifiedBy: appContext.userName,
        createdAt: timestamp,
        // "updatedAt": timestamp
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In convertService(): initObj is: ', JSON.stringify(initObj, null, 2));

    setProgressFlag(true);

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In convertService(): response is: ', JSON.stringify(response, null, 2));
        setProgressFlag(false);

        if (response.data.convertedItemsCount > 0) {
          // Show Success Snackbar
          setSnackbarsVariant("success");
          setSnackbarsMsg(convertSuccessMsg);
          setSnackbarOpen(true);

          initializeForm();
        } else {
          // Show Warning Snackbar
          setSnackbarsVariant("warning");
          setSnackbarsMsg(noDataConverted);
          setSnackbarOpen(true);
        }
      })
      .catch((err) => {
        console.log(
          "In convertService(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setGlobalErrorFlag(true);
        setGlobalErrorMsg(convertFailureMsg);

        setProgressFlag(false);
        setFormSubmitFlag(false);
      });
  }

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);
    setResourceLanguage(selectedOption);

    setGlobalErrorMsg("");
    setGlobalErrorFlag(false);

    // Filter out selected option from target options
    let flteredRecs = [];
    // let flteredRecs = langSelectOptions.filter(record => record.value !== selectedOption);
    if (selectedOption === "ko") {
      // Can't convert from Korean to Hebrew
      flteredRecs = langSelectOptions.filter(
        (record) => record.value !== selectedOption && record.value !== "he"
      );
    } else if (selectedOption === "zh-TW") {
      // Can't convert from Tradional to Simplified Chinese and vice versa
      flteredRecs = langSelectOptions.filter(
        (record) => record.value !== selectedOption && record.value !== "zh"
      );
    } else if (selectedOption === "zh") {
      flteredRecs = langSelectOptions.filter(
        (record) => record.value !== selectedOption && record.value !== "zh-TW"
      );
    } else if (selectedOption === "no") {
      // Can't convert from Norwegian to Arabic or Hebrew
      flteredRecs = langSelectOptions.filter(
        (record) =>
          record.value !== selectedOption &&
          record.value !== "ar" &&
          record.value !== "he"
      );
    } else {
      flteredRecs = langSelectOptions.filter(
        (record) => record.value !== selectedOption
      );
    }
    setTargetLangSelectOptions(flteredRecs);
    setTargetResourceLanguage(null);

    if (
      selectedOption !== null &&
      selectedOption !== null &&
      selectedOption !== "" &&
      targetResourceLanguage !== null &&
      targetResourceLanguage !== null &&
      targetResourceLanguage !== ""
    ) {
      setFormSubmitFlag(true);
    }
  }

  function handleTargetLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleTargetLanguageChange(): selectedOption is', selectedOption);
    setTargetResourceLanguage(selectedOption);

    setGlobalErrorMsg("");
    setGlobalErrorFlag(false);

    if (
      selectedOption !== null &&
      selectedOption !== null &&
      selectedOption !== "" &&
      resourceLanguage !== null &&
      resourceLanguage !== null &&
      resourceLanguage !== ""
    ) {
      setFormSubmitFlag(true);
    }
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    setSnackbarOpen(false);
  }

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { !appContext.signedIn &&
          <Redirect to='/signIn' />
        } */}

        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%", height: "100%" }}>
            {progressFlag && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                  size={15}
                />
              </div>
            )}
            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="md"
            >
              <Grid container justify="center">
                <Grid item xs={12} sm={9}>
                  <StyledPaper elevation={3} className={classes.paper}>
                    <Typography
                      variant="h6"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      {convertContactInfo}
                    </Typography>

                    <Typography
                      variant="caption"
                      style={{ color: "green", textAlign: "center" }}
                    >
                      {prevConvertedMsg}
                    </Typography>

                    {globalErrorFlag && (
                      <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                    )}

                    <LineSpacing value={24} />

                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="formLanguageId"
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {convertFrom}
                        </Typography>
                      </label>
                      <Select
                        id="formLanguageId"
                        value={resourceLanguage}
                        placeholder={selectSourceLanguage}
                        onChange={handleLanguageChange}
                        variant="outlined"
                        displayEmpty={true}
                        className={classes.selectEmpty}
                        // autoFocus
                      >
                        <MenuItem value={null} disabled>
                          <Typography variant="body1">
                            {selectSourceLanguage}
                          </Typography>
                        </MenuItem>
                        {langSelectOptions.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="formLanguageId"
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">{convertTo}</Typography>
                      </label>
                      <Select
                        id="formLanguageId"
                        value={targetResourceLanguage}
                        placeholder={selectTargetLanguage}
                        onChange={handleTargetLanguageChange}
                        variant="outlined"
                        displayEmpty={true}
                        className={classes.selectEmpty}
                        autoFocus
                      >
                        <MenuItem value={null} disabled>
                          <Typography variant="body1">
                            {selectTargetLanguage}
                          </Typography>
                        </MenuItem>
                        {targetLangSelectOptions.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <LineSpacing value={24} />

                    <Button
                      variant="contained"
                      size="large"
                      disabled={!formSubmitFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={convertService}
                    >
                      <SaveIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {submit}
                    </Button>

                    <LineSpacing value={18} />

                    {/* Mineral-ui 
                    
                    <FormField label={convertFrom} caption={""}>
                      <Select 
                        data={langSelectOptions} 
                        name="sourceLang" 
                        selectedItem={resourceLanguage}
                        placeholder={selectSourceLanguage}
                        onChange={handleLanguageChange}
                        disabled={false}
                        readOnly={false} 
                        size={"medium"}
                        variant={"success"}
                        style={{textAlign: 'left'}} 
                      />
                    </FormField>

                    <LineSpacing value={20} />
                    
                    <FormField label={convertTo} caption={""}>
                      <Select 
                        data={targetLangSelectOptions} 
                        name="targetLang" 
                        selectedItem={targetResourceLanguage}
                        placeholder={selectTargetLanguage}
                        onChange={handleTargetLanguageChange}
                        disabled={false}
                        readOnly={false} 
                        size={"medium"}
                        variant={"success"}
                        style={{textAlign: 'left'}} 
                      />
                    </FormField>

                    <LineSpacing value={20} />

                    { !formSubmitFlag &&
                      <Button variant="contained" size="small" disabled color="secondary" className={classes.button}>
                        <SaveIcon className={classes.leftIcon} />
                        {submit}
                      </Button>
                    }

                    { formSubmitFlag && 
                      <Button variant="contained" size="small" color="primary" className={classes.button} onClick={convertService}>
                        <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
                        {submit}
                      </Button>
                    }

                    <LineSpacing value={16} />*/}
                  </StyledPaper>
                </Grid>
              </Grid>
            </Container>
          </div>

          {/* variant: 'success', 'warning', 'error', 'info' */}
          <TsiCustomizedSnackbars
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={8000}
            onClose={snackbarOnClose}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">{snackbarsMsg}</span>}
            variant={snackbarsVariant}
          />
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter isSignedIn={isSignedIn} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default ConvertContactInfo;
