function FormatCurrency(amount, currencyCode, localeCode) {
    // console.log('In FormatCurrency(): currencyCode is: ', currencyCode);
    let retValue = null;
    
    // let languageCode = "en-US";

    // // Reformat to proper format
    // if (localeCode) {
    //     let lang = null;

    //     switch(localeCode) {
    //         case "en":
    //             lang = "en-US";
    //         break;
    //         case "zh":
    //             lang = "zh-CN";
    //         break;
    //         default:
    //             lang = "en-US";
    //             break;

    //     }
    //     const locales1 = [lang];
    //     const options1 = { localeMatcher: 'lookup' };
        
    //     let supportedLocales = Intl.NumberFormat.supportedLocalesOf(locales1, options1);
    //     // console.log('In FormatCurrency(): supportedLocales is: ', supportedLocales);
    //     if (supportedLocales && supportedLocales.length > 0) {
    //         languageCode = supportedLocales[0];
    //     }
    // }
    // console.log('In FormatCurrency(): languageCode is: ', languageCode);

    retValue = new Intl.NumberFormat(
        localeCode, 
        { 
            style: 'currency', 
            currency: currencyCode,
        }
    ).format(amount);
    // console.log("In FormatCurrency(): retValue is: ", retValue);
    return retValue;
};
  
export default FormatCurrency;
