import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import InputAdornment from "@material-ui/core/InputAdornment";
import TranslateIcon from '@material-ui/icons/Translate';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SaveIcon from "@material-ui/icons/Save";
import Container from "@material-ui/core/Container";

// import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// For Primereact
// import { Dialog } from 'primereact/dialog';
import { OrderList } from 'primereact/orderlist';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Amplify
// import { API } from "aws-amplify";
// import { AmplifyTheme   } from 'aws-amplify-react';

// AppContext
import AppContext from "../../../config/AppContext";

import LineSpacing from "../../../components/misc/LineSpacing";
import GlobalErrorMessage from "../../../components/dialogs/GlobalErrorMessage";

import FaqConverter from "../FaqConverter";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        width: "100% !important",
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    leftPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "left",
    },
    leftPanelSubItems: {
        width: "100% !important",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1rem" /* Footer height */,

        width: "100% !important",
        marginTop: "auto",
        // backgroundColor: '#fff',
        textAlign: "center",
    },
    paper: {
        minWidth: "300px",
        marginTop: theme.spacing(2),
        alignItems: "center",
        padding: "10px 30px 10px 30px !important",
        textAlign: "center",
    },
    divPadding: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

function ManageFaqsMv(props) {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);
    
    const theme = useTheme();
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    const { 
        manageFaqsLabel, 
        requiredFields,
        languageLabel,
        languagePrompt,
        languageErrMsg,
        domainLabel,
        domainPrompt,
        domainErrMsg,
            
        faqAnswerLabel,
        faqAnswerPrompt,
        faqAnswerErrMsg,
        
        faqQuestionLabel,
        faqQuestionPrompt,
        faqQuestionErrMsg,
        // selectTitlePrompt,

        faqCategoryLabel,
        faqCategoryPrompt,
        faqCategoryErrMsg,

        gridCategory,
        loadingText,
        noOptionsText,
        clearText,
        closeText,
    
        uploadLabel,
        createFaqLabel,
        updateFaqLabel,
        createLabel,
        closeLabel,
        noRecordsFound, 
        gridFaqQuestion,
        gridFaqAnswer,
        lastUpdateLabel,
        convertLabel,
        convertFaqsLabel,

        organizeLabel,
        organizeFaqListLabel,
        freqAskedQuestionsLabel,
        saveLabel,

        filteredRecords,
        dragdropFlag,
        orderListTemplate,
        closeOrderListForm,
        handleFilteredRecords,
        organizeService,

        //==================================

        datePickerLanguage,
        globalErrorFlag,
        globalErrorMsg,
        showListFlag,
        languageErrFlag,
        language,
        languages,
        handleListLanguageChange,
        editModeFlag,
        domainErrFlag,
        domain,
        domainOptions,
        handleListDomainChange,
        faqListRecords,
        showAlertDialog,
        editRecordAction,
        convertAction,
        createMoreAction,
        noRecordFoundFlag,
        createFaqAction,
        showDetailsFormFlag,
        closeDetailsForm,
        handleLanguageChange,
        handleDomainChange,
        faqCategoryErrFlag,
        faqCategory,
        categoryOptions,
        handleFaqCategoryChange,
        faqAnswerErrFlag,
        faqAnswer,
        handleChange,
        textMaxLength,
        faqQuestionErrFlag,
        faqQuestion,
        submitButtonFlag,
        buttonFormAction,
        convertFlag,
        closeConvertForm,
        orderListAction,
        orderListFlag,
        handleOrderListCategoryChange,
    } = props;
        
    useEffect(() => {
        // console.log('In ManageFaqsMv(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        // sessionStorage.removeItem("current-live-event");
    }, []);

    useLayoutEffect(() => {
        // console.log("In ManageFaqsMv(): useLayoutEffect()");
    }, []);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
            <div className={clsx(classes.main, classes.divPadding)}>
                {/* <StyledPaper elevation={3} className={classes.paper}> */}
                    <Grid container justify="center">
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h5" color="primary" style={{ textAlign: "center" }}>
                                {manageFaqsLabel}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                                {requiredFields}
                            </Typography>
                        </Grid>

                        {globalErrorFlag && (
                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={24} />
                                <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={12}>
                            <LineSpacing value={24} />
                        </Grid>
                        
                        <Grid item xs={12} sm={12}>
                            <Grid container justify="center">
                                {showListFlag &&
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                *{languageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="languageId"
                                                            value={language}
                                                            options={languages}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleListLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={languagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={languageErrFlag}
                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                *{domainLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="domainId"
                                                            value={domain}
                                                            options={domainOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleListDomainChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            // disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={domainPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={domainErrFlag}
                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            {faqListRecords && faqListRecords.length > 0 ?
                                                <>
                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={12} />
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container justify="center">
                                                            <Grid item xs={4} sm={4}>
                                                                <Grid container justify="center">
                                                                    <Tooltip title={convertLabel} placement="bottom">
                                                                        <IconButton color="primary" aria-label={convertLabel} component="span" onClick={convertAction}>
                                                                            <TranslateIcon className={clsx(classes.iconLarge)} aria-label={convertLabel}/>
                                                                        </IconButton>
                                                                    </Tooltip>

                                                                    {/* <Button variant="contained" size="small" disabled={false} color="primary" onClick={convertAction}>
                                                                        <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {convertLabel}
                                                                    </Button> */}
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={4} sm={4}>
                                                                <Grid container justify="center">
                                                                    <Tooltip title={organizeLabel} placement="bottom">
                                                                        <IconButton color="primary" aria-label={organizeLabel} component="span" onClick={orderListAction}>
                                                                            <ImportExportIcon className={clsx(classes.iconLarge)} aria-label={organizeLabel}/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    {/* <Button variant="outlined" size="small" disabled={false} color="primary" onClick={orderListAction}>
                                                                        <ImportExportIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {organizeLabel}
                                                                    </Button> */}
                                                                </Grid>
                                                            </Grid>

                                                            <Grid item xs={4} sm={4}>
                                                                <Grid container justify="flex-end">
                                                                    <Tooltip title={createLabel} placement="bottom">
                                                                        <IconButton color="primary" aria-label={createLabel} component="span" onClick={createMoreAction}>
                                                                            <AddIcon className={clsx(classes.iconLarge)} aria-label={createLabel}/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    {/* <Button variant="contained" size="small" disabled={false} color="primary" onClick={createMoreAction}>
                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {createLabel}
                                                                    </Button> */}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LineSpacing value={8} />
                                                    </Grid>
                                                    
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <List className={classes.listContainer}>
                                                                <div>
                                                                    {faqListRecords && faqListRecords.length > 0 &&
                                                                        faqListRecords.map((record, index) => (
                                                                            <Grid key={index} container justify="center">
                                                                                <ListItem alignItems="flex-start" disableGutters={true}>
                                                                                    <ListItemText style={{backgroundColor: (index % 2) === 0 ? '#F8F8FF' : 'inherit', padding: '8px'}}>
                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {gridCategory}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.FaqCategoryText}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {gridFaqQuestion}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.FaqQuestion}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        
                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {gridFaqAnswer}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.FaqAnswer}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="center">
                                                                                                <Grid item xs={3} sm={3}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {lastUpdateLabel}:
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item xs={9} sm={9}>
                                                                                                    <Typography variant="caption" style={{display: 'block'}}>
                                                                                                        {record.FormattedDateTime}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        
                                                                                        <Grid item xs={12} sm={12}>
                                                                                            <Grid container justify="flex-end">
                                                                                                <Grid item style={{paddingRight: theme.spacing(3)}}>
                                                                                                    <DeleteIcon color="secondary" onClick={() => showAlertDialog(record, "single")}/>
                                                                                                </Grid>

                                                                                                <Grid item>
                                                                                                    <EditIcon color="primary" onClick={() => editRecordAction(record)}/>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </ListItemText>
                                                                                </ListItem>
                                                                            </Grid>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </List>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            :
                                                <>
                                                    {noRecordFoundFlag &&
                                                        <Grid container justify="center">
                                                            <Grid item xs={12} sm={12}>
                                                                <Typography variant="body1" color="secondary">
                                                                    {noRecordsFound}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid item xs={12} sm={12}>
                                                                <LineSpacing value={12} />
                                                            </Grid>

                                                            <Grid item xs={12} sm={12}>
                                                                <Button variant="contained" size="large" disabled={false} color="primary" onClick={createFaqAction}>
                                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                    {createFaqLabel}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                }

                                {showDetailsFormFlag && 
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-end">
                                                <Tooltip title={closeLabel} placement="bottom">
                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-start">
                                                <Typography variant="h6" color="primary">
                                                    {editModeFlag ? updateFaqLabel : createFaqLabel}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                *{languageLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="languageId"
                                                            value={language}
                                                            options={languages}
                                                            getOptionLabel={option => option.languageName}
                                                            getOptionSelected={option => option.languageName}
                                                            onChange={handleLanguageChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={languagePrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={languageErrFlag}
                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                *{domainLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="domainId"
                                                            value={domain}
                                                            options={domainOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleDomainChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={domainPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={domainErrFlag}
                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={faqCategoryErrFlag}>
                                                        <label htmlFor="faqCategoryId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={faqCategoryErrFlag ? "secondary" : "inherit"}>
                                                                *{faqCategoryLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="faqCategoryId"
                                                            value={faqCategory}
                                                            options={categoryOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleFaqCategoryChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={faqCategoryPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={faqCategoryErrFlag}
                                                                    helperText={faqCategoryErrFlag ? faqCategoryErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={faqQuestionErrFlag}>
                                                        <label htmlFor="faqQuestionId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={faqQuestionErrFlag ? "secondary" : "inherit"}>
                                                                *{faqQuestionLabel}
                                                            </Typography>
                                                        </label>

                                                        <TextField
                                                            id="faqQuestionId"
                                                            value={faqQuestion}
                                                            placeholder={faqQuestionPrompt}
                                                            onChange={handleChange}
                                                            required
                                                            fullWidth
                                                            type={"text"}
                                                            error={faqQuestionErrFlag}
                                                            margin="none"
                                                            variant="outlined"
                                                            helperText={faqQuestionErrFlag ? faqQuestionErrMsg : ""}
                                                            // InputProps={{
                                                            //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                            // }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={faqAnswerErrFlag}>
                                                                <label htmlFor="faqAnswerId" style={directionAlignment}>
                                                                    <Typography variant="subtitle1" color={faqAnswerErrFlag ? "secondary" : "inherit"}>
                                                                        {faqAnswerLabel}
                                                                    </Typography>
                                                                </label>

                                                                <TextareaAutosize
                                                                    id="faqAnswerId"
                                                                    aria-label={faqAnswerLabel}
                                                                    rows={1.5}
                                                                    rowsMax={6}
                                                                    value={faqAnswer}
                                                                    placeholder={faqAnswerPrompt}
                                                                    onChange={handleChange}
                                                                    required
                                                                    // autoFocus={true}
                                                                    margin="none"
                                                                    className={classes.textArea}
                                                                    maxLength={textMaxLength}
                                                                />
                                                                <FormHelperText>{faqAnswerErrFlag ? faqAnswerErrMsg : ""}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={buttonFormAction}>
                                                    {editModeFlag ?
                                                    <>
                                                        <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                        {updateFaqLabel} 
                                                    </>
                                                    : 
                                                    <>
                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                        {createFaqLabel}
                                                    </>
                                                    }
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                {convertFlag &&
                                    <>
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-end">
                                                <Tooltip title={closeLabel} placement="bottom">
                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-start">
                                                <Typography variant="h6" color="primary">
                                                    {convertFaqsLabel}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <FaqConverter 
                                                showHeadersFlag={false}
                                                referrer={"ManageFaqs"}
                                            />
                                        </Grid>
                                    </>
                                }

                                {orderListFlag &&
                                    <Container component="div" className={classes.main} maxWidth="md">
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-end">
                                                <Tooltip title={closeLabel} placement="bottom">
                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeOrderListForm}>
                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="flex-start">
                                                <Typography variant="h6" color="primary">
                                                    {organizeFaqListLabel}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={faqCategoryErrFlag}>
                                                        <label htmlFor="faqCategoryId" style={directionAlignment}>
                                                            <Typography variant="subtitle1" color={faqCategoryErrFlag ? "secondary" : "inherit"}>
                                                                *{faqCategoryLabel}
                                                            </Typography>
                                                        </label>

                                                        <Autocomplete
                                                            id="faqCategoryId"
                                                            value={faqCategory}
                                                            options={categoryOptions}
                                                            getOptionLabel={option => option.text}
                                                            getOptionSelected={option => option.text}
                                                            onChange={handleOrderListCategoryChange}
                                                            loadingText={loadingText}
                                                            noOptionsText={noOptionsText}
                                                            autoHighlight={true}
                                                            clearText={clearText}
                                                            closeText={closeText}
                                                            disabled={editModeFlag ? true : false}
                                                            renderInput={params => 
                                                                <TextField 
                                                                    {...params} 
                                                                    placeholder={faqCategoryPrompt} 
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={faqCategoryErrFlag}
                                                                    helperText={faqCategoryErrFlag ? faqCategoryErrMsg : ""}
                                                                    // InputProps={{
                                                                    //     readOnly: editModeFlag ? true : false,
                                                                    // }}
                                                                />
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>
                                                                                                                        
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center" style={{width: '100% !important'}}>
                                                <div style={{minWidth: '100%'}}>
                                                    <OrderList 
                                                        value={filteredRecords} 
                                                        header={freqAskedQuestionsLabel} 
                                                        dragdrop={dragdropFlag}
                                                        listStyle={{width: '100% !important', height:'auto'}} 
                                                        dataKey="FaqId"
                                                        itemTemplate={orderListTemplate} 
                                                        onChange={(e) => handleFilteredRecords(e)}
                                                        style={{width: '100% !important', display: 'flex'}}
                                                        >
                                                    </OrderList>
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={organizeService}>
                                                    <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                    {saveLabel} 
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                }

                                <Grid item xs={12} sm={12}>
                                    <LineSpacing value={18} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                {/* </StyledPaper> */}
            </div>

            <Grid item xs={12} sm={12}>
                <LineSpacing value={48} />
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

export default ManageFaqsMv;
