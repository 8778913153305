import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
// import { Redirect } from 'react-router-dom';
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
// import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
// import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import clsx from "clsx";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import GlobalConstants from "../../utils/GlobalConstants";
import SetCacheItem from "../../utils/cache/SetCacheItem";
// import ClearCacheItems from '../../utils/cache/ClearCacheItems';
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

// import ForgotPassword from './ForgotPassword';
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import GlobalInfoMessage from "../../components/dialogs/GlobalInfoMessage";
import LineSpacing from "../../components/misc/LineSpacing";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 4px 0 2px 0 !important;
`;

const StyledButton = styled(Button)`
  background: transparent;
  border-radius: 3px;
  border: 2px solid palevioletred;
  color: palevioletred;
  margin-bottom: 12px !important;

  /* When the "primary" property is set we want to add some more css to our component */
  /* in this case change the background and color. */

  ${(props) =>
    props.color === "primary" &&
    css`
      background: green !important;
      color: white !important;
      margin: 0 20px 12px 0 !important;
    `};
`;

const StyledTypography = styled(Typography)`
  text-align: right !important;
  color: green;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    // backgroundColor: '#fff',
  },
  main: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    // backgroundColor: '#fff',
  },
  mainForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "20px 40px 20px 40px !important",
    textAlign: "center",
  },
  textField: {
    width: "100%",
  },
  progress: {
    margin: theme.spacing(2),
  },
}));

let forgotPw = {
  // verificationCodeHdr: "Verify code",
  verificationCodeSubHdr:
    "Verify the code you received to reset your password.",
  verificationCodeLabel: "Verification code",
  // verifyCode: "Verify code",
  invalidUsername: "Invalid or unknown username (email).",
  resetPassword: "Reset password",
  cancel: "Cancel",
  newPasswordLabel: "New password",
  verifyNewPasswordLabel: "Verify new password",
  verifyNewPasswordFailed: "Could not verify new password.",
  enterCorrectPassword: "Please enter a valid password.",
  passwordConstraintsNotMet: "Password constraints are not met.",
  // enterVerificationCode: "Please enter verification code",
  enterReceivedVeriCode: "Please enter verification code you received",
  // usernameTaken: "Username has been taken.",
  resendCode: "Resend verification code",
  verificationCodeSent: "We just sent you a new verification code.",
  // alreadyConfirmed: "You are already confirmed.",
  // limitTrialExceeded: "Attempt limit exceeded, please try later.",
  // usernameLabel: "Username",
  // enterCorrectUsername: "Please enter a valid email address.",
  codeMismatch: "Sorry!!! you entered the wrong code.",
  emailValidationCodeMsg:
    "We sent a validation code to the email you entered. Please check your email and verify the code you received in the form below." +
    " Also, enter a new password.",
  limitExceededException: "Attempt limit exceeded, please try after some time.",
  toggleNewPassword: "Toggle new password visibility",
  toggleVerifyPassword: "Toggle verify new password visibility",
};

function ForgotPassword(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const [forgotPwRes, setForgotPwRes] = useState(forgotPw);

  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  const [username, setUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [verifyNewPassword, setVerifyNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showVerifyNewPassword, setShowVerifyNewPassword] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  const [resendCodeFlag, setResendCodeFlag] = useState(false);
  const [forgotPasswordFlag, setForgotPasswordFlag] = useState(false);

  // const { currentLanguage, isSignedIn } = props;

  // For Error Flags
  const [newPasswordErrFlag, setNewPasswordErrFlag] = useState(false);
  const [newPasswordFocus, setNewPasswordFocus] = useState(false);
  const [verifyNewPasswordErrFlag, setVerifyNewPasswordErrFlag] = useState(
    false
  );
  const [verifyNewPasswordFocus, setVerifyNewPasswordFocus] = useState(false);
  const [verificationCodeErrFlag, setVerificationCodeErrFlag] = useState(false);
  const [verificationCodeFocus, setVerificationCodeFocus] = useState(false);
  const [progressFlag, setProgressFlag] = useState(false);

  // Variants
  const [verificationCodeVariant, setVerificationCodeVariant] = useState(null);
  const [resetPasswordButtonFlag, setResetPasswordButtonFlag] = useState(false);

  // For New Password
  const [newPwVariant, setNewPwVariant] = useState(null);
  const [newPwType, setNewPwType] = useState("password");
  const [newPwVisibilityIcon, setNewPwVisibilityIcon] = useState(null);

  // For Verify New Password
  const [verifyNewPwVariant, setVerifyNewPwVariant] = useState(null);
  const [verifyNewPwType, setVerifyNewPwType] = useState("password");
  const [verifyNewPwVisibilityIcon, setVerifyNewPwVisibilityIcon] = useState(
    null
  );

  const [showPassword, setShowPassword] = useState(false);

  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const {
    // verificationCodeHdr,
    verificationCodeSubHdr,
    verificationCodeLabel,
    // verifyCode,
    invalidUsername,
    resetPassword,
    cancel,
    newPasswordLabel,
    verifyNewPasswordLabel,
    verifyNewPasswordFailed,
    enterCorrectPassword,
    passwordConstraintsNotMet,
    // enterVerificationCode,
    enterReceivedVeriCode,
    // usernameTaken,
    resendCode,
    verificationCodeSent,
    // alreadyConfirmed,
    // limitTrialExceeded,
    // usernameLabel,
    // enterCorrectUsername,
    codeMismatch,
    emailValidationCodeMsg,
    limitExceededException,
    toggleNewPassword,
    toggleVerifyPassword,
  } = forgotPwRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(forgotPw);
    // console.log("In ForgotPassword(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ForgotPassword(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
        // "queryType": "hash-query",
        // "resourcePK": currentLanguage.languageCode,
        // "keyConditionExpression": "#resourcePK = :resourcePK",
        // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
        // "expressionAttributeValues": {":resourcePK": currentLanguage.languageCode}
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            // id: ""+index,
            // ResourceLanguageCode: item.ResourceLanguageCode,
            // ResourcePK: item.ResourcePK,
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
            // ModifiedBy: item.ModifiedBy,
            // CreatedAt: item.CreatedAt,
            // UpdatedAt: item.UpdatedAt,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "verificationCodeSubHdr")
              forgotPw.verificationCodeSubHdr = item.ResourceDesc;
            if (item.ResourceCode === "verificationCodeLabel")
              forgotPw.verificationCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "invalidUsername")
              forgotPw.invalidUsername = item.ResourceDesc;
            if (item.ResourceCode === "resetPassword")
              forgotPw.resetPassword = item.ResourceDesc;
            if (item.ResourceCode === "newPasswordLabel")
              forgotPw.newPasswordLabel = item.ResourceDesc;
            if (item.ResourceCode === "verifyNewPasswordLabel")
              forgotPw.verifyNewPasswordLabel = item.ResourceDesc;
            if (item.ResourceCode === "verifyNewPasswordFailed")
              forgotPw.verifyNewPasswordFailed = item.ResourceDesc;
            if (item.ResourceCode === "enterCorrectPassword")
              forgotPw.enterCorrectPassword = item.ResourceDesc;
            if (item.ResourceCode === "passwordConstraintsNotMet")
              forgotPw.passwordConstraintsNotMet = item.ResourceDesc;
            if (item.ResourceCode === "enterReceivedVeriCode")
              forgotPw.enterReceivedVeriCode = item.ResourceDesc;
            if (item.ResourceCode === "resendCode")
              forgotPw.resendCode = item.ResourceDesc;
            if (item.ResourceCode === "verificationCodeSent")
              forgotPw.verificationCodeSent = item.ResourceDesc;
            if (item.ResourceCode === "codeMismatch")
              forgotPw.codeMismatch = item.ResourceDesc;
            if (item.ResourceCode === "emailValidationCodeMsg")
              forgotPw.emailValidationCodeMsg = item.ResourceDesc;
            if (item.ResourceCode === "limitExceededException")
              forgotPw.limitExceededException = item.ResourceDesc;
            if (item.ResourceCode === "toggleNewPassword")
              forgotPw.toggleNewPassword = item.ResourceDesc;
            if (item.ResourceCode === "toggleVerifyPassword")
              forgotPw.toggleVerifyPassword = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, forgotPw);
          setForgotPwRes(forgotPw);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setForgotPwRes(forgotPw);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    const { username } = props;
    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In ForgotPassword(): currentLanguage is: ', currentLanguage);
    // if (currentLanguage !== "en"){
    //   SetCacheItem("msg", msg);
    // }
    //msg = GetCacheItem("msg");
    //console.log('In ForgotPassword(): useEffect(): VerificationCode is: ', JSON.stringify(msg, null, 2));

    setNewPasswordFocus(false);
    setVerificationCodeFocus(true);

    if (username && username !== null && username !== "") {
      setUsername(username);
    }

    // setNewPwVisibilityIcon(newPwIconVisibility);
    // setVerifyNewPwVisibilityIcon(verifyNewPwIconVisibility);
  }, []);

  useLayoutEffect(() => {
    // console.log("In ForgotPassword(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "forgotPw";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ForgotPassword(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ForgotPassword(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ForgotPassword(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ForgotPassword(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ForgotPassword(): useLayoutEffect(): got resource from cache');
      setForgotPwRes(cacheItem);
    }

    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In ForgotPassword(): useLayoutEffect(): currentLanguage is: ', JSON.stringify(currentLanguage, null, 2));
    // console.log('In ForgotPassword(): useLayoutEffect(): direction is: ', direction);
    // console.log('In ForgotPassword(): useLayoutEffect(): appContext.signedIn is: ', appContext.signedIn);
  }, []);

  function handleChange(event) {
    // console.log("In handleChange(): id is: ", event.target.id);
    //console.log("In handleChange(): tagName is: "+event.target.tagName);
    // console.log("In handleChange(): value is: ", event.target.value);

    setGlobalErrorFlag(false);
    setResetPasswordButtonFlag(false);

    setVerificationCodeVariant("danger");
    setNewPwVariant("danger");
    setVerifyNewPwVariant("danger");

    switch (event.target.id) {
      case "verificationCodeId":
        setResendCodeFlag(false);
        setVerificationCodeErrFlag(false);
        setVerificationCode(event.target.value);

        // Enable button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          newPassword !== undefined &&
          newPassword !== null &&
          newPassword !== "" &&
          verifyNewPassword !== undefined &&
          verifyNewPassword !== null &&
          verifyNewPassword !== ""
        ) {
          setResetPasswordButtonFlag(true);
        }
        break;
      case "newPasswordId":
        setNewPasswordErrFlag(false);
        setNewPassword(event.target.value);

        // Enable button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          verifyNewPassword !== undefined &&
          verifyNewPassword !== null &&
          verifyNewPassword !== "" &&
          verificationCode !== undefined &&
          verificationCode !== null &&
          verificationCode !== ""
        ) {
          setResetPasswordButtonFlag(true);
        }
        break;
      case "verifyNewPasswordId":
        setVerifyNewPasswordErrFlag(false);
        setVerifyNewPassword(event.target.value);

        // Enable button
        if (
          event.target.value !== undefined &&
          event.target.value !== null &&
          event.target.value !== "" &&
          newPassword !== undefined &&
          newPassword !== null &&
          newPassword !== "" &&
          verificationCode !== undefined &&
          verificationCode !== null &&
          verificationCode !== ""
        ) {
          setResetPasswordButtonFlag(true);
        }
        break;
      default:
        break;
    }
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function handleClickShowNewPassword() {
    setShowNewPassword(!showNewPassword);
  }

  function handleClickShowVerifyNewPassword() {
    setShowVerifyNewPassword(!showVerifyNewPassword);
  }

  function validateForm() {
    let constraintErrFlag = false;
    let formErrFlag = false;

    setNewPasswordErrFlag(false);
    setVerificationCodeErrFlag(false);

    if (newPassword === null || newPassword === "") {
      formErrFlag = true;
      setNewPasswordErrFlag(true);
    } else if (!newPassword.match(GlobalConstants.passwordRegex)) {
      constraintErrFlag = true;
      formErrFlag = true;
      setNewPasswordErrFlag(true);
    }

    if (verifyNewPassword === null || verifyNewPassword === "") {
      formErrFlag = true;
      setVerifyNewPasswordErrFlag(true);
    } else if (!verifyNewPassword.match(GlobalConstants.passwordRegex)) {
      constraintErrFlag = true;
      formErrFlag = true;
      setVerifyNewPasswordErrFlag(true);
    }

    if (verifyNewPassword !== newPassword) {
      formErrFlag = true;
      setVerifyNewPasswordErrFlag(true);
    }

    if (verificationCode === null || verificationCode === "") {
      formErrFlag = true;
      setVerificationCodeErrFlag(true);
    }

    // Check for Constraints Error
    if (constraintErrFlag) {
      setGlobalErrorFlag(true);
      setGlobalErrorMsg(passwordConstraintsNotMet);
    }

    return formErrFlag;
  }

  async function forgotPasswordService() {
    // console.log("In forgotPasswordService(): username is: ", username);
    // console.log("In forgotPasswordService(): verificationCode is: ", verificationCode);
    // console.log("In forgotPasswordService(): newPassword is: ", newPassword);
    // console.log("In forgotPasswordService(): verifyNewPassword is: ", verifyNewPassword);

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");
    setForgotPasswordFlag(false);

    // await SetCacheItem("isSignedIn", true);
    // await console.log("In forgotPasswordService(): isSignedIn is: "+GetCacheItem("isSignedIn"));

    if (!validateForm()) {
      // Validation Passed - you can proceed
      // console.log("Validation Passed.");
      setProgressFlag(true);

      Auth.forgotPasswordSubmit(username, verificationCode, newPassword)
        .then((result) => {
          // console.log('In forgotPasswordService(): result is', JSON.stringify(result, null, 2));
          setForgotPasswordFlag(true);
          setProgressFlag(false);
        })
        .catch((err) => {
          console.log(
            "In forgotPasswordService(): err is",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setProgressFlag(false);
          switch (err.code) {
            case "CodeMismatchException":
              setGlobalErrorMsg(codeMismatch);
              break;
            default:
              setGlobalErrorMsg(codeMismatch);
              break;
          }
        });
    }
  }

  async function resendVerificationCode() {
    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");
    setResendCodeFlag(false);

    Auth.forgotPassword(username)
      .then((result) => {
        // console.log('In resendVerificationCode(): result is', JSON.stringify(result, null, 2));
        setResendCodeFlag(true);
        setVerificationCode("");
        // setForgotPasswordFlag(true);
      })
      .catch((err) => {
        console.log(
          "In resendVerificationCode(): err is",
          JSON.stringify(err, null, 2)
        );
        setGlobalErrorFlag(true);
        switch (err.code) {
          case "UserNotFoundException":
            setGlobalErrorMsg(invalidUsername);
            break;
          case "LimitExceededException":
            setGlobalErrorMsg(limitExceededException);
            break;
          default:
            setGlobalErrorMsg(invalidUsername);
            break;
        }
      });
  }

  return (
    <div dir={direction}>
      <div className={classes.root}>
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%" }}>
            {progressFlag && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                  size={15}
                />
              </div>
            )}

            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="md"
            >
              {forgotPasswordFlag && <ForgotPassword />}

              <Container
                component="div"
                className={classes.mainForm}
                maxWidth="sm"
              >
                <Grid container justify="center">
                  <Typography variant="caption" style={{ textAlign: "center" }}>
                    {emailValidationCodeMsg}
                  </Typography>
                </Grid>
              </Container>

              {!forgotPasswordFlag && (
                <Grid container justify="center">
                  <Grid item xs={12} sm={9}>
                    <StyledPaper elevation={3} className={classes.paper}>
                      <Typography
                        variant="h5"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        {resetPassword}
                      </Typography>

                      <Typography
                        variant="caption"
                        style={{ color: "green", textAlign: "center" }}
                      >
                        {verificationCodeSubHdr}
                      </Typography>

                      {globalErrorFlag && (
                        <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                      )}

                      <LineSpacing value={12} />

                      <FormControl
                        className={classes.textField}
                        variant="outlined"
                      >
                        <label
                          htmlFor="verificationCodeId"
                          style={directionAlignment}
                        >
                          <Typography variant="subtitle1">
                            {verificationCodeLabel}
                          </Typography>
                        </label>
                        <OutlinedInput
                          id="verificationCodeId"
                          value={verificationCode}
                          placeholder={enterReceivedVeriCode}
                          onChange={handleChange}
                          required
                          fullWidth
                          type={"text"}
                          error={verificationCodeErrFlag}
                          margin="none"
                        />
                      </FormControl>

                      <LineSpacing value={12} />

                      {/* For New Password */}

                      <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                        error={newPasswordErrFlag}
                      >
                        <label
                          htmlFor="newPasswordId"
                          style={directionAlignment}
                        >
                          <Typography
                            variant="subtitle1"
                            color={newPasswordErrFlag ? "error" : "inherit"}
                          >
                            {newPasswordLabel}
                          </Typography>
                        </label>
                        <OutlinedInput
                          id="newPasswordId"
                          type={showPassword ? "text" : "password"}
                          value={newPassword}
                          placeholder={enterCorrectPassword}
                          onChange={handleChange}
                          error={newPasswordErrFlag}
                          required
                          fullWidth
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle newPassword visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {newPasswordErrFlag && (
                          <FormHelperText id="newPassword-helper-text">
                            {passwordConstraintsNotMet}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <LineSpacing value={12} />

                      {/* For Verify New Password */}

                      <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        variant="outlined"
                        error={verifyNewPasswordErrFlag}
                      >
                        <label
                          htmlFor="verifyNewPasswordId"
                          style={directionAlignment}
                        >
                          <Typography
                            variant="subtitle1"
                            color={
                              verifyNewPasswordErrFlag ? "error" : "inherit"
                            }
                          >
                            {verifyNewPasswordLabel}
                          </Typography>
                        </label>
                        <OutlinedInput
                          id="verifyNewPasswordId"
                          type={showPassword ? "text" : "password"}
                          value={verifyNewPassword}
                          placeholder={enterCorrectPassword}
                          onChange={handleChange}
                          error={verifyNewPasswordErrFlag}
                          required
                          fullWidth
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle verifyNewPassword visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {verifyNewPasswordErrFlag && (
                          <FormHelperText id="verifyNewPassword-helper-text">
                            {passwordConstraintsNotMet}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <LineSpacing value={24} />

                      <Button
                        variant="contained"
                        size="large"
                        disabled={!resetPasswordButtonFlag ? true : false}
                        color="primary"
                        className={classes.button}
                        onClick={forgotPasswordService}
                      >
                        <LockOutlinedIcon
                          className={clsx(classes.leftIcon, classes.iconSmall)}
                        />
                        {resetPassword}
                      </Button>

                      <LineSpacing value={12} />

                      <Grid container justify="center">
                        {resendCodeFlag && (
                          <GlobalInfoMessage
                            globalInfoMsg={verificationCodeSent}
                            justify="center"
                            color="primary"
                          />
                        )}

                        <StyledTypography
                          variant="caption"
                          color="primary"
                          onClick={resendVerificationCode}
                          style={directionAlignment}
                        >
                          {resendCode}
                        </StyledTypography>
                      </Grid>

                      <LineSpacing value={12} />
                    </StyledPaper>
                  </Grid>
                </Grid>
              )}
            </Container>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ForgotPassword;
