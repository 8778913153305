import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import PinterestIcon from "@material-ui/icons/Pinterest";
import RedditIcon from "@material-ui/icons/Reddit";

const SocialIcon = (props) => {
  const { name, iconColor } = props;

  const useStyles = makeStyles((theme) => ({
    socialIcons: {
      color: iconColor ? iconColor : "#fff",
    },
  }));

  const classes = useStyles();

  // console.log("In SocialIcon(): name is: ", name);
  // console.log("In SocialIcon(): iconColor is: ", iconColor);

  switch (name) {
    case "facebook":
      return (
        <>
          <FacebookIcon className={classes.socialIcons} />
        </>
      );
    case "twitter":
      return (
        <>
          <TwitterIcon className={classes.socialIcons} />
        </>
      );
    case "youtube":
      return (
        <>
          <YouTubeIcon className={classes.socialIcons} />
        </>
      );
    case "instagram":
      return (
        <>
          <InstagramIcon className={classes.socialIcons} />
        </>
      );
    case "linkedin":
      return (
        <>
          <LinkedInIcon className={classes.socialIcons} />
        </>
      );
    case "reddit":
      return (
        <>
          <RedditIcon className={classes.socialIcons} />
        </>
      );
    case "pinterest":
      return (
        <>
          <PinterestIcon className={classes.socialIcons} />
        </>
      );
    default:
      return <></>;
  }
  return <></>;
};

export default SocialIcon;
