import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
// import Typography from '@material-ui/core/Typography';
import clsx from "clsx";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";

// Validator
// import validator from 'validator';
import isEmail from "validator/lib/isEmail";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../misc/TsiCustomizedSnackbars";
// import LineSpacing from '../../components/misc/LineSpacing';

Amplify.configure(AmplifyConfig);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
  },
  googlePlayBadgeImage: {
    width: "156px",
  },
  appStoreBadgeGrid: {
    // marginTop: '10px',
    // background: 'red',
  },
  googlePlayBadgeGrid: {
    // marginTop: '10px',
    // background: 'green',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    // margin: theme.spacing(1),
  },
}));

let joinListLabels = {
  mailEmailLabel: "Join our mailing list",
  mailEmailPrompt: "Enter your email address",
  mailEmailErrMsg: "Please enter a correct email address",
  submit: "Submit",

  joinMailSuccessMsg: "You have successfully joined our mailing list.",
  joinMailFailureMsg: "Error while processing your request.",
};

function JoinMailingList(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  const [prevLanguageCode, setPrevLanguageCode] = useState(
    appContext.currentLanguage.languageCode
  );
  const [progressFlag, setProgressFlag] = useState(false);

  const [joinListLabelsRes, setJoinListLabelsRes] = useState(joinListLabels);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  const [mailEmail, setMailEmail] = useState("");
  const [submitFlag, setSubmitFlag] = useState(false);
  const [mailEmailErrFlag, setMailEmailErrFlag] = useState(false);
  const [mailEmailFocus, setMailEmailFocus] = useState(true);

  // Variants
  const [mailEmailVariant, setMailEmailVariant] = useState(null);

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  const { currLanguage } = props;

  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const {
    mailEmailLabel,
    mailEmailPrompt,
    mailEmailErrMsg,
    submit,
    joinMailSuccessMsg,
    joinMailFailureMsg,
  } = joinListLabelsRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(joinListLabels);
    // console.log("In ContactInfo(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ContactInfo(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "mailEmailLabel")
              joinListLabels.mailEmailLabel = item.ResourceDesc;
            if (item.ResourceCode === "mailEmailPrompt")
              joinListLabels.mailEmailPrompt = item.ResourceDesc;
            if (item.ResourceCode === "mailEmailErrMsg")
              joinListLabels.mailEmailErrMsg = item.ResourceDesc;
            if (item.ResourceCode === "submit")
              joinListLabels.submit = item.ResourceDesc;
            if (item.ResourceCode === "joinMailSuccessMsg")
              joinListLabels.joinMailSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "joinMailFailureMsg")
              joinListLabels.joinMailFailureMsg = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, joinListLabels);
          setJoinListLabelsRes(joinListLabels);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setJoinListLabelsRes(joinListLabels);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In CompanyFooter(): useEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));
    //-------------------------------
    // Handle Label Resources
    //-------------------------------

    if (
      currLanguage !== undefined &&
      currLanguage !== null &&
      currLanguage !== currentLanguage
    ) {
      setPrevLanguageCode(currentLanguage.languageCode); // for manipulating icons
      setCurrentLanguage(currLanguage);
      appContext.currentLanguage = currLanguage;

      // Fetch resources from catche
      let appsInfoLabelsKey = "appsInfoLabels";
      appsInfoLabelsKey += ":";
      appsInfoLabelsKey += appContext.currentLanguage.languageCode;

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        RemoveCacheItem(appsInfoLabelsKey);
      }

      let cacheLabelItems = GetCacheItem(appsInfoLabelsKey);
      // console.log('In CompanyFooter(): useEffect(): cacheLabelItems is: ', cacheLabelItems);

      if (cacheLabelItems === null) {
        // Fetch labels
        fetchDbResources(appsInfoLabelsKey);
      } else {
        // console.log('In CompanyFooter(): useEffect(): got resource from cache');
        setJoinListLabelsRes(cacheLabelItems);
      }
    }
  });

  useLayoutEffect(() => {
    // console.log("In CompanyFooter(): useLayoutEffect()");
    let langCode = appContext.currentLanguage.languageCode;

    //-------------------------------
    // Handle Label Resources
    //-------------------------------

    let cacheLabelsKey = "appsInfoLabels";
    cacheLabelsKey += ":";
    cacheLabelsKey += langCode;
    // console.log('In CompanyFooter(): useLayoutEffect(): cacheLabelsKey is: ', cacheLabelsKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(langCode)
    ) {
      // console.log("In CompanyFooter(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheLabelsKey);
    }

    let cacheLabelItems = GetCacheItem(cacheLabelsKey);
    // console.log('In CompanyFooter(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheLabelItems === null) {
      // Fetch labels
      fetchDbResources(cacheLabelsKey);
    } else {
      // console.log('In CompanyFooter(): useLayoutEffect(): got resource from cache');
      setJoinListLabelsRes(cacheLabelItems);
    }
  }, []);

  function initializeForm() {
    // console.log("In initializeForm()");

    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setMailEmail("");

    setMailEmailFocus(true);
  }

  function handleChange(event) {
    // console.log("In handleChange(): id is: "+event.target.id);
    // console.log("In handleChange(): value is: "+event.target.value);
    setGlobalErrorFlag(false);
    setSubmitFlag(false);

    // Variants
    setMailEmailErrFlag(false);
    setMailEmailVariant("");

    setMailEmail(event.target.value);

    if (
      event.target.value !== undefined &&
      event.target.value !== null &&
      event.target.value !== ""
    ) {
      setSubmitFlag(true);
    }
  }

  function validateForm() {
    let formErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    // Variants
    setMailEmailVariant("");

    if (mailEmail === null || mailEmail === "") {
      formErrFlag = true;
      setMailEmailErrFlag(true);
    } else if (!isEmail(mailEmail)) {
      formErrFlag = true;
      setMailEmailErrFlag(true);
      setMailEmailVariant("danger");
      setGlobalErrorMsg(mailEmailErrMsg);
    }

    if (formErrFlag) {
      setGlobalErrorFlag(true);
    }
    return formErrFlag;
  }

  async function joinMailService() {
    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    const rowsData = [];

    if (!validateForm()) {
      // const d = new Date();
      const timestamp = new Date();
      // const timestamp = d.toISOString();
      // console.log("In joinMailService(): timestamp is: ", timestamp);

      let rowData = {
        ResourcePK: "mail-list", // mail-list
        ResourceSK: currentLanguage.languageCode + ":" + mailEmail,
        LanguageCode: currentLanguage.languageCode,

        Email: mailEmail,

        ModifiedBy: appContext.userName,
        CreatedAt: timestamp,
        UpdatedAt: timestamp,
      };

      rowsData.push(rowData);

      let params = {
        apiName: "ResourceAPI",
        path: "/dynamodb/resources/batchWrite",
        payload: {
          items: rowsData,
        },
      };

      let initObj = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        response: true, // return entire response object instead of response.data
        body: {},
      };

      initObj.headers.Authorization = appContext.jwtToken;
      initObj.body = params.payload;
      // console.log('In joinMailService(): initObj is: ', JSON.stringify(initObj, null, 2));

      setProgressFlag(true);

      API.post(params.apiName, params.path, initObj)
        .then((response) => {
          // console.log('In joinMailService(): response is: ', JSON.stringify(response, null, 2));

          // Show Snackbar
          setSnackbarsVariant("success");
          setSnackbarsMsg(joinMailSuccessMsg);
          setSnackbarOpen(true);

          setProgressFlag(false);
          initializeForm();
        })
        .catch((err) => {
          console.log(
            "In joinMailService(): err is: ",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setGlobalErrorMsg(joinMailFailureMsg);

          setProgressFlag(false);
        });
    }
  }

  function snackbarOnClose(event, reason) {
    setSnackbarOpen(false);
  }

  return (
    <div dir={direction}>
      <div style={{ textAlign: "center" }}>
        <CssBaseline />

        {globalErrorFlag && (
          <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
        )}

        <Grid container alignContent="center" alignItems="center">
          <Grid item xs={12} xm={12} style={{ textAlign: "center" }}>
            <Grid container alignContent="center" alignItems="center">
              <Grid item xs={6} xm={6} className={classes.googlePlayBadgeGrid}>
                <FormControl className={classes.textField} variant="outlined">
                  <label htmlFor="mailEmailId" style={directionAlignment}>
                    <Typography variant="subtitle1">
                      {mailEmailLabel}
                    </Typography>
                  </label>
                  <OutlinedInput
                    id="mailEmailId"
                    value={mailEmail}
                    placeholder={mailEmailPrompt}
                    onChange={handleChange}
                    required
                    fullWidth
                    // autoFocus
                    type={"text"}
                    error={mailEmailErrFlag}
                    margin="none"
                  />
                </FormControl>

                {/* { !mailEmailErrFlag &&
                                    <FormField label={mailEmailLabel}>
                                    <TextInput
                                        id="mailEmailId"
                                        value={mailEmail}
                                        onChange={handleChange} 
                                        placeholder={mailEmailPrompt}
                                        disabled={false}
                                        readOnly={false} 
                                        size={"medium"}
                                        variant={"success"}
                                        type="email"
                                    />
                                    </FormField>
                                }

                                { mailEmailErrFlag &&
                                    <FormField label={mailEmailLabel} caption={""} variant={mailEmailVariant}>
                                    <TextInput
                                        id="mailEmailId"
                                        value={mailEmail}
                                        onChange={handleChange} 
                                        placeholder={mailEmailPrompt}
                                        disabled={false}
                                        readOnly={false} 
                                        size={"medium"}
                                        variant={"success"}
                                        type="email"
                                    />
                                    </FormField>
                                } */}
              </Grid>

              <Button
                variant="contained"
                size="large"
                disabled={!submitFlag ? true : false}
                color="primary"
                className={classes.button}
                onClick={joinMailService}
              >
                <SaveIcon
                  className={clsx(classes.leftIcon, classes.iconSmall)}
                />
                {submit}
              </Button>

              {/* { !submitFlag &&
                                    <Button variant="contained" size="medium" disabled color="secondary" className={classes.button}>
                                        <SaveIcon className={classes.leftIcon} />
                                        {submit}
                                    </Button>
                                }

                                { submitFlag &&
                                    <Button variant="contained" size="medium" color="primary" className={classes.button} onClick={joinMailService}>
                                        <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
                                        {submit}
                                    </Button>
                                } */}
            </Grid>
          </Grid>
        </Grid>
      </div>

      {/* variant: 'success', 'warning', 'error', 'info' */}
      <TsiCustomizedSnackbars
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={8000}
        onClose={snackbarOnClose}
        ContentProps={{ "aria-describedby": "message-id" }}
        message={<span id="message-id">{snackbarsMsg}</span>}
        variant={snackbarsVariant}
      />
    </div>
  );
}

export default JoinMailingList;
