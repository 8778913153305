import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
// import SaveIcon from "@material-ui/icons/Save";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from "@material-ui/core/Container";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import InputAdornment from "@material-ui/core/InputAdornment";
import TranslateIcon from '@material-ui/icons/Translate';
import Paper from "@material-ui/core/Paper";

// react-responsive
import { useMediaQuery } from 'react-responsive';

// moment
import moment from "moment";

// Lodash
import lodash from "lodash";

// react-media
// import Media from "react-media";

// Validator
// import validator from 'validator';
// import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";
// import isMobilePhone from "validator/lib/isMobilePhone";

//---------------------
// For Primereact
//---------------------

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

// For Primereact
import { Dialog } from 'primereact/dialog';

// Froala
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Amplify
import { API } from "aws-amplify";
// import { AmplifyTheme   } from 'aws-amplify-react';

// AppContext
import AppContext from "../../config/AppContext";

import LineSpacing from "../../components/misc/LineSpacing";
import FetchS3Object from "../../components/media/FetchS3Object";
import FroalaCommonEditor from "../../components/editor/FroalaCommonEditor";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import AlertDialog from "../../components/dialogs/AlertDialog";
import CommonMediaSelector from "../../components/dialogs/CommonMediaSelector";
import DomainUploadCapture from "../../components/upload/DomainUploadCapture";
import CommonConverter from "../../components/convert/CommonConverter";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GenerateUniqueName from "../../utils/GenerateUniqueName";
import GenerateUlid from "../../utils/GenerateUlid";
// import FormatDateTime from "../../../../../utils/moment/FormatDateTime";
import FormatTimezoneDates from "../../utils/moment/FormatTimezoneDates";
import FilterArrayFromArray from "../../utils/filter/FilterArrayFromArray";
import FormatNumber from "../../utils/numberFormat/FormatNumber";
import EditorPixelTransform from "../../utils/transform/EditorPixelTransform";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// Views
import BlogCaptureTv from "./views/BlogCaptureTv";
import BlogCaptureMv from "./views/BlogCaptureMv";

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    leftPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "left",
    },
    leftPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1rem" /* Footer height */,

        width: "100% !important",
        marginTop: "auto",
        // backgroundColor: '#fff',
        textAlign: "center",
    },
    paper: {
        minWidth: "300px",
        marginTop: theme.spacing(2),
        alignItems: "center",
        padding: "10px 30px 10px 30px !important",
        textAlign: "center",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    divPadding: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

let pageLabels = {
    manageBlogsLabel: "Manage blog postings",
    requiredFields: "* Required fields",

    languageLabel: "Language",
    languagePrompt: "Select language",
    languageErrMsg: "Language is required",

    domainLabel: "Domain",
    domainPrompt: "Select a domain",
    domainErrMsg: "Domain is required",

    blogBannerUrlLabel: "Blog banner URL",
    blogBannerUrlPrompt: "Select blog banner",
    blogBannerUrlErrMsg: "Blog banner is required",

    yourNamesLabel: "Your names",
    yourNamesPrompt: "Enter your full names",
    yourNamesErrMsg: "Your names are required",
    yourNamesMsg: "Names entered will appear in the blog",
    
    blogTitleLabel: "Blog title",
    blogTitlePrompt: "Please enter a title for your blog",
    blogTitleErrMsg: "Blog title is required",
    selectTitlePrompt: "Select title",

    blogTagsLabel: "Blog tags",
    blogTagsPrompt: "Enter blog tags separated by space",
    blogTagsErrMsg: "Blog tags are required",
    
    blogStartTimeLabel: "Blog start time",
    blogStartTimePrompt: "Select date & time to publish the blog",
    blogStartTimeErrMsg: "Blog start time is invalid",

    blogCategoryLabel: "Blog category",
    blogCategoryPrompt: "Select a blog category",
    blogCategoryErrMsg: "Blog category is required",

    // uploadBlogTitleLabel: "Blog title",
    // uploadBlogTitlePrompt: "Please select blog title",
    // uploadBlogTitleErrMsg: "Blog title is required",

    // liveEventUrlLabel: "Live event URL",
    // liveEventUrlPrompt: "Please enter the live event URL",
    // liveEventUrlErrMsg: "Live event URL is invalid",

    // liveEventUrlTypeLabel: "Live event URL type",
    // liveEventUrlTypePrompt: "Select live event URL type",
    // liveEventUrlTypeErrMsg: "Live event URL type is required",

    blogContentLabel: "Blog content",
    blogContentPrompt: "Please enter your blog content",
    // blogContentErrMsg: "Live event description is required",

    yourTimeZoneMsg: "Your timeZone is",
    // timeZoneLabel: "Time zone",
    cloneRecordLabel: "Clone record",

    blogRecordLabel: "blog record",
    blogRecordsLabel: "blog records",
    // inProgressLabel: "In progress",
    startLabel: "Start",
    endLabel: "End",
    notStartedLabel: "Not started",
    // dateCreatedLabel: "Date created",

    // statusLabel: "Status",
    editLabel: "Edit",
    deleteLabel: "Delete",

    toLabel: "to",
    ofLabel: "of",
    recordsLabel: "records",
    noRecordsFound: "No records found",

    operationSuccessMsg: "Operation was successful.",
    operationFailureMsg: "Operation failed.",
    duplicateTransMsg: "Duplicate record",

    loadingText: "Loading ...",
    noOptionsText: "No options",
    clearText: "Clear",
    closeText: "Close",
    // showLabel: "Show",
    // hideLabel: "Hide",

    cancelLabel: "Cancel",
    clearLabel: "Clear",
    okLabel: "Ok",
  
    createBlogContentLabel: "Create blog content",
    updateBlogContentLabel: "Update blog content",

    createMoreLabel: "Create more",
    createLabel: "Create",
    // editEventDestLabel: "Edit email template",
    closeLabel: "Close",
    noBlogRecordsFound: "No blog records found.", 
    startEventInfoMsg: "This event can't be started now.",    
    // createBlogContentLabel: "Create live event",
        
    gridNames: "Names",
    gridTitle: "Title",
    gridEventUrl: "Event URL",
    gridEventUrlType: "URL type",
    gridStartTime: "Start time",
    gridBannerUrl: "Banner URL",
    
    convertLabel: "Convert",

    sourceLanguageLabel: "Source language",
    sourceLanguagePrompt: "Select source language",
    sourceLanguageErrMsg: "Source language is required",

    targetLanguageLabel: "Target language",
    targetLanguagePrompt: "Select target language",
    targetLanguageErrMsg: "Target language is required",

    moreLabel: "- more -",

    uploadLabel: "Upload",
};

function BlogCapture() {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);
    const [renderDomFlag, setRenderDomFlag] = useState(false);
    const [progressFlag, setProgressFlag] = useState(false);

    // For Global Errors
    const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");

    // For Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarsMsg, setSnackbarsMsg] = useState("");
    const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

    //-------------------
    // Table Names
    //-------------------
    const tableName = "blog";

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    //--------------------
    // For Froala Editor
    //--------------------
    const editorRef = useRef(null);
    // const [editorModelData, setEditorModelData] = useState(null);
    const [editorMaxChars, setEditorMaxChars] = useState(10000);// Max chars allowed in editor

    //-------------------------
    // For Language and Domain
    //-------------------------
    const [language, setLanguage] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [languageErrFlag, setLanguageErrFlag] = useState(false);

    const [domain, setDomain] = useState(null);
    const [domainOptions, setDomainOptions] = useState([]);
    const [domainErrFlag, setDomainErrFlag] = useState(false);

    //--------------------
    // For Form Attributes
    //--------------------
    const [profilePhotoSrc, setProfilePhotoSrc] = useState(null);

    const [yourNames, setYourNames] = useState("");
    const [authorPhotoSrc, setAuthorPhotoSrc] = useState("");
    const [blogTitle, setBlogTitle] = useState("");
    const [blogTags, setBlogTags] = useState("");
    const [blogStartTime, setBlogStartTime] = useState(null);
    const [blogCategory, setBlogCategory] = useState(null);
    // const [liveEventUrl, setLiveEventUrl] = useState("");
    // const [liveEventUrlType, setLiveEventUrlType] = useState(null);
    const [blogContent, setBlogContent] = useState("");
    const [blogBannerUrl, setBlogBannerUrl] = useState("");
    
    // const [eventThumbnailUrl, setEventThumbnailUrl] = useState("");
    // const [eventBannerUrl, setEventBannerUrl] = useState("");
    // const [eventPosterUrl, setEventPosterUrl] = useState("");
    // const [eventAudioUrl, setEventAudioUrl] = useState("");
    
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
         
    const [blogBannerUrlErrFlag, setBlogBannerUrlErrFlag] = useState(false);
    const [yourNamesErrFlag, setYourNamesErrFlag] = useState(false);
    const [blogTitleErrFlag, setBlogTitleErrFlag] = useState(false);
    const [blogTagsErrFlag, setBlogTagsErrFlag] = useState(false);
    const [blogStartTimeErrFlag, setBlogStartTimeErrFlag] = useState(false);
    const [blogCategoryErrFlag, setBlogCategoryErrFlag] = useState(false);
    // const [liveEventUrlErrFlag, setLiveEventUrlErrFlag] = useState(false);
    // const [liveEventUrlTypeErrFlag, setLiveEventUrlTypeErrFlag] = useState(false);
    const [blogContentErrFlag, setBlogContentErrFlag] = useState(false);

    // const [liveEventUrlTypeOptions, setLiveEventUrlTypeOptions] = useState([]);
    // const [blogTagsOptions, setBlogTagsOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    
    const [blogRecordsBackup, setBlogRecordsBackup] = useState([]);
    const [blogRecords, setBlogRecords] = useState([]);

    const [submitButtonFlag, setSubmitButtonFlag] = useState(false);
    const [addTagButtonFlag, setAddTagButtonFlag] = useState(false);
    const [editModeFlag, setEditModeFlag] = useState(false);

    const [datePickerLanguage, setDatePickerLanguage] = useState(appContext.currentLanguage.languageCode);
    
    
    const [showBlogListFlag, setShowBlogListFlag] = useState(true);
    const [showBlogDetailsFormFlag, setShowBlogDetailsFormFlag] = useState(false);
    const [uploadFlag, setUploadFlag] = useState(false);
    const [convertFlag, setConvertFlag] = useState(false);
       
    const [pageSize, setPageSize] = useState(10000);// AWS put PageSize to a max 0f 100

    const [noBlogRecordFoundFlag, setNoBlogRecordFoundFlag] = useState(false);
    const [eventStartedFlag, setEventStartedFlag] = useState(false);
    const [startedEventId, setStartedEventId] = useState(null);
    
    const [textMaxLength, setTextMaxLength] = useState(256);

    //--------------------------
    // For sliceText and Dialog
    //--------------------------
    const [dialogData, setDialogData] = useState("");
    const [dialogHeader, setDialogHeader] = useState("");
    
    // const [terminologyNames, setTerminologyNames] = useState([
    //     "translate_custom_terminology.txt"
    // ]);
    

    //-------------------------
    // For Primereact 
    // DataTable Attributes 
    //-------------------------
    // const [ dataTableInfo, setDataTableInfo] = useState([]);
    // const [dataTableInfoFlag, setDataTableInfoFlag] = useState(false);
    
    // const [resizableColumnsFlag, setResizableColumnsFlag] = useState(true);
    // const [columnResizeMode, setColumnResizeMode] = useState("fit");
    // const [sortMode, setSortMode] = useState("multiple");
    // const [responsiveFlag, setResponsiveFlag] = useState(true);
    // const [reorderableColumnsFlag, setReorderableColumnsFlag] = useState(true);
    // const [paginatorFlag, setPaginatorFlag] = useState(true);
    const [paginatorTemplate, setPaginatorTemplate] = useState("FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown");
    // const [currentPageReportTemplate, setCurrentPageReportTemplate] = useState(null);
    const [visibleRowsCount, setVisibleRowsCount] = useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5,10,20,50,100]);

    // const [selectionMode, setSelectionMode] = useState("multiple");// single or multiple
    // const [selectedTableRow, setSelectedTableRow] = useState(null);// For single selection
    const [selectedTableRows, setSelectedTableRows] = useState([]);// For multiple selection
    // const [updatedTableRows, setUpdatedTableRows] = useState([]); // For updated (edited) table rows
    // const [deletedTableRows, setDeletedTableRows] = useState([]); // For deleted table rows
    const [tableDirtyFlag, setTableDirtyFlag] = useState(false); // To show DataTable cell was edited (modified)

    // const [deleteRowFlag, setDeleteRowFlag] = useState(false);

    //--------------------
    // For AlertDialog
    //--------------------
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogSubContent, setDialogSubContent] = useState(null);

    const [recordToDelete, setRecordToDelete] = useState(null);
    const [recordToEdit, setRecordToEdit] = useState(null);
    
    //-----------------------
    // For Primereact Dialog
    //-----------------------
    const [dlgVisibleFlag, setDlgVisibleFlag] = useState(false);
    
    // const {
    //     // editorId,
    // } = props;

    const theme = useTheme();
  
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    const { 
        manageBlogsLabel, 
        requiredFields,

        languageLabel,
        languagePrompt,
        languageErrMsg,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,
    
        blogBannerUrlLabel,
        blogBannerUrlPrompt,
        blogBannerUrlErrMsg,
            
        yourNamesLabel,
        yourNamesPrompt,
        yourNamesErrMsg,
        yourNamesMsg,
        
        blogTitleLabel,
        blogTitlePrompt,
        blogTitleErrMsg,
        selectTitlePrompt,

        blogTagsLabel,
        blogTagsPrompt,
        blogTagsErrMsg,
    
        blogStartTimeLabel,
        blogStartTimePrompt,
        blogStartTimeErrMsg,

        blogCategoryLabel,
        blogCategoryPrompt,
        blogCategoryErrMsg,

        // uploadBlogTitleLabel,
        // uploadBlogTitlePrompt,
        // uploadBlogTitleErrMsg,

        // liveEventUrlLabel,
        // liveEventUrlPrompt,
        // liveEventUrlErrMsg,

        // liveEventUrlTypeLabel,
        // liveEventUrlTypePrompt,
        // liveEventUrlTypeErrMsg,

        blogRecordsLabel,
        // inProgressLabel,
        startLabel,
        endLabel,
        notStartedLabel,
    
        // dateCreatedLabel,
    
        blogContentLabel,
        blogContentPrompt,
        // blogContentErrMsg,

        yourTimeZoneMsg,
        // timeZoneLabel,
        cloneRecordLabel,
    
        toLabel,
        ofLabel,
        recordsLabel,
        noRecordsFound,

        // statusLabel,
        editLabel,
        deleteLabel,

        operationSuccessMsg,
        operationFailureMsg,
        duplicateTransMsg,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,
        // showLabel,
        // hideLabel,

        cancelLabel,
        clearLabel,
        okLabel,

        uploadLabel,
        
        createBlogContentLabel,
        updateBlogContentLabel,

        blogRecordLabel,
        createMoreLabel,
        createLabel,
        // editEventDestLabel,
        closeLabel,
        noBlogRecordsFound, 
        startEventInfoMsg,
        // createBlogContentLabel,

        gridTitle,
        gridNames,
        // gridEventUrl,
        // gridEventUrlType,
        gridStartTime,
        gridBannerUrl,
    
        convertLabel,

        sourceLanguageLabel,
        sourceLanguagePrompt,
        sourceLanguageErrMsg,

        targetLanguageLabel,
        targetLanguagePrompt,
        targetLanguageErrMsg,

        moreLabel,
    } = pageLabelsRes;

    async function fetchDbResources(cacheItemKey) {
        backdropProgress(true);

        let keys = [];
        let objKeys = Object.keys(pageLabels);
        // console.log("In BlogCapture(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
        if (objKeys !== null && objKeys.length > 0) {
            objKeys.map((key) => {
                let partitionKey = appContext.currentLanguage.languageCode;
                partitionKey += ".";
                partitionKey += GenerateModulusHash(key, 200);

                let itemKey = {
                ResourcePK: partitionKey, // e.g. 'en.91'
                ResourceSK: key, // e.g. 'firstName'
                };
                keys.push(itemKey);
            });
        }
        // console.log("In BlogCapture(): fetchDbResources(): keys.length is: ", keys.length);
        // console.log("In BlogCapture(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

        let arrayData = [];

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/batchGet",
            payload: {
                keys: keys,
                projectionExpression: "ResourceCode,ResourceDesc",
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
            response.data.Responses.resource.map((item, index) => {
            let record = {
                ResourceCode: item.ResourceCode,
                ResourceDesc: item.ResourceDesc,
            };

            arrayData.push(record);
            });
                    
            if (arrayData && arrayData.length > 0) {
                arrayData.map((item) => {
                    if (item.ResourceCode === "manageBlogsLabel") pageLabels.manageBlogsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "requiredFields") pageLabels.requiredFields = item.ResourceDesc;
                    
                    if (item.ResourceCode === "languageLabel") pageLabels.languageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "languagePrompt") pageLabels.languagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "languageErrMsg") pageLabels.languageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "domainLabel") pageLabels.domainLabel = item.ResourceDesc;
                    if (item.ResourceCode === "domainPrompt") pageLabels.domainPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "domainErrMsg") pageLabels.domainErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "blogBannerUrlLabel") pageLabels.blogBannerUrlLabel = item.ResourceDesc;
                    if (item.ResourceCode === "blogBannerUrlPrompt") pageLabels.blogBannerUrlPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "blogBannerUrlErrMsg") pageLabels.blogBannerUrlErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "yourNamesLabel") pageLabels.yourNamesLabel = item.ResourceDesc;
                    if (item.ResourceCode === "yourNamesPrompt") pageLabels.yourNamesPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "yourNamesErrMsg") pageLabels.yourNamesErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "yourNamesMsg") pageLabels.yourNamesMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "blogTitleLabel") pageLabels.blogTitleLabel = item.ResourceDesc;
                    if (item.ResourceCode === "blogTitlePrompt") pageLabels.blogTitlePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "blogTitleErrMsg") pageLabels.blogTitleErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "selectTitlePrompt") pageLabels.selectTitlePrompt = item.ResourceDesc;
                                
                    if (item.ResourceCode === "blogTagsLabel") pageLabels.blogTagsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "blogTagsPrompt") pageLabels.blogTagsPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "blogTagsErrMsg") pageLabels.blogTagsErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "blogStartTimeLabel") pageLabels.blogStartTimeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "blogStartTimePrompt") pageLabels.blogStartTimePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "blogStartTimeErrMsg") pageLabels.blogStartTimeErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "blogCategoryLabel") pageLabels.blogCategoryLabel = item.ResourceDesc;
                    if (item.ResourceCode === "blogCategoryPrompt") pageLabels.blogCategoryPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "blogCategoryErrMsg") pageLabels.blogCategoryErrMsg = item.ResourceDesc;
            
                    // if (item.ResourceCode === "uploadBlogTitleLabel") pageLabels.uploadBlogTitleLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "uploadBlogTitlePrompt") pageLabels.uploadBlogTitlePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "uploadBlogTitleErrMsg") pageLabels.uploadBlogTitleErrMsg = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "liveEventUrlTypeLabel") pageLabels.liveEventUrlTypeLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "liveEventUrlTypePrompt") pageLabels.liveEventUrlTypePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "liveEventUrlTypeErrMsg") pageLabels.liveEventUrlTypeErrMsg = item.ResourceDesc;
                                    
                    if (item.ResourceCode === "yourTimeZoneMsg") pageLabels.yourTimeZoneMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "timeZoneLabel") pageLabels.timeZoneLabel = item.ResourceDesc;
                    if (item.ResourceCode === "cloneRecordLabel") pageLabels.cloneRecordLabel = item.ResourceDesc;
                                
                    if (item.ResourceCode === "blogRecordsLabel") pageLabels.blogRecordsLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "inProgressLabel") pageLabels.inProgressLabel = item.ResourceDesc;
                    if (item.ResourceCode === "startLabel") pageLabels.startLabel = item.ResourceDesc;
                    if (item.ResourceCode === "endLabel") pageLabels.endLabel = item.ResourceDesc;
                    if (item.ResourceCode === "notStartedLabel") pageLabels.notStartedLabel = item.ResourceDesc;
                      
                    // if (item.ResourceCode === "dateCreatedLabel") pageLabels.dateCreatedLabel = item.ResourceDesc;
                    
                    if (item.ResourceCode === "blogContentLabel") pageLabels.blogContentLabel = item.ResourceDesc;
                    if (item.ResourceCode === "blogContentPrompt") pageLabels.blogContentPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "blogContentErrMsg") pageLabels.blogContentErrMsg = item.ResourceDesc; 

                    if (item.ResourceCode === "toLabel") pageLabels.toLabel = item.ResourceDesc;
                    if (item.ResourceCode === "ofLabel") pageLabels.ofLabel = item.ResourceDesc;
                    if (item.ResourceCode === "recordsLabel") pageLabels.recordsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noRecordsFound") pageLabels.noRecordsFound = item.ResourceDesc;

                    if (item.ResourceCode === "createLabel") pageLabels.createLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "statusLabel") pageLabels.statusLabel = item.ResourceDesc;
                    if (item.ResourceCode === "editLabel") pageLabels.editLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteLabel") pageLabels.deleteLabel = item.ResourceDesc;

                    if (item.ResourceCode === "operationSuccessMsg") pageLabels.operationSuccessMsg = item.ResourceDesc;
                    if (item.ResourceCode === "operationFailureMsg") pageLabels.operationFailureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "duplicateTransMsg") pageLabels.duplicateTransMsg = item.ResourceDesc;
                                                                        
                    if (item.ResourceCode === "loadingText") pageLabels.loadingText = item.ResourceDesc;
                    if (item.ResourceCode === "noOptionsText") pageLabels.noOptionsText = item.ResourceDesc;
                    if (item.ResourceCode === "clearText") pageLabels.clearText = item.ResourceDesc;
                    if (item.ResourceCode === "closeText") pageLabels.closeText = item.ResourceDesc;
                    // if (item.ResourceCode === "showLabel") pageLabels.showLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "hideLabel") pageLabels.hideLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "cancelLabel") pageLabels.cancelLabel = item.ResourceDesc;
                    if (item.ResourceCode === "clearLabel") pageLabels.clearLabel = item.ResourceDesc;
                    if (item.ResourceCode === "okLabel") pageLabels.okLabel = item.ResourceDesc;

                    if (item.ResourceCode === "uploadLabel") pageLabels.uploadLabel = item.ResourceDesc;
          
                    if (item.ResourceCode === "createBlogContentLabel") pageLabels.createBlogContentLabel = item.ResourceDesc;
                    if (item.ResourceCode === "updateBlogContentLabel") pageLabels.updateBlogContentLabel = item.ResourceDesc;
                              
                    if (item.ResourceCode === "blogRecordLabel") pageLabels.blogRecordLabel = item.ResourceDesc;
                    if (item.ResourceCode === "createMoreLabel") pageLabels.createMoreLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "editEventDestLabel") pageLabels.editEventDestLabel = item.ResourceDesc;
                    if (item.ResourceCode === "closeLabel") pageLabels.closeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noBlogRecordsFound") pageLabels.noBlogRecordsFound = item.ResourceDesc;
                    if (item.ResourceCode === "startEventInfoMsg") pageLabels.startEventInfoMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "createBlogContentLabel") pageLabels.createBlogContentLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "gridTitle") pageLabels.gridTitle = item.ResourceDesc;
                    if (item.ResourceCode === "gridEventUrl") pageLabels.gridEventUrl = item.ResourceDesc;
                    if (item.ResourceCode === "gridEventUrlType") pageLabels.gridEventUrlType = item.ResourceDesc;
                                              
                    if (item.ResourceCode === "gridStartTime") pageLabels.gridStartTime = item.ResourceDesc;
                    if (item.ResourceCode === "gridBannerUrl") pageLabels.gridBannerUrl = item.ResourceDesc;
                    if (item.ResourceCode === "gridNames") pageLabels.gridNames = item.ResourceDesc;
                                              
                    if (item.ResourceCode === "convertLabel") pageLabels.convertLabel = item.ResourceDesc;
                    
                    if (item.ResourceCode === "sourceLanguageLabel") pageLabels.sourceLanguageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "sourceLanguagePrompt") pageLabels.sourceLanguagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "sourceLanguageErrMsg") pageLabels.sourceLanguageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "targetLanguageLabel") pageLabels.targetLanguageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "targetLanguagePrompt") pageLabels.targetLanguagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "targetLanguageErrMsg") pageLabels.targetLanguageErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "moreLabel") pageLabels.moreLabel = item.ResourceDesc;
                });
                // Add it to cache
                SetCacheItem(cacheItemKey, pageLabels);
            }

            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setRenderDomFlag(true);
        }).catch((err) => {
            console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setRenderDomFlag(true);
        });
    }
    
    let listNextToken = null;

    async function fetchBlogRecords(language, domain) {
        // console.log('In fetchBlogRecords()');
        if (domain && language) {
            backdropProgress(true);
            setNoBlogRecordFoundFlag(false);
            setBlogRecordsBackup([]);

            let params = {
                apiName: "BlogAPI",
                path: "/dynamodb/blog/query",
                payload: {
                    keyConditionExpression:
                        // "#blogPK = :blogPK",
                        "#blogPK = :blogPK AND begins_with(#blogSK, :blogSK)",
                    expressionAttributeNames: {
                        "#blogPK": "BlogPK",
                        "#blogSK": "BlogSK",
                    },
                    expressionAttributeValues: {
                        ":blogPK": domain.value + "#" + language.languageCode + "#blog",
                        ":blogSK": domain.value + "#" + language.languageCode + "#blog",
                    },
                    // indexName: "BlogDomain-DomainLangAuthorUserName-index"
                        // "keyConditionExpression": "#blogPK = :blogPK",
                        // "expressionAttributeNames": {"#blogPK": "BlogPK"},
                        // "expressionAttributeValues": {":blogPK": "languages"}
                },
            };
    
            if (!lodash.isEmpty(listNextToken)) {
                params.payload.nextToken = listNextToken;
            }
    
            if (!lodash.isEmpty(pageSize)) {
                params.payload.pageSize = pageSize;
            }

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchBlogRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchBlogRecords(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchBlogRecords(): response.data.Items is: ', response.data.Items);
                // console.log('In fetchBlogRecords(): response.data.NextToken is: ', response.data.NextToken);

                let records = [];

                listNextToken = null;

                if (response && !lodash.isEmpty(response.data.NextToken)) {
                    listNextToken = response.data.NextToken;
                    records = blogRecords;
                }
            
                let items = response.data.Items;

                if (items && items.length > 0) {
                    setBlogRecords([]);

                    items.map((record) => {
                        // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                        // console.log('In fetchBlogRecords(): formatedDateTime is: ', formatedDateTime);
                        
                        let formattedStartTime = null;
                        if (record.StartTime) {
                            formattedStartTime = FormatTimezoneDates(record.StartTime, record.LanguageCode, record.BlogTimeZone, "shortDateAndTime");
                        }
                        // console.log('In fetchBlogRecords(): formattedStartTime is: ', formattedStartTime);

                        let editorTransform = record.BlogContent;
                        if (record.BlogContent) {
                            if (isTabletView || isMobileView) {
                                editorTransform = EditorPixelTransform(record.BlogContent, (isTabletView ? "tabletView" : "mobileView"));
                            }
                        }
                        // console.log('In fetchBlogRecords(): editorTransform is: ', editorTransform);
    
                        let item = {
                            BlogPK: record.BlogPK,
                            BlogSK: record.BlogSK,

                            LanguageObject: record.LanguageObject,
                            LanguageCode: record.LanguageCode,
                            Domain: record.Domain,

                            "BlogId": lodash.isEmpty(record.BlogId) ? "" : record.BlogId,

                            "BlogDomain": lodash.isEmpty(record.BlogDomain) ? "" : record.BlogDomain,
                            "BlogCategory": lodash.isEmpty(record.BlogCategory) ? "" : record.BlogCategory.text,
                            "BlogTitle": lodash.isEmpty(record.BlogTitle) ? "" : record.BlogTitle,
                            "BlogContent": lodash.isEmpty(editorTransform) ? "" : editorTransform,
                            "Tags": lodash.isEmpty(record.Tags) ? "" : record.Tags,
                            "BannerUrl": lodash.isEmpty(record.BannerUrl) ? "" : record.BannerUrl,
                            "AuthorNames": lodash.isEmpty(record.AuthorNames) ? "" : record.AuthorNames,
                            "AuthorUserName": lodash.isEmpty(record.AuthorUserName) ? "" : record.AuthorUserName,
                            "PopularityCount": record.PopularityCount,
            
                            "FormattedStartTime": formattedStartTime,
                            "StartTime": record.StartTime,
                            "BlogTimeZone": lodash.isEmpty(record.BlogTimeZone) ? "" : record.BlogTimeZone,
                        };
                        records.push(item);
                    });
                } else {
                    setNoBlogRecordFoundFlag(true);
                }

                // console.log('In fetchBlogRecords(): listNextToken is: ', listNextToken);
                if (!lodash.isEmpty(listNextToken)) {
                    await fetchBlogRecords(language, domain);
                }
                
                setTimeout(() => {
                    records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                    setBlogRecords(records);
                    setBlogRecordsBackup(records);
                }, 100);

                backdropProgress(false);
            }).catch((err) => {
            console.error("In fetchBlogRecords(): err is: ", JSON.stringify(err, null, 2));
            setBlogRecords([]);
            setNoBlogRecordFoundFlag(true);
            backdropProgress(false);
            });
        } else {
            setNoBlogRecordFoundFlag(true);
        }
    }

    async function fetchAuthorBlogs() {
        // console.log('In fetchAuthorBlogs()');
        backdropProgress(true);
        setNoBlogRecordFoundFlag(false);
        setBlogRecordsBackup([]);

        let params = {
            apiName: "BlogAPI",
            path: "/dynamodb/blog/query",
            payload: {
                keyConditionExpression:
                    // "#blogPK = :blogPK",
                    "#blogPK = :blogPK AND begins_with(#blogSK, :blogSK)",
                expressionAttributeNames: {
                    "#blogPK": "BlogDomain",
                    "#blogSK": "DomainLangAuthorUserName",
                },
                expressionAttributeValues: {
                    ":blogPK": appContext.domainName,
                    ":blogSK": appContext.currentLanguage.languageCode + "#" + appContext.userName,
                },
                indexName: "BlogDomain-DomainLangAuthorUserName-index"
                    // "keyConditionExpression": "#blogPK = :blogPK",
                    // "expressionAttributeNames": {"#blogPK": "BlogPK"},
                    // "expressionAttributeValues": {":blogPK": "languages"}
            },
        };
  
        if (!lodash.isEmpty(listNextToken)) {
            params.payload.nextToken = listNextToken;
        }
  
        if (!lodash.isEmpty(pageSize)) {
            params.payload.pageSize = pageSize;
        }

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
  
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchAuthorBlogs(): initObj is: ', JSON.stringify(initObj, null, 2));
  
        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In fetchAuthorBlogs(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchAuthorBlogs(): response.data.Items is: ', response.data.Items);
            // console.log('In fetchAuthorBlogs(): response.data.NextToken is: ', response.data.NextToken);

            let records = [];

            listNextToken = null;

            if (response && !lodash.isEmpty(response.data.NextToken)) {
                listNextToken = response.data.NextToken;
                records = blogRecords;
            }
        
            let items = response.data.Items;

            if (items && items.length > 0) {
                setBlogRecords([]);

                items.map((record) => {
                    // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                    // console.log('In fetchAuthorBlogs(): formatedDateTime is: ', formatedDateTime);
                    
                    let formattedStartTime = null;
                    if (record.StartTime) {
                        formattedStartTime = FormatTimezoneDates(record.StartTime, appContext.currentLanguage.languageCode, record.BlogTimeZone, "shortDateAndTime");
                    }
                    // console.log('In fetchAuthorBlogs(): formattedStartTime is: ', formattedStartTime);

                    let item = {
                        BlogPK: record.BlogPK,
                        BlogSK: record.BlogSK,

                        LanguageObject: record.LanguageObject,
                        LanguageCode: record.LanguageCode,
                        Domain: record.Domain,

                        "BlogId": lodash.isEmpty(record.BlogId) ? "" : record.BlogId,

                        "BlogDomain": lodash.isEmpty(record.BlogDomain) ? "" : record.BlogDomain,
                        "BlogCategory": lodash.isEmpty(record.BlogCategory) ? "" : record.BlogCategory.text,
                        "BlogTitle": lodash.isEmpty(record.BlogTitle) ? "" : record.BlogTitle,
                        "BlogContent": lodash.isEmpty(record.BlogContent) ? "" : record.BlogContent,
                        "Tags": lodash.isEmpty(record.Tags) ? "" : record.Tags,
                        "BannerUrl": lodash.isEmpty(record.BannerUrl) ? "" : record.BannerUrl,
                        "AuthorNames": lodash.isEmpty(record.AuthorNames) ? "" : record.AuthorNames,
                        "AuthorUserName": lodash.isEmpty(record.AuthorUserName) ? "" : record.AuthorUserName,
                        "PopularityCount": record.PopularityCount,
        
                        "FormattedStartTime": formattedStartTime,
                        "StartTime": record.StartTime,
                        "BlogTimeZone": lodash.isEmpty(record.BlogTimeZone) ? "" : record.BlogTimeZone,
                    };
                    records.push(item);
                });
            } else {
                setNoBlogRecordFoundFlag(true);
            }

            // console.log('In fetchAuthorBlogs(): listNextToken is: ', listNextToken);
            if (!lodash.isEmpty(listNextToken)) {
                await fetchAuthorBlogs();
            }
            
            setTimeout(() => {
                records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                setBlogRecords(records);
                setBlogRecordsBackup(records);
            }, 100);

            backdropProgress(false);
        }).catch((err) => {
          console.error("In fetchAuthorBlogs(): err is: ", JSON.stringify(err, null, 2));
          setBlogRecords([]);
          setNoBlogRecordFoundFlag(true);
          backdropProgress(false);
        });
    }
    
    async function getBlog(key) {
        // console.log('In getBlog(): key.BlogPK is: ', key.BlogPK);
        // console.log('In getBlog(): key.BlogSK is: ', key.BlogSK);
        
        backdropProgress(true);
    
        let params = {
            apiName: "BlogAPI",
            path: "/dynamodb/blog/get",
            payload: {
                key: {
                    BlogPK: key.BlogPK,
                    BlogSK: key.BlogSK,
                },
            },
        };
  
        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
  
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In getBlog(): initObj is: ', JSON.stringify(initObj, null, 2));
  
        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In getBlog(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In getBlog(): response.data is: ', response.data);
        
            let item = response.data.Item;

            appContext.currentPK = item.BlogPK;
            appContext.currentSK = item.BlogSK;
        
            setLanguage(item.LanguageObject);
            setDomain(item.Domain);
            
            setYourNames(item.AuthorNames);
            setAuthorPhotoSrc(item.AuthorPhotoSrc);
            setBlogTitle(item.BlogTitle);
            setBlogContent(item.BlogContent);
            setBlogTags(item.Tags);
            setBlogBannerUrl(item.BannerUrl ? item.BannerUrl : "");

            setBlogStartTime(item.StartTime);
            setBlogCategory(item.BlogCategory);
            setTimeZone(item.BlogTimeZone);

            setShowBlogListFlag(false);
            setUploadFlag(false);
            setShowBlogDetailsFormFlag(true);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);

            backdropProgress(false);
        }).catch((err) => {
            console.error("In getBlog(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    }
  
    async function editBlogAction(item) {
        // console.log("In editBlogAction(): item is: ", item);
        // console.log("In editBlogAction(): blogRecords is: ", blogRecords);
        
        setLanguage(null);
        setDomain(null);
        setShowBlogDetailsFormFlag(true);

        setRecordToEdit(item);
        await getBlog({BlogPK: item.BlogPK, BlogSK: item.BlogSK,});
    }

    let  refDataSelectOptions = [];
    
    async function fetchReferenceData(refType, lang) {
        return new Promise((resolve, reject) => {
            // console.log('In ManageFaqs  fetchReferenceData(): refType is: ', refType);
            // console.log('In ManageFaqs  fetchReferenceData(): lang is: ', lang);
            backdropProgress(true);

            refDataSelectOptions = [];

            let apiName = null;
            let path = null;
            let keyConditionExpression = null;
            let expressionAttributeNames = {};
            let expressionAttributeValues = {};
            
            switch(refType) {
                case "supported-domains":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "supported-domains",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                default:
                    break;
            }

            setProgressFlag(true);
        
            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    keyConditionExpression: keyConditionExpression,
                    expressionAttributeNames: expressionAttributeNames,
                    expressionAttributeValues: expressionAttributeValues,
                    // "keyConditionExpression": "#resourcePK = :resourcePK",
                    // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                    // "expressionAttributeValues": {":resourcePK": "languages"}
                },
            };
        
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
        
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In  fetchReferenceData(): initObj is: ', JSON.stringify(initObj, null, 2));
        
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In  fetchReferenceData(): response.length is: ', response.data.Items.length);
                // console.log('In  fetchReferenceData(): response is: ', JSON.stringify(response.data.Items, null, 2));
                response.data.Items.map((item) => {
                    let record = {
                        value: item.ResourceCode,
                        text: item.ResourceDesc,
                    };

                    if (record.value !== "all") {// Bypass all
                        refDataSelectOptions.push(record);
                    }
                });

                switch(refType) {
                    case "supported-domains":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setDomainOptions(refDataSelectOptions);
                        break;
                    default:
                        break;
                }

                backdropProgress(false);
                resolve(refDataSelectOptions);
            }).catch((err) => {
                console.error("In ManageFaqs  fetchReferenceData(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject(err);
            });
        });
    }

    async function fetchLanguages() {
        // console.log("In fetchLanguages()");
        // setFilterDisabledFlag(true);

        //-------------------------------------------------------
        // Make sure languages is not in cache before proceeding
        //-------------------------------------------------------

        let cachedLanguages = GetCacheItem("languages");
        // console.log('In fetchLanguages(): cachedLanguages is: ', JSON.stringify(cachedLanguages, null, 2));
        cachedLanguages = null;
        if (cachedLanguages && cachedLanguages !== null && cachedLanguages.length > 0) {
            // console.log('In fetchLanguages(): cachedLanguages length is: ', cachedLanguages.length);
            setLanguages(cachedLanguages);
            return true;
        }

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/query",
            payload: {
                keyConditionExpression: "#resourcePK = :resourcePK",
                expressionAttributeNames: { "#resourcePK": "ResourcePK" },
                expressionAttributeValues: { ":resourcePK": "languages" },
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log("In fetchLanguages(): response length is: ", response.data.Items.length);
            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));

            // response.data.Items.sort((a, b) =>
            //     a.LanguageName > b.LanguageName ? 1 : -1
            // );

            let records = [];
            let items = response.data.Items;

            items.map(item => {
                let record ={
                    languageCode: item.ResourceCode,
                    languageName: item.LanguageName
                };
                records.push(record);
            });

            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
            setLanguages(records);

            // Add it to cache
            SetCacheItem("languages", response.data.Items);
            backdropProgress(false);
        }).catch((err) => {
            console.error("In fetchLanguages(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    };

    let categorySelectOptions = [];

    async function fetchCategories(referrer) {
        // console.log('In BlogCapture fetchCategories()');
        backdropProgress(true);

        let apiName = null;
        let path = null;
        let keyConditionExpression = null;
        let expressionAttributeNames = {};
        let expressionAttributeValues = {};
        
        switch(referrer) {
            case "BlogCapture":
                apiName = "ResourceAPI";
                path = "/dynamodb/resources/query";
                keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                expressionAttributeNames = {
                    "#resourcePK": "ResourcePK",
                    "#resourceSK": "ResourceSK",
                };
                expressionAttributeValues = {
                    ":resourcePK": "blog-categories",
                    ":resourceSK": appContext.currentLanguage.languageCode,
                };
                break;
            default:
                break;
        }

        setProgressFlag(true);
    
        let params = {
            apiName: apiName,
            path: path,
            payload: {
                keyConditionExpression: keyConditionExpression,
                expressionAttributeNames: expressionAttributeNames,
                expressionAttributeValues: expressionAttributeValues,
                // "keyConditionExpression": "#resourcePK = :resourcePK",
                // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                // "expressionAttributeValues": {":resourcePK": "languages"}
            },
        };
    
        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
    
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchCategories(): initObj is: ', JSON.stringify(initObj, null, 2));
    
        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchCategories(): response.length is: ', response.data.Items.length);
            // console.log('In BlogCapture fetchCategories(): response is: ', JSON.stringify(response.data.Items, null, 2));
            response.data.Items.map((item) => {
                let record = {
                    value: item.ResourceCode,
                    text: item.ResourceDesc,
                };
                // if (record.value === "all") {
                //     setBlogCategory(record); // set it as default value 
                // }

                if (record.value !== "all") {// Bypass all
                    categorySelectOptions.push(record);
                }
            });
    
            categorySelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
            setCategoryOptions(categorySelectOptions);
            backdropProgress(false);
            setProgressFlag(false);
        }).catch((err) => {
            console.error("In BlogCapture fetchCategories(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
            setProgressFlag(false);
        });
    }

    // async function imageObjectCallback(result) {
    //     // console.log("In imageObjectCallback(): appContext.storageCfUrl is: ", appContext.storageCfUrl);
    //     // console.log("In imageObjectCallback(): result is: ", result);
    //     sessionStorage.removeItem("user-photo-url");

    //     if (result) {
    //         const photoUrl = appContext.storageCfUrl + result;
    //         setProfilePhotoSrc(photoUrl);
    //         sessionStorage.setItem("user-photo-url", photoUrl);
    //     }
    // }
        
    useEffect(() => {
        // console.log('In BlogCapture(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        // sessionStorage.removeItem("current-live-event");
    }, []);

    useLayoutEffect(() => {
        // console.log("In BlogCapture(): useLayoutEffect()");
        async function fetchItems() {
            await fetchLanguages();

            await fetchCategories("BlogCapture");

            // try{
            //     let imageKeyPrefix = "ccms/onyii5119@gmail.com/" + "capitalone_m2.jpg";// Test
            //     // let imageKeyPrefix = appContext.shortDomain + "/" + appContext.userName + "/user/profile/";
            //     await FetchS3Object(imageKeyPrefix, imageObjectCallback);
            // } catch (error) {
            //     console.log("In BlogCapture(): useLayoutEffect(): error is: ", error);
            // }

            // Fetch resource from catche
            let cacheItemKey = "blog-capture";
            cacheItemKey += ":";
            cacheItemKey += appContext.currentLanguage.languageCode;
            // console.log('In BlogCapture(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

            if (
                appContext.clearCacheLanguageCodes &&
                appContext.clearCacheLanguageCodes.length > 0 &&
                appContext.clearCacheLanguageCodes.includes(appContext.currentLanguage.languageCode)
            ) {
                // console.log("In BlogCapture(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                RemoveCacheItem(cacheItemKey);
            }

            let cacheItem = GetCacheItem(cacheItemKey);
            // console.log('In BlogCapture(): useLayoutEffect(): cacheItem is: ', cacheItem);
            cacheItem = null; // remove after testing

            if (cacheItem === null) {
                // resource is not in cache, fetch from DB
                // console.log('In BlogCapture(): useLayoutEffect(): resource is not in cache, fetch from DB');
                await fetchDbResources(cacheItemKey);
            } else {
                // console.log('In BlogCapture(): useLayoutEffect(): got resource from cache');
                setPageLabelsRes(cacheItem);
                setRenderDomFlag(true);
            }
        }

        fetchItems();
    }, []);
    
    function handleChange(event) {
        // console.log("In handleChange(): event.target.id is: ", event.target.id);
        // console.log("In handleChange(): event.target.value is: ", event.target.value);
        // setSubmitButtonFlag(false);

        switch(event.target.id) {
            case "blogTagsId":
                // console.log("In handleChange(): event.target.value is: ", event.target.value);
                setBlogTagsErrFlag(false);
                setBlogTags(event.target.value);

                setSubmitButtonFlag(false);
                if (
                    !lodash.isEmpty(event.target.value) &&
                    !lodash.isEmpty(blogContent) &&
                    !lodash.isEmpty(yourNames) &&
                    !lodash.isEmpty(blogTitle) &&
                    !lodash.isEmpty(blogStartTime) &&
                    !lodash.isEmpty(blogCategory) &&
                    !lodash.isEmpty(language)
                    ) {
                    setSubmitButtonFlag(true);
                }
                break;
            case "yourNamesId":
                // console.log("In handleChange(): event.target.value is: ", event.target.value);
                setYourNamesErrFlag(false);
                setYourNames(event.target.value);

                setSubmitButtonFlag(false);
                if (
                    !lodash.isEmpty(event.target.value) &&
                    !lodash.isEmpty(blogTitle) &&
                    !lodash.isEmpty(blogContent) &&
                    !lodash.isEmpty(blogStartTime) &&
                    !lodash.isEmpty(blogTags) &&
                    !lodash.isEmpty(blogCategory) &&
                    !lodash.isEmpty(language)
                    ) {
                    setSubmitButtonFlag(true);
                }
                break;
            case "blogTitleId":
                setBlogTitleErrFlag(false);
                setBlogTitle(event.target.value);

                setSubmitButtonFlag(false);
                if (
                    !lodash.isEmpty(event.target.value) &&
                    !lodash.isEmpty(yourNames) &&
                    !lodash.isEmpty(blogContent) &&
                    !lodash.isEmpty(blogStartTime) &&
                    !lodash.isEmpty(blogTags) &&
                    !lodash.isEmpty(blogCategory) &&
                    !lodash.isEmpty(language)
                    ) {
                    setSubmitButtonFlag(true);
                }
                break;
            case "blogBannerUrlId":
                setBlogBannerUrlErrFlag(false);
                setBlogBannerUrl(event.target.value ? event.target.value : "");

                // setSubmitButtonFlag(false);
                // if (
                //     !lodash.isEmpty(event.target.value) &&
                //     !lodash.isEmpty(yourNames) &&
                //     !lodash.isEmpty(blogContent) &&
                //     !lodash.isEmpty(blogStartTime) &&
                //     !lodash.isEmpty(blogTags) &&
                //     !lodash.isEmpty(language)
                //     ) {
                //     setSubmitButtonFlag(true);
                // }
                break;
            default:
                break;
        }
    }
    
    function handleLanguageChange(event, item) {
        // console.log("In handleLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        setSubmitButtonFlag(false);        
        setLanguage(item);

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(blogTitle) &&
            !lodash.isEmpty(yourNames) &&
            !lodash.isEmpty(blogContent) &&
            !lodash.isEmpty(blogStartTime) &&
            !lodash.isEmpty(blogCategory) &&
            !lodash.isEmpty(blogTags) 
        ) {
            setSubmitButtonFlag(true);
        }

        if (!lodash.isEmpty(item)) {
            Promise.all([
                fetchReferenceData("supported-domains", item)
            ]).then(async (results) => {
                // console.log("In handleListLanguageChange(): results is: ", results);
            }).catch(error => {
                console.log("In handleListLanguageChange(): error is: ", error);
            });
        }
    }

    function handleDomainChange(event, item) {
        // console.log("In handleDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            sessionStorage.setItem("media-domain-name", item.value);
        }

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(blogTitle) &&
            !lodash.isEmpty(yourNames) &&
            !lodash.isEmpty(blogContent) &&
            !lodash.isEmpty(blogStartTime) &&
            !lodash.isEmpty(blogCategory) &&
            !lodash.isEmpty(blogTags) 
            ) {
                setSubmitButtonFlag(true);
        }

        if (showBlogListFlag && language && item) {
            fetchBlogRecords(language, item);
        }
    }

    function handleListDomainChange(event, item) {
        // console.log("In handleListDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            fetchBlogRecords(item);
        }
    }

    // For tablet and mobile views
    function handleCategoryFilterChange(event, item) {
        // event.preventDefault();
        // event.persist();
        // console.log("In handleCategoryFilterChange(): item is ", item);
        
        setBlogCategory(item);

        setBlogRecords([]);

        if (item && blogRecordsBackup && blogRecordsBackup.length > 0) {
            console.log("In handleCategoryFilterChange(): item.value is ", item.value);
            if (item.value === "all") {
                setBlogRecords(blogRecordsBackup);
                return;
            }
            let filteredRecords = blogRecordsBackup.filter(record => record.mediaCategoryCode === item.value);
            if (filteredRecords) {
                setBlogRecords(filteredRecords);
            }
        }
    }

    function handleBlogStartTimeChange(startTime) {
        // console.log("In handleBlogStartTimeChange(): startTime is: ", startTime);

        setGlobalErrorFlag(false);
        setBlogStartTimeErrFlag(false);

        setBlogStartTime(startTime);

        // const timestamp = new Date();
        // const timestamp2 = Date.now();
        // console.log("In handleBlogStartTimeChange(): timestamp is: ", timestamp);
        // console.log("In handleBlogStartTimeChange(): timestamp2 is: ", timestamp2);

        // const startTime2 = moment.tz(startTime, timeZone);
        // console.log("In handleBlogStartTimeChange(): startTime2 is: ", startTime2);

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(startTime) &&
            !lodash.isEmpty(yourNames) &&
            !lodash.isEmpty(blogTitle) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(blogContent) &&
            !lodash.isEmpty(blogCategory) &&
            !lodash.isEmpty(blogTags) 
            ) {
            setSubmitButtonFlag(true);
        }
    }

    function handleBlogCategoryChange(event, item) {
        // console.log("In handleBlogCategoryChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setBlogCategoryErrFlag(false);
        
        setBlogCategory(item);

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(yourNames) &&
            !lodash.isEmpty(blogTitle) &&
            !lodash.isEmpty(language) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(blogContent) &&
            !lodash.isEmpty(blogTags) &&
            !lodash.isEmpty(blogStartTime)
            ) {
            setSubmitButtonFlag(true);
        }
    }

    //--------------------
    // For Froala Editor
    //--------------------
    async function handleEditorHtmlBodyChange(editorData) {
        // console.log("In handleEditorHtmlBodyChange(): editorData is: ", editorData);

        // if (editorData || editorData === "") {
            setBlogContent(editorData);
            setSubmitButtonFlag(false);

            if (
                !lodash.isEmpty(editorData) &&
                !lodash.isEmpty(yourNames) &&
                !lodash.isEmpty(blogTitle) &&
                !lodash.isEmpty(language) &&
                !lodash.isEmpty(domain) &&
                !lodash.isEmpty(blogCategory) &&
                !lodash.isEmpty(blogTags) &&
                !lodash.isEmpty(blogStartTime)
                ) {
                setSubmitButtonFlag(true);
            }
        // }
    }

    function initializeForm() {
        setYourNames("");
        setBlogTitle("");
        setBlogStartTime(null);
        setBlogCategory(null);
        setBlogContent("");
        setBlogBannerUrl("");
        setBlogTags("");
    }

    function createLiveEventAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowBlogListFlag(false);
        setShowBlogDetailsFormFlag(true);
        setUploadFlag(false);
    }

    function createMoreAction() {
        initializeForm();
        setLanguage(null);
        setDomain(null);

        setEditModeFlag(false);
        setShowBlogListFlag(false);
        setShowBlogDetailsFormFlag(true);
        setUploadFlag(false);
    }

    function uploadAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowBlogListFlag(false);
        setShowBlogDetailsFormFlag(false);
        setUploadFlag(true);
    }
    
    function convertAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowBlogListFlag(false);
        setShowBlogDetailsFormFlag(false);
        setUploadFlag(false);
        setConvertFlag(true);
    }
    
    async function closeConvertForm() {
        await fetchBlogRecords(language, domain);
        
        setTimeout(() => {
            setShowBlogDetailsFormFlag(false);
            setUploadFlag(false);
            setConvertFlag(false);
            setShowBlogListFlag(true);
        }, 500);
    }
    
    async function closeDetailsForm() {
        await fetchBlogRecords(language, domain);
        
        setTimeout(() => {
            setShowBlogDetailsFormFlag(false);
            setUploadFlag(false);
            setShowBlogListFlag(true);
            setEditModeFlag(false);
        }, 500);
    }
    
    async function closeUploadForm() {
        await fetchBlogRecords(language, domain);
        
        setTimeout(() => {
            setShowBlogListFlag(true);
            setShowBlogDetailsFormFlag(false);
            setUploadFlag(false);
        }, 500);
    }
    
    // function liveEventsUploadCallback() {
    //     setEditModeFlag(false);
    //     setShowBlogListFlag(true);
    //     setShowBlogDetailsFormFlag(false);
    //     setUploadFlag(false);
    // }
    
    async function createService() {
        console.log("In createService(): domain is: ", domain);
        console.log("In createService(): language is: ", language);
        // console.log("In createService(): blogContent is: ", blogContent);
        // console.log("In createService(): blogTitle is: ", blogTitle);
        // console.log("In createService(): blogTags is: ", blogTags);
        // console.log("In createService(): blogStartTime is: ", blogStartTime);
        // console.log("In createService(): blogCategory is: ", blogCategory);
        // console.log("In createService(): timeZone is: ", timeZone);
        // console.log("In createService(): appContext.deceasedCode is: ", appContext.deceasedCode);
        // console.log("In createService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In createService(): appContext.userName is: ", appContext.userName);
    
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In createService(): Validation passed.");
            // console.log("In createService(): Validation passed.");

            // const timestamp = new Date();
            const timestamp = moment.tz(Date.now(), timeZone);
            // console.log("In createService(): timestamp is: ", timestamp);

            const startTime = moment.tz(blogStartTime, timeZone);
            // console.log("In createService(): startTime is: ", startTime);

            backdropProgress(true);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            let uniqueId = GenerateUniqueName();

            let key = {
                BlogPK: domain.value + "#" + language.languageCode + "#blog",
                BlogSK: domain.value + "#" + language.languageCode + "#blog#" + uniqueId,
            };

            let item = {
                "BlogPK": key.BlogPK,
                "BlogSK":  key.BlogSK,

                "BlogId": uniqueId,

                "UniqueAttribute": lodash.isEmpty(key) ? null : key,
                "LanguageObject": lodash.isEmpty(language) ? null : language,
                "LanguageCode": lodash.isEmpty(language) ? null : language.languageCode,
                "Domain": domain,

                "BlogDomain": domain.value,
                "BlogCategory": lodash.isEmpty(blogCategory) ? null : blogCategory,
                "BlogCategoryCode": lodash.isEmpty(blogCategory) ? null : blogCategory.value,
                "BlogTitle": lodash.isEmpty(blogTitle) ? null : blogTitle,
                "BlogContent": lodash.isEmpty(blogContent) ? null : blogContent,
                "Tags": lodash.isEmpty(blogTags) ? null : blogTags,
                "BannerUrl": lodash.isEmpty(blogBannerUrl) ? null : blogBannerUrl,
                "AuthorNames": lodash.isEmpty(yourNames) ? null : yourNames,
                "AuthorUserName": appContext.userName,
                "AuthorPhotoSrc": lodash.isEmpty(profilePhotoSrc) ? null : profilePhotoSrc, 
                "PopularityCount": 0,

                "StartTime": startTime,
                "BlogTimeZone": timeZone,

                "DomainLangAuthorUserName": domain.value + "#" + language.languageCode + "#" + appContext.userName,// For GSI
                "DomainLangBlogTitle": domain.value + "#" + language.languageCode + "#" + blogTitle,// For GSI
                "DomainLangStartTime": domain.value + "#" + language.languageCode + "#" + startTime,// For GSI
                "DomainLangPopularityCount": domain.value + "#" + language.languageCode + "#" + 0,// For GSI
                "DomainLangBlogCategoryCode": domain.value + "#" + language.languageCode + "#" + blogCategory.value,// For GSI

                "ModifiedBy": appContext.userName,
                "CreatedAt": timestamp,
                "UpdatedAt": timestamp,
            };

            // console.log('In createService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_not_exists(UniqueAttribute)";

            let params = {
                apiName: "BlogAPI",
                path: "/dynamodb/blog/put",
                payload: {
                    item: item,
                    conditionExpression: conditionExpression,
                    // updateExpression: updateExpression,
                    // expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In createService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In createService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In createService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar 
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);
            }).catch((err) => {
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                switch(err.code) {
                    case "ConditionalCheckFailedException":
                        console.error("In createService(): Duplicate Error");
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        break;
                    default:
                        console.error("In createService(): err is: ", JSON.stringify(err, null, 2));
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                    break;
                }
            });
        }
    }

    async function updateService() {
        // console.log("In updateService(): liveEventUrl is: ", liveEventUrl);
        // console.log("In updateService(): liveEventUrlType is: ", liveEventUrlType);
        // console.log("In updateService(): blogContent is: ", blogContent);
        // console.log("In updateService(): blogTitle is: ", blogTitle);
        // console.log("In updateService(): blogTags is: ", blogTags);
        // console.log("In updateService(): blogStartTime is: ", blogStartTime);
        // console.log("In updateService(): blogCategory is: ", blogCategory);
        // console.log("In updateService(): timeZone is: ", timeZone);
        // console.log("In updateService(): appContext.deceasedCode is: ", appContext.deceasedCode);
        // console.log("In updateService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In updateService(): appContext.userName is: ", appContext.userName);
        // console.log("In updateService(): appContext.currentPK is: ", appContext.currentPK);
        // console.log("In updateService(): appContext.currentSK is: ", appContext.currentSK);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In updateService(): Validation passed.");

            // let blogId = GenerateUniqueName();// conversion
                
            const timestamp = new Date();

            backdropProgress(true);
            const startTime = moment.tz(blogStartTime, timeZone);
            // console.log("In updateService(): startTime is: ", startTime);

            let key = {
                BlogPK: appContext.currentPK,
                BlogSK: appContext.currentSK,
            };
            // console.log("In updateService(): key is: ", key);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
            let updateExpression =
                "SET " +
                "UniqueAttribute = :uniqueAttribute, " +
                // "LanguageObject = :languageObject, " +
                // "LanguageCode = :languageCode, " +

                // "BlogId = :blogId, " + // conversion
                
                "BlogCategory = :blogCategory, " +
                "BlogCategoryCode = :blogCategoryCode, " +
                "BlogTitle = :blogTitle, " +
                "BlogContent = :blogContent, " +
                "Tags = :tags, " +
                "BannerUrl = :bannerUrl, " +
                "AuthorNames = :authorNames, " +
                "AuthorPhotoSrc = :authorPhotoSrc, " +
                "StartTime = :startTime, " +
                "BlogTimeZone = :blogTimeZone, " +
                
                "DomainLangAuthorUserName = :domainLangAuthorUserName, " +
                "DomainLangBlogTitle = :domainLangBlogTitle, " +
                "DomainLangStartTime = :domainLangStartTime, " +
                "DomainLangBlogCategoryCode = :domainLangBlogCategoryCode, " +

                "ModifiedBy = :modifiedBy, " + 
                "UpdatedAt = :updatedAt";

            let expressionAttributeValues = {
                ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
                // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
                // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,
                
                // ":blogId": lodash.isEmpty(blogId) ? null : blogId,// conversion
                
                ":blogCategory": lodash.isEmpty(blogCategory) ? null : blogCategory,
                ":blogCategoryCode": lodash.isEmpty(blogCategory) ? null : blogCategory.value,
                ":blogTitle": lodash.isEmpty(blogTitle) ? null : blogTitle,
                ":blogContent": lodash.isEmpty(blogContent) ? null : blogContent,
                ":tags": lodash.isEmpty(blogTags) ? null : blogTags,
                ":bannerUrl": lodash.isEmpty(blogBannerUrl) ? null : blogBannerUrl,
                ":authorNames": lodash.isEmpty(yourNames) ? null : yourNames,
                ":authorPhotoSrc": lodash.isEmpty(profilePhotoSrc) ? null : profilePhotoSrc,
                ":startTime": lodash.isEmpty(startTime) ? null : startTime,
                ":blogTimeZone": lodash.isEmpty(timeZone) ? null : timeZone,
                
                ":domainLangAuthorUserName": domain.value + "#" + language.languageCode + "#" + appContext.userName,// For GSI
                ":domainLangBlogTitle": domain.value + "#" + language.languageCode + "#" + blogTitle,// For GSI
                ":domainLangStartTime": domain.value + "#" + language.languageCode + "#" + startTime,// For GSI
                ":domainLangBlogCategoryCode": domain.value + "#" + language.languageCode + "#" + blogCategory.value,// For GSI
                
                ":modifiedBy": appContext.userName,
                ":updatedAt": moment.tz(timestamp, timeZone),
            };
            // console.log('In updateService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_exists(UniqueAttribute)";

            let params = {
                apiName: "BlogAPI",
                path: "/dynamodb/blog/update",
                payload: {
                    key: key,
                    conditionExpression: conditionExpression,
                    updateExpression: updateExpression,
                    expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In updateService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In updateService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In updateService(): response is: ', JSON.stringify(response, null, 2));
                setShowBlogDetailsFormFlag(false);
                setUploadFlag(false);
                setEditModeFlag(false);

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);

                await fetchBlogRecords(language, domain);
                setShowBlogListFlag(true);
            }).catch((err) => {
                console.error("In updateService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    async function deleteService(recToDelete) {
        // console.log("In deleteService(): recToDelete is: ", recToDelete);
        // setDeleteCompleteFlag(false);
        // let itemsToDelete = [];

        if (!lodash.isEmpty(recToDelete)) {
            backdropProgress(true);

            let key = {
                BlogPK: recToDelete.BlogPK,
                BlogSK: recToDelete.BlogSK,
            };

            let params = {
                apiName: "BlogAPI",
                path: "/dynamodb/blog/delete",
                payload: {
                    key: key,
                },
            };
    
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In deleteService(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In deleteService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                backdropProgress(false);
                await fetchBlogRecords(language, domain);
            }).catch((err) => {
                console.error("In deleteService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    function validateForm() {
        // console.log("In validateForm()");
        let formErrFlag = false;
  
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
    
        setLanguageErrFlag(false);
        setDomainErrFlag(false);
        setYourNamesErrFlag(false);
        setBlogTitleErrFlag(false);
        setBlogStartTimeErrFlag(false);
        setBlogCategoryErrFlag(false);
        setBlogTagsErrFlag(false);
        setBlogContentErrFlag(false);
        setBlogBannerUrlErrFlag(false);

        if (lodash.isEmpty(language)) {
            formErrFlag = true;
            setLanguageErrFlag(true);
        }

        if (lodash.isEmpty(domain)) {
            formErrFlag = true;
            setDomainErrFlag(true);
        }
    
        if (lodash.isEmpty(yourNames)) {
            formErrFlag = true;
            setYourNamesErrFlag(true);
        }
    
        if (lodash.isEmpty(blogTitle)) {
            formErrFlag = true;
            setBlogTitleErrFlag(true);
        }
    
        if (lodash.isEmpty(blogStartTime)) {
            formErrFlag = true;
            setBlogStartTimeErrFlag(true);
        }
    
        if (lodash.isEmpty(blogCategory)) {
            formErrFlag = true;
            setBlogCategoryErrFlag(true);
        }
    
        if (lodash.isEmpty(blogTags)) {
            formErrFlag = true;
            setBlogTagsErrFlag(true);
        }
    
        if (lodash.isEmpty(blogContent)) {
            formErrFlag = true;
            setBlogContentErrFlag(true);
        }
    
        if (!lodash.isEmpty(blogBannerUrl) && !isURL(blogBannerUrl)) {
            formErrFlag = true;
            setBlogBannerUrlErrFlag(true);
        }
    
        if (formErrFlag) {
            setGlobalErrorFlag(true);
        }
        // console.log("In validateForm(): formErrFlag is: ", formErrFlag);
        return formErrFlag;
    }

    async function blogService() {
        // console.log("In blogService()");

        if (!validateForm()) {
            // console.log("In blogService(): Validation passed.");
            if (editModeFlag) {
                await updateService();
            } else {
                await createService();
            }
        }
    }
    
    function snackbarOnClose(event, reason) {
        setSnackbarOpen(false);
    }

    function handleMultipleSelectionChange(data) {
        // console.log("In handleMultipleSelectionChange(): data.value is: ", data.value);
        setSelectedTableRows(data.value);
        setTableDirtyFlag(true);
    }

    //------------------
    // For AlertDialog
    //------------------
    const [deleteType, setDeleteType] = useState("");

    function showAlertDialog(item, type) {
        // console.log("In showAlertDialog(): type is: ", type);
        setDeleteType(type);
        
        if (type && type === "single") {// single deletion
            setRecordToDelete(item);
            setDialogSubContent(item.BlogTitle);
            setAlertOpen(true);
        } else {// multiple deletions
            let msg = FormatNumber(selectedTableRows.length, appContext.currentLanguage.languageCode);
            msg += " ";
            msg += selectedTableRows.length > 1 ? blogRecordsLabel : blogRecordLabel;
            setDialogSubContent(msg);
            setAlertOpen(true);
        }
    }

    function alertClickAgree() {
        // Close Alert
        if (deleteType && deleteType === "single") {
            setAlertOpen(false);
            deleteService(recordToDelete);
        } else {
            setAlertOpen(false);
            // deleteMultipleAction();
            processMultipleDeletionRecs();
        }
    }

    function alertClickDisagree() {
        // Close Alert
        setAlertOpen(false);
        return false;
    }

    function alertClose() {
        // Close Alert
        setAlertOpen(false);
    }

    function deleteIconTemplate(rowData, column) {
        return <DeleteIcon color="secondary" onClick={() => showAlertDialog(rowData, "single")}/>
    }

    function editIconTemplate(rowData, column) {
        return <EditIcon color="primary" onClick={() => editBlogAction(rowData)}/>
    }

    async function createOrUpdateStreamRecord(rowData) {
        // console.log("In createOrUpdateStreamRecord(): rowData is: ", JSON.stringify(rowData, null, 2));
        // console.log("In createOrUpdateStreamRecord(): CobPK is: ", rowData.CobPK);
        // console.log("In createOrUpdateStreamRecord(): CobSK is: ", rowData.CobSK);

        // console.log("In createOrUpdateStreamRecord(): liveEventUrl is: ", rowData.LiveEventUrl);
        // console.log("In createOrUpdateStreamRecord(): liveEventUrlType is: ", rowData.LiveEventUrlType);
        // console.log("In createOrUpdateStreamRecord(): blogContent is: ", rowData.BlogContent);
        // console.log("In createOrUpdateStreamRecord(): blogTitle is: ", rowData.BlogTitle);
        // console.log("In createOrUpdateStreamRecord(): blogTags is: ", rowData.BlogTags);

        // console.log("In createOrUpdateStreamRecord(): blogStartTime is: ", rowData.BlogStartTime);
        // console.log("In createOrUpdateStreamRecord(): blogCategory is: ", rowData.BlogCategory);

        // console.log("In createOrUpdateStreamRecord(): timeZone is: ", timeZone);
        // console.log("In createOrUpdateStreamRecord(): appContext.deceasedCode is: ", appContext.deceasedCode);
        // console.log("In createOrUpdateStreamRecord(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In createOrUpdateStreamRecord(): appContext.userName is: ", appContext.userName);

        let blogCategory = null;
        if (rowData.BlogTags) {
            blogCategory = moment(rowData.BlogStartTime).add(parseInt(rowData.BlogTags.value), 'minutes');
        }
        // console.log("In createOrUpdateStreamRecord(): blogCategory is: ", blogCategory);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: "live-events",
            CobSK: appContext.currentLanguage.languageCode + "#" + appContext.deceasedCode,
        };
        // console.log("In createOrUpdateStreamRecord(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        // let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            "LanguageObject = :languageObject, " +
            "LanguageCode = :languageCode, " +
            
            "LiveEventStarted = :liveEventStarted, " +

            "BlogTags = :blogTags, " +

            "BlogStartTime = :blogStartTime, " +
            "BlogCategory = :blogCategory, " +

            "EventTimeZone = :eventTimeZone, " +

            "LiveEventUrl = :liveEventUrl, " +
            "LiveEventUrlType = :liveEventUrlType, " +
            "BlogContent = :blogContent, " +
            "YourNames = :yourNames, " +
            "BlogTitle = :blogTitle, " +

            "EventThumbnailUrl = :eventThumbnailUrl, " +
            "EventBannerUrl = :eventBannerUrl, " +
            "EventPosterUrl = :eventPosterUrl, " +
            "EventAudioUrl = :eventAudioUrl, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            ":languageObject": lodash.isEmpty(rowData.LanguageObject) ? null : rowData.LanguageObject,
            ":languageCode": lodash.isEmpty(rowData.LanguageCode) ? null : rowData.LanguageCode,
                    
            ":liveEventStarted": true,

            ":blogTags": lodash.isEmpty(rowData.BlogTags) ? null : rowData.BlogTags,

            ":blogStartTime": lodash.isEmpty(rowData.BlogStartTime) ? null : rowData.BlogStartTime,
            ":blogCategory": lodash.isEmpty(blogCategory) ? null : blogCategory,

            ":eventTimeZone": lodash.isEmpty(timeZone) ? null : timeZone,

            ":liveEventUrl": lodash.isEmpty(rowData.LiveEventUrl) ? null : rowData.LiveEventUrl,
            ":liveEventUrlType": lodash.isEmpty(rowData.LiveEventUrlType) ? null : rowData.LiveEventUrlType,
            ":yourNames": lodash.isEmpty(rowData.YourNames) ? null : rowData.YourNames,
            ":blogTitle": lodash.isEmpty(rowData.BlogTitle) ? null : rowData.BlogTitle,
            ":blogContent": lodash.isEmpty(rowData.BlogContent) ? null : rowData.BlogContent,

            ":eventThumbnailUrl": lodash.isEmpty(rowData.EventThumbnailUrl) ? null : rowData.EventThumbnailUrl,
            ":eventBannerUrl": lodash.isEmpty(rowData.EventBannerUrl) ? null : rowData.EventBannerUrl,
            ":eventPosterUrl": lodash.isEmpty(rowData.EventPosterUrl) ? null : rowData.EventPosterUrl,
            ":eventAudioUrl": lodash.isEmpty(rowData.EventAudioUrl) ? null : rowData.EventAudioUrl,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In createOrUpdateStreamRecord(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        // conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                // conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In createOrUpdateStreamRecord(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In createOrUpdateStreamRecord(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In createOrUpdateStreamRecord(): response is: ', JSON.stringify(response, null, 2));
            // setShowBlogDetailsFormFlag(false);
            // setUploadFlag(false);

            // Show Snackbar
            setSnackbarsVariant("success");
            setSnackbarsMsg(operationSuccessMsg);
            setSnackbarOpen(true);

            // initializeForm();
            // const now = moment();
            // let remainTime = now.diff(moment(formattedTime), "minutes");
            // console.log("In createOrUpdateStreamRecord(): remainTime is: ", remainTime + " minutes");

            let records = [];
            rowData.LiveEventStarted = true;
            records = blogRecords.filter(item => item.LiveEventId !== rowData.LiveEventId);
            if (records) {
                records.push(rowData);
                records.sort((a, b) => (a.BlogStartTime > b.BlogStartTime ? 1 : -1));
                setBlogRecords(records);
            }

            backdropProgress(false);
        }).catch((err) => {
            console.error("In createOrUpdateStreamRecord(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }

    async function updateLiveEventStatus(rowData) {
        // console.log("In updateLiveEventStatus(): rowData.CobPK is: ", rowData.CobPK);
        // console.log("In updateLiveEventStatus(): rowData.CobSK is: ", rowData.CobSK);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
                
        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: rowData.CobPK,
            CobSK: rowData.CobSK,
        };
        // console.log("In updateLiveEventStatus(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            // "LanguageObject = :languageObject, " +
            // "LanguageCode = :languageCode, " +

            "LiveEventStarted = :liveEventStarted, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
            // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,

            ":liveEventStarted": true,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In updateLiveEventStatus(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In updateLiveEventStatus(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In updateLiveEventStatus(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In updateLiveEventStatus(): response is: ', JSON.stringify(response, null, 2));
            setShowBlogDetailsFormFlag(false);
            setUploadFlag(false);

            // Show Snackbar
            setSnackbarsVariant("success");
            setSnackbarsMsg(operationSuccessMsg);
            setSnackbarOpen(true);

            // initializeForm();

            backdropProgress(false);

            await fetchBlogRecords(language, domain);
            setShowBlogListFlag(true);
        }).catch((err) => {
            console.error("In updateLiveEventStatus(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }

    async function forcedToEndUpdate(rowData, showStatus) {
        // console.log("In forcedToEndUpdate(): rowData.CobPK is: ", rowData.CobPK);
        // console.log("In forcedToEndUpdate(): rowData.CobSK is: ", rowData.CobSK);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        // console.log("In forcedToEndUpdate(): Validation passed.");
            
        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: rowData.CobPK,
            CobSK: rowData.CobSK,
        };
        // console.log("In forcedToEndUpdate(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            // "LanguageObject = :languageObject, " +
            // "LanguageCode = :languageCode, " +

            "ForcedToEndFlag = :forcedToEndFlag, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
            // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,

            ":forcedToEndFlag": true,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In forcedToEndUpdate(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In forcedToEndUpdate(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In forcedToEndUpdate(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In forcedToEndUpdate(): response is: ', JSON.stringify(response, null, 2));
            setShowBlogDetailsFormFlag(false);
            setUploadFlag(false);

            // Show Snackbar
            if (showStatus) {
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);
            }

            // initializeForm();

            backdropProgress(false);

            await fetchBlogRecords(language, domain);
            setShowBlogListFlag(true);
        }).catch((err) => {
            console.error("In forcedToEndUpdate(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }

    async function startEventAction(rowData) {
        // console.log("In startEventAction(): rowData is: ", JSON.stringify(rowData));

        if (rowData) {
            // console.log("In startEventAction(): rowData.BlogCategory is: ", rowData.BlogCategory);
            setGlobalErrorFlag(false);
            // sessionStorage.setItem("current-live-event", JSON.stringify(rowData));
            // let diff2 = moment(rowData.BlogCategory).fromNow();
            // console.log("In startEventAction(): diff2 is: ", diff2);

            let diff = moment(rowData.BlogStartTime).diff(moment(), "minutes");
            // console.log("In startEventAction(): diff is: ", diff);

            if (diff <= appContext.liveEventLeadTime) {
                setEventStartedFlag(true);
                setStartedEventId(rowData.LiveEventId);

                await createOrUpdateStreamRecord(rowData);
                await updateLiveEventStatus(rowData);
            } else {
                // Show Snackbar
                setSnackbarsVariant("error");
                setSnackbarsMsg(startEventInfoMsg);
                setSnackbarOpen(true);
            }
        }
    }

    async function endEventAction(rowData) {
        // console.log("In endEventAction(): rowData.LiveEventId is: ", rowData.LiveEventId);

        await forcedToEndUpdate(rowData, true);
    }

    // function MobileEventStatus(props) {
    //     // console.log("In MobileEventStatus(): props.rowData is: ", props.rowData);
    //     // let liveEventStatus = sessionStorage.getItem("live-event-status");
    //     // console.log("In MobileEventStatus(): BlogTitle is: ", rowData.BlogTitle);

    //     const {
    //         rowData,
    //     } = props;

    //     if (rowData) {
    //         let eventStarted = rowData.LiveEventStarted;
    //         // console.log("In MobileEventStatus(): eventStarted is: ", eventStarted);
    
    //         let forcedToEnd = rowData.ForcedToEndFlag;
    //         // console.log("In MobileEventStatus(): forcedToEnd is: ", forcedToEnd);
    
    //         let currentTime = moment();
    //         let startTime = moment(rowData.BlogStartTime).subtract(parseInt(appContext.liveEventLeadTime), 'minutes');
    //         // let startTime = moment(rowData.BlogStartTime);
    //         let endTime = moment(rowData.BlogCategory);
    
    //         // console.log("In MobileEventStatus(): currentTime is: ", currentTime);
    //         // console.log("In MobileEventStatus(): startTime is: ", startTime);
    //         // console.log("In MobileEventStatus(): endTime is: ", endTime);
    
    //         if (forcedToEnd) {
    //             return  (
    //                 <CheckIcon style={{color: 'green'}}/>
    //             );
    //         }
    
    //         if (currentTime.isAfter(startTime) && currentTime.isSameOrBefore(endTime)) {
    //             if (eventStarted) {
    //                 return (
    //                     <>
    //                         <LinearProgress color="secondary" style={{background: '#ff9800'}} />
    
    //                         <Button size="small" disabled={false} color="primary" onClick={() => endEventAction(rowData)}>
    //                             {endLabel}
    //                         </Button>
    //                     </>
    //                 );
    //             } else {// Event has not started. Give user opportunity to start the event.
    //                 return (
    //                     <>
    //                         <Typography variant="caption" color="secondary">
    //                             {notStartedLabel}
    //                         </Typography>
    
    //                         <Button variant="contained" size="small" disabled={false} color="primary" onClick={() => startEventAction(rowData)}>
    //                             {startLabel}
    //                         </Button>
    //                     </>
    //                 );
    //             }
    //         } else if (currentTime.isBefore(startTime)) {
    //             if (!eventStarted) {
    //                 // Event not started and It is not yet time to start the event. 
    //                 // Give user the opportunity to start the event
    //                 return (
    //                     <Button variant="contained" size="small" disabled={false} color="primary" onClick={() => startEventAction(rowData)}>
    //                         {startLabel}
    //                     </Button>
    //                 );
    //             }
    //         } else if (currentTime.isAfter(endTime)) {
    //             // Event should have started and ended
    //             if (eventStarted) {
    //                 // Silently mark event as ended
    //                 forcedToEndUpdate(rowData, false);
                    
    //                 return (
    //                     <CheckIcon style={{color: 'green'}}/>
    //                 );
    //             } else {
    //                 return (
    //                     <Typography variant="caption" color="secondary">
    //                         {notStartedLabel}
    //                     </Typography>
    //                 );
    //             }
    //         }
    //     }
    //     return null;
    // }
    
    // function sortDateTimeField(event) {
    //     // console.log("In sortDateTimeField(): event is: ", event);

    //     var values = [...blogRecords];
    //     values.sort((data1, data2) => {
    //         // Note: We are using actual DateTime object and not the dateTimeFormatted 
    //         // used for display on data table.
    //         let value1 = data1.CreatedTimestamp;
    //         let value2 = data2.CreatedTimestamp;
    //         let result = null;

    //         if (value1 == null && value2 != null)
    //             result = -1;
    //         else if (value1 != null && value2 == null)
    //             result = 1;
    //         else if (value1 == null && value2 == null)
    //             result = 0;
    //         else if (typeof value1 === 'string' && typeof value2 === 'string')
    //             result = value1.localeCompare(value2);
    //         else
    //             result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

    //         return (event.order * result);
    //     });

    //     return values;
    // }
    
    function sortDateField(event) {
        // console.log("In sortDateField(): event is: ", event);

        var values = [...blogRecords];
        values.sort((data1, data2) => {
            // Note: We are using actual DateTime object and not the dateTimeFormatted 
            // used for display on data table.
            let value1 = data1.StartTime;
            let value2 = data2.StartTime;
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });

        return values;
    }
    
    // function sortTimeField(event) {
    //     // console.log("In sortTimeField(): event is: ", event);

    //     var values = [...blogRecords];
    //     values.sort((data1, data2) => {
    //         // Note: We are using actual DateTime object and not the dateTimeFormatted 
    //         // used for display on data table.
    //         let value1 = data1.BlogCategory;
    //         let value2 = data2.BlogCategory;
    //         let result = null;

    //         if (value1 == null && value2 != null)
    //             result = -1;
    //         else if (value1 != null && value2 == null)
    //             result = 1;
    //         else if (value1 == null && value2 == null)
    //             result = 0;
    //         else if (typeof value1 === 'string' && typeof value2 === 'string')
    //             result = value1.localeCompare(value2);
    //         else
    //             result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

    //         return (event.order * result);
    //     });

    //     return values;
    // }

    let processedRecs = 0;

    async function deleteMultipleAction() {
        // console.log("In deleteMultipleAction(): selectedTableRows.length is: ", selectedTableRows.length);
        // console.log("In deleteMultipleAction(): selectedTableRows is: ", selectedTableRows);

        // Disable Save changes button
        setTableDirtyFlag(false);

        return new Promise((resolve, reject) => {
            if (selectedTableRows && selectedTableRows.length > 0) {
                selectedTableRows.map(record => {
                    setTimeout(async () => {
                        await deleteService(record);

                        processedRecs++;

                        if (processedRecs === selectedTableRows.length) {
                            resolve("Done");
                        }
                    }, 1000);
                });
            }
        });
    }

    async function processMultipleDeletionRecs() {
        // console.log("In processMultipleDeletionRecs()");

        //-------------------
        // Promise.all
        //-------------------
        Promise.all(
            [
                deleteMultipleAction()
            ]).then(async (results) => {
                // console.log("In processMultipleDeletionRecs(): results is: ", results);
                // console.log("In processMultipleDeletionRecs(): records deleted from AWS, now delete them from DataTable");
                // Remove deleted records from DataTable
                let remainingRecords = FilterArrayFromArray(blogRecords, selectedTableRows);
                if (remainingRecords) {
                    setBlogRecords(remainingRecords);
                    setSelectedTableRows([]);
                }
            });
    }

    function cloneEventAction() {
        setBlogStartTime(null);
        setBlogCategory(null);
        setEditModeFlag(false);
        setSubmitButtonFlag(false);
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        setBlogContent(item.BlogContent);

        setRecordToEdit(item);

        appContext.currentPK = item.BlogPK;
        appContext.currentSK = item.BlogSK;
        await getBlog({BlogPK: item.BlogPK, BlogSK: item.BlogSK,});

        setTimeout(() => {
            setShowBlogListFlag(false);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);
            setShowBlogDetailsFormFlag(true);
        }, 500);
    }
  
    function sliceUrl(url, maxChars) {
        let retValue = url;
        if (url.length > maxChars) {
            retValue = url.substring(0, (maxChars - 1));
            retValue += " ...";
        }
        return retValue;
    }

    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    //--------------------------
    // For CommonMediaSelector
    //--------------------------
    const [dialogOpenFlag, setDialogOpenFlag] = useState(false);
    const [imagesLocationUri, setImagesLocationUri] = useState("");
    
    function onRowSelectedCallback(src) {
        // console.log("In onRowSelectedCallback(): src is: ", src);
        // console.log("In onRowSelectedCallback(): editorInstance is: ", editorInstance);
        // editorInstance.html.insert('<img src="'+ src + '" style="width: 300px;" class="fr-fic fr-dib">');
        setBlogBannerUrl(src ? src : "");
        setDialogOpenFlag(false);
    }

    function dialogCloseCallback() {
        // console.log("In dialogCloseCallback()");
        setDialogOpenFlag(false);
    }

    function selectImageAction() {
        // Open CommonMediaSelector
        setDialogOpenFlag(true);
        // setImageSelectorFlag(true);
    }

    //------------------------
    // For sliceText and Dialog
    //------------------------

    function sliceMoreAction(record) {
        setDialogData(record.BlogContent);
        setDialogHeader(record.BlogTitle);
        setDlgVisibleFlag(true);
    }

    function hideModalDlg() {
        setDlgVisibleFlag(false);
    }
  
    function sliceText(text, maxChars) {
        let retValue = text;
        if (text.length > maxChars) {
            retValue = text.substring(0, (maxChars - 1));
            retValue += " ...";
        }
        return retValue;
    }

    function LanguageAndDomain(props) {
        return (
            <>
                {isDeskTopView &&
                    <>
                        <Grid item xs={12} sm={6}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                        <label htmlFor="languageId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                *{languageLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="languageId"
                                            value={language}
                                            options={languages}
                                            getOptionLabel={option => option.languageName}
                                            getOptionSelected={option => option.languageName}
                                            onChange={handleLanguageChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={languagePrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={languageErrFlag}
                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                        <label htmlFor="domainId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                *{domainLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="domainId"
                                            value={domain}
                                            options={domainOptions}
                                            getOptionLabel={option => option.text}
                                            getOptionSelected={option => option.text}
                                            onChange={handleDomainChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={domainPrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={domainErrFlag}
                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }

                {isTabletView &&
                    <div>
                        <Grid item xs={12} sm={12}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                        <label htmlFor="languageId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                *{languageLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="languageId"
                                            value={language}
                                            options={languages}
                                            getOptionLabel={option => option.languageName}
                                            getOptionSelected={option => option.languageName}
                                            onChange={handleLanguageChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={languagePrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={languageErrFlag}
                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <LineSpacing value={12} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                        <label htmlFor="domainId" style={directionAlignment}>
                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                *{domainLabel}
                                            </Typography>
                                        </label>

                                        <Autocomplete
                                            id="domainId"
                                            value={domain}
                                            options={domainOptions}
                                            getOptionLabel={option => option.text}
                                            getOptionSelected={option => option.text}
                                            onChange={handleDomainChange}
                                            loadingText={loadingText}
                                            noOptionsText={noOptionsText}
                                            autoHighlight={true}
                                            clearText={clearText}
                                            closeText={closeText}
                                            // disabled={editModeFlag ? true : false}
                                            renderInput={params => 
                                                <TextField 
                                                    {...params} 
                                                    placeholder={domainPrompt} 
                                                    variant="outlined"
                                                    fullWidth
                                                    error={domainErrFlag}
                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                    // InputProps={{
                                                    //     readOnly: editModeFlag ? true : false,
                                                    // }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                }

                {isMobileView &&
                    <div className={clsx(classes.divPadding, classes.divWrapper)}>
                        <Grid container justify="center">
                            <Grid item xs={12} sm={6}>
                                <Grid container justify="center">
                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                            <label htmlFor="languageId" style={directionAlignment}>
                                                <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                    *{languageLabel}
                                                </Typography>
                                            </label>

                                            <Autocomplete
                                                id="languageId"
                                                value={language}
                                                options={languages}
                                                getOptionLabel={option => option.languageName}
                                                getOptionSelected={option => option.languageName}
                                                onChange={handleLanguageChange}
                                                loadingText={loadingText}
                                                noOptionsText={noOptionsText}
                                                autoHighlight={true}
                                                clearText={clearText}
                                                closeText={closeText}
                                                disabled={editModeFlag ? true : false}
                                                renderInput={params => 
                                                    <TextField 
                                                        {...params} 
                                                        placeholder={languagePrompt} 
                                                        variant="outlined"
                                                        fullWidth
                                                        error={languageErrFlag}
                                                        helperText={languageErrFlag ? languageErrMsg : ""}
                                                        // InputProps={{
                                                        //     readOnly: editModeFlag ? true : false,
                                                        // }}
                                                    />
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Grid container justify="center">
                                    <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                        <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                            <label htmlFor="domainId" style={directionAlignment}>
                                                <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                    *{domainLabel}
                                                </Typography>
                                            </label>

                                            <Autocomplete
                                                id="domainId"
                                                value={domain}
                                                options={domainOptions}
                                                getOptionLabel={option => option.text}
                                                getOptionSelected={option => option.text}
                                                onChange={handleDomainChange}
                                                loadingText={loadingText}
                                                noOptionsText={noOptionsText}
                                                autoHighlight={true}
                                                clearText={clearText}
                                                closeText={closeText}
                                                // disabled={editModeFlag ? true : false}
                                                renderInput={params => 
                                                    <TextField 
                                                        {...params} 
                                                        placeholder={domainPrompt} 
                                                        variant="outlined"
                                                        fullWidth
                                                        error={domainErrFlag}
                                                        helperText={domainErrFlag ? domainErrMsg : ""}
                                                        // InputProps={{
                                                        //     readOnly: editModeFlag ? true : false,
                                                        // }}
                                                    />
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                }
            </>
        );
    }

    const cloneRecordAction = () => {
        setLanguage(null);
        setBlogCategory(null);
        setBlogStartTime(null);
        setBlogTitle("");

        setSubmitButtonFlag(false);
        setEditModeFlag(false);
    }

    //====================RETURN=====================================================================================

    return (
        <div dir={direction} className={clsx(classes.root, classes.divWrapper)}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {renderDomFlag &&
                <>
                    {/* { !appContext.signedIn && <Redirect to='/signIn' />} */}
                    <CssBaseline />

                    <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />

                    {isDeskTopView && 
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
                            <Container component="div" className={classes.main} maxWidth="lg">
                                <StyledPaper elevation={3} className={classes.paper}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h5" color="primary" style={{ textAlign: "center" }}>
                                                {manageBlogsLabel}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                                                {requiredFields}
                                            </Typography>
                                        </Grid>

                                        {globalErrorFlag && (
                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={24} />
                                                <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                {showBlogListFlag &&
                                                    <>
                                                        <LanguageAndDomain/>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            {blogRecords && blogRecords.length > 0 ?
                                                                <>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-start">
                                                                                    <Button variant="contained" size="large" disabled={selectedTableRows && selectedTableRows.length > 0 ? false : true} color="primary" onClick={() => showAlertDialog(null, "multiple")}>
                                                                                        <DeleteIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {deleteLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={convertAction}>
                                                                                        <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {convertLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={uploadAction}>
                                                                                        <CloudUploadIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {uploadLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-end">
                                                                                    <Button variant="contained" size="large" disabled={false} color="primary" onClick={createMoreAction}>
                                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {createMoreLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12}>
                                                                        <LineSpacing value={8} />
                                                                    </Grid>
                                                                    
                                                                    {/* {blogRecords.map((record, index) => ( */}
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12}>
                                                                            <div className="datatable-doc-demo">
                                                                                <DataTable 
                                                                                    value={blogRecords}
                                                                                    resizableColumns={true} 
                                                                                    columnResizeMode={"fit"}
                                                                                    sortMode={"multiple"}
                                                                                    responsive={true}
                                                                                    reorderableColumns={true}

                                                                                    // selectionMode={"multiple"}
                                                                                    selection={selectedTableRows}
                                                                                    onSelectionChange={handleMultipleSelectionChange}

                                                                                    // paginator={visibleRowsCount > 10 ? paginatorFlag : !paginatorFlag} 
                                                                                    paginator={blogRecords && blogRecords.length > 10} 
                                                                                    // paginatorLeft={paginatorLeft}

                                                                                    paginatorTemplate={paginatorTemplate}
                                                                                    currentPageReportTemplate={"{first}" + " " + toLabel + " " + "{last}" + " " + ofLabel + " " + "{totalRecords}" + " " + recordsLabel} 
                                                                                    rows={visibleRowsCount} 
                                                                                    rowsPerPageOptions={rowsPerPageOptions}
                                                                                    // className="p-datatable-responsive-cob"
                                                                                    // className="p-datatable-striped"
                                                                                    emptyMessage={noRecordsFound}
                                                                                    className={clsx("p-datatable-striped", "p-datatable-customers")}
                                                                                    rowHover 
                                                                                    >

                                                                                    <Column 
                                                                                        header={deleteLabel} 
                                                                                        selectionMode="multiple" 
                                                                                        style={{width:'5em', textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="BlogCategory" 
                                                                                        header={blogCategoryLabel} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="AuthorNames" 
                                                                                        header={gridNames} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="BlogTitle" 
                                                                                        header={gridTitle} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="Tags" 
                                                                                        header={blogTagsLabel} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains"
                                                                                        style={{textAlign:'center'}} 
                                                                                    />
                                                                                    <Column 
                                                                                        field="FormattedStartTime" 
                                                                                        header={gridStartTime} 
                                                                                        sortable={true} 
                                                                                        sortFunction={sortDateField}
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="BannerUrl" 
                                                                                        header={gridBannerUrl} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    {/* <Column 
                                                                                        field="BlogContent" 
                                                                                        header={blogContentLabel} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    /> */}
                                                                                    <Column 
                                                                                        field="deleteIcon" 
                                                                                        header={deleteLabel} 
                                                                                        body={deleteIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="editIcon" 
                                                                                        header={editLabel} 
                                                                                        body={editIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                </DataTable>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            :
                                                                <>
                                                                    {noBlogRecordFoundFlag &&
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={12} sm={12}>
                                                                                <Typography variant="body1">
                                                                                    {noBlogRecordsFound}
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <LineSpacing value={12} />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <Button variant="contained" size="large" disabled={false} color="primary" onClick={createLiveEventAction}>
                                                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                    {createBlogContentLabel}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                            }
                                                        </Grid>
                                                    </>
                                                }

                                                {showBlogDetailsFormFlag && 
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={6} sm={3}>
                                                                    <Grid container justify="flex-end">
                                                                        <Typography variant="subtitle1" color="primary">
                                                                            {yourTimeZoneMsg}: <span style={{color: '#000', paddingLeft: '8px'}}>{timeZone}</span>
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={6} sm={3}>
                                                                    <Grid container justify="center">
                                                                        <Button variant="contained" size="small" disabled={editModeFlag ? false : true} color="primary" onClick={cloneRecordAction}>
                                                                            {cloneRecordLabel}
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={12} sm={6}>
                                                                    <Grid container justify="center">
                                                                        <img src={blogBannerUrl} style={{height: '100px'}} />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <LanguageAndDomain/>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={blogCategoryErrFlag}>
                                                                        <label htmlFor="blogCategoryId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={blogCategoryErrFlag ? "secondary" : "inherit"}>
                                                                                *{blogCategoryLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="blogCategoryId"
                                                                            value={blogCategory}
                                                                            options={categoryOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleBlogCategoryChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            // disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={blogCategoryPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={blogCategoryErrFlag}
                                                                                    helperText={blogCategoryErrFlag ? blogCategoryErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={blogBannerUrlErrFlag}>
                                                                        <label htmlFor="blogBannerUrlId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={blogBannerUrlErrFlag ? "secondary" : "inherit"}>
                                                                                {blogBannerUrlLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <OutlinedInput
                                                                            id="blogBannerUrlId"
                                                                            value={blogBannerUrl}
                                                                            placeholder={blogBannerUrlPrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={blogBannerUrlErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            endAdornment={
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        aria-label={blogBannerUrlPrompt}
                                                                                        onClick={selectImageAction}
                                                                                    >
                                                                                        <ImageSearchIcon />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            }
                                                                        />
                                                                        <FormHelperText>{blogBannerUrlErrFlag ? blogBannerUrlErrMsg : ""}</FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={yourNamesErrFlag}>
                                                                        <label htmlFor="yourNamesId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={yourNamesErrFlag ? "secondary" : "inherit"}>
                                                                                *{yourNamesLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <TextField
                                                                            id="yourNamesId"
                                                                            value={yourNames}
                                                                            placeholder={yourNamesPrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={yourNamesErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            helperText={yourNamesErrFlag ? yourNamesErrMsg : yourNamesMsg}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={blogTitleErrFlag}>
                                                                        <label htmlFor="blogTitleId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={blogTitleErrFlag ? "secondary" : "inherit"}>
                                                                                *{blogTitleLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <TextField
                                                                            id="blogTitleId"
                                                                            value={blogTitle}
                                                                            placeholder={blogTitlePrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={blogTitleErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            helperText={blogTitleErrFlag ? blogTitleErrMsg : ""}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={blogStartTimeErrFlag}>
                                                                                <label htmlFor="blogStartTimeId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={blogStartTimeErrFlag ? "secondary" : "inherit"}>
                                                                                        *{blogStartTimeLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <KeyboardDateTimePicker
                                                                                    id="blogStartTimeId"
                                                                                    value={blogStartTime}
                                                                                    // views={["year"]}
                                                                                    onChange={handleBlogStartTimeChange}
                                                                                    clearable
                                                                                    placeholder={blogStartTimePrompt}
                                                                                    format="MMM DD, YYYY hh:mm a z"
                                                                                    // inputVariant="outlined"
                                                                                    invalidDateMessage={blogStartTimeErrMsg}
                                                                                    cancelLabel={cancelLabel}
                                                                                    clearLabel={clearLabel}
                                                                                    okLabel={okLabel}
                                                                                    // className={classes.phone}
                                                                                    inputVariant="outlined"
                                                                                    margin="none"
                                                                                />
                                                                                <FormHelperText>{blogStartTimeErrFlag ? blogStartTimeErrMsg : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={blogTagsErrFlag}>
                                                                                <label htmlFor="blogTagsId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={blogTagsErrFlag ? "secondary" : "inherit"}>
                                                                                        *{blogTagsLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextareaAutosize
                                                                                    id="blogTagsId"
                                                                                    aria-label={blogTagsLabel}
                                                                                    rows={1.5}
                                                                                    rowsMax={6}
                                                                                    value={blogTags}
                                                                                    placeholder={blogTagsPrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    // autoFocus={true}
                                                                                    margin="none"
                                                                                    className={classes.textArea}
                                                                                    maxLength={textMaxLength}
                                                                                />
                                                                                <FormHelperText>{blogTagsErrFlag ? blogTagsErrMsg : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={blogContentErrFlag}>
                                                                        <label htmlFor="blogContentId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={blogContentErrFlag ? "secondary" : "inherit"}>
                                                                                *{blogContentLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <FroalaCommonEditor
                                                                            editorId={"blogContentId"}
                                                                            editorRef={editorRef}
                                                                            // editorType={"minimal"} // Full Editor when not set
                                                                            placeholderPrompt={blogContentPrompt}
                                                                            editorMaxChars={editorMaxChars}
                                                                            editorModel={blogContent}
                                                                            modelChangeCallback={handleEditorHtmlBodyChange}
                                                                            referrer={"BlogCapture"}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={blogService}>
                                                                    {editModeFlag ?
                                                                    <>
                                                                        <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {updateBlogContentLabel} 
                                                                    </>
                                                                    : 
                                                                    <>
                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {createBlogContentLabel}
                                                                    </>
                                                                    }
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {uploadFlag && 
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeUploadForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <DomainUploadCapture 
                                                                showHeadersFlag={false}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                {convertFlag &&
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <CommonConverter 
                                                                groupingCategoryLabel={blogTitleLabel}
                                                                groupingCategoryPrompt={blogTitlePrompt}
                                                                groupingCategoryErrMsg={blogTitleErrMsg}

                                                                showHeadersFlag={false}
                                                                actionMsgLabel={uploadLabel}

                                                                referrer={"BlogCapture"}
                                                                categoryType={"blog-titles"}

                                                                tableName={tableName}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={18} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledPaper>
                            </Container>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={48} />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    }

                    {isTabletView && 
                        <BlogCaptureTv
                            manageBlogsLabel={manageBlogsLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            blogBannerUrlLabel={blogBannerUrlLabel}
                            blogBannerUrlPrompt={blogBannerUrlPrompt}
                            blogBannerUrlErrMsg={blogBannerUrlErrMsg}
                                
                            yourNamesLabel={yourNamesLabel}
                            yourNamesPrompt={yourNamesPrompt}
                            yourNamesErrMsg={yourNamesErrMsg}
                            yourNamesMsg={yourNamesMsg}
                            
                            blogTitleLabel={blogTitleLabel}
                            blogTitlePrompt={blogTitlePrompt}
                            blogTitleErrMsg={blogTitleErrMsg}
                            selectTitlePrompt={selectTitlePrompt}
                    
                            blogTagsLabel={blogTagsLabel}
                            blogTagsPrompt={blogTagsPrompt}
                            blogTagsErrMsg={blogTagsErrMsg}
                        
                            blogStartTimeLabel={blogStartTimeLabel}
                            blogStartTimePrompt={blogStartTimePrompt}
                            blogStartTimeErrMsg={blogStartTimeErrMsg}
                    
                            blogCategoryLabel={blogCategoryLabel}
                            blogCategoryPrompt={blogCategoryPrompt}
                            blogCategoryErrMsg={blogCategoryErrMsg}
                    
                            // uploadBlogTitleLabel={uploadBlogTitleLabel}
                            // uploadBlogTitlePrompt={uploadBlogTitlePrompt}
                            // uploadBlogTitleErrMsg={uploadBlogTitleErrMsg}
                    
                            // blogRecordsLabel={blogRecordsLabel}
                            // inProgressLabel={inProgressLabel}
                            // startLabel={startLabel}
                            // endLabel={endLabel}
                            // notStartedLabel={notStartedLabel}
                            // dateCreatedLabel={dateCreatedLabel}
                        
                            blogContentLabel={blogContentLabel}
                            blogContentPrompt={blogContentPrompt}
                            yourTimeZoneMsg={yourTimeZoneMsg}

                            // timeZoneLabel={timeZoneLabel}
                            // toLabel={toLabel}
                            // ofLabel={ofLabel}
                            // recordsLabel={recordsLabel}
                            // noRecordsFound={noRecordsFound}
                            // statusLabel={statusLabel}
                            // editLabel={editLabel}
                            // deleteLabel={deleteLabel}
                    
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            cancelLabel={cancelLabel}
                            clearLabel={clearLabel}
                            okLabel={okLabel}
                            uploadLabel={uploadLabel}
                            createBlogContentLabel={createBlogContentLabel}
                            updateBlogContentLabel={updateBlogContentLabel}
                            // blogRecordLabel={blogRecordLabel}
                            createMoreLabel={createMoreLabel}
                            createLabel={createLabel}
                            closeLabel={closeLabel}
                            noBlogRecordsFound={noBlogRecordsFound} 
                            // startEventInfoMsg={startEventInfoMsg}
                    
                            gridTitle={gridTitle}
                            gridNames={gridNames}
                            // gridEventUrl={gridEventUrl}
                            // gridEventUrlType={gridEventUrlType}
                            gridStartTime={gridStartTime}
                            gridBannerUrl={gridBannerUrl}
                        
                            convertLabel={convertLabel}

                            sourceLanguageLabel={sourceLanguageLabel}
                            sourceLanguagePrompt={sourceLanguagePrompt}
                            sourceLanguageErrMsg={sourceLanguageErrMsg}

                            targetLanguageLabel={targetLanguageLabel}
                            targetLanguagePrompt={targetLanguagePrompt}
                            targetLanguageErrMsg={targetLanguageErrMsg}

                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            moreLabel={moreLabel}
                            
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showBlogListFlag={showBlogListFlag}
                            blogRecords={blogRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noBlogRecordFoundFlag={noBlogRecordFoundFlag}
                            createLiveEventAction={createLiveEventAction}
                            showBlogDetailsFormFlag={showBlogDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}
                            blogBannerUrl={blogBannerUrl}
                            blogCategoryErrFlag={blogCategoryErrFlag}
                            blogCategory={blogCategory}
                            categoryOptions={categoryOptions}
                            handleBlogCategoryChange={handleBlogCategoryChange}
                            blogBannerUrlErrFlag={blogBannerUrlErrFlag}
                            handleChange={handleChange}
                            selectImageAction={selectImageAction}
                            yourNamesErrFlag={yourNamesErrFlag}
                            yourNames={yourNames}
                            blogTitleErrFlag={blogTitleErrFlag}
                            blogTitle={blogTitle}
                            blogStartTimeErrFlag={blogStartTimeErrFlag}
                            blogStartTime={blogStartTime}
                            handleBlogStartTimeChange={handleBlogStartTimeChange}
                            blogTagsErrFlag={blogTagsErrFlag}
                            blogTags={blogTags}
                            textMaxLength={textMaxLength}
                            blogContentErrFlag={blogContentErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            blogContent={blogContent}
                            handleEditorHtmlBodyChange={handleEditorHtmlBodyChange}
                            submitButtonFlag={submitButtonFlag}
                            blogService={blogService}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            closeConvertForm={closeConvertForm}

                            LanguageAndDomain={LanguageAndDomain}
                            globalErrorMsg={globalErrorMsg}
                        />
                    }

                    {isMobileView && 
                        <BlogCaptureMv
                            manageBlogsLabel={manageBlogsLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            blogBannerUrlLabel={blogBannerUrlLabel}
                            blogBannerUrlPrompt={blogBannerUrlPrompt}
                            blogBannerUrlErrMsg={blogBannerUrlErrMsg}
                                
                            yourNamesLabel={yourNamesLabel}
                            yourNamesPrompt={yourNamesPrompt}
                            yourNamesErrMsg={yourNamesErrMsg}
                            yourNamesMsg={yourNamesMsg}
                            
                            blogTitleLabel={blogTitleLabel}
                            blogTitlePrompt={blogTitlePrompt}
                            blogTitleErrMsg={blogTitleErrMsg}
                            selectTitlePrompt={selectTitlePrompt}
                    
                            blogTagsLabel={blogTagsLabel}
                            blogTagsPrompt={blogTagsPrompt}
                            blogTagsErrMsg={blogTagsErrMsg}
                        
                            blogStartTimeLabel={blogStartTimeLabel}
                            blogStartTimePrompt={blogStartTimePrompt}
                            blogStartTimeErrMsg={blogStartTimeErrMsg}
                    
                            blogCategoryLabel={blogCategoryLabel}
                            blogCategoryPrompt={blogCategoryPrompt}
                            blogCategoryErrMsg={blogCategoryErrMsg}
                    
                            // uploadBlogTitleLabel={uploadBlogTitleLabel}
                            // uploadBlogTitlePrompt={uploadBlogTitlePrompt}
                            // uploadBlogTitleErrMsg={uploadBlogTitleErrMsg}
                    
                            // blogRecordsLabel={blogRecordsLabel}
                            // inProgressLabel={inProgressLabel}
                            // startLabel={startLabel}
                            // endLabel={endLabel}
                            // notStartedLabel={notStartedLabel}
                            // dateCreatedLabel={dateCreatedLabel}
                        
                            blogContentLabel={blogContentLabel}
                            blogContentPrompt={blogContentPrompt}
                            yourTimeZoneMsg={yourTimeZoneMsg}

                            // timeZoneLabel={timeZoneLabel}
                            // toLabel={toLabel}
                            // ofLabel={ofLabel}
                            // recordsLabel={recordsLabel}
                            // noRecordsFound={noRecordsFound}
                            // statusLabel={statusLabel}
                            // editLabel={editLabel}
                            // deleteLabel={deleteLabel}
                    
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            cancelLabel={cancelLabel}
                            clearLabel={clearLabel}
                            okLabel={okLabel}
                            uploadLabel={uploadLabel}
                            createBlogContentLabel={createBlogContentLabel}
                            updateBlogContentLabel={updateBlogContentLabel}
                            // blogRecordLabel={blogRecordLabel}
                            createMoreLabel={createMoreLabel}
                            createLabel={createLabel}
                            closeLabel={closeLabel}
                            noBlogRecordsFound={noBlogRecordsFound} 
                            // startEventInfoMsg={startEventInfoMsg}
                    
                            gridTitle={gridTitle}
                            gridNames={gridNames}
                            // gridEventUrl={gridEventUrl}
                            // gridEventUrlType={gridEventUrlType}
                            gridStartTime={gridStartTime}
                            gridBannerUrl={gridBannerUrl}
                        
                            convertLabel={convertLabel}

                            sourceLanguageLabel={sourceLanguageLabel}
                            sourceLanguagePrompt={sourceLanguagePrompt}
                            sourceLanguageErrMsg={sourceLanguageErrMsg}

                            targetLanguageLabel={targetLanguageLabel}
                            targetLanguagePrompt={targetLanguagePrompt}
                            targetLanguageErrMsg={targetLanguageErrMsg}

                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            moreLabel={moreLabel}
    
                            domainLabel={domainLabel}
                            domainPrompt={domainPrompt}
                            domainErrMsg={domainErrMsg}
                            domainErrFlag={domainErrFlag}
                            domain={domain}
                            domainOptions={domainOptions}
                            handleDomainChange={handleDomainChange}
                                                                    
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showBlogListFlag={showBlogListFlag}
                            blogRecords={blogRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noBlogRecordFoundFlag={noBlogRecordFoundFlag}
                            createLiveEventAction={createLiveEventAction}
                            showBlogDetailsFormFlag={showBlogDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}
                            blogBannerUrl={blogBannerUrl}
                            blogCategoryErrFlag={blogCategoryErrFlag}
                            blogCategory={blogCategory}
                            categoryOptions={categoryOptions}
                            handleBlogCategoryChange={handleBlogCategoryChange}
                            blogBannerUrlErrFlag={blogBannerUrlErrFlag}
                            handleChange={handleChange}
                            selectImageAction={selectImageAction}
                            yourNamesErrFlag={yourNamesErrFlag}
                            yourNames={yourNames}
                            blogTitleErrFlag={blogTitleErrFlag}
                            blogTitle={blogTitle}
                            blogStartTimeErrFlag={blogStartTimeErrFlag}
                            blogStartTime={blogStartTime}
                            handleBlogStartTimeChange={handleBlogStartTimeChange}
                            blogTagsErrFlag={blogTagsErrFlag}
                            blogTags={blogTags}
                            textMaxLength={textMaxLength}
                            blogContentErrFlag={blogContentErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            blogContent={blogContent}
                            handleEditorHtmlBodyChange={handleEditorHtmlBodyChange}
                            submitButtonFlag={submitButtonFlag}
                            blogService={blogService}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            closeConvertForm={closeConvertForm}

                            globalErrorMsg={globalErrorMsg}
                        />
                    }
                </>
            }

            <Dialog 
                id="modalDlgId"
                rtl={appContext.dirction === 'rtl'}
                showHeader={true}
                header={dialogHeader}
                visible={dlgVisibleFlag} 
                maximizable={true}
                maximized={(isTabletView || isMobileView) ? true : false}
                modal={true} 
                onHide={hideModalDlg}
                >
                <Container component="div" className={classes.modalDlgMain} maxWidth="md">
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="flex-start" style={{color: '#000'}}>
                            <FroalaEditorView model={dialogData}/>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>

            <CommonMediaSelector
                referrer={"BlogCapture"}
                mediaLanguageCode={language ? language.languageCode : null}
                mediaDomainName={domain ? domain.value : null}
                mediaActionFlag={showBlogDetailsFormFlag}
                queryType={"media"} // media, audios or videos
                
                dialogOpenFlag={dialogOpenFlag}
                onRowSelectedCallback={onRowSelectedCallback}
                dialogCloseCallback={dialogCloseCallback}
            />

            {alertOpen && 
                <AlertDialog
                    alertOpen={alertOpen}
                    alertClose={alertClose}
                    alertClickAgree={alertClickAgree}
                    alertClickDisagree={alertClickDisagree}

                    dialogSubContent={dialogSubContent}
                />
            }

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={8000}
                onClose={snackbarOnClose}
                ContentProps={{ "aria-describedby": "message-id" }}
                message={<span id="message-id">{snackbarsMsg}</span>}
                variant={snackbarsVariant}
            />

            <footer className={classes.footer}>
                <div>
                    <PageFooter isSignedIn={appContext.signedIn} />
                </div>
            </footer>
        </div>
    )
}

export default BlogCapture;
