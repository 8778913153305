import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
// import FormControl from '@material-ui/core/FormControl';
// import Link from '@material-ui/core/Link';
// import Select from '@material-ui/core/Select';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import Select from 'react-select';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

// Phone
// import ReactPhoneInput from 'react-phone-input-2';
// import "react-phone-input-2/lib/style.css";

// AG-Grid
import { AgGridReact, AgGridColumn } from "ag-grid-react";
//import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

// Amplify
import Amplify, { AUTH, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// import GlobalConstants from '../../utils/GlobalConstants';
// import GeneratePassword from '../../utils/GeneratePassword';
// import GenerateClientId from '../../utils/GenerateClientId';
// import GenerateTransactionId from '../../utils/GenerateTransactionId';
// import GenerateRandomHash from '../../utils/GenerateRandomHash';
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import LineSpacing from "../../components/misc/LineSpacing";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// Renderers
// import NumberCellRenderer from '../../components/renderers/NumberCellRenderer';
import ActionsCellRenderer from "../../components/renderers/ActionsCellRenderer";
// import { create } from 'jss';
// import CurrencyCellRenderer from '../../components/renderers/CurrencyCellRenderer';
// import CountryCellRenderer from '../../components/renderers/CountryCellRenderer';

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    backgroundImage: `url(${"assets/logos/background20.png"})`,
  },
  gridWidth: {
    width: "80%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  main: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  mainForm: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  mainGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  textField: {
    width: "100%",
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  formControlLabel: {
    margin: theme.spacing(1),
  },
  verticalAlignLabel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  textArea: {
    padding: theme.spacing(1),
    borderRadius: "5px",
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
    textAlign: "center !important",
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  phone: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "row",
  },
}));

let localeText = {
  page: "Page",
  to: "to",
  of: "of",
  noRowsToShow: "No records found",
};

// let localeText2 = {
//   page: "daPage",
//   more: "daMore",
//   to: "daTo",
//   of: "daOf",
//   next: "daNexten",
//   last: "daLasten",
//   first: "daFirsten",
//   previous: "daPreviousen",
//   loadingOoo: "daLoading...",
//   selectAll: "daSelect Allen",
//   searchOoo: "daSearch...",
//   blanks: "daBlanc",
//   filterOoo: "daFilter...",
//   applyFilter: "daApplyFilter...",
//   equals: "daEquals",
//   notEqual: "daNotEqual",
//   lessThan: "daLessThan",
//   greaterThan: "daGreaterThan",
//   lessThanOrEqual: "daLessThanOrEqual",
//   greaterThanOrEqual: "daGreaterThanOrEqual",
//   inRange: "daInRange",
//   contains: "daContains",
//   notContains: "daNotContains",
//   startsWith: "daStarts dawith",
//   endsWith: "daEnds dawith",
//   andCondition: "daAND",
//   orCondition: "daOR",
//   group: "laGroup",
//   columns: "laColumns",
//   filters: "laFilters",
//   rowGroupColumns: "laPivot Cols",
//   rowGroupColumnsEmptyMessage: "la drag cols to group",
//   valueColumns: "laValue Cols",
//   pivotMode: "laPivot-Mode",
//   groups: "laGroups",
//   values: "laValues",
//   pivots: "laPivots",
//   valueColumnsEmptyMessage: "la drag cols to aggregate",
//   pivotColumnsEmptyMessage: "la drag here to pivot",
//   toolPanelButton: "la tool panel",
//   noRowsToShow: "la no rows",
//   pinColumn: "laPin Column",
//   valueAggregation: "laValue Agg",
//   autosizeThiscolumn: "laAutosize Diz",
//   autosizeAllColumns: "laAutsoie em All",
//   groupBy: "laGroup by",
//   ungroupBy: "laUnGroup by",
//   resetColumns: "laReset Those Cols",
//   expandAll: "laOpen-em-up",
//   collapseAll: "laClose-em-up",
//   toolPanel: "laTool Panelo",
//   export: "laExporto",
//   csvExport: "la CSV Exportp",
//   excelExport: "la Excel Exporto (.xlsx)",
//   excelXmlExport: "la Excel Exporto (.xml)",
//   pinLeft: "laPin &lt;&lt;",
//   pinRight: "laPin &gt;&gt;",
//   noPin: "laDontPin &lt;&gt;",
//   sum: "laSum",
//   min: "laMin",
//   max: "laMax",
//   none: "laNone",
//   count: "laCount",
//   average: "laAverage",
//   copy: "laCopy",
//   copyWithHeaders: "laCopy Wit hHeaders",
//   ctrlC: "ctrl n C",
//   paste: "laPaste",
//   ctrlV: "ctrl n V"
// };

// For AG-Grid

//------------------------------
// RENDERERS
//------------------------------
const gridOptions = {
  style: "currency", // decimal, currency, percent
  currency: "USD",
  currencyDisplay: "symbol", // symbol, code, name to use a localized currency name such as "dollar"
  useGrouping: true,
  minimumIntegerDigits: 21,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  payload: {},

  getRowHeight: function (params) {
    // console.log("In getRowHeight: params is: ", params);
    // assuming 50 characters per line, working how how many lines we need
    // console.log("In getRowHeight: words is: ", params.data.referenceDesc.length);
    let lines = 36 * (Math.floor(params.data.referenceDesc.length / 45) + 1);
    // console.log("In getRowHeight: lines is: ", lines);
    return lines > 36 ? lines : 36;
  },
};

let createRef = {
  languageCodeLabel: "Language code",
  languageNameLabel: "Language name",
  referenceCodeLabel: "Reference code",
  referenceTypeLabel: "Reference type",
  referenceCodePrompt: "Please enter reference code",
  referenceDescLabel: "Reference description",
  referenceDescPrompt: "Please enter reference description",
  referenceLanguageLabel: "Reference language",
  gridRefreshWarning: "If you change language, grid will refresh.",
  gridTypeRefreshWarning: "If you change type, grid will refresh.",
  selectReferenceLanguage: "Please select a reference language",
  selectReferenceType: "Please select a reference type",
  submit: "Submit",
  deleteLabel: "Delete",
  createReferenceSuccessMsg: "Create references was successful.",
  createReferenceFailureMsg: "Error while creating references.",
  createReferences: "Create reference data",
  addToGrid: "Add to grid",
  deleteSelectedRows: "Delete selected rows",
  allFieldsAreRequired: "All fields are required",
  maxRecordsAllowed:
    "Maximum number of records allowed in grid is {MAX_RECORDS_ALLOWED}.",
  totalNumberRows: "Total number of rows",

  // For Grid
  // gridPage: "Page",
  // gridTo: "to",
  // gridOf: "of",
  noRowsToShow: "No records found",
};

function CreateReferenceData(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [referenceLanguage, setReferenceLanguage] = useState("");
  const [referenceType, setReferenceType] = useState("");
  const [referenceCode, setReferenceCode] = useState("");
  const [referenceDesc, setReferenceDesc] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);
  // const [ initComplete, setInitComplete ] = useState(appContext.signedIn);
  const [payload, setPayload] = useState({});
  const [rowData, setRowData] = useState([]);

  const [langSelectOptions, setLangSelectOptions] = useState([]);
  const [refTypeSelectOptions, setRefTypeSelectOptions] = useState([]);

  // AG-Grid
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [progressFlag, setProgressFlag] = useState(false);
  const [addToGridFlag, setAddToGridFlag] = useState(false);
  const [rowSelectedFlag, setRowSelectedFlag] = useState(false);
  const [gridSubmitFlag, setGridSubmitFlag] = useState(false);
  const [gridRowsCount, setGridRowsCount] = useState(0);
  //const [maxRowsFlag, setMaxRowsFlag] = useState(false);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  // const [ confirmCodeFlag, setConfirmCodeFlag ] = useState(false);
  // const [ changePasswordFlag, setChangePasswordFlag ] = useState(false);
  // const [ theOneTimePasswordFlag, setTheOneTimePasswordFlag ] = useState(false);
  // const [ userNotConfirmedFlag, setUserNotConfirmedFlag ] = useState(false);
  // const [ forgotPasswordFlag, setForgotPasswordFlag ] = useState(false);

  // For Error Flags
  const [referenceLanguageErrFlag, setReferenceLanguageErrFlag] = useState(
    false
  );
  const [referenceCodeErrFlag, setReferenceCodeErrFlag] = useState(false);
  const [referenceCodeFocus, setReferenceCodeFocus] = useState(true);
  const [referenceDescErrFlag, setReferenceDescErrFlag] = useState(false);
  const [referenceDescFocus, setReferenceDescFocus] = useState(false);

  const [redirectSignUpFlag, setRedirectSignUpFlag] = useState(false);
  // const [globalErrorMsg, setGlobalErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  // For Resources
  const [createRefRes, setCreateRefRes] = useState(createRef);
  const [prevLangCode, setPrevLangCode] = useState(
    appContext.currentLanguage.languageCode
  );

  // For Grid Resources
  const [localeTextRes, setLocaleTextRes] = useState(localeText);

  const [renderGridFlag, setRenderGridFlag] = useState(false);

  //console.log('AmplifyConfig is: ', JSON.stringify(AmplifyConfig, null, 2));
  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  const {
    languageCodeLabel,
    languageNameLabel,
    referenceLanguageLabel,
    gridRefreshWarning,
    gridTypeRefreshWarning,
    referenceCodeLabel,
    referenceTypeLabel,
    referenceCodePrompt,
    referenceDescLabel,
    referenceDescPrompt,
    selectReferenceLanguage,
    selectReferenceType,
    submit,
    deleteLabel,
    createReferenceSuccessMsg,
    createReferenceFailureMsg,
    // createReferences,
    addToGrid,
    allFieldsAreRequired,
    maxRecordsAllowed,
    totalNumberRows,
  } = createRefRes;

  function fetchDbResourcesCallback(updatedCreateRef) {
    // console.log("In fetchDbResourcesCallback()");
    // console.log("In fetchDbResourcesCallback(): createReferences is: ", updatedCreateRef.createReferences);

    setCreateRefRes(updatedCreateRef);
    // clearGridData();
  }

  function fetchDbResources(cacheItemKey, callback) {
    let keys = [];
    let objKeys = Object.keys(createRef);
    // console.log("In ManageLanguages(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ManageLanguages(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "languageCodeLabel")
              createRef.languageCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "languageNameLabel")
              createRef.languageNameLabel = item.ResourceDesc;
            if (item.ResourceCode === "referenceLanguageLabel")
              createRef.referenceLanguageLabel = item.ResourceDesc;
            if (item.ResourceCode === "gridRefreshWarning")
              createRef.gridRefreshWarning = item.ResourceDesc;
            if (item.ResourceCode === "gridTypeRefreshWarning")
              createRef.gridTypeRefreshWarning = item.ResourceDesc;

            if (item.ResourceCode === "referenceCodeLabel")
              createRef.referenceCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "referenceCodePrompt")
              createRef.referenceCodePrompt = item.ResourceDesc;
            if (item.ResourceCode === "referenceDescLabel")
              createRef.referenceDescLabel = item.ResourceDesc;
            if (item.ResourceCode === "referenceDescPrompt")
              createRef.referenceDescPrompt = item.ResourceDesc;
            if (item.ResourceCode === "referenceTypeLabel")
              createRef.referenceTypeLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectReferenceLanguage")
              createRef.selectReferenceLanguage = item.ResourceDesc;
            if (item.ResourceCode === "selectReferenceType")
              createRef.selectReferenceType = item.ResourceDesc;

            if (item.ResourceCode === "selectLanguage")
              createRef.selectLanguage = item.ResourceDesc;
            if (item.ResourceCode === "submit")
              createRef.submit = item.ResourceDesc;
            if (item.ResourceCode === "deleteLabel")
              createRef.deleteLabel = item.ResourceDesc;

            if (item.ResourceCode === "createReferenceSuccessMsg")
              createRef.createReferenceSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "createReferenceFailureMsg")
              createRef.createReferenceFailureMsg = item.ResourceDesc;
            if (item.ResourceCode === "allFieldsAreRequired")
              createRef.allFieldsAreRequired = item.ResourceDesc;

            if (item.ResourceCode === "createReferences")
              createRef.createReferences = item.ResourceDesc;
            if (item.ResourceCode === "addToGrid")
              createRef.addToGrid = item.ResourceDesc;
            if (item.ResourceCode === "maxRecordsAllowed")
              createRef.maxRecordsAllowed = item.ResourceDesc;
            if (item.ResourceCode === "totalNumberRows")
              createRef.totalNumberRows = item.ResourceDesc;

            // For Grid
            // if (item.ResourceCode === "gridPage") localeText.page = item.ResourceDesc;
            // if (item.ResourceCode === "gridTo") localeText.to = item.ResourceDesc;
            // if (item.ResourceCode === "gridOf") localeText.from = item.ResourceDesc;
            if (item.ResourceCode === "noRowsToShow")
              localeText.noRowsToShow = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, createRef);
        }

        setCreateRefRes(createRef);
        setLocaleTextRes(localeText);

        setRenderGridFlag(true);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setCreateRefRes(createRef);
        setProgressFlag(false);
      });
  }

  // Maximum number of rows allowed in the grid
  const MAX_RECORDS_ALLOWED = 50;

  // Fetch Reference Type Options
  function fetchReferenceTypeOptions(selectedOption) {
    // console.log('In fetchReferenceTypeOptions(): selectedOption.value is: ', selectedOption.value);

    setProgressFlag(true);

    setRefTypeSelectOptions([]);
    let selectOptions = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "reference-types",
          ":resourceSK": selectedOption,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchReferenceTypeOptions(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchReferenceTypeOptions(): response length is: ', response.data.Items.length);
        // console.log('In fetchReferenceTypeOptions(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            text: item.ResourceDesc,
          };

          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setRefTypeSelectOptions(selectOptions);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchReferenceTypeOptions(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  let selectOptions = [];

  function fetchLanguages() {
    // console.log('In fetchLanguages()');
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages(): response.length is: ', response.data.Items.length);
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            text: item.LanguageName,
          };
          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In useEffect()");

    let currLangCode = appContext.currentLanguage.languageCode;
    // console.log("In useEffect(): currLangCode is, ", currLangCode);
    if (currLangCode !== prevLangCode) {
      setPrevLangCode(currLangCode);

      // Fetch resource from catche
      let cacheItemKey = "createRef";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      fetchDbResources(cacheItemKey, fetchDbResourcesCallback);

      // setCreateRefRes(createRef);
      // clearGridData();
    }
  }, []);

  useLayoutEffect(() => {
    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In CreateReferenceData(): useLayoutEffect(): currentLanguage is: ', currentLanguage);

    if (selectOptions.length === 0) {
      fetchLanguages();
    }

    setReferenceCodeFocus(true);
    setReferenceDescFocus(false);

    // Fetch resource from catche
    let cacheItemKey = "createRef";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ManageLanguages(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ManageLanguages(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ManageLanguages(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ManageLanguages(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey, fetchDbResourcesCallback);
    } else {
      // console.log('In ManageLanguages(): useLayoutEffect(): got resource from cache');
      setCreateRefRes(cacheItem);
    }
  }, []);

  function checkAddToGrid() {
    // console.log('In checkAddToGrid(): referenceLanguage is: ', referenceLanguage);
    // console.log('In checkAddToGrid(): referenceType is: ', referenceType);
    // console.log('In checkAddToGrid(): referenceCode is: ', referenceCode);
    // console.log('In checkAddToGrid(): referenceDesc is: ', referenceDesc);

    // Enable add to grid button
    setAddToGridFlag(false);
    if (
      referenceCode !== undefined &&
      referenceCode !== null &&
      referenceCode !== "" &&
      referenceDesc !== undefined &&
      referenceDesc !== null &&
      referenceDesc !== "" &&
      referenceLanguage !== undefined &&
      referenceLanguage !== null &&
      referenceLanguage !== "" &&
      referenceType !== undefined &&
      referenceType !== null &&
      referenceType !== ""
    ) {
      setAddToGridFlag(true);
    }
  }

  function handleChange(event) {
    // console.log("In handleChange(): id is: "+event.target.id);
    // console.log("In handleChange(): value is: "+event.target.value);
    setGlobalErrorFlag(false);
    setAddToGridFlag(false);

    switch (event.target.id) {
      case "referenceCodeId":
        setReferenceCodeErrFlag(false);
        setReferenceCode(event.target.value);
        break;
      // case "referenceDescId":
      //   setReferenceDescErrFlag(false);
      //   setReferenceDesc(event.target.value);
      //   break;
      default:
        break;
    }

    // Enable add to grid button
    setAddToGridFlag(false);
    if (
      event.target.value !== undefined &&
      event.target.value !== null &&
      event.target.value !== "" &&
      referenceDesc !== undefined &&
      referenceDesc !== null &&
      referenceDesc !== "" &&
      referenceLanguage !== undefined &&
      referenceLanguage !== null &&
      referenceLanguage !== "" &&
      referenceType !== undefined &&
      referenceType !== null &&
      referenceType !== ""
    ) {
      setAddToGridFlag(true);
    }

    // checkAddToGrid();
  }

  function validateForm() {
    let formErrFlag = false;

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setReferenceCodeErrFlag(false);
    setReferenceDescErrFlag(false);

    if (referenceLanguage === null || referenceLanguage === "") {
      formErrFlag = true;
      setReferenceLanguageErrFlag(true);
    }

    if (referenceType === null || referenceType === "") {
      formErrFlag = true;
      setReferenceLanguageErrFlag(true);
    }

    if (referenceCode === null || referenceCode === "") {
      formErrFlag = true;
      setReferenceCodeErrFlag(true);
    }

    if (referenceDesc === null || referenceDesc === "") {
      formErrFlag = true;
      setReferenceDescErrFlag(true);
    }

    return formErrFlag;
  }

  async function referenceDataService() {
    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    // Get Grid data
    const gridRowCount = getRowCount();
    // console.log('In referenceDataService(): gridRowCount is: ', gridRowCount);

    if (gridRowCount > 0) {
      // we have data to save
      // console.log('We have data to save.');

      const rowsData = [];

      // const d = new Date();
      const timestamp = new Date();
      // const timestamp = d.toISOString();
      // console.log("In referenceDataService(): timestamp is: ", timestamp);

      gridApi.forEachNode(function (node) {
        // Process each node (row)
        let rowData = {
          ResourcePK: node.data.referenceTypeCode, // e.g polly
          ResourceSK:
            node.data.referenceLanguageCode + ":" + node.data.referenceCode,
          LanguageCode: node.data.referenceLanguageCode,

          ResourceDesc: node.data.referenceDesc,
          ResourceCode: node.data.referenceCode,

          ModifiedBy: appContext.userName,
          CreatedAt: timestamp,
          UpdatedAt: timestamp,
        };

        rowsData.push(rowData);
      });

      let params = {
        apiName: "ResourceAPI",
        path: "/dynamodb/resources/batchWrite",
        payload: {
          items: rowsData,
        },
      };

      let initObj = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "",
        },
        response: true, // return entire response object instead of response.data
        body: {},
      };

      initObj.headers.Authorization = appContext.jwtToken;
      initObj.body = params.payload;
      // console.log('In referenceDataService(): initObj is: ', JSON.stringify(initObj, null, 2));

      setProgressFlag(true);

      API.post(params.apiName, params.path, initObj)
        .then((response) => {
          // console.log('In referenceDataService(): response is: ', JSON.stringify(response, null, 2));

          // Clear the grid of any data
          clearGridData();

          // Show Snackbar
          setSnackbarsVariant("success");
          setSnackbarsMsg(createReferenceSuccessMsg);
          setSnackbarOpen(true);

          setProgressFlag(false);
        })
        .catch((err) => {
          console.log(
            "In referenceDataService(): err is: ",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setGlobalErrorMsg(createReferenceFailureMsg);

          setProgressFlag(false);
        });
    }
  }

  async function redirectSignUp() {
    setRedirectSignUpFlag(true);
  }

  //===================================================

  gridOptions.payload = payload;

  // Grid Functions

  const deleteRows = (e) => {
    //console.log("In deleteRows(): gridApi is: ", gridApi);
    //console.log("In deleteRows(): gridApi.getSelectedNodes() is: ", gridApi.getSelectedNodes());
    var selectedRows = gridApi.getSelectedRows();
    var res = gridApi.updateRowData({ remove: selectedRows });
    // console.log("In deleteRows(): res is: ", res);

    // Update number of rows in grid
    getRowCount();
    initializeForm();

    // Disable Submit button if need be
    let gridRowsCount = gridApi.getModel().getRowCount();
    if (gridRowsCount === 0) {
      setGridSubmitFlag(false);
    }
  };

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
  }

  const onCellClicked = (event) => {
    // console.log('onCellClicked: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellClicked: event.colDef.field is: ', event.colDef.field);
    if (event.colDef.field === "delete") {
      var selectedData = gridApi.getSelectedRows();
      var res = gridApi.updateRowData({ remove: selectedData });
      // console.log("In onCellClicked() res is: ", res);

      // Update number of rows in grid
      getRowCount();
      initializeForm();

      // Disable Submit button if need be
      let gridRowsCount = gridApi.getModel().getRowCount();
      if (gridRowsCount === 0) {
        setGridSubmitFlag(false);
      }
    }
  };

  const onCellValueChanged = (event) => {
    // console.log('onCellValueChanged: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onCellValueChanged: event.colDef.field is: ', event.colDef.field);
  };

  const onRowSelected = (event) => {
    //console.log('onRowSelected: event is: ', event);
    //console.log('onRowSelected: event.data is: ', JSON.stringify(event.data, null, 2));
    setRowSelectedFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getSelectedNodes().length > 0
    ) {
      setRowSelectedFlag(true);
      // console.log('onRowSelected: after rowSelectedFlag is: ', rowSelectedFlag);
    }
  };

  const onRowClicked = (event) => {
    // console.log('onRowClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onRowClicked: event.data is: ', JSON.stringify(event.data, null, 2));
  };

  const onRowValueChanged = (event) => {
    // console.log('onRowValueChanged: event is:  ', event);
  };

  const onRowDataChanged = (event) => {
    // console.log('onRowDataChanged: event is: ', event);
    setColumnDefs(columnDefsArray);
    setLocaleTextRes(localeText);
    // setCreateRefRes(createRef);
    setGridSubmitFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getModel().getRowCount() > 0
    ) {
      // console.log('onRowDataChanged: gridApi.getModel().getRowCount() is: ', gridApi.getModel().getRowCount());
      setGridSubmitFlag(true);
    }
  };

  const onRowDataUpdated = (event) => {
    // console.log('onRowDataUpdated: event is:  ', event);
  };

  const onFirstDataRendered = (event) => {
    // console.log('onFirstDataRendered: event is:  ', event);
  };

  function clearGridData() {
    if (gridApi) {
      gridApi.setRowData([]);

      getRowCount();

      // Disable Submit button
      setGridSubmitFlag(false);
    }
  }

  function getDridData() {
    var gridData = [];
    gridApi.forEachNode(function (node) {
      gridData.push(node.data);
    });
    // console.log("In getDridData(): Grid Data is: ", gridData);
    return gridData;
  }

  function initializeForm() {
    // console.log("In initializeForm()");

    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    setReferenceCode("");
    setReferenceDesc("");

    setReferenceCodeFocus(true);
  }

  function getRowCount() {
    let gridRowsCount = gridApi.getModel().getRowCount();
    setGridRowsCount(gridRowsCount);
    return gridRowsCount;
  }

  function addRecordToGrid() {
    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");

    if (!validateForm()) {
      // Validation Passed - you can proceed
      // console.log('Validation Passed.');
      setAddToGridFlag(false);
      setGridSubmitFlag(true);

      // Make sure max records allowed in grid is not exceeded
      if (getRowCount() >= MAX_RECORDS_ALLOWED) {
        setGlobalErrorMsg(
          maxRecordsAllowed.replace(
            "{MAX_RECORDS_ALLOWED}",
            MAX_RECORDS_ALLOWED
          )
        );
        setGlobalErrorFlag(true);
        return;
      }

      // Add form data to grid
      let newItem = {
        referenceLanguageCode: referenceLanguage,
        referenceLanguageName: referenceLanguage,
        referenceTypeCode: referenceType,
        referenceTypeName: referenceType,
        referenceCode: referenceCode,
        referenceDesc: referenceDesc,
      };
      var res = gridApi.updateRowData({ add: [newItem] });
      // console.log("In addRecordToGrid(): res is: ", res);

      // Update number of rows in grid
      getRowCount();

      // Initialize Variables
      initializeForm();
    }
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    setSnackbarOpen(false);
  }

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);
    setReferenceLanguage(selectedOption);

    // setReferenceLanguage(selectedOption);
    setReferenceCodeFocus(true);

    setAddToGridFlag(false);
    if (
      referenceCode !== undefined &&
      referenceCode !== null &&
      referenceCode !== "" &&
      referenceDesc !== undefined &&
      referenceDesc !== null &&
      referenceDesc !== "" &&
      referenceType !== undefined &&
      referenceType !== null &&
      selectedOption !== undefined &&
      selectedOption !== null
    ) {
      setAddToGridFlag(true);
    }

    setRefTypeSelectOptions(null);
    setReferenceType("");
    fetchReferenceTypeOptions(selectedOption);
  }

  function handleTypeChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleTypeChange(): selectedOption is', selectedOption);
    setReferenceType(selectedOption);

    setReferenceCodeFocus(true);

    setAddToGridFlag(false);
    if (
      referenceCode !== undefined &&
      referenceCode !== null &&
      referenceCode !== "" &&
      referenceDesc !== undefined &&
      referenceDesc !== null &&
      referenceDesc !== "" &&
      referenceLanguage !== undefined &&
      referenceLanguage !== null &&
      selectedOption !== undefined &&
      selectedOption !== null
    ) {
      setAddToGridFlag(true);
    }
  }

  function handleTextareaChange(event) {
    // console.log('In handleTextareaChange(): event is: ', event.target.value);
    // console.log('In handleTextareaChange(): referenceCode is: ', referenceCode);
    // console.log('In handleTextareaChange(): referenceLanguage is: ', referenceLanguage);
    // console.log('In handleTextareaChange(): referenceType is: ', referenceType);
    setReferenceDesc(null);
    if (event.target.value) {
      setReferenceDesc(event.target.value);
    }

    // console.log('In handleTextareaChange(): undefined is: ', (event.target.value !== undefined));
    // console.log('In handleTextareaChange(): is null is: ', (event.target.value !== null));
    // console.log('In handleTextareaChange(): is empty is: ', (event.target.value.trim() === ""));
    setAddToGridFlag(false);
    if (
      referenceCode !== undefined &&
      referenceCode !== null &&
      referenceCode !== "" &&
      event.target.value !== undefined &&
      event.target.value !== null &&
      event.target.value.trim() !== "" &&
      referenceLanguage !== undefined &&
      referenceLanguage !== null &&
      referenceType !== undefined &&
      referenceType !== null
    ) {
      setAddToGridFlag(true);
    }
  }

  function cellStyle(params) {
    return { "white-space": "normal" };
  }

  //----------------------------
  // AG-GRID Column Definitions
  //----------------------------

  let columnDefsArray = [
    {
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressMenu: true,
      resizable: true,
    },
    {
      headerName: languageCodeLabel,
      headerClass: classes.gridHeader,
      field: "referenceLanguageCode",
      width: 240,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: languageNameLabel,
      headerClass: classes.gridHeader,
      field: "referenceLanguageName",
      width: 250,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: referenceTypeLabel,
      headerClass: classes.gridHeader,
      field: "referenceTypeCode",
      width: 120,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: referenceTypeLabel,
      headerClass: classes.gridHeader,
      field: "referenceTypesCode",
      width: 340,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: referenceTypeLabel,
      headerClass: classes.gridHeader,
      field: "referenceTypeName",
      width: 300,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
    },
    {
      headerName: referenceCodeLabel,
      headerClass: classes.gridHeader,
      field: "referenceCode",
      width: 300,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellStyle: cellStyle,
    },
    {
      headerName: referenceDescLabel,
      headerClass: classes.gridHeader,
      field: "referenceDesc",
      width: 500,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellStyle: cellStyle,
    },
    {
      headerName: deleteLabel,
      field: "delete",
      width: 150,
      filter: false,
      sortable: false,
      resizable: true,
      cellRendererFramework: ActionsCellRenderer,
    },
  ];

  const [columnDefs, setColumnDefs] = useState(columnDefsArray);

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* !appContext.signedIn &&
          <Redirect to='/signIn' />
        */}

        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%", height: "100%" }}>
            {/* progressFlag &&
              <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
              </div>
            */}
            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="md"
            >
              <Grid container justify="center">
                <Grid item xs={12} sm={9}>
                  <StyledPaper elevation={3} className={classes.paper}>
                    <Typography
                      variant="h6"
                      style={{ color: "black", textAlign: "center" }}
                    >
                      {createRefRes.createReferences}
                    </Typography>

                    <Typography
                      variant="caption"
                      style={{ color: "green", textAlign: "center" }}
                    >
                      {allFieldsAreRequired}
                    </Typography>

                    {globalErrorFlag && (
                      <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                    )}

                    <LineSpacing value={24} />

                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="formLanguageId"
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {referenceLanguageLabel}
                        </Typography>
                      </label>
                      <Select
                        id="formLanguageId"
                        value={referenceLanguage}
                        placeholder={selectReferenceLanguage}
                        onChange={handleLanguageChange}
                        variant="outlined"
                        displayEmpty={true}
                        autoFocus
                        fullWidth={true}
                        style={{ width: "100%", flexGrow: 1 }}
                      >
                        <MenuItem value={null} disabled>
                          <Typography variant="body1">
                            {selectReferenceLanguage}
                          </Typography>
                        </MenuItem>
                        {langSelectOptions.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="referenceTypeId"
                        className={classes.verticalAlignLabel}
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {referenceTypeLabel}
                        </Typography>
                      </label>
                      <Select
                        id="referenceTypeId"
                        value={referenceType}
                        placeholder={selectReferenceType}
                        onChange={handleTypeChange}
                        variant="outlined"
                        displayEmpty={true}
                        // autoFocus
                        fullWidth={true}
                        style={{ width: "100%", flexGrow: 1 }}
                      >
                        <MenuItem value={null} disabled>
                          <Typography variant="body1">
                            {selectReferenceType}
                          </Typography>
                        </MenuItem>
                        {refTypeSelectOptions.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <label
                        htmlFor="languageCodeId"
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {referenceCodeLabel}
                        </Typography>
                      </label>
                      <OutlinedInput
                        id="referenceCodeId"
                        value={referenceCode}
                        placeholder={referenceCodePrompt}
                        onChange={handleChange}
                        required
                        fullWidth={true}
                        // autoFocus
                        type={"text"}
                        error={referenceCodeErrFlag}
                        margin="none"
                      />
                    </FormControl>

                    <LineSpacing value={24} />

                    <FormControl
                      className={classes.textField}
                      variant="outlined"
                    >
                      <label
                        htmlFor="resourceDescId"
                        style={directionAlignment}
                      >
                        <Typography variant="subtitle1">
                          {referenceDescLabel}
                        </Typography>
                      </label>
                      <TextareaAutosize
                        id="referenceDescId"
                        aria-label="reference desc"
                        rows={3}
                        rowsMax={6}
                        value={referenceDesc}
                        placeholder={referenceDescPrompt}
                        onChange={handleTextareaChange}
                        required
                        // fullWidth={true}
                        margin="none"
                        className={classes.textArea}
                      />
                      <FormHelperText>{referenceDescErrFlag ? "" : ""}</FormHelperText>
                    </FormControl>

                    <LineSpacing value={18} />

                    <Button
                      variant="contained"
                      size="large"
                      disabled={!addToGridFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={addRecordToGrid}
                    >
                      <AddIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {addToGrid}
                    </Button>
                  </StyledPaper>
                </Grid>
              </Grid>
            </Container>

            {/* AG-GRID */}

            {renderGridFlag && (
              <Container
                component="div"
                className={classes.mainGrid}
                maxWidth="lg"
              >
                <Grid container justify="center">
                  <Grid item xs>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!rowSelectedFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={deleteRows}
                      style={directionFloat}
                    >
                      <DeleteIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {deleteLabel}
                    </Button>
                  </Grid>

                  <Grid item xs>
                    <Button
                      variant="contained"
                      size="large"
                      disabled={!gridSubmitFlag ? true : false}
                      color="primary"
                      className={classes.button}
                      onClick={referenceDataService}
                      style={directionReverseFloat}
                    >
                      <SaveIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {submit}
                    </Button>
                  </Grid>
                </Grid>

                <div style={{ width: "100%", height: "100%" }}>
                  <div
                    id="refGrid"
                    style={{
                      // height: "400px",
                      width: "100%",
                    }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      localeText={localeTextRes}
                      domLayout="autoHeight"
                      onGridReady={onGridReady}
                      columnDefs={columnDefs}
                      rowData={rowData}
                      rowSelection="multiple"
                      rowMultiSelectWithClick={true}
                      rowDeselection={true}
                      enablePivot={true}
                      enableValue={true}
                      gridOptions={gridOptions}
                      enableRtl={enableRtl}
                      onCellClicked={onCellClicked}
                      onCellValueChanged={onCellValueChanged}
                      onRowSelected={onRowSelected}
                      onRowClicked={onRowClicked}
                      onRowValueChanged={onRowValueChanged}
                      onRowDataChanged={onRowDataChanged}
                      onRowDataUpdated={onRowDataUpdated}
                      onFirstDataRendered={onFirstDataRendered}
                    />
                  </div>
                </div>

                <Typography
                  variant="caption"
                  color="primary"
                  style={directionReverseFloat}
                >
                  {totalNumberRows}: {gridRowsCount}
                </Typography>
              </Container>
            )}
          </div>

          {/* variant: 'success', 'warning', 'error', 'info' */}
          <TsiCustomizedSnackbars
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={snackbarOpen}
            autoHideDuration={8000}
            onClose={snackbarOnClose}
            ContentProps={{ "aria-describedby": "message-id" }}
            message={<span id="message-id">{snackbarsMsg}</span>}
            variant={snackbarsVariant}
          />
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter isSignedIn={isSignedIn} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default CreateReferenceData;
