import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from '@material-ui/core/CardMedia';
// import CardActions from '@material-ui/core/CardActions';
import clsx from "clsx";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";

// Mineral-ui
import { FormField } from "mineral-ui/Form";
import { IconLocationOn } from "mineral-ui-icons";
import { IconLaunch } from "mineral-ui-icons";
import { IconPhone } from "mineral-ui-icons";
import { IconSms } from "mineral-ui-icons";
import { IconPrint } from "mineral-ui-icons";
import { IconEmail } from "mineral-ui-icons";
import { IconLink } from "mineral-ui-icons";
import { IconKeyboardArrowDown } from "mineral-ui-icons";
import { IconKeyboardArrowUp } from "mineral-ui-icons";
import Tabs, { Tab } from "mineral-ui/Tabs";
import Text from "mineral-ui/Text";
import { themed } from "mineral-ui/themes";

// Moment
import moment from "moment";
import Moment from "react-moment";
import "moment-timezone";
// import 'moment/locale/fr';

// Phone
// import ReactPhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import mapStyles from "../../utils/maps/mapStyles";

// AppContext
import AppContext from "../../config/AppContext";

// import ContactInfoMap from './ContactInfoMap';
import ContactInfoCustomMap from "./ContactInfoCustomMap";
// import ContactInfoGoogleMaps from './ContactInfoGoogleMaps';
import LineSpacing from "../../components/misc/LineSpacing";

Amplify.configure(AmplifyConfig);

const PageContainer = styled.div`
  width: 100%,
  display: 'flex',
  flexWrap: 'wrap',
  background: #fff;
`;

const TabThemed = themed(Tab)({
  Tab_color: "#fff",
  Tab_borderColor_focus: "tomato",
  Tab_backgroundColor_selected: "tomato",
  Tab_color_selected: "#fff",
  Tab_color_selectedHover: "tomato",
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  progress: {
    margin: theme.spacing(1),
  },
  mainGrid: {
    width: "100%",
    textAlign: "left",
  },
  contInfo: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  hdr: {
    color: "#fff",
  },
  textItem: {
    whiteSpace: "pre-wrap", // Obeys '\n' character
  },
  tomatoColor: {
    color: "Tomato",
    whiteSpace: "pre-wrap", // Obeys '\n' character
  },
  contactGrid: {
    // color: 'red',
    width: "100%",
    fontSize: "12px",
    backgroundColor: "transparent",
  },
  mainGridItems: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  detailGridItems: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  rowGrid: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  card: {
    // marginLeft: theme.spacing(2),
    width: "100%",
  },
  directionItem: {
    marginRight: theme.spacing(1),
    verticalAlign: "middle",
  },
  directionLink: {
    width: "100%",
    textAlign: "center",
  },
  cardAndTab: {
    // marginLeft: theme.spacing(2),
  },
  tabContent: {
    // marginLeft: theme.spacing(1),
  },
  firstColumn: {
    flexBasis: "13%",
  },
  secondColumn: {
    flexBasis: "87%",
  },
  callMeIcon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  phone: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "row",
  },
  tomatoBg: {
    backgroundColor: "Tomato",
  },
  orangeBg: {
    backgroundColor: "Orange",
  },
  dodgerBlueBg: {
    backgroundColor: "DodgerBlue",
  },
  mediumSeaGreenBg: {
    backgroundColor: "MediumSeaGreen",
  },
  grayBg: {
    backgroundColor: "Gray",
  },
  slateBlueBg: {
    backgroundColor: "SlateBlue",
  },
  violetBg: {
    backgroundColor: "Violet",
  },
  lightGrayBg: {
    backgroundColor: "LightGray",
  },
  tealBg: {
    backgroundColor: "Teal",
  },
}));

let contactLabels = {
  viewOfficeHrs: "View hours",
  getDirections: "Get directions",
  pleaseNote: "Please note",
  customerService: "Customer service",
  contacts: "Contact Info",
  support: "Support",
  sales: "Sales",

  closed: "Closed",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",

  whatsAppPhoneLabel: "WhatsApp number",
  searchPlaceholder: "Search countries",
};

function ContactInfo(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  // For ContactInfo
  const [contactLabelsRes, setContactLabelsRes] = useState(contactLabels);
  const [contactInfoData, setContactInfoData] = useState([]);

  const {
    currLanguage,

    cardBackground,
    headerColor,
    iconsColor,
    textColor,
  } = props;

  const classes = useStyles();

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  // Map Options
  let initMapOptions = {
    // Stafford: { lat: 38.436995, lng: -77.425396 }, Ottawa: { lat: 45.4211, lng: -75.6903 } // 38.4326818, -77.4287286
    center: { lat: 38.436995, lng: -77.425396 },
    zoom: 9,
    styles: mapStyles,
  };
  const [mapOptions, setMapOptions] = useState(initMapOptions);
  const [zoom, setZoom] = useState(9);

  // For Tabs
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [downArrowShow, setDownArrowShow] = useState(true);
  const [upArrowShow, setUpArrowShow] = useState(false);

  const [whatsAppPhone, setWhatsAppPhone] = useState("");
  const [whatsAppPhoneErrFlag, setWhatsAppPhoneErrFlag] = useState(false);

  // For Phone/Fax Number Input
  const [defaultPhoneCountry, setDefaultPhoneCountry] = useState(
    appContext.phoneNumber.defaultPhoneCountry
  ); // For phone flag
  const [preferredCountries, setPreferredCountries] = useState(
    appContext.phoneNumber.preferredCountries
  ); //Show these countries at top of dropdown
  const [phoneLocale, setPhoneLocale] = useState(
    appContext.phoneNumber.phoneLocale
  ); // For phone validation
  const [localization, setLocalization] = useState(
    appContext.phoneNumber.localization
  ); // e.g {'Germany': 'Deutschland', 'Spain': 'España'}
  const [enableSearchField, setEnableSearchField] = useState(true);

  const {
    getDirections,

    viewOfficeHrs,
    customerService,
    contacts,
    pleaseNote,
    support,
    sales,

    closed,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,

    whatsAppPhoneLabel,
    searchPlaceholder,
  } = contactLabelsRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(contactLabels);
    // console.log("In ContactInfo(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ContactInfo(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "viewOfficeHrs")
              contactLabels.viewOfficeHrs = item.ResourceDesc;
            if (item.ResourceCode === "getDirections")
              contactLabels.getDirections = item.ResourceDesc;
            if (item.ResourceCode === "pleaseNote")
              contactLabels.pleaseNote = item.ResourceDesc;
            if (item.ResourceCode === "customerService")
              contactLabels.customerService = item.ResourceDesc;
            if (item.ResourceCode === "contacts")
              contactLabels.contacts = item.ResourceDesc;
            if (item.ResourceCode === "support")
              contactLabels.support = item.ResourceDesc;
            if (item.ResourceCode === "sales")
              contactLabels.sales = item.ResourceDesc;

            if (item.ResourceCode === "closed")
              contactLabels.closed = item.ResourceDesc;
            if (item.ResourceCode === "monday")
              contactLabels.monday = item.ResourceDesc;
            if (item.ResourceCode === "tuesday")
              contactLabels.tuesday = item.ResourceDesc;
            if (item.ResourceCode === "wednesday")
              contactLabels.wednesday = item.ResourceDesc;
            if (item.ResourceCode === "thursday")
              contactLabels.thursday = item.ResourceDesc;
            if (item.ResourceCode === "friday")
              contactLabels.friday = item.ResourceDesc;
            if (item.ResourceCode === "saturday")
              contactLabels.saturday = item.ResourceDesc;

            if (item.ResourceCode === "sunday")
              contactLabels.sunday = item.ResourceDesc;

            if (item.ResourceCode === "whatsAppPhoneLabel")
              contactLabels.whatsAppPhoneLabel = item.ResourceDesc;
            if (item.ResourceCode === "searchPlaceholder")
              contactLabels.searchPlaceholder = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, contactLabels);
          setContactLabelsRes(contactLabels);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setContactLabelsRes(contactLabels);
        setProgressFlag(false);
      });
  }

  // Fetch ContactInfo Data from Database
  async function fetchDbContactInfo() {
    // console.log('In fetchDbContactInfo(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);

    setProgressFlag(true);

    let arrayData = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "contact-info",
          ":resourceSK": appContext.currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbContactInfo(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbContactInfo(): response length is: ', response.data.Items.length);
        // console.log('In fetchDbContactInfo(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item, index) => {
          // let record = {
          //   id: ""+index,
          //   LanguageCode: item.LanguageCode,
          //   ResourcePK: item.ResourcePK,
          //   ResourceCode: item.ResourceCode,
          //   ResourceDesc: item.ResourceDesc,
          //   ResourceAddressUrl: item.ResourceAddressUrl,
          //   ResourceIconUrl: item.ResourceIconUrl,
          //   Icon: item.ResourceIconUrl,
          //   ModifiedBy: item.ModifiedBy,
          //   CreatedAt: item.CreatedAt,
          //   UpdatedAt: item.UpdatedAt,
          // };
          // arrayData.push(record);

          arrayData.push(item);
        });

        setContactInfoData(arrayData);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbContactInfo(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  function onScriptLoad() {
    console.log("In onScriptLoad(): script loaded successfully.");
    // console.log("In onScriptLoad(): mapOptions is: ", mapOptions);

    // const mapElement = document.getElementById(mapId);
    // onMapScriptLoaded(mapElement);
  }

  function loadGoogleMapScript(langCode) {
    // console.log("In loadGoogleMapScript(): window.google is: ", window.google);

    // let mapElement = document.getElementById(mapId);
    // console.log("In loadGoogleMapScript(): mapElement is: ", mapElement);

    // if (mapElement === null){
    // console.log("In loadGoogleMapScript(): langCode is: ", langCode);

    // Build the JS API request node.
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "mapScriptId";
    // var query = '?key=AIzaSyD8dsL8KOtOMMdbYwdVLCSoa4t_SzQKjUo';
    var query = "";
    //   var langCode = getUrlParameter('language');
    // Try to be generous with accepting upper/lower case.
    if (langCode.length === 2) {
      langCode = langCode.toLowerCase();
    }
    if (langCode) {
      query = "&language=" + langCode;
    }
    //   var regionCode = getUrlParameter('region').toUpperCase();
    // if (regionCode && regionCode !== null && regionCode !== "") {
    //     query += '&region=' + regionCode;
    // }
    query += `&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;
    // console.log("In loadGoogleMapScript(): query is: ", query);

    script.src =
      "https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places,geocode" +
      query;
    // script.src=`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&language=${langCode}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;
    //   script.setAttribute('async', '');
    //   script.setAttribute('defer', '');
    //   document.head.appendChild(script);

    var scriptTag = document.getElementsByTagName("head")[0];
    scriptTag.parentNode.insertBefore(script, scriptTag);
    // Below is important.
    // We cannot access google.maps until it's finished loading
    script.addEventListener("load", (e) => {
      // handleMapLoaded();
      onScriptLoad();
    });
    // }
  }

  useEffect(() => {
    // console.log("In ContactInfo(): useEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));

    // currLanguage is the most current language passed to this component through props
    // We are using useEffect to get the functionality of componentDidUpdate()
    if (
      currLanguage !== undefined &&
      currLanguage !== null &&
      currLanguage !== currentLanguage
    ) {
      setCurrentLanguage(currLanguage);
      appContext.currentLanguage = currLanguage;

      // console.log('In ContactInfo(): useEffect(): about to call fetchDbResources()');

      // Fetch resource from catche
      let cacheItemKey = "contactLabels";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      // cacheItemKey += currLanguage.languageCode;
      // console.log('In ContactInfo(): useEffect(): cacheItemKey is: ', cacheItemKey);

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        // console.log("In ContactInfo(): useEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
        RemoveCacheItem(cacheItemKey);
      }

      let cacheItem = GetCacheItem(cacheItemKey);
      // console.log('In ContactInfo(): useEffect(): cacheItem is: ', cacheItem);

      if (cacheItem === null) {
        // resource is not in cache, fetch from DB
        // console.log('In ContactInfo(): useEffect(): resource is not in cache, fetch from DB');
        fetchDbResources(cacheItemKey);
      } else {
        // console.log('In ContactInfo(): useEffect(): got resource from cache');
        setContactLabelsRes(cacheItem);
      }
      // Fetch SocialNetworks Data
      fetchDbContactInfo();
    }
  });

  useLayoutEffect(() => {
    // console.log("In ContactInfo(): useLayoutEffect()");

    // Load Goole Map Scripts
    loadGoogleMapScript(appContext.currentLanguage.languageCode);

    // Fetch resource from catche
    let cacheItemKey = "contactLabels";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ContactInfo(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ContactInfo(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ContactInfo(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ContactInfo(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ContactInfo(): useLayoutEffect(): got resource from cache');
      setContactLabelsRes(cacheItem);
    }
    // Fetch SocialNetworks Data
    fetchDbContactInfo();
  }, []);

  // function RenderGoogleMap(props) {
  //   const {index, lat, lng, infoWindowTitle} = props;

  //   return (
  //     <div>
  //     <ContactInfoCustomMap
  //         currLanguage={currentLanguage}

  //         mapId={index}

  //         lat={lat}
  //         lng={lng}
  //         zoom={zoom}
  //         infoWindowTitle={infoWindowTitle}
  //         mapOptions={mapOptions}

  //         paperBackground={cardBackground}
  //     />
  //     </div>
  //   );
  // }

  // function handleTabChange(selectedTabIndex) {
  //   setSelectedTabIndex(selectedTabIndex);
  // }

  function DisplayBusinessHrs(props) {
    const { item } = props;
    // console.log(moment(item.BusinessHours.monStartDate, "hh:mm a"));
    // const startTime = moment(item.BusinessHours.monStartDate, "hh:mm a");
    // const endTime = moment(item.BusinessHours.monEndDate, "hh:mm a");
    return (
      <div>
        <Grid container>
          <Grid item xs={3}>
            <Typography
              variant="caption"
              className={classes.textItem}
              style={{ color: textColor }}
            >
              {monday}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {item.BusinessHours.monStartDate !== null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: textColor }}
              >
                {item.BusinessHours.monStartDate} -{" "}
                {item.BusinessHours.monEndDate}
              </Typography>
            )}
            {item.BusinessHours.monStartDate === null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: textColor }}
              >
                {closed}
              </Typography>
            )}
          </Grid>

          <Grid item xs={3}>
            <Typography
              variant="caption"
              className={classes.textItem}
              style={{ color: textColor }}
            >
              {tuesday}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {item.BusinessHours.tueStartDate !== null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: textColor }}
              >
                {item.BusinessHours.tueStartDate} -{" "}
                {item.BusinessHours.tueEndDate}
              </Typography>
            )}
            {item.BusinessHours.tueStartDate === null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: textColor }}
              >
                {closed}
              </Typography>
            )}
          </Grid>

          <Grid item xs={3}>
            <Typography
              variant="caption"
              className={classes.textItem}
              style={{ color: textColor }}
            >
              {wednesday}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {item.BusinessHours.wedStartDate !== null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: textColor }}
              >
                {item.BusinessHours.wedStartDate} -{" "}
                {item.BusinessHours.wedEndDate}
              </Typography>
            )}
            {item.BusinessHours.wedStartDate === null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: textColor }}
              >
                {closed}
              </Typography>
            )}
          </Grid>

          <Grid item xs={3}>
            <Typography
              variant="caption"
              className={classes.textItem}
              style={{ color: textColor }}
            >
              {thursday}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {item.BusinessHours.thuStartDate !== null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: textColor }}
              >
                {item.BusinessHours.thuStartDate} -{" "}
                {item.BusinessHours.thuEndDate}
              </Typography>
            )}
            {item.BusinessHours.thuStartDate === null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: textColor }}
              >
                {closed}
              </Typography>
            )}
          </Grid>

          <Grid item xs={3}>
            <Typography
              variant="caption"
              className={classes.textItem}
              style={{ color: textColor }}
            >
              {friday}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {item.BusinessHours.friStartDate !== null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: textColor }}
              >
                {item.BusinessHours.friStartDate} -{" "}
                {item.BusinessHours.friEndDate}
              </Typography>
            )}
            {item.BusinessHours.friStartDate === null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: textColor }}
              >
                {closed}
              </Typography>
            )}
          </Grid>

          <Grid item xs={3}>
            <Typography
              variant="caption"
              className={classes.textItem}
              style={{ color: textColor }}
            >
              {saturday}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {item.BusinessHours.satStartDate !== null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: textColor }}
              >
                {item.BusinessHours.satStartDate} -{" "}
                {item.BusinessHours.satEndDate}
              </Typography>
            )}
            {item.BusinessHours.satStartDate === null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: textColor }}
              >
                {closed}
              </Typography>
            )}
          </Grid>

          <Grid item xs={3}>
            <Typography
              variant="caption"
              className={classes.textItem}
              style={{ color: textColor }}
            >
              {sunday}
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {item.BusinessHours.sunStartDate !== null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: textColor }}
              >
                {item.BusinessHours.sunStartDate} -{" "}
                {item.BusinessHours.sunEndDate}
              </Typography>
            )}
            {item.BusinessHours.sunStartDate === null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: textColor }}
              >
                {closed}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} style={{ marginTop: "10px" }}>
            {item.Notes !== null && (
              <Typography variant="subtitle2" className={classes.tomatoColor}>
                {pleaseNote}:
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {item.Notes !== null && (
              <Typography
                variant="caption"
                className={classes.textItem}
                style={{ color: "#fff" }}
              >
                {item.Notes}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }

  function downArrowIcon(event) {
    event.preventDefault();
    console.log("In downArrowIcon()");
    setDownArrowShow(false);
    setUpArrowShow(true);
  }

  function upArrowIcon(event) {
    event.preventDefault();
    console.log("In upArrowIcon()");
    setUpArrowShow(false);
    setDownArrowShow(true);
  }

  function callMeFn(event) {
    console.log("In callMeFn(): event is: ", event);
  }

  function handleWhatsAppPhoneChange(whatsAppNum) {
    // console.log("In handleWhatsAppPhoneChange(): whatsAppNum is: ", whatsAppNum);
    setGlobalErrorFlag(false);
    setWhatsAppPhoneErrFlag(false);
    setWhatsAppPhone(whatsAppNum);
  }

  return (
    <div dir={direction}>
      <PageContainer className={classes.root}>
        <CssBaseline />

        <Container
          component="div"
          className={classes.contInfo}
          maxWidth="lg"
          style={{ background: "#fff" }}
        >
          {/* progressFlag &&
            <div style={{textAlign: 'center'}}>
              <CircularProgress className={classes.progress} color="secondary" size={15}/>
            </div>
          */}

          {contactInfoData &&
            contactInfoData.map((item, index) => {
              return (
                <Grid
                  key={index}
                  container
                  className={classes.mainGrid}
                  style={{ background: "#fff", width: "100%" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    className={classes.mainGridItems}
                    style={{ background: "#fff" }}
                  >
                    <Grid
                      container
                      alignContent="center"
                      alignItems="center"
                      style={{ width: "100%" }}
                    >
                      <Grid item style={{ width: "100%" }}>
                        <ContactInfoCustomMap
                          currLanguage={currentLanguage}
                          mapId={index}
                          lat={item.Lat}
                          lng={item.Lng}
                          zoom={zoom}
                          infoWindowTitle={item.ResourceDesc}
                          mapOptions={mapOptions}
                          paperBackground={cardBackground}
                        />

                        <LineSpacing value={4} />
                      </Grid>

                      <Grid
                        item
                        className={classes.directionLink}
                        style={{ marginLeft: "40px" }}
                      >
                        <div className={classes.directionLink}>
                          <Link
                            href={`https://www.google.com/maps/dir//${item.Lat},${item.Lng}/@${item.Lat},${item.Lng}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Grid container>
                              <Grid item className={classes.directionItem}>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.textItem}
                                  style={{ color: "black" }}
                                >
                                  {getDirections}
                                </Typography>
                              </Grid>

                              <Grid item className={classes.directionItem}>
                                <IconLaunch
                                  size="medium"
                                  style={{ color: iconsColor }}
                                />
                              </Grid>
                            </Grid>
                          </Link>
                        </div>
                        <LineSpacing value={8} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={1}
                    className={classes.mainGridItems}
                    style={{ background: "#fff" }}
                  ></Grid>

                  <Grid item xs={12} sm={5} className={classes.detailGridItems}>
                    <Grid container alignContent="center" alignItems="center">
                      <Grid item>
                        <Card
                          key={index}
                          className={classes.card}
                          style={{ background: cardBackground }}
                        >
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              style={{
                                color: headerColor,
                                textAlign: "center",
                              }}
                            >
                              {item.ResourceDesc}
                            </Typography>

                            <LineSpacing value={8} />

                            <Tabs
                              align="start"
                              // onChange={handleTabChange}
                              defaultSelectedTabIndex={0}
                              // selectedTabIndex={selectedTabIndex} 15.75em
                              label="Minerals"
                              // height="16.75em"
                              width="100%"
                            >
                              <TabThemed title={contacts} maxWidth="100%">
                                <Grid
                                  container
                                  key={index}
                                  className={classes.contactGrid}
                                >
                                  {item.StreetAddress && (
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={2}
                                        style={{ textAlign: "center" }}
                                      >
                                        <IconLocationOn
                                          size="large"
                                          style={{ color: iconsColor }}
                                        />
                                      </Grid>

                                      <Grid item xs={10}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.textItem}
                                          style={{ color: textColor }}
                                        >
                                          {item.StreetAddress}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}

                                  {item.Phone && (
                                    <Grid container className={classes.rowGrid}>
                                      <Grid
                                        item
                                        xs={2}
                                        style={{ textAlign: "center" }}
                                      >
                                        <IconPhone
                                          size="large"
                                          style={{ color: iconsColor }}
                                        />
                                      </Grid>

                                      <Grid item xs={10}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.textItem}
                                          style={{ color: textColor }}
                                        >
                                          {item.Phone}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}

                                  {item.Fax && (
                                    <Grid container className={classes.rowGrid}>
                                      <Grid
                                        item
                                        xs={2}
                                        style={{ textAlign: "center" }}
                                      >
                                        <IconPrint
                                          size="large"
                                          style={{ color: iconsColor }}
                                        />
                                      </Grid>

                                      <Grid item xs={10}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.textItem}
                                          style={{ color: textColor }}
                                        >
                                          {item.Fax}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}

                                  {item.Email && (
                                    <Grid container className={classes.rowGrid}>
                                      <Grid
                                        item
                                        xs={2}
                                        style={{ textAlign: "center" }}
                                      >
                                        <IconEmail
                                          size="large"
                                          style={{ color: iconsColor }}
                                        />
                                      </Grid>

                                      <Grid item xs={10}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.textItem}
                                          style={{ color: textColor }}
                                        >
                                          {item.Email}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}

                                  {item.WebAddress && (
                                    <Grid container className={classes.rowGrid}>
                                      <Grid
                                        item
                                        xs={2}
                                        style={{ textAlign: "center" }}
                                      >
                                        <IconLink
                                          size="large"
                                          style={{ color: iconsColor }}
                                        />
                                      </Grid>

                                      <Grid item xs={10}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.textItem}
                                          style={{ color: textColor }}
                                        >
                                          {item.WebAddress}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              </TabThemed>

                              <TabThemed title={viewOfficeHrs} maxWidth="100%">
                                <DisplayBusinessHrs item={item} />
                              </TabThemed>

                              <TabThemed title={support} maxWidth="100%">
                                <Grid
                                  container
                                  key={index}
                                  className={classes.contactGrid}
                                >
                                  {item.SupportPhone && (
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={2}
                                        style={{ textAlign: "center" }}
                                      >
                                        <IconPhone
                                          size="large"
                                          style={{ color: iconsColor }}
                                        />
                                      </Grid>

                                      <Grid item xs={10}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.textItem}
                                          style={{ color: textColor }}
                                        >
                                          {item.SupportPhone}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}

                                  {item.SmsPhone && (
                                    <Grid container className={classes.rowGrid}>
                                      <Grid
                                        item
                                        xs={2}
                                        style={{ textAlign: "center" }}
                                      >
                                        <IconSms
                                          size="large"
                                          style={{ color: iconsColor }}
                                        />
                                      </Grid>

                                      <Grid item xs={10}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.textItem}
                                          style={{ color: textColor }}
                                        >
                                          {item.SmsPhone}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}

                                  {item.WhatsAppPhone && (
                                    <Grid container className={classes.rowGrid}>
                                      <Grid
                                        item
                                        xs={2}
                                        style={{ textAlign: "center" }}
                                      >
                                        <img
                                          src="/assets/images/whatsapp_logo.png"
                                          style={{ width: "24px" }}
                                        />
                                      </Grid>

                                      <Grid item xs={10}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.textItem}
                                          style={{ color: textColor }}
                                        >
                                          {item.WhatsAppPhone}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}

                                  {item.SupportEmail && (
                                    <Grid container className={classes.rowGrid}>
                                      <Grid
                                        item
                                        xs={2}
                                        style={{ textAlign: "center" }}
                                      >
                                        <IconEmail
                                          size="large"
                                          style={{ color: iconsColor }}
                                        />
                                      </Grid>

                                      <Grid item xs={10}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.textItem}
                                          style={{ color: textColor }}
                                        >
                                          {item.SupportEmail}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}

                                  {/* <Grid container style={{backgroundColor: "#fff"}}>
                                      <Grid item xs={12}><Text className={classes.tomatoBg}>{"tomatoBg"}</Text></Grid>
                                      <Grid item xs={12}><Text className={classes.orangeBg}>{"orangeBg"}</Text></Grid>
                                      <Grid item xs={12}><Text className={classes.dodgerBlueBg}>{"dodgerBlueBg"}</Text></Grid>
                                      <Grid item xs={12}><Text className={classes.mediumSeaGreenBg}>{"mediumSeaGreenBg"}</Text></Grid>
                                      <Grid item xs={12}><Text className={classes.grayBg}>{"grayBg"}</Text></Grid>
                                      <Grid item xs={12}><Text className={classes.slateBlueBg}>{"slateBlueBg"}</Text></Grid>
                                      <Grid item xs={12}><Text className={classes.violetBg}>{"violetBg"}</Text></Grid>
                                      <Grid item xs={12}><Text className={classes.lightGrayBg}>{"lightGrayBg"}</Text></Grid>
                                      <Grid item xs={12}><Text className={classes.tealBg}>{"tealBg"}</Text></Grid>
                                    </Grid> */}
                                </Grid>
                              </TabThemed>

                              <TabThemed title={sales} minWidth="100%">
                                <Grid
                                  container
                                  key={index}
                                  className={classes.contactGrid}
                                >
                                  {item.SalesPhone && (
                                    <Grid
                                      container
                                      style={{ minWidth: "100%" }}
                                    >
                                      <Grid
                                        item
                                        xs={2}
                                        style={{ textAlign: "center" }}
                                      >
                                        <IconPhone
                                          size="large"
                                          style={{ color: iconsColor }}
                                        />
                                      </Grid>

                                      <Grid item xs={10}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.textItem}
                                          style={{ color: textColor }}
                                        >
                                          {item.SalesPhone}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}

                                  {item.SalesEmail && (
                                    <Grid container className={classes.rowGrid}>
                                      <Grid
                                        item
                                        xs={2}
                                        style={{ textAlign: "center" }}
                                      >
                                        <IconEmail
                                          size="large"
                                          style={{ color: iconsColor }}
                                        />
                                      </Grid>

                                      <Grid item xs={10}>
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.textItem}
                                          style={{ color: textColor }}
                                        >
                                          {item.SalesEmail}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}

                                  {/* item.CallMePhone &&
                                      <Grid container className={classes.rowGrid}>
                                          <Grid item xs={12}>
                                            <ExpansionPanel style={{background: cardBackground}}>
                                              <ExpansionPanelSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                              >
                                                <div className={classes.firstColumn}>
                                                  <IconPhone size="large" className={classes.callMeIcon} style={{color: iconsColor}}/>
                                                </div>

                                                <div className={classes.secondColumn}>
                                                  <Typography variant="subtitle2" className={classes.textItem} style={{color: '#fff'}}>
                                                      {"Call me now"}
                                                  </Typography>
                                                </div>
                                              </ExpansionPanelSummary>

                                              <ExpansionPanelDetails className={classes.details}>
                                                <FormField label={whatsAppPhoneLabel}/>
                                                <div dir={'ltr'}>
                                                  <ReactPhoneInput 
                                                      defaultCountry={defaultPhoneCountry} 
                                                      value={whatsAppPhone} 
                                                      onChange={handleWhatsAppPhoneChange} 
                                                      inputClass={classes.phone}
                                                      preferredCountries={preferredCountries}
                                                      localization={localization}
                                                      enableSearchField={enableSearchField}
                                                      searchPlaceholder={searchPlaceholder}
                                                  />
                                                </div>
                                              </ExpansionPanelDetails>

                                              <Divider />

                                              <ExpansionPanelActions>
                                                <Button size="small" color="primary" onClick={() => callMeFn(item.SupportPhone)}>
                                                  Call me
                                                </Button>
                                              </ExpansionPanelActions>
                                            </ExpansionPanel>
                                          </Grid>
                                      </Grid>
                                    */}
                                </Grid>
                              </TabThemed>
                            </Tabs>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
        </Container>
      </PageContainer>
    </div>
  );
}

export default ContactInfo;
