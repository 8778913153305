import React, { useState, useEffect, useLayoutEffect, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
// import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

// react-responsive
import { useMediaQuery } from 'react-responsive';

// Amplify
import { API } from "aws-amplify";

// AppContext
import AppContext from "../../../config/AppContext";

const useStyles = makeStyles((theme) => ({
    lead: {
        fontSize: '1.38rem',
        fontWeight: 400,
    },
    mainContainer: {
        width: '100%',
        marginBottom: theme.spacing(1),
        // textAlign: "center",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function Contents07(props) {
    const appContext = useContext(AppContext);

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

    //------------------------
    // For Form Attributes
    //------------------------
    const [designTypeRef, setDesignTypeRef] = useState("Contents07");
    const [designBlockRecord, setDesignBlockRecord] = useState(null);
    const [designDetailRecords, setDesignDetailRecords] = useState([]);

    const {
        direction,
        webPage,
    } = props;

    const theme = useTheme();
    const classes = useStyles();

    async function fetchWebPageRecords(pageCategoryValue) {
        // console.log('In fetchWebPageRecords(): pageCategoryValue is: ', pageCategoryValue);

        return new Promise((resolve, reject) => {
            if (!pageCategoryValue) {
                reject("Error in fetchWebPageRecords(): pageCategoryValue is empty");
            }

            backdropProgress(true);

            let params = {
                apiName: "PagesAPI",
                path: "/dynamodb/pages/query",
                payload: {
                    keyConditionExpression:
                        // "#pagePK = :pagePK",
                        "#pagePK = :pagePK AND begins_with(#pageSK, :pageSK)",
                    expressionAttributeNames: {
                        "#pagePK": "PagePK",
                        "#pageSK": "PageSK",
                    },
                    expressionAttributeValues: {
                        ":pagePK": appContext.domainName + "#" + appContext.currentLanguage.languageCode + "#page",
                        ":pageSK": appContext.domainName + "#" + appContext.currentLanguage.languageCode + "#page#" + pageCategoryValue + "#" + "4a38ksq0g",
                    },
                    // indexName: "PriceDomain-LangAuthorUserName-index"
                        // "keyConditionExpression": "#pagePK = :pagePK",
                        // "expressionAttributeNames": {"#pagePK": "PagePK"},
                        // "expressionAttributeValues": {":pagePK": "languages"}
                },
            };

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchWebPageRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchWebPageRecords(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchWebPageRecords(): response.data.NextToken is: ', response.data.NextToken);
                // console.log('In fetchWebPageRecords(): response.data.Items is: ', response.data.Items);

                let records = [];
                let items = response.data.Items;
                if (items && items.length > 0) {
                    items.map((record) => {
                        records.push(record);
                    });
                }
                resolve(records);

                backdropProgress(false);
            }).catch((err) => {
                console.error("In fetchWebPageRecords(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject("Error fetchWebPageRecords()");
            });
        });
    }

    async function fetchDesignBlocksForWebPage(pageId) {
        // console.log('In fetchDesignBlocksForWebPage(): pageId is: ', pageId);

        return new Promise((resolve, reject) => {
            if (!pageId) {
                reject("Error in fetchDesignBlocksForWebPage(): pageId is empty");
            }

            backdropProgress(true);

            let params = {
                apiName: "DesignAPI",
                path: "/dynamodb/design/query",
                payload: {
                    keyConditionExpression:
                        // "#designPK = :designPK",
                        "#designPK = :designPK AND begins_with(#designSK, :designSK)",
                    expressionAttributeNames: {
                        "#designPK": "DesignPK",
                        "#designSK": "DesignSK",
                    },
                    expressionAttributeValues: {
                        ":designPK": appContext.domainName + "#" + appContext.currentLanguage.languageCode + "#design",
                        ":designSK": appContext.domainName + "#" + appContext.currentLanguage.languageCode + "#design#" + pageId + "#",
                    },
                    // indexName: "PriceDomain-LangAuthorUserName-index"
                        // "keyConditionExpression": "#designPK = :designPK",
                        // "expressionAttributeNames": {"#designPK": "DesignPK"},
                        // "expressionAttributeValues": {":designPK": "languages"}
                },
            };

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchDesignBlocksForWebPage(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchDesignBlocksForWebPage(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchDesignBlocksForWebPage(): response.data.NextToken is: ', response.data.NextToken);
                // console.log('In fetchDesignBlocksForWebPage(): response.data.Items is: ', response.data.Items);

                let records = [];
                let items = response.data.Items;
                if (items && items.length > 0) {
                    items.map((record) => {
                        records.push(record);
                    });
                }
                resolve(records);
                backdropProgress(false);
            }).catch((err) => {
                console.error("In fetchDesignBlocksForWebPage(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject("Error fetchDesignBlocksForWebPage()");
            });
        });
    }

    async function fetchDesignDetailsForDesignBlock(designId) {
        // console.log('In fetchDesignDetailsForDesignBlock(): designId is: ', designId);

        return new Promise((resolve, reject) => {
            if (!designId) {
                reject("Error in fetchDesignDetailsForDesignBlock(): designId is empty");
            }

            backdropProgress(true);

            let params = {
                apiName: "DesignAPI",
                path: "/dynamodb/design/query",
                payload: {
                    keyConditionExpression:
                        // "#designPK = :designPK",
                        "#designPK = :designPK AND begins_with(#designSK, :designSK)",
                    expressionAttributeNames: {
                        "#designPK": "DesignPK",
                        "#designSK": "DesignSK",
                    },
                    expressionAttributeValues: {
                        ":designPK": appContext.domainName + "#" + appContext.currentLanguage.languageCode + "#detail",
                        ":designSK": appContext.domainName + "#" + appContext.currentLanguage.languageCode + "#detail#" + designId + "#",
                    },
                    // indexName: "PriceDomain-LangAuthorUserName-index"
                        // "keyConditionExpression": "#designPK = :designPK",
                        // "expressionAttributeNames": {"#designPK": "DesignPK"},
                        // "expressionAttributeValues": {":designPK": "languages"}
                },
            };

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchDesignDetailsForDesignBlock(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchDesignDetailsForDesignBlock(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchDesignDetailsForDesignBlock(): response.data.NextToken is: ', response.data.NextToken);
                // console.log('In fetchDesignDetailsForDesignBlock(): response.data.Items is: ', response.data.Items);

                let records = [];
                let items = response.data.Items;
                if (items && items.length > 0) {
                    items.map((record) => {
                        records.push(record);
                    });
                }
                resolve(records);
                backdropProgress(false);
            }).catch((err) => {
                console.error("In fetchDesignDetailsForDesignBlock(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject("Error fetchDesignDetailsForDesignBlock()");
            });
        });
    }
    
    useEffect(() => {
        // console.log('In Contents07(): useEffect()');
    }, []);

    useLayoutEffect(() => {
        // console.log("In Contents07(): useLayoutEffect()");
        async function fetchItems() {
            Promise.all([
                fetchWebPageRecords(webPage)
            ]).then(async (results) => {
                // console.log("In getWebPageRecord(): results[0] is: ", results[0]);
                // console.log("In getWebPageRecord(): results[0][0].PageId is: ", results[0][0].PageId);//=> 4a38ksq0g

                if (results[0][0] && results[0][0].PageId) {
                    Promise.all([
                        fetchDesignBlocksForWebPage(results[0][0].PageId)
                    ]).then(async (results2) => {
                        // console.log("In getWebPageRecord(): results2[0] is: ", results2[0]);
                        let designBlockRecords = results2[0];

                        // Filter designBlock for "contents" type
                        let filteredRecords = [];
                        if (designBlockRecords && designBlockRecords.length > 0) {
                            filteredRecords = designBlockRecords.filter((record) => 
                                (record.DesignTypeCode === "contents" && record.DesignTypeRef && record.DesignTypeRef.value === designTypeRef));
                            // console.log("In getWebPageRecord(): contentRecords is: ", contentRecords);
                            setDesignBlockRecord(filteredRecords[0]);

                            if (filteredRecords && filteredRecords.length > 0) {
                                Promise.all([
                                    fetchDesignDetailsForDesignBlock(filteredRecords[0].DesignId)
                                ]).then(async (results3) => {
                                    // console.log("In getWebPageRecord(): results3[0] is: ", results3[0]);
                                    setDesignDetailRecords(results3[0]);
                                }).catch(error3 => {
                                    console.error("In getWebPageRecord(): Promise error3 is: ", error3);
                                })
                            }
                        }
                    }).catch(error2 => {
                        console.error("In getWebPageRecord(): Promise error2 is: ", error2);
                    });
                }
            }).catch(error => {
                console.error("In getWebPageRecord(): Promise error is: ", error);
            });
        }

        fetchItems();
    }, []);

    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    return (
        <>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {isDeskTopView &&
                <div style={{background: '#ffffff'}}>
                        {designBlockRecord &&
                            <Container component="div" className={classes.mainContainer} maxWidth="lg">
                                <Grid container justify="flex-start" spacing={4}>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-start">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <h1>
                                                        {designBlockRecord.DesignTitle}
                                                    </h1>
                                                </Grid>
                                            </Grid>

                                            {designDetailRecords && designDetailRecords.length > 0 &&
                                                designDetailRecords.map((record, index) => (
                                                    <Grid key={index} item xs={12} sm={6} style={{paddingRight: theme.spacing(4), marginTop: theme.spacing(4)}}>
                                                        <Grid container justify="flex-start">
                                                            <p className={classes.lead} style={{textAlign: 'left'}}>
                                                                {record.DetailDesc}
                                                            </p>
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>           
                        }
                </div>
            }

            {isTabletView &&
                <div style={{background: '#ffffff'}}>
                        {designBlockRecord &&
                            <Container component="div" className={classes.mainContainer} maxWidth="lg">
                                <Grid container justify="flex-start" spacing={4}>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-start">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <h1>
                                                        {designBlockRecord.DesignTitle}
                                                    </h1>
                                                </Grid>
                                            </Grid>

                                            {designDetailRecords && designDetailRecords.length > 0 &&
                                                designDetailRecords.map((record, index) => (
                                                    <Grid key={index} item xs={12} sm={6} style={{paddingRight: theme.spacing(4), marginTop: theme.spacing(4)}}>
                                                        <Grid container justify="flex-start">
                                                            <p className={classes.lead} style={{textAlign: 'left'}}>
                                                                {record.DetailDesc}
                                                            </p>
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>           
                        }
                </div>
            }

            {isMobileView &&
                <div style={{background: '#ffffff'}}>
                        {designBlockRecord &&
                            <Container component="div" className={classes.mainContainer} maxWidth="lg">
                                <Grid container justify="flex-start" spacing={4}>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container justify="flex-start">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container justify="center">
                                                    <h2>
                                                        {designBlockRecord.DesignTitle}
                                                    </h2>
                                                </Grid>
                                            </Grid>

                                            {designDetailRecords && designDetailRecords.length > 0 &&
                                                designDetailRecords.map((record, index) => (
                                                    <Grid key={index} item xs={12} sm={6} style={{marginTop: theme.spacing(2)}}>
                                                        <Grid container justify="flex-start">
                                                            <p className={classes.lead} style={{textAlign: 'left'}}>
                                                                {record.DetailDesc}
                                                            </p>
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>           
                        }
                </div>
            }
        </>
    );
}

export default Contents07;
