export default async function ImportEditorLanguageFile(selectedLang) {
  // console.log("In ImportEditorLanguageFile(): selectedLang is: ", selectedLang);

  switch (selectedLang) {
    case "ar": // Arabic
      await import("froala-editor/js/languages/ar.js");
      break;
    case "bs": // 
      await import("froala-editor/js/languages/bs.js");
      break;
    case "cs": // 
      await import("froala-editor/js/languages/cs.js");
      break;
    case "da": // 
      await import("froala-editor/js/languages/da.js");
      break;
    case "de": // German
      await import("froala-editor/js/languages/de.js");
      break;
    case "el": // 
      await import("froala-editor/js/languages/el.js");
      break;
    case "es": // Spanish
      await import("froala-editor/js/languages/es.js");
      break;
    case "et": // 
      await import("froala-editor/js/languages/et.js");
      break;
    case "fa": // 
      await import("froala-editor/js/languages/fa.js");
      break;
    case "fi": // 
      await import("froala-editor/js/languages/fi.js");
      break;
    case "fr": // French
      await import("froala-editor/js/languages/fr.js");
      break;
    case "he": // hebrew
      await import("froala-editor/js/languages/he.js");
      break;
    case "hr": // 
      await import("froala-editor/js/languages/hr.js");
      break;
    case "hu": // 
      await import("froala-editor/js/languages/hu.js");
      break;
    case "id": // 
      await import("froala-editor/js/languages/id.js");
      break;
    case "it": // Italian
      await import("froala-editor/js/languages/it.js");
      break;
    case "ja": // Japanese
      await import("froala-editor/js/languages/ja.js");
      break;
    case "ko": // Korean
      await import("froala-editor/js/languages/ko.js");
      break;
    case "ku": // 
      await import("froala-editor/js/languages/ku.js");
      break;
    case "me": // 
      await import("froala-editor/js/languages/me.js");
      break;
    case "nb": // 
      await import("froala-editor/js/languages/nb.js");
      break;
    case "nl": // 
      await import("froala-editor/js/languages/nl.js");
      break;
    case "pl": // Polish
      await import("froala-editor/js/languages/pl.js");
      break;
    case "br": // Brazil
      await import("froala-editor/js/languages/pt_br.js");
      break;
    case "pt": // Portuguese
      await import("froala-editor/js/languages/pt_pt.js");
      break;
    case "ro": // 
      await import("froala-editor/js/languages/ro.js");
      break;
    case "ru": // Russia
      await import("froala-editor/js/languages/ru.js");
      break;
    case "sk": // 
      await import("froala-editor/js/languages/sk.js");
      break;
    case "sr": // 
      await import("froala-editor/js/languages/sr.js");
      break;
    case "sv": // 
      await import("froala-editor/js/languages/sv.js");
      break;
    case "th": // 
      await import("froala-editor/js/languages/th.js");
      break;
    case "tr": // 
      await import("froala-editor/js/languages/tr.js");
      break;
    case "uk": // 
      await import("froala-editor/js/languages/uk.js");
      break;
    case "vi": // 
      await import("froala-editor/js/languages/vi.js");
      break;
    case "zh": // Chinese (Simplified)
      await import("froala-editor/js/languages/zh_cn.js");
      break;
    case "zh-TW": // Chinese (Traditional)
      await import("froala-editor/js/languages/zh_tw.js");
      break;
    default:
      break;
  }
}
