import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

// Cards
import {
  UserCard,
  ProductCard,
  TaggedContentCard,
  FlippingCard,
  FlippingCardFront,
  FlippingCardBack,
  RecipeCard,
  NewsHeaderCard,
  CryptoCard,
  PaymentCard,
  DropdownCard,
} from "react-ui-cards";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

// Amplify
import Amplify, { API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

const StyledPaper = styled(Paper)`
  min-width: 300px;
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "#fff",
  },
  main: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(8),
  },
  progress: {
    margin: theme.spacing(2),
  },
  mainForm: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  taggedCard: {
    width: "100%",
  },
}));

let pgTemplate = {
  pageTemplateTitle: "CardsExamples",
};

function CardsExamples(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  const [progressFlag, setProgressFlag] = useState(false);

  // For Resources
  const [pgTemplateRes, setPgTemplateRes] = useState(pgTemplate);

  const classes = useStyles();

  const { currLanguage } = props;

  const { pageTemplateTitle } = pgTemplateRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(pgTemplate);
    // console.log("In CardsExamples(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In CardsExamples(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        console.log(
          "In fetchDbResources(): response length is: ",
          response.data.Responses.resource.length
        );
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "pgTemplateTitle")
              pgTemplate.pgTemplateTitle = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, pgTemplate);
        }

        setPgTemplateRes(pgTemplate);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setPgTemplateRes(pgTemplate);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    console.log("In CardsExamples(): useEffect()");
  }, []);

  useLayoutEffect(() => {
    // console.log("In CardsExamples(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "pgTemplate";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In CardsExamples(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In CardsExamples(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In CardsExamples(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In CardsExamples(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In CardsExamples(): useLayoutEffect(): got resource from cache');
      setPgTemplateRes(cacheItem);
    }
  }, []);

  return (
    <div dir={direction}>
      <div className={classes.root}>
        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />
        {/* { !appContext.signedIn &&
                    <Redirect to='/signIn' />
                } */}

        <Container component="div" className={classes.main} maxWidth="lg">
          <div
            className="ag-theme-balham"
            style={{ width: "100%", height: "100%" }}
          >
            {progressFlag && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                  size={15}
                />
              </div>
            )}
            <Container component="div" className={classes.mainForm}>
              <StyledPaper elevation={0} className={classes.paper}>
                <Typography
                  variant="h6"
                  style={{ color: "black", textAlign: "center" }}
                >
                  {pageTemplateTitle}
                </Typography>
              </StyledPaper>

              <Grid container justify="center">
                <Grid item>
                  <Typography
                    variant="caption"
                    style={{ color: "black", textAlign: "left" }}
                  >
                    {"UserCard examples"}
                  </Typography>
                  <Grid container justify="center">
                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <UserCard
                          float
                          header="https://i.imgur.com/w5tX1Pn.jpg"
                          avatar="https://i.imgur.com/uDYejhJ.jpg"
                          name="Justin Case"
                          positionName="Software Developer"
                          stats={[
                            { name: "followers", value: 21 },
                            { name: "following", value: 37 },
                            { name: "posts", value: 117 },
                          ]}
                          style={{ textAlign: "center" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <UserCard
                          float
                          href="https://github.com/nukeop"
                          header="https://i.imgur.com/vRAtM3i.jpg"
                          avatar="https://i.imgur.com/XJxqvsU.jpg"
                          name="Frank Hepsfield"
                          positionName="Software Engineering Manager"
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <UserCard
                          float
                          href="https://github.com/nukeop"
                          header="https://i.imgur.com/p5yXGQk.jpg"
                          avatar="https://i.imgur.com/kFkyYkZ.jpg"
                          name="Joseph Cheps"
                          positionName="Firmware Engineer"
                          stats={[
                            { name: "commits", value: 365 },
                            { name: "stars", value: 110 },
                            { name: "repositories", value: 54 },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography
                    variant="caption"
                    style={{ color: "black", textAlign: "left" }}
                  >
                    {"ProductCard examples"}
                  </Typography>
                  <Grid container justify="center">
                    <Grid item xs={12} sm={6}>
                      <Grid container justify="center">
                        <ProductCard
                          photos={[
                            "https://i.imgur.com/t7DTziH.jpg",
                            "https://i.imgur.com/kA5lx6t.jpg",
                            "https://i.imgur.com/cUsl6Gs.jpg",
                          ]}
                          price="$1.50"
                          productName="Cupcake"
                          description="Nullam velit leo, pulvinar non metus feugiat, pharetra ornare enim. Vivamus ac quam a purus venenatis fringilla."
                          rating={5}
                          url="https://github.com/nukeop"
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid container justify="center">
                        <ProductCard
                          photos={[
                            "https://i.imgur.com/jRVDeI8.jpg",
                            "https://i.imgur.com/raPe27t.jpg",
                            "https://i.imgur.com/IpEsYSH.jpg",
                          ]}
                          price="$99"
                          productName="Headphones"
                          description="Donec lectus nulla, molestie aliquam nisl vitae, tempor placerat magna. Morbi dignissim in felis vel aliquet."
                          buttonText="Add to cart"
                          rating={3}
                          url="https://github.com/nukeop"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography
                    variant="caption"
                    style={{ color: "black", textAlign: "left" }}
                  >
                    {"TaggedContentCard examples"}
                  </Typography>
                  <Grid container justify="center">
                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <TaggedContentCard
                          href="https://github.com/nukeop"
                          thumbnail="https://i.imgur.com/yCch7GN.jpg"
                          title="Cheesecake"
                          description="a cake with raspberries"
                          tags={["food", "cake", "fruits"]}
                          className={classes.taggedCard}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <TaggedContentCard
                          href="https://github.com/nukeop"
                          thumbnail="https://i.imgur.com/57I3RAq.jpg"
                          title="Cupcakes"
                          description="a bite-sized cake"
                          tags={["food", "cake", "icing"]}
                          className={classes.taggedCard}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <TaggedContentCard
                          href="https://github.com/nukeop"
                          thumbnail="https://i.imgur.com/H0f0R6p.jpg"
                          title="Burger"
                          description="the cornerstone of every nutritious breakfast"
                          tags={["food", "sandwich", "meat"]}
                          className={classes.taggedCard}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography
                    variant="caption"
                    style={{ color: "black", textAlign: "left" }}
                  >
                    {"FlippingCardBack examples"}
                  </Typography>
                  <Grid container justify="center">
                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <FlippingCard>
                          <FlippingCardBack>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundImage:
                                  "url(https://i.imgur.com/wjbYGNv.jpg)",
                              }}
                            ></div>
                          </FlippingCardBack>
                          <FlippingCardFront>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundImage:
                                  "url(https://i.imgur.com/3sKjY8V.jpg)",
                              }}
                            ></div>
                          </FlippingCardFront>
                        </FlippingCard>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <FlippingCard>
                          <FlippingCardBack>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundImage:
                                  "url(https://i.imgur.com/m0GnZH3.jpg)",
                              }}
                            ></div>
                          </FlippingCardBack>
                          <FlippingCardFront>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundImage:
                                  "url(https://i.imgur.com/E1jz6WQ.jpg)",
                              }}
                            ></div>
                          </FlippingCardFront>
                        </FlippingCard>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <FlippingCard>
                          <FlippingCardBack>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundImage:
                                  "url(https://i.imgur.com/1abAoUz.jpg)",
                              }}
                            ></div>
                          </FlippingCardBack>
                          <FlippingCardFront>
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundImage:
                                  "url(https://i.imgur.com/UjbK2Wb.png)",
                              }}
                            ></div>
                          </FlippingCardFront>
                        </FlippingCard>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography
                    variant="caption"
                    style={{ color: "black", textAlign: "left" }}
                  >
                    {"RecipeCard examples"}
                  </Typography>
                  <Grid container justify="center">
                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <RecipeCard
                          href="https://github.com/nekonee"
                          thumbnail="https://i.imgur.com/VkHTicg.jpg"
                          title="Fluffy pancakes"
                          time="0:30"
                          servings="3-5"
                          likeCallback={() =>
                            alert("You added Fluffy pancakes to favourites")
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <RecipeCard
                          href="https://github.com/nekonee"
                          thumbnail="https://i.imgur.com/0308iuN.jpg"
                          title="Cupcakes"
                          time="1:30"
                          servings="4-6"
                          likeCallback={() =>
                            alert("You added Cupcakes to favourites")
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <RecipeCard
                          href="https://github.com/nekonee"
                          thumbnail="https://i.imgur.com/s9oR6BE.jpg"
                          title="Macarons"
                          time="2:00"
                          servings="7-10"
                          likeCallback={() =>
                            alert("You added Macarons to favourites")
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography
                    variant="caption"
                    style={{ color: "black", textAlign: "left" }}
                  >
                    {"NewsHeaderCard examples"}
                  </Typography>
                  <Grid container justify="center">
                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <NewsHeaderCard
                          href="https://github.com/nekonee"
                          thumbnail="https://i.imgur.com/rLFk5nd.jpg"
                          title="Polish mountaineers on the top"
                          author="Daily Sport"
                          date="Feb 2, 2018"
                          tags={["nature", "photo", () => <i>component</i>]}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <NewsHeaderCard
                          href="https://github.com/nekonee"
                          thumbnail="https://i.imgur.com/1fk52PJ.jpg"
                          title="Most poisonous dishes you must avoid"
                          author="Daily Health"
                          date="Jan 2, 2018"
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <NewsHeaderCard
                          href="https://github.com/nekonee"
                          thumbnail="https://i.imgur.com/n5Wg2Po.jpg"
                          title="Cool looking test tubes"
                          author="Daily Science"
                          date="Mar 2, 2018"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Typography
                    variant="caption"
                    style={{ color: "black", textAlign: "left" }}
                  >
                    {"PaymentCard examples"}
                  </Typography>
                  <Grid container justify="center">
                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <PaymentCard
                          issuerIcon="https://i.imgur.com/MulWZzk.png"
                          backgroundPattern="spiral"
                          number="4445 8509 0753 1365"
                          date="12/18"
                          name="Jean Pierre Polnareff"
                          cvv="213"
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <PaymentCard
                          background="linear-gradient(135deg, #00b4db, #0083b0)"
                          issuerIcon="https://i.imgur.com/31vser1g.png"
                          number="5458 8074 7691 1254"
                          date="05/20"
                          name="Joseph Joestar"
                          cvv="714"
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Grid container justify="center">
                        <PaymentCard
                          background="linear-gradient( 135deg, #11998e, #38ef7d)"
                          backgroundPattern="triangles"
                          issuerIcon="https://i.imgur.com/CRdoJbX.png"
                          number="3421 7270 0864 956"
                          date="02/22"
                          name="Jotaro Kujo"
                          cvv="880"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </div>
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default CardsExamples;
