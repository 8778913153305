import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import styled, { css } from "styled-components";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// import TextField from '@material-ui/core/TextField';
// import IconButton from '@material-ui/core/IconButton';
import Input from "@material-ui/core/Input";
// import InputLabel from '@material-ui/core/InputLabel';
// import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import GlobalConstants from "../../utils/GlobalConstants";
import SetCacheItem from "../../utils/cache/SetCacheItem";
// import ClearCacheItems from '../../utils/cache/ClearCacheItems';
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

// import ConfirmVerificationCode from './ConfirmVerificationCode';
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import GlobalInfoMessage from "../../components/dialogs/GlobalInfoMessage";
// import AppMainMenu from '../headers/AppMainMenu';
// import PageFooter from '../footers/PageFooter';
import LineSpacing from "../../components/misc/LineSpacing";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin: 4px 0 2px 0 !important;
`;

const StyledButton = styled(Button)`
  background: transparent;
  border-radius: 3px;
  border: 2px solid palevioletred;
  color: palevioletred;
  margin-bottom: 12px !important;

  /* When the "primary" property is set we want to add some more css to our component */
  /* in this case change the background and color. */

  ${(props) =>
    props.color === "primary" &&
    css`
      background: green !important;
      color: white !important;
      margin: 0 0 12px 20px !important;
    `};
`;

const StyledTypography = styled(Typography)`
  text-align: center !important;
  color: green;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    // flexDirection: 'column',
    minHeight: "100vh",
    // backgroundColor: '#fff',
  },
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    // backgroundColor: '#fff',
  },
  mainForm: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  textField: {
    width: "100%",
  },
  iconSmall: {
    fontSize: 20,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

let confirmVerifCode = {
  verificationCodeHdr: "Verify code",
  verificationCodeSubHdr: "Let's verify the code you received.",
  verificationCodeLabel: "Verification code",
  verifyCode: "Verify code",
  // cancel: "Cancel",
  enterVerificationCode: "Please enter verification code",
  enterReceivedVeriCode: "Please enter verification code you received",
  // invalidUsername: "Invalid or unknown username (email).",
  // usernameTaken: "Username has been taken.",
  resendCode: "Resend verification code",
  verificationCodeSent: "We just sent you a new verification code.",
  alreadyConfirmed: "You are already confirmed.",
  limitTrialExceeded: "Attempt limit exceeded, please try later.",
  usernameLabel: "Username",
  enterCorrectUsername: "Please enter a valid email address.",
  codeMismatch: "Sorry!!! you entered the wrong code.",
  // limitExceededException: "Attempt limit exceeded, please try after some time.",
};

function ConfirmVerificationCode(props) {
  const appContext = useContext(AppContext);
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);
  const [direction, setDirection] = useState(appContext.direction);
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const [confirmVerifCodeRes, setConfirmVerifCodeRes] = useState(
    confirmVerifCode
  );

  const [enableRtl, setEnableRtl] = useState(false);
  const [progressFlag, setProgressFlag] = useState(false);

  const [username, setUsername] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmSignUp, setConfirmSignUp] = useState(false);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  const [resendCodeFlag, setResendCodeFlag] = useState(false);

  // For Error Flags
  const [usernameErrFlag, setUsernameErrFlag] = useState(false);
  const [usernameFocus, setUsernameFocus] = useState(false);
  const [verificationCodeErrFlag, setVerificationCodeErrFlag] = useState(false);
  const [verificationCodeFocus, setVerificationCodeFocus] = useState(true);

  const [verifyCodeButtonFlag, setVerifyCodeButtonFlag] = useState(true);
  const [codeVerifiedFlag, setCodeVerifiedFlag] = useState(false);

  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  const {
    verificationCodeHdr,
    verificationCodeSubHdr,
    verificationCodeLabel,
    verifyCode,
    // cancel,
    enterVerificationCode,
    enterReceivedVeriCode,
    // invalidUsername,
    // usernameTaken,
    resendCode,
    verificationCodeSent,
    alreadyConfirmed,
    limitTrialExceeded,
    usernameLabel,
    enterCorrectUsername,
    codeMismatch,
    // limitExceededException,
  } = confirmVerifCodeRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(confirmVerifCode);
    // console.log("In ConfirmVerificationCode(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ConfirmVerificationCode(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
        // "queryType": "hash-query",
        // "resourcePK": currentLanguage.languageCode,
        // "keyConditionExpression": "#resourcePK = :resourcePK",
        // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
        // "expressionAttributeValues": {":resourcePK": currentLanguage.languageCode}
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        console.log(
          "In fetchDbResources(): response length is: ",
          response.data.Responses.resource.length
        );
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            // id: ""+index,
            // ResourceLanguageCode: item.ResourceLanguageCode,
            // ResourcePK: item.ResourcePK,
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
            // ModifiedBy: item.ModifiedBy,
            // CreatedAt: item.CreatedAt,
            // UpdatedAt: item.UpdatedAt,
          };

          arrayData.push(record);
        });
        // setDbResources(arrayData);

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "verificationCodeHdr")
              confirmVerifCode.verificationCodeHdr = item.ResourceDesc;
            if (item.ResourceCode === "verificationCodeSubHdr")
              confirmVerifCode.verificationCodeSubHdr = item.ResourceDesc;
            if (item.ResourceCode === "verificationCodeLabel")
              confirmVerifCode.verificationCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "verifyCode")
              confirmVerifCode.verifyCode = item.ResourceDesc;
            if (item.ResourceCode === "enterVerificationCode")
              confirmVerifCode.enterVerificationCode = item.ResourceDesc;
            if (item.ResourceCode === "enterReceivedVeriCode")
              confirmVerifCode.enterReceivedVeriCode = item.ResourceDesc;
            if (item.ResourceCode === "resendCode")
              confirmVerifCode.resendCode = item.ResourceDesc;
            if (item.ResourceCode === "verificationCodeSent")
              confirmVerifCode.verificationCodeSent = item.ResourceDesc;
            if (item.ResourceCode === "alreadyConfirmed")
              confirmVerifCode.alreadyConfirmed = item.ResourceDesc;
            if (item.ResourceCode === "limitTrialExceeded")
              confirmVerifCode.limitTrialExceeded = item.ResourceDesc;
            if (item.ResourceCode === "usernameLabel")
              confirmVerifCode.usernameLabel = item.ResourceDesc;
            if (item.ResourceCode === "codeMismatch")
              confirmVerifCode.codeMismatch = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, confirmVerifCode);
          setConfirmVerifCodeRes(confirmVerifCode);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setConfirmVerifCodeRes(confirmVerifCode);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    const { username } = props;
    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In ConfirmVerificationCode(): currentLanguage is: ', currentLanguage);
    // if (currentLanguage !== "en"){
    //   SetCacheItem("msg", msg);
    // }
    //msg = GetCacheItem("msg");
    //console.log('In ConfirmVerificationCode(): useEffect(): VerificationCode is: ', JSON.stringify(msg, null, 2));

    setVerificationCodeFocus(true);
    if (username && username !== null && username !== "") {
      setUsernameFocus(true);
      setUsername(username);
    }
  }, []);

  useLayoutEffect(() => {
    console.log("In ConfirmVerificationCode(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "confirmVerifCode";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    console.log(
      "In ConfirmVerificationCode(): useLayoutEffect(): cacheItemKey is: ",
      cacheItemKey
    );

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      console.log(
        "In ConfirmVerificationCode(): useLayoutEffect(): removing cacheItem for ",
        appContext.currentLanguage.languageCode
      );
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    console.log(
      "In ConfirmVerificationCode(): useLayoutEffect(): cacheItem is: ",
      cacheItem
    );

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      console.log(
        "In ConfirmVerificationCode(): useLayoutEffect(): resource is not in cache, fetch from DB"
      );
      fetchDbResources(cacheItemKey);
    } else {
      console.log(
        "In ConfirmVerificationCode(): useLayoutEffect(): got resource from cache"
      );
      setConfirmVerifCodeRes(cacheItem);
    }

    // const currentLanguage = GetCacheItem("currentLanguage");
    // console.log('In ConfirmVerificationCode(): useLayoutEffect(): currentLanguage is: ', JSON.stringify(currentLanguage, null, 2));
    // console.log('In ConfirmVerificationCode(): useLayoutEffect(): direction is: ', direction);
    // console.log('In ConfirmVerificationCode(): useLayoutEffect(): appContext.signedIn is: ', appContext.signedIn);
  }, []);

  function handleChange(event) {
    // console.log("In handleChange(): id is: "+event.target.id);
    //console.log("In handleChange(): tagName is: "+event.target.tagName);
    // console.log("In handleChange(): value is: "+event.target.value);

    setVerifyCodeButtonFlag(true); // For disable

    // Clear Flags
    setGlobalErrorFlag(false);

    switch (event.target.id) {
      case "usernameId":
        setUsernameErrFlag(false);
        setUsername(event.target.value);
        break;
      case "verificationCodeId":
        if (event.target.value) {
          setVerifyCodeButtonFlag(false);
        }
        setResendCodeFlag(false);
        setVerificationCodeErrFlag(false);
        setVerificationCode(event.target.value);
        break;
      default:
        setVerificationCode("");
        break;
    }
  }

  function validateForm() {
    let formErrFlag = false;

    setUsernameErrFlag(false);
    setVerificationCodeErrFlag(false);

    if (username === null || username === "") {
      formErrFlag = true;
      setUsernameErrFlag(true);
    } else if (!username.match(GlobalConstants.emailRegex)) {
      formErrFlag = true;
      setUsernameErrFlag(true);
    }

    if (verificationCode === null || verificationCode === "") {
      formErrFlag = true;
      setVerificationCodeErrFlag(true);
    }

    return formErrFlag;
  }

  async function confirmCodeService() {
    // const { codeMismatch } = msg;

    console.log(
      "In confirmCodeService(): verificationCode is: ",
      verificationCode
    );
    console.log("In confirmCodeService(): username is: ", username);

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");
    setConfirmSignUp(false);
    setCodeVerifiedFlag(false);

    // await SetCacheItem("isSignedIn", true);
    // await console.log("In confirmCodeService(): isSignedIn is: "+GetCacheItem("isSignedIn"));

    if (!validateForm()) {
      // Validation Passed - you can proceed
      console.log("Validation Passed.");
      setProgressFlag(true);

      let forceAliasCreation = {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: true,
      };

      Auth.confirmSignUp(username, verificationCode, forceAliasCreation)
        .then((result) => {
          console.log(
            "In confirmCodeService(): result is",
            JSON.stringify(result, null, 2)
          );
          setConfirmSignUp(true);
          setCodeVerifiedFlag(true);
          setProgressFlag(false);
        })
        .catch((err) => {
          console.log(
            "In confirmCodeService(): err is",
            JSON.stringify(err, null, 2)
          );
          setGlobalErrorFlag(true);
          setProgressFlag(false);
          switch (err.code) {
            case "CodeMismatchException":
              setGlobalErrorMsg(codeMismatch);
              break;
            default:
              setGlobalErrorMsg(codeMismatch);
              break;
          }
        });
    }
  }

  async function resendVerificationCode() {
    // const { alreadyConfirmed } = msg;
    // const { limitTrialExceeded } = msg;
    setProgressFlag(true);

    // Initialize
    setGlobalErrorFlag(false);
    setGlobalErrorMsg("");
    setResendCodeFlag(false);

    Auth.resendSignUp(username)
      .then(() => {
        setResendCodeFlag(true);
        setVerificationCode("");
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In resendVerificationCode(): err is",
          JSON.stringify(err, null, 2)
        );
        setGlobalErrorFlag(true);
        setProgressFlag(false);
        switch (err.code) {
          case "InvalidParameterException":
            setGlobalErrorMsg(alreadyConfirmed);
            break;
          case "LimitExceededException":
            setGlobalErrorMsg(limitTrialExceeded);
            break;
          default:
            break;
        }
      });
  }

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {codeVerifiedFlag && <Redirect to="/signIn" />}
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div style={{ width: "100%", height: "100%" }}>
            {progressFlag && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                  size={15}
                />
              </div>
            )}

            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="md"
            >
              {/* { confirmSignUp &&
                <ConfirmVerificationCode />
              } */}

              {!confirmSignUp && (
                <Grid container justify="center">
                  <Grid item xs={12} sm={9}>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography
                        variant="h6"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        {verificationCodeHdr}
                      </Typography>

                      <Typography
                        variant="caption"
                        style={{ color: "green", textAlign: "center" }}
                      >
                        {verificationCodeSubHdr}
                      </Typography>

                      {globalErrorFlag && (
                        <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                      )}

                      <LineSpacing value={16} />

                      <FormControl
                        className={classes.textField}
                        variant="outlined"
                        error={verificationCodeErrFlag}
                      >
                        <label
                          htmlFor="verificationCodeId"
                          style={directionAlignment}
                        >
                          <Typography
                            variant="subtitle1"
                            color={usernameErrFlag ? "error" : "inherit"}
                          >
                            {verificationCodeLabel}
                          </Typography>
                        </label>
                        <OutlinedInput
                          id="verificationCodeId"
                          value={verificationCode}
                          onChange={handleChange}
                          placeholder={enterVerificationCode}
                          required
                          fullWidth
                          autoFocus
                          error={verificationCodeErrFlag}
                        />
                      </FormControl>

                      <LineSpacing value={16} />

                      {usernameFocus && (
                        <FormControl
                          className={classes.textField}
                          variant="outlined"
                          error={usernameErrFlag}
                        >
                          <label
                            htmlFor="usernameId"
                            style={directionAlignment}
                          >
                            <Typography
                              variant="subtitle1"
                              color={usernameErrFlag ? "error" : "inherit"}
                            >
                              {usernameLabel}
                            </Typography>
                          </label>
                          <OutlinedInput
                            id="usernameId"
                            value={username}
                            onChange={handleChange}
                            placeholder={enterCorrectUsername}
                            required
                            fullWidth
                            readOnly
                            error={usernameErrFlag}
                          />
                        </FormControl>
                      )}

                      <LineSpacing value={16} />

                      <Button
                        variant="contained"
                        size="large"
                        disabled={verifyCodeButtonFlag ? true : false}
                        color="primary"
                        onClick={confirmCodeService}
                      >
                        <LockOutlinedIcon
                          className={clsx(classes.leftIcon, classes.iconSmall)}
                        />
                        {verifyCode}
                      </Button>

                      <LineSpacing value={16} />

                      {resendCodeFlag && (
                        <Grid container item justify="center">
                          <GlobalInfoMessage
                            globalInfoMsg={verificationCodeSent}
                            justify="center"
                            color="inherit"
                          />
                        </Grid>
                      )}

                      <LineSpacing value={4} />

                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            color="inherit"
                            style={directionReverseFloat}
                          >
                            <Link href="#" onClick={resendVerificationCode}>
                              {resendCode}
                            </Link>
                          </Typography>
                        </Grid>
                      </Grid>

                      <LineSpacing value={18} />
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Container>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default ConfirmVerificationCode;
