import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";

import Amplify, { Auth, API } from "aws-amplify";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Paper from "@material-ui/core/Paper";
import MatAvatar from "@material-ui/core/Avatar";
import green from "@material-ui/core/colors/green";
import pink from "@material-ui/core/colors/pink";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import CheckIcon from "@material-ui/icons/Check";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { green, pink } from '@material-ui/core/colors';
import Avatar from "@material-ui/core/Avatar";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import SetCacheItem from "../../utils/cache/SetCacheItem";
// import ClearCacheItems from '../../utils/cache/ClearCacheItems';
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";
import LineSpacing from "../../components/misc/LineSpacing";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;
  padding: 20px 30px 20px 30px !important;*/
  text-align: center !important;
  align-items: center;
`;

const StyledDiv = styled.div`
  background: cyan;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px; /* future proofing */

  display: inline-flex;
  border: 1px solid grey;
`;

const StyledButton = styled(Button)`
  background: transparent;
  border-radius: 3px;
  border: 2px solid palevioletred;
  color: palevioletred;
  margin-bottom: 12px !important;

  /* When the "primary" property is set we want to add some more css to our component */
  /* in this case change the background and color. */

  ${(props) =>
    props.color === "primary" &&
    css`
      background: green !important;
      color: white !important;
      margin: 0 20px 12px 0 !important;
    `};
`;

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  signOutCard: {
    textAlign: "center",
  },
  card: {
    marginTop: theme.spacing(4),
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  green: {
    color: "#fff",
    backgroundColor: green[500],
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  content: {
    flex: "1 0 auto",
  },
  leftCard: {
    textAlign: "center",
  },
  rightCard: {
    textAlign: "center",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    // margin: 10,
    // color: '#fff',
    // backgroundColor: red[500],
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "20px 40px 20px 40px !important",
    textAlign: "center",
  },
  checkAvatar: {
    margin: theme.spacing(1),
    /*backgroundColor: theme.palette.primary.signout,*/
    backgroundColor: green[500],
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  pinkAvatar: {
    margin: 5,
    color: "#fff",
    backgroundColor: pink[500],
  },
  greenAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: green[500],
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  container: {
    marginTop: "50px",
  },
  signout: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    backgroundColor: "#fff",
    textAlign: "center",
  },
  media2: {
    height: 140,
  },
}));

let signout = {
  signOutLabel: "Sign out",
  successSignOut: "You've successfully signed out.",
  signOutSecurityMsg:
    "For your security, make sure you close the current browser tab.",
  signOutThanks:
    "Thanks for choosing CloudCMS. We look forward to serving you again.",
  signInLabel: "Sign in",
  signInAgain: "You may sign in again.",
};

function SignOut(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [floatDirection, setFloatDirection] = useState(
    direction === "rtl" ? "left" : "right"
  );
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const [signoutRes, setSignoutRes] = useState(signout);

  const [isSignedIn, setIsSignedIn] = useState(appContext.signIn);
  const [progressFlag, setProgressFlag] = useState(false);

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const {
    signOut,
    successSignOut,
    signOutSecurityMsg,
    signOutThanks,
    signInLabel,
    signInAgain,
  } = signoutRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(signout);
    // console.log("In SignOut(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In SignOut(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
        // "queryType": "hash-query",
        // "resourcePK": currentLanguage.languageCode,
        // "keyConditionExpression": "#resourcePK = :resourcePK",
        // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
        // "expressionAttributeValues": {":resourcePK": currentLanguage.languageCode}
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            // id: ""+index,
            // ResourceLanguageCode: item.ResourceLanguageCode,
            // ResourcePK: item.ResourcePK,
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
            // ModifiedBy: item.ModifiedBy,
            // CreatedAt: item.CreatedAt,
            // UpdatedAt: item.UpdatedAt,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "signOut")
              signout.signOut = item.ResourceDesc;
            if (item.ResourceCode === "successSignOut")
              signout.successSignOut = item.ResourceDesc;
            if (item.ResourceCode === "signOutSecurityMsg")
              signout.signOutSecurityMsg = item.ResourceDesc;
            if (item.ResourceCode === "signOutThanks")
              signout.signOutThanks = item.ResourceDesc;
            if (item.ResourceCode === "signInLabel")
              signout.signInLabel = item.ResourceDesc;
            if (item.ResourceCode === "signInAgain")
              signout.signInAgain = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, signout);
          setSignoutRes(signout);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setSignoutRes(signout);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    setProgressFlag(true);
    //signOut = GetCacheItem("signOut");
    //console.log('In SignOut(): useEffect(): VerificationCode is: ', JSON.stringify(signOut, null, 2));

    // By doing this, you are revoking all the auth tokens(id token, access token and refresh token)
    // which means the user is signed out from all the devices
    // Note: although the tokens are revoked, the AWS credentials will resignout valid until they expire (which by default is 1 hour)
    // function signOutUser() {
    Auth.signOut({ global: true })
      .then((result) => {
        //console.log('In signOutUser(): result is: ', JSON.stringify(result, null, 2));
        setIsSignedIn(false);
        SetCacheItem("isSignedIn", false);
        //ClearCacheItems();

        appContext.signedIn = false;
        appContext.userName = "";
        appContext.jwtToken = null;
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log("In signOutUser(): err is: ", JSON.stringify(err, null, 2));
        setIsSignedIn(false);
        SetCacheItem("isSignedIn", false);
        setProgressFlag(false);
      });
  }, []);

  useLayoutEffect(() => {
    // console.log("In SignOut(): useLayoutEffect(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);

    // Fetch resource from catche
    let cacheItemKey = "signout";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In SignOut(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    fetchDbResources(cacheItemKey);

    // if (appContext.clearCacheLanguageCodes &&
    //   appContext.clearCacheLanguageCodes.length > 0 &&
    //   appContext.clearCacheLanguageCodes.includes(appContext.currentLanguage.languageCode
    // )) {
    //   // console.log("In SignOut(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
    //   RemoveCacheItem(cacheItemKey);
    // }

    // let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In SignOut(): useLayoutEffect(): cacheItem is: ', cacheItem);

    // if (cacheItem === null) {
    //   // resource is not in cache, fetch from DB
    //   // console.log('In SignOut(): useLayoutEffect(): resource is not in cache, fetch from DB');
    //   fetchDbResources(cacheItemKey);
    // } else {
    //   // console.log('In SignOut(): useLayoutEffect(): got resource from cache');
    //   setSignoutRes(cacheItem);
    // }
  }, []);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  return (
    <div className={classes.root} dir={direction}>
      <div className={classes.root}>
        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.signout} maxWidth="lg">
          {/* { progressFlag &&
            <div style={{textAlign: 'center'}}>
              <CircularProgress className={classes.progress} color="secondary" size={15}/>
            </div>
          } */}

          <Container component="div" maxWidth="md">
            <Paper elevation={3} className={classes.paper}>
              <Grid container spacing={3} justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Grid container justify="center">
                    <Avatar
                      className={clsx(
                        classes.green,
                        classes.large,
                        classes.avatar
                      )}
                      style={{ alignSelf: "center" }}
                    >
                      <CheckIcon />
                    </Avatar>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h4">{successSignOut}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">{signOutSecurityMsg}</Typography>

                  <Typography variant="h6">{signOutThanks}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle2">{signInAgain}</Typography>

                  <LineSpacing value={12} />

                  <Link component={RouterLink} to="/signIn" variant="body2">
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      className={classes.button}
                    >
                      <LockOutlinedIcon
                        className={clsx(classes.leftIcon, classes.iconSmall)}
                      />
                      {signInLabel}
                    </Button>
                  </Link>
                </Grid>
              </Grid>

              <LineSpacing value={12} />
            </Paper>
          </Container>

          {/* <Container component="div" className={classes.signout} maxWidth="md">
              <Card style={{padding: '30px'}}>
                <Grid container spacing={3} justify="center">
                  <Grid item xs={12} sm={5} style={{background: '#3f51b5'}}> 
                    <Grid container spacing={1} justify="center">
                      <Grid item xs={1} sm={1} >
                        <Avatar  className={clsx(classes.pink, classes.large)}>
                          <CheckIcon />
                        </Avatar>
                      </Grid>

                      <Grid item item xs={11} sm={11}>
                        <Typography variant="caption" style={{color: '#fff'}}>
                          This is the text. This is the text. This is the text. This is the text. This is the text. This is the text. This is the text. 
                          This is the text. This is the text. This is the text. This is the text. This is the text. This is the text. This is the text. 
                        </Typography>
                      </Grid>

                      <Grid item xs={1} sm={1}>
                        <Avatar  className={clsx(classes.pink, classes.large)}>
                          <CheckIcon />
                        </Avatar>
                      </Grid>

                      <Grid item item xs={11} sm={11}>
                        <Typography variant="caption" style={{color: '#fff'}} display="block">
                          This is the text. This is the text. This is the text. This is the text. This is the text. This is the text. This is the text. 
                          This is the text. This is the text. This is the text. This is the text. This is the text. This is the text. This is the text. 
                          This is the text. This is the text. This is the text. This is the text. This is the text. This is the text. This is the text. 
                          President Trump is trying to calm weary investors as he plans to tout low unemployment in his push for reelection in 2020. But this centerpiece of his campaign effort comes as some economists warn of another recession taking shape. Nearly 70% of CFO's said they fear a
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={1} style={{background: '#fff', verticalAlign: 'center'}}>
                  </Grid>

                  <Grid item xs={12} sm={6} style={{background: '#fff', verticalAlign: 'center'}}>
                    <Card className={classes.rightCard}>
                      <CardHeader
                        avatar={
                          <MatAvatar className={classes.greenAvatar} size="jumbo">
                            <CheckIcon />
                          </MatAvatar>
                        }

                        title={successSignOut}
                        subheader={signInAgain}
                      />
                      
                      <CardActions style={{float: floatDirection, margin: '0 10px 0 10px'}}>
                        <Link component={ RouterLink } to="/signIn" variant="body2">
                          <StyledButton variant="contained" size="small" color="primary" className={classes.button}>
                            <LockOutlinedIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
                            {signInLabel}
                          </StyledButton>
                        </Link>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            </Container> */}

          <Grid
            container
            spacing={3}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <MatAvatar aria-label="Recipe" className={classes.avatar}>
                    R
                  </MatAvatar>
                }
                action={
                  <IconButton>
                    <MoreVertIcon />
                  </IconButton>
                }
                title="Shrimp and Chorizo Paella"
                subheader="September 14, 2016"
              />

              <CardMedia
                className={classes.media}
                image="https://material-ui.com/static/images/cards/paella.jpg"
                title="Paella dish"
              />

              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  This impressive paella is a perfect party dish and a fun meal
                  to cook together with your guests. Add 1 cup of frozen peas
                  along with the mussels, if you like.
                </Typography>
              </CardContent>

              <CardActions disableSpacing>
                <IconButton aria-label="Add to favorites">
                  <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="Share">
                  <ShareIcon />
                </IconButton>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="Show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>

              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography paragraph>Method:</Typography>
                  <Typography paragraph>
                    Heat 1/2 cup of the broth in a pot until simmering, add
                    saffron and set aside for 10 minutes.
                  </Typography>

                  <Typography paragraph>
                    Heat oil in a (14- to 16-inch) paella pan or a large, deep
                    skillet over medium-high heat. Add chicken, shrimp and
                    chorizo, and cook, stirring occasionally until lightly
                    browned, 6 to 8 minutes. Transfer shrimp to a large plate
                    and set aside, leaving chicken and chorizo in the pan. Add
                    pimentón, bay leaves, garlic, tomatoes, onion, salt and
                    pepper, and cook, stirring often until thickened and
                    fragrant, about 10 minutes. Add saffron broth and
                    resignouting 4 1/2 cups chicken broth; bring to a boil.
                  </Typography>

                  <Typography paragraph>
                    Add rice and stir very gently to distribute. Top with
                    artichokes and peppers, and cook without stirring, until
                    most of the liquid is absorbed, 15 to 18 minutes. Reduce
                    heat to medium-low, add reserved shrimp and mussels, tucking
                    them down into the rice, and cook again without stirring,
                    until mussels have opened and rice is just tender, 5 to 7
                    minutes more. (Discard any mussels that don’t open.)
                  </Typography>

                  <Typography>
                    Set aside off of the heat to let rest for 10 minutes, and
                    then serve.
                  </Typography>
                </CardContent>
              </Collapse>
            </Card>
          </Grid>

          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  className={classes.media2}
                  image="https://material-ui.com/static/images/cards/contemplative-reptile.jpg"
                  title="Contemplative Reptile"
                />

                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Lizard
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Lizards are a widespread group of squamate reptiles, with
                    over 6,000 species, ranging across all continents except
                    Antarctica
                  </Typography>
                </CardContent>
              </CardActionArea>

              <CardActions>
                <Button size="small" color="primary">
                  Share
                </Button>
                <Button size="small" color="primary">
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter
              currentLanguage={currentLanguage}
              isSignedIn={isSignedIn}
            />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default SignOut;
