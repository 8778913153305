import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import Amplify, { Auth, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

// AppContext
import AppContext from "../../config/AppContext";

Amplify.configure(AmplifyConfig);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#3f51b5",
  },
  progress: {
    margin: theme.spacing(1),
  },
  pageContainer: {
    marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(1),
  },
  link: {
    marginTop: 4,
    marginBottom: 12,
    color: "#ffffff",
  },
  grid: {
    color: "#ffffff",
  },
}));

let bottomFooterLabels = {
  terms: "Terms",
  privacy: "Privacy",
  platform: "Platform",
  security: "Security",
};

function PageBottomFooter(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  const [currentYear, setCurrentYear] = useState(2019);
  const [progressFlag, setProgressFlag] = useState(false);
  const [containerMaxWidth, setContainerMaxWidth] = useState("lg"); // xl, lg, md, sm, xs

  // For Bottom Page Footer
  const [bottomFooterLabelsRes, setBottomFooterLabelsRes] = useState(
    bottomFooterLabels
  );

  const classes = useStyles();

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  const { currLanguage } = props;

  const { terms, privacy, security, platform } = bottomFooterLabelsRes;

  const tobiSoftInc = "TobiSoft Inc.";

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(bottomFooterLabels);
    // console.log("In BottomFooterLabels(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In BottomFooterLabels(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "terms")
              bottomFooterLabels.terms = item.ResourceDesc;
            if (item.ResourceCode === "privacy")
              bottomFooterLabels.privacy = item.ResourceDesc;
            if (item.ResourceCode === "security")
              bottomFooterLabels.security = item.ResourceDesc;
            if (item.ResourceCode === "platform")
              bottomFooterLabels.platform = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, bottomFooterLabels);
        }
        setBottomFooterLabelsRes(bottomFooterLabels);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setBottomFooterLabelsRes(bottomFooterLabels);
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log('In PageBottomFooter(): useEffect(): currLanguage is: ', JSON.stringify(currLanguage, null, 2));
    // console.log('In PageBottomFooter(): useEffect(): currentLanguage is: ', JSON.stringify(currentLanguage, null, 2));

    // currLanguage is the most current language passed to this component through props
    // We are using useEffect to get the functionality of componentDidUpdate()
    if (
      currLanguage !== undefined &&
      currLanguage !== null &&
      currLanguage !== currentLanguage
    ) {
      setCurrentLanguage(currLanguage);
      appContext.currentLanguage = currLanguage;

      // console.log('In PageBottomFooter(): useEffect(): about to call fetchDbResources()');

      // Fetch resource from catche
      let cacheItemKey = "bottomFooterLabels";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      // cacheItemKey += currLanguage.languageCode;
      // console.log('In BottomFooterLabels(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        // console.log("In BottomFooterLabels(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
        RemoveCacheItem(cacheItemKey);
      }

      let cacheItem = GetCacheItem(cacheItemKey);
      // console.log('In BottomFooterLabels(): useLayoutEffect(): cacheItem is: ', cacheItem);

      if (cacheItem === null) {
        // resource is not in cache, fetch from DB
        // console.log('In BottomFooterLabels(): useLayoutEffect(): resource is not in cache, fetch from DB');
        fetchDbResources(cacheItemKey);
      } else {
        // console.log('In BottomFooterLabels(): useLayoutEffect(): got resource from cache');
        setBottomFooterLabelsRes(cacheItem);
      }
    }
  });

  useLayoutEffect(() => {
    // console.log("In BottomFooterLabels(): useLayoutEffect()");

    // Calculate current year
    let today = new Date();
    const currYear = today.getFullYear();
    setCurrentYear(currYear);

    // Fetch resource from catche
    let cacheItemKey = "bottomFooterLabels";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In BottomFooterLabels(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In BottomFooterLabels(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In BottomFooterLabels(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In BottomFooterLabels(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In BottomFooterLabels(): useLayoutEffect(): got resource from cache');
      setBottomFooterLabelsRes(cacheItem);
    }
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Container
        component="div"
        className={classes.pageContainer}
        maxWidth={containerMaxWidth}
      >
        {progressFlag && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress
              className={classes.progress}
              color="secondary"
              size={15}
            />
          </div>
        )}

        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={8} sm={4}>
            <Link component={RouterLink} to="/">
              <Typography variant="subtitle2" className={classes.link}>
                &copy;{currentYear} {tobiSoftInc}
              </Typography>
            </Link>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container spacing={2} className={classes.grid}>
              <Grid item xs={4} sm={2}>
                <Link component={RouterLink} to="/terms">
                  <Typography variant="subtitle2" className={classes.link}>
                    {terms}
                  </Typography>
                </Link>
              </Grid>

              <Grid item xs={4} sm={2}>
                <Link component={RouterLink} to="/privacy">
                  <Typography variant="subtitle2" className={classes.link}>
                    {privacy}
                  </Typography>
                </Link>
              </Grid>

              <Grid item xs={4} sm={2}>
                <Link component={RouterLink} to="/platformAgreement">
                  <Typography variant="subtitle2" className={classes.link}>
                    {platform}
                  </Typography>
                </Link>
              </Grid>

              <Grid item xs={4} sm={2}>
                <Link component={RouterLink} to="/security">
                  <Typography variant="subtitle2" className={classes.link}>
                    {security}
                  </Typography>
                </Link>
              </Grid>

              <Grid item xs={4} sm={2}></Grid>

              <Grid item xs={4} sm={2}></Grid>
            </Grid>
          </Grid>

          <Grid item xs={4} sm={2}></Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default PageBottomFooter;
