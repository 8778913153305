import React from "react";
import { makeStyles } from "@material-ui/core/styles";

function ImageCellRenderer(props) {
  const { value } = props;

  const {
    // style,
    // currency,
    // currencyDisplay,
    // useGrouping,
    // minimumIntegerDigits,
    // minimumFractionDigits,
    // maximumFractionDigits,
    imageWidth,
    divContainerHeight,

    // payload,
  } = props.agGridReact.gridOptions;

  const useStyles = makeStyles((theme) => ({
    divContainer: {
      height: divContainerHeight, // '32px !important'
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    image: {
      width: imageWidth, // '16px !important'
    },
  }));

  const classes = useStyles();

  // console.log("In ImageCellRenderer(): value is: ", value);
  // console.log("In ImageCellRenderer(): imageWidth is: ", imageWidth);
  // console.log("In ImageCellRenderer(): divContainerHeight is: ", divContainerHeight);
  // console.log("In ImageCellRenderer(): props.agGridReact.gridOptions is: ", props.agGridReact.gridOptions);

  return (
    <div className={classes.divContainer}>
      <img src={value} className={classes.image} />
    </div>
  );
}

export default ImageCellRenderer;
