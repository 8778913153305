import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
} from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
// import Button from '@material-ui/core/Button';
import CssBaseline from "@material-ui/core/CssBaseline";
// import { NavLink } from 'react-router-dom';
// import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { red } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";

// FileSaver.js
import { saveAs } from "file-saver";

import Amplify, { API, Storage } from "aws-amplify";
import { S3Image, S3Album } from "aws-amplify-react";

import Resizer from "react-image-file-resizer";

import AmplifyConfig from "../../../config/AmplifyConfig";

// Cache Utilities
import SetCacheItem from "../../../utils/cache/SetCacheItem";
import GetCacheItem from "../../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../../utils/GenerateModulusHash";

import AppMainMenu from "../../headers/AppMainMenu";
import PageFooter from "../../footers/PageFooter";

// Galleries and Carousel
import PhotoGallery from "../../../components/imageTools/PhotoGallery";
import BnbGallery from "../../../components/imageTools/BnbGallery";
import ModalImageViewer from "../../../components/imageTools/ModalImageViewer";
import ImageCarousel from "../../../components/imageTools/ImageCarousel";
import ImagePhotoEditor from "../../../components/imageTools/ImagePhotoEditor";
import LineSpacing from "../../../components/misc/LineSpacing";

// AppContext
import AppContext from "../../../config/AppContext";
import ResizeImage from "../../../components/imageTools/ResizeImage";

Amplify.configure(AmplifyConfig);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#fff",
  },
  progress: {
    margin: theme.spacing(1),
  },
  pageContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  mainGrid: {
    textAlign: "left",
  },
  titleGrid: {
    textAlign: "center",
  },
  title: {
    color: "#000",
    textAlign: "center",
  },
  subTitle: {
    color: "#000",
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  titleContent: {
    color: "#000",
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  bodyGrid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: "#fff",
  },
  bodyLC: {
    color: "#000",
    textAlign: "left",
    background: "#fff",
  },
  bodyContent: {
    color: "#000",
    textAlign: "left",
    background: "#fff",
  },
  bodyRC: {
    color: "#000",
    textAlign: "left",
    background: "#fff",
  },
  footerGrid: {
    textAlign: "center",
    marginTop: theme.spacing(1),
    background: "#fff",
  },
  footerTC: {
    color: "#000",
    textAlign: "left",
    marginTop: theme.spacing(1),
    background: "#fff",
  },
  footerMiddleContent: {
    color: "#000",
    textAlign: "left",
    marginTop: theme.spacing(1),
    background: "#fff",
  },
  footerBottomContent: {
    color: "#000",
    textAlign: "left",
    marginTop: theme.spacing(1),
    background: "#fff",
  },
  textItem: {
    color: "#000",
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    backgroundColor: "#fff",
    textAlign: "center",
  },
  smallImgClassName: {
    width: "100%",
  },
  carouselContainer: {
    position: "relative",
    textAlign: "center",
    color: "red",
    width: "100% !important",
  },
  carouselAbsDiv: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

let menuNoSub2 = {
  menuNoSub2Title: "MenuNoSub2 Title",
  menuNoSub2SubTitle: "Sub title",
  menuNoSub2TC: "Top content",
  menuNoSub2LC: "Left content",
  menuNoSub2BC: "Body content",
  menuNoSub2RC: "Right content",
  menuNoSub2FCT: "Footer top",
  menuNoSub2FCM: "Footer middle",
  menuNoSub2FCB: "Footer bottom",
};

function MenuNoSub2(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [currentLanguageCode, setCurrentLanguageCode] = useState(
    appContext.currentLanguage.languageCode
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.isSignedIn);

  // Page Column Format
  // 'leftBodyRight' = render left, body and right of page
  // 'bodyOnly' = render only body of page
  // 'leftAndBody' = render left column and body of page
  // 'bodyAndRight' = render body and right column of page
  const [pageColumnFormat, setPageColumnFormat] = useState("bodyOnly");
  const [containerMaxWidth, setContainerMaxWidth] = useState("lg"); // xl, lg, md, sm, xs

  // For Products
  const [menuNoSub2Res, setMenuNoSub2Res] = useState(menuNoSub2);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [progressFlag, setProgressFlag] = useState(false);

  // For BnbGallery
  const [bnbGalleryOpened, setBnbGalleryOpened] = useState(true);
  const [showThumbnails, setShowThumbnails] = useState(true);
  const [cycleContinously, setCycleContinously] = useState(true);
  const [bnbBackgroundColor, setBnbBackgroundColor] = useState("black");
  const [phrases, setPhrases] = useState("Hide/Show");

  // For ModalImageViewer
  const [smallImgTitle, setSmallImgTitle] = useState("This small image title");
  const [smallImgUrl, setSmallImgUrl] = useState(
    "https://aautio.github.io/react-modal-image/example_img_small.jpg"
  );
  const [mediumImgUrl, setMediumImgUrl] = useState(
    "https://aautio.github.io/react-modal-image/example_img_medium.jpg"
  );
  const [largeImgUrl, setLargeImgUrl] = useState(
    "https://aautio.github.io/react-modal-image/example_img_large.jpg"
  );

  const [hideDownload, setHideDownload] = useState(false);
  const [hideZoom, setHideZoom] = useState(false);
  const [showRotate, setShowRotate] = useState(true);
  const [imageBackgroundColor, setImageBackgroundColor] = useState("#000");

  // For ImageCarousel
  const imageCarouselRef = useRef(null);
  const [imageItems, setImageItems] = useState([]);
  const [imageItemsDesc, setImageItemsDesc] = useState([]);
  const [lazyLoadFlag, setLazyLoadFlag] = useState(false);
  const [infiniteFlag, setInfiniteFlag] = useState(true);
  const [showBulletsFlag, setShowBulletsFlag] = useState(true);
  const [showFullscreenButtonFlag, setShowFullscreenButtonFlag] = useState(
    false
  );
  const [
    showGalleryFullscreenButtonFlag,
    setShowGalleryFullscreenButtonFlag,
  ] = useState(true);
  const [showPlayButtonFlag, setShowPlayButtonFlag] = useState(true);
  const [showGalleryPlayButtonFlag, setShowGalleryPlayButtonFlag] = useState(
    true
  );
  const [showThumbnailsFlag, setShowThumbnailsFlag] = useState(true);
  const [showIndexFlag, setShowIndexFlag] = useState(true);
  const [showNavFlag, setShowNavFlag] = useState(true);
  const [thumbnailPosition, setThumbnailPosition] = useState("bottom"); // bottom
  const [slideDuration, setSlideDuration] = useState(450); // 450
  const [slideInterval, setSlideInterval] = useState(5000); // 2000
  const [slideOnThumbnailOverFlag, setSlideOnThumbnailOverFlag] = useState(
    false
  );

  const [mediaDescription, setMediaDescription] = useState("");

  // For AWS Amplify Storage
  const [storageLevel, setStorageLevel] = useState("private"); // public, protected or private
  const [domain, setDomain] = useState(appContext.domain); // ccms
  const [userName, setUserName] = useState(appContext.userName); // "onyii5119@gmail.com"
  // const [objectKeyPath, setObjectKeyPath] = useState("onyii5119@gmail.com");// onyii5119@gmail.com  jonathan.ekwempu@cloudmediainc.com
  const [customPrefix, setCustomPrefix] = useState(null);
  const [contentType, setContentType] = useState("text/plain");
  const [cacheControl, setCacheControl] = useState(null);
  const [contentDisposition, setContentDisposition] = useState("");
  const [expires, setExpires] = useState(3 * 60); // Expires in 3 minutes
  const [metadata, setMetadata] = useState(null);
  const [presignedURLs, setPresignedURLs] = useState([]);
  const [imageCount, setImageCount] = useState(0);

  // For ImagePhotoEditor
  const imageEditorRef = useRef(null);
  const [imageEditor, setImageEditor] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedMediaUrl, setSelectedMediaUrl] = useState("");
  const [imageEditorFlag, setImageEditorFlag] = useState(false);

  const supportingFileAPI = !!(
    window.File &&
    window.FileList &&
    window.FileReader
  );

  const classes = useStyles();

  const {
    menuNoSub2Title,
    menuNoSub2TC,
    menuNoSub2SubTitle,
    menuNoSub2LC,
    menuNoSub2BC,
    menuNoSub2RC,
    menuNoSub2FCT,
    menuNoSub2FCM,
    menuNoSub2FCB,
  } = menuNoSub2Res;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(menuNoSub2);
    // console.log("In MenuNoSub2(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In MenuNoSub2(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "menuNoSub2Title")
              menuNoSub2.menuNoSub2Title = item.ResourceDesc;
            if (item.ResourceCode === "menuNoSub2SubTitle")
              menuNoSub2.menuNoSub2SubTitle = item.ResourceDesc;
            if (item.ResourceCode === "menuNoSub2TC")
              menuNoSub2.menuNoSub2TC = item.ResourceDesc;
            if (item.ResourceCode === "menuNoSub2LC")
              menuNoSub2.menuNoSub2LC = item.ResourceDesc;
            if (item.ResourceCode === "menuNoSub2BC")
              menuNoSub2.menuNoSub2BC = item.ResourceDesc;
            if (item.ResourceCode === "menuNoSub2RC")
              menuNoSub2.menuNoSub2RC = item.ResourceDesc;
            if (item.ResourceCode === "menuNoSub2FCT")
              menuNoSub2.menuNoSub2FCT = item.ResourceDesc;
            if (item.ResourceCode === "menuNoSub2FCM")
              menuNoSub2.menuNoSub2FCM = item.ResourceDesc;
            if (item.ResourceCode === "menuNoSub2FCB")
              menuNoSub2.menuNoSub2FCB = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, menuNoSub2);
        }
        setMenuNoSub2Res(menuNoSub2);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setMenuNoSub2Res(menuNoSub2);
        setProgressFlag(false);
      });
  }

  //--------------------------------
  // For ImagePhotoEditor - config
  //--------------------------------

  let imageEditorConfig = {
    language: "en",
    translations: {
      en: {
        "header.image_editor_title": "My Filerobot Image Editor",
        "toolbar.download": "Download",
        "toolbar.save": "Save",
        "toolbar.apply": "Apply",
        "toolbar.cancel": "Cancel",
        "toolbar.go_back": "Go Back",
        "toolbar.adjust": "Adjust",
        "toolbar.effects": "Effects",
        "toolbar.filters": "Filters",
        "toolbar.orientation": "Orientation",
        "toolbar.crop": "Crop",
        "toolbar.resize": "Resize",
        "toolbar.watermark": "Watermark",
        "adjust.brightness": "Brightness",
        "adjust.contrast": "Contrast",
        "adjust.exposure": "Exposure",
        "adjust.saturation": "Saturation",
        "orientation.rotate_l": "Rotate Left",
        "orientation.rotate_r": "Rotate Right",
        "orientation.flip_h": "Flip Horizontally",
        "orientation.flip_v": "Flip Vertically",
        "pre_resize.title":
          "Would you like to reduce resolution before editing the image?",
        "pre_resize.keep_original_resolution": "Keep original resolution",
        "pre_resize.resize_n_continue": "Resize & Continue",
        "footer.reset": "Reset",
        "footer.undo": "Undo",
        "footer.redo": "Redo",
        "spinner.label": "Processing...",
        "warning.too_big_resolution":
          "The resolution of the image is too big for the web. It can cause problems with Image Editor performance.",
        "common.width": "width",
        "common.height": "height",
        "common.custom": "custom",
        "common.original": "original",
        "common.square": "square",
        "common.opacity": "opacity",
        "common.apply_watermark": "Apply watermark",
      },
    },
  };

  let mediaUrls = [];

  async function getAwsImage(objectKey, imgCount, callback) {
    // console.log("In getAwsImage(): objectKey: ", objectKey);

    setProgressFlag(true);

    await Storage.get(objectKey, {
      level: storageLevel,
      customPrefix: customPrefix !== null ? customPrefix : null,
      contentType: contentType !== null ? contentType : "text/plain",
      cacheControl: cacheControl !== null ? cacheControl : "",
      contentDisposition: contentDisposition !== null ? contentDisposition : "",
      expires: expires !== null ? expires : null,
      metadata: metadata !== null ? metadata : null,
      download: false,
    })
      .then((result) => {
        // console.log("In getAwsImage(): result is: ", result);

        if (result) {
          let item = { url: result };
          mediaUrls.push(item);
          callback(imgCount);
        }

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log("In getAwsImage(): err is: ", err);
      });
  }

  const callbackFn = (imgCount) => {
    // console.log("In callbackFn(): Partial Processing complete. imgCount is: ", imgCount);
    // console.log("In callbackFn(): Partial Processing complete. mediaUrls.length is: ", mediaUrls.length);
    if (mediaUrls.length === imgCount) {
      setPresignedURLs(mediaUrls);
      // console.log("In callbackFn(): Processing complete: mediaUrls is: ", mediaUrls);
    }
  };

  async function getImageKeys(theObject) {
    // console.log("In getImageKeys(): theObject.length is: ", theObject.length);
    if (theObject && theObject.length > 0) {
      setImageCount(theObject.length);

      setTimeout(async function () {
        await theObject.map((item) =>
          getAwsImage(item.key, theObject.length, callbackFn)
        );
      }, 1000);
    }
  }

  async function listAwsImages() {
    // console.log("In listAwsImages()");

    setProgressFlag(true);

    // For public: ccms/onyii5119@gmail.com/myfile.png  For protected/private:
    let objectKey =
      storageLevel === "public"
        ? domain + "/" + userName + "/"
        : domain + "/" + userName + "/";
    // let objectKey = storageLevel === 'public' ? '' : objectKeyPath+"/";

    Storage.list(objectKey, {
      level: storageLevel,
      customPrefix: customPrefix !== null ? customPrefix : null,
      contentType: contentType !== null ? contentType : "text/plain",
      cacheControl: cacheControl !== null ? cacheControl : "",
      contentDisposition: contentDisposition !== null ? contentDisposition : "",
      expires: expires !== null ? expires : null,
      metadata: metadata !== null ? metadata : null,
      download: false,
    })
      .then((result) => {
        // console.log("In listAwsImages(): result is: ", result);
        setImageCount(result.length);
        getImageKeys(result);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log("In listAwsImages(): err is: ", err);
      });
  }

  useEffect(() => {
    // console.log("In MenuNoSub2(): useEffect()");
    if (imageEditorRef) {
      // const imageEditorInstance = imageEditorRef.current.getInstance();
      // // console.log('In useEffect(): imageEditorInstance is: ', imageEditorInstance);
      // if (imageEditorInstance) {
      //     setImageEditor(imageEditorInstance);
      // }
    }

    populateImages();

    listAwsImages();
  }, []);

  useLayoutEffect(() => {
    // console.log("In MenuNoSub2(): useLayoutEffect()");

    // Fetch resource from catche
    let cacheItemKey = "menuNoSub2";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In MenuNoSub2(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In MenuNoSub2(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In MenuNoSub2(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In MenuNoSub2(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In MenuNoSub2(): useLayoutEffect(): got resource from cache');
      setMenuNoSub2Res(cacheItem);
    }
  }, []);

  //-----------------------
  // For Gallery Items
  //-----------------------

  let galleryItems = [
    {
      src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
      width: 4,
      height: 3,
    },
    {
      src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
      width: 1,
      height: 1,
    },
    {
      src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
      width: 3,
      height: 4,
    },
    {
      src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
      width: 3,
      height: 4,
    },
    {
      src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
      width: 3,
      height: 4,
    },
    {
      src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
      width: 4,
      height: 3,
    },
    {
      src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
      width: 3,
      height: 4,
    },
    {
      src: "https://source.unsplash.com/PpOHJezOalU/800x599",
      width: 4,
      height: 3,
    },
    {
      src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
      width: 4,
      height: 3,
    },
  ];

  //-----------------------
  // For Bnb Gallery Items
  //-----------------------

  let bnbGalleryItems = [
    {
      photo: "https://source.unsplash.com/aZjw7xI3QAA/1144x763",
      number: 1,
      caption: "Caption 1",
      subcaption: "Subcaption 1",
    },
    {
      photo: "https://source.unsplash.com/c77MgFOt7e0/1144x763",
      number: 2,
      caption: "Caption 2",
      subcaption: "Subcaption 2",
    },
    {
      photo: "https://source.unsplash.com/YIHRJrdgJqw/1144x763",
      number: 3,
      caption: "Caption 3",
      subcaption: "Subcaption 3",
    },
    {
      photo: "https://source.unsplash.com/tC_SbXEhS5Y/1144x763",
      number: 4,
      caption: "Caption 4",
      subcaption: "Subcaption 4",
    },
    {
      photo: "https://source.unsplash.com/h9Iq22JJlGk/1144x763",
      number: 5,
      caption: "Caption 5",
      subcaption: "Subcaption 5",
    },
    {
      photo: "https://source.unsplash.com/6NT7jy6OU9I/1144x763",
      number: 6,
      caption: "Caption 6",
      subcaption: "Subcaption 6",
    },
    {
      photo: "https://source.unsplash.com/-vWmir7fGRM/1144x763",
      number: 7,
      caption: "Caption 7",
      subcaption: "Subcaption 7",
    },
    {
      photo: "https://source.unsplash.com/8AJN9q9Rxqw/1144x763",
      number: 8,
      caption: "Caption 8",
      subcaption: "Subcaption 8",
    },
    {
      photo: "https://source.unsplash.com/kI00pEcN4bg/1144x763",
      number: 9,
      caption: "Caption 9",
      subcaption: "Subcaption 9",
    },
  ];

  function toggleBnbGallery() {
    setBnbGalleryOpened(!bnbGalleryOpened);
  }

  //-----------------------
  // For ImageCarousel
  //-----------------------

  const PREFIX_URL =
    "https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/";
  // let imageItems = [];

  // Original image dimension: width: 1083px, height: 722px;  Thumbnail image dimension: width: 150px, height: 100px
  function populateImages() {
    let images = [];
    let desc = [];
    for (let i = 2; i < 12; i++) {
      images.push({
        original: `${PREFIX_URL}${i}.jpg`,
        thumbnail: `${PREFIX_URL}${i}t.jpg`,
        // description:`${i}.jpg item descriptiom`
      });
      desc.push(`${i}.jpg item descriptiom`);

      // Initialization
      if (i === 2) {
        setMediaDescription(`${i}.jpg item descriptiom`);
      }
    }
    setImageItems(images);
    setImageItemsDesc(desc);
    // console.log("In populateImages(): images.length is: ", images.length);
  }

  // Use this to know which image was clicked
  function onClick(event) {
    // console.log('In onClick(): event', event);
    console.log(
      "In onClick(): clicked image index is: ",
      imageCarouselRef.current.state.currentIndex
    );
    console.log("In onClick():  image", event.target);
  }

  const onImageLoad = (event) => {
    // console.log('loaded image', event.target.src);
  };

  const onSlide = (currentIndex) => {
    // console.log('In onSlide(): currentIndex is: ', currentIndex);
    switch (currentIndex) {
      case 0:
        setMediaDescription(imageItemsDesc[currentIndex]);
        break;
      case 1:
        setMediaDescription(imageItemsDesc[currentIndex]);
        break;
      case 2:
        setMediaDescription(imageItemsDesc[currentIndex]);
        break;
      case 3:
        setMediaDescription(imageItemsDesc[currentIndex]);
        break;
      case 4:
        setMediaDescription(imageItemsDesc[currentIndex]);
        break;
      case 5:
        setMediaDescription(imageItemsDesc[currentIndex]);
        break;
      case 6:
        setMediaDescription(imageItemsDesc[currentIndex]);
        break;
      case 7:
        setMediaDescription(imageItemsDesc[currentIndex]);
        break;
      case 8:
        setMediaDescription(imageItemsDesc[currentIndex]);
        break;
      case 9:
        setMediaDescription(imageItemsDesc[currentIndex]);
        break;
      case 10:
        setMediaDescription(imageItemsDesc[currentIndex]);
        break;
      default:
        setMediaDescription(imageItemsDesc[currentIndex]);
        break;
    }
  };

  const onBeforeSlide = (nextIndex) => {
    console.log("In onBeforeSlide(): nextIndex is: ", nextIndex);
  };

  function onPause(index) {
    // console.log('paused on index', index);
  }

  function onScreenChange(fullScreenElement) {
    // console.log('isFullScreen?', !!fullScreenElement);
  }

  function onPlay(index) {
    // console.log('playing from index', index);
  }

  function handleInputChange(state, event) {
    // this.setState({[state]: event.target.value});
  }

  function handleCheckboxChange(state, event) {
    // this.setState({[state]: event.target.checked});
  }

  function handleThumbnailPositionChange(event) {
    // this.setState({thumbnailPosition: event.target.value});
  }

  const imageOnClick = (event) => {
    // console.log('In imageOnClick(): event is: ', event);
    console.log("In imageOnClick(): event.target.src is: ", event.target.src);

    setSelectedMediaUrl(event.target.src);
    imageEditorShow();

    // Don't delete
    // let file = null;
    // if (file) {
    //     const reader = new FileReader();
    //     reader.addEventListener('load', () => {
    //             console.log("In imageOnClick(): reader.result is: ", reader.result);
    //             setSelectedImage(reader.result)
    //         }
    //     );
    //     reader.readAsDataURL(file);
    // }
  };

  //--------------------
  // For imageEditor
  //--------------------

  const imageEditorShow = () => {
    setImageEditorFlag(true);
  };

  const imageEditorClose = () => {
    setImageEditorFlag(false);
  };

  const onComplete = (props) => {
    console.log("In onComplete(): props is: ", props);
  };

  const onBeforeComplete = (props) => {
    console.log("In onBeforeComplete(): props is: ", props);
  };

  const imageEditorOptions = {
    // loadImage: {
    path: selectedImage,
    name: "SampleImage",
    // },
  };

  const handleFileChange = (event) => {
    console.log("In handleFileChange(): event is: ", event);
    var file;

    if (!supportingFileAPI) {
      alert("This browser does not support file-api");
    }

    file = event.target.files[0];

    // Make sure `file.name` matches our extensions criteria
    if (file && /\.(jpe?g|png|gif)$/i.test(file.name)) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        // console.log("In handleFileChange(): reader.result is: ", reader.result);
        setSelectedImage(reader.result);

        imageEditorShow();
      });
      reader.readAsDataURL(file);
    }

    // imageEditor.loadImageFromFile(file).then(result => {
    //     console.log('In handleFileChange(): result is: ', result);
    //     imageEditor.clearUndoStack();
    // });
  };

  const handleFlipImage = (event) => {
    console.log("In handleFlipImage(): imageEditorRef is: ", imageEditorRef);
    if (imageEditor) {
      // imageEditor.flipX();
      // imageEditor.flipY();
      // imageEditor.crop(imageEditor.startDrawingMode('CROPPER'));
      imageEditor.setAngle(-30)["catch"](() => {});
    }
  };

  function base64ToBlob(data) {
    var mimeString = "";
    var raw, uInt8Array, i, rawLength;
    var rImageType = /data:(image\/.+);base64,/;

    raw = data.replace(rImageType, (header, imageType) => {
      mimeString = imageType;

      return "";
    });

    raw = atob(raw);
    rawLength = raw.length;
    uInt8Array = new Uint8Array(rawLength); // eslint-disable-line

    for (i = 0; i < rawLength; i += 1) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: mimeString });
  }

  const downloadEditedImage = () => {
    const supportingFileAPI = !!(
      window.File &&
      window.FileList &&
      window.FileReader
    );
    var imageName = imageEditor.getImageName();
    var dataURL = imageEditor.toDataURL();
    var blob, type, w;
    console.log("In downloadEditedImage(): initial imageName is: ", imageName);

    if (supportingFileAPI) {
      blob = base64ToBlob(dataURL);
      type = blob.type.split("/")[1];
      console.log("In downloadEditedImage(): blob type is: ", type);
      if (imageName.split(".").pop() !== type) {
        let imgName = imageName.split(".")[0];
        imgName += "." + type;
        imageName = imgName;
        // imageName += '.' + type;
      }
      console.log("In downloadEditedImage(): final imageName is: ", imageName);

      // Library: FileSaver.js - saveAs
      saveAs(blob, imageName); // eslint-disable-line
    } else {
      alert("This browser needs a file-server");
      w = window.open();
      w.document.body.innerHTML = '<img src="' + dataURL + '">';
    }
  };

  //--------------------------------
  // For ImageResizer
  //--------------------------------

  const [imageUri, setImageUri] = useState(null);
  const [imageName, setImageName] = useState("testme.jpg");
  const [maxWidth, setMaxWidth] = useState(200);
  const [maxHeight, setMaxHeight] = useState(200);
  const [compressFormat, setCompressFormat] = useState("JPEG"); // PNG, JPEG
  const [quality, setQuality] = useState(100); // 0 - 100
  const [rotation, setRotation] = useState(0); // 0, 90, 180, 270, 360
  const [outputType, setOutputType] = useState("base64");

  const responseUriFunc = (uri) => {
    console.log("In responseUriFunc(): uri is: ", uri);
    setImageUri(uri);
  };

  const resizeTheImage = (file) => {
    console.log("In resizeTheImage(): file is: ", file);
    return Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      rotation,
      (uri) => {
        setImageUri(uri);
      },
      outputType
    );

    // <ResizeImage
    //     file={file}
    //     maxWidth={maxWidth}
    //     maxHeight={maxHeight}
    //     compressFormat={compressFormat}
    //     quality={quality}
    //     rotation={rotation}
    //     responseUriFunc={responseUriFunc}
    //     outputType={outputType}
    // />
  };

  const fileChangeHandler = (event) => {
    if (event.target.files[0]) {
      resizeTheImage(event.target.files[0]);
    }
  };

  const onImageClicked = (event) => {
    console.log("In onImageClicked(): event.target.src is: ", event.target.src);

    if (event.target.src) {
      fetch(event.target.src)
        .then((resp) => resp.blob()) //
        .then((blob) => {
          let objectURL = URL.createObjectURL(blob);
          console.log("In onImageClicked(): objectURL is: ", objectURL);
          // setImageUri(objectURL);

          // A Blob is almost like a File, It's just missing the date and name properties. Add those
          // properties to a Blob to make it a File
          blob.lastModifiedDate = new Date();
          blob.name = imageName;

          // Then resize the file (blob is now file)
          resizeTheImage(blob);
        });
    }
  };

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { !appContext.signedIn &&
                        <Redirect to='/signIn' />
                    } */}

        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        {/* ImageCarousel */}

        <Grid container>
          <Grid item xs={12} sm={12}>
            <div className={classes.carouselContainer}>
              <ImageCarousel
                currLanguage={currentLanguage}
                imageCarouselRef={imageCarouselRef}
                imageItems={imageItems}
                lazyLoadFlag={lazyLoadFlag}
                onClick={onClick}
                onImageLoad={onImageLoad} // func
                onSlide={onSlide} // func
                onBeforeSlide={onBeforeSlide} // func
                onPause={onPause} // func
                onScreenChange={onScreenChange} // func
                onPlay={onPlay} // func
                infiniteFlag={infiniteFlag}
                showBulletsFlag={showBulletsFlag}
                showFullscreenButtonFlag={showFullscreenButtonFlag}
                showGalleryFullscreenButtonFlag={
                  showGalleryFullscreenButtonFlag
                }
                showPlayButtonFlag={showPlayButtonFlag}
                showGalleryPlayButtonFlag={showGalleryPlayButtonFlag}
                showThumbnailsFlag={showThumbnailsFlag}
                showIndexFlag={showIndexFlag}
                showNavFlag={showNavFlag}
                isRTL={enableRtl}
                thumbnailPosition={thumbnailPosition} // bottom
                slideDuration={slideDuration} // 450
                slideInterval={slideInterval} // 2000
                slideOnThumbnailOverFlag={slideOnThumbnailOverFlag}
              />

              <div className={classes.carouselAbsDiv}>
                <Typography variant="h2">{mediaDescription}</Typography>
              </div>
            </div>
          </Grid>
        </Grid>

        <Container
          component="div"
          className={classes.pageContainer}
          maxWidth={containerMaxWidth}
        >
          {progressFlag && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress
                className={classes.progress}
                color="secondary"
                size={15}
              />
            </div>
          )}

          {/* <Typography variant="h5" className={classes.textItem}>{"For Edit Image"}</Typography> */}

          <Grid container spacing={3}>
            {presignedURLs &&
              presignedURLs.length > 0 &&
              presignedURLs.map((item, index) => (
                <Grid key={index} item xs={12} sm={4}>
                  <img src={item.url} style={{ width: "100%" }} />
                  {/* <img src={item.url} style={{width: '100%'}} onClick={imageOnClick}/> */}
                </Grid>
              ))}
          </Grid>

          {/* <Typography variant="h5" className={classes.textItem}>{"For Resize Image"}</Typography>

                        <Grid container spacing={3}>
                            { presignedURLs && presignedURLs.length > 0 && 
                                presignedURLs.map((item, index) => 
                                    <Grid key={index} item xs={12} sm={4}>
                                        <img src={item.url} style={{width: '100%'}} onClick={onImageClicked}/>
                                    </Grid>
                                )
                            }
                        </Grid> */}

          {
            <Container component="div" maxWidth={containerMaxWidth}>
              <Grid container spacing={3}>
                <Grid item>
                  <Typography variant="h5" className={classes.textItem}>
                    {"For Edit Image"}
                  </Typography>
                  <img
                    src={smallImgUrl}
                    onClick={imageOnClick}
                    style={{ margin: "20px" }}
                  />
                </Grid>

                <Grid item>
                  <Typography variant="h5" className={classes.textItem}>
                    {"For Resize Image"}
                  </Typography>
                  <img
                    src={smallImgUrl}
                    onClick={onImageClicked}
                    style={{ margin: "20px" }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item>
                  {
                    <ImagePhotoEditor
                      imageEditorRef={imageEditorRef}
                      selectedMediaUrl={selectedMediaUrl}
                      currentLanguageCode={currentLanguageCode}
                      imageEditorConfig={imageEditorConfig}
                      imageEditorFlag={imageEditorFlag}
                      imageEditorShow={imageEditorShow}
                      imageEditorClose={imageEditorClose}
                      onBeforeComplete={onBeforeComplete}
                      onComplete={onComplete}
                    />
                  }
                </Grid>

                <LineSpacing value={24} />

                <Grid container spacing={3} className={classes.pageContainer}>
                  <Grid item>
                    For Edit Image
                    <input
                      type="file"
                      accept="image/*"
                      id="input-image-file"
                      onChange={handleFileChange}
                    ></input>
                  </Grid>

                  <Grid item>
                    For Image Resizer
                    <input
                      type="file"
                      accept="image/*"
                      id="input-image-file"
                      onChange={fileChangeHandler}
                    ></input>
                  </Grid>
                </Grid>

                <LineSpacing value={24} />

                <Grid container spacing={3} className={classes.pageContainer}>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={handleFlipImage}
                    >
                      {"Image Operation"}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={downloadEditedImage}
                    >
                      {"Download Image"}
                    </Button>
                  </Grid>
                </Grid>

                <LineSpacing value={24} />

                <Grid container spacing={3} className={classes.pageContainer}>
                  <Grid item>
                    Resized Image
                    <img src={imageUri} />
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          }

          <LineSpacing value={24} />

          {/* testURLs && testURLs.length > 0 && 
                            testURLs.map(item => <Typography variant="h5" className={classes.textItem}>{item.key}</Typography>)
                            
                        */}

          {/* <S3Image level="protected" imgKey={"onyii5119@gmail.com/chi-and-me-smiling.jpg"} />
                        <S3Image level="protected" imgKey={"onyii5119@gmail.com/chi-and-me-smiling.jpg"} picker />
                        <S3Album level="protected" path={"onyii5119@gmail.com/"} picker /> */}

          <Container
            component="div"
            className={classes.mainForm}
            maxWidth={containerMaxWidth}
          >
            <Grid container className={classes.mainGrid}>
              {/* Page Titles and Title Body */}
              <Grid container className={classes.titleGrid}>
                {menuNoSub2Title !== null && menuNoSub2Title !== "" && (
                  <Grid item xs={12} className={classes.title}>
                    <Typography variant="button" gutterBottom>
                      {menuNoSub2Title}
                    </Typography>
                  </Grid>
                )}

                {menuNoSub2SubTitle !== null && menuNoSub2SubTitle !== "" && (
                  <Grid item xs={12} className={classes.subTitle}>
                    <Typography
                      variant="subtitle2"
                      className={classes.textItem}
                    >
                      {menuNoSub2SubTitle}
                    </Typography>
                  </Grid>
                )}

                {menuNoSub2TC !== null && menuNoSub2TC !== "" && (
                  <Grid item xs={12} className={classes.titleContent}>
                    <Typography variant="caption" gutterBottom>
                      {menuNoSub2TC}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              {/* Page Body Content */}
              <Grid container className={classes.bodyGrid}>
                {pageColumnFormat === "leftBodyRight" && (
                  <Grid container className={classes.bodyGrid}>
                    <Grid item xs={3} className={classes.bodyLC}>
                      <Typography
                        variant="caption"
                        className={classes.textItem}
                      >
                        {menuNoSub2LC}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} className={classes.bodyContent}>
                      <Typography
                        variant="caption"
                        className={classes.textItem}
                      >
                        {menuNoSub2BC}
                      </Typography>
                      {/* PhotoGallery 
                                                <PhotoGallery 
                                                    currLanguage={currentLanguage} 
                                                    galleryItems={galleryItems} 
                                                    layoutDirection={"column"} 
                                                    selectAllPhotos={false}
                                                /> */}

                      {/* BnbGallery */}
                      <BnbGallery
                        currLanguage={currentLanguage}
                        galleryItems={bnbGalleryItems}
                        bnbGalleryOpened={bnbGalleryOpened}
                        toggleBnbGallery={toggleBnbGallery}
                        showThumbnails={showThumbnails}
                        cycleContinously={cycleContinously}
                        bnbBackgroundColor={bnbBackgroundColor}
                        phrases={phrases}
                      />
                    </Grid>

                    <Grid item xs={3} className={classes.bodyRC}>
                      <Typography
                        variant="subtitle2"
                        className={classes.textItem}
                      >
                        {menuNoSub2RC}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {pageColumnFormat === "bodyOnly" && (
                  <Grid item xs={12} className={classes.bodyContent}>
                    <Typography variant="caption" className={classes.textItem}>
                      {menuNoSub2BC}
                    </Typography>
                    {/* PhotoGallery 
                                            <PhotoGallery 
                                                currLanguage={currentLanguage} 
                                                galleryItems={galleryItems} 
                                                layoutDirection={"column"} 
                                                selectAllPhotos={false}
                                            /> */}

                    {/* BnbGallery
                                            <BnbGallery 
                                                currLanguage={currentLanguage} 
                                                galleryItems={bnbGalleryItems} 
                                                bnbGalleryOpened={bnbGalleryOpened}
                                                toggleBnbGallery={toggleBnbGallery}
                                                showThumbnails={showThumbnails}
                                                cycleContinously={cycleContinously}
                                                bnbBackgroundColor={bnbBackgroundColor}
                                                phrases={phrases}
                                            /> */}

                    {/* ModalImageViewer */}

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3}>
                        {smallImgUrl && (
                          <ModalImageViewer
                            currLanguage={currentLanguage}
                            // smallImgClassName={classes.bosmallImgClassNameyLC}
                            smallImgTitle={smallImgTitle}
                            smallImgUrl={smallImgUrl}
                            mediumImgUrl={mediumImgUrl}
                            largeImgUrl={largeImgUrl}
                            hideDownload={hideDownload}
                            hideZoom={hideZoom}
                            showRotate={showRotate}
                            imageBackgroundColor={imageBackgroundColor}
                            style={{ width: "100%" }}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        {smallImgUrl && (
                          <ModalImageViewer
                            currLanguage={currentLanguage}
                            // smallImgClassName={classes.bosmallImgClassNameyLC}
                            smallImgTitle={smallImgTitle}
                            smallImgUrl={smallImgUrl}
                            mediumImgUrl={mediumImgUrl}
                            largeImgUrl={largeImgUrl}
                            hideDownload={hideDownload}
                            hideZoom={hideZoom}
                            showRotate={showRotate}
                            imageBackgroundColor={imageBackgroundColor}
                            style={{ width: "100%" }}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        {smallImgUrl && (
                          <ModalImageViewer
                            currLanguage={currentLanguage}
                            // smallImgClassName={classes.bosmallImgClassNameyLC}
                            smallImgTitle={smallImgTitle}
                            smallImgUrl={smallImgUrl}
                            mediumImgUrl={mediumImgUrl}
                            largeImgUrl={largeImgUrl}
                            hideDownload={hideDownload}
                            hideZoom={hideZoom}
                            showRotate={showRotate}
                            imageBackgroundColor={imageBackgroundColor}
                            style={{ width: "100%" }}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        {smallImgUrl && (
                          <ModalImageViewer
                            currLanguage={currentLanguage}
                            // smallImgClassName={classes.bosmallImgClassNameyLC}
                            smallImgTitle={smallImgTitle}
                            smallImgUrl={smallImgUrl}
                            mediumImgUrl={mediumImgUrl}
                            largeImgUrl={largeImgUrl}
                            hideDownload={hideDownload}
                            hideZoom={hideZoom}
                            showRotate={showRotate}
                            imageBackgroundColor={imageBackgroundColor}
                            style={{ width: "100%" }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {pageColumnFormat === "leftAndBody" && (
                  <Grid container className={classes.bodyGrid}>
                    <Grid item xs={3} className={classes.bodyLC}>
                      <Typography
                        variant="caption"
                        className={classes.textItem}
                      >
                        {menuNoSub2LC}
                      </Typography>
                    </Grid>

                    <Grid item xs={9} className={classes.bodyContent}>
                      <Typography
                        variant="caption"
                        className={classes.textItem}
                      >
                        {menuNoSub2BC}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {pageColumnFormat === "bodyAndRight" && (
                  <Grid container className={classes.bodyGrid}>
                    <Grid item xs={9} className={classes.bodyContent}>
                      <Typography
                        variant="caption"
                        className={classes.textItem}
                      >
                        {menuNoSub2BC}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} className={classes.bodyRC}>
                      <Typography
                        variant="subtitle2"
                        className={classes.textItem}
                      >
                        {menuNoSub2RC}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {/* Page Body Footer */}
              <Grid container className={classes.footerGrid}>
                {menuNoSub2FCT !== null && menuNoSub2FCT !== "" && (
                  <Grid item xs={12} className={classes.footerTC}>
                    <Typography variant="caption" className={classes.textItem}>
                      {menuNoSub2FCT}
                    </Typography>
                  </Grid>
                )}

                {menuNoSub2FCM !== null && menuNoSub2FCM !== "" && (
                  <Grid item xs={12} className={classes.footerMiddleContent}>
                    <Typography
                      variant="subtitle2"
                      className={classes.textItem}
                    >
                      {menuNoSub2FCM}
                    </Typography>
                  </Grid>
                )}

                {menuNoSub2FCB !== null && menuNoSub2FCB !== "" && (
                  <Grid item xs={12} className={classes.footerBottomContent}>
                    <Typography variant="caption" className={classes.textItem}>
                      {menuNoSub2FCB}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Container>
        </Container>

        <footer className={classes.footer}>
          <PageFooter />
        </footer>
      </div>
    </div>
  );
}

export default MenuNoSub2;
