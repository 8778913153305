import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
import SaveIcon from "@material-ui/icons/Save";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from "@material-ui/core/Container";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import CheckIcon from '@material-ui/icons/Check';
// import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import InputAdornment from "@material-ui/core/InputAdornment";
import TranslateIcon from '@material-ui/icons/Translate';
import Paper from "@material-ui/core/Paper";
import ImportExportIcon from '@material-ui/icons/ImportExport';

// react-responsive
import { useMediaQuery } from 'react-responsive';

// moment
import moment from "moment";

// Lodash
import lodash from "lodash";

// currency-symbol-map
import getSymbolFromCurrency from 'currency-symbol-map';


// react-media
// import Media from "react-media";

// Validator
// import validator from 'validator';
// import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";
// import isMobilePhone from "validator/lib/isMobilePhone";

//---------------------
// For Primereact
//---------------------

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

// For Primereact
import { Dialog } from 'primereact/dialog';
import { OrderList } from 'primereact/orderlist';

// Froala
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Amplify
import { API } from "aws-amplify";
// import { AmplifyTheme   } from 'aws-amplify-react';

// AppContext
import AppContext from "../../config/AppContext";

import LineSpacing from "../../components/misc/LineSpacing";
// import FetchS3Object from "../../components/media/FetchS3Object";
import FroalaCommonEditor from "../../components/editor/FroalaCommonEditor";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import AlertDialog from "../../components/dialogs/AlertDialog";
import CommonMediaSelector from "../../components/dialogs/CommonMediaSelector";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import DomainUploadCapture from "../../components/upload/DomainUploadCapture";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GenerateUniqueName from "../../utils/GenerateUniqueName";
// import GenerateUlid from "../../utils/GenerateUlid";
// import FormatDateTime from "../../../../../utils/moment/FormatDateTime";
import FormatTimezoneDates from "../../utils/moment/FormatTimezoneDates";
import FilterArrayFromArray from "../../utils/filter/FilterArrayFromArray";
import FormatNumber from "../../utils/numberFormat/FormatNumber";
import FormatNumberWithOption from "../../utils/numberFormat/FormatNumberWithOption";
import FormatCurrency from "../../utils/numberFormat/FormatCurrency";
import EditorPixelTransform from "../../utils/transform/EditorPixelTransform";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

import WebPageConverter from "./WebPageConverter";

// Views
import ManagePageSectionsTv from "./views/ManagePageSectionsTv";
import ManagePageSectionsMv from "./views/ManagePageSectionsMv";

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    leftPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "left",
    },
    leftPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    divLeftPadding: {
        width: '100% !important',
        paddingLeft: theme.spacing(1),
        // paddingRight: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1rem" /* Footer height */,

        width: "100% !important",
        marginTop: "auto",
        // backgroundColor: '#fff',
        textAlign: "center",
    },
    paper: {
        minWidth: "300px",
        marginTop: theme.spacing(2),
        alignItems: "center",
        padding: "10px 30px 10px 30px !important",
        textAlign: "center",
    },
}));

let pageLabels = {
    managePageSectionsLabel: "Manage page sections",
    requiredFields: "* Required fields",

    languageLabel: "Language",
    languagePrompt: "Select language",
    languageErrMsg: "Language is required",

    domainLabel: "Domain",
    domainPrompt: "Select a domain",
    domainErrMsg: "Domain is required",

    webPageLabel: "Web page",
    webPagePrompt: "Select a web page",
    webPageErrMsg: "Web page is required",

    textContentLabel: "Text content",
    textContentPrompt: "Enter text content for this section",
    textContentErrMsg: "Text content is required",
    textContentMsg: "Please leave editor content blank",
    
    sectionTitleLabel: "Section title",
    sectionTitlePrompt: "Enter a title for the section",
    sectionTitleErrMsg: "Section title is required",
    sectionTitleMsg: "An identifying title for this page section.",

    mediaCaptionLabel: "Media caption",
    mediaCaptionPrompt: "Enter the media caption",
    mediaCaptionErrMsg: "Media caption is required",

    mediaDescLabel: "Media description",
    mediaDescPrompt: "Enter the media description",
    mediaDescErrMsg: "Media description is required",

    mediaUrlLabel: "Media URL",
    mediaUrlPrompt: "Select an media",
    mediaUrlErrMsg: "Media URL is required",

    mediaDescBeforeErrMsg: "End time must be greater or equal to start time",

    editorContentLabel: "Editor content",
    editorContentPrompt: "Enter content to display on page section",
    editorContentErrMsg: "Editor content is required",
    editorContentMsg: "Please leave text content blank",



    // selectTitlePrompt: "Select title",
    
    // mediaCaptionLabel: "Blog start time",
    // mediaCaptionPrompt: "Select date & time to publish the blog",
    // mediaCaptionErrMsg: "Blog start time is invalid",

    // uploadSectionTitleLabel: "Blog title",
    // uploadSectionTitlePrompt: "Please select blog title",
    // uploadSectionTitleErrMsg: "Blog title is required",

    // liveEventUrlLabel: "Live event URL",
    // liveEventUrlPrompt: "Please enter the live event URL",
    // liveEventUrlErrMsg: "Live event URL is invalid",

    convertPageSectionsLabel: "Convert page sections",
    // createPageSectionLabel: "Create page section",
    uploadFilesLabel: "Upload files",

    // yourTimeZoneMsg: "Your timeZone is",
    // timeZoneLabel: "Time zone",
    // cloneEventLabel: "Clone event",

    pageSectionLabel: "page section record",
    pageSectionsLabel: "page section records",
    // inProgressLabel: "In progress",
    // startLabel: "Start",
    // endLabel: "End",
    // notStartedLabel: "Not started",
    // dateCreatedLabel: "Date created",

    editLabel: "Edit",
    deleteLabel: "Delete",

    toLabel: "to",
    ofLabel: "of",
    recordsLabel: "records",
    noRecordsFound: "No records found",

    operationSuccessMsg: "Operation was successful.",
    operationFailureMsg: "Operation failed.",
    duplicateTransMsg: "Duplicate transaction",

    loadingText: "Loading ...",
    noOptionsText: "No options",
    clearText: "Clear",
    closeText: "Close",
    // showLabel: "Show",
    // hideLabel: "Hide",

    cancelLabel: "Cancel",
    clearLabel: "Clear",
    okLabel: "Ok",

    yesLabel: "Yes",
    deleteRequested: "Delete requested",
    areYouSureMsg: "Are you sure?",
    requestedToDeleteMsg: "You have requested to delete",
  
    createPageSectionLabel: "Create page section",
    updatePageSectionLabel: "Update page section",

    createMoreLabel: "Create more",
    createLabel: "Create",
    cloneRecordLabel: "Clone this record",
    closeLabel: "Close",
    noPageSectionRecordsFound: "No page section records found.", 
    
    descLabel: "Description",  
    // sectionTitleLabel: "Title",  
            
    gridPageName: "Page name",
    gridSectionName: "Section name",
    gridTitle: "Title",
    gridContent: "Content",
    lastUpdateLabel: "Last update",

    // gridMediaCaption: "Start time",
    // gridMediaUrl: "Banner URL",
    
    convertLabel: "Convert",

    sectionNameLabel: "Section name",
    sectionNamePrompt: "Enter a section name",
    sectionNameErrMsg: "Section name is required",

    sectionDescLabel: "Section description",
    sectionDescPrompt: "Enter a short description for this section",
    sectionDescErrMsg: "ection description is required",

    moreLabel: "- more -",
    uploadLabel: "Upload",

    organizeLabel: "Organize",
    organizePageSectionsLabel: "Organize page sections",
    availablePageSectionsLabel: "Page sections",
    saveLabel: "Save",
};

function ManagePageSections() {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);// appContext.direction
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);
    const [renderDomFlag, setRenderDomFlag] = useState(false);
    const [progressFlag, setProgressFlag] = useState(false);

    // For Global Errors
    const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");

    // For Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarsMsg, setSnackbarsMsg] = useState("");
    const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    //--------------------
    // For Froala Editor
    //--------------------
    const editorRef = useRef(null);
    const editorRef2 = useRef(null);
    const editorRef3 = useRef(null);
    // const [editorModelData, setEditorModelData] = useState(null);
    const [editorMaxChars, setEditorMaxChars] = useState(10000);// Max chars allowed in editor

    //--------------------
    // For Form Attributes
    //--------------------
    const [language, setLanguage] = useState(null);
    const [domain, setDomain] = useState(null);
    const [webPage, setWebPage] = useState(null);
    const [textContent, setTextContent] = useState("");
    const [sectionTitle, setSectionTitle] = useState("");
    const [sectionName, setSectionName] = useState("");
    const [sectionDesc, setSectionDesc] = useState("");
    const [editorContent, setEditorContent] = useState("");
    const [mediaCaption, setMediaCaption] = useState("");
    const [mediaDesc, setMediaDesc] = useState("");
    const [mediaUrl, setMediaUrl] = useState("");
    const [domainOptions, setDomainOptions] = useState([]);
    
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
         
    const [languageErrFlag, setLanguageErrFlag] = useState(false);
    const [domainErrFlag, setDomainErrFlag] = useState(false);
    const [textContentErrFlag, setTextContentErrFlag] = useState(false);
    const [sectionTitleErrFlag, setSectionTitleErrFlag] = useState(false);
    const [sectionNameErrFlag, setSectionNameErrFlag] = useState(false);
    const [sectionDescErrFlag, setSectionDescErrFlag] = useState(false);
    const [editorContentErrFlag, setEditorContentErrFlag] = useState(false);
    const [mediaCaptionErrFlag, setMediaCaptionErrFlag] = useState(false);
    const [webPageErrFlag, setWebPageErrFlag] = useState(false);
    const [mediaUrlErrFlag, setMediaUrlErrFlag] = useState(false);
    
    const [webPageOptions, setWebPageOptions] = useState([]);
    const [recordsBackup, setRecordsBackup] = useState([]);
    const [webPageRecords, setWebPageRecords] = useState([]);
    const [pageSectionRecords, setPageSectionRecords] = useState([]);

    const [submitButtonFlag, setSubmitButtonFlag] = useState(false);
    const [editModeFlag, setEditModeFlag] = useState(false);

    const [datePickerLanguage, setDatePickerLanguage] = useState(appContext.currentLanguage.languageCode);
    
    const [showListFlag, setShowListFlag] = useState(true);
    const [showDetailsFormFlag, setShowDetailsFormFlag] = useState(false);
    const [uploadFlag, setUploadFlag] = useState(false);
    const [convertFlag, setConvertFlag] = useState(false);
       
    const [pageSize, setPageSize] = useState(10000);// AWS put PageSize to a max 0f 100

    const [noRecordFoundFlag, setNoRecordFoundFlag] = useState(false);
    const [eventStartedFlag, setEventStartedFlag] = useState(false);
    const [startedEventId, setStartedEventId] = useState(null);
    
    const [textMaxLength, setTextMaxLength] = useState(256);

    // const [mediaCaptionOptions, setMediaCaptionOptions] = useState([]);

    // const [mediaDescOptions, setMediaDescOptions] = useState([]);
    const [mediaDescErrFlag, setMediaDescErrFlag] = useState(false);
    const [timeBeforeErrFlag, setTimeBeforeErrFlag] = useState(false);// true if mediaDesc isBefore mediaCaption
    
    //--------------------------
    // For sliceText and Dialog
    //--------------------------
    const [dialogData, setDialogData] = useState("");
    const [dialogHeader, setDialogHeader] = useState("");
    
    // const [terminologyNames, setTerminologyNames] = useState([
    //     "translate_custom_terminology.txt"
    // ]);
    

    //-------------------------
    // For Primereact 
    // DataTable Attributes 
    //-------------------------
    // const [ dataTableInfo, setDataTableInfo] = useState([]);
    // const [dataTableInfoFlag, setDataTableInfoFlag] = useState(false);
    
    // const [resizableColumnsFlag, setResizableColumnsFlag] = useState(true);
    // const [columnResizeMode, setColumnResizeMode] = useState("fit");
    // const [sortMode, setSortMode] = useState("multiple");
    // const [responsiveFlag, setResponsiveFlag] = useState(true);
    // const [reorderableColumnsFlag, setReorderableColumnsFlag] = useState(true);
    // const [paginatorFlag, setPaginatorFlag] = useState(true);
    const [paginatorTemplate, setPaginatorTemplate] = useState("FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown");
    // const [currentPageReportTemplate, setCurrentPageReportTemplate] = useState(null);
    const [visibleRowsCount, setVisibleRowsCount] = useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5,10,20,50,100]);

    // const [selectionMode, setSelectionMode] = useState("multiple");// single or multiple
    // const [selectedTableRow, setSelectedTableRow] = useState(null);// For single selection
    const [selectedTableRows, setSelectedTableRows] = useState([]);// For multiple selection
    // const [updatedTableRows, setUpdatedTableRows] = useState([]); // For updated (edited) table rows
    // const [deletedTableRows, setDeletedTableRows] = useState([]); // For deleted table rows
    const [tableDirtyFlag, setTableDirtyFlag] = useState(false); // To show DataTable cell was edited (modified)

    // const [deleteRowFlag, setDeleteRowFlag] = useState(false);

    //--------------------
    // For AlertDialog
    //--------------------
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogSubContent, setDialogSubContent] = useState(null);

    const [recordToDelete, setRecordToDelete] = useState(null);
    const [recordToEdit, setRecordToEdit] = useState(null);
    
    //-----------------------
    // For Primereact Dialog
    //-----------------------
    const [dlgVisibleFlag, setDlgVisibleFlag] = useState(false);

    //--------------------
    // For Languages
    //--------------------
    const [languages, setLanguages] = useState([]);

    //------------------
    // For OrderList
    //------------------
    const [filteredRecords, setFilteredRecords] = useState([]);
    const [orderListFlag, setOrderListFlag] = useState(false);
    const [dragdropFlag, setDragdropFlag] = useState(true); // Allow drag and drop
    
    // const {
    //     // editorId,
    // } = props;

    const theme = useTheme();
  
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    const { 
        managePageSectionsLabel, 
        requiredFields,

        languageLabel,
        languagePrompt,
        languageErrMsg,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,
            
        textContentLabel,
        textContentPrompt,
        textContentErrMsg,
        textContentMsg,
        
        sectionTitleLabel,
        sectionTitlePrompt,
        sectionTitleErrMsg,
        sectionTitleMsg,
        // selectTitlePrompt,

        mediaCaptionLabel,
        mediaCaptionPrompt,
        mediaCaptionErrMsg,

        mediaDescLabel,
        mediaDescPrompt,
        mediaDescErrMsg,
        mediaDescBeforeErrMsg,

        editorContentLabel,
        editorContentPrompt,
        editorContentErrMsg,
        editorContentMsg,
    
        // mediaCaptionLabel,
        // mediaCaptionPrompt,
        // mediaCaptionErrMsg,

        webPageLabel,
        webPagePrompt,
        webPageErrMsg,

        // uploadSectionTitleLabel,
        // uploadSectionTitlePrompt,
        // uploadSectionTitleErrMsg,

        // liveEventUrlLabel,
        // liveEventUrlPrompt,
        // liveEventUrlErrMsg,

        convertPageSectionsLabel,
        // createPageSectionLabel,
        uploadFilesLabel,

        pageSectionLabel,
        pageSectionsLabel,
        // inProgressLabel,
        // startLabel,
        // endLabel,
        // notStartedLabel,
    
        // dateCreatedLabel,
    
        mediaUrlLabel,
        mediaUrlPrompt,
        mediaUrlErrMsg,

        // yourTimeZoneMsg,
        // timeZoneLabel,
        // cloneEventLabel,
    
        toLabel,
        ofLabel,
        recordsLabel,
        noRecordsFound,

        gridPageName,
        editLabel,
        deleteLabel,

        operationSuccessMsg,
        operationFailureMsg,
        duplicateTransMsg,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,
        // showLabel,
        // hideLabel,

        cancelLabel,
        clearLabel,
        okLabel,

        yesLabel,
        deleteRequested,
        areYouSureMsg,
        requestedToDeleteMsg,
    
        uploadLabel,
        
        createPageSectionLabel,
        updatePageSectionLabel,

        createMoreLabel,
        createLabel,
        cloneRecordLabel,
        closeLabel,
        noPageSectionRecordsFound, 

        descLabel,
        // sectionTitleLabel,
        // createPageSectionLabel,

        gridTitle,
        gridSectionName,
        gridContent,
        lastUpdateLabel,
        // gridMediaCaption,
        // gridMediaUrl,
    
        convertLabel,

        sectionNameLabel,
        sectionNamePrompt,
        sectionNameErrMsg,

        sectionDescLabel,
        sectionDescPrompt,
        sectionDescErrMsg,

        moreLabel,

        organizeLabel,
        organizePageSectionsLabel,
        availablePageSectionsLabel,
        saveLabel,
    } = pageLabelsRes;

    async function fetchDbResources(cacheItemKey) {
        backdropProgress(true);

        let keys = [];
        let objKeys = Object.keys(pageLabels);
        // console.log("In ManagePageSections(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
        if (objKeys !== null && objKeys.length > 0) {
            objKeys.map((key) => {
                let partitionKey = appContext.currentLanguage.languageCode;
                partitionKey += ".";
                partitionKey += GenerateModulusHash(key, 200);

                let itemKey = {
                ResourcePK: partitionKey, // e.g. 'en.91'
                ResourceSK: key, // e.g. 'firstName'
                };
                keys.push(itemKey);
            });
        }
        // console.log("In ManagePageSections(): fetchDbResources(): keys.length is: ", keys.length);
        // console.log("In ManagePageSections(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

        let arrayData = [];

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/batchGet",
            payload: {
                keys: keys,
                projectionExpression: "ResourceCode,ResourceDesc",
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
            response.data.Responses.resource.map((item, index) => {
            let record = {
                ResourceCode: item.ResourceCode,
                ResourceDesc: item.ResourceDesc,
            };

            arrayData.push(record);
            });
                    
            if (arrayData && arrayData.length > 0) {
                arrayData.map((item) => {
                    if (item.ResourceCode === "managePageSectionsLabel") pageLabels.managePageSectionsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "requiredFields") pageLabels.requiredFields = item.ResourceDesc;
                    
                    if (item.ResourceCode === "languageLabel") pageLabels.languageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "languagePrompt") pageLabels.languagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "languageErrMsg") pageLabels.languageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "domainLabel") pageLabels.domainLabel = item.ResourceDesc;
                    if (item.ResourceCode === "domainPrompt") pageLabels.domainPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "domainErrMsg") pageLabels.domainErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "textContentLabel") pageLabels.textContentLabel = item.ResourceDesc;
                    if (item.ResourceCode === "textContentPrompt") pageLabels.textContentPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "textContentErrMsg") pageLabels.textContentErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "textContentMsg") pageLabels.textContentMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "sectionTitleLabel") pageLabels.sectionTitleLabel = item.ResourceDesc;
                    if (item.ResourceCode === "sectionTitlePrompt") pageLabels.sectionTitlePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "sectionTitleErrMsg") pageLabels.sectionTitleErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "sectionTitleMsg") pageLabels.sectionTitleMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "selectTitlePrompt") pageLabels.selectTitlePrompt = item.ResourceDesc;
                    
                    if (item.ResourceCode === "mediaCaptionLabel") pageLabels.mediaCaptionLabel = item.ResourceDesc;
                    if (item.ResourceCode === "mediaCaptionPrompt") pageLabels.mediaCaptionPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "mediaCaptionErrMsg") pageLabels.mediaCaptionErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "mediaDescLabel") pageLabels.mediaDescLabel = item.ResourceDesc;
                    if (item.ResourceCode === "mediaDescPrompt") pageLabels.mediaDescPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "mediaDescErrMsg") pageLabels.mediaDescErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "mediaDescBeforeErrMsg") pageLabels.mediaDescBeforeErrMsg = item.ResourceDesc;
                                                    
                    if (item.ResourceCode === "editorContentLabel") pageLabels.editorContentLabel = item.ResourceDesc;
                    if (item.ResourceCode === "editorContentPrompt") pageLabels.editorContentPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "editorContentErrMsg") pageLabels.editorContentErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "editorContentMsg") pageLabels.editorContentMsg = item.ResourceDesc;
                                
                    // if (item.ResourceCode === "mediaCaptionLabel") pageLabels.mediaCaptionLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "mediaCaptionPrompt") pageLabels.mediaCaptionPrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "mediaCaptionErrMsg") pageLabels.mediaCaptionErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "webPageLabel") pageLabels.webPageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "webPagePrompt") pageLabels.webPagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "webPageErrMsg") pageLabels.webPageErrMsg = item.ResourceDesc;
            
                    // if (item.ResourceCode === "uploadSectionTitleLabel") pageLabels.uploadSectionTitleLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "uploadSectionTitlePrompt") pageLabels.uploadSectionTitlePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "uploadSectionTitleErrMsg") pageLabels.uploadSectionTitleErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "convertPageSectionsLabel") pageLabels.convertPageSectionsLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "createPageSectionLabel") pageLabels.createPageSectionLabel = item.ResourceDesc;
                    if (item.ResourceCode === "uploadFilesLabel") pageLabels.uploadFilesLabel = item.ResourceDesc;
                                    
                    // if (item.ResourceCode === "yourTimeZoneMsg") pageLabels.yourTimeZoneMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "timeZoneLabel") pageLabels.timeZoneLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "cloneEventLabel") pageLabels.cloneEventLabel = item.ResourceDesc;
                                
                    if (item.ResourceCode === "pageSectionsLabel") pageLabels.pageSectionsLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "inProgressLabel") pageLabels.inProgressLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "startLabel") pageLabels.startLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "endLabel") pageLabels.endLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "notStartedLabel") pageLabels.notStartedLabel = item.ResourceDesc;
                      
                    // if (item.ResourceCode === "dateCreatedLabel") pageLabels.dateCreatedLabel = item.ResourceDesc;
                    
                    if (item.ResourceCode === "mediaUrlLabel") pageLabels.mediaUrlLabel = item.ResourceDesc;
                    if (item.ResourceCode === "mediaUrlPrompt") pageLabels.mediaUrlPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "mediaUrlErrMsg") pageLabels.mediaUrlErrMsg = item.ResourceDesc; 

                    if (item.ResourceCode === "toLabel") pageLabels.toLabel = item.ResourceDesc;
                    if (item.ResourceCode === "ofLabel") pageLabels.ofLabel = item.ResourceDesc;
                    if (item.ResourceCode === "recordsLabel") pageLabels.recordsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noRecordsFound") pageLabels.noRecordsFound = item.ResourceDesc;

                    if (item.ResourceCode === "createLabel") pageLabels.createLabel = item.ResourceDesc;
                    if (item.ResourceCode === "gridPageName") pageLabels.gridPageName = item.ResourceDesc;
                    if (item.ResourceCode === "editLabel") pageLabels.editLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteLabel") pageLabels.deleteLabel = item.ResourceDesc;

                    if (item.ResourceCode === "operationSuccessMsg") pageLabels.operationSuccessMsg = item.ResourceDesc;
                    if (item.ResourceCode === "operationFailureMsg") pageLabels.operationFailureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "duplicateTransMsg") pageLabels.duplicateTransMsg = item.ResourceDesc;
                                                                        
                    if (item.ResourceCode === "loadingText") pageLabels.loadingText = item.ResourceDesc;
                    if (item.ResourceCode === "noOptionsText") pageLabels.noOptionsText = item.ResourceDesc;
                    if (item.ResourceCode === "clearText") pageLabels.clearText = item.ResourceDesc;
                    if (item.ResourceCode === "closeText") pageLabels.closeText = item.ResourceDesc;
                    // if (item.ResourceCode === "showLabel") pageLabels.showLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "hideLabel") pageLabels.hideLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "cancelLabel") pageLabels.cancelLabel = item.ResourceDesc;
                    if (item.ResourceCode === "clearLabel") pageLabels.clearLabel = item.ResourceDesc;
                    if (item.ResourceCode === "okLabel") pageLabels.okLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "yesLabel") pageLabels.yesLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteRequested") pageLabels.deleteRequested = item.ResourceDesc;
                    if (item.ResourceCode === "areYouSureMsg") pageLabels.areYouSureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "requestedToDeleteMsg") pageLabels.requestedToDeleteMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "uploadLabel") pageLabels.uploadLabel = item.ResourceDesc;
          
                    if (item.ResourceCode === "createPageSectionLabel") pageLabels.createPageSectionLabel = item.ResourceDesc;
                    if (item.ResourceCode === "updatePageSectionLabel") pageLabels.updatePageSectionLabel = item.ResourceDesc;
                              
                    if (item.ResourceCode === "pageSectionLabel") pageLabels.pageSectionLabel = item.ResourceDesc;
                    if (item.ResourceCode === "createMoreLabel") pageLabels.createMoreLabel = item.ResourceDesc;
                    if (item.ResourceCode === "cloneRecordLabel") pageLabels.cloneRecordLabel = item.ResourceDesc;
                    if (item.ResourceCode === "closeLabel") pageLabels.closeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noPageSectionRecordsFound") pageLabels.noPageSectionRecordsFound = item.ResourceDesc;

                    if (item.ResourceCode === "descLabel") pageLabels.descLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "createPageSectionLabel") pageLabels.createPageSectionLabel = item.ResourceDesc;
                         
                    if (item.ResourceCode === "gridTitle") pageLabels.gridTitle = item.ResourceDesc;
                    if (item.ResourceCode === "gridContent") pageLabels.gridContent = item.ResourceDesc;
                    if (item.ResourceCode === "lastUpdateLabel") pageLabels.lastUpdateLabel = item.ResourceDesc;
                                              
                    // if (item.ResourceCode === "gridMediaCaption") pageLabels.gridMediaCaption = item.ResourceDesc;
                    // if (item.ResourceCode === "gridMediaUrl") pageLabels.gridMediaUrl = item.ResourceDesc;
                    if (item.ResourceCode === "gridSectionName") pageLabels.gridSectionName = item.ResourceDesc;
                                              
                    if (item.ResourceCode === "convertLabel") pageLabels.convertLabel = item.ResourceDesc;
                    
                    if (item.ResourceCode === "sectionNameLabel") pageLabels.sectionNameLabel = item.ResourceDesc;
                    if (item.ResourceCode === "sectionNamePrompt") pageLabels.sectionNamePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "sectionNameErrMsg") pageLabels.sectionNameErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "sectionDescLabel") pageLabels.sectionDescLabel = item.ResourceDesc;
                    if (item.ResourceCode === "sectionDescPrompt") pageLabels.sectionDescPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "sectionDescErrMsg") pageLabels.sectionDescErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "moreLabel") pageLabels.moreLabel = item.ResourceDesc;
                    
                    if (item.ResourceCode === "organizeLabel") pageLabels.organizeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "organizePageSectionsLabel") pageLabels.organizePageSectionsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "availablePageSectionsLabel") pageLabels.availablePageSectionsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "saveLabel") pageLabels.saveLabel = item.ResourceDesc;
                });
                // Add it to cache
                SetCacheItem(cacheItemKey, pageLabels);
            }

            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setRenderDomFlag(true);
        }).catch((err) => {
            console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setRenderDomFlag(true);
        });
    }

    async function fetchWebPageRecords(selectedDomain) {
        // console.log('In fetchWebPageRecords(): selectedDomain is: ', selectedDomain);
        // console.log('In fetchWebPageRecords(): language is: ', language);
        if (language && selectedDomain) {
            backdropProgress(true);
            setPageSectionRecords([]);
            // setNoRecordFoundFlag(false);
            // setRecordsBackup([]);
            setWebPage(null);

            let params = {
                apiName: "PagesAPI",
                path: "/dynamodb/pages/query",
                payload: {
                    keyConditionExpression:
                        // "#pagePK = :pagePK",
                        "#pagePK = :pagePK AND begins_with(#pageSK, :pageSK)",
                    expressionAttributeNames: {
                        "#pagePK": "PagePK",
                        "#pageSK": "PageSK",
                    },
                    expressionAttributeValues: {
                        ":pagePK": selectedDomain.value + "#" + language.languageCode + "#page",
                        ":pageSK": selectedDomain.value + "#" + language.languageCode + "#page",
                    },
                    // indexName: "PriceDomain-LangAuthorUserName-index"
                        // "keyConditionExpression": "#pagePK = :pagePK",
                        // "expressionAttributeNames": {"#pagePK": "PagePK"},
                        // "expressionAttributeValues": {":pagePK": "languages"}
                },
            };

            // if (!lodash.isEmpty(listNextToken)) {
            //     params.payload.nextToken = listNextToken;
            // }
    
            // if (!lodash.isEmpty(pageSize)) {
            //     params.payload.pageSize = pageSize;
            // }

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchWebPageRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchWebPageRecords(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchWebPageRecords(): response.data.NextToken is: ', response.data.NextToken);
                // console.log('In fetchWebPageRecords(): response.data.Items is: ', response.data.Items);

                let records = [];

                response.data.Items.map((item) => {
                    let record = {
                        value: item.PageId,
                        text: item.PageTitle,
                    };
                    records.push(record);
                });

                records.sort((a, b) => (a.text > b.text ? 1 : -1));
                setWebPageOptions(records);

                backdropProgress(false);
            }).catch((err) => {
                console.error("In fetchWebPageRecords(): err is: ", JSON.stringify(err, null, 2));
                setWebPageRecords([]);
                setPageSectionRecords([]);
                setNoRecordFoundFlag(true);
                backdropProgress(false);
            });
        }
    }
    
    let listNextToken = null;

    async function fetchPageSectionRecords(selectedWebPage) {
        // console.log('In fetchPageSectionRecords(): language is: ', language);
        // console.log('In fetchPageSectionRecords(): domain is: ', domain);
        // console.log('In fetchPageSectionRecords(): selectedWebPage is: ', selectedWebPage);

        if (!(language && domain && selectedWebPage)) {
            setNoRecordFoundFlag(true);
            return;
        }

        backdropProgress(true);
        setNoRecordFoundFlag(false);
        setRecordsBackup([]);
        let textContentFlag = false;

        let params = {
            apiName: "PagesAPI",
            path: "/dynamodb/pages/query",
            payload: {
                keyConditionExpression:
                    // "#pagePK = :pagePK",
                    "#pagePK = :pagePK AND begins_with(#pageSK, :pageSK)",
                expressionAttributeNames: {
                    "#pagePK": "PagePK",
                    "#pageSK": "PageSK",
                },
                expressionAttributeValues: {
                    ":pagePK": domain.value + "#" + language.languageCode + "#page#section",
                    ":pageSK": domain.value + "#" + language.languageCode + "#page#section#" + selectedWebPage.value,
                },
                // indexName: "PriceDomain-LangAuthorUserName-index"
                    // "keyConditionExpression": "#pagePK = :pagePK",
                    // "expressionAttributeNames": {"#pagePK": "PagePK"},
                    // "expressionAttributeValues": {":pagePK": "languages"}
            },
        };

        if (!lodash.isEmpty(listNextToken)) {
            params.payload.nextToken = listNextToken;
        }

        if (!lodash.isEmpty(pageSize)) {
            params.payload.pageSize = pageSize;
        }

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchPageSectionRecords(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In fetchPageSectionRecords(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchPageSectionRecords(): response.data.NextToken is: ', response.data.NextToken);
            // console.log('In fetchPageSectionRecords(): response.data.Items is: ', response.data.Items);

            let records = [];

            listNextToken = null;

            if (response && !lodash.isEmpty(response.data.NextToken)) {
                listNextToken = response.data.NextToken;
                records = webPageRecords;
            }
        
            let items = response.data.Items;

            if (items && items.length > 0) {
                setPageSectionRecords([]);

                items.map((record) => {
                    // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                    // console.log('In fetchPageSectionRecords(): formatedDateTime is: ', formatedDateTime);
                    // console.log('In fetchPageSectionRecords(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
                    let formattedDateTime = "";
                    if (record.UpdatedAt) {
                        formattedDateTime = FormatTimezoneDates(record.UpdatedAt, record.LanguageCode, timeZone, "shortDateAndTime");
                    }

                    let editorTransform = record.EditorContent;
                    if (record.EditorContent) {
                        if (isTabletView || isMobileView) {
                            editorTransform = EditorPixelTransform(record.EditorContent, (isTabletView ? "tabletView" : "mobileView"));
                        }
                    }
                    // console.log('In fetchPageSectionRecords(): editorTransform is: ', editorTransform);
                    textContentFlag = false;
                    if (!lodash.isEmpty(record.TextContent)) {
                        textContentFlag = true;// true = TextContent; false = EditorContent
                    }

                    let item = {
                        PagePK: record.PagePK,
                        PageSK: record.PageSK,

                        LanguageObject: record.LanguageObject,
                        LanguageCode: record.LanguageCode,

                        Domain: record.Domain,
                        "SectionDomain": lodash.isEmpty(record.Domain) ? "" : record.Domain.value,

                        "WebPage": record.WebPage,
                        "WebPageText": lodash.isEmpty(record.WebPage) ? "" : record.WebPage.text,

                        "PageId": lodash.isEmpty(record.PageId) ? "" : record.PageId,
                        "SectionId": lodash.isEmpty(record.SectionId) ? "" : record.SectionId,

                        "SectionTitle": lodash.isEmpty(record.SectionTitle) ? "" : record.SectionTitle,
                        "SectionName": lodash.isEmpty(record.SectionName) ? "" : record.SectionName,
                        "SectionDesc": lodash.isEmpty(record.SectionDesc) ? "" : record.SectionDesc,
                        
                        "TextContent": lodash.isEmpty(record.TextContent) ? "" : record.TextContent,
                        "EditorContent": lodash.isEmpty(editorTransform) ? "" : editorTransform,
                        "DisplayContent": lodash.isEmpty(record.TextContent) ? ((lodash.isEmpty(record.EditorContent) ? "" : editorTransform)) : record.TextContent,

                        "MediaCaption": lodash.isEmpty(record.MediaCaption) ? "" : record.MediaCaption,
                        "MediaDesc": lodash.isEmpty(record.MediaDesc) ? "" : record.MediaDesc,
                        "MediaUrl": lodash.isEmpty(record.MediaUrl) ? "" : record.MediaUrl,

                        "SectionSeqNumber": record.SectionSeqNumber,
                        "TextContentFlag": textContentFlag,

                        "FormattedDateTime": formattedDateTime,
                        "UpdatedAt": record.UpdatedAt,
                    };
                    records.push(item);
                });
            } else {
                setNoRecordFoundFlag(true);
            }

            // console.log('In fetchPageSectionRecords(): listNextToken is: ', listNextToken);
            if (!lodash.isEmpty(listNextToken)) {
                await fetchPageSectionRecords(selectedWebPage);
            }
            
            setTimeout(() => {
                records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                setPageSectionRecords(records);
                setRecordsBackup(records);
            }, 100);

            backdropProgress(false);
        }).catch((err) => {
            console.error("In fetchPageSectionRecords(): err is: ", JSON.stringify(err, null, 2));
            setPageSectionRecords([]);
            setNoRecordFoundFlag(true);
            backdropProgress(false);
        });
    }
    
    let sectionNextToken = null;

    async function fetchPageSectionsForPage(selectedWebPage) {
        // console.log('In fetchPageSectionsForPage(): language is: ', language);
        // console.log('In fetchPageSectionsForPage(): domain is: ', domain);
        // console.log('In fetchPageSectionsForPage(): selectedWebPage is: ', selectedWebPage);

        if (!(language && domain && selectedWebPage)) {
            setNoRecordFoundFlag(true);
            return;
        }

        backdropProgress(true);
        setNoRecordFoundFlag(false);
        setSubmitButtonFlag(false);
        setFilteredRecords([]);
        let textContentFlag = false;

        let params = {
            apiName: "PagesAPI",
            path: "/dynamodb/pages/query",
            payload: {
                keyConditionExpression:
                    // "#pagePK = :pagePK",
                    "#pagePK = :pagePK AND begins_with(#pageSK, :pageSK)",
                expressionAttributeNames: {
                    "#pagePK": "PagePK",
                    "#pageSK": "PageSK",
                },
                expressionAttributeValues: {
                    ":pagePK": domain.value + "#" + language.languageCode + "#page#section",
                    ":pageSK": domain.value + "#" + language.languageCode + "#page#section#" + selectedWebPage.value,
                },
                // indexName: "PriceDomain-LangAuthorUserName-index"
                    // "keyConditionExpression": "#pagePK = :pagePK",
                    // "expressionAttributeNames": {"#pagePK": "PagePK"},
                    // "expressionAttributeValues": {":pagePK": "languages"}
            },
        };

        if (!lodash.isEmpty(sectionNextToken)) {
            params.payload.nextToken = sectionNextToken;
        }

        if (!lodash.isEmpty(pageSize)) {
            params.payload.pageSize = pageSize;
        }

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchPageSectionsForPage(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In fetchPageSectionsForPage(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchPageSectionsForPage(): response.data.NextToken is: ', response.data.NextToken);
            // console.log('In fetchPageSectionsForPage(): response.data.Items is: ', response.data.Items);

            let records = [];

            sectionNextToken = null;

            if (response && !lodash.isEmpty(response.data.NextToken)) {
                sectionNextToken = response.data.NextToken;
                records = webPageRecords;
            }
        
            let items = response.data.Items;

            if (items && items.length > 0) {
                // setPageSectionRecords([]);
                setFilteredRecords([]);

                items.map((record) => {
                    // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                    // console.log('In fetchPageSectionsForPage(): formatedDateTime is: ', formatedDateTime);
                    // console.log('In fetchPageSectionsForPage(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
                    let formattedDateTime = "";
                    if (record.UpdatedAt) {
                        formattedDateTime = FormatTimezoneDates(record.UpdatedAt, record.LanguageCode, timeZone, "shortDateAndTime");
                    }

                    let editorTransform = record.EditorContent;
                    if (record.EditorContent) {
                        if (isTabletView || isMobileView) {
                            editorTransform = EditorPixelTransform(record.EditorContent, (isTabletView ? "tabletView" : "mobileView"));
                        }
                    }
                    // console.log('In fetchPageSectionsForPage(): editorTransform is: ', editorTransform);
                    textContentFlag = false;
                    if (!lodash.isEmpty(record.TextContent)) {
                        textContentFlag = true;// true = TextContent; false = EditorContent
                    }

                    let item = {
                        PagePK: record.PagePK,
                        PageSK: record.PageSK,

                        LanguageObject: record.LanguageObject,
                        LanguageCode: record.LanguageCode,

                        Domain: record.Domain,
                        "SectionDomain": lodash.isEmpty(record.Domain) ? "" : record.Domain.value,

                        "WebPage": record.WebPage,
                        "WebPageText": lodash.isEmpty(record.WebPage) ? "" : record.WebPage.text,

                        "PageId": lodash.isEmpty(record.PageId) ? "" : record.PageId,
                        "SectionId": lodash.isEmpty(record.SectionId) ? "" : record.SectionId,

                        "SectionTitle": lodash.isEmpty(record.SectionTitle) ? "" : record.SectionTitle,
                        "SectionName": lodash.isEmpty(record.SectionName) ? "" : record.SectionName,
                        "SectionDesc": lodash.isEmpty(record.SectionDesc) ? "" : record.SectionDesc,
                        
                        "TextContent": lodash.isEmpty(record.TextContent) ? "" : record.TextContent,
                        "EditorContent": lodash.isEmpty(editorTransform) ? "" : editorTransform,
                        "DisplayContent": lodash.isEmpty(record.TextContent) ? ((lodash.isEmpty(record.EditorContent) ? "" : editorTransform)) : record.TextContent,

                        "MediaCaption": lodash.isEmpty(record.MediaCaption) ? "" : record.MediaCaption,
                        "MediaDesc": lodash.isEmpty(record.MediaDesc) ? "" : record.MediaDesc,
                        "MediaUrl": lodash.isEmpty(record.MediaUrl) ? "" : record.MediaUrl,

                        "SectionSeqNumber": record.SectionSeqNumber,
                        "TextContentFlag": textContentFlag,

                        "FormattedDateTime": formattedDateTime,
                        "UpdatedAt": record.UpdatedAt,
                    };
                    records.push(item);
                });
            } else {
                setNoRecordFoundFlag(true);
            }

            // console.log('In fetchPageSectionsForPage(): sectionNextToken is: ', sectionNextToken);
            if (!lodash.isEmpty(sectionNextToken)) {
                await fetchPageSectionsForPage(selectedWebPage);
            }
            
            setTimeout(() => {
                records.sort((a, b) => (a.SectionSeqNumber > b.SectionSeqNumber ? 1 : -1));// asc
                // setPageSectionRecords(records);
                setFilteredRecords(records);

                if (records && records.length > 0) {
                    setSubmitButtonFlag(true);
                }
            }, 100);

            backdropProgress(false);
        }).catch((err) => {
            console.error("In fetchPageSectionsForPage(): err is: ", JSON.stringify(err, null, 2));
            // setPageSectionRecords([]);
            setFilteredRecords([]);
            setNoRecordFoundFlag(true);
            backdropProgress(false);
        });
    }
    
    async function getPageSectionRecord(key) {
        // console.log('In getPageSectionRecord(): key.PagePK is: ', key.PagePK);
        // console.log('In getPageSectionRecord(): key.PageSK is: ', key.PageSK);
        
        backdropProgress(true);
    
        let params = {
            apiName: "PagesAPI",
            path: "/dynamodb/pages/get",
            payload: {
                key: {
                    PagePK: key.PagePK,
                    PageSK: key.PageSK,
                },
            },
        };
  
        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
  
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In getPageSectionRecord(): initObj is: ', JSON.stringify(initObj, null, 2));
  
        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In getPageSectionRecord(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In getPageSectionRecord(): response.data.Item is: ', response.data.Item);

            setLanguage(null);// To trigger CommonMediaSelector
        
            let item = response.data.Item;

            appContext.currentPK = item.PagePK;
            appContext.currentSK = item.PageSK;
        
            setLanguage(item.LanguageObject);

            setDomain(item.Domain ? item.Domain : null);

            setWebPage(item.WebPage ? item.WebPage : null);

            setSectionTitle(item.SectionTitle ? item.SectionTitle : "");
            setSectionName(item.SectionName ? item.SectionName : "");
            setSectionDesc(item.SectionDesc ? item.SectionDesc : "");
            
            setTextContent(item.TextContent ? item.TextContent : "");
            setEditorContent(item.EditorContent ? item.EditorContent : "");

            setMediaCaption(item.MediaCaption ? item.MediaCaption : "");
            setMediaDesc(item.MediaDesc ? item.MediaDesc : "");
            setMediaUrl(item.MediaUrl ? item.MediaUrl : "");

            setShowListFlag(false);
            setOrderListFlag(false);
            setUploadFlag(false);
            setShowDetailsFormFlag(true);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);

            backdropProgress(false);

            Promise.all([
                // fetchReferenceData("web-pages", item.LanguageObject)
            ]).then(async (results) => {
                // console.log("In getPageSectionRecord(): results is: ", results);
            }).catch(error => {
                console.error("In getPageSectionRecord(): Promise error is: ", error);
            });
        }).catch((err) => {
            console.error("In getPageSectionRecord(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        // console.log("In editRecordAction(): webPageRecords is: ", webPageRecords);
        
        setLanguage(null);
        setDomain(null);

        setShowDetailsFormFlag(true);
        
        setRecordToEdit(item);
        await getPageSectionRecord({PagePK: item.PagePK, PageSK: item.PageSK,});
    }

    async function fetchLanguages() {
        // console.log("In fetchLanguages()");
        // setFilterDisabledFlag(true);

        //-------------------------------------------------------
        // Make sure languages is not in cache before proceeding
        //-------------------------------------------------------

        let cachedLanguages = GetCacheItem("languages");
        // console.log('In fetchLanguages(): cachedLanguages is: ', JSON.stringify(cachedLanguages, null, 2));
        cachedLanguages = null;
        if (cachedLanguages && cachedLanguages !== null && cachedLanguages.length > 0) {
            // console.log('In fetchLanguages(): cachedLanguages length is: ', cachedLanguages.length);
            setLanguages(cachedLanguages);
            return true;
        }

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/query",
            payload: {
                keyConditionExpression: "#resourcePK = :resourcePK",
                expressionAttributeNames: { "#resourcePK": "ResourcePK" },
                expressionAttributeValues: { ":resourcePK": "languages" },
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log("In fetchLanguages(): response length is: ", response.data.Items.length);
            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));

            // response.data.Items.sort((a, b) =>
            //     a.LanguageName > b.LanguageName ? 1 : -1
            // );

            let records = [];
            let items = response.data.Items;

            items.map(item => {
                let record ={
                    languageCode: item.ResourceCode,
                    languageName: item.LanguageName
                };
                records.push(record);
            });

            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
            setLanguages(records);

            // Add it to cache
            SetCacheItem("languages", response.data.Items);
            backdropProgress(false);
        }).catch((err) => {
            console.error("In fetchLanguages(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    };

    let  refDataSelectOptions = [];
    
    async function fetchReferenceData(refType, lang) {
        return new Promise((resolve, reject) => {
            // console.log('In ManagePageSections  fetchReferenceData(): refType is: ', refType);
            // console.log('In ManagePageSections  fetchReferenceData(): lang is: ', lang);
            backdropProgress(true);

            refDataSelectOptions = [];

            let apiName = null;
            let path = null;
            let keyConditionExpression = null;
            let expressionAttributeNames = {};
            let expressionAttributeValues = {};
            
            switch(refType) {
                case "web-pages":
                    setWebPage(null);
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "web-pages",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                case "supported-domains":
                    setDomain(null);
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "supported-domains",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                // case "pricing-units":
                //     apiName = "ResourceAPI";
                //     path = "/dynamodb/resources/query";
                //     keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                //     expressionAttributeNames = {
                //         "#resourcePK": "ResourcePK",
                //         "#resourceSK": "ResourceSK",
                //     };
                //     expressionAttributeValues = {
                //         ":resourcePK": "pricing-units",
                //         ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                //     };
                //     break;
                // case "stripe-curr":
                //     apiName = "ResourceAPI";
                //     path = "/dynamodb/resources/query";
                //     keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                //     expressionAttributeNames = {
                //         "#resourcePK": "ResourcePK",
                //         "#resourceSK": "ResourceSK",
                //     };
                //     expressionAttributeValues = {
                //         ":resourcePK": "stripe-curr",
                //         ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                //     };
                //     break;
                default:
                    break;
            }

            setProgressFlag(true);
        
            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    keyConditionExpression: keyConditionExpression,
                    expressionAttributeNames: expressionAttributeNames,
                    expressionAttributeValues: expressionAttributeValues,
                    // "keyConditionExpression": "#resourcePK = :resourcePK",
                    // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                    // "expressionAttributeValues": {":resourcePK": "languages"}
                },
            };
        
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
        
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In  fetchReferenceData(): initObj is: ', JSON.stringify(initObj, null, 2));
        
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In  fetchReferenceData(): response.length is: ', response.data.Items.length);
                // console.log('In  fetchReferenceData(): response is: ', JSON.stringify(response.data.Items, null, 2));
                response.data.Items.map((item) => {
                    let record = {
                        value: item.ResourceCode,
                        text: item.ResourceDesc,
                    };

                    if (record.value !== "all") {// Bypass all
                        refDataSelectOptions.push(record);
                    }
                });

                switch(refType) {
                    case "web-pages":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setWebPageOptions(refDataSelectOptions);
                        break;
                    case "supported-domains":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setDomainOptions(refDataSelectOptions);
                        break;
                    // case "pricing-units":
                    //     refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                    //     setMediaCaptionOptions(refDataSelectOptions);
                    //     break;
                    // case "stripe-curr":
                    //     refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                    //     setMediaDescOptions(refDataSelectOptions);
                    //     break;
                    default:
                        break;
                }

                backdropProgress(false);
                resolve(refDataSelectOptions);
            }).catch((err) => {
                console.error("In ManagePageSections  fetchReferenceData(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject(err);
            });
        });
    }
        
    useEffect(() => {
        // console.log('In ManagePageSections(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        // sessionStorage.removeItem("current-live-event");
    }, []);

    useLayoutEffect(() => {
        // console.log("In ManagePageSections(): useLayoutEffect()");
        async function fetchItems() {
            await fetchLanguages();

            // Fetch resource from catche
            let cacheItemKey = "manage-web-pages";
            cacheItemKey += ":";
            cacheItemKey += appContext.currentLanguage.languageCode;
            // console.log('In ManagePageSections(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

            if (
                appContext.clearCacheLanguageCodes &&
                appContext.clearCacheLanguageCodes.length > 0 &&
                appContext.clearCacheLanguageCodes.includes(appContext.currentLanguage.languageCode)
            ) {
                // console.log("In ManagePageSections(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                RemoveCacheItem(cacheItemKey);
            }

            let cacheItem = GetCacheItem(cacheItemKey);
            // console.log('In ManagePageSections(): useLayoutEffect(): cacheItem is: ', cacheItem);
            cacheItem = null; // remove after testing

            if (cacheItem === null) {
                // resource is not in cache, fetch from DB
                // console.log('In ManagePageSections(): useLayoutEffect(): resource is not in cache, fetch from DB');
                await fetchDbResources(cacheItemKey);
            } else {
                // console.log('In ManagePageSections(): useLayoutEffect(): got resource from cache');
                setPageLabelsRes(cacheItem);
                setRenderDomFlag(true);
            }
        }

        fetchItems();
    }, []);
    
    function handleChange(event) {
        // console.log("In handleChange(): event.target.id is: ", event.target.id);
        // console.log("In handleChange(): event.target.value is: ", event.target.value);
        // setSubmitButtonFlag(false);

        switch(event.target.id) {
            case "sectionTitleId":
                setSectionTitleErrFlag(false);
                setSectionTitle(event.target.value);

                setSubmitButtonFlag(false);
                if (!lodash.isEmpty(event.target.value)) {
                    if (
                        !lodash.isEmpty(event.target.value) &&
                        !lodash.isEmpty(sectionName) &&
                        // !lodash.isEmpty(mediaDesc) &&
                        // !lodash.isEmpty(editorContent) &&
                        // !lodash.isEmpty(mediaUrl) &&
                        ((!lodash.isEmpty(textContent) && lodash.isEmpty(editorContent)) ||
                        (lodash.isEmpty(textContent) && !lodash.isEmpty(editorContent))) &&
                        !lodash.isEmpty(webPage) &&
                        !lodash.isEmpty(domain) &&
                        !lodash.isEmpty(language)
                        ) {
                        setSubmitButtonFlag(true);
                    }
                }
                break;
            case "sectionNameId":
                setSectionNameErrFlag(false);
                setSectionName(event.target.value);

                setSubmitButtonFlag(false);
                if (!lodash.isEmpty(event.target.value)) {
                    if (
                        !lodash.isEmpty(event.target.value) &&
                        !lodash.isEmpty(sectionTitle) &&
                        // !lodash.isEmpty(mediaDesc) &&
                        // !lodash.isEmpty(editorContent) &&
                        // !lodash.isEmpty(mediaUrl) &&
                        ((!lodash.isEmpty(textContent) && lodash.isEmpty(editorContent)) ||
                        (lodash.isEmpty(textContent) && !lodash.isEmpty(editorContent))) &&
                        !lodash.isEmpty(webPage) &&
                        !lodash.isEmpty(domain) &&
                        !lodash.isEmpty(language)
                        ) {
                        setSubmitButtonFlag(true);
                    }
                }
                break;
            case "textContentId":
                // console.log("In handleChange(): event.target.value is: ", event.target.value);
                setTextContentErrFlag(false);
                setTextContent(event.target.value);

                setSubmitButtonFlag(false);
                if (
                    ((!lodash.isEmpty(event.target.value) && lodash.isEmpty(editorContent)) ||
                    (lodash.isEmpty(event.target.value) && !lodash.isEmpty(editorContent))) &&
                    // !lodash.isEmpty(mediaCaption) &&
                    // !lodash.isEmpty(mediaDesc) &&
                    // !lodash.isEmpty(editorContent) &&
                    !lodash.isEmpty(sectionTitle) &&
                    !lodash.isEmpty(sectionName) &&
                    // !lodash.isEmpty(mediaUrl) &&
                    !lodash.isEmpty(webPage) &&
                    !lodash.isEmpty(domain) &&
                    !lodash.isEmpty(language)
                    ) {
                    setSubmitButtonFlag(true);
                }
                break;
            case "sectionDescId":
                setSectionDescErrFlag(false);
                setSectionDesc(event.target.value);
                break;
            case "mediaCaptionId":
                setMediaCaptionErrFlag(false);
                setMediaCaption(event.target.value);
                break;
            case "mediaDescId":
                setMediaDescErrFlag(false);
                setMediaDesc(event.target.value);
                break;
            case "mediaUrlId":
                // console.log("In handleChange(): event.target.value is: ", event.target.value);
                setMediaUrlErrFlag(false);
                setMediaUrl(event.target.value);
                break;
            default:
                break;
        }
    }

    //--------------------
    // For Froala Editor
    //--------------------
    async function handleEditorContentChange(editorData) {
        // console.log("In handleEditorContentChange(): editorData is: ", editorData);

        setEditorContent(editorData);
        setSubmitButtonFlag(false);

        if (
            ((!lodash.isEmpty(editorData) && lodash.isEmpty(textContent)) ||
            (lodash.isEmpty(editorData) && !lodash.isEmpty(textContent))) &&
            // !lodash.isEmpty(mediaCaption) &&
            // !lodash.isEmpty(mediaDesc) &&
            // !lodash.isEmpty(mediaUrl) &&
            !lodash.isEmpty(sectionTitle) &&
            !lodash.isEmpty(sectionName) &&
            // !lodash.isEmpty(textContent) &&
            !lodash.isEmpty(webPage) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(language)
            ) {
            setSubmitButtonFlag(true);
        }
    }
    
    function handleLanguageChange(event, item) {
        // console.log("In handleLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        setSubmitButtonFlag(false);        
        setLanguage(item);

        if (!lodash.isEmpty(item)) {
            Promise.all([
                fetchReferenceData("web-pages", item),
            ]).then(async (results) => {
                // console.log("In handleLanguageChange(): results is: ", results);
                Promise.all([
                    await fetchReferenceData("supported-domains", item)
                ]).then(async (results2) => {
                    // console.log("In handleLanguageChange(): results2 is: ", results2);
                    // Promise.all([
                    //     await fetchReferenceData("pricing-units", item)
                    // ]).then(async (results3) => {
                    //     // console.log("In handleLanguageChange(): results3 is: ", results3);
                    //     await fetchReferenceData("stripe-curr", item)
                    // });
                });
            }).catch(error => {
                console.error("In handleLanguageChange(): error is: ", error);
            });
        }

        if (
            !lodash.isEmpty(item) &&
            // !lodash.isEmpty(mediaCaption) &&
            // !lodash.isEmpty(mediaDesc) &&
            ((!lodash.isEmpty(editorContent) && lodash.isEmpty(textContent)) ||
            (lodash.isEmpty(editorContent) && !lodash.isEmpty(textContent))) &&
            !lodash.isEmpty(sectionTitle) &&
            !lodash.isEmpty(sectionName) &&
            // !lodash.isEmpty(textContent) &&
            !lodash.isEmpty(webPage) &&
            !lodash.isEmpty(domain) 
            // !lodash.isEmpty(mediaUrl)
        ) {
            setSubmitButtonFlag(true);
        }
    }
    
    async function handleListLanguageChange(event, item) {
        // console.log("In handleListLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        // setSubmitButtonFlag(false);        
        setLanguage(item);

        setDomain(null);
        setWebPageRecords([]);
        setRecordsBackup([]);

        if (!lodash.isEmpty(item)) {
            Promise.all([
                await fetchReferenceData("supported-domains", item)
            ]).then(async (results) => {
                // console.log("In handleListLanguageChange(): results is: ", results);
            }).catch(error => {
                console.error("In handleListLanguageChange(): error is: ", error);
            });
        }
    }

    async function handleWebPageChange(event, item) {
        // console.log("In handleWebPageChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setWebPageErrFlag(false);
        
        setWebPage(item);

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            // !lodash.isEmpty(mediaCaption) &&
            // !lodash.isEmpty(mediaDesc) &&
            ((!lodash.isEmpty(editorContent) && lodash.isEmpty(textContent)) ||
            (lodash.isEmpty(editorContent) && !lodash.isEmpty(textContent))) &&
            !lodash.isEmpty(sectionTitle) &&
            !lodash.isEmpty(sectionName) &&
            // !lodash.isEmpty(textContent) &&
            // !lodash.isEmpty(mediaUrl) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(language)
            ) {
                setSubmitButtonFlag(true);
        }

        if (item) {
            await fetchPageSectionRecords(item);
        }
    }


    function handleDomainChange(event, item) {
        // console.log("In handleDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            sessionStorage.setItem("media-domain-name", item.value);
        }

        if (!lodash.isEmpty(item)) {
            fetchWebPageRecords(item);
        }

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            // !lodash.isEmpty(mediaCaption) &&
            // !lodash.isEmpty(mediaDesc) &&
            ((!lodash.isEmpty(editorContent) && lodash.isEmpty(textContent)) ||
            (lodash.isEmpty(editorContent) && !lodash.isEmpty(textContent))) &&
            !lodash.isEmpty(sectionTitle) &&
            !lodash.isEmpty(sectionName) &&
            // !lodash.isEmpty(textContent) &&
            !lodash.isEmpty(webPage) &&
            // !lodash.isEmpty(mediaUrl) &&
            !lodash.isEmpty(language)
            ) {
                setSubmitButtonFlag(true);
        }
    }

    function handleListDomainChange(event, item) {
        // console.log("In handleListDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            fetchWebPageRecords(item);
        }
    }

    function initializeForm() {
        // setLanguage(null);
        // setDomain(null);

        setSectionTitle("");
        setSectionName("");
        setSectionDesc("");

        setTextContent("");
        setEditorContent("");

        setMediaCaption("");
        setMediaDesc("");
        setMediaUrl("");
    }

    function createPriceAction() {
        initializeForm();

        // setLanguage(null);
        setDomain(null);

        setEditModeFlag(false);
        setShowListFlag(false);
        setOrderListFlag(false);
        setUploadFlag(false);
        setShowDetailsFormFlag(true);
    }

    function createMoreAction() {
        initializeForm();

        // setLanguage(null);
        setDomain(null);
        // setWebPage(null);

        setEditModeFlag(false);
        setShowListFlag(false);
        setOrderListFlag(false);
        setUploadFlag(false);
        setShowDetailsFormFlag(true);
    }

    function uploadAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setOrderListFlag(false);
        setUploadFlag(true);
    }

    async function orderListAction() {
        // console.log("In orderListAction(): webPage is: ", webPage);
        // await fetchPageSectionRecords(webPage);

        setWebPage(null);
        setFilteredRecords([]);

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setUploadFlag(false);
        setOrderListFlag(true);
    }

    function closeOrderListForm() {
        initializeForm();

        setEditModeFlag(false);
        setShowDetailsFormFlag(false);
        setUploadFlag(false);
        setOrderListFlag(false);
        setShowListFlag(true);
    }
    
    function convertAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setOrderListFlag(false);
        setUploadFlag(false);
        setConvertFlag(true);
    }
    
    async function closeConvertForm() {
        // await fetchWebPageRecords();
        
        setTimeout(() => {
            setShowDetailsFormFlag(false);
            setOrderListFlag(false);
            setUploadFlag(false);
            setConvertFlag(false);
            setShowListFlag(true);
        }, 500);
    }
    
    async function closeDetailsForm() {
        await fetchPageSectionRecords(webPage);
        
        setTimeout(() => {
            setGlobalErrorMsg("");
            setGlobalErrorFlag(false);

            setShowDetailsFormFlag(false);
            setOrderListFlag(false);
            setUploadFlag(false);
            setEditModeFlag(false);
            setShowListFlag(true);
        }, 500);
    }
    
    async function closeUploadForm() {
        await fetchPageSectionRecords(webPage);
        
        setTimeout(() => {
            setGlobalErrorMsg("");
            setGlobalErrorFlag(false);

            setEditModeFlag(false);
            setShowDetailsFormFlag(false);
            setOrderListFlag(false);
            setUploadFlag(false);
            setShowListFlag(true);
        }, 500);
    }
    
    async function createService() {
        // console.log("In createService(): language is: ", language);
        // console.log("In createService(): domain is: ", domain);
        // console.log("In createService(): webPage is: ", webPage);
        // console.log("In createService(): textContent is: ", textContent);
        // console.log("In createService(): sectionTitle is: ", sectionTitle);
        // console.log("In createService(): editorContent is: ", editorContent);
        // console.log("In createService(): timeZone is: ", timeZone);
        // console.log("In createService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In createService(): appContext.userName is: ", appContext.userName);
    
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In createService(): Validation passed.");
            // console.log("In createService(): Validation passed.");

            backdropProgress(true);

            const timestamp = moment.tz(Date.now(), timeZone);
            // console.log("In createService(): timestamp is: ", timestamp);

            // let tzMediaCaption = null;
            // if (mediaCaption) {
            //     tzMediaCaption = moment.tz(mediaCaption, timeZone);
            // }
            // console.log("In createService(): tzMediaCaption is: ", tzMediaCaption);

            // let tzMediaDesc = null;
            // if (mediaDesc) {
            //     tzMediaDesc = moment.tz(mediaDesc, timeZone);
            // }
            // console.log("In createService(): tzMediaDesc is: ", tzMediaDesc);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            let uniqueId = GenerateUniqueName();

            let key = {
                PagePK: domain.value + "#" + language.languageCode + "#page#section",
                PageSK: domain.value + "#" + language.languageCode + "#page#section#" + webPage.value + "#" + uniqueId,
            };
            // console.log("In createService(): key is: ", key);

            let item = {
                "PagePK": key.PagePK,
                "PageSK": key.PageSK,

                "SectionId": uniqueId,

                "PageId": webPage.value,
                "WebPage": lodash.isEmpty(webPage) ? null : webPage,

                "UniqueAttribute": lodash.isEmpty(key) ? null : key,

                "LanguageObject": lodash.isEmpty(language) ? null : language,
                "LanguageCode": lodash.isEmpty(language) ? null : language.languageCode,

                "Domain": domain,
                "SectionDomain": lodash.isEmpty(domain) ? null : domain.value,

                "SectionTitle": lodash.isEmpty(sectionTitle) ? null : sectionTitle,
                "SectionName": lodash.isEmpty(sectionName) ? null : sectionName,
                "SectionDesc": lodash.isEmpty(sectionDesc) ? null : sectionDesc,

                "TextContent": lodash.isEmpty(textContent) ? null : textContent,
                "EditorContent": lodash.isEmpty(editorContent) ? null : editorContent,

                "MediaCaption": lodash.isEmpty(mediaCaption) ? null : mediaCaption,
                "MediaDesc": lodash.isEmpty(mediaDesc) ? null : mediaDesc,
                "MediaUrl": lodash.isEmpty(mediaUrl) ? null : mediaUrl,

                "DomainLangWebPageCode": domain.value + "#" + language.languageCode + "#" + webPage.value,// For GSI

                "SectionSeqNumber": 0,

                "ModifiedBy": appContext.userName,
                "CreatedAt": timestamp,
                "UpdatedAt": timestamp,
            };

            // console.log('In createService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_not_exists(UniqueAttribute)";

            let params = {
                apiName: "PagesAPI",
                path: "/dynamodb/pages/put",
                payload: {
                    item: item,
                    conditionExpression: conditionExpression,
                    // updateExpression: updateExpression,
                    // expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In createService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In createService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In createService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar 
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);
            }).catch((err) => {
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                switch(err.code) {
                    case "ConditionalCheckFailedException":
                        console.error("In createService(): Duplicate Error");
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        break;
                    default:
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        console.error("In createService(): err is: ", JSON.stringify(err, null, 2));
                        break;
                }
            });
        }
    }

    async function updateService() {
        // console.log("In updateService(): language is: ", language);
        // console.log("In updateService(): domain is: ", domain);
        // console.log("In updateService(): webPage is: ", webPage);
        // console.log("In updateService(): textContent is: ", textContent);
        // console.log("In updateService(): sectionTitle is: ", sectionTitle);
        // console.log("In updateService(): editorContent is: ", editorContent);
        // console.log("In updateService(): timeZone is: ", timeZone);
        // console.log("In updateService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In updateService(): appContext.userName is: ", appContext.userName);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In updateService(): Validation passed.");

            // let uniqueId = GenerateUniqueName();// conversion

            backdropProgress(true);

            const timestamp = moment.tz(Date.now(), timeZone);
            // console.log("In updateService(): timestamp is: ", timestamp);

            // let tzMediaCaption = null;
            // if (mediaCaption) {
            //     tzMediaCaption = moment.tz(mediaCaption, timeZone);
            // }
            // // console.log("In updateService(): tzMediaCaption is: ", tzMediaCaption);

            // let tzMediaDesc = null;
            // if (mediaDesc) {
            //     tzMediaDesc = moment.tz(mediaDesc, timeZone);
            // }
            // // console.log("In updateService(): tzMediaDesc is: ", tzMediaDesc);

            let key = {
                PagePK: appContext.currentPK,
                PageSK: appContext.currentSK,
            };
            // console.log("In updateService(): key is: ", key);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
            let updateExpression =
                "SET " +
                "UniqueAttribute = :uniqueAttribute, " +
                
                // "PageDomain = :pageDomain, " + 
                // "WebPage = :webPage, " +

                "SectionTitle = :sectionTitle, " +
                "SectionName = :sectionName, " +
                "SectionDesc = :sectionDesc, " +

                "TextContent = :textContent, " +
                "EditorContent = :editorContent, " +

                "MediaCaption = :mediaCaption, " +
                "MediaDesc = :mediaDesc, " +
                "MediaUrl = :mediaUrl, " +
                
                "DomainLangWebPageCode = :domainLangWebPageCode, " +

                "ModifiedBy = :modifiedBy, " + 
                "UpdatedAt = :updatedAt";

            let expressionAttributeValues = {
                ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
                // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
                // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,
                
                // ":uniqueId": lodash.isEmpty(uniqueId) ? null : uniqueId,// conversion
                
                // ":pageDomain": lodash.isEmpty(domain) ? null : domain.value,
                
                ":sectionTitle": lodash.isEmpty(sectionTitle) ? null : sectionTitle,
                ":sectionName": lodash.isEmpty(sectionName) ? null : sectionName,
                ":sectionDesc": lodash.isEmpty(sectionDesc) ? null : sectionDesc,

                ":textContent": lodash.isEmpty(textContent) ? null : textContent,
                ":editorContent": lodash.isEmpty(editorContent) ? null : editorContent,

                ":mediaCaption": lodash.isEmpty(mediaCaption) ? null : mediaCaption,
                ":mediaDesc": lodash.isEmpty(mediaDesc) ? null : mediaDesc,
                ":mediaUrl": lodash.isEmpty(mediaUrl) ? null : mediaUrl,

                ":domainLangWebPageCode": domain.value + "#" + language.languageCode + "#" + webPage.value,// For GSI
                
                ":modifiedBy": appContext.userName,
                ":updatedAt": timestamp,
            };
            // console.log('In updateService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_exists(UniqueAttribute)";

            let params = {
                apiName: "PagesAPI",
                path: "/dynamodb/pages/update",
                payload: {
                    key: key,
                    conditionExpression: conditionExpression,
                    updateExpression: updateExpression,
                    expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In updateService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In updateService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.put(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In updateService(): response is: ', JSON.stringify(response, null, 2));
                setShowDetailsFormFlag(false);
                setOrderListFlag(false);
                setUploadFlag(false);
                setEditModeFlag(false);

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                // initializeForm();

                backdropProgress(false);

                await fetchPageSectionRecords(webPage);
                setShowListFlag(true);
            }).catch((err) => {
                console.error("In updateService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    async function deleteService(recToDelete) {
        // console.log("In deleteService(): recToDelete is: ", recToDelete);
        // setDeleteCompleteFlag(false);
        // let itemsToDelete = [];

        if (!lodash.isEmpty(recToDelete)) {
            backdropProgress(true);

            let key = {
                PagePK: recToDelete.PagePK,
                PageSK: recToDelete.PageSK,
            };

            let params = {
                apiName: "PagesAPI",
                path: "/dynamodb/pages/delete",
                payload: {
                    key: key,
                },
            };
    
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In deleteService(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In deleteService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                backdropProgress(false);
                await fetchPageSectionRecords(webPage);
            }).catch((err) => {
                console.error("In deleteService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    function validateForm() {
        // console.log("In validateForm()");
        let formErrFlag = false;
  
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
    
        setLanguageErrFlag(false);
        setDomainErrFlag(false);
        setWebPageErrFlag(false);

        setSectionTitleErrFlag(false);
        setTextContentErrFlag(false);
        setEditorContentErrFlag(false);

        setMediaCaptionErrFlag(false);
        setMediaDescErrFlag(false);
        setMediaUrlErrFlag(false);

        if (lodash.isEmpty(language)) {
            formErrFlag = true;
            setLanguageErrFlag(true);
        }

        if (lodash.isEmpty(domain)) {
            formErrFlag = true;
            setDomainErrFlag(true);
        }

        if (lodash.isEmpty(webPage)) {
            formErrFlag = true;
            setWebPageErrFlag(true);
        }

        if (lodash.isEmpty(sectionTitle)) {
            formErrFlag = true;
            setSectionTitleErrFlag(true);
        }

        if (lodash.isEmpty(sectionName)) {
            formErrFlag = true;
            setSectionTitleErrFlag(true);
        }

        if (lodash.isEmpty(textContent) && lodash.isEmpty(editorContent)) {
            formErrFlag = true;
            setTextContentErrFlag(true);
            setEditorContentErrFlag(true);
        }

        if (!lodash.isEmpty(mediaUrl)) {
            if (!isURL(mediaUrl)) {
                formErrFlag = true;
                setMediaUrlErrFlag(true);
            }
        }
    
        if (formErrFlag) {
            setGlobalErrorFlag(true);
        }
        // console.log("In validateForm(): formErrFlag is: ", formErrFlag);
        return formErrFlag;
    }

    async function buttonFormAction() {
        // console.log("In buttonFormAction()");

        if (editModeFlag) {
            await updateService();
        } else {
            await createService();
        }
    }
    
    function snackbarOnClose(event, reason) {
        setSnackbarOpen(false);
    }

    function handleMultipleSelectionChange(data) {
        // console.log("In handleMultipleSelectionChange(): data.value is: ", data.value);
        setSelectedTableRows(data.value);
        setTableDirtyFlag(true);
    }

    //------------------
    // For AlertDialog
    //------------------
    const [deleteType, setDeleteType] = useState("");

    function showAlertDialog(item, type) {
        // console.log("In showAlertDialog(): type is: ", type);
        setDeleteType(type);
        
        if (type && type === "single") {// single deletion
            setRecordToDelete(item);

            let subContent = item.WebPageText;
            if (!lodash.isEmpty(item.SectionTitle)) {
                subContent += " - ";
                subContent += item.SectionTitle;
            }
            setDialogSubContent(subContent);
            setAlertOpen(true);
        } else {// multiple deletions
            let msg = FormatNumber(selectedTableRows.length, appContext.currentLanguage.languageCode);
            msg += " ";
            msg += selectedTableRows.length > 1 ? pageSectionsLabel : pageSectionLabel;
            setDialogSubContent(msg);
            setAlertOpen(true);
        }
    }

    function alertClickAgree() {
        // Close Alert
        if (deleteType && deleteType === "single") {
            setAlertOpen(false);
            deleteService(recordToDelete);
        } else {
            setAlertOpen(false);
            // deleteMultipleAction();
            processMultipleDeletionRecs();
        }
    }

    function alertClickDisagree() {
        // Close Alert
        setAlertOpen(false);
        return false;
    }

    function alertClose() {
        // Close Alert
        setAlertOpen(false);
    }

    function deleteIconTemplate(rowData, column) {
        return <DeleteIcon color="secondary" onClick={() => showAlertDialog(rowData, "single")}/>
    }

    function editIconTemplate(rowData, column) {
        return <EditIcon color="primary" onClick={() => editRecordAction(rowData)}/>
    }

    function contentTemplate(rowData, column) {
        return (
            <>
                {rowData.TextContentFlag ?
                    <>
                        <Typography variant="caption" style={{textAlign: 'left'}}>
                            {sliceText(rowData.DisplayContent, 200)}
                        </Typography>
                    </>
                    :
                    <>
                        <Typography variant="caption" style={{textAlign: 'left'}}>
                            <FroalaEditorView model={sliceText(rowData.DisplayContent, 300)}/>
                        </Typography>
                    </>
                }
            </>
        )
    }

    async function createOrUpdateStreamRecord(rowData) {
        // console.log("In createOrUpdateStreamRecord(): rowData is: ", JSON.stringify(rowData, null, 2));
        // console.log("In createOrUpdateStreamRecord(): CobPK is: ", rowData.CobPK);
        // console.log("In createOrUpdateStreamRecord(): CobSK is: ", rowData.CobSK);

        // console.log("In createOrUpdateStreamRecord(): liveEventUrl is: ", rowData.LiveEventUrl);
        // console.log("In createOrUpdateStreamRecord(): liveEventUrlType is: ", rowData.LiveEventUrlType);
        // console.log("In createOrUpdateStreamRecord(): mediaUrl is: ", rowData.MediaUrl);
        // console.log("In createOrUpdateStreamRecord(): sectionTitle is: ", rowData.SectionTitle);
        // console.log("In createOrUpdateStreamRecord(): editorContent is: ", rowData.EditorContent);

        // console.log("In createOrUpdateStreamRecord(): mediaCaption is: ", rowData.MediaCaption);
        // console.log("In createOrUpdateStreamRecord(): webPage is: ", rowData.WebPage);

        // console.log("In createOrUpdateStreamRecord(): timeZone is: ", timeZone);
        // console.log("In createOrUpdateStreamRecord(): appContext.deceasedCode is: ", appContext.deceasedCode);
        // console.log("In createOrUpdateStreamRecord(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In createOrUpdateStreamRecord(): appContext.userName is: ", appContext.userName);

        let webPage = null;
        if (rowData.EditorContent) {
            webPage = moment(rowData.MediaCaption).add(parseInt(rowData.EditorContent.value), 'minutes');
        }
        // console.log("In createOrUpdateStreamRecord(): webPage is: ", webPage);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: "live-events",
            CobSK: appContext.currentLanguage.languageCode + "#" + appContext.deceasedCode,
        };
        // console.log("In createOrUpdateStreamRecord(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        // let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            "LanguageObject = :languageObject, " +
            "LanguageCode = :languageCode, " +
            
            "LiveEventStarted = :liveEventStarted, " +

            "EditorContent = :editorContent, " +

            "MediaCaption = :mediaCaption, " +
            "WebPage = :webPage, " +

            "EventTimeZone = :eventTimeZone, " +

            "LiveEventUrl = :liveEventUrl, " +
            "LiveEventUrlType = :liveEventUrlType, " +
            "MediaUrl = :mediaUrl, " +
            "TextContent = :textContent, " +
            "SectionTitle = :sectionTitle, " +

            "EventThumbnailUrl = :eventThumbnailUrl, " +
            "EventMediaUrl = :eventMediaUrl, " +
            "EventPosterUrl = :eventPosterUrl, " +
            "EventAudioUrl = :eventAudioUrl, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            ":languageObject": lodash.isEmpty(rowData.LanguageObject) ? null : rowData.LanguageObject,
            ":languageCode": lodash.isEmpty(rowData.LanguageCode) ? null : rowData.LanguageCode,
                    
            ":liveEventStarted": true,

            ":editorContent": lodash.isEmpty(rowData.EditorContent) ? null : rowData.EditorContent,

            ":mediaCaption": lodash.isEmpty(rowData.MediaCaption) ? null : rowData.MediaCaption,
            ":webPage": lodash.isEmpty(webPage) ? null : webPage,

            ":eventTimeZone": lodash.isEmpty(timeZone) ? null : timeZone,

            ":liveEventUrl": lodash.isEmpty(rowData.LiveEventUrl) ? null : rowData.LiveEventUrl,
            ":liveEventUrlType": lodash.isEmpty(rowData.LiveEventUrlType) ? null : rowData.LiveEventUrlType,
            ":textContent": lodash.isEmpty(rowData.TextContent) ? null : rowData.TextContent,
            ":sectionTitle": lodash.isEmpty(rowData.SectionTitle) ? null : rowData.SectionTitle,
            ":mediaUrl": lodash.isEmpty(rowData.MediaUrl) ? null : rowData.MediaUrl,

            ":eventThumbnailUrl": lodash.isEmpty(rowData.EventThumbnailUrl) ? null : rowData.EventThumbnailUrl,
            ":eventMediaUrl": lodash.isEmpty(rowData.EventMediaUrl) ? null : rowData.EventMediaUrl,
            ":eventPosterUrl": lodash.isEmpty(rowData.EventPosterUrl) ? null : rowData.EventPosterUrl,
            ":eventAudioUrl": lodash.isEmpty(rowData.EventAudioUrl) ? null : rowData.EventAudioUrl,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In createOrUpdateStreamRecord(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        // conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                // conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In createOrUpdateStreamRecord(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In createOrUpdateStreamRecord(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.put(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In createOrUpdateStreamRecord(): response is: ', JSON.stringify(response, null, 2));
            // setShowDetailsFormFlag(false);
            // setOrderListFlag(false);
            // setUploadFlag(false);

            // Show Snackbar
            setSnackbarsVariant("success");
            setSnackbarsMsg(operationSuccessMsg);
            setSnackbarOpen(true);

            // initializeForm();
            // const now = moment();
            // let remainTime = now.diff(moment(formattedTime), "minutes");
            // console.log("In createOrUpdateStreamRecord(): remainTime is: ", remainTime + " minutes");

            let records = [];
            rowData.LiveEventStarted = true;
            records = webPageRecords.filter(item => item.LiveEventId !== rowData.LiveEventId);
            if (records) {
                records.push(rowData);
                records.sort((a, b) => (a.MediaCaption > b.MediaCaption ? 1 : -1));
                // setBlogRecords(records);
            }

            backdropProgress(false);
        }).catch((err) => {
            console.error("In createOrUpdateStreamRecord(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }

    async function updateLiveEventStatus(rowData) {
        // console.log("In updateLiveEventStatus(): rowData.CobPK is: ", rowData.CobPK);
        // console.log("In updateLiveEventStatus(): rowData.CobSK is: ", rowData.CobSK);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
                
        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: rowData.CobPK,
            CobSK: rowData.CobSK,
        };
        // console.log("In updateLiveEventStatus(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            // "LanguageObject = :languageObject, " +
            // "LanguageCode = :languageCode, " +

            "LiveEventStarted = :liveEventStarted, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
            // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,

            ":liveEventStarted": true,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In updateLiveEventStatus(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In updateLiveEventStatus(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In updateLiveEventStatus(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.put(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In updateLiveEventStatus(): response is: ', JSON.stringify(response, null, 2));
            setShowDetailsFormFlag(false);
            setOrderListFlag(false);
            setUploadFlag(false);

            // Show Snackbar
            setSnackbarsVariant("success");
            setSnackbarsMsg(operationSuccessMsg);
            setSnackbarOpen(true);

            // initializeForm();

            backdropProgress(false);

            // await fetchWebPageRecords();
            setShowListFlag(true);
        }).catch((err) => {
            console.error("In updateLiveEventStatus(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }

    async function forcedToEndUpdate(rowData, showStatus) {
        // console.log("In forcedToEndUpdate(): rowData.CobPK is: ", rowData.CobPK);
        // console.log("In forcedToEndUpdate(): rowData.CobSK is: ", rowData.CobSK);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        // console.log("In forcedToEndUpdate(): Validation passed.");
            
        const timestamp = new Date();

        backdropProgress(true);

        let key = {
            CobPK: rowData.CobPK,
            CobSK: rowData.CobSK,
        };
        // console.log("In forcedToEndUpdate(): key is: ", key);

        // ConditionExpression: "attribute_exists(resourceCode)",
        let conditionExpression = "";

        // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
        let updateExpression =
            "SET " +
            "UniqueAttribute = :uniqueAttribute, " +
            // "LanguageObject = :languageObject, " +
            // "LanguageCode = :languageCode, " +

            "ForcedToEndFlag = :forcedToEndFlag, " +

            "ModifiedBy = :modifiedBy, " + 
            "UpdatedAt = :updatedAt";

        let expressionAttributeValues = {
            ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
            // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
            // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,

            ":forcedToEndFlag": true,

            ":modifiedBy": appContext.userName,
            ":updatedAt": timestamp,
        };
        // console.log('In forcedToEndUpdate(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

        conditionExpression = "attribute_exists(UniqueAttribute)";

        let params = {
            apiName: "CobAPI",
            path: "/dynamodb/cob/update",
            payload: {
                key: key,
                conditionExpression: conditionExpression,
                updateExpression: updateExpression,
                expressionAttributeValues: expressionAttributeValues,
            },
        };
        // console.log('In forcedToEndUpdate(): params.payload is: ', JSON.stringify(params.payload, null, 2));

        let initObj = {
            headers: {
            "Content-Type": "application/json",
            Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In forcedToEndUpdate(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.put(params.apiName, params.path, initObj).then(async (response) => {
            // console.log('In forcedToEndUpdate(): response is: ', JSON.stringify(response, null, 2));
            setShowDetailsFormFlag(false);
            setOrderListFlag(false);
            setUploadFlag(false);

            // Show Snackbar
            if (showStatus) {
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);
            }

            // initializeForm();

            backdropProgress(false);

            // await fetchWebPageRecords();
            setShowListFlag(true);
        }).catch((err) => {
            console.error("In forcedToEndUpdate(): err is: ", JSON.stringify(err, null, 2));
            setGlobalErrorFlag(true);
            setGlobalErrorMsg(operationFailureMsg);
            backdropProgress(false);
        });
    }
    
    function sortMediaCaption(event) {
        console.log("In sortMediaCaption(): event is: ", event);

        var values = [...webPageRecords];
        values.sort((data1, data2) => {
            // Note: We are using actual DateTime object and not the dateTimeFormatted 
            // used for display on data table.
            let value1 = data1.MediaCaption;
            let value2 = data2.MediaCaption;
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });

        return values;
    }
    
    function sortMediaDesc(event) {
        console.log("In sortMediaDesc(): event is: ", event);

        var values = [...webPageRecords];
        values.sort((data1, data2) => {
            // Note: We are using actual DateTime object and not the dateTimeFormatted 
            // used for display on data table.
            let value1 = data1.MediaDesc;
            let value2 = data2.MediaDesc;
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });

        return values;
    }
    
    // function sortTimeField(event) {
    //     // console.log("In sortTimeField(): event is: ", event);

    //     var values = [...webPageRecords];
    //     values.sort((data1, data2) => {
    //         // Note: We are using actual DateTime object and not the dateTimeFormatted 
    //         // used for display on data table.
    //         let value1 = data1.WebPage;
    //         let value2 = data2.WebPage;
    //         let result = null;

    //         if (value1 == null && value2 != null)
    //             result = -1;
    //         else if (value1 != null && value2 == null)
    //             result = 1;
    //         else if (value1 == null && value2 == null)
    //             result = 0;
    //         else if (typeof value1 === 'string' && typeof value2 === 'string')
    //             result = value1.localeCompare(value2);
    //         else
    //             result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

    //         return (event.order * result);
    //     });

    //     return values;
    // }

    let processedRecs = 0;

    async function deleteMultipleAction() {
        // console.log("In deleteMultipleAction(): selectedTableRows.length is: ", selectedTableRows.length);
        // console.log("In deleteMultipleAction(): selectedTableRows is: ", selectedTableRows);

        // Disable Save changes button
        setTableDirtyFlag(false);

        return new Promise((resolve, reject) => {
            if (selectedTableRows && selectedTableRows.length > 0) {
                selectedTableRows.map(record => {
                    setTimeout(async () => {
                        await deleteService(record);

                        processedRecs++;

                        if (processedRecs === selectedTableRows.length) {
                            resolve("Done");
                        }
                    }, 1000);
                });
            }
        });
    }

    async function processMultipleDeletionRecs() {
        // console.log("In processMultipleDeletionRecs()");

        //-------------------
        // Promise.all
        //-------------------
        Promise.all(
            [
                deleteMultipleAction()
            ]).then(async (results) => {
                // console.log("In processMultipleDeletionRecs(): results is: ", results);
                // console.log("In processMultipleDeletionRecs(): records deleted from AWS, now delete them from DataTable");
                // Remove deleted records from DataTable
                let remainingRecords = FilterArrayFromArray(webPageRecords, selectedTableRows);
                if (remainingRecords) {
                    // setBlogRecords(remainingRecords);
                    setSelectedTableRows([]);
                }
            });
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        setMediaUrl(item.MediaUrl);

        setRecordToEdit(item);

        appContext.currentPK = item.PagePK;
        appContext.currentSK = item.PageSK;
        await getPageSectionRecord({PagePK: item.PagePK, PageSK: item.PageSK,});

        setTimeout(() => {
            setShowListFlag(false);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);
            setShowDetailsFormFlag(true);
        }, 500);
    }
  
    // function sliceUrl(url, maxChars) {
    //     let retValue = url;
    //     if (url.length > maxChars) {
    //         retValue = url.substring(0, (maxChars - 1));
    //         retValue += " ...";
    //     }
    //     return retValue;
    // }

    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    //--------------------------
    // For CommonMediaSelector
    //--------------------------
    const [dialogOpenFlag, setDialogOpenFlag] = useState(false);
    // const [mediaActionFlag, setMediaActionFlag] = useState(false);
    const [imagesLocationUri, setImagesLocationUri] = useState("");
        
    function onRowSelectedCallback(src) {
        // console.log("In onRowSelectedCallback(): src is: ", src);
        // console.log("In onRowSelectedCallback(): editorInstance is: ", editorInstance);
        // editorInstance.html.insert('<img src="'+ src + '" style="width: 300px;" class="fr-fic fr-dib">');
        setMediaUrl(src ? src : "");
        setDialogOpenFlag(false);
    }

    function dialogCloseCallback() {
        // console.log("In dialogCloseCallback()");
        setDialogOpenFlag(false);
    }

    function selectImageAction() {
        // We are doing this to be able to trigger a change in
        // CommonMediaSelector especially when editing a record.
        
        if (editModeFlag) {
            const currLang = language;
            setLanguage(null);

            setTimeout(() => {
                setLanguage(currLang);
                setDialogOpenFlag(true);
            }, 500);
        } else {
            setDialogOpenFlag(true);
        }
    }

    //------------------------
    // For sliceText and Dialog
    //------------------------

    function sliceMoreAction(record) {
        setDialogData(record.EditorContent);
        setDialogHeader(record.SectionTitle);
        setDlgVisibleFlag(true);
    }

    function hideModalDlg() {
        setDlgVisibleFlag(false);
    }

    // Clone
    function cloneAction() {
        setDomain(null);        
        // setSectionTitle("");
        // setSectionName("");
        // setMediaDesc("");
        // setMediaUrl("");

        setEditModeFlag(false);
        setSubmitButtonFlag(false);
    }
  
    function sliceText(text, maxChars) {
        let retValue = text;
        if (text.length > maxChars) {
            retValue = text.substring(0, (maxChars - 1));
            retValue += " ...";
        }
        return retValue;
    }
  
    
    async function organizeService() {
        // console.log("In organizeService(): filteredRecords is: ", filteredRecords);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (filteredRecords && filteredRecords.length > 0) {
            // console.log("In organizeService(): Validation passed.");

            // Get user timezone
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const timestamp = moment.tz(Date.now(), timeZone);
            const items = [];

            filteredRecords.map((record, index) => {
                let key = {
                    PagePK: record.PagePK,
                    PageSK: record.PageSK,
                };
                // console.log("In updateGuestBookService(): key is: ", key);

                // ConditionExpression: "attribute_exists(resourceCode)",
                let conditionExpression = "";

                // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
                let updateExpression =
                    "SET " +
                    "UniqueAttribute = :uniqueAttribute, " +

                    "SectionSeqNumber = :sectionSeqNumber, " +

                    "ModifiedBy = :modifiedBy, " + 
                    "UpdatedAt = :updatedAt";
        
                let expressionAttributeValues = {
                    ":uniqueAttribute": key,

                    ":sectionSeqNumber": index + 1,

                    ":modifiedBy": appContext.userName,
                    ":updatedAt": timestamp,
                };
                // console.log('In updateGuestBookService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

                conditionExpression = "attribute_exists(UniqueAttribute)";

                let item = {
                    key: key,
                    conditionExpression: conditionExpression,
                    updateExpression: updateExpression,
                    expressionAttributeValues: expressionAttributeValues,
                };
                items.push(item);
            });
            // console.log("In organizeService(): items is: ", items);

            let params = {
                apiName: "PagesAPI",
                path: "/dynamodb/pages/customBatchUpdate",
                payload: {
                    updateItems: items,
                },
            };
            // console.log('In organizeService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In organizeService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In organizeService(): response is: ', JSON.stringify(response, null, 2));
                setShowDetailsFormFlag(false);
                setUploadFlag(false);
                setOrderListFlag(false);

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);

                await fetchPageSectionRecords(webPage);
                setShowListFlag(true);
            }).catch((err) => {
                console.error("In organizeService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }

    }

    //------------------
    // For OrderList
    //------------------

    function handleFilteredRecords(event) {
        setFilteredRecords(event.value);
    }

    async function handleOrderListWebPageChange(event, item) {
        event.preventDefault();
        event.persist();
        // console.log("In handleOrderListWebPageChange(): item is ", item);
        // console.log("In handleOrderListWebPageChange(): pageSectionRecords is ", pageSectionRecords);

        setFilteredRecords([]);
        setSubmitButtonFlag(false);
        
        setWebPage(item);

        await fetchPageSectionsForPage(item);

        // if (!lodash.isEmpty(item) && pageSectionRecords && pageSectionRecords.length > 0) {
        //     let filteredRecs = pageSectionRecords.filter(record => record.PageId === item.value);
        //     // setFilteredRecords(filteredRecords);
        //     if (filteredRecs && filteredRecs.length > 0) {
        //         filteredRecs.sort((a, b) => (a.SectionSeqNumber > b.SectionSeqNumber ? 1 : -1));
        //         setFilteredRecords(filteredRecs);
        //         setSubmitButtonFlag(true);
        //     }
        // }
    }
    
    function orderListTemplate(record) {
        return (
            <Grid container justify="flex-start" style={{width: '100% !important', display: 'flex'}}>
                <Grid item xs={12} sm={12}>
                    <Grid container justify="flex-start">
                            <Grid item xs={1} sm={1} style={{textAlign: 'left'}}>
                                <Typography variant="caption" style={{textAlign: 'left'}}>
                                    {record.SectionSeqNumber}.
                                </Typography>
                            </Grid>

                            {isMobileView ?
                                <>
                                    <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {record.SectionTitle}
                                        </Typography>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item xs={2} sm={2} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {sectionTitleLabel}:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sm={9} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {record.SectionTitle}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                    </Grid>
                </Grid>

                {record.SectionDesc && 
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="flex-start">
                                <Grid item xs={1} sm={1} style={{textAlign: 'left'}}>
                                    <Typography variant="caption" style={{textAlign: 'left'}}>
                                        {""}
                                    </Typography>
                                </Grid>

                                {isMobileView ?
                                <>
                                    <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {record.SectionDesc}
                                        </Typography>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item xs={2} sm={2} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {descLabel}:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} sm={9} style={{textAlign: 'left'}}>
                                        <Typography variant="caption" style={{textAlign: 'left'}}>
                                            {record.SectionDesc}
                                        </Typography>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                }
            </Grid>
        );
    }
                                        
    return (
        <div dir={direction} className={clsx(classes.root, classes.divWrapper)}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {renderDomFlag &&
                <>
                    {/* { !appContext.signedIn && <Redirect to='/signIn' />} */}
                    <CssBaseline />
                    <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />

                    {isDeskTopView && 
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
                            <Container component="div" className={classes.main} maxWidth="lg">
                                <StyledPaper elevation={3} className={classes.paper}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h5" color="primary" style={{ textAlign: "center" }}>
                                                {managePageSectionsLabel}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                                                {requiredFields}
                                            </Typography>
                                        </Grid>

                                        {globalErrorFlag && (
                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={24} />
                                                <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                {showListFlag &&
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                                *{languageLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="languageId"
                                                                            value={language}
                                                                            options={languages}
                                                                            getOptionLabel={option => option.languageName}
                                                                            getOptionSelected={option => option.languageName}
                                                                            onChange={handleListLanguageChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={languagePrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={languageErrFlag}
                                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                                *{domainLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="domainId"
                                                                            value={domain}
                                                                            options={domainOptions}
                                                                            getOptionLabel={option => option ? option.text : ""}
                                                                            getOptionSelected={option => option ? option.text : ""}
                                                                            onChange={handleListDomainChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            // disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={domainPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={domainErrFlag}
                                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={webPageErrFlag}>
                                                                        <label htmlFor="webPageId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={webPageErrFlag ? "secondary" : "inherit"}>
                                                                                *{webPageLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="webPageId"
                                                                            value={webPage}
                                                                            options={webPageOptions}
                                                                            getOptionLabel={option => option ? option.text : ""}
                                                                            getOptionSelected={option => option ? option.text : ""}
                                                                            onChange={handleWebPageChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={webPagePrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={webPageErrFlag}
                                                                                    helperText={webPageErrFlag ? webPageErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            {pageSectionRecords && pageSectionRecords.length > 0 ?
                                                                <>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <LineSpacing value={12} />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-start">
                                                                                    <Button variant="contained" size="large" disabled={selectedTableRows && selectedTableRows.length > 0 ? false : true} color="primary" onClick={() => showAlertDialog(null, "multiple")}>
                                                                                        <DeleteIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {deleteLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={2} sm={2}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={orderListAction}>
                                                                                        <ImportExportIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {organizeLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={2} sm={2}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={convertAction}>
                                                                                        <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {convertLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={2} sm={2}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={uploadAction}>
                                                                                        <CloudUploadIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {uploadLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-end">
                                                                                    <Button variant="contained" size="large" disabled={false} color="primary" onClick={createMoreAction}>
                                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {createMoreLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12}>
                                                                        <LineSpacing value={8} />
                                                                    </Grid>
                                                                    
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12}>
                                                                            <div className="datatable-doc-demo">
                                                                                <DataTable 
                                                                                    value={pageSectionRecords}
                                                                                    resizableColumns={true} 
                                                                                    columnResizeMode={"fit"}
                                                                                    sortMode={"multiple"}
                                                                                    responsive={true}
                                                                                    reorderableColumns={true}

                                                                                    // selectionMode={"multiple"}
                                                                                    selection={selectedTableRows}
                                                                                    onSelectionChange={handleMultipleSelectionChange}

                                                                                    // paginator={visibleRowsCount > 10 ? paginatorFlag : !paginatorFlag} 
                                                                                    paginator={pageSectionRecords && pageSectionRecords.length > 10} 
                                                                                    // paginatorLeft={paginatorLeft}

                                                                                    paginatorTemplate={paginatorTemplate}
                                                                                    currentPageReportTemplate={"{first}" + " " + toLabel + " " + "{last}" + " " + ofLabel + " " + "{totalRecords}" + " " + recordsLabel} 
                                                                                    rows={visibleRowsCount} 
                                                                                    rowsPerPageOptions={rowsPerPageOptions}
                                                                                    // className="p-datatable-responsive-cob"
                                                                                    // className="p-datatable-striped"
                                                                                    emptyMessage={noRecordsFound}
                                                                                    className={clsx("p-datatable-striped", "p-datatable-customers")}
                                                                                    rowHover 
                                                                                    >

                                                                                    <Column 
                                                                                        header={deleteLabel} 
                                                                                        selectionMode="multiple" 
                                                                                        style={{width:'5em', textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="WebPageText" 
                                                                                        header={gridPageName} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="SectionName" 
                                                                                        header={gridSectionName} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="SectionTitle" 
                                                                                        header={gridTitle} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains"
                                                                                        style={{textAlign:'center'}} 
                                                                                    />
                                                                                    <Column 
                                                                                        field="Display" 
                                                                                        header={gridContent} 
                                                                                        // sortable={true} 
                                                                                        // filter={true} 
                                                                                        // filterMatchMode="contains" 
                                                                                        body={contentTemplate} 
                                                                                    />
                                                                                    {/* <Column 
                                                                                        field="FormattedMediaCaption" 
                                                                                        header={mediaCaptionLabel} 
                                                                                        sortable={true} 
                                                                                        sortFunction={sortMediaCaption}
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="FormattedMediaDesc" 
                                                                                        header={mediaDescLabel} 
                                                                                        sortable={true} 
                                                                                        sortFunction={sortMediaDesc}
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    /> */}
                                                                                    <Column 
                                                                                        field="deleteIcon" 
                                                                                        header={deleteLabel} 
                                                                                        body={deleteIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="editIcon" 
                                                                                        header={editLabel} 
                                                                                        body={editIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                </DataTable>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            :
                                                                <>
                                                                    {noRecordFoundFlag &&
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={12} sm={12}>
                                                                                <LineSpacing value={12} />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <Typography variant="body1" color="secondary">
                                                                                    {noPageSectionRecordsFound}
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <LineSpacing value={12} />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <Button variant="contained" size="large" disabled={false} color="primary" onClick={createPriceAction}>
                                                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                    {createPageSectionLabel}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {showDetailsFormFlag && 
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={6}>
                                                                    <Grid container justify="flex-start">
                                                                        <Typography variant="h6" color="primary">
                                                                            {createPageSectionLabel}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={12} sm={6}>
                                                                    {editModeFlag &&
                                                                        <Grid container justify="center">
                                                                            <Button color="primary" onClick={cloneAction}>
                                                                                <Typography variant="subtitle1" color="primary">
                                                                                    {cloneRecordLabel}
                                                                                </Typography>
                                                                            </Button>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                                *{languageLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="languageId"
                                                                            value={language}
                                                                            options={languages}
                                                                            getOptionLabel={option => option ? option.languageName : ""}
                                                                            getOptionSelected={option => option ? option.languageName : ""}
                                                                            onChange={handleLanguageChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={languagePrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={languageErrFlag}
                                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                                *{domainLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="domainId"
                                                                            value={domain}
                                                                            options={domainOptions}
                                                                            getOptionLabel={option => option ? option.text : ""}
                                                                            getOptionSelected={option => option ? option.text : ""}
                                                                            onChange={handleDomainChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={domainPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={domainErrFlag}
                                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={webPageErrFlag}>
                                                                        <label htmlFor="webPageId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={webPageErrFlag ? "secondary" : "inherit"}>
                                                                                *{webPageLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="webPageId"
                                                                            value={webPage}
                                                                            options={webPageOptions}
                                                                            getOptionLabel={option => option ? option.text : ""}
                                                                            getOptionSelected={option => option ? option.text : ""}
                                                                            onChange={handleWebPageChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={webPagePrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={webPageErrFlag}
                                                                                    helperText={webPageErrFlag ? webPageErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={sectionTitleErrFlag}>
                                                                                <label htmlFor="sectionTitleId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={sectionTitleErrFlag ? "secondary" : "inherit"}>
                                                                                        *{sectionTitleLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextField
                                                                                    id="sectionTitleId"
                                                                                    value={sectionTitle}
                                                                                    placeholder={sectionTitlePrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    fullWidth
                                                                                    type={"text"}
                                                                                    error={sectionTitleErrFlag}
                                                                                    margin="none"
                                                                                    variant="outlined"
                                                                                    helperText={sectionTitleErrFlag ? sectionTitleErrMsg : sectionTitleMsg}
                                                                                    // InputProps={{
                                                                                    //     startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                                                                    // }}
                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={sectionNameErrFlag}>
                                                                                <label htmlFor="sectionNameId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={sectionNameErrFlag ? "secondary" : "inherit"}>
                                                                                        *{sectionNameLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextField
                                                                                    id="sectionNameId"
                                                                                    value={sectionName}
                                                                                    placeholder={sectionNamePrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    fullWidth
                                                                                    type={"text"}
                                                                                    error={sectionNameErrFlag}
                                                                                    margin="none"
                                                                                    variant="outlined"
                                                                                    helperText={sectionNameErrFlag ? sectionNameErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                                                                    // }}
                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={sectionDescErrFlag}>
                                                                                <label htmlFor="sectionDescId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={sectionDescErrFlag ? "secondary" : "inherit"}>
                                                                                        {sectionDescLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextareaAutosize
                                                                                    id="sectionDescId"
                                                                                    aria-label={sectionDescLabel}
                                                                                    rows={1.5}
                                                                                    rowsMax={6}
                                                                                    value={sectionDesc}
                                                                                    placeholder={sectionDescPrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    // autoFocus={true}
                                                                                    margin="none"
                                                                                    className={classes.textArea}
                                                                                    maxLength={textMaxLength}
                                                                                />
                                                                                <FormHelperText>{sectionDescErrFlag ? sectionDescErrMsg : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={textContentErrFlag}>
                                                                                <label htmlFor="textContentId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={textContentErrFlag ? "secondary" : "inherit"}>
                                                                                        *{textContentLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextareaAutosize
                                                                                    id="textContentId"
                                                                                    aria-label={textContentLabel}
                                                                                    rows={1.5}
                                                                                    rowsMax={6}
                                                                                    value={textContent}
                                                                                    placeholder={textContentPrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    // autoFocus={true}
                                                                                    margin="none"
                                                                                    className={classes.textArea}
                                                                                    maxLength={textMaxLength}
                                                                                />
                                                                                <FormHelperText>{textContentErrFlag ? textContentErrMsg : textContentMsg}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={mediaCaptionErrFlag}>
                                                                                <label htmlFor="mediaCaptionId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={mediaCaptionErrFlag ? "secondary" : "inherit"}>
                                                                                        {mediaCaptionLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextField
                                                                                    id="mediaCaptionId"
                                                                                    value={mediaCaption}
                                                                                    placeholder={mediaCaptionPrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    fullWidth
                                                                                    type={"text"}
                                                                                    error={mediaCaptionErrFlag}
                                                                                    margin="none"
                                                                                    variant="outlined"
                                                                                    helperText={mediaCaptionErrFlag ? mediaCaptionErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                                                                    // }}
                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={mediaDescErrFlag}>
                                                                                <label htmlFor="mediaDescId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={mediaDescErrFlag ? "secondary" : "inherit"}>
                                                                                        {mediaDescLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextareaAutosize
                                                                                    id="mediaDescId"
                                                                                    aria-label={mediaDescLabel}
                                                                                    rows={1.5}
                                                                                    rowsMax={6}
                                                                                    value={mediaDesc}
                                                                                    placeholder={mediaDescPrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    // autoFocus={true}
                                                                                    margin="none"
                                                                                    className={classes.textArea}
                                                                                    maxLength={textMaxLength}
                                                                                />
                                                                                <FormHelperText>{mediaDescErrFlag ? mediaDescErrMsg : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={mediaUrlErrFlag}>
                                                                        <label htmlFor="mediaUrlId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={mediaUrlErrFlag ? "secondary" : "inherit"}>
                                                                                {mediaUrlLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <OutlinedInput
                                                                            id="mediaUrlId"
                                                                            value={mediaUrl}
                                                                            placeholder={mediaUrlPrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={mediaUrlErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            endAdornment={
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        aria-label={mediaUrlPrompt}
                                                                                        onClick={selectImageAction}
                                                                                    >
                                                                                        <ImageSearchIcon />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            }
                                                                        />
                                                                        <FormHelperText>{mediaUrlErrFlag ? mediaUrlErrMsg : ""}</FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={editorContentErrFlag}>
                                                                                <label htmlFor="editorContentId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={editorContentErrFlag ? "secondary" : "inherit"}>
                                                                                        *{editorContentLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <FroalaCommonEditor
                                                                                    editorId={"editorContentId"}
                                                                                    editorRef={editorRef2}
                                                                                    // editorType={"minimal"} // Full Editor when not set
                                                                                    placeholderPrompt={editorContentPrompt}
                                                                                    editorMaxChars={editorMaxChars}
                                                                                    editorModel={editorContent}
                                                                                    modelChangeCallback={handleEditorContentChange}
                                                                                    language={language}
                                                                                    domain={domain}
                                                                                />
                                                                                <FormHelperText>{editorContentErrFlag ? editorContentErrMsg : editorContentMsg}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        
                                                        <Grid item xs={12} sm={6}>
                                                            {mediaUrl &&
                                                                <Grid container justify="center">
                                                                    <img src={mediaUrl} style={{height: '96px'}} />
                                                                </Grid>
                                                            }           
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={buttonFormAction}>
                                                                    {editModeFlag ?
                                                                    <>
                                                                        <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {updatePageSectionLabel} 
                                                                    </>
                                                                    : 
                                                                    <>
                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {createPageSectionLabel}
                                                                    </>
                                                                    }
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {uploadFlag && 
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeUploadForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Container component="div" maxWidth="lg">
                                                                <Grid container justify="flex-start" className={clsx(classes.divLeftPadding)}>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container justify="flex-start">
                                                                            <Typography variant="h6" color="primary">
                                                                                {uploadFilesLabel}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Container>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <DomainUploadCapture 
                                                                showHeadersFlag={false}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                {convertFlag &&
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Container component="div" maxWidth="lg">
                                                                <Grid container justify="flex-start" className={clsx(classes.divLeftPadding)}>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container justify="flex-start">
                                                                            <Typography variant="h6" color="primary">
                                                                                {convertPageSectionsLabel}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Container>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <WebPageConverter 
                                                                showHeadersFlag={false}
                                                                referrer={"ManagePageSections"}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                {orderListFlag &&
                                                    <Container component="div" className={classes.main} maxWidth="md">
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeOrderListForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-start">
                                                                <Typography variant="h6" color="primary">
                                                                    {organizePageSectionsLabel}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={webPageErrFlag}>
                                                                        <label htmlFor="webPageId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={webPageErrFlag ? "secondary" : "inherit"}>
                                                                                *{webPageLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="webPageId"
                                                                            value={webPage}
                                                                            options={webPageOptions}
                                                                            getOptionLabel={option => option ? option.text : ""}
                                                                            getOptionSelected={option => option ? option.text : ""}
                                                                            onChange={handleOrderListWebPageChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={webPagePrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={webPageErrFlag}
                                                                                    helperText={webPageErrFlag ? webPageErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center" style={{width: '100% !important'}}>
                                                                <div style={{minWidth: '100%'}}>
                                                                    <OrderList 
                                                                        value={filteredRecords} 
                                                                        header={availablePageSectionsLabel} 
                                                                        dragdrop={dragdropFlag}
                                                                        listStyle={{width: '100% !important', height:'auto'}} 
                                                                        dataKey="PriceId"
                                                                        itemTemplate={orderListTemplate} 
                                                                        onChange={(e) => handleFilteredRecords(e)}
                                                                        style={{width: '100% !important', display: 'flex'}}
                                                                        >
                                                                    </OrderList>
                                                                </div>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={24} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={organizeService}>
                                                                    <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                    {saveLabel} 
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Container>
                                                }

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={18} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledPaper>
                            </Container>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={48} />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    }

                    {isTabletView && 
                        <ManagePageSectionsTv
                            managePageSectionsLabel={managePageSectionsLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            domainLabel={domainLabel}
                            domainPrompt={domainPrompt}
                            domainErrMsg={domainErrMsg}
                                
                            textContentLabel={textContentLabel}
                            textContentPrompt={textContentPrompt}
                            textContentErrMsg={textContentErrMsg}
                            textContentMsg={textContentMsg}
                            
                            sectionTitleLabel={sectionTitleLabel}
                            sectionTitlePrompt={sectionTitlePrompt}
                            sectionTitleErrMsg={sectionTitleErrMsg}
                            sectionTitleMsg={sectionTitleMsg}
                            // selectTitlePrompt={selectTitlePrompt}
                                
                            mediaCaptionLabel={mediaCaptionLabel}
                            mediaCaptionPrompt={mediaCaptionPrompt}
                            mediaCaptionErrMsg={mediaCaptionErrMsg}
                                
                            mediaDescLabel={mediaDescLabel}
                            mediaDescPrompt={mediaDescPrompt}
                            mediaDescErrMsg={mediaDescErrMsg}
        
                            mediaUrlLabel={mediaUrlLabel}
                            mediaUrlPrompt={mediaUrlPrompt}
                            mediaUrlErrMsg={mediaUrlErrMsg}
                    
                            // mediaCaptionLabel={mediaCaptionLabel}
                            // mediaCaptionPrompt={mediaCaptionPrompt}
                            // mediaCaptionErrMsg={mediaCaptionErrMsg}
                    
                            // mediaDescLabel={mediaDescLabel}
                            // mediaDescPrompt={mediaDescPrompt}
                            // mediaDescErrMsg={mediaDescErrMsg}
                    
                            editorContentLabel={editorContentLabel}
                            editorContentPrompt={editorContentPrompt}
                            editorContentErrMsg={editorContentErrMsg}
                            editorContentMsg={editorContentMsg}
                    
                            webPageLabel={webPageLabel}
                            webPagePrompt={webPagePrompt}
                            webPageErrMsg={webPageErrMsg}
                            gridPageName={gridPageName}
                            // editLabel={editLabel}
                            // deleteLabel={deleteLabel}
                            
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            moreLabel={moreLabel}
                            uploadLabel={uploadLabel}
                            createPageSectionLabel={createPageSectionLabel}
                            updatePageSectionLabel={updatePageSectionLabel}
                            createLabel={createLabel}
                            closeLabel={closeLabel}
                            noPageSectionRecordsFound={noPageSectionRecordsFound} 
                            descLabel={descLabel}
                    
                            gridTitle={gridTitle}
                            gridSectionName={gridSectionName}
                            gridContent={gridContent}
                            lastUpdateLabel={lastUpdateLabel}
                            convertLabel={convertLabel}
                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            // moreLabel={moreLabel}
                            // cloneEventLabel={cloneEventLabel}
                            cancelLabel={cancelLabel}

                            clearLabel={clearLabel}
                            okLabel={clearLabel}

                            cloneRecordLabel={cloneRecordLabel}
                            cloneAction={cloneAction}

                            convertPageSectionsLabel={convertPageSectionsLabel}
                            // createPageSectionLabel={createPageSectionLabel}
                            uploadFilesLabel={uploadFilesLabel}

                            sectionNameLabel={sectionNameLabel}
                            sectionNamePrompt={sectionNamePrompt}
                            sectionNameErrMsg={sectionNameErrMsg}
                    
                            sectionDescLabel={sectionDescLabel}
                            sectionDescPrompt={sectionDescPrompt}
                            sectionDescErrMsg={sectionDescErrMsg}
                                                                                                            
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showListFlag={showListFlag}
                            webPageRecords={webPageRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noRecordFoundFlag={noRecordFoundFlag}
                            createPriceAction={createPriceAction}
                            showDetailsFormFlag={showDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleListLanguageChange={handleListLanguageChange}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}
                            domain={domain}
                            domainOptions={domainOptions}
                            handleListDomainChange={handleListDomainChange}
                            handleDomainChange={handleDomainChange}
                            webPageErrFlag={webPageErrFlag}
                            webPage={webPage}
                            webPageOptions={webPageOptions}
                            handleWebPageChange={handleWebPageChange}
                            domainErrFlag={domainErrFlag}
                            handleChange={handleChange}
                            selectImageAction={selectImageAction}
                            textContentErrFlag={textContentErrFlag}
                            textContent={textContent}
                            sectionTitleErrFlag={sectionTitleErrFlag}
                            sectionTitle={sectionTitle}
                            mediaCaptionErrFlag={mediaCaptionErrFlag}
                            mediaCaption={mediaCaption}
                            editorContentErrFlag={editorContentErrFlag}
                            editorContent={editorContent}
                            textMaxLength={textMaxLength}
                            mediaUrlErrFlag={mediaUrlErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            mediaUrl={mediaUrl}
                            submitButtonFlag={submitButtonFlag}
                            buttonFormAction={buttonFormAction}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            closeConvertForm={closeConvertForm}

                            // mediaCaptionErrFlag={mediaCaptionErrFlag}
                            // mediaCaption={mediaCaption}

                            mediaDescErrFlag={mediaDescErrFlag}
                            mediaDesc={mediaDesc}
        
                            editorRef2={editorRef2}
                            handleEditorContentChange={handleEditorContentChange}
                            timeBeforeErrFlag={timeBeforeErrFlag}
                            mediaDescBeforeErrMsg={mediaDescBeforeErrMsg}

                            organizeLabel={organizeLabel}
                            organizePageSectionsLabel={organizePageSectionsLabel}
                            availablePageSectionsLabel={availablePageSectionsLabel}
                            saveLabel={saveLabel}
                            orderListAction={orderListAction}

                            // organizeLabel={organizeLabel}
                            // organizePageSectionsLabel={organizePageSectionsLabel}
                            // availablePageSectionsLabel={availablePageSectionsLabel}
                            // saveLabel={saveLabel}
                            // orderListAction={orderListAction}
                            globalErrorFlag={globalErrorFlag}
                            globalErrorMsg={globalErrorMsg}
                            pageSectionRecords={pageSectionRecords}
                            // gridCategory={gridCategory}
                            // gridPageTags={gridPageTags}
                            // pageHeaderLabel={pageHeaderLabel}
                            sectionNameErrFlag={sectionNameErrFlag}
                            sectionName={sectionName}
                            sectionDescErrFlag={sectionDescErrFlag}
                            sectionDesc={sectionDesc}
                            orderListFlag={orderListFlag}
                            closeOrderListForm={closeOrderListForm}
                            handleOrderListWebPageChange={handleOrderListWebPageChange}
                            filteredRecords={filteredRecords}
                            dragdropFlag={dragdropFlag}
                            orderListTemplate={orderListTemplate}
                            handleFilteredRecords={handleFilteredRecords}
                            organizeService={organizeService}
                        />
                    }

                    {isMobileView && 
                        <ManagePageSectionsMv
                            managePageSectionsLabel={managePageSectionsLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            domainLabel={domainLabel}
                            domainPrompt={domainPrompt}
                            domainErrMsg={domainErrMsg}
                                
                            textContentLabel={textContentLabel}
                            textContentPrompt={textContentPrompt}
                            textContentErrMsg={textContentErrMsg}
                            textContentMsg={textContentMsg}
                    
                            // mediaCaptionLabel={mediaCaptionLabel}
                            // mediaCaptionPrompt={mediaCaptionPrompt}
                            // mediaCaptionErrMsg={mediaCaptionErrMsg}
                    
                            // mediaDescLabel={mediaDescLabel}
                            // mediaDescPrompt={mediaDescPrompt}
                            // mediaDescErrMsg={mediaDescErrMsg}
                                
                            mediaCaptionLabel={mediaCaptionLabel}
                            mediaCaptionPrompt={mediaCaptionPrompt}
                            mediaCaptionErrMsg={mediaCaptionErrMsg}
                                
                            mediaDescLabel={mediaDescLabel}
                            mediaDescPrompt={mediaDescPrompt}
                            mediaDescErrMsg={mediaDescErrMsg}
        
                            mediaUrlLabel={mediaUrlLabel}
                            mediaUrlPrompt={mediaUrlPrompt}
                            mediaUrlErrMsg={mediaUrlErrMsg}
                            
                            sectionTitleLabel={sectionTitleLabel}
                            sectionTitlePrompt={sectionTitlePrompt}
                            sectionTitleErrMsg={sectionTitleErrMsg}
                            sectionTitleMsg={sectionTitleMsg}
                            // selectTitlePrompt={selectTitlePrompt}
                    
                            editorContentLabel={editorContentLabel}
                            editorContentPrompt={editorContentPrompt}
                            editorContentErrMsg={editorContentErrMsg}
                            editorContentMsg={editorContentMsg}
                            webPageLabel={webPageLabel}
                            webPagePrompt={webPagePrompt}
                            webPageErrMsg={webPageErrMsg}
                            gridPageName={gridPageName}
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            moreLabel={moreLabel}
                            cancelLabel={cancelLabel}

                            yesLabel={yesLabel}
                            deleteRequested={deleteRequested}
                            areYouSureMsg={areYouSureMsg}
                            requestedToDeleteMsg={requestedToDeleteMsg}
                            uploadLabel={uploadLabel}
                            createPageSectionLabel={createPageSectionLabel}
                            updatePageSectionLabel={updatePageSectionLabel}
                            // pageSectionLabel={pageSectionLabel}
                            // createMoreLabel={createMoreLabel}
                            createLabel={createLabel}
                            closeLabel={closeLabel}
                            noPageSectionRecordsFound={noPageSectionRecordsFound} 
                            descLabel={descLabel}
                    
                            gridTitle={gridTitle}
                            gridSectionName={gridSectionName}
                            gridContent={gridContent}
                            lastUpdateLabel={lastUpdateLabel}
                            // gridMediaCaption={gridMediaCaption}
                            // gridMediaUrl={gridMediaUrl}
                        
                            convertLabel={convertLabel}
                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            // moreLabel={moreLabel}

                            clearLabel={clearLabel}
                            okLabel={clearLabel}

                            cloneRecordLabel={cloneRecordLabel}
                            cloneAction={cloneAction}

                            convertPageSectionsLabel={convertPageSectionsLabel}
                            // createPageSectionLabel={createPageSectionLabel}
                            uploadFilesLabel={uploadFilesLabel}

                            sectionNameLabel={sectionNameLabel}
                            sectionNamePrompt={sectionNamePrompt}
                            sectionNameErrMsg={sectionNameErrMsg}
                    
                            sectionDescLabel={sectionDescLabel}
                            sectionDescPrompt={sectionDescPrompt}
                            sectionDescErrMsg={sectionDescErrMsg}
                            
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showListFlag={showListFlag}
                            webPageRecords={webPageRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noRecordFoundFlag={noRecordFoundFlag}
                            createPriceAction={createPriceAction}
                            showDetailsFormFlag={showDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleListLanguageChange={handleListLanguageChange}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}
                            domain={domain}
                            domainOptions={domainOptions}
                            handleListDomainChange={handleListDomainChange}
                            handleDomainChange={handleDomainChange}
                            webPageErrFlag={webPageErrFlag}
                            webPage={webPage}
                            webPageOptions={webPageOptions}
                            handleWebPageChange={handleWebPageChange}
                            domainErrFlag={domainErrFlag}
                            handleChange={handleChange}
                            selectImageAction={selectImageAction}
                            textContentErrFlag={textContentErrFlag}
                            textContent={textContent}
                            sectionTitleErrFlag={sectionTitleErrFlag}
                            sectionTitle={sectionTitle}
                            mediaCaptionErrFlag={mediaCaptionErrFlag}
                            mediaCaption={mediaCaption}
                            editorContentErrFlag={editorContentErrFlag}
                            editorContent={editorContent}
                            textMaxLength={textMaxLength}
                            mediaUrlErrFlag={mediaUrlErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            mediaUrl={mediaUrl}
                            submitButtonFlag={submitButtonFlag}
                            buttonFormAction={buttonFormAction}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            closeConvertForm={closeConvertForm}

                            // mediaCaptionErrFlag={mediaCaptionErrFlag}
                            // mediaCaption={mediaCaption}

                            mediaDescErrFlag={mediaDescErrFlag}
                            mediaDesc={mediaDesc}
        
                            editorRef2={editorRef2}
                            handleEditorContentChange={handleEditorContentChange}
                            timeBeforeErrFlag={timeBeforeErrFlag}
                            mediaDescBeforeErrMsg={mediaDescBeforeErrMsg}

                            organizeLabel={organizeLabel}
                            organizePageSectionsLabel={organizePageSectionsLabel}
                            availablePageSectionsLabel={availablePageSectionsLabel}
                            saveLabel={saveLabel}
                            orderListAction={orderListAction}

                            // organizeLabel={organizeLabel}
                            // organizePageSectionsLabel={organizePageSectionsLabel}
                            // availablePageSectionsLabel={availablePageSectionsLabel}
                            // saveLabel={saveLabel}
                            // orderListAction={orderListAction}
                            globalErrorFlag={globalErrorFlag}
                            globalErrorMsg={globalErrorMsg}
                            pageSectionRecords={pageSectionRecords}
                            // gridCategory={gridCategory}
                            // gridPageTags={gridPageTags}
                            // pageHeaderLabel={pageHeaderLabel}
                            sectionNameErrFlag={sectionNameErrFlag}
                            sectionName={sectionName}
                            sectionDescErrFlag={sectionDescErrFlag}
                            sectionDesc={sectionDesc}
                            orderListFlag={orderListFlag}
                            closeOrderListForm={closeOrderListForm}
                            handleOrderListWebPageChange={handleOrderListWebPageChange}
                            filteredRecords={filteredRecords}
                            dragdropFlag={dragdropFlag}
                            orderListTemplate={orderListTemplate}
                            handleFilteredRecords={handleFilteredRecords}
                            organizeService={organizeService}
                        />
                    }
                </>
            }

            <Dialog 
                id="modalDlgId"
                rtl={appContext.direction === 'rtl'}
                showHeader={true}
                header={dialogHeader}
                visible={dlgVisibleFlag} 
                maximizable={true}
                maximized={(isTabletView || isMobileView) ? true : false}
                modal={true} 
                onHide={hideModalDlg}
                >
                <Container component="div" className={classes.modalDlgMain} maxWidth="md">
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="flex-start" style={{color: '#000'}}>
                            <FroalaEditorView model={dialogData}/>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>

            <CommonMediaSelector
                referrer={"ManagePageSections"}
                mediaLanguageCode={language ? language.languageCode : null}
                mediaDomainName={domain ? domain.value : null}
                mediaActionFlag={showDetailsFormFlag}
                queryType={"media"} // media, audios or videos
                
                dialogOpenFlag={dialogOpenFlag}
                onRowSelectedCallback={onRowSelectedCallback}
                dialogCloseCallback={dialogCloseCallback}
            />

            {alertOpen && 
                <AlertDialog
                    alertOpen={alertOpen}
                    alertClose={alertClose}
                    alertClickAgree={alertClickAgree}
                    alertClickDisagree={alertClickDisagree}

                    dialogTitle={deleteRequested}
                    dialogMainContent={requestedToDeleteMsg}
                    dialogSubContent={dialogSubContent}
                    dialogQuestion={areYouSureMsg}
                    yesLabel={yesLabel}
                    noLabel={cancelLabel}
                />
            }

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={8000}
                onClose={snackbarOnClose}
                ContentProps={{ "aria-describedby": "message-id" }}
                message={<span id="message-id">{snackbarsMsg}</span>}
                variant={snackbarsVariant}
            />

            <footer className={classes.footer}>
                <div>
                    <PageFooter isSignedIn={appContext.signedIn} />
                </div>
            </footer>
        </div>
    )
}

export default ManagePageSections;
