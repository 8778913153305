import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from "react";
import styled, { css } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { NavLink, Link as RouterLink, Redirect } from "react-router-dom";
// import Link from '@material-ui/core/Link';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from '@material-ui/core/FormControl';
// import SaveIcon from "@material-ui/icons/Save";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
// import Visibility from "@material-ui/icons/Visibility";
// import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import UpdateIcon from '@material-ui/icons/Update';
import AddIcon from '@material-ui/icons/Add';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Container from "@material-ui/core/Container";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import CheckIcon from '@material-ui/icons/Check';
// import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import InputAdornment from "@material-ui/core/InputAdornment";
import TranslateIcon from '@material-ui/icons/Translate';
import Paper from "@material-ui/core/Paper";

// react-responsive
import { useMediaQuery } from 'react-responsive';

// moment
import moment from "moment";

// Lodash
import lodash from "lodash";

// currency-symbol-map
import getSymbolFromCurrency from 'currency-symbol-map';


// react-media
// import Media from "react-media";

// Validator
// import validator from 'validator';
// import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";
// import isMobilePhone from "validator/lib/isMobilePhone";

//---------------------
// For Primereact
//---------------------

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

// For Primereact
import { Dialog } from 'primereact/dialog';

// Froala
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

// For DatePicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Amplify
import { API } from "aws-amplify";
// import { AmplifyTheme   } from 'aws-amplify-react';

// AppContext
import AppContext from "../../config/AppContext";

import LineSpacing from "../../components/misc/LineSpacing";
// import FetchS3Object from "../../components/media/FetchS3Object";
import FroalaCommonEditor from "../../components/editor/FroalaCommonEditor";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";
import AlertDialog from "../../components/dialogs/AlertDialog";
import CommonMediaSelector from "../../components/dialogs/CommonMediaSelector";
import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";
import DomainUploadCapture from "../../components/upload/DomainUploadCapture";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";
import GenerateUniqueName from "../../utils/GenerateUniqueName";
// import GenerateUlid from "../../utils/GenerateUlid";
// import FormatDateTime from "../../../../../utils/moment/FormatDateTime";
import FormatTimezoneDates from "../../utils/moment/FormatTimezoneDates";
import FilterArrayFromArray from "../../utils/filter/FilterArrayFromArray";
import FormatNumber from "../../utils/numberFormat/FormatNumber";
import FormatNumberWithOption from "../../utils/numberFormat/FormatNumberWithOption";
import FormatCurrency from "../../utils/numberFormat/FormatCurrency";
import EditorPixelTransform from "../../utils/transform/EditorPixelTransform";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";

import WebPageConverter from "./WebPageConverter";

// Views
import ManageWebPagesTv from "./views/ManageWebPagesTv";
import ManageWebPagesMv from "./views/ManageWebPagesMv";

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100% !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      minHeight: '140vh',
      // backgroundImage: `url(${appContext.storageCfUrl + "assets/bg/background20.png"})`,
      textAlign: "center",
    },
    main: {
        // marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    leftPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "left",
    },
    leftPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(3),
        textAlign: "left",
        // cursor: 'pointer',
    },
    rightPanelSubItems: {
        width: "100%",
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        textAlign: "left",
    },
    rightPanelMainItems: {
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    textField: {
        width: "100%",
    },
    iconLarge: {
        fontSize: 36,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalAlignLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formControl: {
        display: "flex",
        textAlign: "left",
    },
    fixedSize: {
        tableLayout: 'fixed',
        wordWrap: 'breakWord',
    },
    textArea: {
        padding: theme.spacing(1.5),
        borderRadius: "5px", // "5px",
    },
    divWrapper: {
        width: '100% !important',
    },
    divLeftPadding: {
        width: '100% !important',
        paddingLeft: theme.spacing(1),
        // paddingRight: theme.spacing(1),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "1rem" /* Footer height */,

        width: "100% !important",
        marginTop: "auto",
        // backgroundColor: '#fff',
        textAlign: "center",
    },
    paper: {
        minWidth: "300px",
        marginTop: theme.spacing(2),
        alignItems: "center",
        padding: "10px 30px 10px 30px !important",
        textAlign: "center",
    },
}));

let pageLabels = {
    manageWebPagesLabel: "Manage web pages",
    requiredFields: "* Required fields",

    languageLabel: "Language",
    languagePrompt: "Select language",
    languageErrMsg: "Language is required",

    domainLabel: "Domain",
    domainPrompt: "Select a domain",
    domainErrMsg: "Domain is required",

    pageCategoryLabel: "Page category",
    pageCategoryPrompt: "Select a page category",
    pageCategoryErrMsg: "Page category is required",

    pageTagsLabel: "Page tags",
    pageTagsPrompt: "Enter page tags",
    pageTagsErrMsg: "Page tags is required",
    pageTagsMsg: "Seperate tags with space.",
    
    pageNameLabel: "Page name",
    pageNamePrompt: "Enter a name for the page",
    pageNameErrMsg: "Page name is required",
    pageNameMsg: "A reference name for identifying this page.",

    pageTitleLabel: "Page title",
    pageTitlePrompt: "Please enter the title",
    pageTitleErrMsg: "Page title is required",

    startTimeLabel: "Start time",
    startTimePrompt: "Select a start time",
    startTimeErrMsg: "Invalid start time",

    endTimeLabel: "End time",
    endTimePrompt: "Select an end time",
    endTimeErrMsg: "Invalid end time",
    endTimeBeforeErrMsg: "End time must be greater or equal to start time",

    pageHeaderLabel: "Header content",
    pageHeaderPrompt: "Enter content to display on page header",
    pageHeaderErrMsg: "Header content is required",
    pageHeaderMsg: "This content will display on the page header",



    // selectTitlePrompt: "Select title",
    
    // startTimeLabel: "Blog start time",
    // startTimePrompt: "Select date & time to publish the blog",
    // startTimeErrMsg: "Blog start time is invalid",

    // liveEventUrlLabel: "Live event URL",
    // liveEventUrlPrompt: "Please enter the live event URL",
    // liveEventUrlErrMsg: "Live event URL is invalid",

    convertWebPagesLabel: "Convert web pages",
    // createWebPageLabel: "Create web page",
    uploadFilesLabel: "Upload files",

    bannerUrlLabel: "Banner URL",
    bannerUrlPrompt: "Select a banner",
    bannerUrlErrMsg: "Banner URL is required",

    // yourTimeZoneMsg: "Your timeZone is",
    // timeZoneLabel: "Time zone",
    // cloneEventLabel: "Clone event",

    webPageLabel: "web page record",
    webPagesLabel: "web page records",
    // inProgressLabel: "In progress",
    // startLabel: "Start",
    // endLabel: "End",
    // notStartedLabel: "Not started",
    // dateCreatedLabel: "Date created",

    editLabel: "Edit",
    deleteLabel: "Delete",

    toLabel: "to",
    ofLabel: "of",
    recordsLabel: "records",
    noRecordsFound: "No records found",

    operationSuccessMsg: "Operation was successful.",
    operationFailureMsg: "Operation failed.",
    duplicateTransMsg: "Duplicate transaction",

    loadingText: "Loading ...",
    noOptionsText: "No options",
    clearText: "Clear",
    closeText: "Close",
    // showLabel: "Show",
    // hideLabel: "Hide",

    cancelLabel: "Cancel",
    clearLabel: "Clear",
    okLabel: "Ok",

    yesLabel: "Yes",
    deleteRequested: "Delete requested",
    areYouSureMsg: "Are you sure?",
    requestedToDeleteMsg: "You have requested to delete",
  
    createWebPageLabel: "Create web page",
    updateWebPageLabel: "Update web page",

    createMoreLabel: "Create more",
    createLabel: "Create",
    cloneRecordLabel: "Clone this record",
    closeLabel: "Close",
    noWebPageRecordsFound: "No web page records found.", 
    // startEventInfoMsg: "This event can't be started now.",    
            
    gridCategory: "Category",
    gridPageName: "Name",
    gridPageTitle: "Title",
    gridPageTags: "Tags",
    lastUpdateLabel: "Last update",

    // gridStartTime: "Start time",
    // gridBannerUrl: "Banner URL",
    
    convertLabel: "Convert",

    // sourceLanguageLabel: "Source language",
    // sourceLanguagePrompt: "Select source language",
    // sourceLanguageErrMsg: "Source language is required",

    // targetLanguageLabel: "Target language",
    // targetLanguagePrompt: "Select target language",
    // targetLanguageErrMsg: "Target language is required",

    moreLabel: "- more -",

    uploadLabel: "Upload",
};

function ManageWebPages() {
    const appContext = useContext(AppContext);

    const [direction, setDirection] = useState(appContext.direction);
    const [enableRtl, setEnableRtl] = useState(direction === "rtl");
    const [currentLanguage, setCurrentLanguage] = useState(appContext.currentLanguage);

    // For Resources
    const [pageLabelsRes, setPageLabelsRes] = useState(pageLabels);
    const [renderDomFlag, setRenderDomFlag] = useState(false);
    const [progressFlag, setProgressFlag] = useState(false);

    // For Global Errors
    const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
    const [globalErrorMsg, setGlobalErrorMsg] = useState("");

    // For Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarsMsg, setSnackbarsMsg] = useState("");
    const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

    //------------------------
    // For react-responsive
    //------------------------
    const isDeskTopView = useMediaQuery({query: appContext.mediaQueries.deskTopView});
    const isTabletView = useMediaQuery({query: appContext.mediaQueries.tabletView});
    const isMobileView = useMediaQuery({query: appContext.mediaQueries.mobileView});
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    //--------------------
    // For Froala Editor
    //--------------------
    const editorRef = useRef(null);
    const editorRef2 = useRef(null);
    const editorRef3 = useRef(null);
    // const [editorModelData, setEditorModelData] = useState(null);
    const [editorMaxChars, setEditorMaxChars] = useState(10000);// Max chars allowed in editor

    //--------------------
    // For Form Attributes
    //--------------------
    const [language, setLanguage] = useState(null);
    const [domain, setDomain] = useState(null);
    const [pageCategory, setPageCategory] = useState(null);
    const [pageTags, setPageTags] = useState("");
    const [pageName, setPageName] = useState("");
    const [pageTitle, setPageTitle] = useState("");
    const [pageHeader, setPageHeader] = useState("");
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [bannerUrl, setBannerUrl] = useState("");
    const [domainOptions, setDomainOptions] = useState([]);
    
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
         
    const [languageErrFlag, setLanguageErrFlag] = useState(false);
    const [domainErrFlag, setDomainErrFlag] = useState(false);
    const [pageTagsErrFlag, setPageTagsErrFlag] = useState(false);
    const [pageNameErrFlag, setPageNameErrFlag] = useState(false);
    const [pageTitleErrFlag, setPageTitleErrFlag] = useState(false);
    const [pageHeaderErrFlag, setPageHeaderErrFlag] = useState(false);
    const [startTimeErrFlag, setStartTimeErrFlag] = useState(false);
    const [pageCategoryErrFlag, setPageCategoryErrFlag] = useState(false);
    const [bannerUrlErrFlag, setBannerUrlErrFlag] = useState(false);

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [recordsBackup, setRecordsBackup] = useState([]);
    const [webPageRecords, setWebPageRecords] = useState([]);

    const [submitButtonFlag, setSubmitButtonFlag] = useState(false);
    const [editModeFlag, setEditModeFlag] = useState(false);

    const [datePickerLanguage, setDatePickerLanguage] = useState(appContext.currentLanguage.languageCode);
    
    const [showListFlag, setShowListFlag] = useState(true);
    const [showDetailsFormFlag, setShowDetailsFormFlag] = useState(false);
    const [uploadFlag, setUploadFlag] = useState(false);
    const [convertFlag, setConvertFlag] = useState(false);
       
    const [pageSize, setPageSize] = useState(10000);// AWS put PageSize to a max 0f 100

    const [noRecordFoundFlag, setNoRecordFoundFlag] = useState(false);
    const [eventStartedFlag, setEventStartedFlag] = useState(false);
    const [startedEventId, setStartedEventId] = useState(null);
    
    const [textMaxLength, setTextMaxLength] = useState(256);

    const [startTimeOptions, setStartTimeOptions] = useState([]);

    const [endTimeOptions, setEndTimeOptions] = useState([]);
    const [endTimeErrFlag, setEndTimeErrFlag] = useState(false);
    const [timeBeforeErrFlag, setTimeBeforeErrFlag] = useState(false);// true if endTime isBefore startTime
    const [forUserOnly, setForUserOnly] = useState(false);
    
    //--------------------------
    // For sliceText and Dialog
    //--------------------------
    const [dialogData, setDialogData] = useState("");
    const [dialogHeader, setDialogHeader] = useState("");
    
    // const [terminologyNames, setTerminologyNames] = useState([
    //     "translate_custom_terminology.txt"
    // ]);
    

    //-------------------------
    // For Primereact 
    // DataTable Attributes 
    //-------------------------
    // const [ dataTableInfo, setDataTableInfo] = useState([]);
    // const [dataTableInfoFlag, setDataTableInfoFlag] = useState(false);
    
    // const [resizableColumnsFlag, setResizableColumnsFlag] = useState(true);
    // const [columnResizeMode, setColumnResizeMode] = useState("fit");
    // const [sortMode, setSortMode] = useState("multiple");
    // const [responsiveFlag, setResponsiveFlag] = useState(true);
    // const [reorderableColumnsFlag, setReorderableColumnsFlag] = useState(true);
    // const [paginatorFlag, setPaginatorFlag] = useState(true);
    const [paginatorTemplate, setPaginatorTemplate] = useState("FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown");
    // const [currentPageReportTemplate, setCurrentPageReportTemplate] = useState(null);
    const [visibleRowsCount, setVisibleRowsCount] = useState(10);
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5,10,20,50,100]);

    // const [selectionMode, setSelectionMode] = useState("multiple");// single or multiple
    // const [selectedTableRow, setSelectedTableRow] = useState(null);// For single selection
    const [selectedTableRows, setSelectedTableRows] = useState([]);// For multiple selection
    // const [updatedTableRows, setUpdatedTableRows] = useState([]); // For updated (edited) table rows
    // const [deletedTableRows, setDeletedTableRows] = useState([]); // For deleted table rows
    const [tableDirtyFlag, setTableDirtyFlag] = useState(false); // To show DataTable cell was edited (modified)

    // const [deleteRowFlag, setDeleteRowFlag] = useState(false);

    //--------------------
    // For AlertDialog
    //--------------------
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogSubContent, setDialogSubContent] = useState(null);

    const [recordToDelete, setRecordToDelete] = useState(null);
    const [recordToEdit, setRecordToEdit] = useState(null);
    
    //-----------------------
    // For Primereact Dialog
    //-----------------------
    const [dlgVisibleFlag, setDlgVisibleFlag] = useState(false);

    //--------------------
    // For Languages
    //--------------------
    const [languages, setLanguages] = useState([]);
    
    // const {
    //     // editorId,
    // } = props;

    const theme = useTheme();
    const classes = useStyles();

    // const marginAlignment = {
    //     flex: "1",
    //     marginRight: appContext.direction === "ltr" ? "12px" : "0px",
    //     marginLeft: appContext.direction === "rtl" ? "12px" : "0px",
    // };

    const directionAlignment = {
        textAlign: appContext.direction === "ltr" ? "left" : "right",
    };

    const { 
        manageWebPagesLabel, 
        requiredFields,

        languageLabel,
        languagePrompt,
        languageErrMsg,
    
        domainLabel,
        domainPrompt,
        domainErrMsg,
            
        pageTagsLabel,
        pageTagsPrompt,
        pageTagsErrMsg,
        pageTagsMsg,
        
        pageNameLabel,
        pageNamePrompt,
        pageNameErrMsg,
        pageNameMsg,
        // selectTitlePrompt,

        pageTitleLabel,
        pageTitlePrompt,
        pageTitleErrMsg,

        startTimeLabel,
        startTimePrompt,
        startTimeErrMsg,

        endTimeLabel,
        endTimePrompt,
        endTimeErrMsg,
        endTimeBeforeErrMsg,

        pageHeaderLabel,
        pageHeaderPrompt,
        pageHeaderErrMsg,
        pageHeaderMsg,
    
        // startTimeLabel,
        // startTimePrompt,
        // startTimeErrMsg,

        pageCategoryLabel,
        pageCategoryPrompt,
        pageCategoryErrMsg,

        // liveEventUrlLabel,
        // liveEventUrlPrompt,
        // liveEventUrlErrMsg,

        convertWebPagesLabel,
        // createWebPageLabel,
        uploadFilesLabel,

        webPageLabel,
        webPagesLabel,
        // inProgressLabel,
        // startLabel,
        // endLabel,
        // notStartedLabel,
    
        // dateCreatedLabel,
    
        bannerUrlLabel,
        bannerUrlPrompt,
        bannerUrlErrMsg,

        // yourTimeZoneMsg,
        // timeZoneLabel,
        // cloneEventLabel,
    
        toLabel,
        ofLabel,
        recordsLabel,
        noRecordsFound,

        gridCategory,
        editLabel,
        deleteLabel,

        operationSuccessMsg,
        operationFailureMsg,
        duplicateTransMsg,
  
        loadingText,
        noOptionsText,
        clearText,
        closeText,
        // showLabel,
        // hideLabel,

        cancelLabel,
        clearLabel,
        okLabel,

        yesLabel,
        deleteRequested,
        areYouSureMsg,
        requestedToDeleteMsg,
    
        uploadLabel,
        
        createWebPageLabel,
        updateWebPageLabel,

        createMoreLabel,
        createLabel,
        cloneRecordLabel,
        closeLabel,
        noWebPageRecordsFound, 
        // startEventInfoMsg,
        // createWebPageLabel,

        gridPageTitle,
        gridPageName,
        gridPageTags,
        lastUpdateLabel,
        // gridStartTime,
        // gridBannerUrl,
    
        convertLabel,

        // sourceLanguageLabel,
        // sourceLanguagePrompt,
        // sourceLanguageErrMsg,

        // targetLanguageLabel,
        // targetLanguagePrompt,
        // targetLanguageErrMsg,

        moreLabel,
    } = pageLabelsRes;

    async function fetchDbResources(cacheItemKey) {
        backdropProgress(true);

        let keys = [];
        let objKeys = Object.keys(pageLabels);
        // console.log("In ManageWebPages(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
        if (objKeys !== null && objKeys.length > 0) {
            objKeys.map((key) => {
                let partitionKey = appContext.currentLanguage.languageCode;
                partitionKey += ".";
                partitionKey += GenerateModulusHash(key, 200);

                let itemKey = {
                ResourcePK: partitionKey, // e.g. 'en.91'
                ResourceSK: key, // e.g. 'firstName'
                };
                keys.push(itemKey);
            });
        }
        // console.log("In ManageWebPages(): fetchDbResources(): keys.length is: ", keys.length);
        // console.log("In ManageWebPages(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

        let arrayData = [];

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/batchGet",
            payload: {
                keys: keys,
                projectionExpression: "ResourceCode,ResourceDesc",
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
            response.data.Responses.resource.map((item, index) => {
            let record = {
                ResourceCode: item.ResourceCode,
                ResourceDesc: item.ResourceDesc,
            };

            arrayData.push(record);
            });
                    
            if (arrayData && arrayData.length > 0) {
                arrayData.map((item) => {
                    if (item.ResourceCode === "manageWebPagesLabel") pageLabels.manageWebPagesLabel = item.ResourceDesc;
                    if (item.ResourceCode === "requiredFields") pageLabels.requiredFields = item.ResourceDesc;
                    
                    if (item.ResourceCode === "languageLabel") pageLabels.languageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "languagePrompt") pageLabels.languagePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "languageErrMsg") pageLabels.languageErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "domainLabel") pageLabels.domainLabel = item.ResourceDesc;
                    if (item.ResourceCode === "domainPrompt") pageLabels.domainPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "domainErrMsg") pageLabels.domainErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "pageTagsLabel") pageLabels.pageTagsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "pageTagsPrompt") pageLabels.pageTagsPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "pageTagsErrMsg") pageLabels.pageTagsErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "pageTagsMsg") pageLabels.pageTagsMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "pageNameLabel") pageLabels.pageNameLabel = item.ResourceDesc;
                    if (item.ResourceCode === "pageNamePrompt") pageLabels.pageNamePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "pageNameErrMsg") pageLabels.pageNameErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "pageNameMsg") pageLabels.pageNameMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "selectTitlePrompt") pageLabels.selectTitlePrompt = item.ResourceDesc;
            
                    if (item.ResourceCode === "pageTitleLabel") pageLabels.pageTitleLabel = item.ResourceDesc;
                    if (item.ResourceCode === "pageTitlePrompt") pageLabels.pageTitlePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "pageTitleErrMsg") pageLabels.pageTitleErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "startTimeLabel") pageLabels.startTimeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "startTimePrompt") pageLabels.startTimePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "startTimeErrMsg") pageLabels.startTimeErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "endTimeLabel") pageLabels.endTimeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "endTimePrompt") pageLabels.endTimePrompt = item.ResourceDesc;
                    if (item.ResourceCode === "endTimeErrMsg") pageLabels.endTimeErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "endTimeBeforeErrMsg") pageLabels.endTimeBeforeErrMsg = item.ResourceDesc;
                                                    
                    if (item.ResourceCode === "pageHeaderLabel") pageLabels.pageHeaderLabel = item.ResourceDesc;
                    if (item.ResourceCode === "pageHeaderPrompt") pageLabels.pageHeaderPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "pageHeaderErrMsg") pageLabels.pageHeaderErrMsg = item.ResourceDesc;
                    if (item.ResourceCode === "pageHeaderMsg") pageLabels.pageHeaderMsg = item.ResourceDesc;
                                
                    // if (item.ResourceCode === "startTimeLabel") pageLabels.startTimeLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "startTimePrompt") pageLabels.startTimePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "startTimeErrMsg") pageLabels.startTimeErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "pageCategoryLabel") pageLabels.pageCategoryLabel = item.ResourceDesc;
                    if (item.ResourceCode === "pageCategoryPrompt") pageLabels.pageCategoryPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "pageCategoryErrMsg") pageLabels.pageCategoryErrMsg = item.ResourceDesc;
                    
                    if (item.ResourceCode === "convertWebPagesLabel") pageLabels.convertWebPagesLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "createWebPageLabel") pageLabels.createWebPageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "uploadFilesLabel") pageLabels.uploadFilesLabel = item.ResourceDesc;
                                    
                    // if (item.ResourceCode === "yourTimeZoneMsg") pageLabels.yourTimeZoneMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "timeZoneLabel") pageLabels.timeZoneLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "cloneEventLabel") pageLabels.cloneEventLabel = item.ResourceDesc;
                                
                    if (item.ResourceCode === "webPagesLabel") pageLabels.webPagesLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "inProgressLabel") pageLabels.inProgressLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "startLabel") pageLabels.startLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "endLabel") pageLabels.endLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "notStartedLabel") pageLabels.notStartedLabel = item.ResourceDesc;
                      
                    // if (item.ResourceCode === "dateCreatedLabel") pageLabels.dateCreatedLabel = item.ResourceDesc;
                    
                    if (item.ResourceCode === "bannerUrlLabel") pageLabels.bannerUrlLabel = item.ResourceDesc;
                    if (item.ResourceCode === "bannerUrlPrompt") pageLabels.bannerUrlPrompt = item.ResourceDesc;
                    if (item.ResourceCode === "bannerUrlErrMsg") pageLabels.bannerUrlErrMsg = item.ResourceDesc; 

                    if (item.ResourceCode === "toLabel") pageLabels.toLabel = item.ResourceDesc;
                    if (item.ResourceCode === "ofLabel") pageLabels.ofLabel = item.ResourceDesc;
                    if (item.ResourceCode === "recordsLabel") pageLabels.recordsLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noRecordsFound") pageLabels.noRecordsFound = item.ResourceDesc;

                    if (item.ResourceCode === "createLabel") pageLabels.createLabel = item.ResourceDesc;
                    if (item.ResourceCode === "gridCategory") pageLabels.gridCategory = item.ResourceDesc;
                    if (item.ResourceCode === "editLabel") pageLabels.editLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteLabel") pageLabels.deleteLabel = item.ResourceDesc;

                    if (item.ResourceCode === "operationSuccessMsg") pageLabels.operationSuccessMsg = item.ResourceDesc;
                    if (item.ResourceCode === "operationFailureMsg") pageLabels.operationFailureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "duplicateTransMsg") pageLabels.duplicateTransMsg = item.ResourceDesc;
                                                                        
                    if (item.ResourceCode === "loadingText") pageLabels.loadingText = item.ResourceDesc;
                    if (item.ResourceCode === "noOptionsText") pageLabels.noOptionsText = item.ResourceDesc;
                    if (item.ResourceCode === "clearText") pageLabels.clearText = item.ResourceDesc;
                    if (item.ResourceCode === "closeText") pageLabels.closeText = item.ResourceDesc;
                    // if (item.ResourceCode === "showLabel") pageLabels.showLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "hideLabel") pageLabels.hideLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "cancelLabel") pageLabels.cancelLabel = item.ResourceDesc;
                    if (item.ResourceCode === "clearLabel") pageLabels.clearLabel = item.ResourceDesc;
                    if (item.ResourceCode === "okLabel") pageLabels.okLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "yesLabel") pageLabels.yesLabel = item.ResourceDesc;
                    if (item.ResourceCode === "deleteRequested") pageLabels.deleteRequested = item.ResourceDesc;
                    if (item.ResourceCode === "areYouSureMsg") pageLabels.areYouSureMsg = item.ResourceDesc;
                    if (item.ResourceCode === "requestedToDeleteMsg") pageLabels.requestedToDeleteMsg = item.ResourceDesc;
            
                    if (item.ResourceCode === "uploadLabel") pageLabels.uploadLabel = item.ResourceDesc;
          
                    // if (item.ResourceCode === "createWebPageLabel") pageLabels.createWebPageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "updateWebPageLabel") pageLabels.updateWebPageLabel = item.ResourceDesc;
                              
                    if (item.ResourceCode === "webPageLabel") pageLabels.webPageLabel = item.ResourceDesc;
                    if (item.ResourceCode === "createMoreLabel") pageLabels.createMoreLabel = item.ResourceDesc;
                    if (item.ResourceCode === "cloneRecordLabel") pageLabels.cloneRecordLabel = item.ResourceDesc;
                    if (item.ResourceCode === "closeLabel") pageLabels.closeLabel = item.ResourceDesc;
                    if (item.ResourceCode === "noWebPageRecordsFound") pageLabels.noWebPageRecordsFound = item.ResourceDesc;
                    // if (item.ResourceCode === "startEventInfoMsg") pageLabels.startEventInfoMsg = item.ResourceDesc;
                    // if (item.ResourceCode === "createWebPageLabel") pageLabels.createWebPageLabel = item.ResourceDesc;
                      
                    if (item.ResourceCode === "gridPageTitle") pageLabels.gridPageTitle = item.ResourceDesc;
                    if (item.ResourceCode === "gridPageTags") pageLabels.gridPageTags = item.ResourceDesc;
                    if (item.ResourceCode === "lastUpdateLabel") pageLabels.lastUpdateLabel = item.ResourceDesc;
                                              
                    // if (item.ResourceCode === "gridStartTime") pageLabels.gridStartTime = item.ResourceDesc;
                    // if (item.ResourceCode === "gridBannerUrl") pageLabels.gridBannerUrl = item.ResourceDesc;
                    if (item.ResourceCode === "gridPageName") pageLabels.gridPageName = item.ResourceDesc;
                                              
                    if (item.ResourceCode === "convertLabel") pageLabels.convertLabel = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "sourceLanguageLabel") pageLabels.sourceLanguageLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "sourceLanguagePrompt") pageLabels.sourceLanguagePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "sourceLanguageErrMsg") pageLabels.sourceLanguageErrMsg = item.ResourceDesc;
                    
                    // if (item.ResourceCode === "targetLanguageLabel") pageLabels.targetLanguageLabel = item.ResourceDesc;
                    // if (item.ResourceCode === "targetLanguagePrompt") pageLabels.targetLanguagePrompt = item.ResourceDesc;
                    // if (item.ResourceCode === "targetLanguageErrMsg") pageLabels.targetLanguageErrMsg = item.ResourceDesc;

                    if (item.ResourceCode === "moreLabel") pageLabels.moreLabel = item.ResourceDesc;
                });
                // Add it to cache
                SetCacheItem(cacheItemKey, pageLabels);
            }

            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setTimeout(() => {setRenderDomFlag(true);}, 500);
        }).catch((err) => {
            console.error("In fetchDbResources(): err is: ", JSON.stringify(err, null, 2));
            setPageLabelsRes(pageLabels);
            backdropProgress(true);
            backdropProgress(false);
            setTimeout(() => {setRenderDomFlag(true);}, 500);
        });
    }
    
    let listNextToken = null;

    async function fetchWebPageRecords(selectedDomain) {
        // console.log('In fetchWebPageRecords(): selectedDomain is: ', selectedDomain);
        if (language && selectedDomain) {
            backdropProgress(true);
            setNoRecordFoundFlag(false);
            setRecordsBackup([]);

            let params = {
                apiName: "PagesAPI",
                path: "/dynamodb/pages/query",
                payload: {
                    keyConditionExpression:
                        // "#pagePK = :pagePK",
                        "#pagePK = :pagePK AND begins_with(#pageSK, :pageSK)",
                    expressionAttributeNames: {
                        "#pagePK": "PagePK",
                        "#pageSK": "PageSK",
                    },
                    expressionAttributeValues: {
                        ":pagePK": selectedDomain.value + "#" + language.languageCode + "#page",
                        ":pageSK": selectedDomain.value + "#" + language.languageCode + "#page",
                    },
                    // indexName: "PriceDomain-LangAuthorUserName-index"
                        // "keyConditionExpression": "#pagePK = :pagePK",
                        // "expressionAttributeNames": {"#pagePK": "PagePK"},
                        // "expressionAttributeValues": {":pagePK": "languages"}
                },
            };

            if (!lodash.isEmpty(listNextToken)) {
                params.payload.nextToken = listNextToken;
            }
    
            if (!lodash.isEmpty(pageSize)) {
                params.payload.pageSize = pageSize;
            }

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In fetchWebPageRecords(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In fetchWebPageRecords(): response is: ', JSON.stringify(response, null, 2));
                // console.log('In fetchWebPageRecords(): response.data.NextToken is: ', response.data.NextToken);
                // console.log('In fetchWebPageRecords(): response.data.Items is: ', response.data.Items);

                let records = [];

                listNextToken = null;

                if (response && !lodash.isEmpty(response.data.NextToken)) {
                    listNextToken = response.data.NextToken;
                    records = webPageRecords;
                }
            
                let items = response.data.Items;

                if (items && items.length > 0) {
                    setWebPageRecords([]);

                    items.map((record) => {
                        // let formatedDateTime = FormatDateTime(record.CreatedTimestamp, appContext.currentLanguage.languageCode, "shortDateAndTime");
                        // console.log('In fetchWebPageRecords(): formatedDateTime is: ', formatedDateTime);
                        // console.log('In fetchWebPageRecords(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
                        let formattedDateTime = "";
                        if (record.UpdatedAt) {
                            formattedDateTime = FormatTimezoneDates(record.UpdatedAt, record.LanguageCode, timeZone, "shortDateAndTime");
                        }
                        // console.log('In fetchWebPageRecords(): formattedDateTime is: ', formattedDateTime);

                        let formattedStartTime = "";
                        if (record.StartTime) {
                            formattedStartTime = FormatTimezoneDates(record.StartTime, record.LanguageCode, timeZone, "shortDateAndTime");
                        }
                        // console.log('In fetchWebPageRecords(): formattedStartTime is: ', formattedStartTime);

                        let formattedEndTime = "";
                        if (record.EndTime) {
                            formattedEndTime = FormatTimezoneDates(record.EndTime, record.LanguageCode, timeZone, "shortDateAndTime");
                        }
                        // console.log('In fetchWebPageRecords(): formattedEndTime is: ', formattedEndTime);

                        let editorTransform = record.PageHeader;
                        if (record.PageHeader) {
                            if (isTabletView || isMobileView) {
                                editorTransform = EditorPixelTransform(record.PageHeader, (isTabletView ? "tabletView" : "mobileView"));
                            }
                        }
                        // console.log('In fetchWebPageRecords(): editorTransform is: ', editorTransform);

                        let item = {
                            PagePK: record.PagePK,
                            PageSK: record.PageSK,

                            LanguageObject: record.LanguageObject,
                            LanguageCode: record.LanguageCode,
                            Domain: record.Domain,

                            "PageId": lodash.isEmpty(record.PageId) ? "" : record.PageId,

                            "PageDomain": lodash.isEmpty(record.Domain) ? "" : record.Domain.value,
                            "PageCategoryText": lodash.isEmpty(record.PageCategory) ? "" : record.PageCategory.text,
                            "PageTags": lodash.isEmpty(record.PageTags) ? "" : record.PageTags,

                            "PageName": lodash.isEmpty(record.PageName) ? "" : record.PageName,
                            "PageTitle": lodash.isEmpty(record.PageTitle) ? "" : record.PageTitle,
                            "PageHeader": lodash.isEmpty(editorTransform) ? "" : editorTransform,

                            "FormattedStartTime": formattedStartTime,
                            "StartTime": record.StartTime,
                            "FormattedEndTime": formattedEndTime,
                            "EndTime": record.EndTime,

                            "PageBannerUrl": lodash.isEmpty(record.PageBannerUrl) ? "" : record.PageBannerUrl,
            
                            "FormattedDateTime": formattedDateTime,
                            "UpdatedAt": record.UpdatedAt,
                        };
                        records.push(item);
                    });
                } else {
                    setNoRecordFoundFlag(true);
                }

                // console.log('In fetchWebPageRecords(): listNextToken is: ', listNextToken);
                if (!lodash.isEmpty(listNextToken)) {
                    await fetchWebPageRecords();
                }
                
                setTimeout(() => {
                    records.sort((a, b) => (b.UpdatedAt > a.UpdatedAt ? 1 : -1));// asc
                    setWebPageRecords(records);
                    setRecordsBackup(records);
                }, 100);

                backdropProgress(false);
            }).catch((err) => {
                console.error("In fetchWebPageRecords(): err is: ", JSON.stringify(err, null, 2));
                setWebPageRecords([]);
                setNoRecordFoundFlag(true);
                backdropProgress(false);
            });
        }
    }
    
    async function getWebPageRecord(key) {
        // console.log('In getWebPageRecord(): key.PagePK is: ', key.PagePK);
        // console.log('In getWebPageRecord(): key.PageSK is: ', key.PageSK);
        
        backdropProgress(true);
    
        let params = {
            apiName: "PagesAPI",
            path: "/dynamodb/pages/get",
            payload: {
                key: {
                    PagePK: key.PagePK,
                    PageSK: key.PageSK,
                },
            },
        };
  
        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };
  
        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In getWebPageRecord(): initObj is: ', JSON.stringify(initObj, null, 2));
  
        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log('In getWebPageRecord(): response is: ', JSON.stringify(response, null, 2));
            // console.log('In getWebPageRecord(): response.data.Item is: ', response.data.Item);

            setLanguage(null);// To trigger CommonMediaSelector
        
            let item = response.data.Item;

            appContext.currentPK = item.PagePK;
            appContext.currentSK = item.PageSK;
        
            setLanguage(item.LanguageObject);

            setDomain(item.Domain ? item.Domain : null);

            setPageCategory(item.PageCategory ? item.PageCategory : null);
            setPageTags(item.PageTags ? item.PageTags : "");
            setPageName(item.PageName ? item.PageName : "");
            setPageTitle(item.PageTitle ? item.PageTitle : "");
            setPageHeader(item.PageHeader ? item.PageHeader : "");

            setStartTime(item.StartTime ? item.StartTime : null);
            setEndTime(item.EndTime ? item.EndTime : null);

            setBannerUrl(item.PageBannerUrl ? item.PageBannerUrl : "");

            setShowListFlag(false);
            setUploadFlag(false);
            setShowDetailsFormFlag(true);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);

            backdropProgress(false);

            Promise.all([
                fetchReferenceData("web-pages", item.LanguageObject)
            ]).then(async (results) => {
                // console.log("In getWebPageRecord(): results is: ", results);
                // fetchReferenceData("stripe-curr", item.LanguageObject)
            }).catch(error => {
                console.error("In getWebPageRecord(): Promise error is: ", error);
            });
        }).catch((err) => {
            console.error("In getWebPageRecord(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        // console.log("In editRecordAction(): webPageRecords is: ", webPageRecords);
        
        setLanguage(null);
        setDomain(null);

        setShowDetailsFormFlag(true);
        
        setRecordToEdit(item);
        await getWebPageRecord({PagePK: item.PagePK, PageSK: item.PageSK,});
    }

    async function fetchLanguages() {
        // console.log("In fetchLanguages()");
        // setFilterDisabledFlag(true);

        //-------------------------------------------------------
        // Make sure languages is not in cache before proceeding
        //-------------------------------------------------------

        let cachedLanguages = GetCacheItem("languages");
        // console.log('In fetchLanguages(): cachedLanguages is: ', JSON.stringify(cachedLanguages, null, 2));
        cachedLanguages = null;
        if (cachedLanguages && cachedLanguages !== null && cachedLanguages.length > 0) {
            // console.log('In fetchLanguages(): cachedLanguages length is: ', cachedLanguages.length);
            setLanguages(cachedLanguages);
            return true;
        }

        backdropProgress(true);

        let params = {
            apiName: "ResourceAPI",
            path: "/dynamodb/resources/query",
            payload: {
                keyConditionExpression: "#resourcePK = :resourcePK",
                expressionAttributeNames: { "#resourcePK": "ResourcePK" },
                expressionAttributeValues: { ":resourcePK": "languages" },
            },
        };

        let initObj = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "",
            },
            response: true, // return entire response object instead of response.data
            body: {},
        };

        initObj.headers.Authorization = appContext.jwtToken;
        initObj.body = params.payload;
        // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

        API.post(params.apiName, params.path, initObj).then((response) => {
            // console.log("In fetchLanguages(): response length is: ", response.data.Items.length);
            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));

            // response.data.Items.sort((a, b) =>
            //     a.LanguageName > b.LanguageName ? 1 : -1
            // );

            let records = [];
            let items = response.data.Items;

            items.map(item => {
                let record ={
                    languageCode: item.ResourceCode,
                    languageName: item.LanguageName
                };
                records.push(record);
            });

            // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
            setLanguages(records);

            // Add it to cache
            SetCacheItem("languages", response.data.Items);
            backdropProgress(false);
        }).catch((err) => {
            console.error("In fetchLanguages(): err is: ", JSON.stringify(err, null, 2));
            backdropProgress(false);
        });
    };

    let  refDataSelectOptions = [];
    
    async function fetchReferenceData(refType, lang) {
        return new Promise((resolve, reject) => {
            // console.log('In ManageWebPages  fetchReferenceData(): refType is: ', refType);
            // console.log('In ManageWebPages  fetchReferenceData(): lang is: ', lang);
            backdropProgress(true);

            refDataSelectOptions = [];

            let apiName = null;
            let path = null;
            let keyConditionExpression = null;
            let expressionAttributeNames = {};
            let expressionAttributeValues = {};
            
            switch(refType) {
                case "web-pages":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "web-pages",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                case "supported-domains":
                    apiName = "ResourceAPI";
                    path = "/dynamodb/resources/query";
                    keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                    expressionAttributeNames = {
                        "#resourcePK": "ResourcePK",
                        "#resourceSK": "ResourceSK",
                    };
                    expressionAttributeValues = {
                        ":resourcePK": "supported-domains",
                        ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                    };
                    break;
                // case "pricing-units":
                //     apiName = "ResourceAPI";
                //     path = "/dynamodb/resources/query";
                //     keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                //     expressionAttributeNames = {
                //         "#resourcePK": "ResourcePK",
                //         "#resourceSK": "ResourceSK",
                //     };
                //     expressionAttributeValues = {
                //         ":resourcePK": "pricing-units",
                //         ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                //     };
                //     break;
                // case "stripe-curr":
                //     apiName = "ResourceAPI";
                //     path = "/dynamodb/resources/query";
                //     keyConditionExpression = "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)";
                //     expressionAttributeNames = {
                //         "#resourcePK": "ResourcePK",
                //         "#resourceSK": "ResourceSK",
                //     };
                //     expressionAttributeValues = {
                //         ":resourcePK": "stripe-curr",
                //         ":resourceSK": lang ? lang.languageCode : appContext.currentLanguage.languageCode,
                //     };
                //     break;
                default:
                    break;
            }

            setProgressFlag(true);
        
            let params = {
                apiName: apiName,
                path: path,
                payload: {
                    keyConditionExpression: keyConditionExpression,
                    expressionAttributeNames: expressionAttributeNames,
                    expressionAttributeValues: expressionAttributeValues,
                    // "keyConditionExpression": "#resourcePK = :resourcePK",
                    // "expressionAttributeNames": {"#resourcePK": "ResourcePK"},
                    // "expressionAttributeValues": {":resourcePK": "languages"}
                },
            };
        
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
        
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In  fetchReferenceData(): initObj is: ', JSON.stringify(initObj, null, 2));
        
            API.post(params.apiName, params.path, initObj).then((response) => {
                // console.log('In  fetchReferenceData(): response.length is: ', response.data.Items.length);
                // console.log('In  fetchReferenceData(): response is: ', JSON.stringify(response.data.Items, null, 2));
                response.data.Items.map((item) => {
                    let record = {
                        value: item.ResourceCode,
                        text: item.ResourceDesc,
                    };

                    if (record.value !== "all") {// Bypass all
                        refDataSelectOptions.push(record);
                    }
                });

                switch(refType) {
                    case "web-pages":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setCategoryOptions(refDataSelectOptions);
                        break;
                    case "supported-domains":
                        refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                        setDomainOptions(refDataSelectOptions);
                        break;
                    // case "pricing-units":
                    //     refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                    //     setStartTimeOptions(refDataSelectOptions);
                    //     break;
                    // case "stripe-curr":
                    //     refDataSelectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
                    //     setEndTimeOptions(refDataSelectOptions);
                    //     break;
                    default:
                        break;
                }

                backdropProgress(false);
                resolve(refDataSelectOptions);
            }).catch((err) => {
                console.error("In ManageWebPages  fetchReferenceData(): err is: ", JSON.stringify(err, null, 2));
                backdropProgress(false);
                reject(err);
            });
        });
    }
        
    useEffect(() => {
        // console.log('In ManageWebPages(): useEffect(): appContext.currentLanguage.languageCode is: ', appContext.currentLanguage.languageCode);
        // sessionStorage.removeItem("current-live-event");
    }, []);

    useLayoutEffect(() => {
        // console.log("In ManageWebPages(): useLayoutEffect()");
        async function fetchItems() {
            await fetchLanguages();

            // Fetch resource from catche
            let cacheItemKey = "manage-web-pages";
            cacheItemKey += ":";
            cacheItemKey += appContext.currentLanguage.languageCode;
            // console.log('In ManageWebPages(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

            if (
                appContext.clearCacheLanguageCodes &&
                appContext.clearCacheLanguageCodes.length > 0 &&
                appContext.clearCacheLanguageCodes.includes(appContext.currentLanguage.languageCode)
            ) {
                // console.log("In ManageWebPages(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
                RemoveCacheItem(cacheItemKey);
            }

            let cacheItem = GetCacheItem(cacheItemKey);
            // console.log('In ManageWebPages(): useLayoutEffect(): cacheItem is: ', cacheItem);
            cacheItem = null; // remove after testing

            if (cacheItem === null) {
                // resource is not in cache, fetch from DB
                // console.log('In ManageWebPages(): useLayoutEffect(): resource is not in cache, fetch from DB');
                await fetchDbResources(cacheItemKey);
            } else {
                // console.log('In ManageWebPages(): useLayoutEffect(): got resource from cache');
                setPageLabelsRes(cacheItem);
                setRenderDomFlag(true);
            }
        }

        fetchItems();
    }, []);
    
    function handleChange(event) {
        // console.log("In handleChange(): event.target.id is: ", event.target.id);
        // console.log("In handleChange(): event.target.value is: ", event.target.value);
        // setSubmitButtonFlag(false);

        switch(event.target.id) {
            case "pageNameId":
                setPageNameErrFlag(false);
                setPageName(event.target.value);

                setSubmitButtonFlag(false);
                if (!lodash.isEmpty(event.target.value)) {
                    if (
                        !lodash.isEmpty(event.target.value) &&
                        !lodash.isEmpty(startTime) &&
                        // !lodash.isEmpty(endTime) &&
                        !lodash.isEmpty(pageHeader) &&
                        !lodash.isEmpty(pageTitle) &&
                        !lodash.isEmpty(pageTags) &&
                        !lodash.isEmpty(pageCategory) &&
                        !lodash.isEmpty(domain) &&
                        !lodash.isEmpty(language)
                        ) {
                        setSubmitButtonFlag(true);
                    }
                }
                break;
            case "pageTitleId":
                // console.log("In handleChange(): event.target.value is: ", event.target.value);
                setPageTitleErrFlag(false);
                setPageTitle(event.target.value);

                setSubmitButtonFlag(false);
                if (!lodash.isEmpty(pageName)) {
                    if (
                        !lodash.isEmpty(event.target.value) &&
                        !lodash.isEmpty(startTime) &&
                        // !lodash.isEmpty(endTime) &&
                        !lodash.isEmpty(pageHeader) &&
                        !lodash.isEmpty(pageName) &&
                        !lodash.isEmpty(pageTags) &&
                        !lodash.isEmpty(pageCategory) &&
                        !lodash.isEmpty(domain) &&
                        !lodash.isEmpty(language)
                        ) {
                        setSubmitButtonFlag(true);
                    }
                }
                break;
            case "pageTagsId":
                // console.log("In handleChange(): event.target.value is: ", event.target.value);
                setPageTagsErrFlag(false);
                setPageTags(event.target.value);

                setSubmitButtonFlag(false);
                if (
                    !lodash.isEmpty(event.target.value) &&
                    !lodash.isEmpty(startTime) &&
                    // !lodash.isEmpty(endTime) &&
                    !lodash.isEmpty(pageHeader) &&
                    !lodash.isEmpty(pageName) &&
                    !lodash.isEmpty(pageTitle) &&
                    !lodash.isEmpty(pageCategory) &&
                    !lodash.isEmpty(domain) &&
                    !lodash.isEmpty(language)
                    ) {
                    setSubmitButtonFlag(true);
                }
                break;
            case "bannerUrlId":
                // console.log("In handleChange(): event.target.value is: ", event.target.value);
                setBannerUrlErrFlag(false);
                setBannerUrl(event.target.value);

                // setSubmitButtonFlag(false);
                // if (
                //     !lodash.isEmpty(event.target.value) &&
                //     !lodash.isEmpty(startTime) &&
                //     !lodash.isEmpty(endTime) &&
                //     !lodash.isEmpty(pageHeader) &&
                //     !lodash.isEmpty(pageName) &&
                //     !lodash.isEmpty(pageTags) &&
                //     !lodash.isEmpty(pageCategory) &&
                //     !lodash.isEmpty(domain) &&
                //     !lodash.isEmpty(language)
                //     ) {
                //     setSubmitButtonFlag(true);
                // }
                break;
            default:
                break;
        }
    }

    //--------------------
    // For Froala Editor
    //--------------------
    async function handleEditorContentChange(editorData) {
        // console.log("In handleEditorContentChange(): editorData is: ", editorData);

        setPageHeader(editorData);
        setSubmitButtonFlag(false);

        if (
            !lodash.isEmpty(editorData) &&
            !lodash.isEmpty(startTime) &&
            // !lodash.isEmpty(endTime) &&
            !lodash.isEmpty(pageTitle) &&
            !lodash.isEmpty(pageName) &&
            !lodash.isEmpty(pageTags) &&
            !lodash.isEmpty(pageCategory) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(language)
            ) {
            setSubmitButtonFlag(true);
        }
    }
    
    function handleStartTimeChange(item) {
        // console.log("In handleStartTimeChange(): item is: ", item);

        setGlobalErrorFlag(false);
        setStartTimeErrFlag(false);

        setStartTime(item);

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(pageTitle) &&
            // !lodash.isEmpty(endTime) &&
            !lodash.isEmpty(pageHeader) &&
            !lodash.isEmpty(pageName) &&
            !lodash.isEmpty(pageTags) &&
            !lodash.isEmpty(pageCategory) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(language)
            ) {
            setSubmitButtonFlag(true);
        }
    }

    function handleEndTimeChange(item) {
        // console.log("In handleEndTimeChange(): item is: ", item);

        setGlobalErrorFlag(false);
        setEndTimeErrFlag(false);
        setTimeBeforeErrFlag(false);

        setEndTime(item);

        // setSubmitButtonFlag(false);
        // if (
        //     !lodash.isEmpty(item) &&
        //     !lodash.isEmpty(startTime) &&
        //     !lodash.isEmpty(bannerUrl) &&
        //     !lodash.isEmpty(pageHeader) &&
        //     !lodash.isEmpty(pageName) &&
        //     !lodash.isEmpty(pageTags) &&
        //     !lodash.isEmpty(pageCategory) &&
        //     !lodash.isEmpty(domain) &&
        //     !lodash.isEmpty(language)
        //     ) {
        //     setSubmitButtonFlag(true);
        // }
    }
    
    function handleLanguageChange(event, item) {
        // console.log("In handleLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        setSubmitButtonFlag(false);        
        setLanguage(item);

        if (!lodash.isEmpty(item)) {
            Promise.all([
                fetchReferenceData("web-pages", item),
            ]).then(async (results) => {
                // console.log("In handleLanguageChange(): results is: ", results);
                Promise.all([
                    await fetchReferenceData("supported-domains", item)
                ]).then(async (results2) => {
                    // console.log("In handleLanguageChange(): results2 is: ", results2);
                    // Promise.all([
                    //     await fetchReferenceData("pricing-units", item)
                    // ]).then(async (results3) => {
                    //     // console.log("In handleLanguageChange(): results3 is: ", results3);
                    //     await fetchReferenceData("stripe-curr", item)
                    // });
                });
            }).catch(error => {
                console.error("In handleLanguageChange(): error is: ", error);
            });
        }

        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(startTime) &&
            // !lodash.isEmpty(endTime) &&
            !lodash.isEmpty(pageHeader) &&
            !lodash.isEmpty(pageName) &&
            !lodash.isEmpty(pageTags) &&
            !lodash.isEmpty(pageCategory) &&
            !lodash.isEmpty(domain) 
            // !lodash.isEmpty(bannerUrl)
        ) {
            setSubmitButtonFlag(true);
        }
    }
    
    async function handleListLanguageChange(event, item) {
        // console.log("In handleListLanguageChange(): item is: ", item);
        event.preventDefault();
        event.persist();

        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        setLanguageErrFlag(false);
        // setSubmitButtonFlag(false);        
        setLanguage(item);

        setDomain(null);
        setWebPageRecords([]);
        setRecordsBackup([]);

        if (!lodash.isEmpty(item)) {
            Promise.all([
                await fetchReferenceData("supported-domains", item)
            ]).then(async (results) => {
                // console.log("In handleListLanguageChange(): results is: ", results);
            }).catch(error => {
                console.error("In handleListLanguageChange(): error is: ", error);
            });
        }
    }

    function handlePageCategoryChange(event, item) {
        // console.log("In handlePageCategoryChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setPageCategoryErrFlag(false);
        
        setPageCategory(item);

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(startTime) &&
            // !lodash.isEmpty(endTime) &&
            !lodash.isEmpty(pageHeader) &&
            !lodash.isEmpty(pageName) &&
            !lodash.isEmpty(pageTags) &&
            !lodash.isEmpty(pageTitle) &&
            !lodash.isEmpty(domain) &&
            !lodash.isEmpty(language)
            ) {
                setSubmitButtonFlag(true);
            }
    }


    function handleDomainChange(event, item) {
        // console.log("In handleDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        // setPageCategory(null);
        // setWebPageRecords([]);
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            sessionStorage.setItem("media-domain-name", item.value);
        }

        setSubmitButtonFlag(false);
        if (
            !lodash.isEmpty(item) &&
            !lodash.isEmpty(startTime) &&
            // !lodash.isEmpty(endTime) &&
            !lodash.isEmpty(pageHeader) &&
            !lodash.isEmpty(pageName) &&
            !lodash.isEmpty(pageTags) &&
            !lodash.isEmpty(pageCategory) &&
            !lodash.isEmpty(pageTitle) &&
            !lodash.isEmpty(language)
            ) {
                setSubmitButtonFlag(true);
        }
    }

    function handleListDomainChange(event, item) {
        // console.log("In handleListDomainChange(): item is: ", item);
        setGlobalErrorFlag(false);
        setDomainErrFlag(false);
        
        setDomain(item);

        if (!lodash.isEmpty(item)) {
            fetchWebPageRecords(item);
        }

        // setSubmitButtonFlag(false);
        // if (
        //     !lodash.isEmpty(item) &&
        //     !lodash.isEmpty(language) &&
        //     !lodash.isEmpty(pageCategory) 

        //     // !lodash.isEmpty(pageName) &&
        //     !lodash.isEmpty(pageTitle) &&
        //     // !lodash.isEmpty(pageHeader) &&
        //     // !lodash.isEmpty(startTime)
        //     ) {
        //         if (lodash.isEmpty(pageName)) {            
        //             setSubmitButtonFlag(true);
        //         } else if (!lodash.isEmpty(pageName) && !lodash.isEmpty(pageHeader)) {
        //             setSubmitButtonFlag(true);
        //         }
        // }
    }

    function initializeForm() {
        // setLanguage(null);
        // setDomain(null);
        setPageCategory(null);
        setPageTags("");
        setPageName("");
        setPageTitle("");
        setPageHeader("");
        setStartTime(null);
        setEndTime(null);
        setBannerUrl("");


        setPageTags("");
        setPageCategory(null);
        // setDomain(null);
        setPageName("");
        setPageHeader("");
    }

    function createPriceAction() {
        initializeForm();

        setLanguage(null);
        setDomain(null);

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(true);
        setUploadFlag(false);
    }

    function createMoreAction() {
        initializeForm();

        setLanguage(null);
        setDomain(null);
        setPageCategory(null);

        setEditModeFlag(false);
        setShowListFlag(false);
        setUploadFlag(false);
        setShowDetailsFormFlag(true);
    }

    function uploadAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setUploadFlag(true);
    }
    
    function convertAction() {
        initializeForm();

        setEditModeFlag(false);
        setShowListFlag(false);
        setShowDetailsFormFlag(false);
        setUploadFlag(false);
        setConvertFlag(true);
    }
    
    async function closeConvertForm() {
        // await fetchWebPageRecords();
        
        setTimeout(() => {
            setShowDetailsFormFlag(false);
            setUploadFlag(false);
            setConvertFlag(false);
            setShowListFlag(true);
        }, 500);
    }
    
    async function closeDetailsForm() {
        await fetchWebPageRecords(domain);
        
        setTimeout(() => {
            setGlobalErrorMsg("");
            setGlobalErrorFlag(false);

            setShowDetailsFormFlag(false);
            setUploadFlag(false);
            setEditModeFlag(false);
            setShowListFlag(true);
        }, 500);
    }
    
    async function closeUploadForm() {
        await fetchWebPageRecords(domain);
        
        setTimeout(() => {
            setGlobalErrorMsg("");
            setGlobalErrorFlag(false);

            setEditModeFlag(false);
            setShowDetailsFormFlag(false);
            setUploadFlag(false);
            setShowListFlag(true);
        }, 500);
    }
    
    // function liveEventsUploadCallback() {
    //     setEditModeFlag(false);
    //     setShowListFlag(true);
    //     setShowDetailsFormFlag(false);
    //     setUploadFlag(false);
    // }
    
    async function createService() {
        // console.log("In createService(): language is: ", language);
        // console.log("In createService(): domain is: ", domain);
        // console.log("In createService(): pageCategory is: ", pageCategory);
        // console.log("In createService(): pageTags is: ", pageTags);
        // console.log("In createService(): pageName is: ", pageName);
        // console.log("In createService(): pageHeader is: ", pageHeader);
        // console.log("In createService(): timeZone is: ", timeZone);
        // console.log("In createService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In createService(): appContext.userName is: ", appContext.userName);
    
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In createService(): Validation passed.");
            // console.log("In createService(): Validation passed.");

            backdropProgress(true);

            const timestamp = moment.tz(Date.now(), timeZone);
            // console.log("In createService(): timestamp is: ", timestamp);

            let tzStartTime = null;
            if (startTime) {
                tzStartTime = moment.tz(startTime, timeZone);
            }
            console.log("In createService(): tzStartTime is: ", tzStartTime);

            let tzEndTime = null;
            if (endTime) {
                tzEndTime = moment.tz(endTime, timeZone);
            }
            console.log("In createService(): tzEndTime is: ", tzEndTime);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            let uniqueId = GenerateUniqueName();

            let key = {
                PagePK: domain.value + "#" + language.languageCode + "#page",
                PageSK: domain.value + "#" + language.languageCode + "#page#" + pageCategory.value + "#" + uniqueId,
            };
            // console.log("In createService(): key is: ", key);

            let item = {
                "PagePK": key.PagePK,
                "PageSK": key.PageSK,

                "PageId": uniqueId,

                "UniqueAttribute": lodash.isEmpty(key) ? null : key,
                "LanguageObject": lodash.isEmpty(language) ? null : language,
                "LanguageCode": lodash.isEmpty(language) ? null : language.languageCode,
                "Domain": domain,

                "PageDomain": lodash.isEmpty(domain) ? null : domain.value,

                "PageCategory": lodash.isEmpty(pageCategory) ? null : pageCategory,
                "PageCategoryCode": lodash.isEmpty(pageCategory) ? null : pageCategory.value,
                "PageTags": lodash.isEmpty(pageTags) ? null : pageTags,
                "PageName": lodash.isEmpty(pageName) ? null : pageName,
                "PageTitle": lodash.isEmpty(pageTitle) ? null : pageTitle,
                "PageHeader": lodash.isEmpty(pageHeader) ? null : pageHeader,

                "StartTime": tzStartTime,
                "EndTime": tzEndTime,

                "PageBannerUrl": lodash.isEmpty(bannerUrl) ? null : bannerUrl,

                "DomainLangCategoryCode": domain.value + "#" + language.languageCode + "#" + pageCategory.value,// For GSI

                "ModifiedBy": appContext.userName,
                "CreatedAt": timestamp,
                "UpdatedAt": timestamp,
            };

            // console.log('In createService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_not_exists(UniqueAttribute)";

            let params = {
                apiName: "PagesAPI",
                path: "/dynamodb/pages/put",
                payload: {
                    item: item,
                    conditionExpression: conditionExpression,
                    // updateExpression: updateExpression,
                    // expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In createService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In createService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In createService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar 
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                initializeForm();

                backdropProgress(false);
            }).catch((err) => {
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
                switch(err.code) {
                    case "ConditionalCheckFailedException":
                        console.error("In createService(): Duplicate Error");
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        break;
                    default:
                        setGlobalErrorMsg(duplicateTransMsg);
                        setGlobalErrorFlag(true);
                        console.error("In createService(): err is: ", JSON.stringify(err, null, 2));
                        break;
                }
            });
        }
    }

    async function updateService() {
        // console.log("In updateService(): language is: ", language);
        // console.log("In updateService(): domain is: ", domain);
        // console.log("In updateService(): pageCategory is: ", pageCategory);
        // console.log("In updateService(): pageTags is: ", pageTags);
        // console.log("In updateService(): pageName is: ", pageName);
        // console.log("In updateService(): pageHeader is: ", pageHeader);
        // console.log("In updateService(): timeZone is: ", timeZone);
        // console.log("In updateService(): appContext.currentLanguage.languageCode is: ", appContext.currentLanguage.languageCode);
        // console.log("In updateService(): appContext.userName is: ", appContext.userName);

        backdropProgress(true);

        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");

        if (!validateForm()) {
            // console.log("In updateService(): Validation passed.");

            // let uniqueId = GenerateUniqueName();// conversion

            backdropProgress(true);

            const timestamp = moment.tz(Date.now(), timeZone);
            // console.log("In updateService(): timestamp is: ", timestamp);

            let tzStartTime = null;
            if (startTime) {
                tzStartTime = moment.tz(startTime, timeZone);
            }
            // console.log("In updateService(): tzStartTime is: ", tzStartTime);

            let tzEndTime = null;
            if (endTime) {
                tzEndTime = moment.tz(endTime, timeZone);
            }
            // console.log("In updateService(): tzEndTime is: ", tzEndTime);

            let key = {
                PagePK: appContext.currentPK,
                PageSK: appContext.currentSK,
            };
            // console.log("In updateService(): key is: ", key);

            // ConditionExpression: "attribute_exists(resourceCode)",
            let conditionExpression = "";

            // UpdateExpression: 'SET resourceValue = :resourceValue, modifiedBy = :modifiedBy, updatedAt = :updatedAt'
            let updateExpression =
                "SET " +
                "UniqueAttribute = :uniqueAttribute, " +
                
                "PageDomain = :pageDomain, " + 

                "PageCategory = :pageCategory, " +
                "PageCategoryCode = :pageCategoryCode, " +
                "PageTags = :pageTags, " +
                "PageName = :pageName, " +
                "PageTitle = :pageTitle, " +
                "PageHeader = :pageHeader, " +

                "StartTime = :startTime, " +
                "EndTime = :endTime, " +

                "PageBannerUrl = :pageBannerUrl, " +
                
                "DomainLangCategoryCode = :domainLangCategoryCode, " +

                "ModifiedBy = :modifiedBy, " + 
                "UpdatedAt = :updatedAt";

            let expressionAttributeValues = {
                ":uniqueAttribute": lodash.isEmpty(key) ? null : key,
                // ":languageObject": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage,
                // ":languageCode": lodash.isEmpty(appContext.currentLanguage) ? null : appContext.currentLanguage.languageCode,
                
                // ":uniqueId": lodash.isEmpty(uniqueId) ? null : uniqueId,// conversion
                
                ":pageDomain": lodash.isEmpty(domain) ? null : domain.value,

                ":pageCategory": lodash.isEmpty(pageCategory) ? null : pageCategory,
                ":pageCategoryCode": lodash.isEmpty(pageCategory) ? null : pageCategory.value,
                ":pageTags": lodash.isEmpty(pageTags) ? null : pageTags,
                ":pageName": lodash.isEmpty(pageName) ? null : pageName,
                ":pageTitle": lodash.isEmpty(pageTitle) ? null : pageTitle,
                ":pageHeader": lodash.isEmpty(pageHeader) ? null : pageHeader,

                ":startTime": tzStartTime,
                ":endTime": tzEndTime,

                ":pageBannerUrl": lodash.isEmpty(bannerUrl) ? null : bannerUrl,

                ":domainLangCategoryCode": domain.value + "#" + language.languageCode + "#" + pageCategory.value,// For GSI
                
                ":modifiedBy": appContext.userName,
                ":updatedAt": timestamp,
            };
            // console.log('In updateService(): expressionAttributeValues is: ', JSON.stringify(expressionAttributeValues, null, 2));

            conditionExpression = "attribute_exists(UniqueAttribute)";

            let params = {
                apiName: "PagesAPI",
                path: "/dynamodb/pages/update",
                payload: {
                    key: key,
                    conditionExpression: conditionExpression,
                    updateExpression: updateExpression,
                    expressionAttributeValues: expressionAttributeValues,
                },
            };
            // console.log('In updateService(): params.payload is: ', JSON.stringify(params.payload, null, 2));

            let initObj = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };

            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In updateService(): initObj is: ', JSON.stringify(initObj, null, 2));

            API.put(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In updateService(): response is: ', JSON.stringify(response, null, 2));
                setShowDetailsFormFlag(false);
                setUploadFlag(false);
                setEditModeFlag(false);

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                // initializeForm();

                backdropProgress(false);

                await fetchWebPageRecords(domain);
                setShowListFlag(true);
            }).catch((err) => {
                console.error("In updateService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    async function deleteService(recToDelete) {
        // console.log("In deleteService(): recToDelete is: ", recToDelete);
        // setDeleteCompleteFlag(false);
        // let itemsToDelete = [];

        if (!lodash.isEmpty(recToDelete)) {
            backdropProgress(true);

            let key = {
                PagePK: recToDelete.PagePK,
                PageSK: recToDelete.PageSK,
            };

            let params = {
                apiName: "PagesAPI",
                path: "/dynamodb/pages/delete",
                payload: {
                    key: key,
                },
            };
    
            let initObj = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "",
                },
                response: true, // return entire response object instead of response.data
                body: {},
            };
    
            initObj.headers.Authorization = appContext.jwtToken;
            initObj.body = params.payload;
            // console.log('In deleteService(): initObj is: ', JSON.stringify(initObj, null, 2));
    
            API.post(params.apiName, params.path, initObj).then(async (response) => {
                // console.log('In deleteService(): response is: ', JSON.stringify(response, null, 2));

                // Show Snackbar
                setSnackbarsVariant("success");
                setSnackbarsMsg(operationSuccessMsg);
                setSnackbarOpen(true);

                backdropProgress(false);
                await fetchWebPageRecords(domain);
            }).catch((err) => {
                console.error("In deleteService(): err is: ", JSON.stringify(err, null, 2));
                setGlobalErrorFlag(true);
                setGlobalErrorMsg(operationFailureMsg);
                backdropProgress(false);
            });
        }
    }

    function validateForm() {
        // console.log("In validateForm()");
        let formErrFlag = false;
  
        // Initialize
        setGlobalErrorFlag(false);
        setGlobalErrorMsg("");
    
        setLanguageErrFlag(false);
        setDomainErrFlag(false);
        setPageCategoryErrFlag(false);
        setPageTagsErrFlag(false);
        setPageNameErrFlag(false);
        setPageTitleErrFlag(false);
        setPageHeaderErrFlag(false);
        setStartTimeErrFlag(false);
        setEndTimeErrFlag(false);
        setTimeBeforeErrFlag(false);
        setBannerUrlErrFlag(false);

        if (lodash.isEmpty(language)) {
            formErrFlag = true;
            setLanguageErrFlag(true);
        }

        if (lodash.isEmpty(domain)) {
            formErrFlag = true;
            setDomainErrFlag(true);
        }

        if (lodash.isEmpty(pageCategory)) {
            formErrFlag = true;
            setPageCategoryErrFlag(true);
        }

        if (lodash.isEmpty(pageTags)) {
            formErrFlag = true;
            setPageTagsErrFlag(true);
        }

        if (lodash.isEmpty(pageName)) {
            formErrFlag = true;
            setPageNameErrFlag(true);
        }

        if (lodash.isEmpty(pageTitle)) {
            formErrFlag = true;
            setPageTitleErrFlag(true);
        }

        if (lodash.isEmpty(pageHeader)) {
            formErrFlag = true;
            setPageHeaderErrFlag(true);
        }

        if (lodash.isEmpty(startTime)) {
            formErrFlag = true;
            setStartTimeErrFlag(true);
        }

        if (!lodash.isEmpty(endTime)) {
            if (moment(endTime).isBefore(moment(startTime))) {
                formErrFlag = true;
                setTimeBeforeErrFlag(true);
                setEndTimeErrFlag(true);
            }
        }

        if (!lodash.isEmpty(bannerUrl)) {
            if (!(isURL(bannerUrl) || bannerUrl.startsWith("./") || bannerUrl.startsWith("/"))) {
                formErrFlag = true;
                setBannerUrlErrFlag(true);
            }
        }
    
        if (formErrFlag) {
            setGlobalErrorFlag(true);
        }
        // console.log("In validateForm(): formErrFlag is: ", formErrFlag);
        return formErrFlag;
    }

    async function buttonFormAction() {
        // console.log("In buttonFormAction()");

        if (editModeFlag) {
            await updateService();
        } else {
            await createService();
        }
    }
    
    function snackbarOnClose(event, reason) {
        setSnackbarOpen(false);
    }

    function handleMultipleSelectionChange(data) {
        // console.log("In handleMultipleSelectionChange(): data.value is: ", data.value);
        setSelectedTableRows(data.value);
        setTableDirtyFlag(true);
    }

    //------------------
    // For AlertDialog
    //------------------
    const [deleteType, setDeleteType] = useState("");

    function showAlertDialog(item, type) {
        // console.log("In showAlertDialog(): type is: ", type);
        setDeleteType(type);
        
        if (type && type === "single") {// single deletion
            setRecordToDelete(item);

            let subContent = item.PageCategoryText;
            if (!lodash.isEmpty(item.PageName)) {
                subContent += " - ";
                subContent += item.PageName;
            }
            setDialogSubContent(subContent);
            setAlertOpen(true);
        } else {// multiple deletions
            let msg = FormatNumber(selectedTableRows.length, appContext.currentLanguage.languageCode);
            msg += " ";
            msg += selectedTableRows.length > 1 ? webPagesLabel : webPageLabel;
            setDialogSubContent(msg);
            setAlertOpen(true);
        }
    }

    function alertClickAgree() {
        // Close Alert
        if (deleteType && deleteType === "single") {
            setAlertOpen(false);
            deleteService(recordToDelete);
        } else {
            setAlertOpen(false);
            // deleteMultipleAction();
            processMultipleDeletionRecs();
        }
    }

    function alertClickDisagree() {
        // Close Alert
        setAlertOpen(false);
        return false;
    }

    function alertClose() {
        // Close Alert
        setAlertOpen(false);
    }

    function deleteIconTemplate(rowData, column) {
        return <DeleteIcon color="secondary" onClick={() => showAlertDialog(rowData, "single")}/>
    }

    function editIconTemplate(rowData, column) {
        return <EditIcon color="primary" onClick={() => editRecordAction(rowData)}/>
    }
    
    function sortStartTime(event) {
        console.log("In sortStartTime(): event is: ", event);

        var values = [...webPageRecords];
        values.sort((data1, data2) => {
            // Note: We are using actual DateTime object and not the dateTimeFormatted 
            // used for display on data table.
            let value1 = data1.StartTime;
            let value2 = data2.StartTime;
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });

        return values;
    }
    
    function sortEndTime(event) {
        console.log("In sortEndTime(): event is: ", event);

        var values = [...webPageRecords];
        values.sort((data1, data2) => {
            // Note: We are using actual DateTime object and not the dateTimeFormatted 
            // used for display on data table.
            let value1 = data1.EndTime;
            let value2 = data2.EndTime;
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2);
            else
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

            return (event.order * result);
        });

        return values;
    }
    
    // function sortTimeField(event) {
    //     // console.log("In sortTimeField(): event is: ", event);

    //     var values = [...webPageRecords];
    //     values.sort((data1, data2) => {
    //         // Note: We are using actual DateTime object and not the dateTimeFormatted 
    //         // used for display on data table.
    //         let value1 = data1.PageCategory;
    //         let value2 = data2.PageCategory;
    //         let result = null;

    //         if (value1 == null && value2 != null)
    //             result = -1;
    //         else if (value1 != null && value2 == null)
    //             result = 1;
    //         else if (value1 == null && value2 == null)
    //             result = 0;
    //         else if (typeof value1 === 'string' && typeof value2 === 'string')
    //             result = value1.localeCompare(value2);
    //         else
    //             result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

    //         return (event.order * result);
    //     });

    //     return values;
    // }

    let processedRecs = 0;

    async function deleteMultipleAction() {
        // console.log("In deleteMultipleAction(): selectedTableRows.length is: ", selectedTableRows.length);
        // console.log("In deleteMultipleAction(): selectedTableRows is: ", selectedTableRows);

        // Disable Save changes button
        setTableDirtyFlag(false);

        return new Promise((resolve, reject) => {
            if (selectedTableRows && selectedTableRows.length > 0) {
                selectedTableRows.map(record => {
                    setTimeout(async () => {
                        await deleteService(record);

                        processedRecs++;

                        if (processedRecs === selectedTableRows.length) {
                            resolve("Done");
                        }
                    }, 1000);
                });
            }
        });
    }

    async function processMultipleDeletionRecs() {
        // console.log("In processMultipleDeletionRecs()");

        //-------------------
        // Promise.all
        //-------------------
        Promise.all(
            [
                deleteMultipleAction()
            ]).then(async (results) => {
                // console.log("In processMultipleDeletionRecs(): results is: ", results);
                // console.log("In processMultipleDeletionRecs(): records deleted from AWS, now delete them from DataTable");
                // Remove deleted records from DataTable
                let remainingRecords = FilterArrayFromArray(webPageRecords, selectedTableRows);
                if (remainingRecords) {
                    // setBlogRecords(remainingRecords);
                    setSelectedTableRows([]);
                }
            });
    }
  
    async function editRecordAction(item) {
        // console.log("In editRecordAction(): item is: ", item);
        setBannerUrl(item.PageBannerUrl);

        setRecordToEdit(item);

        appContext.currentPK = item.PagePK;
        appContext.currentSK = item.PageSK;
        await getWebPageRecord({PagePK: item.PagePK, PageSK: item.PageSK,});

        setTimeout(() => {
            setShowListFlag(false);
            setSubmitButtonFlag(true);
            setEditModeFlag(true);
            setShowDetailsFormFlag(true);
        }, 500);
    }
  
    // function sliceUrl(url, maxChars) {
    //     let retValue = url;
    //     if (url.length > maxChars) {
    //         retValue = url.substring(0, (maxChars - 1));
    //         retValue += " ...";
    //     }
    //     return retValue;
    // }

    //--------------------
    // For Backdrop
    //--------------------
    const [backdropFlag, setBackdropFlag] = useState(false);
      
    function backdropProgress(flag){
        setBackdropFlag(flag);
    };

    //--------------------------
    // For CommonMediaSelector
    //--------------------------
    const [dialogOpenFlag, setDialogOpenFlag] = useState(false);
    // const [mediaActionFlag, setMediaActionFlag] = useState(false);
    const [imagesLocationUri, setImagesLocationUri] = useState("");
        
    function onRowSelectedCallback(src) {
        // console.log("In onRowSelectedCallback(): src is: ", src);
        // console.log("In onRowSelectedCallback(): editorInstance is: ", editorInstance);
        // editorInstance.html.insert('<img src="'+ src + '" style="width: 300px;" class="fr-fic fr-dib">');
        setBannerUrl(src ? src : "");
        setDialogOpenFlag(false);
    }

    function dialogCloseCallback() {
        // console.log("In dialogCloseCallback()");
        setDialogOpenFlag(false);
    }

    function selectMediaAction() {
        console.log("In selectMediaAction()");
        // We are doing this to be able to trigger a change in
        // CommonMediaSelector especially when editing a record.
        
        if (editModeFlag) {
            const currLang = language;
            setLanguage(null);

            setTimeout(() => {
                setLanguage(currLang);
                setDialogOpenFlag(true);
            }, 500);
        } else {
            setDialogOpenFlag(true);
        }
    }

    function editorReadyCallback(status) {
        // console.log("In editorReadyCallback(): status is: ", status);
        // selectMediaAction();
    }

    //------------------------
    // For sliceText and Dialog
    //------------------------

    function sliceMoreAction(record) {
        setDialogData(record.PageHeader);
        setDialogHeader(record.PageName);
        setDlgVisibleFlag(true);
    }

    function hideModalDlg() {
        setDlgVisibleFlag(false);
    }

    // Clone
    function cloneAction() {
        setDomain(null);
        setPageCategory(null);        
        // setPageHeader("");
        setStartTime(null);
        setEndTime(null);
        // setBannerUrl("");

        setEditModeFlag(false);
        setSubmitButtonFlag(false);
    }
  
    function sliceText(text, maxChars) {
        let retValue = text;
        if (text.length > maxChars) {
            retValue = text.substring(0, (maxChars - 1));
            retValue += " ...";
        }
        return retValue;
    }

    return (
        <div dir={direction} className={clsx(classes.root, classes.divWrapper)}>
            <Backdrop className={classes.backdrop} open={backdropFlag}>
                <CircularProgress color="secondary" />
            </Backdrop>

            {renderDomFlag &&
                <>
                    {/* { !appContext.signedIn && <Redirect to='/signIn' />} */}
                    <CssBaseline />
                    <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />

                    {isDeskTopView && 
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={datePickerLanguage}>
                            <Container component="div" className={classes.main} maxWidth="lg">
                                <StyledPaper elevation={3} className={classes.paper}>
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={12} />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h5" color="primary" style={{ textAlign: "center" }}>
                                                {manageWebPagesLabel}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="caption" style={{ color: "green", textAlign: "center" }}>
                                                {requiredFields}
                                            </Typography>
                                        </Grid>

                                        {globalErrorFlag && (
                                            <Grid item xs={12} sm={12}>
                                                <LineSpacing value={24} />
                                                <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={12}>
                                            <LineSpacing value={24} />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12}>
                                            <Grid container justify="center">
                                                {showListFlag &&
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                                *{languageLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="languageId"
                                                                            value={language}
                                                                            options={languages}
                                                                            getOptionLabel={option => option.languageName}
                                                                            getOptionSelected={option => option.languageName}
                                                                            onChange={handleListLanguageChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={languagePrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={languageErrFlag}
                                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                                *{domainLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="domainId"
                                                                            value={domain}
                                                                            options={domainOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleListDomainChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            // disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={domainPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={domainErrFlag}
                                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            {webPageRecords && webPageRecords.length > 0 ?
                                                                <>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <LineSpacing value={12} />
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-start">
                                                                                    <Button variant="contained" size="large" disabled={selectedTableRows && selectedTableRows.length > 0 ? false : true} color="primary" onClick={() => showAlertDialog(null, "multiple")}>
                                                                                        <DeleteIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {deleteLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={convertAction}>
                                                                                        <TranslateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {convertLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="center">
                                                                                    <Button variant="outlined" size="large" disabled={false} color="primary" onClick={uploadAction}>
                                                                                        <CloudUploadIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {uploadLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={3} sm={3}>
                                                                                <Grid container justify="flex-end">
                                                                                    <Button variant="contained" size="large" disabled={false} color="primary" onClick={createMoreAction}>
                                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                        {createMoreLabel}
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={12}>
                                                                        <LineSpacing value={8} />
                                                                    </Grid>
                                                                    
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12}>
                                                                            <div className="datatable-doc-demo">
                                                                                <DataTable 
                                                                                    value={webPageRecords}
                                                                                    resizableColumns={true} 
                                                                                    columnResizeMode={"fit"}
                                                                                    sortMode={"multiple"}
                                                                                    responsive={true}
                                                                                    reorderableColumns={true}

                                                                                    // selectionMode={"multiple"}
                                                                                    selection={selectedTableRows}
                                                                                    onSelectionChange={handleMultipleSelectionChange}

                                                                                    // paginator={visibleRowsCount > 10 ? paginatorFlag : !paginatorFlag} 
                                                                                    paginator={webPageRecords && webPageRecords.length > 10} 
                                                                                    // paginatorLeft={paginatorLeft}

                                                                                    paginatorTemplate={paginatorTemplate}
                                                                                    currentPageReportTemplate={"{first}" + " " + toLabel + " " + "{last}" + " " + ofLabel + " " + "{totalRecords}" + " " + recordsLabel} 
                                                                                    rows={visibleRowsCount} 
                                                                                    rowsPerPageOptions={rowsPerPageOptions}
                                                                                    // className="p-datatable-responsive-cob"
                                                                                    // className="p-datatable-striped"
                                                                                    emptyMessage={noRecordsFound}
                                                                                    className={clsx("p-datatable-striped", "p-datatable-customers")}
                                                                                    rowHover 
                                                                                    >

                                                                                    <Column 
                                                                                        header={deleteLabel} 
                                                                                        selectionMode="multiple" 
                                                                                        style={{width:'5em', textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="PageCategoryText" 
                                                                                        header={gridCategory} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="PageName" 
                                                                                        header={gridPageName} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    />
                                                                                    {/* <Column 
                                                                                        field="PageHeader" 
                                                                                        header={gridPageTitle} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains"
                                                                                        style={{textAlign:'center'}} 
                                                                                    /> */}
                                                                                    <Column 
                                                                                        field="PageTitle" 
                                                                                        header={gridPageTitle} 
                                                                                        sortable={true} 
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                    />
                                                                                    <Column 
                                                                                        field="FormattedStartTime" 
                                                                                        header={startTimeLabel} 
                                                                                        sortable={true} 
                                                                                        sortFunction={sortStartTime}
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="FormattedEndTime" 
                                                                                        header={endTimeLabel} 
                                                                                        sortable={true} 
                                                                                        sortFunction={sortEndTime}
                                                                                        filter={true} 
                                                                                        filterMatchMode="contains" 
                                                                                        style={{textAlign:'center'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="deleteIcon" 
                                                                                        header={deleteLabel} 
                                                                                        body={deleteIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                    <Column 
                                                                                        field="editIcon" 
                                                                                        header={editLabel} 
                                                                                        body={editIconTemplate} 
                                                                                        style={{width:'5em', textAlign:'center', cursor: 'pointer'}}
                                                                                    />
                                                                                </DataTable>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </>
                                                            :
                                                                <>
                                                                    {noRecordFoundFlag &&
                                                                        <Grid container justify="center">
                                                                            <Grid item xs={12} sm={12}>
                                                                                <Typography variant="body1" color="secondary">
                                                                                    {noWebPageRecordsFound}
                                                                                </Typography>
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <LineSpacing value={12} />
                                                                            </Grid>

                                                                            <Grid item xs={12} sm={12}>
                                                                                <Button variant="contained" size="large" disabled={false} color="primary" onClick={createPriceAction}>
                                                                                    <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                                    {createWebPageLabel}
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {showDetailsFormFlag && 
                                                    <Grid container justify="center">
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeDetailsForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={6}>
                                                                    <Grid container justify="flex-start">
                                                                        <Typography variant="h6" color="primary">
                                                                            {createWebPageLabel}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={12} sm={6}>
                                                                    {editModeFlag &&
                                                                        <Grid container justify="center">
                                                                            <Button color="primary" onClick={cloneAction}>
                                                                                <Typography variant="subtitle1" color="primary">
                                                                                    {cloneRecordLabel}
                                                                                </Typography>
                                                                            </Button>
                                                                        </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={languageErrFlag}>
                                                                        <label htmlFor="languageId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={languageErrFlag ? "secondary" : "inherit"}>
                                                                                *{languageLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="languageId"
                                                                            value={language}
                                                                            options={languages}
                                                                            getOptionLabel={option => option.languageName}
                                                                            getOptionSelected={option => option.languageName}
                                                                            onChange={handleLanguageChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={languagePrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={languageErrFlag}
                                                                                    helperText={languageErrFlag ? languageErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={domainErrFlag}>
                                                                        <label htmlFor="domainId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={domainErrFlag ? "secondary" : "inherit"}>
                                                                                *{domainLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="domainId"
                                                                            value={domain}
                                                                            options={domainOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handleDomainChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={domainPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={domainErrFlag}
                                                                                    helperText={domainErrFlag ? domainErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={pageCategoryErrFlag}>
                                                                        <label htmlFor="pageCategoryId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={pageCategoryErrFlag ? "secondary" : "inherit"}>
                                                                                *{pageCategoryLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <Autocomplete
                                                                            id="pageCategoryId"
                                                                            value={pageCategory}
                                                                            options={categoryOptions}
                                                                            getOptionLabel={option => option.text}
                                                                            getOptionSelected={option => option.text}
                                                                            onChange={handlePageCategoryChange}
                                                                            loadingText={loadingText}
                                                                            noOptionsText={noOptionsText}
                                                                            autoHighlight={true}
                                                                            clearText={clearText}
                                                                            closeText={closeText}
                                                                            disabled={editModeFlag ? true : false}
                                                                            renderInput={params => 
                                                                                <TextField 
                                                                                    {...params} 
                                                                                    placeholder={pageCategoryPrompt} 
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    error={pageCategoryErrFlag}
                                                                                    helperText={pageCategoryErrFlag ? pageCategoryErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     readOnly: editModeFlag ? true : false,
                                                                                    // }}
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={pageTagsErrFlag}>
                                                                                <label htmlFor="pageTagsId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={pageTagsErrFlag ? "secondary" : "inherit"}>
                                                                                        *{pageTagsLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextareaAutosize
                                                                                    id="pageTagsId"
                                                                                    aria-label={pageTagsLabel}
                                                                                    rows={1.5}
                                                                                    rowsMax={6}
                                                                                    value={pageTags}
                                                                                    placeholder={pageTagsPrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    // autoFocus={true}
                                                                                    margin="none"
                                                                                    className={classes.textArea}
                                                                                    maxLength={textMaxLength}
                                                                                />
                                                                                <FormHelperText>{pageTagsErrFlag ? pageTagsErrMsg : pageTagsMsg}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={startTimeErrFlag}>
                                                                                <label htmlFor="startTimeId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={startTimeErrFlag ? "secondary" : "inherit"}>
                                                                                        *{startTimeLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <KeyboardDateTimePicker
                                                                                    id="startTimeId"
                                                                                    value={startTime}
                                                                                    // views={["year"]}
                                                                                    onChange={handleStartTimeChange}
                                                                                    clearable
                                                                                    placeholder={startTimePrompt}
                                                                                    format="MMM DD, YYYY hh:mm a z"
                                                                                    // inputVariant="outlined"
                                                                                    invalidDateMessage={startTimeErrMsg}
                                                                                    cancelLabel={cancelLabel}
                                                                                    clearLabel={clearLabel}
                                                                                    okLabel={okLabel}
                                                                                    // className={classes.phone}
                                                                                    inputVariant="outlined"
                                                                                    margin="none"
                                                                                />
                                                                                <FormHelperText>{startTimeErrFlag ? startTimeErrMsg : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={endTimeErrFlag}>
                                                                                <label htmlFor="endTimeId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={endTimeErrFlag ? "secondary" : "inherit"}>
                                                                                        {endTimeLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <KeyboardDateTimePicker
                                                                                    id="endTimeId"
                                                                                    value={endTime}
                                                                                    // views={["year"]}
                                                                                    onChange={handleEndTimeChange}
                                                                                    clearable
                                                                                    placeholder={endTimePrompt}
                                                                                    format="MMM DD, YYYY hh:mm a z"
                                                                                    // inputVariant="outlined"
                                                                                    invalidDateMessage={timeBeforeErrFlag ? endTimeBeforeErrMsg : endTimeErrMsg}
                                                                                    cancelLabel={cancelLabel}
                                                                                    clearLabel={clearLabel}
                                                                                    okLabel={okLabel}
                                                                                    // className={classes.phone}
                                                                                    inputVariant="outlined"
                                                                                    margin="none"
                                                                                />
                                                                                <FormHelperText>{endTimeErrFlag ? (timeBeforeErrFlag ? endTimeBeforeErrMsg : endTimeErrMsg) : ""}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={pageNameErrFlag}>
                                                                                <label htmlFor="pageNameId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={pageNameErrFlag ? "secondary" : "inherit"}>
                                                                                        *{pageNameLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextField
                                                                                    id="pageNameId"
                                                                                    value={pageName}
                                                                                    placeholder={pageNamePrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    fullWidth
                                                                                    type={"text"}
                                                                                    error={pageNameErrFlag}
                                                                                    margin="none"
                                                                                    variant="outlined"
                                                                                    helperText={pageNameErrFlag ? pageNameErrMsg : pageNameMsg}
                                                                                    // InputProps={{
                                                                                    //     startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                                                                    // }}
                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={pageTitleErrFlag}>
                                                                                <label htmlFor="pageTitleId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={pageTitleErrFlag ? "secondary" : "inherit"}>
                                                                                        *{pageTitleLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <TextField
                                                                                    id="pageTitleId"
                                                                                    value={pageTitle}
                                                                                    placeholder={pageTitlePrompt}
                                                                                    onChange={handleChange}
                                                                                    required
                                                                                    fullWidth
                                                                                    type={"text"}
                                                                                    error={pageTitleErrFlag}
                                                                                    margin="none"
                                                                                    variant="outlined"
                                                                                    helperText={pageTitleErrFlag ? pageTitleErrMsg : ""}
                                                                                    // InputProps={{
                                                                                    //     startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                                                                    // }}
                                                                                />
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12} className={clsx(classes.leftPanelSubItems)}>
                                                                    <FormControl className={clsx(classes.textField)} variant="outlined" error={bannerUrlErrFlag}>
                                                                        <label htmlFor="bannerUrlId" style={directionAlignment}>
                                                                            <Typography variant="subtitle1" color={bannerUrlErrFlag ? "secondary" : "inherit"}>
                                                                                {bannerUrlLabel}
                                                                            </Typography>
                                                                        </label>

                                                                        <OutlinedInput
                                                                            id="bannerUrlId"
                                                                            value={bannerUrl}
                                                                            placeholder={bannerUrlPrompt}
                                                                            onChange={handleChange}
                                                                            required
                                                                            fullWidth
                                                                            type={"text"}
                                                                            error={bannerUrlErrFlag}
                                                                            margin="none"
                                                                            variant="outlined"
                                                                            endAdornment={
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        aria-label={bannerUrlPrompt}
                                                                                        onClick={selectMediaAction}
                                                                                    >
                                                                                        <ImageSearchIcon />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            }
                                                                        />
                                                                        <FormHelperText>{bannerUrlErrFlag ? bannerUrlErrMsg : ""}</FormHelperText>
                                                                    </FormControl>
                                                                </Grid>

                                                                <Grid item xs={12} sm={12}>
                                                                    <LineSpacing value={4} />
                                                                </Grid>

                                                                <Grid item xs={12} sm={12}>
                                                                    {bannerUrl &&
                                                                        <Grid container justify="center">
                                                                            <img src={bannerUrl} style={{height: '96px'}} />
                                                                        </Grid>
                                                                    }           
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={6}>
                                                            <Grid container justify="center">
                                                                <Grid item xs={12} sm={12}>
                                                                    <Grid container justify="center">
                                                                        <Grid item xs={12} sm={12} className={clsx(classes.rightPanelSubItems)}>
                                                                            <FormControl className={clsx(classes.textField)} variant="outlined" error={pageHeaderErrFlag}>
                                                                                <label htmlFor="pageHeaderId" style={directionAlignment}>
                                                                                    <Typography variant="subtitle1" color={pageHeaderErrFlag ? "secondary" : "inherit"}>
                                                                                        *{pageHeaderLabel}
                                                                                    </Typography>
                                                                                </label>

                                                                                <FroalaCommonEditor
                                                                                    editorId={"pageHeaderId"}
                                                                                    editorRef={editorRef2}
                                                                                    // editorType={"minimal"} // Full Editor when not set
                                                                                    placeholderPrompt={pageHeaderPrompt}
                                                                                    editorMaxChars={editorMaxChars}
                                                                                    editorModel={pageHeader}
                                                                                    modelChangeCallback={handleEditorContentChange}
                                                                                    language={language}
                                                                                    domain={domain}
                                                                                    editorReadyCallback={editorReadyCallback}
                                                                                    forUserOnly={forUserOnly}
                                                                                />
                                                                                <FormHelperText>{pageHeaderErrFlag ? pageHeaderErrMsg : pageHeaderMsg}</FormHelperText>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <LineSpacing value={12} />
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="center">
                                                                <Button variant="contained" size="large" disabled={submitButtonFlag ? false : true} color="primary" onClick={buttonFormAction}>
                                                                    {editModeFlag ?
                                                                    <>
                                                                        <UpdateIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {updateWebPageLabel} 
                                                                    </>
                                                                    : 
                                                                    <>
                                                                        <AddIcon className={clsx(classes.leftIcon, classes.iconSmall)}/>
                                                                        {createWebPageLabel}
                                                                    </>
                                                                    }
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }

                                                {uploadFlag && 
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeUploadForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Container component="div" maxWidth="lg">
                                                                <Grid container justify="flex-start" className={clsx(classes.divLeftPadding)}>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container justify="flex-start">
                                                                            <Typography variant="h6" color="primary">
                                                                                {uploadFilesLabel}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Container>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <DomainUploadCapture 
                                                                showHeadersFlag={false}
                                                                forUserOnly={forUserOnly}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                {convertFlag &&
                                                    <>
                                                        <Grid item xs={12} sm={12}>
                                                            <Grid container justify="flex-end">
                                                                <Tooltip title={closeLabel} placement="bottom">
                                                                    <IconButton color="secondary" aria-label={closeLabel} component="span" onClick={closeConvertForm}>
                                                                        <CloseIcon className={clsx(classes.iconLarge)} aria-label={closeLabel}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <Container component="div" maxWidth="lg">
                                                                <Grid container justify="flex-start" className={clsx(classes.divLeftPadding)}>
                                                                    <Grid item xs={12} sm={12}>
                                                                        <Grid container justify="flex-start">
                                                                            <Typography variant="h6" color="primary">
                                                                                {convertWebPagesLabel}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Container>
                                                        </Grid>

                                                        <Grid item xs={12} sm={12}>
                                                            <WebPageConverter 
                                                                showHeadersFlag={false}
                                                                referrer={"ManageWebPages"}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                <Grid item xs={12} sm={12}>
                                                    <LineSpacing value={18} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledPaper>
                            </Container>

                            <Grid item xs={12} sm={12}>
                                <LineSpacing value={48} />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    }

                    {isTabletView && 
                        <ManageWebPagesTv
                            manageWebPagesLabel={manageWebPagesLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            domainLabel={domainLabel}
                            domainPrompt={domainPrompt}
                            domainErrMsg={domainErrMsg}
                                
                            pageTagsLabel={pageTagsLabel}
                            pageTagsPrompt={pageTagsPrompt}
                            pageTagsErrMsg={pageTagsErrMsg}
                            pageTagsMsg={pageTagsMsg}
                            
                            pageNameLabel={pageNameLabel}
                            pageNamePrompt={pageNamePrompt}
                            pageNameErrMsg={pageNameErrMsg}
                            pageNameMsg={pageNameMsg}
                            // selectTitlePrompt={selectTitlePrompt}

                            pageTitleLabel={pageTitleLabel}
                            pageTitlePrompt={pageTitlePrompt}
                            pageTitleErrMsg={pageTitleErrMsg}
                                        
                            startTimeLabel={startTimeLabel}
                            startTimePrompt={startTimePrompt}
                            startTimeErrMsg={startTimeErrMsg}
                    
                            endTimeLabel={endTimeLabel}
                            endTimePrompt={endTimePrompt}
                            endTimeErrMsg={endTimeErrMsg}
                    
                            pageHeaderLabel={pageHeaderLabel}
                            pageHeaderPrompt={pageHeaderPrompt}
                            pageHeaderErrMsg={pageHeaderErrMsg}
                            pageHeaderMsg={pageHeaderMsg}
                    
                            pageCategoryLabel={pageCategoryLabel}
                            pageCategoryPrompt={pageCategoryPrompt}
                            pageCategoryErrMsg={pageCategoryErrMsg}
                            gridCategory={gridCategory}
                            // editLabel={editLabel}
                            // deleteLabel={deleteLabel}
                            
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            moreLabel={moreLabel}
                            uploadLabel={uploadLabel}
                            createWebPageLabel={createWebPageLabel}
                            updateWebPageLabel={updateWebPageLabel}
                            createLabel={createLabel}
                            closeLabel={closeLabel}
                            noWebPageRecordsFound={noWebPageRecordsFound} 
                            // startEventInfoMsg={startEventInfoMsg}
                    
                            gridPageTitle={gridPageTitle}
                            gridPageName={gridPageName}
                            gridPageTags={gridPageTags}
                            lastUpdateLabel={lastUpdateLabel}
                            convertLabel={convertLabel}
                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            // moreLabel={moreLabel}
                            // cloneEventLabel={cloneEventLabel}
                            cancelLabel={cancelLabel}

                            clearLabel={clearLabel}
                            okLabel={clearLabel}
        
                            bannerUrlLabel={bannerUrlLabel}
                            bannerUrlPrompt={bannerUrlPrompt}
                            bannerUrlErrMsg={bannerUrlErrMsg}

                            cloneRecordLabel={cloneRecordLabel}
                            cloneAction={cloneAction}

                            convertWebPagesLabel={convertWebPagesLabel}
                            // createWebPageLabel={createWebPageLabel}
                            uploadFilesLabel={uploadFilesLabel}
                                                                                        
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showListFlag={showListFlag}
                            webPageRecords={webPageRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noRecordFoundFlag={noRecordFoundFlag}
                            createPriceAction={createPriceAction}
                            showDetailsFormFlag={showDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleListLanguageChange={handleListLanguageChange}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}
                            domain={domain}
                            domainOptions={domainOptions}
                            handleListDomainChange={handleListDomainChange}
                            handleDomainChange={handleDomainChange}
                            pageCategoryErrFlag={pageCategoryErrFlag}
                            pageCategory={pageCategory}
                            categoryOptions={categoryOptions}
                            handlePageCategoryChange={handlePageCategoryChange}
                            domainErrFlag={domainErrFlag}
                            handleChange={handleChange}
                            selectMediaAction={selectMediaAction}
                            pageTagsErrFlag={pageTagsErrFlag}
                            pageTags={pageTags}
                            pageNameErrFlag={pageNameErrFlag}
                            // pageNameErrFlag={pageNameErrFlag}
                            pageName={pageName}
                            pageTitle={pageTitle}
                            startTimeErrFlag={startTimeErrFlag}
                            startTime={startTime}
                            pageHeaderErrFlag={pageHeaderErrFlag}
                            pageHeader={pageHeader}
                            textMaxLength={textMaxLength}
                            bannerUrlErrFlag={bannerUrlErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            bannerUrl={bannerUrl}
                            submitButtonFlag={submitButtonFlag}
                            buttonFormAction={buttonFormAction}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            closeConvertForm={closeConvertForm}

                            // startTimeErrFlag={startTimeErrFlag}
                            // startTime={startTime}
                            startTimeOptions={startTimeOptions}

                            endTimeErrFlag={endTimeErrFlag}
                            endTime={endTime}
                            endTimeOptions={endTimeOptions}
                            forUserOnly={forUserOnly}

                            handleStartTimeChange={handleStartTimeChange}
                            handleEndTimeChange={handleEndTimeChange}
        
                            editorRef2={editorRef2}
                            handleEditorContentChange={handleEditorContentChange}
                            editorReadyCallback={editorReadyCallback}
                            timeBeforeErrFlag={timeBeforeErrFlag}
                            endTimeBeforeErrMsg={endTimeBeforeErrMsg}
                        />
                    }

                    {isMobileView && 
                        <ManageWebPagesMv
                            manageWebPagesLabel={manageWebPagesLabel} 
                            requiredFields={requiredFields}
                            languageLabel={languageLabel}
                            languagePrompt={languagePrompt}
                            languageErrMsg={languageErrMsg}
                        
                            domainLabel={domainLabel}
                            domainPrompt={domainPrompt}
                            domainErrMsg={domainErrMsg}
                                
                            pageTagsLabel={pageTagsLabel}
                            pageTagsPrompt={pageTagsPrompt}
                            pageTagsErrMsg={pageTagsErrMsg}
                            pageTagsMsg={pageTagsMsg}
                    
                            startTimeLabel={startTimeLabel}
                            startTimePrompt={startTimePrompt}
                            startTimeErrMsg={startTimeErrMsg}
                    
                            endTimeLabel={endTimeLabel}
                            endTimePrompt={endTimePrompt}
                            endTimeErrMsg={endTimeErrMsg}
                            
                            pageNameLabel={pageNameLabel}
                            pageNamePrompt={pageNamePrompt}
                            pageNameErrMsg={pageNameErrMsg}
                            pageNameMsg={pageNameMsg}
                            // selectTitlePrompt={selectTitlePrompt}

                            pageTitleLabel={pageTitleLabel}
                            pageTitlePrompt={pageTitlePrompt}
                            pageTitleErrMsg={pageTitleErrMsg}
                    
                            pageHeaderLabel={pageHeaderLabel}
                            pageHeaderPrompt={pageHeaderPrompt}
                            pageHeaderErrMsg={pageHeaderErrMsg}
                            pageHeaderMsg={pageHeaderMsg}
                            pageCategoryLabel={pageCategoryLabel}
                            pageCategoryPrompt={pageCategoryPrompt}
                            pageCategoryErrMsg={pageCategoryErrMsg}
                            gridCategory={gridCategory}
                            loadingText={loadingText}
                            noOptionsText={noOptionsText}
                            clearText={clearText}
                            closeText={closeText}
                            moreLabel={moreLabel}
                            cancelLabel={cancelLabel}

                            yesLabel={yesLabel}
                            deleteRequested={deleteRequested}
                            areYouSureMsg={areYouSureMsg}
                            requestedToDeleteMsg={requestedToDeleteMsg}
                            uploadLabel={uploadLabel}
                            createWebPageLabel={createWebPageLabel}
                            updateWebPageLabel={updateWebPageLabel}
                            // webPageLabel={webPageLabel}
                            // createMoreLabel={createMoreLabel}
                            createLabel={createLabel}
                            closeLabel={closeLabel}
                            noWebPageRecordsFound={noWebPageRecordsFound} 
                            // startEventInfoMsg={startEventInfoMsg}
                    
                            gridPageTitle={gridPageTitle}
                            gridPageName={gridPageName}
                            gridPageTags={gridPageTags}
                            lastUpdateLabel={lastUpdateLabel}
                            // gridStartTime={gridStartTime}
                            // gridBannerUrl={gridBannerUrl}
                        
                            convertLabel={convertLabel}
                            sliceText={sliceText}
                            sliceMoreAction={sliceMoreAction}
                            // moreLabel={moreLabel}

                            clearLabel={clearLabel}
                            okLabel={clearLabel}
        
                            bannerUrlLabel={bannerUrlLabel}
                            bannerUrlPrompt={bannerUrlPrompt}
                            bannerUrlErrMsg={bannerUrlErrMsg}

                            cloneRecordLabel={cloneRecordLabel}
                            cloneAction={cloneAction}

                            convertWebPagesLabel={convertWebPagesLabel}
                            // createWebPageLabel={createWebPageLabel}
                            uploadFilesLabel={uploadFilesLabel}
                            
                            //=======================

                            datePickerLanguage={datePickerLanguage}
                            showListFlag={showListFlag}
                            webPageRecords={webPageRecords}
                            // selectedTableRows={selectedTableRows}
                            showAlertDialog={showAlertDialog}
                            convertAction={convertAction}
                            editRecordAction={editRecordAction}
                            uploadAction={uploadAction}
                            createMoreAction={createMoreAction}
                            noRecordFoundFlag={noRecordFoundFlag}
                            createPriceAction={createPriceAction}
                            showDetailsFormFlag={showDetailsFormFlag}
                            closeDetailsForm={closeDetailsForm}
                            timeZone={timeZone}
                            languageErrFlag={languageErrFlag}
                            language={language}
                            languages={languages}
                            handleListLanguageChange={handleListLanguageChange}
                            handleLanguageChange={handleLanguageChange}
                            editModeFlag={editModeFlag}
                            domain={domain}
                            domainOptions={domainOptions}
                            handleListDomainChange={handleListDomainChange}
                            handleDomainChange={handleDomainChange}
                            pageCategoryErrFlag={pageCategoryErrFlag}
                            pageCategory={pageCategory}
                            categoryOptions={categoryOptions}
                            handlePageCategoryChange={handlePageCategoryChange}
                            domainErrFlag={domainErrFlag}
                            handleChange={handleChange}
                            selectMediaAction={selectMediaAction}
                            pageTagsErrFlag={pageTagsErrFlag}
                            pageTags={pageTags}
                            pageNameErrFlag={pageNameErrFlag}
                            // pageNameErrFlag={pageNameErrFlag}
                            pageName={pageName}
                            pageTitle={pageTitle}
                            startTimeErrFlag={startTimeErrFlag}
                            startTime={startTime}
                            pageHeaderErrFlag={pageHeaderErrFlag}
                            pageHeader={pageHeader}
                            textMaxLength={textMaxLength}
                            bannerUrlErrFlag={bannerUrlErrFlag}
                            editorRef={editorRef}
                            editorMaxChars={editorMaxChars}
                            bannerUrl={bannerUrl}
                            submitButtonFlag={submitButtonFlag}
                            buttonFormAction={buttonFormAction}
                            uploadFlag={uploadFlag}
                            closeUploadForm={closeUploadForm}
                            convertFlag={convertFlag}
                            closeConvertForm={closeConvertForm}

                            // startTimeErrFlag={startTimeErrFlag}
                            // startTime={startTime}
                            startTimeOptions={startTimeOptions}

                            endTimeErrFlag={endTimeErrFlag}
                            endTime={endTime}
                            endTimeOptions={endTimeOptions}
                            forUserOnly={forUserOnly}

                            handleStartTimeChange={handleStartTimeChange}
                            handleEndTimeChange={handleEndTimeChange}
        
                            editorRef2={editorRef2}
                            handleEditorContentChange={handleEditorContentChange}
                            editorReadyCallback={editorReadyCallback}
                            timeBeforeErrFlag={timeBeforeErrFlag}
                            endTimeBeforeErrMsg={endTimeBeforeErrMsg}
                        />
                    }
                </>
            }

            <Dialog 
                id="modalDlgId"
                rtl={appContext.direction === 'rtl'}
                showHeader={true}
                header={dialogHeader}
                visible={dlgVisibleFlag} 
                maximizable={true}
                maximized={(isTabletView || isMobileView) ? true : false}
                modal={true} 
                onHide={hideModalDlg}
                >
                <Container component="div" className={classes.modalDlgMain} maxWidth="md">
                    <Grid item xs={12} sm={12}>
                        <Grid container justify="flex-start" style={{color: '#000'}}>
                            <FroalaEditorView model={dialogData}/>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>

            <CommonMediaSelector
                // referrer={"ManageWebPages"}
                mediaLanguageCode={language ? language.languageCode : null}
                mediaDomainName={domain ? domain.value : null}
                mediaActionFlag={showDetailsFormFlag}
                queryType={"media"} // media, audios or videos

                forUserOnly={forUserOnly}

                dialogOpenFlag={dialogOpenFlag}
                onRowSelectedCallback={onRowSelectedCallback}
                dialogCloseCallback={dialogCloseCallback}
            />

            {alertOpen && 
                <AlertDialog
                    alertOpen={alertOpen}
                    alertClose={alertClose}
                    alertClickAgree={alertClickAgree}
                    alertClickDisagree={alertClickDisagree}

                    dialogTitle={deleteRequested}
                    dialogMainContent={requestedToDeleteMsg}
                    dialogSubContent={dialogSubContent}
                    dialogQuestion={areYouSureMsg}
                    yesLabel={yesLabel}
                    noLabel={cancelLabel}
                />
            }

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={8000}
                onClose={snackbarOnClose}
                ContentProps={{ "aria-describedby": "message-id" }}
                message={<span id="message-id">{snackbarsMsg}</span>}
                variant={snackbarsVariant}
            />

            <footer className={classes.footer}>
                <div>
                    <PageFooter isSignedIn={appContext.signedIn} />
                </div>
            </footer>
        </div>
    )
}

export default ManageWebPages;
