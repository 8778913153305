import React from "react";

function Reducer(state, action) {
  console.log("In reducer()");
  switch (action.type) {
    case "AUTH_BEGIN":
      console.log("In reducer(): AUTH_BEGIN");
      return {
        ...state,
        signedIn: true,
      };

    case "AUTH_SUCCESS":
      return {
        isAuthenticating: false,
        user: action.user,
        permissions: action.permissions,
        authError: null,
      };

    case "AUTH_FAILURE":
      return {
        isAuthenticating: false,
        user: null,
        permissions: [],
        authError: action.error,
      };

    default:
      return state;
  }
}

export default Reducer;
