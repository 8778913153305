import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
// import { Redirect } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";

// AG-Grid
import { AgGridReact, AgGridColumn } from "ag-grid-react";
// import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import Amplify, { API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";
import LineSpacing from "../../components/misc/LineSpacing";

// Renderers
import DateCellRenderer from "../../components/renderers/DateCellRenderer";

import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${"assets/logos/background20.png"})`,
  },
  main: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mainForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    width: "100%",
  },
  verticalAlignLabel: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  filterGrid: {
    textAlign: "left",
    marginBottom: "8px",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  footer: {
    bottom: 0,
    height: "1rem" /* Footer height */,
    width: "100% !important",
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
    textAlign: "center !important",
  },
  progress: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));

let localeText = {
  page: "Page",
  to: "to",
  of: "of",
  noRowsToShow: "No records found",
};

//------------------------------
// RENDERERS
//------------------------------
const gridOptions = {
  style: "currency", // decimal, currency, percent
  currency: "USD",
  currencyDisplay: "symbol", // symbol, code, name to use a localized currency name such as "dollar"
  useGrouping: true,
  minimumIntegerDigits: 21,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  payload: {},

  weekday: "short", // long, short
  year: "numeric",
  month: "short", // long, short
  day: "numeric",
  // timeZone: 'America/New_York', // Auto calculated in DateCellRenderer
  timeZoneName: "short", // long, short
  hour12: true,
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  locale: "default", // To use the browser's default locale, use 'default'

  getRowHeight: function (params) {
    // console.log("In getRowHeight: params is: ", params);
    // assuming 50 characters per line, working how how many lines we need
    // console.log("In getRowHeight: words is: ", params.data.ResourceDesc.length);
    let lines = 36 * (Math.floor(params.data.ResourceDesc.length / 45) + 1);
    // console.log("In getRowHeight: lines is: ", lines);
    return lines;
  },
};

let viewRsr = {
  dataLanguageLabel: "Data language",
  viewResources: "View resources",
  resourceLanguageLabel: "Resource language",
  selectLanguagePrompt: "Please select a language",
  resourceCodeLabel: "Resource code",
  resourceDescLabel: "Resource description",

  modifiedBy: "Modified by",
  createdAt: " Create date",
  updatedAt: "Modified date",
  filterInputPrompt: "Please enter filter criteria.",
  filterGridLabel: "Filter grid",

  // For Grid
  gridPage: "Page",
  gridTo: "to",
  gridOf: "of",
  noRowsToShow: "No records found",
};

function sortAndFilter(allOfTheData, sortModel, filterModel) {
  return sortData(sortModel, filterData(filterModel, allOfTheData));
}

function sortData(sortModel, data) {
  var sortPresent = sortModel && sortModel.length > 0;
  if (!sortPresent) {
    return data;
  }
  var resultOfSort = data.slice();
  resultOfSort.sort(function (a, b) {
    for (var k = 0; k < sortModel.length; k++) {
      var sortColModel = sortModel[k];
      var valueA = a[sortColModel.colId];
      var valueB = b[sortColModel.colId];
      if (valueA === valueB) {
        continue;
      }
      var sortDirection = sortColModel.sort === "asc" ? 1 : -1;
      if (valueA > valueB) {
        return sortDirection;
      } else {
        return sortDirection * -1;
      }
    }
    return 0;
  });
  return resultOfSort;
}

function filterData(filterModel, data) {
  var filterPresent = filterModel && Object.keys(filterModel).length > 0;
  if (!filterPresent) {
    return data;
  }

  var resultOfFilter = [];

  if (filterModel.ResourceCode) {
    switch (filterModel.ResourceCode.type) {
      case "equals":
        return data.filter(
          (record) => record.ResourceCode === filterModel.ResourceCode.filter
        );
      case "notEqual":
        return data.filter(
          (record) => record.ResourceCode !== filterModel.ResourceCode.filter
        );
      case "contains":
        return data.filter((record) =>
          record.ResourceCode.includes(filterModel.ResourceCode.filter)
        );
      case "notContains":
        return data.filter(
          (record) =>
            !record.ResourceCode.includes(filterModel.ResourceCode.filter)
        );
      case "startsWith":
        return data.filter((record) =>
          record.ResourceCode.startsWith(filterModel.ResourceCode.filter)
        );
      case "endsWith":
        return data.filter((record) =>
          record.ResourceCode.endsWith(filterModel.ResourceCode.filter)
        );
    }
  } else if (filterModel.ResourceDesc) {
    switch (filterModel.ResourceDesc.type) {
      case "equals":
        return data.filter(
          (record) => record.ResourceDesc === filterModel.ResourceDesc.filter
        );
      case "notEqual":
        return data.filter(
          (record) => record.ResourceDesc !== filterModel.ResourceDesc.filter
        );
      case "contains":
        return data.filter((record) =>
          record.ResourceDesc.includes(filterModel.ResourceDesc.filter)
        );
      case "notContains":
        return data.filter(
          (record) =>
            !record.ResourceDesc.includes(filterModel.ResourceDesc.filter)
        );
      case "startsWith":
        return data.filter((record) =>
          record.ResourceDesc.startsWith(filterModel.ResourceDesc.filter)
        );
      case "endsWith":
        return data.filter((record) =>
          record.ResourceDesc.endsWith(filterModel.ResourceDesc.filter)
        );
    }
  } else if (filterModel.ModifiedBy) {
    switch (filterModel.ModifiedBy.type) {
      case "equals":
        return data.filter(
          (record) => record.ModifiedBy === filterModel.ModifiedBy.filter
        );
      case "notEqual":
        return data.filter(
          (record) => record.ModifiedBy !== filterModel.ModifiedBy.filter
        );
      case "contains":
        return data.filter((record) =>
          record.ModifiedBy.includes(filterModel.ModifiedBy.filter)
        );
      case "notContains":
        return data.filter(
          (record) => !record.ModifiedBy.includes(filterModel.ModifiedBy.filter)
        );
      case "startsWith":
        return data.filter((record) =>
          record.ModifiedBy.startsWith(filterModel.ModifiedBy.filter)
        );
      case "endsWith":
        return data.filter((record) =>
          record.ModifiedBy.endsWith(filterModel.ModifiedBy.filter)
        );
    }
  }

  if (filterModel.CreatedAt) {
    switch (filterModel.CreatedAt.type) {
      case "equals":
        return data.filter(
          (record) => record.CreatedAt === filterModel.CreatedAt.dateFrom
        );
      case "notEqual":
        return data.filter(
          (record) => record.CreatedAt !== filterModel.CreatedAt.dateFrom
        );
      case "greaterThan":
        return data.filter(
          (record) => record.CreatedAt > filterModel.CreatedAt.dateFrom
        );
      case "lessThan":
        return data.filter(
          (record) => record.CreatedAt < filterModel.CreatedAt.dateFrom
        );
      case "inRange":
        return data.filter(
          (record) =>
            record.CreatedAt >= filterModel.CreatedAt.dateFrom &&
            record.CreatedAt <= filterModel.CreatedAt.dateTo
        );
      default:
        return data;
    }
  }

  if (filterModel.UpdatedAt) {
    // console.log(">>>>In filterData(): filterModel.UpdatedAt is: ", filterModel.UpdatedAt);
    // console.log(">>>>In filterData(): filterModel.UpdatedAt.type is: ", filterModel.UpdatedAt.type);
    // console.log(">>>>In filterData(): filterModel.UpdatedAt.dateFrom is: ", filterModel.UpdatedAt.dateFrom);
    // console.log(">>>>In filterData(): data[0].UpdatedAt is: ", data[1].UpdatedAt);
    switch (filterModel.UpdatedAt.type) {
      case "equals":
        return data.filter(
          (record) => record.UpdatedAt === filterModel.UpdatedAt.dateFrom
        );
      case "notEqual":
        return data.filter(
          (record) => record.UpdatedAt !== filterModel.UpdatedAt.dateFrom
        );
      case "greaterThan":
        return data.filter(
          (record) => record.UpdatedAt > filterModel.UpdatedAt.dateFrom
        );
      case "lessThan":
        return data.filter(
          (record) => record.UpdatedAt < filterModel.UpdatedAt.dateFrom
        );
      case "inRange":
        return data.filter(
          (record) =>
            record.UpdatedAt >= filterModel.UpdatedAt.dateFrom &&
            record.UpdatedAt <= filterModel.UpdatedAt.dateTo
        );
      default:
        return data;
    }
  }
}

function ViewResources(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [resourceLanguage, setResourceLanguage] = useState(null);

  // const { history } = props;
  const [isSignedIn, setIsSignedIn] = useState(false);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  // For Resources
  const [viewRsrRes, setViewRsrRes] = useState(viewRsr);

  // For Grid Resources
  const [localeTextRes, setLocaleTextRes] = useState(localeText);

  const [autoFocusFlag, setAutoFocusFlag] = useState(false);
  const [renderGridFlag, setRenderGridFlag] = useState(false);

  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  // AG-Grid
  const defColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
  };

  const [gridData, setGridData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [defaultColDef, setDefaultColDef] = useState(defColDef);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [progressFlag, setProgressFlag] = useState(false);
  const [gridRowsCount, setGridRowsCount] = useState(0);

  const [langSelectOptions, setLangSelectOptions] = useState([]);
  const [selectLangChangedFlag, setSelectLangChangedFlag] = useState(false);

  const [gridFilterValue, setGridFilterValue] = useState("");
  const [paginationPageSize, setPaginationPageSize] = useState(12);
  const [filterDisabledFlag, setFilterDisabledFlag] = useState(true);

  const {
    dataLanguageLabel,
    viewResources,
    resourceLanguageLabel,
    resourceCodeLabel,
    resourceDescLabel,
    selectLanguagePrompt,

    modifiedBy,
    createdAt,
    updatedAt,
    filterInputPrompt,
    filterGridLabel,
  } = viewRsrRes;

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(viewRsr);
    // console.log("In ViewLanguages(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ViewLanguages(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "dataLanguageLabel")
              viewRsr.dataLanguageLabel = item.ResourceDesc;
            if (item.ResourceCode === "viewResources")
              viewRsr.viewResources = item.ResourceDesc;
            if (item.ResourceCode === "resourceLanguageLabel")
              viewRsr.resourceLanguageLabel = item.ResourceDesc;
            if (item.ResourceCode === "resourceCodeLabel")
              viewRsr.resourceCodeLabel = item.ResourceDesc;
            if (item.ResourceCode === "resourceDescLabel")
              viewRsr.resourceDescLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectLanguagePrompt")
              viewRsr.selectLanguagePrompt = item.ResourceDesc;

            if (item.ResourceCode === "modifiedBy")
              viewRsr.modifiedBy = item.ResourceDesc;
            if (item.ResourceCode === "createdAt")
              viewRsr.createdAt = item.ResourceDesc;
            if (item.ResourceCode === "updatedAt")
              viewRsr.updatedAt = item.ResourceDesc;
            if (item.ResourceCode === "filterInputPrompt")
              viewRsr.filterInputPrompt = item.ResourceDesc;
            if (item.ResourceCode === "filterGridLabel")
              viewRsr.filterGridLabel = item.ResourceDesc;

            // For Grid
            if (item.ResourceCode === "gridPage")
              localeText.page = item.ResourceDesc;
            if (item.ResourceCode === "gridTo")
              localeText.to = item.ResourceDesc;
            if (item.ResourceCode === "gridOf")
              localeText.of = item.ResourceDesc;
            if (item.ResourceCode === "noRowsToShow")
              localeText.noRowsToShow = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, viewRsr);
        }

        setViewRsrRes(viewRsr);

        setColumnDefs(columnDefsArray);
        setLocaleTextRes(localeText);

        setRenderGridFlag(true);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setViewRsrRes(viewRsr);
        setProgressFlag(false);
      });
  }

  const columnDefsArray = [
    {
      headerName: resourceCodeLabel,
      field: "ResourceCode",
      width: 200,
      // filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
    },
    {
      headerName: resourceDescLabel,
      field: "ResourceDesc",
      width: 250,
      // filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
    },
    {
      headerName: modifiedBy,
      field: "ModifiedBy",
      width: 250,
      // filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      filterParams: {
        clearButton: false,
        applyButton: false,
        debounceMs: 200,
      },
    },
    {
      headerName: createdAt,
      field: "CreatedAt",
      width: 260,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
    },
    {
      headerName: updatedAt,
      field: "UpdatedAt",
      width: 262,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
    },
  ];
  const [columnDefs, setColumnDefs] = useState(columnDefsArray);

  const updateData = (data) => {
    console.log("In updateData(): data is: ", JSON.stringify(data, null, 2));
    // data.forEach(function(data, index) {
    //   data.id = "R" + (index + 1);
    // });

    var dataSource = {
      rowCount: null,
      getRows: function (params) {
        console.log(
          "In updateData(): asking for " +
            params.startRow +
            " to " +
            params.endRow
        );
        setTimeout(function () {
          var dataAfterSortingAndFiltering = sortAndFilter(
            data,
            params.sortModel,
            params.filterModel
          );
          var rowsThisPage = dataAfterSortingAndFiltering.slice(
            params.startRow,
            params.endRow
          );
          var lastRow = -1;
          if (dataAfterSortingAndFiltering.length <= params.endRow) {
            lastRow = dataAfterSortingAndFiltering.length;
          }
          params.successCallback(rowsThisPage, lastRow);
        }, 500);
      },
    };

    gridApi.setDatasource(dataSource);
  };

  function getRowCount() {
    let gridRowsCount = gridApi.getModel().getRowCount();
    setGridRowsCount(gridRowsCount);
    return gridRowsCount;
  }

  function clearGridData() {
    // console.log('In clearGridData()');
    // gridApi.rowData = null;
    gridApi.setRowData([]);

    getRowCount();
  }

  // Fetch Grid Data
  function fetchGridData(lang) {
    // console.log('In fetchGridData(): lang is: ', lang);
    // console.log('In fetchGridData(): resourceLanguage.value is: ', resourceLanguage.value);

    let arrayData = [];

    setFilterDisabledFlag(true);
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        queryType: "hash-query",
        resourcePK: lang,
        keyConditionExpression: "#resourcePK = :resourcePK",
        expressionAttributeNames: { "#resourcePK": "ResourcePK" },
        expressionAttributeValues: { ":resourcePK": lang },
        // "expressionAttributeValues": {":resourcePK": resourceLanguage.value}
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchGridData(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchGridData(): response length is: ', response.data.Items.length);
        // console.log('In fetchGridData(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item, index) => {
          let record = {
            id: "" + index,
            ResourceLanguageCode: item.ResourceLanguageCode,
            ResourcePK: item.ResourcePK,
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
            ModifiedBy: item.ModifiedBy,
            CreatedAt: item.CreatedAt,
            UpdatedAt: item.UpdatedAt,
          };

          arrayData.push(record);
        });

        setGridData(arrayData);

        if (response.data.Items.length > 0) {
          setFilterDisabledFlag(false);
        }

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchGridData(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);
    setResourceLanguage(selectedOption);

    // initializeForm();

    setSelectLangChangedFlag(true);

    // Clear the grid of any data
    clearGridData();

    fetchGridData(selectedOption);
  }

  let selectOptions = [];

  function fetchLanguages() {
    // console.log('In fetchLanguages()');

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages(): response.length is: ', response.data.Items.length);
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            // label: item.LanguageName,
            text: item.LanguageName,
          };

          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        setLangSelectOptions(selectOptions);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );

        setProgressFlag(false);
      });
  }

  useEffect(() => {
    // console.log("In useEffect()");
  }, []);

  useLayoutEffect(() => {
    // console.log("In ManageLanguages(): useLayoutEffect()");

    if (selectOptions.length === 0) {
      fetchLanguages();
    }

    // Fetch resource from catche
    let cacheItemKey = "viewRsr";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In ManageLanguages(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In ManageLanguages(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In ManageLanguages(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In ManageLanguages(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In ManageLanguages(): useLayoutEffect(): got resource from cache');
      setViewRsrRes(cacheItem);
    }
  }, []);

  function cellStyle(params) {
    return { "white-space": "normal" };
  }

  const onGridReady = (params) => {
    // console.log("In onGridReady()");
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
  };

  const gridComponents = {
    loadingRenderer: function (params) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<CircularProgress className={classes.progress} color="secondary" size={15}/>';
      }
    },
  };

  function handleGridFilterChange(event) {
    // console.log("In handleGridFilterChange(): value is: "+event.target.value);
    setGridFilterValue(event.target.value);
    gridApi.setQuickFilter(event.target.value);
  }

  const onRowDataChanged = (event) => {
    setColumnDefs(columnDefsArray);
    setLocaleTextRes(localeText);
    //gridApi = event.api;
    // console.log('onRowDataChanged: event is: ', event);
    // setColumnDefs(columnDefsArray);
    // setGridSubmitFlag(false);
    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getModel().getRowCount() > 0
    ) {
      // console.log('onRowDataChanged: gridApi.getModel().getRowCount() is: ', gridApi.getModel().getRowCount());
      // setGridSubmitFlag(true);

      // Update display of number of rows in grid
      getRowCount();
    }
  };

  const onRowDataUpdated = (event) => {
    // console.log('onRowDataUpdated: event is:  ', event);
  };

  const onFirstDataRendered = (event) => {
    // console.log('onFirstDataRendered: event is:  ', event);
  };

  function getRowCount() {
    let gridRowsCount = gridApi.getModel().getRowCount();
    setGridRowsCount(gridRowsCount);
    return gridRowsCount;
  }

  return (
    <div dir={direction}>
      <div className={classes.root}>
        {/* { (!appContext.signedIn) &&
          <Redirect to='/signIn' />
        } */}

        <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
        <CssBaseline />

        <Container component="div" className={classes.main} maxWidth="lg">
          <div
            id="myGrid"
            style={{ boxSizing: "border-box", width: "100%", height: "50%" }}
          >
            {/* progressFlag &&
              <div style={{textAlign: 'center'}}>
                <CircularProgress className={classes.progress} color="secondary" size={15}/>
              </div>
            */}
            <Container
              component="div"
              className={classes.mainForm}
              maxWidth="md"
            >
              <Grid container justify="center">
                <StyledPaper elevation={0} className={classes.paper}>
                  <Typography
                    variant="h6"
                    style={{ color: "black", textAlign: "center" }}
                  >
                    {viewResources}
                  </Typography>

                  {globalErrorFlag && (
                    <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                  )}

                  <LineSpacing value={12} />
                </StyledPaper>
              </Grid>
            </Container>

            <LineSpacing value={24} />

            {/* AG-GRID */}

            <Container component="div" className={classes.main} maxWidth="lg">
              <Grid container className={classes.filterGrid}>
                <Grid item xs>
                  <Grid container className={classes.filterGrid}>
                    <Grid
                      item
                      xs={3}
                      className={classes.verticalAlignLabel}
                      style={directionAlignment}
                    >
                      <label htmlFor="formLanguageId">
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                        >
                          {dataLanguageLabel}:{" "}
                        </Typography>
                      </label>
                    </Grid>

                    <Grid item xs={8}>
                      <FormControl className={classes.formControl}>
                        <Select
                          id="formLanguageId"
                          value={resourceLanguage}
                          placeholder={selectLanguagePrompt}
                          onChange={handleLanguageChange}
                          variant="outlined"
                          displayEmpty={true}
                          className={classes.selectEmpty}
                          autoFocus
                        >
                          <MenuItem value={null} disabled>
                            <Typography variant="body1">
                              {selectLanguagePrompt}
                            </Typography>
                          </MenuItem>
                          {langSelectOptions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={1}></Grid>
                </Grid>

                <Grid item xs>
                  <Grid container className={classes.filterGrid}>
                    <Grid
                      item
                      xs={2}
                      className={classes.verticalAlignLabel}
                      style={directionAlignment}
                    >
                      <label htmlFor="filterTextId">
                        <Typography
                          variant="subtitle1"
                          className={classes.label}
                        >
                          {filterGridLabel}:{" "}
                        </Typography>
                      </label>
                    </Grid>

                    <Grid item xs={10}>
                      <FormControl
                        className={classes.textField}
                        variant="outlined"
                      >
                        <OutlinedInput
                          id="filterTextId"
                          value={gridFilterValue}
                          placeholder={filterInputPrompt}
                          onChange={handleGridFilterChange}
                          fullWidth
                          autoFocus={autoFocusFlag}
                          type="search"
                          disabled={filterDisabledFlag}
                          startAdornment={
                            <InputAdornment position="start">
                              <Search color="primary" />
                            </InputAdornment>
                          }
                          margin="none"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {renderGridFlag && (
                <div style={{ width: "100%", height: "100%" }}>
                  <div
                    id="refGrid"
                    style={{
                      // height: "400px",
                      width: "100%",
                    }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      localeText={localeTextRes}
                      domLayout="autoHeight"
                      onGridReady={onGridReady}
                      gridOptions={gridOptions}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      rowData={gridData}
                      // rowSelection={rowSelection}
                      rowMultiSelectWithClick={true}
                      rowDeselection={true}
                      suppressRowClickSelection={false}
                      // debug={true}
                      pagination={true}
                      components={gridComponents}
                      paginationPageSize={paginationPageSize}
                      cacheOverflowSize={2}
                      maxConcurrentDatasourceRequests={2}
                      infiniteInitialRowCount={1}
                      maxBlocksInCache={2}
                      enableRtl={enableRtl}
                      onRowDataChanged={onRowDataChanged}
                      onRowDataUpdated={onRowDataUpdated}
                      onFirstDataRendered={onFirstDataRendered}
                    />
                  </div>
                </div>
              )}
            </Container>
          </div>
        </Container>

        <footer className={classes.footer}>
          <div>
            <PageFooter isSignedIn={isSignedIn} />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default ViewResources;
