import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
// import FormControl from '@material-ui/core/FormControl';
// import TextField from '@material-ui/core/TextField';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import Typography from "@material-ui/core/Typography";
// import InputAdornment from '@material-ui/core/InputAdornment';
import LanguageIcon from "@material-ui/icons/Language";
import Button from "@material-ui/core/Button";
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from "@material-ui/core/Popover";
import Link from "@material-ui/core/Link";
// import Popper from '@material-ui/core/Popper';
// import Paper from '@material-ui/core/Paper';
// import ReactTooltip from 'react-tooltip';
import Avatar from "@material-ui/core/Avatar";
import green from "@material-ui/core/colors/brown";

import Grid from "@material-ui/core/Grid";
import { Dialog, DialogTitle } from "@material-ui/core";
// import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import Amplify, { Auth } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

Amplify.configure(AmplifyConfig);

const StyledSpan = styled.span`
  position: 'relative',
  text-align: right !important;
  padding: 0 0 0 0;
`;

const StyledTipButton = styled(Button)`
  padding: 0 0 0 0;
  margin: 0 50px 0 0 !important;
  vertical-align: middle !important;
  line-height: normal !important;
`;

const SelectSpan = styled.span`
  min-width: 50px !important;
`;

const StyledDiv = styled.div`
  min-width: 150px !important;
  margin: 8px 8px 8px 12px;
  cursor: pointer;
`;

const useStyles = makeStyles({
  avatar: {
    width: 27,
    height: 27,
    marginRight: 5,
    marginLeft: 5,
    color: "#fff",
    verticalAlign: "middle",
  },
  popover: {
    width: 200,
    maxHeight: 300,
    verticalAlign: "middle",
  },
});

function SetupLanguages(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  // const [direction, setDirection] = useState("rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const classes = useStyles();

  // const directionAlignment = {
  //   textAlign: direction === 'ltr' ? 'left' : 'right',
  // };

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  const {
    // currentLanguage,
    isSignedIn,
    langPopupId,
    langPopupOpen,
    langAnchorEl,
    handleLangClicked,
    handleLangClose,
    currentLanguageName,
    languages,
    languageSelectChange,

    selectPreferredLanguage,
    dialogModalOpen,
    dialogModalClose,
  } = props;

  // const langRef = useRef(null);

  useEffect(() => {
    // console.log("In SetupLanguages(): useEffect(): currentLanguage is: ", JSON.stringify(currentLanguage, null, 2));
    // console.log("In SetupLanguages(): useEffect(): currentLanguageName is: ", currentLanguageName);
  }, []);

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  return (
    <StyledSpan>
      {/* Popover */}
      <StyledTipButton
        aria-describedby={langPopupId}
        color="primary"
        size="medium"
        onClick={handleLangClicked}
      >
        <Avatar className={classes.avatar}>
          <LanguageIcon
            fontSize="default"
            color="action"
            style={{ textAlign: "middle !important" }}
          />
        </Avatar>
        <SelectSpan>{appContext.currentLanguage.languageName}</SelectSpan>
      </StyledTipButton>

      {/* <Popover
        id={langPopupId}
        open={langPopupOpen}
        anchorEl={langAnchorEl}
        className={classes.popover}
        onClose={handleLangClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        { 
          languages.map((language, index) => {
            return (
              <Link key={index}>
                <StyledDiv key={index} onClick={event => languageSelectChange(event, index)}>
                  {language.LanguageName}
                </StyledDiv>
              </Link>
            )
          })
        }
      </Popover> */}

      <div style={{ alignItems: "center", justifyContent: "center" }}>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          // TransitionComponent={Draggable}
          // TransitionProps={{ handle: '.dialog-title' }}
          open={dialogModalOpen}
          onClose={dialogModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ cursor: "all-scroll", padding: "0" }}
          PaperProps={{ tabIndex: -1 }}
        >
          <DialogContent style={{ margin: 20 }}>
            <Grid container>
              <Grid item xs={12}>
                <IconButton
                  aria-label="Close"
                  size="small"
                  onClick={dialogModalClose}
                  style={directionReverseFloat}
                >
                  <CloseIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>

            {/* <DialogTitle classes={{ root: 'dialog-title' }} id='dialog-modal-title'>
              {selectPreferredLanguage}
            </DialogTitle> */}

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Grid
                item
                xs={12}
                style={{ marginLeft: "8px", marginBottom: "20px" }}
              >
                <Typography variant="h6" style={directionFloat}>
                  {selectPreferredLanguage}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify={
                appContext.direction === "ltr" ? "flex-start" : "flex-end"
              }
              alignItems={
                appContext.direction === "ltr" ? "flex-start" : "flex-end"
              }
              style={{ marginBottom: "20px" }}
            >
              {languages.map((language, index) => {
                return (
                  <Grid key={index} item xs={6} sm={2} style={directionFloat}>
                    <Link key={index}>
                      <StyledDiv
                        key={index}
                        onClick={(event) => languageSelectChange(event, index)}
                      >
                        <Typography variant="subtitle1" style={directionFloat}>
                          {language.LanguageName}
                        </Typography>
                      </StyledDiv>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          </DialogContent>

          {/* <DialogActions>
              <Button color="primary" size="small" onClick={alertClickDisagree} autoFocus>
                  {noLabel}
              </Button>
              <Button color="primary" size="small" onClick={alertClickAgree}>
                  {yesLabel}
              </Button>
          </DialogActions> */}
        </Dialog>
      </div>
    </StyledSpan>
  );
}

export default SetupLanguages;
