import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import Draggable from "react-draggable";
// import styled, { css } from 'styled-components';
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
// import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
// import WarningIcon from '@material-ui/icons/Warning';
// import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
// import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
// import Avatar from '@material-ui/core/Avatar';
// import { blue } from '@material-ui/core/colors';
import Container from "@material-ui/core/Container";
// import CircularProgress from '@material-ui/core/CircularProgress';
import { IconSearch } from "mineral-ui-icons";
import { IconClose } from "mineral-ui-icons";

// AG-Grid
import { AgGridReact, AgGridColumn } from "ag-grid-react";
//import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

// Amplify
import Amplify, { AUTH, API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import ImageCellRenderer from "../renderers/ImageCellRenderer";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    // position: 'absolute',
    right: theme.spacing(1),
    color: theme.palette.grey[500],
    fontSize: "12px",
  },
  progress: {
    margin: theme.spacing(2),
  },
  mainGrid: {
    marginTop: theme.spacing(1),
    textAlign: "left",
  },
}));

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

let socialsDialog = {
  selectSocialNetwork: "Select a social network",
  pleaseSelectSocial: "Please select a social network from the grid below:",
  continueLabel: "Continue",
  icon: "Icon",
  name: "Name",
  iconUrl: "URL",
};

const staticGridData = [
  {
    Icon: "/assets/socials/facebook.png",
    Name: "Facebook",
    IconUrl: "/assets/socials/facebook.png",
  },
  {
    Icon: "/assets/socials/facebook_round_bg.png",
    Name: "Facebook",
    IconUrl: "/assets/socials/facebook_round_bg.png",
  },
  {
    Icon: "/assets/socials/facebook_round_bg.png",
    Name: "Facebook",
    IconUrl: "/assets/socials/facebook_round_bg.png",
  },
  {
    Icon: "/assets/socials/facebook_rounded_corners.png",
    Name: "Facebook",
    IconUrl: "/assets/socials/facebook_rounded_corners.png",
  },

  {
    Icon: "/assets/socials/instagram.jpg",
    Name: "Instagram",
    IconUrl: "/assets/socials/instagram.jpg",
  },
  {
    Icon: "/assets/socials/instagram_black_white.png",
    Name: "Instagram",
    IconUrl: "/assets/socials/instagram_black_white.png",
  },

  {
    Icon: "/assets/socials/linkedin.png",
    Name: "LinkedIn",
    IconUrl: "/assets/socials/linkedin.png",
  },
  {
    Icon: "/assets/socials/linkedin_black_bg.png",
    Name: "LinkedIn",
    IconUrl: "/assets/socials/linkedin_black_bg.png",
  },
  {
    Icon: "/assets/socials/linkedin_black_color.png",
    Name: "LinkedIn",
    IconUrl: "/assets/socials/linkedin_black_color.png",
  },
  {
    Icon: "/assets/socials/linkedin_round.png",
    Name: "LinkedIn",
    IconUrl: "/assets/socials/linkedin_round.png",
  },

  {
    Icon: "/assets/socials/twitter.png",
    Name: "Twitter",
    IconUrl: "/assets/socials/twitter.png",
  },
  {
    Icon: "/assets/socials/twitter_black_bg.png",
    Name: "Twitter",
    IconUrl: "/assets/socials/twitter_black_bg.png",
  },
  {
    Icon: "/assets/socials/twitter_round.png",
    Name: "Twitter",
    IconUrl: "/assets/socials/twitter_round.png",
  },
  {
    Icon: "/assets/socials/twitter_rounded_black_bg.png",
    Name: "Twitter",
    IconUrl: "/assets/socials/twitter_rounded_black_bg.png",
  },
  {
    Icon: "/assets/socials/twitter_rounded_corners.png",
    Name: "Twitter",
    IconUrl: "/assets/socials/twitter_rounded_corners.png",
  },

  {
    Icon: "/assets/socials/vimeo.png",
    Name: "Vimeo",
    IconUrl: "/assets/socials/vimeo.png",
  },
  {
    Icon: "/assets/socials/vimeo_blue.png",
    Name: "Vimeo",
    IconUrl: "/assets/socials/vimeo_blue.png",
  },
  {
    Icon: "/assets/socials/vimeo_round_black.png",
    Name: "Vimeo",
    IconUrl: "/assets/socials/vimeo_round_black.png",
  },
  {
    Icon: "/assets/socials/vimeo_round_blue.png",
    Name: "Vimeo",
    IconUrl: "/assets/socials/vimeo_round_blue.png",
  },
  {
    Icon: "/assets/socials/vimeo_rounded_blue.png",
    Name: "Vimeo",
    IconUrl: "/assets/socials/vimeo_rounded_blue.png",
  },

  {
    Icon: "/assets/socials/youtube.jpg",
    Name: "Youtube",
    IconUrl: "/assets/socials/youtube.jpg",
  },
  {
    Icon: "/assets/socials/youtube.png",
    Name: "Youtube",
    IconUrl: "/assets/socials/youtube.png",
  },
  {
    Icon: "/assets/socials/youtube_white_bg.png",
    Name: "Youtube",
    IconUrl: "/assets/socials/youtube_white_bg.png",
  },
];

function SocialsDraggableDialog(props) {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );
  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  const [gridData, setGridData] = useState(staticGridData);
  const [filterDisabledFlag, setFilterDisabledFlag] = useState(true);
  const [socialsDialogRes, setSocialsDialogRes] = useState(socialsDialog);
  const [paginationPageSize, setPaginationPageSize] = useState(5);

  // AG-Grid
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [progressFlag, setProgressFlag] = useState(false);

  const [wrapperRef, setWrapperRef] = useState();

  const [open, setOpen] = useState(false);
  const iconSearch = <IconSearch style={{ color: "green" }} />;

  const {
    currLanguage,
    onRowClickedSocials,

    dialogOpen,
    onDialogClose,
    // alertClickAgree,
    // alertClickDisagree,

    // dialogTitle,
    // dialogQuestion,
    // dialogMainContent,
    // dialogSubContent,
    // yesLabel,
    // noLabel,
  } = props;

  const classes = useStyles();

  const {
    selectSocialNetwork,
    pleaseSelectSocial,
    continueLabel,
    icon,
    name,
    iconUrl,
  } = socialsDialogRes;

  //---------------------
  // AG-GRID Definitions
  //---------------------

  const columnDefsArray = [
    {
      headerName: icon,
      field: "Icon",
      width: 100,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellRendererFramework: ImageCellRenderer,
    },
    {
      headerName: name,
      field: "Name",
      width: 100,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellStyle: cellStyle,
    },
    {
      headerName: iconUrl,
      field: "IconUrl",
      width: 300,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellStyle: cellStyle,
    },
  ];
  const [columnDefs, setColumnDefs] = useState(columnDefsArray);

  // AG-Grid
  const defColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
  };
  const [defaultColDef, setDefaultColDef] = useState(defColDef);

  const [rowSelection, setRowSelection] = useState("single");

  const gridOptions = {
    style: "currency", // decimal, currency, percent
    currency: "USD",
    currencyDisplay: "symbol", // symbol, code, name to use a localized currency name such as "dollar"
    useGrouping: true,
    minimumIntegerDigits: 21,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    imageWidth: "16px !important", // Will scale image to a width of 32px in ImageCellRenderer component
    divContainerHeight: "32px !important", // Will set div container height to 32px in ImageCellRenderer component to align the image in the middle
    payload: {},
    getRowHeight: function (params) {
      //   console.log("In getRowHeight: params is: ", params);
      // assuming 50 characters per line, working how how many lines we need
      //   console.log("In getRowHeight: words is: ", params.data.Icon.length);
      //   let lines = 36 * (Math.floor(params.data.Icon.length / 45) + 1);
      //   console.log("In getRowHeight: lines is: ", lines);
      let lines = 36;
      return lines;
    },
  };

  function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(socialsDialog);
    // console.log("In SocialsDraggableDialog(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In SocialsDraggableDialog(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "selectSocialNetwork")
              socialsDialog.selectSocialNetwork = item.ResourceDesc;
            if (item.ResourceCode === "pleaseSelectSocial")
              socialsDialog.pleaseSelectSocial = item.ResourceDesc;
            if (item.ResourceCode === "continueLabel")
              socialsDialog.continueLabel = item.ResourceDesc;
            if (item.ResourceCode === "icon")
              socialsDialog.icon = item.ResourceDesc;
            if (item.ResourceCode === "name")
              socialsDialog.name = item.ResourceDesc;
            if (item.ResourceCode === "url")
              socialsDialog.url = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, socialsDialog);
          setSocialsDialogRes(socialsDialog);
        }
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setSocialsDialogRes(socialsDialog);
        setProgressFlag(false);
      });
  }

  function handleClickOutside(event) {
    if (
      wrapperRef &&
      !wrapperRef.contains(event.target) &&
      event.target.tagName === "DIV"
    ) {
      //console.log('You clicked outside of me! tagName is: '+event.target.tagName);
      setOpen(false);
    }
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // console.log("In SocialsDraggableDialog(): useEffect(): isSignedIn is: ", isSignedIn);
    // console.log("In SocialsDraggableDialog(): useEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));
    // document.addEventListener('mousedown', handleClickOutside);

    // currLanguage is the most current language passed to this component through props
    // We are using useEffect to get the functionality of componentDidUpdate()
    if (
      currLanguage !== undefined &&
      currLanguage !== null &&
      currLanguage !== currentLanguage
    ) {
      setCurrentLanguage(currLanguage);
      appContext.currentLanguage = currLanguage;

      // console.log('In SocialsDraggableDialog(): useEffect(): about to call fetchDbResources()');

      // Fetch resource from catche
      let cacheItemKey = "socialsDialog";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      // cacheItemKey += currLanguage.languageCode;
      // console.log('In SocialsDraggableDialog(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        // console.log("In SocialsDraggableDialog(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
        RemoveCacheItem(cacheItemKey);
      }

      let cacheItem = GetCacheItem(cacheItemKey);
      // console.log('In SocialsDraggableDialog(): useLayoutEffect(): cacheItem is: ', cacheItem);

      if (cacheItem === null) {
        // resource is not in cache, fetch from DB
        // console.log('In SocialsDraggableDialog(): useLayoutEffect(): resource is not in cache, fetch from DB');
        fetchDbResources(cacheItemKey);
      } else {
        // console.log('In SocialsDraggableDialog(): useLayoutEffect(): got resource from cache');
        setSocialsDialogRes(cacheItem);
      }
    }
  });

  useLayoutEffect(() => {
    // console.log("In SocialsDraggableDialog(): useLayoutEffect(): isSignedIn is: ", isSignedIn);
    // console.log("In SocialsDraggableDialog(): useLayoutEffect(): currLanguage is: ", JSON.stringify(currLanguage, null, 2));
    // console.log("In SocialsDraggableDialog(): useLayoutEffect(): languagesLabel is: ", JSON.stringify(languagesLabel, null, 2));

    // Fetch resource from catche
    let cacheItemKey = "socialsDialog";
    cacheItemKey += ":";
    cacheItemKey += appContext.currentLanguage.languageCode;
    // console.log('In SocialsDraggableDialog(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

    if (
      appContext.clearCacheLanguageCodes &&
      appContext.clearCacheLanguageCodes.length > 0 &&
      appContext.clearCacheLanguageCodes.includes(
        appContext.currentLanguage.languageCode
      )
    ) {
      // console.log("In SocialsDraggableDialog(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
      RemoveCacheItem(cacheItemKey);
    }

    let cacheItem = GetCacheItem(cacheItemKey);
    // console.log('In SocialsDraggableDialog(): useLayoutEffect(): cacheItem is: ', cacheItem);

    if (cacheItem === null) {
      // resource is not in cache, fetch from DB
      // console.log('In SocialsDraggableDialog(): useLayoutEffect(): resource is not in cache, fetch from DB');
      fetchDbResources(cacheItemKey);
    } else {
      // console.log('In SocialsDraggableDialog(): useLayoutEffect(): got resource from cache');
      setSocialsDialogRes(cacheItem);
    }
  }, []);

  const gridComponents = {
    loadingRenderer: function (params) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<CircularProgress className={classes.progress} color="secondary" size={15}/>';
      }
    },
  };

  //------------------------
  // Grid Functions
  //------------------------

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
  }

  function cellStyle(params) {
    return { "white-space": "normal" };
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        TransitionComponent={Draggable}
        open={dialogOpen}
        onClose={onDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ cursor: "all-scroll", padding: "0" }}
        PaperProps={{ tabIndex: "-1" }}
      >
        <DialogContent dividers style={{ textAlign: "center" }}>
          <Grid container>
            <Grid item xs={12}>
              <IconButton
                aria-label="Close"
                size="small"
                onClick={onDialogClose}
                style={{ float: "right" }}
              >
                <IconClose style={{ float: "right" }} />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs={12}>
              <Typography variant="caption" style={{ textAlign: "center" }}>
                {pleaseSelectSocial}
              </Typography>
            </Grid>
          </Grid>

          {/* Grid */}
          <Container component="div" className={classes.mainGrid} maxWidth="lg">
            <div style={{ width: "100%", height: "100%" }}>
              <div
                id="refGrid"
                style={{
                  height: "250px",
                  width: "100%",
                }}
                className="ag-theme-balham"
              >
                <AgGridReact
                  localeText={null}
                  domLayout="autoHeight"
                  onGridReady={onGridReady}
                  gridOptions={gridOptions}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  rowData={gridData}
                  rowSelection={rowSelection}
                  rowMultiSelectWithClick={true}
                  rowDeselection={true}
                  suppressRowClickSelection={false}
                  // debug={true}
                  pagination={true}
                  components={gridComponents}
                  paginationPageSize={paginationPageSize}
                  cacheOverflowSize={2}
                  maxConcurrentDatasourceRequests={2}
                  infiniteInitialRowCount={1}
                  maxBlocksInCache={2}
                  enableRtl={enableRtl}
                  onRowClicked={onRowClickedSocials}
                />
              </div>
            </div>
          </Container>
        </DialogContent>

        {/* <DialogActions>
                    <Button color="primary" size="small" onClick={alertClickDisagree} autoFocus>
                        {noLabel}
                    </Button>
                    <Button color="primary" size="small" onClick={alertClickAgree}>
                        {yesLabel}
                    </Button>
                </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default SocialsDraggableDialog;
