import React from "react";

let appContext = {
  domain: "ccms",
  shortDomain: "ccms",
  domainName: "cloudcms.ws", // In lowercase
  subDomain: "", // e.g. blog
  domainOwner: "TobiSoft Inc.",
  userName: "",
  clientId: null,
  registerMeClientId: null,
  dataLanguageCode: null, // The language code in which the data was saved
  profileUrl: null, // The S3 URL of the user
  deceasedUrls: [], // The array of decesed objects with photo urls
  
  userPoolId: "us-east-1_vllH1y2br", // TsiUserPool userPoolId for cob

  // uploadCategory: "gallery", // e.g. "profile", "gallery", etc
  s3UploadItemId: null, // either userName or deceasedCode. This will be set before using UploadFiles
  referrer: null, // The route of the module requesting the action (example upload). e.g. "/userDashboard"
  referrerAction: null, // e.g. register, create, edit etc
  activeStep: 0, // The active step to open in the dashboard
  dashboardActiveTab: 0, // The active step to open in the wizard flow
  currentPK: null,
  currentSK: null,

  onlineCount: 0,// Number of users currently online

  // For UploadFiles.js
  uploadFilesHdr: null,
  acceptedFiles: null,
  storageLevel: null,
  keyPrefix: null,
  filesLimit: null, // 1 for profles
  showFileNamesInPreview: null,
  showFileNames: null,
  showPreviewsFlag: null,
  showPreviewsInDropzoneFlag: null,
  uploadCategory: null, // profile, gallery, etc
  profileImageSrcFlag: "no", // "yes" if during Storage.get is successful

  imageDimensions: [
    {name: "original", value: 2500},
    // {name: "small100", value: 100},
    {name: "small300", value: 300},

    {name: "medium500", value: 500},
    // {name: "medium750", value: 750},

    {name: "large1500", value: 1500},
    // {name: "large2500", value: 2500},
  ],

  // For LiveEvents Image Dimensions in pixels
  eventImgDimensions: [
    {
      name: "thumbnail",
      resizeWidth: 125,// 75x75, 100x100, 125x125, 150x150 to 200x200
      resizeHeight: 125,
    },

    {
      name: "banner",
      resizeWidth: 1024,// 1024px, 1280px, 1366px, 1600px, 1920px
      resizeHeight: 256,
    },

    {
      name: "poster",
      resizeWidth: 1280,
      resizeHeight: 720,
    },
  ],
  
  storageCfUrl: "https://dj81v6kj43pls.cloudfront.net/", // tsi.storage CloudFront Distribution
  appCfUrl: "https://d22viuz4anqcau.cloudfront.net/", // CloudObituary CloudFront Distribution

  groupName: "",
  sysRole: "",
  precedence: "",
  iamRole: "",
  acceptedTerms: null,

  salutation: "",
  nickName: "",
  firstName: "",
  middleName: "",
  lastName: "",

  signedIn: false,
  guestAuthenticated: false, // when a guest code has been verified (e.g. during guest book signing)
  jwtToken: "",
  direction: "ltr",
  mandatorySignIn: true,
  currentLanguage: {
    languageCode: "en", // Change this when user selects a new language
    languageName: "English", // Change this when user selects a new language
  },
  userLocale: {
    // Populate this from user record when user signs in (can't be changed)
    languageCode: "en",
    languageName: "English",
    localeCode: "en-US"
  },
  defaultCurrency: {
    currencyCode: "USD",
    currencyName: "United States Dollar",
  },
  phoneNumber: {
    defaultPhoneCountry: "us", // For phone flag
    phoneLocale: "en-US", // For phone validation
    preferredCountries: ["mx", "us", "ca"], //Show these countries at top of dropdown
    localization: {
      Germany: "Deutschland",
      Spain: "España",
    },
  },
  
  mediaQueries: {
    mobileView: "(max-width: 767px)",
    tabletView: "(min-width: 768px) and (max-width: 1024px)",
    deskTopView: "(min-width: 1025px)",
    extraLargeView: "(min-width: 1180px)",
    
    // mobileView: "(max-width: 599px)",
    // tabletView: "(min-width: 600px) and (max-width: 1199px)",
    // deskTopView: "(min-width: 1200px)",

    // xs: "(max-width: 599px)",
    // sm: "(min-width: 600px) and (max-width: 959px)",
    // md: "(min-width: 960px) and (max-width: 1279px)",
    // lg: "(min-width: 1280px) and (max-width: 1919px)",
    // xl: "(min-width: 1920px)",
  },
  appsInfoFooter: {
    renderGoogleAppleDomFlag: true,
    googleAppleInfoFlag: true,

    downloadApp: "Download our mobile App now!",

    googleMediaUrl: "/assets/apps-info/google-play-badge_en.png",
    appleMediaUrl: "/assets/apps-info/app_store_badge_en.svg",

    googlePlayLinkUrl: "https://play.google.com/store/apps/",
    googlePlayMediaUrl: "/assets/apps-info/google-play-badge_en.png",

    appleStoreLinkUrl: "https://apps.apple.com/us/",
    appleStoreMediaUrl: "/assets/apps-info/app_store_badge_en.svg",
  },
  pageContent: {
    // The page content template to render
    selectedHomeTemplate: "HomeTemplate01",

    selectedTermsTemplate: "TermsTemplate01",
    selectedPrivacyTemplate: "PrivacyTemplate01",
    selectedSecurityTemplate: "SecurityTemplate01",
  },
  designBlocks: {
    // The design block template to render
    selectedContactsTemplate: "",
    selectedContentsTemplate: "",
    selectedFeaturesTemplate: "",
    selectedFootersTemplate: "",
    selectedFormsTemplate: "",
    selectedHeadersTemplate: "",
    selectedPricingTemplate: "",
    selectedTeamsTemplate: "",
    selectedTestimonialsTemplate: "TestimonialsTemplate01",
  },
  webSocket: {
    region: "us-east-1",
    endpoint: "wss://meh4i9ug6i.execute-api.us-east-1.amazonaws.com/dev",
  },
  // A live event could be started xx minutes to the actual start time (liveEventTime)
  liveEventLeadTime: 30,// minutes
  userPreferences: {
    lastLanguageCode: "en", // This is the last language the user selected - may be better to set it in cache
  },
  // Array of language codes whose cache is to be refreshed by removing them in cache if in cache already
  // Clear the cache for language whenever the resources for language changes (added, deleted, changed)
  // Clear existing cache then refresh
  clearCacheLanguageCodes: [
    "en",
    "zh",
    // "ar",
  ],
};

const AppContext = React.createContext(appContext);

export default AppContext;
