import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import SaveIcon from "@material-ui/icons/Save";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// Colors
import { red } from "@material-ui/core/colors";
import { green } from "@material-ui/core/colors";

// react-select
// import ReactSelect from 'react-select';
// import MomentUtils from '@date-io/moment';

// Moment
import moment from "moment";
// import Moment from 'react-moment';
// import 'moment-timezone';
import "moment/locale/ar";
import "moment/locale/he";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/ko";
import "moment/locale/fr";
import "moment/locale/zh-cn";

// AG-Grid
import { AgGridReact, AgGridColumn } from "ag-grid-react";
//import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

// Amplify
import Amplify, { API } from "aws-amplify";
import AmplifyConfig from "../../config/AmplifyConfig";

// AppContext
import AppContext from "../../config/AppContext";

import GlobalErrorMessage from "../../components/dialogs/GlobalErrorMessage";

// Cache Utilities
import SetCacheItem from "../../utils/cache/SetCacheItem";
import GetCacheItem from "../../utils/cache/GetCacheItem";
import RemoveCacheItem from "../../utils/cache/RemoveCacheItem";
import GenerateModulusHash from "../../utils/GenerateModulusHash";

import AppMainMenu from "../headers/AppMainMenu";
import PageFooter from "../footers/PageFooter";
import LineSpacing from "../../components/misc/LineSpacing";
import TsiCustomizedSnackbars from "../../components/misc/TsiCustomizedSnackbars";

// Renderers
import DateCellRenderer from "../../components/renderers/DateCellRenderer";
// import NumberCellRenderer from '../../components/renderers/NumberCellRenderer';
// import ActionsCellRenderer from '../../components/renderers/ActionsCellRenderer';

Amplify.configure(AmplifyConfig);

const StyledPaper = styled(Paper)`
  min-width: 300px;
  /*background: gray !important;*/
  padding: 10px 20px 10px 20px !important;
  text-align: center !important;
  align-items: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100% !important",
    display: "flex",
    flexDirection: "column",
    // minHeight: '100vh',
    position: "relative",
    backgroundImage: `url(${"assets/logos/background20.png"})`,
  },
  gridWidth: {
    width: "80%",
    leftMargin: theme.spacing(2),
    rightMargin: theme.spacing(2),
  },
  colorRed: {
    color: theme.palette.secondary.main,
  },
  colorGreen: {
    // Breakpoints
    [theme.breakpoints.only("xs")]: {
      // extra-small: 0px
      color: green[900],
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("sm")]: {
      // small: 600px
      color: green[900],
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up("md")]: {
      // medium: 960px
      color: green[900],
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up("lg")]: {
      // large: 1280px
      color: green[900],
      // backgroundColor: green[500],
    },
    [theme.breakpoints.between("lg", "xl")]: {
      // extra-large: 1920px
      color: green[900],
      // backgroundColor: green[500],
    },
  },
  main: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mainForm: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    minWidth: "300px",
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: "10px 30px 10px 30px !important",
    textAlign: "center",
  },
  verticalAlignLabel: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  formControl: {
    display: "flex",
    textAlign: "left",
  },
  footer: {
    width: "100% !important",
    marginTop: "auto",
    textAlign: "center",
  },
  gridHeader: {
    fontWeight: "bold",
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  snackIcon: {
    marginRight: theme.spacing(1),
  },
}));

// For AG-Grid

const gridRowsData = [];

//------------------------------
// RENDERERS
//------------------------------
const gridOptions = {
  style: "currency", // decimal, currency, percent
  currency: "USD",
  currencyDisplay: "symbol", // symbol, code, name to use a localized currency name such as "dollar"
  useGrouping: true,
  minimumIntegerDigits: 21,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  payload: {},

  weekday: "short", // long, short
  year: "numeric",
  month: "short", // long, short
  day: "numeric",
  // timeZone: 'America/New_York', // Auto calculated in DateCellRenderer
  timeZoneName: "short", // long, short
  hour12: true,
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  locale: "default", // To use the browser's default locale, use 'default'
  // getRowNodeId: function(item) {
  //   return item.id;
  // },

  getRowHeight: function (params) {
    // console.log("In getRowHeight: params is: ", params);
    // assuming 50 characters per line, working how how many lines we need
    // console.log("In getRowHeight: words is: ", params.data.StartDate.length);
    let lines = 36;
    // let lines = 36 * (Math.floor(params.data.PageContent.length / 45) + 1);
    // console.log("In getRowHeight: lines is: ", lines);
    return lines;
  },
};

let convertRefData = {
  allFieldsAreRequired: "All fields are required",
  convertPageDataLabel: "Convert page content",
  convertRecordsMsg:
    "Only records not previously converted, would be converted",
  convertFrom: "Convert from (Source)",
  selectSourceLanguage: "Please select source language",
  convertTo: "Convert to (Target)",
  selectTargetLanguage: "Please select target language",
  selectPageName: "Please select page name",
  pageNameLabel: "Page name",
  selectPageToConvert: "Please select the page to convert",

  submit: "Submit",
  convertPageContentSuccessMsg: "Page content conversion was successful.",
  convertPageContentFailureMsg: "Error while converting page content.",
  noDataToConvert: "No records found to convert",

  pageId: "Page ID",
  pageContentLabel: "Page content",
  pageHeaderLabel: "Page header",
  languageNameLabel: "Language",
  startDateLabel: "Start date",
  endDateLabel: "End date",

  modifiedBy: "Modified by",
  createdAt: " Create date",
  updatedAt: "Modified date",

  // For Grid
  gridPage: "Page",
  gridTo: "to",
  gridOf: "of",
  noRowsToShow: "No records found",
};

function cellStyle(params) {
  return { "white-space": "normal" };
}

let localeText = {
  page: "Page",
  to: "to",
  of: "of",
  noRowsToShow: "No records found",
};

function ConvertPageData() {
  const appContext = useContext(AppContext);

  const [direction, setDirection] = useState(appContext.direction);
  const [enableRtl, setEnableRtl] = useState(direction === "rtl");
  const [currentLanguage, setCurrentLanguage] = useState(
    appContext.currentLanguage
  );

  const [isSignedIn, setIsSignedIn] = useState(appContext.signedIn);

  // For Global Errors
  const [globalErrorFlag, setGlobalErrorFlag] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");

  const defaultLang = {
    value: appContext.currentLanguage.languageCode,
    text: appContext.currentLanguage.languageName,
  };
  const [resourceLanguage, setResourceLanguage] = useState(null);
  const [targetResourceLanguage, setTargetResourceLanguage] = useState(null);
  const [langSelectOptions, setLangSelectOptions] = useState([]);
  const [targetLangSelectOptions, setTargetLangSelectOptions] = useState([]);
  const [pageName, setPageName] = useState(null);
  const [pageNameSelectOptions, setPageNameSelectOptions] = useState([]);

  const [formSubmitFlag, setFormSubmitFlag] = useState(false);
  // const [progressFlag, setProgressFlag] = useState(false);

  // For react-select
  // const [pageNameReactSelectOptions, setPageNameReactSelectOptions] = useState([]);
  // const [reactSelectedOption, setReactSelectedOption] = useState([]);

  // For Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarsMsg, setSnackbarsMsg] = useState("");
  const [snackbarsVariant, setSnackbarsVariant] = useState("success"); // 'success', 'warning', 'error', 'info'

  // For Resources
  const [convertRefDataRes, setConvertRefDataRes] = useState(convertRefData);

  const [renderContentFlag, setRenderContentFlag] = useState(false);

  // For Grid
  const [gridData, setGridData] = useState(gridRowsData);
  const [gridFilterValue, setGridFilterValue] = useState("");
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  // const [currentEditRow, setCurrentEditRow] = useState(null);// Current row being edited
  // const [ editRowIndex, setEditRowIndex ] = useState(-1);

  // AG-Grid
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [progressFlag, setProgressFlag] = useState(false);
  const [rowSelectedFlag, setRowSelectedFlag] = useState(false);
  const [gridSubmitFlag, setGridSubmitFlag] = useState(false);
  const [gridRowsCount, setGridRowsCount] = useState(0);
  const [gridSelectedPage, setGridSelectedPage] = useState(null);

  const [filterDisabledFlag, setFilterDisabledFlag] = useState(true);

  // For Grid Resources
  const [localeTextRes, setLocaleTextRes] = useState(localeText);

  const classes = useStyles();

  const directionAlignment = {
    textAlign: direction === "ltr" ? "left" : "right",
  };

  const directionFloat = {
    float: appContext.direction === "ltr" ? "left" : "right",
  };

  const directionReverseFloat = {
    float: appContext.direction === "ltr" ? "right" : "left",
  };

  const {
    allFieldsAreRequired,
    convertPageDataLabel,
    convertRecordsMsg,
    convertFrom,
    selectSourceLanguage,
    convertTo,
    selectTargetLanguage,
    selectPageName,
    pageNameLabel,
    selectPageToConvert,

    submit,
    convertPageContentSuccessMsg,
    convertPageContentFailureMsg,
    noDataToConvert,

    pageId,
    pageContentLabel,
    pageHeaderLabel,
    languageNameLabel,
    startDateLabel,
    endDateLabel,

    modifiedBy,
    createdAt,
    updatedAt,
  } = convertRefDataRes;

  async function fetchDbResources(cacheItemKey) {
    let keys = [];
    let objKeys = Object.keys(convertRefData);
    // console.log("In ViewLanguages(): fetchDbResources(): objKeys are: ", JSON.stringify(objKeys, null, 2));
    if (objKeys !== null && objKeys.length > 0) {
      objKeys.map((key) => {
        let partitionKey = appContext.currentLanguage.languageCode;
        partitionKey += ".";
        partitionKey += GenerateModulusHash(key, 200);

        let itemKey = {
          ResourcePK: partitionKey, // e.g. 'en.91'
          ResourceSK: key, // e.g. 'firstName'
        };
        keys.push(itemKey);
      });
    }
    // console.log("In ViewLanguages(): fetchDbResources(): keys are: ", JSON.stringify(keys, null, 2));

    let arrayData = [];

    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/batchGet",
      payload: {
        keys: keys,
        projectionExpression: "ResourceCode,ResourceDesc",
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchDbResources(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response, null, 2));
        // console.log('In fetchDbResources(): response length is: ', response.data.Responses.resource.length);
        // console.log('In fetchDbResources(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Responses.resource.map((item, index) => {
          let record = {
            ResourceCode: item.ResourceCode,
            ResourceDesc: item.ResourceDesc,
          };

          arrayData.push(record);
        });

        if (arrayData && arrayData.length > 0) {
          arrayData.map((item) => {
            if (item.ResourceCode === "allFieldsAreRequired")
              convertRefData.allFieldsAreRequired = item.ResourceDesc;
            if (item.ResourceCode === "convertPageDataLabel")
              convertRefData.convertPageDataLabel = item.ResourceDesc;
            if (item.ResourceCode === "convertRecordsMsg")
              convertRefData.convertRecordsMsg = item.ResourceDesc;
            if (item.ResourceCode === "convertFrom")
              convertRefData.convertFrom = item.ResourceDesc;
            if (item.ResourceCode === "selectSourceLanguage")
              convertRefData.selectSourceLanguage = item.ResourceDesc;
            if (item.ResourceCode === "convertTo")
              convertRefData.convertTo = item.ResourceDesc;
            if (item.ResourceCode === "selectTargetLanguage")
              convertRefData.selectTargetLanguage = item.ResourceDesc;
            if (item.ResourceCode === "submit")
              convertRefData.submit = item.ResourceDesc;
            if (item.ResourceCode === "convertPageContentSuccessMsg")
              convertRefData.convertResSuccessMsg = item.ResourceDesc;
            if (item.ResourceCode === "convertPageContentFailureMsg")
              convertRefData.convertResFailureMsg = item.ResourceDesc;
            if (item.ResourceCode === "noDataToConvert")
              convertRefData.noDataToConvert = item.ResourceDesc;
            if (item.ResourceCode === "selectPageName")
              convertRefData.selectPageName = item.ResourceDesc;
            if (item.ResourceCode === "pageNameLabel")
              convertRefData.pageNameLabel = item.ResourceDesc;
            if (item.ResourceCode === "selectPageToConvert")
              convertRefData.selectPageToConvert = item.ResourceDesc;

            if (item.ResourceCode === "pageId")
              convertRefData.pageId = item.ResourceDesc;
            if (item.ResourceCode === "pageContentLabel")
              convertRefData.pageContentLabel = item.ResourceDesc;
            if (item.ResourceCode === "pageHeaderLabel")
              convertRefData.pageHeaderLabel = item.ResourceDesc;
            if (item.ResourceCode === "languageNameLabel")
              convertRefData.languageNameLabel = item.ResourceDesc;
            if (item.ResourceCode === "startDateLabel")
              convertRefData.startDateLabel = item.ResourceDesc;
            if (item.ResourceCode === "endDateLabel")
              convertRefData.endDateLabel = item.ResourceDesc;

            if (item.ResourceCode === "modifiedBy")
              convertRefData.modifiedBy = item.ResourceDesc;
            if (item.ResourceCode === "createdAt")
              convertRefData.createdAt = item.ResourceDesc;
            if (item.ResourceCode === "updatedAt")
              convertRefData.updatedAt = item.ResourceDesc;

            // For Grid
            if (item.ResourceCode === "gridPage")
              localeText.page = item.ResourceDesc;
            if (item.ResourceCode === "gridTo")
              localeText.to = item.ResourceDesc;
            if (item.ResourceCode === "gridOf")
              localeText.of = item.ResourceDesc;
            if (item.ResourceCode === "noRowsToShow")
              localeText.noRowsToShow = item.ResourceDesc;
          });
          // Add it to cache
          SetCacheItem(cacheItemKey, convertRefData);
        }

        setConvertRefDataRes(convertRefData);
        setLocaleTextRes(localeText);
        setRenderContentFlag(true);

        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchDbResources(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setConvertRefDataRes(convertRefData);
        setProgressFlag(false);
      });
  }

  // Fetch Reference Type Options
  async function fetchPageNameOptions(lang) {
    // console.log('In fetchPageNameOptions(): lang is: ', lang);

    let selectOptions = [];
    let reactSelectOptions = [];

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "web-pages",
          ":resourceSK": lang,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchPageNameOptions(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj).then((response) => {
      // console.log('In fetchPageNameOptions(): response length is: ', response.data.Items.length);
      // console.log('In fetchPageNameOptions(): response is: ', JSON.stringify(response.data.Items, null, 2));
      response.data.Items.map((item) => {
        let record = {
          value: item.ResourceCode,
          text: item.ResourceDesc,
        };
        selectOptions.push(record);
      });

      selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
      setPageNameSelectOptions(selectOptions);
    }).catch((err) => {
      console.error("In fetchPageNameOptions(): err is: ", JSON.stringify(err, null, 2));
    });
  }

  let selectOptions = [];

  async function fetchLanguages() {
    // console.log('In fetchLanguages()');
    setProgressFlag(true);

    let params = {
      apiName: "ResourceAPI",
      path: "/dynamodb/resources/query",
      payload: {
        keyConditionExpression:
          "#resourcePK = :resourcePK AND begins_with(#resourceSK, :resourceSK)",
        expressionAttributeNames: {
          "#resourcePK": "ResourcePK",
          "#resourceSK": "ResourceSK",
        },
        expressionAttributeValues: {
          ":resourcePK": "cms-languages",
          ":resourceSK": currentLanguage.languageCode,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchLanguages(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj)
      .then((response) => {
        // console.log('In fetchLanguages(): response.length is: ', response.data.Items.length);
        // console.log('In fetchLanguages(): response is: ', JSON.stringify(response.data.Items, null, 2));
        response.data.Items.map((item) => {
          let record = {
            value: item.ResourceCode,
            text: item.LanguageName,
          };
          selectOptions.push(record);
        });

        selectOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
        // selectOptions.sort((a, b) => (a.text > b.text) ? 1 : (a.color === b.color) ? ((a.size > b.size) ? 1 : -1) : -1 );
        setLangSelectOptions(selectOptions);
        setProgressFlag(false);
      })
      .catch((err) => {
        console.log(
          "In fetchLanguages(): err is: ",
          JSON.stringify(err, null, 2)
        );
        setProgressFlag(false);
      });
  }

  useEffect(() => {
    console.log("In useEffect()");
  }, []);

  useLayoutEffect(() => {
    // console.log("In ManageLanguages(): useLayoutEffect()");
    async function fetchItems() {
      if (selectOptions.length === 0) {
        await fetchLanguages();
      }

      // Fetch resource from catche
      let cacheItemKey = "convertRefData";
      cacheItemKey += ":";
      cacheItemKey += appContext.currentLanguage.languageCode;
      // console.log('In ManageLanguages(): useLayoutEffect(): cacheItemKey is: ', cacheItemKey);

      if (
        appContext.clearCacheLanguageCodes &&
        appContext.clearCacheLanguageCodes.length > 0 &&
        appContext.clearCacheLanguageCodes.includes(
          appContext.currentLanguage.languageCode
        )
      ) {
        // console.log("In ManageLanguages(): useLayoutEffect(): removing cacheItem for ", appContext.currentLanguage.languageCode);
        RemoveCacheItem(cacheItemKey);
      }

      let cacheItem = GetCacheItem(cacheItemKey);
      // console.log('In ManageLanguages(): useLayoutEffect(): cacheItem is: ', cacheItem);

      if (cacheItem === null) {
        // resource is not in cache, fetch from DB
        // console.log('In ManageLanguages(): useLayoutEffect(): resource is not in cache, fetch from DB');
        await fetchDbResources(cacheItemKey);
      } else {
        // console.log('In ManageLanguages(): useLayoutEffect(): got resource from cache');
        setConvertRefDataRes(cacheItem);
      }
    }
  
    fetchItems();
  }, []);

  function initializeForm() {
    // console.log("In initializeForm()");
    setPageName(null);
    setTargetResourceLanguage(null);
    setFormSubmitFlag(false);
    clearGridData();
  }

  async function convertService() {
    // console.log("In convertService(): resourceLanguage is: ", resourceLanguage);
    // console.log("In convertService(): targetResourceLanguage is: ", targetResourceLanguage);
    // console.log("In convertService(): pageName is: ", pageName);
    // console.log("In convertService(): gridSelectedPage is: ", gridSelectedPage);

    setProgressFlag(true);
    setGlobalErrorMsg("");
    setGlobalErrorFlag(false);

    // const d = new Date();
    const timestamp = new Date();
    // const timestamp = d.toISOString();
    // console.log("In convertService(): timestamp is: ", timestamp);

    let params = {
      apiName: "TranslatePageContentAPI",
      path: "/dynamodb/pageContent/translatePageContent",
      payload: {
        domain: appContext.domain,
        pagePK: gridSelectedPage.PagePK,
        pageSK: gridSelectedPage.PageSK,
        pageName: pageName,
        sourceLanguageCode: resourceLanguage,
        targetLanguageCode: targetResourceLanguage,

        modifiedBy: appContext.userName,
        createdAt: timestamp,
        // "updatedAt": timestamp
      },
    };
    console.log(
      "In convertService(): params is: ",
      JSON.stringify(params, null, 2)
    );

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In convertService(): initObj is: ', JSON.stringify(initObj, null, 2));

    setProgressFlag(true);

    API.post(params.apiName, params.path, initObj).then((response) => {
      // console.log("In convertService(): response is: ", JSON.stringify(response, null, 2));
      setProgressFlag(false);

      if (response.data.convertedItemsCount > 0) {
        // Show Success Snackbar
        setSnackbarsVariant("success");
        setSnackbarsMsg(convertPageContentSuccessMsg);
        setSnackbarOpen(true);

        setRowSelectedFlag(false);
        initializeForm();
      } else {
        // Show Warning Snackbar
        setSnackbarsVariant("warning");
        setSnackbarsMsg(noDataToConvert);
        setSnackbarOpen(true);
      }
    }).catch((err) => {
      console.error("In convertService(): err is: ", JSON.stringify(err, null, 2));
      setGlobalErrorFlag(true);
      setGlobalErrorMsg(convertPageContentFailureMsg);

      setProgressFlag(false);
      setFormSubmitFlag(false);
    });
  }

  async function handleLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleLanguageChange(): selectedOption is', selectedOption);
    setResourceLanguage(selectedOption);

    setGlobalErrorMsg("");
    setGlobalErrorFlag(false);

    setFormSubmitFlag(false);
    setPageName(null);

    // Clear the grid of any data
    clearGridData();

    // Filter out selected option from target options
    let flteredRecs = [];
    // let flteredRecs = langSelectOptions.filter(record => record.value !== selectedOption);
    if (selectedOption === "ko") {
      // Can't convert from Korean to Hebrew
      flteredRecs = langSelectOptions.filter(
        (record) => record.value !== selectedOption && record.value !== "he"
      );
    } else if (selectedOption === "zh-TW") {
      // Can't convert from Tradional to Simplified Chinese and vice versa
      flteredRecs = langSelectOptions.filter(
        (record) => record.value !== selectedOption && record.value !== "zh"
      );
    } else if (selectedOption === "zh") {
      flteredRecs = langSelectOptions.filter(
        (record) => record.value !== selectedOption && record.value !== "zh-TW"
      );
    } else if (selectedOption === "no") {
      // Can't convert from Norwegian to Arabic or Hebrew
      flteredRecs = langSelectOptions.filter(
        (record) =>
          record.value !== selectedOption &&
          record.value !== "ar" &&
          record.value !== "he"
      );
    } else {
      flteredRecs = langSelectOptions.filter(
        (record) => record.value !== selectedOption
      );
    }
    setTargetLangSelectOptions(flteredRecs);
    setTargetResourceLanguage(null);

    if (
      selectedOption !== null &&
      selectedOption !== null &&
      selectedOption !== "" &&
      targetResourceLanguage !== null &&
      targetResourceLanguage !== null &&
      targetResourceLanguage !== "" &&
      pageName !== null &&
      pageName !== null &&
      pageName !== ""
    ) {
      setFormSubmitFlag(true);
    }

    await fetchPageNameOptions(selectedOption);
  }

  function handleTargetLanguageChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handleTargetLanguageChange(): selectedOption is', selectedOption);
    setTargetResourceLanguage(selectedOption);

    setGlobalErrorMsg("");
    setGlobalErrorFlag(false);

    if (
      selectedOption !== null &&
      selectedOption !== null &&
      selectedOption !== "" &&
      resourceLanguage !== null &&
      resourceLanguage !== null &&
      resourceLanguage !== "" &&
      pageName !== null &&
      pageName !== null &&
      pageName !== ""
    ) {
      setFormSubmitFlag(true);
    }
  }

  function handlePageNameChange(event) {
    let selectedOption = event.target.value;
    // console.log('In handlePageNameChange(): selectedOption is', selectedOption);
    setPageName(selectedOption);

    setGlobalErrorMsg("");
    setGlobalErrorFlag(false);

    if (resourceLanguage && resourceLanguage !== null) {
      fetchGridData(selectedOption);
    }
  }

  function snackbarOnClose(event, reason) {
    // console.log('In snackbarOnClose(): event is', event);
    setSnackbarOpen(false);
  }

  //---------------------
  // AG-GRID Definitions
  //---------------------

  const columnDefsArray = [
    {
      headerName: "PagePK",
      field: "PagePK",
      width: 100,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: pageId,
      field: "PageSK",
      width: 150,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: false,
    },
    {
      headerName: pageContentLabel,
      field: "PageContent",
      width: 250,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      cellStyle: cellStyle,
      hide: false,
    },
    {
      headerName: languageNameLabel,
      field: "LanguageCode",
      width: 100,
      pinned: false,
      editable: false,
      resizable: true,
      filter: true,
      sortable: true,
      hide: true,
    },
    {
      headerName: pageHeaderLabel,
      field: "PageHeader",
      width: 202,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      hide: false,
    },
    {
      headerName: "PageSubHeader",
      field: "PageSubHeader",
      width: 210,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      filter: "agTextColumnFilter",
      hide: true,
    },
    {
      headerName: startDateLabel,
      field: "StartDate",
      width: 140,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      // cellRenderer: DateCellRenderer,
      // filter: "agDateColumnFilter",
    },
    {
      headerName: endDateLabel,
      field: "EndDate",
      width: 140,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      // cellRenderer: DateCellRenderer,
      // filter: "agDateColumnFilter",
    },
    {
      headerName: modifiedBy,
      field: "ModifiedBy",
      width: 210,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
      filter: "agDateColumnFilter",
      hide: true,
    },
    {
      headerName: createdAt,
      field: "CreatedAt",
      width: 210,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
      filter: "agDateColumnFilter",
      hide: true,
    },
    {
      headerName: updatedAt,
      field: "UpdatedAt",
      width: 210,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: false,
      cellRenderer: DateCellRenderer,
      filter: "agDateColumnFilter",
      hide: false,
    },
    // {
    //   headerName: editLabel,
    //   field: "edit",
    //   width: 90,
    //   filter: false,
    //   sortable: false,
    //   resizable: true,
    //   cellRendererFramework: ActionsCellRenderer
    // }
  ];
  const [columnDefs, setColumnDefs] = useState(columnDefsArray);

  // AG-Grid
  const defColDef = {
    editable: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    sortable: true,
    resizable: true,
    filter: true,
  };
  const [defaultColDef, setDefaultColDef] = useState(defColDef);

  const [rowSelection, setRowSelection] = useState("single");

  // Fetch Grid Data
  async function fetchGridData(pageName) {
    // console.log('In fetchGridData(): appContext.domain is: ', appContext.domain);
    // console.log('In fetchGridData(): pageName is: ', pageName);
    // console.log('In fetchGridData(): resourceLanguage is: ', resourceLanguage);

    let domain = appContext.domain;

    // Handle other domains other than ccms
    if (pageName) {
      domain = pageName.substring(0, pageName.indexOf("-"));
    }
    console.log("In fetchGridData(): domain is: ", domain);

    setFilterDisabledFlag(true);
    setProgressFlag(true);

    let arrayData = [];

    let params = {
      apiName: "PagesAPI",
      path: "/dynamodb/pages/query",
      payload: {
        ConditionalOperator: "AND",
        keyConditionExpression:
          "#pagePK = :pagePK AND begins_with(#pageSK, :pageSK)",
        expressionAttributeNames: { "#pagePK": "PagePK", "#pageSK": "PageSK" },
        expressionAttributeValues: {
          ":pagePK": domain + "#" + pageName,
          ":pageSK": resourceLanguage,
        },
      },
    };

    let initObj = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
      response: true, // return entire response object instead of response.data
      body: {},
    };

    initObj.headers.Authorization = appContext.jwtToken;
    initObj.body = params.payload;
    // console.log('In fetchGridData(): initObj is: ', JSON.stringify(initObj, null, 2));

    API.post(params.apiName, params.path, initObj).then((response) => {
      // console.log('In fetchGridData(): response length is: ', response.data.Items.length);
      // console.log('In fetchGridData(): response is: ', JSON.stringify(response.data.Items, null, 2));
      // let langCode = currentLanguage.languageCode;
      // switch(currentLanguage.languageCode) {
      //   case 'zh':
      //     langCode = "zh-cn";
      //     break;
      //   default:
      //     langCode = currentLanguage.languageCode;
      //     break;
      // };
      response.data.Items.map((item, index) => {
        //   let startDate = new moment(item.StartDate).locale(langCode);
        //   let strStartDate = startDate.format('L');

        //   let endDate = new moment(item.EndDate).locale(langCode);
        //   let strEndDate = endDate.format('L');

        let record = {
          id: "" + index,
          PagePK: item.PagePK,
          PageSK: item.PageSK,

          LanguageCode: item.LanguageCode,

          StartDate: item.StartDate,
          EndDate: item.EndDate,

          // StartDate: strStartDate,
          // EndDate: strEndDate,

          PageHeader: item.PageHeader,
          PageSubHeader: item.PageSubHeader,
          PageContent: item.PageContent,

          ModifiedBy: item.ModifiedBy,
          CreatedAt: item.CreatedAt,
          UpdatedAt: item.UpdatedAt,
        };

        arrayData.push(record);
      });

      setGridData(arrayData);

      if (response.data.Items.length > 0) {
        setFilterDisabledFlag(false);
      }

      setProgressFlag(false);
    }).catch((err) => {
      console.error("In fetchGridData(): err is: ", JSON.stringify(err, null, 2));

      setProgressFlag(false);
    });
  }

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    params.api.sizeColumnsToFit();
  }

  const gridComponents = {
    loadingRenderer: function (params) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<CircularProgress className={classes.progress} color="secondary" size={30}/>';
      }
    },
  };

  const onCellClicked = (event) => {
    // console.log('onCellClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onCellClicked: event.data is: ', event.data);
    // console.log('onCellClicked: event.colDef.field is: ', event.colDef.field);

    // let gridRowsCount;
    // setCheckboxDisabled(false);

    let langCode = currentLanguage.languageCode;
    switch (currentLanguage.languageCode) {
      case "zh":
        langCode = "zh-cn";
        break;
      default:
        langCode = currentLanguage.languageCode;
        break;
    }

    switch (
      event.colDef.field
      // case 'delete':
      //   var selectedData = gridApi.getSelectedRows();
      //   // console.log('onCellClicked: selectedData is: ', selectedData);

      //   let filteredSelectedData = selectedData.filter(data => (data.ResourcePK === event.data.ResourcePK && data.PageHeader === event.data.PageHeader));
      //   // console.log('onCellClicked: filteredSelectedData is: ', filteredSelectedData);

      //   var res = gridApi.updateRowData({ remove: filteredSelectedData });
      //   // console.log("In onCellClicked() res is: ", res);

      //   // Update DeletedGridRows State
      //   if (filteredSelectedData && filteredSelectedData.length > 0){
      //     let tempArray = deletedGridRows;
      //     filteredSelectedData.map((record) => {
      //       tempArray.push(record);
      //     })
      //     setDeletedGridRows(tempArray);
      //   }

      //   // Update number of rows in grid
      //   getRowCount();
      //   initializeForm();

      //   setGridSubmitFlag(true);
      //   break;
      //   case 'edit':
      //     if (event.data) {
      //       setCurrentEditRow(event.data);
      //       setEditRowIndex(event.rowIndex);

      //       let startDate = new moment(event.data.StartDate, 'YYYY-MM-DD', langCode, true);

      //       let endDate = new moment(event.data.EndDate, 'YYYY-MM-DD', langCode, true);

      //       setSelectedPagePK(event.data.PagePK);
      //       setSelectedPageSK(event.data.PageSK);
      //       setSelectedModifiedBy(event.data.ModifiedBy);
      //       setSelectedCreatedAt(event.data.CreatedAt);

      //       setStartDate(startDate);
      //       setEndDate(endDate);

      //       setPageHeader(event.data.PageHeader);
      //       setPageSubHeader(event.data.PageSubHeader);
      //       setPageContent(event.data.PageContent);
      //     }
      //     break;
      //   default:
      //     break;
    ) {
    }
  };

  const onCellValueChanged = (event) => {};

  const onRowSelected = (event) => {
    // console.log('onRowSelected: rowSelectedFlag is: ', rowSelectedFlag);
  };

  const onRowClicked = (event) => {
    // console.log('onRowClicked: Total number of rows is: ', gridApi.getDisplayedRowCount());
    // console.log('onRowClicked: event.rowIndex is: ', event.rowIndex);
    // console.log('onRowClicked: event.data is: ', JSON.stringify(event.data, null, 2));
    // console.log('onRowClicked: clicked row is: ', gridApi.getDisplayedRowAtIndex(event.rowIndex));
    setGridSelectedPage(event.data);
    setRowSelectedFlag(true);
  };

  function getRowCount() {
    let gridRowsCount = gridApi.getModel().getRowCount();
    setGridRowsCount(gridRowsCount);
    return gridRowsCount;
  }

  const onRowValueChanged = (event) => {
    // console.log('onRowValueChanged: event is:  ', event);
  };

  const onRowDataChanged = (event) => {
    setColumnDefs(columnDefsArray);
    setLocaleTextRes(localeText);

    if (
      typeof gridApi !== "undefined" &&
      gridApi !== null &&
      gridApi.getSelectedNodes() !== null &&
      gridApi.getModel().getRowCount() > 0
    ) {
      // console.log('>>>>In onRowDataChanged: gridApi.getModel().getRowCount() is: ', gridApi.getModel().getRowCount());
      // setGridSubmitFlag(true);

      // Update display of number of rows in grid
      getRowCount();
    }
  };

  const onFirstDataRendered = (event) => {
    // console.log('onFirstDataRendered: event is:  ', event);
  };

  const onRowDataUpdated = (event) => {
    // console.log('onRowDataUpdated: event is:  ', event);
  };

  function clearGridData() {
    if (gridApi) {
      gridApi.setRowData([]);

      getRowCount();

      // Disable Submit button
      setGridSubmitFlag(false);
    }
  }

  return (
    <div dir={direction}>
      {renderContentFlag && (
        <div className={classes.root}>
          {/* { !appContext.signedIn &&
          <Redirect to='/signIn' />
        } */}

          <AppMainMenu currLanguage={currentLanguage} homePageFlag={true} />
          <CssBaseline />

          <Container component="div" className={classes.main} maxWidth="lg">
            <div style={{ width: "100%", height: "100%" }}>
              {progressFlag && (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress
                    className={classes.progress}
                    color="secondary"
                    size={15}
                  />
                </div>
              )}
              <Container
                component="div"
                className={classes.mainForm}
                maxWidth="md"
              >
                <Grid container justify="center">
                  <Grid item xs={12} sm={9}>
                    <StyledPaper elevation={3} className={classes.paper}>
                      <Typography
                        variant="h6"
                        style={{ color: "black", textAlign: "center" }}
                      >
                        {convertPageDataLabel}
                      </Typography>

                      <Typography
                        variant="caption"
                        className={classes.colorGreen}
                      >
                        {convertRecordsMsg}
                      </Typography>

                      <LineSpacing value={4} />

                      <Typography
                        variant="caption"
                        className={classes.colorGreen}
                      >
                        {allFieldsAreRequired}
                      </Typography>

                      {globalErrorFlag && (
                        <GlobalErrorMessage globalErrorMsg={globalErrorMsg} />
                      )}

                      <LineSpacing value={24} />

                      <FormControl className={classes.formControl}>
                        <label
                          htmlFor="resourceLanguageId"
                          className={classes.verticalAlignLabel}
                          style={directionAlignment}
                        >
                          <Typography variant="subtitle1">
                            {convertFrom}
                          </Typography>
                        </label>
                        <Select
                          id="resourceLanguageId"
                          value={resourceLanguage}
                          placeholder={selectSourceLanguage}
                          onChange={handleLanguageChange}
                          variant="outlined"
                          displayEmpty={true}
                          className={classes.selectEmpty}
                          autoFocus
                          margin="none"
                        >
                          <MenuItem value={null} disabled>
                            <Typography variant="body1">
                              {selectSourceLanguage}
                            </Typography>
                          </MenuItem>
                          {langSelectOptions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <LineSpacing value={24} />

                      <FormControl className={classes.formControl}>
                        <label
                          htmlFor="resourceLanguageId"
                          className={classes.verticalAlignLabel}
                          style={directionAlignment}
                        >
                          <Typography variant="subtitle1">
                            {convertTo}
                          </Typography>
                        </label>
                        <Select
                          id="resourceLanguageId"
                          value={targetResourceLanguage}
                          placeholder={selectTargetLanguage}
                          onChange={handleTargetLanguageChange}
                          variant="outlined"
                          displayEmpty={true}
                          className={classes.selectEmpty}
                          // autoFocus
                          margin="none"
                        >
                          <MenuItem value={null} disabled>
                            <Typography variant="body1">
                              {selectTargetLanguage}
                            </Typography>
                          </MenuItem>
                          {targetLangSelectOptions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <LineSpacing value={24} />

                      <FormControl className={classes.formControl}>
                        <label
                          htmlFor="pageNameId"
                          className={classes.verticalAlignLabel}
                          style={directionAlignment}
                        >
                          <Typography variant="subtitle1">
                            {pageNameLabel}
                          </Typography>
                        </label>
                        <Select
                          id="pageNameId"
                          value={pageName}
                          placeholder={selectPageName}
                          onChange={handlePageNameChange}
                          variant="outlined"
                          displayEmpty={true}
                          // autoFocus
                          fullWidth
                          style={{ width: "100%", flexGrow: 1 }}
                          margin="none"
                        >
                          <MenuItem value={null} disabled>
                            <Typography variant="body1">
                              {selectPageName}
                            </Typography>
                          </MenuItem>
                          {pageNameSelectOptions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                              {item.text}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <LineSpacing value={24} />
                    </StyledPaper>
                  </Grid>
                </Grid>
              </Container>
            </div>

            <LineSpacing value={16} />

            <Container
              component="div"
              className={classes.mainGrid}
              maxWidth="lg"
            >
              <Grid container justify="center">
                <Grid
                  item
                  className={classes.verticalAlignLabel}
                  style={directionAlignment}
                >
                  <Typography variant="subtitle1" style={directionFloat}>
                    {selectPageToConvert}
                  </Typography>
                </Grid>

                <Grid item xs>
                  <Button
                    variant="contained"
                    size="large"
                    disabled={!rowSelectedFlag ? true : false}
                    color="primary"
                    className={classes.button}
                    onClick={convertService}
                    style={directionReverseFloat}
                  >
                    <SaveIcon
                      className={clsx(classes.leftIcon, classes.iconSmall)}
                    />
                    {submit}
                  </Button>
                </Grid>
              </Grid>

              <div style={{ width: "100%", height: "100%" }}>
                <div
                  id="refGrid"
                  style={{
                    // height: "400px",
                    width: "100%",
                  }}
                  className="ag-theme-balham"
                >
                  <AgGridReact
                    localeText={localeTextRes}
                    domLayout="autoHeight"
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowData={gridData}
                    rowSelection={rowSelection}
                    // rowMultiSelectWithClick={false}
                    rowDeselection={true}
                    suppressRowClickSelection={false}
                    // debug={true}
                    pagination={true}
                    components={gridComponents}
                    paginationPageSize={paginationPageSize}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={1}
                    maxBlocksInCache={2}
                    enableRtl={enableRtl}
                    onCellClicked={onCellClicked}
                    onCellValueChanged={onCellValueChanged}
                    onRowSelected={onRowSelected}
                    onRowClicked={onRowClicked}
                    onRowValueChanged={onRowValueChanged}
                    onRowDataChanged={onRowDataChanged}
                    onRowDataUpdated={onRowDataUpdated}
                    onFirstDataRendered={onFirstDataRendered}
                  />
                </div>
              </div>
            </Container>

            {/* variant: 'success', 'warning', 'error', 'info' */}
            <TsiCustomizedSnackbars
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={snackbarOpen}
              autoHideDuration={8000}
              onClose={snackbarOnClose}
              ContentProps={{ "aria-describedby": "message-id" }}
              message={<span id="message-id">{snackbarsMsg}</span>}
              variant={snackbarsVariant}
            />
          </Container>

          <footer className={classes.footer}>
            <div>
              <PageFooter isSignedIn={isSignedIn} />
            </div>
          </footer>
        </div>
      )}
    </div>
  );
}

export default ConvertPageData;
